import {useAuth} from '@pandora/react-auth-provider';
import {useBillingPreview} from '@pandora/react-contract-account-info';
import {useInvoice} from '@pandora/react-contract-payment';
import {useEnv} from '@pandora/react-env-provider';
import {useLogger} from '@pandora/react-logger-provider';

import API_CONSTANTS from 'common/api/ApiConstants';
import rootStore from 'core/RootStore';
import {getContractForAccountPage} from 'features/account/utils/accountUtils';

/**
 * @description Hook to get account page data. Includes
 *              - Billing preview data
 *              - Invoice data
 */
const useAccountPageData = () => {
  const logger = useLogger();
  const env = useEnv();
  const auth = useAuth();

  const accessToken = auth.getAccessToken();
  const apiKey = API_CONSTANTS.CLIENT_ID;

  const orgId = rootStore.organizationStore.activeOrgId;
  const contract = getContractForAccountPage();
  const contractId = contract?.id;

  // Fetch billing preview data
  const {isBillingPreviewLoading, data: billingPreviewData} = useBillingPreview(
    {accessToken, apiKey, env},
    {contractId, orgId}
  );

  // Fetch invoice data
  const {isLoading: isInvoiceLoading, data: invoiceData} = useInvoice(orgId, {
    clientId: apiKey,
  });

  // Log warnings if no billing preview data is found
  if (!isBillingPreviewLoading && !billingPreviewData) {
    logger.warn('No billing preview data found');
  }

  // Log warnings if no invoice data is found
  if (!isInvoiceLoading && !invoiceData) {
    logger.warn('No invoice data found');
  }
};

export default useAccountPageData;
