import {Chiclet, ImageIcon, ProductPicker} from '@admin-tribe/acsc-ui';
import {Cell, Column, Row, TableBody, TableHeader, TableView} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';

import {COLUMN_KEY} from './ProductMigrationTable.constants';
import {
  getDisabledProductIdList,
  getTargetProductList,
  getUserTargetProducts,
} from './ProductMigrationTableUtils';

const MIN_ITEM_WITH_FIXED_HEIGHT = 6;
const HEIGHT = 345;

/**
 * @description Component to show list of user-assigned products and what they can be migrated to.
 */
const ProductMigrationTable = ({
  eligibleMigrations,
  onProductSelectionChange,
  onSortChange,
  sortDescriptor,
}) => {
  const intl = useIntl();

  // adding fixed height so that the table is scrollable
  const height = eligibleMigrations.length >= MIN_ITEM_WITH_FIXED_HEIGHT ? HEIGHT : undefined;

  const memoUserTargetProducts = useMemo(
    () => getUserTargetProducts(eligibleMigrations),
    [eligibleMigrations]
  );

  return (
    <TableView
      aria-label={intl.formatMessage({id: 'products.productMigrationTable.tableLabel'})}
      height={height}
      onSortChange={onSortChange}
      overflowMode="wrap"
      sortDescriptor={sortDescriptor}
    >
      <TableHeader>
        <Column key={COLUMN_KEY.NAME} allowsSorting>
          {intl.formatMessage({id: 'products.productMigrationTable.column.name'})}
        </Column>
        <Column key={COLUMN_KEY.EMAIL}>
          {intl.formatMessage({id: 'products.productMigrationTable.column.email'})}
        </Column>
        <Column key={COLUMN_KEY.SOURCE_PRODUCT} allowsSorting>
          {intl.formatMessage({id: 'products.productMigrationTable.column.sourceProduct'})}
        </Column>
        <Column key={COLUMN_KEY.TARGET_PRODUCT}>
          {intl.formatMessage({id: 'products.productMigrationTable.column.targetProduct'})}
        </Column>
      </TableHeader>
      {/* Unable to use '<TableBody items={eligibleMigrations}' because
          it would require 'mount' during test to render the children Row
          elements, but Table would eventually crash with `useProvider` error.
       */}
      <TableBody>
        {eligibleMigrations.map((migration) => (
          <Row key={migration.id}>
            {/** Have unique cell key otherwise random rows may show as a blank row */}
            <Cell key={`${migration.id}-${COLUMN_KEY.NAME}`}>
              {migration.user.getDisplayNameOrEmail()}
            </Cell>
            <Cell key={`${migration.id}-${COLUMN_KEY.EMAIL}`}>{migration.user.email}</Cell>
            <Cell key={`${migration.id}-${COLUMN_KEY.SOURCE_PRODUCT}`}>
              <Chiclet
                IconComponent={<ImageIcon alt="" size="S" src={migration.source.iconUrl} />}
                isTruncated
                name={migration.source.displayName}
              />
            </Cell>
            <Cell key={`${migration.id}-${COLUMN_KEY.TARGET_PRODUCT}`}>
              <ProductPicker
                disabledProductIdList={getDisabledProductIdList(migration, memoUserTargetProducts)}
                onSelectionChange={(selectedId) =>
                  onProductSelectionChange({migration, selectedId})
                }
                productList={getTargetProductList(intl, migration.targets)}
                selectedProductId={migration.selectedTargetId}
                width="size-3000"
              />
            </Cell>
          </Row>
        ))}
      </TableBody>
    </TableView>
  );
};

ProductMigrationTable.propTypes = {
  /**
   * List of eligible rows (user + source products + target products)
   */
  eligibleMigrations: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Source product information
       */
      source: PropTypes.shape({
        /**
         * Product display name
         */
        displayName: PropTypes.string.isRequired,
        /**
         * Icon source
         */
        iconUrl: PropTypes.string.isRequired,
        /**
         * Unique ID to identify a product
         */
        id: PropTypes.string.isRequired,
      }).isRequired,
      /**
       * Target product information
       */
      targets: PropTypes.arrayOf(
        PropTypes.shape({
          /**
           * Available count
           */
          count: PropTypes.number.isRequired,
          /**
           * Product display name
           */
          displayName: PropTypes.string.isRequired,
          /**
           * Icon source
           */
          iconUrl: PropTypes.string.isRequired,
          /**
           * Unique ID to identify a product
           */
          id: PropTypes.string.isRequired,
        })
      ),
      /**
       * User information
       */
      user: PropTypes.shape({
        /**
         * User's email
         */
        email: PropTypes.string.isRequired,
        /**
         * Function that returns the user name
         */
        getDisplayNameOrEmail: PropTypes.func.isRequired,
        /**
         * User ID
         */
        id: PropTypes.string.isRequired,
      }),
    })
  ).isRequired,
  /**
   * Callback when a new product is assigned to a user.
   * Callback params:
   *   {
   *     migration: an entry from eligibleMigrations
   *     selectedId: either NO_ASSIGNMENT_ID or the "id" from one of eligibleMigration.targets[n].id
   *   }
   */
  onProductSelectionChange: PropTypes.func.isRequired,
  /**
   * Callback when the user sorts table.
   * Callback params:
   *   {
   *     column: column key to sort on
   *     direction: 'ascending' or 'descending'
   *   }
   */
  onSortChange: PropTypes.func.isRequired,
  /**
   * Table sort descriptor
   */
  sortDescriptor: PropTypes.shape({
    /**
     * Column key to sort on
     */
    column: PropTypes.string.isRequired,
    /**
     * Sort direction, either 'ascending' or 'descending'
     */
    direction: PropTypes.string.isRequired,
  }).isRequired,
};

export default ProductMigrationTable;
