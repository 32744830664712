import {configStore, getHeaders} from '@admin-tribe/acsc';
import axios from 'axios';

let clientId, includeRoles, url;

(async function loadJilConfig() {
  ({url, clientId, includeRoles} = await configStore.getServiceConfiguration('jil'));
  url = `${url}/v2/organizations`;
})();

/**
 * @description Gets a list of trusts for the given org id
 *
 * @param {Object} options - The request parameters object
 * @param {String} options.orgId - Organization id
 * @param {number} options.page - Page to get
 * @param {number} options.page_size - Number of items to get
 * @param {String} options.search_query - A query to search by
 * @param {String} options.status - Status of the trust
 * @param {String} options.sort - Property on which to sort
 * @param {String} options.sort_order - Ascending or descending
 *
 * @returns {Promise} Axios request promise
 */
const getTrusts = ({orgId, ...params}) =>
  axios.get(`${url}/${orgId}/trusts`, {
    headers: getHeaders({clientId, includeRoles}),
    params,
  });

/**
 * Gets the trust data for the provided org id and directory id
 *
 * @param orgId - Trustee org id
 * @param directoryId - Id of the trusted directory
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
const getTrust = ({orgId, directoryId}) =>
  axios.get(`${url}/${orgId}/trusts/${directoryId}`, {
    headers: getHeaders({clientId, includeRoles}),
  });

/**
 * @description Applies operations to a trust resource
 *
 * @param {String} options.orgId - Organization id
 * @param {Array<{op: String, path: String}>} operations - An array of operations to update trust notifications
 */
const patchTrusts = ({orgId, operations}) =>
  axios.patch(`${url}/${orgId}/trusts`, operations, {
    headers: getHeaders({clientId, includeRoles}),
  });

/**
 * @description Post requests for access to the specified directories
 *
 * @param {String} options.orgId - Organization id
 * @param {Array<{directoryId: String}>} directories - The directories to request access to
 *
 * @returns {Promise} Axios request promise
 */
const postTrusts = ({directories, orgId}) =>
  axios.post(`${url}/${orgId}/trusts`, directories, {
    headers: getHeaders({clientId, includeRoles}),
  });

/**
 * Used to post data to the notifications API, part of the trusts API.
 *
 * @param {String} directoryId Trusted directory id
 * @param {String} orgId Trustee organization id
 * @param {{directoryId: String}} data Post body
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
const postTrustNotification = ({directoryId, orgId, data}) =>
  axios.post(`${url}/${orgId}/trusts/${directoryId}/notifications`, data, {
    headers: getHeaders({clientId, includeRoles}),
  });

const jilTrusts = {
  getTrust,
  getTrusts,
  patchTrusts,
  postTrustNotification,
  postTrusts,
};

export default jilTrusts;
export {getHeaders};
