import {
  Cache,
  DIRECTORY_EVENT,
  DIRECTORY_OWNERSHIP_STATUS,
  DIRECTORY_TYPE,
  eventBus,
  jilDirectories,
  log,
} from '@admin-tribe/acsc';
import {useCallback} from 'react';

import {DIRECTORY_STATUSES} from 'common/entities/DirectoryEntity';
import rootStore from 'core/RootStore';
import {getAuthSource} from 'features/settings/api/ims-federated';
import {IDP_TYPES} from 'features/settings/entities/IdpEntity';
import {getCertificates} from 'features/settings/hooks/api/useSamlCertificates';
import {mergeParamsWithDefaults} from 'features/settings/hooks/api/utils/hooksApiUtils';

const directoryListCache = new Cache();

/**
 * Invalidate cache when registering Angular events
 */
const invokeDirectoryEventListener = () => {
  eventBus.registerEventHandler((eventId) => {
    if (eventId === DIRECTORY_EVENT.UPDATE) {
      directoryListCache.clearAll();
    }
  });
};

invokeDirectoryEventListener();

const DIRECTORY_LIST_CACHE_KEY = 'directoryList';

/**
 * Creates the directory list cache key from the provided params.
 *
 * @returns {String} The constructed cache key
 */
const getDirectoryListCacheKey = ({
  ownershipStatus,
  page,
  pageSize,
  searchQuery,
  sort,
  sortOrder,
  status,
}) =>
  `${DIRECTORY_LIST_CACHE_KEY}/${rootStore.organizationStore.activeOrgId}.${[
    ownershipStatus,
    page,
    pageSize,
    searchQuery,
    sort,
    sortOrder,
    status,
  ]
    .filter((item) => !!item)
    .join('.')}`;

/**
 * Creates the directory list cache key for global fetches
 * (all directory items).
 *
 * @returns {String} The constructed cache key
 */
const getGlobalDirectoryListCacheKey = () =>
  `${DIRECTORY_LIST_CACHE_KEY}/${rootStore.organizationStore.activeOrgId}`;

/**
 * Gets the list of directories with he provided filtering params.
 * @param options.ownershipStatus - filter by ownership status of directory
 * @param options.page - page to get
 * @param options.pageSize - how many directories to get
 * @param options.searchQuery - filter by a search query
 * @param options.sort - sort the response
 * @param options.sortOrder - the order of sorting
 * @param options.status - filter by the status of directory
 *
 * @returns {Promise<{data, pageSize, totalCount: *}|{data, pageSize, totalCount: *}>}
 */
const getDirectoryList = async (options = {}) => {
  const params = mergeParamsWithDefaults({ownershipStatus: options.ownershipStatus, ...options});

  // check the cache
  const cacheKey = getDirectoryListCacheKey(params);

  try {
    const cachedPromise = directoryListCache.get(cacheKey);

    let response;

    if (cachedPromise) {
      response = await cachedPromise;
    } else {
      const requestPromise = jilDirectories.getDirectories({
        orgId: rootStore.organizationStore.activeOrgId,
        ownershipStatus: params.ownershipStatus,
        page: params.page,
        page_size: params.pageSize,
        search_query: params.searchQuery,
        sort: params.sort,
        sort_order: params.sortOrder,
        status: params.status,
      });

      directoryListCache.put(cacheKey, requestPromise);

      response = await requestPromise;
    }

    return {
      data: response.data,
      pageSize: params.pageSize,
      totalCount: Number.parseInt(response.headers['x-total-count'], 10),
    };
  } catch (error) {
    log.error('[ID][JIL] Error getting the list of directories', error);

    directoryListCache.clear(cacheKey);

    throw error;
  }
};

/**
 * Gets the list of directories with certificates using the provided filtering params.
 * @param options.ownershipStatus - filter by ownership status of directory
 * @param options.page - page to get
 * @param options.pageSize - how many directories to get
 * @param options.searchQuery - filter by a search query
 * @param options.sort - sort the response
 * @param options.sortOrder - the order of sorting
 * @param options.status - filter by the status of directory
 *
 * @returns {Promise<{data, pageSize, totalCount: *}|{data, pageSize, totalCount: *}>}
 */
const getDirectoryListWithCertificates = async (options = {}) => {
  const {isGlobal, ...optionParams} = options;
  const params = mergeParamsWithDefaults({
    ownershipStatus: optionParams?.ownershipStatus,
    ...optionParams,
  });

  // check the cache
  const cacheKey = isGlobal ? getGlobalDirectoryListCacheKey() : getDirectoryListCacheKey(params);

  try {
    const cachedPromise = directoryListCache.get(cacheKey);

    let response;

    if (cachedPromise) {
      response = await cachedPromise;
    } else {
      let requestPromise;
      if (isGlobal) {
        requestPromise = jilDirectories.getDirectories({
          orgId: rootStore.organizationStore.activeOrgId,
        });
      } else {
        requestPromise = jilDirectories.getDirectories({
          orgId: rootStore.organizationStore.activeOrgId,
          ownershipStatus: params.ownershipStatus,
          page: params.page,
          page_size: params.pageSize,
          search_query: params.searchQuery,
          sort: params.sort,
          sort_order: params.sortOrder,
          status: params.status,
        });
      }

      directoryListCache.put(cacheKey, requestPromise);

      response = await requestPromise;

      const federatedActiveAndOwnedDirectories = response.data.filter(
        (directory) =>
          directory.status === DIRECTORY_STATUSES.ACTIVE &&
          directory.ownershipStatus === DIRECTORY_OWNERSHIP_STATUS.OWNED &&
          directory.type === DIRECTORY_TYPE.TYPE3
      );

      await Promise.all(
        federatedActiveAndOwnedDirectories.map(async (directory, index) => {
          const authSourceResponse = await getAuthSource({
            authSourceId: directory.id,
            orgId: rootStore.organizationStore.activeOrgId,
          });
          const directoryDetails = authSourceResponse?.data;

          const defaultSamlIdp = directoryDetails.idps.find(
            (idp) => idp.id === directoryDetails.defaultIdp && idp.federationType === IDP_TYPES.SAML
          );

          if (defaultSamlIdp) {
            try {
              const certificates = await getCertificates({
                directoryId: directory.id,
                idpId: directoryDetails.defaultIdp,
              });
              federatedActiveAndOwnedDirectories[index].idps = directoryDetails.idps;
              federatedActiveAndOwnedDirectories[index].defaultIdp = directoryDetails.defaultIdp;
              federatedActiveAndOwnedDirectories[index].defaultSamlIdp = defaultSamlIdp;
              federatedActiveAndOwnedDirectories[index].certificates = certificates;
            } catch (error) {
              log.error(
                `[ID][JIL] Error getting certificates for directory ${directory.id}`,
                error
              );
              throw error;
            }
          }
        })
      );
    }

    return {
      data: response.data,
      pageSize: params.pageSize,
      totalCount: Number.parseInt(response.headers['x-total-count'], 10),
    };
  } catch (error) {
    log.error('[ID][JIL] Error getting the list of directories', error);

    directoryListCache.clear(cacheKey);

    throw error;
  }
};

/**
 * Deletes the provided list of directories
 *
 * @param directories - array of directories to be deleted
 *
 * @returns {Promise<{deleted: *, notDeleted: *}>}
 */
const deleteDirectories = async (directories) => {
  const operations = directories.map((directory) => ({
    op: 'remove',
    path: `/${directory.id}`,
  }));

  try {
    const response = await jilDirectories.patchDirectories({
      operations,
      orgId: rootStore.organizationStore.activeOrgId,
    });

    const deleted = response.data;
    const notDeleted = directories.filter(
      (directory) => !deleted.find((deletedDirectory) => deletedDirectory.id === directory.id)
    );

    return {
      deleted,
      notDeleted,
    };
  } catch (error) {
    log.error('[ID][JIL] Error deleting directories', error);

    throw error;
  }
};

/**
 * A hook that helps with making calls to the JIL API
 * Handles logging errors and caching.
 */
const useDirectoryList = () => {
  const getDirectoryListCallback = useCallback(getDirectoryList, []);
  const getDirectoryListWithCertificatesCallback = useCallback(
    getDirectoryListWithCertificates,
    []
  );
  const deleteDirectoriesCallback = useCallback(deleteDirectories, []);

  const clearDirectoryCache = useCallback(() => directoryListCache.clearAll(), []);

  return {
    clearDirectoryCache,
    deleteDirectories: deleteDirectoriesCallback,
    getDirectoryList: getDirectoryListCallback,
    getDirectoryListWithCertificates: getDirectoryListWithCertificatesCallback,
  };
};

export default useDirectoryList;
export {
  directoryListCache,
  invokeDirectoryEventListener,
  getDirectoryList,
  getDirectoryListWithCertificates,
  getDirectoryListCacheKey,
  getGlobalDirectoryListCacheKey,
  deleteDirectories,
};
