import {Page, PageBanners, PageContent, PageHeader} from '@admin-tribe/acsc-ui';
import React from 'react';
import {useIntl} from 'react-intl';

import useDocumentTitle from 'common/hooks/useDocumentTitle';
import IdentityContext from 'features/settings/common/components/identity-context/IdentityContext';
import IdentityBanners from 'features/settings/pages/identity-page/IdentityBanners';
import IdentityPageScorecards from 'features/settings/pages/identity-page/IdentityPageScorecards';
import IdentityTabs from 'features/settings/pages/identity-page/IdentityTabs';

const IdentityPage = () => {
  const intl = useIntl();
  const PAGE_TITLE = intl.formatMessage({id: 'settings.identity.title'});

  useDocumentTitle({defaultTitle: PAGE_TITLE});

  return (
    <IdentityContext>
      <Page data-testid="identity-page">
        <PageBanners>
          <IdentityBanners />
        </PageBanners>
        <IdentityPageScorecards />
        <PageHeader title={PAGE_TITLE} />
        <PageContent>
          <IdentityTabs />
        </PageContent>
      </Page>
    </IdentityContext>
  );
};

export default IdentityPage;
