const B2B_FREE_OFFER_NOTIFICATION_CONTENT = 'b2b_free_offer_notification_t30';
const B2B_FREE_OFFER_PROVISIONING_CONTENT = 'b2b_free_offer_provisioning';
const ONE_DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000;
const REMIND_LATER_BANNER_EXPIRY_IN_DAYS = 7;
const REMIND_LATER_KEY = 'remindLaterTnc';
const API_ERROR_KEY = 'freeProductModalApiError';
const API_ERROR_EXPIRY_IN_DAYS = 2;
const SHOW_AEM_MODAL = 'showAemModal';

export {
  B2B_FREE_OFFER_NOTIFICATION_CONTENT,
  B2B_FREE_OFFER_PROVISIONING_CONTENT,
  ONE_DAY_IN_MILLISECONDS,
  REMIND_LATER_BANNER_EXPIRY_IN_DAYS,
  REMIND_LATER_KEY,
  API_ERROR_KEY,
  API_ERROR_EXPIRY_IN_DAYS,
  SHOW_AEM_MODAL,
};
