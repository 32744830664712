import {authentication, configStore} from '@admin-tribe/acsc';
import axios from 'axios';

import API_CONSTANTS from 'common/api/ApiConstants';

const {CLIENT_ID} = API_CONSTANTS;

let url;

// Configure ASW data APIs
(async function loadConfig() {
  ({url} = await configStore.getServiceConfiguration('aswMigration'));
  url = `${url}/organizations`;
})();

/**
 * @description Generates the headers required for API requests, including content type, API key, and authorization token if available.
 *
 * @return {Object} An object containing the necessary headers for API requests.
 */
const getHeaders = () => {
  const headers = {
    'Content-Type': 'application/json',
    'X-Api-Key': CLIENT_ID,
  };
  const token = authentication.getAccessToken()?.token;
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  return headers;
};

/**
 * @description Fetches the free product context for a user
 *
 * @return {Promise} a promise which will resolve with the response
 */
const getFreeProductModal = async (orgId) => {
  const response = await axios.get(`${url}/${orgId}/freeProductModals`, {
    headers: getHeaders(),
  });
  return response.data;
};

/**
 * @description manages the retry calls for the request
 *
 * @return {Promise} a response
 */
async function fetchWithRetry(uri, options = {}, retries = 3, delay = 1000) {
  try {
    const response = await axios(uri, options);

    const statusCode = response.status;
    if (Math.floor(statusCode / 100) !== 2) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return response.data;
  } catch (error) {
    if (retries > 0) {
      await new Promise((_resolve) => {
        setTimeout(_resolve, delay);
      }); // wait before retrying
      return fetchWithRetry(uri, options, retries - 1, delay);
    }
    throw new Error(`Failed after all attempts: ${error.message}`);
  }
}

/**
 * @description Updates the free product context for a user
 *
 * @return {Promise} a promise which will resolve with the response data
 */
const updateFreeProductModalStatus = async (modalType, orgId) => {
  await fetchWithRetry(`${url}/${orgId}/freeProductModals`, {
    data: {modalType},
    headers: getHeaders(),
    method: 'POST',
  });
};

export {fetchWithRetry, getFreeProductModal, getHeaders, updateFreeProductModalStatus};
