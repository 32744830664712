/* eslint-disable max-lines -- this file requires more lines */
import {
  AuthenticatedUser,
  FULFILLABLE_ITEM_CODE,
  MEMBER_TYPE,
  PRODUCT_DELEGATION_TARGET,
  QUALIFIER_TARGET,
  feature,
  hasIndirectContract,
  hasOnlyTeamProducts,
  hasOnlyTrialContracts,
} from '@admin-tribe/acsc';

import rootStore from 'core/RootStore';
import {hasManageAdminsPolicy} from 'core/admin/access/adminAccess';
import {
  canAssignDeveloper as canAssignDeveloperToOrg,
  isAllowedToAddProducts,
  isOrgAdmin,
  isReadOnly,
  isT2eOrg,
} from 'core/organizations/access/organizationAccess';
import trialHelper from 'core/products/trial-helper/trialHelper';
import {
  isCustomModelProduct,
  isProductWithFulfillableItem,
} from 'core/products/utils/consumableProductUtils';

import auth from '../../services/auth';

/**
 * @description Method to determine if admins can be assigned to a product.
 * @param {Product} product the product to check
 *
 * @returns {Boolean} Returns true if an admin can be assigned, else false.
 */
function canAssignAdmin(product) {
  if (!hasManageAdminsPolicy()) {
    return false;
  }
  return (
    product.fulfillableItemList.hasLaboratoryLicenseManagement() ||
    canAssignUserIgnoringLicenseCounts(product)
  );
}

/**
 * @description Method to determine if members can be assign to a product.
 *   Certain members are not allowed to be assigned to normal assignable products.
 *   For example, some products are disabled for TYPE1 users.
 *
 * @param {Product} product the product to check
 * @param {Member} member the member to check
 *
 * @returns {Boolean} Returns true if the member can be assigned, else false.
 */
function canAssignMember(product, member) {
  if (isReadOnly() || !product.isAdministerable()) {
    return false;
  }

  const memberType = member.getType();
  if (memberType.isUser()) {
    return product.isDelegatableToType(memberType.type);
  }
  if (memberType.isTechnicalAccount()) {
    return product.isDelegatableToType(PRODUCT_DELEGATION_TARGET.API_KEY);
  }
  if (memberType.isUserGroup()) {
    return product.fulfillableItemList.hasUserGroupAssignment();
  }
  // For any unexpected type, we deny access
  return false;
}

/**
 * @description Method to determine if a product can be assigned with Type 1 users
 * @param {Product} product the product to check
 *
 * @returns {Boolean} Returns true if the product can be assigned with Type 1 users
 */
function canAssignType1User(product) {
  if (isReadOnly()) {
    return false;
  }

  return product.isDelegatableToType(MEMBER_TYPE.TYPE1);
}

/**
 * @description Method to determine if a product can be assigned with user.
 * @param {Product} product the product to check
 *
 * @returns {Boolean} True if can be assigned with user, false otherwise.
 */
function canAssignUser(product) {
  return canAssignUserIgnoringLicenseCounts(product) && isEnterpriseProductOrHasLicenses();

  function isEnterpriseProductOrHasLicenses() {
    // Trial products may have 'available' licenses even after the trial has expired so we also need to check if
    // product is an expired trial.
    return !product.hasNoAvailableLicenses() && !trialHelper.isExpiredTrial(product);
  }
}

/**
 *
 * @param {Product} product
 * @returns whether the product can be assigned a developer
 */
function canAssignDeveloper(product) {
  return (
    canAssignDeveloperToOrg() &&
    product.isDelegatableToType(PRODUCT_DELEGATION_TARGET.API_KEY) &&
    product.isAdministerable()
  );
}

/**
 * @description Method to determine if a product can be assigned with user ignoring license counts
 * @param {Product} product the product to check
 *
 * @returns {Promise<Boolean>} True if can be assigned with user, false otherwise.
 */
function canAssignUserIgnoringLicenseCounts(product) {
  return (
    auth.canAddUsers() &&
    !isReadOnly() &&
    product.isAdministerable() &&
    product.fulfillableItemList.hasDelegationType() &&
    (feature.isDisabled('temp_onesie_35741') || product.isDelegatableToUser()) &&
    !isExcludedProduct() &&
    rootStore.organizationStore.migrationList.shouldAlignWithAddUserLogic()
  );

  //////////////

  function isExcludedProduct() {
    return (
      product.isFeatureRestrictedLicense() ||
      product.isLegacyDeviceLicense() ||
      product.fulfillableItemList.hasLaboratoryLicenseManagement() ||
      product.fulfillableItemList.hasOrgOnDemandConsumable() ||
      product.fulfillableItemList.hasOrgDelegatable()
    );
  }
}

/**
 * @description Method to determine if this is a team trial that can be converted to a
 *   paid product.
 * @param {Product} product the product to check
 *
 * @returns {Boolean} True if this is a trial which can be converted to a paid product.
 */
function canBuyTrialProduct(product) {
  return (
    hasOnlyTrialContracts(rootStore.organizationStore.contractList) &&
    trialHelper.isTrialProduct(product)
  );
}

/**
 * @description Method to check the url have /add-product for opening the add product mini application in modal popup
 * when mini_app_all OR us_direct flag is on along with deep_link feature flag
 * @returns {Boolean} returns true of self cancel deeplink can be opened
 */
function canViewAddProductMiniAppDeepLink(path) {
  const isAddProductLink = path.includes('/add-products');
  return isAddProductLink && canUseAddProductMiniApp() && canUseAddProductMiniAppForDeepLink();
}

/**
 * @description returns true if path contains "/add-products" URI and feature flag is on for full rollout
 * @param {String} path - the path being requested
 *
 * @returns {Boolean} - return with a boolean indicating whether to open add products in mini app
 */
function canPreventAddProductDefaultRouteForMiniApp(path) {
  // When user click on add-license button on products page
  // Need to check based on url in case of deep_link feature flag is on and mini_all is off
  if (path.includes('/add-products') && canUseAddProductMiniAppForDeepLink()) {
    // add license is only enabled when all flag is on
    if (path.includes('/users')) {
      return canUseAddProductMiniAppForAll();
    }

    // If user click on the buy more button on the overview pages
    return canUseAddProductMiniApp();
  }
  return false;
}

/**
 * @description Method to determine if a product can have additional licenses
 *    purchased.
 * @param {Product} product the product to check
 *
 * @returns {Boolean} Returns true if licenses can be purchased, false otherwise.
 */
function canPurchaseLicenses(product) {
  if (isReadOnly() || !isAllowedToAddProducts()) {
    return false;
  }

  // If product has a PREVENT_PA_CREATION qualifier, licenses can't
  // be purchased.
  return (
    !product.hasTargetQualifier(QUALIFIER_TARGET.PREVENT_PA_CREATION) &&
    (product.isTeam() || product.isEnterpriseIndirect()) &&
    !product.hasUnlimitedLicenses()
  );
}

/**
 * @description Method to determine if a admin can edit products.
 *
 * @returns {Boolean} True if admin can edit products, false otherwise.
 */
function canEditProducts() {
  return !isReadOnly() && auth.canAddUsers();
}

/**
 * @description Whether the auto assignment modal should be enabled
 *   on a Product page.
 * @param {Product} product - the Product model
 * @returns true if the modal should be enabled, false otherwise
 */
function canEnableAutoAssignmentModal(product) {
  return canViewAutoAssignmentRules() && product.isTeam() && product.isDelegatableToUser();
}

/**
 * @description Method to check the url have /assign-products for opening the assign users to products modal popup
 * @returns {Boolean} returns true if assign users deeplink can be opened
 */
function canViewAssignProductsDeepLink(path) {
  const isAssignProductsLink = path.includes('/assign-products');
  return isAssignProductsLink && canUseAssignProductsDeepLink();
}

/**
 * @description Method to determine if a product user can be removed
 *
 * @returns {Boolean} True if user can be removed, false otherwise.
 */
function canRemoveUser() {
  return !isReadOnly() && auth.canAddUsers();
}

/**
 * @description Method to determine if a product admin can be removed
 *
 * @returns {Boolean} True if admin can be removed, false otherwise.
 */
function canRemoveAdmin() {
  return (
    hasManageAdminsPolicy() &&
    !isReadOnly() &&
    (auth.isUserOrgAdmin() || auth.isUserContractAdmin() || auth.isUserProductAdmin())
  );
}

/**
 * @description Method to determine if Admins can be displayed for a product.
 * @param {Product} product the product to check
 *
 * @returns {Boolean} Returns true if Admins can be displayed, else false.
 */
function canShowAdmins(product) {
  if (
    product.customerSegment === 'TEAM' ||
    product.isLegacyDeviceLicense() ||
    product.isFeatureRestrictedLicense() ||
    product.fulfillableItemList.hasOrgDelegatable()
  ) {
    return false;
  }
  return (
    auth.isUserOrgAdmin() ||
    auth.isUserContractAdmin() ||
    (auth.isUserProductAdmin() && auth.isUserProductAdminForTarget(product?.targetExpression))
  );
}

/**
 * @description To determine whether a product can show its buying program.
 * @param {Product|ProductGroupProductList} product or product group model instance.
 *
 * @returns {Boolean} Returns true if buying program can be shown in appropriate places, else false.
 */
function canShowBuyingProgram(productList, productOrProductGroup) {
  return (
    productList?.hasBuyingProgramEtlaAndVipProducts &&
    ((productOrProductGroup?.isBuyingProgramETLA?.() ?? false) ||
      (productOrProductGroup?.isBuyingProgramVIP?.() ?? false))
  );
}

/**
 * @description Method to determine if the product has consumable quota items to show.
 *   Note that this returns false for Enterprise Stock.
 * @param {Product} product the product to check
 *
 * @returns {Boolean} Returns true if there are consumable quota items to show, else false.
 */
function canShowConsumableQuotaTypeItems(product) {
  return (
    product.fulfillableItemList.hasConsumableQuotaTypeItems({
      includeDelegationTypePerson: false,
      requireDelegationConfigurable: false,
    }) &&
    !product.hasLicenseBasedSign() &&
    !product.isFeatureRestrictedLicense() &&
    !product.fulfillableItemList.hasOrgDelegatable() &&
    !(product.isEnterprise() && product.isAdobeStock())
  );
}

/**
 * @description returns true if the add product workflow should use the mini app
 *
 * @returns {Boolean} - return with a boolean indicating whether to open add products in mini app
 */
function canUseAddProductMiniApp() {
  return canUseAddProductMiniAppForUSDirect() || canUseAddProductMiniAppForAll();
}

/**
 * @description returns true if mini-app and mini-app-all feature flag on
 *
 * @returns {Boolean} - return with a boolean indicating whether to open add products in mini app
 */
function canUseAddProductMiniAppForAll() {
  return (
    feature.isEnabled('temp_add_product_mini_app') &&
    feature.isEnabled('temp_add_product_mini_app_all')
  );
}

/**
 * @description returns true if mini_app and deep_link feature flag on
 *
 * @returns {Boolean} - return with a boolean indicating whether to use deep link feature for mini app
 */
function canUseAddProductMiniAppForDeepLink() {
  return (
    feature.isEnabled('temp_add_product_mini_app') &&
    feature.isEnabled('temp_add_product_mini_app_deep_link')
  );
}

/**
 * @description returns true if mini-app and mini-app-us-direct feature flag on
 *
 * @returns {Boolean} - return with a boolean indicating whether to open add products in mini app
 */
function canUseAddProductMiniAppForUSDirect() {
  return (
    feature.isEnabled('temp_add_product_mini_app') &&
    feature.isEnabled('temp_add_product_mini_app_us_direct')
  );
}

/**
 * @description returns true if deep_link feature flag on
 *
 * @returns {Boolean} - return with a boolean indicating whether to use deep link feature for assign users app
 */
function canUseAssignProductsDeepLink() {
  return feature.isEnabled('temp_assign_products_deep_link');
}

/**
 * @description Method to determine if admin can view Auto Assignment Rules
 *
 * @returns {Boolean} Resolves to true if the admin can view the Auto Assignment Rules, false otherwise
 */
function canViewAutoAssignmentRules() {
  return isT2eOrg() && isOrgAdmin();
}

/**
 * @description method to check if the user can view the product devices tab.
 * @param {Product} product
 * @returns {Boolean} True if can view developer or integrations tab
 */
function canViewLegacyDevices(product) {
  return product.isAdministerable() && product.isLegacyDeviceLicense();
}

/**
 * @description method to check if org delegatable unlimited page can be viewed.
 * @param {Product} product the product model
 * @returns {Boolean} true if org delegatable unlimited product page can be viewed
 */
function canViewOrgDelegatableUnlimited(product) {
  return product.isOrgDelegatableUnlimited();
}

/**
 * @description Method to determine if admin can view the Quick Assign Modal.
 *
 * @returns {Boolean} Resolves to true if the admin can view the Quick Assign Modal, false otherwise
 */
function canViewQuickAssignModal() {
  const productList = rootStore.organizationStore.productList;
  const contractList = rootStore.organizationStore.contractList;

  const atLeastOneCanAssign = productList.items.some((product) => canAssignUser(product));

  if (feature.isEnabled('bug_fix_sqa_workaround')) {
    return hasOnlyTeamProducts(productList) && atLeastOneCanAssign;
  }

  const contractIsInRenewalWindow = contractList.items.some((contract) =>
    contract.isInRenewalWindow()
  );

  return !contractIsInRenewalWindow && hasOnlyTeamProducts(productList) && atLeastOneCanAssign;
}

/**
 * @description Method to determine if admin can view the Quick Assign Modal.
 *
 * @returns {Boolean} Resolves to true if the admin can view the Quick Assign Modal, false otherwise
 *          Returns true even if there are no unassigned licenses in the org
 */
function canDisplayQuickAssignModal() {
  const productList = rootStore.organizationStore.productList;
  const contractList = rootStore.organizationStore.contractList;

  if (feature.isEnabled('bug_fix_sqa_workaround')) {
    return hasOnlyTeamProducts(productList);
  }

  const contractIsInRenewalWindow = contractList.items.some((contract) =>
    contract.isInRenewalWindow()
  );

  return !contractIsInRenewalWindow && hasOnlyTeamProducts(productList);
}

/**
 * @description Method to determine if the admin can view this product.
 *   These are the same roles that are in the products route file.
 *
 * @returns {Boolean} True if admin can view product, false otherwise.
 */
function canViewProduct() {
  const userRoles = AuthenticatedUser.get().getRoles();
  const orgId = rootStore.organizationStore.activeOrgId;

  return (
    userRoles.isDeploymentAdminForOrg(orgId) ||
    userRoles.isOrgAdminForOrg(orgId) ||
    userRoles.isContractAdminForOrg(orgId) ||
    userRoles.isProductAdminForOrg(orgId) ||
    auth.isUserProfileAdmin() ||
    (feature.isEnabled('temp_adobe_agent_access') && userRoles.isActingAsAdobeAgentForOrg(orgId))
  );
}

/**
 * @description Method to determine if admin can view product access requests
 *
 * @returns {Boolean} Resolves to true if the admin can view the product access requests, false otherwise
 */
function canViewProductAccessRequests() {
  return isT2eOrg() && isOrgAdmin();
}

/**
 * @description Method to determine if the admin can view the product details bulk operation pages.
 * @param {Product} product the product model
 * @returns {Boolean} True if admin can view product bulk op pages, false otherwise.
 */
function canViewProductBulkOperations(product) {
  return product.isAdministerable() && product.isTeam();
}

/**
 * @description Method to determine if the admin can view this product's license count
 * @param {Product} product the product to check
 *
 * @returns {Boolean} True if admin can view product license count, otherwise false
 */
function canViewProductLicenseCount(product) {
  // Excludes products which don't use seat based delegation, and those which use the consumables API
  return canViewProduct() && product.usesSeatBasedDelegation() && !product.isConsumable();
}

/**
 * @description Method to determine if the admin can view this product's custom model usage count
 * @param {Product} product the product to check
 *
 * @returns {Boolean} True if admin can view custom model count, otherwise false
 */
function canViewCustomModelUsageCount(product) {
  return (
    feature.isEnabled('temp_custom_model_scorecards') &&
    isCustomModelProduct(product) &&
    canViewProduct()
  );
}

/**
 * @description Method to determine if the admin can view this product's firefly_api usage count
 * @param {Product} product The product to check
 *
 * @returns {Boolean} True if admin can view the firefly_api product and its usage, otherwise false
 */
function canViewFireflyApiUsageCount(product) {
  return (
    feature.isEnabled('temp_firefly_api_scorecards') &&
    isProductWithFulfillableItem(product, 'firefly_api') &&
    canViewProduct()
  );
}

/**
 * @description Returns true if transactions tab can be viewed, false otherwise.
 * @param {Product} product the product model
 * @returns {Boolean} true if transactions tab can be viewed, false otherwise.
 */
function canViewTransactions(product) {
  return product.isGroupConsumable() && product.includesBusinessOrEnterpriseSign();
}

/**
 *
 * @description method to check if the product can show users.
 * @param {Product} product
 * @returns {Boolean} True if can show product profile users
 */
function canViewUsers(product) {
  return (
    product.isAdministerable() &&
    !product.isSharedDeviceLicense() &&
    product.fulfillableItemList.hasDelegationType() &&
    !product.isLegacyDeviceLicense() &&
    !product.isFeatureRestrictedLicense() &&
    !product.fulfillableItemList.hasOrgDelegatable()
  );
}

/**
 * Method to determine if the org has any products which allow a developer to be assigned.
 *
 * @returns {Boolean} true if there is a product which allows a developer to be assigned, else false
 */
function hasAnyDeveloperProducts() {
  return rootStore.organizationStore.productList.items.some(canAssignDeveloper);
}

/**
 * @description Method to determine if there is enough quota to assign product support admin.
 *
 * @param {ConsumableSummarizationList} consumableSummarizationList the consumableSummarizationList to get quota
 * @param {Product} product the product to check
 *
 * @returns {Boolean} Returns true if product support admin can be assigned, else false.
 */
function hasQuotaToAssignProductSupportAdmin(consumableSummarizationList, product) {
  const consumableData = consumableSummarizationList.getConsumableForSummaryIdAndFICode(
    FULFILLABLE_ITEM_CODE.SUPPORT_ALLOWED_ADMINS,
    product.id
  );

  // temporary override to mitigate risk for inconsistent consumable quota data
  return feature.isEnabled('temp_hide_psa_quota') ? true : consumableData?.remainingQuantity > 0;
}

/**
 * @description determines whether a product has FI for 'api_access', i.e. whether it is an "API product"
 * @returns {Promise} resolved with a boolean indicating whether the FI is present.
 */
function canViewIntegrations() {
  const isUserOrgAdmin = auth.isUserOrgAdmin();

  if (!isUserOrgAdmin) return false;
  const productList = rootStore.organizationStore.productList;

  if (!productList.items) return false;
  return productList.items.some((product) =>
    product.isDelegatableToType(PRODUCT_DELEGATION_TARGET.API_KEY)
  );
}

/**
 * @description determine whether user has access to view products section
 *       @bluu: Some of the other functions in this module should probably also check this
 * @returns {Boolean} true if user has access to view products section
 */
function canViewProducts() {
  const userRoles = AuthenticatedUser.get().getRoles();
  const orgId = rootStore.organizationStore.activeOrgId;

  return (
    userRoles.isOrgAdminForOrg(orgId) ||
    (feature.isEnabled('temp_contract_admin_role') && userRoles.isContractAdminForOrg(orgId)) ||
    userRoles.isProductAdminForOrg(orgId) ||
    userRoles.isLicenseGroupAdminForOrg(orgId) ||
    (feature.isEnabled('temp_adobe_agent_access') && userRoles.isActingAsAdobeAgentForOrg(orgId))
  );
}

/**
 * Determines if the current user can access the device licenses page
 * @returns {boolean}
 */
function canViewDeviceLicenses() {
  return (
    rootStore.organizationStore.isActiveOrgEdu &&
    hasIndirectContract(rootStore.organizationStore.contractList)
  );
}

/**
 * @deprecated to be removed with temp_quick_assign_sophia - the added
 *     logic for determining if quick assign should be shown during session
 *     start is now handled by Sophia
 *
 * @description Method to determine if Quick Assign Modal will open
 *     automatically. It will open when there are no products provisioned
 *     (with the exception of the trials case where one trial product will
 *     be provisioned).
 *
 * @returns {Boolean} True if above criteria is met, false
 *     otherwise
 */
async function canViewQuickAssignModalInFirstSessionAccess() {
  if (canViewQuickAssignModal()) {
    const canTrialAdminView = await trialHelper.canTrialAdminViewQAModalInFirstSession();

    if (canTrialAdminView) {
      return true;
    }

    const productList = rootStore.organizationStore.productList;

    // Total delegated quantity should be 0 if able to see. This means that if any single product
    // has more than one, we shouldn't see the modal
    return productList.items.every((product) => (product.delegatedQuantity ?? 0) === 0);
  }
  return false;
}

export {
  canAssignAdmin,
  canAssignDeveloper,
  canAssignMember,
  canAssignType1User,
  canAssignUser,
  canAssignUserIgnoringLicenseCounts,
  canBuyTrialProduct,
  canEditProducts,
  canEnableAutoAssignmentModal,
  canPreventAddProductDefaultRouteForMiniApp,
  canPurchaseLicenses,
  canRemoveAdmin,
  canRemoveUser,
  canShowAdmins,
  canShowBuyingProgram,
  canShowConsumableQuotaTypeItems,
  canUseAddProductMiniApp,
  canUseAddProductMiniAppForAll,
  canUseAddProductMiniAppForDeepLink,
  canUseAddProductMiniAppForUSDirect,
  canViewAddProductMiniAppDeepLink,
  canViewAssignProductsDeepLink,
  canUseAssignProductsDeepLink,
  canViewAutoAssignmentRules,
  canViewCustomModelUsageCount,
  canViewDeviceLicenses,
  canViewFireflyApiUsageCount,
  canViewIntegrations,
  canViewLegacyDevices,
  canViewOrgDelegatableUnlimited,
  canViewProduct,
  canViewProductAccessRequests,
  canViewProductBulkOperations,
  canViewProductLicenseCount,
  canViewProducts,
  canViewQuickAssignModal,
  canDisplayQuickAssignModal,
  canViewQuickAssignModalInFirstSessionAccess,
  canViewTransactions,
  canViewUsers,
  hasAnyDeveloperProducts,
  hasQuotaToAssignProductSupportAdmin,
};
/* eslint-enable max-lines -- this file requires more lines */
