import {DetailSection} from '@admin-tribe/acsc-ui';
import {ButtonGroup, Grid, StatusLight, Text} from '@adobe/react-spectrum';
import {DeviceAction} from '@pandora/react-data-source-daco';
import {DEVICE_ACTIVATION_STATUS} from '@pandora/react-data-source-device-activation';
import {GoUrl} from '@pandora/react-go-url';
import React from 'react';
import {useIntl} from 'react-intl';

import {useDeviceLicensesPageContext} from 'features/products/device-licenses/components/page-context/DeviceLicensesPageContext';

import {useDeviceDetailsContext} from '../context/DeviceDetailsContext';
import DeviceDetailsContextualHelp from '../tool-tip/DeviceDetailsContextualHelp';
import {getTranslatedStatus} from '../utils/deviceDrawerUtils';

import ActivationButton from './ActivationButton';

/**
 * @description A method to return a list of action buttons
 * @param {String} activationState - activation state of the current device
 * @param {String} deviceDetailsNamespace - namespace of device details
 * @param {Boolean} canActivateDevices - whether devices can be activated
 * @returns {React.ReactElement || React.Fragment} if the device is activated then ActivationButton component is returned.
 *                                                 If the device is deactivated then React.Fragment component containing 2 ActivationButton component is returned
 */

const getButtons = ({activationState, deviceDetailsNamespace, canActivateDevices}) =>
  activationState === DEVICE_ACTIVATION_STATUS.ACTIVATED ? (
    <ActivationButton
      action={DeviceAction.BLOCK}
      data-testid="device-block-button"
      deviceDetailsNamespace={deviceDetailsNamespace}
    />
  ) : (
    <>
      <DeviceDetailsContextualHelp
        isVisible={!canActivateDevices}
        nameSpace={deviceDetailsNamespace}
      />
      <ActivationButton
        action={DeviceAction.ACTIVATE}
        data-testid="device-activate-button"
        deviceDetailsNamespace={deviceDetailsNamespace}
      />
      <ActivationButton
        action={DeviceAction.REMOVE}
        data-testid="device-remove-button"
        deviceDetailsNamespace={deviceDetailsNamespace}
      />
    </>
  );
const DEVICE_DETAILS_NAMESPACE = 'products.deviceLicenses.deviceDetails';

const statusLightVariant = {
  [DEVICE_ACTIVATION_STATUS.ACTIVATED]: 'positive',
  [DEVICE_ACTIVATION_STATUS.BLOCKED]: 'neutral',
};

/**
 * A component to show the activation details of the device assuming activationState attribute will be non-null
 */
const ActivationSection = () => {
  const intl = useIntl();
  const {deviceDetails} = useDeviceDetailsContext();
  const {canActivateDevices} = useDeviceLicensesPageContext();
  const {activationState} = deviceDetails;
  return (
    <DetailSection
      rightHeaderContent={
        <StatusLight marginTop="size-75" variant={statusLightVariant[activationState]}>
          {getTranslatedStatus({
            activationState,
            intl,
            namespace: `${DEVICE_DETAILS_NAMESPACE}.activationSection.status`,
          })}
        </StatusLight>
      }
      title={intl.formatMessage({
        id: `${DEVICE_DETAILS_NAMESPACE}.activationSection.title`,
      })}
    >
      <Grid rowGap="size-300" rows={['auto']}>
        <Text>
          {intl.formatMessage(
            {
              id: `${DEVICE_DETAILS_NAMESPACE}.activationSection.description`,
            },
            {goUrl: (linkText) => <GoUrl name="manage_device_licenses">{linkText}</GoUrl>}
          )}
        </Text>
        <ButtonGroup>
          {getButtons({
            activationState,
            canActivateDevices,
            deviceDetailsNamespace: DEVICE_DETAILS_NAMESPACE,
          })}
        </ButtonGroup>
      </Grid>
    </DetailSection>
  );
};

ActivationSection.propTypes = {};

export default ActivationSection;
