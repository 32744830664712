import {ORGANIZATION_MARKET_SUBSEGMENT, feature, hasEnterpriseContract} from '@admin-tribe/acsc';
import {showError as showErrorToast, showSuccess as showSuccessToast} from '@admin-tribe/acsc-ui';
import {Button, ButtonGroup, Checkbox, Divider, View} from '@adobe/react-spectrum';
import {GoUrl} from '@pandora/react-go-url';
import {
  ModalContainer,
  ModalContent,
  ModalDialog,
  ModalHeading,
  useModalDialog,
} from '@pandora/react-modal-dialog';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import AssignOrgTypeService from 'common/services/assign-org-type/AssignOrgTypes';
import rootStore from 'core/RootStore';
import {ORGANIZATION_TYPE_BANNER_ID} from 'core/organizations/organization-settings/organizationSettingsConstants';

import Organization from './Organization';

/**
 * Assign Org type
 */
const AssignOrgType = () => {
  const {isOpen, openModal, closeModal} = useModalDialog(false);
  const [organizationType, setOrganizationType] = useState([]);
  const [isTermChecked, setTerms] = useState(false);
  const {formatMessage} = useIntl();

  const orgId = rootStore.organizationStore.activeOrgId;
  const ldlAssignOrgType = new AssignOrgTypeService();

  const contractList = rootStore.organizationStore.contractList;
  const hasAdditionalTerms =
    hasEnterpriseContract(contractList) &&
    organizationType.includes(ORGANIZATION_MARKET_SUBSEGMENT.K_12);

  const resetModal = () => {
    setOrganizationType([]);
    closeModal();
  };
  const handleOrgConfirmation = async () => {
    const requestBody = {
      id: orgId,
      marketSubsegments: organizationType,
    };

    try {
      await ldlAssignOrgType.assignOrgType(requestBody);
      rootStore.organizationStore.globalBannerStore.dismissBannerWithId(
        ORGANIZATION_TYPE_BANNER_ID
      );
      showSuccessToast(
        formatMessage({
          id: `common.contracts.deviceLicenseOrganizationType.toast.successMessage`,
        })
      );
      resetModal();
    } catch (error) {
      resetModal();
      showErrorToast(error.message);
    }
  };

  const additionalTermsLabel = feature.isEnabled('temp_ctir_18688')
    ? formatMessage(
        {
          id: 'offers.freeOfferModal.organizationType.additionalTerms.description2',
        },
        {
          goUrl: (str) => <GoUrl name="primary-secondary-terms">{str}</GoUrl>,
        }
      )
    : formatMessage(
        {
          id: 'offers.freeOfferModal.organizationType.additionalTerms.description',
        },
        {
          goUrl: (str) => <GoUrl name="aac_named_user_add_terms">{str}</GoUrl>,
        }
      );

  return (
    <>
      <Button onPress={openModal} staticColor="white" variant="primary">
        {formatMessage({
          id: `globalBanner.buttonText.organizationTypeSelector`,
        })}
      </Button>
      <ModalContainer>
        {isOpen && (
          <ModalDialog closeModal={resetModal} onCancel={resetModal}>
            <ModalHeading>
              {formatMessage({
                id: `offers.freeOfferModal.organizationType.header`,
              })}
            </ModalHeading>
            <ModalContent>
              <Divider marginBottom="size-150" marginTop="size-150" size="M" />
              <Organization
                data-testid="organization-type"
                organizationType={organizationType}
                setOrganizationType={setOrganizationType}
              />

              {hasAdditionalTerms && (
                <>
                  <Divider marginBottom="size-200" marginTop="size-200" size="M" />
                  <View UNSAFE_style={{fontWeight: 'bold'}}>{additionalTermsLabel}</View>
                  <Checkbox
                    aria-describedby={additionalTermsLabel}
                    data-testid="terms-checkbox"
                    defaultSelected={isTermChecked}
                    onChange={(isSelected) => setTerms(isSelected)}
                  >
                    {formatMessage({
                      id: 'offers.freeOfferModal.organizationType.additionalTerms.agree',
                    })}
                  </Checkbox>
                </>
              )}
            </ModalContent>
            <ButtonGroup>
              <Button onPress={resetModal} variant="secondary">
                {formatMessage({
                  id: `common.modal.buttons.cancel`,
                })}
              </Button>

              <Button
                isDisabled={organizationType.length === 0 || (hasAdditionalTerms && !isTermChecked)}
                onPress={handleOrgConfirmation}
                variant="accent"
              >
                {formatMessage({
                  id: `common.contracts.deviceLicenseMigrationModal.confirmAndContinueButton`,
                })}
              </Button>
            </ButtonGroup>
          </ModalDialog>
        )}
      </ModalContainer>
    </>
  );
};

export default AssignOrgType;
