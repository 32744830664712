import {AppIntegration} from '@admin-tribe/acsc';
import {MoreOptionsMenu} from '@admin-tribe/acsc-ui';
import {ActionButton, Flex, Link, Text, Tooltip, TooltipTrigger} from '@adobe/react-spectrum';
import {ImageIcon} from '@pandora/react-image-icon';
import {EnDashDefaultContent} from '@pandora/react-table-section';
import SettingsIcon from '@spectrum-icons/workflow/Settings';
import ViewDetailIcon from '@spectrum-icons/workflow/ViewDetail';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {useAppIntegrationTableContext} from '../../hooks/AppIntegrationTableContext';
import AppIntegrationsPlatformIcon from '../AppIntegrationsPlatformIcon';

import styles from './AppIntegrationsCell.pcss';
import {ACTIONS_MENU_ITEMS, ALL_COLUMN_KEYS, TABLE_MODE} from './appIntegrationTableConstants';

const POLICY_TO_LOCALIZATION_ID_MAP = {
  allow: 'products.appIntegrations.appIntegrationsCell.status.allow',
  custom: 'products.appIntegrations.appIntegrationsCell.status.custom',
  deny: 'products.appIntegrations.appIntegrationsCell.status.deny',
};

// The content of the Cell within the AppIntegrationsTable.
const AppIntegrationsCell = ({appIntegration, columnKey, mode = TABLE_MODE.DEFAULT}) => {
  const intl = useIntl();
  const {onManagePolicyPress, onViewPolicyDetailsPress, onManageUsersPress} =
    useAppIntegrationTableContext();

  const menuItems = useMemo(() => {
    if (mode !== TABLE_MODE.HAS_POLICIES) {
      // Only TABLE_MODE.HAS_POLICIES renders the MoreOptionsMenu, return null otherwise
      return null;
    }

    const viewDetailsLabel = intl.formatMessage(
      {
        id: 'products.appIntegrations.appIntegrationsCell.actions.viewDetails',
      },
      {
        integrationName: appIntegration.name,
      }
    );
    const managePolicyLabel = intl.formatMessage(
      {
        id: 'products.appIntegrations.appIntegrationsCell.actions.managePolicy',
      },
      {
        integrationName: appIntegration.name,
      }
    );

    // Build dynamic menu items under the 'Actions' column
    return [
      {
        body: (
          <>
            <ViewDetailIcon />
            <Text>{viewDetailsLabel}</Text>
          </>
        ),
        key: ACTIONS_MENU_ITEMS.VIEW_DETAILS,
        textValue: viewDetailsLabel,
      },
      {
        body: (
          <>
            <SettingsIcon />
            <Text>{managePolicyLabel}</Text>
          </>
        ),
        key: ACTIONS_MENU_ITEMS.MANAGE_POLICY,
        textValue: managePolicyLabel,
      },
    ];
  }, [appIntegration.name, intl, mode]);

  switch (columnKey) {
    case ALL_COLUMN_KEYS[0]:
      return (
        <span className={styles['app-integration-name-cell']}>
          <ImageIcon alt="" size="L" src={appIntegration.logo} />
          <Text marginStart="size-100">
            {appIntegration.website ? (
              <Link>
                <a href={appIntegration.website} rel="noreferrer" target="_blank">
                  {appIntegration.name}
                </a>
              </Link>
            ) : (
              appIntegration.name
            )}
          </Text>
        </span>
      );
    case ALL_COLUMN_KEYS[1]:
      return (
        <Flex justifyContent="center">
          {mode === TABLE_MODE.HAS_ACCEPTED_USERS && (
            <TooltipTrigger delay={0}>
              <ActionButton
                aria-label={intl.formatMessage(
                  {
                    id: 'products.appIntegrations.appIntegrationsCell.actions.manageUsers',
                  },
                  {
                    integrationName: appIntegration.name,
                  }
                )}
                isQuiet
                onPress={() => onManageUsersPress(appIntegration)}
              >
                <SettingsIcon />
              </ActionButton>
              <Tooltip>
                <FormattedMessage
                  id="products.appIntegrations.appIntegrationsCell.actions.manageUsers"
                  values={{integrationName: appIntegration.name}}
                />
              </Tooltip>
            </TooltipTrigger>
          )}
          {mode === TABLE_MODE.HAS_POLICIES && (
            <MoreOptionsMenu
              menuItems={menuItems}
              onItemSelected={(key) => {
                if (key === ACTIONS_MENU_ITEMS.MANAGE_POLICY) {
                  onManagePolicyPress(appIntegration);
                }
                if (key === ACTIONS_MENU_ITEMS.VIEW_DETAILS) {
                  onViewPolicyDetailsPress(appIntegration);
                }
              }}
            />
          )}
        </Flex>
      );
    case ALL_COLUMN_KEYS[2]:
      return (
        <EnDashDefaultContent>
          {appIntegration.publisher && (
            <Link>
              <a href={appIntegration.publisher.website} rel="noreferrer" target="_blank">
                {appIntegration.publisher.name}
              </a>
            </Link>
          )}
        </EnDashDefaultContent>
      );
    case ALL_COLUMN_KEYS[3]:
      return (
        <>
          {appIntegration.platforms.map((platform) => (
            <AppIntegrationsPlatformIcon key={platform} platform={platform} />
          ))}
        </>
      );
    case ALL_COLUMN_KEYS[4]:
      return (
        <FormattedMessage id={POLICY_TO_LOCALIZATION_ID_MAP[appIntegration.policy.toLowerCase()]} />
      );
    // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- See below.
    // istanbul ignore next -- Cannot cover. Test will fail due to prop validation
    default:
      return null;
  }
};

AppIntegrationsCell.propTypes = {
  /**
   * The specific app integration the cell will display content for.
   */
  appIntegration: PropTypes.instanceOf(AppIntegration),
  /**
   * The column of this cell. Determines the content for what is displayed.
   */
  columnKey: PropTypes.oneOf(ALL_COLUMN_KEYS),
  /**
   * The viewing mode of the table.
   */
  mode: PropTypes.oneOf(Object.values(TABLE_MODE)),
};

AppIntegrationsCell.displayName = 'AppIntegrationsCell';

export default AppIntegrationsCell;
