// The content for asset credentials setting component.
const AssetCredentialsSettingsContent = [
  {
    headerKey: 'settings.assetSettings.credentials.header.identity',
    values: [
      {
        apiKey: 'contentCredentialsIndividualName',
        descriptionKey: 'settings.assetSettings.credentials.identificationName.description',
        titleKey: 'settings.assetSettings.credentials.identificationName.title',
      },
      {
        apiKey: 'contentCredentialsConnectedAccounts',
        descriptionKey: 'settings.assetSettings.credentials.connectedAccounts.description',
        titleKey: 'settings.assetSettings.credentials.connectedAccounts.title',
      },
    ],
  },
  {
    headerKey: 'settings.assetSettings.credentials.header.usage.preferences',
    values: [
      {
        apiKey: 'contentCredentialsActivities',
        descriptionKey: 'settings.assetSettings.credentials.aiTraining.description',
        titleKey: 'settings.assetSettings.credentials.aiTraining.title',
      },
    ],
  },
];

export default AssetCredentialsSettingsContent;
