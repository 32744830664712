import {ORGANIZATION_MARKET_SUBSEGMENT, feature} from '@admin-tribe/acsc';
import {GoUrl, ModalContent, ModalDescription, ModalHeading} from '@admin-tribe/acsc-ui';
import {Checkbox, CheckboxGroup, Divider, View} from '@adobe/react-spectrum';
import React from 'react';
import {useIntl} from 'react-intl';

import useSelection from 'common/components/table/useSelection';
import rootStore from 'core/RootStore';

import {useFreeOfferModalContext} from './FreeOfferModalContext';

const OrganizationTypePage = () => {
  const intl = useIntl();
  const {selectedItems, setSelectedItems} = useSelection({
    selectableItems: [
      ORGANIZATION_MARKET_SUBSEGMENT.K_12,
      ORGANIZATION_MARKET_SUBSEGMENT.HIGHER_ED,
      ORGANIZATION_MARKET_SUBSEGMENT.NON_PROFIT,
    ],
    trackBy: 'code',
  });
  const hasEnterpriseContract = !!rootStore.organizationStore.contractList.items.find((item) =>
    item.isEnterpriseContract()
  );

  const {
    setIsValid,
    setMarketSubsegments,
    hasAdditionalTermsToAccept,
    setHasAdditionalTermsToAccept,
    setModalError,
  } = useFreeOfferModalContext();

  const k12Details = (
    <ul>
      <li>
        <GoUrl name="aac_org_type_eligible">
          {intl.formatMessage({
            id: 'offers.freeOfferModal.organizationType.k12.seeEligibility',
          })}
        </GoUrl>
      </li>
      <li>
        {intl.formatMessage(
          {
            id: 'offers.freeOfferModal.organizationType.k12.descriptionBullet1',
          },
          {
            goUrl: (str) => <GoUrl name="primary-secondary-terms">{str}</GoUrl>,
          }
        )}
      </li>
      <li>
        {intl.formatMessage(
          {
            id: 'offers.freeOfferModal.organizationType.k12.descriptionBullet2',
          },
          {
            goUrl: (str) => <GoUrl name="aac_org_type_setup">{str}</GoUrl>,
          }
        )}
      </li>
    </ul>
  );

  const onCheckboxGroupChange = (items) => {
    const hasAdditionalTerms =
      hasEnterpriseContract && items.includes(ORGANIZATION_MARKET_SUBSEGMENT.K_12); // has enterprise contract && k12
    setModalError(null);
    setSelectedItems(items);
    setIsValid(
      items.length > 0 &&
        (!hasAdditionalTerms || !items.includes(ORGANIZATION_MARKET_SUBSEGMENT.K_12))
    );
    setMarketSubsegments(items);
    setHasAdditionalTermsToAccept(hasAdditionalTerms);
  };

  const additionalTermsLabel = feature.isEnabled('temp_ctir_18688')
    ? intl.formatMessage(
        {
          id: 'offers.freeOfferModal.organizationType.additionalTerms.description2',
        },
        {
          goUrl: (str) => <GoUrl name="primary-secondary-terms">{str}</GoUrl>,
        }
      )
    : intl.formatMessage(
        {
          id: 'offers.freeOfferModal.organizationType.additionalTerms.description',
        },
        {
          goUrl: (str) => <GoUrl name="aac_named_user_add_terms">{str}</GoUrl>,
        }
      );

  return (
    <>
      <ModalHeading>
        {intl.formatMessage({id: 'offers.freeOfferModal.organizationType.header'})}
      </ModalHeading>
      <ModalDescription>
        {intl.formatMessage({id: 'offers.freeOfferModal.organizationType.description'})}
      </ModalDescription>
      <ModalContent>
        <CheckboxGroup
          label={intl.formatMessage({
            id: 'offers.freeOfferModal.organizationType.checkboxGroupLabel',
          })}
          onChange={onCheckboxGroupChange}
        >
          <Checkbox
            data-testid="k12-checkbox"
            isDisabled={selectedItems.includes(ORGANIZATION_MARKET_SUBSEGMENT.NON_PROFIT)}
            value={ORGANIZATION_MARKET_SUBSEGMENT.K_12}
          >
            {intl.formatMessage({id: 'offers.freeOfferModal.organizationType.k12'})}
            <br />
            {intl.formatMessage({id: 'offers.freeOfferModal.organizationType.k12.description'})}
          </Checkbox>
          {selectedItems.includes(ORGANIZATION_MARKET_SUBSEGMENT.K_12) && k12Details}
          <Checkbox
            data-testid="higher-ed-checkbox"
            isDisabled={selectedItems.includes(ORGANIZATION_MARKET_SUBSEGMENT.NON_PROFIT)}
            value={ORGANIZATION_MARKET_SUBSEGMENT.HIGHER_ED}
          >
            {intl.formatMessage({id: 'offers.freeOfferModal.organizationType.higherEd'})}
            <br />
            {intl.formatMessage({
              id: 'offers.freeOfferModal.organizationType.higherEd.description',
            })}
          </Checkbox>
          <Checkbox
            data-testid="non-profit-checkbox"
            isDisabled={
              selectedItems.includes(ORGANIZATION_MARKET_SUBSEGMENT.K_12) ||
              selectedItems.includes(ORGANIZATION_MARKET_SUBSEGMENT.HIGHER_ED)
            }
            value={ORGANIZATION_MARKET_SUBSEGMENT.NON_PROFIT}
          >
            {intl.formatMessage({id: 'offers.freeOfferModal.organizationType.nonProfit'})}
            <br />
            {intl.formatMessage({
              id: 'offers.freeOfferModal.organizationType.nonProfit.description',
            })}
          </Checkbox>
        </CheckboxGroup>
        {hasAdditionalTermsToAccept && (
          <>
            <Divider marginBottom="size-200" marginTop="size-200" size="M" />
            <View UNSAFE_style={{fontWeight: 'bold'}}>{additionalTermsLabel}</View>
            <Checkbox
              aria-describedby={additionalTermsLabel}
              data-testid="additional-terms-checkbox"
              onChange={setIsValid}
            >
              {intl.formatMessage({
                id: 'offers.freeOfferModal.organizationType.additionalTerms.agree',
              })}
            </Checkbox>
          </>
        )}
      </ModalContent>
    </>
  );
};

export default OrganizationTypePage;
