import {
  EVENT_ACTION,
  Locale,
  authentication,
  configStore,
  dispatchUiEventAnalytics,
  feature,
  loadCss,
  loadJs,
  log,
} from '@admin-tribe/acsc';
import {canAccessEnterpriseSupport} from '@pandora/data-model-support-ticket';

import rootStore from 'core/RootStore';

import {CHAT_APP_ID, CHAT_USER_ROLE} from './chatProviderConstants';

const chatSettings = {};
let isChatInitialized = false;
let isInFakeMode = false;

const DEFAULT_APP_ID = CHAT_APP_ID.ONESIE1;

(async function loadConfig() {
  const {clientId, config} = await configStore.getServiceConfiguration('chat');
  chatSettings.chatConfig = config;
  chatSettings.clientId = clientId;
})();

/**
 * @description Method to determine if the Jarvis chat can be displayed
 *
 * @returns {Boolean} true if chat can be displayed
 */
const canDisplayChat = () => {
  if (typeof window !== 'undefined') {
    return (
      isChatInitialized &&
      (isInFakeMode ||
        feature.isEnabled('temp_chat_unlink_cookies') ||
        window.adobePrivacy?.hasUserProvidedConsent?.())
    );
  }
  return false;
};

/**
 * @description Method to determine if the jarvis window is open see api:
 * https://wiki.corp.adobe.com/pages/viewpage.action?pageId=1497396194
 * @returns {Boolean} true if chat window is open, false otherwise
 */
const isConversationOpen = () => {
  if (typeof window !== 'undefined') {
    return window.AdobeMessagingExperienceClient?.isConversationOpen() ?? false;
  }
  return false;
};

/**
 * @description Method to initialize chat session
 *
 * @returns {Promise} - Resolved promise if successful, rejected promise otherwise.
 */
const initialize = async () => {
  try {
    await downloadChatLib();
    await initializeChatLib();
    isChatInitialized = true;
    return Promise.resolve();
  } catch (error) {
    log.error('Did not initialize chat session', error);
    return Promise.reject(new Error('Did not initialize chat session'));
  }
};

/**
 * @description Method that returns a boolean indicating whether the chat session is initialized.
 *
 * @returns {Boolean} true if the chat session is initialized.
 */
const isInitialized = () => isChatInitialized;

/**
 * @description Method to open message window
 *
 * @param {Object} options - Includes sourceText and sourceType and/or appId
 */
const openMessagingWindow = (options) => {
  if (typeof window !== 'undefined') {
    const appid = options.appId || DEFAULT_APP_ID;
    chatSettings.appId = appid;

    if (feature.isEnabled('bugfix_chat_provider_appid')) {
      const {appId, ...otherOptions} = options;
      window.AdobeMessagingExperienceClient.openMessagingWindow({
        ...otherOptions,
        appid,
      });
    } else {
      const {appId, ...sourceInfo} = options;
      window.AdobeMessagingExperienceClient.openMessagingWindow(sourceInfo);
    }
  }
};

/**
 * Surfaces can use this API to close the Jarvis Chat window
 */
const closeMessageWindow = () => {
  if (typeof window !== 'undefined') {
    window.AdobeMessagingExperienceClient.closeMessageWindow();
  }
};

/**
 * @description Method that is going to be called after sending the initialize,
 *     and before user starts the chat session.
 *
 * @param {Object} options - Includes appId which is the unique identifier used
 *     to configure the chat request
 *
 * @returns {Promise} - Resolves to boolean indicating success
 */
const reinitialize = (options) => {
  if (typeof window !== 'undefined') {
    if (window?.AdobeMessagingExperienceClient?.reinitialize) {
      const appid = options.appId || DEFAULT_APP_ID;
      chatSettings.appId = appid;
      if (feature.isEnabled('bugfix_chat_provider_appid')) {
        window.AdobeMessagingExperienceClient.reinitialize({
          ...options,
          appid,
        });
      } else {
        const {appId, ...sourceInfo} = options;
        window.AdobeMessagingExperienceClient.reinitialize(sourceInfo);
      }
    }
  }
  log.error('Error reinitialize chat: method not present', options);
};

/**
 * @description Setter only used for testing to reset the isChatInitialized
 *     value to false.
 */
const setChatNotInitialized = () => {
  isChatInitialized = false;
};

//////////

/**
 * @description Method to download chat lib
 *
 * @returns {Promise} - Resolved when chat lib is downloaded
 */
function downloadChatLib() {
  if (typeof window !== 'undefined') {
    if (window.AdobeMessagingExperienceClient) {
      isInFakeMode = true;
      return Promise.resolve();
    }
    if (
      chatSettings.chatConfig &&
      (feature.isEnabled('temp_chat_unlink_cookies') ||
        window.adobePrivacy?.hasUserProvidedConsent())
    ) {
      const cssUrl = `${chatSettings.chatConfig.url}/latest/AdobeMessagingClient.css`;
      const jsUrl = `${chatSettings.chatConfig.url}/latest/AdobeMessagingClient.js`;

      return Promise.all([loadCss(cssUrl), loadJs(jsUrl)]);
    }
    log.error('Error downloading chat library');
  }
  return Promise.reject();
}

/**
 * @description Method to initialize chat lib
 *
 * @returns {Promise} - A promise which will resolve with the response data
 */
function initializeChatLib() {
  const accessToken = authentication.getAccessToken();

  return new Promise((resolve, reject) => {
    const [language, region] = Locale.get().activeLocaleCode.split('_');
    const options = {
      accessToken: `Bearer ${accessToken.token}`,
      appid: DEFAULT_APP_ID,
      appver: '1.0',
      callbacks: {
        analyticsCallback,
        getContextCallback,
        initCallback,
        initErrorCallback,
        onReadyCallback,
      },
      clientId: chatSettings.clientId,
      context: {orgId: rootStore.organizationStore.activeOrgId},
      cookiesEnabled: !!(
        feature.isDisabled('temp_chat_unlink_cookies') ||
        // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- In browser
        window.adobePrivacy?.hasUserProvidedConsent()
      ),
      env: chatSettings.chatConfig.env,
      language,
      region,
    };

    // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals --  Don't need the guard because the downloadChatLib method will error out if there is no window before this is called.
    window.AdobeMessagingExperienceClient.initialize(options);

    function getContextCallback() {
      const contractList = rootStore.organizationStore.contractList;
      const productList = rootStore.organizationStore.productList;

      const userRole = canAccessEnterpriseSupport(contractList, productList)
        ? CHAT_USER_ROLE.ENTERPRISE_ADMIN
        : CHAT_USER_ROLE.TEAM_ADMIN;

      const appid = feature.isEnabled('bugfix_chat_provider_appid')
        ? chatSettings.appId || DEFAULT_APP_ID
        : DEFAULT_APP_ID;

      return {
        appid,
        orgId: rootStore.organizationStore.activeOrgId,
        userRole,
      };
    }

    function initCallback() {
      // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals --  Don't need the guard because the downloadChatLib method will error out if there is no window before this is called.
      if (!window.AdobeMessagingExperienceClient.isAdobeMessagingClientEnabled()) {
        log.info('Skipping chat initialization - chat client is currently disabled');
        reject();
      }
    }

    function initErrorCallback(err) {
      log.error('Error intializing chat: initErrorCallback was invoked', err);
      reject();
    }

    function onReadyCallback() {
      resolve();
    }

    function analyticsCallback(...args) {
      if (feature.isEnabled('temp_redirect_self_cancel_to_jarvis')) {
        const chatAnalyticsData = args[0].events[0];
        dispatchUiEventAnalytics({
          chat: chatAnalyticsData,
          eventAction: EVENT_ACTION.DISPLAY,
          eventName: 'Chat',
          interaction: {
            impression: `AdobeMessaging|adminConsole|cancelFlow`,
          },
        });
      }
    }
  });
}

const chatProvider = {
  canDisplayChat,
  closeMessageWindow,
  downloadChatLib,
  initialize,
  initializeChatLib,
  isConversationOpen,
  isInitialized,
  openMessagingWindow,
  reinitialize,
  setChatNotInitialized,
};

export default chatProvider;
