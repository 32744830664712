import {DATE_FORMATS, FormattedDateAndTime, Pagination} from '@admin-tribe/acsc-ui';
import {Cell, Column, Row, TableBody, TableHeader, TableView} from '@adobe/react-spectrum';
import {parseISO} from 'date-fns';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import ContentLogConstants from '../../../../common/entities/content-logs/ContentLogConstants';
import {useContentLogsContext} from '../ContentLogsContext';

import ContentLogsEmptyState from './ContentLogsEmptyState';
import ContentLogStatus from './content-log-status/ContentLogStatus';

/**
 * @description the table containing all content log reports.
 */
const ContentLogsTable = observer(() => {
  const ENDASH_STR = ' \u2013 ';
  const {contentLogsStore} = useContentLogsContext();

  const intl = useIntl();

  const onGoNext = () => {
    contentLogsStore.goToNextPage();
  };

  const onGoPrevious = () => {
    contentLogsStore.goToPreviousPage();
  };

  const onPageSizeChange = (newPageSize) => {
    contentLogsStore.onPageSizeChange(newPageSize);
  };

  const onSelectionChange = (selectionSetOrAll) => {
    contentLogsStore.tableStore.setSelection(selectionSetOrAll);
  };

  const onSortChange = (descriptor) => {
    contentLogsStore.onSortBy(descriptor);
  };

  return (
    <>
      <TableView
        aria-label={intl.formatMessage({id: 'settings.contentLogs.table.ariaLabel'})}
        data-testid="content-logs-table"
        density={contentLogsStore.tableStore.density}
        marginTop="size-200"
        minHeight={contentLogsStore.tableStore.isEmptyTable ? 'size-5000' : undefined}
        onSelectionChange={onSelectionChange}
        onSortChange={onSortChange}
        renderEmptyState={ContentLogsEmptyState}
        selectedKeys={contentLogsStore.tableStore.selectedKeys}
        selectionMode={contentLogsStore.tableStore.selectionMode}
        sortDescriptor={contentLogsStore.tableStore.sortDescriptor}
      >
        <TableHeader>
          <Column
            key={ContentLogConstants.SORT.LOG_REPORT_START_DATE}
            allowsSorting
            data-testid="date-range-column"
          >
            <FormattedMessage id="settings.contentLogs.table.dateHeader" />
          </Column>
          <Column
            key={ContentLogConstants.SORT.LOG_REPORT_CREATOR}
            allowsSorting
            data-testid="creator-column"
          >
            <FormattedMessage id="settings.contentLogs.table.creatorHeader" />
          </Column>
          <Column
            key={ContentLogConstants.SORT.LOG_REPORT_CREATED}
            allowsSorting
            data-testid="created-date-column"
          >
            <FormattedMessage id="settings.contentLogs.table.createdDateHeader" />
          </Column>
          <Column key="reportStatus" data-testid="status-column">
            <FormattedMessage id="settings.contentLogs.table.statusHeader" />
          </Column>
        </TableHeader>
        <TableBody items={contentLogsStore.tableStore.rows}>
          {/* eslint-disable-next-line @admin-tribe/admin-tribe/extract-large-computations -- table virtualizer requires Cell here */}
          {(row) => (
            <Row key={row.id}>
              <Cell>
                <span>
                  {/* `start_date` and `end_date` are in dateFn yyyy-MM-dd format. parseISO returns the parsed date, treating it as in local time zone */}
                  <FormattedDateAndTime value={parseISO(row.data.start_date)} />
                  {ENDASH_STR}
                  <FormattedDateAndTime value={parseISO(row.data.end_date)} />
                </span>
              </Cell>
              <Cell>{row.data.admin_name}</Cell>
              <Cell>
                <FormattedDateAndTime
                  format={DATE_FORMATS.dateTimeMedium}
                  value={row.data.created}
                />
              </Cell>
              <Cell>
                <ContentLogStatus contentLog={row.data} />
              </Cell>
            </Row>
          )}
        </TableBody>
      </TableView>

      <Pagination
        currentPage={contentLogsStore.tableStore.currentPage}
        onGoNext={onGoNext}
        onGoPrevious={onGoPrevious}
        onPageSizeChange={onPageSizeChange}
        pageSize={contentLogsStore.tableStore.pageSize}
        selectedItemCount={contentLogsStore.tableStore.selectedItemCount}
        totalPages={contentLogsStore.tableStore.totalPages}
      />
    </>
  );
});

export default ContentLogsTable;
