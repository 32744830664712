import {createBrowserRouter} from 'react-router-dom';

import getAccountRoutes from 'features/account/routing/accountRoutes';
import getProductApproveRoutes from 'features/approve-product/routing/productApproveRoutes';
import getInsightsRoutes from 'features/insights/routing/insightsRoutes';
import getOverviewRoutes from 'features/overview/routing/overviewRoutes';
import packagesRoutes from 'features/packages/routing/packagesRoutes';
import getProductsRoutes from 'features/products/routing/productsRoutes';
import getSettingsRoutes from 'features/settings/routing/settingsRoutes';
import getStorageRoutes from 'features/storage/routing/storageRoutes';
import getSupportRoutes from 'features/support/routing/supportRoutes';
import getUsersRoutes from 'features/users/routing/usersRoutes';

import getApplicationRoutes from './applicationRoutes';
import {filterDisabledReactRoutes} from './route-migration/routeMigrationUtils';

/**
 * Finds the best matching component for the current URL and renders it
 * @returns {import('@remix-run/router').Router} The component for the matching route
 */
const buildRouter = () => {
  /** The collection of all routes for the application. These functions MUST be synchronous. */
  const allRoutes = filterDisabledReactRoutes([
    ...getProductApproveRoutes(),
    ...getAccountRoutes(),
    ...getProductsRoutes(),
    ...getOverviewRoutes(),
    ...getInsightsRoutes(),
    ...getSettingsRoutes(),
    ...getStorageRoutes(),
    ...getSupportRoutes(),
    ...getUsersRoutes(),
    ...packagesRoutes(),
  ]);

  return createBrowserRouter(getApplicationRoutes({orgRoutes: allRoutes}));
};

export default buildRouter;
