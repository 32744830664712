import {AnalyticsEvent, AuthenticatedUser, GLOBAL_ADMIN_POLICY} from '@admin-tribe/acsc';
import {Subpage} from '@admin-tribe/acsc-ui';
import {Divider} from '@adobe/react-spectrum';
import React, {useCallback, useEffect, useRef, useState} from 'react';

import rootStore from 'core/RootStore';
import {canAddDomains} from 'core/directories/access/directoryAccess';
import AccessRequestPolicySection from 'features/settings/components/access-request-policy-section/AccessRequestPolicySection';
import SharingRestrictionsPolicySection from 'features/settings/components/sharing-restrictions-policy-section/SharingRestrictionsPolicySection';
import useCollaborationPolicies from 'features/settings/hooks/use-collaboration-policies/useCollaborationPolicies';

// The Assets Settings "Sharing options" tab.
const SharingOptionsSubpage = () => {
  const orgId = rootStore.organizationStore.activeOrgId;
  const [hasDomainClaiming, setHasDomainClaiming] = useState(null);
  const [isDomainClaimLoading, setIsDomainClaimLoading] = useState(true);
  const [isBumpered, setIsBumpered] = useState(false);

  const canSetSharingOptions = useRef(
    rootStore.organizationStore.globalAdminPolicyList.getPolicyValue(
      GLOBAL_ADMIN_POLICY.SET_SHARING_POLICY
    )
  ).current;

  // Retrieve organization's current policy
  const {collaborationPolicies, error, isLoading} = useCollaborationPolicies({orgId});

  /**
   * On load, figure out if the org supports domain claiming.
   */
  useEffect(() => {
    async function getDomainClaiming() {
      try {
        const canAdd = await canAddDomains();
        setHasDomainClaiming(canAdd);
      } catch {
        setIsBumpered(true);
      } finally {
        setIsDomainClaimLoading(false);
      }
    }

    getDomainClaiming();
  }, []);

  // Still uses old Angular component name and options.
  const onTriggerAnalytics = useCallback(
    ({attributeProps, componentMethod}) => {
      AnalyticsEvent.dispatch({
        attributes: {
          orgId,
          orgName: rootStore.organizationStore.activeOrg.name,
          userId: AuthenticatedUser.get().getId(),
          ...attributeProps,
        },
        componentMethod,
        componentMethodType: 'submit',
        componentName: 'appSharingOptions',
      });
    },
    [orgId]
  );

  return (
    <Subpage
      data-testid="asset-settings-sharing-options-subpage"
      isBumpered={!!error || isBumpered}
      isLoading={isLoading || isDomainClaimLoading}
    >
      {collaborationPolicies && hasDomainClaiming !== null && (
        <>
          <SharingRestrictionsPolicySection
            canSetSharingOptions={canSetSharingOptions}
            collaborationPolicies={collaborationPolicies}
            hasDomainClaiming={hasDomainClaiming}
            onTriggerAnalytics={onTriggerAnalytics}
          />
          <Divider marginY="static-size-300" size="S" />
          <AccessRequestPolicySection
            canSetSharingOptions={canSetSharingOptions}
            collaborationPolicies={collaborationPolicies}
            onTriggerAnalytics={onTriggerAnalytics}
          />
        </>
      )}
    </Subpage>
  );
};

export default SharingOptionsSubpage;
