import get from 'lodash/get';

import {setProperty} from '../aepAnalytics';

function setRetentionProperties(cancellationOrderDescriptor) {
  const retention = cancellationOrderDescriptor?.retention;
  if (retention !== undefined) {
    setProperty('retention.total.tax', get(retention, 'billingTotalTax'));
    setProperty('retention.total.priceWithoutTax', get(retention, 'billingTotalWithoutTax'));
    setProperty('retention.total.priceWithTax', get(retention, 'billingTotalWithTax'));
    setProperty('retention.total.discountTax', get(retention, 'discountTotalTax'));
    setProperty('retention.total.discountWithoutTax', get(retention, 'discountTotalWithoutTax'));
    setProperty('retention.total.discountWithTax', get(retention, 'discountTotalWithTax'));
    setProperty('retention.offerId', get(retention, 'offerId'));
    setProperty('retention.orderNumber', get(retention, 'orderNumber'));
    setProperty('retention.promoId', get(retention, 'promoId'));
  }
  return retention;
}

function setRetentionOutcomeProperties(retention) {
  const outcomes = retention?.outcomes;
  if (outcomes && outcomes.length > 0) {
    const getPropertyString = (data, outcome, propertyName) =>
      `${data[propertyName] ? `${data[propertyName]}|` : ''}${
        outcome[propertyName] ? outcome[propertyName] : ''
      }`;

    const outcomeValues = outcomes.reduce((data, outcome) => {
      data.discountAmount = getPropertyString(data, outcome, 'discountAmount');
      data.discountCurrency = getPropertyString(data, outcome, 'discountCurrency');
      data.durationAmount = getPropertyString(data, outcome, 'durationAmount');
      data.durationType = getPropertyString(data, outcome, 'durationType');
      data.durationUnit = getPropertyString(data, outcome, 'durationUnit');
      data.type = getPropertyString(data, outcome, 'type');

      return data;
    }, {});

    setProperty('retention.discountAmount', get(outcomeValues, 'discountAmount'));
    setProperty('retention.discountCurrency', get(outcomeValues, 'discountCurrency'));
    setProperty('retention.durationAmount', get(outcomeValues, 'durationAmount'));
    setProperty('retention.durationType', get(outcomeValues, 'durationType'));
    setProperty('retention.durationUnit', get(outcomeValues, 'durationUnit'));
    setProperty('retention.type', get(outcomeValues, 'type'));
  }
}

function setCancellationDetails(cancellationOrderDescriptor) {
  setProperty(
    'transaction.attributes.cancel.orderNumber',
    get(cancellationOrderDescriptor, 'orderNumber')
  );

  const cancellationReasons = cancellationOrderDescriptor?.reasonCodes;
  if (Array.isArray(cancellationReasons) && cancellationReasons.length > 0) {
    setProperty('transaction.attributes.cancel.reasonID', cancellationReasons.join('|'));
  }

  setProperty('transaction.attributes.cancel.userReason', cancellationOrderDescriptor?.userReason);
}

/**
 * Translate the cancellation order descriptor into a format consumable by analytics.
 *
 */
export default function cancellationOrderEventTranslator(cancellationOrderDescriptor) {
  setCancellationDetails(cancellationOrderDescriptor);

  const retention = setRetentionProperties(cancellationOrderDescriptor);

  setRetentionOutcomeProperties(retention);
}
