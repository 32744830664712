import {jilDirectories} from '@admin-tribe/acsc';
import {showError as showErrorToast, showSuccess as showSuccessToast} from '@admin-tribe/acsc-ui';
import {Text, useDialogContainer} from '@adobe/react-spectrum';
import {ModalAlertDialog} from '@pandora/react-modal-dialog';
import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import rootStore from 'core/RootStore';
import {useDirectoryDetailsContext} from 'features/settings/pages/directory-details-page/DirectoryDetailsContext';

const MODAL_ID = 'teacher-validation-policy-confirmation-modal';

const TeacherValidationPolicyConfirmationModal = () => {
  const intl = useIntl();
  const dialog = useDialogContainer();
  const [isLoading, setIsLoading] = useState(false);

  const {directory, reloadDirectory} = useDirectoryDetailsContext();

  const onConfirm = async () => {
    setIsLoading(true);

    try {
      const policies = {...directory.policies};
      // eslint-disable-next-line id-length -- That is the literal policy name
      policies.ENFORCE_VERIFIED_TEACHER_CLASSROOM_INVITES_ONLY = 'true';

      await jilDirectories.updateDirectory({
        directoryData: {
          policies,
        },
        directoryId: directory.id,
        orgId: rootStore.organizationStore.activeOrgId,
      });

      await reloadDirectory();

      showSuccessToast(
        intl.formatMessage({
          id: 'settings.directorySettingsModal.teacherValidationPolicySection.toasts.successEnabled',
        })
      );

      dialog.dismiss();
    } catch (error) {
      showErrorToast();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalAlertDialog
      cancelLabel={intl.formatMessage({
        id: 'settings.directorySettingsModal.teacherAccessPolicySection.confirmationModal.buttons.cancelButton',
      })}
      id={MODAL_ID}
      isLoading={isLoading}
      onClose={() => dialog.dismiss()}
      onPrimaryAction={onConfirm}
      primaryActionLabel={intl.formatMessage({
        id: 'settings.directorySettingsModal.teacherAccessPolicySection.confirmationModal.buttons.ctaButton',
      })}
      title={intl.formatMessage({
        id: 'settings.directorySettingsModal.teacherAccessPolicySection.confirmationModal.heading',
      })}
      variant="confirmation"
    >
      <Text>
        <p>
          <FormattedMessage id="settings.directorySettingsModal.teacherAccessPolicySection.confirmationModal.description1" />
        </p>
        <p>
          <FormattedMessage id="settings.directorySettingsModal.teacherAccessPolicySection.confirmationModal.description2" />
        </p>
      </Text>
    </ModalAlertDialog>
  );
};

export default TeacherValidationPolicyConfirmationModal;
