import {feature} from '@admin-tribe/acsc';
import {View} from '@adobe/react-spectrum';
import {OptionSelector} from '@pandora/react-option-selector';
import ProviderV2 from '@react/react-spectrum/Provider';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';

import BlackboardLogo from 'features/settings/assets/blackboard-logo.svg';
import CanvasLogo from 'features/settings/assets/canvas-logo.svg';
import ClasslinkLogo from 'features/settings/assets/classlink-logo.svg';
import CleverLogo from 'features/settings/assets/clever-logo.svg';
import GoogleLogo from 'features/settings/assets/google-logo.svg';
import SchoologyLogo from 'features/settings/assets/schoology_logo.svg';
import OptionSelectorWrapper from 'features/settings/common/components/option-selector-wrapper/OptionSelectorWrapper';
import EduIdpCard from 'features/settings/components/edu-idp-chooser/EduIdpCard';
import {SOIDC_NAMES} from 'features/settings/entities/IdpEntity';

const SOIDC_IDP_CARDS = [
  {
    logo: BlackboardLogo,
    providerName: SOIDC_NAMES.BLACKBOARD,
    title: 'settings.authentication.nameProviders.Blackboard',
  },
  {
    logo: CanvasLogo,
    providerName: SOIDC_NAMES.CANVAS,
    title: 'settings.authentication.nameProviders.Canvas',
  },
  {
    logo: ClasslinkLogo,
    providerName: SOIDC_NAMES.CLASSLINK,
    title: 'settings.authentication.nameProviders.Classlink',
  },
  {
    logo: CleverLogo,
    providerName: SOIDC_NAMES.CLEVER,
    title: 'settings.authentication.nameProviders.Clever',
  },
  {
    cardSize: 'XL',
    logo: GoogleLogo,
    providerName: SOIDC_NAMES.GOOGLE,
    title: 'settings.authentication.nameProviders.google',
  },
];

const SCHOOLOGY_CARD = {
  className: 'schoology-card',
  logo: SchoologyLogo,
  providerName: SOIDC_NAMES.SCHOOLOGY,
  title: 'settings.authentication.nameProviders.Schoology',
};

const EduIdpChooser = observer(({onSelectProvider, defaultSelectedProvider}) => {
  const AVAILABLE_SOIDC_IDP_CARDS = useMemo(
    () =>
      [...SOIDC_IDP_CARDS, ...(feature.isEnabled('temp_schoology') ? [SCHOOLOGY_CARD] : [])] // only append schoology card if schoology flag is enabled
        .map((card) => ({
          // set "selected" property to true for default selected provider card
          ...card,
          selected: defaultSelectedProvider === card.providerName,
        })),
    [defaultSelectedProvider]
  );

  return (
    <ProviderV2>
      <OptionSelectorWrapper>
        <OptionSelector
          data={AVAILABLE_SOIDC_IDP_CARDS}
          options={{
            on_selection_change: (event) =>
              event.selected && onSelectProvider?.(event.data.providerName),
            preventDeselection: true,
          }}
          react_function={(card) => (
            <View>
              <EduIdpCard {...card} />
            </View>
          )}
        />
      </OptionSelectorWrapper>
    </ProviderV2>
  );
});

EduIdpChooser.propTypes = {
  onSelectProvider: PropTypes.func,
};

export default EduIdpChooser;
