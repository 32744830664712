import {authentication} from '@admin-tribe/acsc';
import React from 'react';
import {useIntl} from 'react-intl';

import ErrorBumper from './ErrorBumper';

const UnauthorizedBumper = () => {
  const intl = useIntl();
  const detailsText = intl.formatMessage({id: 'main.routes.unauthorized.details'});
  const secondaryLabel = intl.formatMessage({id: 'main.routes.unauthorized.secondaryButton'});
  const titleText = intl.formatMessage({id: 'main.routes.unauthorized.title'});
  const buttonLabelText = intl.formatMessage({id: 'main.routes.bumper.button.primary'});

  const onSecondaryButtonClick = () => authentication.signOut();

  return (
    <ErrorBumper
      buttonLabelText={buttonLabelText}
      detailsText={detailsText}
      iconType="cloud"
      onButtonClick={() => {
        // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- In browser
        window.history.back();
      }}
      onSecondaryButtonClick={onSecondaryButtonClick}
      secondaryLabel={secondaryLabel}
      titleText={titleText}
    />
  );
};

export default UnauthorizedBumper;
