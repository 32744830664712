import {OverlayWait, showError as showErrorToast} from '@admin-tribe/acsc-ui';
import {
  Button,
  DialogContainer,
  Divider,
  Flex,
  Text,
  useDialogContainer,
} from '@adobe/react-spectrum';
import {useIsMounted} from '@pandora/react-is-mounted';
import {
  ModalButtonGroup,
  ModalContent,
  ModalDialog,
  ModalHeading,
} from '@pandora/react-modal-dialog';
import Alert from '@react/react-spectrum/Alert';
import ProviderV2 from '@react/react-spectrum/Provider';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import EduApplicationSelection from 'features/settings/common/components/edu-application-selection/EduApplicationSelection';
import EduRosterSyncSchedule from 'features/settings/common/components/edu-roster-sync-schedule/EduRosterSyncSchedule';
import {EDU_SYNC_STATUSES} from 'features/settings/components/edu-sync-card/EduSyncStatus';
import {isProductIntegrationError} from 'features/settings/components/edu-sync-card/footer/eduActionsUtils';
import RemoveRosterSyncConfirmationModal from 'features/settings/components/edu-sync-card/modals/RemoveRosterSyncConfirmationModal';
import useEduRosterSync from 'features/settings/hooks/api/useEduRosterSync';
import useRosterSyncSetup from 'features/settings/hooks/roster-sync-setup/useRosterSyncSetup';

const getEduAlert = (areControlsDisabled, isError, sync) => {
  const title = 'settings.sync.eduSyncSettingsModal.alert.title';
  const errorTitle = 'settings.sync.eduSyncSettingsModal.alert.applicationsError.title';

  const description =
    sync.status === EDU_SYNC_STATUSES.QUEUED
      ? 'settings.sync.eduSyncSettingsModal.alert.syncQueuedDescription'
      : 'settings.sync.eduSyncSettingsModal.alert.syncRunningDescription';
  const errorDescription =
    'settings.sync.eduSyncSettingsModal.alert.syncApplicationsErrorDescription';

  return {
    alertDescription: isError ? errorDescription : description,
    alertTitle: isError ? errorTitle : title,
    isAlertDisplayed: areControlsDisabled || isError,
  };
};

const EduSyncSettingsModal = ({sync}) => {
  const intl = useIntl();
  const isMounted = useIsMounted();
  const [syncData, setSyncData] = useState(sync);

  const areControlsDisabled =
    sync.status === EDU_SYNC_STATUSES.QUEUED || sync.status === EDU_SYNC_STATUSES.RUNNING;

  const isError = isProductIntegrationError({
    integrations: syncData.integrations,
    products: sync.products,
  });

  const {isAlertDisplayed, alertTitle, alertDescription} = getEduAlert(
    areControlsDisabled,
    isError,
    sync
  );

  const {
    isCtaEnabled,
    onChangeProducts,
    onChangeSchedule,
    updateSync,
    isLoading: isUpdatingSync,
  } = useRosterSyncSetup(
    syncData.syncId,
    syncData.products.map((product) => product.name),
    syncData.syncSchedule
  );
  const {fetchRosterSyncInfo} = useEduRosterSync();
  const dialog = useDialogContainer();
  const [isLoading, setIsLoading] = useState(false);
  const [isVisibleRemoveConfirmationModal, setIsVisibleRemoveConfirmationModal] = useState(false);

  // Reload the sync details to get fresh integrations
  useEffect(() => {
    setIsLoading(true);

    const fetchIntegrations = async () => {
      try {
        const {data} = await fetchRosterSyncInfo({fetchIntegration: true, syncId: syncData.syncId});

        if (isMounted()) {
          setSyncData(data);
        }
      } catch (error) {
        // If there is an error close the modal and show an error toast
        showErrorToast(
          intl.formatMessage({id: 'settings.sync.eduSyncSettingsModal.toasts.errorLoadingSettings'})
        );
        dialog.dismiss();
      } finally {
        if (isMounted()) {
          setIsLoading(false);
        }
      }
    };

    fetchIntegrations();

    // eslint-disable-next-line react-hooks/exhaustive-deps -- this only needs to run once
  }, []);

  const onSaveChanges = async () => {
    await updateSync();
    dialog.dismiss();
  };

  return (
    <>
      <ModalDialog size="L">
        <ModalHeading>
          <FormattedMessage id="settings.sync.eduSyncSettingsModal.title" />
          <Divider />
        </ModalHeading>
        <ModalContent>
          <OverlayWait isLoading={isLoading || isUpdatingSync} showContent={isUpdatingSync}>
            {isAlertDisplayed && (
              <ProviderV2>
                <Alert
                  header={intl.formatMessage({id: alertTitle})}
                  style={{width: '100%'}}
                  variant={isError ? 'error' : 'warning'}
                >
                  <FormattedMessage id={alertDescription} />
                </Alert>
              </ProviderV2>
            )}

            {syncData.integrations && (
              <EduApplicationSelection
                defaultSelectedProducts={syncData.products.map((product) => product.name)}
                defaultValidationState="valid"
                isDisabled={areControlsDisabled}
                label={intl.formatMessage({
                  id: 'settings.sync.eduSyncSettingsModal.syncedProductsLabel',
                })}
                marginTop={isAlertDisplayed ? 'size-250' : '0'}
                onChange={onChangeProducts}
                products={syncData.integrations}
              />
            )}

            <Divider marginBottom="size-250" marginTop="size-250" size="S" />

            <Flex direction="column">
              <Text>
                <strong>
                  <FormattedMessage id="settings.sync.eduSyncSettingsModal.syncScheduleLabel" />
                </strong>
              </Text>
              <Text>
                <FormattedMessage id="settings.sync.eduSyncSettingsModal.syncScheduleDescription" />
              </Text>
              <Text>
                <FormattedMessage id="settings.sync.eduSyncSettingsModal.syncScheduleNote" />
              </Text>
              <EduRosterSyncSchedule
                defaultSelectedKey={syncData.syncSchedule}
                isDisabled={areControlsDisabled}
                onChange={onChangeSchedule}
              />
            </Flex>

            <Divider marginBottom="size-250" marginTop="size-250" size="S" />

            <Flex direction="column">
              <Text>
                <strong>
                  <FormattedMessage id="settings.sync.eduSyncSettingsModal.removeSyncLabel" />
                </strong>
              </Text>
              <Text marginBottom="size-200">
                <FormattedMessage id="settings.sync.eduSyncSettingsModal.removeSyncDescription" />
              </Text>
              <Button
                alignSelf="flex-start"
                data-testid="remove-edu-sync-button"
                isDisabled={areControlsDisabled}
                onPress={() => setIsVisibleRemoveConfirmationModal(true)}
                variant="negative"
              >
                <FormattedMessage id="settings.sync.eduSyncSettingsModal.buttons.removeSync" />
              </Button>
            </Flex>
          </OverlayWait>
        </ModalContent>

        <ModalButtonGroup
          cancelLabel={intl.formatMessage({
            id: 'settings.sync.eduSyncSettingsModal.buttons.cancel',
          })}
          ctaLabel={intl.formatMessage({id: 'settings.sync.eduSyncSettingsModal.buttons.save'})}
          isCtaDisabled={!isCtaEnabled || isUpdatingSync || isLoading}
          onCancel={() => dialog.dismiss()}
          onCta={onSaveChanges}
        />
      </ModalDialog>

      <DialogContainer onDismiss={() => isMounted() && setIsVisibleRemoveConfirmationModal(false)}>
        {isVisibleRemoveConfirmationModal && (
          <RemoveRosterSyncConfirmationModal
            onConfirm={() => dialog.dismiss()}
            rosterSource={syncData.rosterSource}
            syncId={syncData.syncId}
          />
        )}
      </DialogContainer>
    </>
  );
};

EduSyncSettingsModal.propTypes = {
  sync: PropTypes.shape({
    /**
     * Selected products of the provided sync
     */
    products: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      })
    ),
    /**
     * roster source of the provided sync
     */
    rosterSource: PropTypes.string,
    /**
     * Status of the sync
     */
    status: PropTypes.string,
    /**
     * Id of the provided sync
     */
    syncId: PropTypes.string,
    /**
     * Schedule of thr provided sync
     */
    syncSchedule: PropTypes.string,
  }),
};

export default EduSyncSettingsModal;
