import {Cache, log} from '@admin-tribe/acsc';
import {useCallback} from 'react';

import rootStore from 'core/RootStore';
import {updateIdp} from 'features/settings/api/ims-federated';
import samlCertificates from 'features/settings/api/samlCertificates';

import {getCacheKey} from './utils/hooksApiUtils';

const CERTIFICATES_CACHE_KEY = 'certificates';
const CSRS_CACHE_KEY = 'csrs';

const certificatesCache = new Cache();
const csrsCache = new Cache();

const getCertificatesCacheKey = ({directoryId, idpId}) =>
  getCacheKey(CERTIFICATES_CACHE_KEY, directoryId, idpId);

const getCsrsCacheKey = ({directoryId, idpId}) => getCacheKey(CSRS_CACHE_KEY, directoryId, idpId);

/**
 * Retrieves the list of certificates for a specific directory and IdP
 *
 * @param {Object} options - The options for retrieving the certificates.
 * @param {string} options.directoryId - The directory ID.
 * @param {string} options.idpId - The IDP (Identity Provider) ID.
 * @returns {Promise} A promise that resolves with the list of certificates.
 * @throws {Error} If there is an error retrieving the certificates.
 */
const getCertificates = async ({directoryId, idpId}) => {
  const cacheKey = getCertificatesCacheKey({directoryId, idpId});

  try {
    const cachedPromise = certificatesCache.get(cacheKey);

    let response;

    // if we have a promise that is cached get the response from that
    if (cachedPromise) {
      response = await cachedPromise;
    } else {
      const requestPromise = samlCertificates.getCertificates({
        directoryId,
        idpId,
      });

      // cache the promise before returning so parallel calls can wait for the same call
      certificatesCache.put(cacheKey, requestPromise);

      response = await requestPromise;
    }

    return response.data;
  } catch (error) {
    log.error(
      `[ID][SAML_CERTIFICATES] Error getting the list of certificates for idp: ${idpId}`,
      error
    );

    throw error;
  }
};

/**
 * Creates a certificate for a specific directory and IdP
 *
 * @param {Object} options - The options for creating the certificate.
 * @param {string} options.directoryId - The directory ID.
 * @param {string} options.idpId - The IDP (Identity Provider) ID.
 * @returns {Promise} A promise that resolves when the certificate is created.
 * @throws {Error} If there is an error creating the certificate.
 */
const createCertificate = async ({directoryId, idpId}) => {
  try {
    return await samlCertificates.createCertificate({
      directoryId,
      idpId,
    });
  } catch (error) {
    log.error(`[ID][SAML_CERTIFICATES] Error creating a certificate for idp: ${idpId}`, error);

    throw error;
  }
};

/**
 * Updates the status of a certificate for a specific directory, IdP, and certificate ID
 *
 * @param {Object} options - The options for updating the certificate status.
 * @param {string} options.directoryId - The directory ID.
 * @param {string} options.idpId - The IDP (Identity Provider) ID.
 * @param {string} options.certificateId - The certificate ID.
 * @param {string} options.status - The new status of the certificate.
 * @returns {Promise} A promise that resolves when the certificate status is updated.
 * @throws {Error} If there is an error updating the certificate status.
 */
const updateCertificateStatus = async ({directoryId, idpId, certificateId, status}) => {
  try {
    return await samlCertificates.updateCertificateStatus({
      certificateId,
      directoryId,
      idpId,
      status,
    });
  } catch (error) {
    log.error(
      `[ID][SAML_CERTIFICATES] Error updating the status for certificate: ${certificateId}`,
      error
    );

    throw error;
  }
};

/**
 * Sets a certificate as the default for a specific directory, federation type, and IdP
 *
 * @param {Object} options - The options for setting the certificate as default.
 * @param {string} options.directoryId - The directory ID.
 * @param {string} options.federationType - The federation type.
 * @param {string} options.idpId - The IDP (Identity Provider) ID.
 * @param {string} options.certificateId - The certificate ID.
 * @returns {Promise} A promise that resolves when the certificate is set as default.
 * @throws {Error} If there is an error updating the IdP.
 */
const setCertificateAsDefault = async ({directoryId, federationType, idpId, certificateId}) => {
  try {
    return await updateIdp({
      authSourceId: directoryId,
      data: {federationType, spDefaultKey: certificateId},
      idpId,
      // TODO: ims-federated should be refactored to get the org id from the rootStore automatically
      orgId: rootStore.organizationStore.activeOrgId,
    });
  } catch (error) {
    log.error(
      `[ID][FED] Error updating IdP with id ${idpId} in directory ${directoryId} in organization ${rootStore.organizationStore.activeOrgId}`,
      error
    );

    throw error;
  }
};

/**
 * Removes a certificate for a specific directory and IdP
 *
 * @param {Object} options - The options for removing the certificate.
 * @param {string} options.directoryId - The directory ID.
 * @param {string} options.idpId - The IDP (Identity Provider) ID.
 * @param {string} options.certificateId - The certificate ID.
 * @returns {Promise} A promise that resolves when the certificate is removed.
 * @throws {Error} If there is an error removing the certificate.
 */
const removeCertificate = async ({directoryId, idpId, certificateId}) => {
  try {
    return await samlCertificates.removeCertificate({
      certificateId,
      directoryId,
      idpId,
    });
  } catch (error) {
    log.error(`[ID][SAML_CERTIFICATES] Error removing the certificate: ${certificateId}`, error);

    throw error;
  }
};

/**
 * Removes a certificate signing request for a specific directory and IdP
 *
 * @param {Object} options - The options for removing the certificate signing request.
 * @param {string} options.directoryId - The directory ID.
 * @param {string} options.idpId - The IDP (Identity Provider) ID.
 * @param {string} options.csrId - The certificate signing request ID.
 * @returns {Promise} A promise that resolves when the certificate signing request is removed.
 * @throws {Error} If there is an error removing the certificate signing request.
 */
const removeCertificateSigningRequest = async ({directoryId, idpId, csrId}) => {
  try {
    return await samlCertificates.removeCertificateSigningRequest({
      csrId,
      directoryId,
      idpId,
    });
  } catch (error) {
    log.error(`[ID][SAML_CERTIFICATES] Error removing the CSR: ${csrId}`, error);

    throw error;
  }
};

/**
 * Downloads a certificate for a specific directory and IdP
 *
 * @param {Object} options - The options for downloading the certificate.
 * @param {string} options.directoryId - The directory ID.
 * @param {string} options.idpId - The IDP (Identity Provider) ID.
 * @param {string} options.certificateId - The certificate ID.
 * @returns {Promise} A promise that resolves with the downloaded certificate.
 * @throws {Error} If there is an error downloading the certificate.
 */
const downloadCertificate = async ({directoryId, idpId, certificateId}) => {
  try {
    return await samlCertificates.downloadCertificate({
      certificateId,
      directoryId,
      idpId,
    });
  } catch (error) {
    log.error(`[ID][SAML_CERTIFICATES] Error downloading the certificate: ${certificateId}`, error);

    throw error;
  }
};

/**
 * Retrieves the list of certificate signing requests for a specific directory and IdP
 *
 * @param {Object} options - The options for retrieving certificate signing requests.
 * @param {string} options.directoryId - The directory ID.
 * @param {string} options.idpId - The IDP (Identity Provider) ID.
 * @returns {Promise} A promise that resolves with the list of certificate signing requests.
 * @throws {Error} If there is an error retrieving the certificate signing requests.
 */
const getCertificatesSigningRequests = async ({directoryId, idpId}) => {
  const cacheKey = getCsrsCacheKey({directoryId, idpId});

  try {
    const cachedPromise = csrsCache.get(cacheKey);
    let response;

    // if we have a promise that is cached, get the response from that
    if (cachedPromise) {
      response = await cachedPromise;
    } else {
      const requestPromise = await samlCertificates.getCertificatesSigningRequests({
        directoryId,
        idpId,
      });
      // cache the promise before returning so parallel calls can wait for the same call
      csrsCache.put(cacheKey, requestPromise?.data);

      response = await requestPromise?.data;
    }

    return response;
  } catch (error) {
    log.error(
      `[ID][SAML_CERTIFICATES] Error getting the list of certificates for idp: ${idpId}`,
      error
    );

    throw error;
  }
};

/**
 * Creates a certificate signing request
 *
 * @param {Object} options - The options for creating a certificate signing request.
 * @param {string} options.directoryId - The directory ID.
 * @param {string} options.idpId - The IDP (Identity Provider) ID.
 * @param {Object} options.certificateData - The certificate data.
 * @returns {Promise} A promise that resolves with the created certificate signing request.
 * @throws {Error} If there is an error creating the certificate signing request.
 */
const createCertificateSigningRequest = async ({directoryId, idpId, certificateData}) => {
  try {
    return await samlCertificates.createCertificateSigningRequest({
      certificateData,
      directoryId,
      idpId,
    });
  } catch (error) {
    log.error(
      `[ID][SAML_CERTIFICATES] Error creating a certificate signing request for idp: ${idpId}`,
      error
    );

    throw error;
  }
};

/**
 * Retrieves the details of a CSR (Certificate Signing Request)
 *
 * @param {Object} options - The options for retrieving the CSR details.
 * @param {string} options.directoryId - The directory ID.
 * @param {string} options.idpId - The IDP (Identity Provider) ID.
 * @param {string} options.csrId - The CSR ID.
 * @returns {Promise} A promise that resolves with the CSR details.
 * @throws {Error} If there is an error retrieving the CSR details.
 */
const getCsrDetails = async ({directoryId, idpId, csrId}) => {
  try {
    return await samlCertificates.getCsrDetails({
      csrId,
      directoryId,
      idpId,
    });
  } catch (error) {
    log.error(`[ID][SAML_CERTIFICATES] Error getting the details for csr: ${csrId}`, error);

    throw error;
  }
};

/**
 * Uploads a CSR (Certificate Signing Request) file
 *
 * @param {Object} options - The options for uploading the CSR file.
 * @param {string} options.directoryId - The directory ID.
 * @param {string} options.idpId - The IDP (Identity Provider) ID.
 * @param {string} options.csrId - The CSR ID.
 * @param {File} options.csrFile - The CSR file to upload.
 * @returns {Promise} A promise that resolves with the result of the upload.
 * @throws {Error} If there is an error uploading the file.
 */
const uploadCsrFile = async ({directoryId, idpId, csrId, csrFile}) => {
  try {
    return await samlCertificates.uploadCsrFile({
      csrFile,
      csrId,
      directoryId,
      idpId,
    });
  } catch (error) {
    log.error(`[ID][SAML_CERTIFICATES] Error uploading the file for csr: ${csrId}`, error);

    throw error;
  }
};

/**
 * Hook to use the SAML Certificates API inside React components
 */
const useSamlCertificates = () => {
  const createCertificateCallback = useCallback(createCertificate, []);
  const createCertificateSigningRequestCallback = useCallback(createCertificateSigningRequest, []);
  const downloadCertificateCallback = useCallback(downloadCertificate, []);
  const getCertificatesCallback = useCallback(getCertificates, []);
  const getCertificatesSigningRequestsCallback = useCallback(getCertificatesSigningRequests, []);
  const getCsrDetailsCallback = useCallback(getCsrDetails, []);
  const removeCertificateCallback = useCallback(removeCertificate, []);
  const removeCertificateSigningRequestCallback = useCallback(removeCertificateSigningRequest, []);
  const setCertificateAsDefaultCallback = useCallback(setCertificateAsDefault, []);
  const updateCertificateStatusCallback = useCallback(updateCertificateStatus, []);
  const uploadCsrFileCallback = useCallback(uploadCsrFile, []);

  const clearCertificatesCache = useCallback(() => certificatesCache.clearAll(), []);
  const clearCsrsCache = useCallback(() => csrsCache.clearAll(), []);

  return {
    clearCertificatesCache,
    clearCsrsCache,
    createCertificate: createCertificateCallback,
    createCertificateSigningRequest: createCertificateSigningRequestCallback,
    downloadCertificate: downloadCertificateCallback,
    getCertificates: getCertificatesCallback,
    getCertificatesSigningRequests: getCertificatesSigningRequestsCallback,
    getCsrDetails: getCsrDetailsCallback,
    removeCertificate: removeCertificateCallback,
    removeCertificateSigningRequest: removeCertificateSigningRequestCallback,
    setCertificateAsDefault: setCertificateAsDefaultCallback,
    updateCertificateStatus: updateCertificateStatusCallback,
    uploadCsrFile: uploadCsrFileCallback,
  };
};

export default useSamlCertificates;
export {
  createCertificate,
  createCertificateSigningRequest,
  downloadCertificate,
  getCertificates,
  getCertificatesSigningRequests,
  getCsrDetails,
  removeCertificate,
  removeCertificateSigningRequest,
  setCertificateAsDefault,
  updateCertificateStatus,
  uploadCsrFile,
};
