import {
  CONSUMABLE_SUMMARIZATION_SUMMARIZE_BY,
  ConsumableSummarizationList,
  LicenseGroupList,
  Product,
  feature,
} from '@admin-tribe/acsc';
import {
  Page,
  PageActions,
  PageBanners,
  PageContent,
  PageHeader,
  PageInfoBar,
  getProductDisplayName,
} from '@admin-tribe/acsc-ui';
import {useAsyncModel} from '@pandora/react-async-model';
import {DEVICE_LICENSING_MODES} from '@pandora/react-data-source-device-activation';
import {ImageIcon} from '@pandora/react-image-icon';
import PropTypes from 'prop-types';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';

import useDocumentTitle from 'common/hooks/useDocumentTitle';
import rootStore from 'core/RootStore';
import ProductBanners from 'features/products/components/banners/ProductBanners';
import ProductPageInfoBar from 'features/products/components/product-page-info-bar/ProductPageInfoBar';
import useTotalDevices from 'features/products/device-licenses/components/hooks/useTotalDevices';
import {getViewHeaderData} from 'features/products/device-licenses/components/utils/deviceLicensesUtils';
import ProductTabs from 'features/products/pages/product-page/ProductTabs';
import ProductDetailsPageActions from 'features/products/pages/product-page/actions/ProductDetailsPageActions';

import SharedDeviceLicenseProductPageBanner from './banner/SharedDeviceLicenseProductPageBanner';
import SharedDeviceLicenseProductPageInfoBar from './info-bar/SharedDeviceLicenseProductPageInfoBar';

/**
 * SDL (shared device license) product details page
 */
const SharedDeviceLicenceProductPage = ({product}) => {
  const intl = useIntl();
  const orgId = rootStore.organizationStore.activeOrgId;
  const productDisplayName = getProductDisplayName(intl, product);
  useDocumentTitle({defaultTitle: productDisplayName});

  // @cframpto - Move this to a hook or util file if many more of these are added for the scorecards.
  const fetchConsumableData = useCallback(() => {
    if (product.isGroupConsumable()) {
      return ConsumableSummarizationList.get({
        license_id: product.id,
        organization_id: orgId,
        summarize_by: CONSUMABLE_SUMMARIZATION_SUMMARIZE_BY.LICENSE_ID,
      });
    }
    return null;
  }, [orgId, product]);

  const {
    error: consumableError,
    isLoading: isLoadingConsumableData,
    model: consumableSummarizationList,
  } = useAsyncModel({
    loadFn: fetchConsumableData,
  });
  const fetchLicenseGroupList = useCallback(
    () =>
      LicenseGroupList.get({
        orgId,
        product,
      }), // eslint-disable-next-line react-hooks/exhaustive-deps -- no dependencies
    []
  );

  const {model: licenseGroupList} = useAsyncModel({
    loadFn: fetchLicenseGroupList,
  });

  let activatedDevices, devices;

  const {
    activatedDevices: activatedDevicesFromTotalDevicesHook,
    isError: totalDevicesIsError,
    isLoading: totalDevicesLoading,
    devices: devicesFromTotalDevicesHook,
  } = useTotalDevices({
    productId: product.id,
    ...(feature.isEnabled('temp_sdl_frl_deactivations') && {
      licensingMode: DEVICE_LICENSING_MODES.NAMED_USER_EDUCATION_LAB,
    }),
  });

  // Since SDL API is not ready until temp_enable_react_sdl is on, total and from consumableSummarizationList will be used for consistency
  if (feature.isDisabled('temp_enable_react_sdl') && feature.isEnabled('bug_fix_abps_33951')) {
    // assuming summaries only has one item
    const firstSummary = consumableSummarizationList?.getSummariesForId(product.id, {
      summarize_by: CONSUMABLE_SUMMARIZATION_SUMMARIZE_BY.LICENSE_ID,
    })?.[0];

    // assuming consumableList.items only has one item
    const consumable = firstSummary?.consumableList?.items?.[0];
    activatedDevices = {
      data: {
        search: {
          total: consumable?.consumedQuantity,
        },
      },
    };

    devices = {
      data: {
        search: {
          total: consumable?.totalQuantity,
        },
      },
    };
  } else {
    activatedDevices = activatedDevicesFromTotalDevicesHook;
    devices = devicesFromTotalDevicesHook;
  }

  const {showBanner, totalLicensesCount, usedLicensesCount, totalDevicesCount, earliestExpiryDate} =
    getViewHeaderData({
      activatedDevices,
      devices,
      intl,
      isLoading: totalDevicesLoading,
      product,
    });

  return (
    <Page
      data-testid="sdl-product-details-page"
      isBumpered={!!totalDevicesIsError || !!consumableError}
      isLoading={!!totalDevicesLoading || !!isLoadingConsumableData}
    >
      <PageBanners>
        <ProductBanners licenseGroupList={licenseGroupList} product={product} />
        <SharedDeviceLicenseProductPageBanner showBanner={showBanner} />
      </PageBanners>

      <PageHeader title={productDisplayName}>
        <ImageIcon alt="" size="M" src={product.getIcon()} />
      </PageHeader>

      <PageActions>
        <ProductDetailsPageActions product={product} />
      </PageActions>

      <PageInfoBar>
        {feature.isEnabled('temp_enable_react_sdl') ? (
          <SharedDeviceLicenseProductPageInfoBar
            earliestExpiryDate={earliestExpiryDate}
            totalDevicesCount={totalDevicesCount}
            totalLicensesCount={totalLicensesCount}
            usedLicensesCount={usedLicensesCount}
          />
        ) : (
          <ProductPageInfoBar
            consumableSummarizationList={consumableSummarizationList}
            product={product}
          />
        )}
      </PageInfoBar>

      <PageContent>
        <ProductTabs product={product} />
      </PageContent>
    </Page>
  );
};

SharedDeviceLicenceProductPage.propTypes = {
  /**
   * The Product model.
   */
  product: PropTypes.instanceOf(Product).isRequired,
};

export default SharedDeviceLicenceProductPage;
