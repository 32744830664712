import {PAGE_TARGET, PAGE_TARGET_TYPE, PageContext} from '@admin-tribe/acsc';
import {
  InfoItemScorecard,
  Page,
  PageContent,
  PageHeader,
  PageInfoBar,
  PageNav,
  useStore,
} from '@admin-tribe/acsc-ui';
import {observer} from 'mobx-react-lite';
import React, {useRef} from 'react';
import {useIntl} from 'react-intl';
import {useParams} from 'react-router-dom';

import MemberProductIconList from 'common/components/member-product-icon-list/MemberProductIconList';
import UserListTableSection from 'common/components/user-list-table-section/UserListTableSection';
import {MemberListContextProvider} from 'common/hooks/member-list-context/MemberListContext';
import useDocumentTitle from 'common/hooks/useDocumentTitle';
import UserStore from 'common/stores/user-store/UserStore';
import OrganizationIntegrationList from 'core/organizations/integration-list/OrganizationIntegrationList';

/**
 * The Api Credentials top level page
 */
const ApiCredentialsPage = observer(() => {
  const {orgId} = useParams();
  const intl = useIntl();

  useDocumentTitle({key: 'users.APICredentials.documentTitle'});

  const pageContext = useRef(
    new PageContext({
      target: PAGE_TARGET.ORGANIZATION,
      targetId: orgId,
      targetType: PAGE_TARGET_TYPE.INTEGRATION,
    })
  ).current;

  const userStore = useStore(
    () =>
      new UserStore({
        immediateLoad: true,
        listClassRef: OrganizationIntegrationList,
      })
  );

  return (
    <Page data-testid="api-credentials-page">
      <PageHeader title={intl.formatMessage({id: 'users.APICredentials.title'})} />
      <PageInfoBar>
        <InfoItemScorecard
          label={intl.formatMessage({id: 'users.APICredentials.scorecard.APICredentials'})}
          value={userStore.scorecardValue}
        />
      </PageInfoBar>
      <PageNav />
      <PageContent>
        <MemberListContextProvider
          canAddMember={false}
          canRemoveMember={false}
          hasSearch={false}
          memberListDisplay={{
            createdDate: true,
            lastModifiedDate: true,
            productIcons: true,
            viewDetails: true,
          }}
          pageContext={pageContext}
          productIconList={<MemberProductIconList inlineProductCount={0} />}
          store={userStore}
        >
          <UserListTableSection />
        </MemberListContextProvider>
      </PageContent>
    </Page>
  );
});

export default ApiCredentialsPage;
