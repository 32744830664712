import {feature, log} from '@admin-tribe/acsc';
import {translateString, translateStringForLocale} from '@admin-tribe/acsc-ui';
import Papa from 'papaparse';

import {CSV_HEADER_LOCALIZATION_MAP} from './bulkOperationConstants';

/**
 * @description Localizes a given CSV file.
 *              For now, only changes the headers.
 *
 * @param {File} csvFile The CSV file to read and localize
 * @returns {Promise} resolves to File with localized text
 */
const localizeCsvFile = async (csvFile) => {
  const fileText = await readFileAsText(csvFile);
  const localizedText = localizeCsvText(fileText);
  return new File([localizedText], csvFile.name, {
    lastModified: csvFile.lastModified,
    type: csvFile.type,
  });
};

/**
 * @description Delocalizes a given CSV file.
 *              For now, only changes the headers.
 *
 * @param {File} csvFile The CSV file to read and delocalize
 * @returns {Promise} resolves to File with delocalized text
 */
const delocalizeCsvFile = async (csvFile) => {
  const fileText = await readFileAsText(csvFile);
  const delocalizedText = delocalizeCsvText(fileText);
  return new File([delocalizedText], csvFile.name, {
    lastModified: csvFile.lastModified,
    type: csvFile.type,
  });
};

/** ================== Private Methods ==================  **/

/**
 * @description Localizes the given CSV text string
 *              For now, only changes the headers.
 *
 * @param {String} csvText The CSV text to localize.
 * @returns {String} The delocalized CSV text.
 */
function localizeCsvText(csvText) {
  const localizationMap = headerLocalizationMap();
  const localizeHeaderFunction = (header) => {
    if (!localizationMap.has(header)) {
      log.error(
        `Unrecognized CSV header returned by backend -- "${header}" -- needs to be supported here or removed in backend`
      );
      return header;
    }
    return localizationMap.get(header);
  };

  const modifiedCsvObjects = Papa.parse(csvText, {
    delimiter: ',',
    header: true,
    skipEmptyLines: true,
    transformHeader: localizeHeaderFunction,
  });

  const newCsvText = Papa.unparse(modifiedCsvObjects, {
    delimiter: ',',
    header: true,
  });
  return newCsvText;
}

/**
 * @description Delocalizes a given CSV text string.
 *              For now, only changes the headers.
 *
 * @param {String} csvText The CSV text to delocalize.
 * @returns {String} The delocalized CSV text.
 * @throws {Error} if CSV header is not valid for the user's locale, or English locale.
 */
function delocalizeCsvText(csvText) {
  const delocalizationMap = headerDelocalizationMap();

  const delocalizeHeaderFunction = (header) => {
    if (!delocalizationMap.has(header)) {
      // Header is not recognized by the localization map
      // Throw the same type of error code as JIL would for an unknown header.
      throw new Error('INVALID_STREAM_CSV_HEADER');
    }
    return delocalizationMap.get(header);
  };

  let newCsvText;
  if (feature.isEnabled('temp_enable_semicolon_delimiter')) {
    const modifiedCsvObjects = Papa.parse(csvText, {
      delimiter: '',
      delimitersToGuess: [',', ';'],
      header: true,
      skipEmptyLines: true,
      transformHeader: delocalizeHeaderFunction,
    });
    newCsvText = Papa.unparse(modifiedCsvObjects, {
      delimiter: modifiedCsvObjects.meta.delimiter,
      header: true,
    });
  } else {
    const modifiedCsvObjects = Papa.parse(csvText, {
      delimiter: ',',
      header: true,
      skipEmptyLines: true,
      transformHeader: delocalizeHeaderFunction,
    });
    newCsvText = Papa.unparse(modifiedCsvObjects, {
      delimiter: ',',
      header: true,
    });
  }
  return newCsvText;
}

function readFileAsText(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener('error', reject);
    reader.addEventListener('load', () => {
      resolve(reader.result);
    });
    reader.readAsText(file);
  });
}

function headerLocalizationMap() {
  const map = new Map();
  CSV_HEADER_LOCALIZATION_MAP.forEach((header) => {
    map.set(
      header.jil,
      translateString({
        id: header.localizationKey,
      })
    );
  });
  return map;
}

function headerDelocalizationMap() {
  const map = new Map();

  CSV_HEADER_LOCALIZATION_MAP.forEach((header) => {
    // When parsing user-submitted CSVs, we want to support both
    // the user's locale, and our original "en" (English) locale that
    // has been supported prior to this feature.
    map.set(translateString({id: header.localizationKey}), header.jil);
    map.set(translateStringForLocale('en', {id: header.localizationKey}, {}), header.jil);
  });

  return map;
}

export {delocalizeCsvFile, localizeCsvFile};
