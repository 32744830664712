import {OrganizationEncryption} from '@admin-tribe/acsc';
import binkyUI from '@admin-tribe/acsc-ui';
import {Flex, Heading, StatusLight, Text} from '@adobe/react-spectrum';
// eslint-disable-next-line @admin-tribe/admin-tribe/react-spectrum-prefer-v3 -- v2 FieldLabel should be replaced with props on v3 components
import FieldLabel from '@react/react-spectrum/FieldLabel';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {ENCRYPTION_SETTINGS_CONSTANTS} from '../EncryptionSettingsPageConstants';

const GoUrl = binkyUI.common.components.GoUrl;
const namespace = 'settings.encryptionSettings';

/**
 * @description the content/trigger area for users to view/change encryption status
 * of an organization
 */
const EncryptionContentArea = ({
  canShowStatus,
  encryptionDescription,
  encryptionInfo,
  encryptionStatus,
}) => {
  const intl = useIntl();

  return (
    <Flex direction="column">
      <Heading data-testid="encryption-settings-header" level={2}>
        {intl.formatMessage({
          id: `${namespace}.description.header`,
        })}
      </Heading>
      <Flex direction="column" marginEnd="size-600">
        <Text data-testid="encryption-settings-description">{encryptionDescription}</Text>
        {encryptionInfo.encryptionStatus ===
          ENCRYPTION_SETTINGS_CONSTANTS.ENCRYPTION_STATUS.DISABLED && (
          <Text data-testid="encryption-settings-secondary-description" marginTop="size-200">
            {intl.formatMessage({
              id: `${namespace}.description.completed.disabledSecondary`,
            })}
          </Text>
        )}
      </Flex>
      <GoUrl marginY="size-200" name="org_asset_encryption">
        {intl.formatMessage({
          id: `${namespace}.description.learnMore`,
        })}
      </GoUrl>
      {canShowStatus && (
        <Flex alignItems="baseline" direction="row" marginBottom="size-200">
          <FieldLabel
            label={intl.formatMessage({
              id: `${namespace}.status.label`,
            })}
            labelFor="status-light"
          />
          <StatusLight
            aria-label={encryptionStatus}
            data-testid="encryption-status"
            id="status-light"
            role="status"
            variant={
              encryptionInfo.encryptionStatus ===
              ENCRYPTION_SETTINGS_CONSTANTS.ENCRYPTION_STATUS.ENABLED
                ? 'positive'
                : 'notice'
            }
          >
            {encryptionStatus}
          </StatusLight>
        </Flex>
      )}
    </Flex>
  );
};

EncryptionContentArea.propTypes = {
  /**
   * True if encryption for an organiztion is
   * not in progress or in an error state so that
   * current status can be displayed
   */
  canShowStatus: PropTypes.bool.isRequired,
  /**
   * Formatted string to show description of encryption state
   */
  encryptionDescription: PropTypes.string.isRequired,
  /**
   * Object that contains encryption information
   * for an organization
   */
  encryptionInfo: PropTypes.instanceOf(OrganizationEncryption).isRequired,
  /**
   * Formatted string to show encryption status
   */
  encryptionStatus: PropTypes.string.isRequired,
};

export default EncryptionContentArea;
