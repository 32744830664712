import {Contract, ProductList} from '@admin-tribe/acsc';
import {ModalContent} from '@admin-tribe/acsc-ui';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';

import {EVENT_NAME} from '../SelfCancelAnalyticsUtils';
import {SELF_CANCEL_STEPS} from '../SelfCancelConstants';
import LossAversionCardFactory from '../components/loss-aversion-cards/LossAversionCardFactory';
import {useSelfCancelAnalyticsContext} from '../components/self-cancel-analytics-context/SelfCancelAnalyticsContext';
import SelfCancelBaseFooter from '../components/self-cancel-base-modal/SelfCancelBaseFooter';
import SelfCancelBaseModal from '../components/self-cancel-base-modal/SelfCancelBaseModal';
import SelfCancelCarousel from '../components/self-cancel-carousel/SelfCancelCarousel';
import SelfCancelFooterTotal from '../components/self-cancel-footer-total/SelfCancelFooterTotal';
import useLossAversionCards from '../hooks/use-loss-aversion-cards/useLossAversionCards';

/**
 * Self cancel workflow step component for the loss aversion/tactics.
 */
const LossAversionStep = ({
  contract,
  onClose,
  onContinue,
  onPrevious,
  productList,
  currentStep,
  selectedSeats,
  steps,
}) => {
  const intl = useIntl();
  const {dispatchSelfCancelPageAnalytics, dispatchNavigationAnalyticsEvent} =
    useSelfCancelAnalyticsContext();

  const cards = useLossAversionCards({contract, selectedSeats});

  // Send page load analytics when component is loaded
  useEffect(() => {
    dispatchSelfCancelPageAnalytics({cards, step: SELF_CANCEL_STEPS.LOSS_AVERSION_STEP});
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Explicitly only execute once on component load
  }, []);

  const onCta = () => {
    dispatchNavigationAnalyticsEvent({clickType: EVENT_NAME.CONTINUE});
    onContinue?.();
  };

  const onCancel = () => {
    dispatchNavigationAnalyticsEvent({clickType: EVENT_NAME.CANCEL});
    onClose?.();
  };

  const onSecondary = () => {
    dispatchNavigationAnalyticsEvent({clickType: EVENT_NAME.PREVIOUS});
    onPrevious?.();
  };

  return (
    <SelfCancelBaseModal
      cancelLabel={intl.formatMessage({id: 'common.modal.buttons.close'})}
      ctaLabel={intl.formatMessage({id: 'account.selfCancel.buttons.continue'})}
      currentStep={currentStep}
      heading={intl.formatMessage({id: 'account.selfCancel.common.title'})}
      onCancel={onCancel}
      onCta={onCta}
      onSecondary={onSecondary}
      secondaryLabel={intl.formatMessage({id: 'account.selfCancel.buttons.previous'})}
      steps={steps}
      subheading={intl.formatMessage({id: 'account.selfCancel.lossAversion.description'})}
    >
      <ModalContent>
        <SelfCancelCarousel cards={cards} Component={LossAversionCardFactory} step={currentStep} />
      </ModalContent>
      <SelfCancelBaseFooter>
        <SelfCancelFooterTotal
          contract={contract}
          productList={productList}
          selectedSeats={selectedSeats}
        />
      </SelfCancelBaseFooter>
    </SelfCancelBaseModal>
  );
};

LossAversionStep.propTypes = {
  /**
   * The org's contract.
   */
  contract: PropTypes.instanceOf(Contract).isRequired,
  /**
   * Current step in the flow.
   */
  currentStep: PropTypes.string,
  /**
   * Handler that is called when the user wants to close the flow.
   */
  onClose: PropTypes.func,
  /**
   * Handler that is called when the user wants to progress in the flow.
   */
  onContinue: PropTypes.func,
  /**
   * Handler that is called when the user wants to navigate back.
   */
  onPrevious: PropTypes.func,
  /**
   * The list of org's products.
   */
  productList: PropTypes.instanceOf(ProductList).isRequired,
  /**
   * The hash map of of initially selected licenses per product id.
   */
  selectedSeats: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  /**
   * List of steps in the flow.
   */
  steps: PropTypes.arrayOf(PropTypes.string),
};

export default LossAversionStep;
