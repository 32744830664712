import {eventBus} from '@admin-tribe/acsc';
import {useEffect, useState} from 'react';

import AddUsersToOrgModal from 'common/components/add-user-modals/AddUsersToOrgModal';
import rootStore from 'core/RootStore';

const MODAL_TYPE = {
  SRC1: 'src1',
  SRC2: 'src2',
};

const GLOBAL_MODAL_OPEN_EVENT = 'global-modals-open';

/**
 * @description Hook to initialize a modal from a src2 component, or to open
 * a src1 global modal using the globalModalManager interface
 *
 * @param {Object} modal - modal payload
 * @param {String} modal.type - the type of modal; 'src1' or 'src2'
 * @param {Object} modal.component - src2 modal component, for src2 modals only
 * @param {Object} componentName - src1 modal component name, for src1 modals only
 * @param {Object} componentBindings - src1 modal component bindings (see global-modals-manager), for src1 modals only
 *
 * @example
 *  const {
 *    closeModal,
 *    Modal
 *    modalProps,
 *    openModal,
 *  } = useGlobalModal({
 *    type: 'src2',
 *    component: AddUsersToOrgModal
 *  })
 *  ...
 *  <Modal {...modalProps}/>
 */
const useGlobalModal = (modal) => {
  const [Modal, setModal] = useState(null);
  const [modalProps, setModalProps] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Open the src1 global modal
  const openSrc1GlobalModal = ({componentName, componentBindings}) => {
    eventBus.emit(GLOBAL_MODAL_OPEN_EVENT, {
      componentBindings,
      componentName,
      version: 2,
    });
  };

  // find modal to display
  useEffect(() => {
    // Set src2 modal and configure its props
    if (modal?.type === MODAL_TYPE.SRC2) {
      switch (modal?.component) {
        case AddUsersToOrgModal:
          setModalProps({
            isOpen: true,
            onClosed: () => {
              setIsModalOpen(false);
            },
            orgId: rootStore.organizationStore.activeOrg.id,
          });
          setModal(AddUsersToOrgModal);
          break;
        default:
          setModal(null);
          setModalProps(null);
          break;
      }
    }
  }, [modal, isModalOpen]);

  /**
   * @description callback to open modal from hook consumer
   */
  const openModal = () => {
    switch (modal?.type) {
      case MODAL_TYPE.SRC1:
        openSrc1GlobalModal(modal);
        break;
      case MODAL_TYPE.SRC2:
        setIsModalOpen(true);
        break;
      default:
        break;
    }
  };
  return {
    Modal: isModalOpen ? Modal : null,
    modalProps,
    openModal,
  };
};

export default useGlobalModal;
export {MODAL_TYPE};
