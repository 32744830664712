import {
  AnalyticsEvent,
  AuthenticatedUser,
  PAGE_TARGET,
  PAGE_TARGET_TYPE,
  isContractOwner,
} from '@admin-tribe/acsc';
import {showError, showSuccess} from '@admin-tribe/acsc-ui';

import rootStore from 'core/RootStore';
import auth from 'core/services/auth';
import {
  getHrefForAdminDetails,
  getHrefForDeveloperDetails,
  getHrefForDirectoryUserDetails,
  getHrefForUserDetails,
  getHrefForUserGroupDetails,
} from 'features/users/routing/navigation-callbacks/navigationCallbacks';

/**
 * @description Method to dispatch analytics for the User operations which includes
 *   everything that was formerlly part of angular's appUsersAll and appUserGroupsAll.
 */
function dispatchRemoveUserAnalytics({pageContext, selectedItemCount}) {
  if (pageContext.isUserGroupTarget()) {
    AnalyticsEvent.dispatch({
      attributes: {userGroupsRemovedCount: selectedItemCount},
      componentMethod: 'removeUserGroups',
      componentMethodType: 'submit',
      componentName: 'appUserGroupsAll',
    });
  } else {
    AnalyticsEvent.dispatch({
      attributes: {usersRemovedCount: selectedItemCount},
      componentMethod: 'removeUsers',
      componentMethodType: 'submit',
      componentName: 'appUsersAll',
      pageContext,
    });
  }
}

/**
 * @description Method to get the href for the details page for the given member.
 *
 * @param {member} member The member.
 * @param {PageContext} pageContext The context for the member.
 * @returns {String} href for the member's details page.
 */
// eslint-disable-next-line @admin-tribe/admin-tribe/jsdoc-exported-functions -- feature flag
function getMemberDetailsHref({member, pageContext}) {
  if (pageContext.isUserTargetType()) {
    if (pageContext.isDirectoryTarget()) {
      return getHrefForDirectoryUserDetails({
        directoryId: pageContext.targetId,
        userId: member.id,
      });
    }
    return member.getType().isUserGroup()
      ? getHrefForUserGroupDetails({userGroupId: member.id})
      : getHrefForUserDetails({userId: member.id});
  }

  if (pageContext.isDeveloperTargetType()) {
    return getHrefForDeveloperDetails({userId: member.id});
  }

  if (pageContext.isAdminTargetType()) {
    return getHrefForAdminDetails({
      userId: member.id,
    });
  }

  if (pageContext.isUserGroupTarget()) {
    return getHrefForUserGroupDetails({userGroupId: member.id});
  }

  if (pageContext.isIntegrationTargetType()) {
    return undefined;
  }

  throw new Error(`getMemberDetailsHref: unhandled targetType ${pageContext.targetType}`);
}

/**
 * @description Method to return the message subkey for the common strings.
 *
 * @param {member} member The member.
 * @param {PageContext} pageContext The context for the member.
 * @returns {String} the message subkey.
 */
function getTableMsgSubkey({pageContext}) {
  switch (pageContext.targetType) {
    case PAGE_TARGET_TYPE.ADMIN:
      return 'admins';
    case PAGE_TARGET_TYPE.DEVELOPER:
      return 'developers';
    case PAGE_TARGET_TYPE.INTEGRATION:
      return 'APICredentials';
    case PAGE_TARGET_TYPE.USER:
      return pageContext.target === PAGE_TARGET.DIRECTORY ? 'directoryUsers' : 'users';
    case PAGE_TARGET_TYPE.USER_GROUP:
      return 'userGroups';
    default:
      throw new Error(`getTableMsgSubkey: unhandled targetType ${pageContext.targetType}`);
  }
}

/**
 * @description Method to determine if a member is the the current logged in admin.
 *
 * @returns {Boolean} return true if member is the logged in admin, else false.
 */
function isLoggedInMember(member) {
  return AuthenticatedUser.get().getId() === member.id;
}

/**
 * @description Method to determine if a member is selectable/editable in the given context.
 *
 * @param {Object} options
 * @param {OrganizationUser|UserGroup} options.member - the entity to check
 *   This is a UserGroup when pageContext.isUserGroupTargetType() is true, otherwise it is
 *   an OrganizationUser.
 * @param {PageContext} options.pageContext - the context for the member
 * @returns {Boolean} return true if member defined and is selectable, else false.
 */
/* eslint-disable complexity -- lots of checks */
function isSelectable({member, pageContext}) {
  if (!member) {
    return false;
  }

  if (member.getType().isTechnicalAccount() && !pageContext.isIntegrationTargetType()) {
    return false;
  }

  const isLoggedIn = isLoggedInMember(member);

  if (pageContext.isOrganizationTarget()) {
    // users can't remove themselves from the org, or top level admin roles,
    // or externally managed users / marked as not removable
    const isRemovable = member.isRemovable?.() ?? true;
    return isRemovable && !isLoggedIn;
  }

  if (pageContext.isAdminTargetType()) {
    // only org admins can remove themselves from other roles
    // but the remaining types can remove users who aren't the current admin
    return auth.isUserOrgAdmin() || !isLoggedIn;
  }

  if (pageContext.isUserGroupTargetType()) {
    // if a user group and externally managed it can not be removed
    // if a user group is shared it cannot be edited or removed
    return !member.isExternallyManaged?.() && !member.isShared?.();
  }

  if (pageContext.isDirectoryTarget()) {
    // directory users can't remove themselves from org
    if (isLoggedIn) {
      return false;
    }
    // an externally managed user is editable only if the account is disabled
    return member.isExternallyManaged?.() ? member.isDisabled() : true;
  }

  // if it is a product/profile assignment, any admin can remove a user
  return true;
}
/* eslint-enable complexity -- lots of checks */

/**
 * Removes the member with the selected item keys, resets the table state,
 * dispatches analytics, reloads the list and pops a toast. On error, pops an error toast.
 *
 * @param {Object} intl Intl object to format the error toasts.
 * @param {Function} onShowSuccessToast Optional callback when the members are successfully removed.
 *   The callback has no paramters. If not specified, the default success toast is used.
 * @param {PageContext} pageContext The context for the page.
 * @param {Object} saveOptions Options to pass down to the save API call.
 * @param {Store} store The admin table store. The save and load methods are in this store.
 * @param {Object} tableSectionUtils From the TableSection context.
 * @returns {Promise} resolves with true if success, false if error
 */
async function onRemoveSelectedMembers({
  intl,
  onShowSuccessToast = showSuccess,
  pageContext,
  saveOptions = {},
  store,
  tableSectionUtils,
}) {
  // Only if the list remove operation is successful, will the store's pageNumber, search filter and selected items be reset.
  try {
    if (pageContext.isDeveloperTargetType()) {
      const params = pageContext.isProductConfigurationTarget()
        ? {productId: pageContext.targetParentId, profileId: pageContext.targetId}
        : {};
      await store.removeSelectedDevelopers(params);
    } else {
      await store.removeSelectedMembers({saveOptions});
    }
  } catch (error) {
    showErrorToast({error, intl, store});
    return false;
  }

  dispatchRemoveUserAnalytics({
    pageContext,
    selectedItemCount: tableSectionUtils.getSelectedItemCount(),
  });

  // Now make sure the table section and the store's list state are in sync.
  tableSectionUtils.deleteAllFilters();
  tableSectionUtils.clearSelectedItemKeys();
  tableSectionUtils.setPageNumber(1);
  store.resetListState();

  onShowSuccessToast();

  // Reload the list now that the item(s) are deleted and the list has
  // been reset.
  store.load();

  return true;
}

///////////////////
// Internal helper methods
///////////////////

function showErrorToast({error, intl, store}) {
  const errorCode = error?.response?.data.errorCode;

  switch (errorCode) {
    case 'CANNOT_REMOVE_CONTRACT_OWNER_FROM_ORG': {
      const contractList = rootStore.organizationStore.contractList;
      // If the admin is a T2E, JIL checks their authenticating account since T2Es cannot be contract owners.
      const removedOwner = store.selectedItems.find((member) =>
        isContractOwner(contractList, {checkAuthUser: true, userId: member.id})
      );
      const name = removedOwner?.getDisplayNameOrEmail?.();
      showError(
        name && intl.formatMessage({id: 'common.memberUtils.toast.cantRemoveContractOwner'}, {name})
      );
      break;
    }
    case 'FORBIDDEN_BY_POLICY':
      showError(intl.formatMessage({id: 'common.memberUtils.toast.forbiddenByPolicy'}));
      break;
    default:
      showError();
      break;
  }
}

export {
  dispatchRemoveUserAnalytics,
  getMemberDetailsHref,
  getTableMsgSubkey,
  isLoggedInMember,
  isSelectable,
  onRemoveSelectedMembers,
};
