import {isMigrationListT2eClean} from '@admin-tribe/acsc';
import {
  showError as showErrorToast,
  showInfo as showInfoToast,
  showSuccess as showSuccessToast,
} from '@admin-tribe/acsc-ui';
import {useDialogContainer} from '@adobe/react-spectrum';
import {ModalAlertDialog} from '@pandora/react-modal-dialog';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, {useMemo, useState} from 'react';
import {useIntl} from 'react-intl';

import DirectoryEntity from 'common/entities/DirectoryEntity';
import useDirectoryList from 'common/hooks/api/useDirectoryList';
import rootStore from 'core/RootStore';
import DeleteDirectoriesErrorModal from 'features/settings/components/directories/directory-list/directory-list-table/delete-directories-modal/DeleteDirectoriesErrorModal';
import DeleteDirectoriesModalDescription from 'features/settings/components/directories/directory-list/directory-list-table/delete-directories-modal/DeleteDirectoriesModalDescription';
import useTrustList from 'features/settings/hooks/api/useTrustList';

const MODAL_ID = 'delete-directories-modal';

const DeleteDirectoriesModal = observer(({directories, handleDirectoriesDeleteConfirm}) => {
  const dialog = useDialogContainer();
  const intl = useIntl();

  const {revokeTrusts} = useTrustList();
  const {deleteDirectories} = useDirectoryList();

  const [isLoading, setIsLoading] = useState(false);
  const [unremovableDirectories, setUnremovableDirectories] = useState([]);

  const isT2eOrg = useMemo(
    () => isMigrationListT2eClean(rootStore.organizationStore.migrationList),
    []
  );

  const trustedDirectories = useMemo(
    () => directories.filter((directory) => directory.isTrusted),
    [directories]
  );

  /**
   * If there are no trusted directories, returns the unfiltered list of directories.
   *
   *
   * If there are trusted directories, it first attempts to revoke those trusts.
   *  - if the API call to revoke trusts succeeds, filters out the directories for which trusts could not be revoked.
   *  - if the API call fails, filters out all trusted directories.
   */
  const handleTrustedDirectories = async () => {
    let directoriesToDelete = [...directories];

    try {
      if (trustedDirectories.length > 0) {
        const trusts = trustedDirectories
          // don't attempt to revoke already revoked or rejected trusts
          .filter((directory) => !directory.isStatusRevoked && !directory.isStatusRejected)
          .map(({id}) => ({
            directoryId: id,
            trusteeOrgId: rootStore.organizationStore.activeOrgId,
          }));

        let failedToRevoke = [];

        if (trusts.length > 0) {
          ({failedToRevoke} = await revokeTrusts(trusts));
        }

        if (failedToRevoke.length > 0) {
          showInfoToast(
            intl.formatMessage({
              id: 'settings.directories.deleteDirectoriesModal.toasts.failedToRevokeTrusts',
            })
          );
        }

        // if a trust couldn't be revoked, the corresponding directory shouldn't be deleted
        directoriesToDelete = directoriesToDelete.filter(
          (directory) => !failedToRevoke.some((trust) => trust.directoryId === directory.id)
        );
      }
    } catch {
      showErrorToast(
        intl.formatMessage({
          id: 'settings.directories.deleteDirectoriesModal.toasts.errorRevokingTrusts',
        })
      );

      // if error revoking trusts, delete only directories that are not trusted
      directoriesToDelete = directoriesToDelete.filter((directory) => !directory.isTrusted);
    }

    return directoriesToDelete;
  };

  const onDeleteDirectories = async () => {
    setIsLoading(true);

    const directoriesToDelete = await handleTrustedDirectories();

    try {
      const {deleted, notDeleted} = await deleteDirectories(directoriesToDelete);

      if (deleted.length > 0) {
        showSuccessToast(
          intl.formatMessage(
            {id: 'settings.directories.deleteDirectoriesModal.toasts.success'},
            {count: deleted.length}
          )
        );
      }

      if (notDeleted.length === 0) {
        dialog.dismiss();
      }

      setUnremovableDirectories(notDeleted);

      if (deleted.length > 0) {
        handleDirectoriesDeleteConfirm();
      }
    } catch (error) {
      showErrorToast();
    } finally {
      setIsLoading(false);
    }
  };

  if (unremovableDirectories.length > 0) {
    return (
      <DeleteDirectoriesErrorModal
        selectedDirectoriesCount={directories.length}
        unremovableDirectories={unremovableDirectories}
      />
    );
  }

  return (
    <ModalAlertDialog
      id={MODAL_ID}
      isLoading={isLoading}
      onCancel={() => dialog.dismiss()}
      onPrimaryAction={onDeleteDirectories}
      primaryActionLabel={intl.formatMessage({
        id: 'settings.directories.deleteDirectoriesModal.buttons.delete',
      })}
      title={intl.formatMessage(
        {id: 'settings.directories.deleteDirectoriesModal.title'},
        {count: directories.length}
      )}
    >
      <DeleteDirectoriesModalDescription
        count={directories.length}
        directoryName={directories[0].directoryName}
        hasTrustedDirectories={trustedDirectories.length > 0}
        isT2eOrg={isT2eOrg}
      />
    </ModalAlertDialog>
  );
});

DeleteDirectoriesModal.propTypes = {
  /**
   * The directories selected for deletion
   */
  directories: PropTypes.arrayOf(PropTypes.instanceOf(DirectoryEntity)).isRequired,
  /**
   * Handler for directory deletion confirmation
   */
  handleDirectoriesDeleteConfirm: PropTypes.func.isRequired,
};
export default DeleteDirectoriesModal;
