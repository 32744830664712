import {feature} from '@admin-tribe/acsc';
import {ActionButton, Cell, Column, Flex, Row, TableBody, TableHeader} from '@adobe/react-spectrum';
import {SORT_ORDER, Table, useTableSectionContext} from '@pandora/react-table-section';
import {TooltipButton} from '@pandora/react-tooltip-button';
import {TruncatedText} from '@pandora/react-truncated-text';
import EditIcon from '@spectrum-icons/workflow/Edit';
import PropTypes from 'prop-types';
import React, {useCallback, useMemo} from 'react';
import {useIntl} from 'react-intl';

import SafePrice from 'common/components/safe-price/SafePrice';
import {INVOICE_TYPES} from 'features/account/billing-history/BillingHistoryConstants';
import {hasUnpaidInvoices} from 'features/account/billing-history/BillingHistoryUtils';
import BillingHistoryActionsMenu from 'features/account/billing-history/components/billing-history-actions-menu/BillingHistoryActionsMenu';
import InvoiceStatus from 'features/account/billing-history/components/invoice-status/InvoiceStatus';
import SafeFormattedDateAndTime from 'features/account/self-cancel/components/safe-formatted-date-and-time/SafeFormattedDateAndTime';

import styles from './BillingHistoryTable.pcss';

const SORT_DESCRIPTOR = {column: 'date', direction: SORT_ORDER.DESC};

const documentTypeContentMapping = {
  [INVOICE_TYPES.INVOICE]: 'account.billingHistory.table.documentType.invoice',
  [INVOICE_TYPES.CREDIT]: 'account.billingHistory.table.documentType.credit',
  [INVOICE_TYPES.RECEIPT]: 'account.billingHistory.table.documentType.receipt',
};

/**
 * Billing History Table React Component - contains invoice information and history
 */
const BillingHistoryTable = ({
  isLoading,
  isPONumberColumnDisplayed,
  onDownloadInvoices,
  onOpenEditPONumber,
  onOpenEmailInvoices,
  onPayNowPressed,
}) => {
  const {tableSectionState} = useTableSectionContext();
  const intl = useIntl();

  const onOpenEditPONumberPress = (invoice) => {
    onOpenEditPONumber({invoice});
  };

  const isUnpaid = hasUnpaidInvoices(tableSectionState.items);
  const showPayNow = isPONumberColumnDisplayed && isUnpaid;

  // As few columns are conditionally added there can't be fixed width value.
  // So therefore this function returns width value in percentage based on props passed.
  const calcWidth = useCallback(
    (widthConf) => {
      if (feature.isEnabled('temp_invoice_column')) {
        if (widthConf.payNowDisplayed && showPayNow) {
          return widthConf.payNowDisplayed;
        }

        if (widthConf.poNumberDisplayed && isPONumberColumnDisplayed) {
          return widthConf.poNumberDisplayed;
        }
      }

      return widthConf.default;
    },
    [isPONumberColumnDisplayed, showPayNow]
  );

  const columns = useMemo(() => {
    const columnsArray = [
      {
        children: intl.formatMessage({id: 'account.billingHistory.table.title.invoiceNumber'}),
        isRowHeader: true,
        key: 'id',
        width: calcWidth({default: '25%', payNowDisplayed: '15%', poNumberDisplayed: '20%'}),
      },
      {
        'aria-label': intl.formatMessage({id: 'account.billingHistory.table.title.actions'}),
        hideHeader: true,
        key: 'actions',
        showDivider: true,
      },
      {
        allowsSorting: true,
        children: intl.formatMessage({id: 'account.billingHistory.table.title.invoiceDate'}),
        key: 'invoiceDate',
        width: calcWidth({default: '17.5%', poNumberDisplayed: '14%'}),
      },
      {
        allowsSorting: true,
        children: intl.formatMessage({id: 'account.billingHistory.table.title.amount'}),
        key: 'amount',
        width: calcWidth({default: '15%', poNumberDisplayed: '12%'}),
      },
    ];
    if (isPONumberColumnDisplayed) {
      columnsArray.push({
        children: intl.formatMessage({id: 'account.billingHistory.table.title.poNumber'}),
        key: 'poNumber',
        minWidth: '15%',
      });
    }
    columnsArray.push({
      children: intl.formatMessage({id: 'account.billingHistory.table.title.status'}),
      key: 'status',
      width: calcWidth({default: '18%', payNowDisplayed: '12%'}),
    });

    if (showPayNow) {
      columnsArray.push({
        'aria-label': intl.formatMessage({id: 'account.billingHistory.table.title.payNow'}),
        key: 'payNow',
        minWidth: '15%',
      });
    }
    if (feature.isEnabled('temp_invoice_column')) {
      columnsArray.splice(2, 0, {
        allowsSorting: true,
        children: intl.formatMessage({id: 'account.billingHistory.table.title.documentType'}),
        key: 'documentType',
        width: calcWidth({default: '18%', payNowDisplayed: '10%', poNumberDisplayed: '12%'}),
      });
    }

    return columnsArray;
  }, [intl, calcWidth, isPONumberColumnDisplayed, showPayNow]);

  const handlePayNowPressed = (event) => {
    onPayNowPressed?.({invoiceId: event.target.dataset.invoiceid});
  };

  return (
    <Table
      aria-label={intl.formatMessage({id: 'account.billingHistory.table.ariaLabel'})}
      sortDescriptor={SORT_DESCRIPTOR}
    >
      <TableHeader columns={columns}>
        {(column) => <Column data-testid={`column-${column.key}`} {...column} />}
      </TableHeader>
      <TableBody items={tableSectionState?.items} loadingState={isLoading ? 'loading' : 'idle'}>
        {/* eslint-disable-next-line @admin-tribe/admin-tribe/extract-large-computations -- inline jsx */}
        {(invoice) => (
          // Key is used to update table selection, which can be passed to make an API call with required data
          <Row key={`${invoice.externalContractId}:${invoice.id}:${invoice.documentType}`}>
            {/* eslint-disable-next-line @admin-tribe/admin-tribe/extract-large-computations, complexity -- inline jsx */}
            {(columnKey) => (
              <Cell data-testid={`cell-${columnKey}`}>
                {columnKey === 'id' && invoice[columnKey]}
                {columnKey === 'poNumber' &&
                  (invoice.isUnpaid() ? (
                    <Flex alignItems="center" data-testid="invoice-po-number">
                      <TruncatedText textContent={invoice.poNumber} />
                      <TooltipButton
                        buttonAriaLabel={intl.formatMessage({
                          id: 'account.billingHistory.table.title.poNumber.tooltip',
                        })}
                        data-invoiceid={invoice.id}
                        hoverText={intl.formatMessage({
                          id: 'account.billingHistory.table.title.poNumber.tooltip',
                        })}
                        isQuiet
                        onPress={() => onOpenEditPONumberPress(invoice)}
                        tooltipDelayMs={0}
                        // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- for cursor to match rest of Admin Console
                        UNSAFE_className={styles['billing-history-table-button']}
                        variant="action"
                      >
                        <EditIcon />
                      </TooltipButton>
                    </Flex>
                  ) : (
                    <TruncatedText textContent={invoice.poNumber} />
                  ))}
                {columnKey === 'status' && <InvoiceStatus invoice={invoice} />}
                {feature.isEnabled('temp_invoice_column') && columnKey === 'documentType' ? (
                  <TruncatedText
                    textContent={intl.formatMessage({
                      id: documentTypeContentMapping[invoice.documentType],
                    })}
                  />
                ) : null}
                {columnKey === 'invoiceDate' && (
                  <SafeFormattedDateAndTime value={invoice.dates?.invoiceDate} />
                )}

                {columnKey === 'amount' && (
                  <SafePrice
                    currency={invoice.currency}
                    isTaxInclusive
                    price={Number(invoice.total?.amountWithTax)}
                  />
                )}
                {columnKey === 'actions' && (
                  <BillingHistoryActionsMenu
                    invoice={invoice}
                    onDownloadInvoices={onDownloadInvoices}
                    onEmailInvoice={onOpenEmailInvoices}
                  />
                )}
                {columnKey === 'payNow' && invoice.isUnpaid() && (
                  <ActionButton
                    data-invoiceid={invoice.id}
                    data-testid="pay-now-btn"
                    onPress={handlePayNowPressed}
                    // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- for cursor to match rest of Admin Console
                    UNSAFE_className={styles['billing-history-table-button']}
                  >
                    {intl.formatMessage({id: 'account.billingHistory.table.payNow'})}
                  </ActionButton>
                )}
              </Cell>
            )}
          </Row>
        )}
      </TableBody>
    </Table>
  );
};

BillingHistoryTable.displayName = 'BillingHistoryTable';
BillingHistoryTable.propTypes = {
  /**
   * Whether the Table component should display the loading indicator or not
   */
  isLoading: PropTypes.bool,
  /**
   * Whether PO number column should be displayed or not. Sourced from contract.
   */
  isPONumberColumnDisplayed: PropTypes.bool,
  /**
   * Handler to download the invoices
   */
  onDownloadInvoices: PropTypes.func.isRequired,
  /**
   * Handler that opens the Edit PO Number modal.
   */
  onOpenEditPONumber: PropTypes.func,
  /**
   * Handler that opens the Email Invoices modal.
   */
  onOpenEmailInvoices: PropTypes.func.isRequired,
  /**
   * Function that allows access to the one time payment modal
   */
  onPayNowPressed: PropTypes.func,
};

export default BillingHistoryTable;
