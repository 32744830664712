import {feature} from '@admin-tribe/acsc';
import {useCallback, useReducer} from 'react';

import DirectoryEntity from 'common/entities/DirectoryEntity';
import rootStore from 'core/RootStore';
import useDirectory from 'features/settings/hooks/api/useDirectory';

const ACTIONS = {
  DATA_LOAD: 'dataLoad',
  DATA_LOAD_ERROR: 'dataLoadError',
  FINISH_DATA_LOAD: 'finishDataLoad',
};

/**
 * A reducer which handles the actions dispatch for the directory state
 */
const directoryStateReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.DATA_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case ACTIONS.DATA_LOAD_ERROR:
      return {
        ...state,
        hasLoadingError: true,
        isLoading: false,
      };
    case ACTIONS.FINISH_DATA_LOAD:
      return {
        ...state,
        directory: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

/**
 * A generic hook that can be used to load a directory
 */
const useDirectoryState = (initialDirectoryData) => {
  const {clearDirectoryCache, getDirectory, getDirectoryWithCertificates} = useDirectory();
  const [state, dispatch] = useReducer(directoryStateReducer, {
    directory: new DirectoryEntity(initialDirectoryData),
    hasLoadingError: false,
    isLoading: false,
  });

  const loadDirectoryWithCertificates = useCallback(
    async ({directoryId = state.directory?.id} = {}) => {
      dispatch({type: ACTIONS.DATA_LOAD});

      try {
        const directory = await getDirectoryWithCertificates({
          directoryId,
          orgId: rootStore.organizationStore.activeOrgId,
        });

        const directoryModel = new DirectoryEntity(directory);

        dispatch({payload: directoryModel, type: ACTIONS.FINISH_DATA_LOAD});
        return directoryModel;
      } catch (error) {
        dispatch({type: ACTIONS.DATA_LOAD_ERROR});
        return undefined;
      }
    },
    [getDirectoryWithCertificates, state.directory?.id]
  );

  const loadDirectory = useCallback(
    async ({directoryId = state.directory?.id} = {}) => {
      dispatch({type: ACTIONS.DATA_LOAD});

      try {
        const directory = await getDirectory({
          directoryId,
          orgId: rootStore.organizationStore.activeOrgId,
        });
        const directoryModel = new DirectoryEntity(directory);

        dispatch({payload: directoryModel, type: ACTIONS.FINISH_DATA_LOAD});

        return directoryModel;
      } catch (error) {
        dispatch({type: ACTIONS.DATA_LOAD_ERROR});
        return undefined;
      }
    },
    [getDirectory, state.directory?.id]
  );

  const reloadDirectory = useCallback(() => {
    clearDirectoryCache();

    return feature.isEnabled('temp_saml_certs') ? loadDirectoryWithCertificates() : loadDirectory();
  }, [clearDirectoryCache, loadDirectory, loadDirectoryWithCertificates]);

  return {loadDirectory, loadDirectoryWithCertificates, reloadDirectory, ...state};
};

export default useDirectoryState;
export {directoryStateReducer};
