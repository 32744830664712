import {Locale, log, navBus, toPandoraProductList} from '@admin-tribe/acsc';
import {
  NavigationAnchor,
  Page,
  PageActions,
  PageContent,
  PageHeader,
  PageInfoBar,
  showSuccess,
} from '@admin-tribe/acsc-ui';
import {ButtonGroup, Item, Link, TabList, TabPanels, Tabs} from '@adobe/react-spectrum';
import {USER_ROLES} from '@pandora/administration-core-types';
import {
  SUPPORT_TICKET_LIST_ENTITY_NAME,
  SUPPORT_TICKET_QUERY_PARAM,
  SUPPORT_TICKET_RECORD_TYPE,
  useSupportTicketListHook,
} from '@pandora/data-model-support-ticket';
import {useAuth} from '@pandora/react-auth-provider';
import {useContentEntry} from '@pandora/react-content-provider';
import {userRoleUtils} from '@pandora/react-data-model-member';
import {useIsMounted} from '@pandora/react-is-mounted';
import {
  SupportTicketListView,
  SupportTicketListViewContentModel,
  filterSupportTicketList,
} from '@pandora/react-support-ticket';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {generatePath, useLocation, useParams} from 'react-router-dom';

import useDocumentTitle from 'common/hooks/useDocumentTitle';
import rootStore from 'core/RootStore';
import SupportHighOverview from 'features/support/components/support-high-overview/SupportHighOverview';
import SupportTicketCreateButton from 'features/support/components/support-ticket-create-button/SupportTicketCreateButton';
import SupportTicketExport from 'features/support/components/support-ticket-export/SupportTicketExport';
import {getHrefForSupportTicketDetails} from 'features/support/routing/navigation-callbacks/navigationCallbacks';

import {
  PATH_EXPERT_SESSIONS_CLOSED,
  PATH_EXPERT_SESSIONS_OPEN,
  PATH_EXPERT_SESSION_DETAILS,
  PATH_SUPPORT_CASES_CLOSED,
  PATH_SUPPORT_CASES_OPEN,
  PATH_SUPPORT_CASE_DETAILS,
} from '../../routing/supportPaths';

const {organizationStore} = rootStore;

/**
 * Shows a tabbed view of open and closed Support Tickets
 */
const SupportTicketsPage = ({recordType}) => {
  const pathParams = useParams();
  const {pathname} = useLocation();
  const {getSupportTicketList} = useSupportTicketListHook();
  const {roles, userId} = useAuth().getUserProfile();
  const content = useContentEntry(SupportTicketListViewContentModel);
  const intl = useIntl();
  const isMounted = useIsMounted();
  const orgId = organizationStore.activeOrgId;
  const productList = toPandoraProductList(organizationStore.productList);
  const productSupportRoleProductIds = userRoleUtils.getTargetsByRole(
    USER_ROLES.PRODUCT_SUPPORT_ADMIN,
    orgId,
    roles
  );
  const isSupportCase = recordType === SUPPORT_TICKET_RECORD_TYPE.CASE;
  const isOrgOrSupportAdmin = userRoleUtils.anyOfForOrg(
    roles,
    [USER_ROLES.ORG_ADMIN, USER_ROLES.SUPPORT_ADMIN],
    orgId
  );
  const pageTitle = intl.formatMessage({
    id: `support.supportTicket.${isSupportCase ? 'supportCases' : 'expertSessions'}.title`,
  });
  useDocumentTitle({defaultTitle: pageTitle});

  const [closedSupportTicketList, setClosedSupportTicketList] = useState([]);
  const [openSupportTicketList, setOpenSupportTicketList] = useState([]);
  const [isServerError, setIsServerError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const getFilteredSupportTicketList = async (status) => {
    const supportTicketList = await getSupportTicketList({
      locale: Locale.get().activeLocaleCode,
      orgId,
      recordType: isSupportCase
        ? SUPPORT_TICKET_LIST_ENTITY_NAME.CASES
        : SUPPORT_TICKET_LIST_ENTITY_NAME.EXPERTS,
      status,
    });
    return filterSupportTicketList(
      !isSupportCase,
      isOrgOrSupportAdmin,
      productList,
      productSupportRoleProductIds,
      supportTicketList.items,
      userId
    );
  };

  // gets support ticket list
  useEffect(() => {
    setIsLoading(true);
    const updateSupportTicketList = async () => {
      try {
        if (isMounted) {
          setOpenSupportTicketList(
            await getFilteredSupportTicketList(SUPPORT_TICKET_QUERY_PARAM.ACTIVE)
          );
          setClosedSupportTicketList(
            await getFilteredSupportTicketList(SUPPORT_TICKET_QUERY_PARAM.INACTIVE)
          );
        }
      } catch (error) {
        log.error('Unable to fetch support ticket list', error);
        setIsServerError(true);
      } finally {
        setIsLoading(false);
      }
    };

    updateSupportTicketList();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- this needs to rerender when is not mounted and recordType changed
  }, [isMounted, recordType]);

  const supportTicketDetailsLink = useCallback(
    ({labelText, recordType: recordTypeForLink, ticketId}) => (
      <Link data-testid="support-ticket-link">
        <NavigationAnchor
          href={getHrefForSupportTicketDetails({recordType: recordTypeForLink, ticketId})}
        >
          {labelText}
        </NavigationAnchor>
      </Link>
    ),
    []
  );

  const tabs = useMemo(() => {
    const generateSupportTicketListView = ({isActiveList}) =>
      !isLoading && (
        <SupportTicketListView
          content={content}
          data-testid="support-ticket-list-view"
          getSupportTicketDetailsLink={supportTicketDetailsLink}
          productList={productList}
          queryParam={
            isActiveList ? SUPPORT_TICKET_QUERY_PARAM.ACTIVE : SUPPORT_TICKET_QUERY_PARAM.INACTIVE
          }
          recordType={recordType}
          supportTicketList={{
            items: isActiveList ? openSupportTicketList : closedSupportTicketList,
          }}
        />
      );

    return [
      {
        content: generateSupportTicketListView({isActiveList: true}),
        name: intl.formatMessage({id: 'support.supportTicket.open.title'}),
        pathname: generatePath(
          isSupportCase ? PATH_SUPPORT_CASES_OPEN : PATH_EXPERT_SESSIONS_OPEN,
          pathParams
        ),
      },
      {
        content: generateSupportTicketListView({isActiveList: false}),
        name: intl.formatMessage({id: 'support.supportTicket.closed.title'}),
        pathname: generatePath(
          isSupportCase ? PATH_SUPPORT_CASES_CLOSED : PATH_EXPERT_SESSIONS_CLOSED,
          pathParams
        ),
      },
    ];
  }, [
    closedSupportTicketList,
    content,
    intl,
    isLoading,
    isSupportCase,
    openSupportTicketList,
    pathParams,
    productList,
    recordType,
    supportTicketDetailsLink,
  ]);

  const onSupportTicketCreated = (ticketId) => {
    showSuccess(
      intl.formatMessage(
        {
          id: isSupportCase
            ? 'pandora.reactMiniAppSupportTicketContent.createCaseModalContent.supportCaseCreatingViewContentModel.caseCreated'
            : 'pandora.reactMiniAppSupportTicketContent.requestExpertSessionModalContentModel.expertSessionCreated',
        },
        {caseId: ticketId, expertSessionId: ticketId}
      )
    );
    navBus.navigate(
      generatePath(
        recordType === SUPPORT_TICKET_RECORD_TYPE.CASE
          ? PATH_SUPPORT_CASE_DETAILS
          : PATH_EXPERT_SESSION_DETAILS,
        {
          orgId: rootStore.organizationStore.activeOrgId,
          ticketId,
        }
      )
    );
  };

  // Find the key for the current url.
  // If not found it is a bug but default to the Tabs default.
  const selectedKey = useMemo(
    () => tabs.find((tab) => tab.pathname === pathname)?.pathname ?? null,
    [pathname, tabs]
  );

  const onChange = (path) => {
    if (path !== selectedKey) {
      navBus.navigate(path);
    }
  };

  return (
    <Page data-testid="support-tickets-page" isBumpered={isServerError} isLoading={isLoading}>
      {!isLoading && <PageHeader title={pageTitle} />}
      {!isLoading && (
        <PageActions>
          <ButtonGroup>
            <SupportTicketCreateButton
              onCreate={onSupportTicketCreated}
              recordType={recordType}
              variant="accent"
            />
            <SupportTicketExport
              recordType={
                isSupportCase
                  ? SUPPORT_TICKET_LIST_ENTITY_NAME.CASES
                  : SUPPORT_TICKET_LIST_ENTITY_NAME.EXPERTS
              }
            />
          </ButtonGroup>
        </PageActions>
      )}
      {!isLoading && (
        <PageInfoBar data-testid="support-ticket-high-overview">
          <SupportHighOverview
            closedCount={closedSupportTicketList.length}
            openCount={openSupportTicketList.length}
            recordType={recordType}
            totalCount={openSupportTicketList.length + closedSupportTicketList.length}
          />
        </PageInfoBar>
      )}
      {!isLoading && (
        <PageContent>
          <Tabs
            aria-label={intl.formatMessage({id: 'support.supportTicket.tabbedNav.ariaLabel'})}
            items={tabs}
            onSelectionChange={onChange}
            selectedKey={selectedKey}
          >
            <TabList>{(item) => <Item key={item.pathname}>{item.name}</Item>}</TabList>
            <TabPanels>{(item) => <Item key={item.pathname}>{item.content}</Item>}</TabPanels>
          </Tabs>
        </PageContent>
      )}
    </Page>
  );
};

SupportTicketsPage.propTypes = {
  /**
   * Used to get the type.
   */
  recordType: PropTypes.oneOf(Object.values(SUPPORT_TICKET_RECORD_TYPE)).isRequired,
};

export default SupportTicketsPage;
