import {navBus} from '@admin-tribe/acsc';
import {generatePath} from 'react-router-dom';

import rootStore from 'core/RootStore';
import {
  PATH_ACCOUNT_BILLING_HISTORY,
  PATH_ACCOUNT_OVERVIEW,
  PATH_ACCOUNT_ROOT,
} from 'features/account/routing/accountPaths';

const goTo = (path) => {
  navBus?.navigate(generatePath(path, {orgId: rootStore.organizationStore.activeOrgId}));
};

/**
 * @description Navigate to the Account overview page for the current organization
 */
const goToAccountOverview = () => goTo(PATH_ACCOUNT_OVERVIEW);

/**
 * @description Navigate to the root Account page for the current organization
 */
const goToAccountRoot = () => goTo(PATH_ACCOUNT_ROOT);

/**
 * @description Navigate to the Billing history page for the current organization
 */
const goToBillingHistory = () => goTo(PATH_ACCOUNT_BILLING_HISTORY);

/**
 * @description Navigate to the Invoice payment modal on the Account page
 *
 * @param {string} payInvoiceId - If provided, will navigate to the Invoice payment modal
 */
const goToPayInvoice = (payInvoiceId) => {
  navBus.navigate(
    `${generatePath(PATH_ACCOUNT_ROOT, {
      orgId: rootStore.organizationStore.activeOrgId,
    })}?payInvoice=${payInvoiceId}`
  );
};

export {goToAccountOverview, goToAccountRoot, goToBillingHistory, goToPayInvoice};
