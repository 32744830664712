import {InfoBar, InfoItem, InfoItemScorecard} from '@admin-tribe/acsc-ui';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

/**
 * A wrapper for SDL Product Details Page Header info bar
 */
const SharedDeviceLicenseProductPageInfoBar = ({
  totalLicensesCount,
  usedLicensesCount,
  totalDevicesCount,
  earliestExpiryDate,
}) => {
  const intl = useIntl();

  return (
    <InfoBar>
      <InfoItemScorecard
        label={intl.formatMessage({
          id: 'products.details.scoreCards.totalLicenses',
        })}
        value={totalLicensesCount}
      />
      <InfoItemScorecard
        label={intl.formatMessage({
          id: 'products.details.scoreCards.usedLicenses',
        })}
        value={usedLicensesCount}
      />
      {totalDevicesCount !== undefined && (
        <InfoItemScorecard
          label={intl.formatMessage({
            id: 'products.details.scoreCards.totalDevices',
          })}
          value={totalDevicesCount}
        />
      )}
      {earliestExpiryDate && (
        <InfoItem
          label={intl.formatMessage({
            id: 'products.details.scoreCards.expiryDate',
          })}
          value={earliestExpiryDate}
        />
      )}
    </InfoBar>
  );
};

SharedDeviceLicenseProductPageInfoBar.propTypes = {
  /**
   * The earliest expiry date. This will be initially undefined until it is resolved
   */
  earliestExpiryDate: PropTypes.string,

  /**
   * The total number of devices. This will be initially undefined until it is resolved
   */
  totalDevicesCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  /**
   * The total number of licenses. This will be initially undefined until it is resolved
   */
  totalLicensesCount: PropTypes.number,

  /**
   * The total number of used licenses. This will be initially undefined until it is resolved
   */
  usedLicensesCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
export default SharedDeviceLicenseProductPageInfoBar;
