import {feature} from '@admin-tribe/acsc';
import {showError as showErrorToast} from '@admin-tribe/acsc-ui';
import {useDialogContainer} from '@adobe/react-spectrum';
import {ModalAlertDialog, ModalContent} from '@pandora/react-modal-dialog';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {useDirectoryContext} from 'features/settings/components/directory/DirectoryContext';

const REMOVE_SYNC_ID = 'remove-sync-modal';

const RemoveSyncModal = observer(({clearCaches, onSyncRemoved}) => {
  const dialog = useDialogContainer();
  const intl = useIntl();
  const {directoryStore} = useDirectoryContext();

  const onConfirmClick = async () => {
    try {
      await directoryStore.sync.removeSync();
      clearCaches();
      dialog.dismiss();
      onSyncRemoved?.();
    } catch (error) {
      showErrorToast(
        intl.formatMessage({
          id: 'settings.sync.error',
        })
      );
    }
  };

  return (
    <ModalAlertDialog
      id={REMOVE_SYNC_ID}
      isLoading={directoryStore.sync.isLoading}
      // eslint-disable-next-line react/jsx-handler-names -- erroneous reporting
      onCancel={dialog.dismiss}
      onPrimaryAction={onConfirmClick}
      primaryActionLabel={intl.formatMessage({id: 'common.modal.buttons.remove'})}
      title={intl.formatMessage({id: 'settings.sync.removeSyncModal.title'})}
    >
      <ModalContent>
        <p>
          <FormattedMessage id="settings.sync.removeSyncModal.description" />
        </p>
        {feature.isEnabled('temp_reauthorize_provisioning_disclaimer') &&
          directoryStore.sync.isGoogle && (
            <p>
              <FormattedMessage
                id="settings.sync.removeSyncModal.disclaimer"
                values={{
                  b:
                    // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- not testing format message values function
                    // istanbul ignore next -- not testing this
                    (chunks) => <strong>{chunks}</strong>,
                }}
              />
            </p>
          )}
        {feature.isEnabled('temp_delete_autoprovisioning_disclaimer') &&
          directoryStore.sync.isGoogle && (
            <p>
              <FormattedMessage
                id="settings.sync.removeSyncModal.disclaimer.deleteConfig"
                values={{
                  b:
                    // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- not testing format message values function
                    // istanbul ignore next -- not testing this
                    (chunks) => <strong>{chunks}</strong>,
                }}
              />
            </p>
          )}
      </ModalContent>
    </ModalAlertDialog>
  );
});

RemoveSyncModal.propTypes = {
  clearCaches: PropTypes.func,
  onSyncRemoved: PropTypes.func,
};

export default RemoveSyncModal;
