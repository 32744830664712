import {ServerErrorIllustratedMessage} from '@admin-tribe/acsc-ui';
import {Content, Flex} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {EVENT_NAME} from '../SelfCancelAnalyticsUtils';
import {useSelfCancelAnalyticsContext} from '../components/self-cancel-analytics-context/SelfCancelAnalyticsContext';
import SelfCancelBaseModal from '../components/self-cancel-base-modal/SelfCancelBaseModal';

/**
 * Error step component shown when there is a server or client error.
 */
const ErrorStep = ({onDone}) => {
  const intl = useIntl();

  const {dispatchNavigationAnalyticsEvent} = useSelfCancelAnalyticsContext();

  const onCta = () => {
    dispatchNavigationAnalyticsEvent({clickType: EVENT_NAME.CLOSE});
    onDone?.();
  };

  return (
    <SelfCancelBaseModal
      ctaLabel={intl.formatMessage({id: 'common.modal.buttons.close'})}
      ctaVariant="secondary"
      onCta={onCta}
    >
      <Content>
        <Flex alignItems="center" direction="column" height="100%" justifyContent="center">
          <ServerErrorIllustratedMessage headingLevel={2} />
        </Flex>
      </Content>
    </SelfCancelBaseModal>
  );
};

ErrorStep.propTypes = {
  /**
   * Handler that is called when the user closes the modal.
   */
  onDone: PropTypes.func,
};

export default ErrorStep;
