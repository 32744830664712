// ***********************************************************************
// ADOBE CONFIDENTIAL
// ___________________
//
// Copyright 2024 Adobe
// All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains
// the property of Adobe and its suppliers, if any. The intellectual
// and technical concepts contained herein are proprietary to Adobe
// and its suppliers and are protected by all applicable intellectual
// property laws, including trade secret and copyright laws.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Adobe.
// ************************************************************************
import {log} from '@admin-tribe/acsc';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';

import {JsonPayloadProvider} from 'common/components/sophia/sophia-promo/JsonPayloadProvider';
import SophiaErrorBoundary from 'common/components/sophia/sophia-promo/SophiaErrorBoundary';
import SOPHIA_CONSTANTS from 'common/services/sophia/SophiaConstants';
import sophiaHelper from 'common/services/sophia/sophiaHelper';
import {mapPromoContent as transformContent} from 'common/services/sophia/utils/utils';
import SophiaRightRailPromoSection from 'features/overview/components/sophia-right-rail-section/SophiaRightRailPromoSection';

// eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- disabled for resolving merge conflicts
// istanbul ignore file
const SophiaRightRailPromoView = ({isHidden}) => {
  const [payload, setPayload] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const surfaceId = SOPHIA_CONSTANTS.SURFACE_ID.ONE_CONSOLE_MERCHANDISING;

  // call sophia to get the content
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        // Resolve the initial payload
        const resolver = () => sophiaHelper.getSophiaContent({surfaceId});
        const resolvedPayload = await resolver();

        // if no payload, campaign does not exist - do nothing - component will not render
        if (!resolvedPayload) {
          return;
        }

        // check for valid payload data
        if (!resolvedPayload?.items?.[0]?.content) {
          log.error('Sophia campaign exists but is not correct format for surfaceId:', surfaceId);
          return;
        }

        // transform the payload
        const transform = (input) => transformContent(JSON.parse(input.items[0].content));
        const transformedPayload = transform(resolvedPayload);

        setPayload(transformedPayload);
      } catch (error_) {
        setError(error_);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [surfaceId]);

  if (loading) {
    return null;
  }

  if (error) {
    log.error('Error resolving or transforming payload:', error.message || error);
    return null;
  }

  return (
    <SophiaErrorBoundary>
      <JsonPayloadProvider payload={payload}>
        <SophiaRightRailPromoSection isHidden={isHidden} />
      </JsonPayloadProvider>
    </SophiaErrorBoundary>
  );
};

SophiaRightRailPromoView.propTypes = {
  /**
   * Responsive props instructing merchandising section how to show/hide at
   * various display sizes. Optional, default is to show all the time
   */
  isHidden: PropTypes.shape({
    base: PropTypes.bool,
    L: PropTypes.bool,
    M: PropTypes.bool,
    S: PropTypes.bool,
  }),
};

export default SophiaRightRailPromoView;
