// eslint-disable-next-line import/extensions -- fake bootstrap
import './fakeLoad.js';
import './colors.pcss';
import './media-breakpoints.pcss';
import {log} from '@admin-tribe/acsc';
import React from 'react';
import ReactDOM from 'react-dom';
import '@admin-tribe/acsc-ui/dist/acsc-ui.css';

import {checkForExternalLogin} from 'core/settings/utils/externalLoginUtils';

import App from './App';

import './overrides.pcss';

// Check for an external login before the application is ready.
// We could have used  a router loader for a redirect but there
// are a couple of issues with that:
// 1. The redirect loader enters an infinite loop because whenever we redirect
//    to the correct page the loaders will re-run.
// 2. In testing there were times when the redirect got stuck and never loaded
//    the page until a refresh was done.
checkForExternalLogin();

(() => {
  try {
    // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- using window obj
    const currentUrl = window.location.href;
    const decodedUrl = decodeURIComponent(currentUrl);
    // eslint-disable-next-line no-restricted-globals -- decodeURIComponent is used
    history.pushState(null, '', decodedUrl);
    // eslint-disable-next-line react/no-deprecated -- main entry point
    ReactDOM.render(<App />, document.querySelector('#app-main'));
  } catch (error) {
    log.error('Error rendering the app', error);
  }
})();
