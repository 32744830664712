import {navBus} from '@admin-tribe/acsc';
import {ActionButton, Link, View} from '@adobe/react-spectrum';
import {SPECTRUM_LOCALE_TO_URL_SUFFIX} from '@pandora/administration-types';
import PropTypes from 'prop-types';
import React from 'react';
import {generatePath} from 'react-router-dom';

import rootStore from 'core/RootStore';
import {PATH_SETTINGS_IDENTITY} from 'features/settings/routing/settingsPaths';
import help from 'shell/help/constants/help';

import styles from '../ConstructContent.pcss';

const {LINK_TARGET_TYPE, LINK_TYPE} = help;

const CallToActionAnchorContent = ({intl, itemIndex, linkItem, linkType, locale, topicIndex}) => {
  const getGoUrl = (name) => {
    const goUrlLanguage = SPECTRUM_LOCALE_TO_URL_SUFFIX[locale] || locale;
    return `https://www.adobe.com/go/${name}_${goUrlLanguage}`;
  };

  const getUnlocalizedGoUrl = (name) => `https://www.adobe.com/go/${name}`;

  const onPress = (pressedLinkItem) => {
    const {locator, params = {}, target} = pressedLinkItem;
    switch (target) {
      case LINK_TARGET_TYPE.APPLICATION:
        if (locator === 'settings.identity') {
          navBus.navigate(
            generatePath(PATH_SETTINGS_IDENTITY, {
              orgId: rootStore.organizationStore.activeOrgId,
              ...params,
            })
          );
        } else {
          navBus.navigate(
            generatePath(locator, {
              orgId: rootStore.organizationStore.activeOrgId,
              ...params,
            })
          );
        }
        break;
      case LINK_TARGET_TYPE.EXTERNAL:
        // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- window will always be defined
        window.open(locator, '_blank');
        break;
      case LINK_TARGET_TYPE.GO_URL:
        // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- window will always be defined
        window.open(getGoUrl(locator), '_blank');
        break;
      case LINK_TARGET_TYPE.GO_URL_UNLOCALIZED:
        // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- window will always be defined
        window.open(getUnlocalizedGoUrl(locator), '_blank');
        break;
      case LINK_TARGET_TYPE.HELP:
        rootStore.helpStore.goToTopic(locator);
        break;
      default:
    }
  };

  const {key: linkItemKey} = linkItem;
  const text = linkItemKey ? intl.formatMessage({id: linkItemKey}) : '';

  return (
    <View
      key={`anchor-${topicIndex}${itemIndex}`}
      data-testid="accordion-anchor"
      UNSAFE_style={{textAlign: linkType === LINK_TYPE.BUTTON ? 'right' : 'unset'}}
    >
      {linkType === LINK_TYPE.BUTTON && (
        <ActionButton
          key={`button-${topicIndex}${itemIndex}`}
          data-testid="accordion-callback-action-btn"
          onPress={() => onPress(linkItem)}
          // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- for custom style btn
          UNSAFE_className={styles['accordion-content-btn']}
          UNSAFE_style={{width: 'fit-content'}}
        >
          {text}
        </ActionButton>
      )}
      {linkType === LINK_TYPE.TEXT && (
        <Link
          key={`link-${topicIndex}${itemIndex}`}
          data-testid="accordion-link"
          onPress={() => onPress(linkItem)}
        >
          {text}
        </Link>
      )}
    </View>
  );
};

CallToActionAnchorContent.propTypes = {
  /**
   * Reference to intl for translations
   */
  intl: PropTypes.shape({formatMessage: PropTypes.func.isRequired}),
  /**
   * The content item index
   */
  itemIndex: PropTypes.number.isRequired,
  /**
   * The help item link to construct CTA content
   */
  linkItem: PropTypes.shape({
    key: PropTypes.string,
    locator: PropTypes.string,
    params: PropTypes.objectOf(PropTypes.string),
    target: PropTypes.string.isRequired,
  }).isRequired,
  /**
   * The link type
   */
  linkType: PropTypes.string.isRequired,
  /**
   * The two character code representing the current localization state
   */
  locale: PropTypes.string,
  /**
   * The topic item index
   */
  topicIndex: PropTypes.number.isRequired,
};

export default CallToActionAnchorContent;
