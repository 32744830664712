import {navBus} from '@admin-tribe/acsc';
import {Item, TabList, TabPanels, Tabs} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {matchPath, useLocation} from 'react-router-dom';

import {PATH_STORAGE_INDIVIDUAL_USER_FOLDERS_DELETED} from 'features/storage/routing/storagePaths';

import {TAB_KEYS} from './IndividualUserFoldersTabsConstants';

const determineKeyByPath = (location) => {
  if (matchPath(PATH_STORAGE_INDIVIDUAL_USER_FOLDERS_DELETED, location.pathname)) {
    return TAB_KEYS.DELETED;
  }

  return TAB_KEYS.FOLDERS;
};

const findTabByKey = (tabs, key) => tabs.find((tab) => tab.key === key);

/**
 * The tabs containing the sub-pages for the `<IndividualUserFoldersPage />`.
 * @param {object} param0
 * @param {object[]} param0.tabs - The tab data
 * @returns
 */
const IndividualUserFoldersTabs = ({tabs}) => {
  const intl = useIntl();
  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState(determineKeyByPath(location));

  /** Keep the currently selected tab in sync with the URL */
  useEffect(() => {
    setSelectedKey(determineKeyByPath(location));
  }, [location]);

  const onChange = useCallback(
    (key) => {
      if (key === selectedKey) {
        // Currently on the tab that was clicked, don't navigate
        return;
      }

      // Just modify the URL, the key will get updated in the useEffect above
      navBus.pushState({url: findTabByKey(tabs, key).pathname});
    },
    [selectedKey, tabs]
  );

  return (
    <Tabs
      aria-label={intl.formatMessage({
        id: 'storage.individualUserFolders.tabbedNav.label',
      })}
      onSelectionChange={onChange}
      selectedKey={selectedKey}
    >
      <TabList>
        {tabs.map((tab) => (
          <Item key={tab.key}>{tab.name}</Item>
        ))}
      </TabList>
      <TabPanels>
        {tabs.map((tab) => (
          <Item key={tab.key}>{tab.content}</Item>
        ))}
      </TabPanels>
    </Tabs>
  );
};

IndividualUserFoldersTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.node.isRequired,
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      pathname: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default IndividualUserFoldersTabs;
