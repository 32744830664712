import {GoUrl} from '@admin-tribe/acsc-ui';
import {Text, useDialogContainer} from '@adobe/react-spectrum';
import {ModalAlertDialog} from '@pandora/react-modal-dialog';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

const AutoAccountCreationToggleWarningModal = ({onConfirm}) => {
  const dialog = useDialogContainer();
  const intl = useIntl();

  return (
    <ModalAlertDialog
      cancelLabel={intl.formatMessage({
        id: 'settings.autoAccountCreationToggleWarningModal.buttons.close',
      })}
      onClose={() => dialog.dismiss()}
      onPrimaryAction={onConfirm}
      primaryActionLabel={intl.formatMessage({
        id: 'settings.autoAccountCreationToggleWarningModal.buttons.disable',
      })}
      title={intl.formatMessage({id: 'settings.autoAccountCreationToggleWarningModal.title'})}
    >
      <Text>
        <FormattedMessage
          id="settings.autoAccountCreationToggleWarningModal.description"
          values={{
            goUrl: (linkText) => <GoUrl name="admin_auto_account_creation">{linkText}</GoUrl>,
          }}
        />
      </Text>
    </ModalAlertDialog>
  );
};

AutoAccountCreationToggleWarningModal.propTypes = {
  onConfirm: PropTypes.func,
};

export default AutoAccountCreationToggleWarningModal;
