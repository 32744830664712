import {
  CONTRACT_BILLING_FREQUENCY,
  FULFILLABLE_ITEM_CHARGING_UNIT,
  Product,
  TAX_TERM,
} from '@admin-tribe/acsc';
import {View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './SelfCancelCart.pcss';
import SelfCancelCartItem from './SelfCancelCartItem';
import SelfCancelCartTotal from './SelfCancelCartTotal';

/**
 * Cancellation cart which consists of individual items/products to be removed from the contract
 * and optionally cart total with a subtotal and total.
 */
const SelfCancelCart = ({currency, items, recurrenceTerm, total, withSubtotalPerItem}) => (
  <View
    data-testid="self-cancel-cart"
    // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- to add box shadow
    UNSAFE_className={styles['self-cancel-cart']}
  >
    {items?.map(
      ({
        product,
        cancellingCount,
        cancellingTotal,
        currentCount,
        currentTotal,
        discountPercentage,
        discountPricePerUnit,
        offerId,
        perUnit,
        pricePerUnit,
        remainingCount,
        remainingTotal,
      }) => (
        <SelfCancelCartItem
          key={`${product?.id}-${offerId}`}
          cancellingCount={cancellingCount}
          cancellingTotal={cancellingTotal}
          currency={currency}
          currentCount={currentCount}
          currentTotal={currentTotal}
          discountPercentage={discountPercentage}
          discountPricePerUnit={discountPricePerUnit}
          perUnit={perUnit}
          pricePerUnit={pricePerUnit}
          product={product}
          recurrenceTerm={recurrenceTerm}
          remainingCount={remainingCount}
          remainingTotal={remainingTotal}
          withSubtotalPerItem={withSubtotalPerItem}
        />
      )
    )}
    {total && (
      <SelfCancelCartTotal
        afterDiscountPeriodTotal={total.afterDiscountPeriodTotal}
        currency={currency}
        discountDuration={total.discountDuration}
        discountNextBillingDate={total.discountNextBillingDate}
        discountTotal={total.discountTotal}
        isMultiTaxRate={total.isMultiTaxRate}
        recurrenceTerm={recurrenceTerm}
        taxRate={total.taxRate}
        taxTerm={total.taxTerm}
        totalDiscountPercentage={total.totalDiscountPercentage}
        totalTax={total.totalTax}
        totalWithoutTax={total.totalWithoutTax}
        totalWithTax={total.totalWithTax}
      />
    )}
  </View>
);

SelfCancelCart.propTypes = {
  /**
   * The currency object where formatString and usePrecision are provided
   */
  currency: PropTypes.shape({
    /**
     * The format string in which to display the currency
     */
    formatString: PropTypes.string.isRequired,
    /**
     * True to include decimal value of the currency, false to only use whole values
     */
    usePrecision: PropTypes.bool,
  }).isRequired,
  /**
   * An array with canceling products
   */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * The number of items of the product to be cancelled
       */
      cancellingCount: PropTypes.number,
      /**
       * The decimal price amount of the items being cancelled to be taken off of the contract
       */
      cancellingTotal: PropTypes.number,
      /**
       * The current count of product units available to be cancelled
       */
      currentCount: PropTypes.number.isRequired,
      /**
       * The current cost of an item billed to the contract prior to cancellation
       */
      currentTotal: PropTypes.number,
      /**
       * Discount percentage provided for the product
       */
      discountPercentage: PropTypes.number,
      /**
       * Discount price provided for the product in decimal form
       */
      discountPricePerUnit: PropTypes.number,
      /**
       * The unit of the item that is being cancelled (i.e. Licenses)
       */
      perUnit: PropTypes.oneOf(Object.values(FULFILLABLE_ITEM_CHARGING_UNIT)).isRequired,
      /**
       * The price per unit in decimal form
       */
      pricePerUnit: PropTypes.number.isRequired,
      /**
       * The product model of the item being cancelled
       */
      product: PropTypes.instanceOf(Product).isRequired,
      /**
       * The remaining number of units after cancellation
       */
      remainingCount: PropTypes.number,
      /**
       * The price billed to the contract after units are removed
       */
      remainingTotal: PropTypes.number,
    })
  ).isRequired,
  /**
   * The billing recurrence of the contract (e.g. `/mo` or `/yr`)
   */
  recurrenceTerm: PropTypes.oneOf(Object.values(CONTRACT_BILLING_FREQUENCY)),
  /**
   * An object with data used to display cart total
   */
  total: PropTypes.shape({
    /**
     * Total after discount period
     */
    afterDiscountPeriodTotal: PropTypes.number,
    /**
     * The number of months the discount will be applied for
     */
    discountDuration: PropTypes.number,
    /**
     * The end date of the discount period
     */
    discountNextBillingDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.instanceOf(Date),
    ]),
    /**
     * Total discount/savings amount
     */
    discountTotal: PropTypes.number,
    /**
     * Whether or not the cart items have different tax rates
     */
    isMultiTaxRate: PropTypes.bool,
    /**
     * The tax rate
     */
    taxRate: PropTypes.number,
    /**
     * The text to be used in the suffix which indicates whether the
     * price is inclusive or exclusive of tax
     */
    taxTerm: PropTypes.oneOf(Object.values(TAX_TERM)).isRequired,
    /**
     * Total percentage of the discount provided for the product
     */
    totalDiscountPercentage: PropTypes.number,
    /**
     * The tax amount/total in decimal form
     */
    totalTax: PropTypes.number.isRequired,
    /**
     * The price billed to the contract after units are removed in decimal form
     */
    totalWithoutTax: PropTypes.number.isRequired,
    /**
     * The total for all remaining licenses with tax total included in decimal form
     */
    totalWithTax: PropTypes.number.isRequired,
  }),
  /**
   * Whether to show subtotal and Cancelling Details accordion section. per line item.
   * Defaults to false.
   */
  withSubtotalPerItem: PropTypes.bool,
};

export default SelfCancelCart;
