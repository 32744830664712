import {formatAnalyticsDate} from '@admin-tribe/acsc';
import get from 'lodash/get';
import set from 'lodash/set';

import {setProperty} from '../aepAnalytics';

/**
 *
 * @param {*} gainsightEventDescriptor
 */
export default function gainsightEventTranslator(gainsightEventDescriptor) {
  const gainsightEvent = {engagement: {}, step: {}};

  // Copy all descriptor properties into the analytics event. The property names
  // between the objects do not change.
  Object.assign(gainsightEvent.engagement, get(gainsightEventDescriptor, 'engagement'));
  Object.assign(gainsightEvent.step, get(gainsightEventDescriptor, 'step'));
  set(gainsightEvent, 'url', get(gainsightEventDescriptor, 'url'));

  // Serialize the unix timestamp into a format consumable by analytics
  const executionTimestamp = get(gainsightEvent, 'engagement.executionTimestamp');
  if (Number.isFinite(executionTimestamp)) {
    const formattedDate = formatAnalyticsDate(new Date(executionTimestamp));
    set(gainsightEvent, 'engagement.executionTimestamp', formattedDate);
  } else {
    // The value is not in the expected format for analytics, so remove it
    set(gainsightEvent, 'engagement.executionTimestamp', undefined);
  }

  setProperty('gainsightEvent', gainsightEvent);
}
