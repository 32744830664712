import {
  AuthenticatedUser,
  ORGANIZATION_MARKET_SEGMENT,
  OrganizationLocale,
  feature,
} from '@admin-tribe/acsc';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useState} from 'react';

import rootStore from 'core/RootStore';
import {mustAcceptContractTerms} from 'core/organizations/access/organizationAccess';
import {useAuthentication} from 'core/providers/authentication/AuthenticationProvider';
import {useConfiguration} from 'core/providers/configuration/ConfigurationProvider';

import {
  configure as configureTermsRedirect,
  redirectToAcceptTC,
  redirectUrlToAcceptEducationalTC,
} from '../../../features/termsRedirect/termsRedirect';
import {useAppLoadingOverlay} from '../AppLoadingOverlayProvider';

import OrgSwitcher from './OrgSwitcher';
import {useOrganization} from './OrganizationProvider';

/**
 * OrganizationSwitcherLoader
 * @param {ReactNode} children
 * @returns {ReactNode} The wrapped children components.
 */
const OrganizationSwitcherLoader = ({children}) => {
  const {organizationsLoaded} = useOrganization();
  const {hideLoadingOverlay} = useAppLoadingOverlay();
  const [showOrgSwitcher, setShowOrgSwitcher] = useState(false);
  const {profile} = useAuthentication();
  const {configuration} = useConfiguration();
  const {contractsStore, organizationStore} = rootStore;

  const setTermsRedirectUrl = useCallback(() => {
    if (feature.isEnabled('temp_terms_redirect')) {
      const termsRedirectUrl = configuration?.services.termsRedirect;
      configureTermsRedirect(termsRedirectUrl);
    }
  }, [configuration]);

  // this method stores accept later info if found in query param saveIfAcceptLaterForContractTnc
  // then check if current user-org combination has opted of accept later for all contracts with new tnc
  const isAllTncContractsOptedAcceptLater = useCallback(
    (contracts) => {
      if (!profile) return false;
      // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- window is available
      contractsStore.saveIfAcceptLaterForContractTnc(contracts, window.location, profile.userId);
      return contractsStore.isContractsWithAcceptLater(contracts, contracts.orgId, profile.userId);
    },
    [profile, contractsStore]
  );

  const mustAcceptEduTerms = useCallback(() => {
    const organization = organizationStore.activeOrg;

    if (!organization) {
      return false;
    }

    const eduMarketSegment =
      get(organization, 'marketSegment', '') === ORGANIZATION_MARKET_SEGMENT.EDUCATION;
    const marketSubSegments = get(organization, 'marketSubsegments', []);
    const termsAcceptances = get(organization, 'termsAcceptances', []);
    const isOrgAdmin = AuthenticatedUser.get()
      .getRoles()
      .isOrgAdminForOrg(rootStore.organizationStore.activeOrg.id);

    const checkIfTermsAcceptanceValid =
      !isEmpty(termsAcceptances) &&
      termsAcceptances.map((termsAcceptance) => termsAcceptance.status).includes('ACCEPTED');
    return (
      isOrgAdmin && eduMarketSegment && (isEmpty(marketSubSegments) || !checkIfTermsAcceptanceValid)
    );
  }, [organizationStore]);

  const checkAcceptTC = useCallback(async () => {
    try {
      const contractList = organizationStore.contractList;
      if (
        feature.isEnabled('temp_terms_redirect') &&
        mustAcceptContractTerms(contractList) &&
        !(
          feature.isEnabled('temp_delegate_without_provisioning_pending_tc') &&
          isAllTncContractsOptedAcceptLater(contractList)
        )
      ) {
        redirectToAcceptTC({orgId: organizationStore.activeOrg.id});
      } else if (feature.isEnabled('temp_pa_7320_edu_terms_mini_app') && mustAcceptEduTerms()) {
        const localeObj = await OrganizationLocale.get({
          orgId: rootStore.organizationStore.activeOrg.id,
        });
        redirectUrlToAcceptEducationalTC(
          rootStore.organizationStore.activeOrg.id,
          get(localeObj, 'locale')
        );
      }
    } catch (error) {
      setShowOrgSwitcher(true);
      hideLoadingOverlay();
      // setHasError(true); TODO: is this needed?
    }
  }, [
    organizationStore,
    isAllTncContractsOptedAcceptLater,
    hideLoadingOverlay,
    mustAcceptEduTerms,
  ]);

  // Check org migration status on org change
  useEffect(() => {
    if (organizationsLoaded) {
      setTermsRedirectUrl();
      checkAcceptTC();
    }
  }, [checkAcceptTC, organizationsLoaded, setTermsRedirectUrl]);

  if (organizationsLoaded) {
    if (showOrgSwitcher) {
      return <OrgSwitcher />;
    }

    checkAcceptTC();
    return children;
  }
  return null;
};

OrganizationSwitcherLoader.propTypes = {
  children: PropTypes.node.isRequired,
};

export default OrganizationSwitcherLoader;
