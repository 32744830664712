import {showError as showErrorToast, showSuccess as showSuccessToast} from '@admin-tribe/acsc-ui';
import {Text, useDialogContainer} from '@adobe/react-spectrum';
import {ModalAlertDialog} from '@pandora/react-modal-dialog';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {useTrusteeListContext} from 'features/settings/common/components/trustee-list-context/TrusteeListContext';
import useTrustList from 'features/settings/hooks/api/useTrustList';

const NotifyAdminsConfirmationModal = ({trustee}) => {
  const intl = useIntl();
  const dialog = useDialogContainer();
  const [isLoading, setIsLoading] = useState(false);
  const {saveNotificationsValue} = useTrustList();
  const {reloadTrusteeList} = useTrusteeListContext();

  // If notification are currently off then the confirmation modal is for turning them on
  const isTurnOnMode = trustee.notifyAdmins === false;

  const confirmButtonLabelId = isTurnOnMode
    ? 'settings.trusteeTable.notifyAdminModal.turnOn.confirmButton'
    : 'settings.trusteeTable.notifyAdminModal.turnOff.confirmButton';

  const successToastLabelId = isTurnOnMode
    ? 'settings.trusteeTable.notifyAdminModal.toast.turnOn.success'
    : 'settings.trusteeTable.notifyAdminModal.toast.turnOff.success';

  const modalTitleLabelId = isTurnOnMode
    ? 'settings.trusteeTable.notifyAdminModal.turnOn.title'
    : 'settings.trusteeTable.notifyAdminModal.turnOff.title';

  const modalTextLabelId = isTurnOnMode
    ? 'settings.trusteeTable.notifyAdminModal.turnOn.text'
    : 'settings.trusteeTable.notifyAdminModal.turnOff.text';

  const handleNotifyAdmins = async () => {
    setIsLoading(true);
    try {
      await saveNotificationsValue({
        directoryId: trustee.directoryId,
        // Toggle the current notifyAdmins value
        notifyAdmins: !trustee.notifyAdmins,
        trusteeOrgId: trustee.trusteeOrgId,
      });

      showSuccessToast(
        intl.formatMessage(
          {
            id: successToastLabelId,
          },
          {
            directoryName: trustee.directoryName,
          }
        )
      );

      reloadTrusteeList();

      dialog.dismiss();
    } catch (error) {
      showErrorToast(
        intl.formatMessage({id: 'settings.trusteeTable.notifyAdminModal.toast.error'})
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalAlertDialog
      cancelLabel={intl.formatMessage({
        id: 'settings.trusteeTable.notifyAdminModal.cancelButton',
      })}
      isLoading={isLoading}
      onClose={() => dialog.dismiss()}
      onPrimaryAction={handleNotifyAdmins}
      primaryActionLabel={intl.formatMessage({
        id: confirmButtonLabelId,
      })}
      title={intl.formatMessage({id: modalTitleLabelId})}
      variant="confirmation"
    >
      <Text>
        <FormattedMessage id={modalTextLabelId} />
      </Text>
    </ModalAlertDialog>
  );
};

NotifyAdminsConfirmationModal.displayName = 'NotifyAdminsConfirmationModal';

NotifyAdminsConfirmationModal.propTypes = {
  trustee: PropTypes.shape({
    /**
     * The ID of the directory
     */
    directoryId: PropTypes.string.isRequired,
    /**
     * The name of the directory
     */
    directoryName: PropTypes.string.isRequired,
    /**
     * Denotes if the admin should be notified via email
     * when new users are created by administrators
     * in this trustee organization.
     */
    notifyAdmins: PropTypes.bool.isRequired,
    /**
     * The ID of the Trustee Org
     */
    trusteeOrgId: PropTypes.string.isRequired,
    /**
     * The name of the Trustee Org
     */
    trusteeOrgName: PropTypes.string,
    /**
     * The email of the org admin
     */
    trusteeOrgSysAdminEmail: PropTypes.string,
  }),
};

export default NotifyAdminsConfirmationModal;
