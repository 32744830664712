import {DeviceAction} from '@pandora/react-data-source-daco';

const BUTTON_VARIANT = {
  [DeviceAction.ACTIVATE]: 'cta',
  [DeviceAction.BLOCK]: 'negative',
  [DeviceAction.REMOVE]: 'negative',
  [DeviceAction.RECOVER_ALL]: 'cta',
};

const UPDATE_ACTIONS = [DeviceAction.ACTIVATE, DeviceAction.BLOCK, DeviceAction.RECOVER_ALL];

export {BUTTON_VARIANT, UPDATE_ACTIONS};
