import {dispatchUiEventAnalytics} from '@admin-tribe/acsc';
import {useState} from 'react';

/**
 * @description Hook to use REBA (React Edit Billing App) modal
 */
const useEditBillingModal = ({invoiceId = ''} = {}) => {
  const [invoiceIdToPay, setInvoiceIdToPay] = useState(invoiceId);
  const [showEditBillingModal, setShowEditBillingModal] = useState(false);

  const openEditBillingModal = () => {
    setShowEditBillingModal(true);
    dispatchUiEventAnalytics({
      eventAction: 'click',
      eventName: 'editBillingReba',
    });
  };

  const closeEditBillingModal = () => {
    setShowEditBillingModal(false);
  };

  return {
    closeEditBillingModal,
    invoiceIdToPay,
    openEditBillingModal,
    setInvoiceIdToPay,
    showEditBillingModal,
  };
};

export default useEditBillingModal;
