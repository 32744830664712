import {feature} from '@admin-tribe/acsc';

import auth from '../../services/auth';

/**
 * @description Object map from jilCode to stockType.
 */
const FI_CODE_TO_STOCK_TYPE_MAP = {
  stock_credit: 'credit',
  stock_image: 'image',
  stock_premium_credits: 'credit_premium',
  stock_standard_credits: 'credit_standard',
  stock_universal_credits: 'credit_universal',
};

/**
 * @description Method to initialize the stock scorecard context.
 *
 * @param {Object} options The options to init with.
 * @param {Boolean} options.isOrgOrProductAdmin The flag for org admin or product admin
 * @param {Array<Object>} options.quotas The array of quota object
 * @param {String} options.variant The stock scorecard variant
 * @returns {Array} an array of the configured quotas
 */
function initializeStockScorecard({intl, isOrgOrProductAdmin, quotas, variant} = {}) {
  const response = [];
  if (!quotas || quotas.length === 0) return response;
  return processState({intl, isOrgOrProductAdmin, quotas, variant});
}

/**
 * @description Method to process the stock scorecard context.
 *
 * @param {Object} options The options to init with.
 * @param {Object} options.intl The React intl object
 * @param {Boolean} options.isOrgOrProductAdmin The flag for org admin or product admin
 * @param {Array<Object>} options.quotas The array of quota object
 * @param {String} options.variant The stock scorecard variant
 * @returns {Array} an array of the configured quotas
 */
function processState({intl, isOrgOrProductAdmin, quotas, variant}) {
  let response = [];
  if (variant === 'account') {
    response = setupScorecard({
      isOrgOrProductAdmin,
      quotas,
      showTotal: true,
    });
  } else if (variant === 'overview') {
    response = setupScorecard({
      isOrgOrProductAdmin,
      quotas,
      showAssigned: true,
      showTotal: true,
    });
  } else if (isOrgOrProductAdmin) {
    response = setupDefaultVariantForOrgOrProductAdmin({intl, quotas});
  } else if (auth.isUserProfileAdmin()) {
    response = setupDefaultVariantForProfileAdmin({intl, quotas});
  }
  return response;
}

/**
 * @description Method to set up the scorecard.
 *
 * @param {Object} options The options to init with.
 * @param {Boolean} options.isOrgOrProductAdmin The flag for org admin or product admin.
 * @param {Array<Object>} options.quotas The array of quota object
 * @param {Boolean} options.showAssigned The flag for the assigned licenses.
 * @param {Boolean} options.showTotal The flag for the total quantity to be fetched.
 * @returns {Array} an array of the configured quotas.
 */
function setupScorecard({isOrgOrProductAdmin, quotas, showAssigned, showTotal}) {
  return quotas.map((quota) => {
    const response = {type: quota.type};
    if (showAssigned && isOrgOrProductAdmin) {
      response.score = quota.distributedQuantity;
      response.consumed = quota.consumedQuantity;
    } else {
      response.score = quota.consumedQuantity;
    }
    if (quota.totalQuantity > 0 && showTotal) {
      response.total = quota.totalQuantity;
    }
    return response;
  });
}

function setupDefaultVariantForOrgOrProductAdmin({intl, quotas}) {
  return quotas.map((quota) => {
    const response = {type: quota.type};
    const unit = getTranslatedUnitForStockType({intl, quota});

    response.scorecards = [];
    if (!isPARStockQuota(quota)) {
      response.scorecards.push({
        label: intl.formatMessage({id: 'products.details.stock.scorecards.stock.total'}, {unit}),
        value: quota.totalQuantity,
      });
    }

    if (feature.isEnabled('temp_ctir_18957') && quota.distributedQuantity !== undefined) {
      response.scorecards.push({
        label: intl.formatMessage({id: 'products.details.stock.scorecards.stock.assigned'}, {unit}),
        value: quota.distributedQuantity,
      });
    }

    response.scorecards.push({
      label: intl.formatMessage({id: 'products.details.stock.scorecards.stock.used'}, {unit}),
      value: quota.consumedQuantity,
    });

    return response;
  });
}

function setupDefaultVariantForProfileAdmin({intl, quotas}) {
  return quotas.map((quota) => {
    const response = {type: quota.type};

    const unit = getTranslatedUnitForStockType({intl, quota});
    response.scorecards = [
      {
        label: intl.formatMessage({id: 'products.details.stock.scorecards.stock.used'}, {unit}),
        value: quota.consumedQuantity,
      },
    ];

    if (quota.type === 'credit' || quota.type === 'image' || quota.type === 'video') {
      response.scorecards.push({
        label: intl.formatMessage(
          {id: 'products.details.stock.scorecards.stock.remaining'},
          {unit}
        ),
        value: quota.remainingQuantity,
      });
    }

    return response;
  });
}

/**
 * @description Method to initialize the stock scorecard context.
 *
 * @param {Array} fulfilledItems The fulfilledItems.
 * @returns {Array} an array of objects with 0'd values.
 */
function generateZeroedQuotas(fulfilledItems) {
  return fulfilledItems
    .filter((item) =>
      [
        'stock_credit',
        'stock_image',
        'stock_standard_credits',
        'stock_premium_credits',
        'stock_universal_credits',
      ].includes(item.code)
    )
    .map((foundItem) => ({
      consumedQuantity: 0,
      totalQuantity:
        foundItem.chargingModel.cap === 'UNLIMITED'
          ? -1 // -1 indicates PAR
          : foundItem.chargingModel.cap,
      type: FI_CODE_TO_STOCK_TYPE_MAP[foundItem.code],
    }));
}

/** Private methods */

function isPARStockQuota(quota) {
  return quota.totalQuantity < 0;
}

function getTranslatedUnitForStockType({intl, quota}) {
  switch (quota.type) {
    case 'credit_premium':
    case 'credit_universal':
    case 'credit':
      return intl.formatMessage({id: 'products.details.stock.scorecards.unit.credit'});
    case 'credit_standard':
    case 'image':
      return intl.formatMessage({id: 'products.details.stock.scorecards.unit.images'});
    case 'video':
    case 'videos':
      return intl.formatMessage({id: 'products.details.stock.scorecards.unit.videos'});
    default:
      return undefined;
  }
}

export {generateZeroedQuotas, initializeStockScorecard};
