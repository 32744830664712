import {
  EVENT_ACTION,
  dispatchPageAnalytics,
  dispatchUiEventAnalytics,
  log,
} from '@admin-tribe/acsc';
import {Button, ButtonGroup, Checkbox, Flex, Image, Link, Text, View} from '@adobe/react-spectrum';
import {ModalContainer, ModalContent, ModalDialog, ModalHeading} from '@pandora/react-modal-dialog';
import Alert from '@spectrum-icons/workflow/Alert';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import './AemModal.pcss';

import {B2B_FREE_OFFER_PROVISIONING_CONTENT} from '../AemConstants';
import {remindLaterButtonCTA, successButtonCTA} from '../aemContentHelper';

const COMPLIMENTARY_OFFER_MODAL_ANALYTIC_EVENT = {
  COMPLIMENTARY_OFFER_PAGE_NAME: 'ComplimentaryOfferModal',
  COMPLIMENTARY_OFFER_T0_PAGE_NAME: 'ComplimentaryOfferModalT0',
  LEARN_MORE_URL: 'goUrlComplimentaryMembership',
};

const getPageNameForAnalytics = (modalName) =>
  modalName === B2B_FREE_OFFER_PROVISIONING_CONTENT
    ? COMPLIMENTARY_OFFER_MODAL_ANALYTIC_EVENT.COMPLIMENTARY_OFFER_T0_PAGE_NAME
    : COMPLIMENTARY_OFFER_MODAL_ANALYTIC_EVENT.COMPLIMENTARY_OFFER_PAGE_NAME;

function trackAnalytics(eventAction, pageName, eventName = pageName) {
  try {
    dispatchPageAnalytics({
      name: pageName,
      pageName,
    });

    dispatchUiEventAnalytics({
      eventAction,
      eventName,
    });
  } catch (error) {
    log.error('ComplimentaryOfferModal Analytics: Unable to dispatch analytics', error);
  }
}

const gotItBtnCTA = (closeModal, modalName) => {
  const eventAction = `GotIt:${EVENT_ACTION.CLICK}`;
  const pageName = getPageNameForAnalytics(modalName);
  successButtonCTA(closeModal);

  trackAnalytics(eventAction, pageName);
};

/**
 * helper to transform user input
 *
 * We allow user input for the body of the modal to have a few special tags.
 * These are transformed into React components.
 *
 * @param {Object} intl - intl object
 * @param {String} text - text to transform
 * @param {String} [learnMoreUrl] - URL to render for a link
 *
 * @returns {String | Array<React.ReactNode>} transformed text to be rendered
 *     directly inside React
 */

const transformText = ({intl, modalName, text, learnMoreUrl, appUrls}) =>
  intl.formatMessage(
    {defaultMessage: text, id: 'dummy.invalid'},
    {
      bold: (str) => <Text UNSAFE_style={{fontWeight: 'bold'}}>{str}</Text>,
      br: () => <br />,
      flex: (str) => (
        <Flex direction="row" gap="size-225">
          {str}
        </Flex>
      ),
      img: (imgSrc) => (
        <Image alt="logo" height="size-250" objectFit="contain" src={imgSrc} width="size-250" />
      ),
      li: (str) => <li styleName="li-style">{str}</li>,
      link: (str) => (
        <Link
          onPress={() => {
            const eventAction = `goUrl-complimentary_membership:${EVENT_ACTION.CLICK}`;
            const pageName = getPageNameForAnalytics(modalName);
            const eventName = COMPLIMENTARY_OFFER_MODAL_ANALYTIC_EVENT.LEARN_MORE_URL;
            trackAnalytics(eventAction, pageName, eventName);
          }}
        >
          <a href={learnMoreUrl} rel="noreferrer" target="_blank">
            {str}
          </a>
        </Link>
      ),
      linkAcrobat: (str) => (
        <Link>
          <a href={appUrls.acrobatUrl} rel="noreferrer" target="_blank">
            {str}
          </a>
        </Link>
      ),
      linkExpress: (str) => (
        <Link>
          <a href={appUrls.expressUrl} rel="noreferrer" target="_blank">
            {str}
          </a>
        </Link>
      ),
      para: (str) => <View elementType="div">{str}</View>,
      span: (str) => <Text>{str}</Text>,
      ul: (str) => <ul styleName="ul-style">{str}</ul>,
    }
  );

/**
 * Simple Modal to demonstrate displaying aem content.  This will be deleted in later PRs.
 */
const AemModal = ({closeModal, content}) => {
  const intl = useIntl();
  const [checkboxSelected, setCheckboxSelected] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const appUrls = {
    acrobatUrl: content?.acrobatUrl,
    expressUrl: content?.expressUrl,
  };
  return (
    <ModalContainer>
      <ModalDialog
        analyticsModalId={`aem-modal-${content?.analyticsId}`}
        closeModal={closeModal}
        onCancel={closeModal}
        size={content?.modalSize}
      >
        <ModalHeading marginBottom="size-200">{content?.heading}</ModalHeading>
        <ModalContent>
          <Flex columnGap="size-200">
            <View flexBasis={0} flexGrow={content?.bodyImageRatio}>
              {transformText({
                appUrls,
                intl,
                learnMoreUrl: content?.bodyLearnMoreUrl,
                modalName: content?.name,
                text: content?.body,
              })}
            </View>

            {content?.imageUrl && (
              <Image
                alt=""
                flexBasis={0}
                flexGrow={1}
                objectFit="contain"
                src={content?.imageUrl}
              />
            )}
          </Flex>
          {content?.body2 && (
            <Text>
              {transformText({intl, learnMoreUrl: content.bodyLearnMoreUrl, text: content.body2})}
            </Text>
          )}
          {(content.isTcRequired && (
            <View
              borderColor="dark"
              borderRadius="medium"
              borderWidth="thin"
              marginTop="size-250"
              padding="size-50"
            >
              <Flex direction="column">
                <Text>
                  {transformText({intl, learnMoreUrl: content.tcUrl, text: content.tcInfoText})}
                </Text>
                <Flex direction="row">
                  <Checkbox
                    aria-label={intl.formatMessage({
                      id: 'common.aemModal.checkBoxAriaLabel',
                    })}
                    data-testid="tncAcceptanceCheckbox"
                    isInvalid={isInvalid}
                    isSelected={checkboxSelected}
                    onChange={() => {
                      setIsInvalid(false);
                      setCheckboxSelected(!checkboxSelected);
                    }}
                  />
                  {content.checkboxText}
                </Flex>
                {isInvalid && (
                  <Flex direction="row" gap="size-85">
                    <Alert aria-label="Negative Alert" color="negative" size="S" />
                    <Text UNSAFE_style={{color: '#D31510'}}>
                      {intl.formatMessage({
                        id: 'common.aemModal.uncheckedBoxWarning',
                      })}
                    </Text>
                  </Flex>
                )}
              </Flex>
            </View>
          )) ||
            null}
        </ModalContent>

        {content.isTcRequired ? (
          <ButtonGroup>
            <Button
              data-testid="remindMeBtn"
              onPress={() => remindLaterButtonCTA(closeModal)}
              variant="secondary"
            >
              {intl.formatMessage({
                id: 'common.aemModal.remindMeLaterCta',
              })}
            </Button>
            <Button
              data-testid="acceptBtn"
              onPress={() => {
                if (!checkboxSelected) {
                  setIsInvalid(true);
                  return;
                }
                setIsInvalid(false);
                successButtonCTA(closeModal);
              }}
              variant="accent"
            >
              {intl.formatMessage({
                id: 'common.aemModal.acceptCta',
              })}
            </Button>
          </ButtonGroup>
        ) : (
          <ButtonGroup>
            {content.buttons.map(({analyticsId, label, variant}) => (
              <Button
                key={label}
                data-testid="gotItBtn"
                id={`aem-modal-button-${analyticsId}`}
                onPress={() => gotItBtnCTA(closeModal, content?.name)}
                variant={variant}
              >
                {label}
              </Button>
            ))}
          </ButtonGroup>
        )}
      </ModalDialog>
    </ModalContainer>
  );
};

AemModal.propTypes = {
  /**
   * method to close the modal
   */
  closeModal: PropTypes.func.isRequired,

  /**
   * content to show in the modal.
   */
  content: PropTypes.shape({
    acrobatUrl: PropTypes.string,
    analyticsId: PropTypes.string,

    // body of the modal
    body: PropTypes.string.isRequired,

    body2: PropTypes.string,
    // width ratio of the body text and the image
    bodyImageRatio: PropTypes.number,
    // URL for the singular link allowed in the body
    bodyLearnMoreUrl: PropTypes.string,
    // buttons to show in the footer.
    buttons: PropTypes.arrayOf(
      PropTypes.shape({
        // the action of the button
        action: PropTypes.string.isRequired,
        // label for the button
        label: PropTypes.string.isRequired,
        // button style variant
        variant: PropTypes.string,
      })
    ),
    checkboxText: PropTypes.string,
    expressUrl: PropTypes.string,
    // heading of the modal
    heading: PropTypes.string.isRequired,

    // URL for the image to show in the modal
    imageUrl: PropTypes.string,
    isTcRequired: PropTypes.bool,
    // size of the modal
    modalSize: PropTypes.oneOf(['S', 'M', 'L', 'workflow']).isRequired,
    // name of the modal
    name: PropTypes.string.isRequired,
    tcInfoText: PropTypes.string,
    tcUrl: PropTypes.string,
  }).isRequired,
};
export default AemModal;
export {COMPLIMENTARY_OFFER_MODAL_ANALYTIC_EVENT};
