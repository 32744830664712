import {showError, showSuccess} from '@admin-tribe/acsc-ui';
import {useDialogContainer} from '@adobe/react-spectrum';
import {ModalAlertDialog} from '@pandora/react-modal-dialog';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {useAuthSettingsContext} from 'features/settings/components/auth-settings-page/AuthSettingsContext';
import SocialLoginPolicySummary from 'features/settings/components/auth-settings-page/social-providers-section/social-providers-modal/SocialLoginPolicySummary';

const UpdateSocialLoginPolicyModal = observer(({allowedProviders}) => {
  const intl = useIntl();
  const dialog = useDialogContainer();

  const {authenticationPoliciesStore} = useAuthSettingsContext();

  const onPrimaryAction = async () => {
    try {
      await authenticationPoliciesStore.setAllowedSocialProviders(allowedProviders);

      showSuccess(
        intl.formatMessage({
          id: `settings.authSettings.socialLogin.toast.updateProvider.success`,
        })
      );

      dialog.dismiss();
    } catch {
      showError(
        intl.formatMessage({
          id: `settings.authSettings.socialLogin.toast.updateProvider.error`,
        })
      );
    }
  };

  return (
    <ModalAlertDialog
      isLoading={authenticationPoliciesStore.isLoadingSocialProviders}
      onCancel={() => dialog.dismiss()}
      onClose={() => dialog.dismiss()}
      onPrimaryAction={onPrimaryAction}
      primaryActionLabel={intl.formatMessage({id: 'common.modal.buttons.confirm'})}
      size="S"
      title={intl.formatMessage({id: 'settings.authSettings.socialLogin.modal.title'})}
      variant="confirmation"
    >
      <FormattedMessage id="settings.authSettings.socialLogin.modal.pleaseConfirm" />
      <SocialLoginPolicySummary allowedProviders={allowedProviders} />
      <FormattedMessage id="settings.authSettings.socialLogin.modal.usersWillBeNotified" />
    </ModalAlertDialog>
  );
});

export default UpdateSocialLoginPolicyModal;
