import {OmniTool} from '@admin-tribe/acsc-ui';
import {Flex, View} from '@adobe/react-spectrum';
import React from 'react';

import HeaderNavWrapper from 'common/components/header/HeaderNavWrapper';
import SiteHeaderLeft from 'common/components/header/SiteHeaderLeft';
import SiteHeaderRight from 'common/components/header/SiteHeaderRight';

/**
 * The Site Header
 */
const GlobalHeader = () => (
  <header>
    <View
      direction="row"
      height="size-700"
      UNSAFE_style={{backgroundColor: 'var(--spectrum-global-color-gray-900)'}}
    >
      <Flex alignItems="center" direction="row" height="size-700" marginX="size-175">
        <View flex="0 0 auto">
          <SiteHeaderLeft />
        </View>
        <View flex="1 1 auto">
          <nav>
            <HeaderNavWrapper />
          </nav>
        </View>
        <View flex="0 0 auto">
          <SiteHeaderRight />
        </View>
      </Flex>
      <OmniTool />
    </View>
  </header>
);

export default GlobalHeader;
