import {Contract, ProductList, feature} from '@admin-tribe/acsc';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import FooterTotal from 'common/components/footer-total/FooterTotal';

import useCancellationTotal from '../../hooks/use-cancellation-total/useCancellationTotal';

/**
 * Wrapper component that retrieves total data from the contract, selectedSeats, productList
 * and useCancellationTotal hook from API response and populates the FooterTotal.
 */
const SelfCancelFooterTotal = ({
  contract,
  isErrorDisplayed = false,
  selectedSeats,
  productList,
}) => {
  const intl = useIntl();
  const {
    currency,
    hasLicensesSelected,
    isLoading,
    recurrenceTerm,
    remainingLicenses,
    taxTerm,
    totalWithoutTax,
  } = useCancellationTotal({
    contract,
    isErrorDisplayed,
    productList,
    selectedSeats,
  });
  const isTaxInclusive = false;

  // In case of trial with payment, footer total should be hidden
  if (feature.isEnabled('trial_with_payment')) {
    return null;
  }

  return (
    <FooterTotal
      currency={currency}
      isLoading={isErrorDisplayed && isLoading}
      isTaxInclusive={isTaxInclusive}
      priceTotal={totalWithoutTax}
      recurrenceTerm={recurrenceTerm}
      taxTerm={taxTerm}
    >
      {intl.formatMessage(
        {
          id: hasLicensesSelected
            ? 'account.selfCancel.components.footer.licensesRetained'
            : 'account.selfCancel.components.footer.licensesTotal',
        },
        {remainingLicenses}
      )}
    </FooterTotal>
  );
};

SelfCancelFooterTotal.propTypes = {
  /**
   * The org's contract.
   */
  contract: PropTypes.instanceOf(Contract),
  /**
   * Whether the component should react to ProductsChange errors or loading state.
   */
  isErrorDisplayed: PropTypes.bool,
  /**
   * The list of org's products.
   */
  productList: PropTypes.instanceOf(ProductList),
  /**
   * The hash map of of initially selected licenses per product id.
   */
  selectedSeats: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
};

export default SelfCancelFooterTotal;
