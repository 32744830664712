import {
  Locale,
  authentication,
  configStore,
  csvBlobTransformer,
  getHeaders,
} from '@admin-tribe/acsc';
import axios from 'axios';

import rootStore from '../RootStore';

let clientId, url;

(async function loadConfig() {
  ({url, clientId} = await configStore.getServiceConfiguration('contentPlatformVa6Direct'));
})();

const getStorageHeaders = () => {
  const tokenObject = authentication.getAccessToken();
  if (!tokenObject?.token) {
    throw new Error('Unable to get token for the content platform storage API call');
  }
  return {
    'Accept-Language': Locale.get().activeLocaleCode,
    Authorization: `Bearer ${tokenObject.token}`,
    'org-id': rootStore.organizationStore.activeOrgId,
    'X-Api-Key': clientId,
  };
};

const createStorageReport = (type) =>
  axios.post(
    `${url}/content/iaqs/reports?type=${type}`,
    {},
    {
      headers: getStorageHeaders(),
    }
  );

const getContentLog = async ({orgId, path}) => {
  const response = await axios.get(`${url}/${path}`, {
    headers: {
      ...getHeaders({clientId}),
      'org-id': orgId,
      'x-user-token': authentication.getAccessToken().token,
    },
    responseType: 'blob',
  });
  return csvBlobTransformer(response).data;
};

const getStorageStats = (params) =>
  axios.get(`${url}/content/iaqs/storageStats`, {
    headers: getStorageHeaders(),
    params,
  });

const getStorageStatsV2 = (params) =>
  axios.get(`${url}/content/iaqs/api/v2/storageStats`, {
    headers: getStorageHeaders(),
    params,
  });

const contentPlatformVa6Direct = {
  createStorageReport,
  getContentLog,
  getStorageStats,
  getStorageStatsV2,
};

export default contentPlatformVa6Direct;
