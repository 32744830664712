import {RESTRICTION_STATUS} from '@admin-tribe/acsc';
import {GoUrl} from '@admin-tribe/acsc-ui';
import {AlertDialog, Link} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {goToIdentitySettings} from 'features/settings/routing/navigation-callbacks/navigationCallbacks';

// Confirmation modal shown when the selection is changed in the "Sharing restrictions policy" section.
const SharingRestrictionsConfirmationDialog = ({onPrimaryAction, restrictionStatus}) => {
  const intl = useIntl();

  const restrictionKey = (() => {
    const MODAL_NAMESPACE_KEY =
      'settings.assetSettings.assetSharing.sharingOptions.sharingRestrictionsPolicy.modal';

    switch (restrictionStatus) {
      case RESTRICTION_STATUS.DOMAIN_USERS:
        return `${MODAL_NAMESPACE_KEY}.domainUsers`;
      case RESTRICTION_STATUS.NONE:
        return `${MODAL_NAMESPACE_KEY}.none`;
      case RESTRICTION_STATUS.PUBLIC_LINK:
        return `${MODAL_NAMESPACE_KEY}.publicLink`;
      default:
        throw new Error(
          `SharingRestrictionsConfirmationDialog: unknown namespace key ${restrictionStatus}`
        );
    }
  })();

  const getContent = (paragraphKey) =>
    intl.formatMessage(
      {id: `${restrictionKey}.content.${paragraphKey}`},
      {
        b: (str) => <strong>{str}</strong>,
        goToIdentityLink: (linkText) => (
          <Link onPress={() => goToIdentitySettings()}>
            <span>{linkText}</span>
          </Link>
        ),
        goUrl: (str) => <GoUrl name="asset_settings_domain">{str}</GoUrl>,
        goUrlAssetSettings: (str) => <GoUrl name="asset_settings">{str}</GoUrl>,
      }
    );

  // There can be 1-3 paragraphs, depending on the resriction status.
  const paragraph1 = getContent('p1');
  const paragraph2 = restrictionStatus === RESTRICTION_STATUS.NONE ? '' : getContent('p2');
  const paragraph3 = restrictionStatus === RESTRICTION_STATUS.NONE ? '' : getContent('p3');

  return (
    <AlertDialog
      cancelLabel={intl.formatMessage({id: 'common.modal.buttons.cancel'})}
      onPrimaryAction={onPrimaryAction}
      primaryActionLabel={intl.formatMessage({id: 'common.modal.buttons.confirm'})}
      title={intl.formatMessage({
        id: `${restrictionKey}.title`,
      })}
      variant="confirmation"
    >
      {paragraph1}
      {paragraph2 && <p>{paragraph2}</p>}
      {paragraph3 && <p>{paragraph3}</p>}
    </AlertDialog>
  );
};

SharingRestrictionsConfirmationDialog.propTypes = {
  /**
   * Called if "Confirm" button is pressed.
   */
  onPrimaryAction: PropTypes.func.isRequired,
  /**
   * The pending restriction status to confirm.
   */
  restrictionStatus: PropTypes.oneOf(Object.values(RESTRICTION_STATUS)).isRequired,
};

export default SharingRestrictionsConfirmationDialog;
