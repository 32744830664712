import {feature} from '@admin-tribe/acsc';
import {Text} from '@adobe/react-spectrum';
import {useAsyncModel} from '@pandora/react-async-model';
import React, {useCallback, useMemo} from 'react';
import {useIntl} from 'react-intl';

import {isOrgAdmin} from 'core/organizations/access/organizationAccess';
import ManageButton from 'features/overview/components/manage-button/ManageButton';
import OverviewList from 'features/overview/components/overview-list/OverviewList';
import UserIntroductionsNotification from 'features/overview/components/user-introductions-notification/UserIntroductionsNotification';
import OverviewPod from 'features/overview/shell/overview-pod/OverviewPod';
import {
  goToAdministrators,
  goToDevelopers,
  goToUserGroups,
  goToUsers,
} from 'features/users/routing/navigation-callbacks/navigationCallbacks';

import UsersSummarySectionItem from './UsersSummarySectionItem';
import {USER_TYPE, canShowUserType, getUserCounts} from './usersSummarySectionUtils';

/**
 * The Users Summary Section component
 */
const UsersSummarySection = () => {
  const intl = useIntl();

  const {
    model: userCounts,
    isLoading,
    error,
    updateModel,
  } = useAsyncModel({
    initState: [null, null, null, null],
    loadFn: getUserCounts,
  });

  // update list state once any count is updated
  const listItems = useMemo(
    () =>
      [
        {
          'data-testid': 'users-link',
          id: 'users',
          nameKey: `.users`,
          navigateFn: goToUsers,
          quantity: userCounts[0],
          type: USER_TYPE.USERS,
        },
        {
          'data-testid': 'administrators-link',
          id: 'administrators',
          nameKey: `.administrators`,
          navigateFn: goToAdministrators,
          quantity: userCounts[1],
          type: USER_TYPE.ADMINISTRATORS,
        },
        {
          'data-testid': 'developers-link',
          id: 'developers',
          nameKey: `.developers`,
          navigateFn: goToDevelopers,
          quantity: userCounts[2],
          type: USER_TYPE.DEVELOPERS,
        },
        {
          'data-testid': 'usergroups-link',
          id: 'userGroups',
          nameKey: `.userGroups`,
          navigateFn: goToUserGroups,
          quantity: userCounts[3],
          type: USER_TYPE.USER_GROUPS,
        },
      ].filter(({type}) => canShowUserType(type)),
    [userCounts]
  );

  const errorMessage = useMemo(
    () => (error ? intl.formatMessage({id: 'overview.users.pod.users.error'}) : null),
    [error, intl]
  );

  const onManage = useCallback(() => {
    // open link for the first user type with a count over 0
    listItems.find(({quantity}) => !!quantity).navigateFn();
  }, [listItems]);

  // if the user can not view any counts, render null
  if (!isLoading && listItems.every((item) => !item)) {
    return null;
  }

  return (
    <OverviewPod
      data-testid="users-summary-section"
      errorMessage={errorMessage}
      isLoading={isLoading}
      minHeight="size-900"
      onReload={() => updateModel(getUserCounts)}
      rightHeaderContent={
        !isLoading && (
          <ManageButton
            aria-label={intl.formatMessage({id: 'overview.users.pod.manageAriaLabel'})}
            data-testid="manage-users-link"
            onPress={onManage}
          />
        )
      }
      title={intl.formatMessage({id: 'overview.users.pod.title'})}
    >
      {feature.isEnabled('temp_admin_driven_domain_matching') && isOrgAdmin() && (
        <UserIntroductionsNotification />
      )}
      {errorMessage ? (
        <Text>{errorMessage}</Text>
      ) : (
        <OverviewList
          aria-label={intl.formatMessage({id: 'overview.users.pod.title'})}
          items={listItems}
        >
          {(item) => <UsersSummarySectionItem item={item} />}
        </OverviewList>
      )}
    </OverviewPod>
  );
};

export default UsersSummarySection;
