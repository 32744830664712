/* eslint-disable max-lines -- scorecard construction logic */
import {
  CONSUMABLE_SUMMARIZATION_SUMMARIZE_BY,
  CONTRACT_EXPIRATION_PHASE,
  PRODUCT_GROUP_PARAMETERS_SCORECARD_TYPE,
  ProductNoAccessUserList,
  feature,
  getContractsForProduct,
  getDirectContract,
  getExpirationPhaseFromContractComplianceSymptoms,
  log,
} from '@admin-tribe/acsc';
import {InfoItem, InfoItemScorecard} from '@admin-tribe/acsc-ui';
import {
  SubTitleWithTooltip,
  SubTitleWithTooltipContentModel,
} from '@pandora/react-assignment-modal';
import ClockIcon from '@spectrum-icons/workflow/Clock';
import React from 'react';

import rootStore from 'core/RootStore';
import {getRenewalSummary} from 'core/contract/contract-renewal/contractRenewalUtils';
import {
  canAssignType1User,
  canShowConsumableQuotaTypeItems,
} from 'core/products/access/productAccess';
import ProductPaymentStatusUserList from 'core/products/payment-status-users/ProductPaymentStatusUserList';
import trialHelper from 'core/products/trial-helper/trialHelper';
import {TRIAL_STATUS} from 'core/products/trial-helper/trialHelperConstants';
import ExpiringUsersDrawer from 'features/products/components/expiring-users-drawer/ExpiringUsersDrawer';
import NoAccessDrawer from 'features/products/components/no-access-drawer/NoAccessDrawer';
import PaymentStatusDrawer from 'features/products/components/payment-status-drawer/PaymentStatusDrawer';
import {PAYMENT_STATUS_TYPE} from 'features/products/components/payment-status-drawer/paymentStatusDrawerConstants';
import {getSummaryItemAutoAssign} from 'features/products/product-profiles/info-bar/infoBarUtils';

// eslint-disable-next-line @admin-tribe/admin-tribe/prefer-composition --- This is a utility function
const createInfoItemScorecard = ({drawer, icon, intl, key, label, count, value}) => {
  const props = {
    'data-testid': key,
    icon,
    key,
    label: intl.formatMessage({id: `products.productPageInfoBar.infoItem.label.${label}`}, {count}),
    value,
  };

  if (typeof value === 'object') {
    return <InfoItem {...props}>{value}</InfoItem>;
  }
  if (typeof value === 'string') {
    return <InfoItem {...props} />;
  }
  return <InfoItemScorecard {...props}>{drawer}</InfoItemScorecard>;
};

/**
 * @description Construct scorecards for total and used license quantities for a product.
 * @param {Object} intl - react-intl object
 * @param {Product} product - the product
 * @returns {InfoItem[]} - array of InfoItem components
 */
const renderLicenseQuantities = ({intl, product}) => {
  const showLicenseQuantities =
    (!product.isScorecardsDefined() && product.usesSeatBasedDelegation()) ||
    product.hasScorecardType(PRODUCT_GROUP_PARAMETERS_SCORECARD_TYPE.LICENSES);

  const usedLicensesQuantity = product.isFeatureRestrictedLicense()
    ? product.getTotalActivationCount()
    : product.provisionedQuantity;

  return showLicenseQuantities
    ? [
        createInfoItemScorecard({
          intl,
          key: 'total-licenses',
          label: 'totalLicenses',
          value: product.getAssignableLicenseCount(),
        }),
        createInfoItemScorecard({
          intl,
          key: 'used-licenses',
          label: 'usedLicenses',
          value: usedLicensesQuantity,
        }),
      ]
    : [];
};

/**
 * @description Construct a scorecard for consumable quota type.
 *   This is for seat-based licenses with quota type items, not true consumables.
 *   CC + Stock has quota type items that should be displayed.
 *   Sign with QUOTA and charging unit LICENSE items should NOT be displayed.
 *   Sign with transaction charging unit items are consumables and handled in renderConsumables.
 *   Feature-restricted licenses have consumables which should not be shown
 * @param {Object} intl - react-intl object
 * @param {Product} product - the product
 * @returns {InfoItem | null} - InfoItem component or null we can't show consumable quota type items
 */
const renderConsumableQuotaTypeItems = ({intl, product}) => {
  if (canShowConsumableQuotaTypeItems(product)) {
    const consumableQuotaTypeItems = product.fulfillableItemList.getConsumableQuotaTypeItems({
      requireDelegationConfigurable: false,
    });
    return consumableQuotaTypeItems
      .map((quota) => {
        const locKey = quota.chargingModel.unit;

        const keyExist = !!intl.messages[`products.productPageInfoBar.infoItem.label.${locKey}`];

        if (keyExist) {
          return createInfoItemScorecard({
            intl,
            key: locKey,
            label: locKey,
            value: quota.isUnlimitedCap
              ? intl.formatMessage({id: `products.productPageInfoBar.infoItem.value.unlimited`})
              : quota.calculatedPaidOrPendingOrGracePastDueCount,
          });
        }
        log.warn(`Missing translation quota unit - ${locKey}`);
        return null;
      })
      .filter(Boolean);
  }
  return null;
};

/**
 * @description Construct scorecards for payment statuses.
 * @param {Object} intl - react-intl object
 * @param {Product} product - the product
 * @returns {Promise<InfoItemScorecard[]>} - resolves to array of InfoItemScorecard components
 */
const renderProductPaymentStatuses = async ({intl, product}) => {
  const items = [];
  const renewalSummary = await getRenewalSummary(product);
  const {needRenewalQuantity, needPaymentLicensesQuantity} = renewalSummary;
  // If product allows over-delegation, hide the needs-renewal scorecard. Users will not lose access.
  // This indirectly hides the renewal drawer because there is no scorecard with an icon to click to access it.
  if (needRenewalQuantity && !product.fulfillableItemList.hasOverdelegationAllowed()) {
    items.push(
      createInfoItemScorecard({
        drawer: (
          <PaymentStatusDrawer
            paymentStatusType={PAYMENT_STATUS_TYPE.NEED_RENEWAL}
            product={product}
          />
        ),
        intl,
        key: 'need-renewal-licenses',
        label: 'needRenewal',
        value: needRenewalQuantity,
      })
    );
  }

  if (needPaymentLicensesQuantity) {
    items.push(
      createInfoItemScorecard({
        drawer: (
          <PaymentStatusDrawer
            paymentStatusType={PAYMENT_STATUS_TYPE.NEED_PAYMENT}
            product={product}
          />
        ),
        intl,
        key: 'need-payment-licenses',
        label: renewalSummary.isVIPMPContract ? `orderPending` : `needPayment`,
        value: needPaymentLicensesQuantity,
      })
    );
  }
  return items;
};

/**
 * @description Construct scorecard for impacted users if any exist.
 * @param {Object} intl - react-intl object
 * @param {Product} product - the product
 * @returns {Promise<InfoItemScorecard | null>} - resolves to InfoItemScorecard component or null if no impacted users
 */
const renderDirectContractImpactedUsers = async ({intl, product}) => {
  let impactedUsers;

  if (product.licenseTupleList.hasGracePastDueLicenses()) {
    impactedUsers = await ProductPaymentStatusUserList.getUsersAtRisk({
      orgId: rootStore.organizationStore.activeOrgId,
      pageSize: 10,
      productId: product.id,
    });
  } else if (product.licenseTupleList.hasPastDueLicenses()) {
    impactedUsers = await ProductPaymentStatusUserList.getUsersWithNoAccess({
      orgId: rootStore.organizationStore.activeOrgId,
      pageSize: 10,
      productId: product.id,
    });
  }

  const impactedUsersCount = impactedUsers?.getTotalItemCount() || 0;
  if (impactedUsersCount === 0) {
    return null;
  }

  return createInfoItemScorecard({
    drawer: (
      <PaymentStatusDrawer
        paymentStatusType={PAYMENT_STATUS_TYPE.IMPACTED_USERS}
        product={product}
      />
    ),
    intl,
    key: 'impacted-users',
    label: 'usersImpacted',
    value: impactedUsersCount,
  });
};

/**
 * @description Build the Team Direct contract renewing licenses scorecard if applicable, else null
 * @param {Object} intl - react-intl object
 * @param {Product} product - the product
 * @returns {InfoItemScorecard|null} returns scorecard for renewing license quantities
 */
const renderDirectContractRenewals = ({intl, product}) => {
  const contract = getDirectContract(rootStore.organizationStore.contractList);
  if (contract?.isRenewableDirectContract() && contract?.isInRenewalWindow()) {
    const renewedQuantity = product.licenseTupleList.getRenewedTotal(
      contract.getRenewalWindowEndDate()
    );
    return createInfoItemScorecard({
      intl,
      key: 'renewing-licenses',
      label: 'renewingLicenses',
      value: renewedQuantity,
    });
  }
  return null;
};

/**
 * @description Construct scorecard for expiring licenses.
 * @param {Object} intl - react-intl object
 * @param {Product} product - the product
 * @returns {InfoItemScorecard | null} - InfoItemScorecard component or null if no expiring licenses
 */
const renderDirectContractExpiringLicenses = ({intl, product}) => {
  const expiringLicensesQuantity = product.licenseTupleList.getExpiringQuantity();

  return expiringLicensesQuantity > 0
    ? createInfoItemScorecard({
        drawer: (
          <ExpiringUsersDrawer
            expiringLicenseCount={expiringLicensesQuantity}
            productId={product.id}
          />
        ),
        intl,
        key: 'expiring-licenses',
        label: 'expiringLicenses',
        value: expiringLicensesQuantity,
      })
    : null;
};

/**
 * @description Construct scorecard for trial status.
 * @param {Object} intl - react-intl object
 * @param {Product} product - the product
 * @returns {InfoItem | null} - InfoItem component or null if trial not close to expiring
 */
const renderForTrialStatus = ({intl, product}) => {
  const {daysLeft, status} = trialHelper.getTrialStatusInfo(product);

  let icon, value;
  switch (status) {
    case TRIAL_STATUS.DAYS_LEFT_INFO:
    case TRIAL_STATUS.DAYS_LEFT_WARNING:
      icon = <ClockIcon />;
      value = intl.formatMessage(
        {id: 'products.productPageInfoBar.infoItem.value.daysLeft'},
        {count: daysLeft}
      );
      break;
    case TRIAL_STATUS.EXPIRED:
      value = intl.formatMessage({
        id: 'products.productPageInfoBar.infoItem.value.ended',
      });
      break;
    case TRIAL_STATUS.LAST_DAY:
      value = intl.formatMessage({
        id: 'products.productPageInfoBar.infoItem.value.lastDay',
      });
      break;
    default:
      break;
  }

  return value
    ? createInfoItemScorecard({
        icon,
        intl,
        key: 'trial-time-left',
        label: 'trialStatus',
        value,
      })
    : null;
};

const getNoAccessUsersFromQuery = async ({productId, userQuery}) => {
  try {
    const userList = await userQuery({
      orgId: rootStore.organizationStore.activeOrgId,
      pageSize: 10,
      productId,
    });
    return userList.getTotalItemCount();
  } catch (error) {
    log.error('Failed to get count of users with no access', error);
  }
  return 0;
};

const shouldCallOverDelegatedAPI = (product) =>
  (feature.isDisabled('temp_user_provisioning_status') &&
    !product.fulfillableItemList.hasOverdelegationAllowed()) ||
  feature.isEnabled('temp_user_provisioning_status');

const shouldSkipScorecard = (product) => {
  if (feature.isDisabled('temp_user_provisioning_status')) {
    const contractList = rootStore.organizationStore.contractList;
    const contracts = getContractsForProduct(contractList, product);
    const anyDirectContracts = contracts.some((contract) => contract.isDirectContract());
    return anyDirectContracts;
  }
  return false;
};

/**
 * @description Construct scorecard for number of product users without access.  There are two
 *   cases that we include in this count:
 *   1. Users with no access due to overdelegation
 *   2. Users who were assigned with invalid ID types
 * @param {Object} intl - react-intl object
 * @param {Product} product - the product
 * @returns {Promise<InfoItemScorecard | null>} - resolves to InfoItemScorecard component or null if scorecard not shown
 */
const renderNoAccessItem = async ({intl, product}) => {
  if (shouldSkipScorecard(product)) {
    return null;
  }

  const overDelegatedUserQuery = ProductPaymentStatusUserList.getUsersWithNoAccess.bind(
    ProductPaymentStatusUserList
  );

  let overDelegatedUserCount = 0;
  if (shouldCallOverDelegatedAPI(product)) {
    overDelegatedUserCount = await getNoAccessUsersFromQuery({
      productId: product.id,
      userQuery: overDelegatedUserQuery,
    });
  }

  const invalidIdTypeQuery = ProductNoAccessUserList.get.bind(ProductNoAccessUserList);
  let invalidIdTypeCount = 0;
  if (product.fulfillableItemList.hasDomainClaiming() && !canAssignType1User(product)) {
    invalidIdTypeCount += await getNoAccessUsersFromQuery({
      productId: product.id,
      userQuery: invalidIdTypeQuery,
    });
  }

  const noAccessUsersQuantity = overDelegatedUserCount + invalidIdTypeCount;
  if (noAccessUsersQuantity === 0) {
    return null;
  }
  return createInfoItemScorecard({
    drawer: (
      <NoAccessDrawer
        invalidIdTypeCount={invalidIdTypeCount}
        invalidIdTypeQuery={invalidIdTypeQuery}
        overDelegatedUserCount={overDelegatedUserCount}
        overDelegatedUserQuery={overDelegatedUserQuery}
        product={product}
      />
    ),
    intl,
    key: 'no-access-users',
    label: 'noAccess',
    value: noAccessUsersQuantity,
  });
};

/**
 * @description Construct NoAccessDrawer
 * @param product - the product
 * @returns {NoAccessDrawer} - Rendered NoAccessDrawer component or null if no access users quantity is zero.
 */

const renderNoAccessDrawer = async ({product}) => {
  if (shouldSkipScorecard(product)) {
    return null;
  }

  const {invalidIdTypeCount, invalidIdTypeQuery, overDelegatedUserCount, overDelegatedUserQuery} =
    await getAccessData(product);

  const noAccessUsersQuantity = overDelegatedUserCount + invalidIdTypeCount;
  if (noAccessUsersQuantity === 0) {
    return null;
  }
  return (
    <NoAccessDrawer
      invalidIdTypeCount={invalidIdTypeCount}
      invalidIdTypeQuery={invalidIdTypeQuery}
      overDelegatedUserCount={overDelegatedUserCount}
      overDelegatedUserQuery={overDelegatedUserQuery}
      product={product}
    />
  );
};

/**
 * @description Construct scorecard for auto assigned products
 * @param {Object} intl - react-intl object
 * @param {Rule} productProfileRule - the product profile rule
 * @returns {InfoItemScorecard} - resolves to InfoItemScorecard component
 */
const renderForAutoAssignSummaryItem = ({intl, productProfileRule}) => {
  const value = productProfileRule
    ? getSummaryItemAutoAssign({
        intl,
        productProfileRule,
      }).value
    : undefined;

  return createInfoItemScorecard({
    intl,
    key: 'auto-assignment-rule',
    label: 'autoAssignmentRule',
    value,
  });
};

/**
 * @description Construct scorecard for contract expiration status
 * @param {Object} intl - react-intl object
 * @param {Product} product - the product
 * @returns {Promise<InfoItemScorecard | null>} - resolves to InfoItemScorecard component or null if scorecard not shown
 */
const renderForContractExpirationStatus = ({intl, product}) => {
  const contractList = rootStore.organizationStore.contractList;
  const etlaContractComplianceSymptoms = getContractsForProduct(contractList, product)
    .filter((contract) => contract.isBuyingProgramETLA())
    .map((etlaContract) => getExpirationPhaseFromContractComplianceSymptoms(etlaContract));

  const {NOTIFICATION, GRACE} = CONTRACT_EXPIRATION_PHASE;

  if (
    etlaContractComplianceSymptoms.includes(GRACE) ||
    etlaContractComplianceSymptoms.includes(NOTIFICATION)
  ) {
    const value = etlaContractComplianceSymptoms.includes(GRACE)
      ? intl.formatMessage({id: 'products.productPageInfoBar.infoItem.value.expired'})
      : intl.formatMessage({id: 'products.productPageInfoBar.infoItem.value.expiring'});
    return createInfoItemScorecard({
      intl,
      key: 'contract-expiration',
      label: 'status',
      value,
    });
  }
  return null;
};

/**
 * @description Construct info item for contract display name
 * @param {Object} intl - react-intl object
 * @param {string} contractDisplayNames - the contractDisplayName
 * @returns {Promise<InfoItem | null>} - resolves to InfoItem component or null if only one contract name
 */
const renderForContractDisplayName = ({intl, contractDisplayNames}) => {
  const subtitleTooltipText = intl.formatMessage({
    id: 'common.productsDetailSection.subtitleTooltipText',
  });
  const subtitleTooltipContent = SubTitleWithTooltipContentModel.createEntry({
    subTextLinkSuffix: subtitleTooltipText,
  });
  return createInfoItemScorecard({
    intl,
    key: 'contractDisplayName',
    label: 'contractDisplayName',
    value: (
      <SubTitleWithTooltip
        content={subtitleTooltipContent}
        data-testid="contract-id-text"
        subtitles={contractDisplayNames}
        subtitleStyle={{
          color: 'var(--spectrum-global-color-black-600)',
          fontSize: 'var(--spectrum-global-dimension-size-225)',
          fontWeight: 'bold',
        }}
      />
    ),
  });
};

/**
 *
 * @param {ConsumableSummarizationList} consumableSummarizationList - the ConsumableSummarizationList
 * @param {Object} intl - react-intl object
 * @param {Product} product - the product
 * @returns {InfoItemScorecard|null} - resolves to InfoItemScorecard component or null if scorecard not applicable
 */
const renderForConsumables = ({consumableSummarizationList, intl, product}) => {
  if (
    !product.isScorecardsDefined() ||
    product.hasScorecardType(PRODUCT_GROUP_PARAMETERS_SCORECARD_TYPE.CONSUMPTION)
  ) {
    // If consumableSummarizationList hasn't loaded yet, don't do anything
    if (consumableSummarizationList) {
      const summaries = consumableSummarizationList.getSummariesForId(product.id, {
        summarize_by: CONSUMABLE_SUMMARIZATION_SUMMARIZE_BY.LICENSE_ID,
      });
      const consumables = summaries.flatMap((summary) => summary.consumableList.items);
      return consumables.flatMap((consumable) => {
        // If this is an unknown consumable, don't return a scorecard for it.
        if (consumable.unit === 'transactions') {
          return [
            createInfoItemScorecard({
              intl,
              key: 'total-transactions',
              label: 'totalTransactions',
              value: consumable.totalQuantity,
            }),
            createInfoItemScorecard({
              intl,
              key: 'used-transactions',
              label: 'usedTransactions',
              value: consumable.consumedQuantity,
            }),
          ];
        }
        // Delete when temp_enable_react_sdl is removed; this is replaced by SharedDeviceLicenseProductPageInfoBar
        if (feature.isDisabled('temp_enable_react_sdl') && consumable.unit === 'machines') {
          return [
            createInfoItemScorecard({
              intl,
              key: 'total-machines',
              label: 'totalMachines',
              value: consumable.totalQuantity,
            }),
            createInfoItemScorecard({
              intl,
              key: 'used-machines',
              label: 'usedMachines',
              value: consumable.consumedQuantity,
            }),
          ];
        }
        log.warn(`Missing translation for consumable quota unit: ${consumable.unit}`);
        return [];
      });
    }
  }
  return null;
};

async function getAccessData(product) {
  const overDelegatedUserQuery = ProductPaymentStatusUserList.getUsersWithNoAccess.bind(
    ProductPaymentStatusUserList
  );
  const overDelegatedUserCount = await getDelegatedUserCount(product, overDelegatedUserQuery);

  const invalidIdTypeQuery = ProductNoAccessUserList.get.bind(ProductNoAccessUserList);
  const invalidIdTypeCount = await getInValidIdTypeCount(product, invalidIdTypeQuery);
  return {invalidIdTypeCount, invalidIdTypeQuery, overDelegatedUserCount, overDelegatedUserQuery};
}

async function getDelegatedUserCount(product, overDelegatedUserQuery) {
  let overDelegatedUserCount = 0;

  if (shouldCallOverDelegatedAPI(product)) {
    overDelegatedUserCount = await getNoAccessUsersFromQuery({
      productId: product.id,
      userQuery: overDelegatedUserQuery,
    });
  }
  return overDelegatedUserCount;
}

async function getInValidIdTypeCount(product, invalidIdTypeQuery) {
  let invalidIdTypeCount = 0;
  if (product.fulfillableItemList.hasDomainClaiming() && !canAssignType1User(product)) {
    invalidIdTypeCount += await getNoAccessUsersFromQuery({
      productId: product.id,
      userQuery: invalidIdTypeQuery,
    });
  }
  return invalidIdTypeCount;
}
export {
  renderConsumableQuotaTypeItems,
  renderDirectContractExpiringLicenses,
  renderDirectContractImpactedUsers,
  renderDirectContractRenewals,
  renderForAutoAssignSummaryItem,
  renderForConsumables,
  renderForContractExpirationStatus,
  renderForTrialStatus,
  renderLicenseQuantities,
  renderNoAccessItem,
  renderNoAccessDrawer,
  renderProductPaymentStatuses,
  renderForContractDisplayName,
};

/* eslint-enable max-lines -- scorecard construction logic */
