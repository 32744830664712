import get from 'lodash/get';

import {setProperty} from '../aepAnalytics';

/**
 *
 * @param {*} primaryProductDescriptor
 */
export default function primaryProductEventTranslator(primaryProductDescriptor) {
  setProperty('primaryProduct.productInfo.offerId', get(primaryProductDescriptor, 'offer_id'));

  setProperty('primaryProduct.quantity', get(primaryProductDescriptor, 'numberSelected'));

  setProperty(
    'primaryProduct.attributes.sourceOfferLocation',
    get(primaryProductDescriptor, 'productBucket')
  );
}
