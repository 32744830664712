// ***********************************************************************
// ADOBE CONFIDENTIAL
// ___________________
//
// Copyright 2024 Adobe
// All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains
// the property of Adobe and its suppliers, if any. The intellectual
// and technical concepts contained herein are proprietary to Adobe
// and its suppliers and are protected by all applicable intellectual
// property laws, including trade secret and copyright laws.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Adobe.
// ************************************************************************

import {Content, ContextualHelp, Flex, Footer, Heading, Text, View} from '@adobe/react-spectrum';
import {GoUrl} from '@pandora/react-go-url';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

const ENDASH_STR = '\u2013';

/**
 * For displaying a scorecard with a contextual help tooltip as required by custom models info bar.
 *
 * @param labelId The localization key for the label of the scorecard
 * @param titleId The localization key for the title of the contextual help tooltip
 * @param contentId The localization key for the content of the contextual help tooltip
 * @param contentFormatOptions FormatMessage options for the content
 * @param linkId The localization key for the link text of the contextual help tooltip
 * @param goUrlCode The optional localization key for the go url code
 * @param count The count to display in the scorecard
 * @returns {Element}
 * @constructor
 */
const ExtendedScoreCard = ({
  labelId,
  titleId,
  contentId,
  contentFormatOptions,
  linkId,
  goUrlCode,
  count,
}) => {
  const intl = useIntl();
  let valueToDisplay = ENDASH_STR;

  if (typeof count === 'string' && count) {
    valueToDisplay = count;
  } else if (Number.isFinite(count)) {
    valueToDisplay = intl.formatNumber(count);
  }

  return (
    <Flex direction="column" rowGap="size-25">
      <View>
        <Text data-testid="info-item-label">{intl.formatMessage({id: labelId})}</Text>
        <ContextualHelp data-testid="count-contextual-help" marginStart="size-100" variant="info">
          <Heading UNSAFE_style={{marginBottom: 'size-100', paddingBottom: 0}}>
            {intl.formatMessage({id: titleId})}
          </Heading>
          <Content UNSAFE_style={{paddingTop: 0}}>
            <Text>{intl.formatMessage({id: contentId}, contentFormatOptions)}</Text>
          </Content>
          {goUrlCode && (
            <Footer data-testid="footer-gourl-contextual-help">
              <GoUrl name={goUrlCode} noWrap={false}>
                {intl.formatMessage({id: linkId})}
              </GoUrl>
            </Footer>
          )}
        </ContextualHelp>
      </View>
      <View data-testid="info-item-value" UNSAFE_style={{fontSize: '18px', fontWeight: 'bold'}}>
        {valueToDisplay}
      </View>
    </Flex>
  );
};

ExtendedScoreCard.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types -- this is the FormatMessage options
  contentFormatOptions: PropTypes.any,
  contentId: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  goUrlCode: PropTypes.string,
  labelId: PropTypes.string.isRequired,
  linkId: PropTypes.string.isRequired,
  titleId: PropTypes.string.isRequired,
};

export default ExtendedScoreCard;
