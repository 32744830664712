import {feature, toPandoraProduct} from '@admin-tribe/acsc';
import {Product as PandoraProduct} from '@pandora/data-model-product';

import rootStore from 'core/RootStore';
import trialHelper from 'core/products/trial-helper/trialHelper';

/**
 * @description - Construct the sorted list of products for a given user by prepending any
 *   organization-wide, auto-assigned product, the products associated with the user.
 *
 * @param {Intl.Collator} options.collator - A collator, usually from 'useCollator'.
 * @param {Object} options.intl - Intl object to format the product name.
 * @param {Array<Product>} options.userProducts - array of Acsc Products assigned to the user. Each product object just contains the product id.
 * @return {Array<PandoraProduct>} where each Product minmally has only an iconTitle and assets but may be a complete Product.
 */
function getUserProductsForDisplay({collator, intl, userProducts}) {
  const orgConsumables = rootStore.organizationStore.orgConsumables;

  // If there is no match for the user product in the product list, leave an
  // undefined in the slot for the product.
  const userProductObjects = feature.isEnabled('temp_b2b_free_provisioning')
    ? getUserProductsWithoutFreeOffer(userProducts)
    : getUserProducts(userProducts);

  // In addition to the user assigned products, include an org consumables an
  // org delegatable products which should look like they are assigned to the user
  // even though they are assigned at the org level.
  const productsForDisplay = orgConsumables.getProductDisplayList(
    collator,
    intl,
    userProductObjects
  );

  // Convert the Acsc Products to PandoraProduct for Pandora's ProductIconList component.
  const products = productsForDisplay.map((acscProduct) => {
    let pandoraProduct;

    if (acscProduct) {
      pandoraProduct = toPandoraProduct(acscProduct);

      // Trial products have `Free trial` appended to the product name.
      if (trialHelper.isTrialProduct(acscProduct)) {
        pandoraProduct.displayOptions = {isTrial: true};
      }
    } else {
      // So ProductIconList will display a "something has gone wrong" icon for this product.
      pandoraProduct = new PandoraProduct({});
    }
    return pandoraProduct;
  });

  return products;
}

/**
 * Internal method to find the product by id in the productList.
 */
function getUserProducts(userProducts) {
  return userProducts.map(({id}) =>
    rootStore.organizationStore.productList.items.find((product) => product.id === id)
  );
}

/**
 * Internal method to find the product excluding the free products by id in the productList.
 */

function getUserProductsWithoutFreeOffer(userProducts) {
  const {items, freeItems} = rootStore.organizationStore.productList;
  const freeItemIds = new Set(freeItems.map((item) => item.id));
  return userProducts
    .filter((product) => !freeItemIds.has(product.id))
    .map((product) => items.find((item) => item.id === product.id));
}

// eslint-disable-next-line import/prefer-default-export -- utils
export {getUserProductsForDisplay};
