import {configStore, getHeaders} from '@admin-tribe/acsc';
import axios from 'axios';

let clientId, includeRoles, url;

(async function loadJilConfig() {
  ({url, clientId, includeRoles} = await configStore.getServiceConfiguration('jil'));
  url = `${url}/v2/organizations`;
})();

/**
 * @description Deletes a Content Log by ID
 *
 * @param {String} id ID of the content log which will be deleted
 * @param {String} orgId Org ID
 * @return {Promise} a promise which will resolve with the response data
 */
const deleteContentLogs = ({id, orgId}) =>
  axios.delete(`${url}/${orgId}/content-logs/${id}`, {
    headers: getHeaders({clientId, includeRoles}),
  });

/**
 * @description Fetches Content Logs
 *
 * @param {Object} params The request parameters object
 * @param {String} orgId Org ID
 * @param {Number} [params.page] The page number of cached model to fetch
 * @param {Number} [params.page_size] The number of reports to display per page
 * @param {String} [params.sort] The sorting criteria
 * @param {String} [params.sort_order] The sort order
 * @return {Promise} a promise which will resolve with the response
 */
const getContentLogsList = ({orgId, ...params}) =>
  axios.get(`${url}/${orgId}/content-logs`, {
    headers: getHeaders({clientId, includeRoles}),
    params,
  });

/**
 * @description Creates a new Content Log
 *
 * @param {String} orgId Org ID
 * @param {Object} payload Details of new content log which will be created
 * @return {Promise} a promise which will resolve with the response data
 */
const postContentLog = ({orgId, payload}) =>
  axios.post(`${url}/${orgId}/content-logs`, payload, {
    headers: getHeaders({clientId, includeRoles}),
  });

const jilContentLogs = {
  deleteContentLogs,
  getContentLogsList,
  postContentLog,
};

export default jilContentLogs;
