import {Product} from '@admin-tribe/acsc';
import {getProductDisplayName} from '@admin-tribe/acsc-ui';
import {
  Cell,
  Column,
  Content,
  Flex,
  Header,
  Heading,
  Row,
  TableBody,
  TableHeader,
  TableView,
  View,
} from '@adobe/react-spectrum';
import {Drawer} from '@pandora/react-drawer';
import {ImageIcon} from '@pandora/react-image-icon';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, FormattedNumber, useIntl} from 'react-intl';

const STORAGE_PLANS_DRAWER_ID = 'storage-plans-drawer-id';

/**
 * This is a drawer available in the Storage Overview page
 */
const StoragePlansDrawer = ({storageProducts}) => {
  const intl = useIntl();

  const getProductRow = (product) => (
    <Row>
      <Cell>
        <Flex alignItems="center" direction="row" gap="size-200">
          {product?.assets?.icons.svg && (
            <Flex alignItems="center" flexShrink={0}>
              <ImageIcon alt="" size="M" src={product.assets.icons.svg} />
            </Flex>
          )}
          <View>{getProductDisplayName(intl, product)}</View>
        </Flex>
      </Cell>
      <Cell>
        <FormattedNumber value={product.getAssignableLicenseCount()} />
      </Cell>
    </Row>
  );

  return (
    <Drawer aria-labelledby={STORAGE_PLANS_DRAWER_ID}>
      <Header>
        <Heading id={STORAGE_PLANS_DRAWER_ID} level={2} marginBottom="size-125" marginTop="size-0">
          <FormattedMessage id="storage.storagePlanDrawer.header" />
        </Heading>
      </Header>
      <Content>
        <View>
          <FormattedMessage id="storage.storagePlanDrawer.description" />
        </View>

        <TableView
          aria-label={intl.formatMessage({id: 'storage.storagePlanDrawer.header'})}
          isQuiet
          marginTop="size-300"
          overflowMode="wrap"
        >
          <TableHeader>
            <Column key="plan" align="start" width="75%">
              <FormattedMessage id="storage.storagePlanDrawer.products.column.additionalStoragePlan" />
            </Column>
            <Column key="quantity" align="end">
              <FormattedMessage id="storage.storagePlanDrawer.products.column.quantity" />
            </Column>
          </TableHeader>
          <TableBody items={storageProducts}>{(product) => getProductRow(product)}</TableBody>
        </TableView>
      </Content>
    </Drawer>
  );
};

StoragePlansDrawer.propTypes = {
  /**
   * The storagePlans property contains all the storage plans for the current OrgId.
   */
  storageProducts: PropTypes.arrayOf(PropTypes.instanceOf(Product)).isRequired,
};

export default StoragePlansDrawer;
