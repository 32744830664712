// ***********************************************************************
// ADOBE CONFIDENTIAL
// ___________________
//
// Copyright 2024 Adobe
// All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains
// the property of Adobe and its suppliers, if any. The intellectual
// and technical concepts contained herein are proprietary to Adobe
// and its suppliers and are protected by all applicable intellectual
// property laws, including trade secret and copyright laws.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Adobe.
// ************************************************************************
import {Heading} from '@adobe/react-spectrum';
import SafeHtml from '@pandora/react-safe-html';
import React from 'react';

import {useJsonPayload} from '../JsonPayloadProvider';

const SophiaPromoTitle = () => {
  const {payload} = useJsonPayload();

  // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- disabled for resolving merge conflicts
  // istanbul ignore next
  if (!payload) {
    return null;
  }

  return payload.title ? (
    <Heading data-testid="one-console-promo-title" level={3} marginBottom="8px">
      <SafeHtml dataTestId="one-console-promo-title-text" html={payload.title} />
    </Heading>
  ) : null;
};

SophiaPromoTitle.propTypes = {};

export default SophiaPromoTitle;
