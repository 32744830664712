import {Content, Heading, IllustratedMessage} from '@adobe/react-spectrum';
import AlertCircle from '@spectrum-icons/workflow/AlertCircle';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {PLUGIN_SUPPORT_TYPE} from '../../../../../packagesConstants';

// Plugin Error Section component displayed when error or if platform selected does not have plugin support in plugin selection screen
const PluginErrorSection = ({
  isInitialLoad = false,
  hasError,
  hasNoPluginSupport = false,
  supportType = PLUGIN_SUPPORT_TYPE.PLATFORM,
}) => {
  const intl = useIntl();

  const sectionHeading = () => {
    if (hasError) {
      return intl.formatMessage({id: 'packages.createPackage.choosePlugins.errorString'});
    }
    // if above condition false, then it is hasNoPluginSupport case, else this function would not have been called
    return intl.formatMessage(
      {
        id: `packages.createPackage.choosePlugins.errorUnavailableForPlatform`,
      },
      {type: supportType}
    );
  };

  // when has plugin support and does not have error
  if (!hasNoPluginSupport && !hasError) {
    return null;
  }

  return (
    <IllustratedMessage
      // when isInitialLoad, then top section does not appear, so width and height to be set accordingly
      minHeight={isInitialLoad ? 'size-6000' : 'auto'}
    >
      <AlertCircle size="XL" />
      <Heading>{sectionHeading()}</Heading>
      {hasError && (
        <Content>
          {intl.formatMessage({id: 'packages.createPackage.choosePlugins.errorTryAgain'})}
        </Content>
      )}
    </IllustratedMessage>
  );
};

PluginErrorSection.propTypes = {
  /**
   * Boolean value to indicate if an error occurred
   */
  hasError: PropTypes.bool.isRequired,
  /**
   * Boolean value to indicate if there is no plugin support. Defaults to false.
   */
  hasNoPluginSupport: PropTypes.bool,
  /**
   * Boolean value to indicate if the screen load is on initial mount. Defaults to false.
   */
  isInitialLoad: PropTypes.bool,
  /**
   * String value to indicate no plugin support for type i.e. platform/offer. Defaults to platform.
   */
  supportType: PropTypes.string,
};
export default PluginErrorSection;
