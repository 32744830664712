import {OrganizationLocale, log} from '@admin-tribe/acsc';
import {LanguagePicker, showError, showSuccess} from '@admin-tribe/acsc-ui';
import {Text, View} from '@adobe/react-spectrum';
import {DividerPlacement, TitledSection} from '@pandora/react-titled-section';
import {useId} from '@react-aria/utils';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';

/**
 * Defines the email language section of the console settings page.
 */
const EmailLanguageSection = ({organizationLocale}) => {
  const languageDescriptionId = useId();
  const [isSavingLanguageCode, setIsSavingLanguageCode] = useState(false);
  const intl = useIntl();
  const [selectedKey, setSelectedKey] = useState(organizationLocale.getLanguage() || undefined);
  const isCurrentRef = useRef(true);

  // this side effect is to track when the component is mounted/unmounted
  useEffect(() => {
    isCurrentRef.current = true;

    return () => {
      isCurrentRef.current = false;
    };
  }, []);

  const onChangeLanguage = async (updatedLanguage) => {
    setIsSavingLanguageCode(true);
    try {
      organizationLocale.setLanguage(updatedLanguage);
      await organizationLocale.update();
      if (isCurrentRef.current) {
        setSelectedKey(updatedLanguage);
        showSuccess(
          intl.formatMessage({id: 'settings.consoleSettings.emailLanguage.toast.success'})
        );
      }
    } catch (error) {
      organizationLocale.revertChanges();
      log.error('Error saving email language: ', error);
      if (isCurrentRef.current) {
        showError(intl.formatMessage({id: 'common.toast.default.error'}));
      }
    } finally {
      if (isCurrentRef.current) {
        setIsSavingLanguageCode(false);
      }
    }
  };

  return (
    <View data-testid="email-language-section">
      <TitledSection
        dividerPlacement={DividerPlacement.TOP}
        headingLevel={2}
        title={intl.formatMessage({
          id: 'settings.consoleSettings.emailLanguage.title',
        })}
      >
        <View marginBottom="size-200">
          <Text data-testid="description" id={languageDescriptionId}>
            {intl.formatMessage({
              id: 'settings.consoleSettings.emailLanguage.description',
            })}
          </Text>
        </View>
        <LanguagePicker
          aria-describedby={languageDescriptionId}
          defaultSelectedKey={selectedKey}
          isLoading={isSavingLanguageCode}
          label={intl.formatMessage({
            id: 'settings.consoleSettings.emailLanguage.label',
          })}
          labelPosition="side"
          onSelectionChange={onChangeLanguage}
          placeholder={intl.formatMessage({
            id: 'settings.consoleSettings.emailLanguage.placeholder',
          })}
        />
      </TitledSection>
    </View>
  );
};

EmailLanguageSection.propTypes = {
  /**
   * The org locale that contains the preferred email language
   */
  organizationLocale: PropTypes.instanceOf(OrganizationLocale).isRequired,
};

export default EmailLanguageSection;
