import binky, {feature} from '@admin-tribe/acsc';
import {INSIGHTS_SECTION} from '@pandora/data-model-insights';
import {redirect} from 'react-router-dom';

import rootStore from 'core/RootStore';
import {
  buildAccessCheckLoader,
  buildDefaultSectionRedirectLoader,
  buildSequentialLoader,
  throwNotFoundError,
} from 'core/router/loaders';

import insightsStore from '../insightsStore';
import {getInsightsPaths} from '../insightsUtils';

import {PATH_INSIGHTS_ROOT} from './insightsPaths';

/**
 * Redirects the URL if the user landed on /insights
 * @param {import('react-router-dom').LoaderFunctionArgs} args - The incoming loader data, which must contain a Request with a `url` field
 * @returns {Response | null}
 */
const insightsDefaultSectionLoader = async ({request}) => {
  const insightsPaths = getInsightsPaths();
  const defaultSection = await insightsStore.getDefaultSection();
  let defaultUrl;
  if (defaultSection === INSIGHTS_SECTION.DASHBOARD) {
    defaultUrl = insightsPaths.dashboard();
  } else if (defaultSection === INSIGHTS_SECTION.LOGS) {
    defaultUrl = insightsPaths.logs();
  } else if (defaultSection === INSIGHTS_SECTION.REPORTS) {
    defaultUrl = insightsPaths.reports();
  }

  if (defaultUrl) {
    return buildDefaultSectionRedirectLoader(PATH_INSIGHTS_ROOT, defaultUrl)({request});
  }

  return null;
};

/**
 * Checks if the user can access Insights
 * @param {import('react-router-dom').LoaderFunctionArgs} param0
 * @returns {Promise<Response>}
 */
const insightsAccessCheck = async ({request}) =>
  buildAccessCheckLoader(await insightsStore.canViewInsights())({request});

/**
 * Top-level loader for Insights. Does the following:
 * 1. Checks if the user can access Insights
 * 2. Redirects the user to the default section if they landed on /insights
 * 3. Loads shared data for all Insights sections
 * @param {import('react-router-dom').LoaderFunctionArgs} param0
 * @returns {Promise<Response>}
 */
const insightsLoader = buildSequentialLoader(
  insightsAccessCheck,
  insightsDefaultSectionLoader,
  async () => ({
    accessOptions: feature.isEnabled('temp_domain_enforcement')
      ? await insightsStore.getAccessOptions2()
      : insightsStore.getAccessOptions(),
    canViewDashboard: await insightsStore.canViewDashboard(),
    canViewLogs: await insightsStore.canViewLogs(),
    canViewReports: await insightsStore.canViewReports(),
  })
);

/**
 * Checks if the user can access Insights Dashboard
 * @param {import('react-router-dom').LoaderFunctionArgs} param0
 * @returns {Promise<Response>}
 */
const dashboardAccessCheck = async ({request}) =>
  buildAccessCheckLoader(await insightsStore.canViewDashboard())({request});

const getContractInfoListData = async () => {
  let topErrorStatus = null;
  let contractInfoList = null;

  try {
    contractInfoList =
      await binky.services.etlaUsageReport.foxManagerUtils.getInitialContractInfoList(
        rootStore.organizationStore.activeOrgId
      );
  } catch (error) {
    topErrorStatus = error.response.status;
  }

  return {
    contractInfoList,
    hasTopError: !!topErrorStatus,
    topErrorStatus,
  };
};

const getInitialContractIdData = async ({contractId}) => {
  let initialReportData = null;
  let contractErrorStatus = null;

  try {
    if (contractId) {
      initialReportData =
        await binky.services.etlaUsageReport.foxManagerUtils.getInitialDataForContractId(
          contractId,
          rootStore.organizationStore.activeOrgId
        );
    }
  } catch (error) {
    contractErrorStatus = error.response.status;
  }

  return {
    contractErrorStatus,
    hasContractError: !!contractErrorStatus,
    initialReportData,
  };
};

const getDashboardData = async () => {
  const {topErrorStatus, contractInfoList, hasTopError} = await getContractInfoListData();
  const {initialReportData, contractErrorStatus, hasContractError} = await getInitialContractIdData(
    {
      contractId: contractInfoList?.[0].contractId,
    }
  );

  return {
    contractErrorStatus,
    contractInfoList,
    hasContractError,
    hasTopError,
    initialReportData,
    topErrorStatus,
  };
};

/**
 * Loader for the Dashboard section of Insights. Does the following:
 * 1. Checks if the user can access Insights Dashboard
 * 2. Loads data for the Dashboard section
 * @param {import('react-router-dom').LoaderFunctionArgs} param0
 * @returns {Promise<Response>}
 */
const dashboardLoader = buildSequentialLoader(dashboardAccessCheck, getDashboardData);

/**
 * Checks if the user can access Insights Logs
 * @param {import('react-router-dom').LoaderFunctionArgs} param0
 * @returns {Promise<Response>}
 */
const logsAccessCheck = async ({request}) =>
  buildAccessCheckLoader(await insightsStore.canViewLogs())({request});

/**
 * Checks if the user can access Insights Reports
 * @param {import('react-router-dom').LoaderFunctionArgs} param0
 * @returns {Promise<Response>}
 */
const reportsAccessCheck = async ({request}) =>
  buildAccessCheckLoader(await insightsStore.canViewReports())({request});

/**
 * Gathers data to render the Insights section page
 * @param {string} insightsSection
 * @returns {() => Promise<{insightsItemList: JilModelList<import('@pandora/data-model-insights').InsightsItem>}>}
 */
const insightsSectionLoader = (insightsSection) => async () => ({
  insightsItemList: await insightsStore.getInsightsItemList(insightsSection),
});

/**
 * Gathers data to render the Insights section details page
 * @param {string} insightsSection
 * @throws A a Not Found error when the Insights Item or tab cannot be found
 * @returns {() => Promise<{insightsItem: undefined | import('@pandora/data-model-insights').InsightsItem>}>}
 */
const insightsSectionDetailsLoader =
  (insightsSection) =>
  async ({params: {insightsItemId, tabName}, request}) => {
    const list = await insightsStore.getInsightsItemList(insightsSection);
    const foundInsightsItem = list?.items.find((item) => item.id === insightsItemId);

    if (!foundInsightsItem || foundInsightsItem.tabs.length === 0) {
      return throwNotFoundError(request);
    }

    if (!tabName) {
      // Handle when the tab ID is not present in the URL, redirect to the first tab
      const insightsPaths = getInsightsPaths();
      const path = insightsPaths.sectionDetails(
        insightsSection,
        insightsItemId,
        foundInsightsItem.tabs[0].id
      );
      return redirect(path);
    }

    // Handle when the tab ID is present in the URL, verify the given tab ID is valid
    const foundTab = foundInsightsItem.tabs.find((tab) => tab.id === tabName);
    if (!foundTab) {
      return throwNotFoundError(request);
    }

    return {insightsItem: foundInsightsItem};
  };

export {
  insightsLoader,
  dashboardLoader,
  insightsSectionLoader,
  insightsSectionDetailsLoader,
  logsAccessCheck,
  reportsAccessCheck,
};
