import {AlphaListState, log} from '@admin-tribe/acsc';
import {
  OverlayWait,
  Subpage,
  TableSectionTable,
  ViewDetailsDrawerTrigger,
  showError,
  showSuccess,
} from '@admin-tribe/acsc-ui';
import {useAsyncModel} from '@pandora/react-async-model';
import {
  EnDashDefaultContent,
  SELECTION_MODE,
  TABLE_SECTION_ACTIONS,
  TableActions,
  TableSection,
  onTokenPaginatedTableSectionChange,
} from '@pandora/react-table-section';
import React, {useCallback, useState} from 'react';
import {useIntl} from 'react-intl';

import useTableSectionPageCounter from 'common/hooks/useTableSectionPageCounter';
import {
  USER_FOLDER_DEFAULT_PAGE_SIZE,
  USER_FOLDER_STATE,
} from 'common/services/user-folder/userFolderConstants';
import UserFolderList from 'common/services/user-folder-list/UserFolderList';
import {isReadOnly} from 'core/organizations/access/organizationAccess';
import FolderIconWithName from 'features/storage/components/folder-icon-with-name/FolderIconWithName';
import SharedUserFolderDetailsDrawer from 'features/storage/components/shared-user-folder-details-drawer/SharedUserFolderDetailsDrawer';
import {
  formatName,
  formatStorageForDisplay,
} from 'features/storage/components/utils/storageDisplayUtils';

import PermanentlyDeleteButton from './PermanentlyDeleteButton';
import useRefreshUserFolderListWithRetry from './useRefreshUserFolderListWithRetry';

const MAX_REFRESH_RETRIES = 5;
const REFRESH_DELAY = 1000;

/**
 *  The individual users folders active users subpage
 */
const InactiveUsersSubpage = () => {
  const intl = useIntl();
  const [isDeleting, setIsDeleting] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const fetchUserFolderList = useCallback(
    () =>
      UserFolderList.get({
        includes: USER_FOLDER_STATE.DISCARDED,
        state: new AlphaListState({
          cacheNextTokens: true,
          pageSize: USER_FOLDER_DEFAULT_PAGE_SIZE,
        }),
      }),
    []
  );

  const {
    model: userFolderList,
    isLoading,
    error,
    updateModel,
  } = useAsyncModel({loadFn: fetchUserFolderList});

  const {refreshListWithRetry} = useRefreshUserFolderListWithRetry({
    maxRetries: MAX_REFRESH_RETRIES,
    refreshDelay: REFRESH_DELAY,
    updateModel,
    userFolderList,
  });

  const refreshList = useCallback(async () => {
    await updateModel(() => userFolderList.refresh());
  }, [userFolderList, updateModel]);

  // Maintains current page and total page state for the paginator
  const {currentPage, onResetPaging, onTableSectionChangePageReducer, totalPages} =
    useTableSectionPageCounter({
      listState: userFolderList?.state,
    });

  const getViewDetailsDrawerTrigger = ({item}) => (
    <ViewDetailsDrawerTrigger
      tooltipText={intl.formatMessage({
        id: 'storage.individualUserFolders.drawerButton.hoverText',
      })}
    >
      {() => <SharedUserFolderDetailsDrawer selectedFolder={item} />}
    </ViewDetailsDrawerTrigger>
  );

  const onTableSectionChange = async ({action, payload, state}) => {
    // Update userFolderList state
    onTokenPaginatedTableSectionChange({
      action,
      payload,
      tokenPaginatedDataUtils: userFolderList?.state,
    });

    // Update page states
    onTableSectionChangePageReducer({action});

    switch (action) {
      case TABLE_SECTION_ACTIONS.GO_TO_NEXT_PAGE:
      case TABLE_SECTION_ACTIONS.GO_TO_PREVIOUS_PAGE:
      case TABLE_SECTION_ACTIONS.ON_PAGE_SIZE_CHANGE:
        await refreshList();
        break;
      case TABLE_SECTION_ACTIONS.ON_ROW_SELECTION_CHANGE:
        setSelectedItems(state.selectedItems);
        break;
      default:
      // do nothing
    }
  };

  const permanentDeleteUserFolders = async (stateUtils) => {
    setIsDeleting(true);
    try {
      await userFolderList.permanentDelete(selectedItems);
      stateUtils.clearSelectedItemKeys();

      // Since this list uses token paging: after deleting, we need to reset to
      // the first page and we can't predict whether there will be additional pages.
      onResetPaging();
      userFolderList.state.resetParams();

      await refreshListWithRetry();
      showSuccess(
        intl.formatMessage(
          {id: 'storage.individualUserFolders.inactiveUsers.toast.success'},
          {count: selectedItems.length}
        )
      );
    } catch (deleteError) {
      showError(intl.formatMessage({id: 'common.toast.default.error'}));
      log.error('Error deleting user folders', deleteError);
    } finally {
      setIsDeleting(false);
    }
  };

  const isInitialLoadDone = userFolderList?.items !== undefined;

  const tableColumnDescriptor = [
    {key: 'folder'},
    {
      key: 'viewDetails',
      props: {align: 'end', hideHeader: true, maxWidth: '15%', showDivider: true},
    },
    {key: 'email'},
    {key: 'size', props: {align: 'end'}},
  ];

  const tableRenderers = {
    email: ({item}) => <EnDashDefaultContent>{item.userName}</EnDashDefaultContent>,
    folder: ({item}) => <FolderIconWithName>{formatName(intl, item)}</FolderIconWithName>,
    size: ({item}) => formatStorageForDisplay(intl, item.quota.consumed),
    viewDetails: ({item}) => getViewDetailsDrawerTrigger({item}),
  };

  return (
    <Subpage isBumpered={!!error} isLoading={isLoading && !isInitialLoadDone}>
      <OverlayWait isLoading={isLoading || isDeleting} showContent={isInitialLoadDone} size="M">
        {isInitialLoadDone && (
          <TableSection
            id="inactive-user-folders-table"
            isServerError={error}
            items={userFolderList.items}
            onTableSectionChange={onTableSectionChange}
            pageNumber={currentPage}
            // hide page size dropdown (https://jira.corp.adobe.com/browse/ONESIE-35717)- note this is not officially supported by Table Section
            pageSizeOptions={false}
            selectionMode={isReadOnly() ? SELECTION_MODE.NONE : SELECTION_MODE.MULTIPLE}
            totalPages={totalPages}
          >
            <TableActions>
              <PermanentlyDeleteButton onCTA={permanentDeleteUserFolders} />
            </TableActions>
            <TableSectionTable
              aria-label={intl.formatMessage({id: 'storage.individualUserFolders.table.ariaLabel'})}
              columnDescriptor={tableColumnDescriptor}
              columnNamespace="storage.individualUserFolders.column"
              noItemsFoundContentMessage={intl.formatMessage({
                id: 'storage.individualUserFolders.table.inactiveUsers.noItemsFoundContentMessage',
              })}
              renderers={tableRenderers}
            />
          </TableSection>
        )}
      </OverlayWait>
    </Subpage>
  );
};

export default InactiveUsersSubpage;
