import {CountryList, DirectoryUser} from '@admin-tribe/acsc';
import {DetailSection, getMemberType, getMemberTypeLabel} from '@admin-tribe/acsc-ui';
import {Grid, Text} from '@adobe/react-spectrum';
import {EN_DASH} from '@pandora/react-table-section';
import {toJS} from 'mobx';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

// Styling for labels.
const labelStyle = {
  color: 'var(--color-grey-700)',
};

const DirectoryUserDetailsSection = ({member}) => {
  const intl = useIntl();

  const [countryName, setCountryName] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  // Get the user country name
  // While waiting it will show the loading spinner.
  useEffect(() => {
    let isMounted = true;

    const getCountry = async () => {
      try {
        const response = await CountryList.get({returnPromise: true});
        const countries = toJS(response);

        const userCountryName = countries?.items?.find(
          (country) => country.countryCode === member.countryCode
        )?.countryName;

        if (isMounted) {
          setCountryName(userCountryName);
        }
      } catch {
        if (isMounted) {
          setHasError(true);
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    getCountry();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- run only on mount
  }, []);

  const memberType = getMemberType(member);
  const memberTypeLabel = getMemberTypeLabel(intl, memberType);

  const detailsFields = [
    {key: 'type', value: memberTypeLabel},
    {key: 'username', value: member.userName},
    {key: 'firstName', value: member.firstName},
    {key: 'lastName', value: member.lastName},
    {key: 'email', value: member.email},
    {key: 'region', value: countryName},
  ];

  return (
    <DetailSection
      isLoading={isLoading}
      showError={hasError}
      title={intl.formatMessage({
        id: 'common.userDetailsSection.title',
      })}
    >
      {!isLoading && !hasError && (
        <Grid
          columnGap="size-200"
          columns={['size-1250', 'size-3000']}
          marginTop="size-100"
          rowGap="size-100"
          rows={['auto']}
        >
          {detailsFields.map(({key, value}) => (
            <React.Fragment key={key}>
              <Text data-testid={`${key}-key`} UNSAFE_style={labelStyle}>
                {intl.formatMessage({
                  id: `users.directoryUsersDrawer.userDetailsSection.${key}.label`,
                })}
              </Text>
              <Text data-testid={`${key}-value`}>{value ?? EN_DASH}</Text>
            </React.Fragment>
          ))}
        </Grid>
      )}
    </DetailSection>
  );
};

DirectoryUserDetailsSection.propTypes = {
  /**
   * The directory user.
   */
  member: PropTypes.instanceOf(DirectoryUser).isRequired,
};

export default DirectoryUserDetailsSection;
