import {feature} from '@admin-tribe/acsc';
import {generatePath, redirect} from 'react-router-dom';

import rootStore from 'core/RootStore';
import {canViewQuickAssignModalInFirstSessionAccess} from 'core/products/access/productAccess';
import {buildDefaultSectionRedirectLoader} from 'core/router/loaders';
import {
  populateAddProductsModalDataLoader,
  populateAssignProductsModalDataLoader,
} from 'core/router/sharedLoaders';
import {
  PATH_OVERVIEW_ADD_PRODUCTS,
  PATH_OVERVIEW_ASSIGN_PRODUCTS,
  PATH_OVERVIEW_QUICK_ASSIGN_PRODUCTS,
} from 'features/overview/routing/overviewPaths';
import {PATH_ADD_PRODUCTS, PATH_ASSIGN_PRODUCTS} from 'features/products/routing/productsPaths';

// variable to track if the user has been redirected to quick assign once if they
// meet conditions to see it for the first time.
let viewQuickAssign = null;

/**
 * Loader for handling /:orgId/overview/add-products. This can be replaced with
 * `populateAddProductsModalDataLoader` when the Angular Add Products flow is removed.
 * @param {import('react-router-dom').LoaderFunctionArgs} loaderData
 * @returns {Response | null}
 */
const addProductsLoader = (loaderData) => {
  const addProductsModalDeeplinkData = populateAddProductsModalDataLoader();
  if (addProductsModalDeeplinkData.openAddProductsModalFromDeeplink) {
    return addProductsModalDeeplinkData;
  }

  // Redirect to the products silo where the Angular page will handle launching the Add Products flow
  // Having temp_react_port_product_overview on will break this as the React version of the products
  // page cannot open the Angular Add Products flow
  return buildDefaultSectionRedirectLoader(
    PATH_OVERVIEW_ADD_PRODUCTS,
    generatePath(PATH_ADD_PRODUCTS, {orgId: rootStore.organizationStore.activeOrgId}),
    {withQueryParams: true}
  )(loaderData);
};

/**
 * Loader for handling /:orgId/overview/assign-products.
 * @param {import('react-router-dom').LoaderFunctionArgs} loaderData
 * @returns {Response | null}
 */
const assignProductsLoader = (loaderData) => {
  const assignProductsModalDeeplinkData = populateAssignProductsModalDataLoader();
  if (assignProductsModalDeeplinkData.openAssignProductsModalFromDeeplink) {
    return assignProductsModalDeeplinkData;
  }

  return buildDefaultSectionRedirectLoader(
    PATH_OVERVIEW_ASSIGN_PRODUCTS,
    generatePath(PATH_ASSIGN_PRODUCTS, {orgId: rootStore.organizationStore.activeOrgId}),
    {withQueryParams: true}
  )(loaderData);
};

/**
 * Loader to determine whether or not the user should be redirected to quick assign.
 * This should only redirect on the first time the user accesses the overview page under
 * specific conditions.
 *
 * @returns {redirect | null}
 */
const overviewLoader = async ({request}) => {
  // first time checking, set to false if cannot view so that access checks will be done only once
  if (viewQuickAssign === null) {
    const canView = await canViewQuickAssignModalInFirstSessionAccess();
    viewQuickAssign = feature.isEnabled('temp_standalone_quick_assign') && canView;
  }

  if (viewQuickAssign) {
    viewQuickAssign = false; // set to false after first redirect to quick assign.

    const requestUrl = new URL(request.url);
    const requestParamsAsObject = Object.fromEntries(requestUrl.searchParams);

    // go to welcome by default
    const params = new URLSearchParams({
      init: 'welcome',
      wts: 'single-seat',
      ...requestParamsAsObject,
    });

    return redirect(
      generatePath(`${PATH_OVERVIEW_QUICK_ASSIGN_PRODUCTS}?${params.toString()}`, {
        orgId: rootStore.organizationStore.activeOrgId,
      })
    );
  }
  return null;
};

export {addProductsLoader, assignProductsLoader, overviewLoader};
