import {EVENT_ACTION, dispatchUiEventAnalytics, eventBus} from '@admin-tribe/acsc';

import {
  goToIndividualUserFolders,
  goToReports,
  goToSharedReports,
  goToUserReports,
} from 'features/storage/routing/navigation-callbacks/navigationCallbacks';

/**
 * @description Callback function used when navigating away from the storage overview page
 * @param {String} newState - The new state to navigate to
 */
const storageOverviewNavCallback = (newState) => {
  if (newState === 'add-storage') {
    dispatchUiEventAnalytics({
      eventAction: EVENT_ACTION.CLICK,
      eventName: 'addStorage',
    });
    eventBus.emit('global-modals-open', {
      componentName: 'appAddProductsModal2',
      version: 2,
    });
  } else if (newState === 'individual-user-folders') {
    goToIndividualUserFolders();
  }
};

/**
 * @description Callback function used when navigating away from the storage reports page
 * @param {String} folderType - The type of folder view to navigate to ('user' or 'assets') or 'reports' for the main reports page
 */
const storageReportsNavCallback = (folderType) => {
  switch (folderType) {
    case 'reports':
      goToReports();
      break;
    case 'assets':
      goToSharedReports();
      break;
    case 'users':
      goToUserReports();
      break;
    default:
      throw new Error(`Invalid folder type: ${folderType}`);
  }
};

export {storageOverviewNavCallback, storageReportsNavCallback};
