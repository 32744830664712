import {Button, Flex, Heading} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';

import SectionView from 'features/settings/common/components/section-view/SectionView';
import {useDirectorySetupContext} from 'features/settings/components/directory/DirectorySetupContext';

import './ConfigureAzureIdp.pcss';

const ConfigureAzureIdp = observer(({onLoginToAzure}) => {
  const {directorySetupStore} = useDirectorySetupContext();

  return (
    <>
      <p>
        <FormattedMessage id="settings.directories.configureAzureIdp.description" />
      </p>

      <SectionView>
        <Heading level={3} marginTop={0}>
          <FormattedMessage id="settings.directories.configureAzureIdp.azureConfigSection.title" />
        </Heading>

        <dl styleName="azure-idp-information">
          <Flex marginBottom="size-100">
            <dt styleName="label">
              <FormattedMessage id="settings.directories.configureAzureIdp.labels.directoryName" />
            </dt>
            <dd styleName="value">{directorySetupStore.directoryStore.name}</dd>
          </Flex>

          <Flex marginBottom="size-100">
            <dt styleName="label">
              <FormattedMessage id="settings.directories.configureAzureIdp.labels.userLoginSetting" />
            </dt>
            <dd styleName="value">
              <FormattedMessage id="settings.directories.configureAzureIdp.values.upn" />
            </dd>
          </Flex>

          <Flex marginBottom="size-100">
            <dt styleName="label">
              <FormattedMessage id="settings.directories.configureAzureIdp.labels.tenantId" />
            </dt>
            <dd styleName="value">{directorySetupStore.idp.aadOrganization}</dd>
          </Flex>

          <Flex marginBottom="size-100">
            <dt styleName="label">
              <FormattedMessage id="settings.directories.configureAzureIdp.labels.protocol" />
            </dt>
            <dd styleName="value">
              <FormattedMessage id="settings.directories.configureAzureIdp.values.openIdConnect" />
            </dd>
          </Flex>
        </dl>
      </SectionView>

      {directorySetupStore.directoryStore.hasAzureIdp && (
        <SectionView marginTop="size-250">
          {!directorySetupStore.isEditingAzureIdp && (
            <>
              <Heading level={3} marginTop={0}>
                <FormattedMessage id="settings.directories.configureAzureIdp.updateTenantSection.title" />
              </Heading>
              <p>
                <FormattedMessage id="settings.directories.configureAzureIdp.updateTenantSection.description" />
              </p>
            </>
          )}

          {directorySetupStore.isEditingAzureIdp && (
            <>
              <Heading level={3} marginTop={0}>
                <FormattedMessage id="settings.directories.configureAzureIdp.updateTenantSection.title" />
              </Heading>

              <dl styleName="azure-idp-information">
                <Flex marginBottom="size-100">
                  <dt styleName="label">
                    <FormattedMessage id="settings.directories.configureAzureIdp.updateTenantSection.labels.oldTenantId" />
                  </dt>
                  <dd styleName="value">
                    <strong>{directorySetupStore.idp.aadOrganization}</strong>
                  </dd>
                </Flex>

                <Flex marginBottom="size-100">
                  <dt styleName="label">
                    <FormattedMessage id="settings.directories.configureAzureIdp.updateTenantSection.labels.newTenantId" />
                  </dt>
                  <dd styleName="value">
                    <strong>{directorySetupStore.newTenantId}</strong>
                  </dd>
                </Flex>
              </dl>

              <p>
                <FormattedMessage id="settings.directories.configureAzureIdp.updateTenantSection.checkDataDescription" />
              </p>
            </>
          )}

          <Button data-testid="login-to-azure-button" onPress={onLoginToAzure} variant="secondary">
            <FormattedMessage id="settings.directories.configureAzureIdp.updateTenantSection.buttons.loginToAzure" />
          </Button>
        </SectionView>
      )}
    </>
  );
});

ConfigureAzureIdp.propTypes = {
  /**
   * This callback is used to handle "Login to Azure" button press.
   * It's currently used only in update azure IdP flow.
   */
  onLoginToAzure: PropTypes.func,
};

export default ConfigureAzureIdp;
