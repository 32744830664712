import {feature} from '@admin-tribe/acsc';
import {generatePath} from 'react-router-dom';

import {buildDefaultSectionRedirectLoader, throwLoaderNoAccessError} from 'core/router/loaders';
import {canManageTickets, canViewSupport} from 'core/support/supportAccess';
import {goToExl, goToExlDetails, isDxOnly} from 'features/support/utils/SupportUtils';

import {
  PATH_EXPERT_SESSIONS,
  PATH_EXPERT_SESSIONS_OPEN,
  PATH_SUPPORT_CASES,
  PATH_SUPPORT_CASES_OPEN,
} from './supportPaths';

/**
 * Redirects from PATH_SUPPORT_CASES (/:orgId/support-cases) to the appropriate sub-route (/:orgId/support-cases/open).
 * @param {import('react-router-dom').LoaderFunctionArgs} params, request
 * @returns {Response}
 */
const supportCasesSectionLoader = ({params: {orgId}, request}) => {
  if (canManageTickets()) {
    if (feature.isEnabled('temp_support_exl_details_redirect') && isDxOnly()) {
      const pattern = /(?:\/sup{2}ort){2}-cases\/(E-\d+$)/;
      const result = request.url.match(pattern);
      // checks if path is for details page else redirects to home page
      if (result && result[1]) {
        goToExlDetails(result[1]);
      } else {
        goToExl();
      }
      return null;
    }

    const toPath = generatePath(PATH_SUPPORT_CASES_OPEN, {
      orgId,
    });
    return buildDefaultSectionRedirectLoader(PATH_SUPPORT_CASES, toPath)({request});
  }
  throwLoaderNoAccessError(request);

  return null;
};

/**
 * Redirects from PATH_EXPERT_SESSIONS (/:orgId/expert-sessions) to the appropriate sub-route (/:orgId/expert-sessions/open).
 * @param {import('react-router-dom').LoaderFunctionArgs} params, request
 * @returns {Response}
 */
const expertSessionsSectionLoader = ({params: {orgId}, request}) => {
  if (canManageTickets()) {
    if (feature.isEnabled('temp_support_exl_details_redirect') && isDxOnly()) {
      const pattern = /\/support\/expert-sessions\/(E-\d+$)/;
      const result = request.url.match(pattern);
      // checks if path is for details page else redirects to home page
      if (result && result[1]) {
        goToExlDetails(result[1]);
      } else {
        goToExl();
      }
      return null;
    }
    const toPath = generatePath(PATH_EXPERT_SESSIONS_OPEN, {
      orgId,
    });
    return buildDefaultSectionRedirectLoader(PATH_EXPERT_SESSIONS, toPath)({request});
  }
  throwLoaderNoAccessError(request);

  return null;
};

/**
 * Revalidate the redirection from PATH_SUPPORT_CASES (/:orgId/support-cases) to the appropriate sub-route (/:orgId/support-cases/open).
 * @param {import('react-router-dom').LoaderFunctionArgs} nextUrl
 * @returns {Response}
 */
const supportCasesShouldRevalidate = ({nextUrl}) => nextUrl.pathname.endsWith('support-cases');

/**
 * Revalidate the redirection from PATH_EXPERT_SESSIONS (/:orgId/expert-sessions) to the appropriate sub-route (/:orgId/expert-sessions/open).
 * @param {import('react-router-dom').LoaderFunctionArgs} nextUrl
 * @returns {Response}
 */
const expertSessionsShouldRevalidate = ({nextUrl}) => nextUrl.pathname.endsWith('expert-sessions');

/**
 * Support Summary Section Loader, called with the /support route.
 * @param {import('react-router-dom').LoaderFunctionArgs} params, request
 * @returns {Response}
 */
const supportSummarySectionLoader = ({request}) => {
  if (!canViewSupport()) {
    throwLoaderNoAccessError(request);
  }
  if (isDxOnly()) {
    const pattern = /\/support\/?$/i;
    if (pattern.test(request.url)) {
      goToExl();
    }
  }
  return null;
};

export {
  expertSessionsSectionLoader,
  expertSessionsShouldRevalidate,
  supportCasesSectionLoader,
  supportCasesShouldRevalidate,
  supportSummarySectionLoader,
};
