import {Product} from '@admin-tribe/acsc';
import {Cell, Column, Row, TableBody, TableHeader, TableView} from '@adobe/react-spectrum';
import {Drawer} from '@pandora/react-drawer';
import {useId} from '@react-aria/utils';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import DeviceRenewalsDrawerCell from 'common/components/device-renewals-drawer/DeviceRenewalsDrawerCell';
import DeviceRenewalsDrawerHeader from 'common/components/device-renewals-drawer/device-renewals-drawer-header/DeviceRenewalsDrawerHeader';
/**
 * Contents of table cell in list of devices that need renewal
 */
const DeviceRenewalsDrawer = ({product, devices}) => {
  const intl = useIntl();
  const headerId = useId();

  return (
    <Drawer aria-labelledby={headerId}>
      <DeviceRenewalsDrawerHeader product={product} />
      <br />
      <TableView
        aria-label={intl.formatMessage({id: 'products.devices.renewalsDrawer.tableLabel'})}
      >
        <TableHeader>
          <Column key="deviceName" data-testid="deviceName">
            {intl.formatMessage({id: 'products.devices.renewalsDrawer.deviceName'})}
          </Column>
          <Column key="activationDate" data-testid="activation-date">
            {intl.formatMessage({id: 'products.devices.renewalsDrawer.activationDate'})}
          </Column>
        </TableHeader>
        <TableBody items={devices}>
          {(device) => (
            <Row key={device.deviceId}>
              <Cell>
                <DeviceRenewalsDrawerCell value={device.deviceName} />
              </Cell>
              <Cell>
                <DeviceRenewalsDrawerCell value={device.lastActivityDate} />
              </Cell>
            </Row>
          )}
        </TableBody>
      </TableView>
    </Drawer>
  );
};

DeviceRenewalsDrawer.propTypes = {
  /**
   * list of selected devices name to renewal
   */
  devices: PropTypes.arrayOf(
    PropTypes.shape({
      deviceId: PropTypes.string.isRequired,
      deviceName: PropTypes.string.isRequired,
      lastActivityDate: PropTypes.string.isRequired,
    })
  ).isRequired,
  /**
   * A Product instance
   */
  product: PropTypes.instanceOf(Product).isRequired,
};

export default DeviceRenewalsDrawer;
