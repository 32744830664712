import {
  AuthenticatedUser,
  Locale,
  configStore,
  dispatchUiEventAnalytics,
  log,
  navBus,
} from '@admin-tribe/acsc';
import {showSuccess as showSuccessToast} from '@admin-tribe/acsc-ui';
import {useIframeMessage} from '@pandora/react-app-context-iframe-message-handler';
import {MessageType, SupportedSystemMessageTypes} from '@pandora/react-app-context-provider';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {generatePath} from 'react-router-dom';

import OverlayWait from 'common/components/wait/OverlayWait';
import rootStore from 'core/RootStore';
import chatProvider from 'core/services/chat/chatProvider';
import {PATH_OVERVIEW_QUICK_ASSIGN_PRODUCTS} from 'features/overview/routing/overviewPaths';

import AddProductModalWrapper from '../add-product-modal-wrapper/AddProductModalWrapper';

import {
  ContractRenewalMiniApp,
  MiniAppContext,
  MiniAppMessageTypes,
  OptInMessageTypes,
  RenewalMessageTypes,
  StepType,
  WorkflowType,
} from './ContractRenewal.constants';
import styles from './ContractRenewalWrapper.pcss';

const CONTRACT_RENEWAL_MINI_APP_URL_ENV_PARAM = 'services.contractRenewalMiniApp';

/**
 * @description Represents a wrapper for contract renewal mini app
 */
const ContractRenewalWrapper = () => {
  const {organizationStore} = rootStore;
  const {url} = configStore.get(CONTRACT_RENEWAL_MINI_APP_URL_ENV_PARAM);
  const orgId = organizationStore.activeOrgId;

  // Prepare for the Locale information, AEM takes locale in BCP-47 format
  const localeLanguage = Locale.get().activeLanguageBCP47Code;

  // Get user information
  const userId = AuthenticatedUser.get().getId();

  // Default iFrame height for the contract is not in renewal window
  const [iFrameHeight, setIFrameHeight] = useState('183px');
  const [loading, setLoading] = useState(true);
  let renewalParam;

  // Passed renewal paramter to mini app url to open opt-in dialog in case of deep link
  // e.g. https://stage.adminconsole.adobe.com/1D931BE964391AC80A494110@AdobeOrg/accounts?renewal=opt-in
  // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- glo62553@ update
  // istanbul ignore else
  if (typeof window !== 'undefined') {
    const urlParams = new URLSearchParams(window.location.search);
    const param = urlParams.get('renewal');
    renewalParam = param ? `&renewal=${param}` : '';
  }

  // Mini App Parameters https://wiki.corp.adobe.com/display/BPS/Contract+Renewal+Mini+App+-+Integration+guide#ContractRenewalMiniAppIntegrationguide-Parameter
  const miniAppUrl = `${url}?orgId=${orgId}&lang=${localeLanguage}&ctx=${MiniAppContext.IN_WEB}&userId=${userId}${renewalParam}`;

  // Determine to launch Contract Renewal Order workflow in modal dialog
  const [launchRenewalOrderModal, setLaunchRenewalOrderModal] = useState(false);

  // By default to update renewal order
  const [step, setStep] = useState(StepType.SELECT_PRODUCT);
  const [workflow, setWorkflow] = useState(WorkflowType.UPDATE_RENEW);

  const intl = useIntl();

  // View or Update Renewal Order in Add Product Mini App
  const RenewalOrderInAddProductMiniApp = React.useCallback(() => {
    // Launch modal dialog when user clicks the CTA
    if (!launchRenewalOrderModal) return null;

    return (
      <AddProductModalWrapper
        chat={chatProvider}
        onClose={() => setLaunchRenewalOrderModal(false)}
        step={step}
        workflow={workflow}
      />
    );
  }, [launchRenewalOrderModal, step, workflow]);

  // User can update or view the renewal order details
  const renewalOrderHandler = (data) => {
    // To view the renewal order
    if (data?.type === RenewalMessageTypes.VIEW) {
      dispatchUiEventAnalytics({
        eventAction: 'click',
        eventName: 'viewDetails',
      });
      setWorkflow(WorkflowType.VIEW_RENEW);
      setStep(StepType.REVIEW_ORDER);
    }
    // To view update renewal order
    if (data?.type === RenewalMessageTypes.UPDATE) {
      dispatchUiEventAnalytics({
        eventAction: 'click',
        eventName: 'updatePlan',
      });
      setWorkflow(WorkflowType.UPDATE_RENEW);
      setStep(StepType.SELECT_PRODUCT);
    }

    // When the user selects to view/update renewal plan and if the account has unassigned licenses,
    // the app shows 2 options. One of them is "No thanks, Continue".
    // If user selects that it means, s/he chose to see the renewal order and did not want to
    // check unassigned licenses at that time.
    // Hence, unassignedLicensesSummaryModalNoThanks is reported to analytics.
    dispatchUiEventAnalytics({
      eventAction: 'click',
      eventName: 'unassignedLicensesSummaryModalNoThanks',
    });

    // Launch Renewal Order workflow in Add Product Mini App
    setLaunchRenewalOrderModal(true);
  };

  // Launch the Quick Assign Dialog
  const renderQuickAssignModel = () => {
    dispatchUiEventAnalytics({
      eventAction: 'click',
      eventName: 'unassignedLicensesSummaryModalAssignNow',
    });
    navBus.navigate(generatePath(PATH_OVERVIEW_QUICK_ASSIGN_PRODUCTS, {orgId}));
  };
  // Resize Contract Renewal Mini App Window size in pixel
  const resetContractRenewalWindowSize = (data) => {
    setIFrameHeight(`${data.height}px`);
  };

  const appLoadedHandler = () => {
    // App is ready so loading is done
    setLoading(false);
  };

  const renderOptInHandler = (data) => {
    // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- thean@ to update
    // istanbul ignore else
    if (data?.type === OptInMessageTypes.SUCCESS) {
      const products = data?.offers;
      const noOfProducts = products?.length;
      const productName = products[0]?.longName;
      const message = intl.formatMessage(
        {
          id: 'common.contracts.optIn.toast.successMessage',
        },
        {noOfProducts, productName}
      );

      showSuccessToast(message);
      dispatchUiEventAnalytics({
        eventName: 'adminConsole:optIn:AutoRenewSuccessMsg',
        interaction: {
          impression: 'optIn:AutoRenewSuccessMsg|adminConsole|renewal',
        },
      });
    }
  };

  // Message handler to process just the System events from Contract Renewal Mini App
  const systemMessageHandler = ({data, subType}) => {
    switch (subType) {
      case SupportedSystemMessageTypes.APP_LOADED:
        appLoadedHandler();
        break;
      case MiniAppMessageTypes.RENEWAL:
        renewalOrderHandler(data);
        break;
      case MiniAppMessageTypes.ASSIGN_LICENSE:
        renderQuickAssignModel();
        break;
      case MiniAppMessageTypes.RESIZE:
        resetContractRenewalWindowSize(data);
        break;
      case MiniAppMessageTypes.OPT_IN:
        renderOptInHandler(data);
        break;
      default:
        break;
    }
  };

  // Message handler to process the events from Contract Renewal Mini App
  // Messages are defined at https://wiki.corp.adobe.com/display/BPS/Contract+Renewal+Mini+App+-+Integration+guide#ContractRenewalMiniAppIntegrationguide-Message
  const messageHandler = ({app, type, subType, data}) => {
    // Ignore the message if it is not from Contract Renewal Mini App
    if (app !== ContractRenewalMiniApp) return;

    log.info('Message from Contract Renewal Mini App:', {data, subType, type});

    if (type === MessageType.SYSTEM) {
      systemMessageHandler({data, subType});
    } else if (type === MessageType.OPEN_URL) {
      // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- opens link
      window.open(data.externalUrl, data.target);
    }
  };

  // Consume message from Contract Renewal Mini App iframe
  useIframeMessage(messageHandler);

  // Spinner over the page
  const Wait = React.useCallback(() => {
    if (loading) {
      return <OverlayWait isLoading size="md" />;
    }

    return null;
  }, [loading]);

  return (
    <div>
      <Wait />
      <iframe
        className={styles['contract-renewal-mini-app-iframe']}
        data-testid="contract-renewal"
        height={iFrameHeight}
        src={miniAppUrl}
        title="Contract Renewal"
      />
      <RenewalOrderInAddProductMiniApp />
    </div>
  );
};

export default ContractRenewalWrapper;
