import {
  LEVEL,
  MIGRATION_TYPE,
  MigrationList,
  PasswordPolicy,
  feature,
  isMigrationListT2eClean,
  log,
} from '@admin-tribe/acsc';
import {
  GoUrl,
  OverlayWait,
  Page,
  PageBanner,
  PageContent,
  PageHeader,
  useStore,
} from '@admin-tribe/acsc-ui';
import React, {useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';

import AppConstants from 'common/services/AppConstants';
import {AuthSettingsContextProvider} from 'features/settings/components/auth-settings-page/AuthSettingsContext';
import AdvancedSettingsSection from 'features/settings/components/auth-settings-page/advanced-settings-section/AdvancedSettingsSection';
import SocialProvidersSection from 'features/settings/components/auth-settings-page/social-providers-section/SocialProvidersSection';
import TwoStepVerificationSection from 'features/settings/components/auth-settings-page/two-step-verification-section/TwoStepVerificationSection';
import AuthenticationPoliciesStore from 'features/settings/stores/AuthenticationPoliciesStore';

import PasswordSettingsSection from './password-settings-section/PasswordSettingsSection';

/**
 * Defines the authentication settings page under the settings tab.
 */
const AuthSettingsPage = () => {
  const intl = useIntl();
  const isCurrentRef = useRef(true);
  // Replace with useStore when migrationList store is available
  const [migrationList, setMigrationList] = useState();
  const [isMigrationListLoading, setIsMigrationListLoading] = useState(false);
  // Replace with useStore when passwordPolicy store is available
  const [policy, setPolicy] = useState();
  const [isPolicyLoading, setIsPolicyLoading] = useState(false);
  const [isLevelSupported, setIsLevelSupported] = useState(true);

  const isValidPasswordLevel = (level) => Object.values(LEVEL).includes(level);

  const onPasswordPolicyChange = () => {
    setIsLevelSupported(isValidPasswordLevel(policy?.passwordPolicy));
  };

  const isLoading = () => isMigrationListLoading || isPolicyLoading;

  const authenticationPoliciesStore = useStore(() => new AuthenticationPoliciesStore());

  // Load authentication policies data needed for the component
  useEffect(() => {
    authenticationPoliciesStore.load();
  }, [authenticationPoliciesStore]);

  // Remove when service stores are available
  useEffect(() => {
    const fetchMigrationList = async () => {
      try {
        setIsMigrationListLoading(true);
        // Logic pulled out from OrganizationManager
        const types = [
          MIGRATION_TYPE.ESM_TYPE1,
          MIGRATION_TYPE.MA_LEGACY_TO_ADMIN_CONSOLE,
          MIGRATION_TYPE.VIP2DIRECT,
          MIGRATION_TYPE.VIPMP,
          MIGRATION_TYPE.T2E,
        ];

        const migrations = await MigrationList.get({
          orgId: AppConstants.orgId,
          types,
        });
        if (isCurrentRef.current) {
          setMigrationList(migrations);
        }
      } catch (error) {
        log.error('Error getting MigrationList: ', error);
      } finally {
        if (isCurrentRef.current) {
          setIsMigrationListLoading(false);
        }
      }
    };
    const fetchPasswordPolicy = async () => {
      try {
        setIsPolicyLoading(true);
        const currPolicy = await PasswordPolicy.get(AppConstants.orgId);
        if (isCurrentRef.current) {
          setPolicy(currPolicy);
          setIsLevelSupported(isValidPasswordLevel(currPolicy?.passwordPolicy));
        }
      } catch (error) {
        log.error('Error getting PasswordPolicy: ', error);
      } finally {
        if (isCurrentRef.current) {
          setIsPolicyLoading(false);
        }
      }
    };

    isCurrentRef.current = true;
    // Remove when MigrationList store is available
    fetchMigrationList();
    // Remove when PasswordPolicy store is available
    fetchPasswordPolicy();
    return () => {
      isCurrentRef.current = false;
    };
  }, []);

  return (
    <Page data-testid="auth-settings-page">
      {!isLevelSupported && (
        <PageBanner data-testid="unsupported-level-banner" variant="error">
          {intl.formatMessage(
            {id: 'settings.authSettings.banner.invalidPolicyWarning'},
            {
              goUrl: (str) => <GoUrl name="ac_authsettings">{str}</GoUrl>,
            }
          )}
        </PageBanner>
      )}
      <PageHeader
        title={intl.formatMessage({
          id: 'settings.authSettings.title',
        })}
      />
      <PageContent>
        <OverlayWait isLoading={isLoading()} showContent>
          {migrationList && policy && (
            <PasswordSettingsSection
              isT2e={isMigrationListT2eClean(migrationList)}
              onPasswordPolicyChange={onPasswordPolicyChange}
              policy={policy}
            />
          )}
        </OverlayWait>
        <AuthSettingsContextProvider authenticationPoliciesStore={authenticationPoliciesStore}>
          {feature.isEnabled('temp_two_step_verification_auth_settings') && (
            <TwoStepVerificationSection />
          )}
          {feature.isEnabled('temp_denied_social_providers') && <SocialProvidersSection />}
          {feature.isEnabled('temp_advanced_auth_settings_section') && <AdvancedSettingsSection />}
        </AuthSettingsContextProvider>
      </PageContent>
    </Page>
  );
};

AuthSettingsPage.propTypes = {};

export default AuthSettingsPage;
