import {Cache, Directory, UserGroup} from '@admin-tribe/acsc';
import {generatePath} from 'react-router-dom';

import {BULK_OPERATION_MODE} from 'common/components/bulk-operation/bulk-operation-utils/bulkOperationConstants';
import {canSwitchIdentityTypes, canViewDirectories} from 'core/directories/access/directoryAccess';
import {buildDefaultSectionRedirectLoader, throwLoaderNoAccessError} from 'core/router/loaders';
import {canViewUsers} from 'core/user/access/userAccess';
import {
  canViewUserGroupDetails,
  canViewUserGroupDetailsAdmins,
  canViewUserGroupDetailsAssignedProductProfiles,
  canViewUserGroupDetailsBulkOperations,
  canViewUserGroupDetailsUsers,
} from 'core/user-group/access/userGroupAccess';
import {
  PATH_ADMINISTRATORS,
  PATH_USERS,
  PATH_USER_GROUP_DETAILS,
  PATH_USER_GROUP_DETAILS_USERS,
  USER_GROUP_DETAILS_TAB_NAV,
} from 'features/users/routing/usersPaths';

// Short-term cache for one loaderData object to be shared between tabs/subpages for a user group.
// The same userGroup must be returned for all the subpages so that UserGroupPage doesn't rerender
// when switching between tabs.
// The cache key is the userGroupId.
const userGroupLoaderCache = new Cache();

/**
 * Redirects from PATH_USERS (/:orgId/users) to the appropriate sub-route, if any.
 * @param {import('react-router-dom').LoaderFunctionArgs} params, request
 * @returns {Response}
 */
const defaultSectionLoader = ({params: {orgId}, request}) => {
  // All users have access to the Users silo.
  // Deployment admins get the Administrators page, everyone else gets Users
  // Redirects to /:orgId/users/administrators ONLY IF the request is on /:orgId/users
  if (!canViewUsers()) {
    const toPath = generatePath(PATH_ADMINISTRATORS, {
      orgId,
    });
    return buildDefaultSectionRedirectLoader(PATH_USERS, toPath)({request});
  }

  return null;
};

/**
 * Method to check whether the admin has access to 'Directory users' or 'Directory user details' routes.
 *
 * @param {{request: Request, params: {}}} loaderData.
 *   React router loader data -params, request.
 * @returns the Directory model if the admin has access
 *
 * @throws
 *  loader access error with status 403 if admin does not have access or access can't be determined
 */
const directoryUsersSectionLoader = async ({params: {directoryId, orgId}, request}) => {
  if (!canViewDirectories()) {
    throwLoaderNoAccessError(request);
  }

  const directory = await Directory.get({id: directoryId, orgId});

  return {directory};
};

/**
 * Method to check whether the admin has access to User group details bulk operation routes.
 *
 * @param {{request: Request, params: {}}} loaderData.
 *   React router loader data -params, request.
 * @returns null if the admin has access
 *
 * @throws
 *  loader access error with status 403 if admin does not have access or access can't be determined
 *  OR error from UserGroup.get() which the PageError should handle
 */
async function userGroupDetailsBulkOperationsLoader({params: {orgId, userGroupId}, request}) {
  let hasAccess = false;

  if (canViewUserGroupDetails()) {
    // If this throws, let the PageError handle it.
    const userGroup = await UserGroup.get(orgId, userGroupId);
    hasAccess = canViewUserGroupDetailsBulkOperations(userGroup);
  }

  if (!hasAccess) {
    throwLoaderNoAccessError(request);
  }

  return null;
}

/**
 * Returns a function which acts as a React Router loader to check the access of the given
 * User group details page or subpage.
 *
 * The signature of the returned function is:
 *
 * @param {USER_GROUP_DETAILS_TAB_NAV} tabNav. tabNav is one of the sub-nav tabs on the 'User group' details page.
 * @returns {Object} The loaderData object for this path will contain {tabNavAccess, userGroup}.
 *   tabNavAccess is an Object.
 *    keys are USER_GROUP_DETAILS_TAB_NAV
 *    value is Boolean which represents whether or not the admin has access to that tab
 *   userGroup - the user group object for this path
 * @throws
 *  loader access error with status 403 if admin does not have access or access can't be determined
 *  OR error from UserGroup.get() which the PageError should handle
 */
const userGroupDetailsLoader =
  (tabNav) =>
  // the loader function args
  async ({params: {orgId, userGroupId}, request}) => {
    let tabNavAccess = {};

    let hasAccess = false;
    let loaderData, userGroup;

    if (canViewUserGroupDetails()) {
      // It is imperative to return the same instance of the user group for each one of the tabs/subpages
      // so that the entire UserGroupPage doesn't re-render when switching tabs.
      loaderData = userGroupLoaderCache.get(userGroupId);
      if (!loaderData) {
        // If this throws, let the PageError handle it.
        userGroup = await UserGroup.get(orgId, userGroupId);

        tabNavAccess = {
          [USER_GROUP_DETAILS_TAB_NAV.ADMINS]: canViewUserGroupDetailsAdmins(userGroup),
          [USER_GROUP_DETAILS_TAB_NAV.ASSIGNED_PROFILES]:
            canViewUserGroupDetailsAssignedProductProfiles(userGroup),
          [USER_GROUP_DETAILS_TAB_NAV.USERS]: canViewUserGroupDetailsUsers(),
        };

        loaderData = {tabNavAccess, userGroup};
        userGroupLoaderCache.put(userGroupId, loaderData);
      }

      hasAccess = loaderData.tabNavAccess[tabNav];
    }

    if (!hasAccess) {
      throwLoaderNoAccessError(request);
    }

    return loaderData;
  };

/**
 * If the admin has permission to view the user group details, this redirects from
 * PATH_USER_GROUP_DETAILS to the user group details users sub-route.
 *
 * @param {import('react-router-dom').LoaderFunctionArgs} params, request
 * @returns {Response}
 * @throws
 *  loader access error with status 403 if admin does not have access
 */
const userGroupDetailsSectionLoader = ({params: {orgId, userGroupId}, request}) => {
  // When loading a user group make sure there isn't a user group cached.
  // This cache is meant to be very short-term since it is never invalidated other than by timeout.
  userGroupLoaderCache.clearAll();

  if (!canViewUserGroupDetails()) {
    throwLoaderNoAccessError(request);
  }

  const toPath = generatePath(PATH_USER_GROUP_DETAILS_USERS, {
    orgId,
    userGroupId,
  });
  return buildDefaultSectionRedirectLoader(PATH_USER_GROUP_DETAILS, toPath)({request});
};

/**
 * This route does a deep-link to the bulk operations modal on the Users page.
 * If the admin has access to the mode, the mode is passed to the UsersPage.
 * @param {import('react-router-dom').LoaderFunctionArgs} params, request
 * @returns {Object} The loaderData object for this path will contain {onInitBulkOperationMode}.
 *   onInitBulkOperationMode is one of BULK_OPERATION_MODE.
 *   Currently only BULK_OPERATION_MODE.SWITCH_IDENTITY_TYPE is implemented.
 * @throws
 *  loader access error with status 403 if admin does not have access to the modal and/or mode
 */
const usersBulkOpsModalLoader = ({params: {mode}, request}) => {
  let onInitBulkOperationMode;

  switch (mode) {
    case 'switch-identity-type':
      if (canSwitchIdentityTypes()) {
        onInitBulkOperationMode = BULK_OPERATION_MODE.SWITCH_IDENTITY_TYPE;
      }
      break;
    default:
      break;
  }

  if (!onInitBulkOperationMode) {
    throwLoaderNoAccessError(request);
  }

  return {onInitBulkOperationMode};
};

export {
  defaultSectionLoader,
  directoryUsersSectionLoader,
  userGroupDetailsBulkOperationsLoader,
  userGroupDetailsLoader,
  userGroupDetailsSectionLoader,
  userGroupLoaderCache, // exported exclusively for unit-testing
  usersBulkOpsModalLoader,
};
