import {PageBreadcrumbs, getProductDisplayName} from '@admin-tribe/acsc-ui';
import {Item} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';
import {
  goToProductDetails,
  goToProductGroupDetails,
  goToProducts,
} from 'features/products/routing/navigation-callbacks/navigationCallbacks';

import {
  ALL_PRODUCTS_AND_SERVICES_ITEM,
  LICENSE_GROUP_ITEM,
  PRODUCT_DETAILS_ITEM,
  PRODUCT_GROUP_ITEM,
} from './ProductPageBreadcrumbConstants';

// Component for displaying the breadcrumbs shown on the ProductPage, the
// InstancesPage, and the OrgConsumablesPage.
const ProductPageBreadcrumbs = ({
  isOnProductGroupPage = false,
  isOnProductProfilePage = false,
  licenseGroupName,
  productDisplayName,
  productOrProductGroupId,
}) => {
  const intl = useIntl();

  const targetGroup = isOnProductGroupPage
    ? undefined
    : rootStore.organizationStore.orgConsumables
        .getProductGroupsOnly()
        .find((group) => group.items.find((item) => item.id === productOrProductGroupId));
  const groupDisplayName = targetGroup ? getProductDisplayName(intl, targetGroup) : undefined;

  /**
   * @description Helper to perform breadcrumb navigation for the ProductPage,
   *     InstancesPage and the OrgConsumablesPage.
   * @param {String} key - Key to get the breadcrumb action for.
   */
  const onBreadcrumbPress = (key) => {
    switch (key) {
      case ALL_PRODUCTS_AND_SERVICES_ITEM:
        goToProducts();
        break;
      case PRODUCT_GROUP_ITEM:
        goToProductGroupDetails({groupId: targetGroup.id});
        break;
      case PRODUCT_DETAILS_ITEM:
        if (isOnProductProfilePage) goToProductDetails({productId: productOrProductGroupId});
        break;
      default:
      // Do nothing if they click the current breadcrumb
    }
  };

  return (
    <PageBreadcrumbs isDisabled={false} onAction={onBreadcrumbPress}>
      <Item key={ALL_PRODUCTS_AND_SERVICES_ITEM}>
        {intl.formatMessage({id: 'products.productDetails.breadcrumbs.allProductsAndServices'})}
      </Item>
      {targetGroup && <Item key={PRODUCT_GROUP_ITEM}>{groupDisplayName}</Item>}
      <Item key={PRODUCT_DETAILS_ITEM}>{productDisplayName}</Item>
      {isOnProductProfilePage && <Item key={LICENSE_GROUP_ITEM}>{licenseGroupName}</Item>}
    </PageBreadcrumbs>
  );
};
// This is a hack so that this component will not be ignored by the Page component.
ProductPageBreadcrumbs.displayName = 'PageBreadcrumbs';
ProductPageBreadcrumbs.propTypes = {
  /**
   * Flag indicating whether this component is being used on OrgConsumablesPage
   * or the InstancesPage. Defaults to false.
   */
  isOnProductGroupPage: PropTypes.bool,
  /**
   * Flag indicating whether this component is being used on the
   * ProductProfileDetailsPage. Defaults to false.
   */
  isOnProductProfilePage: PropTypes.bool,
  /**
   * License group name to display if loaded on the product.
   */
  licenseGroupName: PropTypes.string,
  /**
   * The localized display name for the product or product group.
   */
  productDisplayName: PropTypes.string.isRequired,
  /**
   * Id of the product or product group being displayed.
   */
  productOrProductGroupId: PropTypes.string.isRequired,
};

export default ProductPageBreadcrumbs;
