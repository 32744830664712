import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import unset from 'lodash/unset';

class DigitalData {
  constructor() {
    this.analyticsData = {};
    this.analyticsDataPropertiesToDelete = [];
  }

  cleanUpProperties() {
    this.analyticsDataPropertiesToDelete.forEach((propName) => {
      unset(this.analyticsData, propName);
    });
    this.analyticsDataPropertiesToDelete = [];
  }

  createEventDataAndCleanUp() {
    const snapshotData = {
      _adobe_corpnew: this.snapshot(),
      xdm: {},
    };
    this.cleanUpProperties();
    return snapshotData;
  }

  reset() {
    this.analyticsData = {};
    this.analyticsDataPropertiesToDelete = [];
  }

  setProperty(propName, data, deleteProperty = true) {
    if (data !== undefined) {
      set(this.analyticsData, propName, data);
      if (deleteProperty) {
        this.analyticsDataPropertiesToDelete.push(propName);
      }
    }
  }

  snapshot() {
    return cloneDeep(this.analyticsData);
  }
}

const digitalData = new DigitalData();
export default digitalData;
