import {
  AuthenticatedUser,
  CONTRACT_ADMIN,
  LICENSE_ADMIN,
  ORG_ADMIN,
  PRODUCT_ADMIN,
  PRODUCT_SUPPORT_ADMIN,
  SUPPORT_ADMIN,
  USER_GROUP_ADMIN,
  feature,
  getDeveloperProducts,
} from '@admin-tribe/acsc';

import rootStore from 'core/RootStore';
import {isOrgAdmin} from 'core/organizations/access/organizationAccess';

/**
 * Returns true if there is at least one product that a developer can be
 * assigned to and the admin has a role that allows 'API credentials' to be viewed.
 *
 * @returns {Boolean} whether the user can view the API credentials page
 */
function canViewApiCredentials() {
  // AdobeIO only supports system admins for now
  return isOrgAdmin() && hasAdministerableDeveloperProducts();
}

/**
 * Returns true if there is at least one product that a developer can be
 * assigned to and the admin has a role that allows 'Developers' to be viewed.
 *
 * @returns {Boolean} whether the user can view license developer admins
 */
function canViewDevelopers() {
  const orgId = rootStore.organizationStore.activeOrgId;

  if (feature.isEnabled('temp_adobe_agent_access')) {
    return (
      (hasAdministerableDeveloperProducts() &&
        AuthenticatedUser.get()
          .getRoles()
          .anyOfForOrg(
            [
              LICENSE_ADMIN,
              ORG_ADMIN,
              CONTRACT_ADMIN,
              PRODUCT_ADMIN,
              PRODUCT_SUPPORT_ADMIN,
              SUPPORT_ADMIN,
              USER_GROUP_ADMIN,
            ],
            orgId
          )) ||
      AuthenticatedUser.get().getRoles().isActingAsAdobeAgentForOrg(orgId)
    );
  }

  return (
    hasAdministerableDeveloperProducts() &&
    AuthenticatedUser.get()
      .getRoles()
      .anyOfForOrg(
        [
          LICENSE_ADMIN,
          ORG_ADMIN,
          CONTRACT_ADMIN,
          PRODUCT_ADMIN,
          PRODUCT_SUPPORT_ADMIN,
          SUPPORT_ADMIN,
          USER_GROUP_ADMIN,
        ],
        orgId
      )
  );
}

/**
 * Method to determine if the org has any administerable API products.
 * This method is used by the route access methods to determine if the route is accessible
 * so it does not check the org's read-only status.
 *
 * @returns {Boolean} whether the org has any administerable developer products
 */
function hasAdministerableDeveloperProducts() {
  const developerProducts = getDeveloperProducts(rootStore.organizationStore.productList);
  return developerProducts.some((product) => product.isAdministerable());
}

export {canViewApiCredentials, canViewDevelopers, hasAdministerableDeveloperProducts};
