import {
  InfoBar,
  InfoItemScorecard,
  Page,
  PageContent,
  PageHeader,
  PageHeaderTagGroup,
  PageInfoBar,
  PageNav,
  getProductDisplayName,
} from '@admin-tribe/acsc-ui';
import {ImageIcon} from '@pandora/react-image-icon';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';

import useDocumentTitle from 'common/hooks/useDocumentTitle';
import {getProductTags} from 'core/products/utils/productTagUtils';
import FigProductGroupProductList from 'core/services/product/product-group/product-list/FigProductGroupProductList';
import ProductInstancesTableSection from 'features/products/components/product-group-instances-table/ProductInstancesTableSection';
import ProductPageBreadcrumbs from 'features/products/components/product-page-breadcrumbs/ProductPageBreadcrumbs';

/**
 * Page for grouped DX instances which are represented by a FigProductGroupProductList.
 */
const InstancesPage = ({productGroup}) => {
  const intl = useIntl();
  const productTags = useMemo(() => getProductTags(intl, productGroup), [intl, productGroup]);
  const title = getProductDisplayName(intl, productGroup);
  useDocumentTitle({defaultTitle: title});

  return (
    <Page data-testid="instances-page">
      <ProductPageBreadcrumbs
        isOnProductGroupPage
        productDisplayName={title}
        productOrProductGroupId={productGroup.id}
      />

      <PageHeader title={title}>
        <ImageIcon alt="" size="XL" src={productGroup.getIcon()} />
        <PageHeaderTagGroup productName={title} tags={productTags} />
      </PageHeader>
      <PageInfoBar>
        <InfoBar>
          <InfoItemScorecard
            label={intl.formatMessage({
              id: 'products.instancesPage.details.totalInstances',
            })}
            value={productGroup.items.length}
          />
        </InfoBar>
      </PageInfoBar>
      <PageNav />
      <PageContent>
        <ProductInstancesTableSection groupedProducts={productGroup.items} />
      </PageContent>
    </Page>
  );
};

InstancesPage.propTypes = {
  /**
   * The FigProductGroupList for the grouped DX instances.
   */
  productGroup: PropTypes.instanceOf(FigProductGroupProductList).isRequired,
};

export default InstancesPage;
