import {
  PRODUCT_GROUP_PARAMETERS_SCORECARD_TYPE,
  PRODUCT_METADATA_CACHE_ID,
  jilProductArrangements,
  modelCache,
} from '@admin-tribe/acsc';
import groupBy from 'lodash/groupBy';

import ProductGroupProductList from './ProductGroupProductList';

/**
 * @class FigProductGroupProductList
 * @description Model for an individual fulfillable item group product group
 *   product list (FigProductGroupProductList). The fulfillable item group
 *   product group product list is used as a short-hand to group similar
 *   products by their fulfillable item groups. This is typically used when
 *   dealing with marketing cloud or experience cloud products, which group
 *   items by fulfillable item group id values
 */
class FigProductGroupProductList extends ProductGroupProductList {
  /**
   * @description Constructor for FigProductGroupProductList model Objects.
   * @param {Object} options - options object
   * @param {String} options.figId - The fulfillable item group id for all items in this product group.
   * @param {String} options.items - The products in this group.
   */
  constructor(options) {
    super({
      id: options.figId, // unique id for this productGroup which will be used as the cache key
      items: options.items,
    });
  }

  /**
   * @description Method to fetch and update product metadata in the fig product
   *   group product list
   * @returns {Promise} resolves to fig product group product list (this)
   */
  async getProductMetadata() {
    // Normally products that share the same fig id should also share the same
    // product arrangement code, but get the metadata for each just in case.
    const paCodeGroupedItems = groupBy(this.items, 'productArrangementCode');
    const paCodes = Object.keys(paCodeGroupedItems);
    const productMetadata = paCodes.map(fetchProductMetadataForProductArrangementCode.bind(this));

    await Promise.all(productMetadata);

    return this;

    ///////////////

    async function fetchProductMetadataForProductArrangementCode(paCode) {
      const metadata = modelCache.get(PRODUCT_METADATA_CACHE_ID, paCode);

      if (metadata) {
        mergeMetadata(this.items, metadata);
        return this;
      }

      const response = await jilProductArrangements.metadata.query({
        productArrangementCode: paCode,
      });

      mergeMetadata(this.items, response);
      modelCache.put(PRODUCT_METADATA_CACHE_ID, this.id, response);
      return this;
    }

    function mergeMetadata(items, data) {
      items.forEach((item) => {
        const itemData = data.find((datum) => datum.productId === item.id);
        item.metadata = itemData?.metadata ?? [];
      });
    }
  }

  /**
   * @description Method to determine if any of the products have instances scorecards
   * @returns {boolean} returns true if any of the products have instances scorecards
   */
  hasInstancesScorecard() {
    return this.items.some((item) =>
      item.hasScorecardType(PRODUCT_GROUP_PARAMETERS_SCORECARD_TYPE.INSTANCES)
    );
  }
}

export default FigProductGroupProductList;
