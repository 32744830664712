import {isLicenseGroupConfigurationGroupInvalid} from '@admin-tribe/acsc';
import {
  Cell,
  Column,
  Content,
  ContextualHelp,
  Divider,
  Header,
  Heading,
  Link,
  Row,
  TableBody,
  TableHeader,
  TableView,
  Text,
  View,
} from '@adobe/react-spectrum';
import {TooltipButton} from '@pandora/react-tooltip-button';
import EditIcon from '@react/react-spectrum/Icon/Edit';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {configurationsPropType} from '../edit-product-profile-permissions/prop-types/configurationGroupsPropTypes';
import {EMPTY_TABLE_TYPE} from '../profilePermissionsConstants';

import PermissionsEmptyTable from './PermissionsEmptyTable';
import ProfilePermissionDescription from './ProfilePermissionDescription';
import ProfilePermissionSummary from './ProfilePermissionSummary';

/**
 * Displays multiple tables, one after another. Each table summarizes the groups for a section.
 */
const PermissionsTableList = ({
  configurations,
  isSearching = false,
  onPermissionRowEditClicked,
}) => {
  const intl = useIntl();
  const emptyTableType = isSearching
    ? EMPTY_TABLE_TYPE.NO_SEARCH_RESULTS
    : EMPTY_TABLE_TYPE.NO_PERMISSIONS;

  return (
    <>
      {(configurations === undefined || configurations.length === 0) && (
        <PermissionsEmptyTable tableType={emptyTableType} />
      )}
      {configurations.map((configuration, configurationIndex) =>
        configuration.sections.map((section) => (
          <View key={section.id} data-testid="permissions-section" marginTop="size-300">
            {configurationIndex > 0 && <Divider marginBottom="size-300" size="S" />}
            {section.name && (
              <Header>
                <Text
                  data-testid="permission-section-name"
                  id={`section-name-${configurationIndex}-${section.id}`}
                  marginEnd="size-100"
                  UNSAFE_style={{fontWeight: 'bold'}}
                >
                  {section.name}
                </Text>
                {section.description && (
                  <Text
                    data-testid="permission-section-description"
                    id={`section-description-${configurationIndex}-${section.id}`}
                  >
                    {section.description}
                  </Text>
                )}
              </Header>
            )}
            <TableView
              aria-describedby={
                section.description
                  ? `section-description-${configurationIndex}-${section.id}`
                  : null
              }
              aria-label={
                section.name === undefined
                  ? intl.formatMessage({
                      id: 'products.productProfilePermissions.table.ariaLabel',
                    })
                  : null
              }
              aria-labelledby={
                section.name ? `section-name-${configurationIndex}-${section.id}` : null
              }
              data-testid="permission-section-table"
              overflowMode="wrap"
            >
              <TableHeader>
                <Column>
                  {intl.formatMessage({
                    id: 'products.productProfilePermissions.table.name',
                  })}
                </Column>
                <Column hideHeader showDivider>
                  {intl.formatMessage({
                    id: 'products.productProfilePermissions.table.edit',
                  })}
                </Column>
                <Column>
                  {intl.formatMessage({
                    id: 'products.productProfilePermissions.table.items',
                  })}
                </Column>
                <Column>
                  {intl.formatMessage({
                    id: 'products.productProfilePermissions.table.description',
                  })}
                </Column>
              </TableHeader>
              <TableBody items={section.content}>
                {/* eslint-disable-next-line @admin-tribe/admin-tribe/extract-large-computations -- Testing is difficult when the Row is extracted as a component*/}
                {(currentRow) => (
                  <Row key={currentRow.id}>
                    <Cell>
                      {currentRow.name}
                      {isLicenseGroupConfigurationGroupInvalid(currentRow) && (
                        <ContextualHelp alignSelf="center" placement="right" variant="info">
                          <Content>
                            <Text>
                              {intl.formatMessage({
                                id: 'products.productProfilePermissions.invalidPermissionsTooltip',
                              })}
                            </Text>
                          </Content>
                        </ContextualHelp>
                      )}
                    </Cell>
                    <Cell>
                      <TooltipButton
                        data-testid="edit-permission-button"
                        hoverText={intl.formatMessage(
                          {
                            id: 'products.productProfilePermissions.table.edit.ariaLabel',
                          },
                          {
                            name: currentRow.name,
                          }
                        )}
                        isQuiet
                        onPress={() =>
                          onPermissionRowEditClicked(
                            section.content,
                            currentRow.id,
                            section.id,
                            configurationIndex
                          )
                        }
                        variant="action"
                      >
                        <EditIcon size="S" />
                      </TooltipButton>
                    </Cell>
                    <Cell>
                      <ProfilePermissionSummary group={currentRow} />
                      {currentRow.linkHref && (
                        <ContextualHelp
                          alignSelf="center"
                          marginStart="size-100"
                          placement="right"
                          variant="info"
                        >
                          <Heading>{currentRow.name}</Heading>
                          <Content>
                            <Link>
                              <a
                                href={currentRow.linkHref}
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                {intl.formatMessage(
                                  {
                                    id: 'products.productProfilePermissions.summary.learnMore',
                                  },
                                  {groupName: currentRow.name}
                                )}
                              </a>
                            </Link>
                          </Content>
                        </ContextualHelp>
                      )}
                    </Cell>
                    <Cell>
                      <ProfilePermissionDescription group={currentRow} />
                    </Cell>
                  </Row>
                )}
              </TableBody>
            </TableView>
          </View>
        ))
      )}
    </>
  );
};

PermissionsTableList.propTypes = {
  /**
   * The configurations to display.
   */
  configurations: configurationsPropType.isRequired,

  /**
   * Flag to show when the given cofigurations are the result of a search. It is false by default.
   */
  isSearching: PropTypes.bool,

  /**
   * Callback function for the EditProductProfilePermissionsModal
   */
  onPermissionRowEditClicked: PropTypes.func.isRequired,
};

export default PermissionsTableList;
