import {
  Button,
  ButtonGroup,
  Checkbox,
  Content,
  Dialog,
  Divider,
  Flex,
  Heading,
  Link,
  Text,
  useDialogContainer,
} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import useAcceptLinkedMembershipTerms from './useAcceptLinkedMembershipTerms';

const LinkedMembershipTermsModal = ({contract}) => {
  const intl = useIntl();
  const dialog = useDialogContainer();
  const [isChecked, setIsChecked] = useState(false);

  const title = intl.formatMessage({id: 'common.linkedMembershipTermsModal.title'});
  const description = intl.formatMessage(
    {id: 'common.linkedMembershipTermsModal.description'},
    {
      goUrl: (str) => (
        <Link href="https://www.adobe.com/go/LinkedMembershipTermsandConditions" target="_blank">
          {str}
        </Link>
      ),
    }
  );
  const checkboxContent = intl.formatMessage({
    id: 'common.linkedMembershipTermsModal.checkboxContent',
  });
  const acceptLabel = intl.formatMessage({id: 'common.linkedMembershipTermsModal.accept'});
  const cancelLabel = intl.formatMessage({id: 'common.linkedMembershipTermsModal.cancel'});

  const successMessage = intl.formatMessage({id: 'common.linkedMembershipBanner.success'});

  const {isPending, onAcceptTerms} = useAcceptLinkedMembershipTerms({contract, successMessage});
  const onAccept = () => {
    onAcceptTerms({closeDialog: dialog.dismiss});
  };

  const onClose = () => dialog.dismiss();

  return (
    <Dialog size="M">
      <Heading data-testid="dialog-title">{title}</Heading>
      <Divider />
      <Content>
        <Flex direction="column" gap="size-150">
          <Text>{description}</Text>
          <Checkbox data-testid="terms-checkbox" isSelected={isChecked} onChange={setIsChecked}>
            {checkboxContent}
          </Checkbox>
        </Flex>
      </Content>
      <ButtonGroup>
        <Button data-testid="close-btn" onPress={onClose} variant="secondary">
          {cancelLabel}
        </Button>
        <Button
          data-testid="accept-btn"
          isDisabled={!isChecked || isPending}
          onPress={onAccept}
          variant="accent"
        >
          {acceptLabel}
        </Button>
      </ButtonGroup>
    </Dialog>
  );
};

LinkedMembershipTermsModal.propTypes = {
  contract: PropTypes.shape({
    id: PropTypes.string,
  }),
};

export default LinkedMembershipTermsModal;
