import {Provider as SpectrumV3Provider, defaultTheme} from '@adobe/react-spectrum';
import SpectrumV2Provider from '@react/react-spectrum/Provider';
import PropTypes from 'prop-types';
import React from 'react';

import {useLocale} from 'core/providers/locale/LocaleProvider';

/**
 * Wraps the children components with Spectrum Provider.
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The children components.
 * @returns {React.ReactNode} The wrapped children components.
 */

const TYPE_KIT_ID = 'ubl8raj';

const SpectrumProviderWrapper = ({children}) => {
  const {localeLoaded, localeObject} = useLocale();

  return (
    localeLoaded && (
      <SpectrumV2Provider
        locale={localeObject.activeLanguage}
        scale="medium"
        theme="light"
        toastPlacement="bottom left"
        typekitId={TYPE_KIT_ID}
        UNSAFE_style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          overflow: 'hidden',
        }}
      >
        <SpectrumV3Provider
          colorScheme="light"
          locale={localeObject.activeLanguage}
          theme={defaultTheme}
          typekitId={TYPE_KIT_ID}
          UNSAFE_style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            overflow: 'hidden',
          }}
        >
          {children}
        </SpectrumV3Provider>
      </SpectrumV2Provider>
    )
  );
};

SpectrumProviderWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SpectrumProviderWrapper;
