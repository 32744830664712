import {CollaborationPolicies} from '@admin-tribe/acsc';
import {useAsyncModel} from '@pandora/react-async-model';
import {useCallback} from 'react';

/**
 * Hook that calls the collaboration policy api to fetch the policy information
 * associated with an organization
 */
const useCollaborationPolicies = ({orgId}) => {
  const loadCollaborationPolicies = useCallback(() => CollaborationPolicies.get({orgId}), [orgId]);

  const {model, isLoading, error} = useAsyncModel({
    loadFn: loadCollaborationPolicies,
  });

  return {collaborationPolicies: model, error, isLoading};
};

export default useCollaborationPolicies;
