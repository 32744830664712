import {Store} from '@admin-tribe/acsc';
import {action, makeObservable, observable} from 'mobx';

// Domain data for help topic identifiers
class TopicIdentifierStore extends Store {
  constructor() {
    super();

    Object.assign(this, {
      items: {}, // {String: String} look-up table
    });

    makeObservable(this, {
      add: action.bound,
      items: observable,
    });
  }

  /**
   * @description Method to add a new topic identifier for use in the help
   *   system.
   * @param {String} topicIdentifierId - topic identifier id to add
   * @throws {Error} if attempting to add a topic identifier that has already
   *   been configured
   */
  add(topicIdentifierId) {
    const alreadyConfiguredTopicId = this.items?.[topicIdentifierId];
    if (alreadyConfiguredTopicId === null || alreadyConfiguredTopicId === undefined) {
      // unconfigured topic (new)
      Object.assign(this.items, {[topicIdentifierId]: topicIdentifierId});
    } else {
      throw new TypeError(
        'TopicIdentifierStore: Attempting to add a topic identifier that has already been added (in use).'
      );
    }
  }

  /**
   * @description Method to get a topic identifier object by its string id
   * @param {String} topicIdentifierId - topic identifier id to get
   * @returns {String} topic identifier for this id
   */
  getById(topicIdentifierId) {
    return this.items[topicIdentifierId];
  }
}

export default TopicIdentifierStore;
