// ***********************************************************************
// ADOBE CONFIDENTIAL
// ___________________
//
// Copyright 2024 Adobe
// All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains
// the property of Adobe and its suppliers, if any. The intellectual
// and technical concepts contained herein are proprietary to Adobe
// and its suppliers and are protected by all applicable intellectual
// property laws, including trade secret and copyright laws.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Adobe.
// ************************************************************************

import {Product, feature} from '@admin-tribe/acsc';
import {InfoBar} from '@admin-tribe/acsc-ui';
import {useLogger} from '@pandora/react-logger-provider';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';

import rootStore from 'core/RootStore';
import {setupForOrgConsumableProduct} from 'core/products/utils/productUsageUtils';
import DevConsoleLink from 'features/products/components/dev-console-link/DevConsoleLink';
import ExtendedScoreCard from 'features/products/components/extended-score-card/ExtendedScoreCard';

const FireflyApiInfoBar = ({product}) => {
  const learnMoreGoUrlCode = undefined; // 'aac_learn_firefly_services' // See ONESIE-41754
  const [productUsage, setProductUsage] = React.useState(null);
  const orgId = rootStore.organizationStore.activeOrgId;
  const log = useLogger();
  const linkToDevConsole = feature.isEnabled('temp_show_firefly_devconsole');
  const consumedContentId = linkToDevConsole
    ? 'product.details.fireflyApiInfoBar.contextualHelp.consumed.devconsole.content'
    : 'product.details.fireflyApiInfoBar.contextualHelp.consumed.content';

  // Load the product usage data from the backend service
  useEffect(() => {
    async function load() {
      const usages = await setupForOrgConsumableProduct({
        fiCode: 'firefly_api',
        log,
        orgId,
        product,
      });
      if (usages.length > 0) {
        setProductUsage(usages[0]);
      }
    }
    load();
  }, [orgId, product, log]);

  return (
    <InfoBar data-testid="firefly_api-infobar">
      <ExtendedScoreCard
        contentId="product.details.fireflyApiInfoBar.contextualHelp.total.content"
        count={productUsage?.total}
        goUrlCode={learnMoreGoUrlCode}
        labelId="product.details.fireflyApiInfoBar.total.label"
        linkId="product.details.fireflyApiInfoBar.contextualHelp.learnMoreLink"
        titleId="product.details.fireflyApiInfoBar.contextualHelp.total.title"
      />
      <ExtendedScoreCard
        contentFormatOptions={{
          devConsoleLink: (linkText) => <DevConsoleLink linkText={String(linkText)} />,
        }}
        contentId={consumedContentId}
        count={productUsage?.used}
        goUrlCode={learnMoreGoUrlCode}
        labelId="product.details.fireflyApiInfoBar.consumed.label"
        linkId="product.details.fireflyApiInfoBar.contextualHelp.learnMoreLink"
        titleId="product.details.fireflyApiInfoBar.contextualHelp.consumed.title"
      />
    </InfoBar>
  );
};

FireflyApiInfoBar.propTypes = {
  /**
   * The product we're displaying profiles (aka license groups) for.
   */
  product: PropTypes.instanceOf(Product).isRequired,
};

export default FireflyApiInfoBar;
