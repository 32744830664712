import {feature} from '@admin-tribe/acsc';
import {Footer, OverlayWait, PAGE_CONTENT_HEIGHT, ToastContainer} from '@admin-tribe/acsc-ui';
import {Grid, View} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';
import {useNavigation} from 'react-router-dom';

import rootStore from 'core/RootStore';
import GlobalBanner from 'features/global-banner/GlobalBanner';
import HeaderFooterWrapper from 'shell/components/header-footer-wrapper/HeaderFooterWrapper';
import HelpRailContainer from 'shell/help/rail/help-rail-container/HelpRailContainer';

import './Shell.pcss';

/** Shows a loading spinner while any route action, loader, or navigation is taking place */
const ShellContentOverlay = ({children}) => {
  const {state} = useNavigation();

  return feature.isEnabled('temp_shell_fix') ? (
    <OverlayWait
      data-testid="shell-overlay-wait"
      isLoading={state === 'loading'}
      showContent={false}
      size="L"
    >
      <View
        height={PAGE_CONTENT_HEIGHT}
        UNSAFE_style={{overflow: state === 'loading' ? 'hidden' : 'initial'}}
      >
        {children}
      </View>
    </OverlayWait>
  ) : (
    <OverlayWait data-testid="shell-overlay-wait" isLoading={state === 'loading'} size="L">
      {state === 'loading' ? <View height={PAGE_CONTENT_HEIGHT} /> : children}
    </OverlayWait>
  );
};

ShellContentOverlay.propTypes = {
  /** The children to render underneath the overlay */
  children: PropTypes.node.isRequired,
};

/**
 * react shell component to render global header, global banner, page content, toast & footer
 */

const Shell = observer(({children}) => {
  let helpWidth, remainingWidth;

  const isOpenHelpRail = rootStore.helpStore.ui.isOpen;
  const HELP_RAIL_WIDTH = 'size-5000';

  if (isOpenHelpRail) {
    // 25rem (400px; size-5000) was the width of the help rail defined in _shell.scss
    helpWidth = HELP_RAIL_WIDTH;
    remainingWidth = `calc(100vw - ${HELP_RAIL_WIDTH})`;
  } else {
    helpWidth = 'size-0';
    remainingWidth = '100%';
  }

  // When all Angular UI is gone we can put the GlobalHeader back in here for good
  // See the history of this file for the implementation of the fully-in-React GlobalHeader
  const gridProps = {
    areas: ['content  help', 'footer  help'],
    height: 'calc(100vh - size-700)', // size-700 is the height of the GlobalHeader component
    rows: ['auto', 'size-600'],
  };

  return (
    <Grid
      areas={gridProps.areas}
      columns={[remainingWidth, helpWidth]}
      data-testid="react-shell"
      gap="size-0"
      height={gridProps.height}
      rows={gridProps.rows}
    >
      <View data-testid="page-content" gridArea="content" overflow="auto">
        <GlobalBanner />
        <ShellContentOverlay>
          <View paddingX="size-400" paddingY="size-300">
            {children}
            <ToastContainer />
          </View>
        </ShellContentOverlay>
      </View>
      <View
        data-testid="help-rail-wrapper"
        gridArea="help"
        UNSAFE_style={{overflow: 'hidden auto'}}
      >
        <div styleName="help-rail-background-wrapper">
          <HelpRailContainer />
        </div>
      </View>
      <HeaderFooterWrapper>
        <View gridArea="footer">
          <Footer showAdChoices showCookiePreferences showDoNotSharePersonalInfo />
        </View>
      </HeaderFooterWrapper>
    </Grid>
  );
});

Shell.displayName = 'Shell';

Shell.propTypes = {
  children: PropTypes.node.isRequired,
};

export {ShellContentOverlay};
export default Shell;
