import {feature} from '@admin-tribe/acsc';

import rootStore from 'core/RootStore';
import {getCustomerType} from 'features/packages/services/createPackageServiceUtils';

import {CREATE_PACKAGE_CONSTANTS} from '../../packagesConstants';
import PackagesEntitlements from '../../services/PackagesEntitlementsService';
import PackagesIngestAnalytics from '../../services/PackagesIngestAnalyticsService';
import CCPackageIcon from '../../svgs/CCPackageIcon.svg';
import DCCustomizationWizardIcon from '../../svgs/DCCustomizationWizardIcon.svg';
import ExtensionManagerIcon from '../../svgs/ExtensionManagerIcon.svg';
import ToolIcon from '../../svgs/ToolIcon.svg';

const showAdobeCodeGeneratorTool = () =>
  PackagesEntitlements.hasFrlEntitlement || PackagesEntitlements.hasNuellEntitlement;

const showCcpTool = () =>
  PackagesEntitlements.hasCcEntitlement ||
  PackagesEntitlements.hasPresenterEntitlement ||
  PackagesEntitlements.hasCaptivateEntitlement ||
  PackagesEntitlements.hasFramemakerEntitlement ||
  PackagesEntitlements.hasPremierElementsEntitlement ||
  PackagesEntitlements.hasPhotoshopElementsEntitlement ||
  PackagesEntitlements.hasRoboHelpServerEntitlement ||
  PackagesEntitlements.hasRobohelpEntitlement;

const showDcCustomizationWizard = () => PackagesEntitlements.hasDcEntitlement;

const showExmanCmdTool = () => PackagesEntitlements.hasCcEntitlement;

const showSATTool = () =>
  PackagesEntitlements.hasSubstanceSATEntitlement && PackagesEntitlements.isEnterpriseOrg;

const showSATToolV2 = () =>
  PackagesEntitlements.hasSubstanceWSEntitlement && PackagesEntitlements.isEnterpriseOrg;

const showServerConfigTool = () => PackagesEntitlements.hasFrlLanEntitlement;

/**
 * @description Method to send the ingest analytics
 *
 * @param {String} title title of the tool
 * @param {String} subType indicates which button is clicked
 * @param {Object} info tool information object
 */
const sendIngestAnalytics = (title, subType, info) => {
  PackagesIngestAnalytics.triggerAnalytics({
    content: title,
    pagename: 'ToolsTab',
    subType,
    type: 'click',
    value: {customerType: getCustomerType(), ...info},
  });
};

/**
 * @description Method to get all the tools card details, which will be sent as props to Tools Card component in ToolsPage
 * @param {Object} intl object coming from the useIntl hook
 * @returns {Array} of objects contains props for each ToolsCard component
 */
const getCardDetails = (intl) => [
  {
    ariaLabel: intl.formatMessage({id: 'packages.tools.adminTools.cpp.learnMore'}),
    details: intl.formatMessage({id: 'packages.tools.adminTools.ccp.detailsFrl'}),
    downloadButton: intl.formatMessage({id: 'packages.tools.adminTools.ccp.button'}),
    downloadUrlMac: rootStore.packagesUiConstantsStore.ccpMacUrl,
    downloadUrlWin: rootStore.packagesUiConstantsStore.ccpWinUrl,
    icons: CCPackageIcon,
    name: 'ent_learnmore_ccp',
    showCard: showCcpTool,
    title: intl.formatMessage({id: 'packages.tools.adminTools.ccp.title'}),
  },
  {
    ariaLabel: intl.formatMessage({id: 'packages.tools.adminTools.dc.learnMore'}),
    details: intl.formatMessage({id: 'packages.tools.adminTools.dcCustomizationWizard.details'}),
    downloadButton: intl.formatMessage({
      id: 'packages.tools.adminTools.dcCustomizationWizard.button',
    }),
    downloadUrlMac: rootStore.packagesUiConstantsStore.dcCustomizationWizardMacUrl,
    downloadUrlWin: rootStore.packagesUiConstantsStore.dcCustomizationWizardWinUrl,
    icons: DCCustomizationWizardIcon,
    name: 'ent_acrobat_custwizard_help',
    showCard: showDcCustomizationWizard,
    title: intl.formatMessage({id: 'packages.tools.adminTools.dcCustomizationWizard.title'}),
  },
  {
    ariaLabel: intl.formatMessage({id: 'packages.tools.adminTools.ausst.learnMore'}),
    details: intl.formatMessage({id: 'packages.tools.adminTools.ausst.details'}),
    downloadButton: intl.formatMessage({id: 'packages.tools.adminTools.ausst.button'}),
    downloadUrlMac: rootStore.packagesUiConstantsStore.ausstMacUrl,
    downloadUrlWin: rootStore.packagesUiConstantsStore.ausstWinUrl,
    icons: ToolIcon,
    name: 'learn_ausst_help',
    showCard: () => true,
    title: intl.formatMessage({id: 'packages.tools.adminTools.ausst.title'}),
  },
  {
    ariaLabel: intl.formatMessage({id: 'packages.tools.adminTools.acgt.learnMore'}),
    details: intl.formatMessage({id: 'packages.tools.adminTools.adobeCodeGeneratorTool.details'}),
    downloadButton: intl.formatMessage({
      id: 'packages.tools.adminTools.adobeCodeGeneratorTool.button',
    }),
    downloadUrlMac: rootStore.packagesUiConstantsStore.adobeCodeGeneratorToolMacUrl,
    downloadUrlMacArm: rootStore.packagesUiConstantsStore.adobeCodeGeneratorToolMacArmUrl,
    downloadUrlWin: rootStore.packagesUiConstantsStore.adobeCodeGeneratorToolWinUrl,
    downloadUrlWinArm: rootStore.packagesUiConstantsStore.adobeCodeGeneratorToolWinArmUrl,
    icons: ToolIcon,
    name: 'ent_learnmore_licensingtoolkit',
    showCard: showAdobeCodeGeneratorTool,
    title: intl.formatMessage({id: 'packages.tools.adminTools.adobeCodeGeneratorTool.title'}),
  },
  {
    ariaLabel: intl.formatMessage({id: 'packages.tools.adminTools.rum.learnMore'}),
    details: intl.formatMessage({id: 'packages.tools.adminTools.rum.details'}),
    downloadButton: intl.formatMessage({id: 'packages.tools.adminTools.rum.button'}),
    downloadUrlMac: rootStore.packagesUiConstantsStore.rumMacUrl,
    downloadUrlMacArm: rootStore.packagesUiConstantsStore.rumMacArmUrl,
    downloadUrlWin: rootStore.packagesUiConstantsStore.rumWinUrl,
    downloadUrlWinArm: rootStore.packagesUiConstantsStore.rumWinArmUrl,
    icons: ToolIcon,
    name: 'learn_rum_help',
    showCard: () => true,
    title: intl.formatMessage({id: 'packages.tools.adminTools.rum.title'}),
  },
  {
    ariaLabel: intl.formatMessage({id: 'packages.tools.adminTools.exman.learnMore'}),
    details: intl.formatMessage({id: 'packages.tools.adminTools.exmanCmd.detailsXdx'}),
    downloadButton: intl.formatMessage({id: 'packages.tools.adminTools.exmanCmd.button'}),
    downloadUrlMac: rootStore.packagesUiConstantsStore.exmanCmdMacUrl,
    downloadUrlWin: rootStore.packagesUiConstantsStore.exmanCmdWinUrl,
    icons: ExtensionManagerIcon,
    name: 'ent_exmanCmd_help',
    showCard: showExmanCmdTool,
    title: intl.formatMessage({id: 'packages.tools.adminTools.exmanCmd.title'}),
  },
  {
    ariaLabel: intl.formatMessage({id: 'packages.tools.adminTools.sct.learnMore'}),
    details: intl.formatMessage({id: 'packages.tools.adminTools.serverConfigTool.details'}),
    downloadButton: intl.formatMessage({id: 'packages.tools.adminTools.serverConfigTool.button'}),
    downloadUrlLinux: rootStore.packagesUiConstantsStore.serverConfigToolMacUrl,
    downloadUrlWin: rootStore.packagesUiConstantsStore.serverConfigToolWinUrl,
    icons: ToolIcon,
    name: 'ent_FRL_Ser_Config_Tool',
    showCard: showServerConfigTool,
    title: intl.formatMessage({id: 'packages.tools.adminTools.serverConfigTool.title'}),
  },
  {
    ariaLabel: intl.formatMessage({id: 'packages.tools.adminTools.sat.learnMore'}),
    details: intl.formatMessage({id: 'packages.tools.adminTools.sat.details'}),
    downloadButton: intl.formatMessage({id: 'packages.tools.adminTools.sat.button'}),
    downloadUrlLinux: CREATE_PACKAGE_CONSTANTS.LINUX_PLATFORM,
    downloadUrlMac: CREATE_PACKAGE_CONSTANTS.MAC_PLATFORM,
    downloadUrlWin: CREATE_PACKAGE_CONSTANTS.WIN_PLATFORM,
    icons: ToolIcon,
    isSatDownload: true,
    name: 'ent_sat_help',
    showCard: () => showSATTool() || (showSATToolV2() && feature.isEnabled('packages_sat_v2')),
    title: intl.formatMessage({id: 'packages.tools.adminTools.sat.title'}),
  },
  {
    ariaLabel: intl.formatMessage({id: 'packages.tools.adminTools.sat2.learnMore'}),
    details: intl.formatMessage({id: 'packages.tools.adminTools.sat2.details'}),
    downloadButton: intl.formatMessage({id: 'packages.tools.adminTools.sat2.button'}),
    downloadUrlLinux: CREATE_PACKAGE_CONSTANTS.LINUX_PLATFORM,
    downloadUrlMac: CREATE_PACKAGE_CONSTANTS.MAC_PLATFORM,
    downloadUrlWin: CREATE_PACKAGE_CONSTANTS.WIN_PLATFORM,
    icons: ToolIcon,
    isSatDownload: true,
    name: 'ent_sat2_help',
    showCard: () => showSATToolV2() && feature.isEnabled('packages_sat_v2'),
    showLearnMore: feature.isEnabled('packages_satv2_learnmore'),
    title: intl.formatMessage({id: 'packages.tools.adminTools.sat2.title'}),
    version: 'v2',
  },
  {
    ariaLabel: intl.formatMessage({id: 'packages.tools.adminTools.uninstall.learnMore'}),
    details: intl.formatMessage({id: 'packages.tools.adminTools.uninstall.details'}),
    downloadButton: intl.formatMessage({id: 'packages.tools.adminTools.uninstall.button'}),
    downloadUrlMacUniversal: rootStore.packagesUiConstantsStore.uninstallMacUniversalUrl,
    downloadUrlWin: rootStore.packagesUiConstantsStore.uninstallWinUrl,
    icons: ToolIcon,
    name: 'ent_learn_uninstall_help',
    showCard: () => true,
    title: intl.formatMessage({id: 'packages.tools.adminTools.uninstall.title'}),
  },
];

export {getCardDetails, sendIngestAnalytics};
