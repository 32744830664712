import {feature} from '@admin-tribe/acsc';
import {WorkspaceNav} from '@admin-tribe/acsc-ui';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {useRouteLoaderData} from 'react-router-dom';

import useInsightsPaths from '../hooks/useInsightsPaths';

/** Renders the workspace nav for Insights */
const InsightsWorkspaceNav = () => {
  const intl = useIntl();
  const {canViewDashboard, canViewLogs, canViewReports} = useRouteLoaderData('insights');
  const {dashboard, reports, logs} = useInsightsPaths();

  const navItems = useMemo(() => {
    const items = [];
    if (canViewDashboard) {
      items.push({
        href: dashboard(),
        name: intl.formatMessage({id: 'insights.dashboard.title'}),
        order: 0,
        ...(feature.isEnabled('temp_navitem_testids') ? {testId: 'nav-insights-dashboard'} : {}),
      });
    }

    if (canViewReports) {
      items.push({
        href: reports(),
        name: intl.formatMessage({id: 'insights.reports.title'}),
        order: 1,
        ...(feature.isEnabled('temp_navitem_testids') ? {testId: 'nav-insights-reports'} : {}),
      });
    }

    if (canViewLogs) {
      items.push({
        href: logs(),
        name: intl.formatMessage({id: 'insights.logs.title'}),
        order: 2,
        ...(feature.isEnabled('temp_navitem_testids') ? {testId: 'nav-insights-logs'} : {}),
      });
    }

    return items;
  }, [canViewDashboard, canViewReports, canViewLogs, intl, logs, reports, dashboard]);

  return <WorkspaceNav navItems={navItems} />;
};

InsightsWorkspaceNav.displayName = 'InsightsWorkspaceNav';

export default InsightsWorkspaceNav;
