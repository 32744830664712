import {log} from '@admin-tribe/acsc';
import {
  InfoItem,
  InfoItemScorecard,
  Page,
  PageActions,
  PageBreadcrumbs,
  PageContent,
  PageDescription,
  PageHeader,
  PageInfoBar,
  PageNav,
  showSuccess,
} from '@admin-tribe/acsc-ui';
import {Button, Item} from '@adobe/react-spectrum';
import {Library} from '@pandora/data-model-library';
import {useSharedAssetModel} from '@pandora/data-model-shared-asset';
import {ContentContext, useContentEntry} from '@pandora/react-content-provider';
import {useOrganizationUserHook} from '@pandora/react-data-model-member';
import {
  ASSET_BROWSER_VERSION,
  LIBRARY_ACTION,
  LIBRARY_STATE,
  LibraryAlertModal,
  LibraryAlertModalContentModel,
  LibraryTableSection,
} from '@pandora/react-library';
import {ModalContainer} from '@pandora/react-modal-dialog';
import {EN_DASH} from '@pandora/react-table-section';
import React, {useContext, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {useLocation, useParams} from 'react-router-dom';

import API_CONSTANTS from 'common/api/ApiConstants';
import useDocumentTitle from 'common/hooks/useDocumentTitle';
import {getLibraryFromId} from 'features/storage/libraries/libraryUtils';
import {goToLibraries} from 'features/storage/routing/navigation-callbacks/navigationCallbacks';

// @deprecated with temp_storage_shared_projects
const LibraryDetailsPage = () => {
  const {libraryId} = useParams();

  const {state} = useLocation();

  const library = undefined;
  const libraryState = state.libraryState;

  const intl = useIntl();
  const {locale} = useContext(ContentContext);

  const {getOrganizationUser} = useOrganizationUserHook();
  const {getSharedAssetModel, loadAssetBrowser} = useSharedAssetModel();
  const modalContent = useContentEntry(LibraryAlertModalContentModel);

  const [createdByName, setCreatedByName] = useState();
  const [currentLibrary, setCurrentLibrary] = useState(library);
  const [isBumpered, setIsBumpered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [invitedUsersCount, setInvitedUsersCount] = useState();
  const [memberCount, setMemberCount] = useState();
  const [shouldFetchLibrary, setShouldFetchLibrary] = useState(true);
  const [libraryAction, setLibraryAction] = useState();
  const [libraryDescription, setLibraryDescription] = useState();
  const [libraryName, setLibraryName] = useState('');

  const getActionButton = (action) => (
    <Button onPress={() => setLibraryAction(action)} variant="secondary">
      {intl.formatMessage({id: `storage.libraries.details.button.${action}`})}
    </Button>
  );

  const onSuccess = (editedLibrary) => {
    setLibraryAction(undefined);
    showSuccess(intl.formatMessage({id: `pandora.libraryListTableSection.${libraryAction}`}));

    if (editedLibrary) {
      setLibraryDescription(editedLibrary.description);
      setLibraryName(editedLibrary.name);

      setCurrentLibrary({
        ...currentLibrary,
        description: editedLibrary.description,
        name: editedLibrary.name,
      });
    } else {
      goToLibraries();
    }
  };

  const onUpdateLibrary = (action, updatedUserIds) => {
    if (action === LIBRARY_ACTION.INVITE_USER) {
      setMemberCount(memberCount + updatedUserIds.length);
    } else if (action === LIBRARY_ACTION.REMOVE_USER) {
      setMemberCount(memberCount - updatedUserIds.length);
    }
  };

  // Get library given library ID, in the case of deeplinking to the detail page from a URL parameter
  useEffect(() => {
    const getLibraryData = async () => {
      setIsLoading(true);
      try {
        const sharedAssetModel = await getSharedAssetModel({
          applicationId: 'AdminConsole',
          applicationLocation: 'AdminConsole/Libraries',
          clientId: 'ONESIE1',
        });
        const foundLibrary =
          library?.team || (await getLibraryFromId(sharedAssetModel, libraryId, libraryState));

        if (foundLibrary) {
          // foundLibrary is really of type Team, so convert to library
          const convertedLibrary = new Library({
            getOrganizationUser,
            includeDescription: true,
            includeInvitedUsers: true,
            logger: log,
            team: foundLibrary,
          });
          await convertedLibrary.build();
          await loadAssetBrowser({version: ASSET_BROWSER_VERSION});

          setCurrentLibrary(convertedLibrary);
          setInvitedUsersCount(convertedLibrary.invitedUsers?.length);
          setMemberCount(convertedLibrary.users?.length);
          setLibraryDescription(convertedLibrary.description);
          setLibraryName(convertedLibrary.name);
          setCreatedByName(convertedLibrary.createdBy?.getDisplayNameOrEmail(locale));
        } else {
          log.error(`Error: No libraries present`);

          setIsBumpered(true);
        }
      } catch (error) {
        log.error(`Error getting library data: ${error}`);

        setIsBumpered(true);
      } finally {
        setIsLoading(false);
        setShouldFetchLibrary(false);
      }
    };

    if (shouldFetchLibrary) {
      getLibraryData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- on load
  }, [shouldFetchLibrary]);

  useDocumentTitle({defaultTitle: libraryName});

  const getTitleString = () => intl.formatMessage({id: 'storage.libraries.title'});

  return (
    <Page data-testid="library-details-page" isBumpered={isBumpered} isLoading={isLoading}>
      <PageBreadcrumbs
        isDisabled={false}
        onAction={() => goToLibraries({state: currentLibrary.state})}
      >
        <Item key="libraries">{getTitleString()}</Item>
        <Item key="details">{libraryName}</Item>
      </PageBreadcrumbs>
      <PageHeader title={libraryName} />
      {libraryDescription && <PageDescription>{libraryDescription}</PageDescription>}
      <PageActions>
        {currentLibrary?.state === LIBRARY_STATE.DISCARDED ? (
          <>
            {getActionButton(LIBRARY_ACTION.RESTORE)}
            {getActionButton(LIBRARY_ACTION.REMOVE)}
          </>
        ) : (
          <>
            {getActionButton(LIBRARY_ACTION.EDIT)}
            {getActionButton(LIBRARY_ACTION.DELETE)}
          </>
        )}
      </PageActions>
      <PageInfoBar>
        <InfoItemScorecard
          label={intl.formatMessage({id: 'storage.libraries.scorecard.users'})}
          value={memberCount}
        />
        <InfoItemScorecard
          label={intl.formatMessage({id: 'storage.libraries.scorecard.pendingInvitations'})}
          value={invitedUsersCount}
        />
        <InfoItem
          label={intl.formatMessage({id: 'storage.libraries.scorecard.createdBy'})}
          value={createdByName ?? EN_DASH}
        />
      </PageInfoBar>
      <PageNav />
      <PageContent>
        {libraryAction && (
          <ModalContainer>
            <LibraryAlertModal
              action={libraryAction}
              content={modalContent}
              library={currentLibrary}
              onCancel={() => setLibraryAction(undefined)}
              onSuccess={onSuccess}
            />
          </ModalContainer>
        )}
        {currentLibrary && (
          <LibraryTableSection
            clientId={API_CONSTANTS.CLIENT_ID}
            library={currentLibrary}
            onCloseInviteModal={() => setShouldFetchLibrary(true)}
            onUpdateLibrary={onUpdateLibrary}
          />
        )}
      </PageContent>
      A
    </Page>
  );
};

export default LibraryDetailsPage;
