import {
  OverlayWait,
  Page,
  PageBreadcrumbs,
  PageContent,
  PageHeader,
  PageNav,
  TableSectionContextProvider,
  useStore,
} from '@admin-tribe/acsc-ui';
import {Item} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import useDocumentTitle from 'common/hooks/useDocumentTitle';
import {REPORT_FOLDER_TYPE} from 'common/services/storage-report/StorageReportConstants';
import ReportListTable from 'features/storage/components/report-list-table/ReportListTable';
import StorageReportStore from 'features/storage/stores/StorageReportStore';

/**
 * The page for the storage report table for either shared or individual user folders.
 */
const StorageReportsDetailsPage = observer(({navigationCallback, folderType}) => {
  const intl = useIntl();
  const [isBumpered, setIsBumpered] = useState(false);
  const reportStore = useStore(() => new StorageReportStore({folderType}));

  const titleKey = `storage.storageReports.details.folderType.${folderType}.title`;
  const pageTitle = intl.formatMessage({id: titleKey});
  useDocumentTitle({key: titleKey});

  // Initial load of the table data.
  useEffect(() => {
    let isMounted = true;

    const load = async () => {
      await reportStore.load();
      if (reportStore.hasLoadingError && isMounted) {
        setIsBumpered(true);
      }
    };

    load();

    return () => {
      isMounted = false;
    };
  }, [reportStore]);

  const initialLoad = !reportStore.tokenService;

  return (
    <Page
      data-testid="storage-reports-details-page"
      isBumpered={isBumpered}
      isLoading={initialLoad && reportStore.isLoading}
    >
      <PageBreadcrumbs isDisabled={false} onAction={navigationCallback}>
        <Item key="reports">{intl.formatMessage({id: 'storage.storageReports.title'})}</Item>
        <Item key="details">{pageTitle}</Item>
      </PageBreadcrumbs>
      <PageHeader title={pageTitle} />
      <PageNav />
      <PageContent>
        <OverlayWait isLoading={!initialLoad && reportStore.isLoading} showContent>
          <TableSectionContextProvider listStore={reportStore}>
            <ReportListTable />
          </TableSectionContextProvider>
        </OverlayWait>
      </PageContent>
    </Page>
  );
});

StorageReportsDetailsPage.propTypes = {
  /**
   * Details will be shown for this folder type, either "assets" (shared) or "users" (individual users).
   */
  folderType: PropTypes.oneOf([REPORT_FOLDER_TYPE.ASSETS, REPORT_FOLDER_TYPE.USERS]).isRequired,
  /**
   * Callback to go back to the 'Storage reports' page. The parameter is the breadcrumb key,
   * either 'reports' or 'details'.
   */
  navigationCallback: PropTypes.func.isRequired,
};

export default StorageReportsDetailsPage;
