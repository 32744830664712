import {DIRECTORY_TYPE, feature} from '@admin-tribe/acsc';
import {OverlayWait, TableSectionTable} from '@admin-tribe/acsc-ui';
import {Item, Picker, View} from '@adobe/react-spectrum';
import {DirectoryDomainEnforcementStatus} from '@pandora/react-directory-domain-enforcement-status';
import {
  EnDashDefaultContent,
  SELECTION_MODE,
  TableFilters,
  TableSection,
} from '@pandora/react-table-section';
import camelCase from 'lodash/camelCase';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {DIRECTORY_STATUSES} from 'common/entities/DirectoryEntity';
import useDirectoryList from 'common/hooks/api/useDirectoryList';
import {useDirectoryListContext} from 'features/settings/components/directories/directory-list/DirectoryListContext';
import DirectoryStatus from 'features/settings/components/directories/directory-list/DirectoryStatus';
import DirectoryTableActions from 'features/settings/components/directories/directory-list/directory-list-table/DirectoryTableActions';
import DirectoryName from 'features/settings/components/directories/directory-list/directory-list-table/directory-name/DirectoryName';
import TrustedDirectoryDrawerTrigger from 'features/settings/components/directories/directory-list/directory-list-table/trusted-directory-drawer-trigger/TrustedDirectoryDrawerTrigger';
import useDirectoryTable, {TABLE_SECTION_ACTIONS} from 'features/settings/hooks/useDirectoryTable';

import styles from './DirectoryListTable.pcss';

const DirectoryListTable = ({
  directoryLinks,
  goToDirectoryDetails,
  goToSetupDomains,
  rosterSyncs,
}) => {
  const intl = useIntl();

  const {clearDirectoryCache} = useDirectoryList();
  const {tableOptions, loadDirectoryList, loadDirectoryListWithCertificates, directoryList} =
    useDirectoryListContext();
  const {onTableSectionChange} = useDirectoryTable({isDirectoryListPage: true});

  const getIsItemSelectable = (itemKey) =>
    directoryList.directoryListData.items?.find((directory) => directory.id === itemKey)?.type !==
    DIRECTORY_TYPE.TYPE2E;

  const onDeleteDirectoriesConfirm = () => {
    clearDirectoryCache();

    // We're doing this for now because of the temp_directory_list_refactor. The optional chaining operator (?)
    // should be removed when the flag is removed
    const loadDirectories = () =>
      feature.isEnabled('temp_saml_certs')
        ? loadDirectoryListWithCertificates?.({}, {updateCount: true})
        : loadDirectoryList?.({}, {updateCount: true});

    loadDirectories();
  };

  const columnDescriptor = [
    {key: 'name', props: {allowsSorting: true, minWidth: '40%'}},
    {key: 'triggerDrawer', props: {hideHeader: true, showDivider: true}},
    {key: 'type'},
    {key: 'status'},
  ];

  let renderers = {
    name: ({item}) => (
      <DirectoryName directory={item} goToDirectoryDetails={goToDirectoryDetails} />
    ),
    status: ({item}) => (
      <DirectoryStatus ownershipStatus={item.ownershipStatus} status={item.status} />
    ),
    triggerDrawer: ({item}) => <TrustedDirectoryDrawerTrigger directory={item} />,
    type: ({item}) => (
      <FormattedMessage id={`common.IdTypeSelect.selectOption.${camelCase(item.type)}`} />
    ),
  };

  if (feature.isEnabled('temp_domain_enforcement')) {
    columnDescriptor.splice(2, 0, {key: 'domainEnforcement', props: {minWidth: '20%'}});
    renderers = {
      ...renderers,
      domainEnforcement: ({item}) => {
        const hasDirectoryDE =
          (item.isType3 || item.isType2) &&
          typeof item.domainEnforcement !== 'undefined' &&
          item.domainEnforcement !== null;

        return hasDirectoryDE ? (
          <DirectoryDomainEnforcementStatus
            // stateEndsAt is when the notify state ends and domainEnforcement is enabled
            enablementDate={item.domainEnforcement?.stateEndsAt}
            state={item.domainEnforcement?.state}
            // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- remove formatting
            UNSAFE_className={styles['domain-enforcement-status']}
          />
        ) : (
          <View marginStart="size-400">
            <EnDashDefaultContent />
          </View>
        );
      },
    };
  }

  return (
    <OverlayWait isLoading={directoryList.isLoading} showContent>
      <TableSection
        data-testid="directory-list-table"
        getIsItemSelectable={getIsItemSelectable}
        id="directory-list-table"
        isServerError={directoryList.hasLoadingError}
        items={directoryList?.directoryListData.items}
        onTableSectionChange={onTableSectionChange}
        pageNumber={tableOptions.page}
        pageSizeOptions={tableOptions.pageSizeOptions}
        selectionMode={SELECTION_MODE.MULTIPLE}
        totalPages={tableOptions.totalPages}
      >
        <TableFilters
          isDisabled={directoryList.hasLoadingError}
          label={intl.formatMessage({
            id: 'settings.directory.search.label',
          })}
        >
          <Picker
            data-testid="directory-status-filter"
            defaultSelectedKey="ALL_STATUSES"
            label={intl.formatMessage({
              id: 'settings.directory.statusDropdown.label',
            })}
            marginBottom="size-300"
            marginStart="size-200"
            onSelectionChange={(value) =>
              onTableSectionChange({
                action: TABLE_SECTION_ACTIONS.FILTER_BY_STATUS,
                payload: {value},
              })
            }
          >
            <Item key="ALL_STATUSES">
              {intl.formatMessage({
                id: 'settings.directory.status.ALL_STATUSES',
              })}
            </Item>
            {Object.keys(DIRECTORY_STATUSES).map((status) => (
              <Item key={status}>
                {intl.formatMessage({
                  id: `settings.directory.status.${status}`,
                })}
              </Item>
            ))}
          </Picker>
        </TableFilters>
        <DirectoryTableActions
          directoryLinks={directoryLinks}
          goToSetupDomains={goToSetupDomains}
          onDeleteDirectoriesConfirm={onDeleteDirectoriesConfirm}
          rosterSyncs={rosterSyncs}
        />
        {(directoryList.directoryListData.items || directoryList.hasLoadingError) && (
          <TableSectionTable
            aria-label={intl.formatMessage({id: 'settings.directory.directoryList.ariaLabel'})}
            columnDescriptor={columnDescriptor}
            columnNamespace="settings.directory"
            density="spacious"
            noItemsFoundContentMessage={intl.formatMessage({
              id: 'settings.directory.noItemsFoundContentMessage',
            })}
            noSearchResultsContentMessage={intl.formatMessage({
              id: 'settings.directory.noSearchResultsContentMessage',
            })}
            renderers={renderers}
          />
        )}
      </TableSection>
    </OverlayWait>
  );
};

DirectoryListTable.displayName = 'DirectoryListTable';
DirectoryListTable.propTypes = {
  /**
   * The active organization's directory links
   */
  // eslint-disable-next-line react/forbid-prop-types -- data coming from Angular
  directoryLinks: PropTypes.array,
  /**
   * Handler for redirect to the directory details page
   */
  goToDirectoryDetails: PropTypes.func.isRequired,
  /**
   * Handler for redirect to the directory domains setup page
   */
  goToSetupDomains: PropTypes.func.isRequired,
  /**
   * The active organization's EDU roster syncs
   */
  // eslint-disable-next-line react/forbid-prop-types -- data coming from Angular
  rosterSyncs: PropTypes.array,
};

export default DirectoryListTable;
