import {feature, getHeaders, log} from '@admin-tribe/acsc';
import axios from 'axios';
import {useCallback, useEffect, useState} from 'react';
import {useErrorHandler} from 'react-error-boundary';

import rootStore from 'core/RootStore';
import {useAuthentication} from 'core/providers/authentication/AuthenticationProvider';
import {useConfiguration} from 'core/providers/configuration/ConfigurationProvider';
import {useOrganization} from 'core/providers/organization/OrganizationProvider';

/**
 * Custom React hook to manage the extended user profile.
 * It fetches and updates the extended user profile from the server.
 * It also keeps track of the first login time of the user.
 *
 * @returns {Object} An object containing the first login time and a flag indicating if the extended user profile has been loaded.
 */
const useExtendedUserProfile = () => {
  const [firstLoginTime, setFirstLoginTime] = useState(null);
  const [extendedUserProfileLoaded, setExtendedUserProfileLoaded] = useState(false);
  const {profile, authenticationLoaded, roles} = useAuthentication();
  const {configuration, configurationLoaded} = useConfiguration();
  const {organizationsLoaded} = useOrganization();
  const handleError = useErrorHandler();

  const createHeaders = useCallback(() => {
    const {includeRoles} = configuration;
    const clientId = configuration.services.ims.clientId;
    return getHeaders({clientId, includeRoles});
  }, [configuration]);

  const buildExtendedProfileUrl = useCallback(() => {
    const activeOrgId = rootStore.organizationStore.activeOrg.id;
    const userId = profile.userId;
    return `${configuration.services.jil.url}/v2/organizations/${activeOrgId}/users/${userId}/extended-profile`;
  }, [profile.userId, configuration]);

  const fetchExtendedUserProfile = useCallback(async () => {
    try {
      const url = buildExtendedProfileUrl();
      const response = await axios.get(url, {headers: createHeaders()});
      return response.data;
    } catch (error) {
      log.error('Error fetching extended user profile: ', error);
      throw error;
    }
  }, [buildExtendedProfileUrl, createHeaders]);

  const generatePatchPayloadForExtendedUser = useCallback(
    (userLoginTime) => [
      {
        op: 'replace',
        path: 'adminConsoleFirstLoginTime',
        value: userLoginTime.toISOString(),
      },
    ],
    []
  );

  const updateExtendedUserProfile = useCallback(
    async (userLoginTime) => {
      try {
        const url = buildExtendedProfileUrl();
        await axios.patch(url, generatePatchPayloadForExtendedUser(userLoginTime), {
          headers: createHeaders(),
        });
      } catch (error) {
        log.error('Error updating extended user profile: ', error);
        throw error;
      }
    },
    [buildExtendedProfileUrl, createHeaders, generatePatchPayloadForExtendedUser]
  );

  const initializeAndSetExtendedUserProfile = useCallback(async () => {
    try {
      const jilExtendedUserProfile = await fetchExtendedUserProfile();
      if (jilExtendedUserProfile?.adminConsoleFirstLoginTime) {
        setFirstLoginTime(new Date(jilExtendedUserProfile.adminConsoleFirstLoginTime));
      } else {
        const userLoginTime = new Date();
        await updateExtendedUserProfile(userLoginTime);
        setFirstLoginTime(userLoginTime);
      }
      setExtendedUserProfileLoaded(true);
    } catch (error) {
      handleError(error);
    }
  }, [fetchExtendedUserProfile, updateExtendedUserProfile, handleError]);

  // Fetch the extended user profile and update it if necessary
  useEffect(() => {
    if (!organizationsLoaded || !authenticationLoaded || !configurationLoaded) return;

    const initializeExtendedUserProfile = async () => {
      if (feature.isEnabled('temp_adobe_agent_access')) {
        if (roles.anyForOrg(rootStore.organizationStore.activeOrg.id)) {
          await initializeAndSetExtendedUserProfile();
        } else {
          setExtendedUserProfileLoaded(true);
        }
        return;
      }
      await initializeAndSetExtendedUserProfile();
    };

    initializeExtendedUserProfile();
  }, [
    organizationsLoaded,
    profile.userId,
    authenticationLoaded,
    configurationLoaded,
    configuration,
    roles,
    handleError,
    initializeAndSetExtendedUserProfile,
  ]);

  return {extendedUserProfileLoaded, firstLoginTime};
};

export default useExtendedUserProfile;
