import {LEVEL, PasswordPolicy, log} from '@admin-tribe/acsc';
import {showError, showSuccess} from '@admin-tribe/acsc-ui';
import {Flex, Radio, RadioGroup, Text} from '@adobe/react-spectrum';
import {useId} from '@react-aria/utils';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';

/**
 * Radio group of password settings options for this org.
 */
const PasswordSettingsRadioGroup = ({onPasswordPolicyChange, policy}) => {
  const intl = useIntl();
  const policyLabelId = useId();
  const {passwordPolicy} = policy;
  const [selected, setSelected] = useState(passwordPolicy);
  const isCurrentRef = useRef(true);

  const isRadioButtonSelected = (radioLevel) => radioLevel === selected;

  // This is a quick API. Do not block the UI while the save is outstanding.
  const onChange = async (newPolicy) => {
    try {
      await policy.save(newPolicy);
      if (isCurrentRef.current) {
        setSelected(newPolicy);
        onPasswordPolicyChange();
        showSuccess(intl.formatMessage({id: 'settings.authSettings.password.toast.success'}));
      }
    } catch (error) {
      log.error('Error saving password policy: ', error);
      if (isCurrentRef.current) {
        showError();
      }
    }
  };

  // This side effect is to track when the component is mounted/unmounted
  useEffect(() => {
    isCurrentRef.current = true;

    return () => {
      isCurrentRef.current = false;
    };
  }, []);

  return (
    <Flex alignItems="center" direction="row" gap="size-200" marginBottom="size-200">
      <Text id={policyLabelId} UNSAFE_style={{fontWeight: 'bold'}}>
        {intl.formatMessage({id: 'settings.authSettings.password.label'})}
      </Text>
      <RadioGroup
        aria-labelledby={policyLabelId}
        isEmphasized
        onChange={onChange}
        orientation="horizontal"
        value={selected}
      >
        <Radio
          autoFocus={isRadioButtonSelected(LEVEL.MEDIUM_REUSE)}
          data-testid="radio"
          marginEnd="size-300"
          value={LEVEL.MEDIUM_REUSE}
        >
          {intl.formatMessage({id: 'settings.authSettings.password.level.weak'})}
        </Radio>
        <Radio
          autoFocus={isRadioButtonSelected(LEVEL.MEDIUM_REUSE_RESET)}
          data-testid="radio"
          marginEnd="size-300"
          value={LEVEL.MEDIUM_REUSE_RESET}
        >
          {intl.formatMessage({id: 'settings.authSettings.password.level.medium'})}
        </Radio>
        <Radio autoFocus={isRadioButtonSelected(LEVEL.PCI)} data-testid="radio" value={LEVEL.PCI}>
          {intl.formatMessage({id: 'settings.authSettings.password.level.strong'})}
        </Radio>
      </RadioGroup>
    </Flex>
  );
};

PasswordSettingsRadioGroup.propTypes = {
  /**
   * Callback function triggered with no parameters on the change of a password policy.
   */
  onPasswordPolicyChange: PropTypes.func.isRequired,
  /**
   * The current password settings for the org.
   */
  policy: PropTypes.instanceOf(PasswordPolicy).isRequired,
};

export default PasswordSettingsRadioGroup;
