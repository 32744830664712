import binky, {configStore} from '@admin-tribe/acsc';
import {DetailSection} from '@admin-tribe/acsc-ui';
import {SUPPORT_TICKET_STATUS} from '@pandora/data-model-support-ticket';
import {fetch} from '@pandora/fetch';
import {AuthProvider} from '@pandora/react-auth-provider';
import {EnvProvider} from '@pandora/react-env-provider';
import {FetchProvider} from '@pandora/react-fetch-provider';
import {useModalDialog} from '@pandora/react-modal-dialog';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import SupportTicketActionModal from '../support-ticket-action-modal/SupportTicketActionModal';
import SupportCaseClosedSurveyModal from '../support-ticket-case-closed-survey-modal/SupportCaseClosedSurveyModal';

import ActionSection from './ActionSection';
import {initialState, useCaseDeatils} from './useCaseDetails';

const {authentication} = binky.services;

/**
 * A widget to display the Support Case Action Section which renders the child components <ActionSection />, <SupportTicketActionModal />
 * & <SupportCaseClosedSurveyModal />
 */
const SupportTicketActionSection = ({
  canReopen,
  canTakeSurvey,
  id,
  isClosed,
  isEscalated,
  isEscalationAllowed,
  isOpenCase,
  isSupportCase,
  refresh,
  status,
  surveyUrl = '',
  isCalendly,
}) => {
  const env = configStore.get('name');
  const clientId = configStore.get('services.ims.clientId');
  const intl = useIntl();
  const authDetails = {
    getAccessToken: () => authentication.getAccessToken()?.token,
  };
  const [isOpenSurveyModal, setOpenSurveyModal] = useState(false);
  const {isOpen, openModal, closeModal} = useModalDialog(false);
  const {openModelByCaseType, setStatusObject, statusObject} = useCaseDeatils();
  const caseModal = (type) => {
    openModelByCaseType(type);
    openModal();
  };
  const resetModal = () => {
    closeModal();
    setStatusObject(initialState);
  };

  const customFetch = (path, options) => {
    const mergedOptions = {
      ...options,
      headers: {
        'Content-Type': 'application/json',
        'X-Api-Key': clientId,
        ...options.headers,
      },
    };

    return fetch(path, mergedOptions);
  };

  return (
    <AuthProvider data-testid="modal-support-ticket-action" value={authDetails}>
      <EnvProvider value={env}>
        <FetchProvider value={customFetch}>
          <DetailSection
            data-testid="support-case-actions-buttons"
            headingLevel={3}
            marginBottom="size-225"
            marginTop="size-300"
            title={intl.formatMessage({id: 'support.supportTicketActionsSection.title'})}
            UNSAFE_style={{fontSize: '15px'}}
          >
            <ActionSection
              canReopen={canReopen}
              canTakeSurvey={canTakeSurvey}
              isCalendly={isCalendly}
              isClosed={isClosed}
              isEscalated={isEscalated}
              isEscalationAllowed={isEscalationAllowed}
              isOpenCase={isOpenCase}
              isSupportCase={isSupportCase}
              onClick={caseModal}
              status={status}
              surveyUrl={surveyUrl}
            />
          </DetailSection>
          <div data-testid="support-ticket-action-modal">
            {isOpen && (
              <SupportTicketActionModal
                actionCode={statusObject.ACTION_CODE}
                caseId={id}
                closeModal={() => {
                  closeModal();
                  resetModal();
                }}
                isOpen={isOpen}
                onSaveSuccess={refresh}
                optionLabel={statusObject.OPTION_LABEL}
                reasons={statusObject.REASON}
                resolutionLabel={statusObject.RESOLUTION_LABEL}
                setOpenSurveyModal={setOpenSurveyModal}
                successMessage={statusObject.ON_SAVE_SUCCESS}
                title={statusObject.TITLE}
              />
            )}
            {isOpenSurveyModal && canTakeSurvey && (
              <SupportCaseClosedSurveyModal
                caseId={id}
                isOpen={isOpenSurveyModal && canTakeSurvey}
                onCancel={() => setOpenSurveyModal(false)}
                surveyUrl={surveyUrl}
              />
            )}
          </div>
        </FetchProvider>
      </EnvProvider>
    </AuthProvider>
  );
};

SupportTicketActionSection.propTypes = {
  /**
   * canReopen used to get canReopen button (enable/disable)
   */
  canReopen: PropTypes.bool,
  /**
   * Prop used to display survey button
   */
  canTakeSurvey: PropTypes.bool,
  /**
   * Prop used to get selected ticket ID.
   */
  id: PropTypes.string.isRequired,
  /**
   * Prop used to show different user message under Action section
   */
  isCalendly: PropTypes.bool,
  /**
   * Prop used to get selected case is closed or not.
   */
  isClosed: PropTypes.bool,
  /**
   * Prop used to set escalate case button tooltip & check selected case is already escalated.
   */
  isEscalated: PropTypes.bool,
  /**
   * Prop used to set escalate case button (enable/disable) & tooltip.
   */
  isEscalationAllowed: PropTypes.bool,
  /**
   * Prop used to check case/ticket is open.
   */
  isOpenCase: PropTypes.bool,
  /**
   * Prop used to check if ticket belongs to support case.
   */
  isSupportCase: PropTypes.bool,
  /**
   * Prop used to refresh the modal
   */
  refresh: PropTypes.func,
  /**
   * Prop used to set tooltip
   */
  status: PropTypes.oneOf(Object.values(SUPPORT_TICKET_STATUS)),
  /**
   * Prop used to get surveyUrl for survey form.
   */
  surveyUrl: PropTypes.string,
};
export default SupportTicketActionSection;
