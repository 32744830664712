import {feature} from '@admin-tribe/acsc';
import {Flex, Provider, StatusLight, Text, View, lightTheme} from '@adobe/react-spectrum';
import OverlayTrigger from '@react/react-spectrum/OverlayTrigger';
// eslint-disable-next-line @admin-tribe/admin-tribe/react-spectrum-prefer-v3 -- v2 Popover should be replaced with v3 Dialog
import Popover from '@react/react-spectrum/Popover';
// eslint-disable-next-line @admin-tribe/admin-tribe/react-spectrum-prefer-v3 -- @iteodore needs to fix
import Tooltip from '@react/react-spectrum/Tooltip';
import Alert from '@spectrum-icons/workflow/Alert';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {RawIntlProvider, useIntl} from 'react-intl';

import {SYNC_TASK_STATUSES} from 'features/settings/stores/DirectorySyncStore';

import SyncStatusError from './SyncStatusError';
import SyncStatusLabel from './SyncStatusLabel';
import './SyncCard.pcss';

const LIGHT_VARIANTS = {
  [SYNC_TASK_STATUSES.ADMIN_OFF]: 'neutral',
  [SYNC_TASK_STATUSES.DONE]: 'positive',
  [SYNC_TASK_STATUSES.ERROR]: 'negative',
  [SYNC_TASK_STATUSES.NOT_STARTED]: 'neutral',
  [SYNC_TASK_STATUSES.OFF]: 'neutral',
};

const SyncStatus = observer(
  ({adminEnabled, detailed, directorySyncStore, enabled, status, lastSyncDate}) => {
    const intl = useIntl();

    let statusLightVariant;

    if (adminEnabled) {
      statusLightVariant = enabled
        ? LIGHT_VARIANTS[status] || LIGHT_VARIANTS[SYNC_TASK_STATUSES.DONE]
        : LIGHT_VARIANTS[SYNC_TASK_STATUSES.OFF];
    } else {
      statusLightVariant = LIGHT_VARIANTS[SYNC_TASK_STATUSES.ADMIN_OFF];
    }

    return status === SYNC_TASK_STATUSES.ERROR && detailed ? (
      <StatusLight marginStart="-10px" variant={statusLightVariant}>
        <OverlayTrigger placement="right">
          <div styleName="tooltip-info">
            <SyncStatusLabel
              adminEnabled={adminEnabled}
              enabled={enabled}
              lastSyncDate={lastSyncDate}
              status={status}
            />
            <div styleName="alert-icon-container">
              <Alert aria-label="Negative Alert" color="negative" size="S" />
            </div>
          </div>
          {feature.isEnabled('temp_enhanced_sync_error_tooltip') ? (
            <Popover
              placement="left"
              title={intl.formatMessage({id: 'settings.sync.status.tooltip.error.title'})}
              variant="error"
            >
              <RawIntlProvider value={intl}>
                <Provider colorScheme="light" theme={lightTheme}>
                  <Flex>
                    <View width="240px">
                      <Text>
                        <SyncStatusError directorySyncStore={directorySyncStore} />
                      </Text>
                    </View>
                  </Flex>
                </Provider>
              </RawIntlProvider>
            </Popover>
          ) : (
            <Tooltip>{intl.formatMessage({id: 'settings.sync.status.tooltip.error'})}</Tooltip>
          )}
        </OverlayTrigger>
      </StatusLight>
    ) : (
      <StatusLight marginStart="-10px" variant={statusLightVariant}>
        <SyncStatusLabel
          adminEnabled={adminEnabled}
          enabled={enabled}
          lastSyncDate={lastSyncDate}
          status={status}
        />
      </StatusLight>
    );
  }
);

export default SyncStatus;
export {LIGHT_VARIANTS, SYNC_TASK_STATUSES};
