import {Contract, ProductList} from '@admin-tribe/acsc';
import {ModalContent} from '@admin-tribe/acsc-ui';
import {View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';

import {EVENT_NAME} from '../SelfCancelAnalyticsUtils';
import {SELF_CANCEL_STEPS} from '../SelfCancelConstants';
import MerchandisingText from '../components/merchandising-text/MerchandisingText';
import {
  ACTIONS,
  useProductsChangeContext,
} from '../components/products-change-context/ProductsChangeContext';
import SaveOffersCardFactory from '../components/save-offer-cards/SaveOffersCardFactory';
import {useSelfCancelAnalyticsContext} from '../components/self-cancel-analytics-context/SelfCancelAnalyticsContext';
import SelfCancelBaseFooter from '../components/self-cancel-base-modal/SelfCancelBaseFooter';
import SelfCancelBaseModal from '../components/self-cancel-base-modal/SelfCancelBaseModal';
import SelfCancelCarousel from '../components/self-cancel-carousel/SelfCancelCarousel';
import SelfCancelFooterTotal from '../components/self-cancel-footer-total/SelfCancelFooterTotal';
import useSaveOffers from '../hooks/use-save-offers/useSaveOffers';

import styles from './SaveOffersStep.pcss';

const {SAVE_OFFERS_STEP} = SELF_CANCEL_STEPS;

/**
 * Self cancel workflow step component for the save offers selection.
 */
const SaveOffersStep = ({
  comment,
  contract,
  currentStep,
  onAcceptSaveOffer,
  onClose,
  onContinue,
  onPrevious,
  onReviewSaveOffer,
  onStartChat,
  productList,
  selectedReasons,
  selectedSeats,
  steps,
}) => {
  const intl = useIntl();

  const [errorMessage, setErrorMessage] = useState();

  const selectedRetentionId = useRef();

  const {
    dispatchProductsChange,
    isLoading: isLoadingSubmitted,
    error,
    productsChange,
  } = useProductsChangeContext();

  const {dispatchNavigationAnalyticsEvent, dispatchSelfCancelPageAnalytics} =
    useSelfCancelAnalyticsContext();

  const onCta = () => {
    dispatchNavigationAnalyticsEvent({clickType: EVENT_NAME.NO_THANKS});
    onContinue?.();
  };

  const onCancel = () => {
    dispatchNavigationAnalyticsEvent({clickType: EVENT_NAME.CANCEL});
    onClose?.();
  };

  const onSecondary = () => {
    dispatchNavigationAnalyticsEvent({clickType: EVENT_NAME.PREVIOUS});
    onPrevious?.();
  };

  const onCloseError = () => setErrorMessage();

  const onAcceptFreeTimeOffer = useCallback(
    (retentionId) => {
      selectedRetentionId.current = retentionId;
      dispatchProductsChange({
        comment,
        retentionId,
        selectedReasons,
        selectedSeats,
        type: ACTIONS.SUBMIT_SAVE_OFFERS,
      });
    },
    [dispatchProductsChange, comment, selectedReasons, selectedSeats]
  );

  const onSetUpChatSession = useCallback(
    () => onStartChat?.({step: SAVE_OFFERS_STEP}),
    [onStartChat]
  );

  const {isLoading, cards, specialInstructions} = useSaveOffers({
    comment,
    contract,
    onAcceptFreeTimeOffer,
    onReviewSaveOffer,
    onSetUpChatSession,
    selectedReasons,
    selectedSeats,
  });

  // used to check for errors and submission when the Free Months CTA is clicked
  useEffect(() => {
    if (!isLoading && !isLoadingSubmitted) {
      // set error only if error occured when Free Months CTA was clicked.
      if (error && selectedRetentionId.current) {
        setErrorMessage(
          intl.formatMessage({
            id: 'account.selfCancel.saveOffers.submit.error',
          })
        );
        selectedRetentionId.current = undefined;
      } else if (productsChange.submitted) {
        onAcceptSaveOffer?.(selectedRetentionId.current);
      }
    }
  }, [error, intl, isLoading, isLoadingSubmitted, onAcceptSaveOffer, productsChange]);

  const isPageAnalyticsSent = useRef(false);

  // send analytics on state load. isLoading will be true initially. However isLoading will
  // change when API Request is successful and the ProductsChange object updates with retention data.
  // This will result in cards being populated
  useEffect(() => {
    if (!isPageAnalyticsSent.current && !isLoading) {
      isPageAnalyticsSent.current = true;
      dispatchSelfCancelPageAnalytics({cards, step: SELF_CANCEL_STEPS.SAVE_OFFERS_STEP});
    }
  }, [cards, dispatchSelfCancelPageAnalytics, isLoading]);

  return (
    <SelfCancelBaseModal
      cancelLabel={intl.formatMessage({id: 'common.modal.buttons.close'})}
      ctaLabel={intl.formatMessage({id: 'account.selfCancel.buttons.noThanks'})}
      currentStep={currentStep}
      errorMessage={errorMessage}
      heading={intl.formatMessage({id: 'account.selfCancel.common.title'})}
      isLoading={isLoading || isLoadingSubmitted}
      onCancel={onCancel}
      onCloseError={onCloseError}
      onCta={onCta}
      onSecondary={onSecondary}
      secondaryLabel={intl.formatMessage({id: 'account.selfCancel.buttons.previous'})}
      steps={steps}
      subheading={intl.formatMessage({id: 'account.selfCancel.saveOffers.description'})}
    >
      <ModalContent>
        {!isLoading && (
          <>
            <SelfCancelCarousel
              cards={cards}
              carouselAriaLabel={intl.formatMessage({
                id: 'account.selfCancel.saveOffers.carousel.ariaLabel',
              })}
              Component={SaveOffersCardFactory}
              step={currentStep}
            />
            {specialInstructions?.length > 0 && (
              <View
                marginTop="size-400"
                marginX="auto"
                maxWidth="860px"
                // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- text
                UNSAFE_className={styles['special-instructions']}
              >
                {specialInstructions.map((specialInstruction) => (
                  <View key={specialInstruction}>
                    <MerchandisingText message={specialInstruction} />
                  </View>
                ))}
              </View>
            )}
          </>
        )}
      </ModalContent>
      <SelfCancelBaseFooter>
        <SelfCancelFooterTotal
          contract={contract}
          productList={productList}
          selectedSeats={selectedSeats}
        />
      </SelfCancelBaseFooter>
    </SelfCancelBaseModal>
  );
};

SaveOffersStep.propTypes = {
  /**
   * The cancellation reason inputted by admin manually.
   */
  comment: PropTypes.string,
  /**
   * The org's contract.
   */
  contract: PropTypes.instanceOf(Contract).isRequired,
  /**
   * Current step in the flow.
   */
  currentStep: PropTypes.string,
  /**
   * Handler that is called when the user wants to accept and submit a save offer.
   */
  onAcceptSaveOffer: PropTypes.func,
  /**
   * Handler that is called when the user cancels out from the modal.
   */
  onClose: PropTypes.func,
  /**
   * Handler that is called when the user wants to progress in the flow.
   */
  onContinue: PropTypes.func,
  /**
   * Handler that is called when the user wants to navigate back.
   */
  onPrevious: PropTypes.func,
  /**
   * Handler that is called when the user wants to get details for a save offer.
   */
  onReviewSaveOffer: PropTypes.func,
  /**
   * Handler that is called when the user wants to start a chat session.
   * Will also send up an object specifying which step a user is initiating a chat session from.
   * i.e. {step: 'SaveOffersStep'}
   */
  onStartChat: PropTypes.func,
  /**
   * The list of org's products.
   */
  productList: PropTypes.instanceOf(ProductList).isRequired,
  /**
   * The selected reason codes for cancellation.
   */
  selectedReasons: PropTypes.arrayOf(PropTypes.string),
  /**
   * The hash map of of initially selected licenses per product Id.
   */
  selectedSeats: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  /**
   * List of steps in the flow.
   */
  steps: PropTypes.arrayOf(PropTypes.string),
};

export default SaveOffersStep;
