/**
 * @description Redirect the current user session to the main home page for successful approval.
 *
 * @param {String} orgId The org of the user
 */
const redirectToHomePage = (orgId) => {
  if (typeof window !== 'undefined') {
    window.location.href = `/${orgId}`;
  }
};

/**
 * @description Redirect the current user session to the main product access review page for any
 *   error while processing approval.
 * @param {String} orgId The org of the user
 * @param {String} userId The user id of the current user session.
 */
const redirectToProductReviewPage = (orgId, userId) => {
  if (typeof window !== 'undefined') {
    if (userId !== null && userId !== undefined) {
      window.location.href = `/${orgId}/products/requests/${userId}`;
    } else {
      window.location.href = `/${orgId}/products/requests`;
    }
  }
};

export {redirectToHomePage, redirectToProductReviewPage};
