import {PageWait} from '@pandora/react-page-wait';
import PropTypes from 'prop-types';
import React, {createContext, useCallback, useContext, useMemo, useState} from 'react';

const AppLoadingOverlayContext = createContext();

/**
 * Provides Configuration data to its children components.
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The children components.
 * @returns {React.ReactNode} The wrapped children components.
 */
const AppLoadingOverlayProvider = ({children}) => {
  const [isLoading, setIsLoading] = useState(true);

  const hideLoadingOverlay = useCallback(() => {
    setIsLoading(false);
  }, [setIsLoading]);

  const contextValue = useMemo(
    () => ({hideLoadingOverlay, isLoading}),
    [isLoading, hideLoadingOverlay]
  );

  // TODO : localize this content
  const props = {
    ariaLabel: 'Loading Page...',
    delay: '0',
    message: '',
  };

  return (
    <AppLoadingOverlayContext.Provider value={contextValue}>
      {isLoading && <PageWait {...props} />}
      {children}
    </AppLoadingOverlayContext.Provider>
  );
};

AppLoadingOverlayProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useAppLoadingOverlay = () => useContext(AppLoadingOverlayContext);

export {AppLoadingOverlayProvider, useAppLoadingOverlay};
