import {log, modelCache} from '@admin-tribe/acsc';
import {DATE_FORMATS, Page, PageContent, PageHeader, PageNav} from '@admin-tribe/acsc-ui';
import {ButtonGroup} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import useDocumentTitle from 'common/hooks/useDocumentTitle';
import StorageReportFolderList from 'common/services/storage-report-folder-list/StorageReportFolderList';
import {CACHE_ID as STORAGE_REPORT_FOLDER_LIST_CACHE_ID} from 'common/services/storage-report-folder-list/StorageReportFolderListConstants';
import CreateReportButton from 'features/storage/components/create-report/button/CreateReportButton';
import ReportFolderListTable from 'features/storage/components/report-folder-list-table/ReportFolderListTable';

/**
 * Defines the 'Storage reports' page under the Storage tab.
 */
const StorageReportsPage = ({navigationCallback}) => {
  const [isBumpered, setIsBumpered] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [displayList, setDisplayList] = useState([]);
  const intl = useIntl();

  useDocumentTitle({key: 'storage.storageReports.title'});

  // Initial load.
  useEffect(() => {
    let isMounted = true;

    const getDisplayList = (storageReportList) => {
      const list = [];

      storageReportList.items.forEach((item) => {
        list.push({
          folderName: intl.formatMessage({
            id: `storage.storageReports.details.folderType.${item.name}.title`,
          }),
          folderType: item.name,
          lastUpdated: intl.formatDate(
            item.repositoryLastModifiedDate,
            DATE_FORMATS.dateTimeMedium
          ),
        });
      });

      return list;
    };

    const fetchReportFolderList = async () => {
      try {
        const list = await StorageReportFolderList.get();
        if (isMounted) {
          setDisplayList(getDisplayList(list));
        }
      } catch (error) {
        log.error(`Error getting StorageReportList: ${error}`);
        if (isMounted) {
          setDisplayList([]);
          setIsBumpered(true);
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    fetchReportFolderList();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- initial load
  }, []);

  const onCreateReport = (folderType) => {
    // If a report was created for a report folder type which does not yet exist, clear the
    // StorageReportFolderList cache so that we'll fetch a new report folder list.
    const hasReportFolder = displayList.some((folder) => folder.folderType === folderType);
    if (!hasReportFolder) {
      modelCache.clear(STORAGE_REPORT_FOLDER_LIST_CACHE_ID);
    }
    navigationCallback(folderType);
  };

  return (
    <Page data-testid="storage-reports-page" isBumpered={isBumpered} isLoading={isLoading}>
      <PageHeader title={intl.formatMessage({id: 'storage.storageReports.title'})} />
      <PageNav />
      <PageContent>
        {!isLoading && (
          <>
            <ButtonGroup align="end" marginBottom="size-200" width="100%">
              <CreateReportButton onCreateReport={onCreateReport} />
            </ButtonGroup>
            <ReportFolderListTable list={displayList} navigationCallback={navigationCallback} />
          </>
        )}
      </PageContent>
    </Page>
  );
};

StorageReportsPage.propTypes = {
  /**
   * Callback to go to the 'Storage details' page for the given folder type.
   * It takes one param which is of type REPORT_FOLDER_TYPE - either 'shared' or 'users'.
   */
  navigationCallback: PropTypes.func.isRequired,
};

export default StorageReportsPage;
