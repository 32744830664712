import PropTypes from 'prop-types';
import React, {createContext, useContext, useEffect, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';

import getOrganizationContractMigration from 'features/users/services/org-contract-migration/getOrganizationContractMigration';

const TeamToEnterpriseContractMigrationContext = createContext({});

/**
 * Context provider for managing the state related to team to enterprise contract migration.
 *
 * This provider fetches the eligible contract for migration and calculates the total license count.
 * It also manages the loading state and any error messages related to the migration process.
 *
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The child components to be rendered within this provider.
 * @returns {JSX.Element} The context provider component.
 */
// eslint-disable-next-line @admin-tribe/admin-tribe/one-component-file -- This is a context provider
const TeamToEnterpriseMigrationContextProvider = ({children}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [migrationData, setMigrationData] = useState({
    contractEligibleForMigration: null,
    errorMessage: undefined,
  });
  const [totalLicenseCount, setTotalLicenseCount] = useState(0);
  const [showSuccessBanner, setShowSuccessBanner] = useState(false);
  const intl = useIntl();

  // Fetches the migration data and updates the state variables.
  useEffect(() => {
    const calculateTotalLicenses = (migrationLicensesData) =>
      migrationLicensesData.reduce((sum, product) => sum + product.quantity, 0);

    const getMigrationData = async () => {
      try {
        setIsLoading(true);
        const contractEligibleForMigration = await getOrganizationContractMigration();
        if (!contractEligibleForMigration.isEligible) {
          throw new Error('Not eligible for migration');
        }

        const migrationLicensesData =
          contractEligibleForMigration.contractMigrationList.items[0]?.migrationContext.products;
        const totalLicensesCount = calculateTotalLicenses(migrationLicensesData);

        setMigrationData({contractEligibleForMigration, errorMessage: undefined});
        setTotalLicenseCount(totalLicensesCount);
      } catch (error) {
        const errorMsg = intl.formatMessage({
          id: 'migrations.teamToEnterpriseMigrationModal.errorMsg',
        });
        setMigrationData({contractEligibleForMigration: null, errorMessage: errorMsg});
      } finally {
        setIsLoading(false);
      }
    };

    getMigrationData();
  }, [intl]);

  const contextValue = useMemo(
    () => ({
      isLoading,
      migrationData,
      setIsLoading,
      setShowSuccessBanner,
      showSuccessBanner,
      totalLicenseCount,
    }),
    [isLoading, migrationData, showSuccessBanner, totalLicenseCount]
  );

  return (
    <TeamToEnterpriseContractMigrationContext.Provider value={contextValue}>
      {children}
    </TeamToEnterpriseContractMigrationContext.Provider>
  );
};

/**
 *
 * Custom hook to access the team to enterprise contract migration context.
 */
const useTeamToEnterpriseMigrationContext = () =>
  useContext(TeamToEnterpriseContractMigrationContext);

TeamToEnterpriseMigrationContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export {TeamToEnterpriseMigrationContextProvider, useTeamToEnterpriseMigrationContext};
