import {
  FULFILLABLE_ITEM_CODE,
  FULFILLABLE_ITEM_DELEGATION_TYPE,
  Product,
  collatorSort,
  feature,
} from '@admin-tribe/acsc';
import {Content, Header, useCollator} from '@adobe/react-spectrum';
import {Drawer} from '@pandora/react-drawer';
import PropTypes from 'prop-types';
import React, {useEffect, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';

import ProductDetailsDrawerHeader from 'features/products/components/product-details-drawer/product-details-drawer-header/ProductDetailsDrawerHeader';
import ProductInstanceDetailSection from 'features/products/components/product-instance-detail-section/ProductInstanceDetailSection';
import ProductLinksDetailSection from 'features/products/components/product-links-detail-section/ProductLinksDetailSection';
import ProductServicesDetailSection from 'features/products/components/product-services-detail-section/ProductServicesDetailSection';
import ProductSupportLevelDetailSection from 'features/products/components/product-support-detail-section/ProductSupportLevelDetailSection';
import PremierSupportContactsSection from 'features/support/components/premier-support-contacts-section/PremierSupportContactsSection';

const NO_APPLICATIONS_CODE = 'package_preconditioning';
const PRODUCT_DETAILS_DRAWER_ID = 'product-details-drawer-id';

/**
 * Displays details of product selected from product table
 */
// eslint-disable-next-line complexity -- 1 over max
const ProductDetailsDrawer = ({product}) => {
  const intl = useIntl();
  const collator = useCollator();

  const [isLoading, setIsLoading] = useState(true);
  const [metaData, setMetaData] = useState([]);
  const [isMetaDataError, setIsMetaDataError] = useState(false);

  // Fetch meta data and set initial state.
  useEffect(() => {
    let isMounted = true;

    const getMetaData = async () => {
      try {
        const response = await product.getMetadata();
        if (isMounted) {
          setMetaData(response);
        }
      } catch {
        if (isMounted) {
          setIsMetaDataError(true);
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };
    getMetaData();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- run only on mount
  }, []);

  const productSupportLevel = product.getFulfillableItemNameByCode(
    FULFILLABLE_ITEM_CODE.SUPPORT_PLAN_LEVEL
  );
  const applications = useMemo(
    () =>
      collatorSort({
        collator,
        inputArr: product.fulfillableItemList
          .getDesktopTypeItems()
          // Adobe Stock, and perhaps others, don't have applications
          .filter((item) => item.code !== NO_APPLICATIONS_CODE),
        key: 'longName',
      }),
    [collator, product]
  );
  const services = useMemo(
    () =>
      collatorSort({
        collator,
        inputArr: [
          ...product.fulfillableItemList.getServiceTypeItems().filter((fi) => fi.selected),
          ...product.fulfillableItemList
            .getQuotaTypeItems(false, FULFILLABLE_ITEM_DELEGATION_TYPE.PERSON)
            // we compare as a string as the cap can technically be either a string or number
            .filter((fi) => `${fi.chargingModel.cap}` !== '0'),
        ],
        key: 'enterpriseName',
      }),
    [collator, product]
  );
  const hasSingleAppConfig = product.fulfillableItemList.hasSingleDesktopApplicationConfig();
  const links = product?.links?.detailed_help_admin;
  const supportFIs = product.fulfillableItemList.getSupportTypeItems();
  const supportContactsFIcode = supportFIs?.[0]?.code;

  return (
    <Drawer aria-labelledby={PRODUCT_DETAILS_DRAWER_ID} isLoading={isLoading}>
      <Header marginBottom="size-350">
        <ProductDetailsDrawerHeader
          id={PRODUCT_DETAILS_DRAWER_ID}
          productOrProductGroup={product}
        />
      </Header>
      <Content>
        {applications?.length > 0 && (
          <ProductServicesDetailSection
            services={applications}
            title={
              hasSingleAppConfig
                ? intl.formatMessage({
                    id: 'products.productDetailDrawer.availableApplications.title',
                  })
                : intl.formatMessage({
                    id: 'products.productDetailDrawer.includedApplications.title',
                  })
            }
          />
        )}
        {/* If no meta data - don't show the section. If meta data API call failed - show the section with error message */}
        {(metaData.length > 0 || isMetaDataError) && (
          <ProductInstanceDetailSection metaData={metaData} showError={isMetaDataError} />
        )}
        <ProductServicesDetailSection
          hasSingleAppConfig={hasSingleAppConfig}
          services={services}
          title={
            hasSingleAppConfig
              ? intl.formatMessage({id: 'products.productDetailDrawer.availableServices.title'})
              : intl.formatMessage({id: 'products.productDetailDrawer.includedServices.title'})
          }
        />
        {feature.isEnabled('temp_parkour_mm') && productSupportLevel && (
          <ProductSupportLevelDetailSection
            productId={product.id}
            productSupportLevel={productSupportLevel}
          />
        )}
        {feature.isEnabled('temp_parkour_mm') && supportContactsFIcode && (
          <PremierSupportContactsSection
            fulfillableItemCode={supportContactsFIcode}
            instanceId={product.id}
          />
        )}
        {links?.length > 0 && <ProductLinksDetailSection links={links} />}
      </Content>
    </Drawer>
  );
};

ProductDetailsDrawer.propTypes = {
  /**
   * The product with product name, description etc.
   */
  product: PropTypes.instanceOf(Product),
};

export default ProductDetailsDrawer;
