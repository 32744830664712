import {
  InactiveDirectoryDialogTriggerIconContentModel,
  InactiveDomainDialogTriggerIconContentModel,
  MultipleProductProfilesDialogTriggerIconContentModel,
  NoLicensesDialogTriggerIconContentModel,
  RemovedProductDialogTriggerIconContentModel,
  RemovedProductProfileDialogTriggerIconContentModel,
  UnselectedDomainsDialogTriggerIconContentModel,
} from '@pandora/react-auto-product-assignment-errors';
import {AutoProductAssignmentModalContentModel} from '@pandora/react-auto-product-assignment-modal';
import {
  AutoProductAssignmentTableActionsContentModel,
  AutoProductAssignmentTableContentModel,
  AutoProductAssignmentTableSectionContentModel,
} from '@pandora/react-auto-product-assignment-table-section';
import {DirectoryDomainEnforcementStatusContentModel} from '@pandora/react-directory-domain-enforcement-status';
import {
  DirectoryDomainSelectionContentModel,
  DirectoryEntryContentModel,
  DomainEntryContentModel,
} from '@pandora/react-directory-section';
import {InsightsContentModel} from '@pandora/react-insights';
import {
  LibraryListTableSectionContentModel,
  LibraryTableSectionContentModel,
} from '@pandora/react-library';
import {LicensePricingModel} from '@pandora/react-license-pricing';
import {ReactMiniAppSupportTicketContentModel} from '@pandora/react-miniapp-support-ticket';
import {FullProductAssignedLicensesSummary} from '@pandora/react-product-assigned-licenses-summary';
import {ProductRequestSettingsModalContentModel} from '@pandora/react-product-request-settings-modal';
import {ProductUsageContentModel} from '@pandora/react-product-usage';
import {RequestAccessTableSectionContentModel} from '@pandora/react-request-access-table-section';
import {ReviewProductRequestModalContentModel} from '@pandora/react-review-product-request-modal';
import {ReviewProductRequestsTableSectionContentModel} from '@pandora/react-review-product-requests-table-section';
import {
  SharedProjectListTableSectionContentModel,
  SharedProjectTableSectionContentModel,
} from '@pandora/react-shared-projects';
import {
  SupportPlanSummaryContentModel,
  SupportTicketAttachmentsButtonFormSectionContentModel,
  SupportTicketCommentFormSectionContentModel,
  SupportTicketContactsModalContentModel,
  SupportTicketListViewContentModel,
} from '@pandora/react-support-ticket';
import {UserPickerV3ContentModel} from '@pandora/react-user-picker';
import {ValidatedTextFieldContentModel} from '@pandora/react-validated-text-field';

import AddApiCredentialsModalContentModel from 'common/components/add-api-credentials-modal/content/AddApiCredentialsModalContentModel';
import AddAppIntegrationModalContentModel from 'features/products/app-integrations/content/AddAppIntegrationModalContentModel';
import AppIntegrationsManageUsersModalContentModel from 'features/products/app-integrations/content/AppIntegrationsManageUsersModalContentModel';
import AppIntegrationsPolicyModalContentModel from 'features/products/app-integrations/content/AppIntegrationsPolicyModalContentModel';

/**
 * If adding a new Pandora component to onesie (which is not already
 * added in binky-ui) with defaults strings, update the object below
 * to include any content models the component relies on.
 *
 * This object will be merged in with the binky-ui one to allow Pandora
 * to access our translated strings.
 *
 * Binky-ui's contentModel: https://git.corp.adobe.com/admin-tribe/binky-ui/blob/master/src2/core/services/pandoraContentModel.js#L17
 */
const pandoraContentModel = {
  addApiCredentialsModalContent: {
    id: 'addApiCredentialsModalContent',
    type: AddApiCredentialsModalContentModel,
  },
  addAppIntegrationModalContent: {
    id: 'addAppIntegrationModalContent',
    type: AddAppIntegrationModalContentModel,
  },
  appIntegrationsManageUsersModalContent: {
    id: 'appIntegrationsManageUsersModalContent',
    type: AppIntegrationsManageUsersModalContentModel,
  },
  appIntegrationsPolicyModalContent: {
    id: 'appIntegrationsPolicyModalContent',
    type: AppIntegrationsPolicyModalContentModel,
  },
  autoProductAssignmentModalContent: {
    id: 'autoProductAssignmentModalContent',
    type: AutoProductAssignmentModalContentModel,
  },
  autoProductAssignmentTableActionsContent: {
    id: 'autoProductAssignmentTableActionsContent',
    type: AutoProductAssignmentTableActionsContentModel,
  },
  autoProductAssignmentTableContent: {
    id: 'autoProductAssignmentTableContent',
    type: AutoProductAssignmentTableContentModel,
  },
  autoProductAssignmentTableSectionContent: {
    id: 'autoProductAssignmentTableSectionContent',
    type: AutoProductAssignmentTableSectionContentModel,
  },
  directoryDomainEnforcementStatusContent: {
    id: 'directoryDomainEnforcementStatusContent',
    type: DirectoryDomainEnforcementStatusContentModel,
  },
  directoryDomainSelectionContent: {
    id: 'directoryDomainSelectionContent',
    type: DirectoryDomainSelectionContentModel,
  },
  directoryEntryContent: {
    id: 'directoryEntryContent',
    type: DirectoryEntryContentModel,
  },
  domainEntryContent: {
    id: 'domainEntryContent',
    type: DomainEntryContentModel,
  },
  fullProductAssignedLicensesSummary: {
    id: 'fullProductAssignedLicensesSummary',
    type: FullProductAssignedLicensesSummary,
  },
  inactiveDirectoryDialogTriggerIcon: {
    id: 'inactiveDirectoryDialogTriggerIcon',
    type: InactiveDirectoryDialogTriggerIconContentModel,
  },
  inactiveDomainDialogTriggerIcon: {
    id: 'inactiveDomainDialogTriggerIcon',
    type: InactiveDomainDialogTriggerIconContentModel,
  },
  insightsContent: {
    id: 'insightsContent',
    type: InsightsContentModel,
  },
  libraryListTableSection: {
    id: 'libraryListTableSection',
    type: LibraryListTableSectionContentModel,
  },
  libraryTableSection: {
    id: 'libraryTableSection',
    type: LibraryTableSectionContentModel,
  },
  licensePricing: {
    id: 'licensePricing',
    type: LicensePricingModel,
  },
  multipleProductProfilesDialogTriggerIcon: {
    id: 'multipleProductProfilesDialogTriggerIcon',
    type: MultipleProductProfilesDialogTriggerIconContentModel,
  },
  noLicensesDialogTriggerIcon: {
    id: 'noLicensesDialogTriggerIcon',
    type: NoLicensesDialogTriggerIconContentModel,
  },
  productRequestSettingsModalContent: {
    id: 'productRequestSettingsModalContent',
    type: ProductRequestSettingsModalContentModel,
  },
  productUsageContent: {
    id: 'productUsageContent',
    type: ProductUsageContentModel,
  },
  reactMiniAppSupportTicketContent: {
    id: 'reactMiniAppSupportTicketContent',
    type: ReactMiniAppSupportTicketContentModel,
  },
  removedProductDialogTriggerIcon: {
    id: 'removedProductDialogTriggerIcon',
    type: RemovedProductDialogTriggerIconContentModel,
  },
  removedProductProfileDialogTriggerIcon: {
    id: 'removedProductProfileDialogTriggerIcon',
    type: RemovedProductProfileDialogTriggerIconContentModel,
  },
  requestAccessTableSection: {
    id: 'requestAccessTableSection',
    type: RequestAccessTableSectionContentModel,
  },
  reviewProductRequestModal: {
    id: 'reviewProductRequestModal',
    type: ReviewProductRequestModalContentModel,
  },
  reviewProductRequestsTableSection: {
    id: 'reviewProductRequestsTableSection',
    type: ReviewProductRequestsTableSectionContentModel,
  },
  sharedProjectListTableSection: {
    id: 'sharedProjectListTableSection',
    type: SharedProjectListTableSectionContentModel,
  },
  sharedProjectTableSection: {
    id: 'sharedProjectTableSection',
    type: SharedProjectTableSectionContentModel,
  },
  supportPlanSummaryContentModel: {
    id: 'supportPlanSummaryContentModel',
    type: SupportPlanSummaryContentModel,
  },
  /* eslint-disable-next-line id-length -- item needed */
  supportTicketAttachmentsButtonFormSectionContent: {
    id: 'supportTicketAttachmentsButtonFormSectionContent',
    type: SupportTicketAttachmentsButtonFormSectionContentModel,
  },
  supportTicketCommentFormSectionContent: {
    id: 'supportTicketCommentFormSectionContent',
    type: SupportTicketCommentFormSectionContentModel,
  },
  supportTicketContactsModalContentModel: {
    id: 'supportTicketContactsModalContentModel',
    type: SupportTicketContactsModalContentModel,
  },
  supportTicketListViewContentModel: {
    id: 'supportTicketListViewContentModel',
    type: SupportTicketListViewContentModel,
  },
  unselectedDomainsDialogTriggerIcon: {
    id: 'unselectedDomainsDialogTriggerIcon',
    type: UnselectedDomainsDialogTriggerIconContentModel,
  },
  userPickerV3Content: {
    id: 'userPickerV3Content',
    type: UserPickerV3ContentModel,
  },
  validatedTextFieldContent: {
    id: 'validatedTextFieldContent',
    type: ValidatedTextFieldContentModel,
  },
};

export default pandoraContentModel;
