import {configStore, getHeaders} from '@admin-tribe/acsc';
import axios from 'axios';

import rootStore from 'core/RootStore';

let clientId, includeRoles, url;

(async function loadJilConfig() {
  ({url, clientId, includeRoles} = await configStore.getServiceConfiguration('jil'));
  url = `${url}/v2/organizations`;
})();
/**
 * @description Fetches the organization's storage quota.
 *
 * @return {Promise} a promise which will resolve with the response.
 */
const getStorageQuota = () =>
  axios.get(`${url}/${rootStore.organizationStore.activeOrgId}/storage-quota`, {
    headers: getHeaders({clientId, includeRoles}),
  });

const jilStorageQuota = {
  getStorageQuota,
};

export default jilStorageQuota;
