import {ImageIcon} from '@admin-tribe/acsc-ui';
import {Flex, Heading, Text, View} from '@adobe/react-spectrum';
import {TooltipButton} from '@pandora/react-tooltip-button';
import LinkOutIcon from '@spectrum-icons/workflow/LinkOut';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {openLink} from 'features/packages/adminToolsUtils';

import styles from '../FinalizePackagePage.pcss';

const IncludedPlugins = observer(({packageSessionSettings}) => {
  const intl = useIntl();

  return (
    <>
      <Heading marginY="size-100" size={4}>
        {intl.formatMessage({
          id: 'packages.createPackage.finalizePackagePage.includedPlugins',
        })}
        {` (${packageSessionSettings.selectedPlugins.length})`}
      </Heading>
      <Flex
        data-testid="plugin-list"
        direction="column"
        maxHeight="size-3400"
        UNSAFE_style={{overflow: 'auto'}}
      >
        {packageSessionSettings.selectedPlugins.map((plugin) => (
          <Flex
            key={plugin.id}
            alignItems="center"
            // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- styling required on products list
            UNSAFE_className={styles['selected-plugins-list-row']}
          >
            <Flex alignItems="center" flexGrow={1} justifyContent="space-between" width="size-3600">
              {/* Product Icon */}
              <Flex justifyContent="space-between">
                <ImageIcon alt="" size="M" src={plugin.iconUrl} />
                {/* Product name, version and relevant links*/}
                <View marginStart="size-100">
                  <Text>
                    <strong>{plugin.name}</strong>
                  </Text>
                  {plugin.version && <Text marginStart="size-100">({plugin.version})</Text>}
                </View>
              </Flex>
              <TooltipButton
                isQuiet
                marginStart="size-50"
                onPress={() => openLink(plugin.learnMoreUrl)}
                variant="action"
              >
                <LinkOutIcon />
              </TooltipButton>
            </Flex>
          </Flex>
        ))}
      </Flex>
    </>
  );
});

IncludedPlugins.propTypes = {
  /**
   * Shared object for package creation session
   */
  packageSessionSettings: PropTypes.shape({
    frlSelectedServer: PropTypes.instanceOf(Object),
  }).isRequired,
};

export default IncludedPlugins;
