/* eslint-disable max-lines -- this service needs to return all the entitlements separately */
import binky, {CLOUD, Cache, PRODUCT_COMPLIANCE_SYMPTOM} from '@admin-tribe/acsc';
import {when} from 'mobx';

import rootStore from 'core/RootStore';
import {ACROBAT_CLASSIC_24_FICODE} from 'features/packages/components/create-package-modal/CreatePackageConstants';
import {
  COLD_FUSION_SAP_CODE,
  SHOW_SERVERS_TAB,
  SHOW_TEMPLATES_TAB,
} from 'features/packages/packagesConstants';

import PackagesFrlOffer from './PackagesFrlOfferService';

const packagesEntitlementsServiceCache = new Cache();

class PackagesEntitlementsService {
  //////////////////////////////////////////
  /* Fetch from rootStore */

  /**
   * @description Method to fetch ProductList (from which we will derive entitlements)
   *
   */
  static async fetchEntitlements() {
    await when(() => !!rootStore.organizationStore.productList);
    this.productList = rootStore.organizationStore.productList;
    await when(() => !!rootStore.organizationStore.contractList);
    this.contractList = rootStore.organizationStore.contractList;

    // Cloud segment specific entitlements
    // Substance offers have their Cloud as CREATIVE but we do not want to
    // treat Substance offer as CC entitlement

    this.isCCEntitlement = this.productList.items.some(
      (item) =>
        item.isCreativeCloudProduct() &&
        item.fulfillableItemList.hasDesktopTypeItems() &&
        !item.fulfillableItemList.getDesktopTypeItems().some((fi) => this.isNonCCFI(fi))
    );
    this.isDCEntitlement = this.productList.items.some(
      (item) => item.isDocumentCloudProduct() && item.fulfillableItemList.hasDesktopTypeItems()
    );
    this.isELearningEntitlement = this.productList.items.some(
      (item) => item.isOtherCloudProduct() && item.fulfillableItemList.hasDesktopTypeItems()
    );
    this.isCaptivateEntitlement = this.containsDesktopItemWithSapCode('CPTV');
    this.isRoboHelpServerEntitlement = this.containsDesktopItemWithSapCode('RBSV');
    this.isSubstanceEntitlement = this.productList.items.some((item) =>
      item.fulfillableItemList.getDesktopTypeItems().find((dItem) => this.isSubstanceFI(dItem))
    );
    this.isPhotoshopElementsEntitlement = this.containsDesktopItemWithSapCode('PSE');
    this.isPremierElementsEntitlement = this.containsDesktopItemWithSapCode('PRE');
    this.isIndesignServerEntitlement = this.containsDesktopItemWithSapCode('AIS');
    this.isSubstanceNamedEntitlement = this.productList.items.some(
      (item) =>
        item.fulfillableItemList.getDesktopTypeItems().find((dItem) => this.isSubstanceFI(dItem)) &&
        !item.fulfillableItemList
          .getDesktopTypeItems()
          .find(
            (dItem) => dItem.code === rootStore.packagesUiConstantsStore.frlPreconditioningFiCode
          )
    );
    this.isSubstanceSATEntitlement = this.productList.items.some(
      (item) =>
        item.fulfillableItemList.getDesktopTypeItems().find((dItem) => this.isSubstanceFI(dItem)) &&
        !item.fulfillableItemList
          .getDesktopTypeItems()
          .find(
            (dItem) => dItem.code === rootStore.packagesUiConstantsStore.frlPreconditioningFiCode
          ) &&
        !item.fulfillableItemList.items?.find(
          (fi) =>
            fi.code === 'sbst_alchemist_feature_access' &&
            fi.featureSets?.some((fifs) => fifs.id === 'edu_premium_level1')
        )
    );

    this.isSubstanceWSEntitlement = !!this.productList.items.some((item) =>
      item.fulfillableItemList?.getDesktopTypeItems()?.find((dItem) => dItem?.code === 'sbst_ssca')
    );

    this.isPresenterEntitlement = this.containsDesktopItemWithSapCode('CPTL');
    this.isFrameMakerEntitlement = this.containsDesktopItemWithSapCode('FM');
    this.isRoboHelpEntitlement = this.containsDesktopItemWithSapCode('RBHP');

    this.isTcsEntitlement = this.productList.items.some(
      (item) =>
        item.cloud === CLOUD.OTHERS &&
        item.family === rootStore.packagesUiConstantsStore.tcsOfferFamilyName
    );
    this.isEnterprise = this.productList.items.some((item) => item.isEnterprise());
    this.isDevicePool = binky.services.product.productListUtils.hasLegacyDeviceLicenses(
      this.productList
    );

    this.isAcrobatClassicTermLicenseEntitlement = this.productList.items.some((item) =>
      this.isAcrobatClassic24Offer(item)
    );

    // FRL specific entitlements
    this.isFrlIsolatedEntitlement = false;
    this.isFrlOnlineEntitlement = false;
    this.isFrlLanEntitlement = false;
    this.isFrlOfflineEntitlement = false;
    this.isCFEntitlement = this.containsDesktopItemWithSapCode(COLD_FUSION_SAP_CODE);
    await this.fetchOffers();

    this.fetchedAllEntitlements = true;
  }

  /**
   * @description Method to fetch frl and nuell offers
   * @throws {Error} if filterAndGetFrlOffers/filterAndGetNuellOffers throws error
   */
  static async fetchOffers() {
    try {
      this.frlOffers = await this.filterAndGetFrlOffers();

      // SDL specific entitlements
      this.nuellOffers = await this.filterAndGetNuellOffers();
    } catch (error) {
      this.isEnterprise = true;
      throw error;
    }
  }

  /**
   * @description Method to check if atleast one desktop item has the given sapcode
   * @param {String} sapcode
   * @returns {Boolean} true if atleast one item in list has sapcode, else false
   */
  static containsDesktopItemWithSapCode(sapCode) {
    return this.productList.items.some((item) =>
      item.fulfillableItemList
        .getDesktopTypeItems()
        .find(
          (dItem) => dItem.code === rootStore.packagesUiConstantsStore.getFiCodeFromSapCode(sapCode)
        )
    );
  }

  //////////////////////////////////////////
  /* Getter methods */

  /**
   * @description Method to filter and get FRL offers from productList. Also
   *              parses and sets online/isolated/lan specific entitlements.
   *
   * @returns {Array} All offers containing FRL
   */
  static filterAndGetFrlOffers() {
    const items = this.productList.items
      .filter(
        (item) =>
          item.fulfillableItemList.getDesktopTypeItems().some((fulfillableItem) => {
            const unitCodes = fulfillableItem.chargingModel?.unit;
            let hasIsolated = false;
            let hasLan = false;
            let hasOnline = false;
            let hasOffline = false;
            if (this.isFiFrlPreconditioning(fulfillableItem) && unitCodes) {
              hasIsolated = unitCodes.includes(
                rootStore.packagesUiConstantsStore.frlIsolatedUnitCode
              );
              hasLan = unitCodes.includes(rootStore.packagesUiConstantsStore.frlLanUnitCode);
              hasOnline = unitCodes.includes(rootStore.packagesUiConstantsStore.frlOnlineUnitCode);
              hasOffline = unitCodes.includes(
                rootStore.packagesUiConstantsStore.frlOfflineUnitCode
              );
              this.isFrlIsolatedEntitlement = this.isFrlIsolatedEntitlement || hasIsolated;
              this.isFrlLanEntitlement = this.isFrlLanEntitlement || hasLan;
              this.isFrlOnlineEntitlement = this.isFrlOnlineEntitlement || hasOnline;
              this.isFrlOfflineEntitlement = this.isFrlOfflineEntitlement || hasOffline;
            }
            return hasIsolated || hasLan || hasOnline || hasOffline;
          }) && !this.hasOfferExpired(item)
      )
      .map((offer) => PackagesFrlOffer.get(offer));
    return Promise.all(items);
  }

  /**
   * @description Method to filter and get NUELL offers from productList
   *
   * @returns {Array} All offers containing FRL
   */
  static filterAndGetNuellOffers() {
    const items = this.productList.items
      .filter(
        (item) =>
          item.fulfillableItemList.getDesktopTypeItems().some((fulfillableItem) => {
            const unitCodes = fulfillableItem.chargingModel?.unit;
            return (
              this.isFiFrlPreconditioning(fulfillableItem) &&
              unitCodes &&
              unitCodes.includes(rootStore.packagesUiConstantsStore.nuellUnitCode)
            );
          }) && !this.hasOfferExpired(item)
      )
      .map((offer) => PackagesFrlOffer.get(offer));
    return Promise.all(items);
  }

  /**
   * @description Method to get all FRL offers
   * @param {String} frlType differentiate between frloffline, online, isolated and LAN.
   * @returns {Array} all FRL offers the user is entitled to
   */
  static getFrlOffers(frlType) {
    if (!this.isValidFrlType(frlType)) {
      return this.frlOffers;
    }

    return this.frlOffers.filter((item) =>
      item.fulfillableItemList.getDesktopTypeItems().some((fulfillableItem) => {
        if (this.isFiFrlPreconditioning(fulfillableItem)) {
          const unitCodes = fulfillableItem.chargingModel?.unit;
          return unitCodes && unitCodes.includes(frlType);
        }
        return false;
      })
    );
  }

  /**
   * @description Method to get FRL offers by license IDs
   *
   * @param {Array} licenseIds Array of license IDs
   * @returns {Array} all FRL offers the user is entitled to, filtered by given license IDs
   */
  static getFrlOffersByLicenseIds(licenseIds) {
    return this.frlOffers.filter((offer) => licenseIds.includes(offer.id));
  }

  /**
   * @description Method to get all NUELL offers
   *
   * @returns {Array} all NUELL offers the user is entitled to
   */

  static get getNuellOffers() {
    return this.nuellOffers;
  }

  /**
   * @description Method to check if the user has Captivate Entitlement
   *
   * @returns {Boolean} has Captivate Entitlement or not
   */

  static get hasCaptivateEntitlement() {
    return this.isCaptivateEntitlement;
  }

  /**
   * @description Method to check if the user has CC and DC Entitlements
   *
   * @returns {Boolean} has Creative Cloud AND Document Cloud Entitlements or not
   */

  static get hasCcAndDcEntitlement() {
    return this.isCCEntitlement && this.isDCEntitlement;
  }

  /**
   * @description Method to check if the user has CC Entitlement
   *
   * @returns {Boolean} has Creative Cloud Entitlement or not
   */

  static get hasCcEntitlement() {
    return this.isCCEntitlement;
  }

  /**
   * @description Method to check if the user has CC Only Entitlement
   *
   * @returns {Boolean} has Creative Cloud Only Entitlement or not
   */

  static get hasCcOnlyEntitlement() {
    return this.isCCEntitlement && !this.isDCEntitlement;
  }

  /**
   * @description Method to check if the user has CC or DC Entitlement
   *
   * @returns {Boolean} has Creative Cloud OR Document Cloud Entitlements or not
   */

  static get hasCcOrDcEntitlement() {
    return this.isCCEntitlement || this.isDCEntitlement;
  }

  /**
   * @description Method to check if the user has DC Entitlement
   *
   * @returns {Boolean} has Document Cloud Entitlement or not
   */

  static get hasDcEntitlement() {
    return this.isDCEntitlement;
  }

  /**
   * @description Method to check if the user has Acrobat classic term license Entitlement
   *
   * @returns {Boolean} has Acrobat classic term license Entitlement or not
   */

  static get hasAcrobatClassicTermLicense() {
    return this.isAcrobatClassicTermLicenseEntitlement;
  }

  /**
   * @description Method to check if the user has DC Only Entitlement
   *
   * @returns {Boolean} has Document Cloud Only Entitlement or not
   */

  static get hasDcOnlyEntitlement() {
    return !this.isCCEntitlement && this.isDCEntitlement;
  }

  /**
   * @description Method to check if the user has NonCC Only Entitlement
   *
   * @returns {Boolean} has Other cloud entitlement (i.e. NonCC) and not Creative cloud entitlement
   */

  static get hasNonCcOnlyEntitlement() {
    return !this.isCCEntitlement && this.isELearningEntitlement;
  }

  /**
   * @description Method to check if the user has device pool license
   *
   * @returns {Boolean} whether org has enterprise agreement or not
   */

  static get hasDevicePool() {
    return this.isDevicePool;
  }

  /**
   * @description Method to check if the user has E-learning (captivate or presenter) Entitlement
   *
   * @returns {Boolean} has Presenter Entitlement or not
   */

  static get hasElearningEntitlement() {
    return this.isELearningEntitlement;
  }

  /**
   * @description Method to check if all entitlements fetched
   *
   * @returns {Boolean} has fetched all elements or not
   */

  static get hasFetchedAllEntitlements() {
    if (this.fetchedAllEntitlements) {
      return true;
    }
    return false;
  }

  /**
   * @description Method to check if the user has frame maker (FM) Entitlement
   *
   * @returns {Boolean} has framemaker Entitlement or not
   */

  static get hasFramemakerEntitlement() {
    return this.isFrameMakerEntitlement;
  }

  /**
   * @description Method to check if the user has FRL entitlement
   *
   * @returns {Boolean} whether FRL entitlement present or not
   */

  static get hasFrlEntitlement() {
    return !!this.frlOffers?.length;
  }

  /**
   * @description Method to check if the user has FRL Isolated entitlement
   *
   * @returns {Boolean} whether FRL Isolated entitlement present or not
   */

  static get hasFrlIsolatedEntitlement() {
    return this.isFrlIsolatedEntitlement;
  }

  /**
   * @description Method to check if the user has FRL LAN entitlement
   *
   * @returns {Boolean} whether FRL LAN entitlement present or not
   */

  static get hasFrlLanEntitlement() {
    return this.isFrlLanEntitlement;
  }

  /**
   * @description Method to check if the user has FRL Offline entitlement
   *
   * @returns {Boolean} whether FRL Offline entitlement present or not
   */

  static get hasFrlOfflineEntitlement() {
    return this.isFrlOfflineEntitlement;
  }

  /**
   * @description Method to check if the user has FRL Online entitlement
   *
   * @returns {Boolean} whether FRL Online entitlement present or not
   */

  static get hasFrlOnlineEntitlement() {
    return this.isFrlOnlineEntitlement;
  }

  /**
   * @description Method to check if the user has Indesign server (AIS) entitlement
   *
   * @returns {Boolean} has Indesign server (AIS) Entitlement or not
   */

  static get hasIndesignServerEntitlement() {
    return this.isIndesignServerEntitlement;
  }

  /**
   * @description Method to test if org has only non CC & FRL only entitlement
   *
   * @returns {Boolean} true if nonCC & FRL Only
   */

  static get hasNonCCFRLOnlyEntitlement() {
    return !this.productList.items.some(
      (item) =>
        !item.fulfillableItemList.getDesktopTypeItems().find((fi) => this.isNonCCFI(fi)) ||
        !item.fulfillableItemList
          .getDesktopTypeItems()
          .find((fi) => fi.code === rootStore.packagesUiConstantsStore.frlPreconditioningFiCode)
    );
  }

  /**
   * @description Method to check if the user has NUELL entitlement
   *
   * @returns {Boolean} whether NUELL entitlement present or not
   */

  static get hasNuellEntitlement() {
    return !!this.nuellOffers?.length;
  }

  /**
   * @description Method to check if given offer has expired
   *
   * @param {Object} offer Product object to check
   * @returns {Boolean} True if offer has expired
   */
  static hasOfferExpired(offer) {
    let fallBackToEndDate = true;
    const offerTuples = offer.licenseTupleList?.items.filter((tuple) =>
      offer.hasContractId(tuple.contractId)
    );
    let offerExpired = true;
    offerTuples?.forEach((element) => {
      const canAccessComplianceSymptoms = element.complianceSymptoms?.find(
        (symptom) => symptom.name === PRODUCT_COMPLIANCE_SYMPTOM.CAN_ACCESS
      );
      if (element.complianceSymptoms) {
        fallBackToEndDate = false;
      }
      if (canAccessComplianceSymptoms && canAccessComplianceSymptoms.value === 'true') {
        offerExpired = false;
      }
    });
    if (!fallBackToEndDate) {
      return offerExpired;
    }
    const contract = this.contractList.items.find((item) => item.id === offer.contractId);
    // check if such contract exists and the method getEndDate() is defined
    if (fallBackToEndDate && contract?.getEndDate) {
      // VIP is an evergreen buying program and offer should not be treated expired in such case.
      // https://jira.corp.adobe.com/browse/CAUIP-12278
      if (contract.isBuyingProgramVIP()) {
        return false;
      }
      // Date.parse() will evaluate to NaN if the contract doesn't has an end date,
      // and a comparision with NaN will always evaluate to false.
      return Date.parse(contract.getEndDate()) <= Date.now();
    }
    return false;
  }

  /**
   * @description Method to check if the user has Photoshop Elements entitlement.
   * @returns {Boolean} has Photoshop Elements entitlement or not
   */
  static get hasPhotoshopElementsEntitlement() {
    return this.isPhotoshopElementsEntitlement;
  }

  /**
   * @description Method to check if the user has Premier Elements entitlement.
   * @returns {Boolean} has Premier Elements entitlement or not
   */
  static get hasPremierElementsEntitlement() {
    return this.isPremierElementsEntitlement;
  }

  /**
   * @description Method to check if the user has Presenter Entitlement
   *
   * @returns {Boolean} has Presenter Entitlement or not
   */

  static get hasPresenterEntitlement() {
    return this.isPresenterEntitlement;
  }

  /**
   * @description Method to check if the user has robohelp (RBHP) Entitlement
   *
   * @returns {Boolean} has framemaker Entitlement or not
   */

  static get hasRobohelpEntitlement() {
    return this.isRoboHelpEntitlement;
  }

  /**
   * @description Method to check if the user has RoboHelpServer Entitlement
   *
   * @returns {Boolean} has Captivate Entitlement or not
   */

  static get hasRoboHelpServerEntitlement() {
    return this.isRoboHelpServerEntitlement;
  }

  /**
   * @description Method to check if the user has Substance entitlement.
   * @returns {Boolean} has Substance entitlement or not
   */

  static get hasSubstanceEntitlement() {
    return this.isSubstanceEntitlement;
  }

  /**
   * @description Method to check if the user has Substance NAMED only entitlement and not Substance FRL.
   * @returns {Boolean} has SubstanceNamed entitlement or not
   */

  static get hasSubstanceNamedEntitlement() {
    return this.isSubstanceNamedEntitlement;
  }

  /**
   * @description Method to check if the user has Substance NAMED only entitlement without all apps and not Substance FRL.
   * @returns {Boolean} has SubstanceNamed entitlement or not
   */

  static get hasSubstanceSATEntitlement() {
    return this.isSubstanceSATEntitlement;
  }

  /**
   * @description Method to check if the user has Substance 3D Automation and Workflow Solutions entitlement.
   * @returns {Boolean} has Substance 3D Automation and Workflow Solutions entitlement or not
   */

  static get hasSubstanceWSEntitlement() {
    return this.isSubstanceWSEntitlement;
  }

  /**
   * @description Method to check if the user has telecommunication suite Entitlement
   *
   * @returns {Boolean} has TCS Entitlement or not
   */

  static get hasTcsEntitlement() {
    return this.isTcsEntitlement;
  }

  /**
   * @description Method to check if the user has enterprise agreement or team
   *
   * @returns {Boolean} whether org has enterprise agreement or not
   */

  static get isEnterpriseOrg() {
    return this.isEnterprise;
  }

  static isNonCCFI(fi) {
    return fi.code === rootStore.packagesUiConstantsStore.getFiCodeFromSapCode('AIS');
  }

  static isSubstanceFI(fi) {
    return (
      fi.code === rootStore.packagesUiConstantsStore.getFiCodeFromSapCode('SBSTD') ||
      fi.code === rootStore.packagesUiConstantsStore.getFiCodeFromSapCode('SBSTA') ||
      fi.code === rootStore.packagesUiConstantsStore.getFiCodeFromSapCode('SBSTP') ||
      fi.code === rootStore.packagesUiConstantsStore.getFiCodeFromSapCode('STGR') ||
      fi.code === rootStore.packagesUiConstantsStore.getFiCodeFromSapCode('SHPR')
    );
  }

  /**
   * @description Method to check if frlType value is valid or not
   * @param {String} frlType differentiate between frloffline, online, isolated and LAN.
   * @returns {Boolean} if frlType is valid or not
   */
  static isValidFrlType(frlType) {
    return (
      frlType &&
      [
        rootStore.packagesUiConstantsStore.frlOfflineUnitCode,
        rootStore.packagesUiConstantsStore.frlOnlineUnitCode,
        rootStore.packagesUiConstantsStore.frlLanUnitCode,
        rootStore.packagesUiConstantsStore.frlIsolatedUnitCode,
      ].includes(frlType)
    );
  }

  /**
   * @description Method to reset the offers data
   *
   * @returns {undefined} reset all offers the user is entitled to
   */
  static resetOffers() {
    [...this.nuellOffers, ...this.frlOffers].forEach((offer) => {
      offer.disabled = false;

      offer.selected = false;
    });
  }

  /**
   *
   * @description Private Method to check if a tab should be shown
   *
   * @returns {Promise} if tab should be shown
   */

  static async #showTab(cacheKey, condition) {
    const cachedResponse = packagesEntitlementsServiceCache.get(cacheKey);

    if (cachedResponse !== undefined && cachedResponse !== null) {
      return cachedResponse.valueOf();
    }

    await rootStore.packagesUiConstantsStore.fetchUiConstants();
    if (rootStore.packagesUiConstantsStore.hasLoadingError) {
      return false;
    }
    try {
      await PackagesEntitlementsService.fetchEntitlements();
    } catch (error) {
      return false;
    }
    const cacheValue = condition();
    packagesEntitlementsServiceCache.put(cacheKey, cacheValue);
    return cacheValue;
  }

  /**
   * @description Method checks if templates tab should be shown
   *
   * @returns {Promise} if templates tab should be shown
   */
  static showTemplatesTab() {
    return this.#showTab(
      `${SHOW_TEMPLATES_TAB}/${rootStore.organizationStore.activeOrgId}`,
      () => !PackagesEntitlementsService.hasNonCCFRLOnlyEntitlement
    );
  }

  /**
   * @description Method checks if servers tab should be shown
   *
   * @returns {Promise} resolves to true if servers tab should be shown
   */
  static showServersTab() {
    return this.#showTab(
      `${SHOW_SERVERS_TAB}/${rootStore.organizationStore.activeOrgId}`,
      () => PackagesEntitlementsService.hasFrlLanEntitlement
    );
  }

  /**
   * @description Method to check if the user has ColdFusion Entitlement
   *
   * @returns {Boolean} has ColdFusion Entitlement or not
   */
  static get hasCFEntitlement() {
    return this.isCFEntitlement;
  }

  /**
   * @description Method checks FRL pre-conditioning
   * @returns {Boolean} resolves to true if FRL pre-conditioning matches
   */

  static isFiFrlPreconditioning(fulfillableItem) {
    return fulfillableItem.code === rootStore.packagesUiConstantsStore.frlPreconditioningFiCode;
  }

  /**
   * @description Method checks whether offer is FFC products or not
   * @returns {Boolean} resolves to false if offer has FFC products
   */
  static isNonFFCOffer(offer) {
    return offer
      .getPackageableProductSapCodes()
      .some((sapCode) => rootStore.packagesUiConstantsStore.nonFfcProductMap?.[sapCode]);
  }

  /**
   * @description Method checks Acrobat classic term license
   * @returns {Boolean} resolves to true if given offer is Acrobat classic term license
   */
  static isAcrobatClassic24Offer(offer) {
    return (
      offer.isDocumentCloudProduct() &&
      offer.fulfillableItemList
        .getDesktopTypeItems()
        .find((dItem) => dItem.code === ACROBAT_CLASSIC_24_FICODE)
    );
  }
}
export default PackagesEntitlementsService;
/* eslint-enable max-lines -- this service needs to return all the entitlements separately */
