const PATH_PACKAGES_ROOT = '/:orgId/packages';
const PATH_ALL_PACKAGES = `${PATH_PACKAGES_ROOT}/all-packages`;
const PATH_ADOBE_TEMPLATES = `${PATH_PACKAGES_ROOT}/adobe-templates`;
const PATH_PREGENERATED_PACKAGES = `${PATH_PACKAGES_ROOT}/pregenerated-packages`;
const PATH_SINGLE_APPS = 'single-apps';
const PATH_TEMPLATES = 'templates';
const PATH_PREGENERATED_PACKAGES_SINGLE_APPS = `${PATH_PREGENERATED_PACKAGES}/${PATH_SINGLE_APPS}`;
const PATH_PREGENERATED_PACKAGES_TEMPLATES = `${PATH_PREGENERATED_PACKAGES}/${PATH_TEMPLATES}`;
const PATH_PACKAGES_TOOLS = `${PATH_PACKAGES_ROOT}/tools`;
const PATH_PACKAGES_PREFERENCES = `${PATH_PACKAGES_ROOT}/preferences`;
const PATH_PACKAGES_SERVERS = `${PATH_PACKAGES_ROOT}/servers`;

export {
  PATH_PACKAGES_ROOT,
  PATH_ALL_PACKAGES,
  PATH_ADOBE_TEMPLATES,
  PATH_PREGENERATED_PACKAGES,
  PATH_PREGENERATED_PACKAGES_SINGLE_APPS,
  PATH_PREGENERATED_PACKAGES_TEMPLATES,
  PATH_PACKAGES_TOOLS,
  PATH_PACKAGES_PREFERENCES,
  PATH_PACKAGES_SERVERS,
  PATH_TEMPLATES,
  PATH_SINGLE_APPS,
};
