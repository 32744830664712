import {OrganizationUser, download, log} from '@admin-tribe/acsc';
import {Comment, getPersonName, showError, showInfo} from '@admin-tribe/acsc-ui';
import {Divider, View} from '@adobe/react-spectrum';
import {
  SUPPORT_TICKET_COMMENT_TYPE,
  useSupportTicketHook,
} from '@pandora/data-model-support-ticket';
import {TextCard} from '@pandora/react-text-card';
import Attach from '@spectrum-icons/workflow/Attach';
import Download from '@spectrum-icons/workflow/Download';
import uniqueId from 'lodash/uniqueId';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';
import Linkify from 'react-linkify';

import rootStore from 'core/RootStore';

import {SUPPORT_CONSTANTS} from '../../support.constants';

const UNKNOWN_DIRECTION = 'Unknown';

const {SUPPORT_TICKET_DISCUSSION_DIRECTION} = SUPPORT_CONSTANTS;

/**
 * A widget to display the Discussion Items
 */
const SupportTicketDiscussionItems = ({'data-testid': dataTestId, supportTicket}) => {
  const {id, isMigrated} = supportTicket;
  const {downloadTicketAttachment} = useSupportTicketHook();
  const orgId = rootStore.organizationStore.activeOrgId;
  const intl = useIntl();

  const fallbackName = intl.formatMessage({
    id: 'support.supportDiscussionItem.supportTicketPersonName.fallbackName',
  });

  const downloadFile = async (attachmentId, fileName) => {
    try {
      showInfo(
        intl.formatMessage({
          id: 'support.supportDiscussionItem.download.attachment.downloadBeingPrepared',
        })
      );
      const response = await downloadTicketAttachment({attachmentId, id, orgId});
      download(response, fileName);
    } catch (error) {
      log.error(`Failed to download ticket attachment for ${id}. Error: `, error);
      showError(
        intl.formatMessage({
          id: 'support.supportDiscussionItem.errorMessage',
        })
      );
    }
  };

  const renderAttachmentContent = (item) => (
    <TextCard
      backgroundColor="gray-75"
      borderColor="gray-75"
      data-testid="attachment-text-card"
      height="auto"
      interval={0}
      leftIcon={<Attach size="S" />}
      onClickRightIcon={() => downloadFile(item.attachmentId, item.fileName)}
      rightIcon={<Download size="S" />}
      text={item.fileName}
      width="100%"
    />
  );

  const getMember = (item) =>
    (item.direction !== UNKNOWN_DIRECTION || !isMigrated) &&
    new OrganizationUser({
      firstName: item.createdBy.firstName,
      id: item.createdBy.rengaId || item.createdBy.id,
      lastName: item.createdBy.lastName,
    });

  const isAttachmentType = (item) => Object.prototype.hasOwnProperty.call(item, 'attachmentId');

  const getTitle = (item) => {
    const name = getPersonName(intl, {
      fallbackName,
      firstName: item.createdBy.firstName,
      lastName: item.createdBy.lastName,
      showAdobeSuffix: item.direction === SUPPORT_TICKET_DISCUSSION_DIRECTION.OUTBOUND,
      userId: item.createdBy.rengaId,
    });
    if (isAttachmentType(item)) {
      return intl.formatMessage(
        {id: 'support.supportDiscussionItem.supportTicketAttachmentNote.attached'},
        {name}
      );
    }
    if (isMigrated && item.direction === SUPPORT_TICKET_DISCUSSION_DIRECTION.OUTBOUND) {
      return intl.formatMessage({
        id: 'support.supportDiscussionItem.supportTicketCommentNote.adobeSupport',
      });
    }
    return intl.formatMessage(
      {
        id:
          item.type === SUPPORT_TICKET_COMMENT_TYPE.ACTION_PENDING_RESPONSE
            ? 'support.supportDiscussionItem.supportTicketCommentNote.requested'
            : 'support.supportDiscussionItem.supportTicketCommentNote.commented',
      },
      {name}
    );
  };
  return (
    <View data-testid={dataTestId || 'ticket-discussion-items'}>
      {supportTicket.getDiscussionItems()?.map((item) => (
        <View
          key={uniqueId('discussion_item')}
          data-testid="ticket-discussion-item"
          UNSAFE_style={{wordBreak: 'break-word'}}
        >
          <Divider marginBottom="size-100" marginTop="size-250" orientation="horizontal" size="S" />

          <Comment
            bannerContent={
              !isAttachmentType(item) && supportTicket.commentRequiresResponse(item)
                ? intl.formatMessage({
                    id: 'support.supportDiscussionItem.supportTicketCommentNote.pleaseRespond',
                  })
                : ''
            }
            commentContent={
              isAttachmentType(item) ? (
                renderAttachmentContent(item)
              ) : (
                <Linkify
                  componentDecorator={(decoratedHref, decoratedText, key) => (
                    <a key={key} href={decoratedHref} rel="noreferrer">
                      {decoratedText}
                    </a>
                  )}
                  properties={{target: '_blank'}}
                >
                  {item.description}
                </Linkify>
              )
            }
            creationDate={isMigrated ? undefined : item.createdOn}
            data-testid={isAttachmentType(item) ? 'ticket-attachement-note' : 'ticket-comment-note'}
            member={getMember(item)}
            title={getTitle(item)}
          />
        </View>
      ))}
    </View>
  );
};

SupportTicketDiscussionItems.propTypes = {
  /**
   * data-testid is used as identifier of the component.
   * The default is 'ticket-discussion-items'.
   */
  'data-testid': PropTypes.string,
  /**
   * supportTicket is used to get ticket details
   */
  supportTicket: PropTypes.instanceOf(Object).isRequired,
};

export default SupportTicketDiscussionItems;
