import {navBus} from '@admin-tribe/acsc';
import {DialogContainer, Flex, Heading, Link, Text} from '@adobe/react-spectrum';
import {FormattedDateTime} from '@pandora/react-formatted-date-time';
import React, {useCallback, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import useInsightsPaths from 'features/insights/hooks/useInsightsPaths';
import SectionView from 'features/settings/common/components/section-view/SectionView';
import {useDomainEnforcementContext} from 'features/settings/components/directory/domain-enforcement/DomainEnforcementContext';
import ContextualHelpSwitch from 'features/settings/components/directory/domain-enforcement/components/ContextualHelpSwitch';
import ChangeEmailConfirmationModal from 'features/settings/components/directory/domain-enforcement/requirements-section/ChangeEmailConfirmationModal';

const ChangeEmailSection = () => {
  const intl = useIntl();
  const {reports: insightsReportsPath} = useInsightsPaths();

  const {domainEnforcement} = useDomainEnforcementContext();

  const [showEmailChangeConfirmationModal, setShowEmailChangeConfirmationModal] = useState(false);

  const helpTitleLabel = () =>
    intl.formatMessage({
      id: 'settings.domainEnforcement.requirementsSection.changeEmail.popover.title',
    });

  const helpDescriptionLabel = () =>
    intl.formatMessage(
      {id: 'settings.domainEnforcement.requirementsSection.changeEmail.popover.description'},
      {
        activationDate: () => (
          <Text>
            <FormattedDateTime
              date={new Date(domainEnforcement.stateEndsAt)}
              format={{type: 'literal', value: '/'}}
            />
          </Text>
        ),
      }
    );

  const switchLabelId = domainEnforcement.isDeEnforced
    ? 'settings.domainEnforcement.requirementsSection.changeEmail.toggle.ON'
    : 'settings.domainEnforcement.requirementsSection.changeEmail.toggle.OFF';

  const onPressReportsLink = useCallback(() => {
    navBus.navigate(insightsReportsPath());
  }, [insightsReportsPath]);

  const handleOnChangeEmailToggleClick = () => {
    setShowEmailChangeConfirmationModal(true);
  };

  return (
    <>
      <SectionView data-testid="change-email-section">
        <Flex direction="column" gap="size-100" height="100%">
          <Heading level={4} margin="0">
            <FormattedMessage id="settings.domainEnforcement.requirementsSection.changeEmail.title" />
          </Heading>
          <Text marginTop="size-100">
            {intl.formatMessage(
              {id: 'settings.domainEnforcement.requirementsSection.changeEmail.description'},
              {
                link: (linkText) => (
                  <Link onPress={onPressReportsLink}>
                    <span>{linkText}</span>
                  </Link>
                ),
              }
            )}
          </Text>
          <ContextualHelpSwitch
            data-testid="change-email-toggle"
            helpDescriptionLabel={helpDescriptionLabel}
            helpTitleLabel={helpTitleLabel}
            // When status is NOTIFIED, the toggle is disabled and the contextual help is shown
            // DE needs to be ACTIVATED before the toggle can be used
            isDisabled={domainEnforcement.isDeNotified}
            isSelected={domainEnforcement.isDeEnforced}
            onChange={handleOnChangeEmailToggleClick}
            switchLabelId={switchLabelId}
          />
        </Flex>
      </SectionView>
      <DialogContainer
        onDismiss={() => {
          setShowEmailChangeConfirmationModal(false);
        }}
      >
        {showEmailChangeConfirmationModal && <ChangeEmailConfirmationModal />}
      </DialogContainer>
    </>
  );
};

export default ChangeEmailSection;
