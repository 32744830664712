import {GoUrl, Page, PageContent} from '@admin-tribe/acsc-ui';
import {Button, Flex, Heading, View} from '@adobe/react-spectrum';
import ImageIcon from '@spectrum-icons/workflow/Image';
import React, {useRef, useState} from 'react';
import {useIntl} from 'react-intl';

import useDocumentTitle from 'common/hooks/useDocumentTitle';
import HowToDeployModal from 'features/products/components/how-to-deploy-modal/HowToDeployModal';

/**
 * Page to show learn more info for product device licenses
 */
const ProductDevicesLearnMorePage = () => {
  const intl = useIntl();
  const [showModal, setShowModal] = useState(false);
  const openerButton = useRef(null);

  useDocumentTitle({key: 'products.devices.learnMore.title'});

  return (
    <Page>
      <PageContent>
        <Flex alignItems="center" direction="column">
          <ImageIcon alt="image-icon" size="L" />
          <Heading level={2} marginTop="size-200">
            {intl.formatMessage({id: 'products.devices.learnMore.title'})}
          </Heading>
          <View marginTop="size-100">
            {intl.formatMessage({id: 'products.devices.learnMore.placeLicensesOrderInfo'})}
          </View>
          <GoUrl name="manage_device_licenses">
            {intl.formatMessage({id: 'products.devices.learnMore.learnMoreTitle'})}
          </GoUrl>
          <View marginTop="size-150">
            {intl.formatMessage({id: 'products.devices.learnMore.packageInstallationInfo'})}
          </View>
          <View marginTop="size-150">
            {intl.formatMessage(
              {id: 'products.devices.learnMore.termsAndConditionsInfo'},
              {
                goUrl: (str) => <GoUrl name="vip-terms">{str}</GoUrl>,
              }
            )}
          </View>
          <Button
            ref={openerButton}
            data-testid="how-to-deploy-button"
            marginTop="size-400"
            onPress={() => setShowModal(true)}
            type="button"
            variant="accent"
          >
            {intl.formatMessage({id: 'products.devices.learnMore.howToDeployBtn'})}
          </Button>
        </Flex>
        <HowToDeployModal
          isOpen={showModal}
          onCancel={() => {
            setShowModal(false);
            openerButton.current.focus();
          }}
        />
      </PageContent>
    </Page>
  );
};

export default ProductDevicesLearnMorePage;
