import {
  CONTRACT_BILLING_FREQUENCY,
  FULFILLABLE_ITEM_CHARGING_UNIT,
  TAX_TERM,
} from '@admin-tribe/acsc';
import {Price} from '@admin-tribe/acsc-ui';
import PropTypes from 'prop-types';
import React from 'react';

const PRICE_FAILURE = String.fromCharCode(8211, 8211);

/**
 * SafePrice - A thin Binky Price wrapper that will render a fallback node, if price is undefined or
 * not a number, or currency formatString is not defined properly.
 * The default fallback is the exported PRICE_FAILURE constant, unless a custom node is provided.
 * See https://git.corp.adobe.com/admin-tribe/binky-ui/blob/master/src2/common/components/price/Price.jsx
 * for full documentation of props.
 */
const SafePrice = ({fallbackNode = PRICE_FAILURE, ...props}) => {
  const {price, currency} = props;

  // Needs to be in sync with shouldRenderPrice method from Pandora's React Price.
  // See https://git.corp.adobe.com/PandoraUI/commerce/blob/master/packages/react-price/src/js/utils/PriceRendererChecker.ts#L19
  // and https://git.corp.adobe.com/PandoraUI/commerce/blob/master/packages/react-price/src/js/Price.tsx#L301
  // Also, covering NaN price and falsy currency.formatString
  const shouldRenderFallback = !Number.isFinite(price) || !currency?.formatString;

  return shouldRenderFallback ? fallbackNode : <Price {...props} />;
};

SafePrice.propTypes = {
  /**
   * The currency object where formatString and usePrecision are provided.
   * Other values are ignored as Pandora Price does not support them.
   */
  currency: PropTypes.shape({
    /**
     * The format string in which to display the currency.
     */
    formatString: PropTypes.string,
    /**
     * True to include decimal value of the currency, false to only use whole values.
     */
    usePrecision: PropTypes.bool,
  }),
  /**
   * The node to use when price is undefined or currency doesn't have formatString defined.
   * Defaults to exported PRICE_FAILURE constant value.
   */
  fallbackNode: PropTypes.node,
  /**
   * A flag indicating whether the price for display should be
   * treated as inclusive or exclusive of tax. The text indicating
   * whether a price is inclusive or exclusive of tax is only
   * displayed if `taxTerm` is also provided.
   */
  isTaxInclusive: PropTypes.bool,
  /**
   * CSS clases as defined by Pandora, used to make changes to the default styling
   * for reference see https://git.corp.adobe.com/PandoraUI/commerce/tree/master/packages/react-price#supported-css-classes
   */
  // eslint-disable-next-line react/forbid-prop-types -- CSS classes defined in pandora
  overrideStyles: PropTypes.object,
  /**
   * The unit for which the price is being purchased for. Either "per license" or "per transaction".
   */
  perUnit: PropTypes.oneOf(Object.values(FULFILLABLE_ITEM_CHARGING_UNIT)),
  /**
   * The price to apply formatting and styles to.
   */
  price: PropTypes.number,
  /**
   * Any pandora price component. Used for predefined styled components in pandora.
   * https://git.corp.adobe.com/PandoraUI/commerce/tree/master/packages/react-price
   */
  PriceComponent: PropTypes.elementType,
  /**
   * The recurrenceTerm is optional and is used to determine the
   * recurrence text (e.g. `/mo` or `/yr`) to display with the price.
   */
  recurrenceTerm: PropTypes.oneOf(Object.values(CONTRACT_BILLING_FREQUENCY)),
  /**
   * The text to be used in the suffix which indicates whether the
   * price is inclusive or exclusive of tax.
   */
  taxTerm: PropTypes.oneOf(Object.values(TAX_TERM)),
};

export default SafePrice;

export {PRICE_FAILURE};
