import {EVENT_ACTION, LicenseGroupList, dispatchUiEventAnalytics, navBus} from '@admin-tribe/acsc';
import {showError as showErrorToast, showSuccess as showSuccessToast} from '@admin-tribe/acsc-ui';
import {Flex, Heading, Text, View} from '@adobe/react-spectrum';
import {RULE_STATUS, TRIGGERS_TYPE, useAcrsRules} from '@pandora/react-data-source-acrs';
import {
  ModalContainer,
  ModalContent,
  ModalDialog,
  ModalDialogModel,
  ModalHeading,
} from '@pandora/react-modal-dialog';
import PropTypes from 'prop-types';
import React, {useCallback, useState} from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';
import AdobeExpressK12SuccessConfirmationModal from 'features/overview/components/adobe-express-k12/AdobeExpressK12SuccessConfirmationModal';
import {updateIdp} from 'features/settings/api/ims-federated';

const K12_ANALYTICS_EVENTS = {
  CANCEL_K12_SETUP: 'cancelK12Setup',
  CONFIRM_K12_SETUP: 'confirmK12Setup',
};

/**
 * The modal used to patch an incomplete Adobe Express K12 setup for existing admins
 */
const AdobeExpressK12Modal = ({
  adobeExpressK12Product,
  onClose,
  domains,
  patchableIdps,
  inactiveRule,
  hasValidRules,
}) => {
  const hasEnableJITStep = patchableIdps?.length > 0;
  const hasAutoAssignStep = !hasValidRules;

  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmationState, setIsConfirmationState] = useState(false);
  const [unsuccessfulIdps, setUnsuccessfulIdps] = useState(patchableIdps);

  const {createAcrsRule, patchAcrsRules} = useAcrsRules();

  const orgId = rootStore.organizationStore.activeOrgId;

  const intl = useIntl();

  const modalDialogContent = ModalDialogModel.createEntry({
    cancelLabel: intl.formatMessage({
      id: 'common.adobeExpressK12SetupModal.buttons.cancel',
    }),
    ctaLabel: intl.formatMessage({id: 'common.adobeExpressK12SetupModal.buttons.confirm'}),
  });

  const onCloseSuccessModal = () => navBus.reload();

  const onCloseSetupModal = () => {
    dispatchUiEventAnalytics({
      eventAction: EVENT_ACTION.CLICK,
      eventName: K12_ANALYTICS_EVENTS.CANCEL_K12_SETUP,
    });
    onClose();
  };

  const onCta = useCallback(async () => {
    setIsLoading(true);

    dispatchUiEventAnalytics({
      eventAction: EVENT_ACTION.CLICK,
      eventName: K12_ANALYTICS_EVENTS.CONFIRM_K12_SETUP,
    });

    try {
      if (hasEnableJITStep) {
        await Promise.all(
          unsuccessfulIdps.map(async (patchableIdp) => {
            const response = await updateIdp({
              authSourceId: patchableIdp.directoryId,
              data: {
                federationType: patchableIdp.idp.federationType,
                jitConfig: {
                  accountCreation: true,
                  syncStrategy: patchableIdp.idp.jitConfig.syncStrategy,
                },
              },
              idpId: patchableIdp.idp.id,
              orgId,
            });

            if (response?.status >= 200 && response?.status < 300) {
              setUnsuccessfulIdps(unsuccessfulIdps.filter((idp) => idp.id !== patchableIdp.idp.id));
            }
          })
        );

        showSuccessToast(
          intl.formatMessage({
            id: 'common.adobeExpressK12SetupModal.autoAccountCreation.successToast',
          })
        );
      }

      if (hasAutoAssignStep) {
        if (inactiveRule) {
          const operations = [
            {
              op: 'replace',
              path: `/${inactiveRule.ruleId}/status`,
              value: 'ACTIVE',
            },
          ];
          await patchAcrsRules({operations, orgId});
        } else {
          const licenseGroupList = await LicenseGroupList.get({
            orgId: rootStore.organizationStore.activeOrgId,
            product: adobeExpressK12Product,
          });

          await createAcrsRule({
            orgId,
            requestBody: {
              licenseId: adobeExpressK12Product?.id,
              productProfile: licenseGroupList.items[0].id,
              status: RULE_STATUS.ACTIVE,
              triggers: TRIGGERS_TYPE.ON_DEMAND,
            },
          });
        }

        showSuccessToast(
          intl.formatMessage({
            id: 'common.adobeExpressK12SetupModal.autoProductAssignment.successToast',
          })
        );
      }

      setIsConfirmationState(true);
      setIsLoading(false);
    } catch (error) {
      showErrorToast(intl.formatMessage({id: 'common.adobeExpressK12SetupModal.errorToast'}));
    } finally {
      setIsLoading(false);
    }
  }, [
    hasEnableJITStep,
    hasAutoAssignStep,
    unsuccessfulIdps,
    intl,
    orgId,
    inactiveRule,
    patchAcrsRules,
    adobeExpressK12Product,
    createAcrsRule,
  ]);

  const additionalStepCount = hasEnableJITStep && hasAutoAssignStep ? 1 : 0;

  return (
    <>
      {!isConfirmationState && (
        <ModalContainer>
          <ModalDialog
            content={modalDialogContent}
            id="adobe-express-k12-modal"
            isLoading={isLoading}
            onCancel={onCloseSetupModal}
            onCta={onCta}
            size="L"
          >
            <ModalHeading>
              {intl.formatMessage({id: 'common.adobeExpressK12SetupModal.header'})}
            </ModalHeading>
            <ModalContent>
              <Heading level="3">
                {intl.formatMessage({id: 'common.adobeExpressK12SetupModal.description.review'})}
              </Heading>
              {hasEnableJITStep && (
                <Flex gap="size-200">
                  <View height="size-250" minWidth="size-250">
                    <Flex justifyContent="center">
                      <Text UNSAFE_style={{fontWeight: 'bold'}}>1</Text>
                    </Flex>
                  </View>

                  <Flex direction="column">
                    <Heading level={4} margin={0}>
                      {intl.formatMessage({
                        id: 'common.adobeExpressK12SetupModal.description.autoAccountCreation.title',
                      })}
                    </Heading>
                    <Text>
                      {intl.formatMessage({
                        id: 'common.adobeExpressK12SetupModal.description.autoAccountCreation.description',
                      })}
                    </Text>
                  </Flex>
                </Flex>
              )}

              {hasAutoAssignStep && (
                <Flex gap="size-200" marginTop="size-200">
                  <View height="size-250" minWidth="size-250">
                    <Flex justifyContent="center">
                      <Text UNSAFE_style={{fontWeight: 'bold'}}>{1 + additionalStepCount}</Text>
                    </Flex>
                  </View>

                  <Flex direction="column">
                    <Heading level={4} margin={0}>
                      {intl.formatMessage({
                        id: 'common.adobeExpressK12SetupModal.description.autoProductAssignment.title',
                      })}
                    </Heading>
                    <Text>
                      {intl.formatMessage({
                        id: 'common.adobeExpressK12SetupModal.description.autoProductAssignment.description',
                      })}
                    </Text>
                  </Flex>
                </Flex>
              )}
            </ModalContent>
          </ModalDialog>
        </ModalContainer>
      )}

      {isConfirmationState && (
        <ModalContainer>
          <ModalDialog id="adobe-express-k12-confirmation-modal" onCancel={onCloseSuccessModal}>
            <AdobeExpressK12SuccessConfirmationModal domains={domains} />
          </ModalDialog>
        </ModalContainer>
      )}
    </>
  );
};

AdobeExpressK12Modal.displayName = 'AdobeExpressK12Modal';
AdobeExpressK12Modal.propTypes = {
  /**
   * The Adobe Express K12 product
   */
  adobeExpressK12Product: PropTypes.shape({
    id: PropTypes.string,
  }),
  domains: PropTypes.arrayOf(PropTypes.string),
  /**
   * Whether the org has already valid rules
   */
  hasValidRules: PropTypes.bool,
  /**
   * A valid rule the org has, but it is inactive
   */
  inactiveRule: PropTypes.shape({
    ruleId: PropTypes.string,
  }),
  /**
   * Callback to invoke when the cancel or cta button is clicked.
   */
  onClose: PropTypes.func,
  /**
   * The patchable IdPs that can be updated to enable auto account creation
   */
  patchableIdps: PropTypes.arrayOf(
    PropTypes.shape({
      directoryId: PropTypes.string,
      idp: PropTypes.shape({
        federationType: PropTypes.string,
        id: PropTypes.string,
        jitConfig: PropTypes.shape({
          syncStrategy: PropTypes.string,
        }),
      }),
    })
  ),
};

export default AdobeExpressK12Modal;
export {K12_ANALYTICS_EVENTS};
