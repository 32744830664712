import {log} from '@admin-tribe/acsc';
import {DetailSection} from '@admin-tribe/acsc-ui';
import {Flex, Text} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

/**
 * The Users section for the Product profile and User group drawers.
 *
 * For the User group drawer, shows the count of entitled users based on user count.
 * For the Product profile drawer, shows the count of entitled users and the licence quota,
 * based on provisionedQuantity and totalQuantity, respectively.
 */
const UsersQuantitiesDetailSection = ({headingLevel = 3, isLoading, quantities, showError}) => {
  const intl = useIntl();

  if (
    !isLoading &&
    !showError &&
    quantities?.userCount === undefined &&
    quantities?.provisionedQuantity === undefined &&
    quantities?.totalQuantity === undefined
  ) {
    log.error('UsersQuantitiesDetailSection: no valid quantities property');
  }

  const renderQuantity = (countProp) =>
    quantities[countProp] >= 0 && (
      <Text>
        {intl.formatMessage(
          {id: `common.usersDetailSection.${countProp}.label`},
          {count: quantities[countProp]}
        )}
      </Text>
    );

  return (
    <DetailSection
      data-testid="users-detail-section"
      headingLevel={headingLevel}
      isLoading={isLoading}
      showError={showError}
      title={intl.formatMessage({id: 'common.usersDetailSection.header'})}
    >
      {quantities !== undefined && (
        <Flex direction="column" rowGap="size-50">
          {renderQuantity('userCount')}
          {renderQuantity('provisionedQuantity')}
          {renderQuantity('totalQuantity')}
        </Flex>
      )}
    </DetailSection>
  );
};

UsersQuantitiesDetailSection.propTypes = {
  /**
   * The heading level for the section Heading. The default is 3.
   */
  headingLevel: PropTypes.number,
  /**
   * If true, a wait spinner will be shown in the content area.
   * children and errorText are not shown. The DetailSection default is false.
   */
  isLoading: PropTypes.bool,
  /**
   * An object which should at least one property
   *   provisionedQuantity: integer (used only for the Product profile drawer)
   *   totalQuantity: integer (used only for the Product profile drawer)
   *   userCount: integer (used only for the User group drawer)
   */
  quantities: PropTypes.shape({
    provisionedQuantity: PropTypes.number,
    totalQuantity: PropTypes.number,
    userCount: PropTypes.number,
  }),
  /**
   * If true, a default error message will be shown.
   * If not specified, the DetailSection defaults is false.
   */
  showError: PropTypes.bool,
};

export default UsersQuantitiesDetailSection;
