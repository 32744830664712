import {log, modelCache, navBus} from '@admin-tribe/acsc';
import {
  DetailsButton,
  InfoItem,
  InfoItemScorecard,
  Page,
  PageActions,
  PageContent,
  PageHeader,
  PageInfoBar,
  formatFileSize,
} from '@admin-tribe/acsc-ui';
import {Item, TabList, TabPanels, Tabs} from '@adobe/react-spectrum';
import {useSharedAssetModel} from '@pandora/data-model-shared-asset';
import {LIBRARY_ACTION, LIBRARY_STATE, LibraryListTableSection} from '@pandora/react-library';
import {SharedAssetProvider} from '@pandora/react-shared-asset';
import {EN_DASH} from '@pandora/react-table-section';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {generatePath, useLocation} from 'react-router-dom';

import useDocumentTitle from 'common/hooks/useDocumentTitle';
import StorageStats from 'common/services/storage-stats/StorageStats';
import rootStore from 'core/RootStore';
import LibrariesDetailDrawer from 'features/storage/libraries/LibrariesDetailDrawer';
import {
  LIBRARY_COUNT_CACHE_ID,
  getActiveLibraryCount,
} from 'features/storage/libraries/libraryUtils';
import {PATH_STORAGE_LIBRARY_DETAILS} from 'features/storage/routing/storagePaths';

/**
 * @deprecated with temp_storage_shared_projects
 */
const LibrariesPage = observer(() => {
  const {state = LIBRARY_STATE.ACTIVE} = useLocation();

  const intl = useIntl();
  const {getSharedAssetModel} = useSharedAssetModel();

  const [activeLibraryCount, setActiveLibraryCount] = useState();
  const [isBumpered, setIsBumpered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sharedAssetModel, setSharedAssetModel] = useState();
  const [totalStorage, setTotalStorage] = useState(EN_DASH);
  const [usedStorage, setUsedStorage] = useState(EN_DASH);

  const title = intl.formatMessage({id: 'storage.libraries.title'});

  useDocumentTitle({defaultTitle: title});

  // get library data to display in page info bar
  useEffect(() => {
    setIsLoading(true);

    const getLibraryData = async () => {
      try {
        const storageStats = await StorageStats.get();
        const usageData = storageStats.getUsageData({shouldGetUserFolders: false});

        const sam = await getSharedAssetModel({
          applicationId: 'AdminConsole',
          applicationLocation: 'AdminConsole/Libraries',
          clientId: 'ONESIE1',
        });
        const count = await getActiveLibraryCount(sam);

        setSharedAssetModel(sam);
        setActiveLibraryCount(count);
        setUsedStorage(formatFileSize(intl, usageData[0].displayAmount)); // For shared folder usage type
        setTotalStorage(formatFileSize(intl, usageData[2].displayAmount)); // For unused usage type
      } catch (error) {
        log.error(`Error getting library data: ${error}`);
        setIsBumpered(true);
      } finally {
        setIsLoading(false);
      }
    };

    getLibraryData();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- on load
  }, []);

  const onUpdateLibraryList = (action) => {
    if (action === LIBRARY_ACTION.CREATE || action === LIBRARY_ACTION.RESTORE) {
      setActiveLibraryCount(activeLibraryCount + 1);
      modelCache.remove(LIBRARY_COUNT_CACHE_ID, LIBRARY_COUNT_CACHE_ID);
    } else if (action === LIBRARY_ACTION.DELETE) {
      setActiveLibraryCount(activeLibraryCount - 1);
      modelCache.remove(LIBRARY_COUNT_CACHE_ID, LIBRARY_COUNT_CACHE_ID);
    }
  };

  // When this page is moved, this method should be renamed and moved to
  // settings/routing/navigationCallbacks.
  const goToLibraryDetails = (library, libraryState) => {
    const path = generatePath(PATH_STORAGE_LIBRARY_DETAILS, {
      libraryId: library.id,
      orgId: rootStore.organizationStore.activeOrgId,
    });
    navBus.navigate(path, {state: {libraryState}});
  };

  const getLibraryListTableSection = (libraryState) =>
    sharedAssetModel ? (
      <SharedAssetProvider sharedAssetModel={sharedAssetModel}>
        <LibraryListTableSection
          onUpdateLibraryList={onUpdateLibraryList}
          onViewLibrary={(library) => {
            goToLibraryDetails(library, libraryState);
          }}
          orgId={rootStore.organizationStore.activeOrgId}
          state={libraryState}
        />
      </SharedAssetProvider>
    ) : null;

  return (
    <Page data-testid="libraries-page" isBumpered={isBumpered} isLoading={isLoading}>
      <PageHeader title={title} />
      <PageActions>
        <DetailsButton detailsDrawer={<LibrariesDetailDrawer />} />
      </PageActions>
      <PageInfoBar>
        <InfoItem
          label={intl.formatMessage({id: 'storage.libraries.scorecard.used'})}
          value={usedStorage}
        />
        <InfoItem
          label={intl.formatMessage({id: 'storage.libraries.scorecard.available'})}
          value={totalStorage}
        />
        <InfoItemScorecard
          label={intl.formatMessage({id: 'storage.libraries.scorecard.active'})}
          value={activeLibraryCount}
        />
      </PageInfoBar>
      <PageContent>
        <Tabs aria-label={title} defaultSelectedKey={state}>
          <TabList>
            <Item key="active">{intl.formatMessage({id: 'storage.libraries.tab.active'})}</Item>
            <Item key="discarded">
              {intl.formatMessage({id: 'storage.libraries.tab.inactive'})}
            </Item>
          </TabList>
          <TabPanels>
            <Item key="active">{getLibraryListTableSection(LIBRARY_STATE.ACTIVE)}</Item>
            <Item key="discarded">{getLibraryListTableSection(LIBRARY_STATE.DISCARDED)}</Item>
          </TabPanels>
        </Tabs>
      </PageContent>
    </Page>
  );
});

export default LibrariesPage;
