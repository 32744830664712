import {FULFILLABLE_ITEM_DELEGATION_TYPE, LicenseGroup, Product} from '@admin-tribe/acsc';
import binkyUI, {WIZARD_DISPATCH_ACTION, WizardTrigger, WizardView} from '@admin-tribe/acsc-ui';
import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import AddProductProfileModalContext from './AddProductProfileModalContext';
import ProfileDetailsPage from './details-page/AddProductProfileModalProfileDetailsPage';
import ProductsPage from './products-page/AddProductProfileModalProductsPage';
import QuotaDetailsPage from './quota-details-page/AddProductProfileModalQuotaDetailsPage';
import ServicesPage from './services-page/AddProductProfileModalServicesPage';
import StockQuotaPage from './stock-quota/AddProductProfileModalStockQuotaPage';

const {ModalContainer, ModalDialog, ModalHeading, ModalTagHeader} = binkyUI.common.components.modal;
const ImageIcon = binkyUI.common.components.ImageIcon;
const {getProductDisplayName} = binkyUI.product.productDisplayUtils;

// eslint-disable-next-line complexity -- over max
const AddProductProfileModal = ({
  orgId,
  onCancel,
  onSave,
  isOpen,
  isUnitTest = false,
  product,
  productProfile,
}) => {
  const intl = useIntl();
  const isCurrentRef = React.useRef(true);

  const servicesAndQuotas = [
    ...product.fulfillableItemList.getServiceTypeItemsWithSelectedInitialized(),
    ...product.fulfillableItemList
      .getQuotaTypeItems(false, FULFILLABLE_ITEM_DELEGATION_TYPE.PERSON)
      // we compare as a string as the cap can technically be either a string or number
      .filter((fi) => `${fi.chargingModel.cap}` !== '0')
      .map((fi) => {
        const result = cloneDeep(fi);
        if (result.offByDefault) {
          result.chargingModel.cap = 0;
        }
        return result;
      }),
  ];

  const showProductStep = product.fulfillableItemList.hasSingleDesktopApplicationConfig();
  const showQuotaStep = product.usesSeatBasedDelegation() && !product.isAdobeStock();
  const showStockQuotaStep =
    product.isAdobeStock() && product.fulfillableItemList.hasQuotaTypeItems();
  const showServicesStep =
    servicesAndQuotas.some((service) => service.purchased) && !product.isAdobeStock();

  let steps = [
    showProductStep &&
      intl.formatMessage({id: 'products.addProductProfileModal.wizard.productsStep'}),
    intl.formatMessage({
      id: productProfile
        ? 'products.addProductProfileModal.wizard.editDetailsStep'
        : 'products.addProductProfileModal.wizard.detailsStep',
    }),
    showQuotaStep && intl.formatMessage({id: 'products.addProductProfileModal.wizard.quotaStep'}),
    showStockQuotaStep &&
      intl.formatMessage({id: 'products.addProductProfileModal.wizard.stockQuotaStep'}),
    showServicesStep &&
      intl.formatMessage({id: 'products.addProductProfileModal.wizard.servicesStep'}),
  ];

  steps = steps.filter((item) => item);

  const nextText = intl.formatMessage({id: 'products.addProductProfileModal.next'});
  const backText = intl.formatMessage({id: 'products.addProductProfileModal.back'});
  const cancelText = intl.formatMessage({id: 'products.addProductProfileModal.cancel'});
  const saveText = intl.formatMessage({id: 'products.addProductProfileModal.save'});

  const [confirmButtonText, setConfirmButtonText] = useState(nextText);
  const [secondaryButtonText, setSecondaryButtonText] = useState(null);
  const [modalError, setModalError] = useState(null);

  const onError = (error, currentProductProfile) => {
    const errorCode = error.response.data.errorCode;
    // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- zaratan@ to update
    // istanbul ignore else
    if (isCurrentRef.current) {
      if (errorCode === 'LICENSE_GROUP_DUPLICATE_GROUP_NAME') {
        setModalError(
          intl.formatMessage(
            {id: 'products.addProductProfileModal.error.duplicateName'},
            {name: currentProductProfile.name}
          )
        );
      } else if (errorCode === 'INVALID_GROUP_NAME') {
        setModalError(
          intl.formatMessage({id: 'products.addProductProfileModal.error.invalidGroupName'})
        );
      } else if (errorCode === 'LICENSE_GROUP_DUPLICATE_GROUP_PUBLIC_NAME') {
        setModalError(
          intl.formatMessage(
            {id: 'products.addProductProfileModal.error.duplicatePublicName'},
            {name: currentProductProfile.publicName}
          )
        );
      } else {
        setModalError(intl.formatMessage({id: 'common.modal.error.generic'}));
      }
    }
  };

  const onCta = async (
    currentStep,
    dispatch,
    currentProductProfile,
    selectedSingleProductCode,
    setIsLoading
    // eslint-disable-next-line max-params -- need context params
  ) => {
    let isSuccess = false;
    const getProfileNameWithProductPrefix = () =>
      `${product.getFulfillableItemNameByCode(selectedSingleProductCode)}: ${
        currentProductProfile.name
      }`;

    const selectDesktopFI = () => {
      const selectedDesktopFI = currentProductProfile.fulfilledItems.find(
        (item) => item.code === selectedSingleProductCode
      );

      selectedDesktopFI.selected = true;
    };

    if (currentStep < steps.length - 1) {
      setConfirmButtonText(currentStep + 1 === steps.length - 1 ? saveText : nextText);
      dispatch({type: WIZARD_DISPATCH_ACTION.INCREMENT});
      setSecondaryButtonText(backText);
    } else {
      if (showProductStep) {
        selectDesktopFI();
        if (currentProductProfile.isNew())
          Object.assign(currentProductProfile, {name: getProfileNameWithProductPrefix()});
      }

      if (!currentProductProfile.publicName) {
        Object.assign(currentProductProfile, {publicName: currentProductProfile.name});
      }

      setIsLoading(true);

      try {
        const savedProductProfile = await currentProductProfile.save();
        if (isCurrentRef.current && onSave) {
          onSave(savedProductProfile);
        }
        isSuccess = true;
      } catch (error) {
        onError(error, currentProductProfile);
        // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- zaratan@ to update
        // istanbul ignore else
        if (isCurrentRef.current) {
          setIsLoading(false);
        }
      }
    }

    return isSuccess;
  };

  const ctaToastGenerator = (isNew) =>
    intl.formatMessage({
      id: isNew
        ? 'common.toast.modal.productProfileAdded'
        : 'common.toast.modal.productProfileUpdated',
    });

  const onSecondary = (currentStep, dispatch) => {
    setModalError(null);
    setConfirmButtonText(nextText);
    setSecondaryButtonText(currentStep - 1 === 0 ? null : backText);
    dispatch({type: WIZARD_DISPATCH_ACTION.DECREMENT});
  };

  const createModal = useCallback(
    (children) => (
      <ModalContainer>
        <AddProductProfileModalContext
          orgId={orgId}
          product={product}
          productProfile={productProfile}
          setModalError={setModalError}
          showStepList={steps.length > 1}
        >
          {/* eslint-disable-next-line @admin-tribe/admin-tribe/extract-large-computations -- zaratan@ to fix */}
          {({
            currentProductProfile,
            isLoading,
            isNew,
            isValid,
            setIsLoading,
            selectedSingleProductCode,
          }) => (
            <WizardTrigger steps={steps}>
              {/* eslint-disable-next-line @admin-tribe/admin-tribe/extract-large-computations -- cawright@ to fix */}
              {({dispatch, currentStep}) => (
                <ModalDialog
                  cancelLabel={cancelText}
                  ctaLabel={steps.length === 1 ? saveText : confirmButtonText}
                  ctaToastGenerator={() => ctaToastGenerator(isNew)}
                  errorMessage={modalError}
                  heightVariant="static"
                  id={productProfile ? 'edit-product-profile-modal' : 'add-product-profile-modal'}
                  isCtaDisabled={!isValid}
                  isLoading={isLoading}
                  onCancel={onCancel}
                  onCta={() =>
                    onCta(
                      currentStep,
                      dispatch,
                      currentProductProfile,
                      selectedSingleProductCode,
                      setIsLoading
                    )
                  }
                  /* ModalDialog logs a warning if one of onSecondary or secondaryLabel is set and the other is not */
                  onSecondary={
                    secondaryButtonText || isUnitTest
                      ? () => onSecondary(currentStep, dispatch)
                      : undefined
                  }
                  secondaryLabel={secondaryButtonText}
                >
                  <ModalHeading>
                    {intl.formatMessage({
                      id: currentProductProfile.id
                        ? 'products.addProductProfileModal.editTitle'
                        : 'products.addProductProfileModal.createTitle',
                    })}
                  </ModalHeading>

                  {product && (
                    <ModalTagHeader
                      IconComponent={
                        <ImageIcon
                          alt={getProductDisplayName(intl, product)}
                          size="M"
                          src={product.getIcon()}
                        />
                      }
                    >
                      {getProductDisplayName(intl, product)}
                    </ModalTagHeader>
                  )}
                  {children}
                </ModalDialog>
              )}
            </WizardTrigger>
          )}
        </AddProductProfileModalContext>
      </ModalContainer>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only change for these vars
    [confirmButtonText, secondaryButtonText, isOpen, modalError]
  );

  // eslint-disable @admin-tribe/admin-tribe/comment-side-effects -- zaratan@ to update
  useEffect(() => {
    if (!isOpen) {
      setConfirmButtonText(nextText);
      setSecondaryButtonText(null);
      setModalError(null);
    }
  }, [isOpen, nextText]);

  if (!isOpen) {
    return null;
  }

  return createModal(
    <WizardView>
      {showProductStep && <ProductsPage />}
      <ProfileDetailsPage />
      {showQuotaStep && <QuotaDetailsPage />}
      {showStockQuotaStep && <StockQuotaPage />}
      {showServicesStep && <ServicesPage />}
    </WizardView>
  );
};

AddProductProfileModal.propTypes = {
  /**
   * Whether the modal has been opened by its container or not.
   */
  isOpen: PropTypes.bool,
  /**
   * For unit tests, to force the onSecondary function to be defined.
   * secondaryButtonText is orginally undefined, and then updated in onCta and onSecondary.
   * The unit tests are set up to just test one step at a time so it is not possible to
   * transition to either onCta and onSecondary to set the label and then press the secondary
   * button in a test.
   * The default is false.
   */
  isUnitTest: PropTypes.bool,
  /**
   * Callback triggered when the cancel button is clicked.
   */
  onCancel: PropTypes.func,
  /**
   * Callback triggered when the save button is clicked.
   */
  onSave: PropTypes.func,
  /**
   * The org ID this product profile is being added to.
   */
  orgId: PropTypes.string.isRequired,
  /**
   * The product this product profile is being added to.
   */
  product: PropTypes.oneOfType([PropTypes.instanceOf(Product), PropTypes.object]),
  /**
   * If provided it indicates an edit profile rather than a create, and state will be
   * taken from this profile rather than a newly created one.
   */
  productProfile: PropTypes.oneOfType([PropTypes.instanceOf(LicenseGroup), PropTypes.object]),
};

export default AddProductProfileModal;
