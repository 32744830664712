import {showError as showErrorToast, showSuccess as showSuccessToast} from '@admin-tribe/acsc-ui';
import {Text, useDialogContainer} from '@adobe/react-spectrum';
import {ModalAlertDialog} from '@pandora/react-modal-dialog';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {CERTIFICATE_TYPES} from 'features/settings/common/constants/samlCertificatesConstants';
import {useSetupCertificatesContext} from 'features/settings/components/setup-directory-authentication/SetupCertificatesContext';

const MODAL_ID = 'delete-certificate-confirmation-modal';

const DeleteCertificateConfirmationModal = ({certificate}) => {
  const intl = useIntl();
  const dialog = useDialogContainer();
  const {
    state: {isLoading},
    deleteCertificate,
  } = useSetupCertificatesContext();

  const handleRemoveCertificate = async () => {
    try {
      await deleteCertificate(certificate.id, certificate.type);

      showSuccessToast(
        intl.formatMessage({id: 'settings.certificates.table.toasts.deleteSuccess'})
      );
      dialog.dismiss();
    } catch (error) {
      showErrorToast(intl.formatMessage({id: 'settings.certificates.table.toasts.deleteError'}));
    }
  };

  const isCSR = () => certificate.type === CERTIFICATE_TYPES.CERTIFICATE_SIGNING_REQUEST;

  const modalTitle = intl.formatMessage({
    id: isCSR()
      ? 'settings.certificates.removeCerts.csrs.title'
      : 'settings.certificates.removeCerts.certificates.title',
  });
  const modalDescription = intl.formatMessage({
    id: isCSR()
      ? 'settings.certificates.removeCerts.csrs.description'
      : 'settings.certificates.removeCerts.certificates.description',
  });

  return (
    <ModalAlertDialog
      cancelLabel={intl.formatMessage({
        id: 'common.modal.buttons.cancel',
      })}
      id={MODAL_ID}
      isLoading={isLoading}
      onClose={() => dialog.dismiss()}
      onPrimaryAction={handleRemoveCertificate}
      primaryActionLabel={intl.formatMessage({
        id: 'common.modal.buttons.remove',
      })}
      title={modalTitle}
      variant="destructive"
    >
      <Text>{modalDescription}</Text>
    </ModalAlertDialog>
  );
};

DeleteCertificateConfirmationModal.propTypes = {
  certificate: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
};

export default DeleteCertificateConfirmationModal;
