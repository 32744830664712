import {useStore} from '@admin-tribe/acsc-ui';
import {useDialogContainer} from '@adobe/react-spectrum';
import {ModalAlertDialog} from '@pandora/react-modal-dialog';
import {observer} from 'mobx-react-lite';
import {PropTypes} from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {useDirectoryContext} from 'features/settings/components/directory/DirectoryContext';
import useSamlCertificates from 'features/settings/hooks/api/useSamlCertificates';
import useAuthentication from 'features/settings/hooks/directory-setup/useAuthentication';
import DirectorySetupStore from 'features/settings/stores/DirectorySetupStore';

import './RemoveIdpModal.pcss';

const RemoveIdpModal = observer(({idpId, idpName, refreshData}) => {
  const {directoryStore} = useDirectoryContext();
  const dialog = useDialogContainer();
  const directorySetupStore = useStore(() => new DirectorySetupStore(directoryStore));
  const {removeIdp} = useAuthentication(directorySetupStore);
  const {clearCertificatesCache, clearCsrsCache} = useSamlCertificates();

  const intl = useIntl();

  const onRemoveIdp = async () => {
    const isSuccessful = await removeIdp({idpId, idpName});

    if (isSuccessful) {
      clearCertificatesCache();
      clearCsrsCache();

      refreshData();
      dialog.dismiss();
    }
  };

  return (
    <ModalAlertDialog
      cancelLabel={intl.formatMessage({id: 'common.modal.buttons.cancel'})}
      data-test-id="remove-idp-modal"
      isLoading={directoryStore.isLoading}
      onCancel={() => dialog.dismiss()}
      onPrimaryAction={onRemoveIdp}
      primaryActionLabel={intl.formatMessage({
        id: 'settings.directories.removeIdpModal.buttons.remove',
      })}
      title={intl.formatMessage({id: 'settings.directories.removeIdpModal.title'})}
      variant="error"
    >
      {intl.formatMessage(
        {id: 'settings.directories.removeIdpModal.description'},
        {b: (str) => <span styleName="bolded-text">{str}</span>, idpName}
      )}
    </ModalAlertDialog>
  );
});

RemoveIdpModal.propTypes = {
  idpId: PropTypes.string.isRequired,
  idpName: PropTypes.string,
  refreshData: PropTypes.func,
};

export default RemoveIdpModal;
