import {modelCache} from '@admin-tribe/acsc';
import {STATE_CHANGED, TeamFilter, TeamOrder} from '@ccx-public/shared-asset-model-js';
import {LIBRARY_STATE} from '@pandora/react-library';

const LIBRARY_COUNT_CACHE_ID = 'ActiveLibraryCountCacheId';

const getLibraryList = async (sharedAssetModel, libraryState = LIBRARY_STATE.ACTIVE) => {
  const teamFilter =
    libraryState === LIBRARY_STATE.ACTIVE ? TeamFilter.ActiveTeams : TeamFilter.DiscardedTeams;
  const libraryList = sharedAssetModel.spaceContext
    .getTeams()
    .getList(TeamOrder.default, teamFilter);
  await libraryList.refresh();
  await new Promise((resolve) => {
    libraryList.on(STATE_CHANGED, resolve);
  });
  return libraryList;
};

/**
 * @description Get the active library count
 *
 * @param {ISAMCore} sharedAssetModel - shared asset model
 * @returns {Promise<number>} a promise which resolves with the active library count
 */
const getActiveLibraryCount = async (sharedAssetModel) => {
  const cachedPromise = modelCache.get(LIBRARY_COUNT_CACHE_ID, LIBRARY_COUNT_CACHE_ID);
  if (cachedPromise) {
    return cachedPromise;
  }
  const libraryList = await getLibraryList(sharedAssetModel);
  modelCache.put(LIBRARY_COUNT_CACHE_ID, LIBRARY_COUNT_CACHE_ID, libraryList.total);
  return libraryList.total;
};

/**
 * @description Get the library object given a library ID
 *
 * @param {ISAMCore} sharedAssetModel - shared asset model
 * @param {string} libraryId - ID of the library
 * @param {LIBRARY_STATE} libraryState - Fetch active or discarded libraries. Defaults to active.
 * @returns {Promise} a promise which resolves with the given library (or undefined if there is no match)
 */
const getLibraryFromId = async (
  sharedAssetModel,
  libraryId,
  libraryState = LIBRARY_STATE.ACTIVE
) => {
  const libraryList = await getLibraryList(sharedAssetModel, libraryState);
  return libraryList.items.find((item) => item.id === libraryId);
};

export {getActiveLibraryCount, getLibraryFromId, LIBRARY_COUNT_CACHE_ID};
