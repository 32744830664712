import {Contract} from '@admin-tribe/acsc';
import {
  Page,
  PageBanner,
  PageContent,
  PageHeader,
  PageNav,
  showError,
  showInfo,
  showSuccess,
} from '@admin-tribe/acsc-ui';
import {ModalContainer} from '@pandora/react-modal-dialog';
import {TableFilters, TableSection} from '@pandora/react-table-section';
import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import useDocumentTitle from 'common/hooks/useDocumentTitle';
import {MODAL_NAMES} from 'features/account/billing-history/BillingHistoryConstants';
import {updatePoNumber} from 'features/account/billing-history/BillingHistoryUtils';
import BillingHistoryTable from 'features/account/billing-history/components/billing-history-table/BillingHistoryTable';
import BillingHistoryTableSectionActions from 'features/account/billing-history/components/billing-history-table/BillingHistoryTableSectionActions';
import EditPoNumberModal from 'features/account/billing-history/components/edit-po-number-modal/EditPoNumberModal';
import EmailInvoicesModal from 'features/account/billing-history/components/email-invoices-modal/EmailInvoicesModal';
import useDownloadInvoices from 'features/account/billing-history/hooks/useDownloadInvoices';
import useInvoiceList from 'features/account/billing-history/hooks/useInvoiceList';
import useUnpaidInvoicesCheck from 'features/account/billing-history/hooks/useUnpaidInvoicesCheck';
import useUpdateInvoice from 'features/account/billing-history/hooks/useUpdateInvoice';

/**
 * Billing History Page that displays invoice information as well as payment, email, and download capabilities
 */
const BillingHistoryPage = ({contract, onOpenOneTimePayment}) => {
  const {hasUnpaidInvoices} = useUnpaidInvoicesCheck();
  const {dispatchTableSectionChange, error, invoiceList, isLoading} = useInvoiceList();
  const totalPages = invoiceList?.pagination?.totalPages ?? 1;
  const pageNumber = invoiceList?.pageNumber ?? 1;

  const onTableSectionChange = ({action, payload}) => {
    dispatchTableSectionChange({action, payload});
  };

  useDocumentTitle({key: 'account.billingHistory.title'});

  const intl = useIntl();
  const [items, setItems] = useState([]);
  const [dialog, setDialog] = useState('');
  const [selectedInvoices, setSelectedInvoices] = useState({});
  const [editPOInvoice, setEditPOInvoice] = useState();

  const {downloadInvoices, hasDownloadFinished, invoiceCount, isDownloadError, isDownloading} =
    useDownloadInvoices({contract});

  const {
    changePoNumber,
    hasUpdateError,
    isUpdateSubmitted,
    isUpdating,
    updatedInvoiceId,
    updatedPoNumbers,
  } = useUpdateInvoice();

  // Setting state on first render
  useEffect(() => {
    if (updatedPoNumbers) {
      const newItems = cloneDeep(invoiceList?.items);
      Object.entries(updatedPoNumbers).forEach(([invoiceId, poNumber]) => {
        const invoiceToUpdate = newItems.find((invoice) => invoice.id === invoiceId);
        if (invoiceToUpdate) {
          invoiceToUpdate.poNumber = poNumber;
        }
      });
      setItems(newItems);
    } else {
      setItems(invoiceList?.items);
    }
  }, [invoiceList?.items, updatedPoNumbers]);

  // To resolve invoice download toasts
  useEffect(() => {
    if (!isDownloading && hasDownloadFinished) {
      if (isDownloadError) {
        showError(intl.formatMessage({id: 'account.billingHistory.toast.downloadInvoice.error'}));
      } else if (invoiceCount) {
        showSuccess(
          intl.formatMessage(
            {id: 'account.billingHistory.toast.downloadInvoice.success'},
            {count: invoiceCount}
          )
        );
      }
    }
  }, [invoiceCount, intl, isDownloadError, isUpdateSubmitted, isDownloading, hasDownloadFinished]);

  // To resolve download in progress toast
  // Created a separate useEffect, instead of placing it in the useEffect above, otherwise showInfo will be called multiple times
  useEffect(() => {
    if (isDownloading) {
      showInfo(
        intl.formatMessage(
          {id: 'account.billingHistory.toast.downloadInvoice.inProgress'},
          {count: invoiceCount}
        ),
        {timeout: 9000}
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- so the toast is not called multiple times if invoiceCount changes
  }, [isDownloading, intl]);

  // To resolve PO number update toasts
  useEffect(() => {
    if (!isUpdating && isUpdateSubmitted) {
      if (hasUpdateError) {
        showError(intl.formatMessage({id: 'account.billingHistory.toast.editPONumber.error'}));
      } else if (updatedInvoiceId) {
        showSuccess(
          intl.formatMessage(
            {id: 'account.billingHistory.toast.editPONumber.success'},
            {invoiceId: updatedInvoiceId}
          )
        );
      }
    }
  }, [updatedInvoiceId, intl, hasUpdateError, isUpdateSubmitted, isUpdating]);

  const onChangePoNumber = async ({invoiceId, newPoNumber, oldPoNumber, reason, type}) => {
    await changePoNumber({
      contractId: contract.id,
      invoiceId,
      newPoNumber,
      oldPoNumber,
      reason,
    });

    // To update PO number in the state
    if (!hasUpdateError && !isUpdating && isUpdateSubmitted) {
      const newItems = updatePoNumber({invoiceId, items, newPoNumber, type});
      setItems(newItems);
    }

    setDialog(null);
  };

  const onOpenEmailInvoices = ({invoices}) => {
    setSelectedInvoices(invoices);
    setDialog(MODAL_NAMES.EMAIL_INVOICES);
  };

  const onOpenEditPONumber = ({invoice}) => {
    setEditPOInvoice(invoice);
    setDialog(MODAL_NAMES.EDIT_PO_NUMBER);
  };

  return (
    <Page data-testid="account-billing-history">
      {hasUnpaidInvoices && (
        <PageBanner
          header={intl.formatMessage({id: 'account.billingHistory.banner.header'})}
          showDefaultButton={false}
          variant="warning"
        >
          {intl.formatMessage({id: 'account.billingHistory.banner.content'})}
        </PageBanner>
      )}
      <PageHeader title={intl.formatMessage({id: 'account.billingHistory.title'})} />
      <PageNav />
      <PageContent>
        <ModalContainer>
          {dialog === MODAL_NAMES.EDIT_PO_NUMBER && (
            <EditPoNumberModal
              invoice={editPOInvoice}
              isUpdating={isUpdating}
              onCancel={() => setDialog(null)}
              onSuccess={onChangePoNumber}
            />
          )}
          {dialog === MODAL_NAMES.EMAIL_INVOICES && (
            <EmailInvoicesModal
              contract={contract}
              onCancel={() => setDialog(null)}
              onSuccess={() => setDialog(null)}
              selectedInvoices={selectedInvoices}
            />
          )}
        </ModalContainer>
        <TableSection
          getItemKey={(invoice) =>
            `${invoice.externalContractId}:${invoice.id}:${invoice.documentType}`
          }
          isServerError={error}
          items={items}
          onTableSectionChange={onTableSectionChange}
          pageNumber={pageNumber}
          selectionMode="multiple"
          totalPages={totalPages}
        >
          <TableFilters
            isDisabled={isLoading}
            label={intl.formatMessage({id: 'account.billingHistory.searchLabel'})}
          />
          <BillingHistoryTableSectionActions
            isDisabled={isLoading}
            isDownloading={isDownloading}
            onDownloadInvoices={downloadInvoices}
            onOpenEmailInvoices={onOpenEmailInvoices}
          />
          <BillingHistoryTable
            isLoading={isLoading}
            isPONumberColumnDisplayed={contract.isPaymentCategoryPO()}
            onDownloadInvoices={downloadInvoices}
            onOpenEditPONumber={onOpenEditPONumber}
            onOpenEmailInvoices={onOpenEmailInvoices}
            onPayNowPressed={onOpenOneTimePayment}
          />
        </TableSection>
      </PageContent>
    </Page>
  );
};

BillingHistoryPage.propTypes = {
  /**
   * The org's contract. Used to determine PO number column visibility.
   */
  contract: PropTypes.instanceOf(Contract),
  /**
   * Function passed to open the one time payment window.
   */
  onOpenOneTimePayment: PropTypes.func.isRequired,
};

export default BillingHistoryPage;
