import {log} from '@admin-tribe/acsc';
import {OverlayWait, showError, showSuccess} from '@admin-tribe/acsc-ui';
import {TableActions, TableFilters, TableSection} from '@pandora/react-table-section';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import {useProductProfileTableContext} from 'features/products/product-profiles/product-profile-table-section/product-profile-table-context/ProductProfileTableContext';

import ProductProfileTable from './ProductProfileTable';
import ProductProfileTableActions from './ProductProfileTableActions';
import {getProfileTableSectionProps} from './productProfileTableUtils';

const ProductProfileTableSection = () => {
  const {onTableSectionChange, hasError, isLoading, licenseGroupList, pageContext} =
    useProductProfileTableContext();
  const intl = useIntl();

  const [isBusy, setIsBusy] = useState(false);
  const currentItems = licenseGroupList?.items;
  const isSelectableFn = pageContext.isProductTarget() ? 'isRemovable' : 'isAdministerable';

  const {display, selectionMode} = getProfileTableSectionProps({pageContext});

  const getIsProfileSelectable = (itemKey) => {
    const profile = currentItems.find((p) => p.id === itemKey);
    return profile ? profile[isSelectableFn]() : false;
  };

  const onCloseDrawer = async ({productProfile, tableSectionUtils}) => {
    if (productProfile.hasUnsavedChanges()) {
      setIsBusy(true);
      try {
        await productProfile.save();

        // The TableSection's ListContainer controls when items are passed down to the React Spectrum
        // TableView.
        // We've modified an item (productProfile) directly which the ListContainer does not expect/handle.
        // There is a useEffect for items in useControlledPropsInCurrentState.
        // Once the list items are updated below, we have to fool the controlled property check into thinking
        // listContainerState.items !== items so that the controlled items property is updated and the table updates.
        // We do this by adding a propery to the modified item here.
        productProfile.forceRender = true;

        // Triggers TABLE_SECTION_ACTIONS.ON_TABLE_ITEMS_MODIFIED which causes a list get via fetchList
        tableSectionUtils.modifyTableItems(productProfile.id);

        showSuccess();
      } catch (error) {
        // Restore the value of the item to its saved state.
        // This will keep the displayed value and the value in listContainerState.items in sync.
        productProfile.restore();
        log.error(`Failed to remove profile: ${error}`);
        showError();
      } finally {
        setIsBusy(false);
      }
    }
  };

  return (
    <OverlayWait isLoading={isLoading || isBusy} showContent size="M">
      <TableSection
        getIsItemSelectable={getIsProfileSelectable}
        isServerError={hasError}
        items={currentItems ?? []}
        onTableSectionChange={onTableSectionChange}
        pageNumber={licenseGroupList?.pagination.currentPage ?? 1}
        selectionMode={selectionMode}
        totalPages={licenseGroupList?.pagination.totalPages ?? 0}
      >
        <TableFilters
          hasSearch
          label={intl.formatMessage({id: 'products.productProfileTable.search'})}
        />
        <TableActions>
          <ProductProfileTableActions />
        </TableActions>
        {(!!currentItems || hasError) && (
          <ProductProfileTable display={display} onCloseDrawer={onCloseDrawer} />
        )}
      </TableSection>
    </OverlayWait>
  );
};

export default ProductProfileTableSection;
