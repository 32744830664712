/* eslint-disable max-lines -- many routes to cover */
import {navBus} from '@admin-tribe/acsc';
import {generatePath} from 'react-router-dom';

import rootStore from 'core/RootStore';
import {
  PATH_ADMINISTRATORS,
  PATH_ADMINISTRATOR_DETAILS,
  PATH_DEVELOPERS,
  PATH_DEVELOPER_DETAILS,
  PATH_DIRECTORIES,
  PATH_DIRECTORY_USERS,
  PATH_DIRECTORY_USER_BULK_OPERATIONS,
  PATH_DIRECTORY_USER_BULK_OPERATIONS_RESULTS,
  PATH_DIRECTORY_USER_DETAILS,
  PATH_USERS,
  PATH_USERS_BULK_OPERATIONS,
  PATH_USERS_BULK_OPERATIONS_LICENSE_DEFICIT_REPORT,
  PATH_USERS_BULK_OPERATIONS_RESULTS,
  PATH_USERS_BULK_OPS_MODAL_MODE,
  PATH_USER_DETAILS,
  PATH_USER_GROUPS,
  PATH_USER_GROUPS_BULK_OPERATIONS,
  PATH_USER_GROUPS_BULK_OPERATIONS_RESULTS,
  PATH_USER_GROUP_DETAILS,
  PATH_USER_GROUP_DETAILS_BULK_OPERATIONS,
  PATH_USER_GROUP_DETAILS_LICENSE_DEFICIT_REPORT,
  PATH_USER_GROUP_DETAILS_RESULTS,
} from 'features/users/routing/usersPaths';

/**
 * @description Get the href of the Admin details page.
 *
 * @param {Object} object
 * @param {String} object.userId - id of the admin.
 */
const getHrefForAdminDetails = ({userId}) =>
  generatePath(PATH_ADMINISTRATOR_DETAILS, {
    orgId: rootStore.organizationStore.activeOrgId,
    userId,
  });

/**
 * @description Get the href for the Developers details page.
 *
 * @param {Object} object
 * @param {String} object.userId - id of the user.
 */
const getHrefForDeveloperDetails = ({userId}) =>
  generatePath(PATH_DEVELOPER_DETAILS, {
    orgId: rootStore.organizationStore.activeOrgId,
    userId,
  });

/**
 * @description Get the href for directory user bulk operations details
 *
 * @param {Object} object
 * @param {String} object.directoryId - the id of the directory.
 *
 * @returns {Function} A function that takes in a job id as an argument to determine what the href is for the job result
 */
const getHrefForDirectoryUserBulkOpsDetailsFn =
  ({directoryId}) =>
  ({jobId}) =>
    generatePath(PATH_DIRECTORY_USER_BULK_OPERATIONS_RESULTS, {
      directoryId,
      jobId,
      orgId: rootStore.organizationStore.activeOrgId,
    });

/**
 * @description Get the href of the Directory User details page.
 *
 * @param {Object} object
 * @param {String} object.userId - id of the directory.
 * @param {String} object.userId - id of the user.
 */
const getHrefForDirectoryUserDetails = ({directoryId, userId}) =>
  generatePath(PATH_DIRECTORY_USER_DETAILS, {
    directoryId,
    orgId: rootStore.organizationStore.activeOrgId,
    userId,
  });

/**
 * @description Get the href for the page of users for a directory.
 *
 * @param {Object} object
 * @param {String} object.directoryId - id of the directory.
 */
const getHrefForDirectoryUsers = ({directoryId}) =>
  generatePath(PATH_DIRECTORY_USERS, {
    directoryId,
    orgId: rootStore.organizationStore.activeOrgId,
  });

/**
 * @description Get the href for the User groups details page.
 *
 * @param {Object} object
 * @param {String} object.userId - id of the user.
 */
const getHrefForUserDetails = ({userId}) =>
  generatePath(PATH_USER_DETAILS, {
    orgId: rootStore.organizationStore.activeOrgId,
    userId,
  });

/**
 * @description Get the href for user group bulk operations details
 *
 * @param {Object} object
 * @param {String} object.userGroupId - the id of the user group. if undefined, it will link to all user groups
 *
 * @returns {Function} A function that takes in a job id as an argument to determine what the href is for the job result
 */
const getHrefForUserGroupBulkOpsDetailsFn =
  ({userGroupId} = {}) =>
  ({jobId}) => {
    const path = userGroupId
      ? PATH_USER_GROUP_DETAILS_RESULTS
      : PATH_USER_GROUPS_BULK_OPERATIONS_RESULTS;

    return generatePath(path, {
      jobId,
      orgId: rootStore.organizationStore.activeOrgId,
      userGroupId,
    });
  };

/**
 * @description Get the href for the User groups details page.
 *
 * @param {Object} object
 * @param {String} object.userGroupId - id of the user group.
 */
const getHrefForUserGroupDetails = ({userGroupId}) =>
  generatePath(PATH_USER_GROUP_DETAILS, {
    orgId: rootStore.organizationStore.activeOrgId,
    userGroupId,
  });

/**
 * @description Get the href for user group license deficit report.
 *
 * @param {Object} object
 * @param {String} object.targetId - the id of the user group. if undefined, it will link to all user groups
 *
 * @returns {Function} A function that takes in a job id as an argument to determine what the href is for the license defict report
 */
const getHrefForUserGroupLicenseDeficitFn =
  ({userGroupId}) =>
  ({jobId}) =>
    generatePath(PATH_USER_GROUP_DETAILS_LICENSE_DEFICIT_REPORT, {
      jobId,
      orgId: rootStore.organizationStore.activeOrgId,
      userGroupId,
    });

/**
 * @description Get the href for the Users page.
 */
const getHrefForUsers = () =>
  generatePath(PATH_USERS, {
    orgId: rootStore.organizationStore.activeOrgId,
  });

/**
 * @description Get the href of a specifc bulk operation from the
 *              User Bulk Operations list
 *
 * @param {Object} object
 * @param {String} jobId - id of the job
 */
const getHrefForUsersBulkOpsDetails = ({jobId}) =>
  generatePath(PATH_USERS_BULK_OPERATIONS_RESULTS, {
    jobId,
    orgId: rootStore.organizationStore.activeOrgId,
  });

/**
 * @description Get the href of a specifc bulk operation license deficit report
 *
 * @param {Object} object
 * @param {String} jobId - id of the job
 */
const getHrefForUsersLicenseDeficit = ({jobId}) =>
  generatePath(PATH_USERS_BULK_OPERATIONS_LICENSE_DEFICIT_REPORT, {
    jobId,
    orgId: rootStore.organizationStore.activeOrgId,
  });

/**
 * @description Navigate to the Admin details page.
 *
 * @param {Object} object
 * @param {String} object.userId - id of the admin.
 */
const goToAdminDetails = ({userId}) => {
  navBus.navigate(getHrefForAdminDetails({userId}));
};

/**
 * @description Navigate to the 'Administrators' page.
 */
const goToAdministrators = () => {
  navBus.navigate(
    generatePath(PATH_ADMINISTRATORS, {
      orgId: rootStore.organizationStore.activeOrgId,
    })
  );
};

/**
 * @description Navigate to the 'Developers' page.
 */
const goToDevelopers = () => {
  navBus.navigate(
    generatePath(PATH_DEVELOPERS, {
      orgId: rootStore.organizationStore.activeOrgId,
    })
  );
};

/**
 * @description Navigate to the "Directory users" page with the list of owned directories.
 */
const goToDirectoryList = () => {
  navBus.navigate(
    generatePath(PATH_DIRECTORIES, {
      orgId: rootStore.organizationStore.activeOrgId,
    })
  );
};

/**
 * @description Navigate to the DirectoryUsersPage which lists
 *   the users for the specified directory.
 *
 * @param {Object} object
 * @param {String} object.directoryId - id of the directory.
 */
const goToDirectoryUsers = ({directoryId}) => {
  navBus.navigate(getHrefForDirectoryUsers({directoryId}));
};

/**
 * @description Navigate to the DirectoryUsersPage which lists
 *   the users for the specified directory.
 *
 * @param {Object} object
 * @param {String} object.directoryId - id of the directory.
 * @param {String} object.userId - user id in the directory.
 */
const goToDirectoryUserDetails = ({directoryId, userId}) => {
  navBus.navigate(getHrefForDirectoryUserDetails({directoryId, userId}));
};

/**
 * @description Method to go the the BulkOperationsPage for a directory user.
 *
 * @param {Object} object
 * @param {String} object.directoryId - the id of the directory.
 */
const goToDirectoryUserBulkOps = ({directoryId}) =>
  navBus.navigate(
    generatePath(PATH_DIRECTORY_USER_BULK_OPERATIONS, {
      directoryId,
      orgId: rootStore.organizationStore.activeOrgId,
    })
  );

/**
 * @description Returns a method to navigate to directory user bulk operations details.
 *
 * @param {Object} object
 * @param {String} object.directoryId - the id of the directory.
 *
 * @returns {Function} A function that takes in a job id as an argument to determine where to navigate to for the job result
 */
const goToDirectoryUserBulkOpsDetailsFn =
  ({directoryId}) =>
  ({jobId}) =>
    navBus.navigate(
      generatePath(PATH_DIRECTORY_USER_BULK_OPERATIONS_RESULTS, {
        directoryId,
        jobId,
        orgId: rootStore.organizationStore.activeOrgId,
      })
    );

/**
 * @description Navigate to the User details page.
 *
 * @param {Object} object
 * @param {String} object.userId - id of the user.
 */
const goToUserDetails = ({userId}) => {
  navBus.navigate(getHrefForUserDetails({userId}));
};

/**
 * @description Navigate to User Group 'Bulk operations' page.
 */
const goToUserGroupBulkOperations = () => {
  navBus.navigate(
    generatePath(PATH_USER_GROUPS_BULK_OPERATIONS, {
      orgId: rootStore.organizationStore.activeOrgId,
    })
  );
};

/**
 * @description Method that navigates to the Jobs page for a user group.
 *
 * @param {Object} object
 * @param {String} object.userGroupId - the id of the user group.
 */
const goToUserGroupDetailsJobs = ({userGroupId}) => {
  navBus.navigate(
    generatePath(PATH_USER_GROUP_DETAILS_BULK_OPERATIONS, {
      orgId: rootStore.organizationStore.activeOrgId,
      userGroupId,
    })
  );
};

/**
 * @description Navigate to the 'Users' page.
 */
const goToUsers = () => {
  navBus.navigate(getHrefForUsers());
};

/**
 * @description Navigate to a specifc bulk operation from the
 *              User Bulk Operations list.
 *
 * @param {Object} object
 * @param {String} jobId - id of the job
 */
const goToUsersBulkOpsDetails = ({jobId}) =>
  navBus.navigate(getHrefForUsersBulkOpsDetails({jobId}));

/**
 * @description Navigate to the User groups details page.
 *
 * @param {Object} object
 * @param {String} object.userGroupId - id of the user group.
 */
const goToUserGroupDetails = ({userGroupId}) => {
  navBus.navigate(getHrefForUserGroupDetails({userGroupId}));
};

/**
 * @description Navigate to the User groups page.
 */
const goToUserGroups = () => {
  navBus.navigate(generatePath(PATH_USER_GROUPS, {orgId: rootStore.organizationStore.activeOrgId}));
};

/**
 * @description Navigate to Users 'Bulk operations' page.
 */
const goToUsersBulkOperations = () => {
  navBus.navigate(
    generatePath(PATH_USERS_BULK_OPERATIONS, {
      orgId: rootStore.organizationStore.activeOrgId,
    })
  );
};

/**
 * @description Return a method to navigate to user group bulk operations details.
 *
 * @param {Object} object
 * @param {String} object.userGroupId - the id of the user group. If undefined, it will link to all user groups
 *
 * @returns {Function} A function that takes in a job id as an argument to determine where to navigate to for the job result
 */
const goToUserGroupBulkOpsDetailsFn =
  ({userGroupId} = {}) =>
  ({jobId}) => {
    const path = userGroupId
      ? PATH_USER_GROUP_DETAILS_RESULTS
      : PATH_USER_GROUPS_BULK_OPERATIONS_RESULTS;
    navBus.navigate(
      generatePath(path, {
        jobId,
        orgId: rootStore.organizationStore.activeOrgId,
        userGroupId,
      })
    );
  };

/**
 * @description Navigate to the users bulk operation license deficit report.
 *
 * @param {Object} object
 * @param {String} jobId - id of the job
 */
const goToUsersBulkOpsResults = ({jobId}) =>
  navBus.navigate(getHrefForUsersBulkOpsDetails({jobId}));

/**
 * @description Navigate to the users page and open the bulk operation
 *   modal with mode=BULK_OPERATION_MODE.SWITCH_IDENTITY_TYPE.
 *   If the admin does not have access to this operation the route loader will
 *   throw a no access error.
 */
const goToUsersSwitchIdentityType = () => {
  navBus.navigate(
    generatePath(PATH_USERS_BULK_OPS_MODAL_MODE, {
      mode: 'switch-identity-type', // BULK_OPERATION_MODE.SWITCH_IDENTITY_TYPE,
      orgId: rootStore.organizationStore.activeOrgId,
    })
  );
};

/**
 * @description Navigate to Users or Admins page.
 * @deprecated - there should be a seperate method for 'Users' and 'Admins'.
 *
 * @param {Object} object
 * @param {String} object.targetType - can be either 'USER' or 'ADMIN.
 */
const goToAllUsers = ({targetType}) => {
  if (targetType === 'USER') {
    goToUsers();
  } else {
    goToAdministrators();
  }
};

/**
 * @description Replace the current url in the browser history with the url for
 *   the 'Users' page.
 */
const replaceStateUsers = () => {
  navBus.replaceState({url: getHrefForUsers()});
};

export {
  getHrefForAdminDetails,
  getHrefForDeveloperDetails,
  getHrefForDirectoryUserBulkOpsDetailsFn,
  getHrefForDirectoryUserDetails,
  getHrefForDirectoryUsers,
  getHrefForUserDetails,
  getHrefForUserGroupBulkOpsDetailsFn,
  getHrefForUserGroupDetails,
  getHrefForUserGroupLicenseDeficitFn,
  getHrefForUsersBulkOpsDetails,
  getHrefForUsersLicenseDeficit,
  getHrefForUsers,
  goToAdminDetails,
  goToAdministrators,
  goToAllUsers,
  goToDevelopers,
  goToDirectoryList,
  goToDirectoryUsers,
  goToDirectoryUserBulkOps,
  goToDirectoryUserBulkOpsDetailsFn,
  goToDirectoryUserDetails,
  goToUserDetails,
  goToUsers,
  goToUserGroups,
  goToUserGroupDetails,
  goToUserGroupBulkOperations,
  goToUserGroupBulkOpsDetailsFn,
  goToUserGroupDetailsJobs,
  goToUsersBulkOperations,
  goToUsersBulkOpsDetails,
  goToUsersBulkOpsResults,
  goToUsersSwitchIdentityType,
  replaceStateUsers,
};
/* eslint-enable max-lines -- many routes to cover */
