import {MemberList, jilProducts} from '@admin-tribe/acsc';

import ComplianceStatusUser from 'core/products/compliance-status-user/ComplianceStatusUser';

class ProductPaymentStatusUserList extends MemberList {
  /**
   * @description Method to retrieve an existing product user list of users
   *   that are at risk
   * @param {String} options - options to get the provisioning status user list
   * @param {String} options.orgId - id of org to retrieve user list for
   * @param {String} options.productId - productId to retrieve user list
   * @returns {ProductPaymentStatusUserList} model of existing user list
   */
  static getUsersAtRisk(options) {
    const params = {
      ...options,
      license_quality_type: 'at_risk',
    };
    return this.get(params);
  }

  /**
   * @description Method to retrieve an existing product user list of users
   *   that have no access
   * @param {String} options - options to get the provisioning status user list
   * @param {String} options.orgId - id of org to retrieve user list for
   * @param {String} options.productId - productId to retrieve user list
   * @returns {ProductPaymentStatusUserList} model of existing user list
   */
  static getUsersWithNoAccess(options) {
    const params = {
      ...options,
      license_quality_type: 'need_access',
    };
    return this.get(params);
  }

  /**
   * @description Method to retrieve an existing product user list of users
   *   that needs payment (pending payment and grace past due).
   * @param {String} options - options to get the payment status user list
   * @param {String} options.orgId - id of org to retrieve user list for
   * @param {String} options.productId - id of product to retrieve user list for
   * @returns {ProductPaymentStatusUserList} model of existing user list
   */
  static getNeedPaymentUsers(options) {
    const params = {
      ...options,
      license_quality_type: 'need_payment',
    };
    return this.get(params);
  }

  /**
   * @description Method to retrieve an existing product user list of users
   *   that needs renewal (paid and grace past due).
   * @param {String} options - options to get the payment status user list
   * @param {String} options.orgId - id of org to retrieve user list for
   * @param {String} options.productId - id of product to retrieve user list for
   * @param {String} options.anniversaryDate - anniversary date
   * @returns {ProductPaymentStatusUserList} model of existing user list
   */
  static getNeedRenewalUsers(options) {
    const params = {
      ...options,
      license_quality_type: 'need_renewal',
    };
    return this.get(params);
  }

  /**
   * @description Function for fetching a csv of users that need renewal (paid and grace past due).
   * @param {Object} options - options for fetching user csv
   * @param {String} options.anniversaryDate - anniversary date
   * @param {String} options.orgId - id of org to retrieve user list for
   * @returns {Promise} promise - resolved when the csv is downloaded
   */
  static getNeedRenewalUsersCSV(options) {
    const params = {
      ...options,
      license_end_date: options.anniversaryDate,
      license_pool: 'RENEWING',
      payment_statuses: 'PAID,GRACE_PAST_DUE',
    };
    return new ProductPaymentStatusUserList(params).exportCSV();
  }

  /**
   * @description Function for fetching a csv of users that need payment (pending payment and grace past due).
   * @param {Object} options - options for fetching user csv
   * @param {String} options.orgId - id of org to retrieve user list for
   * @param {String} options.productId - id of product to retrieve user list for
   * @returns {Promise} promise - resolved when the csv is downloaded
   */
  static getNeedPaymentUsersCSV(options) {
    const params = {
      ...options,
      license_pool: 'DEFAULT',
      payment_statuses: 'PENDING_PAYMENT,GRACE_PAST_DUE',
    };
    return new ProductPaymentStatusUserList(params).exportCSV();
  }

  /**
   * @class
   * @description Constructor for ProductPaymentStatusUserList model Objects.
   * @param {Object} options- options passed to the List constructor
   * @param {String} options.license_quality_type - license quality type for the user list
   * @param {String} options.orgId - id of org to retrieve user list for
   * @param {String} options.productId - id of product to retrieve user list for
   */
  constructor(options) {
    super({
      itemClassRef: ComplianceStatusUser,
      modelCacheId: 'ProductPaymentStatusUserList',
      refreshResource: jilProducts.getProductLicenseQualityUsers,
      ...options,
    });
    // Used by exportCSV
    this.params = options;
    this.license_quality_type = options.license_quality_type;
    this.productId = options.productId;
  }

  /**
   * @description Function to export the user csv for a given payment status
   * @param {Object} params - query params for making the call, usually includes paymentStatus, paymentStatus, and licensePool info
   *
   * @returns {Promise} promise - resolved when the csv is downloaded
   */
  exportCSV() {
    return jilProducts.exportProductPaymentStatusUsers(this.params);
  }

  getQueryParams() {
    return {
      ...super.getQueryParams(),
      license_quality_type: this.license_quality_type,
      productId: this.productId,
    };
  }
}

export default ProductPaymentStatusUserList;
