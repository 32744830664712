import {
  showError as showErrorToast,
  showInfo as showInfoToast,
  showSuccess as showSuccessToast,
} from '@admin-tribe/acsc-ui';
import {Button} from '@adobe/react-spectrum';
import {useTableSectionContext} from '@pandora/react-table-section';
import PropTypes from 'prop-types';
import React, {useRef, useState} from 'react';
import {useIntl} from 'react-intl';

import TeamList from 'common/services/team-list/TeamList';
import rootStore from 'core/RootStore';
import DeactivateDevicesModal from 'features/products/components/deactivate-devices-modal/DeactivateDevicesModal';

/**
 * Renders the settings button for product devices page which opens DeactivateDevicesModal.
 */
const DeactivateDevicesButton = ({poolId, updateTableChange}) => {
  const {tableSectionState, tableSectionUtils} = useTableSectionContext();
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const items = tableSectionState.selectedItems;
  const count = items.length;
  const intl = useIntl();
  const openerButton = useRef(null);
  const teamListObj = new TeamList();
  const selectedDevices = items.map((item) => item.deviceName);

  const onDeactivate = async () => {
    showInfoToast(intl.formatMessage({id: 'products.details.devices.deactivateDeviceProgress'}));

    const deviceIds = tableSectionState.selectedItemKeys;
    const orgId = rootStore.organizationStore.activeOrgId;

    try {
      setLoading(true);
      await teamListObj.deactivateDevices(deviceIds, orgId, poolId);
      setShowModal(false);
      setLoading(false);
      tableSectionUtils.modifyTableItems(deviceIds);
      updateTableChange();
      showSuccessToast();
    } catch {
      showErrorToast();
    } finally {
      setLoading(false);
      setShowModal(false);
    }
  };

  return (
    <>
      <Button
        ref={openerButton}
        data-testid="deactivate-devices-button"
        onPress={() => {
          setShowModal(true);
        }}
        variant="negative"
      >
        {intl.formatMessage({id: 'products.details.devices.deactivateDevices.button'}, {count})}
      </Button>
      <DeactivateDevicesModal
        isLoading={isLoading}
        isOpen={showModal}
        onCancel={() => {
          openerButton.current.focus();
          setShowModal(false);
        }}
        onDeactivate={onDeactivate}
        selectedCount={count}
        selectedDevices={selectedDevices}
      />
    </>
  );
};

DeactivateDevicesButton.propTypes = {
  /** Identifies the pool that this device belongs to. */
  poolId: PropTypes.string.isRequired,
  /** Reload page and update header section when device table is updated */
  updateTableChange: PropTypes.func.isRequired,
};
export default DeactivateDevicesButton;
