import {feature} from '@admin-tribe/acsc';
import {
  Avatar,
  NavigationAnchor,
  SyncIconActionButton,
  TableIconNameCell,
  TableSectionTable,
  UserGroupShareInfo,
  ViewDetailsDrawerTrigger,
} from '@admin-tribe/acsc-ui';
import {Flex, Link} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {useIntl} from 'react-intl';

import UsersDetailsDrawer from 'common/components/users-details-drawer/UsersDetailsDrawer';
import {getHrefForUserGroupDetails} from 'features/users/routing/navigation-callbacks/navigationCallbacks';

const UserGroupListTable = observer(() => {
  const intl = useIntl();

  const syncIconActionButtonTooltip = feature.isEnabled(
    'temp_domains_externally_managed_account_label'
  )
    ? intl.formatMessage({id: 'settings.domainsTable.externallyManagedByAccountLabel'})
    : undefined;

  const columnDescriptor = [
    {
      key: 'name',
      props: {allowsSorting: true},
    },
    {
      key: 'viewDetails',
      props: {hideHeader: true, showDivider: true},
    },
    {
      key: 'userCount',
      props: {align: 'end'},
    },
    {
      key: 'adminCount',
      props: {align: 'end'},
    },
  ];

  // The render function will be called with an object which contains the 'item' which is the UserGroup
  // and the 'key'.
  const renderers = {
    adminCount: getFormattedNumber,
    name: getName,
    userCount: getFormattedNumber,
    viewDetails: getViewDetailsDrawerTrigger,
  };

  function getName({item}) {
    return (
      <TableIconNameCell IconComponent={<Avatar member={item} />}>
        <Flex alignItems="center">
          <Link UNSAFE_style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
            <NavigationAnchor href={getHrefForUserGroupDetails({userGroupId: item.id})}>
              {item.name}
            </NavigationAnchor>
          </Link>
          {item.externallyManaged && (
            <SyncIconActionButton tooltipText={syncIconActionButtonTooltip} />
          )}
          {item.isShared() && <UserGroupShareInfo member={item} placement="end" size="S" />}
        </Flex>
      </TableIconNameCell>
    );
  }

  function getFormattedNumber({key, item}) {
    return intl.formatNumber(item[key]);
  }

  function getViewDetailsDrawerTrigger({item}) {
    return (
      <ViewDetailsDrawerTrigger tooltipName={item.name}>
        {() => <UsersDetailsDrawer member={item} />}
      </ViewDetailsDrawerTrigger>
    );
  }

  return (
    <TableSectionTable
      aria-label={intl.formatMessage({id: 'users.usersGroups.table.ariaLabel'})}
      columnDescriptor={columnDescriptor}
      columnNamespace="users.usersGroups.table.column"
      noItemsFoundContentMessage={intl.formatMessage({
        id: 'users.usersGroups.table.noItemsFoundContentMessage',
      })}
      noSearchResultsContentMessage={intl.formatMessage({
        id: 'users.usersGroups.table.noSearchResultsContentMessage',
      })}
      renderers={renderers}
    />
  );
});

UserGroupListTable.displayName = 'UserGroupListTable';

export default UserGroupListTable;
