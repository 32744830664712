import {log} from '@admin-tribe/acsc';

import rootStore from 'core/RootStore';
import PackagesAdobeProducts from 'features/packages/services/PackagesAdobeProductsService';
import PackagesAdobeProductsUi from 'features/packages/services/PackagesAdobeProductsUiService';
import PackagesEntitlements from 'features/packages/services/PackagesEntitlementsService';
import PackagesExtensions from 'features/packages/services/PackagesExtensionsService';
import PackagesLanServers from 'features/packages/services/PackagesLanServersService';
import PackagesPreferences from 'features/packages/services/PackagesPreferencesService';

/**
 * @description A helper function that fetch packages dependencies for AllPackageStore.fetchData()
 */
const fetchDataHelper = async () => {
  // Fetching All Packages at ones from backend to perform local pagination and search
  // Fetch in this order:
  // 1. PackagesUiConstants
  // 2. PackagesPreferences
  // 3. PackagesEntitlements
  // 4. PackagesAdobeProducts
  // 5. PackagesAdobeProductsUi
  // 6. PackagesLanServers
  // 7. AdminPackages
  try {
    await rootStore.packagesUiConstantsStore.fetchUiConstants();
    await PackagesPreferences.fetchUserPreferences();
    await PackagesEntitlements.fetchEntitlements();
    if (
      PackagesEntitlements.hasCcEntitlement ||
      PackagesEntitlements.hasDcEntitlement ||
      PackagesEntitlements.hasSubstanceNamedEntitlement
    ) {
      await PackagesExtensions.initApis();
    }

    await PackagesAdobeProducts.fetchAndProcessAdobeProducts();
    await PackagesAdobeProductsUi.fetchAndProcessAdobeProducts();
    if (PackagesEntitlements.hasFrlLanEntitlement) {
      await PackagesLanServers.fetchAndProcessLanServers();
    }
  } catch (error) {
    log.error(error);
  }
};

// eslint-disable-next-line import/prefer-default-export -- utils file
export {fetchDataHelper};
