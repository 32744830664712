import {CLOUD} from '@pandora/administration-core-types';
import {Contract} from '@pandora/data-model-contract';
import {JilModelList} from '@pandora/data-model-list';
import {CoveoAnalyticsMetadataProvider} from '@pandora/react-coveo-search';
import {SupportTicketWrapper} from '@pandora/react-miniapp-support-ticket';
import {ModalContainer} from '@pandora/react-modal-dialog';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useCookies} from 'react-cookie';

import rootStore from 'core/RootStore';

import {SUPPORT_CONSTANTS} from '../../support.constants';
import {GetProductListItems} from '../../utils/GetProductListItems';

const {SUPPORT_CASE_CATEGORY_MAP, SUPPORT_TICKET_RECORD_TYPE} = SUPPORT_CONSTANTS;

const {organizationStore} = rootStore;

/**
 * @description A wrapper for SupportTicketWrapper in pandora library
 */
const SupportTicketMiniappWrapper = ({cloudType, onCancel, onCreate, recordType}) => {
  const orgCookieIdentifier = `AMCV_${organizationStore.activeOrgId}`;
  const [showModal, setShowModal] = useState(true);
  const [cookies] = useCookies([orgCookieIdentifier]);

  const contractList = new JilModelList({
    items: organizationStore.contractList.items.map((contract) => new Contract(contract)),
  });

  const productList = new JilModelList({
    itemCount: organizationStore.productList.pagination.itemCount,
    items: GetProductListItems(organizationStore.productList.items),
  });

  const getECID = React.useCallback(() => {
    let clean = decodeURIComponent(cookies[orgCookieIdentifier]);
    clean = /MCMID\|\d+/.exec(clean);
    const ecid = clean && clean[0] ? clean[0].replace('MCMID|', '') : '';
    return ecid;
  }, [cookies, orgCookieIdentifier]);

  return (
    showModal && (
      <ModalContainer>
        <CoveoAnalyticsMetadataProvider
          value={{
            metadata: {id: getECID()},
          }}
        >
          <SupportTicketWrapper
            contractList={contractList}
            defaultCaseCategory={SUPPORT_CASE_CATEGORY_MAP[cloudType]}
            onCancel={() => {
              onCancel();
              setShowModal(false);
            }}
            onCreate={(id, useCalendly) => {
              onCreate(id, useCalendly);
              setShowModal(!!useCalendly);
            }}
            orgId={organizationStore.activeOrgId}
            productList={productList}
            recordType={recordType}
          />
        </CoveoAnalyticsMetadataProvider>
      </ModalContainer>
    )
  );
};

SupportTicketMiniappWrapper.propTypes = {
  /**
   * prop to get cloud type
   */
  cloudType: PropTypes.oneOf([
    ...Object.keys(CLOUD),
    SUPPORT_CONSTANTS.NAV_MANAGER_CLOUD_KEY.GENERAL,
  ]),
  /**
   * Callback function on cancel
   */
  onCancel: PropTypes.func.isRequired,
  /**
   * Callback function on Create
   */
  onCreate: PropTypes.func.isRequired,
  /**
   * Prop to get recordType
   */
  recordType: PropTypes.oneOf([SUPPORT_TICKET_RECORD_TYPE.CASE, SUPPORT_TICKET_RECORD_TYPE.EXPERT])
    .isRequired,
};

export default SupportTicketMiniappWrapper;
