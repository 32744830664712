import {
  CONSUMABLE_SUMMARIZATION_SUMMARIZE_BY,
  ConsumableSummarizationList,
  LicenseGroup,
  feature,
} from '@admin-tribe/acsc';
import {
  Page,
  PageActions,
  PageBanners,
  PageContent,
  PageHeader,
  PageHeaderTagGroup,
  PageInfoBar,
  getProductDisplayName,
} from '@admin-tribe/acsc-ui';
import {useAsyncModel} from '@pandora/react-async-model';
import {DEVICE_LICENSING_MODES} from '@pandora/react-data-source-device-activation';
import {ImageIcon} from '@pandora/react-image-icon';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import {useLoaderData, useParams} from 'react-router-dom';

import GlobalAdminPolicyBanner from 'common/components/global-admin-policy-banner/GlobalAdminPolicyBanner';
import {ADMIN_CONTEXT} from 'common/components/global-admin-policy-banner/globalAdminBannerConstants';
import useDocumentTitle from 'common/hooks/useDocumentTitle';
import rootStore from 'core/RootStore';
import {getProductTags} from 'core/products/utils/productTagUtils';
import AutoAssignRulesCache from 'core/services/product/AutoAssignRulesCache';
import AutoAssignmentRuleModal from 'features/products/components/auto-assignment-rule-modal/AutoAssignmentRuleModal';
import ProductPageBreadcrumbs from 'features/products/components/product-page-breadcrumbs/ProductPageBreadcrumbs';
import StockInfoBar from 'features/products/components/stock-info-bar/StockInfoBar';
import useTotalDevices from 'features/products/device-licenses/components/hooks/useTotalDevices';
import DeviceLicensesInfoBar from 'features/products/device-licenses/components/info-bar/DeviceLicensesInfoBar';
import {DeviceLicensesPageContextProvider} from 'features/products/device-licenses/components/page-context/DeviceLicensesPageContext';
import {
  getViewHeaderData,
  isActivationAllowed,
} from 'features/products/device-licenses/components/utils/deviceLicensesUtils';
import ProductProfileDetailsPageActions from 'features/products/product-profiles/actions/ProductProfileDetailsPageActions';
import AddProductProfileModal from 'features/products/product-profiles/add-product-profile-modal/AddProductProfileModal';
import ProductProfileDetailsInfoBar from 'features/products/product-profiles/info-bar/ProductProfileDetailsInfoBar';
import {getProductProfileSummaryItems} from 'features/products/product-profiles/info-bar/infoBarUtils';
import ProductProfileDetailsTabContent from 'features/products/product-profiles/tab-content/ProductProfileDetailsTabContent';
import {
  enableAutoAssignmentModal,
  getIcon,
  getProductProfileRule,
} from 'features/products/product-profiles/utils/productProfileUtils';

import {ProductProfileDetailsContextProvider} from './context/ProductProfileDetailsContext';

const HEADER_NAMESPACE = 'products.deviceLicenses';

/**
 * The page for the product profile details.
 */
// eslint-disable-next-line complexity,max-statements -- due to the Page using slots so cannot be broken up further
const ProductProfileDetailsPage = () => {
  const intl = useIntl();
  const [triggerToRefetchDevices, setTriggerToRefetchDevices] = React.useState(false);

  const orgId = rootStore.organizationStore.activeOrgId;
  const {profileId} = useParams();
  const {product} = useLoaderData();
  const productTags = useMemo(() => getProductTags(intl, product), [intl, product]);
  const productName = getProductDisplayName(intl, product);

  const [addProductProfileModalOpen, setAddProductProfileModalOpen] = useState(false);
  const [productProfileRule, setProductProfileRule] = useState(null);
  const [autoAssignmentRuleModalOpen, setAutoAssignmentRuleModalOpen] = useState(false);
  const [isRuleDetailsLoading, setRuleDetailsLoading] = useState(false);

  const initialLoad = useRef(true);

  const fetchLicenseGroup = useCallback(
    () =>
      LicenseGroup.get({
        id: profileId,
        orgId,
        product,
      }),
    [profileId, orgId, product]
  );

  const {
    error: licenseGroupError,
    isLoading: isLicenseGroupLoading,
    model: licenseGroup,
    updateModel: updateLicenseGroup,
  } = useAsyncModel({
    loadFn: fetchLicenseGroup,
  });

  const fetchConsumableData = useCallback(
    () =>
      product.isConsumable()
        ? ConsumableSummarizationList.get({
            group_id: profileId,
            license_id: product.id,
            organization_id: orgId,
            summarize_by: CONSUMABLE_SUMMARIZATION_SUMMARIZE_BY.GROUP_ID,
          })
        : undefined,
    [profileId, product, orgId]
  );

  const {
    error: consumableError,
    isLoading: isConsumableLoading,
    model: consumableSummarizationList,
    updateModel: updateConsumable,
  } = useAsyncModel({
    loadFn: fetchConsumableData,
  });

  const {
    activatedDevices,
    isError: totalDevicesError,
    isLoading: totalDevicesLoading,
    devices,
  } = useTotalDevices({
    licenseGroupId: profileId,
    productId: product.id,
    triggerToRefetchFrlDevices: triggerToRefetchDevices,
    ...(feature.isEnabled('temp_sdl_frl_deactivations') && {
      licensingMode: DEVICE_LICENSING_MODES.NAMED_USER_EDUCATION_LAB,
    }),
  });

  const {totalLicensesCount, usedLicensesCount, totalDevicesCount, earliestExpiryDate} =
    getViewHeaderData({
      activatedDevices,
      devices,
      intl,
      product,
      totalDevicesLoading,
    });

  /* Once the licenseGroup loads, set the product profile rule. This will trigger a scorecard update. */
  useEffect(() => {
    if (licenseGroup && enableAutoAssignmentModal(product)) {
      setProductProfileRule(getProductProfileRule(licenseGroup));
    }
  }, [licenseGroup, product]);

  useDocumentTitle({defaultTitle: licenseGroup?.name});

  const onAddProductProfileModalSave = () => {
    setAddProductProfileModalOpen(false);
    updateLicenseGroup(fetchLicenseGroup);
    updateConsumable(fetchConsumableData);
  };

  const onAutoAssignmentRuleModalSave = async () => {
    setAutoAssignmentRuleModalOpen(false);
    await AutoAssignRulesCache.get().refresh();

    // Once the rules are updated, force infoBarItems to recalculate so PageInfoBar will re-render to
    // update the "Auto assignment rule" scorecard.
    setProductProfileRule(getProductProfileRule(licenseGroup));
  };

  const isBumpered = !!licenseGroupError || !!consumableError || !!totalDevicesError;
  const isLoading = isLicenseGroupLoading || isConsumableLoading || totalDevicesLoading;

  // Content should be shown after the initial load.
  const showContent = initialLoad.current === false || (!isBumpered && !isLoading);

  const deviceCheck = feature.isEnabled('temp_enable_react_sdl')
    ? !product.isDeviceLicense()
    : // The frl-device-activation-details-stage.adobe.io/graphql api called by useTotalDevices
      // is not ready for SDL products so we allow SDL product profiles scorecards to be viewed
      !product.isFeatureRestrictedLicense() && !product.isLegacyDeviceLicense();

  const summaryItems = useMemo(
    () =>
      showContent && !product.isLegacyStock() && deviceCheck
        ? getProductProfileSummaryItems(
            {consumableSummarizationList, licenseGroup, product, productProfileRule},
            intl
          )
        : [],
    [
      consumableSummarizationList,
      deviceCheck,
      intl,
      licenseGroup,
      product,
      productProfileRule,
      showContent,
    ]
  );

  if (!showContent) {
    return (
      <Page isBumpered={isBumpered} isLoading={isLoading}>
        <PageContent />
      </Page>
    );
  }

  initialLoad.current = false;

  return (
    <ProductProfileDetailsContextProvider
      consumableSummarizationList={consumableSummarizationList}
      licenseGroupId={profileId}
      openAutoAssignmentRuleModal={() => setAutoAssignmentRuleModalOpen(true)}
      product={product}
      summaryItems={summaryItems}
    >
      <Page data-testid="product-profile-details-page" isLoading={isRuleDetailsLoading} showContent>
        <ProductPageBreadcrumbs
          isOnProductProfilePage
          licenseGroupName={licenseGroup.name}
          productDisplayName={productName}
          productOrProductGroupId={product.id}
        />

        <PageBanners>
          <GlobalAdminPolicyBanner context={ADMIN_CONTEXT} />
        </PageBanners>

        <PageHeader title={licenseGroup.name}>
          <ImageIcon alt="" size="M" src={getIcon(licenseGroup, product)} />
          <PageHeaderTagGroup productName={productName} tags={productTags} />
        </PageHeader>

        <PageInfoBar>
          {product.isLegacyStock() && <StockInfoBar productProfile={licenseGroup} />}

          {!deviceCheck && (
            <DeviceLicensesInfoBar
              earliestExpiryDate={earliestExpiryDate}
              // showTotalDevices flag is passed as there is conditional logic for FRL devices to display totalDevicesCount only when this flag is true.
              // For SDL devices, the totalDevicesCount will be always displayed
              namespace={HEADER_NAMESPACE}
              showTotalDevices
              totalDevicesCount={totalDevicesCount}
              totalLicensesCount={totalLicensesCount}
              usedLicensesCount={usedLicensesCount}
            />
          )}

          {summaryItems.length > 0 && <ProductProfileDetailsInfoBar />}
        </PageInfoBar>

        <PageActions>
          <ProductProfileDetailsPageActions
            autoAssignModalEnabled={!!productProfileRule}
            consumableSummarizationList={consumableSummarizationList}
            licenseGroup={licenseGroup}
            onDetailsButtonPress={() => setAddProductProfileModalOpen(true)}
            onRecoverLicenses={async () => {
              await updateConsumable(fetchConsumableData);
            }}
          />
        </PageActions>

        <PageContent>
          <DeviceLicensesPageContextProvider
            value={{
              canActivateDevices: isActivationAllowed({
                isOverDeploymentAllowed: product.fulfillableItemList.hasOverdelegationAllowed(),
                totalLicensesCount,
                usedLicensesCount,
              }),
              licenseGroupId: profileId,
              productId: product.id,
              refetchHeaderData: () => setTriggerToRefetchDevices(!triggerToRefetchDevices),
              totalDevicesCount,
              totalLicensesCount,
              usedLicensesCount,
            }}
          >
            <ProductProfileDetailsTabContent licenseGroup={licenseGroup} product={product} />
          </DeviceLicensesPageContextProvider>
        </PageContent>
      </Page>
      <AddProductProfileModal
        isOpen={addProductProfileModalOpen}
        onCancel={() => setAddProductProfileModalOpen(false)}
        onSave={onAddProductProfileModalSave}
        orgId={orgId}
        product={product}
        productProfile={licenseGroup}
      />

      {autoAssignmentRuleModalOpen && (
        <AutoAssignmentRuleModal
          onRuleAssignmentCancel={() => setAutoAssignmentRuleModalOpen(false)}
          onRuleAssignmentSave={onAutoAssignmentRuleModalSave}
          onShowPageLoading={(isRuleLoading) => setRuleDetailsLoading(isRuleLoading)}
          product={product}
          productProfile={licenseGroup}
          rule={productProfileRule}
        />
      )}
    </ProductProfileDetailsContextProvider>
  );
};

export default ProductProfileDetailsPage;
