// eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- unit testing is not needed here as it is constant
/* istanbul ignore next -- unit testing is not needed here as it is constant */
const ANALYTICS_TRACKED_FEATURE_GROUP_FLAGS = [
  'temp_add_product_mini_app',
  'temp_add_product_mini_app_ab_test',
  'temp_add_product_mini_app_all',
  'temp_add_product_mini_app_deep_link',
  'temp_add_product_mini_app_rtp1',
  'temp_add_product_mini_app_us_direct',
  'temp_self_cancel_ab',
  'temp_fake_ab_fg_b', // Remove after fake AB test for self-cancel project
];

// eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- unit testing is not needed here as it is constant
/* istanbul ignore next -- unit testing is not needed here as it is constant */
const CANCEL_REASONS = {
  CHANGE_PLAN: 'CHANGE_PLAN',
  DUPLICATE_LICENSES: 'DUPLICATE_LICENSES',
  NO_LONGER_WITH_COMPANY: 'NO_LONGER_WITH_COMPANY',
  NOT_USING_THE_PRODUCT_ENOUGH: 'NOT_USING_THE_PRODUCT_ENOUGH',
  OTHER: 'OTHER',
  TECHNICAL_DIFFICULTY: 'TECHNICAL_DIFFICULTY',
  TOO_EXPENSIVE: 'TOO_EXPENSIVE',
};

// eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- unit testing is not needed here as it is constant
/* istanbul ignore next -- unit testing is not needed here as it is constant */
const AEP_ANALYTICS_SITE_SECTION = 'adminconsole.adobe.com';

export {AEP_ANALYTICS_SITE_SECTION, ANALYTICS_TRACKED_FEATURE_GROUP_FLAGS, CANCEL_REASONS};
