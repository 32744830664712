import {GoUrl, NoSearchResultsIllustratedMessage} from '@admin-tribe/acsc-ui';
import {Flex, Heading, Text} from '@adobe/react-spectrum';
import {useTableSectionContext} from '@pandora/react-table-section';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import GoToPackagesButton from 'features/products/device-licenses/components/go-to-packages-button/GoToPackagesButton';
import {useDeviceLicensesPageContext} from 'features/products/device-licenses/components/page-context/DeviceLicensesPageContext';

const NAMESPACE = 'products.deviceLicenses.noDevicesView';

/**
 * This component is displayed in the FRL/SDL table, when there are no devices
 */
const NoDevicesView = ({isSdl}) => {
  const {tableSectionState} = useTableSectionContext();
  const {totalDevicesCount} = useDeviceLicensesPageContext();
  const isSearchResult = tableSectionState.filters.length > 0;
  const intl = useIntl();
  const areNoDevices = !totalDevicesCount;
  return areNoDevices ? (
    <Flex alignItems="center" direction="column">
      <Flex alignItems="center" direction="column" marginBottom="size-300">
        <Heading level={2} marginBottom="size-0">
          {intl.formatMessage({id: `${NAMESPACE}.headline`})}
        </Heading>
        <Text>{intl.formatMessage({id: `${NAMESPACE}.subHeadline`})}</Text>
        <GoUrl isDisabled={false} name={isSdl ? 'aac_sdl_deploy_guide' : 'aac_frl_learn_more'}>
          {intl.formatMessage({id: `${NAMESPACE}.goUrl`})}
        </GoUrl>
      </Flex>
      <GoToPackagesButton />
    </Flex>
  ) : (
    <NoSearchResultsIllustratedMessage
      content={
        isSearchResult
          ? '' // trigger default message for no search results
          : ' ' // empty message in case of no results found from filtering
      }
    />
  );
};

NoDevicesView.propTypes = {
  /**
   * isSdl - Flag which indicates if product is sdl or not
   */
  isSdl: PropTypes.bool,
};

export default NoDevicesView;
