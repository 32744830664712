// ***********************************************************************
// ADOBE CONFIDENTIAL
// ___________________
//
// Copyright 2024 Adobe
// All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains
// the property of Adobe and its suppliers, if any. The intellectual
// and technical concepts contained herein are proprietary to Adobe
// and its suppliers and are protected by all applicable intellectual
// property laws, including trade secret and copyright laws.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Adobe.
// ************************************************************************
import {View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';

import SophiaPromoBannerView from 'common/components/sophia/sophia-promo/SophiaPromoBannerView';
import SOPHIA_CONSTANTS from 'common/services/sophia/SophiaConstants';
import sophiaHelper from 'common/services/sophia/sophiaHelper';
import {mapPromoContent} from 'common/services/sophia/utils/utils';

const RenderBannerView = ({dismissView, content}) => (
  <SophiaPromoBannerView onDismiss={dismissView} sophiaContent={content} />
);

RenderBannerView.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object]),
  dismissView: PropTypes.func,
};

const SophiaProductsBannerSection = () => {
  const [sophiaContent, setSophiaContent] = React.useState(null);
  const [isDismissed, setIsDismissed] = React.useState(false);
  const surfaceId = SOPHIA_CONSTANTS.SURFACE_ID.ONE_CONSOLE_PRODUCTS_BANNER;

  // Fetches sophia cards on mount
  useEffect(() => {
    const fetchSophiaContent = async () => {
      const content = await sophiaHelper.getSophiaContent({
        surfaceId,
      });
      setSophiaContent(content);
    };

    fetchSophiaContent();
  }, [surfaceId]);

  // render nothing if there are no cards, or the list fails to fetch
  if (!sophiaContent?.items?.[0]?.content) {
    return null;
  }

  const content = mapPromoContent(JSON.parse(sophiaContent.items[0].content));
  const dismissView = content.dismissible ? () => setIsDismissed(true) : null;

  return (
    <View data-testid="sophia-products-banner-section" isHidden={isDismissed}>
      <RenderBannerView content={content} dismissView={dismissView} />
    </View>
  );
};

SophiaProductsBannerSection.propTypes = {
  /**
   * Responsive props instructing merchandising section how to show/hide at
   * various display sizes. Optional, default is to show all the time
   */
  isHidden: PropTypes.shape({
    base: PropTypes.bool,
    L: PropTypes.bool,
    M: PropTypes.bool,
    S: PropTypes.bool,
  }),
};

export default SophiaProductsBannerSection;
