import {configStore, csvBlobTransformer, getHeaders} from '@admin-tribe/acsc';
import axios from 'axios';

let clientId, includeRoles, url;

(async function loadJilConfig() {
  ({url, clientId, includeRoles} = await configStore.getServiceConfiguration('jil'));
  url = `${url}/v2/organizations`;
})();

/**
 * @description Fetches the asset migrations
 *
 * @param {Object} options - The params
 * @param {String} options.orgId - The org id
 * @returns {Promise} Resolves to the axios response object.
 */
const exportMigrations = async ({orgId}) => {
  const response = await axios.get(`${url}/${orgId}:migrate.assets`, {
    headers: {
      Accept: 'text/csv,application/json',
      ...getHeaders({clientId, includeRoles}),
    },
  });
  return csvBlobTransformer(response);
};

const jilAssetMigrations = {
  exportMigrations,
};

export default jilAssetMigrations;
