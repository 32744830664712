// ***********************************************************************
// ADOBE CONFIDENTIAL
// ___________________
//
// Copyright 2024 Adobe
// All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains
// the property of Adobe and its suppliers, if any. The intellectual
// and technical concepts contained herein are proprietary to Adobe
// and its suppliers and are protected by all applicable intellectual
// property laws, including trade secret and copyright laws.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Adobe.
// ************************************************************************
import {Flex, View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';

import {useJsonPayload} from 'common/components/sophia/sophia-promo/JsonPayloadProvider';
import SophiaPromoActions from 'common/components/sophia/sophia-promo/sophia-promo-actions/SophiaPromoActions';
import SophiaPromoCloseButton from 'common/components/sophia/sophia-promo/sophia-promo-close-button/SophiaPromoCloseButton';
import SophiaPromoDescription from 'common/components/sophia/sophia-promo/sophia-promo-description/SophiaPromoDescription';
import SophiaPromoIcon from 'common/components/sophia/sophia-promo/sophia-promo-icon/SophiaPromoIcon';
import SophiaPromoBasePrice from 'common/components/sophia/sophia-promo/sophia-promo-prices/SophiaPromoBasePrice';
import SophiaPromoDiscountedPrice from 'common/components/sophia/sophia-promo/sophia-promo-prices/SophiaPromoDiscountedPrice';
import SophiaPromoTag from 'common/components/sophia/sophia-promo/sophia-promo-tag/SophiaPromoTag';
import SophiaPromoTermsAndConditionsDialogTrigger from 'common/components/sophia/sophia-promo/sophia-promo-terms-and-conditions/SophiaPromoTermsAndConditionsDialogTrigger';
import SophiaPromoTermsAndConditionsHoverOverTooltip from 'common/components/sophia/sophia-promo/sophia-promo-terms-and-conditions/SophiaPromoTermsAndConditionsHoverOverTooltip';
import SophiaPromoTitle from 'common/components/sophia/sophia-promo/sophia-promo-title/SophiaPromoTitle';

const SophiaPromoBannerPanel = ({onDismiss}) => {
  const {payload} = useJsonPayload();

  // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- disabled for resolving merge conflicts
  // istanbul ignore next
  if (!payload) {
    return null;
  }

  return (
    <View
      borderColor="gray-300"
      borderRadius="regular"
      borderWidth="thin"
      data-testid="one-console-promo-banner"
      padding="size-250"
      position="relative"
      UNSAFE_style={{
        backgroundColor: payload.backgroundColor,
        boxShadow: '0 0.1875rem 0.375rem rgba(0,0,0,.08)',
      }}
      width="100%"
    >
      <Flex
        alignItems="center"
        direction="row"
        gap="size-300"
        justifyContent="space-between"
        marginTop="-10px"
        wrap
      >
        <Flex width="65%">
          <View
            data-testid="one-console-promo-banner-header"
            marginEnd="25px"
            marginStart="15px"
            marginTop="35px"
          >
            <SophiaPromoIcon />
          </View>
          <Flex
            alignItems="start"
            data-testid="one-console-promo-banner-content"
            direction="column"
          >
            <View paddingBottom="15px">
              <SophiaPromoTag mode="banner" />
            </View>
            <SophiaPromoTitle />
            <SophiaPromoDescription>
              <SophiaPromoTermsAndConditionsDialogTrigger />
              <SophiaPromoTermsAndConditionsHoverOverTooltip mode="banner" />
            </SophiaPromoDescription>
          </Flex>
        </Flex>

        <Flex
          alignItems="end"
          data-testid="one-console-promo-banner-price"
          direction="row"
          gap={{base: 'size-150', L: 'size-150', S: 'size-150'}}
          justifyContent={{M: 'space-between'}}
          marginTop="15px"
          wrap
        >
          <Flex alignItems="end" direction="column" marginEnd="15px">
            <SophiaPromoBasePrice />
            <SophiaPromoDiscountedPrice />
          </Flex>
          <View data-testid="one-console-promo-banner-actions" marginBottom="10px" marginEnd="20px">
            <SophiaPromoActions />
          </View>
        </Flex>
        <SophiaPromoCloseButton
          data-testid="one-console-promo-banner-close"
          onDismiss={onDismiss}
        />
      </Flex>
    </View>
  );
};

SophiaPromoBannerPanel.propTypes = {
  onDismiss: PropTypes.func,
};

export default SophiaPromoBannerPanel;
