import {authentication, configStore} from '@admin-tribe/acsc';
import axios from 'axios';

let environment, url;

(async function loadConfig() {
  ({env: environment, url} = await configStore.getServiceConfiguration('ingest'));
})();

// ///////// API Utility methods ////////////

function getHeaderObject() {
  return {
    Authorization: authentication.getAccessToken().token,
    'x-api-key': 'aconsole-web-service',
  };
}

/**
 * Configures the package ingest service with the required parameters.
 *
 * @returns {String} environment
 *
 */
function getIngestEnvironment() {
  return environment;
}

// ///////// Resources ////////////
/**
 * Configures the package ingest service with the required parameters.
 *
 * @param {Object} analyticsObject analyticsObject for package ingest service.
 * @returns {Promise} resolved with response
 *
 */
function sendAnalytics(analyticsObject) {
  return axios.post(url, analyticsObject, {
    headers: getHeaderObject(),
  });
}

export {getIngestEnvironment, sendAnalytics};
