import {showError, showSuccess} from '@admin-tribe/acsc-ui';
import {useCreateRule} from '@pandora/data-model-acrs';
import PropTypes from 'prop-types';
import React, {useCallback, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';

import JitRuleAutoCreateContext from './JitRuleAutoCreateContext';
import {generateCreateRequestBody, getProduct, useLicenseGroups} from './jitRuleAutoCreateUtils';

/**
 * Context provider for auto create widget
 *
 * The component is responsible for fetching data, defining state, handling
 * creation of the rule.
 */
const JitRuleAutoCreateProvider = ({children, closeModal, widgetData}) => {
  const intl = useIntl();
  const {productArrangementCode} = widgetData;
  const [notificationsEnabled, setNotificationsEnabled] = useState(false);
  const product = useMemo(() => getProduct(productArrangementCode), [productArrangementCode]);

  const {
    model: licenseGroupList,
    isLoading: isLoadingLicenseGroups,
    error: errorLicenseGroups,
  } = useLicenseGroups(product);

  const {
    error: errorCreateRule,
    mutate: createRule,
    isLoading: isLoadingCreation,
    data: newRule,
  } = useCreateRule({
    orgId: rootStore.organizationStore.activeOrgId,
  });

  const onSubmit = useCallback(() => {
    createRule(generateCreateRequestBody(product, licenseGroupList, notificationsEnabled));
  }, [createRule, licenseGroupList, notificationsEnabled, product]);

  const closeModalIfNeeded = useCallback(() => {
    if (!product || errorLicenseGroups || errorCreateRule || newRule) {
      closeModal();
    }
    if (errorCreateRule) {
      showError(
        intl.formatMessage(
          {id: 'common.sophia.sophiaModal.widgets.jitRuleAutoCreate.errorToast'},
          {productName: product.shortName}
        )
      );
    } else if (newRule) {
      showSuccess(
        intl.formatMessage(
          {id: 'common.sophia.sophiaModal.widgets.jitRuleAutoCreate.successToast'},
          {productName: product.shortName}
        )
      );
    }
  }, [closeModal, errorCreateRule, errorLicenseGroups, intl, newRule, product]);
  closeModalIfNeeded();

  const isLoading = isLoadingLicenseGroups || isLoadingCreation;

  const contextValue = useMemo(
    () => ({
      closeModal,
      isLoading,
      notificationsEnabled,
      onSubmit,
      productName: product?.shortName,
      setNotificationsEnabled,
    }),
    [closeModal, isLoading, notificationsEnabled, onSubmit, product?.shortName]
  );
  return (
    <JitRuleAutoCreateContext.Provider value={contextValue}>
      {children}
    </JitRuleAutoCreateContext.Provider>
  );
};

JitRuleAutoCreateProvider.propTypes = {
  /**
   * children provided by parent to render under this component
   */
  children: PropTypes.node.isRequired,

  /**
   * callback to close the modal
   */
  closeModal: PropTypes.func.isRequired,

  /**
   * options provided for the JIT rule widget
   */
  widgetData: PropTypes.shape({
    /**
     * product arrangement code for which to search for an applicable JIT rule
     */
    productArrangementCode: PropTypes.string.isRequired,
  }).isRequired,
};

export default JitRuleAutoCreateProvider;
