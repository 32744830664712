import {feature} from '@admin-tribe/acsc';
import {useTabbedNavigation} from '@admin-tribe/acsc-ui';
import {Item, TabList, TabPanels, Tabs, View} from '@adobe/react-spectrum';
import React from 'react';
import {useIntl} from 'react-intl';
import {generatePath, useParams} from 'react-router-dom';

import useDirectoryList from 'common/hooks/api/useDirectoryList';
import {useTrusteeListContext} from 'features/settings/common/components/trustee-list-context/TrusteeListContext';
import TrusteeListTable from 'features/settings/common/components/trustee-list-table/TrusteeListTable';
import DomainEnforcementWrapper from 'features/settings/components/directory/domain-enforcement/DomainEnforcementWrapper';
import DirectoryDomainsTable2 from 'features/settings/components/directory/domains/directory-domains-table/DirectoryDomainsTable2';
import DirectoryOverview from 'features/settings/components/directory/overview/DirectoryOverview';
import DirectorySync from 'features/settings/components/directory/sync/DirectorySync';
import DirectoryAuthentication from 'features/settings/components/directory-authentication/DirectoryAuthentication';
import useDomainList from 'features/settings/hooks/api/useDomainList';
import {useDirectoryDetailsContext} from 'features/settings/pages/directory-details-page/DirectoryDetailsContext';
import {
  DIRECTORY_DETAILS_TAB_NAV,
  PATH_DIRECTORY_DETAILS,
} from 'features/settings/routing/settingsPaths';

// This saves the state of the Trust tab so that if it's been shown once
//  we always show it until the user refreshes the page
let shouldShowTrusteesTab = false;

const DIRECTORY_DETAILS_TAB_DEFINITIONS = [
  {
    content: ({directory}) => (
      <View paddingTop="size-250">
        <DirectoryOverview
          directoryId={directory.id}
          domainEnforcement={directory.domainEnforcement}
        />
      </View>
    ),
    default: true,
    isAvailable: (directory) => directory.isType3,
    key: DIRECTORY_DETAILS_TAB_NAV.OVERVIEW,
    labelKey: `settings.directoryDetails.tabs.${DIRECTORY_DETAILS_TAB_NAV.OVERVIEW}`,
    order: 0,
  },
  {
    // eslint-disable-next-line func-name-matching -- we do this to have a name for the rendered component in snapshot
    content: function DirectoryAuthenticationTab({
      clearDirectoryCache,
      directory,
      reloadDirectory,
    }) {
      return (
        <View paddingTop="size-250">
          <DirectoryAuthentication
            authSource={{...directory}}
            directoryId={directory.id}
            refreshData={() => {
              clearDirectoryCache();
              reloadDirectory();
            }}
          />
        </View>
      );
    },
    isAvailable: (directory) => directory.isType3,
    key: DIRECTORY_DETAILS_TAB_NAV.AUTHENTICATION,
    labelKey: `settings.directoryDetails.tabs.${DIRECTORY_DETAILS_TAB_NAV.AUTHENTICATION}`,
    order: 1,
  },
  {
    content: () => <DirectoryDomainsTable2 />,
    isAvailable: (directory) => directory.isType3 || directory.isType2,
    key: DIRECTORY_DETAILS_TAB_NAV.DOMAINS,
    labelKey: `settings.directoryDetails.tabs.${DIRECTORY_DETAILS_TAB_NAV.DOMAINS}`,
    order: 2,
  },
  {
    content: ({directory, reloadDirectory}) => (
      <View paddingTop="size-250">
        <DomainEnforcementWrapper
          domainEnforcement={directory.domainEnforcement}
          refreshDirectory={reloadDirectory}
        />
      </View>
    ),
    isAvailable: (directory) =>
      feature.isEnabled('temp_domain_enforcement') &&
      directory.domainEnforcement !== undefined &&
      (directory.isType3 || directory.isType2),
    key: DIRECTORY_DETAILS_TAB_NAV.DOMAIN_ENFORCEMENT,
    labelKey: `settings.directoryDetails.tabs.${DIRECTORY_DETAILS_TAB_NAV.DOMAIN_ENFORCEMENT}`,
    order: 3,
  },
  {
    content: ({directory, clearDomainsCache, clearDirectoryCache}) => (
      <View paddingTop="size-250">
        <DirectorySync
          clearCaches={() => {
            clearDomainsCache();
            clearDirectoryCache();
          }}
          directoryId={directory.id}
        />
      </View>
    ),
    isAvailable: (directory) => directory.isType3,
    key: DIRECTORY_DETAILS_TAB_NAV.SYNC,
    labelKey: `settings.directoryDetails.tabs.${DIRECTORY_DETAILS_TAB_NAV.SYNC}`,
    order: 4,
  },
  {
    content: () => <TrusteeListTable />,
    isAvailable: () => shouldShowTrusteesTab,
    key: DIRECTORY_DETAILS_TAB_NAV.TRUSTEES,
    labelKey: `settings.directoryDetails.tabs.${DIRECTORY_DETAILS_TAB_NAV.TRUSTEES}`,
    order: 5,
  },
];

const DirectoryDetailsPageTabs = () => {
  const {directory, reloadDirectory} = useDirectoryDetailsContext();
  const {
    state: {trusteeListData: trusts},
  } = useTrusteeListContext();
  const intl = useIntl();
  const params = useParams();

  const {clearDirectoryCache} = useDirectoryList();
  const {clearDomainsCache} = useDomainList();

  if (trusts.items.length > 0 && !shouldShowTrusteesTab) {
    shouldShowTrusteesTab = true;
  }

  const tabs = DIRECTORY_DETAILS_TAB_DEFINITIONS
    // filter unavailable tabs
    .filter((tab) => tab.isAvailable(directory, trusts.items))
    // sort by their order
    .sort((a, b) => a.order - b.order)
    .map((tab) => ({
      ...tab,
      pathname: generatePath(`${PATH_DIRECTORY_DETAILS}/${tab.key}`, params),
    }));

  const {onTabChange, selectedTabKey} = useTabbedNavigation(tabs);

  return (
    <Tabs items={tabs} onSelectionChange={onTabChange} selectedKey={selectedTabKey}>
      <TabList>
        {(item) => <Item key={item.key}>{intl.formatMessage({id: item.labelKey})}</Item>}
      </TabList>
      <TabPanels>
        {(item) => (
          <Item key={item.key}>
            <item.content
              clearDirectoryCache={clearDirectoryCache}
              clearDomainsCache={clearDomainsCache}
              directory={directory}
              reloadDirectory={reloadDirectory}
            />
          </Item>
        )}
      </TabPanels>
    </Tabs>
  );
};

export default DirectoryDetailsPageTabs;
