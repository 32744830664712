import {
  DIRECTORY_STATUS,
  DIRECTORY_TYPE,
  IDP_STATUS,
  ORGANIZATION_MARKET_SEGMENT,
  feature,
} from '@admin-tribe/acsc';

import eduRosterSync from 'features/settings/api/eduRosterSync';

/**
 * Checks if the directory is configured.
 * @param {Object} directory - The directory object to check.
 * @returns {boolean} Returns true if the directory is configured, false otherwise.
 */
function isConfigured(directory) {
  if (directory.status !== DIRECTORY_STATUS.ACTIVE) {
    return false;
  }
  if (isType3(directory)) {
    const defaultIdpId = directory.tenantInfo?.imsTenantInfo?.defaultIdp;
    const idpList =
      directory.tenantInfo?.imsTenantInfo?.idps.length > 0
        ? directory.tenantInfo.imsTenantInfo.idps
        : [];
    const defaultIdp = idpList.find((idp) => idp.id === defaultIdpId);
    return defaultIdp?.status === IDP_STATUS.ACTIVE;
  }
  return true;
}

/**
 * Checks if the given directory is of type 3.
 *
 * @param {Object} directory - The directory object to check.
 * @returns {boolean} - Returns true if the directory is of type 3, otherwise false.
 */
function isType3(directory) {
  return directory.type === DIRECTORY_TYPE.TYPE3;
}

/**
 * Converts a given date to a timestamp.
 *
 * @param {Date} date - The date to convert to a timestamp.
 * @returns {number|undefined} - The timestamp value if the date is provided, otherwise undefined.
 */
const getTimestamp = (date) => (date ? new Date(date).getTime() : undefined);

/**
 * Retrieves an array of unique values from a list of objects based on a specified property.
 *
 * @param {Array} list - The list of objects.
 * @param {string} property - The property to extract values from.
 * @returns {Array} - An array of unique values from the specified property.
 */
const getPropertyValues = (list, property) => {
  const propertyValues = list
    .map((item) => item[property]) // Map to the specified property
    .filter((value) => value !== null && value !== undefined) // Remove null and undefined values
    .reduce((acc, curr) => {
      // Use reduce to create an array of unique values
      if (!acc.includes(curr)) {
        acc.push(curr);
      }
      return acc;
    }, []);

  return propertyValues;
};

/**
 * Retrieves the education syncs for the active organization.
 * @param {Object} activeOrg - The active organization object.
 * @returns {Promise<Object>} - A promise that resolves to the education sync data.
 */
async function getEduSyncs(activeOrg) {
  const eduSync = isEDUIdPEligible(activeOrg)
    ? await eduRosterSync.getOrgRosterSyncs()
    : {data: {count: 0, syncConfigs: []}};
  return eduSync;
}

/**
 * Checks if the active organization is eligible for EDU IDP.
 *
 * @param {Object} activeOrg - The active organization object.
 * @returns {boolean} - Returns true if the organization is eligible for EDU IDP, otherwise false.
 */
function isEDUIdPEligible(activeOrg) {
  return (
    (activeOrg.marketSegment !== ORGANIZATION_MARKET_SEGMENT.GOVERNMENT &&
      activeOrg.marketSegment !== ORGANIZATION_MARKET_SEGMENT.COMMERCIAL) ||
    feature.isEnabled('force_edu_market_segment')
  );
}

export {isConfigured, isType3, getTimestamp, getPropertyValues, getEduSyncs, isEDUIdPEligible};
