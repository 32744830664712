import {
  CONSUMABLE_SUMMARIZATION_SUMMARIZE_BY,
  ConsumableSummarizationList,
} from '@admin-tribe/acsc';
import {
  DetailsButton,
  OverlayWait,
  Page,
  PageActions,
  PageBanners,
  PageContent,
  PageHeader,
  PageHeaderTagGroup,
  PageInfoBar,
  getProductDisplayName,
} from '@admin-tribe/acsc-ui';
import {useAsyncModel} from '@pandora/react-async-model';
import {ImageIcon} from '@pandora/react-image-icon';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {useParams} from 'react-router-dom';

import useDocumentTitle from 'common/hooks/useDocumentTitle';
import {getProductTags} from 'core/products/utils/productTagUtils';
import FiProductGroupProductList from 'core/services/product/product-group/product-list/FiProductGroupProductList';
import ProductGroupBanners from 'features/products/components/banners/ProductGroupBanners';
import ConsumablesListManager from 'features/products/components/consumables-list-manager/ConsumablesListManager';
import ProductGroupPageInfoBar from 'features/products/components/product-group-page-info-bar/ProductGroupPageInfoBar';
import ProductGroupsDetailsDrawer from 'features/products/components/product-groups-details-drawer/ProductGroupsDetailsDrawer';
import ProductPageBreadcrumbs from 'features/products/components/product-page-breadcrumbs/ProductPageBreadcrumbs';

/**
 * Page for grouped org consumables which are represented by a FiProductGroupProductList.
 *
 * Note: Currently the only product in this category is 'Stock credit packs' and
 * the messaging is specific to this product.
 */
const OrgConsumablesPage = ({productGroup}) => {
  const {orgId} = useParams();
  const intl = useIntl();
  const productTags = useMemo(() => getProductTags(intl, productGroup), [intl, productGroup]);

  const fetchConsumable = useCallback(async () => {
    const summarizationList = await ConsumableSummarizationList.get({
      include_usage: false,
      organization_id: orgId,
      summarize_by: CONSUMABLE_SUMMARIZATION_SUMMARIZE_BY.ORGANIZATION_ID,
    });
    // First item returned will be summary consumable, so grab head and return
    const [consumablesSummary] = summarizationList.getConsumablesForFulfillableItemCode(
      productGroup.id
    );
    // Note: consumablesSummary may be undefined
    return consumablesSummary;
  }, [orgId, productGroup.id]);

  // consumable may be undefined if attempting to get summary info for credit
  // packs after they have all expired (known Stock team API bug); child
  // components need to account for this and handle appropriately
  const {model: consumable, isLoading, error} = useAsyncModel({loadFn: fetchConsumable});

  const fetchUsageList = useCallback(() => {
    if (!consumable) {
      return undefined;
    }
    const {consumableUsageList} = consumable;
    consumableUsageList.sortBy('expiration_date');
    return consumableUsageList.refresh();
  }, [consumable]);
  const {
    model: usageList,
    isLoading: isLoadingUsage,
    error: errorUsage,
  } = useAsyncModel({loadFn: fetchUsageList});

  const [isUsageListLoaded, setIsUsageListLoaded] = useState(!isLoading && !isLoadingUsage);
  // ensure that the usage list is properly loaded
  useEffect(() => {
    setIsUsageListLoaded(!isLoading && !isLoadingUsage);
  }, [isLoading, isLoadingUsage]);

  useDocumentTitle({title: getProductDisplayName(intl, productGroup)});

  const productDisplayName = getProductDisplayName(intl, productGroup);

  return (
    <Page data-testid="org-consumables-page" isBumpered={!!error} isLoading={isLoading} showContent>
      <ProductPageBreadcrumbs
        isOnProductGroupPage
        productDisplayName={productDisplayName}
        productOrProductGroupId={productGroup.id}
      />

      <PageBanners>
        <ProductGroupBanners consumable={consumable} productGroup={productGroup} />
      </PageBanners>

      <PageHeader title={productDisplayName}>
        <ImageIcon alt="" src={productGroup.getIcon()} />
        <PageHeaderTagGroup productName={productDisplayName} tags={productTags} />
      </PageHeader>

      <PageInfoBar>
        <ProductGroupPageInfoBar consumable={consumable} productGroup={productGroup} />
      </PageInfoBar>

      <PageActions>
        <DetailsButton detailsDrawer={<ProductGroupsDetailsDrawer productGroup={productGroup} />} />
      </PageActions>
      <PageContent>
        <OverlayWait isLoading={isLoadingUsage} showContent={isUsageListLoaded} size="M">
          {isUsageListLoaded && (
            <ConsumablesListManager
              errorUsage={errorUsage}
              productGroup={productGroup}
              usageList={usageList}
            />
          )}
        </OverlayWait>
      </PageContent>
    </Page>
  );
};

OrgConsumablesPage.propTypes = {
  /**
   * The FiProductGroupList for the grouped org consumables.
   */
  productGroup: PropTypes.instanceOf(FiProductGroupProductList).isRequired,
};

export default OrgConsumablesPage;
