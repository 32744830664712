/* eslint-disable no-restricted-imports, @admin-tribe/admin-tribe/check-browser-globals -- using window obj*/
import {storePersistentData} from '@admin-tribe/acsc';
import moment from 'moment';

if (window.isBrowserSupported) {
  document.addEventListener('DOMContentLoaded', async () => {
    const {mockUserId} = storePersistentData(['mockUserId']);
    if (mockUserId) {
      window.moment = moment;
      const {bootstrapFakes} = await import(
        /* webpackChunkName: "fakes" */ '../fakes/FakesBootstrap'
      );
      await bootstrapFakes();
    }
  });
}
/* eslint-enable no-restricted-imports, @admin-tribe/admin-tribe/check-browser-globals -- using window obj*/
