import {ModalContent, PageBanner} from '@admin-tribe/acsc-ui';
import {Button, Flex, Heading, Text} from '@adobe/react-spectrum';
import {GoUrl} from '@pandora/react-go-url';
import SpectrumV2Provider from '@react/react-spectrum/Provider';
import LockOpen from '@spectrum-icons/workflow/LockOpen';
import UserGroupIcon from '@spectrum-icons/workflow/UserGroup';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {goToIdentitySettings} from 'features/settings/routing/navigation-callbacks/navigationCallbacks';
import {goToUserGroups} from 'features/users/routing/navigation-callbacks/navigationCallbacks';
/**
 * TeamToEnterpriseContractMigrationSummary Component
 *
 * This component renders a summary for the team to enterprise contract migration process.
 * It displays a banner indicating the success or failure of the migration.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.showSuccessBanner - Flag to determine if the success banner should be shown.
 *
 * @returns {JSX.Element} The rendered component.
 */
const TeamToEnterpriseContractMigrationSummary = ({onCancel, showSuccessBanner}) => {
  const intl = useIntl();
  const getString = (suffix) =>
    intl.formatMessage({id: `migrations.teamToEnterpriseMigrationModal.${suffix}`});

  const renderInfoBlock = (IconComponent, descriptionKey) => (
    <Flex alignItems="center" direction="column" flex gap="size-100" width="size-2400">
      <IconComponent size="L" />
      <Text UNSAFE_style={{textAlign: 'center'}}>{getString(descriptionKey)}</Text>
      {(IconComponent === LockOpen && (
        <>
          <GoUrl name="ac_identity">{getString('processingPanel.learnMore')}</GoUrl>
          <Button
            onPress={() => {
              onCancel();
              goToIdentitySettings();
            }}
            variant="accent"
          >
            {getString('processingPanel.setupIdentityButton')}
          </Button>
        </>
      )) ||
        (IconComponent === UserGroupIcon && (
          <>
            <GoUrl name="aac_learn_enterprise_groups">
              {getString('processingPanel.learnMore')}
            </GoUrl>
            <Button
              onPress={() => {
                onCancel();
                goToUserGroups();
              }}
              variant="accent"
            >
              {getString('processingPanel.manageUsersButton')}
            </Button>
          </>
        ))}
    </Flex>
  );

  return (
    <ModalContent>
      <SpectrumV2Provider>
        <PageBanner
          header={
            showSuccessBanner
              ? getString('processingPanel.successHeader')
              : getString('processingPanel.errorHeader')
          }
          showDefaultButton={false}
          variant={showSuccessBanner ? 'success' : 'error'}
        >
          {showSuccessBanner
            ? getString('processingPanel.successBannerBody2')
            : getString('processingPanel.errorBannerBody')}
        </PageBanner>
      </SpectrumV2Provider>

      <Flex alignItems="center" direction="column" gap="size-250">
        <Heading level={3}>{getString('processingPanel.heading')}</Heading>
        <Flex direction="row" gap="size-400">
          {renderInfoBlock(LockOpen, 'processingPanel.setupIdentityDescription')}
          {renderInfoBlock(UserGroupIcon, 'processingPanel.manageUsersDescription')}
        </Flex>
      </Flex>
    </ModalContent>
  );
};

TeamToEnterpriseContractMigrationSummary.propTypes = {
  onCancel: PropTypes.func.isRequired,
  showSuccessBanner: PropTypes.bool.isRequired,
};

export default TeamToEnterpriseContractMigrationSummary;
