import {GoUrl} from '@admin-tribe/acsc-ui';
import {Checkbox, View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Checkbox along with GoUrl link component wrapped in one component
 */
const CheckboxWithLink = ({isSelected, name, label, linkLabel, onChange}) => (
  <View>
    <Checkbox isEmphasized isSelected={isSelected} onChange={onChange}>
      {label}
    </Checkbox>
    <View marginStart="size-300" width="size-900">
      <GoUrl name={name} variant="primary">
        {linkLabel}
      </GoUrl>
    </View>
  </View>
);

CheckboxWithLink.propTypes = {
  /**
   * Boolean value that tells if checkbox is ticked/not
   */
  isSelected: PropTypes.bool.isRequired,
  /**
   * String that labels the checkbox
   */
  label: PropTypes.string.isRequired,
  /**
   * Visible string that labels the link
   */
  linkLabel: PropTypes.string.isRequired,
  /**
   * The name of the go-url
   */
  name: PropTypes.string.isRequired,
  /**
   * Function called when checkbox value(ticked/unticked) changes
   */
  onChange: PropTypes.func.isRequired,
};
export default CheckboxWithLink;
