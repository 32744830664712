import {
  CONTRACT_MIGRATION_TYPE,
  getTeamToEnterpriseMigrationEligibleContract,
} from '@admin-tribe/acsc';

import rootStore from 'core/RootStore';
import OrganizationContractMigrationList from 'features/contract/models/OrganizationContractMigrationList';

/**
 * @description Get the eligible contract for team to enterprise migration and its associated migration list.
 *
 * @returns {Promise} Resolves with {contract, contractMigrationList, isEligible} if eligible migration contract exists.
 * Otherwise, resolves with isEligible flag set to false.
 */
const getOrganizationContractMigration = async () => {
  const contractList = rootStore.organizationStore.contractList;
  const contract = getTeamToEnterpriseMigrationEligibleContract(contractList);

  if (!contract) {
    return {isEligible: false};
  }

  const contractMigrationList = await OrganizationContractMigrationList.get({
    contractId: contract.id,
    type: CONTRACT_MIGRATION_TYPE.TEAM_VIP_TO_TEAM_EVIP,
  });

  return {
    contract,
    contractMigrationList,
    isEligible: true,
  };
};

export default getOrganizationContractMigration;
