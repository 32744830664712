import {
  CONSUMABLE_SUMMARIZATION_LIST_CACHE_ID,
  jilOrganizationsProductsLicenseGroups,
  modelCache,
} from '@admin-tribe/acsc';
import {showError, showSuccess} from '@admin-tribe/acsc-ui';
import {AlertDialog, Button, DialogTrigger} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';

/**
 * Delete when temp_enable_react_sdl is removed.
 * Shows the recover licenses button which opens the "Recover licenses confirmation modal".
 */
const RecoverLicensesButton = ({onRecoverLicenses, licenseGroupId, productId}) => {
  const intl = useIntl();

  const recoverLicense = async () => {
    try {
      await jilOrganizationsProductsLicenseGroups.deleteLicenseActivations({
        licenseGroupId,
        orgId: rootStore.organizationStore.activeOrgId,
        productId,
      });

      modelCache.clear(CONSUMABLE_SUMMARIZATION_LIST_CACHE_ID);
      await onRecoverLicenses();

      showSuccess(intl.formatMessage({id: 'products.profile.details.licenseRecovery.saveSuccess'}));
    } catch (error) {
      showError(intl.formatMessage({id: 'products.profile.details.licenseRecovery.recoveryError'}));
    }
  };

  return (
    <DialogTrigger>
      <Button data-test-id="recover-licenses" variant="secondary">
        {intl.formatMessage({id: 'products.profiles.recover.licenses.buttonLabel'})}
      </Button>
      <AlertDialog
        onPrimaryAction={recoverLicense}
        primaryActionLabel={intl.formatMessage({
          id: 'products.profiles.recoverLicensesModal.recover',
        })}
        secondaryActionLabel={intl.formatMessage({
          id: 'products.requests.enabledBanner.disableModal.cancel',
        })}
        title={intl.formatMessage({id: 'products.profiles.recoverLicensesModal.title'})}
        variant="confirmation"
      >
        {intl.formatMessage({id: 'products.profiles.recoverLicensesModal.content'})}
      </AlertDialog>
    </DialogTrigger>
  );
};

RecoverLicensesButton.propTypes = {
  // The license group id
  licenseGroupId: PropTypes.string.isRequired,
  // The callback to call when the licenses are recovered
  onRecoverLicenses: PropTypes.func.isRequired,
  // The product id
  productId: PropTypes.string.isRequired,
};
export default RecoverLicensesButton;
