import {feature} from '@admin-tribe/acsc';
import groupBy from 'lodash/groupBy';
import intersection from 'lodash/intersection';

import {CREATE_PACKAGE_CONSTANTS} from 'features/packages/packagesConstants';
import PackagesExtensions from 'features/packages/services/PackagesExtensionsService';

/**
 * @description Fetches plugins and updates with selected plugins based on searchText
 * @param {Array} compatibleAppList - compatible app list
 * @param {String} searchText - text submitted from plugin search field
 * @param {Object} packageCreateObj - Shared object in package creation flow
 * @param {Array} selectedPlugins - Plugins selected in the screen
 *
 * @return {Promise<Array>} Fetched Plugins List updated with selected plugins based on searchText
 */
const fetchPlugins = async ({compatibleAppList, searchText, packageCreateObj, selectedPlugins}) => {
  const plugins = await PackagesExtensions.fetchAndGetExtensions(
    compatibleAppList,
    [],
    searchText,
    true,
    packageCreateObj.platform,
    packageCreateObj.bits
  );

  // Filter only downloadable plugins from the fetched plugins
  const downloadablePlugins = plugins.filter((plugin) => plugin.isDownloadable);
  // Remove all plugins selected by matching with version id of selected plugins
  const nonSelectedDownloadablePlugins = downloadablePlugins.filter(
    (downloadablePlugin) =>
      !selectedPlugins.find(
        (selectedPlugin) => selectedPlugin.versionId === downloadablePlugin.versionId
      )
  );
  // Add all selected plugins
  const allPlugins = [...nonSelectedDownloadablePlugins, ...selectedPlugins];
  if (searchText) {
    // show only those plugins that came as part of the fetch call (search result) and are downloadable
    return allPlugins.filter(
      (plugin) =>
        !!downloadablePlugins.find((downloadablePlugin) => downloadablePlugin.id === plugin.id)
    );
  }
  // when search text is empty, show the fetch call result and previously selected plugins
  return allPlugins;
};

/**
 * @description Get Applicable Selected Product List
 * @param {Array} selectedProducts - Products selected in choose apps screen
 *
 * @return {Array} Applicable Selected Product List
 */
const getApplicableSelectedProductList = (selectedProducts) => {
  // Filter only visible products that do not have KCCC sap code
  // KCCC corresponds to creative cloud desktop app, it does not have any plugins of its own
  // and so, should be excluded from applicableSelectedProductList
  let applicableSelectedProductList = selectedProducts.filter(
    (product) => product.visible && product.sapCode !== 'KCCC'
  );

  // CCES doesn't supports compatibility filtering with more that 45 apps
  // so in that case just filter using latest versions of each app.
  if (applicableSelectedProductList.length > 45) {
    applicableSelectedProductList = Object.values(
      groupBy(applicableSelectedProductList, 'sapCode')
    ).map((appsBySapCode) =>
      appsBySapCode.reduce((prev, current) => (current.version > prev.version ? current : prev), {
        version: '',
      })
    );
  }
  return applicableSelectedProductList;
};

/**
 * @description Returns Updated Plugin List
 * @param {Array} allPlugins - all plugins list
 * @param {Object} plugin - current plugin
 *
 * @return {Array} Updated Plugin List
 */
const getUpdatedPlugins = (allPlugins, plugin) =>
  // Replace the plugin of the allplugins list with current plugin
  allPlugins.map((loopPlugin) => (loopPlugin.id === plugin.id ? plugin : loopPlugin));

/**
 * @description Returns Updated Selected Plugin List
 * @param {Object} plugin - current plugin
 * @param {Array} selectedPlugins - previously selected plugins
 *
 * @return {Array} Updated Selected Plugin List
 */
const getUpdatedSelectedPlugins = (plugin, selectedPlugins) => {
  if (plugin.selected) {
    return [...selectedPlugins, plugin];
  }
  return selectedPlugins.filter(({versionId}) => plugin.versionId !== versionId);
};

/**
 * @description Evaluates if the platform has plugin support
 * @param {String} platform - platform selected in System Config Screen
 *
 * @return {Boolean} if the platform has plugin support
 */
function hasNoPluginSupport(platform, isCfPackage) {
  return (
    (platform === CREATE_PACKAGE_CONSTANTS.WIN_ARM_PLATFORM &&
      feature.isDisabled('packages_extensions_winarm')) ||
    isCfPackage
  );
}

/**
 * @description Checks if incompatible plugins selected
 * @param {Array} applicableSelectedProductList - applicable selected products list
 * @param {Array} selectedPlugins - Plugins selected in the screen screen
 *
 * @return {Boolean} Applicable Selected Product List
 */
function haveIncompatiblePluginsBeenSelected(applicableSelectedProductList, selectedPlugins) {
  // if no products selected set value to false
  if (applicableSelectedProductList.length === 0) {
    return false;
  }
  const selectedProductsSapCode = applicableSelectedProductList.map((product) => product.sapCode);
  // If in any of the selected plugins, its supported product sap codes do not have intersection with
  // selected products sap codes, then return false else true
  return selectedPlugins.some((selectedPlugin) => {
    const selectedPluginsSupportedSapCode = selectedPlugin.supportedProducts.map(
      (supportedProduct) => supportedProduct.sapCode
    );
    // If products not supported by the plugin, return false
    return intersection(selectedProductsSapCode, selectedPluginsSupportedSapCode).length === 0;
  });
}

/**
 * @description When only compatible plugins to be shown, return the applicableSelectedProductList
 * else, empty product list should be returned(as incompatible plugins can be shown as well)
 * @param {Array} applicableSelectedProductList - applicable selected products list
 * @param {Boolean} showCompatiblePlugins - if only compatible plugins have to be shown
 *
 * @return {Array} compatible app list
 */
const getCompatibleAppList = (applicableSelectedProductList, showCompatiblePlugins) =>
  showCompatiblePlugins ? applicableSelectedProductList : [];

/**
 * @description Checks if plugin selection should be disabled. Disable selection when the current plugin is not selected and max plugins have been selected.
 * @param {Boolean} areMaxPluginsSelected - have max plugins been selected
 * @param {Object} plugin - current plugin
 *
 * @return {Boolean} Returns if if plugin selection should be disabled
 */
const isPluginSelectionDisabled = (areMaxPluginsSelected, plugin) =>
  !plugin.selected && areMaxPluginsSelected;

/**
 * @description Checks if error section should be shown. If error on initial load or if the platforn does not support plugins return true, else return false
 * @param {Boolean} hasErrorOnInitialLoad - has error on initial load
 * @param {Sring} platform - platform selected in System Config Screen
 *
 * @return {Boolean} Returns if error section should be shown
 */
const showErrorSection = (hasErrorOnInitialLoad, platform, isCfPackage) =>
  hasErrorOnInitialLoad || hasNoPluginSupport(platform, isCfPackage);

/**
 * @description Checks if filter api error section should be shown. If error in filter api call and api call is no longer in progress return true, else return false
 * @param {Boolean} hasErrorInFilterApi - has error on filter api call
 * @param {Boolean} isFilterApiInProgress - is filter api call in progress
 *
 * @return {Boolean} Returns if filter api error section should be shown
 */
const showFilterApiErrorSection = (hasErrorInFilterApi, isFilterApiInProgress) =>
  !isFilterApiInProgress && hasErrorInFilterApi;

export {
  fetchPlugins,
  getApplicableSelectedProductList,
  getCompatibleAppList,
  getUpdatedPlugins,
  getUpdatedSelectedPlugins,
  hasNoPluginSupport,
  haveIncompatiblePluginsBeenSelected,
  isPluginSelectionDisabled,
  showErrorSection,
  showFilterApiErrorSection,
};
