import {ViewDetailsDrawerTrigger} from '@admin-tribe/acsc-ui';
import PropTypes from 'prop-types';
import React from 'react';

import TrustedDirectoryDetailsDrawer from 'features/settings/components/directories/directory-list/directory-list-table/trusted-directory-details-drawer/TrustedDirectoryDetailsDrawer';

const TrustedDirectoryDrawerTrigger = ({directory}) =>
  directory.isTrusted ? (
    <ViewDetailsDrawerTrigger tooltipName={directory.directoryName}>
      {(close) => <TrustedDirectoryDetailsDrawer close={close} directory={directory} />}
    </ViewDetailsDrawerTrigger>
  ) : null;

TrustedDirectoryDrawerTrigger.propTypes = {
  directory: PropTypes.shape({
    directoryName: PropTypes.string,
    isTrusted: PropTypes.bool,
  }).isRequired,
  domainEnforcement: PropTypes.shape({
    /**
     * The ID of the Directory
     */
    authSrc: PropTypes.string.isRequired,
    /**
     * Date when opt-out phase ends
     */
    notifyAt: PropTypes.number,
    /**
     * Current status of the Policy
     */
    state: PropTypes.string.isRequired,
    /**
     *  Date when the current state phase ends
     */
    stateEndsAt: PropTypes.number,
  }),
};

export default TrustedDirectoryDrawerTrigger;
