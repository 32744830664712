import {
  Locale,
  OrganizationAdminList,
  dispatchUiEventAnalytics,
  eventBus,
  feature,
  log,
} from '@admin-tribe/acsc';
import {showError as showErrorToast, showSuccess as showSuccessToast} from '@admin-tribe/acsc-ui';
import {withAEMContent} from '@pandora/react-aem-content-provider';
import {AccountInfo} from '@pandora/react-contract-account-info';
import {ContractMiniAppContentModel} from '@pandora/react-mini-app-contract';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';
import ChangeContractOwnerModal from 'features/account/change-contract-owner-modal/ChangeContractOwnerModal';
import ChangeResellerModal from 'features/account/change-reseller-modal/ChangeResellerModal';
import {CART_EVENT} from 'features/offers/freeOfferCartConstants';

import {
  CHANGE_RESELLER_ERROR_CODE,
  CHANGE_RESELLER_ERROR_MESSAGE_KEY,
} from './ContractInfoMiniAppWrapper.constants';
import styles from './ContractInfoMiniAppWrapper.pcss';

/**
 * @description Represents for Contract Info Mini App
 *
 * @param {String} contractId - Contract ID
 */
const ContractInfoMiniAppWrapper = ({contractId}) => {
  const intl = useIntl();

  // Show or close the Change Contract Owner modal dialog based on user action
  const [showChangeContractOwnerModal, setShowChangeContractOwnerModal] = useState(false);

  // Launch Change Contract Owner modal dialog
  const onChangeContractOwnerClick = () => {
    dispatchUiEventAnalytics({
      eventAction: 'click',
      eventName: 'changeContractOwner',
    });
    setShowChangeContractOwnerModal(true);
  };

  // Show success toast and then Close Change Contract Owner modal dialog
  const onChangeContractOwnerSubmit = () => {
    showSuccessToast();
    setShowChangeContractOwnerModal(false);
  };

  // Close Change Contract Owner modal dialog directly
  const onChangeContractOwnerCancel = () => {
    setShowChangeContractOwnerModal(false);
  };

  const handleKeyDown = useCallback(
    (event) => {
      // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- Ignoring else as the onCancel should close only if modal is opened
      /* istanbul ignore else  */
      if (
        (event.code === 'Escape' || event.key === 'Escape' || event.keyCode === 27) &&
        showChangeContractOwnerModal &&
        feature.isEnabled('temp_esc_key_close_change_contract_owner')
      ) {
        onChangeContractOwnerCancel();
      }
    },
    [showChangeContractOwnerModal]
  );

  // The Change Contract Modal doesn't gets closed on ESC key press which is default behaviour.
  // This happens because the modal is not opened or closed instead it is conditionally rendered.
  // So Adding Keydown Event Listener to handle ESC key to close the change contract modal in ONESIE instead of mini-apps.
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  // Change Contract Owner modal dialog
  const ChangeContractOwner = useCallback(
    () =>
      showChangeContractOwnerModal && (
        <ChangeContractOwnerModal
          contractId={contractId}
          onCancel={onChangeContractOwnerCancel}
          onSubmit={onChangeContractOwnerSubmit}
        />
      ),
    [contractId, showChangeContractOwnerModal]
  );

  // Revoke Change Contract Owner callback
  const onRevokeChangeContractOwner = (result) => {
    // Reporting analytics for Opening the modal as there is no other place right now.
    dispatchUiEventAnalytics({
      eventAction: 'open',
      eventName: 'revokeContractOwnerInvitationModalId',
    });

    // Also reporting the event of submitting this action.
    dispatchUiEventAnalytics({
      eventAction: 'submit',
      eventName: 'revokeContractOwnerInvitation',
    });

    // No result means the operation is done without error
    if (result === undefined) {
      showSuccessToast();
    } else {
      showErrorToast();
    }
  };

  // Show or close the Change Reseller modal dialog based on user action
  const [showChangeResellerModal, setShowChangeResellerModal] = useState(false);

  // Launch Change Reseller modal dialog
  const onChangeResellerClick = () => {
    dispatchUiEventAnalytics({
      eventAction: 'display',
      eventName: 'changeResellerModal',
    });

    setShowChangeResellerModal(true);
  };

  // Change reseller by change code and close Change Reseller modal dialog
  const onChangeResellerSubmit = useCallback(
    async (resellerChangeCode) => {
      dispatchUiEventAnalytics({
        eventAction: 'click',
        eventName: 'changeResellerModalSuccess',
      });
      try {
        const contractList = rootStore.organizationStore.contractList;
        const contract = contractList.items.find((item) => item.id === contractId);
        await contract?.updateReseller({resellerChangeCode});
        dispatchUiEventAnalytics({
          eventAction: 'api',
          eventName: 'changeResellerModalSuccess',
        });
      } catch (error) {
        dispatchUiEventAnalytics({
          eventAction: 'api',
          eventName: 'changeResellerModalError',
        });
        let errorKey;
        if (error.errorCode === CHANGE_RESELLER_ERROR_CODE.INVALID) {
          errorKey = CHANGE_RESELLER_ERROR_MESSAGE_KEY.INVALID;
        } else if (error.errorCode === CHANGE_RESELLER_ERROR_CODE.EXPIRED) {
          errorKey = CHANGE_RESELLER_ERROR_MESSAGE_KEY.EXPIRED;
        } else {
          errorKey = CHANGE_RESELLER_ERROR_MESSAGE_KEY.GENERIC;
        }

        showErrorToast(
          intl.formatMessage({
            id: `common.contracts.changeContractResellerErrorToastBody.${errorKey}`,
          })
        );

        log.error('Failed to change reseller', error);
      }

      setShowChangeResellerModal(false);
    },
    [contractId, intl]
  );

  const onRefresh = () => {
    // Refresh the account page to reflect the changes
    eventBus.emit(CART_EVENT.SUBMIT, this);
  };

  // Close Change Reseller modal dialog directly
  const onChangeResellerCancel = () => {
    dispatchUiEventAnalytics({
      eventAction: 'click',
      eventName: 'changeResellerModalCancel',
    });
    setShowChangeResellerModal(false);
  };

  // Change Reseller modal dialog
  const ChangeReseller = React.useCallback(
    () => (
      <ChangeResellerModal
        isOpen={showChangeResellerModal}
        onCancel={onChangeResellerCancel}
        onSubmit={onChangeResellerSubmit}
      />
    ),
    [onChangeResellerSubmit, showChangeResellerModal]
  );

  // Get contract for the given contract ID
  const getContract = feature.isEnabled('temp_account_data_optimization')
    ? () => {
        const contractList = rootStore.organizationStore.contractList;
        return contractList.items.find((item) => item.id === contractId);
      }
    : null;

  // Get admins for the given contract ID
  const getAdmins = feature.isEnabled('temp_account_data_optimization')
    ? async () => {
        const admins = await OrganizationAdminList.get({
          includeAllAdminRoles: true,
          orgId: rootStore.organizationStore.activeOrgId,
        });

        return admins.items;
      }
    : null;

  const AccountInfoWithContent = withAEMContent(
    'businesstrials/account-contract-mini-app',
    'reactMiniAppAccountContractV1Main',
    ContractMiniAppContentModel
  )(AccountInfo);

  // Main component
  return (
    <div className={styles['contract-info-mini-app']}>
      <AccountInfoWithContent
        aem={{locale: Locale.get().activeLanguageBCP47Code, usePlaceholderContent: true}}
        contractId={contractId}
        data={{getAdmins, getContract}}
        onChangeContractOwnerClick={onChangeContractOwnerClick}
        onChangeResellerClick={onChangeResellerClick}
        onRefresh={onRefresh}
        onRevokeChangeContractOwner={onRevokeChangeContractOwner}
        orgId={rootStore.organizationStore.activeOrgId}
        showErrorToast={showErrorToast}
        showSuccessToast={showSuccessToast}
      />
      <ChangeContractOwner />
      <ChangeReseller />
    </div>
  );
};

ContractInfoMiniAppWrapper.propTypes = {
  /**
   * Contract ID
   */
  contractId: PropTypes.string,
};

export default ContractInfoMiniAppWrapper;
