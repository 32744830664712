/**
 * @module QuickLinkCohortUtils
 * Provides utility functions to determine the status of cohorts based on various conditions such as available licenses,
 * pending requests, and user introductions. It also offers functionality to generate button arrays based on the cohort status.
 */

import {dispatchUiEventAnalytics} from '@admin-tribe/acsc';

import {
  getIntroductionsData,
  getNumberOfPendingProductRequests,
} from 'common/services/sophia/shared-contextual-params/sharedContextualParamsUtils';
import rootStore from 'core/RootStore';
import {isAllowedToAddProducts} from 'core/organizations/access/organizationAccess';
import {
  canUseAddProductMiniApp,
  canViewProductAccessRequests,
} from 'core/products/access/productAccess';
import {canViewUserIntroductions} from 'core/user/access/userAccess';

/**
 * Enum for cohort status values.
 * @readonly
 * @enum {string}
 */
const CohortStatus = {
  ASSIGN_LICENSES_NEXT: 'ASSIGN_LICENSES_NEXT',
  BUY_LICENSES_NEXT: 'BUY_LICENSES_NEXT',
  CONSOLIDATE_AND_REVIEW_NEXT: 'CONSOLIDATE_AND_REVIEW_NEXT',
  CONSOLIDATE_TEAM_NEXT: 'CONSOLIDATE_TEAM_NEXT',
  REVIEW_REQUESTS_NEXT: 'REVIEW_REQUESTS_NEXT',
};

/**
 * Enum for button keys.
 * @readonly
 * @enum {string}
 */
const ButtonKeys = {
  ACCOUNT_AND_BILLING: 'ACCOUNT_AND_BILLING',
  ADD_USERS: 'ADD_USERS',
  ASSIGN_LICENSES: 'ASSIGN_LICENSES',
  BUY_LICENSES: 'BUY_LICENSES',
  CONSOLIDATE_TEAM: 'CONSOLIDATE_TEAM',
  MANAGE_PRODUCTS: 'MANAGE_PRODUCTS',
  REVIEW_REQUESTS: 'REVIEW_REQUESTS',
};

/** Maximum number of pending introductions allowed for a user. */
const MAX_PENDING_INTRODUCTIONS = 10;

/**
 * Checks if there are available licenses, and the user is allowed to add products,
 * and can use the Add Product Mini App.
 * @returns {boolean} True if there are available licenses, false otherwise.
 */
const hasAvailableLicenses = () => {
  if (!isAllowedToAddProducts() || !canUseAddProductMiniApp()) return false;

  const products = rootStore.organizationStore.productList.items;
  return products.some((product) => !product.hasNoAvailableLicenses());
};

/**
 * Asynchronously determines if there are user introductions that need attention.
 * @async
 * @returns {Promise<boolean>} True if there are introductions that need attention, false otherwise.
 */
const hasUserIntroductions = async () => {
  if (!canViewUserIntroductions()) return false;

  const {ignoredIntroductionsCount, pendingIntroductionsCount} = await getIntroductionsData();

  return (
    ignoredIntroductionsCount > 0 ||
    (pendingIntroductionsCount > 0 && pendingIntroductionsCount <= MAX_PENDING_INTRODUCTIONS)
  );
};

/**
 * Asynchronously checks if there are pending product requests.
 * @async
 * @returns {Promise<boolean>} True if there are pending product requests, false otherwise.
 */
const hasPendingRequests = async () => {
  if (!canViewProductAccessRequests()) return false;

  const numberOfPendingProductRequests = await getNumberOfPendingProductRequests();

  return numberOfPendingProductRequests > 0;
};

/**
 * Determines the current cohort status based on available licenses, pending requests, and user introductions.
 * @async
 * @returns {Promise<CohortStatus>} The current cohort status.
 */
const getCohortStatus = async () => {
  const availableLicenses = hasAvailableLicenses();
  const pendingRequests = await hasPendingRequests();
  const userIntroductions = await hasUserIntroductions();

  if (pendingRequests && userIntroductions) return CohortStatus.CONSOLIDATE_AND_REVIEW_NEXT;
  if (pendingRequests) return CohortStatus.REVIEW_REQUESTS_NEXT;
  if (userIntroductions) return CohortStatus.CONSOLIDATE_TEAM_NEXT;
  if (availableLicenses) return CohortStatus.ASSIGN_LICENSES_NEXT;
  return CohortStatus.BUY_LICENSES_NEXT;
};

/**
 * Generates an array of button keys based on the given cohort status.
 * @param {CohortStatus} cohortStatus - The current cohort status.
 * @returns {ButtonKeys[]} An array of button keys appropriate for the given cohort status.
 */
const getButtonArray = (cohortStatus) => {
  dispatchUiEventAnalytics({
    eventAction: 'display',
    eventName: 'CTIR22282:CohortStatus',
    interaction: {
      impression: cohortStatus,
    },
  });

  switch (cohortStatus) {
    case CohortStatus.CONSOLIDATE_TEAM_NEXT:
      return [
        ButtonKeys.CONSOLIDATE_TEAM,
        ButtonKeys.MANAGE_PRODUCTS,
        ButtonKeys.BUY_LICENSES,
        ButtonKeys.ACCOUNT_AND_BILLING,
      ];
    case CohortStatus.REVIEW_REQUESTS_NEXT:
      return [
        ButtonKeys.REVIEW_REQUESTS,
        ButtonKeys.MANAGE_PRODUCTS,
        ButtonKeys.BUY_LICENSES,
        ButtonKeys.ACCOUNT_AND_BILLING,
      ];
    case CohortStatus.CONSOLIDATE_AND_REVIEW_NEXT:
      return [
        ButtonKeys.CONSOLIDATE_TEAM,
        ButtonKeys.MANAGE_PRODUCTS,
        ButtonKeys.ACCOUNT_AND_BILLING,
      ];
    case CohortStatus.ASSIGN_LICENSES_NEXT:
      return [
        ButtonKeys.ASSIGN_LICENSES,
        ButtonKeys.BUY_LICENSES,
        ButtonKeys.ADD_USERS,
        ButtonKeys.ACCOUNT_AND_BILLING,
      ];
    case CohortStatus.BUY_LICENSES_NEXT:
    default:
      return [
        ButtonKeys.BUY_LICENSES,
        ButtonKeys.MANAGE_PRODUCTS,
        ButtonKeys.ADD_USERS,
        ButtonKeys.ACCOUNT_AND_BILLING,
      ];
  }
};

export {
  CohortStatus,
  ButtonKeys,
  getCohortStatus,
  getButtonArray,
  hasAvailableLicenses,
  hasUserIntroductions,
  hasPendingRequests,
};
