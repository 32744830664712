import {JIL_CONSTANTS, Store, jilDirectories} from '@admin-tribe/acsc';
import {TableConstants, TableStore} from '@admin-tribe/acsc-ui';
import {action, computed, makeObservable, runInAction} from 'mobx';

import DomainEntity from 'common/entities/DomainEntity';
import rootStore from 'core/RootStore';

const DOMAIN_SETUP_STATUSES = {
  COMPLETED: 'COMPLETED',
  IN_PROGRESS: 'IN_PROGRESS',
  UNCONFIGURED: 'UNCONFIGURED',
};

class DirectoryDomainsStore extends Store {
  tableStore;

  constructor(directoryId) {
    super();

    makeObservable(this, {
      goToNextPage: action.bound,
      goToPageNumber: action,
      goToPreviousPage: action.bound,
      hasActiveDomains: computed,
      onPageSizeChange: action.bound,
      search: action.bound,
      setupStatus: computed,
      sortBy: action.bound,
    });

    this.directoryId = directoryId;
    this.tableStore = new TableStore({
      sortDescriptor: {
        column: 'domainName',
        direction: TableConstants.SORT_DIRECTION.ASCENDING,
      },
    });
  }

  async fetchData() {
    // TODO: Add cache layer
    const response = await jilDirectories.getDomains({
      directoryId: this.directoryId,
      orgId: rootStore.organizationStore.activeOrgId,
      page: this.tableStore.currentPage - 1,
      page_size: this.tableStore.pageSize,
      search_query: this.tableStore.searchQuery,
      sort: this.tableStore.sortDescriptor.column,
      sort_order: this.tableStore.isSortDescending
        ? JIL_CONSTANTS.ORDER.DESC
        : JIL_CONSTANTS.ORDER.ASC,
    });

    const domains = response.data.map((domain) => new DomainEntity(domain));

    runInAction(() => {
      this.tableStore.mapDataToRows(domains, {
        // DirectoryDomains are not selectable (yet)
        isSelectableFn: () => false,
      });

      this.tableStore.setTotalItems(Number.parseInt(response.headers['x-total-count'], 10));
    });
  }

  goToNextPage() {
    return this.goToPageNumber(this.tableStore.currentPage + 1);
  }

  goToPageNumber(newPageNumber) {
    this.tableStore.setCurrentPage(newPageNumber);
    return this.load();
  }

  goToPreviousPage() {
    return this.goToPageNumber(this.tableStore.currentPage - 1);
  }

  get hasActiveDomains() {
    // If there are no domains
    if (this.tableStore.totalItems === 0) {
      return false;
    }

    // If there are no active domains on the first iterable page
    // This is a best-effort check and we allow sync if there are multiple pages
    if (
      this.tableStore.rows.some((row) => row.data.status === 'ACTIVE') ||
      this.tableStore.totalPages > 1
    ) {
      return true;
    }

    return false;
  }

  onPageSizeChange(pageSize) {
    this.tableStore.setPageSize(pageSize);

    // Reset page back to the first page and refresh the list.
    return this.goToPageNumber(TableConstants.FIRST_PAGE);
  }

  search(value) {
    this.tableStore.searchQuery = value;
    return this.load();
  }

  get setupStatus() {
    // If there are no domains
    if (this.tableStore.totalItems === 0) {
      return DOMAIN_SETUP_STATUSES.UNCONFIGURED;
    }

    // If there are no active domains on the first iterable page. This is a
    // best-effort check and we consider the setup complete if there are multiple pages
    if (
      !this.tableStore.rows.some((row) => row.data.status === 'ACTIVE') &&
      this.tableStore.totalPages <= 1
    ) {
      return DOMAIN_SETUP_STATUSES.IN_PROGRESS;
    }

    return DOMAIN_SETUP_STATUSES.COMPLETED;
  }

  sortBy(descriptor) {
    this.tableStore.setSortDescriptor(descriptor);
    return this.load();
  }
}

export default DirectoryDomainsStore;
export {DOMAIN_SETUP_STATUSES};
