import {Locale} from '@admin-tribe/acsc';
import axios from 'axios';

import {getRestApiHeaderObject, getTronEndpoint} from './tronData';

/**
 * Retrieves ui constants
 *
 * @return {Promise<AxiosResponse<any>>} Axios response
 */
function getUiConstantsResource() {
  return axios.get(`${getTronEndpoint()}/api/cacheable/v2/ui_constants`, {
    headers: getRestApiHeaderObject(),
  });
}

/**
 * Retrieves adobe products
 *
 * @return {Promise<AxiosResponse<any>>} Axios response
 */
function getAdobeProducts() {
  return axios.get(`${getTronEndpoint()}/api/cacheable/adobe_products`, {
    headers: getRestApiHeaderObject(),
  });
}

/**
 * Retrieves Adobe update resources
 *
 *
 * @return {Promise<AxiosResponse>} - Axios response
 */
function getAdobeUpdatesResource() {
  return axios.get(
    `${getTronEndpoint()}/api/cacheable/adobe_product_updates_locale/${
      Locale.get().activeLanguage
    }`,
    {
      headers: getRestApiHeaderObject(),
      isArray: true,
    }
  );
}

/**
 * Retrieves all Adobe Products for create package from backend
 *
 * @return {Promise<AxiosResponse<any>>} Axios response
 */
function getAdobeProductsToShowResource() {
  return axios.get(`${getTronEndpoint()}/api/cacheable/adobe_product_to_show`, {
    headers: getRestApiHeaderObject(),
  });
}

export {
  getUiConstantsResource,
  getAdobeUpdatesResource,
  getAdobeProducts,
  getAdobeProductsToShowResource,
};
