// ***********************************************************************
// ADOBE CONFIDENTIAL
// ___________________
//
// Copyright 2024 Adobe
// All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains
// the property of Adobe and its suppliers, if any. The intellectual
// and technical concepts contained herein are proprietary to Adobe
// and its suppliers and are protected by all applicable intellectual
// property laws, including trade secret and copyright laws.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Adobe.
// ************************************************************************
import {dispatchUiEventAnalytics} from '@admin-tribe/acsc';
import {Flex, Grid, Heading, Text, View} from '@adobe/react-spectrum';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import DynamicActionsSection from 'features/overview/components/quick-link-banner-section/DynamicActionsSection';
import {getAllButtonData} from 'features/overview/components/quick-link-banner-section/QuickLinkButtonUtils';

const backgroundImageUrl =
  'https://odin.adobe.com/content/dam/pandora/react-personalized-illustrated-message/v1/en_US/final_review_introductions/Quick_links_banner_gradient.png';

/**
 * The Quick Link Banner Component
 *
 * Showing the banner with dynamic action buttons across and on the top of the overview page.
 */
const QuickLinkBannerSection = () => {
  const intl = useIntl();
  const [buttonsData, setButtonsData] = useState([]);
  const [openAddProductMiniApp, setOpenAddProductMiniApp] = useState(false);
  const [isAddUsersModalOpen, setIsAddUsersModalOpen] = useState(false);

  // Side effect: send analytic display event on first render only
  useEffect(() => {
    dispatchUiEventAnalytics({
      eventAction: 'display',
      eventName: 'CTIR22282:QuickLinkBannerSection',
    });
  }, []);

  // Side effect: Fetches button data based on the current cohort and updates the state
  useEffect(() => {
    const fetchButtonData = async () => {
      const result = await getAllButtonData(
        openAddProductMiniApp,
        setOpenAddProductMiniApp,
        isAddUsersModalOpen,
        setIsAddUsersModalOpen
      );
      setButtonsData(result);
    };
    fetchButtonData();
  }, [openAddProductMiniApp, isAddUsersModalOpen]);

  const styles = {
    background: `url(${backgroundImageUrl})`,
    borderRadius: '10px',
    position: 'absolute',
  };

  const useViewportWidth = () => {
    const currentWindow = window;
    const [width, setWidth] = useState(currentWindow.innerWidth);
    const handleResize = () => {
      setWidth(currentWindow.innerWidth);
    };

    /**
     * Detect the window size to display mobile view
     */
    useEffect(() => {
      currentWindow.addEventListener('resize', handleResize);

      // Cleanup listener on unmount
      return () => {
        currentWindow.removeEventListener('resize', handleResize);
      };
    });

    return width;
  };

  const viewportWidth = useViewportWidth();

  const buttonMargin = () => {
    let margin = '0px';
    if (viewportWidth > 640) {
      margin = '10px';
    }

    if (viewportWidth <= 640 && viewportWidth > 453) {
      margin = '35px';
    }

    if (viewportWidth <= 453) {
      margin = '4px';
    }

    return margin;
  };

  return (
    <>
      <View
        borderRadius="10px"
        height={{base: '155px', L: '155px', M: '155px'}}
        top="0px"
        UNSAFE_style={styles}
        width="100%"
      />
      <View alignItems="center" data-testid="quick-link-banner" paddingBottom="30px" zIndex={1}>
        <Grid alignContent="center" alignItems="center" justifyContent="center">
          <Flex alignContent="center" alignItems="center" justifyContent="center" marginTop="5px">
            <Heading
              alignContent="center"
              alignItems="center"
              alignSelf="center"
              data-testid="one-console-hva-quick-link-title"
              UNSAFE_style={{
                fontSize: viewportWidth <= 640 ? '21px' : '28px',
                textAlign: 'center',
              }}
            >
              <Text>{intl.formatMessage({id: 'overview.quickLinks.heading'})}</Text>
            </Heading>
          </Flex>

          <DynamicActionsSection buttonsData={buttonsData} marginTop={buttonMargin()} />
        </Grid>
      </View>
    </>
  );
};

QuickLinkBannerSection.propTypes = {};

export default QuickLinkBannerSection;
