import {feature} from '@admin-tribe/acsc';
import {
  OverlayWait,
  showError as showErrorToast,
  showSuccess as showSuccessToast,
} from '@admin-tribe/acsc-ui';
import {Content, DialogContainer, Divider, Header, Heading, Text} from '@adobe/react-spectrum';
import {Drawer} from '@pandora/react-drawer';
import {useId} from '@react-aria/utils';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import useDirectoryList from 'common/hooks/api/useDirectoryList';
import rootStore from 'core/RootStore';
import DirectoryType from 'features/settings/common/components/directory-type/DirectoryType';
import {useDirectoryListContext} from 'features/settings/components/directories/directory-list/DirectoryListContext';
import DirectoryStatus from 'features/settings/components/directories/directory-list/DirectoryStatus';
import useTrustList from 'features/settings/hooks/api/useTrustList';

import DeleteDirectoriesModal from '../delete-directories-modal/DeleteDirectoriesModal';

import TrustDirectoryDeStatus from './trust-directory-domain-enforcement-status/TrustDirectoryDeStatus';
import TrustDrawerActionButtons from './trust-drawer-action-buttons/TrustDrawerActionButtons';
import TrustMetadata from './trust-metadata/TrustMetadata';

const TrustedDirectoryDetailsDrawer = ({close, directory}) => {
  const headerId = useId();
  const {getTrust, cancelTrustRequest, resendTrustRequest} = useTrustList();
  const {loadDirectoryList} = useDirectoryListContext();
  const {clearDirectoryCache} = useDirectoryList();
  const [trust, setTrust] = useState();
  const [isLoadingTrust, setIsLoadingTrust] = useState(false);
  const [isMakingRequest, setIsMakingRequest] = useState(false);
  const [isDeleteDirectoriesModalVisible, setIsDeleteDirectoriesModalVisible] = useState(false);
  const intl = useIntl();

  // Load trust data when component mounts
  useEffect(() => {
    let isMounted = true;

    const loadTrustDetails = async () => {
      try {
        setIsLoadingTrust(true);

        const trustData = await getTrust(rootStore.organizationStore.activeOrgId, directory.id);

        // TODO: This should be set to the new TrustEntity model after
        //  https://git.corp.adobe.com/admin-tribe/onesie/pull/16694 is merged
        if (isMounted) {
          setTrust(trustData);
        }
      } catch {
        showErrorToast(
          intl.formatMessage({
            id: 'settings.directories.trustedDirectoryDetailsDrawer.toasts.errorLoadingData',
          })
        );
      } finally {
        if (isMounted) {
          setIsLoadingTrust(false);
        }
      }
    };

    loadTrustDetails();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- run only once when component mounts
  }, []);

  const onRemoveTrust = () => {
    setIsDeleteDirectoriesModalVisible(true);
  };

  const onDirectoryRemoved = () => {
    // Clear the catch and reload the list of directories
    clearDirectoryCache();
    loadDirectoryList({}, {updateCount: true});

    // TODO: DrawerTrigger component is using `watchModals` from `@react-aria` package which causes an error
    // when closing the modal as well as the drawer one after another. To mitigate this we add a wait
    // before we close the drawer. A better approach would be to fix the Drawer component
    setTimeout(() => {
      close();
    }, 400);
  };

  const onResendRequest = async () => {
    setIsMakingRequest(true);

    try {
      await resendTrustRequest(trust.trusteeOrgId, trust.directoryId);

      showSuccessToast(
        intl.formatMessage({
          id: 'settings.directories.trustedDirectoryDetailsDrawer.toasts.resendRequestSuccess',
        })
      );
    } catch {
      showErrorToast(
        intl.formatMessage({
          id: 'settings.directories.trustedDirectoryDetailsDrawer.toasts.resendRequestFail',
        })
      );
    } finally {
      setIsMakingRequest(false);
    }
  };

  const onCancelRequest = async () => {
    setIsMakingRequest(true);

    try {
      const {failedToRevoke} = await cancelTrustRequest({
        directoryId: trust.directoryId,
        orgId: trust.trusteeOrgId,
      });

      if (failedToRevoke.length > 0) {
        showErrorToast(
          intl.formatMessage({
            id: 'settings.directories.trustedDirectoryDetailsDrawer.toasts.cancelRequestFail',
          })
        );
        setIsMakingRequest(false);
        return;
      }

      showSuccessToast(
        intl.formatMessage({
          id: 'settings.directories.trustedDirectoryDetailsDrawer.toasts.cancelRequestSuccess',
        })
      );

      close();

      // TODO: Refresh directory list when mechanism is implemented
      clearDirectoryCache();
    } catch {
      showErrorToast(
        intl.formatMessage({
          id: 'settings.directories.trustedDirectoryDetailsDrawer.toasts.cancelRequestFail',
        })
      );
    }
  };

  return (
    <>
      <Drawer aria-labelledby={headerId}>
        <Header id={headerId} margin="size-100">
          <OverlayWait data-testid="header-overlay-wait" isLoading={isMakingRequest} showContent>
            <DirectoryStatus
              ownershipStatus={directory.ownershipStatus}
              status={directory.status}
            />
            <Heading level={2}>{directory.directoryName}</Heading>
            <DirectoryType directoryType={directory.type} withIcon />
            {directory.isStatusPending && (
              <Text marginBottom="size-100">
                <FormattedMessage id="settings.directories.trustedDirectoryDetailsDrawer.activeDescription" />
              </Text>
            )}
            <TrustDrawerActionButtons
              directory={directory}
              onCancelRequest={onCancelRequest}
              onRemoveTrust={onRemoveTrust}
              onResendRequest={onResendRequest}
            />
          </OverlayWait>
        </Header>
        <Content margin="size-100">
          <OverlayWait data-testid="content-overlay-wait" isLoading={isLoadingTrust} showContent>
            <Heading level={3}>
              <FormattedMessage id="settings.directories.trustedDirectoryDetailsDrawer.detailsHeading" />
            </Heading>
            <Divider size="S" />
            {trust && <TrustMetadata directory={directory} marginTop="size-100" trust={trust} />}
          </OverlayWait>
        </Content>
        {directory.domainEnforcement && feature.isEnabled('temp_domain_enforcement') && (
          <TrustDirectoryDeStatus domainEnforcement={directory.domainEnforcement} />
        )}
      </Drawer>

      <DialogContainer onDismiss={() => setIsDeleteDirectoriesModalVisible(false)}>
        {isDeleteDirectoriesModalVisible && (
          <DeleteDirectoriesModal
            directories={[directory]}
            handleDirectoriesDeleteConfirm={onDirectoryRemoved}
          />
        )}
      </DialogContainer>
    </>
  );
};

TrustedDirectoryDetailsDrawer.propTypes = {
  /**
   * Callback used to close the drawer programmatically
   */
  close: PropTypes.func.isRequired,
  directory: PropTypes.shape({
    /**
     * Name of the directory
     */
    directoryName: PropTypes.string,
    /**
     * Domain Enforcement details
     */
    domainEnforcement: PropTypes.shape({
      /**
       * The ID of the Directory
       */
      authSrc: PropTypes.string.isRequired,
      /**
       * Date when opt-out phase ends
       */
      notifyAt: PropTypes.number,
      /**
       * Current status of the Policy
       */
      state: PropTypes.string.isRequired,
      /**
       *  Date when the current state phase ends
       */
      stateEndsAt: PropTypes.number,
    }),
    /**
     * Id of the directory
     */
    id: PropTypes.string,
    /**
     * A getter for pending status
     */
    isStatusPending: PropTypes.bool,
    /**
     * Wether it's a trusted or owned directory
     */
    ownershipStatus: PropTypes.string,
    /**
     * Status of the directory
     */
    status: PropTypes.string,
    /**
     * Type of directory
     */
    type: PropTypes.string,
  }).isRequired,
};

export default TrustedDirectoryDetailsDrawer;
