import {TableIconNameCell} from '@admin-tribe/acsc-ui';
import {Cell, Column, Row, TableBody, TableHeader} from '@adobe/react-spectrum';
import {Table, useTableSectionContext} from '@pandora/react-table-section';
import DeviceDesktop from '@spectrum-icons/workflow/DeviceDesktop';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

/**
 * Table for deactivated devices.
 */
const DeactivatedDevicesTable = () => {
  const {tableSectionState} = useTableSectionContext();
  const {formatMessage, formatDate} = useIntl();

  const columns = [
    {intlLabel: 'products.details.devices.deactivatedDevices.table.column.name', key: 'deviceName'},
    {
      intlLabel: 'products.details.devices.deactivatedDevices.table.column.poolName',
      key: 'poolName',
    },
    {
      intlLabel: 'products.details.devices.deactivatedDevices.table.column.lastActivityDate',
      key: 'lastActivityDate',
    },
  ];

  return (
    <Table
      aria-label={formatMessage({
        id: 'products.details.devices.deactivatedDevices.table.ariaLabel',
      })}
    >
      <TableHeader columns={columns}>
        {(column) => (
          <Column key={column.key} allowsSorting>
            <FormattedMessage id={column.intlLabel} />
          </Column>
        )}
      </TableHeader>
      <TableBody items={tableSectionState.items}>
        {}
        {(item) => (
          <Row key={item.id}>
            <Cell>
              <TableIconNameCell IconComponent={<DeviceDesktop />}>
                {item.deviceName}
              </TableIconNameCell>
            </Cell>
            <Cell>{item.poolName}</Cell>
            <Cell>{formatDate(item.lastActivityDate, {dateStyle: 'long'})}</Cell>
          </Row>
        )}
      </TableBody>
    </Table>
  );
};

DeactivatedDevicesTable.displayName = 'DeactivatedDevicesTable';
export default DeactivatedDevicesTable;
