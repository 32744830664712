import React from 'react';

import ProductApprovePage from 'features/approve-product/pages/ProductApprovePage';
import {PATH_APPROVE_PRODUCT_AUTH_REQUEST_ID} from 'features/approve-product/routing/productApprovePaths';

/**
 * Returns the routes for the product approval feature.
 */
const productApproveRoutes = () => [
  {
    element: <ProductApprovePage />,
    path: PATH_APPROVE_PRODUCT_AUTH_REQUEST_ID,
  },
];

export default productApproveRoutes;
