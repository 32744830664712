import {AcquisitionSummaryList} from '@admin-tribe/acsc';
import {useAsyncModel} from '@pandora/react-async-model';
import {useCallback} from 'react';

/**
 * @description Hook for handling the loading and fetching of AcquistionSummaryList.
 *
 * @param {String} contractId - the contract id
 * @param {String} orgId - the orgId for the app integrations to fetch
 * @returns {Object} state - Object with state variables
 *          {AcquisitionSummaryList} state.acquisitionSummaryList - the AcquisitionSummaryList model
 *          {Boolean} state.isLoading - whether the data is being loaded
 *          {Object} state.error - the error if loading fails
 */
const useAcquisitionSummaryList = ({contractId, orgId}) => {
  const loadAcquisitions = useCallback(
    () =>
      AcquisitionSummaryList.get({
        contractId,
        orgId,
      }),
    [contractId, orgId]
  );

  const {
    model: acquisitionSummaryList,
    isLoading,
    error,
  } = useAsyncModel({
    loadFn: loadAcquisitions,
  });

  return {
    acquisitionSummaryList,
    error,
    isLoading,
  };
};

export default useAcquisitionSummaryList;
