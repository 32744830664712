import {feature} from '@admin-tribe/acsc';
import {SUPPORT_TICKET_RECORD_TYPE} from '@pandora/data-model-support-ticket';
import React from 'react';

import {buildAccessCheckLoader} from 'core/router/loaders';
import {canViewSupport} from 'core/support/supportAccess';
import SupportWorkspaceWrapper from 'features/support/components/SupportWorkspaceWrapper';
import SupportSummaryPage from 'features/support/pages/support-summary-page/SupportSummaryPage';
import SupportTicketDetailsPage from 'features/support/pages/support-ticket-details-page/SupportTicketDetailsPage';
import SupportTicketPage from 'features/support/pages/support-tickets-page/SupportTicketsPage';

import {PATH_SUPPORT} from './supportPaths';
import {
  expertSessionsSectionLoader,
  expertSessionsShouldRevalidate,
  supportCasesSectionLoader,
  supportCasesShouldRevalidate,
  supportSummarySectionLoader,
} from './supportRoutesLoaders';

/** Gathers all of the Support routes */
const supportRoutes = () => [
  {
    children: [
      {
        element: <SupportSummaryPage />,
        index: true,
      },
      {
        element: <SupportSummaryPage />,
        path: 'creative-cloud',
      },
      {
        element: <SupportSummaryPage />,
        path: 'document-cloud',
      },
      {
        element: <SupportSummaryPage />,
        path: 'experience-cloud',
      },
      {
        element: <SupportSummaryPage />,
        path: 'more-products',
      },
      {
        children: [
          {
            element: <SupportTicketPage recordType={SUPPORT_TICKET_RECORD_TYPE.CASE} />,
            path: 'open',
          },
          {
            element: <SupportTicketPage recordType={SUPPORT_TICKET_RECORD_TYPE.CASE} />,
            path: 'closed',
          },
          {
            element: <SupportTicketDetailsPage recordType={SUPPORT_TICKET_RECORD_TYPE.CASE} />,
            path: ':ticketId',
          },
        ],
        loader: supportCasesSectionLoader,
        path: 'support-cases',
        shouldRevalidate: supportCasesShouldRevalidate,
      },
      {
        children: [
          {
            element: <SupportTicketPage recordType={SUPPORT_TICKET_RECORD_TYPE.EXPERT} />,
            path: 'open',
          },
          {
            element: <SupportTicketPage recordType={SUPPORT_TICKET_RECORD_TYPE.EXPERT} />,
            path: 'closed',
          },
          {
            element: <SupportTicketDetailsPage recordType={SUPPORT_TICKET_RECORD_TYPE.EXPERT} />,
            path: ':ticketId',
          },
        ],
        loader: expertSessionsSectionLoader,
        path: 'expert-sessions',
        shouldRevalidate: expertSessionsShouldRevalidate,
      },
    ],
    element: <SupportWorkspaceWrapper />,
    id: 'support',
    loader: feature.isEnabled('temp_support_exl_details_redirect')
      ? supportSummarySectionLoader
      : buildAccessCheckLoader(canViewSupport),
    path: PATH_SUPPORT,
  },
];

export default supportRoutes;
