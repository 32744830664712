import {feature, log} from '@admin-tribe/acsc';
import {WorkspaceNav} from '@admin-tribe/acsc-ui';
import React, {useEffect, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';
import {
  canViewAccountDetails,
  canViewAccountOrganizationDetails,
  canViewAccountOverviewPage,
  canViewBillingHistory,
} from 'core/account/access/accountAccess';

import useAccountPaths from '../hooks/useAccountPaths';

/** Renders the workspace nav for Account */
const AccountWorkspaceNav = () => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(true);

  const {
    accountBillingHistory,
    accountContractDetails,
    accountOrganizationDetails,
    accountOverview,
  } = useAccountPaths();

  // Set isLoading to true once the component is ready
  useEffect(() => {
    setIsLoading(false);
  }, []);

  const navItems = useMemo(() => {
    const getAccountDetailsHref = () => {
      let accountDetailsHref;
      try {
        accountDetailsHref = accountContractDetails(
          rootStore.organizationStore.contractListIncludingInactive.items?.[0]?.id
        );
      } catch (error) {
        log.error(
          'An error occurred generating the path to navigate to account contract details',
          error
        );
      }
      return accountDetailsHref;
    };

    // Do not populate nav items when isLoading is true.
    if (isLoading) {
      return [];
    }

    const items = [];

    if (canViewAccountOverviewPage()) {
      items.push({
        href: accountOverview(),
        name: intl.formatMessage({id: 'account.overview.page.title'}),
        order: 0,
      });
    } else if (!canViewAccountOverviewPage() && canViewAccountDetails()) {
      items.push({
        href: getAccountDetailsHref(),
        name: intl.formatMessage({id: 'account.overview.page.title'}),
        order: 0,
      });
    }

    if (canViewBillingHistory()) {
      items.push({
        href: accountBillingHistory(),
        name: intl.formatMessage({id: 'account.billingHistory.title'}),
        order: 2,
      });
    }

    if (
      feature.isEnabled('temp_react_port_account_organization_details') &&
      canViewAccountOrganizationDetails()
    ) {
      items.push({
        href: accountOrganizationDetails(),
        name: intl.formatMessage({id: 'account.organizationDetails.page.title'}),
        order: 3,
      });
    }

    return items;
  }, [
    intl,
    isLoading,
    accountContractDetails,
    accountOverview,
    accountBillingHistory,
    accountOrganizationDetails,
  ]);

  return <WorkspaceNav navItems={navItems} />;
};

AccountWorkspaceNav.displayName = 'AccountWorkspaceNav';

export default AccountWorkspaceNav;
