import {feature} from '@admin-tribe/acsc';
import {GoUrl, WizardStepList} from '@admin-tribe/acsc-ui';
import {Text} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import StepItem from 'common/components/stepped-view/StepItem';
import SteppedView from 'common/components/stepped-view/SteppedView';
import {useDirectorySetupContext} from 'features/settings/components/directory/DirectorySetupContext';
import SetupIdpStep from 'features/settings/components/setup-directory-authentication/SetupIdpStep';
import SetupCertificatesStep from 'features/settings/components/setup-directory-authentication/setup-saml-certs/SetupCertificatesStep';
import SetupJit from 'features/settings/components/setup-jit/SetupJit';
import {
  SETUP_AUTHENTICATION_STEPS,
  SETUP_SAML_CERTS,
} from 'features/settings/stores/DirectorySetupStore';

const SetupDirectoryAuthentication = observer(
  ({currentStep, isGoogleSelected, isSoidcSelected, updateProvider, onLoginToAzure}) => {
    const intl = useIntl();
    const {directorySetupStore} = useDirectorySetupContext();
    const {idp} = directorySetupStore;

    const SETUP_AUTHENTICATION_CERT_STEPS = {...SETUP_AUTHENTICATION_STEPS, SETUP_SAML_CERTS};
    const hasSamlCertsStep =
      feature.isEnabled('temp_saml_certs') && idp?.isSaml && directorySetupStore.isEditingIdp;

    const FINAL_SETUP_AUTHENTICATION_STEPS = hasSamlCertsStep
      ? SETUP_AUTHENTICATION_CERT_STEPS
      : SETUP_AUTHENTICATION_STEPS;

    const WIZARD_STEPS = Object.values(FINAL_SETUP_AUTHENTICATION_STEPS).map((step) =>
      intl.formatMessage({id: `settings.setupDirectoryAuthentication.steps.${step.id}`})
    );

    const currentStepIndex = Object.values(FINAL_SETUP_AUTHENTICATION_STEPS).findIndex(
      (step) => step.id === currentStep.id
    );

    return (
      <>
        <WizardStepList currentStep={currentStepIndex} steps={WIZARD_STEPS} />

        <SteppedView currentStep={currentStep.id}>
          <StepItem id={FINAL_SETUP_AUTHENTICATION_STEPS.SETUP_IDP.id}>
            <SetupIdpStep
              isGoogleSelected={isGoogleSelected}
              isSoidcSelected={isSoidcSelected}
              onLoginToAzure={onLoginToAzure}
              updateProvider={updateProvider}
            />
          </StepItem>
          <StepItem id={FINAL_SETUP_AUTHENTICATION_STEPS.SETUP_JIT.id}>
            <Text>
              <FormattedMessage
                id="settings.setupDirectoryAuthentication.setupJitDescription"
                values={{
                  link: (linkText) => <GoUrl name="admin_auto_account_creation">{linkText}</GoUrl>,
                }}
              />
            </Text>
            <SetupJit />
          </StepItem>

          {hasSamlCertsStep && (
            <StepItem id={SETUP_SAML_CERTS.id}>
              <Text>
                <FormattedMessage
                  id="settings.certificates.modal.description"
                  values={{
                    goUrl: (linkText) => <GoUrl name="aac_certificates">{linkText}</GoUrl>,
                  }}
                />
              </Text>
              <SetupCertificatesStep />
            </StepItem>
          )}
        </SteppedView>
      </>
    );
  }
);

export default SetupDirectoryAuthentication;
