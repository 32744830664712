import {INVOICE_STATUS, InvoiceList, getDirectContract} from '@admin-tribe/acsc';
import CreditCardIcon from '@spectrum-icons/workflow/CreditCard';

import AuthenticatingUserModal from 'common/components/authenticating-user-modal/AuthenticatingUserModal';
import rootStore from 'core/RootStore';
import {needToSwitchAsAuthenticatingUser} from 'core/contract/access/contractAccess';
import {
  goToBillingHistory,
  goToPayInvoice,
} from 'features/account/routing/navigation-callbacks/navigationCallbacks';

import HvaCard from '../HvaCard';
import {HVA_ORDER, HVA_TYPE} from '../hvaConstants';

/**
 * This class create a HVA card allowing user to pay their unpaid invoices
 */
class UnpaidInvoicesHva extends HvaCard {
  /**
   * @description Method to fetch the UnpaidInvoicesHva
   *
   * @param {Object} [intl] react-intl to translate texts
   *
   * @returns {Promise<UnpaidInvoicesHva[]>} the fetched instance
   */
  static async get({intl}) {
    const contract = getDirectContract(rootStore.organizationStore.contractList);
    if (
      !(
        contract &&
        contract.isContractOwner({checkAuthUser: true}) &&
        contract.isPaymentCategoryPO() &&
        !contract.isComplianceStatusGracePastDue() &&
        !contract.isComplianceStatusPastDue()
      )
    ) {
      return Promise.reject();
    }

    const invoiceList = await InvoiceList.get({
      invoiceStatuses: [INVOICE_STATUS.PAYMENT_DUE, INVOICE_STATUS.PAST_DUE],
      orgId: rootStore.organizationStore.activeOrgId,
    });
    const unpaidInvoices = invoiceList.items;

    if (unpaidInvoices.length === 0) {
      return Promise.reject();
    }

    return [new UnpaidInvoicesHva({contract, intl, unpaidInvoices})];
  }

  /**
   * @description Creates a new UnpaidInvoicesHva instance
   *
   * @param {Object} obj
   *
   * @param {Contract} obj.contract - direct contract, or undefined
   *
   * @param {Object} obj.intl - react-intl to translate texts
   *
   * @param {Object} obj.unpaidInvoices - list of unpaid invoices
   *
   * @returns {UnpaidInvoicesHva} the fetched instance
   */
  constructor({contract, intl, unpaidInvoices}) {
    const namespace = 'overview.highValueActionArea.setUpTasks.unpaidInvoices';

    const hasMultipleUnpaidLicenses = unpaidInvoices.length > 1;

    const modalConfig = needToSwitchAsAuthenticatingUser({contract})
      ? {
          closeProp: 'onClose',
          Component: AuthenticatingUserModal,
          includeModalContainer: true,
          props: {authenticatedUserId: contract.getOwnerUserId(), unpaidInvoices},
        }
      : undefined;

    super({
      ctaLabel: intl.formatMessage({id: `${namespace}.ctaLabel`}, {count: unpaidInvoices.length}),
      description: intl.formatMessage({id: `${namespace}.description`}),
      dismissOnCTA: true,
      header: intl.formatMessage({id: `${namespace}.header`}),
      icon: {
        Component: CreditCardIcon,
        props: {size: 'L'},
      },
      id: 'hva-unpaid-invoices',
      isDismissible: true,
      modalConfig,
      onCTA: () => {
        if (modalConfig === undefined) {
          if (hasMultipleUnpaidLicenses) {
            goToBillingHistory();
          } else {
            goToPayInvoice(unpaidInvoices[0].id);
          }
        }
      },
      priorityOrder: HVA_ORDER.UNPAID_INVOICES,
      sectionTitle: intl.formatMessage({id: `${namespace}.sectionTitle`}),
      type: HVA_TYPE.SET_UP,
    });
  }
}

export default UnpaidInvoicesHva;
