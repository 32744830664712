import {
  COMPARTMENT_ADMIN,
  COMPARTMENT_VIEWER,
  CONTRACT_ADMIN,
  DEPLOYMENT_ADMIN,
  LICENSE_ADMIN,
  ORG_ADMIN,
  OrganizationUser,
  PRODUCT_ADMIN,
  PRODUCT_SUPPORT_ADMIN,
  STORAGE_ADMIN,
  SUPPORT_ADMIN,
  USER_GROUP_ADMIN,
  UserRole,
  feature,
} from '@admin-tribe/acsc';
import {
  DETAIL_SECTION_VARIANT_CONSTANTS,
  DetailSection,
  DetailSectionMessage,
  getProductDisplayName,
} from '@admin-tribe/acsc-ui';
import {ActionMenu, Divider, Heading, Item, View} from '@adobe/react-spectrum';
import {Accordion, AccordionItem} from '@react/react-spectrum/Accordion';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import {ROLE_KEY, USER_DETAILS_MODAL_NAMES} from 'features/users/users.constants';

import {
  canEditAdminRights,
  getStorageAdminRole,
} from './administrative-rights-detail-utils/administrativeRightsDetailUtils';

// Styling for static items.
const itemStyle = {
  color: 'rgb(110, 110, 110)',
  fontSize: 'var(--spectrum-global-dimension-static-font-size-50)',
  fontWeight: 'var(--spectrum-global-font-weight-medium)',
};

const EDIT_ADMIN_RIGHTS_ITEM_KEY = USER_DETAILS_MODAL_NAMES.EDIT_ADMIN_RIGHTS;
const CONTRACT_ADMIN_ROLE_KEY = ROLE_KEY.CONTRACT_ADMIN;

/**
 * The Administrative Rights Detail section.
 */

// eslint-disable-next-line complexity -- due to conditionals in render method for roles
const AdministrativeRightsDetailSection = ({
  headingLevel = 3,
  isLoading,
  isPageVariant,
  onMenuActionPress,
  member,
  showError,
  userRoles,
}) => {
  const intl = useIntl();
  const roleHeadinglevel = headingLevel + 1;

  const [storageAdminRole, setStorageAdminRole] = useState();

  const orgAdminRole = findRole({role: ORG_ADMIN, userRoles});
  const compartmentAdminRole = findRole({role: COMPARTMENT_ADMIN, userRoles});
  const compartmentViewerRole = findRole({role: COMPARTMENT_VIEWER, userRoles});
  const deploymentAdminRole = findRole({role: DEPLOYMENT_ADMIN, userRoles});
  const supportAdminRole = findRole({role: SUPPORT_ADMIN, userRoles});
  const contractAdminRoleTargets = findRole({role: CONTRACT_ADMIN, userRoles})?.targets;

  const productAdminRoleTargets = findRole({role: PRODUCT_ADMIN, userRoles})?.targets;
  const productSupportAdminRoleTargets = findRole({
    role: PRODUCT_SUPPORT_ADMIN,
    userRoles,
  })?.targets;
  const licenseAdminRoleTargets = findRole({role: LICENSE_ADMIN, userRoles})?.targets;
  const userGroupRoleTargets = findRole({role: USER_GROUP_ADMIN, userRoles})?.targets;

  const hasRightTargets =
    (feature.isEnabled('temp_contract_admin_role') && !!contractAdminRoleTargets) ||
    !!productAdminRoleTargets ||
    !!productSupportAdminRoleTargets ||
    !!licenseAdminRoleTargets ||
    !!userGroupRoleTargets;

  const hasAdminRoles =
    !!orgAdminRole ||
    !!compartmentAdminRole ||
    !!compartmentViewerRole ||
    !!deploymentAdminRole ||
    !!storageAdminRole ||
    !!supportAdminRole ||
    !!hasRightTargets;

  // Always show menu once member has loaded so focus can be recovered
  // after admin closes edit modal.
  const canShowEditMenu = member && !showError;
  // Disable the menu item keys until data is ready
  const disabledKeys = !isLoading && !showError ? [] : [EDIT_ADMIN_RIGHTS_ITEM_KEY];

  // To get storage admin role
  useEffect(() => {
    (async () => {
      setStorageAdminRole(await getStorageAdminRole({role: STORAGE_ADMIN, userRoles}));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- run only on mount
  }, []);

  const renderRole = ({roleKey}) => (
    <>
      <View paddingStart="34px" paddingY="size-10">
        <Heading level={roleHeadinglevel} UNSAFE_style={itemStyle}>
          {intl.formatMessage({
            id: `common.administrativeRightsDetailSection.roles.${roleKey}`,
          })}
        </Heading>
      </View>
      <Divider size="S" />
    </>
  );

  const renderRights = ({roleKey, targets}) => (
    <AccordionItem
      header={intl.formatMessage(
        {
          id: `common.administrativeRightsDetailSection.roles.${roleKey}`,
        },
        {count: targets.length}
      )}
    >
      {targets.map((item) => (
        <View key={item.id} marginBottom="size-100">
          {item.name || findDisplayNameBasedOnRole(roleKey, item, intl)}
        </View>
      ))}
    </AccordionItem>
  );

  return (
    <DetailSection
      headingLevel={headingLevel}
      isLoading={isLoading}
      rightHeaderContent={
        canShowEditMenu &&
        canEditAdminRights(member) && (
          <ActionMenu disabledKeys={disabledKeys} isQuiet onAction={onMenuActionPress}>
            <Item key={EDIT_ADMIN_RIGHTS_ITEM_KEY}>
              {intl.formatMessage({
                id: 'common.administrativeRightsDetailSection.moreOptions.edit',
              })}
            </Item>
          </ActionMenu>
        )
      }
      showError={showError}
      title={intl.formatMessage({
        id: 'common.administrativeRightsDetailSection.title',
      })}
      variant={isPageVariant && DETAIL_SECTION_VARIANT_CONSTANTS.PAGE}
    >
      {hasAdminRoles ? (
        <>
          <View>
            {orgAdminRole && renderRole({roleKey: 'orgAdmin'})}
            {compartmentAdminRole && renderRole({roleKey: 'compartmentAdmin'})}
            {compartmentViewerRole && renderRole({roleKey: 'compartmentViewer'})}
            {deploymentAdminRole && renderRole({roleKey: 'deploymentAdmin'})}
            {storageAdminRole && renderRole({roleKey: 'storageAdmin'})}
            {supportAdminRole && renderRole({roleKey: 'supportAdmin'})}
          </View>
          {hasRightTargets && (
            <Accordion
              aria-label={intl.formatMessage({
                id: 'common.administrativeRightsDetailSection.accordion.ariaLabel',
              })}
              ariaLevel={roleHeadinglevel}
            >
              {feature.isEnabled('temp_contract_admin_role') &&
                contractAdminRoleTargets?.length > 0 &&
                renderRights({roleKey: CONTRACT_ADMIN_ROLE_KEY, targets: contractAdminRoleTargets})}
              {productAdminRoleTargets?.length > 0 &&
                renderRights({roleKey: 'productAdmin', targets: productAdminRoleTargets})}
              {productSupportAdminRoleTargets?.length > 0 &&
                renderRights({
                  roleKey: 'productSupportAdmin2',
                  targets: productSupportAdminRoleTargets,
                })}
              {licenseAdminRoleTargets?.length > 0 &&
                renderRights({
                  roleKey: 'licenseAdmin',
                  targets: licenseAdminRoleTargets,
                })}
              {userGroupRoleTargets?.length > 0 &&
                renderRights({
                  roleKey: 'userGroupAdmin',
                  targets: userGroupRoleTargets,
                })}
            </Accordion>
          )}
        </>
      ) : (
        <DetailSectionMessage messageKey="common.administrativeRightsDetailSection.noRights" />
      )}
    </DetailSection>
  );
};

/**
 * @description Method to find a particular role of the member.
 *
 * @param {Object} object
 * @param {String} object.role - particular user role
 * @param {UserRole} object.userRoles - roles assigned to the member
 * @returns {UserRole} found role.
 */
function findRole({role, userRoles}) {
  return userRoles?.find((el) => el?.type === role);
}

/**
 * @description Method to find displayName for a particular role of the member.
 *
 * @param {String} object.role - particular user role
 * @param {Object} item
 * @returns {String} contractDisplayName.
 */
function findDisplayNameBasedOnRole(role, item, intl) {
  if (role === CONTRACT_ADMIN_ROLE_KEY) {
    return item.parentContract?.displayName;
  }
  return getProductDisplayName(intl, item.parentProduct);
}

AdministrativeRightsDetailSection.propTypes = {
  /**
   * The heading level for the section Heading. The default is 3.
   */
  headingLevel: PropTypes.number,
  /**
   * Whether the component should display the loading indicator or not.
   */
  isLoading: PropTypes.bool,
  /**
   * Whether the section has page variant or not.
   */
  isPageVariant: PropTypes.bool,
  /**
   * The user or administrator.
   */
  member: PropTypes.instanceOf(OrganizationUser),
  /**
   * Callback to invoke when the more options menu item is clicked. Params are being passed by MoreOptionsMenu.
   */
  onMenuActionPress: PropTypes.func.isRequired,
  /**
   * Whether an error occurred during fetching data or not.
   */
  showError: PropTypes.bool,
  /**
   * An array of roles assigned to the user.
   */
  userRoles: PropTypes.arrayOf(PropTypes.instanceOf(UserRole)),
};

export default AdministrativeRightsDetailSection;
