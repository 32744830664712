import {TableIconNameCell} from '@admin-tribe/acsc-ui';
import {
  Cell,
  Column,
  Content,
  ContextualHelp,
  Flex,
  Heading,
  Row,
  TableBody,
  TableHeader,
  Text,
  View,
} from '@adobe/react-spectrum';
import {StatusLight} from '@pandora/react-status-light';
import {EN_DASH, Table, useTableSectionContext} from '@pandora/react-table-section';
import DeviceDesktop from '@spectrum-icons/workflow/DeviceDesktop';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {DEVICE_STATUS} from 'features/products/device-licenses/DeviceConstants';

import LegacyDevicesActionsMenu from './LegacyDevicesActionsMenu';

/**
 * Table for Device-based licenses, aka legacy devices.
 */
const LegacyDevicesTableSection = ({poolId, updateTableChange}) => {
  const {tableSectionState} = useTableSectionContext();
  const {formatMessage, formatDate} = useIntl();

  const columns = [
    {
      allowsSorting: true,
      intlLabel: 'products.details.devices.legacyDevices.table.column.name',
      key: 'deviceName',
    },
    {
      allowsSorting: true,
      hideHeader: true,
      intlLabel: 'products.details.devices.legacyDevices.table.column.actions',
      key: 'deviceAction',
      showDivider: true,
    },
    {
      allowsSorting: true,
      intlLabel: 'products.details.devices.legacyDevices.table.column.expires',
      key: 'lastActivityDate',
    },
    {
      intlLabel: 'products.details.devices.legacyDevices.table.column.status',
      key: 'paymentNeeded',
    },
  ];

  const getOverActivatedStatus = () => (
    <Flex direction="row" rowGap="size-100">
      <StatusLight alignStart variant="negative">
        {formatMessage({
          id: 'products.details.devices.legacyDevices.paymentNeeded',
        })}
      </StatusLight>
      <ContextualHelp variant="info">
        <Heading>
          {formatMessage({
            id: 'products.details.devices.legacyDevices.tooltip.title',
          })}
        </Heading>
        <Content>
          <Text data-testid="tooltip-description">
            {formatMessage({
              id: 'products.details.devices.legacyDevices.tooltip.description',
            })}
          </Text>
        </Content>
      </ContextualHelp>
    </Flex>
  );

  return (
    <Table
      aria-label={formatMessage({
        id: 'products.details.devices.legacyDevices.table.ariaLabel',
      })}
    >
      <TableHeader columns={columns}>
        {(column) => (
          <Column
            key={column.key}
            allowsSorting={column.allowsSorting}
            hideHeader={column.hideHeader}
            showDivider={column.showDivider}
          >
            <FormattedMessage id={column.intlLabel} />
          </Column>
        )}
      </TableHeader>
      <TableBody items={tableSectionState.items}>
        {/* eslint-disable-next-line @admin-tribe/admin-tribe/extract-large-computations -- This is not a complex computation. */}
        {(item) => (
          <Row key={item.deviceId}>
            <Cell>
              <TableIconNameCell IconComponent={<DeviceDesktop />}>
                {item.deviceName}
              </TableIconNameCell>
            </Cell>
            <Cell data-testid="legacy-devices-action-cell">
              <LegacyDevicesActionsMenu
                device={item}
                poolId={poolId}
                updateTableChange={updateTableChange}
              />
            </Cell>
            <Cell>
              <View>{formatDate(item.lastActivityDate, {dateStyle: 'long'})}</View>
            </Cell>
            <Cell>
              {item.status === DEVICE_STATUS.OVER_ACTIVATED ? getOverActivatedStatus() : EN_DASH}
            </Cell>
          </Row>
        )}
      </TableBody>
    </Table>
  );
};

LegacyDevicesTableSection.displayName = 'LegacyDevicesTableSection';

LegacyDevicesTableSection.propTypes = {
  /** Identifies the pool that this device belongs to. */
  poolId: PropTypes.string.isRequired,
  /** Reload page and update header section when device table is updated */
  updateTableChange: PropTypes.func.isRequired,
};

export default LegacyDevicesTableSection;
