import {Locale, feature, log} from '@admin-tribe/acsc';
import {USER_ROLES} from '@pandora/administration-core-types';
import {JilModelList} from '@pandora/data-model-list';
import {Product} from '@pandora/data-model-product';
import {
  SUPPORT_TICKET_RECORD_TYPE,
  SUPPORT_TICKET_STATUS_REASON,
  useSupportTicketHook,
} from '@pandora/data-model-support-ticket';
import {useAuth} from '@pandora/react-auth-provider';
import {userRoleUtils} from '@pandora/react-data-model-member';
import {filterSupportTicketList, getSupportPlanLevel} from '@pandora/react-support-ticket';
import {useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';

import useProductEntitlementList from './useProductEntitlementList';

/**
 * useSupportTicketDetailsPageHook hook is for SupportTicketDetailsPage component
 */
const useSupportTicketDetailsPageHook = (id, recordType) => {
  const intl = useIntl();
  const auth = useAuth();
  const {getSupportTicket} = useSupportTicketHook();
  const {fetchProductEntitlementList} = useProductEntitlementList();
  const tempSupportDmeAspFlag = feature.isEnabled('temp_support_dme_asp');

  const [supportTicket, setSupportTicket] = useState();
  const [isBumpered, setIsBumpered] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [supportLevel, setSupportLevel] = useState('');
  const {activeOrgId, productList: binkyProductList} = rootStore.organizationStore;
  const supportTicketActionSectionProps = useRef({});
  const supportDetailsDrawerProps = useRef({});

  const productList = new JilModelList({
    itemCount: binkyProductList.pagination.itemCount,
    items: binkyProductList.items.map(
      (product) =>
        new Product({
          ...product,
          fulfillableItems: product.fulfillableItemList.items,
        })
    ),
  });

  const getTicket = async () => {
    setLoading(true);
    try {
      const ticket = await getSupportTicket({
        id,
        locale: Locale.get().activeLocaleCode,
        orgId: activeOrgId,
        recordType: recordType.toUpperCase(),
      });

      const filteredSupportTickets = filterSupportTicketList(
        ticket.isExpertSession(),
        userRoleUtils.anyOfForOrg(
          auth.getUserProfile().roles,
          [USER_ROLES.ORG_ADMIN, USER_ROLES.SUPPORT_ADMIN],
          activeOrgId
        ),
        productList,
        userRoleUtils.getTargetsByRole(
          USER_ROLES.PRODUCT_SUPPORT_ADMIN,
          activeOrgId,
          auth.getUserProfile().roles
        ),
        [ticket],
        auth.getUserProfile().userId
      );
      if (filteredSupportTickets.length === 0) {
        setIsBumpered(true);
        log.error(`Admin does not have access to Support Ticket ${id}`);
      }
      setValuesInProps(filteredSupportTickets?.[0]);
      setSupportTicket(filteredSupportTickets?.[0]);
    } catch (error) {
      setIsBumpered(true);
      log.error(`Failed to get support ticket for ${id}. Error: `, error);
    } finally {
      setLoading(false);
    }
  };

  function setValuesInProps(ticket) {
    if (recordType === SUPPORT_TICKET_RECORD_TYPE.EXPERT && ticket) {
      supportDetailsDrawerProps.current = {
        ...supportDetailsDrawerProps.current,
        proposedTime1: ticket.proposedTime1,
        proposedTime2: ticket.proposedTime2,
        proposedTime3: ticket.proposedTime3,
      };
      supportTicketActionSectionProps.current = {
        isCalendly: isCalendly(ticket),
      };
    }
  }

  function isCalendly(ticket) {
    return (
      ticket.statusReason === SUPPORT_TICKET_STATUS_REASON.SESSION_SCHEDULED &&
      !ticket.proposedTime1 &&
      !ticket.proposedTime2 &&
      !ticket.proposedTime3
    );
  }

  /** get support ticket details */
  useEffect(() => {
    getTicket();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- required here
  }, [activeOrgId, id, recordType]);

  /* get support level details*/
  useEffect(() => {
    const getSupportLevel = async () => {
      setLoading(true);
      const levels = await fetchProductEntitlementList();
      const supportPlanLevel = getSupportPlanLevel(
        levels,
        supportTicket.licenseId,
        supportTicket.productCode
      );
      setSupportLevel(supportPlanLevel);
      setLoading(false);
    };

    if (supportTicket?.licenseId && tempSupportDmeAspFlag) {
      getSupportLevel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- required here
  }, [supportTicket]);

  return {
    activeOrgId,
    getTicket,
    intl,
    isBumpered,
    isLoading,
    productList,
    supportDetailsDrawerProps,
    supportLevel,
    supportTicket,
    supportTicketActionSectionProps,
  };
};

export default useSupportTicketDetailsPageHook;
