import {download, jilProducts, log} from '@admin-tribe/acsc';
import {showError, showInfo, showSuccess as showSuccessToast} from '@admin-tribe/acsc-ui';
import {Button} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';

/**
 * Renders the export to csv button for product profile page which exports all product devices.
 */
const ExportDevicesButton = ({productId}) => {
  const intl = useIntl();
  const orgId = rootStore.organizationStore.activeOrgId;

  const [isLoading, setIsLoading] = React.useState(false);

  const onExportCallback = async () => {
    setIsLoading(true);
    try {
      showInfo(
        intl.formatMessage({
          id: 'products.details.devices.downloadBeingPrepared',
        })
      );
      const response = await jilProducts.getDevicesForExport({orgId, productId});

      download(response.data.file, 'devices.csv');
      showSuccessToast(
        intl.formatMessage({id: 'settings.directories.directoryListActionMenu.downloadSuccess'})
      );
    } catch (error) {
      log.error(`Failed to download product devices for ${productId}. Error: `, error);
      showError();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button
      data-testid="export-devices-button"
      isDisabled={isLoading}
      onPress={() => onExportCallback()}
      UNSAFE_style={{cursor: isLoading ? 'not-allowed' : 'pointer', marginLeft: '1rem'}}
      variant="secondary"
    >
      {intl.formatMessage({
        id: 'products.details.devices.exportDevicesButton',
      })}
    </Button>
  );
};

ExportDevicesButton.propTypes = {
  /**
   * Export devices for this Product ID
   */
  productId: PropTypes.string,
};

export default ExportDevicesButton;
