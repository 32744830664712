/* eslint-disable @admin-tribe/admin-tribe/comment-side-effects -- setting styling of close button */

import {navBus} from '@admin-tribe/acsc';
import {
  Button,
  Content,
  Divider,
  Flex,
  Header,
  Heading,
  Link,
  Provider,
  Text,
  darkTheme,
} from '@adobe/react-spectrum';
import {Drawer} from '@pandora/react-drawer';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';
import {generatePath} from 'react-router-dom';

import rootStore from 'core/RootStore';
import {PATH_EXPERT_SESSIONS_OPEN} from 'features/support/routing/supportPaths';

import StepTitleAndDescription from './step-title-and-description/StepTitleAndDescription';

const ServerSetupGuideDrawer = ({closeDrawer, onCreateServerButtonClick}) => {
  const intl = useIntl();

  const handleCreateServerButtonClick = () => {
    closeDrawer();
    onCreateServerButtonClick();
  };

  useEffect(() => {
    const ele = document.querySelector('#server-setup-dr > div > div:nth-child(1)');
    ele.style.marginLeft = '-10px';
    ele.style.paddingTop = '12px';
  }, []);

  return (
    <Provider colorScheme="dark" theme={darkTheme}>
      <Drawer
        aria-label={intl.formatMessage({
          id: 'packages.serverSetupGuide.title',
        })}
        id="server-setup-dr"
        style={{
          margin: 'calc(-1 * size-150)',
        }}
      >
        <Header>
          <Heading level={2} marginBottom="size-125" marginStart="size-200" marginTop="size-100">
            {intl.formatMessage({
              id: 'packages.serverSetupGuide.title',
            })}
          </Heading>
          <Divider marginBottom="size-100" size="S" />
          <Heading level={3} marginStart="size-200" marginTop="size-100">
            {intl.formatMessage({
              id: 'packages.serverSetupGuide.header',
            })}
          </Heading>
        </Header>
        <Content>
          {/* <!-- step 1 starts--> */}
          <Flex marginStart="size-50">
            <StepTitleAndDescription
              description={intl.formatMessage({
                id: 'packages.serverSetupGuide.downloadSetupDesc',
              })}
              stepNumber="1"
              title={intl.formatMessage({
                id: 'packages.serverSetupGuide.downloadStepTitle',
              })}
            />
          </Flex>
          {/* <!-- step 2 starts--> */}
          <Flex direction="column" marginTop="size-200">
            <StepTitleAndDescription
              description={intl.formatMessage({
                id: 'packages.serverSetupGuide.generateAuthReportDesc',
              })}
              stepNumber="2"
              title={intl.formatMessage({
                id: 'packages.serverSetupGuide.generateAuthReportTitle',
              })}
            />
          </Flex>
          {/* <!-- step 3 starts--> */}
          <Flex direction="column" marginTop="size-200">
            <StepTitleAndDescription
              description={intl.formatMessage({
                id: 'packages.serverSetupGuide.uploadAuthReportDesc',
              })}
              stepNumber="3"
              title={intl.formatMessage({
                id: 'packages.serverSetupGuide.uploadAuthReportTitle',
              })}
            />
            {/* Need to open create server component on button click */}
            <Button
              alignSelf="end"
              data-testid="create-server-button"
              marginEnd="size-200"
              marginTop="size-200"
              onPress={handleCreateServerButtonClick}
              variant="primary"
            >
              <Text>
                {intl.formatMessage({
                  id: 'packages.serverSetupGuide.uploadAuthReportLink',
                })}
              </Text>
            </Button>
          </Flex>
          {/* <!-- step 4 starts--> */}
          <Flex direction="column" marginTop="size-200">
            <StepTitleAndDescription
              description={intl.formatMessage({
                id: 'packages.serverSetupGuide.uploadATODesc',
              })}
              stepNumber="4"
              title={intl.formatMessage({
                id: 'packages.serverSetupGuide.uploadATOTitle',
              })}
            />
          </Flex>
          {/* <!-- schedule expert session--> */}
          <Flex marginStart="size-200" marginTop="size-300">
            <Text>
              {intl.formatMessage({
                id: 'packages.serverSetupGuide.expertSessionText',
              })}
            </Text>
            {/* Need to link with expert session screen */}
            <Link
              data-testid="expert-session-link"
              marginStart="size-50"
              onPress={() =>
                navBus.navigate(
                  generatePath(PATH_EXPERT_SESSIONS_OPEN, {
                    orgId: rootStore.organizationStore.activeOrgId,
                  })
                )
              }
            >
              {intl.formatMessage({
                id: 'packages.serverSetupGuide.expertSessionLink',
              })}
            </Link>
          </Flex>
        </Content>
      </Drawer>
    </Provider>
  );
};

ServerSetupGuideDrawer.propTypes = {
  /**
   * Callback function to close drawer
   */
  closeDrawer: PropTypes.func,
  /**
   * Callback function to handle Create Server Button Click
   */
  onCreateServerButtonClick: PropTypes.func,
};

export default ServerSetupGuideDrawer;
/* eslint-enable @admin-tribe/admin-tribe/comment-side-effects -- setting styling of close button */
