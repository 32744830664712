import {
  AuthenticatedUser,
  OrganizationList,
  feature,
  findAnyUserAccount,
  getUserAccount,
} from '@admin-tribe/acsc';

/**
 * Find the org ID from the path, if it is present
 *
 * @returns the orgID from the path, or null
 */
function getSelectedOrgIdFromPath() {
  // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- requires window
  const pathParams = window.location.pathname?.split('/');
  return pathParams?.find((param) => param.indexOf('@AdobeOrg') > 0);
}

function isActingAsAdobeAgentForOrg(orgId) {
  return (
    feature.isEnabled('temp_adobe_agent_access') &&
    AuthenticatedUser.get().getRoles().isActingAsAdobeAgentForOrg(orgId)
  );
}

/**
 * @description Look for a user account that represents the org from session storage, or default order.
 *
 * @param {LinkedUserAccountList} options.linkedUserAccountList Resolved LinkedUserAccountList instance
 * @param {OrganizationList} options.organizationList Resolved OrganizationList instance
 *
 * @returns {UserAccount} an object containing the orgId, userId, and an indicator if they're logged in
 *   as the correct profile.
 */
function getUserAccountForDefaultOrgId({linkedUserAccountList, organizationList}) {
  // Check stored org ID only with orgList, do not check linkedAccounts.
  // OrgList contains org list accessible with the current token, i.e.
  // token switch is not needed.
  // Checking both orgList and linkedAccount would cause CAUIP-11119 where
  // the user attempted to login to org/profile B and ended up with org A
  // because org A is stored in the session storage.
  const storedOrgId = OrganizationList.getActiveOrgIdFromSessionStorage();
  if (
    (storedOrgId && organizationList.has(storedOrgId)) ||
    isActingAsAdobeAgentForOrg(storedOrgId)
  ) {
    return {
      currentUser: true,
      orgId: storedOrgId,
      userId: AuthenticatedUser.get().getId(),
    };
  }

  // There's no org available from the URL or from session storage.
  // Attempt to find any available org from the provided orgList or linkedAccounts
  return findAnyUserAccount({
    linkedUserAccountList,
    organizationList,
  });
}

/**
 * @description Look for a user account that represents the specific requested org from the URL.
 *
 * @param {OrganizationList} orgData.organizationList Resolved OrganizationList instance
 * @param {LinkedUserAccountList} orgData.linkedUserAccountList Resolved LinkedUserAccountList instance
 *
 * @returns {UserAccount} an object containing the orgId, userId, and an indicator if they're logged in
 *   as the correct profile.
 */
function getUserAccountForSelectedOrgId({linkedUserAccountList, organizationList}) {
  // Assume that the user must go to the orgID if it's available from the URL.
  // Thus, find it from orgList first, then from linkedAccounts.
  // Otherwise, we'll return null.
  let userAccount;
  const selectedOrgId = getSelectedOrgIdFromPath();
  if (selectedOrgId) {
    userAccount = getUserAccount({
      linkedUserAccountList,
      organizationList,
      orgId: selectedOrgId,
    });

    // If the user is an Adobe Agent, we let them through without an account
    if (!userAccount) {
      const authedUser = AuthenticatedUser.get();
      if (authedUser.getRoles().isAnyAdobeAgent()) {
        userAccount = {
          actingAsAgent: true,
          currentUser: true,
          orgId: selectedOrgId,
          userId: authedUser.getId(),
        };
      }
    }
  }
  return userAccount;
}

/**
 * @description Look for a user account to present as the active organization.
 *
 * @param {OrganizationList} orgData.organizationList Resolved OrganizationList instance
 * @param {LinkedUserAccountList} orgData.linkedUserAccountList Resolved LinkedUserAccountList instance
 *
 * @returns {UserAccount} an object containing the orgId, userId, and an indicator if they're logged in
 *   as the correct profile.
 */
function getActiveUserAccount({linkedUserAccountList, organizationList}) {
  let userAccount = getUserAccountForSelectedOrgId({
    linkedUserAccountList,
    organizationList,
  });
  if (!userAccount) {
    userAccount = getUserAccountForDefaultOrgId({
      linkedUserAccountList,
      organizationList,
    });
  }
  return userAccount;
}

// eslint-disable-next-line import/prefer-default-export -- utils functions
export {getActiveUserAccount};
