import {EVENT_ACTION} from '@admin-tribe/acsc';
import {Button, View} from '@adobe/react-spectrum';
import ChatIcon from '@spectrum-icons/workflow/Chat';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import SelfCancelCard from '../card/SelfCancelCard';
import {useSelfCancelAnalyticsContext} from '../self-cancel-analytics-context/SelfCancelAnalyticsContext';

import styles from './Card.pcss';
import SaveOffersHeader from './SaveOffersHeader';

/**
 * Component to render the Chat card.
 */
const ChatCard = ({index, onCTA}) => {
  const intl = useIntl();
  const {dispatchSelfCancelUiEventAnalytics} = useSelfCancelAnalyticsContext();
  const CARD_TYPE = 'CHAT';

  const onSetUpSessionPress = () => {
    dispatchSelfCancelUiEventAnalytics({
      card: {index, type: CARD_TYPE},
      eventAction: EVENT_ACTION.CLICK,
      eventName: `cancelFlowOffersSetSession`,
    });

    onCTA?.();
  };

  return (
    <SelfCancelCard
      headerBackgroundColor="gray-700"
      headerComponent={<SaveOffersHeader logo={<ChatIcon alt="" size="L" />} />}
      testId="chat-card"
      title={
        <span styleName="title-text">
          {intl.formatMessage({
            id: 'account.selfCancel.components.saveOfferCard.chat.title',
          })}
        </span>
      }
    >
      <View
        marginBottom="size-50"
        minHeight="size-1700"
        // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needed for styling text
        UNSAFE_className={styles['description-text']}
      >
        {intl.formatMessage({
          id: 'account.selfCancel.components.saveOfferCard.chat.content',
        })}
      </View>
      <Button
        alignSelf="flex-end"
        data-testid="setup-session-button"
        marginTop="auto"
        onPress={onSetUpSessionPress}
        variant="primary"
      >
        {intl.formatMessage({
          id: 'account.selfCancel.components.saveOfferCard.chat.startChatButton',
        })}
      </Button>
    </SelfCancelCard>
  );
};

ChatCard.propTypes = {
  /**
   * The location of the card in the carousel.
   */
  index: PropTypes.number,
  /**
   * Handler that is called when the user press the CTA.
   */
  onCTA: PropTypes.func,
};

export default ChatCard;
