import {Locale, authentication, eventBus, localeUtils, log} from '@admin-tribe/acsc';
import {showError, showSuccess} from '@admin-tribe/acsc-ui';
import {getContractTermsAcceptance, putContractAcceptTerms} from '@pandora/data-model-contract';
import {useAuth} from '@pandora/react-auth-provider';
import {useEnv} from '@pandora/react-env-provider';
import {useCallback, useState} from 'react';

import {CART_EVENT} from 'features/offers/freeOfferCartConstants';

/**
 * Converts a JavaScript Date object to a Java ISO date string.
 * @param {Date} date - The JavaScript Date object to be converted.
 * @returns {string} The Java ISO date string in the format "YYYY-MM-DDTHH:MM:SSZ".
 */
const convertDateToJavaISO = (date) => {
  date.setMilliseconds(0);
  return date.toISOString().replace('.000Z', 'Z');
};

const getPutBody = ({contract, locale, termsToAccept, userId}) => {
  const externalLocale = localeUtils.getDateFnsLocaleString(locale).replace('-', '_');
  const templateRevisionContentId = termsToAccept.needsAcceptance.templateDetails?.contents.find(
    (content) => content.locale === externalLocale
  );

  const payload = {
    acceptanceDate: convertDateToJavaISO(new Date()),
    acceptorId: userId,
    clientId: authentication.getClientId(),
    contextEntityType: 'LEGAL_CONTRACT',
    contextEntityValue: contract.id,
    status: 'ACCEPTED',
    templateName: termsToAccept.templateName,
    templateRevision: termsToAccept.needsAcceptance.templateDetails.templateRevision,
    templateRevisionContentId: templateRevisionContentId.id,
  };
  return JSON.stringify(payload);
};

/**
 * Custom hook for accepting linked membership terms and conditions.
 */
const useAcceptLinkedMembershipTerms = ({contract, successMessage}) => {
  const [isPending, setIsPending] = useState(false);
  const auth = useAuth();
  const env = useEnv();
  const locale = Locale.get().activeLocaleCode;
  const userId = auth?.getUserProfile()?.userId;

  const onAcceptTerms = useCallback(
    async ({closeDialog}) => {
      setIsPending(true);
      const termsToAccept = getContractTermsAcceptance(contract, 'tc_vipmp_linked_membership');

      try {
        const fetchOptions = {
          accessToken: auth.getAccessToken(),
          apiKey: authentication.getClientId(),
          env,
        };
        const requestInit = {
          body: getPutBody({contract, locale, termsToAccept, userId}),
          headers: {
            'Content-Type': 'application/json',
          },
        };
        await putContractAcceptTerms({contract, requestInit}, fetchOptions);
        showSuccess(successMessage);
        closeDialog();
        // Refresh the account page to reflect the changes
        eventBus.emit(CART_EVENT.SUBMIT, this);
      } catch (error) {
        log.error('Failed to accept linked membership terms', error);
        showError();
      } finally {
        setIsPending(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only contract id is needed
    [contract.id]
  );

  return {isPending, onAcceptTerms};
};

export default useAcceptLinkedMembershipTerms;
