import {OverlayWait, TableSectionTable} from '@admin-tribe/acsc-ui';
import {Text} from '@adobe/react-spectrum';
import {SELECTION_MODE, TableSection} from '@pandora/react-table-section';
import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';

import NotifyAdminsSwitchButton from 'features/settings/common/components/notify-admins-switch-button/NotifyAdminsSwitchButton';
import {useTrusteeListContext} from 'features/settings/common/components/trustee-list-context/TrusteeListContext';
import TrusteeListTableActions from 'features/settings/common/components/trustee-list-table/TrusteeListTableActions';
import useTrusteeTable from 'features/settings/common/components/trustee-list-table/hooks/useTrusteeTable';

const TrusteeListTable = () => {
  const intl = useIntl();
  const {trusteeList, loadTrusteeList, tableOptions} = useTrusteeListContext();
  const {onTableSectionChange} = useTrusteeTable();

  const columnDescriptor = [
    {
      key: 'organizationName',
      props: {allowsSorting: true},
    },
    {
      key: 'shareAccessTo',
    },
    {
      key: 'trusteeAdminEmail',
    },
    {
      key: 'newUserNotification',
    },
  ];

  const renderers = {
    newUserNotification: ({item}) => <NotifyAdminsSwitchButton trustee={item} />,
    organizationName: ({item}) => <Text>{item.trusteeOrgName}</Text>,
    shareAccessTo: ({item}) => <Text>{item.directoryName}</Text>,
    trusteeAdminEmail: ({item}) => <Text>{item.trusteeOrgSysAdminEmail}</Text>,
  };

  // get the list of trusted directories
  useEffect(() => {
    loadTrusteeList({}, {updateCount: true});

    // eslint-disable-next-line react-hooks/exhaustive-deps -- will run only once during the initial render
  }, []);

  return (
    <OverlayWait isLoading={trusteeList.isLoading} showContent>
      {(trusteeList.trusteeListData.items || trusteeList.hasLoadingError) && (
        <TableSection
          getItemKey={
            // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- not testing this
            // istanbul ignore next -- not testing the internal getItemKey
            (item) => `${item.directoryId}/${item.trusteeOrgId}`
          }
          isServerError={trusteeList.hasLoadingError}
          items={trusteeList?.trusteeListData?.items?.map((item) => ({
            ...item,
            // TableSections require all items to have a key property
            key: `${item.directoryId}/${item.trusteeOrgId}`,
          }))}
          onTableSectionChange={onTableSectionChange}
          pageNumber={tableOptions.page}
          pageSizeOptions={tableOptions.pageSizeOptions}
          selectionMode={SELECTION_MODE.MULTIPLE}
          totalPages={tableOptions.totalPages}
        >
          <TrusteeListTableActions />
          <TableSectionTable
            aria-label={intl.formatMessage({id: 'settings.trusteeTable.ariaLabel'})}
            columnDescriptor={columnDescriptor}
            columnNamespace="settings.trusteeTable"
            noItemsFoundContentMessage={intl.formatMessage({
              id: 'settings.trusteeTable.noItemsFoundContentMessage',
            })}
            noSearchResultsContentMessage={intl.formatMessage({
              id: 'settings.trusteeTable.noSearchResultsContentMessage',
            })}
            renderers={renderers}
          />
        </TableSection>
      )}
    </OverlayWait>
  );
};

TrusteeListTable.displayName = 'TrusteeListTable';

export default TrusteeListTable;
