import {navBus} from '@admin-tribe/acsc';
import {Item, TabList, TabPanels, Tabs} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, {useEffect, useMemo} from 'react';
import {useIntl} from 'react-intl';
import {generatePath, useLocation} from 'react-router-dom';

import {usePregeneratedPackagesPageContext} from 'features/packages/pages/pregenerated-packages/PregeneratedPackagesPageContext';
import {TAB_KEYS} from 'features/packages/pages/pregenerated-packages/PregeneratedPackagesTabConstants';
import SingleApplicationSubPage from 'features/packages/pages/subpages/SingleApplicationSubPage';
import TemplatesSubPage from 'features/packages/pages/subpages/TemplatesSubPage';
import {
  PATH_PREGENERATED_PACKAGES_SINGLE_APPS,
  PATH_PREGENERATED_PACKAGES_TEMPLATES,
  PATH_SINGLE_APPS,
} from 'features/packages/routing/packagesPaths';

const findTabByKey = (tabs, key) => tabs.find((tab) => tab.key === key);

/** Tabs for the Pre-generated packages Page */
const PregeneratedPackagesTabs = observer(({orgId}) => {
  const location = useLocation();
  const intl = useIntl();
  const {packagesAdobeTemplatesStore} = usePregeneratedPackagesPageContext();

  const tabs = useMemo(
    () => [
      {
        content: <SingleApplicationSubPage />,
        key: TAB_KEYS.SINGLE_APPLICATION,
        name: intl.formatMessage({
          id: 'packages.pregeneratedPackages.singleApplication',
        }),
        pathname: generatePath(PATH_PREGENERATED_PACKAGES_SINGLE_APPS, {orgId}),
      },
      {
        content: <TemplatesSubPage />,
        key: TAB_KEYS.TEMPLATES,
        name: intl.formatMessage({
          id: 'packages.pregeneratedPackages.templates',
        }),
        pathname: generatePath(PATH_PREGENERATED_PACKAGES_TEMPLATES, {orgId}),
      },
    ],
    [intl, orgId]
  );

  /** Keep the currently selected tab in sync with the URL */
  useEffect(() => {
    const determineKeyByPath = (urlLocation) =>
      urlLocation.pathname.includes(PATH_SINGLE_APPS)
        ? TAB_KEYS.SINGLE_APPLICATION
        : TAB_KEYS.TEMPLATES;
    packagesAdobeTemplatesStore.setCurrentTab(determineKeyByPath(location));
    // eslint-disable-next-line react-hooks/exhaustive-deps -- -- run only on mount
  }, [location]);

  const onChange = (key) => {
    if (key === packagesAdobeTemplatesStore.currentTab) {
      // Currently on the tab that was clicked, don't navigate
      return;
    }

    const tab = findTabByKey(tabs, key);

    // Just modify the URL, the key will get updated in the useEffect above
    if (tab) {
      navBus.pushState({url: tab.pathname});
      packagesAdobeTemplatesStore.setCurrentTab(tab.key);
    }
  };

  return (
    <Tabs
      aria-label={intl.formatMessage({
        id: 'packages.pregeneratedPackages.tabbedNav.label',
      })}
      onSelectionChange={onChange}
      selectedKey={packagesAdobeTemplatesStore.currentTab}
    >
      <TabList>
        {tabs.map((tab) => (
          <Item key={tab.key}>{tab.name}</Item>
        ))}
      </TabList>
      <TabPanels>
        {tabs.map((tab) => (
          <Item key={tab.key}>{tab.content}</Item>
        ))}
      </TabPanels>
    </Tabs>
  );
});

PregeneratedPackagesTabs.propTypes = {
  orgId: PropTypes.string.isRequired,
};

export default PregeneratedPackagesTabs;
