import {feature} from '@admin-tribe/acsc';
import UserArrowIcon from '@spectrum-icons/workflow/UserArrow';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {getIntroductionsData} from 'common/services/sophia/shared-contextual-params/sharedContextualParamsUtils';
import {canViewUserIntroductions} from 'core/user/access/userAccess';
import {goToUserIntroductions} from 'features/overview/routing/navigation-callbacks/navigationCallbacks';

import QuickLink from '../QuickLink';

const UserIntroductionsQuickLink = ({count}) => {
  const intl = useIntl();

  return (
    <QuickLink
      analyticsOptions={{
        analyticsAction: 'pending-user-introductions',
      }}
      data-testid="quick-link-pending-user-introductions"
      Icon={<UserArrowIcon size="M" />}
      label={intl.formatMessage(
        {id: 'overview.quickLinks.pendingUserIntroductions.title'},
        {count}
      )}
      onPress={() => goToUserIntroductions()}
    />
  );
};

UserIntroductionsQuickLink.propTypes = {
  /**
   * The number of pending user introductions in the organization
   */
  count: PropTypes.number,
};

UserIntroductionsQuickLink.get = async () => {
  if (!canViewUserIntroductions()) {
    return Promise.reject(new Error('User cannot view user introductions'));
  }

  // if we have no ignored introductions, at least one pending introduction, but not so many actionable that
  // probably they are not interested in the action.
  const actionsData = await getIntroductionsData();

  // if we are using the new saved intros quicklink feature, we exit if that would display instead
  if (feature.isEnabled('temp_can_use_saved_introductions_quicklink')) {
    if (actionsData.ignoredIntroductionsCount > 0) {
      return Promise.reject(new Error('Invalid number of user introductions'));
    }
  }

  if (
    actionsData.actionableIntroductionsCount > 0 &&
    actionsData.actionableIntroductionsCount <= 10
  ) {
    return Promise.resolve(
      <UserIntroductionsQuickLink
        key="UserIntroductionsQuickLink"
        count={actionsData.actionableIntroductionsCount}
      />
    );
  }
  return Promise.reject(new Error('Invalid number of user introductions'));
};

export default UserIntroductionsQuickLink;
