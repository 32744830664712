import {LicenseGroupList, feature} from '@admin-tribe/acsc';
import {RULE_STATUS, TRIGGERS_TYPE} from '@pandora/data-model-acrs';
import {useAsyncModel} from '@pandora/react-async-model';
import {useMemo} from 'react';

import rootStore from 'core/RootStore';

/**
 * @description Request body for calling ACRS
 *
 * @param {Product} product product for the rule
 * @param {LicenseGroupList} licenseGroupList license group list from which the
 *     first one license group would be chosen
 * @param {boolean} notificationsEnabled are notifications enabled for the rule
 *
 * @returns {Object} request body
 */
const generateCreateRequestBody = (product, licenseGroupList, notificationsEnabled) => ({
  label: `Rule for ${product.shortName}`,
  licenseId: product.id,
  notificationsEnabled: feature.isEnabled('temp_jit_widget_notifications')
    ? notificationsEnabled
    : undefined,
  productProfile: licenseGroupList?.items?.[0]?.id,
  status: RULE_STATUS.ACTIVE,
  triggers: TRIGGERS_TYPE.ON_DEMAND_OR_URL,
});

/**
 * @description Get product for the target product arrangement code
 *
 * Note - This method will only find Adobe created rules, as this widget is
 * only concerned with those.
 *
 * The function would return undefined in the following cases, since we cannot
 * tell which rule to pick
 * 1. If there is no matching product
 * 2. If there are none or multiple rules from matched products
 *
 * @returns {Product|undefined} product or undefined
 */
const getProduct = (productArrangementCode) => {
  const productList = rootStore.organizationStore.productList;

  const matchingProducts = productList.items.filter(
    (product) => product.productArrangementCode === productArrangementCode
  );
  if (matchingProducts.length === 1) {
    return matchingProducts[0];
  }

  return undefined;
};

/**
 * @description Hook to fetch license groups for a product
 *
 * Note: This hook would not make the API call, or call useAsyncModel with a
 * valid load function, until the product is defined
 *
 * @param {Product} product product for which license groups are to be fetched
 *
 * @returns {Object} values from useAsyncModel
 */
const useLicenseGroups = (product) => {
  const fetchLicenseGroupList = useMemo(() => {
    if (product) {
      return () =>
        LicenseGroupList.get({
          orgId: rootStore.organizationStore.activeOrgId,
          product,
        });
    }
    return null;
  }, [product]);

  return useAsyncModel({
    loadFn: fetchLicenseGroupList,
  });
};

export {getProduct, useLicenseGroups, generateCreateRequestBody};
