const GAINSIGHT_EVENT_TYPES = {
  CUSTOM_BUTTON_CLICK: 'customButtonClick',
  ENGAGEMENT_COMPLETED: 'engagementCompleted',
  ENGAGEMENT_VIEW: 'engagementView',
  ENGAGEMENT_VIEWED_STEP: 'engagementViewedStep',
  LINK_CLICK: 'linkClick',
  SURVEY_RESPONDED: 'surveyResponded',
};

const PANEL_MANAGER = {
  DRAWER: {
    CLOSE: 'DRAWER.CLOSE',
    OPEN: 'DRAWER.OPEN',
  },
  MODAL: {
    CLOSE: 'MODAL.CLOSE',
    OPEN: 'MODAL.OPEN',
  },
  PULLDOWN: {
    CLOSE: 'PULLDOWN.CLOSE',
    OPEN: 'PULLDOWN.OPEN',
  },
  RAIL: {
    CLOSE: 'RAIL.CLOSE',
    OPEN: 'RAIL.OPEN',
  },
  TYPE: {
    DRAWER: 'PANEL.DRAWER',
    MODAL: 'PANEL.MODAL',
    PULLDOWN: 'PANEL.PULLDOWN',
    RAIL: 'PANEL.RAIL',
  },
};

export {GAINSIGHT_EVENT_TYPES, PANEL_MANAGER};
