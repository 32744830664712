import binkyUI from '@admin-tribe/acsc-ui';
import {Content, Flex, Text, View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import styles from './DeactivateDevicesModalContent.pcss';

const DeactivateDevicesModalContent = ({selectedCount, selectedDevices}) => {
  const GoUrl = binkyUI.common.components.GoUrl;
  const intl = useIntl();

  return (
    <Flex data-testid="deactivate-devices-modal-content" direction="row" marginTop="2rem">
      <View>
        <Content>
          {intl.formatMessage(
            {id: 'products.details.devices.deactivateDevices.messageFormat.modalContent1'},
            {count: selectedCount}
          )}
          <View data-testid="description">
            <ul className={styles['device-list']}>
              {selectedDevices.map((device) => (
                <li key={device}>{device}</li>
              ))}
            </ul>
            <Text>
              {intl.formatMessage(
                {id: 'products.details.devices.deactivateDevices.messageFormat.modalContent2'},
                {count: selectedCount}
              )}
            </Text>
          </View>
          <View marginTop="1rem">
            <Text>
              {intl.formatMessage(
                {id: 'products.details.devices.deactivateDevices.learnMore'},
                {
                  goUrl: (str) => <GoUrl name="manage_device_licenses">{str}</GoUrl>,
                }
              )}
            </Text>
          </View>
        </Content>
      </View>
    </Flex>
  );
};

DeactivateDevicesModalContent.propTypes = {
  /**
   * count for number of devices selected to deactivate
   */
  selectedCount: PropTypes.number.isRequired,
  /**
   * list of selected devices name to deactivate
   */
  selectedDevices: PropTypes.arrayOf(PropTypes.string).isRequired,
};
export default DeactivateDevicesModalContent;
