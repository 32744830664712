import {authentication, navBus} from '@admin-tribe/acsc';
import {Bumper} from '@admin-tribe/acsc-ui';
import PropTypes from 'prop-types';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {useParams} from 'react-router-dom';

/** A generic bumper component used for when errors occur within the application */
const GenericErrorBumper = ({details, title}) => {
  const intl = useIntl();
  const {orgId} = useParams();

  const onCtaClick = useCallback(() => {
    const destination = orgId ? `/${orgId}/overview` : '/';

    navBus.navigate(destination);
  }, [orgId]);

  const onSecondaryClick = useCallback(() => {
    authentication.signOut();
  }, []);

  return (
    <Bumper
      ctaButtonLabel={intl.formatMessage({id: 'shell.bumper.notFound.goToOverview'})}
      details={details}
      onButtonClick={onCtaClick}
      onSecondaryButtonClick={onSecondaryClick}
      secondaryLabel={intl.formatMessage({id: 'shell.bumper.notFound.signOut'})}
      title={title}
    />
  );
};

GenericErrorBumper.propTypes = {
  /** A description for the bumper. Uses the default value if not provided. */
  details: PropTypes.string,
  /** A title for the bumper. Uses the default value if not provided. */
  title: PropTypes.string,
};

export default GenericErrorBumper;
