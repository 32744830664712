// ***********************************************************************
// ADOBE CONFIDENTIAL
// ___________________
//
// Copyright 2024 Adobe
// All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains
// the property of Adobe and its suppliers, if any. The intellectual
// and technical concepts contained herein are proprietary to Adobe
// and its suppliers and are protected by all applicable intellectual
// property laws, including trade secret and copyright laws.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Adobe.
// ************************************************************************
import {View} from '@adobe/react-spectrum';
import React from 'react';

import {useJsonPayload} from 'common/components/sophia/sophia-promo/JsonPayloadProvider';

const SophiaPromoGradient = () => {
  const {payload} = useJsonPayload();

  // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- disabled for resolving merge conflicts
  // istanbul ignore next
  if (!payload) {
    return null;
  }

  return payload.backgroundImage ? (
    <View
      borderTopEndRadius="regular"
      borderTopStartRadius="regular"
      data-testid="one-console-promo-gradient"
      height="static-size-65"
      left="size-0"
      position="absolute"
      top="size-0"
      UNSAFE_style={{
        background: `url(${payload.backgroundImage})`,
        backgroundSize: 'cover',
      }}
      width="100%"
    />
  ) : null;
};

SophiaPromoGradient.propTypes = {};

export default SophiaPromoGradient;
