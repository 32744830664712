import {Contract, ProductList, feature} from '@admin-tribe/acsc';
import {BulletList, BulletListItem, DATE_FORMATS, GoUrl, ModalContent} from '@admin-tribe/acsc-ui';
import {Flex, Heading, View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import SafePrice from 'common/components/safe-price/SafePrice';

import {EVENT_NAME} from '../SelfCancelAnalyticsUtils';
import {PRICE_FAILURE, SELF_CANCEL_STEPS} from '../SelfCancelConstants';
import MerchandisingText from '../components/merchandising-text/MerchandisingText';
import {
  ACTIONS,
  useProductsChangeContext,
} from '../components/products-change-context/ProductsChangeContext';
import SafeFormattedDateAndTime from '../components/safe-formatted-date-and-time/SafeFormattedDateAndTime';
import {useSelfCancelAnalyticsContext} from '../components/self-cancel-analytics-context/SelfCancelAnalyticsContext';
import SelfCancelBaseFooter from '../components/self-cancel-base-modal/SelfCancelBaseFooter';
import SelfCancelBaseModal from '../components/self-cancel-base-modal/SelfCancelBaseModal';
import SelfCancelCart from '../components/self-cancel-cart/SelfCancelCart';
import useRetentionCart from '../hooks/use-retention-cart/useRetentionCart';
import useRetentionDetails from '../hooks/use-retention-details/useRetentionDetails';

import styles from './SaveOfferReviewStep.pcss';

/**
 * Self cancel workflow step component for the save offers review.
 */
const SaveOfferReviewStep = ({
  comment,
  contract,
  currentStep,
  onClose,
  onConfirm,
  onPrevious,
  productList,
  retentionId,
  selectedReasons,
  selectedSeats,
  steps,
}) => {
  const intl = useIntl();

  const {dispatchProductsChange, error, isLoading, productsChange} = useProductsChangeContext();
  const [errorMessage, setErrorMessage] = useState();
  const {dispatchNavigationAnalyticsEvent, dispatchSelfCancelPageAnalytics} =
    useSelfCancelAnalyticsContext();

  const retention = useRetentionDetails({contract, retentionId});

  const {items, recurrenceTerm, total} = useRetentionCart({contract, productList, retention});

  const {
    currency,
    discountPercent,
    discountNextBillingDate,
    discountStartDate,
    nextPrice,
    taxTerm,
    termsAndConditions,
  } = retention;

  let footerText;

  // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- not testing this
  /* istanbul ignore next -- not testing this */
  if (feature.isEnabled('temp_fix_save_offer_footer_tnc')) {
    footerText = termsAndConditions
      ? `${termsAndConditions}`
          .replace('{offerPrice}', '<Price></Price>')
          .replace('{price}', '<Price></Price>')
          .replace(
            '{renewalDate, date, ::MMMMddyyyy}',
            intl.formatDate(new Date(discountNextBillingDate), DATE_FORMATS.default)
          )
      : PRICE_FAILURE;
  } else {
    footerText = termsAndConditions
      ? `${termsAndConditions}`.replace('{{price}}', '<Price></Price>')
      : PRICE_FAILURE;
  }

  // for handling when user commits to the retention offer
  useEffect(() => {
    if (!isLoading) {
      if (error) {
        setErrorMessage(
          intl.formatMessage({
            id: 'account.selfCancel.saveOfferReview.submit.error',
          })
        );
      } else if (productsChange.submitted) {
        onConfirm?.();
      }
    }
  }, [error, intl, isLoading, onConfirm, productsChange]);

  // Send page load analytics when component is loaded
  useEffect(() => {
    dispatchSelfCancelPageAnalytics({
      retentionId,
      step: SELF_CANCEL_STEPS.SAVE_OFFER_REVIEW_STEP,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Explicitly only execute once on component load
  }, []);

  const onCta = () => {
    dispatchNavigationAnalyticsEvent({clickType: EVENT_NAME.CONFIRM, retentionId});
    dispatchProductsChange({
      comment,
      retentionId,
      selectedReasons,
      selectedSeats,
      type: ACTIONS.SUBMIT_SAVE_OFFERS,
    });
  };

  const onCancel = () => {
    dispatchNavigationAnalyticsEvent({clickType: EVENT_NAME.CANCEL, retentionId});
    onClose?.();
  };

  const onCloseError = () => {
    setErrorMessage();
  };

  const onSecondary = () => {
    dispatchNavigationAnalyticsEvent({clickType: EVENT_NAME.PREVIOUS, retentionId});
    onPrevious?.();
  };

  return (
    <SelfCancelBaseModal
      cancelLabel={intl.formatMessage({id: 'common.modal.buttons.close'})}
      ctaLabel={intl.formatMessage({id: 'account.selfCancel.discountOffer.agreeAndSubscribe'})}
      currentStep={currentStep}
      errorMessage={errorMessage}
      heading={intl.formatMessage({id: 'account.selfCancel.discountOffer.title'})}
      isCtaDisabled={isLoading || !!errorMessage}
      isLoading={isLoading}
      onCancel={onCancel}
      onCloseError={onCloseError}
      onCta={onCta}
      onSecondary={onSecondary}
      secondaryLabel={intl.formatMessage({id: 'account.selfCancel.buttons.previous'})}
      steps={steps}
      subheading={intl.formatMessage({id: 'account.selfCancel.discountOffer.description'})}
    >
      <ModalContent>
        <Flex gap="size-300">
          <View flexGrow="1">
            <Heading
              level={3}
              // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needed for styling text
              UNSAFE_className={styles.heading}
            >
              {intl.formatMessage({
                id: 'account.selfCancel.discountOffer.detailsTitle',
              })}
            </Heading>
            <BulletList>
              <BulletListItem data-testid="all-licenses-discounted-bullet-point">
                {intl.formatMessage({
                  id: 'account.selfCancel.discountOffer.allLicensesDiscounted',
                })}
              </BulletListItem>
              <BulletListItem data-testid="dates-remained-bullet-point">
                {intl.formatMessage({
                  id: 'account.selfCancel.discountOffer.datesRemained',
                })}
              </BulletListItem>
              <BulletListItem data-testid="discount-applied-bullet-point">
                {intl.formatMessage(
                  {id: 'account.selfCancel.discountOffer.discountApplied'},
                  {
                    discountPercent,
                    discountStartDate: () => (
                      <span styleName="bolded-bullet">
                        <SafeFormattedDateAndTime value={discountStartDate} />
                      </span>
                    ),
                  }
                )}
              </BulletListItem>
            </BulletList>
          </View>
          <View flexShrink="0" width="340px">
            <Heading
              level={3}
              marginBottom="size-150"
              // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needed for styling text
              UNSAFE_className={styles.heading}
            >
              {intl.formatMessage({
                id: 'account.selfCancel.components.selfCancelCart.title',
              })}
            </Heading>
            <SelfCancelCart
              currency={currency}
              items={items}
              recurrenceTerm={recurrenceTerm}
              total={total}
            />
          </View>
        </Flex>
      </ModalContent>
      <SelfCancelBaseFooter>
        <div styleName="terms-and-conditions">
          {/* eslint-disable-next-line @admin-tribe/admin-tribe/extract-large-computations -- complex string styling */}
          {intl.formatMessage(
            {id: 'account.selfCancel.discountOffer.termsAndConditions'},
            {
              GoUrlSubscriptionTerms: (linkText) => (
                <GoUrl name="subscriptionterms" noWrap={false}>
                  {linkText}
                </GoUrl>
              ),
              GoUrlTerms: (linkText) => (
                <GoUrl name="terms" noWrap={false}>
                  {linkText}
                </GoUrl>
              ),
              RetentionTerms: () => (
                <span>
                  <MerchandisingText
                    message={footerText}
                    values={{
                      Price: () => (
                        <SafePrice
                          currency={currency}
                          isTaxInclusive={false}
                          overrideStyles={styles}
                          price={nextPrice}
                          taxTerm={taxTerm}
                        />
                      ),
                    }}
                  />
                </span>
              ),
            }
          )}
        </div>
      </SelfCancelBaseFooter>
    </SelfCancelBaseModal>
  );
};

SaveOfferReviewStep.propTypes = {
  /**
   * Comment provided by the admin as to why they are cancelling licenses
   */
  comment: PropTypes.string.isRequired,
  /**
   * The org's contract.
   */
  contract: PropTypes.instanceOf(Contract).isRequired,

  /**
   * Current step in the flow.
   */
  currentStep: PropTypes.string,
  /**
   * Handler that is called when the user cancels out from the modal.
   */
  onClose: PropTypes.func,
  /**
   * Handler that is called when the user accept the discount save offer.
   */
  onConfirm: PropTypes.func,
  /**
   * Handler that is called when the user wants to navigate back.
   */
  onPrevious: PropTypes.func,
  /**
   * The list of org's products.
   */
  productList: PropTypes.instanceOf(ProductList).isRequired,
  /**
   * The id of the retention offer that the admin reviewing
   */
  retentionId: PropTypes.string.isRequired,
  /**
   * The reasons why an admin wants to cancel licenses
   */
  selectedReasons: PropTypes.arrayOf(PropTypes.string),
  /**
   * The hash map of of initially selected licenses per product id.
   */
  selectedSeats: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  /**
   * List of steps in the flow.
   */
  steps: PropTypes.arrayOf(PropTypes.string),
};

export default SaveOfferReviewStep;
