import {AnalyticsEvent} from '@admin-tribe/acsc';
import {Text} from '@adobe/react-spectrum';
import {ContentContext} from '@pandora/react-content-provider';
import {Item, SearchAutocomplete, Section} from '@react-spectrum/autocomplete';
import LinkOutIcon from '@spectrum-icons/workflow/LinkOut';
import React, {useContext, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';

import styles from './SearchHelpTopics.pcss';
import {getDefaultTopics, isSearchInputNotMinLength} from './searchTopicHelper';
import {useFetchHelpTopicsData} from './useFetchHelpTopicsData';

/**
 * Search help topics common component
 */
const SearchHelpTopics = () => {
  const intl = useIntl();
  const {locale} = useContext(ContentContext);

  const [ignoreNextInput, setIgnoreNextInput] = useState(false);
  const [loadingState, setLoadingState] = useState('idle');
  const [searchInputValue, setSearchInputValue] = useState('');
  const defaultTopics = useMemo(() => getDefaultTopics(intl, locale), [intl, locale]);
  const [searchTopics, setSearchTopics] = useState(defaultTopics);

  const triggerSearchAnalytics = (method, query) => {
    // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- tabel@ to update
    // istanbul ignore block
    AnalyticsEvent.dispatch({
      attributes: {
        searchQuery: query,
      },
      componentMethod: method,
      componentMethodType: 'submit',
      componentName: 'appSearchHelpTopics',
      pageContext: 'search help',
    });
  };

  const onInputChange = (inputValue) => {
    // to fetch dynamic search topics
    // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- tabel@ to update
    /* istanbul ignore next */
    const FetchSearchData = async () => {
      setLoadingState('loading');
      const fetchData = await useFetchHelpTopicsData(inputValue);

      const getSearchTopics = [];

      if (fetchData) {
        getSearchTopics.push({
          children: fetchData,
          id: 'dynamicTopic',
          title: intl.formatMessage({id: 'search.defaultTopics.articles'}),
        });
      }

      setSearchTopics(getSearchTopics);
      setLoadingState('idle');
    };

    if (ignoreNextInput) {
      setIgnoreNextInput(false);
    } else {
      setSearchInputValue(inputValue);
      if (!inputValue) {
        setSearchTopics(defaultTopics);
      } else if (isSearchInputNotMinLength(inputValue)) {
        triggerSearchAnalytics('onInputChange.search', inputValue);
      } else {
        triggerSearchAnalytics('onInputChange.default', inputValue);

        FetchSearchData();
      }
    }
  };

  const onSubmit = (query, key) => {
    let searchRedirectUrl;
    if (key) {
      const selectedTopic = searchTopics[0].children.find((topic) => String(topic.id) === key);
      searchRedirectUrl = selectedTopic.link;
    } else {
      const q = encodeURIComponent(searchInputValue);
      const facetsFields = encodeURIComponent('["applicable_products"]');
      const postFacetsFields = encodeURIComponent(
        '{"applicable_products":["Adobe Enterprise & Teams"]}'
      );
      const scope = encodeURIComponent('["helpx"]');

      const qParams =
        `q=${q}&facets_fields=${facetsFields}` +
        `&post_facet_filters=${postFacetsFields}&scope=${scope}`;

      searchRedirectUrl = `https://helpx.adobe.com/search-results.html?${qParams}`;
    }

    // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- callback
    window.open(searchRedirectUrl, '_blank');

    // ensure input does not update when user selects item...
    setIgnoreNextInput(true);
  };

  return (
    <SearchAutocomplete
      aria-label={intl.formatMessage({
        id: 'search.searchHelpTopics.searchLabel',
      })}
      data-testid="search-topics-menu"
      inputMode="search"
      items={searchTopics}
      loadingState={loadingState}
      marginTop="size-250"
      menuTrigger="focus"
      onInputChange={onInputChange}
      onSubmit={onSubmit}
      type="search"
      // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- alpha styles are breaking
      UNSAFE_className={styles.autocomplete}
      value={searchInputValue}
      width="100%"
    >
      {/* eslint-disable-next-line @admin-tribe/admin-tribe/extract-large-computations -- needed logic*/}
      {(item) => (
        <Section
          key={item.id}
          data-testid="search-result-item"
          items={item.children}
          title={item.title}
        >
          {(child) => (
            <Item key={child.id} textValue={child.title}>
              <LinkOutIcon size="S" />
              <Text data-testid="pulldown-item">{child.title}</Text>
            </Item>
          )}
        </Section>
      )}
    </SearchAutocomplete>
  );
};

export default SearchHelpTopics;
