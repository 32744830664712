import {MIGRATION_STATUS, MIGRATION_TYPE, getDaysLeft} from '@admin-tribe/acsc';

import rootStore from 'core/RootStore';
import DelegationsUsersList from 'core/user/delegation-users/DelegationsUsersList';
import {GLOBAL_BANNER_TYPES} from 'features/global-banner/GlobalBannerConstants';

/**
 * Method to check if global banners for org level migrations should be shown,
 * and if so will generate payloads to add to the globalBannerStore.
 */
function notifyOrganizationMigrationBanners() {
  const t2eMigration = rootStore.organizationStore.migrationList.findByType(MIGRATION_TYPE.T2E);
  if (t2eMigration) {
    setUpType2EMigrationBanner(t2eMigration);
  }

  const esmType1Migration = rootStore.organizationStore.migrationList.findByType(
    MIGRATION_TYPE.ESM_TYPE1
  );
  if (esmType1Migration) {
    setUpESMType1MigrationBanners(esmType1Migration);
  }
}

//////////

async function setUpESMType1MigrationBanners(esmType1Migration) {
  if (esmType1Migration.status === MIGRATION_STATUS.NOT_MIGRATING) {
    rootStore.organizationStore.globalBannerStore.add({
      linkName: 'aac_switch_identity',
      linkText: 'globalBanner.linkText.switchIdentity',
      message: 'globalBanner.messages.migrations.switchIdentity.notMigrated',
      type: GLOBAL_BANNER_TYPES.INFO,
    });
  } else if (esmType1Migration.status === MIGRATION_STATUS.MIGRATED) {
    const delegationsUsersList = await DelegationsUsersList.getIncompatibleIDTypeUsers({
      orgId: rootStore.organizationStore.activeOrgId,
      pageSize: 1,
    });

    if (delegationsUsersList.hasContent()) {
      rootStore.organizationStore.globalBannerStore.add({
        linkName: 'aac_switch_identity',
        linkText: 'globalBanner.linkText.switchIdentity',
        message: 'globalBanner.messages.migrations.switchIdentity.partiallyMigrated',
        type: GLOBAL_BANNER_TYPES.INFO,
      });
    }
  }
}

function setUpType2EMigrationBanner(t2eMigration) {
  let message;
  let otherArgs = {};
  if (t2eMigration.status !== MIGRATION_STATUS.NOT_MIGRATING) {
    return;
  }
  const scheduledStartDate = t2eMigration?.metadata?.scheduledStartDate;

  message =
    'globalBanner.messages.migrations.t2e.scheduledDowntime.moreThanFiveDaysAwayOrNoDateSet';

  const daysLeft = getDaysLeft(scheduledStartDate);

  if (daysLeft > 30 || daysLeft < 0) {
    return;
  }

  if (daysLeft <= 5) {
    message = 'globalBanner.messages.migrations.t2e.scheduledDowntime.inNextFiveDays';
    otherArgs = {
      messageDates: {
        scheduledStartDate,
      },
    };
  }

  rootStore.organizationStore.globalBannerStore.add({
    linkName: 'ac_busID_migrate_learn',
    linkText: 'globalBanner.linkText.learnMore',
    message,
    type: GLOBAL_BANNER_TYPES.INFO,
    ...otherArgs,
  });
}

// eslint-disable-next-line import/prefer-default-export -- Utils file
export {notifyOrganizationMigrationBanners};
