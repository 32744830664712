import binky from '@admin-tribe/acsc';

import jilCustomData from '../../api/jil/jilCustomData';

import CustomData from './CustomData';
import CUSTOM_DATA_CONSTANTS from './CustomDataConstants';

const JilModelList = binky.services.modelList.JilModelList;
const JIL_CONSTANTS = binky.api.jil.JIL_CONSTANTS;

class CustomDataList extends JilModelList {
  /**
   * @description instantiate the list of all CustomData for an Organization.
   */
  constructor() {
    super({
      itemClassRef: CustomData,
      resource: jilCustomData.getDataResource,
      sortExpression: JIL_CONSTANTS.SORT.NAME,
      sortOrder: JIL_CONSTANTS.ORDER.ASC,
    });

    this.items = [];
  }

  /**
   * @description retrieve the ALL_DELEGATES customData note.
   * @returns {CustomData} the ALL_DELEGATES customData note for the org, if one exists.
   */
  getAllDelegatesNote() {
    return this.items.find((element) => element.name === CUSTOM_DATA_CONSTANTS.NAME.ALL_DELEGATES);
  }
}

export default CustomDataList;
