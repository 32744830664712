import {DATE_FORMATS, DetailSection} from '@admin-tribe/acsc-ui';
import {Flex, LabeledValue} from '@adobe/react-spectrum';
import {EN_DASH} from '@pandora/react-table-section';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import ApiIntegrationUser from 'core/api-integration/ApiIntegrationUser';

/**
 * The Details section for the 'API credentials' page drawer.
 */
const ApiCredentialsDetailsSection = ({member}) => {
  const intl = useIntl();

  const formatDate = (value) => (value ? intl.formatDate(value, DATE_FORMATS.default) : undefined);

  const getLabel = (key) =>
    intl.formatMessage({
      id: `users.APICredentialsDetailsSection.label.${key}`,
    });

  const detailsFields = [
    {
      key: 'apiKey',
      value: member.apiKey,
    },
    {
      key: 'technicalId',
      value: member.id,
    },
    {
      key: 'createdOn',
      labelPosition: 'side',
      value: formatDate(member.createdDate),
    },
    {
      key: 'lastModified',
      labelPosition: 'side',
      value: formatDate(member.lastModifiedDate),
    },
  ];

  // The apiKey and technicalId are can be long and need the full width of the drawe w/o the label.
  // If labelPosition is explicitly set use it.
  // Otherwise set labelPosition to 'top' if there is a value, or set to 'side' otherwise.
  return (
    <DetailSection
      title={intl.formatMessage({
        id: 'users.APICredentialsDetailsSection.title',
      })}
    >
      <Flex data-testid="header-details" direction="column">
        {detailsFields.map(({key, labelPosition, value}, index) => (
          <React.Fragment key={key}>
            <LabeledValue
              label={getLabel(key)}
              labelPosition={labelPosition ?? (value ? 'top' : 'side')}
              marginTop={index > 0 ? 'size-50' : undefined}
              value={value ?? EN_DASH}
            />
          </React.Fragment>
        ))}
      </Flex>
    </DetailSection>
  );
};

ApiCredentialsDetailsSection.propTypes = {
  /**
   * The API credential user.
   */
  member: PropTypes.instanceOf(ApiIntegrationUser).isRequired,
};

export default ApiCredentialsDetailsSection;
