import {LicenseGroupList} from '@admin-tribe/acsc';
import {useAsyncModel} from '@pandora/react-async-model';
import {useCallback} from 'react';
import {useParams} from 'react-router-dom';

import {BULK_OPERATIONS_RESULTS_FILENAME} from 'common/components/bulk-operation/bulk-operation-utils/bulkOperationConstants';
import JobList from 'common/services/job-list/JobList';
import JobResultList from 'common/services/job-result-list/JobResultList';
import rootStore from 'core/RootStore';
import {
  getHrefForProductBulkOpsDetailsFn,
  getHrefForProductLicenseDeficitFn,
  getHrefForProductProfileBulkOpsDetailsFn,
  getHrefForProductProfileLicenseDeficitFn,
  goToProductBulkOpsDetailsFn,
  goToProductProfileBulkOpsDetailsFn,
} from 'features/products/routing/navigation-callbacks/navigationCallbacks';
import {
  PATH_PRODUCT_DETAILS_BULK_OPERATIONS,
  PATH_PRODUCT_PROFILE_DETAILS_BULK_OPERATIONS,
} from 'features/products/routing/productsPaths';
import {
  getHrefForDirectoryUserBulkOpsDetailsFn,
  getHrefForUserGroupBulkOpsDetailsFn,
  getHrefForUserGroupLicenseDeficitFn,
  getHrefForUsersBulkOpsDetails,
  getHrefForUsersLicenseDeficit,
  goToDirectoryUserBulkOpsDetailsFn,
  goToUserGroupBulkOpsDetailsFn,
  goToUsersBulkOpsDetails,
} from 'features/users/routing/navigation-callbacks/navigationCallbacks';
import {
  PATH_DIRECTORY_USER_BULK_OPERATIONS,
  PATH_USERS_BULK_OPERATIONS,
  PATH_USER_GROUPS_BULK_OPERATIONS,
  PATH_USER_GROUP_DETAILS_BULK_OPERATIONS,
} from 'features/users/routing/usersPaths';

const downloadResults =
  (fileName) =>
  ({jobId}) =>
    JobResultList.export({fileName, jobId});

/**
 * @description retrieves Job data based on page context
 * @param {String} path. The React router path used to reach this page.
 *   For example, PATH_USERS_BULK_OPERATIONS.
 *
 * @returns {Object} results of retrieving a JobList
 * @property  BulkOps - an object that contains the result of the jobs api call and a function to determine
 *                      the routing link to each specific job based on the page context
 * @property  error - the error if the jobs api call failed
 * @property isLoading - indicator that the jobs api call is in progress or not
 */
const useBulkOperationJobs = (path) => {
  const pathParams = useParams();
  const {directoryId, productId, profileId, userGroupId} = pathParams;

  // Since this is called by 'useAsyncModel' if anything throws, useAyncModel will
  // catch it and return an error.
  const fetchBulkOperationJobs = useCallback(async () => {
    let jobList, jobSummary;

    // Team products have one license group which isn't shown in the UI so we allow
    // the product bulk operations results  page to use the default profile.
    const fetchTeamProductProfileId = async () => {
      const product = rootStore.organizationStore.productList.items.find((p) => p.id === productId);

      const licenseGroupList = await LicenseGroupList.get({
        orgId: rootStore.organizationStore.activeOrgId,
        product,
      });

      return licenseGroupList.items[0]?.id;
    };

    switch (path) {
      // Users bulk ops
      case PATH_DIRECTORY_USER_BULK_OPERATIONS:
        jobList = await JobList.getDirectoryUserJobList(directoryId);
        jobSummary = {
          download: downloadResults(BULK_OPERATIONS_RESULTS_FILENAME.DIRECTORY_USERS),
          getDetailsHref: getHrefForDirectoryUserBulkOpsDetailsFn({directoryId}),
          goToDetails: goToDirectoryUserBulkOpsDetailsFn({directoryId}),
          jobList,
        };
        break;
      case PATH_USERS_BULK_OPERATIONS:
        jobList = await JobList.getUsersJobList();
        jobSummary = {
          download: downloadResults(BULK_OPERATIONS_RESULTS_FILENAME.USERS),
          getDetailsHref: getHrefForUsersBulkOpsDetails,
          getLicenseDeficitHref: getHrefForUsersLicenseDeficit,
          goToDetails: goToUsersBulkOpsDetails,
          jobList,
        };
        break;
      case PATH_USER_GROUPS_BULK_OPERATIONS:
        jobList = await JobList.getUserGroupJobList();
        jobSummary = {
          download: downloadResults(BULK_OPERATIONS_RESULTS_FILENAME.USER_GROUP_USERS),
          getDetailsHref: getHrefForUserGroupBulkOpsDetailsFn(),
          getLicenseDeficitHref: undefined,
          goToDetails: goToUserGroupBulkOpsDetailsFn(),
          jobList,
        };
        break;
      case PATH_USER_GROUP_DETAILS_BULK_OPERATIONS:
        jobList = await JobList.getUserGroupJobList(userGroupId);
        jobSummary = {
          download: downloadResults(BULK_OPERATIONS_RESULTS_FILENAME.USER_GROUP_DETAILS),
          getDetailsHref: getHrefForUserGroupBulkOpsDetailsFn({userGroupId}),
          getLicenseDeficitHref: getHrefForUserGroupLicenseDeficitFn({userGroupId}),
          goToDetails: goToUserGroupBulkOpsDetailsFn({userGroupId}),
          jobList,
        };
        break;

      // Products bulk ops
      case PATH_PRODUCT_DETAILS_BULK_OPERATIONS:
        {
          const teamProfileId = await fetchTeamProductProfileId();
          jobList = await JobList.getLicenseConfigJobList(teamProfileId, productId);
          const params = {productId, profileId: teamProfileId};
          jobSummary = {
            download: downloadResults(BULK_OPERATIONS_RESULTS_FILENAME.PRODUCT_USERS),
            getDetailsHref: getHrefForProductBulkOpsDetailsFn(params),
            getLicenseDeficitHref: getHrefForProductLicenseDeficitFn(params),
            goToDetails: goToProductBulkOpsDetailsFn(params),
            jobList,
          };
        }
        break;

      case PATH_PRODUCT_PROFILE_DETAILS_BULK_OPERATIONS:
        {
          jobList = await JobList.getLicenseConfigJobList(profileId, productId);
          const params = {productId, profileId};
          jobSummary = {
            download: downloadResults(BULK_OPERATIONS_RESULTS_FILENAME.PROFILE_USERS),
            getDetailsHref: getHrefForProductProfileBulkOpsDetailsFn(params),
            getLicenseDeficitHref: getHrefForProductProfileLicenseDeficitFn(params),
            goToDetails: goToProductProfileBulkOpsDetailsFn(params),
            jobList,
          };
        }
        break;

      default:
        throw new Error(`useBulkOperationJobs: path "${path}" not handled`);
    }

    return jobSummary;
  }, [directoryId, path, productId, profileId, userGroupId]);

  const {model, error, isLoading, updateModel} = useAsyncModel({loadFn: fetchBulkOperationJobs});

  return {bulkOps: model, error, isLoading, updateJobList: updateModel};
};

export default useBulkOperationJobs;
