import {Heading} from '@adobe/react-spectrum';
import {GoUrl} from '@pandora/react-go-url';
import {ImageIcon} from '@pandora/react-image-icon';
import {Card} from '@react/react-spectrum/Card';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import './SyncSourceCard.pcss';

const SyncSourceCard = ({goUrl, logo, name, selected, syncType}) => {
  const intl = useIntl();

  return (
    <Card
      allowsSelection={false}
      data-testid={`sync-card-${syncType.toLowerCase()}`}
      selected={selected}
      size="L"
      styleName="provider-card"
    >
      <div styleName="provider-card-body">
        <ImageIcon alt="" size="XL" src={logo} styleName="provider-card-logo" />
        <Heading marginBottom="0">
          <FormattedMessage id={`settings.sync.syncSourceCards.${name}.title`} />
        </Heading>
        <p>
          <FormattedMessage id={`settings.sync.syncSourceCards.${name}.description`} />
        </p>
        <div aria-hidden="true" styleName="provider-card-go-url" tabIndex="-1">
          <GoUrl name={goUrl || 'aac_identity_learn_more'}>
            {intl.formatMessage({
              id: `settings.sync.syncSourceCards.${name}.learnMore`,
            })}
          </GoUrl>
        </div>
      </div>
    </Card>
  );
};

SyncSourceCard.propTypes = {
  goUrl: PropTypes.string,
  logo: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  syncType: PropTypes.string.isRequired,
};

export default SyncSourceCard;
