import {Locale, downloadExportData, log, toPandoraProductList} from '@admin-tribe/acsc';
import {showError, showInfo} from '@admin-tribe/acsc-ui';
import {useSupportTicketListHook} from '@pandora/data-model-support-ticket';
import {TooltipButton} from '@pandora/react-tooltip-button';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';

import {SUPPORT_CONSTANTS} from '../../support.constants';

const {LIST_ENTITY_NAME} = SUPPORT_CONSTANTS;

const SupportTicketExport = ({recordType}) => {
  const {getCsv} = useSupportTicketListHook();
  const intl = useIntl();
  const buttonLabel = intl.formatMessage({id: 'support.cases.buttons.exportToCsv'});
  const downloadFileName =
    recordType === LIST_ENTITY_NAME.CASES ? 'supportCases.csv' : 'expertSessions.csv';
  const locale = Locale.get().activeLocaleCode;
  const orgId = rootStore.organizationStore.activeOrgId;

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const exportToCsv = async () => {
    const productList = toPandoraProductList(rootStore.organizationStore.productList);
    try {
      setIsButtonDisabled(true);
      const csv = await getCsv({locale, orgId, productList, recordType});
      showInfo(intl.formatMessage({id: 'support.toasts.downloadBeingPrepared'}));
      downloadExportData(csv, downloadFileName);
    } catch (error) {
      log.error('Error downloading CSV file for support cases', error);
      showError();
    } finally {
      setIsButtonDisabled(false);
    }
  };

  return (
    <TooltipButton
      buttonAriaLabel={buttonLabel}
      data-testid="export-button"
      hoverText={buttonLabel}
      isDisabled={isButtonDisabled}
      marginEnd="size-175"
      marginStart="size-175"
      onPress={exportToCsv}
      variant="primary"
    >
      {buttonLabel}
    </TooltipButton>
  );
};

SupportTicketExport.propTypes = {
  /**
   * Record Type of the Support Ticket
   */
  recordType: PropTypes.oneOf([LIST_ENTITY_NAME.CASES, LIST_ENTITY_NAME.EXPERTS]).isRequired,
};

export default SupportTicketExport;
