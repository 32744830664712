import {navBus} from '@admin-tribe/acsc';
import {Item, TabList, TabPanels, Tabs} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {generatePath, useLocation} from 'react-router-dom';

import AppIntegrationsPoliciesSubpage from 'features/products/app-integrations/pages/subpages/AppIntegrationsPoliciesSubpage';
import AppIntegrationsUserAcceptedSubpage from 'features/products/app-integrations/pages/subpages/AppIntegrationsUserAcceptedSubpage';
import {
  PATH_PRODUCTS_APP_INTEGRATIONS_POLICIES,
  PATH_PRODUCTS_APP_INTEGRATIONS_USER_ACCEPTED,
  PATH_SEGMENT_APP_INTEGRATIONS_POLICIES,
} from 'features/products/routing/productsPaths';

const TAB_KEYS = {
  ADMIN_AUTH: 'admin-auth',
  USER_INTEGRATIONS: 'user-integrations',
};

const determineKeyByPath = (location) =>
  location.pathname.includes(PATH_SEGMENT_APP_INTEGRATIONS_POLICIES)
    ? TAB_KEYS.ADMIN_AUTH
    : TAB_KEYS.USER_INTEGRATIONS;

const findTabByKey = (tabs, key) => tabs.find((tab) => tab.key === key);

/** Tabs for the AppIntegrationsPage */
const AppIntegrationsTabs = ({orgId}) => {
  const location = useLocation();
  const intl = useIntl();
  const [selectedKey, setSelectedKey] = useState(determineKeyByPath(location));

  const tabs = useMemo(
    () => [
      {
        content: <AppIntegrationsPoliciesSubpage />,
        key: TAB_KEYS.ADMIN_AUTH,
        name: intl.formatMessage({
          id: 'products.appIntegrations.appIntegrationsPage.tabName.adminAuthorizations',
        }),
        pathname: generatePath(PATH_PRODUCTS_APP_INTEGRATIONS_POLICIES, {orgId}),
      },
      {
        content: <AppIntegrationsUserAcceptedSubpage />,
        key: TAB_KEYS.USER_INTEGRATIONS,
        name: intl.formatMessage({
          id: 'products.appIntegrations.appIntegrationsPage.tabName.userAcceptedIntegrations',
        }),
        pathname: generatePath(PATH_PRODUCTS_APP_INTEGRATIONS_USER_ACCEPTED, {orgId}),
      },
    ],
    [intl, orgId]
  );

  /** Keep the currently selected tab in sync with the URL */
  useEffect(() => {
    setSelectedKey(determineKeyByPath(location));
  }, [location]);

  const onChange = useCallback(
    (key) => {
      if (key === selectedKey) {
        // Currently on the tab that was clicked, don't navigate
        return;
      }

      // Just modify the URL, the key will get updated in the useEffect above
      navBus.pushState({url: findTabByKey(tabs, key).pathname});
    },
    [selectedKey, tabs]
  );

  return (
    <Tabs
      aria-label={intl.formatMessage({
        id: 'products.appIntegrations.appIntegrationsPage.tabbedNav.label',
      })}
      onSelectionChange={onChange}
      selectedKey={selectedKey}
    >
      <TabList>
        {tabs.map((tab) => (
          <Item key={tab.key}>{tab.name}</Item>
        ))}
      </TabList>
      <TabPanels>
        {tabs.map((tab) => (
          <Item key={tab.key}>{tab.content}</Item>
        ))}
      </TabPanels>
    </Tabs>
  );
};

AppIntegrationsTabs.propTypes = {
  orgId: PropTypes.string.isRequired,
};

export default AppIntegrationsTabs;
