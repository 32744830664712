import {
  isLicenseGroupConfigurationGroupInvalid,
  isLicenseGroupConfigurationSectionInvalid,
} from '@admin-tribe/acsc';
import binkyUI from '@admin-tribe/acsc-ui';
import {Item, TabList, TabPanels, Tabs, Text, View, useListData} from '@adobe/react-spectrum';
import {useId} from '@react-aria/utils';
import Alert from '@spectrum-icons/workflow/Alert';
import isEqual from 'lodash/isEqual';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import {useEditProductProfilePermissionsContext} from './context/EditProductProfilePermissionsContext';
import EditConfigurationGroup from './edit-group/EditConfigurationGroup';
import RequiresConfigurationConfirmModal from './requires-configuration-confirm-modal/RequiresConfigurationConfirmModal';

const {ModalContainer, ModalContent, ModalDialog, ModalHeading} = binkyUI.common.components.modal;

const EditProductProfilePermissionsModalContent = () => {
  const {configurationGroups, initialGroupId, licenseGroupName, onClose, onSave} =
    useEditProductProfilePermissionsContext();

  const intl = useIntl();
  const headerId = useId();

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const {items: groups, update} = useListData({
    getKey: (group) => group.id,
    initialItems: configurationGroups,
  });

  const sectionInvalid = isLicenseGroupConfigurationSectionInvalid({
    content: groups,
  });
  const hasNoChanges = isEqual(groups, configurationGroups);
  const saveDisabled = hasNoChanges || sectionInvalid;

  // if editable property does not exist on group, it defaults to true
  const readOnly = groups.every((group) => 'editable' in group && !group.editable);

  const onGroupChange = (modifiedGroup) => {
    update(modifiedGroup.id, modifiedGroup);
  };

  const onEditModalClose = () => {
    const requiresConfiguration = isLicenseGroupConfigurationSectionInvalid({
      content: configurationGroups,
    });
    if (requiresConfiguration) {
      setConfirmationModalOpen(true);
    } else {
      onClose();
    }
  };

  const onConfirmationCancel = () => {
    // re-open the modal, with all the changes they had just made
    setConfirmationModalOpen(false);
  };
  const onConfirmationLeave = () => {
    // actually cancel
    setConfirmationModalOpen(false);
    onClose();
  };

  const tabAlertLabel = intl.formatMessage({
    id: 'products.productProfilePermissions.editPermissionsModal.configurationRequired',
  });

  const styledInvalidIcon = (
    <Alert
      aria-label={tabAlertLabel}
      data-testid="invalid-icon"
      size="XS"
      UNSAFE_style={{fill: 'var(--spectrum-semantic-notice-color-icon)'}}
    />
  );

  return (
    <>
      {!confirmationModalOpen && (
        <ModalContainer>
          <ModalDialog
            cancelLabel={intl.formatMessage({
              id: 'products.productProfilePermissions.editPermissionsModal.cancel',
            })}
            closeModal={onEditModalClose}
            ctaLabel={
              readOnly
                ? intl.formatMessage({
                    id: 'products.productProfilePermissions.editPermissionsModal.close',
                  })
                : intl.formatMessage({
                    id: 'products.productProfilePermissions.editPermissionsModal.save',
                  })
            }
            ctaVariant={readOnly ? 'primary' : 'cta'}
            data-testid="edit-product-profile-permissions-modal"
            heightVariant="static"
            hideCancelButton={readOnly}
            id="edit-product-profile-permissions-modal"
            isCtaDisabled={!readOnly && saveDisabled}
            onCta={() => (readOnly ? onEditModalClose() : onSave(groups))}
          >
            <ModalHeading id={headerId}>
              {intl.formatMessage(
                {id: 'products.productProfilePermissions.editPermissionsModal.title'},
                {licenseGroupName}
              )}
            </ModalHeading>
            <ModalContent>
              <View paddingY="size-200">
                <Tabs
                  aria-labelledby={headerId}
                  defaultSelectedKey={initialGroupId}
                  density="compact"
                  items={groups}
                  orientation="vertical"
                >
                  <TabList>
                    {(group) => (
                      <Item key={group.id}>
                        <Text marginEnd="size-100">{group.name}</Text>
                        {isLicenseGroupConfigurationGroupInvalid(group) && styledInvalidIcon}
                      </Item>
                    )}
                  </TabList>
                  <TabPanels>
                    {(group) => (
                      <Item key={group.id}>
                        <EditConfigurationGroup group={group} onChange={onGroupChange} />
                      </Item>
                    )}
                  </TabPanels>
                </Tabs>
              </View>
            </ModalContent>
          </ModalDialog>
        </ModalContainer>
      )}
      {confirmationModalOpen && (
        <ModalContainer dialogType="modal">
          <RequiresConfigurationConfirmModal
            onCancel={onConfirmationCancel}
            onConfirmLeave={onConfirmationLeave}
          />
        </ModalContainer>
      )}
    </>
  );
};

export default EditProductProfilePermissionsModalContent;
