import binky from '@admin-tribe/acsc';
import binkyUI, {
  ImageIcon,
  ModalContent,
  ModalDescription,
  ModalHeading,
  ModalTagHeader,
  ModalWizardStepList,
  getProductDisplayName,
} from '@admin-tribe/acsc-ui';
import {Text, View} from '@adobe/react-spectrum';
import Provider from '@react/react-spectrum/Provider';
import PropTypes from 'prop-types';
import React, {useEffect, useRef} from 'react';
import {useIntl} from 'react-intl';

import AddUserFormTableWrapper from 'common/components/add-user-form-table/AddUserFormTableWrapper';
import {isOrgAdmin} from 'core/organizations/access/organizationAccess';

import './AddAdminModal.pcss';
import {useAdminModalContext} from './AddAdminModalContext';

const log = binky.services.log;
const AddUserForm = binkyUI.common.components.addUser.AddUserForm;
const UserPicker = binkyUI.common.components.UserPicker;

const AddAdminFindUserPage = ({children, productToAddAdmin}) => {
  const intl = useIntl();
  const {orgId, setIsLoadingUser, setModalError, setUser, user} = useAdminModalContext();

  const isMountedRef = useRef(true);

  // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- cawright@ to update
  // istanbul ignore next
  useEffect(() => {
    isMountedRef.current = true;

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const onFormChange = async ({memberHasError, member}) => {
    // clear any errors
    setModalError(null);
    // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- cawright@ to update
    // istanbul ignore else
    if (memberHasError || !member) {
      setUser(null);
    } else if (member) {
      if (!member.isNew()) {
        try {
          // avoid refreshing when going back, without making any changes
          if (user?.id !== member.id) {
            setIsLoadingUser(true);
            await member.refresh();
          }
        } catch (error) {
          log.error(`Failed to refresh user. Error: `, error);
          setModalError(intl.formatMessage({id: 'common.modal.error.generic'}));
        } finally {
          setIsLoadingUser(false);
        }
      }
      setUser(member);
    }
  };

  return (
    <>
      <ModalHeading>{intl.formatMessage({id: 'users.addAdminModal.header.addAdmin'})}</ModalHeading>
      {productToAddAdmin && (
        <ModalTagHeader
          IconComponent={<ImageIcon alt="" size="M" src={productToAddAdmin.getIcon()} />}
        >
          {getProductDisplayName(intl, productToAddAdmin)}
        </ModalTagHeader>
      )}
      <ModalDescription>
        {!children && <ModalWizardStepList />}
        <Text>{intl.formatMessage({id: 'users.addAdminModal.description.assignUser'})}</Text>
      </ModalDescription>
      <ModalContent>
        <View marginTop="size-0">
          {/* spectrum: remove when TruncatedText is ported to v3 (when spectrum is updated to ^3.7.0) */}
          <Provider>
            <AddUserFormTableWrapper orgId={orgId}>
              <AddUserForm
                defaultValue={user}
                id="app-add-admin-modal"
                isSystemAdmin={isOrgAdmin()}
                onFormChange={onFormChange}
                orgId={orgId}
                pickerType={UserPicker.PICKER_TYPE.USERS_ONLY}
                searchType={UserPicker.SEARCH_TYPE.EXISTING_USER}
              />
            </AddUserFormTableWrapper>
          </Provider>
          {children}
        </View>
      </ModalContent>
    </>
  );
};

AddAdminFindUserPage.propTypes = {
  children: PropTypes.node,
  /**
   * The product that should be assigned with admin roles(only for product or product support admin).
   */
  productToAddAdmin: PropTypes.instanceOf(binky.services.product.Product),
};

export default AddAdminFindUserPage;
