import {DIRECTORY_OWNERSHIP_STATUS, DIRECTORY_TYPE} from '@admin-tribe/acsc';

import CertificateModel from 'features/settings/models/CertificateModel';

const DIRECTORY_STATUSES = {
  ACTIVE: 'ACTIVE', // (owned or trusted) either the directory is active (type 2 directories are always ACTIVE) or the directory is trusted
  NEEDS_DOMAIN: 'NEEDS_DOMAIN', // (owned) active directory not linked with any domain(s)
  PENDING: 'PENDING', // (trusted) awaiting Trust request approval/rejection
  REJECTED: 'REJECTED', // (trusted) rejected Trust request
  REQUIRES_ACTIVATION: 'REQUIRES_ACTIVATION', // (owned, type 3 only) directory has SAML configuration, now is ready for admin to activate it
  REQUIRES_APPROVAL: 'REQUIRES_APPROVAL', // (owned, type 3 only) newly created directory, awaiting Adobe approval
  REQUIRES_CONFIGURATION: 'REQUIRES_CONFIGURATION', // (owned, type 3 only) directory has been approved, now needs SAML configuration
  REVOKED: 'REVOKED', // (trusted) revoked Trust request
  TRUSTED: 'TRUSTED', // (trusted) accepted Trust request
};

const DIRECTORY_CERTIFICATE_STATUSES = {
  CERTIFICATE_EXPIRED: 'CERTIFICATE_EXPIRED', // (saml certs) directory has expired certificate
  CERTIFICATE_EXPIRING: 'CERTIFICATE_EXPIRING', // (saml certs) directory has expiring certificate
};

class DirectoryEntity {
  constructor(directoryData) {
    this.id = directoryData.id;
    this.directoryName = directoryData.name;
    this.ownershipStatus = directoryData.ownershipStatus;
    this.directoryStatus = directoryData.status;
    this.type = directoryData.type;
    this.domainEnforcement = directoryData.domainEnforcement;
    this.externallyManaged = directoryData.externallyManaged;
    this.idps = directoryData.idps;
    this.defaultIdp = directoryData.defaultIdp;
    this.defaultSamlIdp = directoryData?.defaultSamlIdp;
    this.policies = directoryData.policies;
    this.certificates = directoryData?.certificates?.map((certificate) => {
      const certificateModel = new CertificateModel(certificate);

      if (certificateModel.id === this.defaultSamlIdp?.spDefaultKey) {
        this.defaultCertificate = certificateModel;
      }
      return certificateModel;
    });
  }

  /**
   * @param {String} policy the policy to check
   * @returns {Boolean} whether the given policy is present and enabled at a directory level
   */
  isPolicyEnabled(policy) {
    return this.policies?.[policy] === 'true';
  }

  get isStatusActive() {
    return this.status === DIRECTORY_STATUSES.ACTIVE;
  }

  get isStatusPending() {
    return this.status === DIRECTORY_STATUSES.PENDING;
  }

  get isStatusRejected() {
    return this.status === DIRECTORY_STATUSES.REJECTED;
  }

  get isStatusRevoked() {
    return this.status === DIRECTORY_STATUSES.REVOKED;
  }

  get isTrusted() {
    return this.ownershipStatus === DIRECTORY_OWNERSHIP_STATUS.TRUSTED;
  }

  get isType2() {
    return this.type === DIRECTORY_TYPE.TYPE2;
  }

  get isType2E() {
    return this.type === DIRECTORY_TYPE.TYPE2E;
  }

  get isType3() {
    return this.type === DIRECTORY_TYPE.TYPE3;
  }

  get requiresActivation() {
    return this.status === DIRECTORY_STATUSES.REQUIRES_ACTIVATION;
  }

  get requiresApproval() {
    return this.status === DIRECTORY_STATUSES.REQUIRES_APPROVAL;
  }

  get requiresConfiguration() {
    return this.status === DIRECTORY_STATUSES.REQUIRES_CONFIGURATION;
  }

  get status() {
    if (this.defaultCertificate) {
      if (this.defaultCertificate?.isExpired) {
        return DIRECTORY_CERTIFICATE_STATUSES.CERTIFICATE_EXPIRED;
      }

      if (this.defaultCertificate?.isExpiring) {
        return DIRECTORY_CERTIFICATE_STATUSES.CERTIFICATE_EXPIRING;
      }
    }

    return this.directoryStatus;
  }
}

export default DirectoryEntity;
export {DIRECTORY_STATUSES, DIRECTORY_CERTIFICATE_STATUSES};
