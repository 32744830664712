import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {
  SOCIAL_PROVIDERS,
  SOCIAL_PROVIDER_NAMES,
} from 'features/settings/components/auth-settings-page/AuthenticationPolicies.constants';

const SocialLoginPolicySummary = ({allowedProviders}) => {
  const intl = useIntl();

  const deniedProviders = Object.values(SOCIAL_PROVIDERS).filter(
    (provider) => !allowedProviders.includes(provider)
  );

  return (
    <ul>
      {allowedProviders.length > 0 && (
        <li>
          <FormattedMessage
            id="settings.authSettings.socialLogin.policySummary.allowedProviders"
            values={{
              providers: intl.formatList(
                allowedProviders.map((provider) =>
                  intl.formatMessage({id: SOCIAL_PROVIDER_NAMES[provider]})
                )
              ),
            }}
          />
        </li>
      )}

      {deniedProviders.length > 0 && (
        <li>
          <FormattedMessage
            id="settings.authSettings.socialLogin.policySummary.deniedProviders"
            values={{
              b:
                // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- not testing format message values function
                // istanbul ignore next -- not testing this
                (chunks) => <strong>{chunks}</strong>,
              providers: intl.formatList(
                deniedProviders.map((provider) =>
                  intl.formatMessage({id: SOCIAL_PROVIDER_NAMES[provider]})
                ),
                {type: 'disjunction'}
              ),
            }}
          />
        </li>
      )}
    </ul>
  );
};

SocialLoginPolicySummary.propTypes = {
  allowedProviders: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default SocialLoginPolicySummary;
