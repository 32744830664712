import {ContractList, Organization, hasDirectContract} from '@admin-tribe/acsc';
import {Flex, Text, View} from '@adobe/react-spectrum';
import {DividerPlacement, TitledSection} from '@pandora/react-titled-section';
import {useId} from '@react-aria/utils';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import EditOrgNameButton from '../edit-org-name/button/EditOrgNameButton';

/**
 * Defines the org name section of the console settings page.
 */
const OrgNameSection = ({contractList, org}) => {
  const [orgName, setOrgName] = useState(org.name);
  const intl = useIntl();
  const orgNameId = useId();

  const isOrgNameEditable = () => hasDirectContract(contractList);

  const onSuccess = (updatedOrg) => {
    setOrgName(updatedOrg.name);
  };

  return (
    <View data-testid="org-name-section" marginBottom="size-200">
      <TitledSection
        dividerPlacement={DividerPlacement.NONE}
        headingLevel={2}
        id={orgNameId}
        title={intl.formatMessage({
          id: 'settings.consoleSettings.organization.title',
        })}
      >
        <Text data-testid="description">
          {intl.formatMessage({
            id: 'settings.consoleSettings.organization.description',
          })}
        </Text>
        <View marginTop="size-200">
          <Flex gap="size-100">
            <Text
              aria-labelledby={orgNameId}
              data-testid="org-name"
              UNSAFE_style={{fontSize: '18.4px', fontStyle: 'italic'}}
            >
              {orgName}
            </Text>
            {isOrgNameEditable() && <EditOrgNameButton onSuccess={onSuccess} org={org} />}
          </Flex>
        </View>
      </TitledSection>
    </View>
  );
};

OrgNameSection.propTypes = {
  /**
   * The list of contracts.
   */
  contractList: PropTypes.instanceOf(ContractList).isRequired,
  /**
   * The organization.
   */
  org: PropTypes.instanceOf(Organization).isRequired,
};

export default OrgNameSection;
