import {PageBanner} from '@admin-tribe/acsc-ui';
import {useCollator} from '@react-aria/i18n';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';

import {getPolicyDisabledActions} from './globalAdminPolicyBannerUtils';

/**
 * For the given context, if needed, renders a banner which lists the actions/operations that are
 * disabled by Global Admin. The banner is not dismissable.
 */
const GlobalAdminPolicyBanner = ({context}) => {
  const intl = useIntl();
  const collator = useCollator();

  const actionNames = useMemo(
    () =>
      getPolicyDisabledActions({
        collator,
        context,
        intl,
      }),
    [collator, context, intl]
  );

  return (
    actionNames && (
      <PageBanner
        data-testid="global-admin-policy-banner"
        header={intl.formatMessage({
          id: 'common.globalAdminPolicyBanner.header',
        })}
        showDefaultButton={false}
      >
        {intl.formatMessage(
          {id: 'common.globalAdminPolicyBanner.description'},
          {
            actionNames,
            count: actionNames.length,
          }
        )}
      </PageBanner>
    )
  );
};

GlobalAdminPolicyBanner.propTypes = {
  /**
   * The context where this banner will be displayed.
   */
  context: PropTypes.string.isRequired,
};

export default GlobalAdminPolicyBanner;
