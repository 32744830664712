import {
  ORGANIZATION_USER_API_QUERY_PARAMS,
  OrganizationUser,
  PAGE_TARGET,
  PAGE_TARGET_TYPE,
  PageContext,
  feature,
  log,
} from '@admin-tribe/acsc';
import {
  Avatar,
  Page,
  PageBreadcrumbs,
  PageContent,
  PageHeader,
  PageNav,
  getMemberDisplayName,
} from '@admin-tribe/acsc-ui';
import {Item} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useEffect, useReducer, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import {useParams} from 'react-router-dom';

import UserDetailsContentWrapper from 'common/components/user-details-content/user-details-content-wrapper/UserDetailsContentWrapper';
import UserDetailsModalsWrapper from 'common/components/user-details-content/user-details-modals-wrapper/UserDetailsModalsWrapper';
import useEditDetailsModal from 'common/hooks/use-edit-details-modal/useEditDetailsModal';
import useDocumentTitle from 'common/hooks/useDocumentTitle';
import {getManageableAdminRoles} from 'core/admin/access/adminAccess';
import {
  goToAdministrators,
  goToDevelopers,
  goToUsers,
} from 'features/users/routing/navigation-callbacks/navigationCallbacks';
import {transformMember} from 'features/users/services/member-transformer/memberTransformerUtils';

/**
 * The User/Administrators/Developers details page.
 */
const UserDetailsPage = ({targetType}) => {
  const {orgId, userId} = useParams();

  const pageContext = useRef(
    new PageContext({
      target: PAGE_TARGET.ORGANIZATION,
      targetId: userId,
      targetType,
    })
  ).current;

  const intl = useIntl();

  const [member, setMember] = useState();
  const [memberName, setMemberName] = useState('');
  const [manageableRoles, setManageableRoles] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [showError, setShowError] = useState(false);
  const [forceUpdate, setForceUpdate] = useReducer((x) => x + 1, 0);

  let homeCrumb, onAction;
  if (pageContext.isAdminTargetType()) {
    homeCrumb = intl.formatMessage({
      id: 'users.administrators.title',
    });
    onAction = goToAdministrators;
  } else if (pageContext.isDeveloperTargetType()) {
    homeCrumb = intl.formatMessage({
      id: 'users.developers.title',
    });
    onAction = goToDevelopers;
  } else {
    homeCrumb = intl.formatMessage({
      id: 'users.users.title',
    });
    onAction = goToUsers;
  }

  // Get the certain member by userId.
  // While waiting it will show the loading spinner.
  // If the call fails it will show the default error message.
  useEffect(() => {
    let isMounted = true;

    const getMember = async () => {
      setIsLoading(true);
      try {
        const include = [
          ORGANIZATION_USER_API_QUERY_PARAMS.PRODUCTS,
          ORGANIZATION_USER_API_QUERY_PARAMS.USER_GROUP_PRODUCTS,
          ORGANIZATION_USER_API_QUERY_PARAMS.USER_CREATION_SOURCE,
          ORGANIZATION_USER_API_QUERY_PARAMS.USER_EDU_ROLE_TAGS,
        ];

        // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- tuskumar@ to update
        // istanbul ignore else
        if (feature.isEnabled('temp_de_exclusion_40038')) {
          include.push(ORGANIZATION_USER_API_QUERY_PARAMS.DOMAIN_ENFORCEMENT_EXCLUSION_INDICATOR);
        }
        const resolvedMember = await OrganizationUser.get({
          include,
          orgId,
          userId: pageContext.targetId,
        });

        const transformedMember = transformMember({intl, member: resolvedMember});

        if (isMounted) {
          setMemberName(
            getMemberDisplayName(intl, resolvedMember, {
              fallbackToEmail: false,
            })
          );

          setManageableRoles(await getManageableAdminRoles());
          setMember(transformedMember);
        }
      } catch (error) {
        if (isMounted) {
          setShowError(true);
          log.error(`Error getting OrganizationUser: ${error}`);
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    // Wait for OrganizationUser.get() to resolve with the member
    getMember();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run on mount
  }, [forceUpdate]);

  useDocumentTitle({defaultTitle: memberName});

  const {onCloseModal, onOpenModal, onSuccess, openedModal} = useEditDetailsModal({
    updateModelFn: () => setForceUpdate(),
  });

  return (
    <Page data-testid="user-details-page" isBumpered={showError} isLoading={isLoading}>
      <PageBreadcrumbs isDisabled={false} onAction={() => onAction()}>
        <Item key="user/admin">{homeCrumb}</Item>
        <Item key="name">{memberName}</Item>
      </PageBreadcrumbs>
      <PageHeader title={memberName}>
        <Avatar alt="" member={member} size="S" />
      </PageHeader>
      <PageNav />
      <PageContent marginTop="size-0">
        {!showError && !isLoading && (
          <>
            <UserDetailsModalsWrapper
              manageableRoles={manageableRoles}
              member={member}
              onCancel={onCloseModal}
              onSuccess={onSuccess}
              openedModal={openedModal}
            />
            <UserDetailsContentWrapper
              headingLevel={2}
              isPageVariant
              member={member}
              onOpenModal={onOpenModal}
            />
          </>
        )}
      </PageContent>
    </Page>
  );
};

UserDetailsPage.propTypes = {
  /**
   * The details page for a user, an admin or a developer.
   */
  targetType: PropTypes.oneOf([
    PAGE_TARGET_TYPE.ADMIN,
    PAGE_TARGET_TYPE.DEVELOPER,
    PAGE_TARGET_TYPE.USER,
  ]),
};

export default UserDetailsPage;
