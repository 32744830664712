import {AnalyticsEvent} from '@admin-tribe/acsc';
import {Button, View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

/**
 * Component is used to show Take Survey button in Action Section. It is used in action section to redirect to Survey form.
 */
const SupportTicketSurvey = ({canTakeSurvey, componentName, surveyUrl}) => {
  const intl = useIntl();

  function onClick() {
    // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- window will always be defined
    window.open(surveyUrl, '_blank');
    AnalyticsEvent.dispatch({
      componentMethod: 'takeSurveyButton',
      componentMethodType: 'click',
      componentName,
    });
  }
  return (
    canTakeSurvey && (
      <View elementType="span" paddingEnd="size-65" paddingStart="size-65">
        <Button
          aria-label={intl.formatMessage({
            id: 'support.supportTicketActionsSection.takeSurvey',
          })}
          class="take-survey"
          data-testid="take-survey"
          marginBottom="size-200"
          onClick={onClick}
          variant="primary"
        >
          {intl.formatMessage({id: 'support.supportTicketActionsSection.takeSurvey'})}
        </Button>
      </View>
    )
  );
};

SupportTicketSurvey.propTypes = {
  /**
   * Used to display survey button
   */
  canTakeSurvey: PropTypes.bool,
  /**
   * Used to pass as prop for Analytics Event
   */
  componentName: PropTypes.string.isRequired,
  /**
   * Used to get surveyUrl for survey form.
   */
  surveyUrl: PropTypes.string,
};

export default SupportTicketSurvey;
