import {Subpage} from '@admin-tribe/acsc-ui';
import {
  SELECTION_MODE,
  TABLE_SECTION_ACTIONS,
  TableActions,
  TableFilters,
  TableSection,
} from '@pandora/react-table-section';
import {observer} from 'mobx-react-lite';
import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';
import CreatePackageButton from 'features/packages/components/create-package-modal/CreatePackageButton';
import TemplateListTable from 'features/packages/components/template-list-table/TemplateListTable';
import {usePregeneratedPackagesPageContext} from 'features/packages/pages/pregenerated-packages/PregeneratedPackagesPageContext';
import CreatePackageFactory from 'features/packages/services/CreatePackageService';
import PackagesEntitlements from 'features/packages/services/PackagesEntitlementsService';

/**
 * Packages Adobe TemplatesSub page
 */
const TemplatesSubPage = observer(() => {
  const {packagesAdobeTemplatesStore} = usePregeneratedPackagesPageContext();
  const intl = useIntl();

  // Initial load of the templates list.
  useEffect(() => {
    if (!packagesAdobeTemplatesStore.isLoading) {
      packagesAdobeTemplatesStore.fetchTemplates();
    }
  }, [packagesAdobeTemplatesStore, packagesAdobeTemplatesStore.isLoading]);

  const onTableSectionChange = ({action, payload}) => {
    switch (action) {
      case TABLE_SECTION_ACTIONS.GO_TO_NEXT_PAGE:
        packagesAdobeTemplatesStore.goToNextPage();
        break;
      case TABLE_SECTION_ACTIONS.GO_TO_PREVIOUS_PAGE:
        packagesAdobeTemplatesStore.goToPreviousPage();
        break;
      case TABLE_SECTION_ACTIONS.ON_PAGE_SIZE_CHANGE:
        packagesAdobeTemplatesStore.onPageSizeChange(payload);
        break;
      case TABLE_SECTION_ACTIONS.ON_SEARCH_SUBMIT:
        packagesAdobeTemplatesStore.search(payload.value);
        break;
      default:
        // Do nothing
        break;
    }
  };

  const setCreatePackageFactoryValues = () => {
    if (PackagesEntitlements.hasCcEntitlement || PackagesEntitlements.hasDcEntitlement) {
      CreatePackageFactory.setCreatePackageFactoryValues();
    } else {
      CreatePackageFactory.setCreatePackageFactoryValuesForFrl();
    }
  };

  const {isLoading, isTemplatesPageLoading, hasLoadingError} = packagesAdobeTemplatesStore;
  const isPageLoading = isTemplatesPageLoading && !isLoading;
  const hasError = rootStore.packagesUiConstantsStore.hasLoadingError || hasLoadingError;
  return (
    <Subpage isBumpered={hasError} isLoading={isPageLoading}>
      <TableSection
        getIsItemSelectable={() => false} // templates list table rows are not selectable
        getItemKey={(item) => item.data.id}
        isServerError={hasError}
        items={packagesAdobeTemplatesStore.tableStore.rows}
        onTableSectionChange={onTableSectionChange}
        pageNumber={packagesAdobeTemplatesStore.tableStore.currentPage}
        selectionMode={SELECTION_MODE.NONE}
        totalPages={packagesAdobeTemplatesStore.tableStore.totalPages}
      >
        <TableFilters
          aria-label={intl.formatMessage({id: 'packages.adobeTemplates.searchLabel'})}
          isDisabled={packagesAdobeTemplatesStore.packagesAdobeTemplatesList.length === 0}
          label={intl.formatMessage({id: 'packages.adobeTemplates.searchLabel'})}
          minLength={0}
          width="size-2400"
        />
        <TableActions>
          <CreatePackageButton
            buttonLabel={intl.formatMessage({id: 'packages.createPackageButtonLabel'})}
            isDisabled={isLoading || isTemplatesPageLoading || hasLoadingError}
            setCreatePackageFactoryValues={setCreatePackageFactoryValues}
          />
        </TableActions>
        {!isLoading && !isTemplatesPageLoading && <TemplateListTable />}
      </TableSection>
    </Subpage>
  );
});

TemplatesSubPage.propTypes = {};

export default TemplatesSubPage;
