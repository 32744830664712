import PropTypes from 'prop-types';
import React, {createContext, useContext, useEffect, useMemo, useState} from 'react';

import {useConfiguration} from '../providers/configuration/ConfigurationProvider';
import {useOrganization} from '../providers/organization/OrganizationProvider';

import AnalyticsEventHandler from './AnalyticsEventHandler';

const AnalyticsContext = createContext();

const AnalyticsProvider = ({children}) => {
  const {configuration} = useConfiguration();
  const {organization} = useOrganization();
  const [eventHandler, setEventHandler] = useState(null);

  // Initialize the analytics event handler
  useEffect(() => {
    if (!configuration || !organization) return;
    const analyticsConfig = configuration.services.launch;
    const eventHandlerInstance = new AnalyticsEventHandler(analyticsConfig, organization);
    setEventHandler(eventHandlerInstance);
  }, [configuration, organization]); // re-run this effect if configuration changes

  const eventHandlerContextValue = useMemo(
    () => ({
      eventHandler,
    }),
    [eventHandler]
  );

  return (
    <AnalyticsContext.Provider value={eventHandlerContextValue}>
      {children}
    </AnalyticsContext.Provider>
  );
};

AnalyticsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

/**
 *
 * @returns {Object} The analytics event handler.
 */
const useAnalytics = () => {
  const context = useContext(AnalyticsContext);
  // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- excluding to avoid mocking useContext
  // istanbul ignore next -- excluding to avoid mocking useContext
  if (context === undefined) {
    throw new Error('useAnalytics must be used within an AnalyticsProvider');
  }
  // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- excluding to avoid mocking useContext
  // istanbul ignore next -- excluding to avoid mocking useContext
  return context;
};

export {AnalyticsProvider, useAnalytics};
