import {showError, showSuccess} from '@admin-tribe/acsc-ui';
import {useCallback} from 'react';
import {useIntl} from 'react-intl';

/**
 * A hook containing callbacks and state for directory details
 */
const useAuthentication = (directorySetupStore) => {
  const intl = useIntl();

  const removeIdp = useCallback(
    async ({idpId, idpName}) => {
      try {
        await directorySetupStore.removeIdp(idpId);

        showSuccess(
          intl.formatMessage(
            {
              id: 'settings.setupDirectoryAuthentication.toasts.successfullyRemovedIdp',
            },
            {idpName}
          )
        );
        return true;
      } catch (error) {
        showError(
          intl.formatMessage(
            {
              id: 'settings.setupDirectoryAuthentication.toasts.errorRemovingIdp',
            },
            {idpName}
          )
        );
        return false;
      }
    },
    [directorySetupStore, intl]
  );

  const setDefaultIdp = useCallback(
    async ({idpId, idpName}) => {
      try {
        await directorySetupStore.setDefaultIdp(idpId);

        showSuccess(
          intl.formatMessage(
            {
              id: 'settings.setupDirectoryAuthentication.toasts.successfullySetDefaultIdp',
            },
            {idpName}
          )
        );
        return true;
      } catch (error) {
        showError(
          intl.formatMessage(
            {
              id: 'settings.setupDirectoryAuthentication.toasts.errorSettingDefaultIdp',
            },
            {idpName}
          )
        );
        return false;
      }
    },
    [directorySetupStore, intl]
  );

  return {
    removeIdp,
    setDefaultIdp,
  };
};

export default useAuthentication;
