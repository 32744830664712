import {ModalDialog} from '@admin-tribe/acsc-ui';
import {
  Button,
  ButtonGroup,
  Content,
  Divider,
  Heading,
  Provider,
  useDialogContainer,
} from '@adobe/react-spectrum';
import Provider2 from '@react/react-spectrum/Provider';
import {useId} from '@react-aria/utils';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, {useMemo, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import AzureScimConfigurationSteps from 'features/settings/components/azure-scim-configuration-steps/AzureScimConfigurationSteps';
import {useDirectoryContext} from 'features/settings/components/directory/DirectoryContext';
import GoogleScimConfigurationSteps from 'features/settings/components/google-scim-configuration-steps/GoogleScimConfigurationSteps';
import DirectoryStore from 'features/settings/stores/DirectoryStore';
import DirectorySyncStore from 'features/settings/stores/DirectorySyncStore';

const ScimConfigModal = observer((props) => {
  const intl = useIntl();
  const [isValid, setIsValid] = useState(false);
  const confirmLabel = useMemo(
    () =>
      props.isNewSync
        ? intl.formatMessage({id: 'settings.ScimConfigModal.add'})
        : intl.formatMessage({id: 'settings.ScimConfigModal.done'}),
    [props.isNewSync, intl]
  );

  const modalHeadingId = useId();
  const dialog = useDialogContainer();

  const directoryStoreContext = useDirectoryContext();

  const directoryStore = directoryStoreContext.directoryStore;
  const directorySyncStore = directoryStoreContext.directoryStore.sync;

  const onStepsValidated = (valid) => {
    setIsValid(valid);
  };

  return (
    <ModalDialog height="100vh" id="scim-config-modal">
      <Heading id={modalHeadingId}>
        <FormattedMessage id="settings.ScimConfigModal.configureSync" />
      </Heading>
      <Divider />
      <Content>
        {/* This is needed for the v2 components to render properly */}
        <Provider2>
          <Provider>
            {directorySyncStore.isAzure && (
              <AzureScimConfigurationSteps
                authSourceId={directoryStore.directoryId}
                onValidate={onStepsValidated}
                validateSteps={props.isNewSync}
              />
            )}

            {directorySyncStore.isGoogle && (
              <GoogleScimConfigurationSteps
                directoryStore={directoryStore}
                onValidate={onStepsValidated}
                validateSteps={props.isNewSync}
              />
            )}
          </Provider>
        </Provider2>
      </Content>
      <ButtonGroup>
        <Button
          data-test-id="cancel-btn"
          // eslint-disable-next-line react/jsx-handler-names -- erroneous reporting
          onPress={dialog.dismiss}
          variant="primary"
        >
          <FormattedMessage id="settings.ScimConfigModal.cancel" />
        </Button>
        <Button
          data-test-id="confirm-btn"
          isDisabled={props.isNewSync && !isValid}
          // eslint-disable-next-line react/jsx-handler-names -- erroneous reporting
          onPress={dialog.dismiss}
          variant="accent"
        >
          {confirmLabel}
        </Button>
      </ButtonGroup>
    </ModalDialog>
  );
});

ScimConfigModal.propTypes = {
  directoryStore: PropTypes.instanceOf(DirectoryStore),
  directorySyncStore: PropTypes.instanceOf(DirectorySyncStore),
  isNewSync: PropTypes.bool,
};

export default ScimConfigModal;
