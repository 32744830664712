import {OverlayWait, Page, PageContent, PageHeader, PageNav, useStore} from '@admin-tribe/acsc-ui';
import {ButtonGroup, Flex} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';

import {ContentLogsContextProvider} from 'features/settings/content-logs/ContentLogsContext';
import CreateContentLogButton from 'features/settings/content-logs/create/CreateContentLogButton';
import RemoveContentLogButton from 'features/settings/content-logs/remove/RemoveContentLogButton';
import ContentLogsTable from 'features/settings/content-logs/table/ContentLogsTable';
import ContentLogsStore from 'features/settings/stores/ContentLogsStore';

const ContentLogsPage = observer(() => {
  const contentLogsStore = useStore(() => new ContentLogsStore());
  const intl = useIntl();

  // Initial load of the content logs list.
  useEffect(() => {
    contentLogsStore.load();
  }, [contentLogsStore]);

  // While waiting for the list the first time, hide the content and show a large-sized spinner.
  // For subsequent loads, show the content and use the default medium-sized spinner.
  const initialLoad = !contentLogsStore.contentLogList;

  return (
    <ContentLogsContextProvider contentLogsStore={contentLogsStore}>
      <Page data-testid="content-logs-page">
        <PageHeader title={intl.formatMessage({id: 'settings.contentLogs.title'})} />
        <PageNav />
        <PageContent>
          <OverlayWait
            isLoading={contentLogsStore.isLoading}
            showContent={!initialLoad}
            size={(initialLoad && 'L') || undefined}
          >
            <Flex alignItems="end" direction="column">
              <ButtonGroup>
                <CreateContentLogButton />
                <RemoveContentLogButton />
              </ButtonGroup>
            </Flex>
            <ContentLogsTable />
          </OverlayWait>
        </PageContent>
      </Page>
    </ContentLogsContextProvider>
  );
});

export default ContentLogsPage;
