import {Locale} from '@admin-tribe/acsc';
import {ShowMoreText} from '@admin-tribe/acsc-ui';
import {Flex} from '@adobe/react-spectrum';
import AlertIcon from '@spectrum-icons/workflow/Alert';
import CloseCircleIcon from '@spectrum-icons/workflow/CloseCircle';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import PropTypes from 'prop-types';
import React from 'react';

import {NOTIFICATION_STATUS, UNREAD} from '../../../notifications.constants';
import NotificationButtons from '../../buttons/NotificationButtons';
import NotificationLearnMore from '../../learn-more/NotificationLearnMore';

import './MessageColumn.pcss';

// unread message text is bold, so we truncate to a shorter string
const MAX_MESSAGE_LENGTH = {READ: 140, UNREAD: 120};

const ENDASH_STR = '\u2013';

const MessageColumn = ({messageId, notification, onClickButton}) => {
  const {payload, state} = notification;

  const {dateFnsLocaleString} = Locale.get();
  const [dateFnsLocaleModule, setDateFnsLocaleModule] = React.useState();

  // We used to just assign {MAX_MESSAGE_LENGTH[state.read ? 'READ' : 'UNREAD']} to the maxLength prop of ShowMoreText
  // but it ended up being part of the root cause of Git issue 13735.  The click handler from ShowMoreText would toggle the truncated state and
  // the change in read/unread state would cause ShowMoreText to recalculate whether or not to truncate its text.  Now whatever the maxLength
  // of a message starts out in when the Notifications drawer is opened, we use that maxLength the whole time the drawer is open.
  // eslint-disable-next-line @admin-tribe/admin-tribe/props-vs-state -- glo56560@ to update
  const [maxMessageLength] = React.useState(MAX_MESSAGE_LENGTH[state.read ? 'READ' : 'UNREAD']);

  // This useEffect just fetches the Date Fns locale module needed, and sets it on the component state
  React.useEffect(() => {
    const getLocaleModule = async () => {
      const newDateFnsLocaleModule = await import(
        /* webpackChunkName: "dateFnsLocale" */ `date-fns/locale/${dateFnsLocaleString}/index.js`
      );
      setDateFnsLocaleModule(newDateFnsLocaleModule);
    };

    if (!dateFnsLocaleModule) {
      getLocaleModule();
    }
  });

  const iconProps = {
    marginEnd: 'size-40',
    size: 'S',
    UNSAFE_style: {verticalAlign: 'sub'},
  };

  const getIcon = () => {
    switch (payload.status) {
      case NOTIFICATION_STATUS.ERROR:
        return <CloseCircleIcon color="negative" {...iconProps} />;
      case NOTIFICATION_STATUS.WARN:
        return <AlertIcon color="notice" {...iconProps} />;
      default:
        return null;
    }
  };

  return (
    <Flex direction="column" gap="size-125" gridArea="message" marginY="size-250">
      {/* eslint-disable-next-line react/forbid-dom-props -- id needed for aria-labelledby */}
      <div data-testid="message" id={messageId}>
        {getIcon()}
        <span styleName={state.read ? '' : UNREAD}>
          <ShowMoreText maxLength={maxMessageLength} text={payload.message || ENDASH_STR} />
        </span>
      </div>
      {(payload.learn_more_link || payload.learn_more_go_url) && (
        <NotificationLearnMore notification={notification} />
      )}
      {(payload.expert_session_id ||
        payload.support_case_id ||
        payload.support_case_survey_url) && (
        <NotificationButtons onClickButton={onClickButton} payload={payload} />
      )}
      <span data-testid="time-from-now" styleName="time-from-now">
        {formatDistanceToNow(new Date(notification.createdTimestamp), {
          locale: dateFnsLocaleModule,
        })}
      </span>
    </Flex>
  );
};

MessageColumn.propTypes = {
  /**
   * A unique id to use for the message text. The notification card will use this for an aria-labelledby prop.
   */
  messageId: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types -- Placeholder for object with type - notification
  notification: PropTypes.object.isRequired,
  onClickButton: PropTypes.func.isRequired,
};

export default MessageColumn;
