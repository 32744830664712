import {Content, Heading, IllustratedMessage, Image, View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * A empty state component that can be used in a TableView or on a page when there are no items.
 * It renders the AddItem image, with an IllustratedMessage which has a header and an optional message.
 *
 * If used in a TableView:
 *  - NOTE the TableView must have a minHeight or height set on it for this to be visible.
 *  - any buttons should should be placed in the TableAction section rather than here.
 *  - this message will fit nicely in 'size-6000' as long as there is not an excessive amount of text
 */
const CustomIllustratedMessage = ({heading, imgAltText, imgSrc, level = 2, message}) => (
  <View marginY="size-1000">
    {imgSrc && (
      <Image alt={imgAltText} height="size-2000" marginX="auto" src={imgSrc} width="size-2000" />
    )}
    <IllustratedMessage>
      <Heading level={level}>{heading}</Heading>
      {message && <Content>{message}</Content>}
    </IllustratedMessage>
  </View>
);

CustomIllustratedMessage.propTypes = {
  /**
   * The heading for the IllustratedMessage
   */
  heading: PropTypes.string.isRequired,

  /**
   * The image alt text for the Custom IllustratedMessage
   */
  imgAltText: PropTypes.string,

  /**
   * The image soruce for the Custom IllustratedMessage
   */
  imgSrc: PropTypes.string,

  /**
   * The heading level. The default is 2.
   */
  level: PropTypes.number,
  /**
   * The optional message for the IllustratedMessage
   */
  message: PropTypes.string,
};

export default CustomIllustratedMessage;
