import {feature} from '@admin-tribe/acsc';
import {ActionButton, Button, Flex, Heading, Provider, TextField} from '@adobe/react-spectrum';
import {useContentEntry} from '@pandora/react-content-provider';
import {FileDropZone, FileDropZoneContentModel} from '@pandora/react-file-drop-zone';
import AttachIcon from '@spectrum-icons/workflow/Attach';
import Close from '@spectrum-icons/workflow/Close';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import CopyToClipboardButton from 'common/components/copy-to-clipboard-button/CopyToClipboardButton';
import ConfigurationStep from 'features/settings/components/configuration-step/ConfigurationStep';
import SamlCustomAttributes from 'features/settings/components/saml-custom-attributes/SamlCustomAttributes';
import IdpEntity from 'features/settings/entities/IdpEntity';

import styles from './SamlConfigurationSteps.pcss';

const METADATA_ACCEPTED_TYPES = ['application/xml', 'text/xml'];

const SamlConfigurationSteps = ({idp, customAttributes, onFileChanged, onAttributesChanged}) => {
  const intl = useIntl();
  const [selectedFile, setSelectedFile] = useState(
    idp.isActive
      ? new File(
          [],
          intl.formatMessage({id: 'settings.samlConfigurationSteps.placeholderFileName'})
        )
      : null
  );

  const handleFilesAdded = (filesAdded) => {
    const file = filesAdded.length > 0 && filesAdded[0];

    if (!file) {
      return;
    }

    setSelectedFile(file);
    onFileChanged?.(file);
  };

  const handleRemoveMetadata = () => {
    setSelectedFile(null);
    onFileChanged?.(null);
  };

  const content = useContentEntry(FileDropZoneContentModel);

  return (
    <>
      <ConfigurationStep title={intl.formatMessage({id: 'settings.samlConfigurationSteps.step1'})}>
        <p>
          <FormattedMessage id="settings.samlConfigurationSteps.setupSaml" />
        </p>
        <p>
          <FormattedMessage id="settings.samlConfigurationSteps.downloadOrUse" />
        </p>

        <Heading
          level={5}
          // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needed some special styling
          UNSAFE_className={styles['option-heading']}
        >
          <FormattedMessage id="settings.samlConfigurationSteps.option1" />
        </Heading>

        <Provider>
          <Button elementType="a" href={idp.entityId} target="_blank" variant="secondary">
            <FormattedMessage id="settings.samlConfigurationSteps.downloadMetadata" />
          </Button>
        </Provider>

        <div styleName="dotted-divider" />

        <Heading
          level={5}
          // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needed some special styling
          UNSAFE_className={styles['option-heading']}
        >
          <FormattedMessage id="settings.samlConfigurationSteps.option2" />
        </Heading>

        <Flex alignItems="flex-start" direction="column" rowGap="16px">
          <TextField
            data-test-id="acs-url-textfield"
            isDisabled
            label={intl.formatMessage({id: 'settings.samlConfigurationSteps.acsUrlLabel'})}
            value={idp.acsUrl}
            width="100%"
          />
          <CopyToClipboardButton
            aria-label={intl.formatMessage({
              id: 'settings.samlConfigurationSteps.copyAcsUrl',
            })}
            data-test-id="acs-url-copy-btn"
            value={idp.acsUrl}
          >
            <FormattedMessage id="settings.samlConfigurationSteps.copyToClipboard" />
          </CopyToClipboardButton>

          <TextField
            data-test-id="entity-id-textfield"
            isDisabled
            label={intl.formatMessage({id: 'settings.samlConfigurationSteps.entityIdLabel'})}
            value={idp.entityId}
            width="100%"
          />
          <CopyToClipboardButton
            aria-label={intl.formatMessage({
              id: 'settings.samlConfigurationSteps.copyEntityId',
            })}
            data-test-id="entity-id-copy-btn"
            value={idp.entityId}
          >
            <FormattedMessage id="settings.samlConfigurationSteps.copyToClipboard" />
          </CopyToClipboardButton>
        </Flex>
      </ConfigurationStep>
      <ConfigurationStep title={intl.formatMessage({id: 'settings.samlConfigurationSteps.step2'})}>
        <p>
          <FormattedMessage id="settings.samlConfigurationSteps.uploadMetadataToAdobe" />
        </p>

        <p>
          <FormattedMessage id="settings.samlConfigurationSteps.uploadMetadata" />
        </p>

        {!selectedFile && (
          <FileDropZone
            acceptedTypes={METADATA_ACCEPTED_TYPES}
            className="fileDropZone"
            content={content}
            onFileAdded={handleFilesAdded}
          />
        )}

        {selectedFile && (
          <div data-test-id="file-card" styleName="file-card">
            <div data-test-id="file-name" styleName="file-name">
              <AttachIcon />
              {selectedFile.name}
            </div>
            <ActionButton
              aria-label={intl.formatMessage({
                id: 'settings.samlConfigurationSteps.removeMetadata',
              })}
              data-test-id="remove-file-btn"
              isQuiet
              onPress={handleRemoveMetadata}
            >
              <Close />
            </ActionButton>
          </div>
        )}
      </ConfigurationStep>

      {feature.isEnabled('temp_mfa_saml_controls') && (
        <SamlCustomAttributes
          mfaAuthnContextClasses={customAttributes.mfaAuthnContextClasses}
          onChange={onAttributesChanged}
          reauthAuthnContextClasses={customAttributes.reauthAuthnContextClasses}
        />
      )}
    </>
  );
};

SamlConfigurationSteps.propTypes = {
  customAttributes: PropTypes.shape({
    mfaAuthnContextClasses: PropTypes.arrayOf(PropTypes.string),
    reauthAuthnContextClasses: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  idp: PropTypes.oneOfType([
    PropTypes.shape({
      acsUrl: PropTypes.string,
      entityId: PropTypes.string,
      isActive: PropTypes.bool,
    }),
    PropTypes.instanceOf(IdpEntity),
  ]),
  onAttributesChanged: PropTypes.func.isRequired,
  onFileChanged: PropTypes.func.isRequired,
};

export default SamlConfigurationSteps;
