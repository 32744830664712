import {configStore} from '@admin-tribe/acsc';
import axios from 'axios';

import {getAuthorizationHeader} from 'common/api/httpUtils';

const SYNC_SOURCES = {
  AZURE: 'AZURE',
  EDU: 'EDU',
  GOOGLE: 'GSUITE',
};

const EDIT_SYNC_STATUS = {
  DISABLED: 'DISABLED',
  ENABLED: 'ENABLED',
};

let baseUrl;

(async function loadConfig() {
  ({url: baseUrl} = await configStore.getServiceConfiguration('directorySync'));
})();

/**
 * Creates a new directory sync link for the specified directoryId.
 *
 * @param {String} directoryId Id of the directory to be linked.
 * @param {String} partnerType A value from `SYNC_SOURCES`.
 * @param {String} orgId Id of the organization.
 *
 * @return {any|Promise<AxiosResponse<any>>} Axios response
 */
const createLink = (directoryId, partnerType, orgId) => {
  const body = {partnerType};

  return axios.post(`${baseUrl}/v1/organizations/${orgId}/authSrcs/${directoryId}/link`, body, {
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

/**
 * Gets the scim token for the provided directory id used to configure a sync.
 *
 * @param {String} directoryId Id of the directory.
 * @param {String} orgId Id of the organization.
 *
 * @return {any|Promise<AxiosResponse<any>>} Axios response
 */
const getScimToken = (directoryId, orgId) =>
  axios.get(`${baseUrl}/v1/organizations/${orgId}/authSrcs/${directoryId}/scimToken`, {
    headers: {
      ...getAuthorizationHeader(),
    },
  });

/**
 * Removes the externally managed policy for the specified directory id.
 *
 * @param {String} directoryId Id of the directory that will lose the policy.
 * @param {String} orgId Id of the organization.
 *
 * @return {any|Promise<AxiosResponse<any>>} Axios response
 */
const removeExternallyManagedPolicy = (directoryId, orgId) => {
  const body = {status: EDIT_SYNC_STATUS.DISABLED};

  return axios.patch(
    `${baseUrl}/v1/organizations/${orgId}/authSrcs/${directoryId}/externally_managed_status`,
    body,
    {
      headers: {
        ...getAuthorizationHeader(),
      },
    }
  );
};

/**
 * Adds the externally managed policy for the specified directory id.
 *
 * @param {String} directoryId Id of the directory that will get the policy.
 * @param {String} orgId Id of the organization.
 *
 * @return {any|Promise<AxiosResponse<any>>} Axios response
 */
const addExternallyManagedPolicy = (directoryId, orgId) => {
  const body = {status: EDIT_SYNC_STATUS.ENABLED};

  return axios.patch(
    `${baseUrl}/v1/organizations/${orgId}/authSrcs/${directoryId}/externally_managed_status`,
    body,
    {
      headers: {
        ...getAuthorizationHeader(),
      },
    }
  );
};

/**
 * Removes a directory sync link for the specified directoryId.
 *
 * @param {String} directoryId Id of the directory to remove link.
 * @param {String} partnerType A value from `SYNC_SOURCES`.
 * @param {String} orgId Id of the organization.
 *
 * @return {any|Promise<AxiosResponse<any>>} Axios response
 */
const removeLink = (directoryId, orgId) => {
  const params = {removeExternallyManagedPolicy: true};

  return axios.delete(`${baseUrl}/v1/organizations/${orgId}/authSrcs/${directoryId}`, {
    headers: {
      ...getAuthorizationHeader(),
    },
    params,
  });
};

/**
 * Gets all the links associated with a directory in the provided org.
 *
 * @param {String} orgId Id of the organization.
 *
 * @return {Promise<AxiosResponse<any>>} Axios response.
 */
const getLinks = (orgId) =>
  axios.get(`${baseUrl}/v1/organizations/${orgId}/links`, {
    headers: {
      ...getAuthorizationHeader(),
    },
  });

/**
 * Gets the sync status of the directory with the provided id.
 *
 * @param {String} orgId Id of the organizations
 * @param {String} directoryId Id of the directory to get the sync status for
 *
 * @return {Promise<AxiosResponse<any>>} Axios reponse
 */
const getStatus = (orgId, directoryId) =>
  axios.get(`${baseUrl}/v1/organizations/${orgId}/authSrcs/${directoryId}/sync/status`, {
    headers: {
      ...getAuthorizationHeader(),
    },
  });

/**
 * Enables/disabled the sync based on the value of the enabled flag provided.
 *
 * @param {String} orgId Organization id of the directory
 * @param {String} directoryId Id of the directory
 * @param {boolean} enabled Whether the sync should be turned on or off
 *
 * @return {Promise<AxiosResponse<any>>}
 */
const toggleSync = (orgId, directoryId, enabled) =>
  axios.post(
    `${baseUrl}/v1/organizations/${orgId}/authSrcs/${directoryId}/sync/enabled`,
    JSON.stringify(enabled),
    {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        ...getAuthorizationHeader(),
      },
    }
  );

export {
  createLink,
  getLinks,
  getScimToken,
  getStatus,
  removeLink,
  toggleSync,
  addExternallyManagedPolicy,
  removeExternallyManagedPolicy,
  SYNC_SOURCES,
  EDIT_SYNC_STATUS,
};
