import {GoUrl} from '@admin-tribe/acsc-ui';
import {Flex, Grid, Text, View} from '@adobe/react-spectrum';
import {useCheckboxGroupItem} from '@react-aria/checkbox';
import {useFocusRing} from '@react-aria/focus';
import PropTypes from 'prop-types';
import React, {useRef} from 'react';
import {useIntl} from 'react-intl';

import CreatePackageBodySection from './CreatePackageCardBodySection';
import {useCreatePackageCardCheckboxContext} from './CreatePackageCardCheckboxGroupContext';
import CreatePackageCardMenuButton from './CreatePackageCardMenuButton';

import './CreatePackageCardCheckbox.pcss';

/**
 * CreatePackageCard is a Card with Checkbox Component with 1 mandatory and 2 optional sections as below
 * 1. Grid part containing the title with optional headerIcon, Checkbox and titleDescription
 * 2. Sections with heading, optional link and description(optional)
 * 3. Button with menu(optional)
 */
const CreatePackageCardCheckbox = ({
  autoFocus = false,
  buttonString,
  cardMenuItems = [],
  contentHeight = 'auto',
  learnMoreAriaLabel,
  learnMoreString,
  name,
  onCardMenuItemPress,
  sections = [],
  title,
  titleDescription,
  titleIcon,
  value,
  ...cardProps
}) => {
  const intl = useIntl();

  const state = useCreatePackageCardCheckboxContext();
  const ref = useRef(null);
  const isSelected = state.isSelected(value);
  const {inputProps} = useCheckboxGroupItem(
    {
      'aria-label': title,
      autoFocus,
      value,
    },
    state,
    ref
  );
  const {isFocusVisible, focusProps} = useFocusRing();

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control -- associated with input
    <label>
      <div
        data-testid="create-package-card-checkbox"
        styleName={`create-package-card-checkbox ${isSelected ? 'selected' : ''} ${
          isFocusVisible ? 'focused' : ''
        }`}
      >
        <View {...cardProps}>
          <Grid
            areas={['title  checkbox', 'content content']}
            columns={['11fr', '1fr']}
            gap="size-50"
            height="auto"
            rows={['size-550', 'auto']}
          >
            <Flex alignItems="center" gap="size-50" gridArea="title">
              {titleIcon}
              <Text UNSAFE_style={{fontSize: '1.25em', fontWeight: 'bold'}}>{title}</Text>
            </Flex>
            <View alignSelf="center" gridArea="checkbox">
              <input {...inputProps} {...focusProps} ref={ref} />
            </View>
            <Flex direction="column" gap="size-100" gridArea="content">
              <View height="100%" minHeight={contentHeight}>
                {titleDescription}
              </View>
              {name && (
                <View>
                  <GoUrl
                    aria-label={
                      learnMoreAriaLabel ||
                      intl.formatMessage(
                        {id: 'packages.createPackage.licenseType.learnMoreAriaLabel'},
                        {title}
                      )
                    }
                    name={name}
                    noWrap={false}
                  >
                    {learnMoreString ||
                      intl.formatMessage({id: 'packages.tools.adminTools.learnMoreAbout'}, {title})}
                  </GoUrl>
                </View>
              )}
            </Flex>
          </Grid>
          {sections.length > 0 && <CreatePackageBodySection sections={sections} />}
          {cardMenuItems.length > 0 && (
            <CreatePackageCardMenuButton
              buttonString={buttonString}
              cardMenuItems={cardMenuItems}
              marginTop="size-200"
              onCardMenuItemPress={onCardMenuItemPress}
            />
          )}
        </View>
      </div>
    </label>
  );
};

CreatePackageCardCheckbox.propTypes = {
  /**
   * Whether the element will be auto focused. Defaults to false.
   */
  autoFocus: PropTypes.bool,
  /**
   * String to be displayed on the button of the card
   */
  buttonString: PropTypes.string,
  /**
   * Array of items to populate card menu
   */
  cardMenuItems: PropTypes.arrayOf(
    PropTypes.shape({displayString: PropTypes.string.isRequired, key: PropTypes.string.isRequired})
  ),
  /**
   * Content Height in the top section
   */
  contentHeight: PropTypes.string,
  /**
   * Aria label of learn more links
   */
  learnMoreAriaLabel: PropTypes.string,
  /**
   * String for the GoUrl learn more link
   */
  learnMoreString: PropTypes.string,
  /**
   * String passed to GoUrl name prop
   */
  name: PropTypes.string,
  /**
   * Function to be called when menu item pressed
   */
  onCardMenuItemPress: PropTypes.func,
  /**
   * Array containing sections of the card
   */
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Content of section
       */
      content: PropTypes.node.isRequired,
      /**
       * Function called when link presses
       */
      handleLinkPress: PropTypes.func,
      /**
       * Display string for link
       */
      linkName: PropTypes.string,
      /**
       * Title of section
       */
      title: PropTypes.string.isRequired,
    })
  ),
  /**
   * Title of the card
   */
  title: PropTypes.string.isRequired,
  /**
   * Description of the card
   */
  titleDescription: PropTypes.string.isRequired,
  /**
   * Icon to be displayed to the left of title
   */
  titleIcon: PropTypes.node,
  /**
   * String that is the value of the CreatePackageCard Checkbox
   */
  value: PropTypes.string.isRequired,
};

export default CreatePackageCardCheckbox;
