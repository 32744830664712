const SELF_CANCEL_STEPS = {
  BUMPER_STEP: 'BumperStep',
  CANCEL_REVIEW_STEP: 'CancelReviewStep',
  CANCELLATION_REASONS_STEP: 'CancellationReasonsStep',
  DONE_DISCOUNT_OFFER_STEP: 'DoneDiscountOfferStep',
  DONE_FREE_MONTHS_OFFER_STEP: 'DoneFreeMonthsOfferStep',
  DONE_FULL_CANCEL_STEP: 'DoneFullCancelStep',
  DONE_PARTIAL_CANCEL_STEP: 'DonePartialCancelStep',
  ERROR_STEP: 'ErrorStep',
  EXIT_STEP: 'ExitStep',
  INITIAL_LOADING_STEP: 'LoadingStep',
  LOSS_AVERSION_STEP: 'LossAversionStep',
  SAVE_OFFER_REVIEW_STEP: 'SaveOfferReviewStep',
  SAVE_OFFERS_STEP: 'SaveOffersStep',
  SELECT_LICENSES_STEP: 'SelectLicensesStep',
};

const SELF_CANCEL_MAX_LICENSES_THRESHOLD = 10;
const CHANGE_PLAN_JARVIS_MAX_LICENSES = 25;

const MANAGE_PLAN_ACTIONS = {
  ADD_LICENSES: 'addLicenses',
  CANCEL_LICENSES: 'cancelLicenses',
  CANCEL_TRIAL: 'cancelTrial',
  CHANGE_PLAN: 'changePlan',
};

const OFFER_TYPE = {
  TRIAL: 'TRIAL',
};

const PRICE_FAILURE = String.fromCharCode(8211, 8211);

const COMPLIANCE_SYMPTOMS = {
  CAN_DELETE: 'can_delete',
  CAN_MESSAGE_UPCOMING_CANCELLATION: 'can_message_upcoming_cancellation',
  CAN_SWITCH: 'can_switch',
};

export {
  CHANGE_PLAN_JARVIS_MAX_LICENSES,
  COMPLIANCE_SYMPTOMS,
  MANAGE_PLAN_ACTIONS,
  PRICE_FAILURE,
  SELF_CANCEL_MAX_LICENSES_THRESHOLD,
  SELF_CANCEL_STEPS,
  OFFER_TYPE,
};
