import {AlphaListState, JilModelList} from '@admin-tribe/acsc';

import jilStorageFolders from '../../api/jil/jilStorageFolders';
import StorageReportFolder from '../storage-report-folder/StorageReportFolder';

import {CACHE_ID, TYPE} from './StorageReportFolderListConstants';

class StorageReportFolderList extends JilModelList {
  /**
   * @description Method to create a new instance of a StorageReportFolderList.
   *
   * @param {Object} options - options passed to the StorageReportFolderList constructor.
   * @param {AlphaListState} options.state - alpha list state used to manage token-page API data.
   */
  constructor(options = {}) {
    super({
      isCacheable: true,
      itemClassRef: StorageReportFolder,
      modelCacheId: CACHE_ID,
      resource: jilStorageFolders.getStorageFolders,
      state: new AlphaListState(),
      ...options, // Override defaults
    });
  }

  /**
   * @description Method to refresh the current model state against what is stored in the back-end.
   *
   * @return {Promise<StorageReportFolderList>} resolves to refreshed StorageReportFolderList model, else rejects with error message
   */
  refresh() {
    return super.refresh({
      type: TYPE.REPORTS,
    });
  }
}

export default StorageReportFolderList;
