import {feature} from '@admin-tribe/acsc';

const ACTIVATION_CODE_PREFIX = 'BB';
const DEVICE_CODE_PREFIX = 'BB';
const UMI_CODE_PREFIX = 'U';
const SECURITY_CODE_PREFIX = 'S';
const ACTIVATION_CODE_VALID_LENGTH = 18;

const charToBitMap = {
  2: '11010',
  3: '11011',
  4: '11100',
  6: '11101',
  7: '11110',
  9: '11111',
  A: '00000',
  B: '00001',
  C: '00010',
  D: '00011',
  E: '00100',
  F: '00101',
  G: '00110',
  H: '00111',
  I: '01000',
  J: '01001',
  K: '01010',
  L: '01011',
  M: '01100',
  N: '01101',
  O: '01110',
  P: '01111',
  Q: '10000',
  R: '10001',
  S: '10010',
  T: '10011',
  U: '10100',
  V: '10101',
  W: '10110',
  X: '10111',
  Y: '11000',
  Z: '11001',
};

const DEVICE_CODE_VALID_LENGTH = 18;
const UMI_CODE_VALID_LENGTH = 19;
const SECURITY_CODE_VALID_LENGTH = 19;
const MAX_ACTIVATION_CODE_VALID_LENGTH = 152;

class ActivationCodeValidator {
  static isValidCode = (code) =>
    code &&
    code.length === ACTIVATION_CODE_VALID_LENGTH &&
    code.startsWith(ACTIVATION_CODE_PREFIX) &&
    this.isCrc16Valid(code);

  static isValidCodeV2 = (code) =>
    code && code.length <= MAX_ACTIVATION_CODE_VALID_LENGTH && this.checkCode(code);

  static checkCode = (code) => {
    const subCode = code.split('@');
    if (subCode.length === 1) return this.isValidDeviceCode(subCode[0]);

    if (!this.isValidDeviceCode(subCode[0])) {
      return false;
    }
    for (let i = 1; i < subCode.length - 1; i++) {
      if (!this.isValidUmiCode(subCode[i], `0${i}`)) {
        return false;
      }
    }
    if (!this.isValidSecurityCode(subCode[subCode.length - 1])) {
      return false;
    }
    return true;
  };

  static isValidDeviceCode = (code) =>
    code &&
    code.length === DEVICE_CODE_VALID_LENGTH &&
    code.startsWith(DEVICE_CODE_PREFIX) &&
    this.isCrc16Valid(code.slice(DEVICE_CODE_PREFIX.length));

  static isValidUmiCode = (code, version) =>
    code &&
    code.length === UMI_CODE_VALID_LENGTH &&
    code.startsWith(UMI_CODE_PREFIX + version) &&
    this.isCrc16Valid(code.slice(UMI_CODE_PREFIX.length + version.length));

  static isValidSecurityCode = (code) =>
    code && code.length === SECURITY_CODE_VALID_LENGTH && code.startsWith(SECURITY_CODE_PREFIX);

  static normalizeCode = (code) =>
    code.toUpperCase().replace(/0/g, 'O').replace(/8/g, 'B').replace(/5/g, 'S').replace(/1/g, 'I');

  static toByteArray = (bitString) =>
    bitString
      .match(/.{1,8}/g)
      .map((str) => Number.parseInt(str, 2))
      .reverse();

  static calculateCrc16(byteArray) {
    /* eslint-disable no-bitwise  -- override as this logic requires bitwise manipulation for calculating Crc16 of the byte array*/
    let crc = 0x0000;
    const polynomial = 0x1021;
    byteArray.forEach((byte) => {
      for (let i = 0; i < 8; i++) {
        const bit = ((byte >> (7 - i)) & 1) === 1;
        const c15 = ((crc >> 15) & 1) === 1;
        crc <<= 1;
        if (c15 ^ bit) {
          crc ^= polynomial;
        }
      }
    });

    return crc & 0xffff;
    /* eslint-enable no-bitwise  -- reason*/
  }

  static isCrc16Valid(code) {
    // remove prefix from activation code
    const codeWithoutPrefix = feature.isEnabled('temp_package_umi_challenge_code')
      ? code
      : code.slice(ACTIVATION_CODE_PREFIX.length);

    // extract bits from activation code
    let bitsInCode = '';
    // eslint-disable-next-line no-restricted-syntax -- override
    for (const element of codeWithoutPrefix) {
      if (!charToBitMap[element]) {
        return false;
      }
      bitsInCode += charToBitMap[element];
    }

    // calculate CRC-16 of first 64 bits that represent CRC-64
    const computedCrc16OfFirst64Bits = this.calculateCrc16(
      this.toByteArray(bitsInCode.slice(0, 64))
    );

    // extract the integer value of last 16 bits which
    // represents CRC-16 of above first 64 bits as CRC-64
    const crc16 = Number.parseInt(bitsInCode.slice(64), 2);

    // valid if the CRC-16 calculated from first 64 bits matches
    // the CRC-16 value represented by the last 16 bits
    return crc16 === computedCrc16OfFirst64Bits;
  }
}

export default ActivationCodeValidator;
