import {feature} from '@admin-tribe/acsc';
import {GoUrl, OverlayWait, showError, showSuccess} from '@admin-tribe/acsc-ui';
import {Flex, Heading, Item, Picker, Text, View} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {useAuthSettingsContext} from 'features/settings/components/auth-settings-page/AuthSettingsContext';
import {
  FINAL_MAX_SESSION_IDLE_TIME_OPTIONS,
  MAX_SESSION_IDLE_TIME_OPTIONS,
  MAX_SESSION_LIFE_OPTIONS,
  UNSET,
} from 'features/settings/components/auth-settings-page/AuthenticationPolicies.constants';
import {AUTHENTICATION_POLICIES} from 'features/settings/stores/AuthenticationPoliciesStore';

const AdvancedSettingsSection = observer(() => {
  const intl = useIntl();

  const {authenticationPoliciesStore} = useAuthSettingsContext();

  // This is a quick API. Do not block the UI while the update is outstanding.
  const updateAuthenticationPolicy = async (authenticationPolicy, id) => {
    const options =
      authenticationPolicy === AUTHENTICATION_POLICIES.MAX_LEN_SINCE_LAST_CRED_ENTRY
        ? MAX_SESSION_LIFE_OPTIONS
        : FINAL_MAX_SESSION_IDLE_TIME_OPTIONS;

    try {
      if (id === UNSET.key) {
        await authenticationPoliciesStore.removeAuthenticationPolicy(authenticationPolicy);
        showSuccess(
          intl.formatMessage({
            id: `settings.authSettings.advancedSettings.toast.${authenticationPolicy}.delete.success`,
          })
        );
      } else {
        await authenticationPoliciesStore.setAuthenticationPolicy(
          authenticationPolicy,
          options.find((option) => option.id === id).value
        );
        showSuccess(
          intl.formatMessage({
            id: `settings.authSettings.advancedSettings.toast.${authenticationPolicy}.update.success`,
          })
        );
      }
    } catch (error) {
      showError(
        intl.formatMessage({
          id: `settings.authSettings.advancedSettings.toast.${authenticationPolicy}.update.error`,
        })
      );
    }
  };

  function handleMaxLenSinceLastCredEntryChange(selection) {
    updateAuthenticationPolicy(AUTHENTICATION_POLICIES.MAX_LEN_SINCE_LAST_CRED_ENTRY, selection);
  }

  function handleMaxSessionIdleChange(selection) {
    updateAuthenticationPolicy(AUTHENTICATION_POLICIES.MAX_SESSION_IDLE_TIME, selection);
  }

  return (
    <View data-testid="advanced-settings-section">
      <OverlayWait isLoading={authenticationPoliciesStore.isLoading} showContent>
        <Heading data-testid="titled-section-title" level={2}>
          <FormattedMessage id="settings.authSettings.advancedSettings.title" />
        </Heading>
        <View
          backgroundColor="gray-50"
          borderColor="gray-200"
          borderRadius="small"
          borderWidth="thin"
          flexGrow="1"
          height="auto"
          marginBottom="size-100"
          width="100%"
        >
          <View padding="size-200">
            <Flex direction="column" gap="size-100" height="100%" padding="size-200">
              <Text>
                <FormattedMessage
                  id="settings.authSettings.advancedSettings.description"
                  values={{
                    goUrl: (linkText) => <GoUrl name="ac_authsettings">{linkText}</GoUrl>,
                  }}
                />
              </Text>

              <Picker
                data-testid="max-reauth-time-picker"
                items={MAX_SESSION_LIFE_OPTIONS}
                label={intl.formatMessage({
                  id: `settings.authSettings.advancedSettings.${AUTHENTICATION_POLICIES.MAX_LEN_SINCE_LAST_CRED_ENTRY}.label`,
                })}
                onSelectionChange={handleMaxLenSinceLastCredEntryChange}
                selectedKey={authenticationPoliciesStore.maxReauthenticationTime.id}
              >
                {(item) => <Item>{intl.formatMessage({id: item.label})}</Item>}
              </Picker>

              <Picker
                data-testid="max-sessionidle-time-picker"
                items={
                  feature.isEnabled('temp_advancedsettings_idle15')
                    ? FINAL_MAX_SESSION_IDLE_TIME_OPTIONS
                    : MAX_SESSION_IDLE_TIME_OPTIONS
                }
                label={intl.formatMessage({
                  id: `settings.authSettings.advancedSettings.${AUTHENTICATION_POLICIES.MAX_SESSION_IDLE_TIME}.label`,
                })}
                onSelectionChange={handleMaxSessionIdleChange}
                selectedKey={authenticationPoliciesStore.maxSessionIdleTime.id}
              >
                {(item) => <Item>{intl.formatMessage({id: item.label})}</Item>}
              </Picker>
            </Flex>
          </View>
        </View>
      </OverlayWait>
    </View>
  );
});

export default AdvancedSettingsSection;
