import {Product} from '@admin-tribe/acsc';

class OrganizationContractMigration {
  static apiResponseTransformer(organizationContractMigration) {
    return new OrganizationContractMigration(organizationContractMigration);
  }

  /**
   * @description Creates a new OrganizationContractMigration for use.
   *
   * @param {Object} options - OrganizationContractMigration object
   * @param {Object} options.migrationContext contract specific migration data about source and target licenses quantities
   * @param {Array} options.migrationContext.products organization product migration
   * @param {String} options.status migration status, [NOT_MIGRATING, MIGRATING, MIGRATED]
   * @param {String} options.type migration type, Ex: TEAM_VIP_TO_TEAM_EVIP, OFFER_SWITCH
   */
  constructor(options) {
    initModel(this, options);
  }
}

/** Private Methods **/

function getMigrationContext(migrationContext) {
  return {
    products: migrationContext?.products
      ? migrationContext.products.map((product) => ({
          quantity: product.quantity,
          // These come from a minimal model, and we ignore the FIs being empty
          source: new Product({...product.source, ignoreEmptyFIs: true}),
          target: new Product({...product.target, ignoreEmptyFIs: true}),
        }))
      : [],
  };
}

function initModel(model, options = {}) {
  Object.assign(model, {
    migrationContext: getMigrationContext(options.migrationContext),
    status: options.status,
    type: options.type,
  });
}

export default OrganizationContractMigration;
