import useAccountPageData from '../hooks/useAccountPageData';

/**
 * Account page preload to prefetch data for optimization
 */
const AccountPagePreload = () => {
  // Prefetch account page data
  useAccountPageData();

  // Render nothing
  return null;
};

export default AccountPagePreload;
