import {dispatchUiEventAnalytics} from '@admin-tribe/acsc';
import {useState} from 'react';

/**
 * Hook to use with various BuyMore Products modal variants which use <AddProductModalWrapper />.
 *
 * @returns {Object} obj The methods to control the modal
 * @returns {Function} obj.closeAddProductModal - Function called when the modal is closed.
 * @returns {Function} obj.openAddProducts - Function called when the modal is opened to add the products.
 * @returns {Boolean} obj.showAddProductModal - Boolean indicator to show/hide the Modal wrapper.
 */
const useAddProductModal = () => {
  const [showAddProductModal, setShowAddProductModal] = useState(false);

  const openAddProducts = () => {
    setShowAddProductModal(true);
    dispatchUiEventAnalytics({
      eventAction: 'click',
      eventName: 'addProducts',
    });
  };

  const closeAddProductModal = () => {
    setShowAddProductModal(false);
  };

  return {closeAddProductModal, openAddProducts, showAddProductModal};
};

export default useAddProductModal;
