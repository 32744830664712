import {csvBlobTransformer, downloadExportData} from '@admin-tribe/acsc';
import {
  Page,
  PageBreadcrumbs,
  PageContent,
  PageHeader,
  PageNav,
  TableSectionTable,
} from '@admin-tribe/acsc-ui';
import {Button} from '@adobe/react-spectrum';
import {useAsyncModel} from '@pandora/react-async-model';
import {TableActions, TableSection} from '@pandora/react-table-section';
import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import React, {useCallback, useMemo} from 'react';
import {useIntl} from 'react-intl';
import {useParams} from 'react-router-dom';

import useDocumentTitle from 'common/hooks/useDocumentTitle';
import Job from 'common/services/job/Job';
import {isReadOnly} from 'core/organizations/access/organizationAccess';
import useBulkOpsBreadcrumbs from 'features/bulk-operations/hooks/useBulkOpsBreadcrumbs';

/**
 * The license deficit report page for a bulk operation job result.
 * It is reached by clicking the "View details" link next to the "insufficient licenses"
 * error in the BulkOperationsDetailsDrawer.
 *
 * ToDo: For breadcrumbs, the home breadcrumb will be based on the route used to reach this page -
 *   i.e. from `Users bulk operations` or from User groups bulk operations`.
 */
const LicenseDeficitReportPage = ({path}) => {
  const {jobId} = useParams();
  const intl = useIntl();

  useDocumentTitle({key: 'bulkOperations.licenseDeficitReport.documentTitle'});

  const fetchJobSummary = useCallback(
    () =>
      Job.get({
        id: jobId,
      }),
    [jobId]
  );

  const {model: job, isLoading, error} = useAsyncModel({loadFn: fetchJobSummary});

  const deficitSummary = useMemo(() => {
    // Only render the TableSection if there is a job and no error.
    if (isLoading || !!error || !job) {
      return null;
    }
    return job.canShowLicenseDeficitSummary()
      ? orderBy(job.getLicenseDeficitSummary(), ['count'], ['desc'])
      : [];
  }, [error, isLoading, job]);

  // Hook returns null for each property until breadcrumbs are returned.
  const {breadcrumbs, onAction: onBreadcrumbsAction} = useBulkOpsBreadcrumbs({
    intl,
    jobResultsLabel: job?.getResultHeading(intl) ?? null,
    path,
  });

  const columnDescriptor = [
    {
      key: 'name',
    },
    {
      key: 'count',
      props: {align: 'end'},
    },
  ];

  // The render function will be called with an object which contains the 'item' which is the
  // deficitSummary item and the column 'key'.
  const renderers = {
    count: ({item: {count}}) => (Number.isFinite(count) ? intl.formatNumber(count) : null),
    name: ({item: {name}}) => name,
  };

  // Download works even when there are no deficit items.
  const onPress = () => {
    function assembleCSV() {
      // Add in the localized headers
      let csv = `${intl.formatMessage({
        id: 'bulkOperations.licenseDeficitReport.table.column.name',
      })},${intl.formatMessage({
        id: 'bulkOperations.licenseDeficitReport.table.column.count',
      })}\n`;
      deficitSummary.forEach((item) => {
        csv += `${item.name},${item.count}\n`;
      });
      return {data: csv};
    }
    const fileName = job.getLicenseDeficitReportFilename();
    const response = assembleCSV();
    downloadExportData(csvBlobTransformer(response).data.file, fileName);
  };

  return (
    <Page
      data-testid="license-deficit-report-page"
      isBumpered={!!error}
      isLoading={isLoading || breadcrumbs === null}
    >
      <PageHeader title={intl.formatMessage({id: 'bulkOperations.licenseDeficitReport.title'})} />
      {breadcrumbs && (
        <PageBreadcrumbs
          isDisabled={false}
          items={breadcrumbs}
          onAction={(key) => onBreadcrumbsAction({breadcrumbs, key})}
        />
      )}
      <PageNav />
      <PageContent>
        {deficitSummary && (
          <TableSection items={deficitSummary} pageNumber={0}>
            {!isReadOnly() && (
              <TableActions>
                <Button data-testid="download-results-button" onPress={onPress} variant="accent">
                  {intl.formatMessage({
                    id: 'bulkOperations.licenseDeficitReport.button.downloadResults',
                  })}
                </Button>
              </TableActions>
            )}

            <TableSectionTable
              aria-label={intl.formatMessage({
                id: 'bulkOperations.licenseDeficitReport.table.ariaLabel',
              })}
              columnDescriptor={columnDescriptor}
              columnNamespace="bulkOperations.licenseDeficitReport.table.column"
              renderers={renderers}
            />
          </TableSection>
        )}
      </PageContent>
    </Page>
  );
};
LicenseDeficitReportPage.propTypes = {
  /**
   * The React router path used to reach this page.
   * For example, PATH_USERS_BULK_OPERATIONS_LICENSE_DEFICIT_REPORT.
   */
  path: PropTypes.string.isRequired,
};

export default LicenseDeficitReportPage;
