import {downloadExportData} from '@admin-tribe/acsc';
import {JilModelList} from '@pandora/data-model-list';

import jilJobs from 'common/api/jil/jilJobs';
import {JOB_RESULT_ITEM_STATUS} from 'common/services/job/JobConstants';
import JobResult from 'common/services/job-result/JobResult';
import rootStore from 'core/RootStore';

class JobResultList extends JilModelList {
  /**
   * @description Downloads the job result as a CSV where each row
   *   corresponds to each row of the input.
   *
   * @param {String} fileName - the file name to export the reponse with
   * @param {String} jobId - the ID of the job whose results should be
   *   exported
   *
   * @return {Promise} a promise which resolves to the filename when the
   *   download is complete, or rejects if the download failed
   */
  static async export({fileName, jobId}) {
    const jobResults = await jilJobs.getJobResults({
      acceptHeader: 'text/csv+organization-job-result',
      jobId,
      orgId: rootStore.organizationStore.activeOrgId,
    });

    downloadExportData(jobResults.data.file, fileName);

    return fileName;
  }

  /**
   * @description Returns a new JobResult representing the result of the
   *   specified job. The JobResult will populate when the back-end call
   *   completes.
   *
   * @param {String} jobId - id of job result to fetch
   *
   * @returns {JobResultList} a JobResultList representing the result of the specified
   *   job
   */
  static get({jobId}) {
    const model = new JobResultList({jobId});
    return model.refresh();
  }

  /**
   * @description Method to create a new instance of a JobResult.
   *
   * @param {String} jobId - ID of job to fetch from back-end
   */
  constructor({jobId}) {
    super();
    this.id = jobId;
  }

  /**
   * @description Retrieves the failed job results from the list.
   *
   * @returns {JobResult[]} A list of failed operations.
   */
  getFailedResultItems() {
    return this.items.filter((item) => item.status === JOB_RESULT_ITEM_STATUS.FAILURE);
  }

  /**
   * @description Retrieves the list of operations for a given bulk operation job
   *
   * @returns {JobResultList} Instance of JobResultsList containing a list of operations.
   */
  async refresh() {
    const result = await jilJobs.getJobResults({
      jobId: this.id,
      orgId: rootStore.organizationStore.activeOrgId,
    });

    this.items = result.data.items?.map(JobResult.apiResponseTransformer) || [];

    // since this does not get set at construction, this needs to manually set
    this.pagination.itemCount = this.items.length;

    return this;
  }
}

export default JobResultList;
