/* eslint-disable max-lines -- to be refactored */
import {Store, log} from '@admin-tribe/acsc';
import {action, computed, makeObservable, observable, runInAction} from 'mobx';

import rootStore from 'core/RootStore';
import {
  addExternallyManagedPolicy,
  removeExternallyManagedPolicy,
} from 'features/settings/api/directory-sync';
import {
  createIdp,
  getAuthSource,
  removeIdp,
  updateAuthSource,
  updateIdp,
  uploadIdpMetadata,
} from 'features/settings/api/ims-federated';
import IdpEntity, {IDP_STATUS, IDP_TYPES} from 'features/settings/entities/IdpEntity';
import DirectoryDomainsStore from 'features/settings/stores/DirectoryDomainsStore';
import DirectorySyncStore from 'features/settings/stores/DirectorySyncStore';

const EXTERNALLY_MANAGED_POLICY = 'EXTERNALLY_MANAGED';

const AUTHENTICATION_SETUP_STATUSES = {
  CERTIFICATE_EXPIRED: 'CERTIFICATE_EXPIRED',
  CERTIFICATE_EXPIRING: 'CERTIFICATE_EXPIRING',
  COMPLETED: 'COMPLETED',
  IN_PROGRESS: 'IN_PROGRESS',
  UNCONFIGURED: 'UNCONFIGURED',
};

const AUTO_ACCOUNT_CREATION_STATUSES = {
  DISABLED: 'DISABLED',
  ENABLED: 'ENABLED',
};

const UPDATE_STRATEGIES = {
  ALWAYS_UPDATE: 'ALWAYS_UPDATE',
  DO_NOT_UPDATE: 'DO_NOT_UPDATE',
  UPDATE_WHEN_NOT_EMPTY: 'UPDATE_WHEN_NOT_EMPTY',
};

class DirectoryStore extends Store {
  defaultIdp;
  directoryId;
  domains;
  errorCreatingSaml = false;
  idps = [];
  isUpdatingIdp = false;
  name;

  policyConfig;
  sync;

  constructor(directoryId) {
    super();

    makeObservable(this, {
      autoAccountCreationStatus: computed,
      createSamlIdp: action,
      defaultIdp: observable,
      directoryId: observable,
      errorCreatingSaml: observable,
      hasAzureIdp: computed,
      hasDefaultIdp: computed,
      hydrate: action,
      idps: observable,
      isExternallyManaged: computed,
      isUpdatingIdp: observable,
      mapData: action,
      mapIdps: action,
      name: observable,
      policyConfig: observable,
      removeIdp: action,
      samlIdp: computed,
      setDefaultIdp: action,
      setDirectoryId: action,
      setDirectoryName: action,
      setIsExternallyManaged: action,
      setupStatus: computed,
      toggleExternallyManagedPolicy: action,
      updateIdp: action,
      uploadIdpMetadata: action,
    });

    this.directoryId = directoryId;
    this.sync = new DirectorySyncStore(this.directoryId);
    this.domains = new DirectoryDomainsStore(this.directoryId);
  }

  get autoAccountCreationStatus() {
    const autoAccountCreationIdpList = this.idps.filter((idp) => idp.jitStatus);

    if (autoAccountCreationIdpList.length === 0) {
      return {status: AUTO_ACCOUNT_CREATION_STATUSES.DISABLED};
    }

    return {
      count: autoAccountCreationIdpList.length,
      status: AUTO_ACCOUNT_CREATION_STATUSES.ENABLED,
    };
  }

  async createIdp(idpData) {
    this.isLoading = true;

    try {
      const response = await createIdp({
        authSourceId: this.directoryId,
        idpData: {
          // set default JIT config when creating a new IdP -- this can be changed by the caller
          jitConfig: {
            accountCreation: true,
            syncStrategy: UPDATE_STRATEGIES.DO_NOT_UPDATE,
          },
          ...idpData,
        },
        orgId: rootStore.organizationStore.activeOrgId,
      });

      runInAction(() => {
        this.isLoading = false;
        this.idps.push(
          new IdpEntity({
            ...response.data,
            authSrcId: this.authSrcId || this.directoryId,
            defaultIdp: this.defaultIdp,
          })
        );
      });

      return this.idps.find((idp) => idp.id === response.data.id);
    } catch (error) {
      log.error(
        `[ID][FED] Error creating IdP with type ${idpData.federationType} in directory ${this.directoryId} in organization ${rootStore.organizationStore.activeOrgId}`,
        error
      );

      throw error;
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  async createSamlIdp() {
    this.isLoading = true;
    let response;

    try {
      response = await createIdp({
        authSourceId: this.directoryId,
        idpData: {
          federationType: IDP_TYPES.SAML,
        },
        orgId: rootStore.organizationStore.activeOrgId,
      });

      runInAction(() => {
        this.isLoading = false;
        this.idps.push(new IdpEntity(response.data));
      });
    } catch (error) {
      runInAction(() => {
        this.errorCreatingSaml = true;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }

    return response;
  }

  async fetchData() {
    const response = await getAuthSource({
      authSourceId: this.directoryId,
      orgId: rootStore.organizationStore.activeOrgId,
    });

    this.mapData(response.data);

    return response;
  }

  get hasAzureIdp() {
    return this.idps.some((idp) => idp.isAzure);
  }

  get hasDefaultIdp() {
    return this.idps.some((idp) => idp.defaultIdp);
  }

  hydrate(authSourceData) {
    if (!authSourceData) {
      return;
    }

    this.authSrcId = authSourceData.id;
    this.name = authSourceData.name;
    this.mapIdps(authSourceData);
  }

  get isExternallyManaged() {
    return Object.hasOwnProperty.call(this.policyConfig, EXTERNALLY_MANAGED_POLICY);
  }

  mapData(authSourceData) {
    this.directoryId = authSourceData.id;
    this.defaultIdp = authSourceData.defaultIdp;
    this.policyConfig = authSourceData.policyConfig;
    this.name = authSourceData.name;
    this.mapIdps(authSourceData);
  }

  mapIdps(authSourceData) {
    this.idps = authSourceData.idps
      .map(
        (idp) =>
          new IdpEntity({
            ...idp,
            authSrcId: this.authSrcId || this.directoryId,
            defaultIdp: authSourceData.defaultIdp,
          })
      )
      .filter((idp) => !idp.isUsageDeprecated)
      .sort((idp) => (idp.isDefault ? -1 : 1));
  }

  async removeIdp(idpId) {
    this.isLoading = true;

    try {
      await removeIdp({
        authSourceId: this.directoryId,
        idpId,
        orgId: rootStore.organizationStore.activeOrgId,
      });

      runInAction(() => {
        const idpToRemove = this.idps.find((idp) => idp.id === idpId);
        this.idps.remove(idpToRemove);
      });
    } catch (error) {
      log.error(
        `[ID][FED] Error removing IdP with id ${idpId} in directory ${this.directoryId} in organization ${rootStore.organizationStore.activeOrgId}`,
        error
      );
      throw error;
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  get samlIdp() {
    return this.idps.find((idp) => idp.isSaml);
  }

  async setDefaultIdp(idpId) {
    this.isLoading = true;

    try {
      await updateAuthSource({
        authSourceId: this.directoryId,
        data: {defaultIdp: idpId},
        orgId: rootStore.organizationStore.activeOrgId,
      });

      runInAction(() => {
        this.defaultIdp = idpId;
      });
    } catch (error) {
      log.error(
        `[ID][FED] Error setting default IdP with id ${idpId} in directory ${this.directoryId} in organization ${rootStore.organizationStore.activeOrgId}`,
        error
      );
      throw error;
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  setDirectoryId(directoryId) {
    this.directoryId = directoryId;
  }

  setDirectoryName(name) {
    this.name = name;
  }

  setIsExternallyManaged(value) {
    if (value) {
      this.policyConfig = {...this.policyConfig, [EXTERNALLY_MANAGED_POLICY]: true};
    } else {
      const policyConfig = {...this.policyConfig};
      delete policyConfig[EXTERNALLY_MANAGED_POLICY];

      this.policyConfig = policyConfig;
    }
  }

  get setupStatus() {
    if (this.idps.length === 0) {
      return AUTHENTICATION_SETUP_STATUSES.UNCONFIGURED;
    }

    if (this.idps.find((idp) => idp.isDefault && idp.isActive)) {
      return AUTHENTICATION_SETUP_STATUSES.COMPLETED;
    }

    return AUTHENTICATION_SETUP_STATUSES.IN_PROGRESS;
  }

  async toggleExternallyManagedPolicy(value) {
    this.isLoading = true;

    if (value) {
      await addExternallyManagedPolicy(this.directoryId, rootStore.organizationStore.activeOrgId);
    } else {
      await removeExternallyManagedPolicy(
        this.directoryId,
        rootStore.organizationStore.activeOrgId
      );
    }

    this.setIsExternallyManaged(value);

    runInAction(() => {
      this.isLoading = false;
    });
  }

  async updateIdp({idpId, data}) {
    this.isUpdatingIdp = true;

    try {
      const updatedIdp = this.idps.find((idp) => idp.id === idpId);
      await updateIdp({
        authSourceId: this.directoryId,
        data: {...data, federationType: updatedIdp.federationType},
        idpId,
        orgId: rootStore.organizationStore.activeOrgId,
      });

      runInAction(() => {
        Object.assign(updatedIdp, data);
      });
    } catch (error) {
      log.error(
        `[ID][FED] Error updating IdP with id ${idpId} in directory ${this.directoryId} in organization ${rootStore.organizationStore.activeOrgId}`,
        error
      );
      throw error;
    } finally {
      runInAction(() => {
        this.isUpdatingIdp = false;
      });
    }
  }

  async uploadIdpMetadata({idpId, metadataFile}) {
    this.isUpdatingIdp = true;

    try {
      const updatedIdp = this.idps.find((idp) => idp.id === idpId);

      await uploadIdpMetadata({
        authSourceId: this.directoryId,
        idpId,
        metadataFile,
        orgId: rootStore.organizationStore.activeOrgId,
      });

      runInAction(() => {
        Object.assign(updatedIdp, {
          status: IDP_STATUS.ACTIVE,
        });
      });
    } catch (error) {
      log.error(
        `[ID][FED] Error uploading metadata for IdP with id ${idpId} in directory ${this.directoryId} in organization ${rootStore.organizationStore.activeOrgId}`,
        error
      );
      throw error;
    } finally {
      runInAction(() => {
        this.isUpdatingIdp = false;
      });
    }
  }
}

export default DirectoryStore;
export {
  AUTHENTICATION_SETUP_STATUSES,
  AUTO_ACCOUNT_CREATION_STATUSES,
  EXTERNALLY_MANAGED_POLICY,
  UPDATE_STRATEGIES,
};
/* eslint-enable max-lines -- to be refactored */
