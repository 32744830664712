import {
  AlphaListState,
  JIL_CONSTANTS,
  JilModelList,
  MEMBER_EVENT,
  eventBus,
  log,
  modelCache,
} from '@admin-tribe/acsc';
import cloneDeep from 'lodash/cloneDeep';

import {JIL_STORAGE_TYPE} from '../../api/jil/jilStorageFolderConstants';
import jilStorageFolders from '../../api/jil/jilStorageFolders';
import UserFolder from '../user-folder/UserFolder';
import {REMOVE_USER_STORAGE_OPTIONS, USER_FOLDER_EVENTS} from '../user-folder/userFolderConstants';

import {CACHE_ID, USER_FOLDER_LIST_EVENTS} from './UserFolderListConstants';

const {SORT} = JIL_CONSTANTS;

class UserFolderList extends JilModelList {
  /**
   * @description Method to fetch the a list of UserFolder based on a given
   *  list of user IDs.
   * @param {Object} options - configuration options for list retrieval
   * @param {String[]} options.userIds - a list of user IDs to search on
   * @returns {UserFolderList} an instance of a UserFolderList with the
   *  specified users, if they exist.
   */
  static async getListByUsers(options) {
    try {
      const {data} = await jilStorageFolders.getUserFoldersByUsers(options.userIds);
      const filteredResults = data.filter((result) => result.responseCode === 200);
      const items = filteredResults.map((result) => new UserFolder(result.response));
      return Promise.resolve(new UserFolderList({items}));
    } catch (error) {
      log.error('Failed to fetch the user folder list given users. Error: ', error);
      return Promise.reject(error);
    }
  }

  /**
   * @description Method to create a new instance of a UserFolderList.
   * @param {Object} options - options passed to the UserFolderList constructor
   * @param {String} options.includes - type of folder to fetch, active, all, and discarded,
   *                                    defaults to active
   * @param {AlphaListState} options.state - the alpha list state used to manage token-page API data.
   */
  constructor(options = {}) {
    super({
      cacheClearingEvents: [
        USER_FOLDER_EVENTS.CREATE,
        USER_FOLDER_EVENTS.DELETE,
        USER_FOLDER_LIST_EVENTS.UPDATE,
        MEMBER_EVENT.CREATE,
        MEMBER_EVENT.DELETE,
        MEMBER_EVENT.UPDATE,
      ],
      isCacheable: true,
      itemClassRef: UserFolder,
      modelCacheId: CACHE_ID,
      resource: jilStorageFolders.getStorageFolders,
      ...options, // Override defaults
    });
    // Save the default state so when search is cleared this
    // can be used again without having the consumer redefine state
    this.defaultState = options.state;
    this.includes = options.includes;
  }

  /**
   * @description Update the current instance of the UserFolderList.
   *
   * @param {Object} options the same attributes passed into the UserFolderList constructor.
   */
  #updateModel(options) {
    // Cloned to avoid issues when updating the nested object items.
    const clonedOptions = cloneDeep(options);
    Object.assign(this, clonedOptions);
    modelCache.put(CACHE_ID, this.getKey(), this);
  }

  /**
   * @description Method to fetch the remove path override for user with user folders.
   *
   * @param {String} id - the id of the user whose storage is being operated on.
   * @param {REMOVE_USER_STORAGE_OPTIONS} type - the remove user storage option type.
   *
   * @returns {String} the string used to override the default patch remove operations.
   */
  getRemovePathOverride({id, type}) {
    const result = {};
    this.items.forEach((userFolder) => {
      switch (type) {
        case REMOVE_USER_STORAGE_OPTIONS.DISCARD:
          result[userFolder.name] = `/${userFolder.name}/STORAGE/ARCHIVE`;
          break;
        case REMOVE_USER_STORAGE_OPTIONS.DELETE:
          result[userFolder.name] = `/${userFolder.name}/STORAGE/DELETE`;
          break;
        default:
          result[userFolder.name] = `/${userFolder.name}/STORAGE/ARCHIVE/${id}`;
      }
    });
    return result;
  }

  /**
   * @description Method to check if there is content in the user folder list
   *
   * @returns {Boolean} true if there is content
   */
  hasContent() {
    return this.state?.hasContent || false;
  }

  /**
   * @description Method to permanently delete user folders.
   *  The caller should refresh the list.
   *
   * @param {Array<UserFolder>} folders - folders to be permanently deleted
   * @returns {Promise} resolves to UserFolderList on success, else rejects with error
   */
  async permanentDelete(folders) {
    const operations = folders.map((folder) => ({
      op: 'remove',
      path: `/${folder.id}/delete`,
    }));

    try {
      const {data} = await jilStorageFolders.patchStorageFolders({
        operations,
        type: JIL_STORAGE_TYPE.USERS,
      });

      if (
        Array.isArray(data) &&
        data.some((item) => !(item.responseCode >= 200 && item.responseCode < 400))
      ) {
        log.error('Failed to permanently delete all user folders.');
        return Promise.reject(new Error('Failed to permanently delete all user folders.'));
      }
      eventBus.emit(USER_FOLDER_EVENTS.DELETE);
      return Promise.resolve(this);
    } catch (error) {
      log.error('Failed to permanently delete user folders. Error: ', error);
      return Promise.reject(error);
    }
  }

  /**
   * @description Method to refresh the current model state against what is stored in the back-end.
   *
   * @return {Promise<UserFolderList>} resolves to refreshed UserFolderList model, else rejects with error message
   */
  refresh() {
    return super.refresh({
      includes: this.includes,
      type: JIL_STORAGE_TYPE.USERS,
    });
  }

  /**
   * @description Method to reset User Folder list.
   *  Typically used after clearing a search.
   *
   * @param {Object} [options] - options passed to the UserFolderList
   *  constructor, defaults to an empty object.
   * @return {Promise<UserFolderList>} resolves to updated UserFolderList model,
   *  else rejects with error message.
   */
  async reset(options = {}) {
    try {
      const userFolderList = await UserFolderList.get({
        ...options,
        state: options.state || this.defaultState,
      });
      this.#updateModel(userFolderList);
    } catch (error) {
      log.error('Failed to fetch the user folder list. Error: ', error);
      return Promise.reject(error);
    }
    return this;
  }

  /**
   * @description Method to search and update the list.
   *
   * @param {Object} options to include in the search
   * @param {User} user - user to search for among the user folders
   * @return {Promise<UserFolderList>} resolves to updated UserFolderList model,
   *  else rejects with error message.
   */
  async search({user}) {
    try {
      const filteredUserFolderList = await UserFolderList.getListByUsers({userIds: [user.id]});
      if (filteredUserFolderList.items.length > 0) {
        filteredUserFolderList.items[0].owners.push(user);
        filteredUserFolderList.items[0].userName = user.userName;
      }

      this.#updateModel({
        ...filteredUserFolderList,
        state: new AlphaListState({
          filterQuery: user.id,
          hasContent: true,
          pageSize: 10,
          sortKey: SORT.CREATED_DATE,
        }),
      });
    } catch {
      // No successful results searching
      this.#updateModel({items: []});
    }
    return this;
  }
}

export default UserFolderList;
