import {Cache, authentication, configStore, feature, log, modelCache} from '@admin-tribe/acsc';
import {
  INTRODUCTION_STATUS,
  REQUEST_ACCESS_STATUS,
  getAcrsFetch,
  getAllAcrsIntroductions,
  getAllAuthzRequests,
} from '@pandora/react-data-source-acrs';
import {Mutex, withTimeout} from 'async-mutex';

import rootStore from 'core/RootStore';
import {isOrgAdmin} from 'core/organizations/access/organizationAccess';
import {canViewAutoAssignmentRules} from 'core/products/access/productAccess';

const SOPHIA_INTRODUCTIONS_MODEL_CACHE_ID = 'SOPHIA_INTRODUCTIONS';
const SOPHIA_PENDING_REQUESTS_MODEL_CACHE_ID = 'SOPHIA_PENDING_REQUESTS';

// remove these cache values with temp_sophia_introductions
const PENDING_REQUESTS_CACHE_TTL = 2 * 60 * 1000; // 2 minutes
const pendingRequestsCache = new Cache();
const pendingRequestMutex = withTimeout(new Mutex(), 100000); // wait for a maximum of 100 seconds

/**
 * construct string for auto assign rules
 *
 * the returned array contains rules in a string representation in a special
 * syntax
 *
 * syntax: <product arrangement code>:<activation status>:<Adobe
 * created>:<notifications on>
 *
 * notifications can be true, false, or null - null indicates that the
 * preference has not been chosen yet
 *
 * examples: CCSV:ACTIVE:false:false LCCC:WAITING:true:null
 *
 * @param {Array} autoAssignRules auto assign rules as returned from ACRS API
 * @param {ProductList} productList product list to get product code from
 *
 * @returns {Array<String>} array of strings representing auto assign rules
 */
function constructAutoAssignRulesSpecialString(autoAssignRules, productList) {
  if (!autoAssignRules) {
    return [];
  }

  return autoAssignRules.map(
    (rule) =>
      `${
        productList.items.find((product) => product.id === rule.licenseId)?.productArrangementCode
      }:${rule.status.toUpperCase()}:${!!rule.waitingUntilDate}:${
        rule.eligibleForNotifications ?? 'null'
      }`
  );
}

/**
 * Construct audience and type string for auto assign rules
 *
 * the returned array contains rules in a string representation in a special
 * syntax
 *
 * syntax:
 *   For audience: [paCode:audience]
 *   For type: [paCode:type]
 *
 * audience can be All-And-External or All-Organization
 * type can be URL only or On Demand URL
 *
 * examples:
 *   For audience: apcc_direct_indirect_team:All-And-External,acro_direct_team:All-Organization
 *   For type: apcc_direct_indirect_team:URL only,acro_direct_team:On Demand URL
 *
 * @param {Array} autoAssignRules auto assign rules as returned from ACRS API
 *
 * @returns [string, string] array of strings representing auto assign rules
 */
function getTypeAndAudienceFromAutoAssignRules(autoAssignRules) {
  if (!autoAssignRules || autoAssignRules.length === 0) {
    return [];
  }

  const ruleTypes = [];
  const ruleAudiences = [];
  autoAssignRules.forEach((rule) => {
    let audience = null;
    let ruleType = null;

    switch (rule.triggers) {
      case 'ON_DEMAND_OR_URL':
        ruleType = 'On Demand URL';
        break;
      case 'ON_URL':
        ruleType = 'URL only';
        break;
      default:
        break;
    }

    if (ruleType) {
      ruleTypes.push(`${rule.paCode}:${ruleType}`);
    }

    switch (rule.userScope) {
      case 'ALL':
        audience = 'All-And-External';
        break;
      case 'ORGANIZATION':
        audience = 'All-Organization';
        break;
      default:
        break;
    }

    if (audience) {
      ruleAudiences.push(`${rule.paCode}:${audience}`);
    }
  });

  return [ruleAudiences.join(','), ruleTypes.join(',')];
}

/**
 * Construct an array for DEFAULT PROFILE JIT rule defined for all entitled products in this org
 *
 * the returned an array contains the string with format code1:offerId:boolean, this indicates the target offerId of this product,
 * and whether it has the JIT rule defined ONLY WITH DEFAULT PROFILE,
 * if a product has JIT rule defined with a non-default profile or no JIT rule defined, it will be false.
 *
 * syntax: [code1:offerId:boolean,code2:offerId:boolean,code3:offerId:boolean]
 *
 * examples: [APAP:B685F1DAB145A3C1DE1987C7B32BDD51:true,DRWV:FCF47CF17AE8637810E2BDEE7427A065:false]
 *
 * @param {Array} autoAssignRules auto assign rules as returned from ACRS API
 * @param {Array} candidateProductsLicenseGroups all profile information of expected entitled products in this org
 *
 * @returns An array of product codes
 */
function getJitRuleDefaultProfileDefined(autoAssignRules, candidateProductsLicenseGroups) {
  const jitRuleDefinedProductCode = {};
  candidateProductsLicenseGroups?.forEach((licenseGroupForOneProduct) => {
    const defaultProfile = licenseGroupForOneProduct.items[0];
    if (defaultProfile && defaultProfile.product) {
      jitRuleDefinedProductCode[
        `${defaultProfile.product.code}:${defaultProfile.product.offerId}`
      ] = false;
      autoAssignRules?.forEach((rule) => {
        if (rule.licenseId === defaultProfile.product.id) {
          jitRuleDefinedProductCode[
            `${defaultProfile.product.code}:${defaultProfile.product.offerId}`
          ] = rule.productProfile === defaultProfile.id;
        }
      });
    }
  });

  return Object.keys(jitRuleDefinedProductCode).map(
    (key) => `${key}:${jitRuleDefinedProductCode[key]}`
  );
}
/**
 * @async
 *
 * get count of pending product requests
 *
 * remove notes with temp_sophia_introductions
 * Notes:
 * - This function tries to use a cached value with a TTL of 2 minutes.
 * - This function assumes that rootStore and configStore have been loaded
 *
 * @returns {Promise<Number|undefined>} number of pending requests or undefined
 *     if not applicable or failure
 */
async function getNumberOfPendingProductRequests() {
  if (feature.isEnabled('temp_sophia_introductions')) {
    return canViewAutoAssignmentRules() ? (await getPendingRequests())?.length : undefined;
  }
  const orgId = rootStore.organizationStore.activeOrgId;

  if (!orgId || !canViewAutoAssignmentRules()) {
    return undefined;
  }

  try {
    // Because multiple workflows trigger this method, there are cases where
    // these call fetchPendingRequests before any single one's callback / await
    // finishes and gets a chance to update the cache. This async-mutex fix
    // ensures one finishes and others wait.
    await pendingRequestMutex.acquire();
    const cachedValue = pendingRequestsCache.get(orgId);
    if (cachedValue !== null) {
      return cachedValue;
    }

    const count = (await fetchPendingRequestsDeprecated(orgId))?.length;
    pendingRequestsCache.put(orgId, count, {
      lifetime: PENDING_REQUESTS_CACHE_TTL,
    });
    return count;
  } catch (error) {
    log.error(`Error in processing pending product requests: ${error}`);
  } finally {
    pendingRequestMutex.release();
  }

  return undefined;
}

/**
 * @description get data on pending or ignored "user introductions". May also contain introductions
 * in other states, see INTRODUCTION_STATUS for details.
 *
 * @return @return {Promise<{[actionableIntroductionsCount]: number,[ignoredIntroductionsCount]: number,[pendingIntroductionsCount]:number}>|}
 * @property {Number} [actionableIntroductionsCount] number of introductions that can be acted upon
 * @property {Number} [ignoredIntroductionsCount] number of introductions ignored
 * @property {Number} [pendingIntroductionsCount] number of introductions pending review
 */
async function getIntroductionsData() {
  if (!isOrgAdmin()) {
    return {};
  }
  const introductions = await getIntroductions();
  if (!introductions) {
    return {};
  }

  const pendingIntroductionsCount = introductions.filter(
    ({status}) => status === INTRODUCTION_STATUS.ADMIN_REVIEW_PENDING
  ).length;
  // This is also considered as "Save for Later" count
  const ignoredIntroductionsCount = introductions.filter(
    ({status}) => status === INTRODUCTION_STATUS.ADMIN_IGNORED
  ).length;
  const actionableIntroductionsCount = pendingIntroductionsCount + ignoredIntroductionsCount;

  return {
    actionableIntroductionsCount,
    ignoredIntroductionsCount,
    pendingIntroductionsCount,
  };
}

//////////////////////////

/**
 * @description general pandora fetch options for modules using IMS
 */
const getPandoraIMSFetchOptions = () => ({
  accessToken: authentication.getAccessToken().token,
  apiKey: configStore.get('services.ims.clientId'),
  env: configStore.get('pandoraEnv'),
  // This fetch override is needed because this class is called outside of a binky react wrapper,
  // which takes care of doing this for other Pandora data providers. The default Pandora fetch
  // cannot be correctly mocked for our fakes environment.
  // Need to replace fetch provider with window.fetch, like:
  // https://git.corp.adobe.com/admin-tribe/onesie/blob/master/src2/app/features/insights/insightsStore.js#L205
  // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- In browser
  fetch: window.fetch.bind(window),
});

/**
 * @description make a network call for pending product requests
 */
async function fetchPendingRequests() {
  const orgId = rootStore.organizationStore.activeOrgId;
  try {
    const response = await getAllAuthzRequests(getAcrsFetch(getPandoraIMSFetchOptions()), {
      orgId,
      params: {
        status: REQUEST_ACCESS_STATUS.PENDING,
      },
    });
    return await response.json();
  } catch (error) {
    log.error(`Error in processing pending product requests: ${error}`);
    return undefined;
  }
}

/**
 * @description make a network call for pending requests
 */
async function fetchPendingRequestsDeprecated(orgId) {
  const acrsFetch = getAcrsFetch({
    accessToken: authentication.getAccessToken().token,
    apiKey: configStore.get('services.ims.clientId'),
    env: configStore.get('pandoraEnv'),
    // This fetch override is needed because this class is called outside of a binky react wrapper,
    // which takes care of doing this for other Pandora data providers. The default Pandora fetch
    // cannot be correctly mocked for our fakes environment.
    // Need to replace fetch provider with window.fetch, like:
    // https://git.corp.adobe.com/admin-tribe/onesie/blob/master/src2/app/features/insights/insightsStore.js#L205
    // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- In browser
    fetch: window.fetch.bind(window),
  });
  const response = await getAllAuthzRequests(acrsFetch, {
    orgId,
    params: {
      status: REQUEST_ACCESS_STATUS.PENDING,
    },
  });

  const jsonData = await response.json();
  return jsonData;
}

/**
 * @description perform a network call for introductions
 */
async function fetchIntroductions() {
  const orgId = rootStore.organizationStore.activeOrgId;
  try {
    const response = await getAllAcrsIntroductions(getAcrsFetch(getPandoraIMSFetchOptions()), {
      orgId,
    });
    return (await response.json()).resources;
  } catch (error) {
    log.error(`Error in processing introductions: ${error}`);
    return undefined;
  }
}

/**
 * @description obtain cached user introductions or fetch them
 */
function getIntroductions() {
  if (modelCache.has(SOPHIA_INTRODUCTIONS_MODEL_CACHE_ID, 'value')) {
    return modelCache.get(SOPHIA_INTRODUCTIONS_MODEL_CACHE_ID, 'value');
  }
  const dataPromise = fetchIntroductions();
  modelCache.put(SOPHIA_INTRODUCTIONS_MODEL_CACHE_ID, 'value', dataPromise);
  return dataPromise;
}

/**
 * @description obtain cached pending product requests or fetch them
 */
function getPendingRequests() {
  if (modelCache.has(SOPHIA_PENDING_REQUESTS_MODEL_CACHE_ID, 'value')) {
    return modelCache.get(SOPHIA_PENDING_REQUESTS_MODEL_CACHE_ID, 'value');
  }
  const dataPromise = fetchPendingRequests();
  modelCache.put(SOPHIA_PENDING_REQUESTS_MODEL_CACHE_ID, 'value', dataPromise);
  return dataPromise;
}

export {
  constructAutoAssignRulesSpecialString,
  getNumberOfPendingProductRequests,
  getIntroductionsData,
  getTypeAndAudienceFromAutoAssignRules,
  getJitRuleDefaultProfileDefined,
};
