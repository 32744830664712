const SOPHIA_CONSTANTS = {
  ANALYTICS_ID: 'AdminConsole|Sophia',
  CLIENT_CODE: 'ONESIE1',
  DISMISSED_SOPHIA_CARD_IDS: 'dismissedSophiaCardIds',
  MODEL_CACHE_ID: 'SophiaSurfaces',
  SOPHIA_REQUIRED_PARAMS: [
    'actionURL',
    'backgroundFillColor',
    'backgroundImage',
    'cardID',
    'cardLabel',
    'ctaLabel',
    'displayText',
  ],
  SURFACE_ID: {
    ONE_CONSOLE: 'one_console',
    ONE_CONSOLE_ACCOUNT: 'one_console_account',
    ONE_CONSOLE_HELPFUL_RESOURCES_ARTICLES: 'one_console_helpful_resources_articles',
    ONE_CONSOLE_HELPFUL_RESOURCES_LINKS: 'one_console_helpful_resources_links',
    ONE_CONSOLE_HVA: 'one_console_hva',
    ONE_CONSOLE_MERCHANDISING: 'one_console_merchandising',
    ONE_CONSOLE_PACKAGES: 'one_console_packages',
    ONE_CONSOLE_PRODUCTS: 'one_console_products',
    ONE_CONSOLE_PRODUCTS_BANNER: 'one_console_products_banner',
    ONE_CONSOLE_PROMOTION: 'one_console_promotion',
    ONE_CONSOLE_QUICK_ASSIGN: 'one_console_quick_assign',
    ONE_CONSOLE_QUICK_LINKS: 'one_console_quick_links',
    ONE_CONSOLE_RENEWAL: 'one_console_renewal',
    ONE_CONSOLE_RENEWAL_PROMO_CHAT: 'one_console_renewal_promo_chat',
    ONE_CONSOLE_SELF_CANCEL_SURVEY: 'one_console_self_cancel_survey',
    ONE_CONSOLE_SETTINGS: 'one_console_settings',
    ONE_CONSOLE_SUPPORT: 'one_console_support',
    ONE_CONSOLE_USERS: 'one_console_users',
    SELF_CANCEL_PROMO_MERCH: 'self_cancel_promo_merch',
  },
};

export default SOPHIA_CONSTANTS;
