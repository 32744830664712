import {StockQuantitiesList} from '@admin-tribe/acsc';
import {useAsyncModel} from '@pandora/react-async-model';
import {useCallback} from 'react';

/**
 * @param {String} orgId - the org id
 * @param {String} [profileId] - the profile id. Defaults to empty string
 * @returns {Object} state - Object with state variables
 *          {StockQuantitiesList} state.stockQuantitiesList - the StockQuantitiesList model
 *          {Boolean} state.isLoading - whether the data is being loaded
 *          {Object} state.error - the error is loading fails
 *          {Function} state.refreshList - the refresh function to update the stockQuantitiesList state.
 */
const useStockQuantitiesList = ({orgId, profileId = ''}) => {
  const loadStockQuantitiesList = useCallback(
    () => StockQuantitiesList.get(orgId, profileId),
    [orgId, profileId]
  );

  const {
    model: stockQuantitiesList,
    isLoading,
    error,
    updateModel,
  } = useAsyncModel({
    loadFn: loadStockQuantitiesList,
  });

  const refreshList = useCallback(async () => {
    await updateModel(() => stockQuantitiesList.refresh());
  }, [stockQuantitiesList, updateModel]);

  return {
    error,
    isLoading,
    refreshList,
    stockQuantitiesList,
  };
};

export default useStockQuantitiesList;
