import {DATE_FORMATS, FormattedDateAndTime} from '@admin-tribe/acsc-ui';
import PropTypes from 'prop-types';
import React from 'react';

import {PRICE_FAILURE} from '../../SelfCancelConstants';

/**
 * SafeFormattedDateAndTime - A thin Binky FormattedDateAndTime wrapper that will render a fallback
 * node if value is undefined. The default fallback is the exported PRICE_FAILURE constant, unless
 * a custom node is provided.
 * See https://git.corp.adobe.com/admin-tribe/binky-ui/blob/master/src2/common/components/formatted-date-and-time/FormattedDateAndTime.jsx
 * for full documentation of props.
 */
const SafeFormattedDateAndTime = ({fallbackNode = PRICE_FAILURE, value, ...props}) =>
  value ? <FormattedDateAndTime value={value} {...props} /> : fallbackNode;

SafeFormattedDateAndTime.propTypes = {
  /**
   * The node to use when price is undefined or currency doesn't have formatString defined.
   * Defaults to exported PRICE_FAILURE constant value.
   */
  fallbackNode: PropTypes.node,

  /**
   * The format for the date/time from DATE_FORMATS. Default is DATE_FORMATS.dateOnly.
   */
  format: PropTypes.oneOf(Object.values(DATE_FORMATS)),
  /**
   * The date/time to be formatted.
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
};

export default SafeFormattedDateAndTime;
