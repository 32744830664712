import {useEffect} from 'react';
import {useLocation, useParams} from 'react-router-dom';

import {useAnalytics} from 'core/analytics/AnalyticsProvider';
import routeMigration from 'core/router/route-migration/routeMigration';

/**
 * Observes the location and sends analytics events
 */
const LocationObserver = () => {
  const location = useLocation();
  const params = useParams();
  const {eventHandler} = useAnalytics();

  // watches for location changes and sends analytics events
  useEffect(() => {
    if (!eventHandler) return;

    routeMigration.forEach((route) => {
      if (location.pathname.replace(params.orgId, ':orgId') === route.url) {
        // disable the analytics event on page load , we already send data on page load in the page component
        // need to verify if this is really used
        // if (!Array.isArray(route.stateName)) {
        //   eventHandler.onPageChange(route.stateName.replace(/\./g, ':'));
        // }
      }
    });
  }, [location, eventHandler, params.orgId]);

  return null;
};

export default LocationObserver;
