import {
  ModelList,
  dispatchUiEventAnalytics,
  feature,
  getSessionStorageItem,
  setSessionStorageItem,
} from '@admin-tribe/acsc';
import isBoolean from 'lodash/isBoolean';
import mapValues from 'lodash/mapValues';
import remove from 'lodash/remove';

import sophiaV2 from 'common/api/sophiaV2';

import SophiaCard from './SophiaCard';
import SOPHIA_CONSTANTS from './SophiaConstants';

/**
 * @description Service for interacting with Sophia (Test & Target).
 *  The Sophia service call is tied to a particular campaign (identified by clientCode and surfaceId)
 *  which is configured to discern relevant offers to display (in the form of card objects)
 *  to the user based on a set of "contextualParams" defined by the campaign.
 */
class SophiaCardList extends ModelList {
  /**
   * @param {Object} options - options for the SophiaCardList
   * @param {Object} options.contextualParams - contextualParams
   * @param {String} options.surfaceID - surfaceID
   */
  constructor(options) {
    const {contextualParams, surfaceID} = options;
    super({
      isCacheable: true,
      itemClassRef: SophiaCard,
      modelCacheId: SOPHIA_CONSTANTS.MODEL_CACHE_ID,
      resource: sophiaV2.getContent,
      transformResponseData: (response) => transformResponse(response, this.dismissedSophiaCardSet),
    });

    Object.assign(this, {
      contextualParams,
      dismissedSophiaCardSet: new Set(
        JSON.parse(getSessionStorageItem(SOPHIA_CONSTANTS.DISMISSED_SOPHIA_CARD_IDS))
      ),
      surfaceID,
    });
  }

  /**
   * @description dismiss a sophia card until a session ends
   * @param {SophiaCard} card sophia card to dismiss
   */
  dismissCard(card) {
    if (this.items.find((c) => c.getCardId?.() === card.getCardId?.())) {
      remove(this.items, card);
      this.dismissedSophiaCardSet.add(card.getSessionStorageCardId());
      setSessionStorageItem(
        SOPHIA_CONSTANTS.DISMISSED_SOPHIA_CARD_IDS,
        JSON.stringify([...this.dismissedSophiaCardSet])
      );
    }
  }

  /**
   * @description method to fetch offer content from Sophia.
   * @returns {Promise} resolved with array of card objects containing Target data to display.
   */
  refresh() {
    return super.refresh({
      clientCode: SOPHIA_CONSTANTS.CLIENT_CODE,
      contextualParams: transformRequest(this.contextualParams.toMinimumModel()),
      surfaceID: [this.surfaceID],
    });
  }
}

/**
 * @description Method to stringify boolean object values (as expected by Sophia API).
 * @param {Object} obj who's values may contain booleans.
 * @returns {Object} object who's values are not booleans.
 */
function transformRequest(obj) {
  return mapValues(obj, (value) => {
    const result = isBoolean(value) ? value.toString() : value;
    return result;
  });
}

/**
 * @description Method to transform the sophia card response into renderable content.
 * @param {Object} response array of cards returned by Sophia.
 * @param {Set} dismissedSophiaCardSet a Set of dismissed sophia cards
 * @returns {Object[]} array of renderable cards.
 */
function transformResponse(response, dismissedSophiaCardSet) {
  const sophiaCards = [];
  const {surfaces} = response;
  const cardAnalytics = [];
  const impressions = [];

  Object.keys(surfaces).forEach((surfaceId) => {
    const {containers} = surfaces[surfaceId];
    sophiaCards.push(
      ...containers.map((container) => {
        let card;

        if (container?.dataType === 'application/json') {
          const containerData = JSON.parse(container?.data);
          let cardContent = containerData;

          /**
           * We need to transform the data because of the following reason:
           *
           * The content in Odin is returned in a different format than the content returned previously by AEM.
           * Since we didn't want to change the existing model, a new Odin model, sophiaHvaBannerContentModelByPath,
           * was created replicating the older AEM model. The HVA banner should always use content fragments from
           * this model.
           */
          if (
            feature.isEnabled('sophia_content_from_odin') &&
            containerData?.data?.sophiaHvaBannerContentModelByPath?.item
          ) {
            cardContent = containerData.data.sophiaHvaBannerContentModelByPath.item;
          }

          card = new SophiaCard({
            card: cardContent,
            containerAnalyticsParams: container?.containerAnalyticsData,
            containerId: container?.containerId,
            surfaceId,
          });

          impressions.push(card.getImpressions());
        } else {
          card = new SophiaCard({
            containerAnalyticsParams: container?.containerAnalyticsData,
            containerId: container?.containerId,
            surfaceId,
          });
        }

        cardAnalytics.push(card.getAnalyticsParams());

        return card;
      })
    );
  });

  dispatchUiEventAnalytics({
    eventAction: 'load',
    eventName: 'sophiaBannerLoad',
    interaction: {
      impression: impressions.join(','),
    },
    sophia: {
      responses: cardAnalytics,
    },
  });

  return sophiaCards.filter(
    (card) => card.isCardValid() && !dismissedSophiaCardSet.has(card.getSessionStorageCardId())
  );
}

export default SophiaCardList;
