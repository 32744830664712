/* eslint-disable max-lines -- this file requires more lines */
import {Contract, ProductList, feature} from '@admin-tribe/acsc';
import {BulletList, BulletListItem, GoUrl, ModalContent} from '@admin-tribe/acsc-ui';
import {Flex, Heading, Text, View} from '@adobe/react-spectrum';
import AlertIcon from '@spectrum-icons/workflow/Alert';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';

import SafePrice from 'common/components/safe-price/SafePrice';

import {EVENT_NAME} from '../SelfCancelAnalyticsUtils';
import {SELF_CANCEL_STEPS} from '../SelfCancelConstants';
import {
  ACTIONS,
  useProductsChangeContext,
} from '../components/products-change-context/ProductsChangeContext';
import SafeFormattedDateAndTime from '../components/safe-formatted-date-and-time/SafeFormattedDateAndTime';
import {useSelfCancelAnalyticsContext} from '../components/self-cancel-analytics-context/SelfCancelAnalyticsContext';
import SelfCancelBaseFooter from '../components/self-cancel-base-modal/SelfCancelBaseFooter';
import SelfCancelBaseModal from '../components/self-cancel-base-modal/SelfCancelBaseModal';
import SelfCancelCart from '../components/self-cancel-cart/SelfCancelCart';
import useCancellationCart from '../hooks/use-cancellation-cart/useCancellationCart';
import useCancellationDetails from '../hooks/use-cancellation-details/useCancellationDetails';

import styles from './CancelReviewStep.pcss';

const BoldedText = (boldedText) => <span styleName="bolded-bullet">{boldedText}</span>;
const {CANCEL_REVIEW_STEP} = SELF_CANCEL_STEPS;

/**
 * Self cancel workflow step component for the cancellation review, where the implications of the
 * cancel are explained.
 */
const CancelReviewStep = ({
  comment,
  contract,
  currentStep,
  onClose,
  onConfirm,
  onPrevious,
  onStartChat,
  productList,
  selectedReasons,
  selectedSeats,
  steps,
}) => {
  const intl = useIntl();

  const {dispatchProductsChange, error, isLoading, productsChange} = useProductsChangeContext();
  const {dispatchNavigationAnalyticsEvent} = useSelfCancelAnalyticsContext();
  const {
    cancelFeeAmt,
    currency,
    daysCount,
    isFullCancelFlow,
    licenseCount,
    nextBillingDate,
    originalInvoiceAmount,
    recurrenceTerm,
    reducedInvoiceAmount,
    refundAmount,
    taxTerm,
    terminationDate,
    users,
  } = useCancellationDetails({contract, selectedSeats});

  const {items, total} = useCancellationCart({
    contract,
    filterCancellingOnly: feature.isEnabled('trial_with_payment'),
    productList,
  });

  const [errorMessage, setErrorMessage] = useState();

  // Pop up a chat window when this page is loaded
  useEffect(() => {
    if (feature.isDisabled('bug_fix_33967')) {
      onStartChat({step: CANCEL_REVIEW_STEP});
      // reset appId to ONESIE1
      return () => onStartChat({});
    }
    return () => {};

    // eslint-disable-next-line react-hooks/exhaustive-deps -- only want this to run on setup and takedown, not every render
  }, []);

  const submitDispatched = useRef(false);
  // To react to submission call resolution
  useEffect(() => {
    if (submitDispatched.current && !isLoading) {
      submitDispatched.current = false;
      if (error) {
        setErrorMessage(
          intl.formatMessage({
            id: 'account.selfCancel.cancelReview.submit.error',
          })
        );
      } else if (productsChange.submitted) {
        onConfirm?.();
      }
    }
  }, [error, intl, isLoading, onConfirm, productsChange]);

  const onCloseError = () => {
    setErrorMessage();
  };

  const onCancel = () => {
    dispatchNavigationAnalyticsEvent({clickType: EVENT_NAME.CANCEL});
    onClose?.();
  };

  const onSecondary = () => {
    dispatchNavigationAnalyticsEvent({clickType: EVENT_NAME.PREVIOUS});
    onPrevious?.();
  };

  const onSubmit = () => {
    dispatchNavigationAnalyticsEvent({clickType: EVENT_NAME.CONFIRM});
    submitDispatched.current = true;
    dispatchProductsChange({comment, selectedReasons, selectedSeats, type: ACTIONS.SUBMIT});
  };

  return (
    <SelfCancelBaseModal
      cancelLabel={intl.formatMessage({id: 'common.modal.buttons.close'})}
      ctaLabel={intl.formatMessage({id: 'account.selfCancel.buttons.confirm'})}
      ctaVariant="negative"
      currentStep={currentStep}
      errorMessage={errorMessage}
      heading={intl.formatMessage({id: 'account.selfCancel.common.title'})}
      isCtaDisabled={isLoading || !!errorMessage}
      isLoading={isLoading}
      onCancel={onCancel}
      onCloseError={onCloseError}
      onCta={onSubmit}
      onSecondary={onSecondary}
      secondaryLabel={intl.formatMessage({id: 'account.selfCancel.buttons.previous'})}
      steps={steps}
      subheading={intl.formatMessage({id: 'account.selfCancel.cancelReview.description'})}
    >
      <ModalContent>
        <Flex gap="size-300">
          <View flexGrow="1">
            <Heading
              level={3}
              // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needed for styling text
              UNSAFE_className={styles.heading}
            >
              {intl.formatMessage({id: 'account.selfCancel.cancelReview.listHeader'})}
            </Heading>
            <BulletList>
              {refundAmount > 0 && (
                <BulletListItem data-testid="refund-bullet-point" useIconColor variant="positive">
                  {/* eslint-disable-next-line @admin-tribe/admin-tribe/extract-large-computations -- necessary for SafePrice */}
                  {intl.formatMessage(
                    {id: 'account.selfCancel.cancelReview.reviewDetailsList.full.refund'},
                    {
                      b: BoldedText,
                      businessDaysCount: 5,
                      refundPrice: () => (
                        <SafePrice
                          currency={currency}
                          isTaxInclusive
                          overrideStyles={styles}
                          price={refundAmount}
                          taxTerm={taxTerm}
                        />
                      ),
                    }
                  )}
                </BulletListItem>
              )}
              {!refundAmount && cancelFeeAmt > 0 && (
                <BulletListItem
                  data-testid="cancellation-fee-bullet-point"
                  Icon={
                    <AlertIcon
                      alt={intl.formatMessage({
                        id: 'account.selfCancel.cancelReview.cancellationFeeAltText',
                      })}
                      color="negative"
                      flexShrink="0"
                      marginTop="size-25"
                      size="S"
                    />
                  }
                  useIconColor
                  variant="negative"
                >
                  {/* eslint-disable-next-line @admin-tribe/admin-tribe/extract-large-computations -- necessary for SafePrice */}
                  {intl.formatMessage(
                    {id: 'account.selfCancel.cancelReview.reviewDetailsList.full.cancellationFee'},
                    {
                      b: BoldedText,
                      cancellationFeePrice: () => (
                        <SafePrice
                          currency={currency}
                          isTaxInclusive
                          overrideStyles={styles}
                          price={cancelFeeAmt}
                          taxTerm={taxTerm}
                        />
                      ),
                    }
                  )}
                </BulletListItem>
              )}
              {isFullCancelFlow ? (
                <>
                  <BulletListItem data-testid="invoice-expiration-bullet-point">
                    {/* eslint-disable-next-line @admin-tribe/admin-tribe/extract-large-computations -- necessary for intl.formatMessage nested functions */}
                    {intl.formatMessage(
                      {
                        id: 'account.selfCancel.cancelReview.reviewDetailsList.full.invoiceExpiration',
                      },
                      {
                        b: BoldedText,
                        changesEffectDate: () => (
                          <SafeFormattedDateAndTime value={nextBillingDate} />
                        ),
                        invoiceExpirationPrice: () => (
                          <SafePrice
                            currency={currency}
                            isTaxInclusive={false}
                            overrideStyles={styles}
                            price={originalInvoiceAmount}
                            recurrenceTerm={recurrenceTerm}
                            taxTerm={taxTerm}
                          />
                        ),
                      }
                    )}
                  </BulletListItem>
                  <BulletListItem data-testid="all-licenses-removed-bullet-point">
                    {intl.formatMessage(
                      {
                        id: 'account.selfCancel.cancelReview.reviewDetailsList.full.allLicensesRemoved',
                      },
                      {
                        b: BoldedText,
                        daysCount,
                      }
                    )}
                  </BulletListItem>
                  <BulletListItem data-testid="cloud-storage-reduced-bullet-point">
                    {/* eslint-disable-next-line @admin-tribe/admin-tribe/extract-large-computations -- this block requires more lines */}
                    {intl.formatMessage(
                      {
                        id: 'account.selfCancel.cancelReview.reviewDetailsList.full.cloudStorageReduced',
                      },
                      {
                        b: BoldedText,
                        goUrl: (urlText) => (
                          <GoUrl name="cloud_storage" noWrap={false}>
                            {urlText}
                          </GoUrl>
                        ),
                      }
                    )}
                  </BulletListItem>
                  {users.length > 0 && (
                    <BulletListItem data-testid="lose-access-bullet-point">
                      {intl.formatMessage(
                        {id: 'account.selfCancel.cancelReview.reviewDetailsList.full.loseAccess'},
                        {
                          b: BoldedText,
                          i: (italicText) => <span styleName="italic-bullet">{italicText}</span>,
                          loseAccessDate: () => (
                            <SafeFormattedDateAndTime value={terminationDate} />
                          ),
                        }
                      )}
                    </BulletListItem>
                  )}
                </>
              ) : (
                <>
                  <BulletListItem data-testid="invoice-reduction-bullet-point">
                    {/* eslint-disable-next-line @admin-tribe/admin-tribe/extract-large-computations -- necessary for intl.formatMessage nested functions */}
                    {intl.formatMessage(
                      {
                        id: 'account.selfCancel.cancelReview.reviewDetailsList.partial.invoiceReduction',
                      },
                      {
                        b: BoldedText,
                        changesEffectDate: () => (
                          <SafeFormattedDateAndTime value={nextBillingDate} />
                        ),
                        originalCost: () => (
                          <SafePrice
                            currency={currency}
                            isTaxInclusive={false}
                            overrideStyles={styles}
                            price={originalInvoiceAmount}
                            recurrenceTerm={recurrenceTerm}
                            taxTerm={taxTerm}
                          />
                        ),
                        reducedCost: () => (
                          <SafePrice
                            currency={currency}
                            isTaxInclusive={false}
                            overrideStyles={styles}
                            price={reducedInvoiceAmount}
                            recurrenceTerm={recurrenceTerm}
                            taxTerm={taxTerm}
                          />
                        ),
                      }
                    )}
                  </BulletListItem>
                  <BulletListItem data-testid="some-licenses-removed-bullet-point">
                    {intl.formatMessage(
                      {
                        id: 'account.selfCancel.cancelReview.reviewDetailsList.partial.someLicensesRemoved',
                      },
                      {
                        b: BoldedText,
                        daysCount,
                        licenseCount,
                      }
                    )}
                  </BulletListItem>
                  <BulletListItem data-testid="cloud-storage-reduced-bullet-point">
                    {/* eslint-disable-next-line @admin-tribe/admin-tribe/extract-large-computations -- this block requires more lines */}
                    {intl.formatMessage(
                      {
                        id: 'account.selfCancel.cancelReview.reviewDetailsList.partial.cloudStorageReduced',
                      },
                      {
                        b: BoldedText,
                        goUrl: (urlText) => (
                          <GoUrl name="cloud_storage" noWrap={false}>
                            {urlText}
                          </GoUrl>
                        ),
                      }
                    )}
                  </BulletListItem>
                  {users.length > 0 && (
                    <BulletListItem data-testid="lose-access-bullet-point">
                      {/* eslint-disable-next-line @admin-tribe/admin-tribe/extract-large-computations -- this block requires more lines */}
                      {intl.formatMessage(
                        {
                          id: 'account.selfCancel.cancelReview.reviewDetailsList.partial.loseAccess',
                        },
                        {
                          b: BoldedText,
                          i: (italicText) => <span styleName="italic-bullet">{italicText}</span>,
                          loseAccessDate: () => (
                            <SafeFormattedDateAndTime value={terminationDate} />
                          ),
                        }
                      )}
                      <View elementType="ul" margin="size-0">
                        {users.map((displayName) => (
                          <li key={displayName}>{displayName}</li>
                        ))}
                      </View>
                    </BulletListItem>
                  )}
                </>
              )}
            </BulletList>
          </View>
          <View flexShrink="0" width="340px">
            <Heading
              level={3}
              marginBottom="size-150"
              // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needed for styling text
              UNSAFE_className={styles.heading}
            >
              {intl.formatMessage({
                id: 'account.selfCancel.components.selfCancelCart.title',
              })}
            </Heading>
            <SelfCancelCart
              currency={currency}
              items={items}
              recurrenceTerm={recurrenceTerm}
              total={total}
            />
          </View>
        </Flex>
      </ModalContent>
      <SelfCancelBaseFooter>
        <Text
          // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needed for styling text
          UNSAFE_className={styles['footer-legal']}
        >
          {/* eslint-disable-next-line @admin-tribe/admin-tribe/extract-large-computations -- this block requires more lines */}
          {intl.formatMessage(
            {id: 'account.selfCancel.cancelReview.footer'},
            {
              subscriptionTermsLink: (linkText) => (
                <GoUrl name="subscriptionterms" noWrap={false}>
                  {linkText}
                </GoUrl>
              ),
              termsLink: (linkText) => (
                <GoUrl name="terms" noWrap={false}>
                  {linkText}
                </GoUrl>
              ),
            }
          )}
        </Text>
      </SelfCancelBaseFooter>
    </SelfCancelBaseModal>
  );
};

CancelReviewStep.propTypes = {
  /**
   * The cancellation reason inputted by admin manually
   */
  comment: PropTypes.string,
  /**
   * The org's contract.
   */
  contract: PropTypes.instanceOf(Contract).isRequired,
  /**
   * Current step in the flow.
   */
  currentStep: PropTypes.string,
  /**
   * Handler that is called when the user wants to close the flow.
   */
  onClose: PropTypes.func,
  /**
   * Handler that is called when the user confirms the cancellation of licenses.
   */
  onConfirm: PropTypes.func,
  /**
   * Handler that is called when the user wants to navigate back.
   */
  onPrevious: PropTypes.func,
  /**
   * Handler that is called when the user wants to start a chat session.
   * Will also send up an object specifying which step a user is initiating a chat session from.
   * i.e. {step: 'CancelReviewStep'}
   */
  onStartChat: PropTypes.func,
  /**
   * The list of org's products.
   */
  productList: PropTypes.instanceOf(ProductList),
  /**
   * The selected reason codes for cancellation
   */
  selectedReasons: PropTypes.arrayOf(PropTypes.string),
  /**
   * The hash map of of initially selected licenses per product id.
   */
  selectedSeats: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  /**
   * List of steps in the flow.
   */
  steps: PropTypes.arrayOf(PropTypes.string),
};

export default CancelReviewStep;
/* eslint-enable max-lines -- this file requires more lines */
