import {PATH_APPROVE_PRODUCT_AUTH_REQUEST_ID} from 'features/approve-product/routing/productApprovePaths';

const productApproveMigration = [
  {
    stateName: '',
    url: PATH_APPROVE_PRODUCT_AUTH_REQUEST_ID,
    useReactRoute: 'true',
  },
];

export default productApproveMigration;
