import {getProductDisplayName} from '@admin-tribe/acsc-ui';

import rootStore from 'core/RootStore';

/**
 * @description Sort products alphabetically
 * @deprecated - Use 'sortProducts' in core/products/utils/productUtils
 *
 * @param {Object} intl - react intl
 * @param {Product[]} products - array of products
 * @returns {Product[]} - alphabetically sorted array of products
 */
function sortProducts(intl, products) {
  return products.sort((aProduct, bProduct) => {
    const aDisplayName = getProductDisplayName(intl, aProduct);
    const bDisplayName = getProductDisplayName(intl, bProduct);
    return aDisplayName.localeCompare(bDisplayName);
  });
}

/**
 * Returns the display name for the first contract in product.contractIds
 *
 * @param {Array} contractIds - list of contract Ids for a product
 * @returns {String|undefined} - display name for first contract ID in contractIds
 */
function getContractDisplayName(contractIds) {
  const organizationContracts = rootStore.organizationStore.contractList?.items;

  if ((organizationContracts?.length ?? 0) <= 1 || !contractIds) return undefined;
  return organizationContracts.find((contract) => contract.id === contractIds[0])?.displayName;
}

export {sortProducts, getContractDisplayName};
