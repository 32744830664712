import {
  ModalContainer,
  ModalDialog,
  ModalHeading,
  WIZARD_DISPATCH_ACTION,
  WizardTrigger,
  WizardView,
} from '@admin-tribe/acsc-ui';
import {Button, Divider} from '@adobe/react-spectrum';
import React, {useCallback, useState} from 'react';
import {useIntl} from 'react-intl';

import OrganizationContractMigrationList from 'features/contract/models/OrganizationContractMigrationList';

import MigrateTeamToEnterpriseLicense from './MigrateTeamToEnterpriseLicense';
import TeamToEnterpriseContractMigrationIntro from './TeamToEnterpriseContractMigrationIntro';
import TeamToEnterpriseContractMigrationSummary from './TeamToEnterpriseContractMigrationSummary';
import {useTeamToEnterpriseMigrationContext} from './context/TeamToEnterpriseContractMigrationContext';

const WIZARD_STEPS = {
  INTRO: 0,
  SUMMARY: 2,
  TRANSFER_LICENSES: 1,
};

const STEPS = [
  WIZARD_STEPS.INTRO.toString(),
  WIZARD_STEPS.TRANSFER_LICENSES.toString(),
  WIZARD_STEPS.SUMMARY.toString(),
];

const TeamToEnterpriseContractMigrationModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const intl = useIntl();
  const {
    isLoading,
    migrationData,
    setIsLoading,
    totalLicenseCount,
    showSuccessBanner,
    setShowSuccessBanner,
  } = useTeamToEnterpriseMigrationContext();

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const getButtonText = (type) => intl.formatMessage({id: `common.modal.buttons.${type}`});

  const getCancelButtonText = () => getButtonText('cancel');

  const getCtaButtonText = (currentStep) => {
    if (currentStep === WIZARD_STEPS.TRANSFER_LICENSES) {
      return getButtonText('confirm');
    }
    if (currentStep === WIZARD_STEPS.SUMMARY) {
      return getButtonText('ok');
    }
    return getButtonText('next');
  };

  const getModalString = (suffix) =>
    intl.formatMessage({id: `migrations.teamToEnterpriseMigrationModal.${suffix}`});

  const migrate = useCallback(async () => {
    setIsLoading(true);
    try {
      const requestBody = {
        contractId: migrationData.contractEligibleForMigration.contract.id,
        type: 'TEAM_VIP_TO_TEAM_EVIP',
      };
      await OrganizationContractMigrationList.migrate(requestBody);
      setIsLoading(false);
      setShowSuccessBanner(true);
      return true;
    } catch (error) {
      setIsLoading(false);
      setShowSuccessBanner(false);
      return false;
    }
  }, [migrationData, setIsLoading, setShowSuccessBanner]);

  const onModalCta = async ({currentStep, dispatch}) => {
    if (currentStep === WIZARD_STEPS.TRANSFER_LICENSES) {
      const isSuccess = await migrate();
      dispatch({type: WIZARD_DISPATCH_ACTION.INCREMENT});
      return isSuccess;
    }
    if (currentStep === WIZARD_STEPS.SUMMARY) {
      closeModal();
    }
    dispatch({type: WIZARD_DISPATCH_ACTION.INCREMENT});
    return false;
  };

  const isCtaDisabled = () => isLoading;
  const onCancel = () => closeModal();
  const onPress = () => {
    setIsModalOpen(true);
  };

  const renderModalContent = () => (
    <>
      <ModalHeading>{getModalString('title')}</ModalHeading>
      <Divider marginBottom="size-150" marginTop="size-150" size="M" />
      <WizardView>
        <TeamToEnterpriseContractMigrationIntro totalLicenseCount={totalLicenseCount} />
        <MigrateTeamToEnterpriseLicense
          migrationData={migrationData}
          totalLicenseCount={totalLicenseCount}
        />
        <TeamToEnterpriseContractMigrationSummary
          onCancel={onCancel}
          showSuccessBanner={showSuccessBanner}
        />
      </WizardView>
    </>
  );

  const renderModalDialog = ({currentStep, dispatch}) => (
    <ModalDialog
      cancelLabel={getCancelButtonText()}
      ctaLabel={getCtaButtonText(currentStep)}
      errorMessage={migrationData?.errorMessage}
      heightVariant="static"
      id="team-to-enterprise-contract-migration"
      isCtaDisabled={isCtaDisabled()}
      isLoading={isLoading}
      onCancel={onCancel}
      onCta={() => onModalCta({currentStep, dispatch})}
    >
      <ModalHeading>{getModalString('title')}</ModalHeading>
      {renderModalContent()}
    </ModalDialog>
  );

  return (
    <>
      <Button onPress={onPress} staticColor="white" variant="primary">
        {intl.formatMessage({
          id: `globalBanner.buttonText.teamToEnterpriseMigration`,
        })}
      </Button>
      {isModalOpen && (
        <ModalContainer>
          <WizardTrigger steps={STEPS}>{renderModalDialog}</WizardTrigger>
        </ModalContainer>
      )}
    </>
  );
};

export default TeamToEnterpriseContractMigrationModal;
