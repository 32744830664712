import {
  SEARCH_TARGET_CLASS,
  SEARCH_TARGET_RESULT_TYPE,
  UserGroup,
  constructAsyncAddAssignUserAnalytics,
} from '@admin-tribe/acsc';
import binkyUI from '@admin-tribe/acsc-ui';
import UserGroupIcon from '@spectrum-icons/workflow/UserGroup';
import cloneDeep from 'lodash/cloneDeep';
import uniqBy from 'lodash/uniqBy';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import AddUserFormTableWrapper from 'common/components/add-user-form-table/AddUserFormTableWrapper';
import {dispatchUserModalSuccessAnalytics} from 'features/users/services/user-modals/userModalAnalyticsUtils';

import AddUsersToUserGroupDescription from './description/AddUsersToUserGroupDescription';

const AddUserFormTable = binkyUI.common.components.addUser.AddUserFormTable;
const MemberAndSelectedItemsList = binkyUI.common.components.addUser.MemberAndSelectedItemsList;
const {ModalContainer, ModalContent, ModalDialog, ModalHeading, ModalTagHeader} =
  binkyUI.common.components.modal;
const UserPicker = binkyUI.common.components.UserPicker;
const {getOrganizationUserErrorMessage} =
  binkyUI.organization.organizationUser.organizationUserDisplayUtils;

const AddUsersToUserGroupModal = observer(({isOpen, onClosed, onSuccess, orgId, userGroupId}) => {
  const intl = useIntl();

  const [modalError, setModalError] = React.useState(null);
  const [isModalOpen, setModalOpen] = React.useState(isOpen);
  const [orgUserList, setOrgUserList] = React.useState();
  const [userGroup, setUserGroup] = React.useState();
  const [isLoading, setIsLoading] = React.useState();
  const memberAndSelectedItemsListRef = React.useRef();

  const showModalError = React.useCallback(() => {
    setModalError(intl.formatMessage({id: 'common.modal.error.generic'}));
  }, [intl]);

  const onChange = (addUserFormTableData) => {
    setModalError(null);
    const newOrgUserList = MemberAndSelectedItemsList.toOrgUserUnsavedList(
      addUserFormTableData,
      orgId,
      (memberAndSelectedItems) => {
        const member = memberAndSelectedItems.member;

        member.userGroups = member.userGroups || [];
        member.userGroups.push({id: userGroupId});
        member.userGroups = uniqBy(member.userGroups, 'id');
        return member;
      }
    );

    setOrgUserList(newOrgUserList);
    memberAndSelectedItemsListRef.current = addUserFormTableData;
  };

  const onConfirm = async () => {
    let isSuccess = false;
    setIsLoading(true);
    try {
      // Need to deep clone this collection as it is mutated by the save()
      const committedItems = cloneDeep(orgUserList.addedItems);
      await orgUserList.save();
      setModalOpen(false);
      isSuccess = true;
      await dispatchUserModalSuccessAnalytics({
        committedItems,
        eventName: 'AddUsersToUserGroupModal',
        orgId,
      });
      onSuccess?.();
    } catch (error) {
      setModalError(getOrganizationUserErrorMessage(intl, error));
    } finally {
      setIsLoading(false);
    }

    return isSuccess;
  };

  const ctaToastGenerator = () =>
    intl.formatMessage(
      {id: 'common.toast.modal.usersAdded'},
      {userCount: orgUserList.items.length}
    );

  React.useEffect(() => {
    let isCurrent = true;
    setIsLoading(true);
    const fetchUserGroup = async () => {
      let fetchedUserGroup;
      try {
        fetchedUserGroup = await UserGroup.get(orgId, userGroupId);
      } catch (error) {
        // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- zakn@ to update
        // istanbul ignore else
        if (isCurrent) {
          showModalError();
        }
      }

      if (isCurrent) {
        setUserGroup(fetchedUserGroup);
        setIsLoading(false);
      }
    };

    fetchUserGroup();

    return () => {
      isCurrent = false;
    };
  }, [orgId, showModalError, userGroupId]);

  return (
    <ModalContainer>
      {isModalOpen && (
        <ModalDialog
          analyticsContextFunc={() => constructAsyncAddAssignUserAnalytics({orgId})}
          cancelLabel={intl.formatMessage({
            id: 'common.addUsersToUserGroupModal.cancelButton',
          })}
          ctaLabel={intl.formatMessage({
            id: 'common.addUsersToUserGroupModal.confirmButton',
          })}
          ctaToastGenerator={ctaToastGenerator}
          errorMessage={modalError}
          heightVariant="static"
          id="add-users-to-user-group-modal"
          isCtaDisabled={
            !orgUserList?.hasUnsavedChanges() || memberAndSelectedItemsListRef.current.isInvalid()
          }
          isLoading={isLoading}
          onCancel={onClosed}
          onCta={onConfirm}
        >
          {userGroup && (
            <ModalTagHeader IconComponent={<UserGroupIcon size="S" />}>
              {userGroup.name}
            </ModalTagHeader>
          )}
          <ModalHeading>
            {intl.formatMessage({id: 'common.addUsersToUserGroupModal.header'})}
          </ModalHeading>
          <AddUsersToUserGroupDescription orgId={orgId} />
          <ModalContent>
            <AddUserFormTableWrapper orgId={orgId}>
              <AddUserFormTable
                data-testid="add-user-form-table-testid"
                onChange={onChange}
                orgId={orgId}
                pickerType={UserPicker.PICKER_TYPE.USERS_ONLY}
                searchType={UserPicker.SEARCH_TYPE.EXISTING_USER}
                targetOptions={{
                  searchTargetType: SEARCH_TARGET_RESULT_TYPE.USER,
                  targetClass: SEARCH_TARGET_CLASS.USER_GROUP,
                  targetId: userGroupId,
                }}
              />
            </AddUserFormTableWrapper>
          </ModalContent>
        </ModalDialog>
      )}
    </ModalContainer>
  );
});

AddUsersToUserGroupModal.propTypes = {
  isOpen: PropTypes.bool,
  onClosed: PropTypes.func,
  /**
   * Optional callback when users have been added to the user group. There is no parameter.
   */
  onSuccess: PropTypes.func,
  orgId: PropTypes.string.isRequired,
  userGroupId: PropTypes.string.isRequired,
};

export default AddUsersToUserGroupModal;
