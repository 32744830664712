import {Workspace} from '@admin-tribe/acsc-ui';
import React from 'react';

import UsersWorkspaceNav from 'features/users/components/users-workspace-nav/UsersWorkspaceNav';

/**
 * The Users workspace displays the UsersWorkspaceNav and
 * any applicable nested children routes specified in usersRoutes.jsx
 */
const UsersWorkspace = () => (
  <Workspace useOutlet>
    <UsersWorkspaceNav />
  </Workspace>
);

export default UsersWorkspace;
