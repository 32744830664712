import {AnalyticsEvent} from '@admin-tribe/acsc';
import {Button} from '@adobe/react-spectrum';
import {CLOUD} from '@pandora/administration-core-types';
import PropTypes from 'prop-types';
import React, {useRef, useState} from 'react';
import {useIntl} from 'react-intl';

import {SUPPORT_CONSTANTS} from '../../support.constants';
import SupportTicketMiniappWrapper from '../support-ticket-miniapp-wrapper/SupportTicketMiniappWrapper';

const {SUPPORT_TICKET_RECORD_TYPE} = SUPPORT_CONSTANTS;

const SupportTicketCreateButton = ({
  cloudType,
  eventName,
  onCreate,
  recordType,
  variant = 'primary',
}) => {
  const intl = useIntl();
  const buttonLabel = intl.formatMessage({
    id:
      recordType === SUPPORT_TICKET_RECORD_TYPE.CASE
        ? 'support.supportCase.buttons.createCase'
        : 'support.expertSession.buttons.requestExpertSession',
  });
  const [showModal, setShowModal] = useState(false);
  const openerButton = useRef(null);

  return (
    <div>
      <Button
        ref={openerButton}
        aria-label={buttonLabel}
        data-testid={`create-${recordType.toLowerCase()}-button`}
        onPress={() => {
          if (eventName) {
            AnalyticsEvent.dispatch({
              eventAction: 'open',
              eventName,
            });
          }
          setShowModal(true);
        }}
        variant={variant}
      >
        {buttonLabel}
      </Button>
      {showModal && (
        <SupportTicketMiniappWrapper
          cloudType={cloudType}
          onCancel={() => {
            openerButton.current.focus();
            setShowModal(false);
          }}
          onCreate={(id, useCalendly) => {
            setShowModal(!!useCalendly);
            // if we're using Calendly to schedule the session, then we don't want to
            // call onCreate yet because the expert session won't have been scheduled yet.
            if (!useCalendly) {
              onCreate?.(id);
            }
          }}
          recordType={recordType}
        />
      )}
    </div>
  );
};

SupportTicketCreateButton.propTypes = {
  /**
   * The cloud we show by default
   */
  cloudType: PropTypes.oneOf([...Object.values(CLOUD), '', 'GENERAL']),
  /**
   * Analytics event name of the Support Ticket Create Button
   */
  eventName: PropTypes.string,
  /**
   * Callback function on creation of the Support Ticket
   */
  onCreate: PropTypes.func,
  /**
   * Record Type of the Support Ticket
   */
  recordType: PropTypes.oneOf([SUPPORT_TICKET_RECORD_TYPE.CASE, SUPPORT_TICKET_RECORD_TYPE.EXPERT])
    .isRequired,
  /**
   * Variant for the button. Default is primary
   */
  variant: PropTypes.oneOf(['accent', 'primary', 'secondary']),
};

export default SupportTicketCreateButton;
