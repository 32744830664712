import {log, modelCache} from '@admin-tribe/acsc';

import adobeioConsoleIntegrations from 'common/api/adobeio-console/adobeioConsoleIntegrations';

const API_ORGANIZATION_LIST_CACHE_ID = 'ApiOrganizationList';
const API_ORGANIZATION_LIST_CACHE_KEY = 'ApiOrganizationListKey';

/**
 * The list of Adobeio orgs.
 * This is used to map the rengaOrgId to the adobeioOrgId.
 */
class ApiOrganizationList {
  static get() {
    const list = new ApiOrganizationList();

    const cachedPromise = modelCache.get(
      API_ORGANIZATION_LIST_CACHE_ID,
      API_ORGANIZATION_LIST_CACHE_KEY
    );
    if (cachedPromise) {
      return cachedPromise;
    }

    // Item is not already cached.
    const promise = list.refresh();
    modelCache.put(API_ORGANIZATION_LIST_CACHE_ID, API_ORGANIZATION_LIST_CACHE_KEY, promise);
    return promise;
  }

  /**
   * @description Constructor for ApiOrganizationList model .
   */
  constructor() {
    this.items = [];
  }

  /**
   * @description Returns the adobe id for the given (Renga) org id.
   *
   * @returns {String|undefined} The adobe id if found, else undefined.
   */
  getAdobeioOrgId({rengaOrgId}) {
    const adobeioOrg = this.items.find((item) => item.code === rengaOrgId);
    return adobeioOrg?.id;
  }

  async refresh() {
    let organizations;
    try {
      organizations = await adobeioConsoleIntegrations.getOrganizations();
    } catch (error) {
      log.error(`ApiOrganizationList.refresh() failed to load organizations. Error: ${error}`);
      return Promise.reject(error);
    }

    this.items = organizations.map((org) => ({code: org.code, id: org.id}));

    return this;
  }
}

export default ApiOrganizationList;
