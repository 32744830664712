import {User} from '@admin-tribe/acsc';
import {useDialogContainer} from '@adobe/react-spectrum';
import {ModalDialog, ModalDialogModel} from '@pandora/react-modal-dialog';
import {WizardDriver, WizardView} from '@pandora/react-wizard';
import PropTypes from 'prop-types';
import React, {useRef, useState} from 'react';
import {useIntl} from 'react-intl';

import UserFolder from 'common/services/user-folder/UserFolder';
import {REMOVE_USER_STORAGE_OPTIONS} from 'common/services/user-folder/userFolderConstants';

import {DeleteUsersModalContext} from './hooks/DeleteUsersModalContext';
import ActionRequiredStep from './steps/ActionRequiredStep';
import ConfimationStep from './steps/ConfirmationStep';
import StorageSelectionStep from './steps/StorageSelectionStep';

const ACTION_REQUIRED_STEP = 'actionRequired';
const CONFIRMATION_STEP = 'confirmation';
const STORAGE_SELECTION_STEP = 'storageSelection';

let wizard;

/**
 * The modal opened while removing user(s) in the 'Org Users' and remove 'Directory users' flows when at least one of the users
 * being removed has associated storage. The admin must choose the disposition of the user's storage.
 * If one user is being removed, then this is a 2-panel modal, and if more than one user is being removed
 * this is a 3-panel modal.
 */
const RemoveUsersWithStorageModal = ({onSuccess, selectedMembers, userStorageList}) => {
  const intl = useIntl();
  const dialog = useDialogContainer();

  const initWizard = useRef({
    stepIndex: 0,
    steps:
      selectedMembers.length > 1
        ? [
            {
              name: ACTION_REQUIRED_STEP,
            },
            {
              name: STORAGE_SELECTION_STEP,
            },
            {
              name: CONFIRMATION_STEP,
            },
          ]
        : [
            {
              name: STORAGE_SELECTION_STEP,
            },
            {
              name: CONFIRMATION_STEP,
            },
          ],
  });

  const [currentStep, setCurrentStep] = useState(initWizard.current.steps[0].name);
  const [designatedUser, setDesignatedUser] = useState({});
  const [storageOption, setStorageOption] = useState(REMOVE_USER_STORAGE_OPTIONS.SHARE);

  const getCTALabel = (step) => {
    switch (step) {
      case ACTION_REQUIRED_STEP:
        return intl.formatMessage({id: 'common.modal.buttons.continue'});
      case CONFIRMATION_STEP:
        return intl.formatMessage(
          {id: 'common.removeUsersWithStorageModal.cta'},
          {
            count: selectedMembers.length,
          }
        );
      default:
        return intl.formatMessage({id: 'common.modal.buttons.next'}); // is next like in the storage selection step
    }
  };

  const content = ModalDialogModel.createEntry({
    cancelLabel: intl.formatMessage({id: 'common.modal.buttons.cancel'}),
    ctaLabel: getCTALabel(currentStep),
    secondaryLabel:
      currentStep === initWizard.current.steps[0].name
        ? undefined // hides the "Previous" button when at the first step
        : intl.formatMessage({id: 'common.modal.buttons.previous'}),
  });

  const onCancelModal = () => {
    dialog.dismiss();
  };

  const onCta = () => {
    if (currentStep === CONFIRMATION_STEP) {
      onSuccess({
        designatedUserId:
          storageOption === REMOVE_USER_STORAGE_OPTIONS.SHARE ? designatedUser.id : undefined,
        storageOption,
      });
      dialog.dismiss();
    } else {
      wizard.goToNextStep();
    }
  };

  const onSecondary = () => {
    wizard.goToPreviousStep();
  };

  const onSelectionChange = (selection) => {
    // for some reason the spectrum radio buttons call back the contents within the UserPicker rather than
    // a selection and somehow makes it such that no selection is selected, simply default to SHARE when this occurs
    // as if the email is not valid it will block the cta, forcing the user to re-select
    // NOTE: This logic only works when REMOVE_USER_STORAGE_OPTIONS.SHARE Radio option is the only element with
    // a non-Radio focusable element (like the UserPicker) within the RadioGroup element. This is also not accessible.
    // If this ever gets ported, this issue should be re-designed.
    if (Object.values(REMOVE_USER_STORAGE_OPTIONS).includes(selection)) {
      setStorageOption(selection);
    } else {
      setStorageOption(REMOVE_USER_STORAGE_OPTIONS.SHARE);
    }
  };

  const onStepChange = (stepChangedParams) => {
    setCurrentStep(stepChangedParams.currentStepName);
  };

  return (
    <ModalDialog
      content={content}
      ctaVariant={currentStep === CONFIRMATION_STEP ? 'negative' : 'primary'}
      heightVariant="static"
      isCtaDisabled={
        currentStep === STORAGE_SELECTION_STEP &&
        storageOption === REMOVE_USER_STORAGE_OPTIONS.SHARE &&
        !designatedUser.id
      }
      onCancel={onCancelModal}
      onCta={onCta}
      onSecondary={onSecondary}
    >
      <DeleteUsersModalContext selectedMembers={selectedMembers} userStorageList={userStorageList}>
        <WizardView initConfig={initWizard.current} onStepChange={onStepChange}>
          {currentStep === ACTION_REQUIRED_STEP && <ActionRequiredStep />}
          {currentStep === STORAGE_SELECTION_STEP && (
            <StorageSelectionStep
              defaultUser={designatedUser}
              onSelectionChange={onSelectionChange}
              onTransferStorageUser={setDesignatedUser}
              selectedOption={storageOption}
            />
          )}
          {currentStep === CONFIRMATION_STEP && <ConfimationStep storageOption={storageOption} />}
          <WizardDriver
            onInit={(inputWizard) => {
              wizard = inputWizard;
            }}
          />
        </WizardView>
      </DeleteUsersModalContext>
    </ModalDialog>
  );
};

RemoveUsersWithStorageModal.propTypes = {
  /**
   * Function called when the modal a storage option has been selected and the modal should be closed.
   * There are 2 parameters:
   *   designatedUserId: if storageOptions is REMOVE_USER_STORAGE_OPTIONS.SHARE this is the id string
   *      of the user that is designated to receive the storage otherwise undefined
   *   storageOption: one of REMOVE_USER_STORAGE_OPTIONS: SHARE, DISCARD, or DELETE
   */
  onSuccess: PropTypes.func.isRequired,
  /**
   * The members selected for deletion.
   */
  selectedMembers: PropTypes.arrayOf(PropTypes.instanceOf(User)).isRequired,
  /**
   * A UserFolderList with the users that have associated storage.
   * The UserFolder's name corresponds to the Member's id.
   */
  userStorageList: PropTypes.arrayOf(PropTypes.instanceOf(UserFolder)).isRequired,
};

export default RemoveUsersWithStorageModal;
