import {Product, feature} from '@admin-tribe/acsc';
import {Content, Header, Heading} from '@adobe/react-spectrum';
import {Drawer} from '@pandora/react-drawer';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import NoAccessDrawerTypeContent from './NoAccessDrawerTypeContent';
import {NO_ACCESS_DRAWER_ID, NO_ACCESS_TYPE} from './noAccessDrawerConstants';

/**
 * @description Drawer that lists delegated users who don't have access to a product.
 *    This includes users who were not provisioned and those with invalid id types.
 *    This drawer is opened from the noAccess Scorecard.
 */
const NoAccessDrawer = ({
  invalidIdTypeCount,
  invalidIdTypeQuery,
  overDelegatedUserCount,
  overDelegatedUserQuery,
  product,
}) => {
  const intl = useIntl();

  const titleId = feature.isEnabled('temp_user_provisioning_status')
    ? 'products.details.drawers.noAccess.title.default'
    : 'products.details.drawers.noAccess.title';

  return (
    <Drawer aria-labelledby={NO_ACCESS_DRAWER_ID}>
      <Header marginBottom="size-250" marginX="size-50">
        <Heading id={NO_ACCESS_DRAWER_ID} level={2} marginBottom="size-125" marginTop="size-0">
          {intl.formatMessage({id: titleId})}
        </Heading>
      </Header>
      <Content marginX="size-50">
        {overDelegatedUserCount > 0 && (
          <NoAccessDrawerTypeContent
            noAccessType={NO_ACCESS_TYPE.OVERDELEGATED}
            product={product}
            userQuery={overDelegatedUserQuery}
          />
        )}
        {invalidIdTypeCount > 0 && (
          <NoAccessDrawerTypeContent
            noAccessType={NO_ACCESS_TYPE.INCOMPATIBLE_ID_TYPE}
            product={product}
            userQuery={invalidIdTypeQuery}
          />
        )}
      </Content>
    </Drawer>
  );
};

NoAccessDrawer.propTypes = {
  /* Number of users with an invalid ID type */
  invalidIdTypeCount: PropTypes.number.isRequired,
  /* Query function used to get users with invalid ID types */
  invalidIdTypeQuery: PropTypes.func.isRequired,
  /* Number of users who were delegated but not provisioned */
  overDelegatedUserCount: PropTypes.number.isRequired,
  /* Query function used to get users who were delegated but not provisioned */
  overDelegatedUserQuery: PropTypes.func.isRequired,
  /* Product that these users don't have access to */
  product: PropTypes.instanceOf(Product).isRequired,
};

export default NoAccessDrawer;
