import {DATE_FORMATS, FormattedDateAndTime, StatusLight} from '@admin-tribe/acsc-ui';
import {
  Cell,
  Column,
  Flex,
  Link,
  Row,
  TableBody,
  TableHeader,
  Text,
  View,
} from '@adobe/react-spectrum';
import {EN_DASH, Table, useTableSectionContext} from '@pandora/react-table-section';
import {observer} from 'mobx-react-lite';
import React, {useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import PackageSummaryDrawer from 'features/packages/components/package-summary-drawer/PackageSummaryDrawer';
import {
  ALL_PACKAGES_CONSTANTS,
  CREATE_PACKAGE_CONSTANTS,
  PACKAGE_TYPE_CONSTANTS,
} from 'features/packages/packagesConstants';
import {useAllPackagesPageContext} from 'features/packages/pages/all-packages/AllPackagesPageContext';

import PackageStatusState from './package-status-state/PackageStatusState';

/**
 * Function getPackageDownloadState returns appropriate actionalable state
 */
const getPackageDownloadState = (item, intl) => {
  switch (item.downloadState) {
    case ALL_PACKAGES_CONSTANTS.ACTION_EXPIRED:
      return (
        <Flex alignItems="center" direction="row" UNSAFE_style={{fontStyle: 'italic'}}>
          <StatusLight alignStart variant="notice">
            <FormattedMessage id="packages.allPackages.table.downloadState.expired" />
          </StatusLight>
        </Flex>
      );
    case ALL_PACKAGES_CONSTANTS.ACTION_DOWNLOAD:
      return (
        <Link onPress={() => item.downloadPackage()}>
          {intl.formatMessage({
            id: `packages.allPackages.table.downloadState.download`,
          })}
        </Link>
      );
    case ALL_PACKAGES_CONSTANTS.ACTION_RETRY:
      return (
        <View alignItems="center" direction="row" UNSAFE_style={{fontStyle: 'italic'}}>
          <FormattedMessage id="packages.allPackages.table.downloadState.retry" />
        </View>
      );
    case ALL_PACKAGES_CONSTANTS.ACTION_CORRUPTED:
      return (
        <View alignItems="center" direction="row" UNSAFE_style={{fontStyle: 'italic'}}>
          <FormattedMessage id="packages.allPackages.table.downloadState.corrupted" />
        </View>
      );
    default:
      return null;
  }
};

/**
 * Function getPackageType returns type of package in the list
 */
// eslint-disable-next-line complexity -- package type checks
const getPackageType = (packageType) => {
  switch (packageType) {
    case PACKAGE_TYPE_CONSTANTS.FRL_HIGH_PRIVACY_ONLINE:
      return <FormattedMessage id="packages.allPackages.packageType.frlHighprivacyOnline" />;
    case PACKAGE_TYPE_CONSTANTS.FRL_ISOLATED:
      return <FormattedMessage id="packages.allPackages.packageType.frlIsolated" />;
    case PACKAGE_TYPE_CONSTANTS.FRL_OFFLINE:
      return <FormattedMessage id="packages.allPackages.packageType.frlOffline" />;
    case PACKAGE_TYPE_CONSTANTS.MANAGED:
    case PACKAGE_TYPE_CONSTANTS.PLUGIN_AND_SW_CONFIG:
    case PACKAGE_TYPE_CONSTANTS.PLUGIN_ONLY:
    case PACKAGE_TYPE_CONSTANTS.SW_CONFIG_ONLY:
      return <FormattedMessage id="packages.allPackages.packageType.managed" />;
    case PACKAGE_TYPE_CONSTANTS.SELF_SERVICE:
      return <FormattedMessage id="packages.allPackages.packageType.selfService" />;
    case PACKAGE_TYPE_CONSTANTS.NUELL:
      return <FormattedMessage id="packages.allPackages.packageType.nuell" />;
    case PACKAGE_TYPE_CONSTANTS.FRL_LAN:
      return <FormattedMessage id="packages.allPackages.packageType.frlLan" />;
    default:
      return null;
  }
};

/**
 * Packages Listing Table React Component - contains list of admin packages
 */
const PackageListTable = observer(() => {
  const {allPackagesStore} = useAllPackagesPageContext();
  const {tableSectionUtils, tableSectionState} = useTableSectionContext();
  const intl = useIntl();

  // clearSelection / clearSearch flags getting updated by store incase
  // of and selected rows are there in the table which required to update TableSection.
  useEffect(() => {
    if (allPackagesStore.clearSelection) {
      tableSectionUtils.clearSelectedItemKeys();
      allPackagesStore.resetClearSelection();
    }
    if (allPackagesStore.clearSearch) {
      tableSectionUtils.deleteSearchFilter();
      allPackagesStore.resetClearSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- need to avoid multiple rerender
  }, [allPackagesStore.clearSelection, allPackagesStore.clearSearch]);

  /**
   * Function to get platform string
   */
  const getPlatform = (item) =>
    intl.formatMessage(
      {
        id: `packages.allPackages.platform.${item.platform}${
          item.platform === CREATE_PACKAGE_CONSTANTS.WIN_PLATFORM ? item.bits : ''
        }`,
      },
      {
        nowrap: (string) => <Text UNSAFE_style={{whiteSpace: 'nowrap'}}>{string}</Text>,
      }
    );

  /**
   * Function to language string for package
   */
  const getLanguage = (item) =>
    intl.formatMessage({
      id: `packages.locales.${item.language}`,
    });

  return (
    <Table
      aria-label={intl.formatMessage({id: 'packages.allPackages.title'})}
      noItemsFoundHeadingMessage={intl.formatMessage({
        id: 'packages.allPackages.table.empty.noPackages.heading',
      })}
      noSearchResultsContentMessage={intl.formatMessage({
        id: 'packages.allPackages.table.empty.noSearchResults.content',
      })}
      noSearchResultsHeadingMessage={intl.formatMessage({
        id: 'packages.allPackages.table.empty.noSearchResults.heading',
      })}
      serverErrorContentMessage={intl.formatMessage({
        id: 'packages.allPackages.table.empty.networkError.content',
      })}
      serverErrorHeadingMessage={intl.formatMessage({
        id: 'packages.allPackages.table.empty.networkError.heading',
      })}
      UNSAFE_style={{overflowWrap: 'anywhere'}}
    >
      <TableHeader>
        <Column key="name" align="start" width="16%">
          <FormattedMessage id="packages.allPackages.table.headerLabels.packageName" />
        </Column>
        <Column
          key="summaryButton"
          hideHeader
          name={intl.formatMessage({id: 'packages.allPackages.table.icon.viewDetails'})}
          showDivider
        >
          <FormattedMessage id="packages.allPackages.table.headerLabels.actions" />
        </Column>
        <Column key="platform" align="start">
          <FormattedMessage id="packages.allPackages.table.headerLabels.platform" />
        </Column>
        <Column key="applications" align="end" width="6%">
          <FormattedMessage id="packages.allPackages.table.headerLabels.applications" />
        </Column>
        <Column key="language" align="start">
          <FormattedMessage id="packages.allPackages.table.headerLabels.language" />
        </Column>
        <Column key="packageType" align="start">
          <FormattedMessage id="packages.allPackages.table.headerLabels.packageType" />
        </Column>
        <Column key="createdOn" align="start">
          <FormattedMessage id="packages.allPackages.table.headerLabels.createdOn" />
        </Column>
        <Column key="statusState" align="start" width="16%">
          <Text>
            <FormattedMessage id="packages.allPackages.table.headerLabels.status" />
          </Text>
        </Column>
        <Column key="downloadState" align="start">
          <FormattedMessage id="packages.allPackages.table.downloadHeading" />
        </Column>
      </TableHeader>
      <TableBody items={tableSectionState.items}>
        {/* eslint-disable-next-line @admin-tribe/admin-tribe/extract-large-computations -- Not a large computation */}
        {(item) => (
          <Row key={item.packageId}>
            <Cell>{item.name}</Cell>
            <Cell>
              <PackageSummaryDrawer allPackagesStore={allPackagesStore} summaryObj={item} />
            </Cell>
            <Cell>{getPlatform(item)}</Cell>
            <Cell>{item.packagedAppsCount}</Cell>
            <Cell>{getLanguage(item)}</Cell>
            <Cell data-testid="package-type">{getPackageType(item.packageType) || EN_DASH}</Cell>
            <Cell>
              <FormattedDateAndTime format={DATE_FORMATS.default} value={item.createdOn} />
            </Cell>
            <Cell>
              <PackageStatusState adminPackage={item} />
            </Cell>
            <Cell>{getPackageDownloadState(item, intl) || EN_DASH}</Cell>
          </Row>
        )}
      </TableBody>
    </Table>
  );
});
PackageListTable.displayName = 'PackageListTable';
export default PackageListTable;
