import {Locale, configStore, dispatchUiEventAnalytics, log} from '@admin-tribe/acsc';
import {useIframeMessage} from '@pandora/react-app-context-iframe-message-handler';
import {MessageType, SupportedSystemMessageTypes} from '@pandora/react-app-context-provider';
import {ModalContainer} from '@pandora/react-modal-dialog';
import PropTypes from 'prop-types';
import qs from 'qs';
import React from 'react';

import rootStore from 'core/RootStore';

import {
  CHANGE_CONTRACT_OWNER_MINI_APP_NAME,
  CHANGE_CONTRACT_OWNER_MINI_APP_URL_ENV_PARAM,
} from './ChangeContractOwnerModal.constants';
import styles from './ChangeContractOwnerModal.pcss';

/**
 * @description Represents a wrapper for Change Contract Owner Mini App
 */
const ChangeContractOwnerModal = ({contractId, onCancel, onSubmit}) => {
  const {organizationStore} = rootStore;

  // Prepare for the Locale information, AEM takes locale in BCP-47 format
  const localeLanguage = Locale.get().activeLanguageBCP47Code;

  const {url} = configStore.get(CHANGE_CONTRACT_OWNER_MINI_APP_URL_ENV_PARAM);
  const orgId = organizationStore.activeOrgId;

  // App Context Identifier as Mini App is loaded in iframe
  const iFrameCtx = 'if';

  // Mini App Parameters https://git.corp.adobe.com/PandoraUI/commerce-mini-apps/tree/master/packages/react-mini-app-change-contract-owner#mini-app-params
  const params = {
    contract: contractId,
    ctx: iFrameCtx,
    lang: localeLanguage,
    org: orgId,
  };

  const miniAppUrl = `${url}?${qs.stringify(params)}`;

  // Message handler to process the events from with subType as CLOSE
  const handleCloseMessages = (data) => {
    // eslint-disable-next-line default-case -- no need to handle default case
    switch (data?.action) {
      case 'invited':
        dispatchUiEventAnalytics({
          eventAction: 'submit',
          eventName: 'changeContractOwner',
        });
        onSubmit();
        break;

      case 'cancelled':
        onCancel();
        break;
    }
  };

  // Message handler to process just the System events from Change Contract Owner Mini App
  const systemMessageHandler = ({data, subType}) => {
    // eslint-disable-next-line default-case -- no need to handle default case
    switch (subType) {
      case SupportedSystemMessageTypes.APP_LOADED:
        dispatchUiEventAnalytics({
          eventAction: 'open',
          eventName: 'changeContractOwnerModal',
        });
        break;
      case SupportedSystemMessageTypes.CLOSE:
        handleCloseMessages(data);
        break;
    }
  };

  const messageHandler = ({app, type, subType, data}) => {
    // Ignore the message if it is not from Change Contract Owner Mini App
    if (app !== CHANGE_CONTRACT_OWNER_MINI_APP_NAME) return;

    log.info('Message from Change Contract Owner Mini App:', {data, subType, type});

    if (type === MessageType.SYSTEM) {
      systemMessageHandler({data, subType});
    } else if (type === MessageType.OPEN_URL) {
      // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- opens link
      window.open(data.externalUrl, data.target);
    }
  };

  // Consume message from Change Contract Owner Mini App iframe
  useIframeMessage(messageHandler);

  return (
    <ModalContainer>
      <section
        className={styles['change-contract-owner-dialog']}
        data-testid="change-contract-owner"
      >
        <div>
          <iframe
            src={miniAppUrl}
            styleName="change-contract-owner-iframe"
            title="Change Contract Owner"
          />
        </div>
      </section>
    </ModalContainer>
  );
};

ChangeContractOwnerModal.propTypes = {
  /**
   * Contract ID
   */
  contractId: PropTypes.string,
  /**
   * Callback to invoke when the modal's cancel button is pressed.
   */
  onCancel: PropTypes.func.isRequired,
  /**
   * Callback to invoke when the modal's action is submitted.
   */
  onSubmit: PropTypes.func.isRequired,
};

export default ChangeContractOwnerModal;
