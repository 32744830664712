import UserAdminIcon from '@spectrum-icons/workflow/UserAdmin';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {useSearchParams} from 'react-router-dom';

import rootStore from 'core/RootStore';
import {
  canAssignAdmin,
  getManageableAdminRoles,
  hasManageAdminsPolicy,
} from 'core/admin/access/adminAccess';
import {isReadOnly} from 'core/organizations/access/organizationAccess';
import AddAdminModal from 'features/users/components/add-admin-modal/AddAdminModal';

import QuickLink from '../QuickLink';

const AddAdminsQuickLink = ({isDisabled, roles}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const isOpenAdminsQLinkModal = searchParams?.get('quick-assign-admins') || false;
  const [isModalOpen, setIsModalOpen] = useState(isOpenAdminsQLinkModal);
  const intl = useIntl();

  return (
    <>
      <QuickLink
        analyticsOptions={{
          analyticsAction: 'add-admins',
        }}
        data-testid="quick-link-add-admins"
        Icon={<UserAdminIcon size="M" />}
        isDisabled={isDisabled}
        label={intl.formatMessage({id: 'overview.quickLinks.addAdmins.title'})}
        onPress={() => setIsModalOpen(true)}
      />
      <AddAdminModal
        isOpen={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
          setSearchParams({});
        }}
        onSuccess={() => {
          setIsModalOpen(false);
          setSearchParams({});
        }}
        orgId={rootStore.organizationStore.activeOrgId}
        roles={roles}
      />
    </>
  );
};

AddAdminsQuickLink.propTypes = {
  /**
   * Whether the link is disabled. Defaults to false
   */
  isDisabled: PropTypes.bool,
  /**
   * Roles to pass in to the AddAdminModal
   */
  roles: PropTypes.arrayOf(PropTypes.string),
};

AddAdminsQuickLink.get = async () => {
  if (!isReadOnly() && canAssignAdmin({checkPolicies: false})) {
    const isDisabled = !hasManageAdminsPolicy();

    let roles = [];

    if (!isDisabled) {
      roles = await getManageableAdminRoles();

      if (roles.length === 0) {
        throw new Error('User cannot manage any admin roles');
      }
    }

    return <AddAdminsQuickLink key="AddAdminsQuickLink" isDisabled={isDisabled} roles={roles} />;
  }

  throw new Error('User cannot assign admin');
};

export default AddAdminsQuickLink;
