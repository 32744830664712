import {OverlayWait} from '@admin-tribe/acsc-ui';
import {
  Button,
  ButtonGroup,
  Divider,
  Flex,
  Provider,
  useDialogContainer,
} from '@adobe/react-spectrum';
import {ModalContent, ModalDialog, ModalHeading} from '@pandora/react-modal-dialog';
import Alert from '@react/react-spectrum/Alert';
import Provider2 from '@react/react-spectrum/Provider';
import {useId} from '@react-aria/utils';
import PropTypes from 'prop-types';
import React, {useMemo, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import rootStore from 'core/RootStore';
import {SYNC_SOURCES, createLink} from 'features/settings/api/directory-sync';
import {SYNC_PROVIDERS} from 'features/settings/api/eduRosterSync';
import AzureScimConfigurationSteps from 'features/settings/components/azure-scim-configuration-steps/AzureScimConfigurationSteps';
import EduSyncConfigurationSteps from 'features/settings/components/edu-sync-configuration-steps/EduSyncConfigurationSteps';
import GoogleScimConfigurationSteps from 'features/settings/components/google-scim-configuration-steps/GoogleScimConfigurationSteps';
import SyncSourceCards from 'features/settings/components/sync-users-modal/SyncSourceCards';
import ExternalAuthService from 'features/settings/services/ExternalAuthService';
import DirectoryStore from 'features/settings/stores/DirectoryStore';

import './SyncUsersModal.pcss';

/* eslint-disable sort-keys -- keep step order for reference */
const STEPS = {
  SELECT_SOURCE: Symbol('SELECT_SOURCE'),
  CONFIG_SCIM: Symbol('CONFIG_SCIM'),
  SELECT_EDU_PROVIDER: Symbol('SELECT_EDU_PROVIDER'),
};
/* eslint-enable sort-keys -- keep step order for reference */

// eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- neculaes@ to update
// istanbul ignore next
const SyncUsersModal = ({
  directoryStore,
  authSrcCode,
  clearCaches,
  getSyncDetails,
  onSyncAdded,
}) => {
  const [selectedCard, setSelectedCard] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(STEPS.SELECT_SOURCE);
  const [isConfigScimValid, setIsConfigScimValid] = useState(false);
  const [modalTitle, setModalTitle] = useState('settings.sync.syncUsersModal.syncTitle');
  const [hasErrorCreatingLink, setHasErrorCreatingLink] = useState(false);
  const [selectedEduProvider, setSelectedEduProvider] = useState();

  const modalHeadingId = useId();
  const dialog = useDialogContainer();

  const intl = useIntl();

  const ctaLabel = useMemo(() => {
    if (step === STEPS.SELECT_SOURCE) {
      return 'common.modal.buttons.next';
    }

    if (step === STEPS.SELECT_EDU_PROVIDER && selectedEduProvider === SYNC_PROVIDERS.CLEVER) {
      return 'settings.sync.syncUsersModal.buttons.loginWithClever';
    }

    if (step === STEPS.SELECT_EDU_PROVIDER && selectedEduProvider === SYNC_PROVIDERS.CLASSLINK) {
      return 'settings.sync.syncUsersModal.buttons.loginWithClasslink';
    }

    return 'common.modal.buttons.done';
  }, [step, selectedEduProvider]);

  const handleSyncUsersModalClose = () => {
    dialog.dismiss();
    getSyncDetails?.();
  };

  const onCardSelected = (cardId) => {
    setSelectedCard(cardId);
  };

  const onPreviousClick = () => {
    if (step === STEPS.SELECT_EDU_PROVIDER) {
      setStep(STEPS.SELECT_SOURCE);
      setModalTitle('settings.sync.syncUsersModal.syncTitle');
    }
  };

  const isPreviousEnabled = useMemo(
    () => step === STEPS.SELECT_EDU_PROVIDER && selectedCard === SYNC_SOURCES.EDU,
    [selectedCard, step]
  );

  const isCtaEnabled = useMemo(() => {
    if (step === STEPS.SELECT_SOURCE) {
      return !!selectedCard;
    }

    if (step === STEPS.SELECT_EDU_PROVIDER) {
      return !!selectedEduProvider;
    }

    if (step === STEPS.CONFIG_SCIM) {
      return isConfigScimValid;
    }

    return false;
  }, [step, selectedCard, selectedEduProvider, isConfigScimValid]);

  const onNextClick = async () => {
    // TBD - all this logic should probably be in the store
    if (step === STEPS.SELECT_SOURCE) {
      try {
        setIsLoading(true);

        if (selectedCard === SYNC_SOURCES.EDU) {
          setModalTitle('settings.sync.syncUsersModal.syncFromEduTitle');
          setStep(STEPS.SELECT_EDU_PROVIDER);
          return;
        }

        // TBD
        await createLink(authSrcCode, selectedCard, rootStore.organizationStore.activeOrgId);

        // Clear the users cache when a link is created.
        // This is done to make sure users that already exist or
        // the new users that were synced are locked in.
        clearCaches();

        // update title to reflect the selected option
        if (selectedCard === SYNC_SOURCES.AZURE) {
          setModalTitle('settings.sync.syncUsersModal.syncFromAzureTitle');
        }

        if (selectedCard === SYNC_SOURCES.GOOGLE) {
          setModalTitle('settings.sync.syncUsersModal.syncFromGoogleTitle');
        }

        // Go to config steps and disable the CTA button
        setStep(STEPS.CONFIG_SCIM);
        setHasErrorCreatingLink(false);

        onSyncAdded?.();
      } catch (error) {
        setHasErrorCreatingLink(true);
      } finally {
        setIsLoading(false);
      }
    }

    if (step === STEPS.CONFIG_SCIM) {
      dialog.dismiss();
    }

    if (step === STEPS.SELECT_EDU_PROVIDER) {
      // Show a spinner while browser redirects to login page
      setIsLoading(true);

      if (selectedEduProvider === SYNC_PROVIDERS.CLEVER) {
        ExternalAuthService.startCleverAuthentication();
        return;
      }

      if (selectedEduProvider === SYNC_PROVIDERS.CLASSLINK) {
        ExternalAuthService.startClassLinkAuthentication();
      }
    }
  };

  const onValidate = (valid) => {
    setIsConfigScimValid(valid);
  };

  return (
    <ModalDialog height="100vh" id="sync-users-modal">
      <ModalHeading id={modalHeadingId}>
        <FormattedMessage id={modalTitle} />
      </ModalHeading>
      <Divider />
      <ModalContent data-testid="modal-content">
        {/* This is needed for the v2 components to render properly */}
        <Provider2>
          <Provider>
            {hasErrorCreatingLink && (
              <Alert
                header={intl.formatMessage({
                  id: 'settings.sync.error',
                })}
                style={{width: '100%'}}
                variant="error"
              />
            )}

            {step === STEPS.SELECT_SOURCE && (
              <OverlayWait isLoading={isLoading} showContent size="M">
                <fieldset styleName="select-sync-fieldset">
                  <legend>
                    <FormattedMessage id="settings.sync.syncUsersModal.description" />
                  </legend>
                  <SyncSourceCards onCardSelected={onCardSelected} />
                </fieldset>
              </OverlayWait>
            )}

            {step === STEPS.CONFIG_SCIM && selectedCard === SYNC_SOURCES.AZURE && (
              <AzureScimConfigurationSteps
                authSourceId={authSrcCode}
                onValidate={onValidate}
                validateSteps
              />
            )}

            {step === STEPS.CONFIG_SCIM && selectedCard === SYNC_SOURCES.GOOGLE && (
              <GoogleScimConfigurationSteps
                directoryStore={directoryStore}
                onValidate={onValidate}
                validateSteps
              />
            )}

            {step === STEPS.SELECT_EDU_PROVIDER && (
              <OverlayWait isLoading={isLoading} showContent size="M">
                <FormattedMessage id="settings.sync.syncUsersModal.description" />
                <EduSyncConfigurationSteps
                  defaultSelectedProvider={selectedEduProvider}
                  onProviderSelected={setSelectedEduProvider}
                />
              </OverlayWait>
            )}
          </Provider>
        </Provider2>
      </ModalContent>
      <ButtonGroup>
        <Flex flex={1}>
          <Button
            data-test-id="previous-btn"
            isDisabled={isLoading || !isPreviousEnabled}
            onPress={onPreviousClick}
            variant="primary"
          >
            <FormattedMessage id="common.modal.buttons.previous" />
          </Button>
        </Flex>

        <Flex flex={1} justifyContent="end">
          <Button
            data-testid="close-btn"
            isDisabled={isLoading}
            onPress={handleSyncUsersModalClose}
            variant="primary"
          >
            <FormattedMessage id="common.modal.buttons.cancel" />
          </Button>
          <Button
            data-testid="cta-btn"
            isDisabled={!isCtaEnabled || isLoading}
            onPress={onNextClick}
            variant="accent"
          >
            {intl.formatMessage({id: ctaLabel})}
          </Button>
        </Flex>
      </ButtonGroup>
    </ModalDialog>
  );
};

SyncUsersModal.propTypes = {
  authSrcCode: PropTypes.string,
  clearCaches: PropTypes.func,
  directoryStore: PropTypes.instanceOf(DirectoryStore),
  getSyncDetails: PropTypes.func,
  onSyncAdded: PropTypes.func,
};

export default SyncUsersModal;
export {STEPS};
