/* eslint-disable @admin-tribe/admin-tribe/check-browser-globals -- this service is used to authenticate to external users so no SSR usage for it */
import {
  getSessionStorageItem,
  removeSessionStorageItem,
  setSessionStorageItem,
} from '@admin-tribe/acsc';

import AppConstants from 'common/services/AppConstants';
import rootStore from 'core/RootStore';
import {SYNC_PROVIDERS} from 'features/settings/api/eduRosterSync';
import {FEDERATED_TYPES} from 'features/settings/api/ims-federated';

const AZURE_SCOPES = {
  EMAIL: 'email',
  OPENID: 'openid',
  PROFILE: 'profile',
  USER_READ: 'User.Read',
};

const GOOGLE_SCOPES = {
  DOMAIN_READONLY: 'https://www.googleapis.com/auth/admin.directory.domain.readonly',
  EMAIL: 'email',
  OPENID: 'openid',
  PROFILE: 'profile',
};

const STATE_DATA_KEY = 'onesieIdpStateData';

// Session storage key name that holds the return url (url when startAuthentication is called)
const RETURN_URL_KEY = 'onesieIdpReturnUrl';

// Session storage key name that holds CSRF token used for validating a redirect
const CSRF_TOKEN_KEY = 'onesieIdpCsrfToken';

// Url of the application
const CONSOLE_URL = `${window.location.protocol}//${window.location.hostname}${
  // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- no way to test this
  // istanbul ignore next
  window.location.port ? `:${window.location.port}` : ''
}`;

/**
 * A service that helps with authenticating users
 * in external services and retrieving the token
 * from the response.
 */
const ExternalAuthService = {
  /**
   * Builds a query string from a key:value pair
   */
  buildAuthenticationQueryString(queryParamsObj) {
    const params = new URLSearchParams(queryParamsObj);
    return params.toString();
  },

  /**
   * Builds the full authentication URL with the provided parameters.
   */
  buildAuthUrl(url, queryParams) {
    const glue = url.includes('?') ? '&' : '?';
    return `${url}${glue}${queryParams}`;
  },

  /**
   * Builds the return url provided to the authenticating service.
   */
  buildReturnUrl() {
    return window.location.pathname;
  },

  /**
   * Completes an Idp authentication initiated with `startAuthentication`.
   * This method needs to be called or the data stored in the session storage
   * as well as hash parameters will remain intact and eventually be lost.
   *
   * If the token needs to be persisted `storeAccessToken` can be used otherwise
   * the token will be returned when this method is called and cleared from everywhere.
   *
   * @returns {Object} An object containing the token, tokenData and the state data set
   *                   when the authentication is initiated.
   */
  completeAuthentication() {
    const token = this.getAccessTokenFromHash();

    const authData = {
      data: this.getStateData(),
      hashData: this.getUrlHashAsObj(),
      queryData: this.getQueryStringAsURLSearchParams(),
      token,
      // only get the token if there is a token available
      tokenData: token && this.getAccessTokenData(token),
    };

    removeSessionStorageItem(CSRF_TOKEN_KEY);
    removeSessionStorageItem(STATE_DATA_KEY);
    removeSessionStorageItem(RETURN_URL_KEY);

    window.hashFromPageLoad = '';

    return authData;
  },

  /**
   * Generates a CSRF token that is used as state between redirects
   */
  generateCsrfToken() {
    // Math.random() does not provide cryptographically secure random numbers
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/random
    return (
      window.crypto.getRandomValues(new Uint32Array(1))[0] + rootStore.organizationStore.activeOrgId
    );
  },

  /**
   * Reads a JWT token by decoding the payload and parsing it using JSON.parse.
   *
   * If the token is not understood an empty object is returned
   */
  getAccessTokenData(token) {
    // store the index of the payload
    const payload = 1;
    const tokenParts = token.split('.');

    if (tokenParts[payload]) {
      try {
        const payloadJson = window.atob(tokenParts[payload]);
        return JSON.parse(payloadJson);
      } catch (error) {
        return {};
      }
    }

    return {};
  },

  /**
   * Get the value of the access token from the hash
   */
  getAccessTokenFromHash() {
    const hashObj = this.getUrlHashAsObj();
    return hashObj && hashObj.access_token;
  },

  /**
   * Gets the azure clientId based on the environment
   *
   * @returns {*|string}
   */
  getAzureClientId() {
    return window.location.hostname.includes('localhost')
      ? AppConstants.configuration.services.directorySync.azureClientIdLocal
      : AppConstants.configuration.services.directorySync.azureClientId;
  },

  /**
   * Returns the query string as a URLSearchParams instance.
   * @returns {module:url.URLSearchParams}
   */
  getQueryStringAsURLSearchParams() {
    return new URLSearchParams(window.location.search);
  },

  /**
   * Gets the saved return url from session storage
   */
  getReturnUrl() {
    return getSessionStorageItem(RETURN_URL_KEY);
  },

  /**
   * Gets the stored state data from session storage
   */
  getStateData() {
    try {
      return JSON.parse(getSessionStorageItem(STATE_DATA_KEY));
    } catch {
      return null;
    }
  },

  /**
   * Transforms the hash into a key value pair object
   */
  getUrlHashAsObj() {
    // slice(1) removes '#' from the beginning of the string.
    // This is needed because the value of window.hashFromPageLoad is set from location.hash that returns it with a '#' prefix.
    // see https://developer.mozilla.org/en-US/docs/Web/API/Location/hash and index.html for hashFromPageLoad value
    return window.hashFromPageLoad
      .slice(1)
      .split('&')
      .reduce((accumulator, pair) => {
        const [key, value] = pair.split('=');
        accumulator[key] = decodeURIComponent(value);
        return accumulator;
      }, {});
  },

  init(externalAuthServiceConnector, configuration) {
    this.connector = externalAuthServiceConnector;
    this.configuration = configuration;
  },

  /**
   * Checks if we are returning from a login.
   * The check is based on the `state` param matching
   * what we have stored in session storage as the
   * CSRF token.
   *
   * @returns {boolean} True if state matches CSRF token, false otherwise.
   */
  isLoginInProgress() {
    const hashObj = this.getUrlHashAsObj();
    const queryParams = new URLSearchParams(window.location.search);

    const isStateInHash = hashObj.state && hashObj.state === getSessionStorageItem(CSRF_TOKEN_KEY);
    const isStateInQueryParams =
      queryParams.get('state') &&
      queryParams.get('state') === getSessionStorageItem(CSRF_TOKEN_KEY);

    return !!(isStateInHash || isStateInQueryParams);
  },

  /**
   * Sets the login state data in the storage
   * The function takes care of stringifying the data
   */
  setStateData(data) {
    setSessionStorageItem(STATE_DATA_KEY, JSON.stringify(data));
  },

  /**
   * Starts an authentication flow for an Idp using the specified url
   * and options. Automatically redirects to that Idp with the correct return_uri
   * and will return back to where the authentication started.
   *
   * @param {String} url Url of the Idp where we want to perform a login
   * @param {Object} options Set of options for this authentication
   * @param {Object} [options.queryParams] Set of extra query params that will be attached
   *                                       to the url. Default query params are `redirect_uri` and `state`
   * @param {Object} [options.data]        Data to be stored in session that can be used after returning from an Idp
   */
  startAuthentication(url, options = {}) {
    const returnUrl = options.returnUrl || this.buildReturnUrl();
    setSessionStorageItem(RETURN_URL_KEY, returnUrl);

    const csrfToken = this.generateCsrfToken();
    setSessionStorageItem(CSRF_TOKEN_KEY, csrfToken);

    if (options.data) {
      // set the state data as is, it can be whatever the user wants/needs
      // setStateData takes care of writing it in a readable format for later
      this.setStateData(options.data);
    }

    // merge the default parameters to the provided ones
    // these parameters can be overridden by the queryParams provided in options
    const queryParams = this.buildAuthenticationQueryString({
      redirect_uri: CONSOLE_URL,
      state: csrfToken,
      ...options.queryParams,
    });

    // get the full auth url and redirect to it
    window.location.href = this.buildAuthUrl(url, queryParams);
  },

  /**
   * This method calls `startAuthentication` with federationType in state data and client_id
   * configured for an azure admin consent authentication. If you need something more
   * specific please use the former.
   *
   * @param {String} scopes A space delimited string of scopes.
   */
  startAzureAdminConsentAuthentication(scopes, data) {
    this.startAuthentication(
      AppConstants.configuration.services.directorySync.azureAdminConsentUrl,
      {
        data: {
          federationType: FEDERATED_TYPES.AZURE,
          ...data,
        },
        queryParams: {
          client_id: this.getAzureClientId(),
          scope: scopes,
        },
      }
    );
  },

  /**
   * This method calls `startAuthentication` with most of the things
   * configured for an Azure authentication. If you need something more
   * specific please use the former.
   *
   * @param {String} scopes A space delimited string of scopes.
   */
  startAzureAuthentication(scopes) {
    this.startAuthentication(AppConstants.configuration.services.directorySync.azureAuthorizeUrl, {
      data: {
        federationType: FEDERATED_TYPES.AZURE,
      },
      queryParams: {
        client_id: this.getAzureClientId(),
        // Always display the account switcher for users so they can
        // confirm they're using the right account
        prompt: 'select_account',
        response_type: 'token',
        scope: scopes,
      },
    });
  },

  /**
   * This method calls `startAuthentication` with most of the things
   * configured for a ClassLink authentication. If you need something more
   * specific please use the former.
   */
  startClassLinkAuthentication() {
    this.startAuthentication(
      AppConstants.configuration.services.eduRosterSync.classLinkAuthorizeUrl,
      {
        data: {
          isEduLogin: true,
          syncType: SYNC_PROVIDERS.CLASSLINK,
        },
        queryParams: {
          client_id: AppConstants.configuration.services.eduRosterSync.classLinkClientId,
          redirect_uri: CONSOLE_URL,
          response_type: 'code',
          scope: 'full',
        },
      }
    );
  },

  /**
   * This method calls `startAuthentication` with most of the things
   * configured for a Clever authentication. If you need something more
   * specific please use the former.
   */
  startCleverAuthentication() {
    this.startAuthentication(AppConstants.configuration.services.eduRosterSync.cleverAuthorizeUrl, {
      data: {
        isEduLogin: true,
        syncType: SYNC_PROVIDERS.CLEVER,
      },
      queryParams: {
        client_id: AppConstants.configuration.services.eduRosterSync.cleverClientId,
        // Clever wants a "/" at the end of the redirect_uri
        redirect_uri: `${CONSOLE_URL}`,
        response_type: 'code',
      },
    });
  },

  /**
   * This method calls `startAuthentication` with most of the things
   * configured for a Google authentication. If you need something more
   * specific please use the former.
   *
   * @param {String} scopes A space delimited string of scopes.
   */
  startGoogleAuthentication(scopes) {
    this.startAuthentication(AppConstants.configuration.services.directorySync.googleAuthorizeUrl, {
      data: {
        federationType: FEDERATED_TYPES.GOOGLE,
      },
      queryParams: {
        client_id: AppConstants.configuration.services.directorySync.googleClientId,
        // Always display the account switcher for users so they can
        // confirm they're using the right account
        prompt: 'select_account',
        redirect_uri: `${window.location.protocol}//${window.location.hostname}`,
        response_type: 'token',
        scope: scopes,
      },
    });
  },
};

export default ExternalAuthService;
export {AZURE_SCOPES, GOOGLE_SCOPES, STATE_DATA_KEY, CONSOLE_URL, RETURN_URL_KEY, CSRF_TOKEN_KEY};
/* eslint-enable @admin-tribe/admin-tribe/check-browser-globals -- this service is used to authenticate to external users so no SSR usage for it */
