import {ModalContent, PageBanner} from '@admin-tribe/acsc-ui';
import {Divider, Flex, Icon, View} from '@adobe/react-spectrum';
import SpectrumV2Provider from '@react/react-spectrum/Provider';
import ArrowRight from '@spectrum-icons/workflow/ArrowRight';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';
/**
 * MigrateTeamToEnterpriseLicense Component
 *
 * This component renders a review and confirmation modal for the team to enterprise contract migration process.
 */

const MigrateTeamToEnterpriseLicense = ({migrationData, totalLicenseCount}) => {
  const migrationLicenses =
    migrationData?.contractEligibleForMigration?.contractMigrationList?.items[0]?.migrationContext
      ?.products || [];
  const intl = useIntl();

  const getString = (suffix) =>
    intl.formatMessage({id: `migrations.teamToEnterpriseMigrationModal.${suffix}`});

  const migrateLicenseInfoRow = ({sourceName, quantity, icon}, index) => (
    <View key={`license-info-row-${index}`} flex>
      <Flex direction="row" justifyContent="space-between">
        <Flex direction="row" gap="size-100">
          <View>
            <Icon size="S">
              <img alt={`${sourceName} icon`} src={icon} />
            </Icon>
          </View>
          <View>{sourceName}</View>
        </Flex>
        <View>{quantity}</View>
      </Flex>
    </View>
  );

  const renderLicenseColumn = (columnTitleKey, licenses, nameKey) => (
    <Flex direction="column" flex>
      <Flex direction="row" justifyContent="space-between">
        <View>{getString(columnTitleKey)}</View>
        <View>{getString('productLicensesQuantity.columns.quantity')}</View>
      </Flex>
      <Divider size="S" />
      {licenses.map((license) =>
        migrateLicenseInfoRow({
          icon: license[nameKey].assets.icons.svg,
          quantity: license.quantity,
          sourceName: license[nameKey].shortName,
        })
      )}
    </Flex>
  );

  return (
    <ModalContent>
      <SpectrumV2Provider>
        <PageBanner header="Review" showDefaultButton={false} variant="info">
          {intl.formatMessage(
            {id: 'migrations.teamToEnterpriseMigrationModal.reviewPanel.bannerBody'},
            {count: totalLicenseCount}
          )}
        </PageBanner>
      </SpectrumV2Provider>

      <Flex direction="row" gap="size-1000" marginTop="size-200" width="100%">
        {renderLicenseColumn(
          'productLicensesQuantity.columns.teamLicenses',
          migrationLicenses,
          'source'
        )}
        <ArrowRight alignSelf="center" size="L" />
        {renderLicenseColumn(
          'productLicensesQuantity.columns.enterpriseLicenses2',
          migrationLicenses,
          'target'
        )}
      </Flex>
    </ModalContent>
  );
};

MigrateTeamToEnterpriseLicense.propTypes = {
  migrationData: PropTypes.shape({
    contractEligibleForMigration: PropTypes.shape({
      contractMigrationList: PropTypes.shape({
        items: PropTypes.arrayOf(
          PropTypes.shape({
            migrationContext: PropTypes.shape({
              products: PropTypes.arrayOf(
                PropTypes.shape({
                  quantity: PropTypes.number,
                  source: PropTypes.shape({
                    shortName: PropTypes.string,
                  }),
                  target: PropTypes.shape({
                    shortName: PropTypes.string,
                  }),
                })
              ),
            }),
          })
        ),
      }),
    }),
  }).isRequired,
  totalLicenseCount: PropTypes.number.isRequired,
};

export default MigrateTeamToEnterpriseLicense;
