import binky from '@admin-tribe/acsc';
import binkyUI from '@admin-tribe/acsc-ui';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React, {useMemo, useRef, useState} from 'react';
import {useIntl} from 'react-intl';

import {getProductsAssignableToUsers} from 'common/utils/userProductUtils';
import {canAssignMember} from 'core/products/access/productAccess';

import AssignmentModalBase from '../assignment-modal-base/AssignmentModalBase';

const Product = binky.services.product.Product;
const productListUtils = binky.services.product.productListUtils;
const LicenseGroup = binky.services.product.licenseGroup.LicenseGroup;
const UserGroup = binky.services.users.UserGroup;
const {constructAsyncAddAssignUserAnalytics} = binky.utils.analyticsContextUtils;

const Avatar = binkyUI.common.components.Avatar;
const GoUrl = binkyUI.common.components.GoUrl;
const {TARGET_TYPE} = binkyUI.common.components.ASSIGNMENT_MENU_CONSTANTS;

const EditProductsAndUserGroupsModal = ({
  canEditProducts,
  canEditUserGroups,
  id = 'edit-products-user-groups-modal',
  isOpen,
  onCancel,
  onSuccess,
  orgId,
  user,
}) => {
  const intl = useIntl();
  const [isTeamOrg, setIsTeamOrg] = useState(false);
  const products = useRef();
  const [targets, setTargets] = useState(() => {
    const targetsArr = [];
    if (canEditUserGroups) {
      targetsArr.push(TARGET_TYPE.USER_GROUPS);
    }
    if (canEditProducts) {
      targetsArr.push(TARGET_TYPE.PRODUCTS);
    }

    return targetsArr;
  });
  const [warningMessage, setWarningMessage] = useState();

  const assignItemsToUser = (assignedItems, assignedProductRoles) => {
    if (canEditProducts) {
      user.products = cloneDeep(assignedItems[TARGET_TYPE.PRODUCT_PROFILES]);
    }
    if (canEditUserGroups) {
      user.userGroups = cloneDeep(assignedItems[TARGET_TYPE.USER_GROUPS]);
    }
    user.productRoles = assignedProductRoles;
  };

  const hasUnsavedChanges = (assignedItems) => {
    let hasChanges = false;

    if (canEditProducts) {
      const productChanges = assignedItems[TARGET_TYPE.PRODUCT_PROFILES] || [];

      hasChanges = !isEqual(
        user.products?.flatMap((p) =>
          // filter out profiles assigned from user groups, since they can only be removed
          // by removing the user from the user group
          p.licenseGroups?.filter((lg) => !lg.userGroup)?.map((lg) => lg.id)
        ),
        productChanges?.flatMap((p) => p.licenseGroups?.map((lg) => lg.id))
      );
    }

    if (canEditUserGroups) {
      const userGroupChanges = assignedItems[TARGET_TYPE.USER_GROUPS] || [];

      if (!hasChanges) {
        hasChanges = !isEqual(
          user.userGroups?.map((t) => t.id),
          userGroupChanges?.map((t) => t.id)
        );
      }
    }

    return hasChanges;
  };

  const onItemAssignment = (selectedItems) => {
    const changes = {};
    changes[TARGET_TYPE.PRODUCT_PROFILES] = [];
    changes[TARGET_TYPE.USER_GROUPS] = [];

    selectedItems.forEach((item) => {
      // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- cawright@ to update
      // istanbul ignore else -- not implemented
      if (item instanceof LicenseGroup || item instanceof Product) {
        const existingProduct = isTeamOrg
          ? null
          : changes[TARGET_TYPE.PRODUCT_PROFILES].find((product) => product.id === item.product.id);

        const product = cloneDeep(isTeamOrg ? item : item.product);
        product.licenseGroups = [];

        const licenseGroupToClone = isTeamOrg ? product.licenseGroupSummaries?.[0] : item;

        if (licenseGroupToClone) {
          const licenseGroup = cloneDeep(licenseGroupToClone);

          delete product.licenseGroupSummaries;
          delete licenseGroup.product.licenseGroupSummaries;

          if (existingProduct) {
            existingProduct.licenseGroups.push(licenseGroup);
          } else {
            product.licenseGroups.push(licenseGroup);
            changes[TARGET_TYPE.PRODUCT_PROFILES].push(product);
          }
        } else {
          // Note that if the product is deselected then the warning message remains
          // This is not meant to be a recoverable error for the user, but just indicate what is going on
          setWarningMessage(
            intl.formatMessage(
              {id: 'common.editProductsAndUserGroupsModal.productIssueWarning'},
              {
                productName: product.shortName,
              }
            )
          );
        }
      } else if (item instanceof UserGroup) {
        changes[TARGET_TYPE.USER_GROUPS].push(item);
      }
    });

    return changes;
  };

  const mapLicenseGroupProduct = (licenseGroup, product) =>
    new LicenseGroup({
      ...licenseGroup,
      product: products.current.find((p) => p.id === product.id),
    });

  const processDisabledItems = () => {
    const mappedTargets = {};

    if (products.current) {
      mappedTargets[TARGET_TYPE.PRODUCTS] = products?.current
        ?.filter((product) => !canAssignMember(product, user))
        .map((p) => p.id);

      const licenseGroups = [];

      user.products.forEach((product) => {
        licenseGroups.push(
          ...product.licenseGroups
            .filter((lg) => lg.userGroup)
            .map((licenseGroup) => mapLicenseGroupProduct(licenseGroup, product))
        );
      });

      mappedTargets[TARGET_TYPE.PRODUCT_PROFILES] = licenseGroups;
    }
    return mappedTargets;
  };

  const processDefaultItemsNew = () => {
    const mappedTargets = {};

    if (canEditProducts && !products.current) {
      return undefined;
    }

    if (products.current) {
      if (isTeamOrg) {
        mappedTargets[TARGET_TYPE.PRODUCTS] = user.products.map((p) => p.id);
      } else {
        const licenseGroups = [];

        user.products.forEach((product) => {
          licenseGroups.push(
            ...product.licenseGroups
              // filter out profiles assigned from user groups, since they can only be removed
              // by removing the user from the user group
              .filter((lg) => !lg.userGroup)
              .map((licenseGroup) => mapLicenseGroupProduct(licenseGroup, product))
          );
        });

        mappedTargets[TARGET_TYPE.PRODUCT_PROFILES] = licenseGroups;
      }
    }

    mappedTargets[TARGET_TYPE.USER_GROUPS] = user.userGroups.map(
      (userGroup) =>
        new UserGroup({
          id: userGroup.id,
          isSource: userGroup.isSource,
          isTarget: userGroup.isTarget,
          name: userGroup.name,
        })
    );

    return mappedTargets;
  };

  const onProductListResolve = (productList) => {
    // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- bluu@ to update
    // istanbul ignore else
    if (canEditProducts) {
      const isTeam = productListUtils.hasOnlyTeamProducts(productList);
      const assignableProducts = getProductsAssignableToUsers(productList);
      products.current = assignableProducts;
      if (assignableProducts.some((product) => !canAssignMember(product, user))) {
        setWarningMessage(
          intl.formatMessage({id: 'common.editProductsAndUserGroupsModal.userProductWarning'})
        );
      }
      if (!isTeam) {
        setTargets([TARGET_TYPE.PRODUCT_PROFILES, ...targets]);
      }
      setIsTeamOrg(isTeam);
    }

    return products.current;
  };

  const locStrings = useMemo(() => {
    if (targets.includes(TARGET_TYPE.USER_GROUPS) && targets.includes(TARGET_TYPE.PRODUCTS)) {
      return {
        assigned: 'common.editProductsAndUserGroupsModal.assigned.productsAndUserGroups',
        description: 'common.editProductsAndUserGroupsModal.description.productsAndUserGroups',
        header: 'common.editProductsAndUserGroupsModal.header.productsAndUserGroups',
      };
    }
    if (targets.includes(TARGET_TYPE.USER_GROUPS)) {
      return {
        assigned: 'common.editProductsAndUserGroupsModal.assigned.userGroupsOnly',
        description: 'common.editProductsAndUserGroupsModal.description.userGroupsOnly',
        goUrl: 'aac_pop_artt_user_groups',
        header: 'common.editProductsAndUserGroupsModal.header.userGroupsOnly',
      };
    }
    return {
      assigned: 'common.editProductsAndUserGroupsModal.assigned.productsOnly',
      description: 'common.editProductsAndUserGroupsModal.description.productsOnly',
      goUrl: 'ac_manage_product',
      header: 'common.editProductsAndUserGroupsModal.header.productsOnly',
    };
  }, [targets]);

  return (
    <AssignmentModalBase
      analyticsContextFunc={() => constructAsyncAddAssignUserAnalytics({orgId})}
      assignedItemLocId={locStrings.assigned}
      assignItemsToUser={assignItemsToUser}
      canEditProductRoles
      description={intl.formatMessage(
        {id: locStrings.description},
        {goUrl: (str) => <GoUrl name={locStrings.goUrl}>{str}</GoUrl>}
      )}
      hasUnsavedChanges={hasUnsavedChanges}
      header={intl.formatMessage({id: locStrings.header})}
      id={id}
      isOpen={isOpen}
      onCancel={onCancel}
      onItemAssignment={onItemAssignment}
      onProductListResolve={onProductListResolve}
      onSuccess={onSuccess}
      orgId={orgId}
      processDefaultItemsNew={processDefaultItemsNew}
      processDisabledItems={processDisabledItems}
      TagHeaderIconComponent={<Avatar alt="" member={user} size="S" />}
      tagHeaderText={user.getDisplayName()}
      targets={targets}
      user={user}
      warningMessage={warningMessage}
    />
  );
};

EditProductsAndUserGroupsModal.propTypes = {
  canEditProducts: PropTypes.bool.isRequired,
  canEditUserGroups: PropTypes.bool.isRequired,
  id: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  orgId: PropTypes.string.isRequired,
  // will be an object if passed in as a prop of type OrgUser (from src1)
  user: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.instanceOf(binky.services.organization.OrganizationUser),
  ]),
};

export default EditProductsAndUserGroupsModal;
