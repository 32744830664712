import {LicenseGroup, Product, navBus} from '@admin-tribe/acsc';
import {Item, TabList, TabPanels, Tabs} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {useLocation} from 'react-router-dom';

import rootStore from 'core/RootStore';
import {findKeyByPath, getTabs} from 'features/products/nav/productsProfileDetailsNav';

const findTabByKey = (tabs, key) => tabs.find((tab) => tab.key === key);

/**
 * A wrapper for Product Profile Details Tab Content
 */
const ProductProfileDetailsTabContent = ({product, licenseGroup}) => {
  const intl = useIntl();
  const orgId = rootStore.organizationStore.activeOrgId;
  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState(findKeyByPath(location));
  const tabs = useMemo(
    () => getTabs({intl, licenseGroup, orgId, product}),
    [intl, licenseGroup, orgId, product]
  );

  /** Keep the currently selected tab in sync with the URL */
  useEffect(() => {
    setSelectedKey(findKeyByPath(location));
  }, [location]);

  const onChange = useCallback(
    (key) => {
      if (key === selectedKey) {
        // Currently on the tab that was clicked, don't navigate
        return;
      }

      // Just modify the URL, the key will get updated in the useEffect above
      navBus.pushState({url: findTabByKey(tabs, key).pathname});
    },
    [selectedKey, tabs]
  );

  return (
    <Tabs aria-label={licenseGroup.name} onSelectionChange={onChange} selectedKey={selectedKey}>
      <TabList>
        {tabs.map((tab) => (
          <Item key={tab.key}>{tab.name}</Item>
        ))}
      </TabList>
      <TabPanels>
        {tabs.map((tab) => (
          <Item key={tab.key}>{tab.content}</Item>
        ))}
      </TabPanels>
    </Tabs>
  );
};

ProductProfileDetailsTabContent.propTypes = {
  /**
   * The license group model
   */
  licenseGroup: PropTypes.instanceOf(LicenseGroup).isRequired,
  /**
   * The product model
   */
  product: PropTypes.instanceOf(Product).isRequired,
};

export default ProductProfileDetailsTabContent;
