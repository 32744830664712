import {download, jilDomains, log} from '@admin-tribe/acsc';
import {
  showError as showErrorToast,
  showInfo as showInfoToast,
  showSuccess as showSuccessToast,
} from '@admin-tribe/acsc-ui';
import {useCallback} from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';

/**
 * Hook that provides the callbacks for the actions exposed by the domain list action menu.
 */
const useDomainListActions = () => {
  const intl = useIntl();

  const exportToCsv = useCallback(async () => {
    const DOMAINS_CSV_FILE_NAME = 'domains.csv';
    const orgId = rootStore.organizationStore.activeOrgId;

    showInfoToast(
      intl.formatMessage({id: 'settings.domainsTable.domainListActionMenu.downloadBeingPrepared'})
    );

    try {
      const response = await jilDomains.exportDomainsToCsv({orgId});

      if (!response?.data?.file || response.data.file.size === 0) {
        throw new Error('received empty domain list response from server');
      }

      download(response.data.file, DOMAINS_CSV_FILE_NAME);

      showSuccessToast(
        intl.formatMessage({id: 'settings.domainsTable.domainListActionMenu.downloadSuccess'})
      );
    } catch (error) {
      log.error(`Failed to download domain list CSV for ${orgId}. Error: `, error);

      showErrorToast(
        intl.formatMessage({id: 'settings.domainsTable.domainListActionMenu.downloadError'})
      );
    }
  }, [intl]);

  return {
    exportToCsv,
  };
};

export default useDomainListActions;
