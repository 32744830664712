const ADD_DOMAINS_MODAL_WIZARD_STEPS = {
  ENTER_DOMAINS_STEP: {id: 'ENTER_DOMAINS'},
  REQUEST_ACCESS_STEP: {id: 'REQUEST_ACCESS'},
  // eslint-disable-next-line sort-keys -- order is important
  CLAIM_DOMAINS_STEP: {id: 'CLAIM_DOMAINS'},
};

const JIL_ADD_DOMAINS_ERROR = {
  DOMAIN_ALREADY_CLAIMED_ACTIVE: 'DOMAIN_ALREADY_CLAIMED_ACTIVE',
  DOMAIN_ALREADY_CLAIMED_BY_ORG_WITH_NO_ADMIN: 'DOMAIN_ALREADY_CLAIMED_BY_ORG_WITH_NO_ADMIN',
  DOMAIN_ALREADY_CLAIMED_BY_THIS_ORG: 'DOMAIN_ALREADY_CLAIMED_BY_THIS_ORG',
  DOMAIN_ALREADY_CLAIMED_NOT_ACTIVE: 'DOMAIN_ALREADY_CLAIMED_NOT_ACTIVE',
  DOMAIN_NAME_INVALID: 'DOMAIN_NAME_INVALID',
  DOMAIN_RESERVED: 'DOMAIN_RESERVED',
  ORG_PENDING_ESM_MIGRATION: 'ORG_PENDING_ESM_MIGRATION',
  ORG_PENDING_T2E_MIGRATION: 'ORG_PENDING_T2E_MIGRATION',
  ORGANIZATIONS_INCOMPATIBLE_FOR_TRUST: 'ORGANIZATIONS_INCOMPATABLE_FOR_TRUST',
  ORGS_DO_NOT_SHARE_GTM: 'ORGS_DO_NOT_SHARE_GTM',
};

export {ADD_DOMAINS_MODAL_WIZARD_STEPS, JIL_ADD_DOMAINS_ERROR};
