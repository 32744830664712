import {showError as showErrorToast, showSuccess as showSuccessToast} from '@admin-tribe/acsc-ui';
import {ActionMenu, DialogContainer, Item} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import useDirectoryList from 'common/hooks/api/useDirectoryList';
import {CERTIFICATE_TYPES} from 'features/settings/common/constants/samlCertificatesConstants';
import {useDirectorySetupContext} from 'features/settings/components/directory/DirectorySetupContext';
import {useSetupCertificatesContext} from 'features/settings/components/setup-directory-authentication/SetupCertificatesContext';
import DeleteCertificateConfirmationModal from 'features/settings/components/setup-directory-authentication/setup-saml-certs/DeleteCertificateConfirmationModal';
import SetDefaultCertificateConfirmationModal from 'features/settings/components/setup-directory-authentication/setup-saml-certs/SetDefaultCertificateConfirmationModal';
import UploadCsrModal from 'features/settings/components/setup-directory-authentication/setup-saml-certs/UploadCsrModal';

const SAML_CERTIFICATE_ACTIONS = {
  ACTIVATE: 'ACTIVATE',
  COMPLETE: 'COMPLETE',
  DEACTIVATE: 'DEACTIVATE',
  DELETE: 'DELETE',
  DOWNLOAD_CERTIFICATE: 'DOWNLOAD_CERTIFICATE',
  DOWNLOAD_CSR: 'DOWNLOAD_CSR',
  DOWNLOAD_METADATA: 'DOWNLOAD_METADATA',
  SET_DEFAULT: 'SET_DEFAULT',
};

const ACTION_MENU_ITEMS = [
  // Adobe signed certificates actions
  {
    isAvailable: ({certificate, defaultCertificateId}) =>
      certificate.type !== CERTIFICATE_TYPES.CERTIFICATE_SIGNING_REQUEST &&
      !certificate.checkIsDefault(defaultCertificateId) &&
      !certificate.isDisabled,
    isDownloadAction: false,
    key: SAML_CERTIFICATE_ACTIONS.SET_DEFAULT,
    labelId: 'settings.certificates.table.actionMenu.setDefault',
    priority: 1,
  },
  {
    isAvailable: ({certificate}) =>
      certificate.type !== CERTIFICATE_TYPES.CERTIFICATE_SIGNING_REQUEST &&
      !certificate.isActive &&
      !certificate.isExpired,
    isDownloadAction: false,
    key: SAML_CERTIFICATE_ACTIONS.ACTIVATE,
    labelId: 'settings.certificates.table.actionMenu.activate',
    priority: 2,
  },
  {
    href: (idp) => idp.entityId,
    isAvailable: ({certificate, defaultCertificateId}) =>
      certificate.type !== CERTIFICATE_TYPES.CERTIFICATE_SIGNING_REQUEST &&
      certificate.checkIsDefault(defaultCertificateId),
    isDownloadAction: true,
    key: SAML_CERTIFICATE_ACTIONS.DOWNLOAD_METADATA,
    labelId: 'settings.certificates.table.actionMenu.downloadMetadata',
    priority: 3,
  },
  {
    href: (idp, certificate) => certificate.downloadUrl,
    isAvailable: ({certificate}) =>
      certificate.type === CERTIFICATE_TYPES.ADOBE_SIGNED ||
      certificate.type === CERTIFICATE_TYPES.CA_SIGNED,
    isDownloadAction: true,
    key: SAML_CERTIFICATE_ACTIONS.DOWNLOAD_CERTIFICATE,
    labelId: 'settings.certificates.table.actionMenu.downloadCertificate',
    priority: 4,
  },
  {
    isAvailable: ({certificate, defaultCertificateId}) =>
      certificate.type !== CERTIFICATE_TYPES.CERTIFICATE_SIGNING_REQUEST &&
      !certificate.isDisabled &&
      !certificate.checkIsDefault(defaultCertificateId),
    isDownloadAction: false,
    key: SAML_CERTIFICATE_ACTIONS.DEACTIVATE,
    labelId: 'settings.certificates.table.actionMenu.deactivate',
    priority: 5,
  },

  // CSR actions
  {
    isAvailable: ({certificate}) =>
      certificate.type === CERTIFICATE_TYPES.CERTIFICATE_SIGNING_REQUEST,
    key: SAML_CERTIFICATE_ACTIONS.DOWNLOAD_CSR,
    labelId: 'settings.certificates.table.actionMenu.downloadCSR',
    priority: 1,
  },
  {
    isAvailable: ({certificate}) =>
      certificate.type === CERTIFICATE_TYPES.CERTIFICATE_SIGNING_REQUEST,
    isDownloadAction: false,
    key: SAML_CERTIFICATE_ACTIONS.COMPLETE,
    labelId: 'settings.certificates.table.actionMenu.complete',
    priority: 2,
  },

  // Both types actions
  {
    // this is available to both type of certificates except the default one
    isAvailable: ({certificate, defaultCertificateId}) =>
      !certificate.checkIsDefault(defaultCertificateId),
    isDownloadAction: false,
    key: SAML_CERTIFICATE_ACTIONS.DELETE,
    labelId: 'settings.certificates.table.actionMenu.delete',
    priority: 6,
  },
];

const DIALOGS = {
  DELETE_CERTIFICATE: 'delete-certificate-modal',
  SET_DEFAULT: 'set-default-modal',
  UPLOAD_CSR: 'upload-csr-modal',
};

const SamlCertificatesActionMenu = ({certificate}) => {
  const intl = useIntl();
  const [dialog, setDialog] = useState(null);
  const {clearDirectoryCache} = useDirectoryList();

  const {
    activateCertificate,
    deactivateCertificate,
    downloadCsrFile,
    setDefault,
    state,
    completeCsr,
  } = useSetupCertificatesContext();
  const {defaultCertificateId} = state;
  const {directorySetupStore} = useDirectorySetupContext();
  const {idp} = directorySetupStore;

  const [selectedCsr, setSelectedCsr] = useState(null);

  const actions = ACTION_MENU_ITEMS.filter((action) =>
    action.isAvailable({certificate, defaultCertificateId})
  ).sort((a, b) => a.priority - b.priority);

  const onActivate = async () => {
    try {
      await activateCertificate(certificate.id);

      showSuccessToast(
        intl.formatMessage({id: 'settings.certificates.table.toasts.activateSuccess'})
      );
    } catch (error) {
      showErrorToast(intl.formatMessage({id: 'settings.certificates.table.toasts.activateError'}));
    }
  };

  const onDeactivate = async () => {
    try {
      await deactivateCertificate(certificate.id);

      showSuccessToast(
        intl.formatMessage({id: 'settings.certificates.table.toasts.deactivateSuccess'})
      );
    } catch (error) {
      showErrorToast(
        intl.formatMessage({id: 'settings.certificates.table.toasts.deactivateError'})
      );
    }
  };

  const onDownloadCsr = async () => {
    try {
      await downloadCsrFile(certificate.id);

      showSuccessToast(
        intl.formatMessage({id: 'settings.certificates.table.toasts.downloadSuccess'})
      );
    } catch (error) {
      showErrorToast(intl.formatMessage({id: 'settings.certificates.table.toasts.downloadError'}));
    }
  };

  const handleOnSetDefault = async () => {
    try {
      await setDefault(certificate.id);
      clearDirectoryCache();

      showSuccessToast(
        intl.formatMessage({id: 'settings.certificates.table.toasts.defaultSuccess'})
      );

      setDialog(null);
    } catch (error) {
      showErrorToast(intl.formatMessage({id: 'settings.certificates.table.toasts.defaultError'}));
    }
  };

  const onSetDefault = () => {
    setDialog(DIALOGS.SET_DEFAULT);
  };

  // upload the certificate and call the API
  const handleOnCompleteCsr = async (csrFile) => {
    try {
      await completeCsr({csrFile, csrId: selectedCsr.id});

      showSuccessToast(
        intl.formatMessage({id: 'settings.certificates.uploadCsrModal.toast.success'})
      );

      setDialog(null);
    } catch (error) {
      if (error.response.data.error === 'saml_certificate_public_key_mismatch') {
        showErrorToast(
          intl.formatMessage({id: 'settings.certificates.uploadCsrModal.toast.errorPublicKey'})
        );

        return;
      }

      showErrorToast();
    }
  };

  // display the modal to upload the certificate
  const onCompleteCsr = () => {
    setSelectedCsr(certificate);
    setDialog(DIALOGS.UPLOAD_CSR);
  };

  const handleAction = (action) => {
    switch (action) {
      case SAML_CERTIFICATE_ACTIONS.DELETE:
        setDialog(DIALOGS.DELETE_CERTIFICATE);
        break;
      case SAML_CERTIFICATE_ACTIONS.ACTIVATE:
        onActivate();
        break;
      case SAML_CERTIFICATE_ACTIONS.DEACTIVATE:
        onDeactivate();
        break;
      case SAML_CERTIFICATE_ACTIONS.DOWNLOAD_CSR:
        onDownloadCsr();
        break;
      case SAML_CERTIFICATE_ACTIONS.SET_DEFAULT:
        onSetDefault();
        break;
      case SAML_CERTIFICATE_ACTIONS.COMPLETE:
        onCompleteCsr();
        break;
      default:
    }
  };

  return (
    <>
      <ActionMenu
        data-testid="certificates-action-menu"
        isQuiet
        items={actions}
        onAction={handleAction}
      >
        {(item) =>
          item.isDownloadAction ? (
            <Item key={item.key} href={item.href(idp, certificate)} target="_blank">
              {intl.formatMessage({id: item.labelId})}
            </Item>
          ) : (
            <Item key={item.key}>{intl.formatMessage({id: item.labelId})}</Item>
          )
        }
      </ActionMenu>

      <DialogContainer
        data-testid="delete-certificate-modal"
        onDismiss={() => {
          setDialog(null);
        }}
      >
        {dialog === DIALOGS.DELETE_CERTIFICATE && (
          <DeleteCertificateConfirmationModal certificate={certificate} />
        )}
      </DialogContainer>

      <DialogContainer
        data-testid="upload-csr-modal"
        onDismiss={() => {
          setDialog(null);
        }}
      >
        {dialog === DIALOGS.UPLOAD_CSR && (
          <UploadCsrModal handleOnCompleteCsr={handleOnCompleteCsr} />
        )}
      </DialogContainer>

      <DialogContainer
        data-testid="set-default-modal"
        onDismiss={() => {
          setDialog(null);
        }}
      >
        {dialog === DIALOGS.SET_DEFAULT && (
          <SetDefaultCertificateConfirmationModal onConfirm={handleOnSetDefault} />
        )}
      </DialogContainer>
    </>
  );
};

SamlCertificatesActionMenu.propTypes = {
  certificate: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
  }),
};

export default SamlCertificatesActionMenu;
export {SAML_CERTIFICATE_ACTIONS};
