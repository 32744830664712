import {Contract, EVENT_ACTION, feature} from '@admin-tribe/acsc';
import {ImageIcon} from '@admin-tribe/acsc-ui';
import {Button, Flex, ProgressCircle, Text} from '@adobe/react-spectrum';
import {InlinePrice, StrikethroughPrice} from '@pandora/react-price';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import SafePrice from 'common/components/safe-price/SafePrice';

import useSophiaPersonalOfferGivenCancelReasons from '../../hooks/use-cancellation-reasons/useSophiaPersonalOfferGivenCancelReasons';
import useRetentionDetails from '../../hooks/use-retention-details/useRetentionDetails';
import SelfCancelCard from '../card/SelfCancelCard';
import MerchandisingText from '../merchandising-text/MerchandisingText';
import {useSelfCancelAnalyticsContext} from '../self-cancel-analytics-context/SelfCancelAnalyticsContext';

import styles from './Card.pcss';
import cardStyles from './FreeTimeOfferCard.pcss';
import inlinePriceStyles from './InlinePrice.pcss';
import SaveOffersHeader from './SaveOffersHeader';

/**
 * Card component to render Free Time Offer type.
 */
// eslint-disable-next-line complexity -- logic will be removed soon
const FreeTimeOfferCard = ({index, onCTA, contract, retentionId, selectedReasons}) => {
  const intl = useIntl();

  const {
    currency,
    icon,
    isBestValue,
    nextPrice,
    description,
    regularPrice,
    shortDescription: regularPriceTitle,
    discountPrice,
    banner,
    headline,
    promotionId = '',
    recurrenceTerm,
  } = useRetentionDetails({contract, retentionId});
  const {dispatchSelfCancelUiEventAnalytics} = useSelfCancelAnalyticsContext();
  const {isLoading, sophiaMerchandisingData} = useSophiaPersonalOfferGivenCancelReasons({
    promotionId,
    selectedReasons: [...selectedReasons].sort().join(','),
    type: 'FREE_TIME',
  });

  const onAcceptOfferPress = () => {
    dispatchSelfCancelUiEventAnalytics({
      card: {index, retentionId},
      eventAction: EVENT_ACTION.CLICK,
      eventName: `cancelFlowOffersAcceptOffer`,
      retentionId,
    });

    onCTA(retentionId);
  };

  let regularTitle, title;

  if (feature.isEnabled('temp_fix_save_offer_footer_tnc')) {
    title = headline && `${headline}`.replace('{price}', '<Price></Price>');
    regularTitle = regularPriceTitle && regularPriceTitle.replace('{price}', '<Price></Price>');
  } else {
    title = headline && `${headline}`.replace('{{price}}', '<Price></Price>');
    regularTitle = regularPriceTitle && regularPriceTitle.replace('{{price}}', '<Price></Price>');
  }

  const [cardText, setCardText] = useState({
    cardDescription: '',
    cardShortDescription: '',
    cardTitle: intl.formatMessage({
      id: 'common.selfCancelFooterTotal.loadingLabel',
    }),
  });

  const loadingCard = () => (
    <SelfCancelCard title={intl.formatMessage({id: 'common.selfCancelFooterTotal.loadingLabel'})}>
      <ProgressCircle
        aria-label={intl.formatMessage({id: 'common.selfCancelFooterTotal.loadingLabel'})}
        data-testid="progressCircle"
        isIndeterminate
        size="S"
      />
    </SelfCancelCard>
  );

  // set card text when sophia merchendising API call is complete
  React.useEffect(() => {
    // populate card text with sophia merchendising data when hook finishes loading and has data
    if (
      feature.isEnabled('temp_sophia_promotional_offer') &&
      !isLoading &&
      sophiaMerchandisingData &&
      Object.entries(sophiaMerchandisingData).length > 0
    ) {
      // Delivering AEM content through sophia results in curly bracket placeholders being parsed as query params rather than content.
      // As a result we are using [[ ]] syntax instead. This does not affect the existing curly bracket used in JIL
      setCardText({
        cardDescription: sophiaMerchandisingData?.description,
        cardShortDescription: sophiaMerchandisingData?.shortDescription,
        cardTitle: `${sophiaMerchandisingData?.headline}`.replace('[[price]]', '<Price></Price>'),
      });
    }

    // fall back to product change merchendising data when sophia merchendising data is not returned
    if (
      feature.isEnabled('temp_sophia_promotional_offer') &&
      !isLoading &&
      (!sophiaMerchandisingData || Object.entries(sophiaMerchandisingData).length === 0)
    ) {
      setCardText({
        cardDescription: description,
        cardShortDescription: regularTitle,
        cardTitle: title,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run when these change
  }, [isLoading, sophiaMerchandisingData]);

  // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- will be removed soon
  // istanbul ignore next
  return (
    <>
      {isLoading && feature.isEnabled('temp_sophia_promotional_offer') && loadingCard()}
      {!isLoading && (
        <SelfCancelCard
          headerComponent={
            <SaveOffersHeader
              headerImage={banner}
              isBestValue={isBestValue}
              logo={icon && <ImageIcon alt="" size="L" src={icon} />}
            />
          }
          testId="free-time-offer-card"
          /* eslint-disable @admin-tribe/admin-tribe/extract-large-computations -- needed to use InlinePrice */
          title={
            <span className={styles['title-text']}>
              <MerchandisingText
                message={
                  feature.isEnabled('temp_sophia_promotional_offer') ? cardText.cardTitle : title
                }
                values={{
                  Price: () => (
                    <SafePrice
                      currency={currency}
                      data-testid="safe-price"
                      overrideStyles={inlinePriceStyles}
                      price={discountPrice}
                      PriceComponent={InlinePrice}
                      recurrenceTerm={recurrenceTerm}
                    />
                  ),
                }}
              />
            </span>
          }
          /* eslint-enable @admin-tribe/admin-tribe/extract-large-computations -- needed to use InlinePrice */
        >
          <Flex data-testid="regular-price" direction="column" minHeight="size-800">
            <Text>
              {feature.isEnabled('temp_sophia_promotional_offer')
                ? cardText.cardShortDescription
                : regularPriceTitle}
            </Text>
            <SafePrice
              currency={currency}
              overrideStyles={cardStyles}
              price={regularPrice}
              PriceComponent={StrikethroughPrice}
              recurrenceTerm={recurrenceTerm}
            />
          </Flex>
          <Flex
            data-testid="card-description"
            direction="column"
            marginBottom="size-150"
            minHeight="size-800"
          >
            <Text
              // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needed for styling text
              UNSAFE_className={styles['green-text']}
            >
              {feature.isEnabled('temp_sophia_promotional_offer')
                ? cardText.cardDescription
                : description}
            </Text>
            <SafePrice
              currency={currency}
              overrideStyles={styles}
              price={nextPrice}
              recurrenceTerm={recurrenceTerm}
            />
          </Flex>
          <Button
            alignSelf="flex-end"
            data-testid="accept-offer-btn"
            marginTop="auto"
            onPress={onAcceptOfferPress}
            variant="primary"
          >
            {intl.formatMessage({
              id: 'account.selfCancel.components.saveOfferCard.freeTime.acceptOfferButton',
            })}
          </Button>
        </SelfCancelCard>
      )}
    </>
  );
};

FreeTimeOfferCard.propTypes = {
  /**
   * The org's contract.
   */
  contract: PropTypes.instanceOf(Contract).isRequired,
  /**
   * The location of the card in the carousel.
   */
  index: PropTypes.number,
  /**
   * Handler that is called when the user press the CTA.
   */
  onCTA: PropTypes.func.isRequired,
  /**
   * The retention id for the Save Offer, used by the component to look it up in ProductsChange
   * response.
   */
  retentionId: PropTypes.string.isRequired,
  /**
   * The cancellation reasons selected
   */
  selectedReasons: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default FreeTimeOfferCard;
