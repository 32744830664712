import {InfoBar, InfoItemScorecard} from '@admin-tribe/acsc-ui';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

/**
 * product deactivated legacy devices score card component
 */

const LegacyDevicesInfoBar = ({entitled, activated}) => {
  const intl = useIntl();
  return (
    <InfoBar data-testid="legacy-devices-info-bar">
      <InfoItemScorecard
        data-testid="legacy-devices-info-bar-score-card-total-licenses"
        label={intl.formatMessage({
          id: 'products.deviceLicenses.scoreCards.totalLicenses',
        })}
        value={entitled}
      />
      <InfoItemScorecard
        data-testid="legacy-devices-info-bar-score-card-used-licenses"
        label={intl.formatMessage({
          id: 'products.deviceLicenses.scoreCards.usedLicenses',
        })}
        value={activated}
      />
    </InfoBar>
  );
};

LegacyDevicesInfoBar.propTypes = {
  /**
   *  Total activated Legacy devices count
   */
  activated: PropTypes.number,
  /**
   *  Total licenses count of the Legacy devices
   */
  entitled: PropTypes.number,
};

export default LegacyDevicesInfoBar;
