import {CONTRACT_BILLING_FREQUENCY, TAX_TERM} from '@admin-tribe/acsc';
import {BOLD_PRICE_STYLES} from '@admin-tribe/acsc-ui';
import {Flex, Text, View} from '@adobe/react-spectrum';
import {TooltipButton} from '@pandora/react-tooltip-button';
import InfoOutlineIcon from '@spectrum-icons/workflow/InfoOutline';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import SafePrice, {PRICE_FAILURE} from 'common/components/safe-price/SafePrice';

import cartItemStyles from '../../../../../common/components/cart/CartItem.pcss';
import SafeFormattedDateAndTime from '../safe-formatted-date-and-time/SafeFormattedDateAndTime';
import SignedPrice from '../signed-price/SignedPrice';

import styles from './SelfCancelCartTotal.pcss';

/**
 * Total with a subtotal of all units to be removed from the contract
 * tax rate, tax total, and final total, including tax.
 */
const SelfCancelCartTotal = ({
  afterDiscountPeriodTotal,
  currency,
  discountDuration,
  discountNextBillingDate,
  discountTotal,
  isMultiTaxRate,
  isTaxInclusive = true,
  taxRate,
  taxTerm,
  recurrenceTerm = CONTRACT_BILLING_FREQUENCY.MONTHLY,
  totalTax,
  totalWithoutTax,
  totalWithTax,
  totalDiscountPercentage,
}) => {
  const intl = useIntl();

  const hasDiscountNotice = !!(totalDiscountPercentage && discountDuration && discountTotal);

  return (
    <View
      backgroundColor="static-white"
      data-testid="self-cancel-cart-total"
      paddingX="size-300"
      paddingY="size-175"
      // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needed for styling
      UNSAFE_className={cartItemStyles['cart-item']}
    >
      {hasDiscountNotice && (
        <Flex
          data-testid="total-discount-notice"
          justifyContent="space-between"
          marginBottom="size-75"
          // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needed for styling
          UNSAFE_className={styles['total-discount-notice']}
        >
          <Text data-testid="notice-key">
            {intl.formatMessage(
              {
                id: 'account.selfCancel.components.selfCancelCartTotal.discountDetails',
              },
              {discountPercentage: totalDiscountPercentage, monthsCount: discountDuration}
            )}
          </Text>
          <View
            // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needed for styling
            UNSAFE_className={styles['discount-price']}
          >
            <SignedPrice
              currency={currency}
              data-testid="notice-value"
              isNegative
              price={discountTotal}
              recurrenceTerm={recurrenceTerm}
            />
          </View>
        </Flex>
      )}
      <Flex justifyContent="space-between">
        <Text>
          {intl.formatMessage({
            id: 'account.selfCancel.components.selfCancelCartTotal.subtotal',
          })}
        </Text>
        <SafePrice
          currency={currency}
          data-testid="subtotal-value"
          price={totalWithoutTax}
          recurrenceTerm={recurrenceTerm}
        />
      </Flex>
      <Flex justifyContent="space-between">
        {isMultiTaxRate ? (
          <Flex alignItems="center">
            <Text data-testid="multi-tax-key">{taxTerm}</Text>
            <TooltipButton
              aria-label={intl.formatMessage({
                id: 'account.selfCancel.components.selfCancelCartTotal.tax.tooltip',
              })}
              data-testid="tax-tooltip"
              elementType="span"
              hoverText={intl.formatMessage({
                id: 'account.selfCancel.components.selfCancelCartTotal.tax.tooltip',
              })}
              isQuiet
              marginStart="size-100"
              minHeight="size-150"
              minWidth="size-0"
              // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- used to resize and remove outline of the button
              UNSAFE_className={styles['tooltip-icon']}
              variant="secondary"
            >
              <InfoOutlineIcon />
            </TooltipButton>
          </Flex>
        ) : (
          <Text data-testid="tax-key">
            {intl.formatMessage(
              {
                id: 'account.selfCancel.components.selfCancelCartTotal.tax',
              },
              {taxRate: taxRate ?? PRICE_FAILURE, taxTerm}
            )}
          </Text>
        )}
        <SafePrice
          currency={currency}
          data-testid="tax-value"
          price={totalTax}
          recurrenceTerm={recurrenceTerm}
        />
      </Flex>
      <Flex
        justifyContent="space-between"
        marginTop="size-75"
        // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needed for styling
        UNSAFE_className={styles['grand-total']}
      >
        <Text
          data-testid="total-due-key"
          // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needed for styling
          UNSAFE_className={styles['bolded-text']}
        >
          {discountDuration
            ? intl.formatMessage(
                {
                  id: 'account.selfCancel.components.selfCancelCartTotal.due',
                },
                {
                  i: (text) => <span className={styles['grey-text']}>{text}</span>,
                  monthsCount: discountDuration,
                }
              )
            : intl.formatMessage({
                id: 'account.selfCancel.components.selfCancelCartTotal.total',
              })}
        </Text>
        <SafePrice
          currency={currency}
          data-testid="total-due-value"
          overrideStyles={BOLD_PRICE_STYLES}
          price={totalWithTax}
          recurrenceTerm={recurrenceTerm}
        />
      </Flex>
      {discountNextBillingDate && typeof afterDiscountPeriodTotal !== 'undefined' && (
        <Flex data-testid="total-after-period" justifyContent="space-between">
          <Text
            data-testid="total-after-key"
            // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needed for styling
            UNSAFE_className={styles['bolded-text']}
          >
            {intl.formatMessage(
              {
                id: 'account.selfCancel.components.selfCancelCartTotal.totalAfterPeriod',
              },
              {
                totalAfterPeriod: () => (
                  <SafeFormattedDateAndTime value={discountNextBillingDate} />
                ),
              }
            )}
          </Text>
          <SafePrice
            currency={currency}
            data-testid="total-after-value"
            isTaxInclusive={isTaxInclusive}
            overrideStyles={styles}
            price={afterDiscountPeriodTotal}
            recurrenceTerm={recurrenceTerm}
            taxTerm={taxTerm}
          />
        </Flex>
      )}
    </View>
  );
};

SelfCancelCartTotal.propTypes = {
  /**
   * Total after discount period
   */
  afterDiscountPeriodTotal: PropTypes.number,
  /**
   * The currency object where formatString and usePrecision are provided
   */
  currency: PropTypes.shape({
    /**
     * The format string in which to display the currency
     */
    formatString: PropTypes.string.isRequired,
    /**
     * True to include decimal value of the currency, false to only use whole values
     */
    usePrecision: PropTypes.bool,
  }).isRequired,
  /**
   * The number of months the discount will be applied for
   */
  discountDuration: PropTypes.number,
  /**
   * The end date of the discount period
   */
  discountNextBillingDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]),
  /**
   * Total discount/savings amount
   */
  discountTotal: PropTypes.number,
  /**
   * Whether or not the cart items have different tax rates
   */
  isMultiTaxRate: PropTypes.bool,
  /**
   * A flag to toggle if the (incl. tax)/(excl. tax) string will be included in displaying the price
   * Dependent on taxTerm prop. If taxTerm is undefined, neither string mentioned above will appear
   */
  isTaxInclusive: PropTypes.bool,
  /**
   * The billing recurrence of the contract (e.g. `/mo` or `/yr`)
   */
  recurrenceTerm: PropTypes.oneOf(Object.values(CONTRACT_BILLING_FREQUENCY)),
  /**
   * The tax rate
   */
  taxRate: PropTypes.number,
  /**
   * The text to be used in the suffix which indicates whether the
   * price is inclusive or exclusive of tax
   */
  taxTerm: PropTypes.oneOf(Object.values(TAX_TERM)).isRequired,
  /**
   * Total percentage of the discount provided for the product
   */
  totalDiscountPercentage: PropTypes.number,
  /**
   * The tax amount/total in decimal form
   */
  totalTax: PropTypes.number.isRequired,
  /**
   * The price billed to the contract after units are removed in decimal form
   */
  totalWithoutTax: PropTypes.number.isRequired,
  /**
   * The total for all remaining licenses with tax total included in decimal form
   */
  totalWithTax: PropTypes.number.isRequired,
};

export default SelfCancelCartTotal;
