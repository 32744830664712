import {AuthenticatedUser, JIL_CONSTANTS, Store} from '@admin-tribe/acsc';
import clamp from 'lodash/clamp';
import {action, makeObservable, observable, runInAction} from 'mobx';

import rootStore from 'core/RootStore';

// Common domain data for Users/Admins/Developers/Directory users/Api credentials and
// the API credentials pageable table in the modal to add credentials to a product profile.
class MemberStore extends Store {
  currentItems = undefined;
  memberList = undefined;
  totalPages = undefined;

  /**
   * @param {Object} [options] - configuration for the store
   * @param {Object} options.listClassRef - the reference to the list class which should be a subclass of MemberList
   * @param {Object} [options.listOptions] - options passed to listClassRef's get method.
   */
  constructor({listClassRef, listOptions}) {
    super();

    // Set this to true right away so the table doesn't have a chance to think it is empty.
    // store.load will set it to false when fetchData completes
    this.isLoading = true;

    this.orgId = rootStore.organizationStore.activeOrgId;
    this.currentUserId = AuthenticatedUser.get().getId();

    this.listClassRef = listClassRef;
    this.listOptions = listOptions || {};

    this.scorecardValue = null; // count of members - displays en-dash until value is set to count

    // See note on 'setSelectedItems' below.
    this.selectedItems = [];

    this.filterQuery = '';
    this.pageNumber = 1;
    this.pageSize = 20;

    // Ensure this is in sync with the hard-coded Table sortDescriptor in MemberListTable.
    // These are the default ModelList values for sort.
    this.sortOrder = JIL_CONSTANTS.ORDER.ASC;
    this.sortExpression = JIL_CONSTANTS.SORT.FNAME_LNAME;

    makeObservable(this, {
      currentItems: observable,
      fetchData: action,
      getItem: action,
      listOptions: observable,
      onFilterQuery: action,
      onPageNumberChange: action,
      onPageSizeChange: action,
      onSortBy: action,
      pageNumber: observable,
      removeSelectedMembers: action,
      resetListState: action,
      scorecardValue: observable,
      selectedItems: observable,
      setSelectedItems: action,
      totalPages: observable,
    });
  }

  /**
   * This is the callback for Store.load() which wraps this in a try/catch.
   * If an error is caught this.hasLoadError will be set to true.
   * @param {Object}[options] - Options
   * @param {Function} [options.joinFn] - Function to call after the list is returned.
   *   This function is called with one parameter which is the list items.
   *   This function should make any other modifications needed to the list items.
   */
  async fetchData(options) {
    this.memberList = await this.listClassRef.get({
      filterQuery: this.filterQuery,
      orgId: this.orgId,
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      sortExpression: this.sortExpression,
      sortOrder: this.sortOrder,
      ...this.listOptions,
    });

    // Join in any other data before currentItems is set.
    // If props are added/modified in items after currentItems is set, the table may not re-render.
    if (this.memberList.items.length > 0 && options?.joinFn) {
      this.memberList.items = await options.joinFn(this.memberList.items);
    }

    runInAction(() => {
      this.currentItems = this.memberList.items;

      // Update the count of items for the paginator.
      this.totalPages = this.memberList.pagination.totalPages;

      // Only update the scorecard value shown if there isn't a search filter applied.
      if (this.memberList.shouldUpdateTotalItemCount()) {
        this.scorecardValue = this.memberList.getTotalItemCount();
      }

      // Setting this to false here instead of relying on it getting set in the finally() in binky's Store.js
      // This prevents an additional state update and re-render to all the components that use this store
      this.isLoading = false;
    });
  }

  fetchError() {
    // The server error is shown in the TableView's empty state so if there is an error
    // make sure there are no items so the error message will be shown.
    this.currentItems = [];
    super.fetchError();
  }

  getItem(itemKey) {
    return this.currentItems?.find((item) => item.id === itemKey);
  }

  onFilterQuery(newFilterQuery) {
    if (this.filterQuery !== newFilterQuery) {
      this.filterQuery = newFilterQuery;
      this.pageNumber = 1; // to mimic what JilModelList does on a refresh
      this.load();
    }
  }

  // One of pageNumber, goToNext or goToPrevious should be set.
  onPageNumberChange({pageNumber, goToNext, goToPrevious}) {
    const currentPageNumber = this.pageNumber;
    if (pageNumber !== undefined) {
      if (this.pageNumber !== pageNumber) {
        this.pageNumber = pageNumber;
      }
    } else if (goToNext) {
      this.pageNumber += 1;
    } else if (goToPrevious) {
      this.pageNumber -= 1;
    }

    // This should not be needed but be safe.
    this.pageNumber = clamp(this.pageNumber, 1, this.totalPages);

    if (this.pageNumber !== currentPageNumber) {
      this.load();
    }
  }

  onPageSizeChange(newPageSize) {
    this.pageSize = newPageSize;
    this.pageNumber = 1;
    this.load();
  }

  // Called when the sorted column or direction changes.
  onSortBy(columnName, isDescending) {
    const TABLE_TO_JIL_MAPPING = {
      email: JIL_CONSTANTS.SORT.EMAIL,
      name: JIL_CONSTANTS.SORT.FNAME_LNAME,
    };
    const newJilSortOrder = isDescending ? JIL_CONSTANTS.ORDER.DESC : JIL_CONSTANTS.ORDER.ASC;

    this.sortExpression = TABLE_TO_JIL_MAPPING[columnName];
    this.sortOrder = newJilSortOrder;
    this.pageNumber = 1;
    this.load();
  }

  // Depending on the list, this may need to be sub-classed.
  async removeSelectedMembers({saveOptions} = {}) {
    this.isLoading = true;
    this.memberList.remove(this.selectedItems);
    try {
      await this.memberList.save({...saveOptions, refresh: false});
    } catch (error) {
      this.memberList.resetRemovedItems();
      throw error;
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  // Use to reset the list state after a delete operation.
  // If using a TableSection make sure to update it to keep it in sync with the list.
  resetListState() {
    this.filterQuery = '';
    this.pageNumber = 1;
    this.selectedItems = [];
  }

  // Now that the TableSection supports tracking the selected items there is
  // no need to also calculate them here.
  // A +1 would be to refactor the store's selectedItems out of existence
  // but this would require significant restructuring since the TableSections's
  // selection is accessed via 'useTableSectionContext' which requires the
  // TableSection be in a different component than the TableActions and Table.
  setSelectedItems(selectedItems = []) {
    this.selectedItems = selectedItems;
  }
}

export default MemberStore;
