import {DetailsButton, Page, PageActions, PageContent, PageHeader} from '@admin-tribe/acsc-ui';
import React from 'react';
import {useIntl} from 'react-intl';
import {useParams} from 'react-router-dom';

import useDocumentTitle from 'common/hooks/useDocumentTitle';
import AppIntegrationsGlobalPolicyModal from 'features/products/app-integrations/components/AppIntegrationsGlobalPolicyModal';
import AppIntegrationsTabs from 'features/products/app-integrations/components/AppIntegrationsTabs';
import useAppIntegrationGlobalPolicy from 'features/products/app-integrations/hooks/useAppIntegrationGlobalPolicy';

/**
 * Top level page for /products/application-integrations.
 */
const AppIntegrationsPage = () => {
  const intl = useIntl();
  const {orgId} = useParams();

  useDocumentTitle({key: 'products.appIntegrations.appIntegrationsPage.title'});

  const {globalPolicy} = useAppIntegrationGlobalPolicy({
    orgId,
  });

  return (
    <Page data-testid="app-integrations-page">
      <PageHeader
        title={intl.formatMessage({
          id: 'products.appIntegrations.appIntegrationsPage.title',
        })}
      />
      {globalPolicy && (
        <PageActions>
          <DetailsButton detailsModal={<AppIntegrationsGlobalPolicyModal />} />
        </PageActions>
      )}
      <PageContent>
        <AppIntegrationsTabs orgId={orgId} />
      </PageContent>
    </Page>
  );
};

export default AppIntegrationsPage;
