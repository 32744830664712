// ***********************************************************************
// ADOBE CONFIDENTIAL
// ___________________
//
// Copyright 2024 Adobe
// All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains
// the property of Adobe and its suppliers, if any. The intellectual
// and technical concepts contained herein are proprietary to Adobe
// and its suppliers and are protected by all applicable intellectual
// property laws, including trade secret and copyright laws.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Adobe.
// ************************************************************************
import {AuthenticatedUser} from '@admin-tribe/acsc';
import {
  TNO_REJECT_PRODUCT_ASSIGN_MATCH_ANY_DENY_REASON,
  TNO_REJECT_PRODUCT_ASSIGN_SURFACE_REQUESTS_ID,
  useTnoRejectProductAssignModal,
} from '@pandora/react-tno-reject-product-assign-modal';
import {useEffect, useMemo, useState} from 'react';

import SOPHIA_CONSTANTS from 'common/services/sophia/SophiaConstants';
import rootStore from 'core/RootStore';
import {useLocale} from 'core/providers/locale/LocaleProvider';
import useUserAccessRequestLists from 'features/products/hooks/useUserAccessRequestLists';

/**
 * This is the productArrangementCode of the Sophia Campaign that the Org must have.
 * @see https://stage.sophia.corp.adobe.com/ui/campaigns/view/61865/audience-and-sizing
 */
const CCLE_DIRECT_INDIRECT_TEAM = 'ccle_direct_indirect_team';

/**
 * This was a late find, a potential blocker to rolling out.
 * LIST_STRING in sophia needs passed e.g. "url?1pacs=123,1pacs=456",
 * rather than "url?1pacs=123,456".
 * The initial Sophia Campaigns are only targeting when the org has the product `ccle_direct_indirect_team` already,
 * so for initial rollout, we can filter the product list to only include this productArrangementCode.
 * @param list
 * @see https://wulff-pack.slack.com/archives/CE8B9GGUA/p1648537808879059?thread_ts=1648493388.001019&cid=CE8B9GGUA
 */
const pandoraLimitationOnSophiaListString = (codes) =>
  codes?.split(',').includes(CCLE_DIRECT_INDIRECT_TEAM) ? CCLE_DIRECT_INDIRECT_TEAM : undefined;

// https://wiki.corp.adobe.com/pages/viewpage.action?spaceKey=SCS&title=Contracts+V2+API+-+Mapping+RTM+to+Sales+Channel+and+Customer+segment
const mapSlsToCommerceMarketSegment = (slsEnum) =>
  slsEnum?.length > 2 ? slsEnum.slice(0, 3).toUpperCase() : slsEnum;

// https://wiki.corp.adobe.com/display/ONE/Sophia+and+Gainsight+contextual+parameters+sent+by+Admin+Console#SophiaandGainsightcontextualparameterssentbyAdminConsole-Contractspecific
const filterForValidTeamContract = (contract) => {
  const isIndirect = contract.salesChannel === 'INDIRECT' && contract.customerSegment === 'TEAM';
  const isDirectTeam = contract.salesChannel === 'DIRECT' && contract.customerSegment === 'TEAM';
  const isActive = contract.status === 'ACTIVE';
  return isActive && (isIndirect || isDirectTeam);
};

/**
 * NOTE ONESIE-42754 for refactoring into the TnO Modal component
 * @returns A hook that provides the TnoRejectProductAssignRequests context
 */
const useTnoRejectProductAssignRequests = ({orgId}) => {
  // state
  const [accessRequestsCount, setAccessRequestsCount] = useState(undefined);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(undefined);

  // hooks
  const {
    localeObject: {activeLocaleCode},
  } = useLocale();

  const {
    data: userAccessRequestLists,
    error: userAccessRequestListsError,
    isLoading: accessRequestListsLoading,
  } = useUserAccessRequestLists();

  // effects
  useEffect(() => {
    setIsLoading(accessRequestListsLoading);
    if (accessRequestListsLoading) {
      return;
    }
    if (userAccessRequestListsError) {
      setIsError(true);
      setError(userAccessRequestListsError);
      return;
    }
    setAccessRequestsCount(
      userAccessRequestLists
        ? userAccessRequestLists.reduce((acc, {accessRequests}) => acc + accessRequests.length, 0)
        : undefined
    );
  }, [accessRequestListsLoading, userAccessRequestLists, userAccessRequestListsError]);

  // variables
  const adminRoles = AuthenticatedUser.get().getRoles().isOrgAdminForOrg(orgId)
    ? 'ORG_ADMIN'
    : undefined;

  const products = rootStore.organizationStore?.productList?.items;
  const productArrangementCodes = useMemo(
    () => products.map((product) => product.productArrangementCode).join(','),
    [products]
  );

  const contracts = rootStore.organizationStore?.contractList?.items;
  const selectedOrgContract = useMemo(
    () => contracts?.find(filterForValidTeamContract),
    [contracts]
  );

  const contextVariables = useMemo(
    () => ({
      '1ms': selectedOrgContract?.marketSegment, // this is a LIST_STRING in Sophia, but only send one value
      '1pacs': pandoraLimitationOnSophiaListString(productArrangementCodes),
      adminRoles,
      api_key: SOPHIA_CONSTANTS.CLIENT_CODE,
      comContractType: selectedOrgContract?.salesChannel,
      comMarketSegment: mapSlsToCommerceMarketSegment(selectedOrgContract?.marketSegment),
      CommonOrgID: orgId,
      contractCountry: selectedOrgContract?.ownerInfo?.countryCode,
      contractCustomerSegment: selectedOrgContract?.customerSegment,
      pendingRequests: accessRequestsCount,
      productLanguage: activeLocaleCode,
    }),
    [
      accessRequestsCount,
      adminRoles,
      activeLocaleCode,
      orgId,
      productArrangementCodes,
      selectedOrgContract?.customerSegment,
      selectedOrgContract?.marketSegment,
      selectedOrgContract?.ownerInfo?.countryCode,
      selectedOrgContract?.salesChannel,
    ]
  );

  const tnoContext = useTnoRejectProductAssignModal({
    actionableAdminReasons: [TNO_REJECT_PRODUCT_ASSIGN_MATCH_ANY_DENY_REASON],
    contentOptions: {
      context: contextVariables,
      surfaceId: TNO_REJECT_PRODUCT_ASSIGN_SURFACE_REQUESTS_ID,
    },
  });

  // returned value
  const providerValue = useMemo(
    () => ({
      contextVariables,
      error,
      isError,
      isLoading,
      orgId,
      tnoContext,
    }),
    [contextVariables, error, isError, isLoading, orgId, tnoContext]
  );

  return providerValue;
};

// eslint-disable-next-line import/prefer-default-export -- we only have one export
export {useTnoRejectProductAssignRequests};
