import {GoUrl} from '@admin-tribe/acsc-ui';
import {Flex, Text, View} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';
import {CREATE_PACKAGE_CONSTANTS} from 'features/packages/packagesConstants';

import {useCreatePackageModalContext} from '../../CreatePackageModalContext';
import CreatePackageCardCheckboxGroup from '../../create-package-card-checkbox-group/CreatePackageCardCheckboxGroup';

import PackageTypeCard from './PackageTypeCard';

// Choose Package Type Page component appears in Create Package flow to choose the package type in named package workflow
const ChoosePackageTypePage = observer(() => {
  const intl = useIntl();
  const {createPackageModalStore} = useCreatePackageModalContext();

  const NAMED_PACKAGE_TYPES_OPTIONS = [
    {benefits: 3, value: CREATE_PACKAGE_CONSTANTS.SELF_SERVE_PACKAGE},
    {benefits: 2, value: CREATE_PACKAGE_CONSTANTS.MANAGED_PACKAGE},
  ];

  /**
   * @description Method to update the package selection
   * @param {String} pkgType of the card selected
   */
  const onPackageTypeSelect = useCallback(
    (pkgType) => {
      createPackageModalStore.setPkgSessionSettingValues('packageType', pkgType);
      createPackageModalStore.setPackageCreateObjValues('packageType', pkgType);
      createPackageModalStore.setPackageCreateObjValues(
        'enableExtenstions',
        rootStore.packagesUiConstantsStore.defaultExtensionsSetting
      );

      if (!createPackageModalStore.packageCreateObj.enableExtenstions) {
        createPackageModalStore.setPkgSessionSettingValues('enableAalExtension', false);
      }
      createPackageModalStore.setPkgSessionSettingValues('selectedPlugins', []);
    },
    [createPackageModalStore]
  );

  const onChange = useCallback(
    (values) => {
      const selectedValuesLength = values.length;
      if (selectedValuesLength > 0) {
        // When another package type selected, then the package type selected is added to end of values list
        // trigger onPackageTypeSelect with the newly selected package type (as only one package type can be selected at a time)
        onPackageTypeSelect(values[selectedValuesLength - 1]);
      } else {
        createPackageModalStore.setPkgSessionSettingValues('packageType', null);
        createPackageModalStore.setPackageCreateObjValues('packageType', null);
      }
      createPackageModalStore.setPkgSessionSettingValues(
        'nextButtonEnabled',
        !!createPackageModalStore.pkgSessionSettings.packageType
      );
    },
    [createPackageModalStore, onPackageTypeSelect]
  );

  return (
    <Flex direction="column">
      <Text UNSAFE_style={{fontSize: '16px', fontWeight: 'bold'}}>
        {intl.formatMessage({id: 'packages.createPackage.packageType.modalTitle'})}
      </Text>
      <View paddingTop="size-100">
        {intl.formatMessage({id: 'packages.createPackage.packageType.description'})}
      </View>
      <Flex
        alignSelf="center"
        direction="column"
        gap="size-300"
        marginTop="size-225"
        maxWidth="100%"
      >
        <CreatePackageCardCheckboxGroup
          aria-label={intl.formatMessage({
            id: 'packages.createPackage.packageType.groupAriaLabel',
          })}
          gap="size-400"
          onChange={onChange}
          value={[createPackageModalStore.pkgSessionSettings.packageType]}
        >
          {NAMED_PACKAGE_TYPES_OPTIONS.map((pkgType, index) => (
            <PackageTypeCard
              key={pkgType.value}
              autoFocus={index === 0}
              benefits={Array.from({length: pkgType.benefits}, (_value, benefitIndex) =>
                intl.formatMessage({
                  id: `packages.createPackage.packageType.${pkgType.value}Benefit${
                    benefitIndex + 1
                  }`,
                })
              )}
              description={intl.formatMessage({
                id: `packages.createPackage.packageType.${pkgType.value}Desc`,
              })}
              isFirstIndex={index === 0}
              result={intl.formatMessage({
                id: `packages.createPackage.packageType.${pkgType.value}ResultDesc`,
              })}
              title={intl.formatMessage({
                id: `packages.createPackage.packageType.${pkgType.value}Title`,
              })}
              value={pkgType.value}
            />
          ))}
        </CreatePackageCardCheckboxGroup>
        <View width="670px">
          <Text marginEnd="size-50" UNSAFE_style={{fontWeight: 'bold'}}>
            {intl.formatMessage({id: 'packages.createPackage.packageType.tip'})}
          </Text>
          {intl.formatMessage({id: 'packages.createPackage.packageType.tipDesc'})}
          <View>
            <GoUrl name="ent_learnmore_packagetype">
              {intl.formatMessage({id: 'packages.createPackage.packageType.learnMore'})}
            </GoUrl>
          </View>
        </View>
      </Flex>
    </Flex>
  );
});

ChoosePackageTypePage.propTypes = {};

export default ChoosePackageTypePage;
