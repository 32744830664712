import {InfoBar, InfoItemScorecard} from '@admin-tribe/acsc-ui';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';
import {useLoaderData} from 'react-router-dom';

import {USER_GROUP_DETAILS_TAB_NAV} from 'features/users/routing/usersPaths';

/**
 * User group details page info bar / scorecards.
 */
const UserGroupDetailsPageInfoBar = ({adminCount, userCount}) => {
  const {tabNavAccess} = useLoaderData();
  const intl = useIntl();

  return (
    // The InfoBar component doesn't display anything if there are no InfoItems.
    <InfoBar>
      {tabNavAccess[USER_GROUP_DETAILS_TAB_NAV.USERS] && (
        <InfoItemScorecard
          key="users-assigned"
          label={intl.formatMessage({id: 'users.userGroupDetails.infoItem.usersAssigned'})}
          value={userCount}
        />
      )}
      {tabNavAccess[USER_GROUP_DETAILS_TAB_NAV.ADMINS] && (
        <InfoItemScorecard
          key="admins-assigned"
          label={intl.formatMessage({id: 'users.userGroupDetails.infoItem.adminsAssigned'})}
          value={adminCount}
        />
      )}
    </InfoBar>
  );
};

UserGroupDetailsPageInfoBar.propTypes = {
  /**
   * The count for the `Admins assigned` scorecard.
   * Will be null, which displays as en-dash, until list load completes.
   */
  adminCount: PropTypes.number,
  /**
   * The count for the `Users assigned` scorecard.
   * Will be null, which displays as en-dash, until list load completes.
   */
  userCount: PropTypes.number,
};

export default UserGroupDetailsPageInfoBar;
