import {DetailSection} from '@admin-tribe/acsc-ui';
import {Link, View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

const ProductLinksDetailSection = ({links}) => {
  const intl = useIntl();
  return (
    <DetailSection
      headingLevel={3}
      title={intl.formatMessage({id: 'products.productDetailDrawer.helpfulLinks.title'})}
    >
      {links.map((link) => (
        <View key={link.text} marginBottom="size-175">
          <Link>
            <a href={link.href} rel="noreferrer" target="_blank">
              {link.text}
            </a>
          </Link>
        </View>
      ))}
    </DetailSection>
  );
};

ProductLinksDetailSection.propTypes = {
  /**
   * Helpful Links of the particular product.
   */
  links: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * URL of the link.
       */
      href: PropTypes.string.isRequired,
      /**
       * Text of the link.
       */
      text: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default ProductLinksDetailSection;
