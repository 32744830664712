import {ViewDetailsDrawerTrigger, useStore} from '@admin-tribe/acsc-ui';
import {ModalContainer} from '@pandora/react-modal-dialog';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import CreateServerModal from 'features/packages/components/servers-tab/create-server-modal/CreateServerModal';
import {CreateServerModalContextProvider} from 'features/packages/components/servers-tab/create-server-modal/CreateServerModalContext';
import PackagesLanServerService from 'features/packages/services/PackagesLanServerService';
import PackagesServerStore from 'features/packages/stores/PackagesServerStore';

import ServerSummaryDrawer from '../server-summary-drawer/ServerSummaryDrawer';
import ReauthorizeServer from '../servers-tab/create-server-modal/screens/reauthorize-server/ReauthorizeServer';

/**
 * Renders the contents of the view detail cell in the Templates list table.
 */
const ServerSummaryButton = observer(({server}) => {
  const packagesServerStore = useStore(() => new PackagesServerStore());
  const [isReauthorizeServerDialogOpen, setIsReauthorizeServerDialogOpen] = useState(false);
  const [isEditServerOpen, setIsEditServerOpen] = useState(false);
  const intl = useIntl();

  const handleEditButtonClick = (isOpen) => {
    setIsReauthorizeServerDialogOpen(false);
    setIsEditServerOpen(isOpen);
  };
  return (
    <>
      <ViewDetailsDrawerTrigger
        aria-label={intl.formatMessage(
          {
            id: 'packages.servers.removeServers.viewDetails.label',
          },
          {serverName: server.name}
        )}
        offsetTop="var(--spectrum-global-dimension-size-700)"
        tooltipText={intl.formatMessage(
          {
            id: 'packages.servers.removeServers.viewDetails.label',
          },
          {serverName: server.name}
        )}
      >
        {(close) => (
          <ServerSummaryDrawer
            closeDrawerCallback={close}
            editServerCallback={() => handleEditButtonClick(true)}
            handleOnReauthorizeButtonClick={() => setIsReauthorizeServerDialogOpen(true)}
            server={server}
          />
        )}
      </ViewDetailsDrawerTrigger>
      <ModalContainer>
        {isReauthorizeServerDialogOpen && (
          <ReauthorizeServer
            onCancel={() => setIsReauthorizeServerDialogOpen(false)}
            serverObject={server}
          />
        )}
        {isEditServerOpen && (
          <CreateServerModalContextProvider packagesServerStore={packagesServerStore}>
            <CreateServerModal
              data-testid="edit-server-modal"
              isEditWorkflow
              onCancel={() => setIsEditServerOpen(false)}
              serverObject={server}
            />
          </CreateServerModalContextProvider>
        )}
      </ModalContainer>
    </>
  );
});

ServerSummaryButton.propTypes = {
  /**
   * Content to be shown in Server Drawer Section section
   */
  server: PropTypes.instanceOf(PackagesLanServerService).isRequired,
};

export default ServerSummaryButton;
