import {
  DirectoryUserList,
  DirectoryUserListCount,
  PAGE_TARGET,
  PAGE_TARGET_TYPE,
  PageContext,
  feature,
} from '@admin-tribe/acsc';
import {
  InfoItemScorecard,
  Page,
  PageBreadcrumbs,
  PageContent,
  PageHeader,
  PageInfoBar,
  PageNav,
  useStore,
} from '@admin-tribe/acsc-ui';
import {Item} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import {useLoaderData, useParams} from 'react-router-dom';

import UserListTableSection from 'common/components/user-list-table-section/UserListTableSection';
import UserOperations from 'common/entities/user-operations/UserOperations';
import {MemberListContextProvider} from 'common/hooks/member-list-context/MemberListContext';
import useDocumentTitle from 'common/hooks/useDocumentTitle';
import UserStore from 'common/stores/user-store/UserStore';
import {canRemoveUser} from 'core/user/access/userAccess';
import {goToDirectoryList} from 'features/users/routing/navigation-callbacks/navigationCallbacks';

/**
 * The page which shows the users for an individual directory.
 */
const DirectoryUsersPage = observer(() => {
  const {directoryId} = useParams();
  const {directory} = useLoaderData();
  const intl = useIntl();
  const directoryName = directory.name;

  const [loadingMessage, setLoadingMessage] = useState();
  const canRemoveMember = canRemoveUser();

  const syncIconActionButtonTooltip = feature.isEnabled(
    'temp_domains_externally_managed_account_label'
  )
    ? intl.formatMessage({id: 'settings.domainsTable.externallyManagedByAccountLabel'})
    : intl.formatMessage({id: 'settings.domainsTable.externallyManagedLabel'});

  const userStore = useStore(
    () =>
      new UserStore({
        immediateLoad: true,
        listClassRef: DirectoryUserList,
        listOptions: {
          directoryId,
        },
        refreshUserCountRef: DirectoryUserListCount,
      })
  );

  const pageContext = useRef(
    new PageContext({
      target: PAGE_TARGET.DIRECTORY,
      targetId: directoryId,
      targetType: PAGE_TARGET_TYPE.USER,
    })
  ).current;

  const userOperations = UserOperations.getDirectoryUserOperations({
    canRemove: canRemoveMember,
    directoryId,
  });

  // If we're in searchOnlyContentMode, display a string with the wait spinner.
  useEffect(() => {
    if (userStore.searchOnlyContentMode) {
      // @cframpto - this message should be set on UserListTableSection OverlayWait
      setLoadingMessage(intl.formatMessage({id: 'users.users.searchForUsers.loadingMessage'}));
    }
  }, [intl, userStore.searchOnlyContentMode]);

  useDocumentTitle({defaultTitle: directoryName});

  return (
    <Page
      data-testid="directory-users-page"
      isLoading={userStore.isLoading && !userStore.currentItems}
      loadingMessage={loadingMessage}
    >
      <PageBreadcrumbs isDisabled={false} onAction={goToDirectoryList}>
        <Item key="directory-list">
          {intl.formatMessage({id: 'users.ownedDirectoryListPage.title'})}
        </Item>
        <Item key="directory-user">{directoryName}</Item>
      </PageBreadcrumbs>
      <PageHeader title={directoryName} />
      <PageInfoBar>
        <InfoItemScorecard
          label={intl.formatMessage({id: 'users.directoryUsers.scorecard.users'})}
          value={userStore.scorecardValue}
        />
      </PageInfoBar>
      <PageNav />
      <PageContent>
        <MemberListContextProvider
          canAddMember={false}
          canRemoveMember={canRemoveMember}
          loadingMessage={loadingMessage}
          memberListDisplay={{
            accountStatus: true,
            email: true,
            userName: true,
            viewDetails: true,
          }}
          pageContext={pageContext}
          store={userStore}
          syncIconActionButtonTooltipText={syncIconActionButtonTooltip}
          userOperations={userOperations}
        >
          <UserListTableSection />
        </MemberListContextProvider>
      </PageContent>
    </Page>
  );
});

export default DirectoryUsersPage;
