import {
  AuthenticatedUser,
  FeaturesCache,
  Locale,
  authentication,
  feature,
  log,
} from '@admin-tribe/acsc';
import {
  AnalyticsAdapter,
  addPandoraContentModel,
  getRootPandoraContentModel,
  getRootPandoraContentValue,
} from '@admin-tribe/acsc-ui';
import 'cross-fetch';
import {AnalyticsProvider as PandoraAnalyticsProvider} from '@pandora/react-analytics-provider';
import {ApiContextProvider} from '@pandora/react-api-context-provider';
import {AuthProvider} from '@pandora/react-auth-provider';
import {ContentEntryProvider} from '@pandora/react-content-provider';
import {DataLayerClientProvider} from '@pandora/react-data-layer-client-provider';
import {EnvProvider} from '@pandora/react-env-provider';
import {FeatureFlagProvider} from '@pandora/react-feature-provider';
import {FetchProvider} from '@pandora/react-fetch-provider';
import {LoggerProvider} from '@pandora/react-logger-provider';
import {TnoRejectProductAssignModalProvider} from '@pandora/react-tno-reject-product-assign-modal';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';
import {RouterProvider} from 'react-router-dom';

import API_CONSTANTS from 'common/api/ApiConstants';
import rootStore from 'core/RootStore';
import {AnalyticsProvider} from 'core/analytics/AnalyticsProvider';
import ErrorBoundary from 'core/errorComponents/ErrorBoundary';
import useGainsightInit from 'core/gainsight/useGainsightInit';
import {AppLoadingOverlayProvider} from 'core/providers/AppLoadingOverlayProvider';
import {
  AppInitializationStateProvider,
  useAppInitializationState,
} from 'core/providers/appInitializationState/AppInitializationStateProvider';
import {AuthenticationProvider} from 'core/providers/authentication/AuthenticationProvider';
import {ConfigurationProvider} from 'core/providers/configuration/ConfigurationProvider';
import {FeatureProvider} from 'core/providers/features/FeatureProvider';
import {LocaleProvider, useLocale} from 'core/providers/locale/LocaleProvider';
import {OrganizationsProvider} from 'core/providers/organization/OrganizationProvider';
import OrganizationSwitcherLoader from 'core/providers/organization/OrganizationSwitcherLoader';
import buildRouter from 'core/router/buildRouter';
import {ChatStateProvider} from 'core/services/chat/ready/ChatStateProvider';
import pandoraContentModel from 'core/services/pandoraContentModel';
import SpectrumProviderWrapper from 'core/spectrumProviderWrapper/SpectrumProviderWrapper';
import GlobalHeader from 'shell/components/global-header/GlobalHeader';
import HeaderFooterWrapper from 'shell/components/header-footer-wrapper/HeaderFooterWrapper';

addPandoraContentModel(pandoraContentModel);

const JIL_API_REGEXP = /^https:\/\/bps[\w-]+\.adobe\.io\/jil-api\//;

const pandoraRootContentModel = getRootPandoraContentModel('OnesieContentModel');

/** The main entrypoint for AAC */
const AppShell = observer(() => {
  const {appInitialized} = useAppInitializationState();

  if (!appInitialized) {
    return null;
  }

  const router = buildRouter();

  return (
    <AppProviders>
      {/* When all Angular UI is gone then the GlobalHeader can move back into Shell.jsx */}
      <HeaderFooterWrapper>
        <GlobalHeader />
      </HeaderFooterWrapper>
      <RouterProvider router={router} />
    </AppProviders>
  );
});

const App = observer(() => (
  <ErrorBoundary>
    <ConfigurationProvider>
      <LocaleProvider>
        <SpectrumProviderWrapper>
          <AppLoadingOverlayProvider>
            <AuthenticationProvider>
              <FeatureProvider>
                <DataLayerClientProvider>
                  <OrganizationsProvider>
                    <OrganizationSwitcherLoader>
                      <ChatStateProvider>
                        <AnalyticsProvider>
                          <AppInitializationStateProvider>
                            <AppShell />
                          </AppInitializationStateProvider>
                        </AnalyticsProvider>
                      </ChatStateProvider>
                    </OrganizationSwitcherLoader>
                  </OrganizationsProvider>
                </DataLayerClientProvider>
              </FeatureProvider>
            </AuthenticationProvider>
          </AppLoadingOverlayProvider>
        </SpectrumProviderWrapper>
      </LocaleProvider>
    </ConfigurationProvider>
  </ErrorBoundary>
));

App.displayName = 'App';

const AppProviders = observer(({children}) => {
  const spectrumLocale = Locale.get().activeLocaleForSpectrum;
  const locale = Locale.get().activeLocaleCode;
  const {localeStrings} = useLocale();
  const features = FeaturesCache.get()?.features || [];

  useGainsightInit();

  const authData = {
    getAccessToken: () => authentication.getAccessToken()?.token,
    getUserProfile: () => AuthenticatedUser.get().profile,
  };

  const customFetch = (path, options) => {
    const customHeaders =
      // This check against the JIL endpoint is needed since at least
      // one other endpoint (MILO) does not currently allow "Accept-Language" past its whitelist,
      // and JIL does not work with the latest browser "Accept-Language" locale-strings (like es-419)
      JIL_API_REGEXP.test(path)
        ? {
            'Accept-Language': locale,
            'X-Api-Key': authentication.getClientId(),
          }
        : {'X-Api-Key': authentication.getClientId()};
    const mergedOptions = {
      ...options,
      headers: {
        ...customHeaders,
        ...options.headers,
      },
    };

    // By default the built-in window fetch will be used. For browsers that do not support this,
    // cross-fetch fetch will be used as a polyfill. Polyfills are defined by not explicitly
    // importing the default like seen above (import 'cross-fetch';). We can not use Pandora fetch
    // due to it using cross-fetch as a ponyfill rather than a polyfill, which we are not able to
    // correctly mock for our fakes environment.
    return fetch(path, mergedOptions);
  };

  if (feature.isEnabled('temp_tno_rejectproductassign_modal')) {
    return (
      <EnvProvider value={authentication.getAppEnv()}>
        <LoggerProvider value={log}>
          <AuthProvider value={authData}>
            <FetchProvider value={customFetch}>
              <FeatureFlagProvider value={features}>
                <ApiContextProvider
                  value={{
                    clientId: API_CONSTANTS.CLIENT_ID,
                    orgId: rootStore.organizationStore.activeOrgId,
                  }}
                >
                  <ContentEntryProvider
                    locale={spectrumLocale}
                    model={pandoraRootContentModel}
                    value={getRootPandoraContentValue(localeStrings)}
                  >
                    <PandoraAnalyticsProvider value={AnalyticsAdapter}>
                      <TnoRejectProductAssignModalProvider>
                        {children}
                      </TnoRejectProductAssignModalProvider>
                    </PandoraAnalyticsProvider>
                  </ContentEntryProvider>
                </ApiContextProvider>
              </FeatureFlagProvider>
            </FetchProvider>
          </AuthProvider>
        </LoggerProvider>
      </EnvProvider>
    );
  }

  return (
    <EnvProvider value={authentication.getAppEnv()}>
      <LoggerProvider value={log}>
        <AuthProvider value={authData}>
          <FetchProvider value={customFetch}>
            <FeatureFlagProvider value={features}>
              <ApiContextProvider
                value={{
                  clientId: API_CONSTANTS.CLIENT_ID,
                  orgId: rootStore.organizationStore.activeOrgId,
                }}
              >
                <ContentEntryProvider
                  locale={spectrumLocale}
                  model={pandoraRootContentModel}
                  value={getRootPandoraContentValue(localeStrings)}
                >
                  <PandoraAnalyticsProvider value={AnalyticsAdapter}>
                    {children}
                  </PandoraAnalyticsProvider>
                </ContentEntryProvider>
              </ApiContextProvider>
            </FeatureFlagProvider>
          </FetchProvider>
        </AuthProvider>
      </LoggerProvider>
    </EnvProvider>
  );
});

AppProviders.displayName = 'AppProviders';

AppProviders.propTypes = {
  /** The children for the providers */
  children: PropTypes.node,
};

export default App;
