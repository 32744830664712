import {EVENT_ACTION, configStore, dispatchUiEventAnalytics} from '@admin-tribe/acsc';
import AddCircleIcon from '@spectrum-icons/workflow/AddCircle';
import BoxAddIcon from '@spectrum-icons/workflow/BoxAdd';
import LinkOutIcon from '@spectrum-icons/workflow/LinkOut';
import ShoppingCartIcon from '@spectrum-icons/workflow/ShoppingCart';
import UserAddIcon from '@spectrum-icons/workflow/UserAdd';
import React from 'react';

import {PRODUCT_TABLE_ACTION_VARIANT} from 'common/services/product-summary-list/ProductSummaryListConstants';
import {formatLinkOutUrl} from 'common/utils/product-link-utils/productLinkUtils';
import rootStore from 'core/RootStore';
import trialHelper from 'core/products/trial-helper/trialHelper';
import {goToAllPackages} from 'features/packages/routing/navigation-callbacks/navigationCallbacks';

/**
 *
 * @param {Object} options - Top level wrapper object.
 * @param {Intl} options.intl - Intl object for translating the labels if
 *     necessary.
 * @param {Function} options.onModalOpenCallback - Callback to invoke when the
 *     button for add users is pressed.
 * @param {Product} options.product - Product whose properties will be used to
 *     generate the action content.
 * @param {String} options.variant - String indicating which button variant is
 *     being used.
 * @returns {Object} Object containing the properties needed to populdate a
 *     ProductTableActionButton or Items of the ProductTableActionMenu.
 */
function getProductTableActionConfig({
  intl,
  model,
  onAddProductsModalOpen,
  onAddUsersModalOpen,
  variant,
}) {
  let goToDeveloperConsoleUrl, productLink;

  // Optional chaining is not needed because the properties were checked in productSummaryListUtils already.
  if (variant === PRODUCT_TABLE_ACTION_VARIANT.GO_TO_DEVELOPER_CONSOLE) {
    const adobeioConsoleConfig = configStore.get('services.adobeioConsole');
    goToDeveloperConsoleUrl = adobeioConsoleConfig.url;
  }
  if (variant === PRODUCT_TABLE_ACTION_VARIANT.LINK_OUT) {
    productLink = {
      href: formatLinkOutUrl(model.links.product_admin[0].href),
      text: model.links.product_admin[0].text,
    };
  }

  switch (variant) {
    case PRODUCT_TABLE_ACTION_VARIANT.ADD_USERS:
      return {
        icon: <UserAddIcon size="S" />,
        label: intl.formatMessage({
          id: 'products.allProducts.actions.ariaLabel.assignUsersToProduct',
        }),
        onPress: () => onAddUsersModalOpen(),
      };
    case PRODUCT_TABLE_ACTION_VARIANT.BUY_LICENSES:
      return {
        icon: <ShoppingCartIcon size="S" />,
        label: intl.formatMessage({
          id: 'products.allProducts.actions.ariaLabel.buyLicenses',
        }),
        onPress: () => onAddProductsModalOpen(),
      };
    case PRODUCT_TABLE_ACTION_VARIANT.BUY_PRODUCT:
      return {
        icon: <ShoppingCartIcon size="S" />,
        label: intl.formatMessage({
          id: 'products.allProducts.actions.ariaLabel.buyNow',
        }),
        onPress: () => trialHelper.openBuyNowUrl(model.id),
      };
    case PRODUCT_TABLE_ACTION_VARIANT.GO_TO_DEVELOPER_CONSOLE:
      return {
        icon: <LinkOutIcon size="S" />,
        label: intl.formatMessage({
          id: 'products.allProducts.actions.ariaLabel.goToDeveloperConsole',
        }),
        onPress: () => {
          // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- In browser
          window.open(`${goToDeveloperConsoleUrl}/integrations`, '_blank', 'noopener,noreferrer');
        },
      };
    case PRODUCT_TABLE_ACTION_VARIANT.GO_TO_PACKAGES:
      return {
        icon: <BoxAddIcon size="S" />,
        label: intl.formatMessage({
          id: 'products.allProducts.actions.ariaLabel.goToPackages',
        }),
        onPress: () => goToAllPackages(),
      };
    case PRODUCT_TABLE_ACTION_VARIANT.LINK_OUT:
      return {
        icon: <LinkOutIcon size="S" />,
        label: productLink.text,
        onPress: () => {
          // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- In browser
          window.open(productLink.href, '_blank', 'noopener,noreferrer');
        },
      };
    default:
      return {
        icon: <AddCircleIcon />,
        label: intl.formatMessage({
          id: 'products.allProducts.actions.ariaLabel.setUpSdl',
        }),
        onPress: () => rootStore.helpStore.goToTopic('get-started.products.manage-sdl#sdl-set-up'),
      };
  }
}

/**
 * Sends analytics when the add license button is opened.
 */
const sendAddLicenseOpenAnalytics = () => {
  dispatchUiEventAnalytics({
    eventAction: EVENT_ACTION.CLICK,
    eventName: 'ProductBuyCTA',
    interaction: {
      impression: 'ProductOverviewAddAssignExperience',
    },
  });
};

/**
 * Sends analytics when the add license button is closed.
 */
const sendAddLicenseCloseAnalytics = () => {
  dispatchUiEventAnalytics({
    eventAction: EVENT_ACTION.CLOSE,
    eventName: 'ProductBuyCTA',
    interaction: {
      impression: 'ProductOverviewAddAssignExperience',
    },
  });
};

/**
 * Sends analytics when the add license button is closed.
 * @param {Object} data - Data to send to the analytics event.
 */
const sendAddLicenseSuccessAnalytics = (data) => {
  const event = {
    eventAction: EVENT_ACTION.SUCCESS,
    eventName: 'ProductOverviewAddAssignExperience:Buy:PlaceOrder:Click',
    interaction: {
      impression: 'ProductOverviewAddAssignExperience',
    },
    productList: {
      items: data?.products?.map((product) => ({
        licenseCount: product.quantity,
        offer_id: product.offerId,
        productName: product.name,
      })),
    },
  };
  dispatchUiEventAnalytics(event);
};

export {
  getProductTableActionConfig,
  sendAddLicenseOpenAnalytics,
  sendAddLicenseCloseAnalytics,
  sendAddLicenseSuccessAnalytics,
};
