import {
  ACTIONBLOCK_ID,
  BANNER_RESPONSE_KEYS,
  CAMPAIGN_ID,
  CLICKABLE_CARD_LABELS,
  CONTAINER_ID,
  CONTROL_GROUP_ID,
  SURFACE_ID,
  TREATMENT_ID,
  VARIATION_ID,
} from '@admin-tribe/acsc';
import has from 'lodash/has';
import omitBy from 'lodash/omitBy';

import SOPHIA_CONSTANTS from './SophiaConstants';
import {transformCard} from './sophiaCardUtils';

/**
 * @description Logical representation of a sophia card encapsulating analytics
 * interactions.
 *
 * reference wiki for parameters consumed from opts.card -
 * https://git.corp.adobe.com/admin-tribe/onesie/wiki/Property-Mapping-from-CCX-card-to-Admin-Console's-Sophia-Card
 */
class SophiaCard {
  constructor(opts) {
    Object.assign(this, {
      card: transformCard(opts.card),
      cardControlAnalyticsParams: opts.cardControlAnalyticsParams,
      containerAnalyticsParams: opts.containerAnalyticsParams,
      containerId: opts.containerId,
      surfaceId: opts.surfaceId,
    });
  }

  /**
   * @description filters the analytics values from the SophiaCard
   * @returns {Array} object containing a subset of values from API response object
   */
  getAnalyticsParams() {
    return omitBy(
      {
        actionBlockId: this.containerAnalyticsParams?.[ACTIONBLOCK_ID],
        campaignId: this.containerAnalyticsParams?.[CAMPAIGN_ID],
        cardId: this.card?.[BANNER_RESPONSE_KEYS.CARD_ID],
        containerId: this[CONTAINER_ID],
        controlGroupId: this.containerAnalyticsParams?.[CONTROL_GROUP_ID],
        ctaLabel: this.card?.[CLICKABLE_CARD_LABELS.PRIMARY],
        secondaryCTALabel: this.card?.[CLICKABLE_CARD_LABELS.SECONDARY],
        surfaceId: this[SURFACE_ID],
        treatmentId: this.containerAnalyticsParams?.[TREATMENT_ID],
        variationId: this.containerAnalyticsParams?.[VARIATION_ID],
      },
      (item) => item === undefined
    );
  }

  getCampaignId() {
    return this.containerAnalyticsParams?.campaignId;
  }

  /**
   * @description Returns the renderable properties of a card (omitting analytics info)
   * @returns {Object} renderable properties for displaying a card.
   */
  getCard() {
    return this.card;
  }

  getCardId() {
    return this.card?.cardID;
  }

  /**
   * Constructs an impression string based on the buttons available on the Sophia card
   * @returns {String} The impression string that represents this card in analytics events
   */
  getImpressions() {
    const {ANALYTICS_ID} = SOPHIA_CONSTANTS;
    const impressions = [];

    if (this.card?.[CLICKABLE_CARD_LABELS.PRIMARY]) {
      impressions.push(`${this.card.ctaLabel}|${ANALYTICS_ID}`);
    }

    if (this.card?.[CLICKABLE_CARD_LABELS.SECONDARY]) {
      impressions.push(`${this.card.secondaryCTALabel}|${ANALYTICS_ID}`);
    }

    return impressions.length > 0 ? impressions.join(',') : undefined;
  }

  getSessionStorageCardId() {
    return `${this.getCampaignId()}_${this.getCardId()}`;
  }

  isCardValid() {
    const renderableProperties = this.getCard();
    return SOPHIA_CONSTANTS.SOPHIA_REQUIRED_PARAMS.every((param) =>
      has(renderableProperties, param)
    );
  }
}

export default SophiaCard;
