// ***********************************************************************
// ADOBE CONFIDENTIAL
// ___________________
//
// Copyright 2024 Adobe
// All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains
// the property of Adobe and its suppliers, if any. The intellectual
// and technical concepts contained herein are proprietary to Adobe
// and its suppliers and are protected by all applicable intellectual
// property laws, including trade secret and copyright laws.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Adobe.
// ************************************************************************
import {feature} from '@admin-tribe/acsc';
import {Flex, View} from '@adobe/react-spectrum';
import {DividerPlacement, TitledSection} from '@pandora/react-titled-section';
import {useId} from '@react-aria/utils';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';

import SophiaPromoRightRailView from 'common/components/sophia/SophiaPromoRightRailView';
import SOPHIA_CONSTANTS from 'common/services/sophia/SophiaConstants';
import sophiaHelper from 'common/services/sophia/sophiaHelper';
import {mapPromoContent} from 'common/services/sophia/utils/utils';
import OverviewPod from 'features/overview/shell/overview-pod/OverviewPod';

const SophiaRightRailMerchandisingSection = ({isHidden}) => {
  const [sophiaContent, setSophiaContent] = React.useState(null);
  const hideContent = {...{base: true, L: true, M: true, S: true, XS: true}, ...isHidden};
  const [isDismissed, setIsDismissed] = React.useState(false);
  const surfaceId = SOPHIA_CONSTANTS.SURFACE_ID.ONE_CONSOLE_MERCHANDISING;
  const intl = useIntl();
  const headingId = useId();

  // Fetches sophia cards on mount
  useEffect(() => {
    const fetchSophiaContent = async () => {
      const rawContent = await sophiaHelper.getSophiaContent({surfaceId});
      setSophiaContent(rawContent);
    };

    fetchSophiaContent();
  }, [surfaceId]);

  // render nothing if there are no cards, or the list fails to fetch
  if (!sophiaContent?.items?.[0]?.content) {
    return null;
  }

  const content = mapPromoContent(JSON.parse(sophiaContent.items[0].content));
  const dismissView = content.dismissible ? () => setIsDismissed(false) : null;

  const useAemForSectionTitle =
    feature.isEnabled('temp_tno_promo_panel_aem_section_title') && !!content.sectionTitle;
  const sectionTitle = useAemForSectionTitle
    ? content.sectionTitle
    : intl.formatMessage({id: 'overview.promotions.pod.title'});

  return (
    <View isHidden={isDismissed}>
      <OverviewPod data-testid="sophia-right-rail-merchandising-section" isHidden={hideContent}>
        <TitledSection
          dividerPlacement={DividerPlacement.NONE}
          headingLevel={2}
          id={headingId}
          title={sectionTitle}
        >
          <Flex
            alignItems="baseline"
            data-testid="sophia-right-rail-merchandising-space"
            gap="size-300"
            marginTop="size-150"
            wrap
          >
            <SophiaPromoRightRailView onDismiss={dismissView} sophiaContent={content} />
          </Flex>
        </TitledSection>
      </OverviewPod>
    </View>
  );
};

SophiaRightRailMerchandisingSection.propTypes = {
  /**
   * Responsive props instructing merchandising section how to show/hide at
   * various display sizes. Optional, default is to show all the time
   */
  isHidden: PropTypes.shape({
    base: PropTypes.bool,
    L: PropTypes.bool,
    M: PropTypes.bool,
    S: PropTypes.bool,
  }),
};

export default SophiaRightRailMerchandisingSection;
