import {
  Page,
  PageActions,
  PageBanners,
  PageContent,
  PageHeader,
  PageNav,
} from '@admin-tribe/acsc-ui';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';

import SophiaBanner from 'common/components/sophia/banner/SophiaBanner';
import SOPHIA_CONSTANTS from 'common/services/sophia/SophiaConstants';
import rootStore from 'core/RootStore';

import PackagesEntitlementsService from '../../services/PackagesEntitlementsService';
import PackagesPreferencesService from '../../services/PackagesPreferencesService';

import AusstOverrideSection from './ausst-override-section/AusstOverrideSection';
import GenuineServiceSection from './genuine-service-section/GenuineServiceSection';
import InstallDirectorySection from './install-directory-section/InstallDirectorySection';
import NotificationsSection from './notifications-section/NotificationsSection';
import PreferencesButtonGroup from './preferences-button-group/PreferencesButtonGroup';
import {goBack, isMacPathValid, isWinPathValid} from './preferencesPageUtils';

/**
 * Defines the preferences page under the packages tab.
 */
const PreferencesPage = observer(
  // eslint-disable-next-line complexity -- 2 over max
  ({
    uiConstantsStore = rootStore.packagesUiConstantsStore,
    organizationStore = rootStore.organizationStore,
  }) => {
    const intl = useIntl();

    const [isLoading, setIsLoading] = useState(
      !PackagesPreferencesService.preferences ||
        !PackagesEntitlementsService.hasFetchedAllEntitlements ||
        !uiConstantsStore.uiConstantsMap ||
        !Object.keys(uiConstantsStore.uiConstantsMap)
    );
    const [preferences, setPreferences] = useState(
      PackagesPreferencesService.transformedPreferences
    );
    const [orgPreferences, setOrgPreferences] = useState(
      PackagesPreferencesService.transformedOrgPreferences
    );
    const [showNotificationToggle, setShowNotificationToggle] = useState(
      PackagesEntitlementsService.hasCcEntitlement
    );

    const showGenuineServiceSection = organizationStore.activeOrg.marketSegment !== 'GOVERNMENT';

    const [showInstallDir, setShowInstallDir] = useState(
      PackagesEntitlementsService.hasCcEntitlement ||
        PackagesEntitlementsService.hasSubstanceEntitlement ||
        PackagesEntitlementsService.hasIndesignServerEntitlement
    );
    const [servicesLoadingError, setServicesLoadingError] = useState(false);

    // refs
    const notificationCheckBoxRef = useRef(null);
    const ausstOverrideWindowsTextAreaRef = useRef(null);
    const genuineServiceChecBoxRef = useRef(null);

    // Returns the reference to the element to focus on after dicard btn clicked(and disabled)
    function getPreviousBtnDisableNextRef() {
      if (showNotificationToggle) {
        return notificationCheckBoxRef;
      }
      if (showGenuineServiceSection) {
        return genuineServiceChecBoxRef;
      }
      return ausstOverrideWindowsTextAreaRef;
    }

    // Fetch all the required data before loading the page
    useEffect(() => {
      let isMounted = true;
      fetchData();

      // Fetch in this order:
      // 1. uiConstantsStore
      // 2. PackagesPreferencesService
      // 3. PackagesEntitlementsService
      async function fetchData() {
        let loadingError = false;
        setIsLoading(true);
        await uiConstantsStore.fetchUiConstants();
        try {
          await PackagesPreferencesService.fetchUserPreferences();
          await PackagesPreferencesService.fetchOrgPreferences();
          await PackagesEntitlementsService.fetchEntitlements();
          onDataFetch();
        } catch (error) {
          loadingError = true;
        } finally {
          if (isMounted) {
            setServicesLoadingError(loadingError);
            setIsLoading(false);
          }
        }
      }

      // Upon fetching data, set states accordingly to show relevant sections of page
      function onDataFetch() {
        if (isMounted) {
          setShowNotificationToggle(PackagesEntitlementsService.hasCcEntitlement);
          setShowInstallDir(
            PackagesEntitlementsService.hasCcEntitlement ||
              PackagesEntitlementsService.hasSubstanceEntitlement ||
              PackagesEntitlementsService.hasIndesignServerEntitlement
          );
          setPreferences(PackagesPreferencesService.transformedPreferences);
          setOrgPreferences(PackagesPreferencesService.orgPreferences);
        }
      }
      return () => {
        isMounted = false;
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps -- only run on mount
    }, []);

    return (
      <Page
        bumperCtaButtonLabel={intl.formatMessage({id: 'packages.preferences.bumper.goBack'})}
        isBumpered={uiConstantsStore.hasLoadingError || servicesLoadingError}
        isLoading={isLoading}
        onClickBumper={goBack}
      >
        <PageBanners>
          <SophiaBanner surfaceId={SOPHIA_CONSTANTS.SURFACE_ID.ONE_CONSOLE_PACKAGES} />
        </PageBanners>
        <PageHeader
          title={intl.formatMessage({
            id: 'packages.preferences.title',
          })}
        />

        <PageActions>
          <PreferencesButtonGroup
            orgPreferences={orgPreferences}
            pathsAreValid={
              isWinPathValid(preferences.userInstallDirectoryWin) &&
              isMacPathValid(preferences.userInstallDirectoryMac)
            }
            preferences={preferences}
            previousBtnDisableNextRef={getPreviousBtnDisableNextRef()}
            setOrgPreferences={setOrgPreferences}
            setPreferences={setPreferences}
          />
        </PageActions>
        <PageNav />

        <PageContent>
          {/* Notifications */}
          {showNotificationToggle && (
            <NotificationsSection
              notificationCheckBoxRef={notificationCheckBoxRef}
              preferences={preferences}
              setPreferences={setPreferences}
            />
          )}
          {/* Adobe Genuine Service Section (Go Cart consent workflow) */}
          {/* If notification section is shown then, divider should be displayed*/}
          {showGenuineServiceSection && (
            <GenuineServiceSection
              genuineServiceChecBoxRef={genuineServiceChecBoxRef}
              preferences={orgPreferences}
              setPreferences={setOrgPreferences}
              showDivider={showNotificationToggle}
            />
          )}

          {/* AUSST Override */}
          {/* If notification section or genuine service section are shown then, divider should be displayed*/}
          <AusstOverrideSection
            ausstOverrideWindowsTextAreaRef={ausstOverrideWindowsTextAreaRef}
            preferences={preferences}
            setPreferences={setPreferences}
            showDivider={showNotificationToggle || showGenuineServiceSection}
          />
          {/* Install Directory */}
          {showInstallDir && (
            <InstallDirectorySection paths={preferences} setPaths={setPreferences} />
          )}
        </PageContent>
      </Page>
    );
  }
);

export default PreferencesPage;
