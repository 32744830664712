import {GoUrl} from '@admin-tribe/acsc-ui';
import {Button, Flex, Text} from '@adobe/react-spectrum';
import {DrawerTrigger} from '@pandora/react-drawer';
import {ModalContainer} from '@pandora/react-modal-dialog';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import ServerSetupGuideDrawer from 'features/packages/components/server-setup-guide-drawer/ServerSetupGuideDrawer';
import CreateServerButton from 'features/packages/components/servers-tab/create-server-modal/CreateServerButton';
import {useServerPageContext} from 'features/packages/pages/servers-page/ServerPageContext';

import styles from '../ServersPage.pcss';
import CreateServerModal from '../create-server-modal/CreateServerModal';
import {CreateServerModalContextProvider} from '../create-server-modal/CreateServerModalContext';

const FirstUserExperience = () => {
  const intl = useIntl();
  const [isCreateServerModalOpen, setIsCreateServerModalOpen] = useState(false);
  const {packagesServerStore} = useServerPageContext();

  return (
    <Flex
      data-testid="first-user-experience"
      direction="column"
      // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- external css to match
      UNSAFE_className={styles['servers-fue']}
    >
      {/* eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- external css to match */}
      <Flex UNSAFE_className={styles['servers-fue-image']} />
      {/* eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- external css to match */}
      <Text UNSAFE_className={styles['fue-title']}>
        {intl.formatMessage({id: 'packages.servers.firstUserExperience.title'})}
      </Text>
      {/* eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- external css to match */}
      <Text UNSAFE_className={styles['fue-desc']}>
        {intl.formatMessage({id: 'packages.servers.firstUserExperience.description'})}
      </Text>
      <GoUrl name="ent_learn_FRL_LAN">
        {intl.formatMessage({
          id: 'packages.servers.firstUserExperience.learnMore',
        })}
      </GoUrl>
      {/* eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- external css to match */}
      <Flex direction="row" justifyContent="center" UNSAFE_className={styles['fue-btn-grp']}>
        <DrawerTrigger offsetTop="var(--spectrum-global-dimension-size-700)">
          <Button variant="primary">
            {intl.formatMessage({id: 'packages.servers.removeServers.setupGuideButton'})}
          </Button>
          {(close) => (
            <ServerSetupGuideDrawer
              closeDrawer={close}
              onCreateServerButtonClick={() => setIsCreateServerModalOpen(true)}
            />
          )}
        </DrawerTrigger>
        <CreateServerButton
          buttonLabel={intl.formatMessage({
            id: 'packages.servers.removeServers.createServerButton',
          })}
          variant="accent"
        />
        <ModalContainer>
          {isCreateServerModalOpen && (
            <CreateServerModalContextProvider packagesServerStore={packagesServerStore}>
              <CreateServerModal
                data-testid="create-server-modal"
                onCancel={() => setIsCreateServerModalOpen(false)}
              />
            </CreateServerModalContextProvider>
          )}
        </ModalContainer>
      </Flex>
    </Flex>
  );
};
FirstUserExperience.displayName = 'FirstUserExperience';

export default FirstUserExperience;
