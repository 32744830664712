import {showSuccess as showSuccessToast} from '@admin-tribe/acsc-ui';
import {useEffect} from 'react';
import {useIntl} from 'react-intl';

/**
 * Display success message in case of product approval via email
 */
const ProductApprovalSuccessMessage = () => {
  const intl = useIntl();

  /**
   * Check value of request_access_approval_result to display product approval success message.
   * Using setTimeOut to avoid removal of the item 'request_access_approval_result' before the
   * toast is displayed due to re-rendering of the page.
   */
  useEffect(() => {
    const displayProductRequestApproval = sessionStorage.getItem('request_access_approval_result');

    function showToastForMilliseconds(stringKey, timeout) {
      showSuccessToast(intl.formatMessage({id: stringKey}));
      setTimeout(() => {
        sessionStorage.removeItem('request_access_approval_result');
      }, timeout);
    }

    if (displayProductRequestApproval === 'success') {
      showToastForMilliseconds('common.requestAccess.requestApproved', 1000);
    } else if (displayProductRequestApproval === 'previously_approved') {
      showToastForMilliseconds('common.requestAccess.requestPreviouslyApproved', 1000);
    }
  }, [intl]);
  return null;
};

export default ProductApprovalSuccessMessage;
