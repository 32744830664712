import {
  ORG_ADMIN,
  hasDirectContract,
  hasEnterpriseContract,
  hasIndirectContract,
} from '@admin-tribe/acsc';

import rootStore from 'core/RootStore';
import auth from 'core/services/auth';

/**
 * @description Determines if the currently logged-in user has access to view
 *   auto assigned product help content or not.
 * @returns {Boolean} true if has access, else false
 */
function hasAutoAssignedProductAccess() {
  // to check whether logged in user is admin or not
  return auth.isUserOrgAdmin();
}

/**
 * Determines if the current logged-in user has access to create packages.
 * @returns {Boolean} true if logged in user has create packages access
 */
function hasCreatePackagesAccess() {
  // to check whether logged in user is admin or not
  const isUserOrgAdmin = auth.isUserOrgAdmin();
  // to check whether logged in user is deployment admin or not
  const isUserDeploymentAdmin = auth.isUserDeploymentAdmin();
  // for getting product list
  const productList = rootStore.organizationStore.productList;

  if ((!isUserOrgAdmin && !isUserDeploymentAdmin) || !productList.items) return false;

  const isAcceptedProductType = (product) =>
    product.isCreativeCloudProduct() ||
    product.isDocumentCloudProduct() ||
    product.isOtherCloudProduct();
  return productList?.items?.some((product) => isAcceptedProductType(product));
}

/**
 * Determines if the current logged-in user has access to enterprise sign.
 * @returns {Boolean} true if logged in user has enterprise sign access
 */
function getEnterpriseSignParams() {
  // to check whether logged in user is admin or not
  const isUserOrgAdmin = auth.isUserOrgAdmin();

  // to check whether logged in user is product admin or not
  const isUserProductAdmin = auth.isUserProductAdmin();

  const {contractList, productList} = rootStore.organizationStore;

  if ((!isUserOrgAdmin && !isUserProductAdmin) || !contractList || !productList.items) {
    return {
      access: () => false,
    };
  }

  const adobeSignEnterpriseProduct = productList?.items?.find(
    (product) => product.isEnterprise() && product.includesBusinessOrEnterpriseSign()
  );
  return hasEnterpriseContract(contractList) && adobeSignEnterpriseProduct?.id
    ? {
        access: () => true,
        id: adobeSignEnterpriseProduct.id,
      }
    : {
        access: () => false,
      };
}

/**
 * Determines if the current logged-in user has access to access to enterprise stock.
 * @returns {Boolean} true if logged in user has access
 */
function getEnterpriseStockParams() {
  const isUserOrgAdmin = auth.isUserOrgAdmin();
  const isUserProductAdmin = auth.isUserProductAdmin();

  if (isUserOrgAdmin || isUserProductAdmin) {
    const {productList, contractList} = rootStore.organizationStore;

    const hasETLAContract = hasEnterpriseContract(contractList) && productList?.hasETLAProducts;

    const adobeStockEnterpriseProduct = productList?.items?.find(
      (product) =>
        (product.isEnterpriseDirect() || product.isEnterpriseIndirect()) && product.isAdobeStock()
    );

    if (hasETLAContract && adobeStockEnterpriseProduct?.id) {
      return {
        access: () => true,
        id: adobeStockEnterpriseProduct.id,
      };
    }
  }

  return {access: () => false};
}

/**
 * @description Determines if the current logged-in user has access to Shared
 *   Device License (SDL) content (manage, etc)
 * @returns {Boolean} true if logged in user has SDL access
 */
function hasManageSdlAccess() {
  if (auth.hasAnyRole([ORG_ADMIN])) {
    return rootStore.organizationStore.productList.items.some((product) =>
      product.fulfillableItemList.hasLaboratoryLicenseManagement()
    );
  }
  return false;
}

/**
 * Determines if the current logged-in user has access for office 365 integration.
 * @returns {Boolean} true if logged in user has office 365 integration access
 */
function hasOffice365IntegrationAccess() {
  // to check whether logged in user is admin or not
  const isUserOrgAdmin = auth.isUserOrgAdmin();

  // to check whether logged in user is product admin or not
  const isUserProductAdmin = auth.isUserProductAdmin();

  // for getting product list
  const productList = rootStore.organizationStore.productList;

  if ((!isUserOrgAdmin && !isUserProductAdmin) || !productList.items) return false;

  return productList?.items?.some(
    (product) => product.isDocumentCloudProduct() && product.isEnterpriseDirect()
  );
}

/**
 * Determines if the current logged-in user has access for team sign 2.
 * @returns {Boolean} true if logged in user has team sign 2 access
 */
function hasTeamSign2Access() {
  // to check whether logged in user is admin or not
  const isUserOrgAdmin = auth.isUserOrgAdmin();

  const {contractList, productList} = rootStore.organizationStore;

  if (!isUserOrgAdmin || !contractList || !productList.items) return false;

  const hasTeamContract = hasDirectContract(contractList) || hasIndirectContract(contractList);

  const adobeSignTeamProductList = productList?.items?.filter(
    (product) =>
      (product.isTeamDirect() || product.isTeamIndirect()) &&
      product.includesBusinessOrEnterpriseSign()
  );

  const adobeSignTeamProduct =
    typeof adobeSignTeamProductList === 'object' && adobeSignTeamProductList?.length > 0;

  return hasTeamContract && adobeSignTeamProduct;
}

/**
 * Determines if the current logged-in user has access to xd for free.
 * @returns {Boolean} true if logged in user has xd for free access
 */
function hasXdForFreeAccess() {
  // to check whether logged in user is admin or not
  const isUserOrgAdmin = auth.isUserOrgAdmin();

  // for getting product list
  const productList = rootStore.organizationStore.productList;

  if (!isUserOrgAdmin || !productList.items) return false;

  return productList?.items?.some((product) => product.isCreativeCloudProduct());
}

/**
 * Determines if the current logged-in user has access to team sign.
 * @returns {Boolean} true if logged in user has access to team sign
 */
function getTeamSignParams() {
  // to check whether logged in user is admin or not
  const isUserOrgAdmin = auth.isUserOrgAdmin();
  if (isUserOrgAdmin) {
    const {productList, contractList} = rootStore.organizationStore;
    const hasTeamContract = hasDirectContract(contractList) || hasIndirectContract(contractList);
    const signProduct = productList?.items?.find(
      (product) =>
        (product.isTeamDirect() || product.isTeamIndirect()) &&
        product.includesBusinessOrEnterpriseSign()
    );
    if (hasTeamContract && signProduct?.id) {
      return {
        access: () => true,
        id: signProduct.id,
      };
    }
  }
  return {
    access: () => false,
    id: null,
  };
}

/**
 * Determines if the current logged-in user has access to team stock.
 * @returns {Boolean} true if logged in user has access to team stock
 */
function getTeamStockParams() {
  // to check whether logged in user is admin or not
  const isUserOrgAdmin = auth.isUserOrgAdmin();
  if (isUserOrgAdmin) {
    const {productList, contractList} = rootStore.organizationStore;
    const hasTeamContract = hasDirectContract(contractList) || hasIndirectContract(contractList);
    const stockProduct = productList?.items?.find(
      (product) => (product.isTeamDirect() || product.isTeamIndirect()) && product.isAdobeStock()
    );
    if (hasTeamContract && stockProduct?.id) {
      return {
        access: () => true,
        id: stockProduct.id,
      };
    }
  }
  return {
    access: () => false,
    id: null,
  };
}

/**
 * Determines if the current logged-in user has access to enterprise cloud.
 * @returns {Boolean} true if logged in user has DMA with analytics products
 * Products: Analytics, Audience Manager, Campaign, AEM, AEM Mobile (not ever in products list?),
 * Media Optimizer, Primetime, Social, Target
 */
function isDMAWithAnalyticsAndAudience(code) {
  return (
    code === 'dma_analytics' ||
    code === 'dma_audiencemanager' ||
    code === 'dma_campaign' ||
    code === 'dma_aem_ams' || // AEM
    code === 'dma_adlens' || // Media Optimizer
    code === 'dma_primetime' ||
    code === 'dma_social' ||
    code === 'dma_bullseye' || // Target
    code === 'dma_tnt' // Target Classic
  );
}

/**
 * Determines if the current logged-in user has access to enterprise cloud.
 * @returns {Boolean} true if logged in user has DMA products
 * Products: Campaign, AEM, Media Optimizer, Primetime, Social, Target
 */
function isDMA(code) {
  return (
    code === 'dma_campaign' ||
    code === 'dma_aem_ams' || // AEM
    code === 'dma_adlens' || // Media Optimizer
    code === 'dma_primetime' ||
    code === 'dma_social' ||
    code === 'dma_bullseye' || // Target
    code === 'dma_tnt' // Target Classic
  );
}

/**
 * Determines if the current logged-in user has access to enterprise cloud.
 * @returns {Boolean} true if logged in user has access
 */
function hasEnterpriseCloudProductAccess() {
  const isUserOrgAdmin = auth.isUserOrgAdmin();
  const isUserProductAdmin = auth.isUserProductAdmin();

  if (isUserOrgAdmin || isUserProductAdmin) {
    const {productList, contractList} = rootStore.organizationStore;
    const hasETLAContract = hasEnterpriseContract(contractList) && productList?.hasETLAProducts;

    const hasExperienceCloudProducts = productList?.items.some((product) =>
      product.isMarketingCloudProduct()
    );

    return hasETLAContract && hasExperienceCloudProducts;
  }

  return false;
}

export {
  getEnterpriseSignParams,
  getEnterpriseStockParams,
  getTeamSignParams,
  getTeamStockParams,
  hasAutoAssignedProductAccess,
  hasCreatePackagesAccess,
  hasEnterpriseCloudProductAccess,
  hasManageSdlAccess,
  hasOffice365IntegrationAccess,
  hasTeamSign2Access,
  hasXdForFreeAccess,
  isDMA,
  isDMAWithAnalyticsAndAudience,
};
