import {AuthenticatedUser, log} from '@admin-tribe/acsc';
import {generatePath, json, matchPath, redirect} from 'react-router-dom';

import rootStore from 'core/RootStore';
import {PATH_OVERVIEW} from 'features/overview/routing/overviewPaths';

/**
 * Note for all "loader" functions in this file: Each function should return a function
 * that accepts @type {{request: Request, params: {}}}
 * and returns @type {Response | null | Promise<Response | null>}.
 *
 * See https://reactrouter.com/en/main/route/loader for more details.
 */

/**
 * Returns a function which acts as a React Router loader to
 * determine if a page can be viewed based on a given value
 *
 * @example <caption>Using pre-calculated primitives</caption>
 * const accessCheckResponse1 = true;
 * const accessCheckResponse2 = false;
 * const loader = buildAccessCheckLoader(accessCheckResponse1, accessCheckResponse2);
 *
 * @example <caption>Using references to functions</caption>
 * const accessCheck1 = ({request}) => request.url.includes('admin'); // Check which passes only if 'admin' is in the URL
 * const accessCheck2 = ({params}) => params.orgId === '123@AdobeOrg'; // Check which passes when match param "orgId" is "123@AdobeOrg"
 * const accessCheck3 = () => auth.hasAnyRole(...); // Check which verifies a user role
 * const loader = buildAccessCheckLoader(accessCheck1, accessCheck2, accessCheck3);
 *
 * @param {(boolean | Promise<boolean> | (loaderArgs: import('react-router-dom').LoaderFunctionArgs) => (Promise<boolean> | boolean))[]} valuesOrFns
 * @returns {(loaderArgs: import('react-router-dom').LoaderFunctionArgs) => Promise<Response | null>}
 */
const buildAccessCheckLoader =
  (...valuesOrFns) =>
  async ({request, params}) => {
    const valuesAndPromises = valuesOrFns.map((valueOrFn) =>
      typeof valueOrFn === 'function' ? valueOrFn({params, request}) : valueOrFn
    );

    const results = await Promise.all(valuesAndPromises);

    for (let i = 0, l = results.length; i < l; i++) {
      if (!results[i]) {
        throwLoaderNoAccessError(request);
      }
    }

    return null;
  };

/**
 * Returns a function which acts as a React Router loader to
 * determine if a page can be viewed based on a given value.
 * If the check fails, it will redirect to a given path. Redirects to PATH_OVERVIEW by default.
 *
 * @example <caption>Using pre-calculated primitives</caption>
 * const accessCheckResponse1 = true;
 * const accessCheckResponse2 = false;
 * const loader = buildAccessCheckLoader(accessCheckResponse1, accessCheckResponse2);
 *
 * * @example <caption>Using references to functions</caption>
 * const accessCheck1 = ({request}) => request.url.includes('admin'); // Check which passes only if 'admin' is in the URL
 * If check fails, url redirected to path specified. Default is PATH_OVERVIEW
 */
const buildAccessCheckLoaderRedirectToPath =
  (path = PATH_OVERVIEW, ...valuesOrFns) =>
  async ({request, params}) => {
    const valuesAndPromises = valuesOrFns.map((valueOrFn) =>
      typeof valueOrFn === 'function' ? valueOrFn({params, request}) : valueOrFn
    );

    try {
      const results = await Promise.all(valuesAndPromises);

      for (let i = 0, l = results.length; i < l; i++) {
        if (!results[i]) {
          return redirect(generatePath(path, {orgId: rootStore.organizationStore.activeOrgId}));
        }
      }
    } catch (error) {
      log.error('Error in buildAccessCheckLoaderRedirectToPath:', error);
      throwLoaderNoAccessError(request);
    }
    return null;
  };

/**
 * Builds a loader that executes multiple given loaders in series. If any of them return a Response
 * then the next loaders are not run.
 *
 * @example <caption>Create a loader which executes an access check and handles redirection</caption>
 * const accessLoader = buildAccessCheckLoader(() => auth.hasAnyRole(...));
 * const redirectLoader = buildDefaultSectionRedirectLoader('/insights', '/insights/dashboard');
 * const combinedLoader = buildSequentialLoader(accessLoader, redirectLoader);
 *
 * @param {((loaderArgs: import('react-router-dom').LoaderFunctionArgs) =>  Promise<Response | null>)[]} loaders
 * @returns {(loaderArgs: import('react-router-dom').LoaderFunctionArgs) => Promise<Response | null>}
 */
const buildSequentialLoader =
  (...loaders) =>
  async (loaderArgs) => {
    for (let i = 0, l = loaders.length; i < l; i++) {
      // eslint-disable-next-line no-await-in-loop -- Needed to conditionally run promises
      const loaderResponse = await loaders[i](loaderArgs);

      if (loaderResponse) {
        return loaderResponse;
      }
    }

    return null;
  };

/**
 * Checks if a given path or path pattern matches the current pathname
 * @param {string} pathOrPattern
 * @param {string} requestPathname
 * @returns {boolean}
 */
const checkForMatch = (pathOrPattern, requestPathname) => {
  if (pathOrPattern.includes(':')) {
    return matchPath(pathOrPattern, requestPathname) !== null;
  }

  return requestPathname === pathOrPattern;
};

/**
 * Handles redirecting to a sub-route in a silo if a user goes to the
 * root URL for the silo.
 *
 * @example <caption>Create a loader which redirects to /insights/dasboard when landing on /insights</caption>
 * const loader = buildDefaultSectionRedirectLoader('/insights', '/insights/dashboard');
 *
 * @example <caption>Create a redirect loader that redirects from 2 different paths</caption>
 * const loader = buildDefaultSectionRedirectLoader(['/', '/:orgId'], '/123@AdobeOrg/overview');
 *
 * @param {string | string[]} fromPathOrPatterns - Can be a static path or a tokenized route path, ex: "/:orgId/overview"
 * @param {string} toPath - A non-tokenized URL to go to, ex: "/123@AdobeOrg/overview"
 * @param {{withQueryParams: boolean}} [options] - Options for the redirect
 * @returns {(loaderArgs: import('react-router-dom').LoaderFunctionArgs) => Response | null}
 */
const buildDefaultSectionRedirectLoader =
  (fromPathOrPatterns, toPath, {withQueryParams} = {withQueryParams: false}) =>
  ({request}) => {
    const requestUrl = new URL(request.url);
    let doRedirect = false;

    if (Array.isArray(fromPathOrPatterns)) {
      doRedirect = fromPathOrPatterns.some((fromPathOrPattern) =>
        checkForMatch(fromPathOrPattern, requestUrl.pathname)
      );
    } else {
      doRedirect = checkForMatch(fromPathOrPatterns, requestUrl.pathname);
    }

    if (doRedirect) {
      const computedToPath = withQueryParams ? `${toPath}${requestUrl.search}` : toPath;

      return redirect(computedToPath);
    }

    return null;
  };

/**
 * Method to throw a loader error if the admin does not have access to the route.
 *
 * @param {Request} request The loader request.
 * @throws loader access error with status 403 if admin does not have access
 */
function throwLoaderNoAccessError(request) {
  throw json(
    {
      pathname: new URL(request.url).pathname,
      userId: AuthenticatedUser.get().getId(),
    },
    {status: 403}
  );
}

/**
 * Method to throw a loader error if the route is not found
 * @param {Request} request
 */
function throwNotFoundError(request) {
  throw json(
    {
      pathname: new URL(request.url).pathname,
      userId: AuthenticatedUser.get().getId(),
    },
    {status: 404}
  );
}

export {
  buildAccessCheckLoader,
  buildAccessCheckLoaderRedirectToPath,
  buildDefaultSectionRedirectLoader,
  buildSequentialLoader,
  throwLoaderNoAccessError,
  throwNotFoundError,
};
