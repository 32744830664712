import {ContractTerms} from '@admin-tribe/acsc';
import {when} from 'mobx';

import rootStore from 'core/RootStore';

const ORGANIZATION_TYPE_ETLA_K12_TERMS_SERVICE_NAME = 'spark_edu_etla_k12_terms';

/**
 * @description Method to provision offers for organization types
 * @param {Array} marketSubsegments an array of market subsegments
 * @param {Object} options options to provision offers
 * @param {Boolean} options.hasAdditionalTermsToAccept the boolean value to accept additional terms
 * @returns {Promise} a promise fulfilled when successfully updating the org's properties and provisioning offers
 */
async function updateOrgMarketSubsegments(marketSubsegments, options = {}) {
  // For an ETLA org if selecting K-12, we have to successfully save agreement to terms, before storing the
  // marketSubsegment.
  // If the marketSubsegment has been reset in one of the management consoles it is possible that the terms have
  // already been accepted but for simplicity we'll just require agreement again.
  if (options.hasAdditionalTermsToAccept) {
    const terms = await ContractTerms.get({
      orgId: rootStore.organizationStore.activeOrg.id,
      serviceName: ORGANIZATION_TYPE_ETLA_K12_TERMS_SERVICE_NAME,
    });
    if (terms.mustAcceptTerms()) {
      await terms.acceptTerms();
    }
    return performUpdate();
  }
  return performUpdate();

  ////////

  async function performUpdate() {
    await when(() => !!rootStore.organizationStore.activeOrg);
    const activeOrg = rootStore.organizationStore.activeOrg;
    return activeOrg.update({marketSubsegments});
  }
}

// eslint-disable-next-line import/prefer-default-export -- helper
export {updateOrgMarketSubsegments};
