import {navBus} from '@admin-tribe/acsc';
import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import {isLocationReactRoute} from 'core/router/route-migration/routeMigrationUtils';

/** Component which registers React Router navigation methods to the navBus */
const NavigationRegisterer = () => {
  const navigate = useNavigate();

  /** Register the function */
  useEffect(() => {
    navBus.registerNavigation({
      onNavigate: ({url, ...other}) => {
        navigate(url, other);
      },
      onPushState: ({url, ...other}) => {
        navigate(url, other);
      },
      onReload: () => {
        navigate(0);
      },
      onReplaceState: ({url, ...other}) => {
        navigate(url, {
          ...other,
          replace: true,
        });
      },
    });
  }, [navigate]);

  /** Registers the migration util methods */
  useEffect(() => {
    navBus.registerMigrationUtils({isLocationReactRoute});
  }, []);

  return null;
};

export default NavigationRegisterer;
