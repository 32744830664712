import React from 'react';

import ReactApp from 'shell/components/shell/ReactApp';

import LocationObserver from './LocationObserver';
import NavigationRegisterer from './NavigationRegisterer';
import {rootLoader} from './applicationRoutesLoaders';
import PageError from './bumpers/PageError';
import RootError from './bumpers/RootError';
import getSiloRoutes from './getSiloRoutes';

/**
 * Assembles the top level routing structure for the app
 * @param {{orgRoutes: import('react-router-dom').RouteObject[]}} param0
 * @returns {import('react-router-dom').RouteObject[]}
 */
const applicationRoutes = ({orgRoutes}) => [
  {
    children: [
      ...getSiloRoutes(),
      {
        children: orgRoutes,
        errorElement: <PageError />,
        id: 'org',
        path: ':orgId',
      },
    ],
    element: (
      <>
        <LocationObserver />
        <NavigationRegisterer />
        <ReactApp />
      </>
    ),
    errorElement: (
      <>
        <NavigationRegisterer />
        <RootError />
      </>
    ),
    id: 'root',
    loader: rootLoader,
    path: '/',
  },
];

export default applicationRoutes;
