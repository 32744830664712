import {log} from '@admin-tribe/acsc';
import PropTypes from 'prop-types';
import React from 'react';

// eslint-disable-next-line no-restricted-properties -- error boundaries require a class component
class SophiaErrorBoundary extends React.Component {
  static getDerivedStateFromError(error) {
    return {error, hasError: true};
  }

  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  componentDidCatch(error, errorInfo) {
    log.error(`Error caught in Sophia call, error: ${error} errorInfo: ${errorInfo}`);
  }

  render() {
    const {hasError} = this.state;
    const {children} = this.props;

    if (hasError) {
      return null;
    }

    return children;
  }
}

SophiaErrorBoundary.propTypes = {
  children: PropTypes.node,
};

// eslint-disable-next-line no-restricted-properties -- error boundaries require a class component
SophiaErrorBoundary.defaultProps = {
  children: null,
};

export default SophiaErrorBoundary;
