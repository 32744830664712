// ***********************************************************************
// ADOBE CONFIDENTIAL
// ___________________
//
// Copyright 2024 Adobe
// All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains
// the property of Adobe and its suppliers, if any. The intellectual
// and technical concepts contained herein are proprietary to Adobe
// and its suppliers and are protected by all applicable intellectual
// property laws, including trade secret and copyright laws.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Adobe.
// ************************************************************************
import PropTypes from 'prop-types';
import React from 'react';

import SophiaPromoBannerPanel from 'common/components/sophia/sophia-promo/SophiaPromoBannerPanel';

import {JsonPayloadProvider} from './JsonPayloadProvider';

/**
 * SophiaPromoBannerView component that displays banner on top of product overview page.
 *
 * @param sophiaContent the Sophia content to display in the right rail
 * @param onDismiss the function to call when the right rail is dismissed
 * @returns {React.JSX.Element} the right rail view
 * @constructor SophiaPromoRightRailView component that displays the right rail version of the Sophia promo
 */
const SophiaPromoBannerView = ({sophiaContent, onDismiss}) => (
  <JsonPayloadProvider payload={sophiaContent}>
    <SophiaPromoBannerPanel onDismiss={onDismiss} />
  </JsonPayloadProvider>
);

SophiaPromoBannerView.propTypes = {
  onDismiss: PropTypes.func,
  sophiaContent: PropTypes.oneOfType([PropTypes.object]),
};

export default SophiaPromoBannerView;
