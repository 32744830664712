import {AppIntegration} from '@admin-tribe/acsc';
import {useAsyncModel} from '@pandora/react-async-model';
import {useCallback} from 'react';

/**
 * Helper hook to help with fetching a specific app integration.
 * @param {String} clientId - the clientId for the app integration policy
 * @param {String} orgId - the orgId for the app integration policy
 * @returns {Object} states - states.appIntegration, states.error, states.isLoading
 */
const useAppIntegration = ({clientId, orgId}) => {
  const loadAppIntegrationByClientId = useCallback(() => {
    if (clientId && orgId) {
      return AppIntegration.getAppIntegrationByClientId({clientId, orgId});
    }
    return null;
  }, [clientId, orgId]);

  const {
    error,
    isLoading,
    model: appIntegration,
  } = useAsyncModel({
    loadFn: loadAppIntegrationByClientId,
  });

  return {
    appIntegration,
    error,
    isLoading,
  };
};

export default useAppIntegration;
