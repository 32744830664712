import {navBus} from '@admin-tribe/acsc';
import {generatePath} from 'react-router-dom';

import rootStore from 'core/RootStore';
import ProductGroupProductList from 'core/services/product/product-group/product-list/ProductGroupProductList';
import {
  PATH_ADD_PRODUCTS,
  PATH_PRODUCTS,
  PATH_PRODUCTS_AUTO_ASSIGN,
  PATH_PRODUCTS_REQUESTS,
  PATH_PRODUCT_DETAILS,
  PATH_PRODUCT_DETAILS_BULK_OPERATIONS,
  PATH_PRODUCT_DETAILS_BULK_OPERATIONS_LICENSE_DEFICIT_REPORT,
  PATH_PRODUCT_DETAILS_BULK_OPERATIONS_RESULTS,
  PATH_PRODUCT_DETAILS_USERS,
  PATH_PRODUCT_GROUP_DETAILS,
  PATH_PRODUCT_PROFILE_DETAILS,
  PATH_PRODUCT_PROFILE_DETAILS_BULK_OPERATIONS,
  PATH_PRODUCT_PROFILE_DETAILS_BULK_OPERATIONS_LICENSE_DEFICIT_REPORT,
  PATH_PRODUCT_PROFILE_DETAILS_BULK_OPERATIONS_RESULTS,
  PATH_PRODUCT_PROFILE_DETAILS_USERS,
} from 'features/products/routing/productsPaths';

/**
 * @description Method that returns a method to get the href for product bulk operations details
 *
 * @param {Object} object
 * @param {String} object.productId - the id of the product.
 * @param {String} object.profileId - the id of the product profile.
 *
 * @returns {Function} A function that takes in a job id as an argument to determine what the href is for the job result
 */
const getHrefForProductBulkOpsDetailsFn =
  ({productId, profileId}) =>
  ({jobId}) =>
    generatePath(PATH_PRODUCT_DETAILS_BULK_OPERATIONS_RESULTS, {
      jobId,
      orgId: rootStore.organizationStore.activeOrgId,
      productId,
      profileId,
    });

/**
 * @description Method to get the href of product details page.
 *
 * @param {Object} product2 object
 * @param {String} object.id - id of the product2.
 */
const getHrefForProductDetails = ({id}) =>
  generatePath(PATH_PRODUCT_DETAILS, {
    orgId: rootStore.organizationStore.activeOrgId,
    productId: id,
  });

/**
 * @description Method to get the href of product group details page.
 *
 * @param {Object} options - Top level wrapper object.
 * @param {String} options.id - id of the product group list.
 *
 * @returns {Function} A function that takes an id and returns the href for
 *     navigating to that product group's detail page.
 */
const getHrefForProductGroupDetails = ({id}) =>
  generatePath(PATH_PRODUCT_GROUP_DETAILS, {
    groupId: id,
    orgId: rootStore.organizationStore.activeOrgId,
  });

/**
 * @description Method that returns a method to get the href for product license deficit report
 *
 * @param {Object} object
 * @param {String} object.productId - the id of the product.
 *
 * @returns {Function} A function that takes in a job id as an argument to determine what the href is for the license deficit report
 */
const getHrefForProductLicenseDeficitFn =
  ({productId}) =>
  ({jobId}) =>
    generatePath(PATH_PRODUCT_DETAILS_BULK_OPERATIONS_LICENSE_DEFICIT_REPORT, {
      jobId,
      orgId: rootStore.organizationStore.activeOrgId,
      productId,
    });

/**
 * Method to obtain the href for navigating to the product details page for a
 *     given product or product group.
 *
 * @param {Product | ProductGroupProductList} productOrProductGroup - Product or
 *     ProductGroupProductList object to generate an href for.
 * @returns {href} href for navigating to the relevant product details page.
 */
const getHrefForProductOrProductGroup = (productOrProductGroup) =>
  productOrProductGroup instanceof ProductGroupProductList
    ? getHrefForProductGroupDetails({id: productOrProductGroup.id})
    : getHrefForProductDetails({id: productOrProductGroup.id});

/**
 * @description Method that returns a method to get the href for product profile bulk operations details
 *
 * @param {Object} object
 * @param {String} object.productId - the id of the product.
 * @param {String} object.profileId - the id of the product profile.
 *
 * @returns {Function} A function that takes in a job id as an argument to determine what the href is for the job result
 */
const getHrefForProductProfileBulkOpsDetailsFn =
  ({productId, profileId}) =>
  ({jobId}) =>
    generatePath(PATH_PRODUCT_PROFILE_DETAILS_BULK_OPERATIONS_RESULTS, {
      jobId,
      orgId: rootStore.organizationStore.activeOrgId,
      productId,
      profileId,
    });

/**
 * @description Method to get the href of the Product Profile details page.
 *
 * @param {Object} object
 * @param {String} object.profileId - id of the product profile.
 * @param {String} object.productId - id of the product.
 */
const getHrefForProductProfileDetails = ({productId, profileId}) =>
  generatePath(PATH_PRODUCT_PROFILE_DETAILS, {
    orgId: rootStore.organizationStore.activeOrgId,
    productId,
    profileId,
  });

/**
 * @description Method that returns a method to get the href for product profile license deficit report
 *
 * @param {Object} object
 * @param {String} object.productId - the id of the product.
 * @param {String} object.profileId - the id of the product profile.
 *
 * @returns {Function} A function that takes in a job id as an argument to determine what the href is for the license deficit report
 */
const getHrefForProductProfileLicenseDeficitFn =
  ({productId, profileId}) =>
  ({jobId}) =>
    generatePath(PATH_PRODUCT_PROFILE_DETAILS_BULK_OPERATIONS_LICENSE_DEFICIT_REPORT, {
      jobId,
      orgId: rootStore.organizationStore.activeOrgId,
      productId,
      profileId,
    });

/**
 * @description Method that returns a method to navigate to product bulk operations details
 *
 * @param {Object} object
 * @param {String} object.productId - the id of the product.
 * @param {String} object.profileId - the id of the product profile.
 *
 * @returns {Function} A function that takes in a job id as an argument to determine where to navigate to for the job result
 */
const goToProductBulkOpsDetailsFn =
  ({productId}) =>
  ({jobId}) => {
    navBus.navigate(
      generatePath(PATH_PRODUCT_DETAILS_BULK_OPERATIONS_RESULTS, {
        jobId,
        orgId: rootStore.organizationStore.activeOrgId,
        productId,
      })
    );
  };

/**
 * @description Method to navigate to the product details page.
 *
 * @param {Object} object
 * @param {String} object.productId - the id of the product.
 */
const goToProductDetails = ({productId}) => {
  navBus.navigate(
    generatePath(PATH_PRODUCT_DETAILS, {
      orgId: rootStore.organizationStore.activeOrgId,
      productId,
    })
  );
};

/**
 * @description Method to navigate to the product details jobs page.
 *
 * @param {Object} object
 * @param {String} object.productId - the id of the product.
 */
const goToProductDetailsBulkOps = ({productId}) => {
  navBus.navigate(
    generatePath(PATH_PRODUCT_DETAILS_BULK_OPERATIONS, {
      orgId: rootStore.organizationStore.activeOrgId,
      productId,
    })
  );
};

/**
 * @description Method to navigate to the product details page for a product group.
 *
 * @param {Object} object
 * @param {String} object.productId - the id of the product.
 */
const goToProductGroupDetails = ({groupId}) => {
  navBus.navigate(
    generatePath(PATH_PRODUCT_GROUP_DETAILS, {
      groupId,
      orgId: rootStore.organizationStore.activeOrgId,
    })
  );
};

/**
 * @description Method to navigate to the product details page for a
 *     given product or product group.
 *
 * @param {Object} options - Top level wrapper object
 * @param {Product | ProductGroupProductList} productOrProductGroup - Product or
 *     ProductGroupProductList object to generate an href for.
 */
const goToProductOrProductGroupDetails = ({productOrProductGroup}) => {
  navBus.navigate(getHrefForProductOrProductGroup(productOrProductGroup));
};

/**
 * @description Method to navigate to the product profile details bulk operations page.
 *
 * @param {Object} object
 * @param {String} object.productId - the id of the product.
 * @param {String} object.profileId - the id of the product profile.
 */
const goToProductProfileBulkOps = ({productId, profileId}) => {
  navBus.navigate(
    generatePath(PATH_PRODUCT_PROFILE_DETAILS_BULK_OPERATIONS, {
      orgId: rootStore.organizationStore.activeOrgId,
      productId,
      profileId,
    })
  );
};

/**
 * @description Method that returns a method to navigate to product profile bulk operations details
 *
 * @param {Object} object
 * @param {String} object.productId - the id of the product.
 * @param {String} object.profileId - the id of the product profile.
 *
 * @returns {Function} A function that takes in a job id as an argument to determine wheere to navigate to for the job result
 */
const goToProductProfileBulkOpsDetailsFn =
  ({productId, profileId}) =>
  ({jobId}) => {
    navBus.navigate(
      generatePath(PATH_PRODUCT_PROFILE_DETAILS_BULK_OPERATIONS_RESULTS, {
        jobId,
        orgId: rootStore.organizationStore.activeOrgId,
        productId,
        profileId,
      })
    );
  };

/**
 * @description Method to navigate to the Product Profile details page.
 *
 * @param {Object} object
 * @param {String} object.profileId - id of the product profile.
 * @param {String} object.productId - id of the product.
 */
const goToProductProfileDetails = ({productId, profileId}) => {
  navBus.navigate(
    generatePath(PATH_PRODUCT_PROFILE_DETAILS, {
      orgId: rootStore.organizationStore.activeOrgId,
      productId,
      profileId,
    })
  );
};

/**
 * @description Method to navigate to the Product Profiles page.
 *
 * @param {String} object.productId - id of the product.
 */
const goToProductProfiles = ({productId}) => {
  navBus.navigate(
    generatePath(PATH_PRODUCT_PROFILE_DETAILS_USERS, {
      orgId: rootStore.organizationStore.activeOrgId,
      productId,
    })
  );
};

/**
 * @description Method to navigate to the Product Requests page.
 */
const goToProductRequests = () => {
  navBus.navigate(
    generatePath(PATH_PRODUCTS_REQUESTS, {
      orgId: rootStore.organizationStore.activeOrgId,
    })
  );
};

/**
 * @description Method to navigate to the 'Products' summary page.
 */
const goToProducts = () => {
  navBus.navigate(generatePath(PATH_PRODUCTS, {orgId: rootStore.organizationStore.activeOrgId}));
};

/**
 * @description Method to navigate to the Product users page.
 *
 * @param {String} object.productId - id of the product.
 */
const goToProductUsers = ({productId}) => {
  navBus.navigate(
    generatePath(PATH_PRODUCT_DETAILS_USERS, {
      orgId: rootStore.organizationStore.activeOrgId,
      productId,
    })
  );
};

/**
 * @description Method to navigate to the 'Auto-Assignment Rules' page.
 */
const goToProductsAutoAssignmentRules = () => {
  navBus.navigate(
    generatePath(PATH_PRODUCTS_AUTO_ASSIGN, {orgId: rootStore.organizationStore.activeOrgId})
  );
};

/**
 * @description Method to navigate to the buy licenses dialog on the product page.
 */
const goToAddProducts = () => {
  navBus.navigate(
    generatePath(PATH_ADD_PRODUCTS, {orgId: rootStore.organizationStore.activeOrgId})
  );
};

export {
  goToProductsAutoAssignmentRules,
  getHrefForProductBulkOpsDetailsFn,
  getHrefForProductDetails,
  getHrefForProductGroupDetails,
  getHrefForProductLicenseDeficitFn,
  getHrefForProductOrProductGroup,
  getHrefForProductProfileBulkOpsDetailsFn,
  getHrefForProductProfileDetails,
  getHrefForProductProfileLicenseDeficitFn,
  goToAddProducts,
  goToProductBulkOpsDetailsFn,
  goToProductDetails,
  goToProductDetailsBulkOps,
  goToProductGroupDetails,
  goToProductOrProductGroupDetails,
  goToProductProfileBulkOps,
  goToProductProfileBulkOpsDetailsFn,
  goToProductProfileDetails,
  goToProductProfiles,
  goToProductRequests,
  goToProducts,
  goToProductUsers,
};
