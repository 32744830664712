// eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore --  temparorily ignore this as the functionality needs to be completed
// istanbul ignore file
import {AuthenticatedUser, Locale, authentication, feature, getDaysLeft} from '@admin-tribe/acsc';
import {withAEMContent} from '@pandora/react-aem-content-provider';
import {
  ContractMiniAppContentModel,
  SwitchProfile,
  UnpaidInvoices,
  UpdatePayment,
  UpdatePoNumber,
} from '@pandora/react-mini-app-contract';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useState} from 'react';

import EditBillingModal from 'common/components/edit-billing-modal/EditBillingModal';
import LinkedMembershipBanner from 'common/components/linked-membership-banner/LinkedMembershipBanner';
import ThreeYearCommitBanner from 'common/components/three-year-commit-banner/ThreeYearCommitBanner';
import useEditBillingModal from 'common/hooks/useEditBillingModal';
import rootStore from 'core/RootStore';
import {ACCOUNT_STATE_PARAM} from 'core/account/account-meta/AccountMetaConstants';
import chatProvider from 'core/services/chat/chatProvider';
import useInvoiceList from 'features/account/billing-history/hooks/useInvoiceList';
import useUnpaidInvoicesCheck from 'features/account/billing-history/hooks/useUnpaidInvoicesCheck';
import AddProductModalWrapper from 'features/products/components/add-product-modal-wrapper/AddProductModalWrapper';
import {
  StepType,
  WorkflowType,
} from 'features/products/components/contract-renewal-wrapper/ContractRenewal.constants';

import {goToBillingHistory} from '../routing/navigation-callbacks/navigationCallbacks';

import styles from './ContractBanner.pcss';
import {
  EXPIRING_DAYS_LEFT,
  INVOICE_UNPAID_STATUSES,
  isCardPayment,
  isIncompletePaymentInfo,
  isVIPOrVIPMPContract,
} from './ContractBanner.utils';

/**
 * Find contract by contract ID.
 * @param {String} contractId - Current Contract ID.
 * @returns {Contract} Contract with ID matched or undefined.
 */
const getContract = (contractId) => {
  const contractList = rootStore.organizationStore.contractList;
  return contractList.items.find((item) => item.id === contractId);
};

/**
 * Banner for switch user profile.
 * @param {String} contractId - Current Contract ID.
 * @returns {React.Element|null} Switch user banner if current user is the contract owner but not the type1 user.
 */
const SwitchUserBanner = observer(({contractId}) => {
  const contract = getContract(contractId);
  const isContractOwner = contract?.ownerInfo?.authUserIsOwner;
  const ownerUserId = contract?.ownerInfo?.userId;
  const authUserId = feature.isEnabled('temp_t2e_contract_owners')
    ? AuthenticatedUser.get().getAuthenticatingAccountId()
    : AuthenticatedUser.get().getId();

  /**
   * Handler for switching user profile. After signed in with the new user profile,
   * go to the account page
   */
  const switchUserHandler = () => {
    authentication.switchTo(ownerUserId, `${ownerUserId}/account`);
  };

  if (contract.isDirectContract() && isContractOwner && ownerUserId !== authUserId) {
    return (
      <div className={styles['contract-banner']} data-testid="switch-profile-wrapper">
        <SwitchProfile switchUserHandler={switchUserHandler} />
      </div>
    );
  }

  return null;
});

SwitchUserBanner.propTypes = {
  contractId: PropTypes.string.isRequired,
};

/**
 * Banner for update payment notification
 * @param {String} contractId - Current contract ID.
 * @returns {React.Element|null} Banner if contract is going to be expired or there is incomplete payment information.
 */
const UpdatePaymentBanner = observer(({contractId}) => {
  const contract = getContract(contractId);
  const expiringDaysLeft = getDaysLeft(contract?.billingInfo?.paymentInstrumentInfo?.expiryDate);
  const creditCardExpired = !!(expiringDaysLeft < 0);

  const isContractOwner = contract?.ownerInfo?.authUserIsOwner;

  const {paymentInstrumentInfo} = contract?.billingInfo || {};

  if (
    isContractOwner &&
    !isVIPOrVIPMPContract(contract) &&
    isCardPayment(paymentInstrumentInfo) &&
    (expiringDaysLeft < EXPIRING_DAYS_LEFT || isIncompletePaymentInfo(paymentInstrumentInfo))
  ) {
    return (
      <div className={styles['contract-banner']} data-testid="update-payment-wrapper">
        <UpdatePayment creditCardExpired={creditCardExpired} />
      </div>
    );
  }

  return null;
});

UpdatePaymentBanner.propTypes = {
  contractId: PropTypes.string.isRequired,
};

/**
 * Banner for unpaid invoices
 * @param {String} contractId - Current contract ID.
 * @param {Object} invoiceList - Invoice Object
 * @param {boolean} isLoading - loading
 * @returns {React.Element|null} Banner if contract is going to be expired.
 */
const UnpaidInvoicesBanner = observer(({contractId}) => {
  const contract = getContract(contractId);
  const {invoiceList, isLoading} = useInvoiceList();
  const expiringDaysLeft = getDaysLeft(contract?.billingInfo?.paymentInstrumentInfo?.expiryDate);
  const creditCardExpired = !!(expiringDaysLeft < 0);
  const {hasUnpaidInvoices} = useUnpaidInvoicesCheck();
  const [action, setAction] = useState();
  const [payInvoiceValue, setPayInvoiceValue] = useState();
  const [paypalDecision, setPaypalDecision] = useState();
  const {closeEditBillingModal, openEditBillingModal, showEditBillingModal} = useEditBillingModal();
  const clearAutoLaunchStateAndCloseEditBilling = () => {
    setAction(undefined);
    setPayInvoiceValue(undefined);
    setPaypalDecision(undefined);
    closeEditBillingModal();
  };

  const ownerUserId = contract?.ownerInfo?.userId;
  const authUserId = feature.isEnabled('temp_t2e_contract_owners')
    ? AuthenticatedUser.get().getAuthenticatingAccountId()
    : AuthenticatedUser.get().getId();

  const showUnpaidInvoiceFlag =
    contract.salesChannel === 'DIRECT' &&
    contract?.customerSegment === 'TEAM' &&
    contract?.billingInfo?.paymentInstrumentInfo?.paymentType === 'PO';

  const unpaidInvoices = invoiceList?.items?.filter((item) =>
    INVOICE_UNPAID_STATUSES.includes(item.invoiceStatus)
  );
  const viewBillingHistoryHandler = () => {
    goToBillingHistory();
  };

  const payNowHandler = useCallback(() => {
    // Display REBA in a modal
    openEditBillingModal();
  }, [openEditBillingModal]);

  const invoiceHandler = () => {
    if (unpaidInvoices.length === 1) {
      payNowHandler();
      setPayInvoiceValue(unpaidInvoices?.[0]?.id);
    } else {
      viewBillingHistoryHandler();
    }
  };

  // Extract query param value, set to component state, and remove from URL
  const extractQueryParam = (paramKey, stateSetter) => {
    if (typeof window !== 'undefined') {
      const url = new URL(window.location);
      const value = url.searchParams.get(paramKey);

      if (value) {
        stateSetter(value);
      }

      // Remove query param from URL to avoid duplicate processing
      url.searchParams.delete(paramKey);
      window.history.replaceState(null, '', url.toString());
    }
  };

  // Extract action, payInvoice, and paypal query param values (if present) to component state
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const url = new URL(window.location);
      const isAddProductsPath = url.pathname.includes('/add-products');

      // Extract query params to trigger Edit Billing auto-launch only if URL is not for Add Products (which would already auto-launch)
      if (!isAddProductsPath) {
        extractQueryParam('action', setAction);
        extractQueryParam(
          ACCOUNT_STATE_PARAM.AUTO_OPEN_ONE_TIME_PAYMENT_QUERY_STRING,
          setPayInvoiceValue
        );
        extractQueryParam('paypal', setPaypalDecision);
      }
    }
  }, []);

  // Auto-launch edit payment modal per extraction of payInvoice or paypal query param
  useEffect(() => {
    const isEditBillingNotOpen = !showEditBillingModal;
    const shouldAutoOpenEditPaymentModal =
      (action || payInvoiceValue || paypalDecision) && isEditBillingNotOpen;

    if (shouldAutoOpenEditPaymentModal) {
      payNowHandler();
    }
  }, [action, paypalDecision, payNowHandler, payInvoiceValue, showEditBillingModal]);

  return (
    <div className={styles['contract-banner']} data-testid="unpaid-invoice-wrapper">
      {showUnpaidInvoiceFlag && hasUnpaidInvoices && ownerUserId === authUserId && !isLoading && (
        <UnpaidInvoices
          creditCardExpired={creditCardExpired}
          invoiceHandler={invoiceHandler}
          unpaidInvoicesCount={unpaidInvoices.length}
        />
      )}
      {showEditBillingModal && (
        <EditBillingModal
          contractId={contract?.id}
          inPaymentMode={Boolean(payInvoiceValue)}
          invoiceId={payInvoiceValue}
          isOpen={showEditBillingModal}
          onCancel={clearAutoLaunchStateAndCloseEditBilling}
          onSubmit={clearAutoLaunchStateAndCloseEditBilling}
        />
      )}
    </div>
  );
});

UnpaidInvoicesBanner.propTypes = {
  contractId: PropTypes.string.isRequired,
};

const UpdatePoNumberBanner = observer(({contractId, purchaseOrderNumber = ''}) => {
  const contract = getContract(contractId);
  // Determine to launch Contract Renewal Order workflow in modal dialog
  const [showAddProductModal, setShowAddProductModal] = useState(false);

  const addPoHandler = () => {
    // Launch modal dialog when user clicks the CTA
    setShowAddProductModal(true);
  };

  if (contract.isDirectContract() && contract.isPaymentCategoryPO()) {
    if (contract.isInRenewalWindow()) {
      if (purchaseOrderNumber?.length === 0) {
        return (
          <div className={styles['contract-banner']} data-testid="update-purchase-order-wrapper">
            <UpdatePoNumber addPoHandler={addPoHandler} />
            {showAddProductModal && (
              <AddProductModalWrapper
                chat={chatProvider}
                onClose={() => setShowAddProductModal(false)}
                step={StepType.REVIEW_ORDER}
                workflow={WorkflowType.VIEW_RENEW}
              />
            )}
          </div>
        );
      }
    }
  }

  return null;
});

UpdatePoNumberBanner.propTypes = {
  contractId: PropTypes.string.isRequired,
  purchaseOrderNumber: PropTypes.string,
};

/**
 * Banners based on contract status.
 * @param {String} contractId - Current contract ID.
 * @returns {React.Element|null} - Various banners based on the contract status.
 */
const ContractBannerInternal = observer(({contractId, purchaseOrderNumber}) => (
  <div>
    <SwitchUserBanner contractId={contractId} />
    <ThreeYearCommitBanner contractId={contractId} />
    <UpdatePaymentBanner contractId={contractId} />
    <UnpaidInvoicesBanner contractId={contractId} />
    <UpdatePoNumberBanner contractId={contractId} purchaseOrderNumber={purchaseOrderNumber} />
    <LinkedMembershipBanner contractId={contractId} />
  </div>
));

ContractBannerInternal.propTypes = {
  contractId: PropTypes.string.isRequired,
  purchaseOrderNumber: PropTypes.string,
};

/**
 * Banners based on contract status.
 * @param {String} contractId - Current contract ID.
 * @returns {React.Element|null} - Various banners based on the contract status.
 */

const ContractBanner = ({contractId, purchaseOrderNumber}) => {
  const ContractBannerInternalWithContent = withAEMContent(
    'businesstrials/account-contract-mini-app',
    'reactMiniAppAccountContractV1Main',
    ContractMiniAppContentModel
  )(ContractBannerInternal);

  return (
    <ContractBannerInternalWithContent
      aem={{locale: Locale.get().activeLanguageBCP47Code, usePlaceholderContent: true}}
      contractId={contractId}
      purchaseOrderNumber={purchaseOrderNumber}
    />
  );
};

ContractBanner.propTypes = {
  contractId: PropTypes.string.isRequired,
  purchaseOrderNumber: PropTypes.string,
};

export default ContractBanner;
