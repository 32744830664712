import {
  AlertDialog,
  showError as showErrorToast,
  showSuccess as showSuccessToast,
} from '@admin-tribe/acsc-ui';
import {Text} from '@adobe/react-spectrum';
import {useId} from '@react-aria/utils';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {useContentLogsContext} from '../ContentLogsContext';

/**
 * @description the button to confirm the removal of one/more content logs from the table.
 */
const RemoveContentLogModal = observer(({onClose}) => {
  const {contentLogsStore} = useContentLogsContext();

  const intl = useIntl();
  const id = useId();

  const selectedCount = contentLogsStore.tableStore.selectedItemCount;
  const removeReportLabel = intl.formatMessage(
    {id: 'settings.contentLogs.removeReport'},
    {count: selectedCount}
  );

  const onRemoveContentLogs = async () => {
    try {
      onClose();
      await contentLogsStore.removeSelectedContentLogs();
      showSuccessToast();
      contentLogsStore.resetPageState();
      await contentLogsStore.load();
    } catch (error) {
      showErrorToast();
    }
  };

  return (
    <AlertDialog
      ctaLabel={removeReportLabel}
      data-testid="remove-content-log-modal"
      id={id}
      onCancel={onClose}
      onCta={onRemoveContentLogs}
      title={removeReportLabel}
      variant="destructiveWarning"
    >
      <Text>
        {intl.formatMessage({id: 'settings.contentLogs.removeReport.message'}, {selectedCount})}
      </Text>
    </AlertDialog>
  );
});

RemoveContentLogModal.propTypes = {
  /**
   * Function to close the alert dialog.
   */
  onClose: PropTypes.func.isRequired,
};

export default RemoveContentLogModal;
