import binky, {Locale, log} from '@admin-tribe/acsc';
import PropTypes from 'prop-types';
import React, {createContext, useContext, useEffect, useMemo, useState} from 'react';
import {useErrorHandler} from 'react-error-boundary';
import {IntlProvider} from 'react-intl';

import {getStrings, loadStrings} from '../../services/locales';
import {useConfiguration} from '../configuration/ConfigurationProvider';

const {localeReady} = binky.ready;

const LocaleContext = createContext();

/**
 * Provides Locale data to its children components.
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The children components.
 * @returns {React.ReactNode} The wrapped children components.
 */

const LocaleProvider = ({children}) => {
  const [localeObject, setLocaleObject] = useState(null);
  const [localeLoaded, setLocaleLoaded] = useState(false);
  const [localeStrings, setLocaleStrings] = useState({});
  const {configurationLoaded} = useConfiguration();
  const handleError = useErrorHandler();

  // Fetch data from the Locale file
  useEffect(() => {
    const fetchData = async () => {
      if (!configurationLoaded) return;

      try {
        localeReady.run();
        const locale = Locale.get();
        const language = locale.activeLanguage;
        await loadStrings(language);
        const strings = getStrings(language);

        setLocaleStrings(strings);
        setLocaleObject(locale);
        setLocaleLoaded(true);
      } catch (error) {
        log.error('Error fetching locale data:', error);
        handleError(error);
      }
    };
    fetchData();
  }, [configurationLoaded, handleError]);

  const contextValue = useMemo(
    () => ({localeLoaded, localeObject, localeStrings}),
    [localeLoaded, localeObject, localeStrings]
  );

  return (
    localeLoaded && (
      <IntlProvider
        defaultLocale="en"
        locale={localeObject.activeLanguage}
        messages={localeStrings}
      >
        <LocaleContext.Provider value={contextValue}>{children}</LocaleContext.Provider>
      </IntlProvider>
    )
  );
};

LocaleProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useLocale = () => useContext(LocaleContext);

export {LocaleProvider, useLocale};
