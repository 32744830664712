import {collaborationPolicyService, feature, log} from '@admin-tribe/acsc';
import {
  Page,
  PageActions,
  PageContent,
  PageHeader,
  PageNav,
  showError,
  showSuccess,
} from '@admin-tribe/acsc-ui';
import {Button} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';
import {goToSharedProjects} from 'features/storage/routing/navigation-callbacks/navigationCallbacks';

import SharedProjectCreationPolicySection from '../../components/shared-project-creation-policy-section/SharedProjectCreationPolicySection';

import {
  SHARED_PROJECT_CREATION_POLICY_NAME,
  SHARED_PROJECT_CREATION_POLICY_VALUES,
} from './sharedProjectPoliciesConstants';

// The settings page for configuring "Shared projects".
// We display this as just "Projects", the "Shared" keyword is for dev-clarity and not to be shown in UX.
const SharedProjectPoliciesPage = observer(() => {
  const intl = useIntl();

  const [etag, setEtag] = useState('');
  const [isBumpered, setIsBumpered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [policy, setPolicy] = useState(SHARED_PROJECT_CREATION_POLICY_VALUES.ALL);

  const NAMESPACE = 'settings.sharedProjects';

  // get shared-project policy data to display in the page
  useEffect(() => {
    setIsLoading(true);

    // ONESIE-38205 -- We should update CollaborationPolicies to support this policy
    // to reuse similar code and avoid handling raw http responses
    const fetchCreationPolicy = async () => {
      try {
        const response = await collaborationPolicyService.getPolicies({
          orgId: rootStore.organizationStore.activeOrgId,
        });
        const projectCreationPolicy = response.data.find(
          (item) => item.name === SHARED_PROJECT_CREATION_POLICY_NAME
        );

        if (projectCreationPolicy) {
          setEtag(projectCreationPolicy.etag);
          setPolicy(
            projectCreationPolicy.value === 'true'
              ? SHARED_PROJECT_CREATION_POLICY_VALUES.ALL
              : SHARED_PROJECT_CREATION_POLICY_VALUES.ADMIN
          );
        } else {
          log.error('Can not find shared-project policy');
          setIsBumpered(true);
        }
      } catch (error) {
        log.error(`Error getting shared-project policy: ${error}`);
        setIsBumpered(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCreationPolicy();
  }, []);

  const onConfirmCreationPolicy = async (pendingValue) => {
    // ONESIE-38205 -- We should update CollaborationPolicies to support this policy
    // to reuse similar code and avoid handling raw http responses
    try {
      setIsLoading(true);
      const response = await collaborationPolicyService.setPolicies({
        orgId: rootStore.organizationStore.activeOrgId,
        policies: [
          {
            category: 'workflow',
            ifMatch: etag,
            name: SHARED_PROJECT_CREATION_POLICY_NAME,
            value: `${pendingValue === SHARED_PROJECT_CREATION_POLICY_VALUES.ALL}`,
          },
        ],
      });
      const responseData = response.data[0];

      if (responseData.code === 200) {
        setPolicy(pendingValue);
        setEtag(responseData.etag);
        showSuccess(intl.formatMessage({id: `${NAMESPACE}.toast.success`}));
      } else {
        throw responseData.status;
      }
    } catch (error) {
      // Precondition Failed (HTTP 412) indicates a policy has been updated and local copy needs refresh.
      const msg =
        error === 'Precondition Failed'
          ? intl.formatMessage({id: `${NAMESPACE}.toast.error.concurrency`})
          : intl.formatMessage({id: `${NAMESPACE}.toast.error`});
      showError(msg);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Page isBumpered={isBumpered} isLoading={isLoading}>
      <PageHeader title={intl.formatMessage({id: `${NAMESPACE}.title`})} />
      <PageNav />
      <PageActions>
        {feature.isEnabled('temp_storage_shared_projects') && (
          <Button onPress={() => goToSharedProjects()} variant="secondary">
            {intl.formatMessage({id: `${NAMESPACE}.header.button.viewProjects`})}
          </Button>
        )}
      </PageActions>
      <PageContent>
        <SharedProjectCreationPolicySection
          onConfirm={onConfirmCreationPolicy}
          selectedPolicy={policy}
        />
      </PageContent>
    </Page>
  );
});

export default SharedProjectPoliciesPage;
