import {feature} from '@admin-tribe/acsc';

import HvaCard from '../HvaCard';
import {HVA_ORDER, HVA_TYPE} from '../hvaConstants';

class TakeATourHva extends HvaCard {
  static get({intl}) {
    if (feature.isDisabled('gainsight_tracking')) {
      // The tour is powered by gainsight, so this HVA should be hidden if our connection is disabled.
      return Promise.reject();
    }

    return Promise.resolve([new TakeATourHva({intl})]);
  }

  constructor({intl}) {
    const namespace = 'overview.highValueActionArea.setUpTasks.takeATour';

    super({
      ctaLabel: intl.formatMessage({id: `${namespace}.ctaLabel`}),
      description: intl.formatMessage({id: `${namespace}.description`}),
      dismissOnCTA: true,
      header: intl.formatMessage({id: `${namespace}.header`}),
      icon: {
        src: 'https://odin.adobe.com/content/dam/admin-tribe/assets/hva-illustrations/take-tour.svg',
        style: {width: '40px'},
      },
      id: 'hva-take-a-tour',
      isDismissible: true,
      onCTA: () => {}, // no-op since Gainsight controls this
      priorityOrder: HVA_ORDER.TAKE_TOUR,
      sectionTitle: intl.formatMessage({id: `${namespace}.sectionTitle`}),
      type: HVA_TYPE.SET_UP,
    });
  }
}

export default TakeATourHva;
