import {Page, PageContent, PageHeader, useStore} from '@admin-tribe/acsc-ui';
import {observer} from 'mobx-react-lite';
import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';
import {useParams} from 'react-router-dom';

import PregeneratedPackagesTabs from 'features/packages/components/pregenerated-packages/PregeneratedPackagesTabs';
import PackagesAdobeTemplatesStore from 'features/packages/stores/PackagesAdobeTemplatesStore';

import {PregeneratedPackagesPageContextProvider} from './PregeneratedPackagesPageContext';

/**
 * Top level page for pre-generated packages
 */
const PregeneratedPackagesPage = observer(() => {
  const intl = useIntl();
  const {orgId} = useParams();

  const packagesAdobeTemplatesStore = useStore(() => new PackagesAdobeTemplatesStore());

  // Initial load of the templates list.
  useEffect(() => {
    packagesAdobeTemplatesStore.load();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- required to execute this only on mount
  }, []);

  return (
    <PregeneratedPackagesPageContextProvider
      packagesAdobeTemplatesStore={packagesAdobeTemplatesStore}
    >
      <Page
        data-testid="pre-generated-packages-page"
        isLoading={packagesAdobeTemplatesStore.isLoading}
      >
        <PageHeader
          title={intl.formatMessage({
            id: 'packages.pregeneratedPackages.title',
          })}
        />
        <PageContent>
          <PregeneratedPackagesTabs orgId={orgId} />
        </PageContent>
      </Page>
    </PregeneratedPackagesPageContextProvider>
  );
});

export default PregeneratedPackagesPage;
