import {
  AuthenticatedUser,
  LinkedUserAccountList,
  OrganizationList,
  authentication,
  log,
} from '@admin-tribe/acsc';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useState} from 'react';
import {useErrorHandler} from 'react-error-boundary';
import {useIntl} from 'react-intl';

import {useAuthentication} from 'core/providers/authentication/AuthenticationProvider';

import ErrorBumper from '../../errorComponents/ErrorBumper';

/**
 * Retrieves the organizations and linked user accounts.
 *
 * @returns {Promise<Object>} A promise that resolves to an object containing the linked user account list and organization list.
 */
const getOrganizationsAndLinkedUserAccounts = async () => {
  const [organizationList, linkedUserAccountList] = await Promise.all([
    OrganizationList.get(),
    LinkedUserAccountList.get({includePaths: true, userId: AuthenticatedUser.get().getId()}),
  ]);
  return {linkedUserAccountList, organizationList};
};

/**
 * Handles the organization change event.
 *
 * @param {Object} params - The parameters for the organization change event.
 * @param {boolean} params.authenticationLoaded - Indicates if the authentication has been loaded.
 * @param {string} params.orgId - The ID of the organization.
 * @param {string} params.profileUserId - The ID of the profile user.
 * @param {string} params.userId - The ID of the user.
 * @returns {Promise<void>} - A promise that resolves when the organization change is complete.
 */
const orgChangeHandler = async ({authenticationLoaded, orgId, profileUserId, userId}) => {
  if (!authenticationLoaded) return;
  const authenticatedUserId = profileUserId;
  // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- window.location is used to get the current pathname
  const modifiedPathname = window.location.pathname.replace(/\/[^/]+@AdobeOrg\//, '/');

  const redirectPathSegment = modifiedPathname === '/' ? '/overview' : modifiedPathname;

  if (authenticatedUserId === userId) {
    // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- window.location is used to get the current protocol, hostname, and port
    const redirectUrl = `${window.location.protocol}//${window.location.hostname}${
      // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- window.location is used
      window.location.port ? `:${window.location.port}` : ''
    }/${orgId}${redirectPathSegment}`;
    // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- window.location is used to redirect the user
    window.location.href = redirectUrl;
  } else {
    await authentication.switchTo(userId, `${orgId}${redirectPathSegment}`);
  }
};

/**
 * OrgSwitcher component displays a dropdown for switching between organizations.
 *
 * @param {Object} props - The properties that define the OrgSwitcher component.
 * @param {string} props.title - The title of the OrgSwitcher.
 * @param {string} props.details - The details to display in the OrgSwitcher.
 * @param {string} [props.iconType='Cloud'] - The type of the icon to display in the OrgSwitcher.
 * @returns {JSX.Element} The OrgSwitcher component.
 */
const OrgSwitcher = ({title, details, iconType = 'Cloud'}) => {
  const {profile, authenticationLoaded} = useAuthentication();

  const [orgSwitcherConfig, setOrgSwitcherConfig] = useState(null);

  const [hasError, setHasError] = useState(false);

  const handleError = useErrorHandler();

  const intl = useIntl();

  const titleText = title || intl.formatMessage({id: 'main.routes.orgNotFound.title'});
  const detailsText = details || intl.formatMessage({id: 'main.routes.orgNotFound.details'});
  const buttonLabelText = intl.formatMessage({id: 'main.routes.bumper.button.primary'});

  const onOrgChange = useCallback(
    async ({orgId, userId}) => {
      try {
        await orgChangeHandler({
          authenticationLoaded,
          orgId,
          profileUserId: profile.userId,
          userId,
        });
      } catch (error) {
        log.error('Error changing organization:', error);
        setHasError(true);
        handleError(error);
      }
    },
    [authenticationLoaded, profile.userId, handleError]
  );

  const loadOrgSwitcher = useCallback(async () => {
    const {linkedUserAccountList} = await getOrganizationsAndLinkedUserAccounts();

    const orgSwitcherConfiguration = {
      activeOrgId: null,
      authenticatedUserId: profile.authId,
      filterToTypes: ['ENTERPRISE', 'DIRECT'],
      linkedUserAccounts: linkedUserAccountList?.items,
      onChange: ({orgId, userId}) => {
        onOrgChange({orgId, userId});
      },
    };
    setOrgSwitcherConfig(orgSwitcherConfiguration);
  }, [onOrgChange, profile.authId]);

  // Load the org switcher
  useEffect(() => {
    loadOrgSwitcher();
  }, [loadOrgSwitcher]);

  return (
    !hasError &&
    orgSwitcherConfig && (
      <ErrorBumper
        buttonLabelText={buttonLabelText}
        data-testid="error-bumper-id"
        detailsText={detailsText}
        iconType={iconType}
        onButtonClick={() => {
          // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- window.location is used
          window.history.back();
        }}
        orgSwitcherProps={orgSwitcherConfig}
        titleText={titleText}
      />
    )
  );
};

OrgSwitcher.propTypes = {
  details: PropTypes.string,
  iconType: PropTypes.string,
  title: PropTypes.string,
};

export {orgChangeHandler, getOrganizationsAndLinkedUserAccounts};
export default OrgSwitcher;
