import {Product} from '@admin-tribe/acsc';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import {renderNoAccessDrawer} from 'features/products/components/product-page-info-bar/product-page-info-bar-constructor/ProductPageInfoBarConstructor';

import NoAccessDrawerTriggerContainer from './NoAccessDrawerTriggerContainer';

/**
 * NoAccessDrawerContainer
 *  is a container component that fetches data and renders
 * the NoAccessDrawerTriggerContainer with the fetched content.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.intl - The internationalization object.
 * @param {Object} props.statusLightProps - The props to be passed to NoAccessDrawerTriggerContainer's UpdatedUserStatusContextualHelp's StatusLight.
 * @param {boolean} props.isContextualHelpOpen - Flag indicating whether contextual help is open.
 * @param {Object} props.product - The product data.
 * @returns {React.ReactNode} - Rendered NoAccessDrawerContainer
 *  component.
 */
const NoAccessDrawerContainer = ({statusLightProps, hasContextualHelp, product}) => {
  const intl = useIntl();
  const [drawer, setDrawer] = useState(null);

  /**
   * Fetches data using renderNoAccessDrawer and sets the result to the 'drawer' state.
   * This side effect runs once when the component mounts or when 'intl' or 'product' props change.
   */
  useEffect(() => {
    const fetchData = async () => {
      const result = await renderNoAccessDrawer({intl, product});
      setDrawer(result);
    };
    fetchData();
  }, [intl, product]);

  return (
    <NoAccessDrawerTriggerContainer
      drawer={drawer}
      hasContextualHelp={hasContextualHelp}
      statusLightProps={statusLightProps}
    />
  );
};

NoAccessDrawerContainer.propTypes = {
  hasContextualHelp: PropTypes.bool,
  product: PropTypes.instanceOf(Product).isRequired,
  statusLightProps: PropTypes.shape({
    variant: PropTypes.string.isRequired,
  }).isRequired,
};

export default NoAccessDrawerContainer;
