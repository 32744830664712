import {feature} from '@admin-tribe/acsc';
import React from 'react';

import {
  canAccessAccountPage,
  canAccessAccountPageChildRoutes,
  canViewAccountOrganizationDetails,
  canViewAccountOverviewPage,
  canViewAddProductsOnAccount,
  canViewBillingHistory,
  canViewContractDetails,
} from 'core/account/access/accountAccess';
import {buildAccessCheckLoader} from 'core/router/loaders';
import AccountWorkspace from 'features/account/pages/AccountWorkspace';
import {defaultSectionLoader} from 'features/account/routing/accountRoutesLoaders';

import AccountBillingHistory from '../pages/AccountBillingHistory';
import AccountOverviewPage from '../pages/AccountOverviewPage';
import AccountPage from '../pages/AccountPage';
import OrganizationDetails from '../pages/OrganizationDetails';

import {
  PATH_ACCOUNT_ADD_PRODUCTS,
  PATH_ACCOUNT_BILLING_HISTORY,
  PATH_ACCOUNT_CANCEL_LICENSES,
  PATH_ACCOUNT_CONTRACT_DETAILS,
  PATH_ACCOUNT_ORGANIZATION_DETAILS,
  PATH_ACCOUNT_OVERVIEW,
  PATH_ACCOUNT_ROOT,
} from './accountPaths';

/** Gathers all of the account routes */
const accountRoutes = () => {
  const routes = [
    {
      children: [
        {
          element: <AccountOverviewPage />,
          loader: canViewAccountOverviewPage,
          path: PATH_ACCOUNT_OVERVIEW,
        },
        {
          element: <AccountPage />,
          loader: feature.isEnabled('temp_contract_admin_pa_4759')
            ? buildAccessCheckLoader(canViewContractDetails)
            : canAccessAccountPage,
          path: PATH_ACCOUNT_CONTRACT_DETAILS,
        },
        {
          element: <AccountBillingHistory />,
          loader: feature.isEnabled('temp_account_child_routes_redirect')
            ? canAccessAccountPageChildRoutes(canViewBillingHistory)
            : canViewBillingHistory,
          path: PATH_ACCOUNT_BILLING_HISTORY,
        },
        {
          element: <OrganizationDetails />,
          loader: feature.isEnabled('temp_account_child_routes_redirect')
            ? canAccessAccountPageChildRoutes(canViewAccountOrganizationDetails)
            : canViewAccountOrganizationDetails,
          path: PATH_ACCOUNT_ORGANIZATION_DETAILS,
        },
        {
          element: <AccountPage />,
          loader: feature.isEnabled('temp_account_child_routes_redirect')
            ? canAccessAccountPageChildRoutes(canViewAddProductsOnAccount)
            : canViewAddProductsOnAccount,
          path: PATH_ACCOUNT_ADD_PRODUCTS,
        },
        {
          element: <AccountPage />,
          loader: canAccessAccountPage,
          path: PATH_ACCOUNT_CANCEL_LICENSES,
        },
      ],
      element: <AccountWorkspace />,
      loader: feature.isEnabled('temp_pa_6055_account_bug_35700') ? defaultSectionLoader : null,
      path: PATH_ACCOUNT_ROOT,
      shouldRevalidate: () => true,
    },
  ];

  return routes;
};

export default accountRoutes;
