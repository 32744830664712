import {log, observabilityMetrics} from '@admin-tribe/acsc';
import {useEffect, useState} from 'react';

import {useConfiguration} from '../providers/configuration/ConfigurationProvider';
import {useOrganization} from '../providers/organization/OrganizationProvider';

/**
 * Custom React hook to configure Observability.
 *
 * @returns {void}
 */
const useObservability = () => {
  const {configuration} = useConfiguration();
  const {organization, organizationsLoaded} = useOrganization();
  const [observabilityLoaded, setObservabilityLoaded] = useState(false);

  // Fetch data from the Feature file
  useEffect(() => {
    const fetchData = async () => {
      if (!organizationsLoaded) return;

      try {
        await observabilityMetrics.initialize(configuration.services.observability);
        setObservabilityLoaded(true);
      } catch (error) {
        log.error('Failed to configure Observability. Error:', error);
      }
    };

    fetchData();
  }, [configuration, organization, organizationsLoaded]);
  return {observabilityLoaded};
};

export default useObservability;
