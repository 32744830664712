import {Product} from '@admin-tribe/acsc';
import {ActionButton, Button, Text, Tooltip, TooltipTrigger} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import AddUsersToProductModal from 'common/components/add-user-modals/AddUsersToProductModal';
import {PRODUCT_TABLE_ACTION_VARIANT} from 'common/services/product-summary-list/ProductSummaryListConstants';
import rootStore from 'core/RootStore';
import chatProvider from 'core/services/chat/chatProvider';
import ProductGroupProductList from 'core/services/product/product-group/product-list/ProductGroupProductList';
import {ADD_PRODUCT_WORKFLOWS} from 'features/products/components/add-product-modal-wrapper/AddProduct.constants';
import AddProductModalWrapper from 'features/products/components/add-product-modal-wrapper/AddProductModalWrapper';
import {TRACKING_IDS} from 'features/settings/common/constants/testingAndOptimizationConstants';

import {
  getProductTableActionConfig,
  sendAddLicenseCloseAnalytics,
  sendAddLicenseOpenAnalytics,
  sendAddLicenseSuccessAnalytics,
} from './productTableActionButtonUtils';

/**
 * Button that encapsulates the possible table actions for the product table
 * introduced as part of PA-6055.
 */
const ProductTableActionButton = ({model, orgId, variant}) => {
  const intl = useIntl();

  const [isAddProductsModalOpen, setIsAddProductsModalOpen] = useState(false);
  const [isAddUsersModalOpen, setIsAddUsersModalOpen] = useState(false);

  const openAddProductsModel = () => {
    setIsAddProductsModalOpen(true);
    sendAddLicenseOpenAnalytics();
  };

  const closeAddProductsModel = () => {
    setIsAddProductsModalOpen(false);
    sendAddLicenseCloseAnalytics();
  };

  const {icon, label, onPress} = getProductTableActionConfig({
    intl,
    model,
    onAddProductsModalOpen: openAddProductsModel,
    onAddUsersModalOpen: () => setIsAddUsersModalOpen(true),
    variant,
  });

  const cartItems = [];

  if (variant === PRODUCT_TABLE_ACTION_VARIANT.BUY_LICENSES && isAddProductsModalOpen) {
    const product =
      model.id && rootStore.organizationStore.productList.items.find((p) => p.id === model.id);

    if (product) {
      cartItems.push({
        offerId: product.offerId,
        quantity: 1,
      });
    }
  }

  let button;
  if (variant === PRODUCT_TABLE_ACTION_VARIANT.BUY_LICENSES) {
    button = (
      <Button
        aria-label={label}
        data-testid="product-action-buy-licenses-button"
        marginEnd="size-100"
        onPress={onPress}
        variant="primary"
      >
        {icon}
        <Text>
          <FormattedMessage id="products.allProducts.actions.buy" />
        </Text>
      </Button>
    );
  } else {
    button = (
      <ActionButton
        aria-label={label}
        data-testid="product-action-button"
        isQuiet
        marginEnd="size-100"
        onPress={onPress}
      >
        {icon}
      </ActionButton>
    );
  }

  return (
    <>
      <TooltipTrigger>
        {button}
        <Tooltip>{label}</Tooltip>
      </TooltipTrigger>
      {variant === PRODUCT_TABLE_ACTION_VARIANT.ADD_USERS && isAddUsersModalOpen && (
        <AddUsersToProductModal
          isOpen
          onClosed={() => setIsAddUsersModalOpen(false)}
          orgId={orgId}
          productId={model.id}
        />
      )}
      {variant === PRODUCT_TABLE_ACTION_VARIANT.BUY_LICENSES && isAddProductsModalOpen && (
        <AddProductModalWrapper
          chat={chatProvider}
          contractId={model.contractId}
          items={cartItems}
          onClose={closeAddProductsModel}
          onSuccess={sendAddLicenseSuccessAnalytics}
          tracking={TRACKING_IDS.BUY_LICENSE_DIRECT_TRACKING_ID}
          workflow={ADD_PRODUCT_WORKFLOWS.ADD_LICENSE}
        />
      )}
    </>
  );
};

ProductTableActionButton.propTypes = {
  /** Product or ProductGroupProductList supplied to generate action properties. */
  model: PropTypes.oneOfType([
    PropTypes.instanceOf(Product),
    PropTypes.instanceOf(ProductGroupProductList),
  ]).isRequired,
  /** Org id to be supplied for the add users action. */
  orgId: PropTypes.string,
  /**
   * Action variant that should be shown. Should be one of the four values
   * available in the PRODUCT_TABLE_ACTION_VARIANT constant.
   */
  variant: PropTypes.oneOf(Object.values(PRODUCT_TABLE_ACTION_VARIANT)).isRequired,
};

export default ProductTableActionButton;
