import {FULFILLABLE_ITEM_CODE, feature} from '@admin-tribe/acsc';

import rootStore from 'core/RootStore';
import {canShowBuyingProgram} from 'core/products/access/productAccess';
import trialHelper from 'core/products/trial-helper/trialHelper';

/**
 * @description To each product in the list, adds any tags that should be displayed with this
 *   product. This is particularly useful to display tags on a product-based Pill.
 * @param {Object} intl - internationalization service
 * @param {ProductList}  productList instance - the assumption is that the get does a deep clone of
 *   this list and its models so modifying it here will only impact this instance of the list.
 * @param {Object}  options -
 * @param {Array}  [options.filteredProducts] - optional partial list of the ProductList items to tag.
 *   If omitted, productList.items is tagged.
 */
function addTagsToProducts(intl, productList, {filteredProducts} = {}) {
  const products = filteredProducts || productList.items;
  products.forEach((product) => {
    product.tagList = getProductTags(intl, product);
  });
}

/**
 * @description Method to construct a list of TagGroup item data to represent a
 *   given Product's tags
 * @param {Object} intl - internationalization service
 * @param {Product|ProductGroup} productOrProductGroup - to build tags from
 * @returns {Object[]} - list of tags (ids/values) that apply to product
 */
function getProductTags(intl, productOrProductGroup) {
  const result = [];
  // support level (if any) tag
  checkAddSupportLevelTag(result, productOrProductGroup);
  // trial tag
  checkAddTrialTag(intl, result, productOrProductGroup);
  // buying program tag
  checkAddBuyingProgramTag(result, productOrProductGroup);
  return result;
}

////////

function checkAddBuyingProgramTag(tagList, productOrProductGroup) {
  if (canShowBuyingProgram(rootStore.organizationStore.productList, productOrProductGroup)) {
    tagList.push({
      key: productOrProductGroup.buyingProgram,
      label: productOrProductGroup.buyingProgram,
    });
  }
}

function checkAddSupportLevelTag(tagList, productOrProductGroup) {
  if (feature.isEnabled('temp_parkour_mm')) {
    const supportLevel = productOrProductGroup.getFulfillableItemNameByCode(
      FULFILLABLE_ITEM_CODE.SUPPORT_PLAN_LEVEL
    );
    if (supportLevel) {
      tagList.push({
        key: 'supportLevel',
        label: supportLevel,
      });
    }
  }
}

function checkAddTrialTag(intl, tagList, productOrProductGroup) {
  if (trialHelper.isTrialProduct(productOrProductGroup)) {
    tagList.push({
      key: 'trial',
      label: intl.formatMessage({id: 'common.tagGroup.tag.trial'}),
    });
  }
}

export {addTagsToProducts, getProductTags};
