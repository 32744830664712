import {download, jilProducts, log} from '@admin-tribe/acsc';
import {showError, showInfo} from '@admin-tribe/acsc-ui';
import {Button} from '@adobe/react-spectrum';
import React from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';

/**
 * Renders the export to csv button for product profile page which exports all deactivated product devices.
 */
const ExportDeactivatedDevicesButton = () => {
  const intl = useIntl();
  const orgId = rootStore.organizationStore.activeOrgId;
  const [isLoading, setIsLoading] = React.useState(false);

  const onExportCallback = async () => {
    setIsLoading(true);
    try {
      showInfo(
        intl.formatMessage({
          id: 'products.details.deactivated.devices.downloadBeingPrepared',
        })
      );
      const response = await jilProducts.exportDeactivatedDevices({
        orgId,
      });
      download(response?.data.file, 'deactivated-devices.csv');
    } catch (error) {
      log.error(`Failed to download deactivated product devices for ${orgId}. Error: `, error);
      showError();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button
      data-testid="export-deactivated-devices-button"
      isDisabled={isLoading}
      onPress={() => onExportCallback()}
      UNSAFE_style={{cursor: isLoading ? 'not-allowed' : 'pointer', marginLeft: '1rem'}}
      variant="secondary"
    >
      {intl.formatMessage({
        id: 'products.details.deactivated.devices.exportDevicesButton',
      })}
    </Button>
  );
};

export default ExportDeactivatedDevicesButton;
