import {EVENT_ACTION} from '@admin-tribe/acsc';
import binkyUI from '@admin-tribe/acsc-ui';
import {Link, View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {dispatchEvent} from '../../models/notificationUtils';
import {ANALYTIC_EVENT} from '../../notifications.constants';

const GoUrl = binkyUI.common.components.GoUrl;

const NotificationLearnMore = ({notification}) => {
  const {payload} = notification;
  const intl = useIntl();

  const dispatchAnalyticsEvent = () => {
    dispatchEvent({
      action: EVENT_ACTION.CLICK,
      name: ANALYTIC_EVENT.NAME.LINK,
      notification,
    });
  };

  return (
    <View marginTop="-10px">
      {payload.learn_more_link && (
        <Link onPress={dispatchAnalyticsEvent}>
          <a
            aria-describedby={intl.formatMessage({
              id: 'notifications.card.learnMore.describedby',
            })}
            href={payload.learn_more_link}
            rel="noopener noreferrer"
            target="_blank"
          >
            <span className="learn-more-link">
              <FormattedMessage id="notifications.card.learnMore.label" />
            </span>
          </a>
        </Link>
      )}
      {payload.learn_more_go_url && (
        <GoUrl
          ariaDescribedby={intl.formatMessage({
            id: 'notifications.card.learnMore.describedby',
          })}
          name={payload.learn_more_go_url}
          onPressStart={dispatchAnalyticsEvent}
        >
          <FormattedMessage id="notifications.card.learnMore.label" />
        </GoUrl>
      )}
    </View>
  );
};

NotificationLearnMore.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types -- Placeholder for object with type - payload
  notification: PropTypes.object.isRequired,
};

export default NotificationLearnMore;
