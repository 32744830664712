import {ORGANIZATION_MARKET_SEGMENT, feature} from '@admin-tribe/acsc';
import React, {useCallback} from 'react';

import useModalContent from 'common/components/aem/aem-content/useModalContent';
import AemModal from 'common/components/aem/aem-modal/AemModal';
import {aemContentFetcher} from 'common/components/aem/aemContentHelper';
import SophiaModal from 'common/components/sophia/sophia-modal/SophiaModal';
import useSophiaModal from 'common/components/sophia/sophia-modal/useSophiaModal';
import rootStore from 'core/RootStore';
import {isOrgAdmin} from 'core/organizations/access/organizationAccess';
import chatProvider from 'core/services/chat/chatProvider';
import AddProductModalWrapper from 'features/products/components/add-product-modal-wrapper/AddProductModalWrapper';
import useAddProductsModalFromDeeplink from 'features/products/hooks/useAddProductsModalFromDeeplink';

/**
 * Handle showing of modals on Overview page
 */
const ModalManager = () => {
  const {
    closeModal: closeSophiaModal,
    isOpen: isSophiaModalEligible,
    sophiaModalContent,
    trigger: triggerSophia,
  } = useSophiaModal();
  const {
    isModalOpen: isAddProductsModalEligible,
    props: addProductsModalProps,
    closeModal: closeAddProductsModal,
  } = useAddProductsModalFromDeeplink('overview.add-products');

  const {
    isOpen: canShowAemModal,
    closeModal: closeAemModal,
    content: aemModalContent,
    trigger: triggerAem,
  } = useModalContent(useCallback(aemContentFetcher, []));

  const marketSegment = rootStore.organizationStore.activeOrg.marketSegment;

  if (isAddProductsModalEligible) {
    return (
      <AddProductModalWrapper
        chat={chatProvider}
        onClose={closeAddProductsModal}
        {...addProductsModalProps}
      />
    );
  }
  if (
    feature.isEnabled('temp_b2b_free_provisioning') &&
    marketSegment === ORGANIZATION_MARKET_SEGMENT.COMMERCIAL &&
    isOrgAdmin()
  ) {
    triggerAem();
    if (canShowAemModal) {
      return <AemModal closeModal={closeAemModal} content={aemModalContent} />;
    }
  }

  triggerSophia();
  if (isSophiaModalEligible) {
    return <SophiaModal closeModal={closeSophiaModal} content={sophiaModalContent} />;
  }
  return null;
};

export default ModalManager;
