import {ModalDescription, ModalHeading} from '@admin-tribe/acsc-ui';
import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';

import {useFreeOfferModalContext} from './FreeOfferModalContext';

const NoOffersDetailsPage = () => {
  const intl = useIntl();

  const {setIsValid} = useFreeOfferModalContext();

  // set to true
  useEffect(() => {
    setIsValid(true);
  }, [setIsValid]);

  return (
    <>
      <ModalHeading>
        {intl.formatMessage({id: 'offers.freeOfferModal.offerDetails.noOffers.header'})}
      </ModalHeading>
      <ModalDescription>
        {intl.formatMessage({id: 'offers.freeOfferModal.offerDetails.noOffers.text'})}
      </ModalDescription>
    </>
  );
};

export default NoOffersDetailsPage;
