import {Cache} from '@admin-tribe/acsc';
import {STATE_CHANGED, TeamFilter, TeamOrder} from '@ccx-public/shared-asset-model-js';
import {SHARED_PROJECT_STATE} from '@pandora/react-shared-projects';

const SHARED_PROJECT_COUNT_CACHE_ID = 'ActiveSharedProjectCountCacheId';

const sharedProjectsUtilsCache = new Cache();

const getSharedProjectList = async (
  sharedAssetModel,
  projectState = SHARED_PROJECT_STATE.ACTIVE
) => {
  const teamFilter =
    projectState === SHARED_PROJECT_STATE.ACTIVE
      ? TeamFilter.ActiveTeams
      : TeamFilter.DiscardedTeams;
  const projectList = sharedAssetModel.spaceContext
    .getTeams()
    .getList(TeamOrder.default, teamFilter);
  await projectList.refresh();
  await new Promise((resolve) => {
    projectList.on(STATE_CHANGED, resolve);
  });
  return projectList;
};

/**
 * @description Get the active project count
 *
 * @param {ISAMCore} sharedAssetModel - shared asset model
 * @returns {Promise<number>} a promise which resolves with the active project count
 */
const getActiveSharedProjectCount = async (sharedAssetModel) => {
  const cachedPromise = sharedProjectsUtilsCache.get(SHARED_PROJECT_COUNT_CACHE_ID);
  if (cachedPromise) {
    return cachedPromise;
  }
  const projectList = await getSharedProjectList(sharedAssetModel);
  sharedProjectsUtilsCache.put(SHARED_PROJECT_COUNT_CACHE_ID, projectList.total);
  return projectList.total;
};

/**
 * @description Get the project object given a project ID
 *
 * @param {ISAMCore} sharedAssetModel - shared asset model
 * @param {string} projectId - ID of the project
 * @param {SHARED_PROJECT_STATE} projectState - Fetch active or discarded projects. Defaults to active.
 * @returns {Promise} a promise which resolves with the given project (or undefined if there is no match)
 */
const getSharedProjectFromId = async (
  sharedAssetModel,
  projectId,
  projectState = SHARED_PROJECT_STATE.ACTIVE
) => {
  const projectList = await getSharedProjectList(sharedAssetModel, projectState);
  return projectList.items.find((item) => item.id === projectId);
};

export {getActiveSharedProjectCount, getSharedProjectFromId, SHARED_PROJECT_COUNT_CACHE_ID};
