const DEVICE_STATUS = {
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED',
  OVER_ACTIVATED: 'OVER_ACTIVATED',
};

const DEVICE_SORT_COLUMN = {
  DAYS_TO_EXPIRE: 'daysToExpire',
  DEVICE_ID: 'deviceId',
  DEVICE_NAME: 'deviceName',
  EXPIRES_DATE_STR: 'expiresDateStr',
  LAST_ACTIVITY_DATE_NUMERIC: 'lastActivityDateNumeric',
  PRODUCT_NAME: 'productName',
};

const DEVICE_API_ERROR = {
  BULK_RESET_IN_PROGRESS: 409,
  RECOVERY_NOT_PERMITTED: 'ERR001',
};

export {DEVICE_API_ERROR, DEVICE_STATUS, DEVICE_SORT_COLUMN};
