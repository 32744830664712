import {feature} from '@admin-tribe/acsc';
import {Content, Heading, IllustratedMessage, View} from '@adobe/react-spectrum';
import {CLOUD} from '@pandora/administration-core-types';
import NoSearchResults from '@spectrum-icons/illustrations/NoSearchResults';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';

// Component for showing the no results illustrated message when no products are
// found on the all products page introduced with PA-6055.
const AllProductsNoSearchResultsIllustratedMessage = ({searchResults}) => {
  const intl = useIntl();

  const isProductListEmpty = rootStore.organizationStore.productList.items.length === 0;

  return (
    (feature.isDisabled('temp_ctir_18512_product_offers') || searchResults.OFFERS.length === 0) &&
    !isProductListEmpty &&
    searchResults[CLOUD.CREATIVE].length === 0 &&
    searchResults[CLOUD.DOCUMENT].length === 0 &&
    searchResults[CLOUD.EXPERIENCE].length === 0 &&
    searchResults[CLOUD.OTHERS].length === 0 && (
      <View backgroundColor="gray-50" paddingY="size-800">
        <IllustratedMessage>
          <NoSearchResults />
          <Heading level={2}>
            {intl.formatMessage({id: 'products.allProducts.noSearchResults.heading'})}
          </Heading>
          <Content>
            {intl.formatMessage({id: 'products.allProducts.noSearchResults.message'})}
          </Content>
        </IllustratedMessage>
      </View>
    )
  );
};

AllProductsNoSearchResultsIllustratedMessage.propTypes = {
  /** Object containing the product summary list entries grouped by cloud */
  searchResults: PropTypes.shape({
    /* eslint-disable react/forbid-prop-types -- existing usage */
    [CLOUD.CREATIVE]: PropTypes.arrayOf(PropTypes.object),
    [CLOUD.DOCUMENT]: PropTypes.arrayOf(PropTypes.object),
    [CLOUD.EXPERIENCE]: PropTypes.arrayOf(PropTypes.object),
    [CLOUD.OTHERS]: PropTypes.arrayOf(PropTypes.object),
    OFFERS: PropTypes.arrayOf(PropTypes.object),
    /* eslint-enable react/forbid-prop-types -- existing usage */
  }).isRequired,
};

export default AllProductsNoSearchResultsIllustratedMessage;
