import {feature, log, modelCache} from '@admin-tribe/acsc';
import {BigDecimal} from '@pandora/big-decimal';
import {
  FILE_SIZE_UNITS,
  fetchReadableUnitsOfStorage,
  getNumBytes,
} from '@pandora/react-formatted-file-size';

import StorageTopFolder from 'common/entities/storage-top-folder/StorageTopFolder';
import rootStore from 'core/RootStore';

import contentPlatformVa6Direct from '../../../core/content-platform/contentPlatformVa6Direct';

import {CACHE_ID, USAGE_THRESHOLD, USAGE_TYPES} from './StorageStatsConstants';

const bigDecimalZero = new BigDecimal(0);

class StorageStats {
  /**
   * @description instantiates a new instance of StorageStats.
   *
   * @returns {StorageStats} StorageStats model object.
   */
  static get() {
    const model = new StorageStats();
    const key = StorageStats.getKey();
    if (modelCache.has(CACHE_ID, key)) {
      const cachedPromise = modelCache.get(CACHE_ID, key);
      if (cachedPromise) {
        return cachedPromise;
      }
    }

    // Item is not already cached.
    return model.refresh();
  }

  /**
   * @description Fetch the storage stats key, used for caching.
   *
   * @returns {String} the key for the instance of the StorageStats for this org.
   */
  static getKey() {
    return rootStore.organizationStore.activeOrgId;
  }

  /**
   * @description Creates a new StorageStats for use.
   *
   * @param {Object} options Initialization Object (params described below)
   * @param {Array} options.assetsTopFolder - A list of shared folders with the
   *    highest usage among the org.
   * @param {Object} options.orgAssetsQuota - The shared folder quota usage of the current org.
   * @param {Object} options.orgOthersQuota - Remaining quota usage of the current org.
   * @param {Object} options.orgDeletedQuota - The deleted quota usage of the current org.
   * @param {Object} options.orgReclaimedQuota - The reclaimed quota usage of the current org.
   * @param {Object} options.orgReportsQuota - The reports quota usage of the current org.
   * @param {Object} options.orgTotalQuota - The total quota of the current org.
   * @param {Object} options.orgUsersQuota - The user folder quota usage of the current org.
   * @param {Array} options.usersTopFolder - A list of user folders with the
   *    highest usage among the org.
   */
  constructor(options = {}) {
    if (feature.isEnabled('temp_ctir_20442')) {
      this.#updateModelV2(options);
    } else {
      this.#updateModel(options);
    }
  }

  /**
   * @description Define the folders. Create defined folder objects for each folder.
   *
   * @returns {Object[]} the defined folders or an empty array if no folders exist.
   * @returns {getDefinedQuota Object} consumedQuota -  the consumed quota of the folder.
   * @returns {getDefinedQuota Object} totalQuota - the total quota of the folder.
   * @returns {getDefinedQuota Object} utilizedQuota - the utilized quota of the folder.
   */
  #getDefinedFolders(folders) {
    return (
      folders?.map((folder) => ({
        ...folder,
        // Fill in folder's quota in case incomplete:
        consumedQuota: this.#getDefinedQuota(folder.consumedQuota),
        totalQuota: this.#getDefinedQuota(folder.totalQuota),
        utilizedQuota: this.#getDefinedQuota(folder.utilizedQuota),
      })) || []
    );
  }

  // eslint-disable-next-line class-methods-use-this -- private function
  #getDefinedQuota(quota) {
    return {
      unit: FILE_SIZE_UNITS[0],
      ...quota,
      value: Number(quota?.value || '0') === 0 ? '0' : quota.value,
    };
  }

  /**
   * @description Initializes Storage Stats data.
   *
   * @param {Object} options initialization object (as described in constructor options parameter).
   */
  #updateModel(options) {
    Object.assign(this, {
      // Set defaults in case not all storage stats are defined
      assetsTopFolder: this.#getDefinedFolders(options.assetsTopFolder),
      orgAssetsQuota: this.#getDefinedQuota(options.orgAssetsQuota),
      orgDeletedQuota: this.#getDefinedQuota(options.orgDeletedQuota),
      orgReclaimedQuota: this.#getDefinedQuota(options.orgReclaimedQuota),
      orgReportsQuota: this.#getDefinedQuota(options.orgReportsQuota),
      orgTotalQuota: this.#getDefinedQuota(options.orgTotalQuota),
      orgUsersQuota: this.#getDefinedQuota(options.orgUsersQuota),
      usersTopFolder: this.#getDefinedFolders(options.usersTopFolder),
    });
  }

  /**
   * @description Initializes Storage Stats data from ACP Storage Stats V2's response.
   *
   * @param {Object} options initialization object (as described in constructor options parameter).
   */
  #updateModelV2(options) {
    Object.assign(this, {
      orgAssetsQuota: this.#getDefinedQuota(options.orgAssetsQuota),
      orgOthersQuota: this.#getDefinedQuota(options.orgOthersQuota),
      orgTotalQuota: this.#getDefinedQuota(options.orgTotalQuota),
      orgUsersQuota: this.#getDefinedQuota(options.orgUsersQuota),
      usersTopFolder: this.#getDefinedFolders(options.usersTopFolder),
    });
  }

  /**
   * @description Fetch the amount of remaining consumed quota in bytes.
   *
   * @returns {BigDecimal} the total number of remaining used bytes.
   */
  getRemainingUsedAmount() {
    if (feature.isEnabled('temp_ctir_20442')) {
      return new BigDecimal(this.orgOthersQuota.value);
    }
    return new BigDecimal(this.orgReclaimedQuota.value).add([
      new BigDecimal(this.orgDeletedQuota.value),
      new BigDecimal(this.orgReportsQuota.value),
    ]);
  }

  /**
   * @description Fetch top user folders' data.
   *
   * @returns {Object[]} user folders with top usage among the current org, if none exist it is an empty array.
   * @returns {FileSize} [].consumed - The object containing info about the amount of quota that is being used.
   * @returns {String} [].name - The name of the folder.
   * @returns {String} [].percentage - The percentage of the quota that is being used. '0.0' will display as '0'.
   * @returns {FileSize} [].total - The size of the folder.
   */
  getTopUserFolders() {
    return this.usersTopFolder.map(
      (folder) =>
        new StorageTopFolder({
          consumed: fetchReadableUnitsOfStorage(folder.consumedQuota.value, 1),
          name: folder.folderName,
          percentage: folder.utilizedQuota.value,
          total: fetchReadableUnitsOfStorage(
            getNumBytes(folder.totalQuota.value, folder.totalQuota.unit),
            1
          ),
        })
    );
  }

  /**
   * @description Fetch the total amount of allocated bytes
   *
   * @returns {BigDecimal} total number of bytes.
   */
  getTotalAmount() {
    return new BigDecimal(getNumBytes(this.orgTotalQuota.value, this.orgTotalQuota.unit));
  }

  /**
   * @description Fetch the amount of unused quota in bytes.
   *
   * @returns {BigDecimal} number of unused bytes.
   */
  getUnusedAmount() {
    return BigDecimal.max(
      new BigDecimal(getNumBytes(this.orgTotalQuota.value, this.orgTotalQuota.unit)).subtract(
        this.getUsedAmount()
      ),
      bigDecimalZero
    );
  }

  /**
   * @description Fetch the usage data for displaying with usage-bar.
   *
   * @param {Object} options - dictates what data to fetch.
   * @param {Boolean} options.shouldGetUserFolders - whether to get user folder data or not.
   *   Defaults to true.
   * @param {Boolean} options.shouldGetSharedFolders - whether to get shared folder data or not.
   *   Defaults to true.
   *
   * @returns {Object[]}
   * @returns {String} [].amount - The amount of the usage in bytes.
   * @returns {FileSize} [].displayAmount - The human-readable file size amount information.
   * @returns {Number} [].percentage - The percentage of the total used.
   * @returns {String} [].type - The type of data.
   */
  getUsageData(options = {}) {
    const {shouldGetUserFolders = true, shouldGetSharedFolders = true} = options;
    const result = [];

    // Percentage could possibily be 99 due to rounding errors or over 100 if storage overage
    if (shouldGetUserFolders) {
      result.push({
        amount: this.orgUsersQuota.value,
        displayAmount: fetchReadableUnitsOfStorage(this.orgUsersQuota.value, 1),
        percentage: this.getUsagePercentage(new BigDecimal(this.orgUsersQuota.value)),
        type: USAGE_TYPES.USER_FOLDERS,
      });
    }
    if (shouldGetSharedFolders) {
      result.push({
        amount: this.orgAssetsQuota.value,
        displayAmount: fetchReadableUnitsOfStorage(this.orgAssetsQuota.value, 1),
        percentage: this.getUsagePercentage(new BigDecimal(this.orgAssetsQuota.value)),
        type: USAGE_TYPES.SHARED_FOLDERS,
      });
    }

    const remainingUsedAmount = this.getRemainingUsedAmount();
    const unusedAmount = this.getUnusedAmount();

    result.push(
      {
        amount: remainingUsedAmount.toString(),
        displayAmount: fetchReadableUnitsOfStorage(remainingUsedAmount, 1),
        percentage: this.getUsagePercentage(remainingUsedAmount),
        type: USAGE_TYPES.REMAINING_USED,
      },
      {
        amount: unusedAmount.toString(),
        displayAmount: fetchReadableUnitsOfStorage(unusedAmount, 1),
        percentage: this.getUsagePercentage(unusedAmount),
        type: USAGE_TYPES.UNUSED,
      }
    );

    return result;
  }

  /**
   * @description Fetch the percentage of available storage used.
   *   This is the usedAmount / getTotalAmount() * 100.
   *
   * @param {BigNumber} [usedAmount] The amount of storage used which is the numerator for the
   *   calculation.  The default is the amount used by orgUsersQuota + orgAssetsQuota.
   * @returns {Number} The rounded percentage of bytes used or zero if there is no available storage.
   */
  getUsagePercentage(usedAmount = this.getUsedAmount()) {
    const total = this.getTotalAmount();
    if (total.isGreaterThan(bigDecimalZero)) {
      const fraction = usedAmount.divide(total);
      const bigDecimalPercentage = fraction.multiply(new BigDecimal(100));
      return Math.round(Number(bigDecimalPercentage.toString()));
    }
    return 0;
  }

  /**
   * @description Fetch the usage summary
   *
   * @returns {Object} an usage summary object.
   * @returns {FileSize} Object.amount - the consumed amount as a FileSize.
   * @returns {FileSize} Object.total - the total amount as a FileSize.
   */
  getUsageSummary() {
    return {
      amount: fetchReadableUnitsOfStorage(this.getUsedAmount(), 1),
      total: fetchReadableUnitsOfStorage(this.getTotalAmount(), 1),
    };
  }

  /**
   * @description Fetch the amount of consumed quota in bytes.
   *
   * @returns {BigDecimal} the number of used bytes.
   */
  getUsedAmount() {
    return new BigDecimal(this.orgUsersQuota.value).add([
      new BigDecimal(this.orgAssetsQuota.value),
      this.getRemainingUsedAmount(),
    ]);
  }

  /**
   * @description Fetch the over usage status.
   *
   * @returns {Boolean} true if the consumed amount is greater than the total amount, false otherwise.
   */
  isOverUsage() {
    return this.getUsedAmount().isGreaterThan(this.getTotalAmount());
  }

  /**
   * @description Fetch the storage low status.
   *
   * @returns {Boolean} true if the percentage of the consumed amount is greater or equal to 80%.
   */
  isStorageLow() {
    return this.getUsagePercentage() >= USAGE_THRESHOLD;
  }

  /**
   * @description Refresh the contents of the Storage Stats.
   *
   * @returns {Promise} promise - resolved when the storage stats is refreshed.
   */
  async refresh() {
    try {
      if (feature.isEnabled('temp_ctir_20442')) {
        const response = await contentPlatformVa6Direct.getStorageStatsV2({top: 5});
        this.#updateModelV2(response.data);
      } else {
        const response = await contentPlatformVa6Direct.getStorageStats({top: 5});
        this.#updateModel(response.data);
      }
      modelCache.put(CACHE_ID, StorageStats.getKey(), this);
    } catch (error) {
      log.error('StorageStats failed to fetch stats. Error: ', error);
      return Promise.reject(error);
    }
    return this;
  }

  /**
   * @description Define the quota. The unit defaults to FILE_SIZE_UNITS[0] and the value to '0' if not defined.
   *   If value is '0.0' or '0' with any number of decimals it is converted to '0'.
   *
   * @returns {Object} the defined quota.
   * @returns {String} unit - the quota's unit, defaults to bytes.
   * @returns {String} value - the quota's value, defaults to '0'.
   */
}

export default StorageStats;
