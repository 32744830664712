import {feature} from '@admin-tribe/acsc';
import {
  DEVICE_ACTIVATION_STATUS,
  useDevicesApi,
} from '@pandora/react-data-source-device-activation';
import {PAGE_SIZE_OPTIONS} from '@pandora/react-pagination';
import {TABLE_SECTION_ACTIONS} from '@pandora/react-table-section';
import {useCallback, useState} from 'react';

import rootStore from 'core/RootStore';

import {DEVICE_API_ERROR} from '../device-licenses/DeviceConstants';

TABLE_SECTION_ACTIONS.FILTER_BY_STATUS = 'filterByStatus';

/**
 * A hook that helps with managing the devices list
 *
 * @param {String} productId - Id of the current product
 * @param {String} licenseGroupId - Id of the current license group
 */
const useDeviceList = ({licenseGroupId, licensingMode, productId}) => {
  const apiOptions = {
    licenseId: productId,
    orgId: rootStore.organizationStore.activeOrgId,
  };
  // In the case of SDL where we would have a licenseGroupId
  if (licenseGroupId) apiOptions.licenseConfigId = licenseGroupId;
  if (licensingMode) apiOptions.licensingMode = licensingMode;
  if (feature.isEnabled('temp_sdl_frl_deactivations')) apiOptions.includeStatusAndMessage = true;

  const {getDeviceDetails} = useDevicesApi(apiOptions);

  const [cursorStack, setCursorStack] = useState([]); // maintains all the cursors
  const [deviceList, setDeviceList] = useState([]);
  const [hasLoadingError, setHasLoadingError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [isDeactivationInProgress, setIsDeactivationInProgress] = useState(false);

  const [tableOptions, setTableOptions] = useState({
    limit: PAGE_SIZE_OPTIONS.DEFAULT[1].id,
    next: null,
    pageSize: PAGE_SIZE_OPTIONS.DEFAULT[1].id,
  });

  // This callback is used for API call and setting the cursors and device output response from API
  const makeRequest = useCallback(
    async (requestData, handleError) => {
      try {
        setIsLoading(true);
        setHasLoadingError(false); // Explicitly setting the loading error state to False in case of any previous error states

        const response = await getDeviceDetails(requestData);
        const body = await response.json();

        if (body?.data?.search?.statusCode === DEVICE_API_ERROR.BULK_RESET_IN_PROGRESS) {
          setIsDeactivationInProgress(true);
          return;
        }

        const totalDevices = Number.parseInt(body.data.search.total, 10);
        const totalPages = Math.ceil(totalDevices / requestData.pageSize);
        setCursorStack((prevCursorStack) => [...prevCursorStack, body.data.search.cursor]);
        setTableOptions({
          ...requestData,
          totalPages,
        });

        setDeviceList(body.data.search.data);
      } catch (error) {
        handleError?.();
        setHasLoadingError(true);
      } finally {
        setIsLoading(false);
      }
    },
    [getDeviceDetails]
  );

  const resetPageData = useCallback(() => {
    setCurrentPage(1); // Current page must get set first
  }, []);

  // Callback used for filtering by status
  const filterByStatus = useCallback(
    ({value}) => {
      // reset the page
      resetPageData();
      const filterStatusMap = {
        activatedDevices: DEVICE_ACTIVATION_STATUS.ACTIVATED,
        blockedDevices: DEVICE_ACTIVATION_STATUS.BLOCKED,
      };
      setCursorStack([]);
      const updatedTabledOptions = {
        ...tableOptions,
        activationState: filterStatusMap[value],
        next: null,
      };
      makeRequest(updatedTabledOptions);
    },
    [makeRequest, resetPageData, tableOptions]
  );

  // Callback used when next page is clicked
  const goToNextPage = useCallback(
    (page) => {
      setCurrentPage(currentPage + 1);
      const updatedTabledOptions = {
        ...tableOptions,
        next: cursorStack[cursorStack.length - 1],
        page,
      };
      makeRequest(updatedTabledOptions);
    },
    [currentPage, tableOptions, cursorStack, makeRequest]
  );

  // Callback used when previous page is clicked
  const goToPrevPage = useCallback(
    (page) => {
      setCurrentPage(currentPage - 1);
      const updatedCursorStack = cursorStack.slice(0, -2);
      setCursorStack(updatedCursorStack);
      const updatedTabledOptions = {
        ...tableOptions,
        next: updatedCursorStack[updatedCursorStack.length - 1],
        page,
      };
      makeRequest(updatedTabledOptions);
    },
    [currentPage, cursorStack, tableOptions, makeRequest]
  );

  // Callback used when search is clicked
  const search = useCallback(
    ({value}) => {
      // reset the page
      resetPageData();
      setCursorStack([]);
      const updatedTabledOptions = {
        ...tableOptions,
        deviceId: value,
        deviceName: value,
        next: null,
      };

      makeRequest(updatedTabledOptions);
    },
    [makeRequest, resetPageData, tableOptions]
  );

  // Callback used when sort on column is clicked
  const sortBy = useCallback(
    ({id, sortedOrder}) => {
      // reset the page
      resetPageData();
      const sortOrderMap = {ascending: 'asc', descending: 'desc'};
      setCursorStack([]);
      const updatedTabledOptions = {
        ...tableOptions,
        next: null,
        sort: `[{field: ${id}, order: ${sortOrderMap[sortedOrder]}}, {field: id, order: ${sortOrderMap[sortedOrder]}}]`,
      };

      makeRequest(updatedTabledOptions);
    },

    [makeRequest, resetPageData, tableOptions]
  );

  // Callback used when items per page are changed
  const onPageSizeChange = useCallback(
    (pageSize) => {
      // reset the page
      resetPageData();
      setCursorStack([]);
      const updatedTabledOptions = {
        ...tableOptions,
        // Always reset the current page to page 1 when the page size changes
        limit: pageSize,
        next: null,
        pageSize,
      };

      makeRequest(updatedTabledOptions);
    },
    [makeRequest, resetPageData, tableOptions]
  );

  const loadInitialData = useCallback(
    ({loadOptions = tableOptions, handleError} = {}) => {
      makeRequest(loadOptions, handleError);
    },
    [makeRequest, tableOptions]
  );

  const onTableSectionChange = useCallback(
    ({action, payload}) => {
      // Reducer for table actions
      switch (action) {
        case TABLE_SECTION_ACTIONS.ON_SEARCH_SUBMIT:
          search(payload);
          break;
        case TABLE_SECTION_ACTIONS.GO_TO_NEXT_PAGE:
          goToNextPage(payload);
          break;
        case TABLE_SECTION_ACTIONS.GO_TO_PREVIOUS_PAGE:
          goToPrevPage(payload);
          break;
        case TABLE_SECTION_ACTIONS.ON_PAGE_SIZE_CHANGE:
          onPageSizeChange(payload);
          break;
        case TABLE_SECTION_ACTIONS.ON_SORT_BY:
          sortBy(payload);
          break;
        case TABLE_SECTION_ACTIONS.FILTER_BY_STATUS:
          filterByStatus(payload);
          break;
        case TABLE_SECTION_ACTIONS.ON_TABLE_ITEMS_MODIFIED:
          loadInitialData();
          break;
        default:
          // Do nothing
          break;
      }
    },
    [filterByStatus, loadInitialData, search, goToNextPage, goToPrevPage, onPageSizeChange, sortBy]
  );

  return {
    currentPage,
    deviceList,
    filterByStatus,
    goToNextPage,
    goToPrevPage,
    hasLoadingError,
    isDeactivationInProgress,
    isLoading,
    loadInitialData,
    onPageSizeChange,
    onTableSectionChange,
    search,
    sortBy,
    tableOptions,
  };
};
export default useDeviceList;
export {TABLE_SECTION_ACTIONS};
