import {log} from '@admin-tribe/acsc';
import {
  Page,
  PageBanner,
  PageBanners,
  PageContent,
  PageHeader,
  PageNav,
} from '@admin-tribe/acsc-ui';
import {Link} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import SophiaBanner from 'common/components/sophia/banner/SophiaBanner';
import useAddProductModal from 'common/hooks/useAddProductModal';
import useDocumentTitle from 'common/hooks/useDocumentTitle';
import SOPHIA_CONSTANTS from 'common/services/sophia/SophiaConstants';
import StorageRepositoryList from 'common/services/storage-repository-list/StorageRepositoryList';
import StorageStats from 'common/services/storage-stats/StorageStats';
import {getStorageOnlyOfferId} from 'common/utils/storage/storageUtils';
import rootStore from 'core/RootStore';
import chatProvider from 'core/services/chat/chatProvider';
import {canPurchaseAdditionalStorage} from 'core/storage/access/storageAccess';
import AddProductModalWrapper from 'features/products/components/add-product-modal-wrapper/AddProductModalWrapper';
import RepositoryNameSection from 'features/storage/components/repository-name-section/RepositoryNameSection';
import TopIndividualUsersSection from 'features/storage/components/top-individual-users-section/TopIndividualUsersSection';
import UsageBarSection from 'features/storage/components/usage-bar-section/UsageBarSection';

/**
 * The 'Storage overview' page.
 */
const StorageOverviewPage = ({navigationCallback}) => {
  const MARGIN_BOTTOM = 'size-400';

  const [showLowStorageBanner, setShowLowStorageBanner] = useState(false);
  const [, setStorageOnlyOfferId] = useState();
  const [storageStats, setStorageStats] = useState();
  const [topUserFolders, setTopUserFolders] = useState();

  const [repositoryList, setRepositoryList] = useState();

  const [isBumpered, setIsBumpered] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const intl = useIntl();

  useDocumentTitle({key: 'storage.overview.documentTitle'});

  const {closeAddProductModal, openAddProducts, showAddProductModal} = useAddProductModal();

  // Initial load.
  useEffect(() => {
    const STATUS_FULFILLED = 'fulfilled';
    let isMounted = true;

    const processRepositoryListPromise = ({reason, status, value}) => {
      if (status === STATUS_FULFILLED) {
        setRepositoryList(value);
      } else {
        log.error(`Error getting StorageRepositoryList: ${reason}`);
      }
      return value;
    };

    const processStorageStatsPromise = ({reason, status, value}) => {
      if (status === STATUS_FULFILLED) {
        setStorageStats(value);
        setTopUserFolders(value.getTopUserFolders());
      } else {
        log.error(`Error getting StorageStats: ${reason}`);
      }
      return value;
    };

    const processStorageOfferPromise = ({reason, status, value}) => {
      if (status === STATUS_FULFILLED) {
        setStorageOnlyOfferId(value);
      } else {
        log.error(`Error getting StorageOnlyOfferId: ${reason}`);
      }
      return value;
    };

    const loadStorageInfo = async () => {
      const canBuyStorage = await canPurchaseAdditionalStorage();

      const promises = [
        StorageRepositoryList.get(), // results[0]
        StorageStats.get(), // results[1]
      ];
      if (canBuyStorage) {
        promises.push(getStorageOnlyOfferId()); // results[2]
      }

      // Each result returns with status 'fulfilled' with a value, or status 'rejected' with a reason.
      const results = await Promise.allSettled(promises);

      if (isMounted) {
        const list = processRepositoryListPromise(results[0]);

        const stats = processStorageStatsPromise(results[1]);
        if (canBuyStorage && stats?.isStorageLow()) {
          const storageOfferId = processStorageOfferPromise(results[2]);
          setShowLowStorageBanner(!!storageOfferId);
        }

        // Bumper the page if no sections can be shown.
        if (!list && !stats) {
          setIsBumpered(true);
        }

        setIsLoading(false);
      }
    };

    loadStorageInfo();

    return () => {
      isMounted = false;
    };
  }, []);

  // Note that if more storage is purchased it likely will not be reflected in the storage drawer.
  // The actual quota is updated asynchronously.
  const onPressPurchaseMore = () => {
    openAddProducts();
  };

  return (
    <Page data-testid="storage-overview-page" isBumpered={isBumpered} isLoading={isLoading}>
      <PageBanners>
        <SophiaBanner surfaceId={SOPHIA_CONSTANTS.SURFACE_ID.ONE_CONSOLE_ACCOUNT} />
        {showLowStorageBanner && (
          <PageBanner removeWithKey={`${rootStore.organizationStore.activeOrgId}_lowStorageBanner`}>
            {intl.formatMessage(
              {id: 'storage.overview.banner.lowStorage'},
              {link: ([linkText]) => <Link onPress={onPressPurchaseMore}>{linkText}</Link>}
            )}
          </PageBanner>
        )}
      </PageBanners>
      <PageHeader title={intl.formatMessage({id: 'storage.overview.title'})} />
      <PageNav />
      <PageContent>
        {storageStats && (
          <UsageBarSection marginBottom={MARGIN_BOTTOM} storageStats={storageStats} />
        )}
        {topUserFolders && (
          <TopIndividualUsersSection
            marginBottom={MARGIN_BOTTOM}
            navigationCallback={navigationCallback}
            topUserFolders={topUserFolders}
          />
        )}
        {repositoryList && (
          <RepositoryNameSection
            marginBottom={MARGIN_BOTTOM}
            repositoryList={repositoryList}
            showDivider={!!storageStats} /* topUserFolders is dependant on storageStats */
          />
        )}
        {showAddProductModal && (
          <AddProductModalWrapper chat={chatProvider} onClose={() => closeAddProductModal()} />
        )}
      </PageContent>
    </Page>
  );
};

StorageOverviewPage.propTypes = {
  /**
   * Callback for navigation. Params are newState and optional options.
   * Valid states are
   *   - 'add-storage' requires an options.offerId and will open a modal to purchase more storage.
   *   - 'individual-user-folders' which has no options which goes to the Individual user folders page.
   */
  navigationCallback: PropTypes.func.isRequired,
};

export default StorageOverviewPage;
