import {
  AuthenticatedUser,
  CONTRACT_BUYING_PROGRAM,
  SALES_CHANNEL,
  feature,
} from '@admin-tribe/acsc';

import rootStore from 'core/RootStore';
import {ACCOUNT_STATE_PARAM} from 'core/account/account-meta/AccountMetaConstants';
import {getTeamPhoneNumber} from 'core/contact-sales/contactSalesUtils';
import {
  GLOBAL_BANNER_PRIORITY,
  GLOBAL_BANNER_TYPES,
} from 'features/global-banner/GlobalBannerConstants';

import {
  COMPLIANCE_CONTEXT,
  COMPLIANCE_GRACE_PAST_DUE_BANNER_ID,
} from './ContractComplianceConstants';
import {
  getDirectContractSummary,
  hasGracePastDueLicenses,
  hasPastDueLicenses,
  hasPendingPaymentLicenses,
  hasPendingPaymentOrderQuantityExceeded,
} from './contractComplianceUtils';

const {GRACE_PAST_DUE_PRIORITY, PAST_DUE_PRIORITY} = GLOBAL_BANNER_PRIORITY.COMPLIANCE;

/**
 * @description Method for checking indirect/team direct contracts for
 *     compliance state and will generate and add global banners to the
 *     globalBannerStore.
 */
function notifyContractComplianceBanners() {
  if (rootStore.organizationStore.contractList.items.length > 0) {
    const banners = [
      notifyForCancelledTeamDirectContracts(),
      notifyForCancellingTeamDirectContracts(),
      notifyForPendingPaymentLicenses(),
      notifyForGracePastDueLicenses(),
      notifyForPastDueLicenses(),
      notifyForPendingPaymentOrderQuantityExceeded(),
    ];

    banners.flat().forEach((banner) => {
      if (banner) {
        rootStore.organizationStore.globalBannerStore.add(banner);
      }
    });
  }
}

//////////

// eslint-disable-next-line id-length -- Needed for descriptive method name
function createIndirectGracePastDueLicensesNotification(payload) {
  return {
    message:
      'globalBanner.messages.contract.compliance.indirect.messageFormat.gracePastDueLicenses',
    priority: GRACE_PAST_DUE_PRIORITY,
    type: GLOBAL_BANNER_TYPES.WARNING,
    ...getEndDateArgs(payload),
  };
}

function createIndirectPastDueLicensesNotification(payload) {
  return {
    message: 'globalBanner.messages.contract.compliance.indirect.pastDueLicenses',
    messageArgs: {
      resellerName: payload.resellerName,
    },
    priority: PAST_DUE_PRIORITY,
    type: GLOBAL_BANNER_TYPES.ERROR,
  };
}

// eslint-disable-next-line id-length -- Needed for descriptive method name
function createIndirectPendingPaymentLicensesNotification(payload) {
  const key =
    payload.buyingProgram === CONTRACT_BUYING_PROGRAM.VIPMP
      ? 'pendingPaymentLicensesVIPMP'
      : 'pendingPaymentLicenses';

  return {
    message: `globalBanner.messages.contract.compliance.indirect.messageFormat.${key}`,
    type: GLOBAL_BANNER_TYPES.INFO,
    ...getEndDateArgs(payload),
  };
}

// eslint-disable-next-line id-length -- Needed for descriptive method name
function createPendingPaymentOrderQuantityExceededNotification() {
  return {
    message:
      'globalBanner.messages.contract.compliance.indirect.pendingPaymentOrderQuantityExceeded',
    type: GLOBAL_BANNER_TYPES.WARNING,
  };
}

// eslint-disable-next-line id-length -- Needed for descriptive method name
function createTeamDirectCCPaypalAPMGracePastDueLicensesNotification() {
  return {
    id: COMPLIANCE_GRACE_PAST_DUE_BANNER_ID,
    message:
      'globalBanner.messages.contract.compliance.teamDirect.creditCardPaypal.monthly.gracePastDueLicenses',
    priority: GRACE_PAST_DUE_PRIORITY,
    type: GLOBAL_BANNER_TYPES.ERROR,
    ...getEditPaymentLink(),
  };
}

// eslint-disable-next-line id-length -- Needed for descriptive method name
function createTeamDirectCCPaypalAPMPastDueLicensesNotification() {
  return {
    message:
      'globalBanner.messages.contract.compliance.teamDirect.creditCardPaypal.monthly.pastDueLicenses',
    messageArgs: {
      goUrlName: 'CCMTeamSupport',
    },
    priority: PAST_DUE_PRIORITY,
    type: GLOBAL_BANNER_TYPES.ERROR,
  };
}

// eslint-disable-next-line id-length -- Needed for descriptive method name
function createTeamDirectCCPaypalPUFGracePastDueLicensesNotification() {
  return {
    id: COMPLIANCE_GRACE_PAST_DUE_BANNER_ID,
    message:
      'globalBanner.messages.contract.compliance.teamDirect.creditCardPaypal.annual.gracePastDueLicenses',
    priority: GRACE_PAST_DUE_PRIORITY,
    type: GLOBAL_BANNER_TYPES.ERROR,
    ...getEditPaymentLink(),
  };
}

// eslint-disable-next-line id-length -- Needed for descriptive method name
function createTeamDirectCCPaypalPUFPastDueLicensesNotification() {
  return {
    message:
      'globalBanner.messages.contract.compliance.teamDirect.creditCardPaypal.annual.pastDueLicenses',
    messageArgs: {
      goUrlName: 'CCMTeamSupport',
    },
    priority: PAST_DUE_PRIORITY,
    type: GLOBAL_BANNER_TYPES.ERROR,
  };
}

// eslint-disable-next-line id-length -- Needed for descriptive method name
function createTeamDirectDigitalRiverGracePastDueLicensesNotification() {
  return {
    id: COMPLIANCE_GRACE_PAST_DUE_BANNER_ID,
    message:
      'globalBanner.messages.contract.compliance.teamDirect.digitalRiver.gracePastDueLicenses',
    priority: GRACE_PAST_DUE_PRIORITY,
    type: GLOBAL_BANNER_TYPES.ERROR,
    ...getEditPaymentLink(),
  };
}

// eslint-disable-next-line id-length -- Needed for descriptive method name
function createTeamDirectDigitalRiverPastDueLicensesNotification() {
  return {
    message: 'globalBanner.messages.contract.compliance.teamDirect.digitalRiver.pastDueLicenses',
    messageArgs: {
      goUrlName: 'CCMTeamSupport',
    },
    priority: PAST_DUE_PRIORITY,
    type: GLOBAL_BANNER_TYPES.ERROR,
  };
}

// eslint-disable-next-line id-length -- Needed for descriptive method name
function createTeamDirectPendingPaymentQuantityExceededLicensesNotification() {
  return {
    message: 'globalBanner.messages.contract.compliance.teamDirect.pendingLicensesExceeded',
    messageArgs: {
      salesPhoneNumber: getTeamPhoneNumber(AuthenticatedUser.get().getCountryCode()),
    },
    type: GLOBAL_BANNER_TYPES.INFO,
  };
}

// eslint-disable-next-line id-length -- Needed for descriptive method name
function createTeamDirectPOOfflineGracePastDueLicensesNotification() {
  return {
    message:
      'globalBanner.messages.contract.compliance.teamDirect.purchaseOrderOffline.gracePastDueLicenses',
    messageArgs: {
      linkHref: 'mailto:CTTPO-Credit@adobe.com',
    },
    priority: GRACE_PAST_DUE_PRIORITY,
    type: GLOBAL_BANNER_TYPES.ERROR,
    ...getBillingHistoryLink(),
  };
}

// eslint-disable-next-line id-length -- Needed for descriptive method name
function createTeamDirectExternalWalletGracePastDueLicensesNotification() {
  return {
    id: COMPLIANCE_GRACE_PAST_DUE_BANNER_ID,
    message:
      'globalBanner.messages.contract.compliance.teamDirect.externalWallet.gracePastDueLicenses',
    priority: GRACE_PAST_DUE_PRIORITY,
    type: GLOBAL_BANNER_TYPES.ERROR,
    ...getEditPaymentLink(),
  };
}

// eslint-disable-next-line id-length -- Needed for descriptive method name
function createTeamDirectLPMGracePastDueLicensesNotification() {
  return {
    id: COMPLIANCE_GRACE_PAST_DUE_BANNER_ID,
    message: 'globalBanner.messages.contract.compliance.teamDirect.lpm.gracePastDueLicenses',
    priority: GRACE_PAST_DUE_PRIORITY,
    type: GLOBAL_BANNER_TYPES.ERROR,
    ...getEditPaymentLink(),
  };
}

// eslint-disable-next-line id-length -- Needed for descriptive method name
function createTeamDirectPOOfflinePastDueLicensesNotification() {
  return {
    message:
      'globalBanner.messages.contract.compliance.teamDirect.purchaseOrderOffline.pastDueLicenses',
    messageArgs: {
      linkHref: 'mailto:CTTPO-Credit@adobe.com',
    },
    priority: PAST_DUE_PRIORITY,
    type: GLOBAL_BANNER_TYPES.ERROR,
    ...getBillingHistoryLink(),
  };
}

function getBillingHistoryLink() {
  return {
    linkText: 'globalBanner.linkText.viewInvoices',
    linkUiSref: 'account.billing-history',
  };
}

function getEditPaymentLink() {
  return {
    linkText: 'globalBanner.linkText.updateBillingInfo',
    linkUiSref: 'account.account',
    linkUiSrefArgs: {action: ACCOUNT_STATE_PARAM.ACTION.EDIT_PAYMENT},
  };
}

function getEndDateArgs(payload) {
  return {
    messageArgs: {
      hasEndDate: !!payload.endDate,
      resellerName: payload.resellerName,
    },
    messageDates: {
      endDate: payload.endDate,
    },
    messageTimes: {
      endTime: payload.endDate,
    },
  };
}

function notifyForCancelledTeamDirectContracts() {
  const response = getDirectContractSummary();
  if (response.canMessageCancellation()) {
    return {
      message: 'globalBanner.messages.contract.compliance.teamDirect.cancelled',
      messageArgs: {
        salesPhoneNumber: getTeamPhoneNumber(AuthenticatedUser.get().getCountryCode()),
      },
      type: GLOBAL_BANNER_TYPES.ERROR,
    };
  }
  return undefined;
}

function notifyForCancellingTeamDirectContracts() {
  const response = getDirectContractSummary();
  const salesPhoneNumber = getTeamPhoneNumber(AuthenticatedUser.get().getCountryCode());
  if (response.canMessageUpcomingCancellation()) {
    if (feature.isEnabled('temp_self_cancel') && !response.offsetFromNextBillingDate) {
      return {
        message: 'globalBanner.messages.contract.compliance.teamDirect.cancellingPending',
        messageArgs: {
          salesPhoneNumber,
        },
        type: GLOBAL_BANNER_TYPES.ERROR,
      };
    }
    return {
      message: 'globalBanner.messages.contract.compliance.teamDirect.cancelling',
      messageArgs: {
        count: response.offsetFromNextBillingDate,
        salesPhoneNumber,
      },
      messageNumbers: {
        offsetFromNextBillingDate: response.offsetFromNextBillingDate,
      },
      type: GLOBAL_BANNER_TYPES.ERROR,
    };
  }
  return undefined;
}

function notifyForGracePastDueLicenses() {
  const payloadArr = hasGracePastDueLicenses();
  return payloadArr.map((payload) => {
    if (payload?.count > 0) {
      switch (payload.complianceContext) {
        case COMPLIANCE_CONTEXT.INDIRECT:
          return createIndirectGracePastDueLicensesNotification(payload);
        case COMPLIANCE_CONTEXT.TEAM_DIRECT_CCPAYPAL_APM: {
          return createTeamDirectCCPaypalAPMGracePastDueLicensesNotification();
        }
        case COMPLIANCE_CONTEXT.TEAM_DIRECT_CCPAYPAL_PUF: {
          return createTeamDirectCCPaypalPUFGracePastDueLicensesNotification();
        }
        case COMPLIANCE_CONTEXT.TEAM_DIRECT_DIGITAL_RIVER: {
          return createTeamDirectDigitalRiverGracePastDueLicensesNotification();
        }
        case COMPLIANCE_CONTEXT.TEAM_DIRECT_PO_OFFLINE: {
          return createTeamDirectPOOfflineGracePastDueLicensesNotification();
        }
        case COMPLIANCE_CONTEXT.TEAM_DIRECT_LPM: {
          return createTeamDirectLPMGracePastDueLicensesNotification();
        }
        case COMPLIANCE_CONTEXT.TEAM_DIRECT_EXTERNAL_WALLET: {
          return createTeamDirectExternalWalletGracePastDueLicensesNotification();
        }
        default:
          return undefined;
      }
    }
    return undefined;
  });
}

function notifyForPastDueLicenses() {
  const payloadArr = hasPastDueLicenses();
  return payloadArr.map((payload) => {
    if (payload?.count > 0) {
      switch (payload.complianceContext) {
        case COMPLIANCE_CONTEXT.INDIRECT:
          return createIndirectPastDueLicensesNotification(payload);
        case COMPLIANCE_CONTEXT.TEAM_DIRECT_CCPAYPAL_APM: {
          return createTeamDirectCCPaypalAPMPastDueLicensesNotification();
        }
        case COMPLIANCE_CONTEXT.TEAM_DIRECT_CCPAYPAL_PUF: {
          return createTeamDirectCCPaypalPUFPastDueLicensesNotification();
        }
        case COMPLIANCE_CONTEXT.TEAM_DIRECT_DIGITAL_RIVER: {
          return createTeamDirectDigitalRiverPastDueLicensesNotification();
        }
        case COMPLIANCE_CONTEXT.TEAM_DIRECT_PO_OFFLINE: {
          return createTeamDirectPOOfflinePastDueLicensesNotification();
        }
        default:
          return undefined;
      }
    }
    return undefined;
  });
}

function notifyForPendingPaymentLicenses() {
  const payloadArr = hasPendingPaymentLicenses();
  return payloadArr.map((payload) => {
    if (payload?.count > 0 && payload?.complianceContext === COMPLIANCE_CONTEXT.INDIRECT) {
      return createIndirectPendingPaymentLicensesNotification(payload);
    }
    if (
      payload?.count > 0 &&
      payload?.salesChannel === SALES_CHANNEL.DIRECT &&
      payload?.canMessageUnbackedPromiseQuantityExceeded()
    ) {
      return createTeamDirectPendingPaymentQuantityExceededLicensesNotification();
    }
    return null;
  });
}

function notifyForPendingPaymentOrderQuantityExceeded() {
  return hasPendingPaymentOrderQuantityExceeded()
    ? createPendingPaymentOrderQuantityExceededNotification()
    : null;
}

// eslint-disable-next-line import/prefer-default-export -- Utils file
export {notifyContractComplianceBanners};
