import {configStore} from '@admin-tribe/acsc';
import axios from 'axios';

import {apiServices} from 'common/api/apiServices';
import {getAuthorizationHeader} from 'common/api/httpUtils';
import AppConstants from 'common/services/AppConstants';

const AUTH_SOURCE_TYPES = {
  ENTERPRISE: 'ent',
  FEDERATED: 'fed',
};

const FEDERATED_TYPES = {
  AZURE: 'AZURE',
  GOOGLE: 'GOOGLE',
};

const UPLOAD_METADATA_ERROR_CODES = {
  CANNOT_READ_METADATA: 'cannot_read_metadata',
  EMPTY_METADATA: 'empty_metadata',
  INVALID_METADATA: 'invalid_metadata',
  INVALID_XML: 'invalid_xml',
  MALFORMED_SSO_URL: 'malformed_sso_url',
  MULTIPLE_ENTITY_DESCRIPTORS: 'multiple_entity_descriptors',
  NO_IDP_SSO_DESCRIPTOR: 'no_idp_sso_descriptor',
  NO_SSO_SERVICE_PRESENT: 'no_sso_service_present',
  UNRECOGNIZED_METADATA_FORMAT: 'unrecognized_metadata_format',
};

let baseUrl;

(async function loadConfig() {
  ({url: baseUrl} = await configStore.getServiceConfiguration('imsFederated'));
})();

/**
 * Calls IMS Federated APIs to retrieve a list of
 * domains from a federated source.
 *
 * @param {String} authSourceId - AuthSource id
 * @param {String} externalToken - The access token of the source
 * @param {String} federatedType - The source to interogate for domains
 *
 * @returns {Promise} The request promise
 */
const getFederatedDomains = ({authSourceId, externalToken, federatedType}) => {
  const headers = buildHeadersForFederatedDomains({externalToken, federatedType});

  return apiServices.imsFederated.get(
    `/organizations/${AppConstants.orgId}/authSources/${authSourceId}/externalDomains`,
    {
      headers,
    }
  );
};

/**
 * Calls IMS Federated APIs to claim a list of
 * domains from a federated source.
 *
 * @param {String} authSourceId - AuthSource id
 * @param {String} externalToken - The access token of the source
 * @param {String} federatedType - The source to interogate for domains
 * @param {Array} domains - A list of domain names to be claimed
 *
 * @returns {Promise} The request promise
 */
const claimFederatedDomains = ({authSourceId, externalToken, federatedType, domains}) => {
  const headers = buildHeadersForFederatedDomains({externalToken, federatedType});

  return apiServices.imsFederated.post(
    `/organizations/${AppConstants.orgId}/authSources/${authSourceId}/externalDomains`,
    {domains},
    {headers}
  );
};

/**
 * Calls IMS Federated APIs to create a new IdP with the data provided.
 *
 * @param {String} authSourceId - AuthSource in which this IdP lives
 * @param {String} orgId - The organization in which this IdP lives
 * @param {Object} idpData - Data from which the new IdP will be created
 *
 * @returns {Promise} The request promise
 */
const createIdp = ({orgId, authSourceId, idpData}) =>
  axios.post(`${baseUrl}/organizations/${orgId}/authSources/${authSourceId}/idps`, idpData, {
    headers: {
      ...getAuthorizationHeader(),
    },
  });

/**
 *
 * Calls IMS Federated APIs to update an IdP.
 *
 * @param {String} authSourceId - AuthSource id
 * @param {String} orgId - The organization in which this IdP lives
 * @param {String} idpId - IdP id
 * @param {Object} data - IdP fields to update
 *
 * @returns {Promise} The request promise
 */
const updateIdp = ({orgId, authSourceId, idpId, data}) =>
  apiServices.imsFederated.post(
    `/organizations/${orgId}/authSources/${authSourceId}/idps/${idpId}`,
    data
  );

/**
 * Calls the IMS Federated APIs to upload metadata file for a GS IdP.
 *
 * @param {String} authSourceId - AuthSource id
 * @param {String} orgId - The organization in which this IdP lives
 * @param {String} idpId - IdP id
 * @param {File} metadataFile - The metadata file that will get uploaded
 *
 * @returns {Promise} The request promise
 */
const uploadIdpMetadata = ({orgId, authSourceId, idpId, metadataFile}) => {
  const formData = new FormData();
  formData.append('file', metadataFile);

  return apiServices.imsFederated.post(
    `/organizations/${orgId}/authSources/${authSourceId}/idps/${idpId}`,
    formData,
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
  );
};

/**
 * Calls the IMS Federated APIs to remove an IdP.
 *
 * @param {String} authSourceId - AuthSource id
 * @param {String} orgId - The organization in which this IdP lives
 * @param {String} idpId - IdP id
 *
 * @returns {Promise<AxiosResponse<any>>} Axios response
 */
const removeIdp = ({orgId, authSourceId, idpId}) =>
  axios.delete(`${baseUrl}/organizations/${orgId}/authSources/${authSourceId}/idps/${idpId}`, {
    headers: {
      ...getAuthorizationHeader(),
    },
  });

/**
 * @deprecated The usage of this should be replaced with the more generic updateIdp
 *
 * Calls IMS Federated APIs to set the status of an IdP based
 * to DISABLED or ACTIVE, respectively.
 *
 * @param {String} authSourceId - AuthSource id
 * @param {String} idpId - IdP id
 * @param {String} status - DISABLED or ACTIVE status
 *
 * @returns {Promise} The request promise
 */
const updateIdpStatus = ({authSourceId, idpId, status}) => {
  const body = {
    federationType: 'SOIDC',
    status,
  };

  return apiServices.imsFederated.post(
    `/organizations/${AppConstants.orgId}/authSources/${authSourceId}/idps/${idpId}`,
    body
  );
};

/**
 * Creates a new AuthSource with the provided type and name.
 *
 * @param {{name: string, type: string, orgId: string}} options The parameters used to create a new AuthSource (type, name and orgId)
 *
 * @returns {Promise} The request promise
 */
const createAuthSource = ({orgId, name, type}) => {
  const authSourceData = {
    name,
    type,
  };

  return axios.post(`${baseUrl}/organizations/${orgId}/authSources/`, authSourceData, {
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

/**
 * Gets the AuthSource with the provided id.
 *
 * @param {String} authSourceId - AuthSource id
 * @param {String} orgId - id of the organization the AuthSource belongs to
 *
 * @returns {Promise<AxiosResponse<any>>} Axios response
 */
const getAuthSource = ({authSourceId, orgId}) =>
  axios.get(`${baseUrl}/organizations/${orgId}/authSources/${authSourceId}`, {
    headers: {
      ...getAuthorizationHeader(),
    },
  });

/**
 * Gets all the AuthSources for the provided organization from IMS Federated.
 *
 * @param orgId
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
const getAuthSources = ({orgId}) =>
  axios.get(`${baseUrl}/organizations/${orgId}/authSources`, {
    headers: {
      ...getAuthorizationHeader(),
    },
  });

/**
 * Updates an existing AuthSource with the provided data (defaultIdp and/or name).
 *
 * @param {String} authSourceId - AuthSource id
 * @param {String} orgId - id of the organization the AuthSource belongs to
 * @param {Object} data - Body of the request
 *
 * @returns {Promise<AxiosResponse<any>>} Axios response
 */
const updateAuthSource = ({authSourceId, orgId, data}) =>
  axios.post(`${baseUrl}/organizations/${orgId}/authSources/${authSourceId}`, data, {
    headers: {
      ...getAuthorizationHeader(),
    },
  });

/**
 * Gets the attribute mappings for a specified IdP.
 *
 * @param {String} orgId - id of the organization the AuthSource belongs to
 * @param {String} authSourceId - id of the AuthSource the IdP belongs to
 * @param {String} idpId - id of the IdP
 *
 * @returns {Promise<AxiosResponse<any>>} Axios response
 */
const getAttributeMappings = ({orgId, authSourceId, idpId}) =>
  axios.get(
    `${baseUrl}/organizations/${orgId}/authSources/${authSourceId}/idps/${idpId}/mappings`,
    {
      headers: {
        ...getAuthorizationHeader(),
      },
    }
  );

/**
 * Updates/adds the attribute mappings for a specified IdP.
 *
 * @param {String} orgId - id of the organization the AuthSource belongs to
 * @param {String} authSourceId - id of the AuthSource the IdP belongs to
 * @param {String} idpId - id of the IdP
 * @param {String} property - Name of the property to be added/updated
 * @param {Object} data - Body of the request
 *
 * @returns {Promise<AxiosResponse<any>>} Axios response
 */
const putAttributeMapping = ({orgId, authSourceId, idpId, property, data}) =>
  axios.put(
    `${baseUrl}/organizations/${orgId}/authSources/${authSourceId}/idps/${idpId}/mappings/${property}`,
    data,
    {
      headers: {
        ...getAuthorizationHeader(),
      },
    }
  );

// Helpers

// eslint-disable-next-line @admin-tribe/admin-tribe/jsdoc-exported-functions -- neculaes@ to update
function buildHeadersForFederatedDomains({federatedType, externalToken}) {
  if (!Object.keys(FEDERATED_TYPES).includes(federatedType)) {
    throw new Error(`Unknown federated type provided ${federatedType}`);
  }

  const headers = {};

  if (federatedType === FEDERATED_TYPES.AZURE) {
    headers['X-Azure-Authorization'] = externalToken;
  }

  if (federatedType === FEDERATED_TYPES.GOOGLE) {
    headers['X-GSuite-Authorization'] = externalToken;
  }

  return headers;
}

export {
  createAuthSource,
  getAuthSource,
  getAuthSources,
  getFederatedDomains,
  claimFederatedDomains,
  createIdp,
  buildHeadersForFederatedDomains,
  updateAuthSource,
  updateIdp,
  uploadIdpMetadata,
  updateIdpStatus,
  getAttributeMappings,
  putAttributeMapping,
  removeIdp,
  FEDERATED_TYPES,
  AUTH_SOURCE_TYPES,
  UPLOAD_METADATA_ERROR_CODES,
};
