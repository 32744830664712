import {GoUrl, showError as showErrorToast} from '@admin-tribe/acsc-ui';
import {Button, Heading} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {useDirectoryContext} from 'features/settings/components/directory/DirectoryContext';
import SyncStatus from 'features/settings/components/sync-card/SyncStatus';

const SyncStatusSection = observer(() => {
  const {directoryStore} = useDirectoryContext();
  const intl = useIntl();

  const isAzureSync = directoryStore.sync.isAzure;

  const syncStatusLabel = directoryStore.sync.directoryLink.enabled
    ? 'settings.sync.syncSettingsModal.syncStatus.status.OFF2'
    : 'settings.sync.syncSettingsModal.syncStatus.status.ON2';

  const toggleSync = async () => {
    try {
      await directoryStore.sync.toggleSync();
    } catch {
      showErrorToast(intl.formatMessage({id: 'settings.sync.error'}));
    }
  };

  return (
    <>
      <Heading>
        <FormattedMessage id="settings.sync.syncSettingsModal.syncStatus.title" />
      </Heading>

      <p>
        <FormattedMessage id="settings.sync.syncSettingsModal.syncStatus.description" />
        <GoUrl name={isAzureSync ? 'add_azure_sync_prod' : 'add_google_sync_prod'}>
          <FormattedMessage
            id={`settings.sync.syncSettingsModal.syncStatus.learnMore.${
              isAzureSync ? 'azure' : 'google'
            }`}
          />
        </GoUrl>
      </p>

      <SyncStatus
        adminEnabled={directoryStore.sync.directoryLink.adminEnabled}
        directorySyncStore={directoryStore.sync}
        enabled={directoryStore.sync.directoryLink.enabled}
        lastSyncDate={directoryStore.sync.directoryLink.lastSyncDate}
        status={directoryStore.sync.directoryLink.status}
      />

      <Button
        data-test-id="toggle-sync-btn"
        isDisabled={directoryStore.sync.isLoading}
        onPress={toggleSync}
        variant="secondary"
      >
        <FormattedMessage id={syncStatusLabel} />
      </Button>
    </>
  );
});

export default SyncStatusSection;
