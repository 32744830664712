import {ModalDialog} from '@admin-tribe/acsc-ui';
import {
  Button,
  ButtonGroup,
  Content,
  DialogContainer,
  Flex,
  Heading,
  Image,
  Text,
  View,
  useDialogContainer,
} from '@adobe/react-spectrum';
import {GoUrl} from '@pandora/react-go-url';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {FormattedMessage} from 'react-intl';

import soidcNoDirectoryError from 'features/settings/assets/soidc-no-directory-error.svg';
import CreateDirectoryModal from 'features/settings/components/directories/create-directory-modal/CreateDirectoryModal';

import styles from './NoFederatedDirectoriesErrorModal.pcss';

const MODAL_ID = 'no-federated-directories-error-modal';

const NoFederatedDirectoriesErrorModal = ({clearDirectoryCache, goToSetupDomains}) => {
  const [isCreateDirectoryDialogOpen, setIsCreateDirectoryDialogOpen] = useState(false);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(true);
  const dialog = useDialogContainer();

  const namespace = 'settings.directories.noFederatedDirectories';

  const handleOpenCreateDirectory = () => {
    setIsErrorDialogOpen(false);
    setIsCreateDirectoryDialogOpen(true);
  };

  const handleCloseCreateDirectory = () => {
    setIsCreateDirectoryDialogOpen(false);
    dialog.dismiss();
  };

  return (
    <>
      {isErrorDialogOpen && (
        <ModalDialog id={MODAL_ID} isDismissable maxWidth="60vw">
          <Heading
            data-testid="modal-heading"
            level={2}
            // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needed for styling text
            UNSAFE_className={styles.heading}
          >
            <FormattedMessage id={`${namespace}.title`} />
          </Heading>

          <Content data-testid="modal-content">
            <Flex alignItems="center" direction="row">
              <Flex direction="column" maxWidth="50%">
                <Text>
                  <FormattedMessage id={`${namespace}.description.completeSetup`} />
                </Text>
                <Text marginTop="size-200">
                  <FormattedMessage id={`${namespace}.description.defaultIdp`} />
                </Text>
                <Text marginTop="size-200">
                  <FormattedMessage id={`${namespace}.description.SSO`} />
                </Text>
              </Flex>

              <Image alt="" marginStart="size-500" src={soidcNoDirectoryError} />
            </Flex>
            <ButtonGroup>
              <View marginEnd="size-300" marginTop="size-50">
                <GoUrl name="aac_create_directory_federated">
                  <FormattedMessage id={`${namespace}.learnMore`} />
                </GoUrl>
              </View>

              <Button
                data-testid="create-directory-btn"
                onPress={handleOpenCreateDirectory}
                variant="accent"
              >
                <FormattedMessage id={`${namespace}.button.cta`} />
              </Button>
            </ButtonGroup>
          </Content>
        </ModalDialog>
      )}

      <DialogContainer onDismiss={handleCloseCreateDirectory}>
        {isCreateDirectoryDialogOpen && (
          <CreateDirectoryModal
            clearDirectoryCache={clearDirectoryCache}
            goToSetupDomains={goToSetupDomains}
          />
        )}
      </DialogContainer>
    </>
  );
};

NoFederatedDirectoriesErrorModal.propTypes = {
  clearDirectoryCache: PropTypes.func,
  goToSetupDomains: PropTypes.func,
};

export default NoFederatedDirectoriesErrorModal;
