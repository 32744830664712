import {Product, downloadExportData, feature, log} from '@admin-tribe/acsc';
import {
  MemberListTable,
  showError as showErrorToast,
  showInfo as showInfoToast,
  showSuccess as showSuccessToast,
} from '@admin-tribe/acsc-ui';
import {Button, Heading, Text, View} from '@adobe/react-spectrum';
import {GoUrl} from '@pandora/react-go-url';
import {TABLE_SECTION_ACTIONS, TableSection} from '@pandora/react-table-section';
import kebabcase from 'lodash/kebabCase';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';
import ProductProvisioningStatusUserList from 'core/products/provisioning-status-users/ProductProvisioningStatusUserList';
import trialHelper from 'core/products/trial-helper/trialHelper';
import {canViewUserDetails} from 'core/user/access/userAccess';
import {getHrefForUserDetails} from 'features/users/routing/navigation-callbacks/navigationCallbacks';

import {NO_ACCESS_DRAWER_ID, NO_ACCESS_TYPE} from './noAccessDrawerConstants';

const DRAWER_NAMESPACE = 'products.details.drawers.noAccess';
const memberListDisplay = {avatar: true, email: true};

/**
 * @description The content portion of the NoAccessDrawer. This component displays an explanation of
 *   the reason the users do not have access to the product along with a list of those users.  Users
 *   do not have access either because they are overdelegated or because they have an incompatible
 *   identity type.  Additionally, there is custom content for users who are overdelegated because
 *   they are on an expired trial.
 */
const NoAccessDrawerTypeContent = ({noAccessType, product, userQuery}) => {
  const intl = useIntl();
  const isExpiredTrial =
    noAccessType === NO_ACCESS_TYPE.OVERDELEGATED && trialHelper.isTrialProduct(product);
  const isOverDelegatedTypeAndFFEnabled =
    feature.isEnabled('temp_user_provisioning_status') &&
    noAccessType === NO_ACCESS_TYPE.OVERDELEGATED;

  const isInCompatibleIdType = noAccessType === NO_ACCESS_TYPE.INCOMPATIBLE_ID_TYPE;
  const isOverDelegateType = noAccessType === NO_ACCESS_TYPE.OVERDELEGATED;
  const defaultFeature = 'default';
  const goUrlInfo = getGoUrlInfo();

  function getTitleId() {
    const titleKey = 'title';
    const trialTitleKey = 'trialTitle';
    if (
      feature.isDisabled('temp_user_provisioning_status') &&
      noAccessType === NO_ACCESS_TYPE.OVERDELEGATED
    ) {
      if (isExpiredTrial) {
        return `${DRAWER_NAMESPACE}.${noAccessType}.${trialTitleKey}`;
      }
    }
    return `${DRAWER_NAMESPACE}.${noAccessType}.${titleKey}`;
  }

  function isTitleShown() {
    return (
      (feature.isDisabled('temp_user_provisioning_status') &&
        noAccessType === NO_ACCESS_TYPE.OVERDELEGATED) ||
      noAccessType === NO_ACCESS_TYPE.INCOMPATIBLE_ID_TYPE
    );
  }

  function getDescriptionId() {
    const descriptionKey = 'description';
    const trialDescriptionKey = 'trialDescription';
    if (feature.isEnabled('temp_user_provisioning_status')) {
      return `${DRAWER_NAMESPACE}.${noAccessType}.${descriptionKey}.${defaultFeature}`;
    }
    if (feature.isDisabled('temp_user_provisioning_status') && isExpiredTrial) {
      return `${DRAWER_NAMESPACE}.${noAccessType}.${trialDescriptionKey}`;
    }
    return `${DRAWER_NAMESPACE}.${noAccessType}.${descriptionKey}`;
  }

  function getGoUrlInfo() {
    const link = 'link';
    let goUrlId = null;
    const goUrlName =
      noAccessType === NO_ACCESS_TYPE.OVERDELEGATED
        ? 'users_without_license_access'
        : 'ac_identity';
    if (feature.isEnabled('temp_user_provisioning_status')) {
      if (noAccessType === NO_ACCESS_TYPE.OVERDELEGATED) {
        goUrlId = `${DRAWER_NAMESPACE}.${noAccessType}.${link}`;
      }
      if (noAccessType === NO_ACCESS_TYPE.INCOMPATIBLE_ID_TYPE) {
        goUrlId = `${DRAWER_NAMESPACE}.${noAccessType}.${link}.${defaultFeature}`;
      }
    }
    goUrlId = `${DRAWER_NAMESPACE}.${noAccessType}.${link}`;
    return {
      goUrlId,
      goUrlName,
    };
  }

  const [downloadInProgress, setDownloadInProgress] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [userList, setUserList] = useState([]);
  const fileName = `${kebabcase(product.longName)}-no-access-users-report.csv`;

  const onClickDownloadCsv = async () => {
    setDownloadInProgress(true);
    showInfoToast(intl.formatMessage({id: 'common.toast.csvDownloadBeingPrepared'}));

    try {
      const results = await ProductProvisioningStatusUserList.getNoAccessUsersCSV({
        orgId: rootStore.organizationStore.activeOrgId,
        productId: product.id,
      });
      downloadExportData(results.data.file, fileName);
      showSuccessToast(intl.formatMessage({id: 'common.toast.csvDownloadSuccess'}));
    } catch (error) {
      showErrorToast(intl.formatMessage({id: 'common.toast.downloadError'}));
      log.error('NoAccessDrawer failed download', error);
    } finally {
      setDownloadInProgress(false);
    }
  };

  // Query for users on initial render and whenever the page number changes
  useEffect(() => {
    const queryUsers = async () => {
      try {
        const users = await userQuery({
          orgId: rootStore.organizationStore.activeOrgId,
          pageNumber,
          pageSize: 10,
          productId: product.id,
        });
        setUserList(users);
      } catch (error) {
        log.error('Failed to query users with no access', error);
      }
    };
    queryUsers();
  }, [pageNumber, product.id, userQuery]);

  const onTableSectionChange = useCallback(({action}) => {
    switch (action) {
      case TABLE_SECTION_ACTIONS.GO_TO_NEXT_PAGE:
        setPageNumber((curr) => curr + 1);
        break;
      case TABLE_SECTION_ACTIONS.GO_TO_PREVIOUS_PAGE:
        setPageNumber((curr) => curr - 1);
        break;
      default:
        break;
    }
  }, []);

  return (
    <View>
      {isTitleShown() && (
        <Heading level={3} marginY="size-100">
          {intl.formatMessage({id: getTitleId()})}
        </Heading>
      )}
      <Text>
        {intl.formatMessage({id: getDescriptionId()})}
        {(isInCompatibleIdType || isOverDelegatedTypeAndFFEnabled) && (
          <GoUrl marginStart="size-40" name={goUrlInfo.goUrlName} noWrap={false}>
            {intl.formatMessage({id: goUrlInfo.goUrlId})}
          </GoUrl>
        )}
      </Text>
      <View>
        {isOverDelegateType && (
          <Button
            data-testid="download-users-csv-button"
            isDisabled={downloadInProgress}
            marginY="size-200"
            onPress={onClickDownloadCsv}
            variant="primary"
          >
            {intl.formatMessage({id: `${DRAWER_NAMESPACE}.${noAccessType}.button`})}
          </Button>
        )}
      </View>

      {userList?.items?.length && (
        <TableSection
          items={userList.items}
          onTableSectionChange={onTableSectionChange}
          pageNumber={pageNumber}
          // hide page size dropdown - note this is not officially supported by Table Section
          pageSizeOptions={false}
          // Compensate for inconsistent paging headers returned by the API - https://jira.corp.adobe.com/browse/ONESIE-36760
          totalPages={Math.max(pageNumber, userList.pagination.totalPages)}
        >
          <MemberListTable
            allowsSorting={false}
            aria-labelledby={NO_ACCESS_DRAWER_ID}
            canViewMemberDetails={canViewUserDetails}
            display={memberListDisplay}
            getDisplayNameHref={(member) => getHrefForUserDetails({userId: member.id})}
          />
        </TableSection>
      )}
    </View>
  );
};

NoAccessDrawerTypeContent.propTypes = {
  /* The type of access failure for these users. */
  noAccessType: PropTypes.oneOf(Object.values(NO_ACCESS_TYPE)).isRequired,
  /* Product that these users don't have access to */
  product: PropTypes.instanceOf(Product).isRequired,
  /* Async function that returns a list of users without access to this product */
  userQuery: PropTypes.func.isRequired,
};

export default NoAccessDrawerTypeContent;
