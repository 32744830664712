import {AuthenticatedUser, Locale, log} from '@admin-tribe/acsc';
import {fetchPersistentQuery} from '@pandora/aem-content-fetcher';

import rootStore from 'core/RootStore';

import {getFreeProductModal, updateFreeProductModalStatus} from '../../api/aswMigration';

import {
  API_ERROR_EXPIRY_IN_DAYS,
  API_ERROR_KEY,
  B2B_FREE_OFFER_NOTIFICATION_CONTENT,
  B2B_FREE_OFFER_PROVISIONING_CONTENT,
  ONE_DAY_IN_MILLISECONDS,
  REMIND_LATER_BANNER_EXPIRY_IN_DAYS,
  REMIND_LATER_KEY,
  SHOW_AEM_MODAL,
} from './AemConstants';

const AEM_MODAL_MAPPING = {
  AVAILABLE_NOW: {isTC: false, name: B2B_FREE_OFFER_PROVISIONING_CONTENT},
  AVAILABLE_NOW_ACCEPT_TNC: {isTC: true, name: B2B_FREE_OFFER_PROVISIONING_CONTENT},
  AVAILABLE_SOON: {isTC: false, name: B2B_FREE_OFFER_NOTIFICATION_CONTENT},
  AVAILABLE_SOON_ACCEPT_TNC: {isTC: true, name: B2B_FREE_OFFER_NOTIFICATION_CONTENT},
};

// Object to store the modal type and cacheable status
const modalTypeObj = {};

/**
 * Fetch aem content from ODIN API
 *
 * @returns {Promise} a promise which will resolve with the response
 */
const getAemContent = async () => {
  let modalContent;
  try {
    const localeVariables = Locale.get().activeLocaleCode.toLowerCase();
    modalContent = await fetchPersistentQuery({
      queryName: 'adminconsole/b2b-free-offer-modal',
      variables: {locale: localeVariables},
    });
  } catch (error) {
    log.error('Error calling aem for b2b-free-offer-modal:', error);
  }
  return modalContent;
};

/**
 * Get the modal type for the Org and User
 *
 * @param {string} orgId - Organization ID
 * @param {string} userId - User ID
 *
 * @returns {Promise} a promise which will resolve with the response
 */
const getModalType = async (orgId, userId) => {
  try {
    Object.assign(modalTypeObj, await getFreeProductModal(orgId));
    if (modalTypeObj?.modalType === 'NONE' && modalTypeObj?.isCacheable) {
      localStorage.setItem(JSON.stringify({id: SHOW_AEM_MODAL, orgId, userId}), 'false');
    }
  } catch (error) {
    log.error(`Failed to fetch modalType for - orgId:${orgId} userId:${userId}`, error);
    throw new Error('Failed to fetch modalType for the specified orgId and userId');
  }
  return modalTypeObj;
};

/**
 * Map ODIN content to modal fields
 *
 * @param {Object} aemContent - ODIN content
 * @param {string} modalType - Modal Type
 *
 * @returns {Object} a mapped object
 */
const mapAEMContent = (aemContent, modalType) => {
  const nestedData = aemContent?.data?.b2bFreeOfferModalList.items || [];
  const modalContentMappingObj = AEM_MODAL_MAPPING[modalType];
  const modalContent = nestedData.find((item) => item.name === modalContentMappingObj?.name);
  if (modalContent === undefined) {
    return undefined;
  }

  return {
    acrobatUrl: modalContent.acrobatUrl,
    analyticsId: modalContent.analyticsId,
    body: modalContent.body?.plaintext,
    body2: modalContent.body2?.plaintext,
    bodyImageRatio: modalContent.bodyImageRatio,
    bodyLearnMoreUrl: modalContent.bodyLearnMoreUrl,
    buttons: modalContent.buttons || [],
    checkboxText: modalContent.checkboxText,
    expressUrl: modalContent.expressUrl,
    heading: modalContent.heading,
    imageUrl: modalContent.imageUrl,
    isTcRequired: modalContentMappingObj.isTC,
    modalSize: modalContent.modalSize,
    name: modalContent.name,
    tcInfoText: modalContent.tcInfoText?.plaintext,
    tcUrl: modalContent.tcUrl,
  };
};

const handleFreeProductModalStatus = async (orgId, userId) => {
  try {
    await updateFreeProductModalStatus(modalTypeObj.modalType, orgId);
    localStorage.removeItem(JSON.stringify({id: API_ERROR_KEY, orgId, userId}));
  } catch (error) {
    log.error('free product modal status failed to update. Error: ', error);
    localStorage.setItem(
      JSON.stringify({id: API_ERROR_KEY, orgId, userId}),
      new Date().getTime() + API_ERROR_EXPIRY_IN_DAYS * ONE_DAY_IN_MILLISECONDS
    );
  }
};

/**
 * CTA for Got it and Accept Button.
 *
 * @param {Function} closeModal - Function to close the modal
 */
const successButtonCTA = (closeModal) => {
  const orgId = rootStore.organizationStore.activeOrgId;
  const userId = AuthenticatedUser.get().getId();

  handleFreeProductModalStatus(orgId, userId);
  closeModal();
};

/**
 * CTA for "Remind me later" Button.
 *
 * @param {Function} closeModal - Function to close the modal
 */
const remindLaterButtonCTA = (closeModal) => {
  const userId = AuthenticatedUser.get().getId();
  const orgId = rootStore.organizationStore.activeOrgId;
  try {
    const remindLaterTime =
      new Date().getTime() + REMIND_LATER_BANNER_EXPIRY_IN_DAYS * ONE_DAY_IN_MILLISECONDS;
    localStorage.setItem(JSON.stringify({id: REMIND_LATER_KEY, orgId, userId}), [
      remindLaterTime,
      modalTypeObj?.modalType,
    ]);
  } catch (error) {
    log.error('Failed to set remindLater expiry date. Error: ', error);
  } finally {
    closeModal();
  }
};

const shouldShowModal = (orgId, userId) => {
  const showModal = localStorage.getItem(JSON.stringify({id: SHOW_AEM_MODAL, orgId, userId}));
  const apiErrorExpiry = localStorage.getItem(JSON.stringify({id: API_ERROR_KEY, orgId, userId}));
  if (showModal === 'false') {
    return false;
  }

  if (apiErrorExpiry && new Date().getTime() < Number.parseInt(apiErrorExpiry, 10)) {
    return false;
  }
  return true;
};

/**
 * Fetches AEM content and modal type.
 *
 * @returns {Object | null} a mapped object or null
 */
const aemContentFetcher = async () => {
  const userId = AuthenticatedUser.get().getId();
  const orgId = rootStore.organizationStore.activeOrgId;
  const [remindLaterExpiry, remindLaterModalType] =
    localStorage.getItem(JSON.stringify({id: REMIND_LATER_KEY, orgId, userId}))?.split(',') ?? [];

  if (!shouldShowModal(orgId, userId)) return null;

  try {
    const [aemContent, {modalType}] = await Promise.all([
      getAemContent(),
      getModalType(orgId, userId),
    ]);

    if (!modalType || modalType === 'NONE') {
      localStorage.removeItem(JSON.stringify({id: REMIND_LATER_KEY, orgId, userId}));
      return null;
    }
    if (remindLaterExpiry && new Date().getTime() < Number.parseInt(remindLaterExpiry, 10)) {
      if (modalType === remindLaterModalType) {
        return null;
      }
      localStorage.removeItem(JSON.stringify({id: REMIND_LATER_KEY, orgId, userId}));
    }

    const mappedAemContent = mapAEMContent(aemContent, modalType);
    return mappedAemContent;
  } catch (error) {
    log.error('Error calling fetcher:', error);
  }
  return null;
};

export {
  aemContentFetcher,
  API_ERROR_EXPIRY_IN_DAYS,
  API_ERROR_KEY,
  B2B_FREE_OFFER_NOTIFICATION_CONTENT,
  B2B_FREE_OFFER_PROVISIONING_CONTENT,
  mapAEMContent,
  ONE_DAY_IN_MILLISECONDS,
  REMIND_LATER_BANNER_EXPIRY_IN_DAYS,
  REMIND_LATER_KEY,
  successButtonCTA,
  remindLaterButtonCTA,
};
