import {PageContext, Product} from '@admin-tribe/acsc';
import PropTypes from 'prop-types';
import React, {createContext, useContext, useMemo} from 'react';

import UserOperations from 'common/entities/user-operations/UserOperations';
import MemberStore from 'common/stores/member-store/MemberStore';

/**
 * Private Context for any component which uses an admin list API to populate a table.
 * Used to pass the store between sub-components.
 */
const MemberListContext = createContext(null);
const useMemberListContext = () => useContext(MemberListContext);

// eslint-disable-next-line @admin-tribe/admin-tribe/one-component-file -- this is one component
const MemberListContextProvider = ({
  addMemberProps,
  canAddLicense = false,
  canAddMember = true,
  canAddMemberDisabled = false,
  canAddMemberByCsv = false,
  canRemoveMember = true,
  children,
  getIsItemSelectable = () => true,
  hasSearch = true,
  loadingMessage,
  memberListDisplay,
  pageContext,
  productIconList,
  store,
  userOperations,
  syncIconActionButtonTooltipText,
}) => {
  const value = useMemo(
    () => ({
      addMemberProps,
      canAddLicense,
      canAddMember,
      canAddMemberByCsv,
      canAddMemberDisabled,
      canRemoveMember,
      getIsItemSelectable,
      hasSearch,
      loadingMessage,
      memberListDisplay,
      pageContext,
      productIconList,
      store,
      syncIconActionButtonTooltipText,
      userOperations,
    }),
    [
      addMemberProps,
      canAddLicense,
      canAddMember,
      canAddMemberByCsv,
      canAddMemberDisabled,
      canRemoveMember,
      getIsItemSelectable,
      hasSearch,
      loadingMessage,
      memberListDisplay,
      pageContext,
      productIconList,
      store,
      syncIconActionButtonTooltipText,
      userOperations,
    ]
  );
  return <MemberListContext.Provider value={value}>{children}</MemberListContext.Provider>;
};

MemberListContextProvider.propTypes = {
  /**
   * Props to pass to the 'Add' modal for the given type of member.
   * This is required if props needed for a modal can't be found in the pageContext.
   */
  addMemberProps: PropTypes.shape({
    productToAddAdmin: PropTypes.instanceOf(Product),
    roles: PropTypes.arrayOf(PropTypes.string),
  }),
  /**
   * A boolean indicating whether or not the table should support the add license operation.
   * If false, the add license button is not shown.
   * The default is false.
   */
  canAddLicense: PropTypes.bool,
  /**
   * A boolean indicating whether or not the table should support the add operation.
   * If false, the add button is not shown.
   * The default is true.
   */
  canAddMember: PropTypes.bool,
  /**
   * A boolean indicating whether or not the table should support the add by CSV operation.
   * If true, the add by CSV button is shown.
   * The default is false.
   */
  canAddMemberByCsv: PropTypes.bool,
  /**
   * A boolean indicating whether or not the table should disable the add operation.
   * If canAddMember is true and this is true, the canAddMember button is rendered but it is disabled.
   * The default is  false.
   */
  canAddMemberDisabled: PropTypes.bool,
  /**
   * A boolean indicating whether or not the table should support the remove operation.
   * If false, selection is not enabled and the remove button is disabled.
   * The default is true.
   */
  canRemoveMember: PropTypes.bool,
  /**
   * The content to be passed through the content provider.
   */
  children: PropTypes.node.isRequired,
  /**
   * Function which when passed a member, returns true if the member is selectable, else false.
   * If the function is not provided, the default is a function which returns true.
   */
  getIsItemSelectable: PropTypes.func,
  /**
   * If true, the search component will be shown.
   * The default is true.
   */
  hasSearch: PropTypes.bool,
  /**
   * Optional message to display under the ProgressCircle when the table is loading.
   */
  loadingMessage: PropTypes.string,
  /**
   * The object which determines which columns in the table are shown.
   * The name column is always shown but all other columns are optional.
   */
  memberListDisplay: PropTypes.shape({
    adminRole: PropTypes.bool,
    avatar: PropTypes.bool,
    email: PropTypes.bool,
    idType: PropTypes.bool,
    productIcons: PropTypes.bool,
    provisioningStatus: PropTypes.bool,
    viewDetails: PropTypes.bool,
  }),
  /**
   * The PageContext object used to dispatch analytics for the add and remove operations.
   */
  pageContext: PropTypes.instanceOf(PageContext).isRequired,
  /**
   * If specified, it should be a MemberProductIconList component.
   * Because of a circuler dependency PropTypes.instanceOf(MemberProductIconList) won't work here.
   */
  productIconList: PropTypes.node,
  /**
   * The domain store for the type of member - for example AdminStore, UserStore or ProductUserStore.
   */
  store: PropTypes.instanceOf(MemberStore).isRequired,
  /**
   * Override the SyncIconActionButton tooltipText property
   */
  syncIconActionButtonTooltipText: PropTypes.string,
  /**
   * The user operations defined for the UserOperationsMenu.
   * If not defined the menu will not be shown.
   */
  userOperations: PropTypes.instanceOf(UserOperations),
};

export {MemberListContextProvider, useMemberListContext};
