import {formatFileSize, formatPercentage} from '@admin-tribe/acsc-ui';
import {
  Cell,
  Column,
  Link,
  Row,
  TableBody,
  TableHeader,
  TableView,
  View,
} from '@adobe/react-spectrum';
import {EN_DASH} from '@pandora/react-table-section';
import {DividerPlacement, TitledSection} from '@pandora/react-titled-section';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import StorageTopFolder from 'common/entities/storage-top-folder/StorageTopFolder';

/**
 * Top individual users usage section for Storage overview page.
 */
const TopIndividualUsersSection = ({
  marginBottom,
  maxFolders = 5,
  navigationCallback,
  topUserFolders,
}) => {
  const intl = useIntl();

  const numFolders = Math.min(topUserFolders.length, maxFolders);
  const folders = topUserFolders.slice(0, numFolders); // shallow copy

  const getHeading = () =>
    intl.formatMessage(
      {
        id:
          numFolders === maxFolders
            ? 'storage.topIndividualUsersSection.title.top'
            : 'storage.topIndividualUsersSection.title',
      },
      {
        numFolders: maxFolders,
      }
    );

  return (
    <TitledSection
      dividerPlacement={DividerPlacement.TOP}
      headingLevel={2}
      id="top-usage-section"
      marginBottom={marginBottom}
      title={getHeading()}
    >
      <View marginTop="size-100">
        <Link onPress={() => navigationCallback('individual-user-folders')}>
          {intl.formatMessage({
            id: 'storage.topIndividualUsersSection.link.showUserFolders',
          })}
        </Link>
      </View>
      {numFolders > 0 && (
        <TableView
          aria-label={intl.formatMessage({id: 'storage.topIndividualUsersSection.table.ariaLabel'})}
          density="compact"
          marginTop="size-200"
        >
          <TableHeader>
            <Column key="name">
              <FormattedMessage id="storage.topIndividualUsersSection.table.column.name" />
            </Column>
            <Column key="usage" align="end">
              <FormattedMessage id="storage.topIndividualUsersSection.table.column.usage" />
            </Column>
            <Column key="total" align="end">
              <FormattedMessage id="storage.topIndividualUsersSection.table.column.quota" />
            </Column>
            <Column key="percentageOfQuota" align="end">
              <FormattedMessage id="storage.topIndividualUsersSection.table.column.percentageOfQuota" />
            </Column>
          </TableHeader>
          <TableBody items={folders}>
            {(item) => (
              <Row key={item.id}>
                <Cell>{item.name || EN_DASH}</Cell>
                <Cell>{formatFileSize(intl, item.consumed)}</Cell>
                <Cell>{formatFileSize(intl, item.total)}</Cell>
                <Cell>{formatPercentage(intl, item.percentage)}</Cell>
              </Row>
            )}
          </TableBody>
        </TableView>
      )}
    </TitledSection>
  );
};

TopIndividualUsersSection.propTypes = {
  /**
   * Margin to add after the section. Should be specified with Spectrum sizes.
   * The default is undefined.
   */
  marginBottom: PropTypes.string,
  /**
   * The maximum number of StorageTopFolder to show in the table. The default is 5.
   */
  maxFolders: PropTypes.number,
  /**
   * Callback for navigation. Param should be set to 'individual-user-folders'
   * which routes to the 'Individual user folders' page.
   */
  navigationCallback: PropTypes.func.isRequired,
  /**
   * An array of StorageTopFolder which may be empty.
   */
  topUserFolders: PropTypes.arrayOf(PropTypes.instanceOf(StorageTopFolder)).isRequired,
};

export default TopIndividualUsersSection;
