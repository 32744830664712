// ***********************************************************************
// ADOBE CONFIDENTIAL
// ___________________
//
// Copyright 2024 Adobe
// All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains
// the property of Adobe and its suppliers, if any. The intellectual
// and technical concepts contained herein are proprietary to Adobe
// and its suppliers and are protected by all applicable intellectual
// property laws, including trade secret and copyright laws.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Adobe.
// ************************************************************************
import {AlertDialog, DialogContainer, Flex, TextField} from '@adobe/react-spectrum';
import {error, success} from '@react/react-spectrum/Toast';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

const CopyUrlModal = ({onDismiss, urlText}) => {
  const intl = useIntl();
  // If the clipboard copy fails the dialog still closes.
  // The AlertDialog is not really the right UI for this workflow.
  const onPrimaryAction = async () => {
    try {
      // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- This is a browser check
      await window.navigator.clipboard.writeText(urlText);
      success(
        intl.formatMessage({
          id: 'pandora.autoProductAssignmentModalContent.copyUrlModalContent.copySuccess',
        }),
        {timeout: 3000}
      );
    } catch (error_) {
      // Error toasts must be explicitly dismissed by the admin.
      error(
        intl.formatMessage({
          id: 'pandora.autoProductAssignmentModalContent.copyUrlModalContent.copyFailure',
        }),
        {timeout: 0}
      );
    }
  };

  // onDismiss is called on Esc, or whenever any of the buttons are pressed
  return (
    <DialogContainer onDismiss={onDismiss}>
      <AlertDialog
        cancelLabel={intl.formatMessage({
          id: 'pandora.autoProductAssignmentModalContent.copyUrlModalContent.closeButton',
        })}
        onPrimaryAction={onPrimaryAction}
        primaryActionLabel={intl.formatMessage({
          id: 'pandora.autoProductAssignmentModalContent.copyUrlModalContent.copyLinkButton',
        })}
        title={intl.formatMessage({
          id: 'pandora.autoProductAssignmentModalContent.copyUrlModalContent.title',
        })}
        variant="confirmation"
      >
        <Flex direction="column" gap="size-200">
          {intl.formatMessage({
            id: 'pandora.autoProductAssignmentModalContent.copyUrlModalContent.description',
          })}
          <TextField
            aria-label={intl.formatMessage({
              id: 'pandora.autoProductAssignmentModalContent.copyUrlModalContent.title',
            })}
            data-testid="auto-assignment-rule-url-text-field"
            defaultValue={urlText}
            isReadOnly
            width="100%"
          />
        </Flex>
      </AlertDialog>
    </DialogContainer>
  );
};

CopyUrlModal.propTypes = {
  onDismiss: PropTypes.func,
  urlText: PropTypes.string,
};

export default CopyUrlModal;
