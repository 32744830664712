import {log} from '@admin-tribe/acsc';
import {showSuccess} from '@admin-tribe/acsc-ui';
import {Divider, Radio, RadioGroup, View} from '@adobe/react-spectrum';
import {
  ModalContent,
  ModalDialog,
  ModalDialogModel,
  ModalHeading,
} from '@pandora/react-modal-dialog';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import StorageReport from 'common/services/storage-report/StorageReport';
import {REPORT_FOLDER_TYPE} from 'common/services/storage-report/StorageReportConstants';

/**
 * The modal opened by the `Create report` button which is on StorageReportsPage and StorageReportsDetailsPage.
 */
const CreateReportModal = ({folderType, onClose, onSuccess}) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [isCreatingReport, setIsCreatingReport] = useState(false);
  const showFolderOptions = folderType === undefined;
  const [selectedFolderType, setSelectedFolderType] = useState(
    showFolderOptions ? REPORT_FOLDER_TYPE.USERS : folderType
  );

  const intl = useIntl();
  const isCurrentRef = useRef(true);

  const content = ModalDialogModel.createEntry({
    cancelLabel: intl.formatMessage({id: 'common.modal.buttons.cancel'}),
    ctaLabel: intl.formatMessage({id: 'storage.storageReports.createReportModal.button.cta'}),
  });

  // This side effect is to track when the component is mounted/unmounted
  useEffect(() => {
    isCurrentRef.current = true;

    return () => {
      isCurrentRef.current = false;
    };
  }, []);

  const onCancel = () => {
    setErrorMessage(null);
    onClose();
  };

  const onCta = async () => {
    setIsCreatingReport(true);
    setErrorMessage(null);

    try {
      await StorageReport.create(selectedFolderType);
      if (isCurrentRef.current) {
        onSuccess(selectedFolderType);
        showSuccess(
          intl.formatMessage({id: 'storage.storageReports.createReportModal.toast.confirm'})
        );
      }
      onClose();
    } catch (error) {
      log.error(`Error creating report: ${error}`);
      if (isCurrentRef.current) {
        setErrorMessage(intl.formatMessage({id: 'common.modal.error.generic'}));
      }
    } finally {
      if (isCurrentRef.current) {
        setIsCreatingReport(false);
      }
    }
  };

  return (
    <ModalDialog
      content={content}
      dialogStyle={{width: 'inherit'}}
      errorMessage={errorMessage}
      isCtaDisabled={isCreatingReport}
      isLoading={false}
      modalId="create-report-modal"
      onCancel={onCancel}
      onCta={onCta}
    >
      <ModalHeading>
        <FormattedMessage id="storage.storageReports.createReportModal.title" />
      </ModalHeading>
      <ModalContent>
        <Divider marginBottom="size-150" marginTop="size-150" size="M" />
        {showFolderOptions && (
          <>
            <View>
              <FormattedMessage id="storage.storageReports.createReportModal.description" />
            </View>
            <RadioGroup
              isDisabled={isCreatingReport}
              label={intl.formatMessage({
                id: 'storage.storageReports.createReportModal.folderType.label',
              })}
              marginTop="size-100"
              onChange={(value) => setSelectedFolderType(value)}
              value={selectedFolderType}
            >
              <Radio value="users">
                <FormattedMessage id="storage.storageReports.createReportModal.folderType.users" />
              </Radio>
              <Radio value="assets">
                <FormattedMessage id="storage.storageReports.createReportModal.folderType.assets" />
              </Radio>
            </RadioGroup>
          </>
        )}
        {!showFolderOptions && (
          <>
            <p>
              <strong>
                <FormattedMessage
                  id={`storage.storageReports.createReportModal.folderType.${folderType}`}
                />
              </strong>
            </p>
            <p>
              <FormattedMessage
                id={`storage.storageReports.createReportModal.description.${folderType}`}
              />
            </p>
          </>
        )}
      </ModalContent>
    </ModalDialog>
  );
};

CreateReportModal.propTypes = {
  /**
   * Determines whether the modal refers to creation of shared storage or individual user folders.
   * If undefined, the modal will prompt for the folderType of the report.
   */
  folderType: PropTypes.oneOf([REPORT_FOLDER_TYPE.ASSETS, REPORT_FOLDER_TYPE.USERS]),
  /**
   * Function to close the modal.
   */
  onClose: PropTypes.func.isRequired,
  /**
   * Callback to invoke when the modal's actions have been successful. There is 1 parameter which
   * is the selected folderType  - either REPORT_FOLDER_TYPE.ASSETS or REPORT_FOLDER_TYPE.USERS.
   */
  onSuccess: PropTypes.func.isRequired,
};

export default CreateReportModal;
