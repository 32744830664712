import {OverlayWait, WizardStepList} from '@admin-tribe/acsc-ui';
import {Button, ButtonGroup, Divider, Flex, useDialogContainer} from '@adobe/react-spectrum';
import {ModalContent, ModalDialog, ModalHeading} from '@pandora/react-modal-dialog';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import StepItem from 'common/components/stepped-view/StepItem';
import SteppedView from 'common/components/stepped-view/SteppedView';
import DomainsTextArea from 'features/settings/components/dns-import-domains-modal/DomainsTextArea';
import AddDomainsTableSection from 'features/settings/components/domains/add-domains-modal/add-domains-table/AddDomainsTableSection';
import {ADD_DOMAINS_MODAL_WIZARD_STEPS} from 'features/settings/components/domains/add-domains-modal/addDomainsModalConstants';
import {AddDomainsModalContextProvider} from 'features/settings/components/domains/add-domains-modal-context/AddDomainsModalContext';
import useAddDomainsSetup from 'features/settings/components/domains/hooks/useAddDomainsSetup';

const MODAL_ID = 'add-domains-modal';

const AddDomainsModal = observer(({onDomainsAdded}) => {
  const dialog = useDialogContainer();
  const intl = useIntl();

  const addDomainsSetupState = useAddDomainsSetup();
  const {
    state,
    hasClaimStep,
    claimSelectedDomains,
    ctaButtonLabelId,
    setDomainsToAdd,
    savePreviewDomains,
    requestAccess,
    isCtaButtonDisabled,
    hasRequestStep,
    addDomainsModalStepManager,
  } = addDomainsSetupState;

  const handleGoPrevious = useCallback(() => {
    if (
      addDomainsModalStepManager.currentStep.id ===
        ADD_DOMAINS_MODAL_WIZARD_STEPS.CLAIM_DOMAINS_STEP.id &&
      !hasRequestStep.current
    ) {
      addDomainsModalStepManager.goToStep(ADD_DOMAINS_MODAL_WIZARD_STEPS.ENTER_DOMAINS_STEP);
    } else {
      addDomainsModalStepManager.goPrevious();
    }

    // Unless we are at the Enter Domains step, reset the domains to be added
    // in case the user turns back at some point in the setup
    setDomainsToAdd([]);
  }, [addDomainsModalStepManager, hasRequestStep, setDomainsToAdd]);

  const onCta = useCallback(async () => {
    let response;

    switch (addDomainsModalStepManager.currentStep.id) {
      case ADD_DOMAINS_MODAL_WIZARD_STEPS.ENTER_DOMAINS_STEP.id:
        response = await savePreviewDomains();

        if (response) {
          if (hasRequestStep.current) {
            addDomainsModalStepManager.goNext();
          } else {
            addDomainsModalStepManager.goToStep(ADD_DOMAINS_MODAL_WIZARD_STEPS.CLAIM_DOMAINS_STEP);
          }
        }
        break;
      case ADD_DOMAINS_MODAL_WIZARD_STEPS.REQUEST_ACCESS_STEP.id:
        await requestAccess();

        if (hasClaimStep.current) {
          addDomainsModalStepManager.goNext();
        } else {
          dialog.dismiss();
        }

        onDomainsAdded();
        break;
      case ADD_DOMAINS_MODAL_WIZARD_STEPS.CLAIM_DOMAINS_STEP.id:
        await claimSelectedDomains();
        onDomainsAdded();
        dialog.dismiss();
        break;
      default:
        break;
    }
  }, [
    addDomainsModalStepManager,
    claimSelectedDomains,
    dialog,
    hasClaimStep,
    hasRequestStep,
    onDomainsAdded,
    requestAccess,
    savePreviewDomains,
  ]);

  const WIZARD_STEPS = Object.values(ADD_DOMAINS_MODAL_WIZARD_STEPS).map((step) =>
    intl.formatMessage({id: `settings.domains.addDomainsModal.steps.${step.id}`})
  );

  const currentStepIndex = Object.values(ADD_DOMAINS_MODAL_WIZARD_STEPS).findIndex(
    (step) => step.id === addDomainsModalStepManager.currentStep.id
  );

  return (
    <AddDomainsModalContextProvider state={addDomainsSetupState}>
      <ModalDialog height="100vh" id={MODAL_ID}>
        <ModalHeading>
          <FormattedMessage id="settings.domains.addDomainsModal.title" />
        </ModalHeading>
        <Divider />

        <ModalContent>
          <OverlayWait isLoading={state.isLoading} showContent>
            <WizardStepList currentStep={currentStepIndex} steps={WIZARD_STEPS} />

            <SteppedView currentStep={addDomainsModalStepManager.currentStep.id}>
              <StepItem id={ADD_DOMAINS_MODAL_WIZARD_STEPS.ENTER_DOMAINS_STEP.id}>
                <DomainsTextArea onChange={setDomainsToAdd} />
              </StepItem>
              <StepItem id={ADD_DOMAINS_MODAL_WIZARD_STEPS.REQUEST_ACCESS_STEP.id}>
                <p>
                  <FormattedMessage id="settings.domains.addDomainsModal.requestAccess.description" />
                </p>
                <AddDomainsTableSection />
              </StepItem>
              <StepItem id={ADD_DOMAINS_MODAL_WIZARD_STEPS.CLAIM_DOMAINS_STEP.id}>
                <p>
                  <FormattedMessage id="settings.domains.addDomainsModal.addDomains.description" />
                </p>
                <AddDomainsTableSection />
              </StepItem>
            </SteppedView>
          </OverlayWait>
        </ModalContent>

        <ButtonGroup>
          <Flex flex={1}>
            <Button
              data-testid="previous-button"
              isDisabled={state.isLoading || !addDomainsModalStepManager.canGoPrevious()}
              onPress={handleGoPrevious}
              variant="primary"
            >
              <FormattedMessage id="common.modal.buttons.back" />
            </Button>
          </Flex>

          <Flex flex={1} justifyContent="end">
            <Button data-testid="cancel-button" onPress={() => dialog.dismiss()} variant="primary">
              <FormattedMessage id="common.modal.buttons.cancel" />
            </Button>

            <Button
              data-testid="cta-button"
              isDisabled={isCtaButtonDisabled}
              onPress={onCta}
              variant="accent"
            >
              {intl.formatMessage({id: ctaButtonLabelId})}
            </Button>
          </Flex>
        </ButtonGroup>
      </ModalDialog>
    </AddDomainsModalContextProvider>
  );
});

export default AddDomainsModal;
