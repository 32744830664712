import {showError as showErrorToast, showSuccess as showSuccessToast} from '@admin-tribe/acsc-ui';
import {Flex, Text, View, useDialogContainer} from '@adobe/react-spectrum';
import {ModalAlertDialog} from '@pandora/react-modal-dialog';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import EduProviderLogo from 'features/settings/common/components/edu-provider-logo/EduProviderLogo';
import {useRosterSyncContext} from 'features/settings/components/directory/sync/RosterSyncContext';
import useEduRosterSync from 'features/settings/hooks/api/useEduRosterSync';

const StopRosterSyncConfirmationModal = ({rosterSource, syncId}) => {
  const intl = useIntl();
  const dialog = useDialogContainer();
  const [isLoading, setIsLoading] = useState(false);

  const {updateRosterSyncData} = useRosterSyncContext();
  const {stopRosterSyncAction} = useEduRosterSync();

  const handleStopSync = async () => {
    setIsLoading(true);

    try {
      const response = await stopRosterSyncAction({syncId});
      const updatedSyncData = response.data;

      updateRosterSyncData({syncId, updatedSyncData});

      showSuccessToast(
        intl.formatMessage({id: 'settings.sync.eduSync.stopSyncModal.toast.success'})
      );

      dialog.dismiss();
    } catch {
      showErrorToast(intl.formatMessage({id: 'settings.sync.eduSync.stopSyncModal.toast.error'}));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalAlertDialog
      cancelLabel={intl.formatMessage({
        id: 'settings.sync.eduSync.stopSyncModal.buttons.cancel',
      })}
      data-test-id="stop-edu-roster-sync-confirmation-modal"
      isLoading={isLoading}
      onClose={() => dialog.dismiss()}
      onPrimaryAction={handleStopSync}
      primaryActionLabel={intl.formatMessage({
        id: 'settings.sync.eduSync.stopSyncModal.buttons.stopSync',
      })}
      title={intl.formatMessage({
        id: 'settings.sync.eduSync.stopSyncModal.title',
      })}
      variant="destructive"
    >
      <View>
        <EduProviderLogo rosterSource={rosterSource} />
      </View>
      <Flex direction="column">
        <Text>
          <FormattedMessage id="settings.sync.eduSync.stopSyncModal.description" />
        </Text>

        <Text marginTop="size-200">
          <strong>
            <FormattedMessage id="settings.sync.eduSync.stopSyncModal.description.note" />
          </strong>
        </Text>
      </Flex>
    </ModalAlertDialog>
  );
};

StopRosterSyncConfirmationModal.propTypes = {
  rosterSource: PropTypes.string.isRequired,
  syncId: PropTypes.string.isRequired,
};

export default StopRosterSyncConfirmationModal;
