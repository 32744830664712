import {showError as showErrorToast, showInfo as showInfoToast} from '@admin-tribe/acsc-ui';
import {Flex, Text, View, useDialogContainer} from '@adobe/react-spectrum';
import {ModalAlertDialog} from '@pandora/react-modal-dialog';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import EduProviderLogo from 'features/settings/common/components/edu-provider-logo/EduProviderLogo';
import {useRosterSyncContext} from 'features/settings/components/directory/sync/RosterSyncContext';
import useEduRosterSync from 'features/settings/hooks/api/useEduRosterSync';

const QueueRosterSyncConfirmationModal = ({rosterSource, syncId}) => {
  const intl = useIntl();
  const dialog = useDialogContainer();
  const [isLoading, setIsLoading] = useState(false);

  const {updateRosterSyncData} = useRosterSyncContext();
  const {queueRosterSyncAction} = useEduRosterSync();

  const handleQueueSync = async () => {
    setIsLoading(true);

    try {
      const response = await queueRosterSyncAction({syncId});
      const updatedSyncData = response.data;

      updateRosterSyncData({syncId, updatedSyncData});

      showInfoToast(intl.formatMessage({id: 'settings.sync.eduSync.toast.addToQueue'}));

      dialog.dismiss();
    } catch {
      showErrorToast(intl.formatMessage({id: 'settings.sync.eduSync.toast.error'}));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalAlertDialog
      cancelLabel={intl.formatMessage({
        id: 'settings.sync.eduSync.queueSyncModal.buttons.cancel',
      })}
      data-test-id="queue-edu-roster-sync-modal"
      isLoading={isLoading}
      onClose={() => dialog.dismiss()}
      onPrimaryAction={handleQueueSync}
      primaryActionLabel={intl.formatMessage({
        id: 'settings.sync.eduSync.queueSyncModal.buttons.queueNow',
      })}
      title={intl.formatMessage({
        id: 'settings.sync.eduSync.queueSyncModal.title',
      })}
      variant="confirmation"
    >
      <View>
        <EduProviderLogo rosterSource={rosterSource} />
      </View>
      <Flex direction="column">
        <Text>
          <FormattedMessage id="settings.sync.eduSync.queueSyncModal.description" />
        </Text>

        <Text marginTop="size-200">
          <strong>
            <FormattedMessage id="settings.sync.eduSync.queueSyncModal.description.note" />
          </strong>
        </Text>
      </Flex>
    </ModalAlertDialog>
  );
};

QueueRosterSyncConfirmationModal.propTypes = {
  rosterSource: PropTypes.string.isRequired,
  syncId: PropTypes.string.isRequired,
};

export default QueueRosterSyncConfirmationModal;
