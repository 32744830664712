import {log} from '@admin-tribe/acsc';

import digitalData from './digitalData';

/**
 *
 * @param {*} propName
 * @param {*} data
 * @param {*} deleteProperty
 */
function setProperty(propName, data, deleteProperty = true) {
  digitalData.setProperty(propName, data, deleteProperty);
}

/**
 *
 * @param {*} name
 * @param {*} action
 */
function trackEvent(name, action) {
  setProperty('primaryEvent.eventInfo.eventName', `adminConsole:${name}:${action}`);
  setProperty('primaryEvent.eventInfo.eventAction', name);

  setProperty('digitalData.primaryEvent.eventInfo.eventName', `adminConsole:${name}:${action}`);
  setProperty('digitalData.primaryEvent.eventInfo.eventAction', name);

  track();
}

/**
 *
 * @param {*} type
 */
function track(type = 'event') {
  try {
    // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- unit testing is not needed here as it is not server side rendering
    /* istanbul ignore next -- unit testing is not needed here as it is it is not server side rendering */
    if (typeof window !== 'undefined') {
      // `_satellite` is a global object provided by Adobe Launch. It's necessary to use it for tracking.
      const {_satellite} = window;
      if (_satellite) {
        _satellite.track(type, {
          data: digitalData.createEventDataAndCleanUp(),
        });
      }
    }
  } catch (error) {
    log.error('Analytics: Unable to file analytics data.', error);
  }
}

export {setProperty, trackEvent, track};
