import {feature, translateJson} from '@admin-tribe/acsc';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import set from 'lodash/set';

import {CANCEL_REASONS} from '../AnalyticsConstants';
import {setProperty} from '../aepAnalytics';

function getProductList(items) {
  return items?.map((item) => {
    const product = {};
    translateJson(item, product, {
      assignableLicenseCount: 'attributes.totalLicense', // Purchased licenses
      customer_segment: 'attributes.customerSegment',
      numberSelected: 'quantity',
      offer_id: 'productInfo.offerId',
      pricing: {
        amount_with_tax: 'price.priceWithTax',
        amount_without_tax: 'price.basePrice',
        tax: 'price.tax',
      },
      productBucket: 'attributes.sourceOfferLocation',
      provisionedQuantity: 'attributes.assignedLicense', // Assigned licenses
    });

    // moved these product assignments out of translateJson due to problems feature flagging within the map variable
    if (feature.isEnabled('temp_renewal_analytcs_promos')) {
      set(product, 'price.currency', get(item, 'pricing.currency.code'));
      set(product, 'price.taxRate', get(item, 'pricing.tax_rate'));
    }

    return product;
  });
}

function setTransactionDetails(cartDescriptor) {
  setProperty('transaction.attributes.isNFR', get(cartDescriptor, 'contract.isNFR'));

  // Use '.contract' for clarification
  setProperty('transaction.attributes.contract.model', get(cartDescriptor, 'contract.model'));

  setProperty(
    'transaction.attributes.contract.salesChannel',
    get(cartDescriptor, 'contract.salesChannel')
  );

  setProperty(
    'transaction.attributes.contract.customerSegment',
    get(cartDescriptor, 'contract.customerSegment')
  );

  setProperty('transaction.attributes.contract.model', get(cartDescriptor, 'contract.model'));

  setProperty('transaction.attributes.contractID', get(cartDescriptor, 'contract.id'));

  setProperty(
    'transaction.attributes.offsetFromAnniversaryDate',
    get(cartDescriptor, 'contract.offsetFromAnniversaryDate')
  );

  setProperty(
    'transaction.attributes.inRenewalWindow',
    get(cartDescriptor, 'contract.inRenewalWindow')
  );

  setProperty('transaction.total.basePrice', get(cartDescriptor, 'total.subtotal'));

  setProperty('transaction.total.priceWithTax', get(cartDescriptor, 'total.total'));

  setProperty('currency.code', get(cartDescriptor, 'total.currency.code'));

  setProperty('transaction.transactionID', get(cartDescriptor, 'purchaseAuthId'));
}

function setCancellationDetails(cartDescriptor) {
  const cancellationReasons = get(cartDescriptor, 'cancellationReasons');
  // Don't send an empty array to '...userSelectedReason'
  if (!isEmpty(cancellationReasons)) {
    const reasonList = cancellationReasons.map((reason) => {
      const reasonObj = {reasonID: reason};
      if (reason === CANCEL_REASONS.OTHER) {
        // Just set the value because Launch will eliminate empty string, null, and undefined values
        reasonObj.userReason = get(cartDescriptor, 'cancellationReasonComment');
      }
      return reasonObj;
    });

    setProperty('adminConsole.purchase.userSelectedReason', reasonList);
  }
}

function setCartDetails(cartDescriptor, productList) {
  setProperty('cart.attributes', get(cartDescriptor, 'attributes'));
  setProperty('cart.id', get(cartDescriptor, 'id'));
  setProperty('cart.item', productList);
  setProperty('cart.price.basePrice', get(cartDescriptor, 'total.subtotal'));
  setProperty('cart.price.cartTotal', get(cartDescriptor, 'total.total'));
  setProperty('cart.price.currency', get(cartDescriptor, 'total.currency.code'));
  setProperty('cart.price.priceWithTax', get(cartDescriptor, 'total.total'));
}

/**
 *
 * @param {*} launchContext
 * @param {*} cartDescriptor
 */
export default function cartEventTranslator(cartDescriptor) {
  const items = get(cartDescriptor, 'items');
  const productList = getProductList(items);

  if (feature.isEnabled('temp_renewal_analytcs_promos')) {
    setCartDetails(cartDescriptor, productList);
  } else {
    setProperty('product', productList);
  }

  setTransactionDetails(cartDescriptor);

  setCancellationDetails(cartDescriptor);
}
