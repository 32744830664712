import {Product, feature} from '@admin-tribe/acsc';
import {
  DETAIL_SECTION_VARIANT_CONSTANTS,
  DetailSection,
  DetailSectionMessage,
  getProductDisplayName,
} from '@admin-tribe/acsc-ui';
import {Flex, Heading, View} from '@adobe/react-spectrum';
import {
  SubTitleWithTooltip,
  SubTitleWithTooltipContentModel,
} from '@pandora/react-assignment-modal';
import {ImageIcon} from '@pandora/react-image-icon';
import {useId} from '@react-aria/utils';
import {Item, TagGroup} from '@react-spectrum/tag';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';

import ProductDetailAccordion from 'common/components/products-detail-section/product-detail-accordion/ProductDetailAccordion';
import UserGroupLinkMessage from 'common/components/user-group-link-message/UserGroupLinkMessage';
import trialHelper from 'core/products/trial-helper/trialHelper';
import {getContractDisplayNames} from 'core/products/utils/productUtils';
import FiProductGroupProductList from 'core/services/product/product-group/product-list/FiProductGroupProductList';
import ProductGroupProductList from 'core/services/product/product-group/product-list/ProductGroupProductList';

import UserProvisioningStatus from './UserProvisioningStatus';
import {getContractDisplayName} from './productsDetailSectionUtils';

/**
 * The Products detail section.
 * Once the accordion has focus you can use the `Enter` key to open and close it
 */
/* eslint-disable complexity -- not refactoring now */
const ProductsDetailSection = ({
  headingLevel = 3,
  isDeveloperAccess = false,
  isPageVariant,
  products,
  rightHeaderContent,
  isLoading,
  showError,
}) => {
  const intl = useIntl();
  const trialTagId = useId();
  const subtitleTooltipText = intl.formatMessage({
    id: 'common.productsDetailSection.subtitleTooltipText',
  });
  const subtitleTooltipContent = SubTitleWithTooltipContentModel.createEntry({
    subTextLinkSuffix: subtitleTooltipText,
  });

  const tags = useMemo(() => {
    const result = {};

    products?.forEach((product) => {
      result[product.id] = [];
      if (trialHelper.isTrialProduct(product)) {
        result[product.id].push({
          key: trialTagId,
          value: intl.formatMessage({id: 'common.tagGroup.tag.trial'}),
        });
      }
    });

    return result;
  }, [intl, products, trialTagId]);

  const productNameHeadingLevel = headingLevel + 1;
  const accordionAriaLevel = headingLevel + 2;

  const getGroupLabel = () =>
    intl.formatMessage({id: 'common.productsDetailSection.label'}, {count: 1});

  const sectionTitle = isDeveloperAccess
    ? intl.formatMessage({id: 'common.productsDetailSection.developerAccess.title'})
    : intl.formatMessage({id: 'common.productsDetailSection.header'});

  const noProductsMessage = intl.formatMessage({
    id: isDeveloperAccess
      ? 'common.productsDetailSection.developerAccess.noProducts'
      : 'common.productsDetailSection.noProducts',
  });

  const flexProps = isPageVariant
    ? {
        direction: {base: 'column', L: 'row'},
        justifyContent: {base: 'stretch', L: 'space-between'},
        rowGap: {base: 'size-0', L: 'size-150'},
        wrap: {base: false, L: true},
      }
    : {direction: 'column', justifyContent: 'stretch', rowGap: 'size-150', wrap: false};

  const productFlex = isPageVariant ? {base: null, L: '0 0 50%'} : null;

  return (
    <DetailSection
      data-testid="products-detail-section"
      headingLevel={headingLevel}
      isLoading={isLoading}
      rightHeaderContent={rightHeaderContent}
      showError={showError}
      title={sectionTitle}
      variant={isPageVariant && DETAIL_SECTION_VARIANT_CONSTANTS.PAGE}
    >
      {/* Don't render anything until ready to show it. */}
      {!isLoading &&
        !showError &&
        (products?.length > 0 ? (
          <Flex {...flexProps}>
            {products?.map((product, index) => {
              const productName = getProductDisplayName(intl, product);
              const contractDisplayNames = getContractDisplayNames(product.contractIds);
              return (
                <Flex
                  key={product.id}
                  columnGap="size-200"
                  direction="row"
                  flex={productFlex}
                  minHeight={index !== products.length - 1 && 'size-800'}
                >
                  <View flexShrink="0">
                    <ImageIcon
                      alt=""
                      className="image-icon-class"
                      size="L"
                      src={product.getIcon()}
                    />
                  </View>
                  <Flex direction="column">
                    <Flex alignItems="center" direction="row">
                      <Heading
                        id={`product-${product.id}`}
                        level={productNameHeadingLevel}
                        marginY="size-0"
                        UNSAFE_style={{overflowWrap: 'anywhere'}}
                      >
                        {productName}
                      </Heading>
                      {tags[product.id].length > 0 && (
                        <TagGroup
                          allowsRemoving={false}
                          aria-label={intl.formatMessage(
                            {id: 'common.tagGroup.product.label'},
                            {productName}
                          )}
                          data-testid="product-tag-group"
                          flexShrink="0"
                          marginStart="size-25"
                        >
                          {tags[product.id].map((tag) => (
                            <Item key={tag.key}>{tag.value}</Item>
                          ))}
                        </TagGroup>
                      )}
                    </Flex>
                    {renderContractDisplayName(product)}
                    {feature.isEnabled('temp_user_provisioning_status') &&
                      feature.isEnabled('temp_add_contract_display_name') &&
                      contractDisplayNames?.length > 0 && (
                        <SubTitleWithTooltip
                          content={subtitleTooltipContent}
                          data-testid="contract-id-text"
                          subtitles={contractDisplayNames}
                        />
                      )}
                    {product.licenseGroups?.length === 1 && product.isEnterprise() && (
                      <>
                        <View aria-label={getGroupLabel()} data-testid="license-group-text">
                          {product.licenseGroups[0].name}
                        </View>
                        {product.licenseGroups[0]?.userGroup && (
                          <View data-testid="license-group-user-group">
                            <UserGroupLinkMessage
                              msgKeyNamespace="common.productsDetailSection"
                              userGroup={product.licenseGroups[0]?.userGroup}
                            />
                          </View>
                        )}
                      </>
                    )}
                    {product.licenseGroups?.length > 1 && (
                      <ProductDetailAccordion
                        aria-labelledby={`product-${product.id}`}
                        ariaLevel={accordionAriaLevel}
                        data-testid="license-group-accordion"
                        product={product}
                      />
                    )}
                    {product instanceof FiProductGroupProductList ||
                    product.fulfillableItemList?.hasOrgDelegatable() ? (
                      <View data-testid="auto-assigned-text">
                        {intl.formatMessage({id: 'common.productsDetailSection.autoAssigned'})}
                      </View>
                    ) : (
                      feature.isEnabled('temp_user_provisioning_status') && (
                        <View data-testid="user-provisioning-section">
                          <UserProvisioningStatus
                            product={product}
                            provisioningStatus={product.provisioningStatus}
                          />
                        </View>
                      )
                    )}
                  </Flex>
                </Flex>
              );
            })}
          </Flex>
        ) : (
          <DetailSectionMessage translatedString={noProductsMessage} />
        ))}
    </DetailSection>
  );
};

function renderContractDisplayName(product) {
  if (
    feature.isEnabled('temp_user_provisioning_status') &&
    feature.isDisabled('temp_add_contract_display_name')
  ) {
    const displayName = getContractDisplayName(product.contractIds);
    if (displayName) {
      return (
        <View aria-label={displayName} data-testid="contract-id-text">
          {displayName}
        </View>
      );
    }
  }
  return null;
}

ProductsDetailSection.propTypes = {
  /**
   * The heading level for the section Heading. The is 3.
   */
  headingLevel: PropTypes.number,
  /**
   * Whether section is for developer access products. Defaults to false.
   */
  isDeveloperAccess: PropTypes.bool,
  /**
   * Whether the component should display the loading indicator or not.
   */
  isLoading: PropTypes.bool,
  /**
   * Whether the section has page variant or not.
   */
  isPageVariant: PropTypes.bool,
  /**
   * Sorted list of products to show.
   */
  products: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.instanceOf(Product),
      PropTypes.instanceOf(ProductGroupProductList),
    ]).isRequired
  ),
  /**
   * Content to be displayed on the right side of the header.
   */
  rightHeaderContent: PropTypes.node,
  /**
   * Whether an error occurred during fetching data or not.
   */
  showError: PropTypes.bool,
};

export default ProductsDetailSection;
/* eslint-enable complexity -- not refactoring now */
