// ***********************************************************************
// ADOBE CONFIDENTIAL
// ___________________
//
// Copyright 2024 Adobe
// All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains
// the property of Adobe and its suppliers, if any. The intellectual
// and technical concepts contained herein are proprietary to Adobe
// and its suppliers and are protected by all applicable intellectual
// property laws, including trade secret and copyright laws.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Adobe.
// ************************************************************************

import {Content, ContextualHelp, Heading, Text} from '@adobe/react-spectrum';
import React from 'react';
import {useIntl} from 'react-intl';

import DevConsoleLink from 'features/products/components/dev-console-link/DevConsoleLink';

const DevConsoleUsageContextualHelp = () => {
  const intl = useIntl();
  return (
    <ContextualHelp data-testid="firefly-contextual-help" marginStart="size-100" variant="info">
      <Heading UNSAFE_style={{marginBottom: 'size-100', paddingBottom: 0}}>
        {intl.formatMessage({id: 'products.allProducts.firefly.contextualHelp.title'})}
      </Heading>
      <Content UNSAFE_style={{paddingTop: 0}}>
        <Text>
          {intl.formatMessage(
            {id: 'products.allProducts.firefly.contextualHelp.content'},
            {
              devConsoleLink: (linkText) => DevConsoleLink({linkText}),
            }
          )}
        </Text>
      </Content>
    </ContextualHelp>
  );
};

DevConsoleUsageContextualHelp.propTypes = {};

export default DevConsoleUsageContextualHelp;
