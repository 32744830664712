import {log} from '@admin-tribe/acsc';
import {
  EditSecurityContactsButton,
  GoUrl,
  Page,
  PageActions,
  PageContent,
  PageDescription,
  PageHeader,
  PageNav,
  SecurityContactsSection,
} from '@admin-tribe/acsc-ui';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';

/**
 * Defines the security contacts page under the settings tab.
 * An observer is required since this component references an observable.
 */
const SecurityContactsPage = observer(() => {
  const isCurrentRef = useRef(true);

  const [isLoading, setIsLoading] = useState(false);

  const intl = useIntl();

  const onSuccess = async () => {
    setIsLoading(true);
    try {
      await rootStore.organizationStore.updateContactListSingleton();
    } catch (error) {
      log.error('Error updating the contact list: ', error);
    } finally {
      if (isCurrentRef.current) {
        setIsLoading(false);
      }
    }
  };

  const isDisabled = () => isLoading || !rootStore.organizationStore.contactList;

  // Track if component is mounted
  useEffect(() => {
    isCurrentRef.current = true;

    return () => {
      isCurrentRef.current = false;
    };
  }, []);

  return (
    <Page isLoading={isLoading}>
      <PageHeader
        title={intl.formatMessage({
          id: 'settings.securityContacts.title',
        })}
      />
      <PageDescription>
        {intl.formatMessage(
          {
            id: 'settings.securityContacts.description',
          },
          {
            goUrl: (str) => <GoUrl name="ac_security_contacts">{str}</GoUrl>,
          }
        )}
      </PageDescription>
      <PageActions>
        <EditSecurityContactsButton
          key="contacts-button"
          contactList={rootStore.organizationStore.contactList}
          isDisabled={isDisabled()}
          onSuccess={onSuccess}
        />
      </PageActions>
      <PageNav />
      {!isDisabled() && (
        <PageContent>
          <SecurityContactsSection contactList={rootStore.organizationStore.contactList} />
        </PageContent>
      )}
    </Page>
  );
});

export default SecurityContactsPage;
