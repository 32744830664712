import {
  LicenseGroup,
  download,
  feature,
  jilOrganizationsProductsLicenseGroups,
  log,
} from '@admin-tribe/acsc';
import {showError, showInfo} from '@admin-tribe/acsc-ui';
import {Button} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';
import {
  canCreateDeviceReports,
  canRecoverLicenses,
} from 'core/products/access/productProfileAccess';
import {useProductProfileDetailsContext} from 'features/products/pages/product-profile-details-page/context/ProductProfileDetailsContext';
import {canEditProfile} from 'features/products/product-profiles/utils/productProfileUtils';

import RecoverLicensesButton from './RecoverLicensesButton';

/**
 * A wrapper of Product Profile Details Page Header buttons
 */
const ProductProfileDetailsPageActions = ({
  autoAssignModalEnabled,
  onRecoverLicenses,
  licenseGroup,
  onDetailsButtonPress,
}) => {
  const intl = useIntl();
  const {consumableSummarizationList, openAutoAssignmentRuleModal, product, licenseGroupId} =
    useProductProfileDetailsContext();

  const recoverLicenses =
    consumableSummarizationList &&
    canRecoverLicenses(consumableSummarizationList, licenseGroupId, product);

  const createDeviceReports = canCreateDeviceReports(product);

  const createReport = useCallback(async () => {
    showInfo(intl.formatMessage({id: 'common.toast.csvDownloadBeingPrepared'}));

    try {
      const response = await jilOrganizationsProductsLicenseGroups.exportLicenseGroupDevices({
        licenseGroupId,
        orgId: rootStore.organizationStore.activeOrgId,
        productId: product.id,
      });
      download(response.data.file, 'devices.csv');
    } catch (error) {
      log.error(error);
      showError(intl.formatMessage({id: 'common.toast.downloadError'}));
    }
  }, [intl, licenseGroupId, product.id]);

  return (
    <>
      {canEditProfile(licenseGroup, product) && (
        <Button data-testid="details-button" onPress={onDetailsButtonPress} variant="secondary">
          {intl.formatMessage({
            id: 'products.productProfileDetails.detailsButton.label',
          })}
        </Button>
      )}
      {feature.isDisabled('temp_enable_react_sdl') && createDeviceReports && (
        <Button data-testid="create-report-button" onPress={createReport} variant="secondary">
          {intl.formatMessage({
            id: 'products.productProfileDetails.deviceReport.createReportButton',
          })}
        </Button>
      )}
      {feature.isDisabled('temp_enable_react_sdl') && recoverLicenses && (
        <RecoverLicensesButton
          canRecoverLicenses={recoverLicenses}
          licenseGroupId={licenseGroupId}
          onRecoverLicenses={onRecoverLicenses}
          productId={product.id}
        />
      )}
      {autoAssignModalEnabled && (
        <Button
          data-testid="auto-assign-button"
          onPress={openAutoAssignmentRuleModal}
          variant="secondary"
        >
          {intl.formatMessage({
            id: 'products.productProfileDetails.autoAssignment.changeAutoAssignmentButton',
          })}
        </Button>
      )}
    </>
  );
};

ProductProfileDetailsPageActions.propTypes = {
  /**
   * Whether the auto assignment rule is enabled or not
   */
  autoAssignModalEnabled: PropTypes.bool,
  /** The licenseGroup model */
  licenseGroup: PropTypes.instanceOf(LicenseGroup).isRequired,
  /** The callback for when the details button is pressed */
  onDetailsButtonPress: PropTypes.func,
  /** The callback for when the recover licenses button is pressed */
  onRecoverLicenses: PropTypes.func.isRequired,
};

export default ProductProfileDetailsPageActions;
