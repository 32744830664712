import {feature} from '@admin-tribe/acsc';
import {ContractExpirationStatus, NavigationAnchor, TableSectionTable} from '@admin-tribe/acsc-ui';
import {Flex} from '@adobe/react-spectrum';
import {useContentEntry} from '@pandora/react-content-provider';
import {ProductNameCell} from '@pandora/react-product-name-cell';
import {ProductUsage, ProductUsageContentModel} from '@pandora/react-product-usage';
import {useTableSectionContext} from '@pandora/react-table-section';
import {Item, TagGroup} from '@react-spectrum/tag';
import PropTypes from 'prop-types';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {v4 as uuid4} from 'uuid';

import StockConsumedQuantityTooltip from 'common/components/stock-consumed-quantity-tooltip/StockConsumedQuantityTooltip';
import {getProductContractNames} from 'common/services/product-summary-list/productSummaryListUtils';
import {canViewFireflyApiUsageCount} from 'core/products/access/productAccess';
import trialHelper from 'core/products/trial-helper/trialHelper';
import {canClickItem} from 'features/overview/components/products-summary-section/productsSummarySectionUtils';
import DevConsoleUsageContextualHelp from 'features/products/components/product-table/DevConsoleUsageContextualHelp';
import ProductTableActionButtonGroup from 'features/products/components/product-table-action-button-group/ProductTableActionButtonGroup';
import {
  getHrefForProductOrProductGroup,
  goToProductOrProductGroupDetails,
} from 'features/products/routing/navigation-callbacks/navigationCallbacks';
import TrialOfferBadge from 'features/trial-offer-badge/TrialOfferBadge';

// Product table to display on the new product overview page.
const ProductTable = ({ariaLabel}) => {
  const productUsageContent = useContentEntry(ProductUsageContentModel);
  const intl = useIntl();
  const {tableSectionState} = useTableSectionContext();
  const showTrial = feature.isEnabled('trial_with_payment');
  const showDevConsoleUsageContextualHelp = (item) =>
    feature.isEnabled('temp_show_firefly_devconsole') &&
    canViewFireflyApiUsageCount(item.product.model);

  const renderLinkCallback = (linkText, item) => (
    <NavigationAnchor href={getHrefForProductOrProductGroup(item.product.model)}>
      {linkText}
    </NavigationAnchor>
  );

  const getProductTableAction = ({item}) =>
    item.actions.length > 0 ? (
      <ProductTableActionButtonGroup actions={item.actions} model={item.product.model} />
    ) : undefined;

  const getProductNameCell = ({item}) => (
    <ProductNameCell
      iconSize="L"
      iconSrc={item.product.icon}
      productName={item.product.name}
      renderLink={
        canClickItem(item.product.model)
          ? (productName) => renderLinkCallback(productName, item)
          : undefined
      }
      showTooltip={!!feature.isEnabled('temp_add_contract_display_name')}
      subTextLinkSuffix={intl.formatMessage({
        id: `products.allProducts.table.contractNamesList.subTextLinkSuffix`,
      })}
      subTextList={getProductContractNames(item.contracts)}
    />
  );

  const getStatus = ({item}) =>
    item.status.shouldShowStatusContent ? (
      <ContractExpirationStatus
        contractStatus={item.status.contractStatus}
        expirationPhase={item.status.expirationPhase}
        isContractEtlaOrAllocation
      />
    ) : undefined;

  const renderTrialBadgesTableCell = useCallback(
    (item) => {
      const {activeTrial, daysInBadge} = trialHelper.getTrialWithPaymentInfo(item);

      return (
        showTrial &&
        activeTrial &&
        daysInBadge > 0 && <TrialOfferBadge className="" days={daysInBadge} />
      );
    },
    [showTrial]
  );

  const getTags = ({item}) =>
    item.tags.length > 0 ? (
      <TagGroup
        allowsRemoving={false}
        aria-label={intl.formatMessage(
          {id: 'products.allProducts.body.tags.ariaLabel'},
          {productName: item.product.name}
        )}
      >
        {item.tags.map((tag) => (
          <Item key={uuid4()}>{intl.formatMessage({id: tag.label})}</Item>
        ))}
      </TagGroup>
    ) : (
      // When temp_trial_compliance_symptoms is turned on, this tag will never render because there will be a standard trial tag added to item.tags.
      // Remove with temp_trial_compliance_symptoms because there will be a standard trial tag added to item.tags.
      showTrial && renderTrialBadgesTableCell(item.product)
    );

  const getUsageQuantity = ({item}) => {
    const firstScore = item.product.quantity.scores?.[0];
    if (firstScore) {
      return (
        <Flex alignItems="center" gap="size-100" justifyContent="end">
          <ProductUsage
            content={productUsageContent}
            totalQuantity={firstScore.total}
            unitName={firstScore.unit}
            usedQuantity={firstScore.used}
          />
          {firstScore.consumed !== undefined && (
            <StockConsumedQuantityTooltip
              consumed={firstScore.consumed}
              unit={firstScore.consumedUnit}
            />
          )}
        </Flex>
      );
    }
    return undefined;
  };

  const getInfo = ({item}) =>
    showDevConsoleUsageContextualHelp(item) ? <DevConsoleUsageContextualHelp /> : undefined;

  const columnDescriptor = [
    {
      key: 'name',
      props: {width: '30%'},
    },
    {
      allowMissingValue: true,
      key: 'action',
      props: {align: 'end', hideHeader: true, showDivider: true, width: '20%'},
    },
    {
      key: 'quantity',
      props: {align: 'end', width: '15%'},
    },
    {
      allowMissingValue: true,
      key: 'info',
      props: {align: 'start', hideHeader: true, width: '1%'},
    },
    {
      key: 'tags',
      props: {width: '19%'},
    },
    {
      key: 'status',
      props: {align: 'start', width: '15%'},
    },
  ];

  const renderers = {
    action: getProductTableAction,
    info: getInfo,
    name: getProductNameCell,
    quantity: getUsageQuantity,
    status: getStatus,
    tags: getTags,
  };

  const onRowAction = (key) => {
    const targetItem = tableSectionState.items.find((item) => item.id === key);
    if (canClickItem(targetItem.product.model)) {
      goToProductOrProductGroupDetails({
        productOrProductGroup: targetItem.product.model,
      });
    }
  };

  return (
    <TableSectionTable
      aria-label={ariaLabel}
      columnDescriptor={columnDescriptor}
      columnNamespace="products.allProducts.table.column"
      density="spacious"
      onAction={onRowAction}
      renderers={renderers}
    />
  );
};
ProductTable.displayName = 'ProductTable';

ProductTable.propTypes = {
  /** Aria label to apply to the table. */
  ariaLabel: PropTypes.string.isRequired,
};

export default ProductTable;
