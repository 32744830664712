/* eslint-disable max-lines -- this file requires more lines */
import {
  CONSUMABLE_SUMMARIZATION_SUMMARIZE_BY,
  ConsumableSummarizationList,
  MEMBER_TECH_ACCOUNT_DOMAIN,
  PRODUCT_DELEGATION_TARGET,
  PRODUCT_GROUP_PARAMETERS_SCORECARD_TYPE,
  Product,
  StockQuantitiesList,
  feature,
} from '@admin-tribe/acsc';
import {
  DEVICE_ACTIVATION_STATUS,
  getDevicesFetch,
  getTotalDevicesByStatus,
} from '@pandora/react-data-source-device-activation';
import {UNIT_LABELS, convertRawUnitsToUnitLabels} from '@pandora/react-product-usage';

import ProductUsage from 'common/entities/product-usage/ProductUsage';
import StockProductUsage from 'common/entities/product-usage/StockProductUsage';
import {generateZeroedQuotas, initializeStockScorecard} from 'core/products/utils/stockUsageUtils';
import ProductUserList from 'core/services/product/ProductUserList';
import FiProductGroupProductList from 'core/services/product/product-group/product-list/FiProductGroupProductList';
import FigProductGroupProductList from 'core/services/product/product-group/product-list/FigProductGroupProductList';
import {
  getNonFRLOnlineUsedLicenses,
  isProductFRLOfflineOnly,
  isProductFRLOnlineOnly,
} from 'features/products/device-licenses/components/utils/deviceLicensesUtils';

/**
 * @description A function to retrieve the product usage data (scores) for a product or a productGroupProductList.
 * The scores for different product types are sorted in certain order so that the primary usage
 * is the first one followed by other usages.
 *
 * @param {Object} options - options to find the product usage data
 * @param {apiKey} options.apiKey - The apiKey for the consumer to pass in the API call
 * @param {Function} options.canShowConsumableQuotaTypeItems - This method determines if the product has consumable quota items to show
 * @param {Function} options.canViewCustomModelUsageCount - This method determines if the product has custom models to show
 * @param {Function} options.canViewFireflyApiUsageCount - This method determines if the user can view firefly api usage
 * @param {Function} options.canViewProductLicenseCount - This method determines if the admin can view this product's license count
 * @param {String} options.env - The environment
 * @param {fetch} options.fetch - The window fetch object
 * @param {Boolean} options.isOrgOrProductAdmin - The flag for org admin or product admin
 * @param {log} options.log - The log object
 * @param {orgId} options.orgId - The org id
 * @param {Product | ProductGroupProductList} options.productOrProductGroup - the product / product group product list
 * @param {token} options.token - The authentication access token
 * @returns {Object} - Object wrapping product usage data with the below fields:
 * @returns {Promise} Object.promise - promise for usage quantity that requires additional API calls.
 *            When the promise is resolved, it will return an array of product usages. This array can be used to
 *            replace the previous object.scores.
 * @returns {Array<ProductUsage>} Object.scores - either an empty array or array of product usages.
 *            The array of product usages are returned without waiting for object.promise. For example, some product
 *            licenses can be calculated without any additional API (outside of Products API).
 */
function getProductUsageQuantity({
  apiKey,
  canShowConsumableQuotaTypeItems,
  canViewCustomModelUsageCount,
  canViewFireflyApiUsageCount,
  canViewProductLicenseCount,
  env,
  fetch,
  isOrgOrProductAdmin,
  log,
  orgId,
  productOrProductGroup,
  token,
}) {
  // This array stores a mix of ProductUsage and Promise<ProductUsage>,
  // and is used to maintain the order of ProductUsage
  const scores = [];

  if (productOrProductGroup instanceof Product) {
    const productFulfillableItemList = productOrProductGroup.fulfillableItemList;

    // Legacy stock licenses
    setUpForLegacyStockProduct({isOrgOrProductAdmin, log, orgId, productOrProductGroup, scores});

    // Unlimited licenses
    setupForUnlimitedLicense(canShowConsumableQuotaTypeItems, productOrProductGroup, scores);

    // Custom models
    if (canViewCustomModelUsageCount(productOrProductGroup)) {
      scores.push(
        setupForOrgConsumableProduct({
          fiCode: 'firefly_cme_quota',
          log,
          orgId,
          product: productOrProductGroup,
        })
      );
    }

    if (canViewFireflyApiUsageCount(productOrProductGroup)) {
      scores.push(
        setupForOrgConsumableProduct({
          fiCode: 'firefly_api',
          log,
          orgId,
          product: productOrProductGroup,
        })
      );
    } else if (feature.isEnabled('temp_pa_6055_bug_35381')) {
      // Change in order as seatBased product usage is displayed before groupConsumables product usage.
      // Seat-based licenses
      setUpForSeatBasedProduct(canViewProductLicenseCount, productOrProductGroup, scores);

      // Group consumable licenses
      setUpForGroupConsumables({
        canShowConsumableQuotaTypeItems,
        log,
        orgId,
        productOrProductGroup,
        scores,
      });
    } else {
      // Group consumable licenses
      setUpForGroupConsumables({
        canShowConsumableQuotaTypeItems,
        log,
        orgId,
        productOrProductGroup,
        scores,
      });

      // Seat-based licenses
      setUpForSeatBasedProduct(canViewProductLicenseCount, productOrProductGroup, scores);
    }

    // Legacy device licenses
    setUpForLegacyDeviceLicense(productOrProductGroup, scores);

    // Feature-restricted licenses
    setUpForFeatureRestrictedLicense({
      apiKey,
      env,
      fetch,
      log,
      orgId,
      productFulfillableItemList,
      productOrProductGroup,
      scores,
      token,
    });

    // Api integration licenses
    setUpForApiIntegrations({log, orgId, productOrProductGroup, scores});
  }
  // Org consumable licenses
  else if (productOrProductGroup instanceof FiProductGroupProductList) {
    setUpForOrgConsumable({log, orgId, productOrProductGroup, scores});
  }

  // DX Product with instances (for instance Analytics) based licenses
  else if (productOrProductGroup instanceof FigProductGroupProductList) {
    setUpForDXInstances(productOrProductGroup, scores);
  }

  return {
    promise: processPromise(scores),
    // scores contains ProductUsage instance if the particular index doesn't have a Promise.
    // Replace Promise with null and let the caller waits for the "promise" above.
    scores: scores.map((score) => (score instanceof ProductUsage ? score : null)),
  };
}

///////////////////
// Internal helper methods
///////////////////

/**
 * @async
 * @description Method to fetch usage data for product consumables.
 *
 * @param {log} log - The log object
 * @param {String} orgId - The org id
 * @param {Product} product - The product whose usages will be checked
 * @returns {Promise} promise which will resolve with the Product Usage Data
 */
async function fetchConsumables(log, orgId, product) {
  try {
    const summarizationList = await ConsumableSummarizationList.get({
      include_usage: false,
      organization_id: orgId,
      summarize_by: CONSUMABLE_SUMMARIZATION_SUMMARIZE_BY.LICENSE_ID,
    });

    const consumables = summarizationList.getConsumablesForSummaryId(product.id, {
      summarize_by: CONSUMABLE_SUMMARIZATION_SUMMARIZE_BY.LICENSE_ID,
    });
    return mapConsumablesToUsages(consumables);
  } catch (error) {
    log.error(`fetchConsumables - orgId:${orgId} productId:${product.id}`, error);
    throw new Error('Failed to fetch usage data for Consumables product.');
  }
}

/**
 * @async
 * @description Method to fetch usage data for Org Consumables.
 *
 * @param {log} log - The log object
 * @param {FIProductGroupProductList} productGroup - the product group
 * @param {String} orgId - The org id
 * @returns {Promise} promise which will resolve with the Product Usage Data
 */
async function getConsumableSummarizationList(log, productGroup, orgId) {
  try {
    const summarizationList = await ConsumableSummarizationList.get({
      include_usage: false,
      organization_id: orgId,
      summarize_by: CONSUMABLE_SUMMARIZATION_SUMMARIZE_BY.ORGANIZATION_ID,
    });
    return getOrgConsumableScores(summarizationList, productGroup);
  } catch (error) {
    log.error(
      `getConsumableSummarizationList - orgId:${orgId} productId:${productGroup.id}`,
      error
    );
    throw new Error('Failed to fetch usage data for ProductGroup Consumables.');
  }
}

/**
 * @description Method to fetch usage data for Consumables.
 *
 * @param {Product} product - The product whose usages will be checked
 * @returns {Array<ProductUsage>} - returns an array of product usage data
 */
function getConsumableQuotaCount(product) {
  return product.fulfillableItemList
    .getConsumableQuotaTypeItems({
      includeDelegationTypePerson: false,
      requireDelegationConfigurable: false,
    })
    .map((quota) => {
      const count = quota.calculatedPaidOrPendingOrGracePastDueCount;
      return new ProductUsage({
        total: null,
        unit: convertRawUnitsToUnitLabels(quota.chargingModel.unit),
        used: count,
      });
    });
}

/**
 * @description Method to fetch usage data for Legacy Stock product.
 *
 * @param {Product} product - The product whose usages will be checked
 * @param {Array} data - Stock Dataset
 * @param {Boolean} isOrgOrProductAdmin - The flag for org admin or product admin
 * @returns {Array<ProductUsage>} - returns an array of product usage data
 */
function getEnterpriseStockScores(product, data, isOrgOrProductAdmin) {
  // If no data items, fallback to QUOTA cap from Stock FIs.
  const rawQuotas =
    data.items.length === 0 ? generateZeroedQuotas(product.fulfillableItemList.items) : data.items;

  const quotas = initializeStockScorecard({
    isOrgOrProductAdmin,
    quotas: rawQuotas,
    variant: 'overview',
  });
  return quotas.map((quota) =>
    feature.isEnabled('temp_ctir_17968')
      ? new StockProductUsage({
          consumed: quota.consumed,
          total: quota.total,
          unit: quota.type,
          used: quota.score,
        })
      : new ProductUsage({
          total: quota.total,
          unit: quota.type,
          used: quota.score,
        })
  );
}

/**
 * @description Method to fetch usage data for the productGroup which has a scorecard
 *
 * @param {ProductGroupProductList} productGroup - The product whose usages will be checked
 * @returns {ProductUsage} - product usage data.
 */
function getInstanceCount(productGroup) {
  const score = productGroup.items.length;
  return new ProductUsage({total: null, unit: UNIT_LABELS.INSTANCES, used: score});
}

/**
 * @async
 * @description Method to fetch usage data for Integrations product.
 *
 * @param {log} log - The log object
 * @param {String} orgId - The org id
 * @param {Product} product - The product whose usages will be checked
 * @returns {Promise} promise which will resolve with the Product Usage Data
 */
async function getIntegrationsForProduct(log, orgId, product) {
  try {
    const userList = await ProductUserList.get({
      filterQuery: MEMBER_TECH_ACCOUNT_DOMAIN,
      orgId,
      pageSize: undefined,
      productId: product.id,
    });
    return new ProductUsage({
      total: null,
      unit: UNIT_LABELS.INTEGRATIONS,
      used: userList.getTotalItemCount(),
    });
  } catch (error) {
    log.error(`getIntegrationsForProduct - orgId:${orgId} productId:${product.id}`, error);
    throw new Error('Failed to fetch usage data for Integrations product.');
  }
}

/**
 * @async
 * @description Method to fetch usage data for FRL product.
 *
 * @param {apiKey} options.apiKey - The apiKey for the consumer to pass in the API call
 * @param {String} options.env - The environment
 * @param {fetch} options.fetch - The window fetch object
 * @param {log} options.log - The log object
 * @param {orgId} options.orgId - The org id
 * @param {Product} options.productOrProductGroup - The product whose usages will be checked
 * @param {token} options.token - The authentication access token
 * @returns {Promise} promise which will resolve with the Product Usage Data
 */
async function getFeatureRestrictedCount({
  apiKey,
  env,
  fetch,
  log,
  orgId,
  productOrProductGroup,
  token,
}) {
  try {
    let additionalUsedLicensesCount;
    const productFulfillableItemList = productOrProductGroup.fulfillableItemList;

    if (isProductFRLOnlineOnly(productFulfillableItemList)) {
      additionalUsedLicensesCount = 0;
    } else {
      // multi-flavors FRL product
      additionalUsedLicensesCount = getNonFRLOnlineUsedLicenses(productOrProductGroup);
    }

    // since this call is specific for FRL_ONLINE, licensing mode is not passed explicitly(CAL defaults to FRL_ONLINE)
    const devicesApiOptions = {
      activationState: DEVICE_ACTIVATION_STATUS.ACTIVATED,
      licenseId: productOrProductGroup.id,
      orgId,
      ...(feature.isEnabled('temp_sdl_frl_deactivations') && {includeStatusAndMessage: true}),
    };
    const devicesFetch = getDevicesFetch({
      accessToken: token,
      apiKey,
      env,
      fetch,
    });

    const totalDevicesByStatusData = await getTotalDevicesByStatus(devicesFetch, devicesApiOptions);
    const totalDevicesByStatusJsonData = await totalDevicesByStatusData.json();
    return new ProductUsage({
      total: productOrProductGroup.getAssignableLicenseCount(),
      unit: UNIT_LABELS.DEVICES, // Changed to make it consistent with SDL devices
      used: totalDevicesByStatusJsonData.data.search.total + additionalUsedLicensesCount,
    });
  } catch (error) {
    log.error(
      `getFeatureRestrictedCount - orgId:${orgId} productId:${productOrProductGroup.id}`,
      error
    );
    throw new Error('Failed to fetch usage data for FRL product.');
  }
}

/**
 * @description Method to find product usage for FRL LAN, Isolated product.
 *
 * @param {Product} product - The product whose usages will be checked
 * @returns {ProductUsage} - product usage data
 */
function getFRLLanOrIsolated(product) {
  return new ProductUsage({
    total: product.getAssignableLicenseCount(),
    unit: UNIT_LABELS.DEVICES, // Changed to make it consistent with SDL devices
    used: getNonFRLOnlineUsedLicenses(product),
  });
}

/**
 * @description Method to find product usage for FRL Offline product.
 *
 * @param {Product} product - The product whose usages will be checked
 * @returns {ProductUsage} - product usage data
 */
function getFRLOfflineCount(product) {
  return new ProductUsage({
    total: product.getAssignableLicenseCount(),
    unit: UNIT_LABELS.DEVICES, // Changed to make it consistent with SDL devices
    used: null,
  });
}

/**
 * @description Method to fetch usage data for the product which has legacy devices
 *
 * @param {Product} product - The product whose usages will be checked
 * @returns {ProductUsage} - product usage data.
 */
function getLegacyDeviceBasedCount(product) {
  const used = product.getAssignableLicenseCount();
  return new ProductUsage({total: null, unit: UNIT_LABELS.DEVICE_LICENSES, used});
}

/**
 * @description Method to fetch usage data for Consumables.
 *
 * @param {ConsumableSummarizationList}  summarizationList - the Consumable SummarizationList.
 * @param {FIProductGroupProductList} productGroup - the product group.
 * @returns {Array<ProductUsage>} - returns an array of product usage data.
 */
function getOrgConsumableScores(summarizationList, productGroup) {
  const consumables = summarizationList.getConsumablesForFulfillableItemCode(productGroup.id);

  // This should not happen but it does.
  if (consumables.length === 0) {
    throw new Error('No consumables found');
  }

  return mapConsumablesToUsages(consumables);
}

/**
 * @async
 * @description Method to fetch usage data for Legacy Stock product.
 *
 * @param {Boolean} options.isOrgOrProductAdmin - The flag for org admin or product admin
 * @param {log} options.log - The log object
 * @param {orgId} options.orgId - The org id
 * @param {Product} options.productOrProductGroup - The product whose usages will be checked
 * @returns {Promise} promise which will resolve with the Product Usage Data
 */
async function getStockQuantitiesList({isOrgOrProductAdmin, log, orgId, productOrProductGroup}) {
  try {
    const data = await StockQuantitiesList.get(orgId);
    return getEnterpriseStockScores(productOrProductGroup, data, isOrgOrProductAdmin);
  } catch (error) {
    log.error(
      `getStockQuantitiesList - orgId:${orgId} productId:${productOrProductGroup.id}`,
      error
    );
    throw new Error('Failed to fetch usage data for Enterprise Stock product.');
  }
}

/**
 * @description Method to fetch usage data for the product which has unlimited licenses
 *
 * @param {Product} product - The product whose usages will be checked
 * @returns {ProductUsage} - product usage data
 */
function getUnlimitedLicenseCount(product) {
  const used = product.provisionedQuantity;
  return new ProductUsage({total: null, unit: UNIT_LABELS.USERS, used});
}

/**
 * @description Method to fetch usage data for the product which has a user based usage
 *
 * @param {Product} product - The product whose usages will be checked
 * @returns {ProductUsage} - product usage data.
 */
function getUserBasedCount(product) {
  const used = product.provisionedQuantity;
  const total = product.getAssignableLicenseCount();
  return new ProductUsage({total, unit: UNIT_LABELS.LICENSES, used});
}

/**
 * @description Method to map usage data for Consumables.
 *
 * @param {Array<Consumable>} consumables - the list of consumable objects
 * @returns {Array<ProductUsage>} - returns an array of product usage data
 */
function mapConsumablesToUsages(consumables) {
  return consumables.map(
    (consumable) =>
      new ProductUsage({
        total: consumable.totalQuantity,
        unit: convertRawUnitsToUnitLabels(consumable.unit),
        used: consumable.consumedQuantity,
      })
  );
}

/**
 * @async
 * @description Method to process the scores array which is a mix of ProductUsage and Promise<ProductUsage> and
 * return the final scores after all the promised are resolved/rejected.
 *
 * @param {Array<ProductUsage | Promise<ProductUsage>>} scores - List of ProductUsage and Promise<ProductUsage>.
 * @returns {Array<ProductUsage>} - The array of product usages are returned with merging from immediately available
 * and promised available data. For the promises which are rejected, null value is used.
 */
async function processPromise(scores) {
  // Put all Promises and ProductUsage through the Promise.allSettled.
  // If an entry is not a Promise, then it'll be resolved immediately
  let results = await Promise.allSettled(scores);

  results = results.map((productUsageOrPromise) => {
    if (productUsageOrPromise.value instanceof ProductUsage) {
      return productUsageOrPromise.value;
    }
    // This is a promise and for promise rejected should set to null
    return productUsageOrPromise.status === 'fulfilled' ? productUsageOrPromise.value : null;
  });

  // Doing the flattening logic as some promise (API calls) return an array of scores.
  return results.flatMap((score) => score);
}

/**
 * @description Method to fetch usage data for api integrations of a product.
 *
 * @param {Object} options - options to find the product usage data
 * @param {log} options.log - The log object
 * @param {String} options.orgId - The org id
 * @param {Product} options.productOrProductGroup - The product whose usages will be checked
 * @param {Array<ProductUsage | Promise<ProductUsage>>} options.scores - The list of product usages
 */
function setUpForApiIntegrations({log, orgId, productOrProductGroup, scores}) {
  if (
    shouldShowBasedOnGroupParamsScorecard(productOrProductGroup) &&
    productOrProductGroup.isDelegatableToType(PRODUCT_DELEGATION_TARGET.API_KEY)
  ) {
    scores.push(getIntegrationsForProduct(log, orgId, productOrProductGroup));
  }
}

/**
 * @description Method to fetch usage data for DX product with instances.
 *
 * @param {ProductGroupProductList} productGroup - The productGroup whose usages will be checked
 * @param {Array<ProductUsage | Promise<ProductUsage>>} scores - The list of product usages
 */
function setUpForDXInstances(productGroup, scores) {
  if (
    productGroup.hasInstancesScorecard() ||
    (productGroup.items.length > 0 && !productGroup.isConsumable)
  ) {
    scores.push(getInstanceCount(productGroup));
  }
}

/**
 * @description Method to fetch usage data for FRL product.
 *
 * @param {Object} options - options to find the product usage data
 * @param {apiKey} options.apiKey - The apiKey for the consumer to pass in the API call
 * @param {String} options.env - The environment
 * @param {fetch} options.fetch - The window fetch object
 * @param {log} options.log - The log object
 * @param {orgId} options.orgId - The org id
 * @param {Product} options.productOrProductGroup - The product whose usages will be checked
 * @param {FulfillableItemList} options.productFulfillableItemList - The list of fulfillable items
 * @param {Array<ProductUsage | Promise<ProductUsage>>} options.scores - The list of product usages
 * @param {token} options.token - The authentication access token
 */
function setUpForFeatureRestrictedLicense({
  apiKey,
  env,
  fetch,
  log,
  orgId,
  productFulfillableItemList,
  productOrProductGroup,
  scores,
  token,
}) {
  // Feature-restricted licenses
  if (productOrProductGroup.isFeatureRestrictedLicense()) {
    if (isProductFRLOfflineOnly(productFulfillableItemList)) {
      // FRL Offline only
      scores.push(getFRLOfflineCount(productOrProductGroup));
    } else if (productFulfillableItemList.hasPackagePreconditioningForFRLOnline()) {
      // multi-flavors FRL product or FRL online
      scores.push(
        getFeatureRestrictedCount({apiKey, env, fetch, log, orgId, productOrProductGroup, token})
      );
    } else {
      // Either it's only FRL LAN or Isolated or both
      scores.push(getFRLLanOrIsolated(productOrProductGroup));
    }
  }
}

/**
 * @description Method to fetch usage data for Group Consumables and SDL product.
 *
 * @param {Object} options - The params
 * @param {Function} options.canShowConsumableQuotaTypeItems - This method determines if the product has consumable quota items to show
 * @param {log} options.log - The log object
 * @param {String} options.orgId - The org id
 * @param {Product} options.productOrProductGroup - The product whose usages will be checked
 * @param {Array<ProductUsage | Promise<ProductUsage>>} options.scores - The list of product usages
 */
function setUpForGroupConsumables({
  canShowConsumableQuotaTypeItems,
  log,
  orgId,
  productOrProductGroup,
  scores,
}) {
  if (productOrProductGroup.isGroupConsumable()) {
    scores.push(fetchConsumables(log, orgId, productOrProductGroup));
  } else if (
    shouldShowBasedOnGroupParamsScorecard(productOrProductGroup) &&
    canShowConsumableQuotaTypeItems(productOrProductGroup)
  ) {
    scores.push(...getConsumableQuotaCount(productOrProductGroup));
  }
}

/**
 * @description Method to fetch usage data for Legacy Devices.
 *
 * @param {Product} product - The product whose usages will be checked
 * @param {Array<ProductUsage | Promise<ProductUsage>>} scores - The list of product usages
 */
function setUpForLegacyDeviceLicense(product, scores) {
  if (product.isLegacyDeviceLicense()) {
    scores.push(getLegacyDeviceBasedCount(product));
  }
}

/**
 * @description Method to fetch usage data for Legacy Stock product.
 *
 * @param {Object} options - The params
 * @param {Boolean} options.isOrgOrProductAdmin - The flag for org admin or product admin
 * @param {log} options.log - The log object
 * @param {orgId} options.orgId - The org id
 * @param {Product} options.productOrProductGroup - The product whose usages will be checked
 * @param {Array<ProductUsage | Promise<ProductUsage>>} options.scores - The list of product usages
 */
function setUpForLegacyStockProduct({
  isOrgOrProductAdmin,
  log,
  orgId,
  productOrProductGroup,
  scores,
}) {
  if (productOrProductGroup.isLegacyStock()) {
    scores.push(getStockQuantitiesList({isOrgOrProductAdmin, log, orgId, productOrProductGroup}));
  }
}

/**
 * @description Method to fetch usage data for Org consumables product.
 *
 * @param {Object} options - options to find the product usage data
 * @param {log} options.log - The log object
 * @param {String} options.orgId - The org id
 * @param {Product} options.productOrProductGroup - The product whose usages will be checked
 * @param {Array<ProductUsage | Promise<ProductUsage>>} options.scores - The list of product usages
 */
function setUpForOrgConsumable({log, orgId, productOrProductGroup, scores}) {
  if (productOrProductGroup.isConsumable) {
    scores.push(getConsumableSummarizationList(log, productOrProductGroup, orgId));
  }
}

/**
 * @description Method to fetch usage data for Seat based product.
 *
 * @param {Function} options.canViewProductLicenseCount - This method determines if the admin can view this product's license count
 * @param {Product} product - The product whose usages will be checked
 * @param {Array<ProductUsage | Promise<ProductUsage>>} scores - The list of product usages
 */
function setUpForSeatBasedProduct(canViewProductLicenseCount, product, scores) {
  if (product.isAdministerable() && canViewProductLicenseCount(product)) {
    scores.push(getUserBasedCount(product));
  }
}

/**
 * @description Method to fetch usage data for unlimited license product.
 *
 * @param {Function} options.canShowConsumableQuotaTypeItems - This method determines if the product has consumable quota items to show
 * @param {Product} product - The product whose usages will be checked
 * @param {Array<ProductUsage | Promise<ProductUsage>>} scores - The list of product usages
 */
function setupForUnlimitedLicense(canShowConsumableQuotaTypeItems, product, scores) {
  if (
    product.isAdministerable() &&
    (shouldShowBasedOnUsersScorecard(product) ||
      (product.hasUnlimitedLicenses() &&
        !shouldIgnoreUnlimitedLicenses(canShowConsumableQuotaTypeItems, product)))
  ) {
    scores.push(getUnlimitedLicenseCount(product));
  }
}

/**
 * @description Method to find if the unlimited licenses product should be ignored.
 *
 * @param {Function} canShowConsumableQuotaTypeItems - This method determines if the product has consumable quota items to show
 * @param {Product} product - The product whose usages will be checked
 * @returns {Boolean} - True/False
 */
function shouldIgnoreUnlimitedLicenses(canShowConsumableQuotaTypeItems, product) {
  return (
    product.isLegacyStock() ||
    canShowConsumableQuotaTypeItems(product) ||
    product.isFeatureRestrictedLicense()
  );
}

/**
 * @description Method to find if Product has scorecard defined, only scores in PRODUCT_GROUP_PARAMETERS_SCORECARD_TYPE
 *
 * @param {Product} product - The product whose usages will be checked
 * @returns {Boolean} - True/False
 */
function shouldShowBasedOnGroupParamsScorecard(product) {
  return !product.isScorecardsDefined();
}

/**
 * @description Method to find if the unlimited licenses product should show based on users scorecard.
 *
 * @param {Product} product - The product whose usages will be checked
 * @returns {Boolean} - True/False
 */
function shouldShowBasedOnUsersScorecard(product) {
  return product.hasScorecardType(PRODUCT_GROUP_PARAMETERS_SCORECARD_TYPE.USERS);
}

/**
 * Returns a promise containing an array of ProductUsage objects for the consumables of a product.
 *
 * @param orgId The organization ID
 * @param fiCode The fulfillable item code of the product
 * @param product The product object with its fulfillable item list
 * @param log A logger object to record errors to
 * @returns {Promise<Array<ProductUsage>>}
 */
async function setupForOrgConsumableProduct({orgId, fiCode, product, log}) {
  try {
    const summarizationList = await ConsumableSummarizationList.get({
      include_usage: false,
      organization_id: orgId,
      summarize_by: CONSUMABLE_SUMMARIZATION_SUMMARIZE_BY.ORGANIZATION_ID,
    });
    const consumables = summarizationList.getConsumablesForFulfillableItemCode(fiCode);
    if (consumables.length === 0) {
      throw new Error('No consumables found');
    }

    return mapConsumablesToUsages(consumables);
  } catch (error) {
    log.error(`getConsumableSummarizationList - orgId:${orgId} productId:${product.id}`, error);
    throw new Error('Failed to fetch usage data for Product Consumables.');
  }
}

export {getProductUsageQuantity, setupForOrgConsumableProduct};
/* eslint-enable max-lines -- this file requires more lines */
