import {Contract, feature} from '@admin-tribe/acsc';
import {InfoBar, InfoItem, getContractExpirationStatusTextFromContract} from '@admin-tribe/acsc-ui';
import {ActionButton, Tooltip, TooltipTrigger, View} from '@adobe/react-spectrum';
import EditIcon from '@spectrum-icons/workflow/Edit';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import {isAuthUserContractAdmin} from 'core/contract/access/contractAccess';
import {isOrgAdmin} from 'core/organizations/access/organizationAccess';

import styles from './ContractInfoBar.pcss';
import EditContractNameWrapper from './EditContractNameWrapper/EditContractNameWrapper';

/**
 * @description Display contract information in InfoBar format
 *
 * @param {Contract} contract InfoBar displays this Contract data
 */
const ContractInfoBar = ({contract}) => {
  // To check whether logged in user is sysadmin or contract owner
  const isContractOwner = isOrgAdmin() || contract.isContractOwner({checkAuthUser: true});

  const canEditContractName = feature.isEnabled('temp_contract_admin_pa_4759')
    ? isContractOwner || isAuthUserContractAdmin(contract.id)
    : isContractOwner;

  const intl = useIntl();
  const [showEditContractName, setShowEditContractName] = useState(false);

  const contractDisplayNameLabel = intl.formatMessage({
    id: 'account.contractInfoBar.contractDisplayNameLabel',
  });
  const contractDisplayNameEditAriaLabel = intl.formatMessage({
    id: 'account.contractInfoBar.contractDisplayNameEdit.ariaLabel',
  });
  const contractDisplayNameEditTooltip = intl.formatMessage({
    id: 'account.contractInfoBar.contractDisplayNameEditTooltip',
  });
  const contractIdLabel = intl.formatMessage({id: 'account.contractInfoBar.contractIdLabel'});
  const statusLabel = intl.formatMessage({id: 'account.contractInfoBar.statusLabel'});

  const handleEditContractNameClose = () => {
    setShowEditContractName(false);
  };

  return (
    <div
      className={
        feature.isEnabled('temp_pa6066_edit_contract_name') ? styles['contract-info-bar'] : null
      }
    >
      <div className={styles['contract-info']}>
        <InfoBar>
          <InfoItem label={contractIdLabel} value={contract.id} />
          <InfoItem
            label={statusLabel}
            value={getContractExpirationStatusTextFromContract({contract, intl})}
          />
          <InfoItem label={contractDisplayNameLabel} value={contract.getDisplayName()} />
        </InfoBar>
      </div>
      {canEditContractName && (
        <div className={styles['contract-edit-icon']}>
          <InfoBar>
            {feature.isEnabled('temp_pa6066_edit_contract_name') ? (
              <View data-testid="edit-contract-name-button" marginTop="16px">
                <InfoItem
                  // eslint-disable-next-line @admin-tribe/admin-tribe/extract-large-computations -- needed more lines
                  icon={
                    <TooltipTrigger>
                      <ActionButton
                        aria-label={contractDisplayNameEditAriaLabel}
                        isQuiet
                        onPress={() => setShowEditContractName(true)}
                      >
                        <EditIcon size="S" />
                      </ActionButton>
                      <Tooltip>{contractDisplayNameEditTooltip}</Tooltip>
                    </TooltipTrigger>
                  }
                />
              </View>
            ) : null}
          </InfoBar>
        </div>
      )}
      <EditContractNameWrapper
        contract={contract}
        isOpen={showEditContractName}
        onClose={handleEditContractNameClose}
      />
    </div>
  );
};

ContractInfoBar.propTypes = {
  /**
   * InfoBar displays this Contract data
   */
  contract: PropTypes.instanceOf(Contract).isRequired,
};

export default ContractInfoBar;
