import {AnalyticsEvent} from '@admin-tribe/acsc';
import {Button, Text} from '@adobe/react-spectrum';
import LinkOutIcon from '@spectrum-icons/workflow/LinkOut';
import PropTypes from 'prop-types';
import React from 'react';

import {formatLinkOutUrl} from 'common/utils/product-link-utils/productLinkUtils';

const GoToProductButton = ({goToProductLink}) => {
  const url = formatLinkOutUrl(goToProductLink.href);
  const showButton = url && !!goToProductLink?.text;

  const triggerAnalytics = () => {
    const attributes = {
      clickedLinkHref: goToProductLink.href,
      clickedLinkText: goToProductLink.text,
    };
    AnalyticsEvent.dispatch({
      attributes,
      componentMethod: 'goToLink',
      componentMethodType: 'click',
      componentName: 'appProductLink',
    });
  };

  return (
    showButton && (
      <Button
        data-testid="product-link-button"
        elementType="a"
        href={url}
        onPress={triggerAnalytics}
        rel="noopener noreferrer"
        target="_blank"
        variant="primary"
      >
        <LinkOutIcon />
        <Text>{goToProductLink.text}</Text>
      </Button>
    )
  );
};

GoToProductButton.propTypes = {
  /**
   *  Go to product link.
   */
  goToProductLink: PropTypes.shape({
    /**
     * URL of the link.
     */
    href: PropTypes.string.isRequired,
    /**
     * Text of the link.
     */
    text: PropTypes.string.isRequired,
  }).isRequired,
};

export default GoToProductButton;
