import {dispatchUiEventAnalytics, feature} from '@admin-tribe/acsc';
import {Button} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import {useMemberListContext} from 'common/hooks/member-list-context/MemberListContext';
import rootStore from 'core/RootStore';
import {canUseAddProductMiniAppForAll} from 'core/products/access/productAccess';
import chatProvider from 'core/services/chat/chatProvider';
import {ADD_PRODUCT_WORKFLOWS} from 'features/products/components/add-product-modal-wrapper/AddProduct.constants';
import AddProductModalWrapper from 'features/products/components/add-product-modal-wrapper/AddProductModalWrapper';

import {getOrgInfoWithSubscriptionStatusForAnalytics} from '../../../utils/analyticsUtils';

// The 'Add license' button, and the associated modal to add new licenses to
// the Product Users page.
const AddLicensesButton = observer(() => {
  const intl = useIntl();
  const {canAddLicense, store} = useMemberListContext();
  const product =
    store.productId &&
    rootStore.organizationStore.productList.items.find((p) => p.id === store.productId);

  const showButton = canUseAddProductMiniAppForAll() && canAddLicense && !!product;
  const [showAddProductModal, setShowAddProductModal] = useState(false);

  const cartItems = showButton
    ? [
        {
          offerId: product.offerId,
          quantity: 1,
        },
      ]
    : undefined;

  const onPress = () => {
    const newOrg = getOrgInfoWithSubscriptionStatusForAnalytics(product.offerId, product.offerType);
    dispatchUiEventAnalytics({
      eventAction: 'click',
      eventName: 'addLicenses',
      organizationInfo: newOrg,
    });

    setShowAddProductModal(true);
  };

  let variant = 'secondary';
  if (feature.isEnabled('temp_add_licenses_cta_blue')) {
    variant = 'accent';
  }

  return (
    <>
      {showButton && (
        <Button data-testid="add-license-button" onPress={onPress} variant={variant}>
          {intl.formatMessage({id: 'common.userListTableSection.button.addLicenses'})}
        </Button>
      )}
      {showAddProductModal && (
        <AddProductModalWrapper
          chat={chatProvider}
          items={cartItems}
          onClose={() => setShowAddProductModal(false)}
          workflow={ADD_PRODUCT_WORKFLOWS.ADD_LICENSE}
        />
      )}
    </>
  );
});

export default AddLicensesButton;
