import {
  HorizontalProductsList,
  ModalContent,
  ModalDescription,
  ModalHeading,
} from '@admin-tribe/acsc-ui';
import {Heading, View} from '@adobe/react-spectrum';
import React from 'react';
import {useIntl} from 'react-intl';

import ProductMigrationTable from 'features/products/product-migration-table/ProductMigrationTable';

import {TARGET_PRODUCT_INDEX} from './OfferSwitchMigrationConstants';
import {
  MIGRATION_DATA_ACTIONS,
  useOfferSwitchMigrationContext,
} from './context/OfferSwitchMigrationContext';

const OfferSwitchMigrationAssignProducts = () => {
  const {dispatchMigrationData, migrationData} = useOfferSwitchMigrationContext();
  const productGroups = migrationData.productGroups || [];
  const targetProducts = productGroups[TARGET_PRODUCT_INDEX]?.productList || [];

  const intl = useIntl();

  const getString = (suffix) =>
    intl.formatMessage(
      {id: `users.offerSwitchMigrationAssignProducts.${suffix}`},
      {
        productCount: targetProducts.length,
        productName: targetProducts[0]?.displayName,
      }
    );

  const onProductSelectionChange = ({migration, selectedId}) => {
    dispatchMigrationData({
      migration,
      selectedId,
      type: MIGRATION_DATA_ACTIONS.SET_TARGET_PRODUCT,
    });
  };

  const onSortChange = (sortDescriptor) => {
    dispatchMigrationData({
      sortDescriptor,
      type: MIGRATION_DATA_ACTIONS.SORT_MIGRATIONS,
    });
  };

  return (
    <>
      <ModalHeading>{getString('title')}</ModalHeading>
      <ModalDescription>
        <View>{getString('summary')}</View>
        <Heading level={4}>{getString('purchasedLicenses.title')}</Heading>
        <View marginStart="-16px" marginY="-8px">
          <HorizontalProductsList products={targetProducts} />
        </View>
      </ModalDescription>
      <ModalContent>
        <ProductMigrationTable
          eligibleMigrations={migrationData.eligibleMigrations}
          onProductSelectionChange={onProductSelectionChange}
          onSortChange={onSortChange}
          sortDescriptor={migrationData.sortDescriptor}
        />
      </ModalContent>
    </>
  );
};

export default OfferSwitchMigrationAssignProducts;
