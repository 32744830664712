import {Locale} from '@admin-tribe/acsc';
import get from 'lodash/get';

import {AEP_ANALYTICS_SITE_SECTION} from '../AnalyticsConstants';
import {setProperty} from '../aepAnalytics';

/**
 *
 * @param {*} launchContext
 * @param {*} analyticsPageDescriptor
 */
export default function analyticsPageEventTranslator(analyticsPageDescriptor, launchContext) {
  const {analyticsConfig} = launchContext;
  const pageName = get(analyticsPageDescriptor, 'name');
  const locale = Locale.get().activeLocaleCode.replace('_', '-');

  setProperty('page.autoTrack', false, false);
  setProperty('page.env', analyticsConfig.env, false);
  setProperty('page.language', locale, false);

  let host = '';
  // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- unit testing is not needed here as it is window object check
  /* istanbul ignore next -- unit testing is not needed here as it is window object check */
  if (typeof window !== 'undefined') {
    host = window.location.host;
  }
  setProperty('page.customPageName', `${host}:${pageName}`, false);
  launchContext.triggerStateChange(pageName);

  setProperty('page.siteSection', AEP_ANALYTICS_SITE_SECTION, false);
  setProperty('page.solution.name', 'aac', false);
}
