import {feature} from '@admin-tribe/acsc';
import {
  AlertDialog,
  Button,
  ButtonGroup,
  Content,
  Dialog,
  Divider,
  Heading,
  ProgressCircle,
  Text,
} from '@adobe/react-spectrum';
import {Device} from '@pandora/react-data-model-device';
import {DeviceAction, useDevicesManager} from '@pandora/react-data-source-daco';
import {DEVICE_LICENSING_MODES, useDevicesApi} from '@pandora/react-data-source-device-activation';
import {useDeviceDeactivations} from '@pandora/react-data-source-jil';
import {GoUrl} from '@pandora/react-go-url';
import {EN_DASH} from '@pandora/react-table-section';
import {
  error as showError,
  info as showInfo,
  success as showSuccess,
} from '@react/react-spectrum/Toast';
import {useId} from '@react-aria/utils';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';
import {useDeviceLicensesPageContext} from 'features/products/device-licenses/components/page-context/DeviceLicensesPageContext';

import {BUTTON_VARIANT, UPDATE_ACTIONS} from '../device-details-drawer/DeviceDetailsConstants';
import {useDeviceDetailsContext} from '../device-details-drawer/context/DeviceDetailsContext';
import {getIntlActionId} from '../device-details-drawer/utils/deviceDrawerUtils';
import {handleDeviceAction, pollDevicesApi} from '../utils/deviceLicensesUtils';

const NAMESPACE = 'products.deviceLicenses.deviceDetails';

/**
 * @description A function to make device details for API calls
 * @param {Device} device - device details
 * @returns {Object} result - Object composed of the following the details of a device used for API calls
 *          {Boolean} result.enableVdiMarkerExists - whether enabled VDI marker exists
 *          {String} result.osUserId - OS user ID
 *          {String} result.deviceId - device ID
 */
const makeDeviceDetail = (device) => {
  const {deviceId, osUserId, enableVdiMarkerExists} = device;
  const deviceDetail = {
    enableVdiMarkerExists,
  };
  // Logic of adding osUserId or deviceId depending on enableVdiMarkerExists
  if (enableVdiMarkerExists) {
    deviceDetail.osUserId = osUserId;
  } else {
    deviceDetail.deviceId = deviceId;
  }
  return deviceDetail;
};

const getCountToBeShownInConfirmationDialog = (
  licensingMode,
  usedLicensesCount,
  frlOnlineUsedLicensesCount
) => {
  if (
    feature.isEnabled('temp_sdl_frl_deactivations') &&
    licensingMode === DEVICE_LICENSING_MODES.FRL_ONLINE
  ) {
    // In case the product is FRL, only the frlOnlineDevices will be recovered and hence the frlOnlineUsedLicensesCount is shown
    return frlOnlineUsedLicensesCount;
  }
  return usedLicensesCount;
};

const getDeviceOperationOptions = (deviceDetailsList, action) => {
  const deviceOperationOptions = {
    deviceDetails: deviceDetailsList.map((device) => makeDeviceDetail(device)),
  };
  if (UPDATE_ACTIONS.includes(action)) {
    // Removal does not require action
    deviceOperationOptions.action = action;
  }
  return deviceOperationOptions;
};

const getContentKeyAndGoUrlName = (actionLowerCase, licensingMode, action) => {
  let contentKey = `${NAMESPACE}.confirmationDialog.${actionLowerCase}.content`;
  let goUrlName = 'manage_device_licenses';

  if (licensingMode === DEVICE_LICENSING_MODES.NAMED_USER_EDUCATION_LAB) {
    if (action === DeviceAction.RECOVER_ALL) {
      contentKey = `${NAMESPACE}.confirmationDialog.recover_all.content.sdl`;
      goUrlName = '';
    } else {
      goUrlName = 'sdl-online-manage-devices';
    }
  } else if (action === DeviceAction.RECOVER_ALL) {
    // licensing mode is FRL_ONLINE
    contentKey = `${NAMESPACE}.confirmationDialog.recover_all.content.frl`;
    goUrlName = 'recover-all-licenses-frl';
  }
  return {contentKey, goUrlName};
};

/**
 * A component to display a dialog to the user to confirm the action
 */
const ConfirmationDialog = ({
  action,
  close,
  deviceDetailsList,
  productId,
  licenseGroupId,
  licensingMode,
  onDeviceUpdated,
  onDialogUpdated,
}) => {
  const nameId = useId();
  const intl = useIntl();

  const {
    frlOnlineUsedLicensesCount,
    productId: productIdFromContextProvider,
    usedLicensesCount,
  } = useDeviceLicensesPageContext();
  const {
    onDeviceUpdated: onDeviceUpdateFromContextProvider,
    onDialogUpdated: onDialogUpdateFromContextProvider,
  } = useDeviceDetailsContext();
  const onDialogUpdateCallback = onDialogUpdated || onDialogUpdateFromContextProvider;
  let currentOptions = {
    licenseId: productId || productIdFromContextProvider,
    licensingMode,
    orgId: rootStore.organizationStore.activeOrgId,
  };
  if (licenseGroupId !== EN_DASH) {
    currentOptions = {...currentOptions, licenseConfigId: licenseGroupId};
  }
  if (feature.isEnabled('temp_sdl_frl_deactivations'))
    currentOptions = {...currentOptions, includeStatusAndMessage: true};

  const {updateDevicesStatus, deleteDevices} = useDevicesManager(currentOptions);
  const {deactivateOrganizationDevices} = useDeviceDeactivations(currentOptions);
  const deviceOperations = {
    [DeviceAction.ACTIVATE]: updateDevicesStatus,
    [DeviceAction.BLOCK]: updateDevicesStatus,
    [DeviceAction.REMOVE]: deleteDevices,
    [DeviceAction.RECOVER_ALL]: deactivateOrganizationDevices,
  };
  const [isConfirmButtonClicked, setIsConfirmButtonClicked] = React.useState(false);
  const [isAlertDialog, setAlertDialog] = React.useState(false);
  const [alertTitle, setAlertTitle] = React.useState('');
  const [alertMessage, setAlertMessage] = React.useState('');

  // Side effect to inform drawer that confirmation dialog is closed given the action is confirmed
  React.useEffect(
    () => () => {
      // This is intentionally different from XD as it was suggested that
      // the toast should only appear when the drawer is closed. Therefore, whenver the action
      // is confirmed by used, the confirmation dialog and drawer will be closed then the toast will
      // be displayed regardless whether it is Activation, Deactivation, or Removal/Deletion of device.
      if (isConfirmButtonClicked) {
        onDialogUpdateCallback?.();
      }
    },
    [action, isConfirmButtonClicked, onDialogUpdateCallback]
  );
  const actionLowerCase = action.toLowerCase();
  const showErrorAlertDialog = (title, message) => {
    setAlertTitle(title);
    setAlertMessage(message);
    setAlertDialog(true);
  };

  const intlActionId = getIntlActionId({action, namespace: `${NAMESPACE}.activationSection`});
  const deviceOperationOptions = getDeviceOperationOptions(deviceDetailsList, action);

  const deviceCount = deviceDetailsList.length;

  const {contentKey, goUrlName} = getContentKeyAndGoUrlName(actionLowerCase, licensingMode, action);

  const values = {
    br: <br />,
    deviceCount,
    goUrl: (linkText) => <GoUrl name={goUrlName}>{linkText}</GoUrl>,
  };

  const {getDeviceDetails} = useDevicesApi(currentOptions);
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Method that executes when an device update action was successful
   * @param {Number} timestamp
   * @param {String} successMessage
   * @returns {Function} - onDeviceUpdated callback
   */
  const onDeviceUpdatedSuccess = async (timestamp, successMessage) => {
    // in case of RECOVER_ALL all active devices are deactivated, hence polling not required
    if (feature.isEnabled('temp_device_polling') && action !== DeviceAction.RECOVER_ALL) {
      try {
        setIsLoading(true);
        await pollDevicesApi({
          devices: deviceDetailsList,
          getDeviceDetails,
          options: {...currentOptions, limit: deviceCount},
          timestamp,
        });
        showSuccess(successMessage);
      } catch {
        showInfo(intl.formatMessage({id: `${NAMESPACE}.confirmationDialog.timeoutError`}));
      }
      setIsConfirmButtonClicked(true);
      close();
    } else {
      setIsConfirmButtonClicked(true);
      close();
      showSuccess(successMessage);
    }

    return onDeviceUpdated ? onDeviceUpdated() : onDeviceUpdateFromContextProvider();
  };

  /**
   * Method that executes when an device update action had an error
   * @param {String} errorMessage
   * @param {Boolean} showAlertDialog
   * @param {String} alertDialogTitle
   */
  const onDeviceUpdatedError = (errorMessage, showAlertDialog, alertDialogTitle) => {
    if (showAlertDialog) {
      showErrorAlertDialog(alertDialogTitle, errorMessage);
      return;
    }
    showError(errorMessage);
    close();
  };

  const devicesCountToBeRecovered = getCountToBeShownInConfirmationDialog(
    licensingMode,
    usedLicensesCount,
    frlOnlineUsedLicensesCount
  );

  return (
    <>
      {!isAlertDialog && (
        <Dialog aria-labelledby={nameId} data-testid="device-confirmation-dialog">
          <Heading>
            {intl.formatMessage(
              {
                id: `${NAMESPACE}.confirmationDialog.heading.${action.toLowerCase()}`,
              },
              {
                deviceCount,
                usedLicensesCount: devicesCountToBeRecovered,
              }
            )}
          </Heading>
          <Divider />
          <Content>
            <Text>{intl.formatMessage({id: `${contentKey}`}, values)}</Text>
          </Content>
          <ButtonGroup>
            <Button
              data-testid="dialog-cancel-button"
              isDisabled={isLoading}
              onPress={() => {
                close();
              }}
              variant="secondary"
            >
              {intl.formatMessage({id: `${NAMESPACE}.confirmationDialog.cancel`})}
            </Button>
            <Button
              data-testid="dialog-confirm-button"
              isDisabled={isLoading}
              onPress={() => {
                handleDeviceAction({
                  deviceDetailsNamespace: `${NAMESPACE}.confirmationDialog`,
                  deviceOperation: deviceOperations[action],
                  deviceOperationOptions,
                  intl,
                  onDeviceUpdatedError,
                  onDeviceUpdatedSuccess,
                });
              }}
              variant={BUTTON_VARIANT[action]}
            >
              {isLoading && (
                <ProgressCircle
                  aria-label={intl.formatMessage({id: `${NAMESPACE}.confirmationDialog.loading`})}
                  isIndeterminate
                  size="S"
                />
              )}
              {!isLoading && intl.formatMessage({id: `${intlActionId}.buttonName`}, {deviceCount})}
            </Button>
          </ButtonGroup>
        </Dialog>
      )}
      ;
      {isAlertDialog && (
        <AlertDialog
          data-testid="confirm-dialog-alert"
          onPrimaryAction={close}
          primaryActionLabel={intl.formatMessage({id: `${NAMESPACE}.confirmationDialog.close`})}
          title={alertTitle}
          variant="confirmation"
        >
          {alertMessage}
        </AlertDialog>
      )}
    </>
  );
};

ConfirmationDialog.propTypes = {
  /**
   * Whether it is Activate, Deactivate, or Remove action
   */
  action: PropTypes.oneOf(Object.values(DeviceAction)).isRequired,

  /**
   * Callback close function of DialogTrigger
   */
  close: PropTypes.func.isRequired,

  /**
   * List of device details
   */
  deviceDetailsList: PropTypes.arrayOf(PropTypes.instanceOf(Device)).isRequired,

  /**
   * License group ID or profile ID which is available in SDL
   */
  licenseGroupId: PropTypes.string,

  /**
   * Licensing mode
   */
  licensingMode: PropTypes.string.isRequired,

  /**
   * Callback function when device is activated/blocked/removed
   */
  onDeviceUpdated: PropTypes.func,

  /**
   * Callback function when the action is confirmed
   */
  onDialogUpdated: PropTypes.func,

  /**
   * Product ID
   */
  productId: PropTypes.string,
};

export default ConfirmationDialog;
