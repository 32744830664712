// ***********************************************************************
// ADOBE CONFIDENTIAL
// ___________________
//
// Copyright 2024 Adobe
// All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains
// the property of Adobe and its suppliers, if any. The intellectual
// and technical concepts contained herein are proprietary to Adobe
// and its suppliers and are protected by all applicable intellectual
// property laws, including trade secret and copyright laws.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Adobe.
// ************************************************************************
import {View} from '@adobe/react-spectrum';
import React from 'react';

import {useJsonPayload} from 'common/components/sophia/sophia-promo/JsonPayloadProvider';
import SpotlightPriceInfo from 'common/components/sophia/sophia-promo/sophia-promo-prices/spotlight-price-info/SpotlightPriceInfo';

const SophiaPromoDiscountedPrice = () => {
  const {payload} = useJsonPayload();

  // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- disabled for resolving merge conflicts
  // istanbul ignore next
  if (!payload) {
    return null;
  }

  return payload.discountedPrice && payload.priceFrequency ? (
    <View
      data-testid="one-console-promo-discount-price"
      marginBottom={{base: 'size-100', S: 'none'}}
    >
      <SpotlightPriceInfo
        color={payload.discountPriceColor}
        data-testid="one-console-promo-discount-price-spotlight"
        frequency={payload.priceFrequency}
        price={payload.discountedPrice} // defaults to black if not passed
      />
    </View>
  ) : null;
};

SophiaPromoDiscountedPrice.propTypes = {};

export default SophiaPromoDiscountedPrice;
