import {
  showError as showErrorToast,
  showInfo as showInfoToast,
  showSuccess as showSuccessToast,
} from '@admin-tribe/acsc-ui';
import {Button} from '@adobe/react-spectrum';
import {useTableSectionContext} from '@pandora/react-table-section';
import PropTypes from 'prop-types';
import React, {useRef, useState} from 'react';
import {useIntl} from 'react-intl';

import TeamList from 'common/services/team-list/TeamList';
import rootStore from 'core/RootStore';
import DeactivateDevicesModal from 'features/products/components/deactivate-devices-modal/DeactivateDevicesModal';

/**
 * Renders the deactivate button for legacy device details drawer which opens DeactivateDevicesModal.
 */
const DeactivateDeviceButton = ({device, poolId, onUpdateTableChange}) => {
  const {tableSectionUtils} = useTableSectionContext();
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const count = 1;
  const intl = useIntl();
  const openerButton = useRef(null);
  const teamListObj = new TeamList();
  const selectedDevice = [device.deviceName];

  const onDeactivate = async () => {
    showInfoToast(intl.formatMessage({id: 'products.details.devices.deactivateDeviceProgress'}));

    const deviceIds = [device.deviceId];
    const orgId = rootStore.organizationStore.activeOrgId;

    try {
      setLoading(true);
      await teamListObj.deactivateDevices(deviceIds, orgId, poolId);
      tableSectionUtils.modifyTableItems(deviceIds);
      onUpdateTableChange();
      showSuccessToast();
    } catch {
      showErrorToast();
    } finally {
      setLoading(false);
      setShowModal(false);
    }
  };

  return (
    <>
      <Button
        ref={openerButton}
        data-testid="deactivate-device-button"
        onPress={() => {
          setShowModal(true);
        }}
        variant="negative"
      >
        {intl.formatMessage({id: 'products.details.devices.legacyDevices.drawer.deactivateButton'})}
      </Button>
      <DeactivateDevicesModal
        isLoading={isLoading}
        isOpen={showModal}
        onCancel={() => {
          openerButton.current.focus();
          setShowModal(false);
        }}
        onDeactivate={onDeactivate}
        selectedCount={count}
        selectedDevices={selectedDevice}
      />
    </>
  );
};

DeactivateDeviceButton.propTypes = {
  /**
   * selected device details object
   */
  device: PropTypes.shape({
    deviceId: PropTypes.string,
    deviceName: PropTypes.string,
  }).isRequired,
  /** Reload page and update header section when device table is updated */
  onUpdateTableChange: PropTypes.func.isRequired,
  /** Identifies the pool that this device belongs to. */
  poolId: PropTypes.string.isRequired,
};
export default DeactivateDeviceButton;
