import {showError, showSuccess} from '@admin-tribe/acsc-ui';
import {Button, ButtonGroup, ProgressCircle} from '@adobe/react-spectrum';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import PackagesPreferencesService from '../../../services/PackagesPreferencesService';

// Button Group to save/discard changes in the Preferences page
const PreferencesButtonGroup = ({
  pathsAreValid,
  preferences,
  orgPreferences,
  previousBtnDisableNextRef,
  setPreferences,
  setOrgPreferences,
}) => {
  const intl = useIntl();
  const [actionLoading, setActionLoading] = useState(false);

  // On pressing save, update preferences and based on response show relevant toast
  async function updatePreferences() {
    setActionLoading(true);
    let orgPrefResponse, userPrefResponse;
    try {
      if (haveUserPreferencesChanged()) {
        userPrefResponse = await PackagesPreferencesService.storePreferences(preferences);
      }
      if (haveOrgPreferencesChanged()) {
        orgPrefResponse = await PackagesPreferencesService.storeOrgPreferences(orgPreferences);
      }
      setActionLoading(false);
      if (
        isPreferencesSaveSuccessful(orgPrefResponse) &&
        isPreferencesSaveSuccessful(userPrefResponse)
      ) {
        showSuccess(intl.formatMessage({id: 'packages.alerts.preferences.saveSuccess'}));
      }
      if (userPrefResponse?.data === 'ERROR_INVALID_AUSST_OVERRIDE') {
        showError(
          `${intl.formatMessage({
            id: 'packages.errors.preferences.saveError',
          })} ${intl.formatMessage({id: 'packages.preferences.ausst.invalidTooltip'})}`
        );
      }
    } catch (error) {
      setActionLoading(false);
      showError(intl.formatMessage({id: 'packages.errors.preferences.saveError'}));
    }
  }

  // On pressing discard, reset to preferences to old preferences
  function resetPreferences() {
    // Shifting focus before disabling else focus ring stays on disable button on disabling
    previousBtnDisableNextRef.current?.focus();
    setPreferences(PackagesPreferencesService.transformedPreferences);
    setOrgPreferences(PackagesPreferencesService.transformedOrgPreferences);
  }

  function isPreferencesSaveSuccessful(response) {
    if (response) {
      return response.data === 'SUCCESS';
    }
    return true;
  }

  // Checks if preferences have changed and returns true/false
  function havePreferencesChanged() {
    return haveUserPreferencesChanged() || haveOrgPreferencesChanged();
  }

  function haveUserPreferencesChanged() {
    const oldPreferences = PackagesPreferencesService.transformedPreferences;
    if (oldPreferences) {
      return !isEqual(preferences, oldPreferences);
    }
    return false;
  }
  function haveOrgPreferencesChanged() {
    const oldOrgPreferences = PackagesPreferencesService.transformedOrgPreferences;
    if (oldOrgPreferences) {
      return !isEqual(orgPreferences, oldOrgPreferences);
    }
    return false;
  }

  // Disable save if any of the functions return false
  function isSaveAllowed() {
    return havePreferencesChanged() && pathsAreValid;
  }

  return actionLoading ? (
    // ProgressCircle shown when action is in progress
    <ProgressCircle
      aria-label={intl.formatMessage({id: 'packages.preferences.action.loading'})}
      data-testid="preferences-progress-circle"
      isIndeterminate
    />
  ) : (
    <ButtonGroup data-testid="preferences-button-group">
      {/* Button to discard the changes made in the preferences page */}
      <Button
        data-testid="discard-preferences-button"
        isDisabled={!havePreferencesChanged()}
        onPress={resetPreferences}
        variant="primary"
      >
        {intl.formatMessage({id: 'packages.preferences.reset'})}
      </Button>
      {/* Button to save the changes made in the preferences page */}
      <Button
        data-testid="save-preferences-button"
        isDisabled={!isSaveAllowed()}
        onPress={updatePreferences}
        variant="accent"
      >
        {intl.formatMessage({id: 'packages.preferences.save'})}
      </Button>
    </ButtonGroup>
  );
};

PreferencesButtonGroup.propTypes = {
  /**
   * Organization Preferences object
   */
  orgPreferences: PropTypes.shape({
    /**
     * whether org has agreed to install adobe genuine service or not.
     */
    consentValue: PropTypes.bool,
    /**
     * organization country
     */
    country: PropTypes.string,
    /**
     * organization's market segment - commercial or education or government
     */
    organizationMarketSegment: PropTypes.string,
    /**
     * organization ID
     */
    orgId: PropTypes.string,
  }).isRequired,
  /**
   * Boolean value to indicate if userInstallDirectoryMac and userInstallDirectoryWin paths are valid
   */
  pathsAreValid: PropTypes.bool,
  /**
   * Preferences object
   */
  preferences: PropTypes.shape({
    /**
     * Indicates to bypass your organization's internal update server when it is unavailable
     */
    ausstFallbackEnabled: PropTypes.bool,
    /**
     * Override XML code for mac
     */
    ausstOverrideMac: PropTypes.string,
    /**
     * Override XML code for windows
     */
    ausstOverrideWin: PropTypes.string,
    /**
     * Indicates if user to be notified by email when creative cloud application is available.
     */
    subscribed: PropTypes.bool,
    /**
     * Install path for mac
     */
    userInstallDirectoryMac: PropTypes.string,
    /**
     * Install path for windows
     */
    userInstallDirectoryWin: PropTypes.string,
  }).isRequired,
  /**
   * Reference to the next element to focus on once discard button clicked(and disabled)
   */
  previousBtnDisableNextRef: PropTypes.shape({
    current: PropTypes.shape({focus: PropTypes.func}),
  }).isRequired,

  /**
   * Function to be called with updated preferences object when organization preferences object is to be modified
   */
  setOrgPreferences: PropTypes.func.isRequired,

  /**
   * Function to be called with updated preferences object when preferences object is to be modified
   */
  setPreferences: PropTypes.func.isRequired,
};

export default PreferencesButtonGroup;
