import {GoUrl} from '@admin-tribe/acsc-ui';
import {ModalAlertDialog} from '@pandora/react-modal-dialog';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {SHARED_PROJECT_CREATION_POLICY_VALUES} from '../../pages/shared-projects/sharedProjectPoliciesConstants';

// Confirmation modal shown when the selection is changed in the "shared-project creation-policy" section.
const SharedProjectCreationConfirmationDialog = ({onCancel, onPrimaryAction, pendingPolicy}) => {
  const intl = useIntl();

  const getContent = (key) =>
    intl.formatMessage(
      {id: `settings.sharedProjects.creationPolicy.modal.${pendingPolicy}.${key}`},
      // Pending ONESIE-37612 for the adobe_workspaces GoURL name to be updated and/or the destination to be assigned.
      {goUrl: (str) => <GoUrl name="aac_adobe_workspaces">{str}</GoUrl>}
    );

  return (
    <ModalAlertDialog
      analyticsModalId="shared-project-policy-modal"
      cancelLabel={intl.formatMessage({id: 'common.modal.buttons.cancel'})}
      onCancel={onCancel}
      onPrimaryAction={onPrimaryAction}
      primaryActionLabel={intl.formatMessage({id: 'common.modal.buttons.confirm'})}
      title={getContent('header')}
      variant="confirmation"
    >
      {getContent('description')}
    </ModalAlertDialog>
  );
};

SharedProjectCreationConfirmationDialog.propTypes = {
  /**
   * Called if "Cancel" button is pressed.
   */
  onCancel: PropTypes.func.isRequired,
  /**
   * Called if "Confirm" button is pressed.
   */
  onPrimaryAction: PropTypes.func.isRequired,
  /**
   * The pending policy to confirm.
   */
  pendingPolicy: PropTypes.oneOf(Object.values(SHARED_PROJECT_CREATION_POLICY_VALUES)).isRequired,
};

export default SharedProjectCreationConfirmationDialog;
