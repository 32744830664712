import {ORGANIZATION_MARKET_SUBSEGMENT, feature} from '@admin-tribe/acsc';
import {GoUrl} from '@admin-tribe/acsc-ui';
import {Checkbox, CheckboxGroup, Heading, View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import rootStore from 'core/RootStore';

const Organization = ({setOrganizationType, organizationType}) => {
  const [selectedOrganizationType, setSelected] = React.useState(organizationType);

  const OPTION_KEYS = {
    HIGHER_EDUCATION: ORGANIZATION_MARKET_SUBSEGMENT.HIGHER_ED,
    K12: ORGANIZATION_MARKET_SUBSEGMENT.K_12,
    NON_PROFIT: ORGANIZATION_MARKET_SUBSEGMENT.NON_PROFIT,
  };
  const previousSelOrgType = rootStore.organizationStore.activeOrg.getMarketSubsegments();
  const hasAlreadySetOrgTypes = previousSelOrgType?.length > 0;

  const intl = useIntl();
  const handleChange = (selectedOrgType) => {
    setSelected(selectedOrgType);
    setOrganizationType(selectedOrgType);
  };

  const copy = (key) =>
    intl.formatMessage({
      id: `common.contracts.deviceLicenseMigrationModal.organization.${key}`,
    });

  const higherEducationSubtitle = feature.isEnabled('temp_ctir_18688')
    ? copy('higherEducation.subtitle2')
    : copy('higherEducation.subtitle');

  return (
    <>
      <Heading level={3} marginBottom={0}>
        {hasAlreadySetOrgTypes ? copy('review.heading') : copy('heading')}
      </Heading>
      <View elementType="p" marginTop="size-50">
        {hasAlreadySetOrgTypes ? copy('review.description') : copy('description')}
      </View>

      {hasAlreadySetOrgTypes ? (
        <>
          {previousSelOrgType?.includes(OPTION_KEYS.K12) && (
            <View marginBottom="size-100">{copy('k12.title')}</View>
          )}
          {previousSelOrgType?.includes(OPTION_KEYS.HIGHER_EDUCATION) && (
            <View marginBottom="size-100">{copy('higherEducation.title')}</View>
          )}
          {previousSelOrgType?.includes(OPTION_KEYS.NON_PROFIT) && (
            <View marginBottom="size-100">{copy('nonProfit.title')}</View>
          )}
        </>
      ) : (
        <CheckboxGroup isEmphasized onChange={handleChange}>
          <View marginBottom="size-100">
            <Checkbox
              isDisabled={selectedOrganizationType?.includes(OPTION_KEYS.NON_PROFIT)}
              value={OPTION_KEYS.K12}
            >
              {copy('k12.title')}
            </Checkbox>
            <View marginStart="size-300">
              <FormattedMessage
                id="common.contracts.deviceLicenseMigrationModal.organization.k12.subtitle"
                values={{
                  link: (linkText) => <GoUrl name="aac_sdl_deploy_guide">{linkText}</GoUrl>,
                }}
              />
            </View>
            {selectedOrganizationType?.includes(OPTION_KEYS.K12) && (
              <ul>
                <li>
                  <FormattedMessage
                    id="common.contracts.deviceLicenseMigrationModal.organization.k12.description"
                    values={{
                      link: (linkText) => <GoUrl name="primary-secondary-terms">{linkText}</GoUrl>,
                    }}
                  />
                </li>
                <li>
                  <FormattedMessage
                    id="common.contracts.deviceLicenseMigrationModal.organization.k12.description2"
                    values={{
                      link: (linkText) => <GoUrl name="aac_org_type_setup">{linkText}</GoUrl>,
                    }}
                  />
                </li>
              </ul>
            )}
          </View>

          <View marginBottom="size-100">
            <Checkbox
              isDisabled={selectedOrganizationType?.includes(OPTION_KEYS.NON_PROFIT)}
              value={OPTION_KEYS.HIGHER_EDUCATION}
            >
              {copy('higherEducation.title')}
            </Checkbox>
            <View data-testid="higher-edu-subtitle" marginStart="size-300">
              {higherEducationSubtitle}
            </View>
          </View>

          <View marginBottom="size-100">
            <Checkbox
              isDisabled={selectedOrganizationType?.some((val) =>
                [OPTION_KEYS.K12, OPTION_KEYS.HIGHER_EDUCATION].includes(val)
              )}
              value={OPTION_KEYS.NON_PROFIT}
            >
              {copy('nonProfit.title')}
            </Checkbox>
            <View marginStart="size-300">{copy('nonProfit.subtitle')}</View>
          </View>
        </CheckboxGroup>
      )}
    </>
  );
};

Organization.propTypes = {
  /**
   * Organization types eg: k12, higherEd, nonProfit
   */
  organizationType: PropTypes.arrayOf(PropTypes.string).isRequired,
  /**
   * set organization type from the parent component
   */
  setOrganizationType: PropTypes.func.isRequired,
};

export default Organization;
