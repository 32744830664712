import {
  CollaborationPolicies,
  CollaborationPoliciesConcurrencyError,
  feature,
} from '@admin-tribe/acsc';
import {OverlayWait, showError, showSuccess} from '@admin-tribe/acsc-ui';
import {
  AlertDialog,
  Checkbox,
  CheckboxGroup,
  DialogContainer,
  Radio,
  RadioGroup,
  Text,
  View,
  Well,
} from '@adobe/react-spectrum';
import {useId} from '@react-aria/utils';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import ConfirmationButtonGroup from 'features/settings/common/components/confirmation-button-group/ConfirmationButtonGroup';

import SharingOptionsHeading from '../sharing-options-heading/SharingOptionsHeading';

const ALLOW_ACCESS = 'allowAccess';
const NO_ACCESS = 'noAccess';

// The "Assets restrictions policy" section for the "Sharing options" tab.
const AccessRequestPolicySection = ({
  canSetSharingOptions,
  collaborationPolicies,
  onTriggerAnalytics,
}) => {
  const accessRequestPolicyStatus = collaborationPolicies.getRequestAccessEnabled()
    ? ALLOW_ACCESS
    : NO_ACCESS;
  const initializeValueState = () =>
    feature.isDisabled('temp_asset_settings')
      ? [accessRequestPolicyStatus] // string[]
      : accessRequestPolicyStatus; // string
  const intl = useIntl();
  const descriptionId = useId();
  const headingId = useId();
  const [value, setValue] = useState(initializeValueState());
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pendingValue, setPendingValue] = useState(null);
  const isConfirmationButtonGroupDisabled =
    feature.isEnabled('temp_asset_settings') && value === accessRequestPolicyStatus;
  const NAMESPACE = 'settings.assetSettings.assetSharing.sharingOptions.assetRequestPolicy';

  const onChange = (selected /* string[] */) => {
    if (feature.isDisabled('temp_asset_settings')) {
      // If the checkbox is unchecked, there will be 0 selected.
      // Return immediately which effectively blocks the uncheck.
      if (selected.length > 1) {
        // At this point there will be 2 checkboxes selected. The initial selection and the checkbox just selected.
        const newValue = selected[selected.length - 1];
        setPendingValue(newValue);
        setIsOpen(true);
      }
    } else {
      setValue(selected);
      setPendingValue(selected);
    }
  };

  const onDismiss = () => {
    setIsOpen(false);
    if (feature.isEnabled('temp_asset_settings')) {
      setValue(accessRequestPolicyStatus);
    }
    setPendingValue(null);
  };

  const onSave = () => {
    setIsOpen(true);
  };

  const onDiscard = () => {
    setValue(accessRequestPolicyStatus);
    setPendingValue(null);
  };

  const onUpdateAccessReqest = async () => {
    try {
      setIsLoading(true);
      if (feature.isDisabled('temp_asset_settings')) {
        await collaborationPolicies.setRequestAccessEnabled(pendingValue === ALLOW_ACCESS);
        setValue([pendingValue]);
      } else {
        setValue(pendingValue);
        await collaborationPolicies.setRequestAccessEnabled(pendingValue === ALLOW_ACCESS);
      }
      showSuccess();
    } catch (error) {
      // Precondition Failed (412) indicates a policy has been updated and local copy needs refresh.
      const msg =
        error instanceof CollaborationPoliciesConcurrencyError
          ? intl.formatMessage({id: `${NAMESPACE}.error.concurrency`})
          : undefined;
      showError(msg);
      return;
    } finally {
      setIsLoading(false);
    }

    onTriggerAnalytics({
      attributeProps: {
        requestAccessPolicy:
          pendingValue === ALLOW_ACCESS ? 'AllowAccessRequests' : 'NoAccessRequests',
      },
      componentMethod: 'updateRequestAccess',
    });
  };

  return (
    <>
      <OverlayWait isLoading={isLoading} showContent size="S">
        <SharingOptionsHeading canSetSharingOptions={canSetSharingOptions} id={headingId}>
          {intl.formatMessage({id: `${NAMESPACE}.title`})}
        </SharingOptionsHeading>

        {/* Change of context notice is required for accessibility Success Criterion 3.2.2 */}
        <View id={descriptionId} marginBottom="static-size-200">
          {intl.formatMessage({id: `${NAMESPACE}.contextChange`})}
        </View>

        {feature.isDisabled('temp_asset_settings') ? (
          /* The Checkbox label should be succinct. Details belong in the description which is linked to the Checkbox. */
          <Well>
            <CheckboxGroup
              aria-describedby={descriptionId}
              aria-labelledby={headingId}
              isDisabled={!canSetSharingOptions}
              onChange={onChange}
              value={value}
            >
              <Checkbox aria-describedby="access-request-policy-section-allow" value={ALLOW_ACCESS}>
                <strong>{intl.formatMessage({id: `${NAMESPACE}.radio.allow.label`})}</strong>
              </Checkbox>
              <Text id="access-request-policy-section-allow">
                {intl.formatMessage({id: `${NAMESPACE}.radio.allow.description`})}
              </Text>
              <Checkbox
                aria-describedby="access-request-policy-section-no-access"
                value={NO_ACCESS}
              >
                <strong>{intl.formatMessage({id: `${NAMESPACE}.radio.noAccess.label`})}</strong>
              </Checkbox>
              <Text id="access-request-policy-section-no-access">
                {intl.formatMessage({id: `${NAMESPACE}.radio.noAccess.description`})}
              </Text>
            </CheckboxGroup>
          </Well>
        ) : (
          /* The RadioButton label should be succinct. Details belong in the description which is linked to the RadioButton. */
          <Well>
            <RadioGroup
              aria-describedby={descriptionId}
              aria-labelledby={headingId}
              isDisabled={!canSetSharingOptions}
              onChange={onChange}
              value={value}
            >
              <Radio aria-describedby="access-request-policy-section-allow" value={ALLOW_ACCESS}>
                <strong>{intl.formatMessage({id: `${NAMESPACE}.radio.allow.label`})}</strong>
              </Radio>
              <Text id="access-request-policy-section-allow">
                {intl.formatMessage({id: `${NAMESPACE}.radio.allow.description`})}
              </Text>
              <Radio aria-describedby="access-request-policy-section-no-access" value={NO_ACCESS}>
                <strong>{intl.formatMessage({id: `${NAMESPACE}.radio.noAccess.label`})}</strong>
              </Radio>
              <Text id="access-request-policy-section-no-access">
                {intl.formatMessage({id: `${NAMESPACE}.radio.noAccess.description`})}
              </Text>
            </RadioGroup>
            <ConfirmationButtonGroup
              isDisabled={isConfirmationButtonGroupDisabled}
              onDiscard={onDiscard}
              onSave={onSave}
            />
          </Well>
        )}
      </OverlayWait>

      <DialogContainer onDismiss={onDismiss}>
        {isOpen && (
          <AlertDialog
            cancelLabel={intl.formatMessage({id: 'common.modal.buttons.cancel'})}
            onPrimaryAction={onUpdateAccessReqest}
            primaryActionLabel={intl.formatMessage({id: 'common.modal.buttons.confirm'})}
            title={intl.formatMessage({
              id: `${NAMESPACE}.modal.${pendingValue}.title`,
            })}
            variant="confirmation"
          >
            {intl.formatMessage({
              id: `${NAMESPACE}.modal.${pendingValue}.content`,
            })}
          </AlertDialog>
        )}
      </DialogContainer>
    </>
  );
};

AccessRequestPolicySection.propTypes = {
  /**
   * True if the radio group in the section is enabled.
   */
  canSetSharingOptions: PropTypes.bool.isRequired,
  /**
   * The collaboration policies for the org.
   */
  collaborationPolicies: PropTypes.instanceOf(CollaborationPolicies),
  /**
   * The Analytics callback which is triggered when a selection is changed.
   */
  onTriggerAnalytics: PropTypes.func.isRequired,
};

export default AccessRequestPolicySection;
export {ALLOW_ACCESS, NO_ACCESS};
