import {Locale, feature} from '@admin-tribe/acsc';
import {withAEMContent} from '@pandora/react-aem-content-provider';
import {PaymentDetails} from '@pandora/react-contract-payment';
import {ContractMiniAppContentModel} from '@pandora/react-mini-app-contract';
import PropTypes from 'prop-types';
import React from 'react';

import rootStore from 'core/RootStore';

import {goToBillingHistory} from '../routing/navigation-callbacks/navigationCallbacks';

import styles from './PaymentInfoMiniAppWrapper.pcss';

/**
 * Go to Billing History page
 */
const viewBillingHistoryHandler = () => {
  goToBillingHistory();
};

/**
 * @description Represents for Contract Payment Info Mini App
 *
 * @param {String} contractId - Contract ID
 */
const PaymentInfoMiniAppWrapper = ({contractId, openEditPaymentInfoModal}) => {
  // Get contract for the given contract ID
  const getContract = feature.isEnabled('temp_account_data_optimization')
    ? () => {
        const contractList = rootStore.organizationStore.contractList;
        return contractList.items.find((item) => item.id === contractId);
      }
    : null;

  const PaymentInfoMiniAppWithContent = withAEMContent(
    'businesstrials/account-contract-mini-app',
    'reactMiniAppAccountContractV1Main',
    ContractMiniAppContentModel
  )(PaymentDetails);

  // Main component
  return (
    <div className={styles['payment-info-mini-app']} data-testid="payment-info-mini-app">
      <PaymentInfoMiniAppWithContent
        aem={{locale: Locale.get().activeLanguageBCP47Code, usePlaceholderContent: true}}
        contractId={contractId}
        data={{getContract}}
        editPaymentHandler={openEditPaymentInfoModal}
        orgId={rootStore.organizationStore.activeOrgId}
        viewBillingHistoryHandler={viewBillingHistoryHandler}
      />
    </div>
  );
};

PaymentInfoMiniAppWrapper.propTypes = {
  /**
   * Contract ID
   */
  contractId: PropTypes.string,
  /**
   * Open Payment Info Editor Modal
   */
  openEditPaymentInfoModal: PropTypes.func,
};

export default PaymentInfoMiniAppWrapper;
