import {PasswordPolicy} from '@admin-tribe/acsc';
import {Text, View} from '@adobe/react-spectrum';
import {DividerPlacement, TitledSection} from '@pandora/react-titled-section';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import PasswordSettingsRadioGroup from './password-settings-radio-group/PasswordSettingsRadioGroup';
import PasswordSettingsTable from './password-settings-table/PasswordSettingsTable';

/**
 * Defines the password settings section of the auth settings page.
 */
const PasswordSettingsSection = ({isT2e, onPasswordPolicyChange, policy}) => {
  const intl = useIntl();

  const getDescription = () =>
    intl.formatMessage({
      id: `settings.authSettings.password.description${isT2e ? 'T2e' : ''}`,
    });

  return (
    <TitledSection
      data-testid="password-settings-section"
      dividerPlacement={DividerPlacement.NONE}
      headingLevel={2}
      title={intl.formatMessage({
        id: 'settings.authSettings.password.title',
      })}
    >
      <Text data-testid="description">{getDescription()}</Text>
      <View marginTop="size-100">
        <PasswordSettingsTable />
      </View>
      <View marginTop="size-200">
        {/* For the screen reader admin, the description, including the table, should come before the control. */}
        <PasswordSettingsRadioGroup
          onPasswordPolicyChange={onPasswordPolicyChange}
          policy={policy}
        />
      </View>
    </TitledSection>
  );
};

PasswordSettingsSection.propTypes = {
  /**
   * True if the organization has migrated to be T2E.
   */
  isT2e: PropTypes.bool.isRequired,
  /**
   * Callback function triggered with no parameters on the change of a password policy.
   */
  onPasswordPolicyChange: PropTypes.func.isRequired,
  /**
   * The policy object containing the current password policy level.
   */
  policy: PropTypes.instanceOf(PasswordPolicy).isRequired,
};

export default PasswordSettingsSection;
