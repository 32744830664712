/* eslint-disable max-lines -- over line limit */
import {ORGANIZATION_MARKET_SEGMENT, feature} from '@admin-tribe/acsc';
import {translateString} from '@admin-tribe/acsc-ui';
import cloneDeep from 'lodash/cloneDeep';
import isMatch from 'lodash/isMatch';

import rootStore from 'core/RootStore';
import {
  COLD_FUSION_SAP_CODE,
  CREATE_PACKAGE_CONSTANTS,
  FLOW_TYPES,
  INVALID_PACKAGE_NAME_REGEX,
  KCCC_SAP_CODE,
  PACKAGE_TYPE_CONSTANTS,
  PRODUCT_SAPCODES,
  SCREEN_NAME_CONSTANTS,
} from 'features/packages/packagesConstants';

import {ACROBAT_CLASSIC_24_VERSION} from '../components/create-package-modal/CreatePackageConstants';
import CreatePackageFactory from '../services/CreatePackageService';
import PackagesAdobeProductsUi from '../services/PackagesAdobeProductsUiService';
import PackagesEntitlements from '../services/PackagesEntitlementsService';
import PackagesIngestAnalytics from '../services/PackagesIngestAnalyticsService';

const INGEST_ANALYTICS_VARS = [
  'rumEnabled',
  'extensionsEnabled',
  'userInstallDirectory',
  'ausstOverrideEnabled',
];

const FRL_PACKAGE_TO_FLOW_MAPPING = {
  [CREATE_PACKAGE_CONSTANTS.FRL_ONLINE_PACKAGE]: FLOW_TYPES.FRL_ONLINE_PACKAGE_FLOW,
  [CREATE_PACKAGE_CONSTANTS.FRL_LAN_PACKAGE]: FLOW_TYPES.FRL_LAN_PACKAGE_FLOW,
  [CREATE_PACKAGE_CONSTANTS.FRL_ISOLATED_PACKAGE]: FLOW_TYPES.FRL_ISOLATED_PACKAGE_FLOW,
  [CREATE_PACKAGE_CONSTANTS.FRL_OFFLINE_PACKAGE]: FLOW_TYPES.FRL_OFFLINE_PACKAGE_FLOW,
};

/**
 * @description Scrolls the wizard's scroll bars to initial position
 * @param {Object} contentRef
 */
const scrollToInitialPosition = (contentRef) => {
  if (contentRef.current?.UNSAFE_getDOMNode()?.scrollTo) {
    contentRef.current.UNSAFE_getDOMNode().scrollTo(0, 0);
  }
};

/**
 * @description Deduce package flow to initiate
 * @param {Boolean} isDcOnlyEntitlement
 * @param {Boolean} isNonCcOnlyEntitlement
 * @param {String} packagingMode
 * @param {String} packageType
 * @returns {String} package flow
 */
const getPackageFlow = (
  isDcOnlyEntitlement,
  isNonCcOnlyEntitlement,
  packagingMode,
  packageType
) => {
  switch (packagingMode) {
    case CREATE_PACKAGE_CONSTANTS.NAMED_PACKAGE_TYPE:
      return FLOW_TYPES.NAMED_PACKAGE_FLOW;
    case CREATE_PACKAGE_CONSTANTS.FRL_PACKAGE_TYPE:
      if (
        (isDcOnlyEntitlement || isNonCcOnlyEntitlement) &&
        packageType === CREATE_PACKAGE_CONSTANTS.FRL_ISOLATED_PACKAGE
      ) {
        return FLOW_TYPES.FRL_ISOLATED_DC_NONCC_PACKAGE_FLOW;
      }
      return FRL_PACKAGE_TO_FLOW_MAPPING[packageType];
    case CREATE_PACKAGE_CONSTANTS.NUELL_PACKAGE_MODE:
      return FLOW_TYPES.NUELL_PACKAGE_FLOW;
    default:
      return '';
  }
};

/**
 * @description Method to get the custom DNS URL in the required format
 *
 * @returns {String} custom dns url.
 */
const customDnsUrlFormatted = (profileServerUrl) => {
  const customDNSUrl = profileServerUrl.toLocaleLowerCase();
  if (!(customDNSUrl.startsWith('http://') || customDNSUrl.startsWith('https://'))) {
    return `https://${customDNSUrl}`;
  }
  return customDNSUrl;
};

/**
 * @description filter the set of products that are applicable for FRL workflow based on what entitlements are selected and if products are NGL enabled
 * @param {Array} platformSpecificAdobeProductsUI array of all products for that platform
 * @returns {Array} array of products applicable for FRL workflow
 */
const filterProductsForFrlWorkflow = (pkgSessionSettings, platformSpecificAdobeProductsUI) => {
  let entitlementSapcodes = [];
  pkgSessionSettings.selectedFrlOffers.forEach((offer) => {
    entitlementSapcodes = [
      ...new Set([...entitlementSapcodes, ...offer.getPackageableProductSapCodes()]),
    ];
  });
  if (!entitlementSapcodes || entitlementSapcodes.length === 0) {
    return [];
  }
  const stiProducts = platformSpecificAdobeProductsUI.filter((product) => product.isSti());
  const entitledProducts = [];
  platformSpecificAdobeProductsUI.forEach((product) => {
    if (product.isNGLEnabled() && entitlementSapcodes.includes(product.getSapCode())) {
      if (
        pkgSessionSettings.packageType !== rootStore.packagesUiConstantsStore.frlOfflineUnitCode ||
        product.isFRLOfflineSupported()
      ) {
        entitledProducts.push(product);
      }
    } else if (pkgSessionSettings.showAccInUI && product.getSapCode() === KCCC_SAP_CODE) {
      entitledProducts.push(product);
    }
  });

  return [...new Set([...stiProducts, ...entitledProducts])];
};

/**
 * @description Method to initialize package create object with required attrs
 *
 * @returns {Object} Package create object.
 */
const initializePackageCreateObj = (packageType) => {
  const packageCreateObj = {
    accAdminPrivileged: rootStore.packagesUiConstantsStore.defaultPackageAdminPrivilegeSetting,
    appPanelEnabled: rootStore.packagesUiConstantsStore.defaultPackageAppsPanelSetting,
    ausstFallbackEnabled: false,
    ausstOverride: false,
    bits: CREATE_PACKAGE_CONSTANTS.SIXTY_FOUR_BIT,
    browserBasedAuthEnabled: rootStore.packagesUiConstantsStore.defaultBrowserBasedAuthSetting,
    customerType: PackagesEntitlements.isEnterpriseOrg
      ? CREATE_PACKAGE_CONSTANTS.ENTERPRISE_CUSTOMER
      : CREATE_PACKAGE_CONSTANTS.TEAM_CUSTOMER,
    enableExtenstions: rootStore.packagesUiConstantsStore.defaultExtensionsSetting,
    filesPanelEnabled: rootStore.packagesUiConstantsStore.defaultCoreSyncSetting,
    locale: rootStore.packagesUiConstantsStore.defaultPackageLocaleSetting,
    marketPanelEnabled: rootStore.packagesUiConstantsStore.defaultCoreSyncSetting,
    osLangDetection: rootStore.packagesUiConstantsStore.defaultPackageOsLanguageDetectionSetting,
    packageName: '',
    packageType: CREATE_PACKAGE_CONSTANTS.SELF_SERVE_PACKAGE,
    rumEnabled: rootStore.packagesUiConstantsStore.defaultPackageRumSetting,
    selectedAdobeProductIds: [],
    templateType: null,
    updatesEnabled: rootStore.packagesUiConstantsStore.defaultPackageUpdatesSetting,
    userInstallDirectory: false,
  };

  packageCreateObj.selectedProductAddOnsMap = {};

  packageCreateObj.accDisableAutoAppUpdate =
    rootStore.packagesUiConstantsStore.defaultPackageDisableAutoAppUpdateSetting;
  packageCreateObj.betaAppDownloadEnabled = false;
  if (
    packageType === PACKAGE_TYPE_CONSTANTS.MANAGED ||
    packageType === PACKAGE_TYPE_CONSTANTS.SELF_SERVICE ||
    packageType === PACKAGE_TYPE_CONSTANTS.NUELL
  ) {
    packageCreateObj.betaAppDownloadEnabled =
      rootStore.packagesUiConstantsStore.defaultBetaAppDownloadSetting;
  }
  packageCreateObj.selfServePluginsEnabled = false;
  if (
    rootStore.organizationStore.activeOrg.marketSegment !== ORGANIZATION_MARKET_SEGMENT.EDUCATION
  ) {
    packageCreateObj.selfServePluginsEnabled =
      rootStore.packagesUiConstantsStore.defaultSelfServePluginsSetting;
  }
  return packageCreateObj;
};

/**
 * @description Method to check if given string is a valid package name
 * @param {String} nameParam
 *
 * @returns {Boolean} indicates if the given string is a valid package name
 */
function isPackageNameValid(nameParam) {
  const regex = new RegExp(INVALID_PACKAGE_NAME_REGEX);
  const name = nameParam.trim();
  if (name.length === 0 || name.search(regex) !== -1 || name.length > 40 || name[0] === '.') {
    return false;
  }
  return true;
}

/**
 * @description Add required values to pkgSessionSettings
 *
 * @returns {Object} pkgSessionSettings object
 */
const addValuesToPkgSessionsSetting = () => {
  const pkgSessionSettings = {
    createPackageDisabled: true,
    enableAalExtension: false,
    hasFrlEntitlement: PackagesEntitlements.hasFrlEntitlement,
    hasNuellEntitlement: PackagesEntitlements.hasNuellEntitlement,
    nextButtonEnabled: true,
  };
  pkgSessionSettings.enableCustomDNS = false;
  pkgSessionSettings.adobeLicenseFixedDns = false;
  pkgSessionSettings.adobeLicenseServerFixedIp = false;
  pkgSessionSettings.customDNS = {};
  pkgSessionSettings.selectedPlugins = [];
  pkgSessionSettings.isPluginsScreenVisible = false;

  return pkgSessionSettings;
};
/**
 * @description Evaluates if the product should be visible
 *
 * @param {Object} pkgSessionSettings Shared object across package creation session
 * @param {Object} product whose visibility is to be evaluated
 * @returns {Boolean} true if product should be visible
 */
const getProductVisiblity = (pkgSessionSettings, product) => {
  // if adobe creative cloud should not be shown and the product is acc, return false
  if (!pkgSessionSettings.showAccInUI && product.getSapCode() === KCCC_SAP_CODE) {
    return false;
  }

  if (CreatePackageFactory.isNonCCTemplate()) {
    return true;
  }

  // always hide e-learning products in regular create package workflow
  if (
    pkgSessionSettings.packagingMode === CREATE_PACKAGE_CONSTANTS.NAMED_PACKAGE_TYPE &&
    rootStore.packagesUiConstantsStore.nonCcProductSapCodes.find(
      (nonCcSapCode) => nonCcSapCode === product.getSapCode()
    )
  ) {
    return false;
  }

  // mark all products except adobe creative cloud's visibility false for now if extra products are not to be shown
  // and they will be made visible when templates are parsed.
  if (
    pkgSessionSettings.isTemplatizedPackageCreate &&
    !pkgSessionSettings.showExtraProductsInTemplates &&
    product.getSapCode() !== KCCC_SAP_CODE
  ) {
    return false;
  }
  return true;
};

const LATEST_KCCC_VERSION = '4.9';

/**
 * @description Evaluates if the product has latest Core components
 *
 * @param {Object} product being evaluated
 * @param {Number} bits of OS
 * @returns {Boolean} true if the product has latest Core components
 */
const isLatestKCCC = (product, bits) =>
  product.getSapCode() === KCCC_SAP_CODE &&
  ((bits === CREATE_PACKAGE_CONSTANTS.THIRTY_TWO_BIT && product.version === LATEST_KCCC_VERSION) ||
    (bits === CREATE_PACKAGE_CONSTANTS.SIXTY_FOUR_BIT && !product.isArchived()));

/**
 * @description Method to send the ingest analytics appropiately
 *
 * @param {String} contentName name of the property selected
 * @param {String} packageType type of package
 */
const sendIngestAnalytics = (contentName, packageType) => {
  PackagesIngestAnalytics.triggerAnalytics({
    content: contentName,
    subCategory: PackagesIngestAnalytics.getIngestSubcategory(packageType, 'Options'),
    subType: 'management-options',
    type: 'click',
  });
};

/**
 * @description Method to add product into adobeProductsUI
 * @param {Object} product product object for a platform
 * @param {Array} adobeProductsUI array of product object
 * @param {Object} adobeProductsUIKeyMap <sapcode/version/platform> key to product object map
 */
const pushProductToProductUi = (product, adobeProductsUI, adobeProductsUIKeyMap) => {
  if (
    product.sapCode === PRODUCT_SAPCODES.APRO &&
    PackagesEntitlements.hasAcrobatClassicTermLicense
  ) {
    if (
      product.version === ACROBAT_CLASSIC_24_VERSION &&
      feature.isEnabled('temp_packages_acrobat_only_flow')
    ) {
      product.visible = true;
      product.archive = false;
      adobeProductsUI.push(product);
      adobeProductsUIKeyMap[product.product_key] = product;
    }
  } else {
    adobeProductsUI.push(product);
    adobeProductsUIKeyMap[product.product_key] = product;
  }
};
/* eslint-disable complexity -- will be fixed when we remove feature flag */
/**
 *
 * @param {Object} pkgSessionSettings shared object for create package flow session
 * @param {Object} product product object for a platform
 * @param {Array} adobeProductsUI array of product object
 * @param {Object} adobeProductsUIKeyMap <sapcode/version/platform> key to product object map
 */
const updateAdobeProductsUI = (
  pkgSessionSettings,
  product,
  adobeProductsUI,
  adobeProductsUIKeyMap
) => {
  // In case of Acrobat template when there is no CC entitlement in org or for non CC template, only add those products in the map which
  // are included in template (templatized products only)
  if (
    CreatePackageFactory.isNonCCTemplate() ||
    (feature.isEnabled('temp_packages_acrobat_only_flow') &&
      !PackagesEntitlements.hasCcEntitlement &&
      CreatePackageFactory.isAcrobatOnlyTemplate())
  ) {
    if (
      pkgSessionSettings.template
        ?.getPackagedAppsForUi()
        .find((packagedAppInTemplate) => product.sapCode === packagedAppInTemplate.sapCode)
    ) {
      pushProductToProductUi(product, adobeProductsUI, adobeProductsUIKeyMap);
    }
  } else if (
    feature.isEnabled('temp_packages_acrobat_only_flow') &&
    PackagesEntitlements.hasDcOnlyEntitlement &&
    pkgSessionSettings.packagingMode === CREATE_PACKAGE_CONSTANTS.NAMED_PACKAGE_TYPE
  ) {
    if (product.sapCode === PRODUCT_SAPCODES.APRO) {
      pushProductToProductUi(product, adobeProductsUI, adobeProductsUIKeyMap);
    }
  } else if (
    // add product to adobeProductsUI and to map adobeProductsUIKeyMap
    // If packageType is not of "FRL Offline" type, Then we have to show all products but
    // In case of  frl_offline, we have to show only offlineSupported products.
    pkgSessionSettings.packageType !== CREATE_PACKAGE_CONSTANTS.FRL_OFFLINE_PACKAGE ||
    product.isFRLOfflineSupported()
  ) {
    pushProductToProductUi(product, adobeProductsUI, adobeProductsUIKeyMap);
  }
};

/* eslint-enable complexity -- will be fixed when we remove feature flag */

/**
 * @description Method to make keys from sapCode, platform and locale and add to adobeProductsUI (for non STIs)
 *
 * @param {Number} bits selected in create package flow
 * @param {String} platform  selected in create package flow
 * @param {Object} pkgSessionSettings shared object for create package flow session
 * @param {Object} template package on which customize flow has been triggered
 *
 * @returns {Object} updated adobeProductsUI, adobeProductsUIKeyMap, selectedProducts
 */
const getAdobeProductUIKeyMap = (bits, platform, pkgSessionSettings) => {
  const adobeProductsUI = [];
  const adobeProductsUIKeyMap = {}; // <sapcode/version/platform> key to product object map
  const selectedProducts = [];
  // get products for platform selected into platformSpecificAdobeProductsUI
  let platformSpecificAdobeProductsUI = cloneDeep(
    PackagesAdobeProductsUi.getProductsForPlatform(platform, bits)
  );
  if (
    pkgSessionSettings.packagingMode === CREATE_PACKAGE_CONSTANTS.FRL_PACKAGE_TYPE ||
    pkgSessionSettings.packagingMode === CREATE_PACKAGE_CONSTANTS.NUELL_PACKAGE_MODE
  ) {
    platformSpecificAdobeProductsUI = filterProductsForFrlWorkflow(
      pkgSessionSettings,
      platformSpecificAdobeProductsUI
    );
  }
  platformSpecificAdobeProductsUI.forEach((product) => {
    if (!product.isSti()) {
      updateAdobeProductsUI(pkgSessionSettings, product, adobeProductsUI, adobeProductsUIKeyMap);
      product.visible = getProductVisiblity(pkgSessionSettings, product);
    }

    // special case: mark latest Core components (KCCC) selected by default when light weight pacakge workflow is not enabled
    if (
      (pkgSessionSettings.packageType !== PACKAGE_TYPE_CONSTANTS.MANAGED ||
        pkgSessionSettings.isTemplatizedPackageCreate) &&
      pkgSessionSettings.showAccInUI &&
      isLatestKCCC(product, bits)
    ) {
      product.selected = true;
      selectedProducts.push(product);
    }
  });
  if (pkgSessionSettings.isTemplatizedPackageCreate) {
    const templateProdList = pkgSessionSettings.template.getPackagedAppsForCustomization(
      platform,
      bits === CREATE_PACKAGE_CONSTANTS.THIRTY_TWO_BIT
    );
    templateProdList.forEach((product) => {
      if (adobeProductsUIKeyMap[product.product_key]) {
        if (product.visible) {
          adobeProductsUIKeyMap[product.product_key].visible = true;
        }
        const addingProd = adobeProductsUIKeyMap[product.product_key];
        if (!addingProd.isSelected()) {
          adobeProductsUIKeyMap[product.product_key].selected = true;
          selectedProducts.push(adobeProductsUIKeyMap[product.product_key]);
        }
      }
    });
  }

  return {
    adobeProductsUI,
    adobeProductsUIKeyMap,
    selectedProducts,
  };
};

/**
 * @description Posts package creation request
 *
 * @param {Boolean} containsArchived indicates if archived product is present
 * @param {Object} packageCreateObj create package request data object
 * @param {Object} pkgSessionSettings shared object for package creation session
 *
 */
const postPackageCreationRequest = (containsArchived, packageCreateObj, pkgSessionSettings) => {
  const goCartValues = {};
  goCartValues.goCartConsentText = translateString({
    id: 'packages.preferences.genuine.service.consentText',
  });
  goCartValues.orgCountryCode = rootStore.organizationStore.activeOrg.countryCode;
  goCartValues.orgMarketSegment = rootStore.organizationStore.activeOrg.marketSegment;

  const deviceViewConsent = {};

  if (
    isMatch(pkgSessionSettings, {
      deviceInformationShared: true,
      licenseFileSelected: true,
      packageType: CREATE_PACKAGE_CONSTANTS.FRL_ONLINE_PACKAGE,
      packagingMode: CREATE_PACKAGE_CONSTANTS.FRL_PACKAGE_TYPE,
    })
  ) {
    deviceViewConsent.deviceInformationShared = true;
  }
  let workflow = 'CreateNewPackageWorkflow';
  if (pkgSessionSettings.isTemplatizedPackageCreate) {
    workflow = 'createTemplateWorkflow';
    if (pkgSessionSettings.isSingleApp) {
      workflow = 'createSingleAppWorkflow';
    }
  }
  const packagedPlugins = pkgSessionSettings.selectedPlugins ?? [];

  CreatePackageFactory.postPackageCreationRequest({
    containsArchived,
    isUpdatesOnlyPackage: false,
    packageCreateObj: {...packageCreateObj, ...goCartValues, ...deviceViewConsent},
    packagedPlugins,
    workflow,
  });
};

/**
 * @description Method to get frl package type based on entitlements
 *
 * @returns {String} frl package type
 */
const getFrlPackageType = () => {
  if (PackagesEntitlements.hasFrlOnlineEntitlement) {
    return CREATE_PACKAGE_CONSTANTS.FRL_ONLINE_PACKAGE;
  }
  if (PackagesEntitlements.hasFrlLanEntitlement) {
    return CREATE_PACKAGE_CONSTANTS.FRL_LAN_PACKAGE;
  }
  if (PackagesEntitlements.hasFrlOfflineEntitlement) {
    return CREATE_PACKAGE_CONSTANTS.FRL_OFFLINE_PACKAGE;
  }
  if (PackagesEntitlements.hasFrlIsolatedEntitlement) {
    return CREATE_PACKAGE_CONSTANTS.FRL_ISOLATED_PACKAGE;
  }
  return '';
};

/**
 * @description Triggers analytics call and reset entitlements offers
 *
 */
const triggerAnalyticsAndResetOffers = () => {
  PackagesIngestAnalytics.triggerAnalytics({
    subCategory: 'none',
    subType: 'create-package',
    type: 'click',
  });
  PackagesEntitlements.resetOffers();
};

const STORE_VALUES_FOR_FLOW = {
  [FLOW_TYPES.CHOOSE_PACKAGE_FLOW]: {
    currentScreen: SCREEN_NAME_CONSTANTS.CHOOSE_PACKAGE_TYPE,
    packageType: CREATE_PACKAGE_CONSTANTS.SELF_SERVE_PACKAGE,
    packageTypeSelectionWizardSteps: [SCREEN_NAME_CONSTANTS.CHOOSE_PACKAGE_TYPE],
    packagingMode: CREATE_PACKAGE_CONSTANTS.NAMED_PACKAGE_TYPE,
    screenstate: 1,
    showPackageTypeSelectionWizard: true,
  },
  [FLOW_TYPES.COMPLETE_FLOW]: {
    currentScreen: SCREEN_NAME_CONSTANTS.CHOOSE_LICENSE_TYPE,
    packageType: CREATE_PACKAGE_CONSTANTS.SELF_SERVE_PACKAGE,
    packageTypeSelectionWizardSteps: [SCREEN_NAME_CONSTANTS.CHOOSE_LICENSE_TYPE],
    packagingMode: CREATE_PACKAGE_CONSTANTS.NAMED_PACKAGE_TYPE,
    screenstate: 0,
    showPackageTypeSelectionWizard: true,
  },
  [FLOW_TYPES.FRL_FUE_FLOW]: {
    currentScreen: SCREEN_NAME_CONSTANTS.CHOOSE_ACTIVATION_TYPE,
    packageType: CREATE_PACKAGE_CONSTANTS.FRL_PACKAGE_TYPE,
    packageTypeSelectionWizardSteps: [SCREEN_NAME_CONSTANTS.CHOOSE_ACTIVATION_TYPE],
    packagingMode: CREATE_PACKAGE_CONSTANTS.FRL_PACKAGE_TYPE,
    screenstate: 1,
    showPackageTypeSelectionWizard: true,
  },
  [FLOW_TYPES.NAMED_MANAGED_PACKAGE_FLOW]: {
    currentScreen: SCREEN_NAME_CONSTANTS.SYSTEM_CONFIG_SCREEN,
    packageType: CREATE_PACKAGE_CONSTANTS.MANAGED_PACKAGE,
    packageTypeSelectionWizardSteps: [],
    packagingMode: CREATE_PACKAGE_CONSTANTS.NAMED_PACKAGE_TYPE,
    screenstate: 2,
    showPackageTypeSelectionWizard: false,
  },
  [FLOW_TYPES.NUELL_FUE_FLOW]: {
    currentScreen: SCREEN_NAME_CONSTANTS.CHOOSE_ENTITLEMENT,
    packageType: CREATE_PACKAGE_CONSTANTS.NUELL_PACKAGE_MODE,
    packageTypeSelectionWizardSteps: [],
    packagingMode: CREATE_PACKAGE_CONSTANTS.NUELL_PACKAGE_MODE,
    screenstate: 1,
    showPackageTypeSelectionWizard: false,
  },
  [FLOW_TYPES.SELF_SERVE_FLOW]: {
    currentScreen: SCREEN_NAME_CONSTANTS.SELF_SERVICE_SCREEN,
    packageType: CREATE_PACKAGE_CONSTANTS.SELF_SERVE_PACKAGE,
    packageTypeSelectionWizardSteps: [SCREEN_NAME_CONSTANTS.SELF_SERVICE_SCREEN],
    packagingMode: CREATE_PACKAGE_CONSTANTS.NAMED_PACKAGE_TYPE,
    screenstate: 2,
    showPackageTypeSelectionWizard: true,
  },
};

const PACKAGE_CREATE_OBJ_NUELL_FUE = {
  accAdminPrivileged: false,
  accDisableAutoAppUpdate: true,
  appPanelEnabled: false,
  filesPanelEnabled: false,
  marketPanelEnabled: false,
  selfServePluginsEnabled: false,
};

const updatePackageType = (packageType, pkgSessionSettings, packageCreateObj) => {
  let pkgType = packageType;
  if (packageType === CREATE_PACKAGE_CONSTANTS.FRL_PACKAGE_TYPE) {
    pkgType = getFrlPackageType();
  } else if (
    feature.isEnabled('temp_packages_acrobat_only_flow') &&
    PackagesEntitlements.hasDcOnlyEntitlement
  ) {
    // For Dc only org, only managed flow is shown for NAMED license package.
    pkgType = CREATE_PACKAGE_CONSTANTS.MANAGED_PACKAGE;
  }
  packageCreateObj.packageType = pkgType;
  pkgSessionSettings.packageType = pkgType;
};

/* eslint-disable max-statements, complexity -- Will be reduced when flags removed */
/**
 * @description Method to get/ update store member values based on flow to be initiated
 *
 * @param {Object} pkgSessionSettings shared object for create package flow session
 *
 * @returns {Object} updated packageCreateObj, packageTypeSelectionWizardSteps, pkgSessionSettings,
 * screenstate and showPackageTypeSelectionWizard,
 */
const getStoreValuesForFlowPkgCreate = (packageSessionSettings) => {
  let flow = FLOW_TYPES.COMPLETE_FLOW;
  let packageCreateObj = {
    bits: CREATE_PACKAGE_CONSTANTS.SIXTY_FOUR_BIT,
  };
  const pkgSessionSettings = {
    isTemplatizedPackageCreate: false,
  };
  // Find the type of flow to be initiated and update packageCreateObj and pkgSessionSettings appropriately
  if (PackagesEntitlements.hasDcOnlyEntitlement && !packageSessionSettings.hasFrlEntitlement) {
    if (feature.isEnabled('temp_packages_acrobat_only_flow')) {
      flow = FLOW_TYPES.NAMED_MANAGED_PACKAGE_FLOW;
      pkgSessionSettings.showAccInUI = false;
    } else {
      flow = FLOW_TYPES.SELF_SERVE_FLOW;
    }
  } else if (CreatePackageFactory.isFueNamedPackageCreate()) {
    if (PackagesEntitlements.hasDcOnlyEntitlement) {
      if (feature.isEnabled('temp_packages_acrobat_only_flow')) {
        flow = FLOW_TYPES.NAMED_MANAGED_PACKAGE_FLOW;
        pkgSessionSettings.showAccInUI = false;
      } else {
        flow = FLOW_TYPES.SELF_SERVE_FLOW;
      }
    } else {
      flow = FLOW_TYPES.CHOOSE_PACKAGE_FLOW;
    }
  } else if (CreatePackageFactory.isFueFrlPackageCreate()) {
    // Show acc in ui refers to showing adobe creative cloud in apps screen
    pkgSessionSettings.showAccInUI = false;
    packageCreateObj.rumEnabled = false;
    packageCreateObj.enableExtenstions = false;
    flow = FLOW_TYPES.FRL_FUE_FLOW;
  } else if (CreatePackageFactory.isFueNuellPackageCreate()) {
    packageCreateObj = {
      ...packageCreateObj,
      ...PACKAGE_CREATE_OBJ_NUELL_FUE,
    };
    pkgSessionSettings.showAccInUI = true;
    pkgSessionSettings.nextButtonEnabled = false;
    PackagesEntitlements.resetOffers();
    flow = FLOW_TYPES.NUELL_FUE_FLOW;
  } else if (
    packageSessionSettings.hasFrlEntitlement ||
    packageSessionSettings.hasNuellEntitlement
  ) {
    PackagesEntitlements.resetOffers();
    pkgSessionSettings.selectedFrlOffers = [];
    if (
      feature.isEnabled('temp_packages_acrobat_only_flow') &&
      PackagesEntitlements.hasDcOnlyEntitlement
    ) {
      pkgSessionSettings.showAccInUI = false;
    }
  } else {
    flow = FLOW_TYPES.CHOOSE_PACKAGE_FLOW;
  }
  const {
    currentScreen,
    packageType,
    packageTypeSelectionWizardSteps,
    packagingMode,
    screenstate,
    showPackageTypeSelectionWizard,
  } = STORE_VALUES_FOR_FLOW[flow];

  updatePackageType(packageType, pkgSessionSettings, packageCreateObj);
  pkgSessionSettings.packagingMode = packagingMode;
  pkgSessionSettings.currentScreen = currentScreen;
  pkgSessionSettings.template = {};

  return {
    packageCreateObj,
    packageTypeSelectionWizardSteps,
    pkgSessionSettings,
    screenstate,
    showPackageTypeSelectionWizard,
  };
};
/* eslint-enable max-statements, complexity -- Will be reduced when flags removed */

/**
 * @description Method to get/ update store member values when tempatlized create package flow
 *
 * @returns {Object} updated modalTitle, packageCreateObj, packageTypeSelectionWizardSteps, pkgSessionSettings,
 * screenstate, showPackageTypeSelectionWizard and template
 */
const getStoreValuesForTempPkgCreate = () => {
  let packageTypeSelectionWizardSteps = [SCREEN_NAME_CONSTANTS.SELF_SERVICE_SCREEN];
  let showPackageTypeSelectionWizard = true;
  const pkgSessionSettings = {
    currentScreen: SCREEN_NAME_CONSTANTS.SELF_SERVICE_SCREEN,
    isTemplatizedPackageCreate: true,
    packageType: CREATE_PACKAGE_CONSTANTS.SELF_SERVE_PACKAGE,
    packagingMode: CREATE_PACKAGE_CONSTANTS.NAMED_PACKAGE_TYPE,
    showAccInUI: CreatePackageFactory.isAccRequiredInTemplateView(),
    showExtraProductsInTemplates: CreatePackageFactory.shouldShowExtraProductsInTemplateView(),
    template: CreatePackageFactory.getPackageTemplate(),
  };

  const packageCreateObj = {
    bits: CREATE_PACKAGE_CONSTANTS.SIXTY_FOUR_BIT,
    packageName: pkgSessionSettings.template.name,
    packageType: CREATE_PACKAGE_CONSTANTS.SELF_SERVE_PACKAGE,
    platform: pkgSessionSettings.template.platform,
  };
  if (
    feature.isEnabled('temp_packages_acrobat_only_flow') ||
    !PackagesEntitlements.hasDcOnlyEntitlement ||
    CreatePackageFactory.isNonCCTemplate()
  ) {
    packageTypeSelectionWizardSteps = [];
    showPackageTypeSelectionWizard = false;
    packageCreateObj.packageType = CREATE_PACKAGE_CONSTANTS.MANAGED_PACKAGE;
    pkgSessionSettings.packageType = CREATE_PACKAGE_CONSTANTS.MANAGED_PACKAGE;
    pkgSessionSettings.currentScreen = SCREEN_NAME_CONSTANTS.SYSTEM_CONFIG_SCREEN;
  }

  return {
    modalTitle: translateString(
      {id: 'packages.createPackage.titleCustomized'},
      {
        templateName: packageCreateObj.packageName,
      }
    ),
    packageCreateObj,
    packageTypeSelectionWizardSteps,
    pkgSessionSettings,
    // There are 2 possible flows for tempatlized package create: Self service package or managed package flow,
    // both start at screen state 2
    screenstate: 2,
    showPackageTypeSelectionWizard,
  };
};

/**
 * @description Returns updated sti key
 *
 * @param {String} updateKey
 * @returns {String} updates sti key
 */
const getStiUpdateKey = (updateKey) => {
  const attrs = updateKey.split('/');
  return `${attrs[0]}/${attrs[1]}/${attrs[2]}`;
};

/**
 * @description if product is not STI, return its dependent STIs
 *
 * @param {Object} product
 * @returns {Set} dependent STIs
 */
const getDependentSTIs = (product) => {
  const dependentSTIsSet = new Set();
  if (!product.isSti() && product.getStiDependencyMap()) {
    Object.values(product.getStiDependencyMap()).forEach((updateKey) => {
      // always add the latest sti version for product. if update is not present, base sti will be added
      dependentSTIsSet.add(getStiUpdateKey(updateKey));
    });
  }

  return dependentSTIsSet;
};

/**
 * @description if product is not STI, return its dependent STIs from mac universal map
 *
 * @param {Object} product
 * @returns {Set} dependent STIs from mac universal map
 */
const getDependentMacUSTIs = (product) => {
  const dependentSTIsSet = new Set();
  if (!product.isSti() && product.getStiDependencyMapMacU()) {
    Object.values(product.getStiDependencyMapMacU()).forEach((updateKeyList) => {
      updateKeyList.forEach((updateKey) => {
        // always add the latest sti version for product. if update is not present, base sti will be added
        dependentSTIsSet.add(getStiUpdateKey(updateKey));
      });
    });
  }

  return dependentSTIsSet;
};

/**
 * @description Returns selected adobe product Ids
 *
 * @param {Array} adobeProductsUI array of products from which ids are to be extracted
 * @param {String} packagingMode packaging mode
 *
 * @returns {Object} selectedAdobeProductIDs, containsArchived
 */
const getSelectedAdobeProductIDs = (adobeProductsUI, packagingMode) => {
  let selectedAdobeProductIDs = [];
  let containsArchived = false;

  adobeProductsUI.forEach((product) => {
    // for DC only entitlement, add latest acrobat automatically
    if (
      feature.isDisabled('temp_packages_acrobat_only_flow') &&
      PackagesEntitlements.hasDcOnlyEntitlement &&
      product.sapCode === PRODUCT_SAPCODES.APRO &&
      packagingMode !== CREATE_PACKAGE_CONSTANTS.FRL_PACKAGE_TYPE &&
      !product.archive &&
      !CreatePackageFactory.isNonCCTemplate()
    ) {
      product.selected = true;
    }
    // if product is selected, add its key to the list
    if (product.isSelected() && product.getSapCode() !== KCCC_SAP_CODE) {
      selectedAdobeProductIDs.push(product.product_key);

      if (product.archive) {
        containsArchived = true;
      }
      // if product is not STI, add its dependent STIs
      selectedAdobeProductIDs = [
        ...selectedAdobeProductIDs,
        ...getDependentSTIs(product),
        ...getDependentMacUSTIs(product),
      ];
    }
  });

  return {containsArchived, selectedAdobeProductIDs: new Set(selectedAdobeProductIDs)};
};

/**
 * @description check if the particular FI has atleast one NGL enabled product
 * @param {String} fiCode fi code to be checked for
 * @param {Array} frlEnabledProducts list of products which are NGL enabled
 * @returns {Boolean} true or false base on the check
 */
function isApplicableFIForFRL(fiCode, frlEnabledProducts) {
  const sapCode = rootStore.packagesUiConstantsStore.getSapCodeFromFiCode(fiCode);

  // filter those offers which have atleast 1 packageable FRL enabled product
  return (
    frlEnabledProducts.some((item) => sapCode.includes(item.sapCode)) ||
    (sapCode.includes(COLD_FUSION_SAP_CODE) && feature.isEnabled('temp_packages_CF_ontron'))
  );
}

/**
 * @description Retrieve frl licenses from selectedFrlOffers
 *
 * @param {Array} selectedFrlOffers
 * @returns {Array} frl licenses from selectedFrlOffers
 */
const getFrlLicenses = (selectedFrlOffers = []) => {
  const licenses = [];
  selectedFrlOffers.forEach((offer) => {
    const frlLicense = {
      licenseFullName: offer.longName,
      licenseId: offer.id,
      licenseShortName: offer.shortName,
    };
    const fiMap = {};
    let addLicense = false;
    const frlEnabledProducts = PackagesAdobeProductsUi.getAllPackageableAdobeProducts().filter(
      (product) => product.frlEnabled
    );

    // include license information of all the offers selected
    offer.getPackageableFulfillableItems().forEach((fiJson) => {
      if (isApplicableFIForFRL(fiJson.code, frlEnabledProducts)) {
        fiMap[fiJson.code] = JSON.stringify(fiJson);

        addLicense = true;
      }
    });

    frlLicense.apps = fiMap;
    // we should add the license only if there is atleast one FI being added for this license
    if (addLicense) {
      licenses.push(frlLicense);
    }
  });
  return licenses;
};

/**
 * @description Returns package type and triggers ingest analytics
 *
 * @param {String} packageType
 * @returns {String} package type
 */
const getPkgTypeAndTriggerIngestAnalytics = (packageType) => {
  let pkgType, subCategory;
  switch (packageType) {
    case CREATE_PACKAGE_CONSTANTS.FRL_ONLINE_PACKAGE: {
      pkgType = PACKAGE_TYPE_CONSTANTS.FRL_HIGH_PRIVACY_ONLINE;
      subCategory = 'FRL:Online:Finalize';
      break;
    }
    case CREATE_PACKAGE_CONSTANTS.FRL_LAN_PACKAGE: {
      pkgType = PACKAGE_TYPE_CONSTANTS.FRL_LAN;
      subCategory = 'FRL:LAN:Finalize';
      break;
    }
    case CREATE_PACKAGE_CONSTANTS.FRL_ISOLATED_PACKAGE: {
      pkgType = PACKAGE_TYPE_CONSTANTS.FRL_ISOLATED;
      subCategory = 'FRL:Offline:Finalize';
      break;
    }
    case CREATE_PACKAGE_CONSTANTS.FRL_OFFLINE_PACKAGE: {
      pkgType = PACKAGE_TYPE_CONSTANTS.FRL_OFFLINE;
      subCategory = 'FRL:NewOffline:Finalize';
      break;
    }
    case CREATE_PACKAGE_CONSTANTS.NUELL_PACKAGE_MODE: {
      pkgType = PACKAGE_TYPE_CONSTANTS.NUELL;
      subCategory = 'SDL:Finalize';
      break;
    }
    default: {
      break;
    }
  }
  if (subCategory) {
    PackagesIngestAnalytics.triggerAnalytics({
      subCategory,
      subType: 'build-package',
      type: 'click',
    });
  }
  return pkgType;
};

/**
 * @description Checks if license file selected on frl package type
 *
 * @param {String} packageType
 * @param {Boolean} licenseFileSelected
 * @returns {Boolean} true, if license file selected on frl package type, else, false
 */
const isLicenseSelectedFrlPackageType = (packageType, licenseFileSelected) =>
  (packageType === CREATE_PACKAGE_CONSTANTS.FRL_ONLINE_PACKAGE ||
    packageType === CREATE_PACKAGE_CONSTANTS.FRL_LAN_PACKAGE ||
    packageType === CREATE_PACKAGE_CONSTANTS.FRL_OFFLINE_PACKAGE) &&
  licenseFileSelected;

/**
 * @description Trigger analytics when build package clicked
 *
 * @param {String} packageType
 * @param {String} packagingMode
 * @param {Boolean} selectedAdobeProductIds
 */
const sendIngestAnalyticsForCreatePackage = (
  packageType,
  packagingMode,
  selectedAdobeProductIds
) => {
  if (PackagesIngestAnalytics.shouldSendIngestAnalytics(packagingMode)) {
    PackagesIngestAnalytics.triggerAnalytics({
      content: selectedAdobeProductIds,
      subCategory: PackagesIngestAnalytics.getIngestSubcategory(packageType, 'Apps'),
      subType: 'apps',
      type: 'click',
    });
  }
};

/* eslint-disable complexity -- will be fixed when we remove feature flag */
/**
 * @description Method to get package parameters
 *
 * @param {Object} pkgSessionSettings shared object for create package flow session
 *
 * @returns {Object} package parameters
 */
const getPackageParameters = (pkgSessionSettings) => {
  const packageParams = {
    accDisabled: false,
  };
  const isDcOnlyEntitlement = PackagesEntitlements.hasDcOnlyEntitlement;
  if (
    pkgSessionSettings.packageType === CREATE_PACKAGE_CONSTANTS.SELF_SERVE_PACKAGE ||
    (feature.isEnabled('temp_packages_acrobat_only_flow') &&
      isDcOnlyEntitlement &&
      pkgSessionSettings.packageType === CREATE_PACKAGE_CONSTANTS.MANAGED_PACKAGE &&
      !CreatePackageFactory.isNonCCTemplate())
  ) {
    packageParams.appPanelEnabled = !isDcOnlyEntitlement;
    packageParams.accAdminPrivileged = !isDcOnlyEntitlement;
    packageParams.accDisableAutoAppUpdate = isDcOnlyEntitlement;
    packageParams.selfServePluginsEnabled = !isDcOnlyEntitlement;

    if (!isDcOnlyEntitlement) {
      packageParams.selfServePluginsEnabled =
        rootStore.organizationStore.activeOrg.marketSegment !==
        ORGANIZATION_MARKET_SEGMENT.EDUCATION;
      packageParams.rumEnabled = true;
      packageParams.selectedAdobeProductIds = [];
    }
  }
  // make acc disabled for DC packages and enabled for CC Packages
  if (
    pkgSessionSettings.packagingMode === CREATE_PACKAGE_CONSTANTS.FRL_PACKAGE_TYPE ||
    isDcOnlyEntitlement ||
    (pkgSessionSettings.isTemplatizedPackageCreate && !pkgSessionSettings.showAccInUI)
  ) {
    packageParams.accDisabled = true;
  }
  return packageParams;
};
/* eslint-enable complexity -- will be fixed when we remove feature flag */

/**
 * @description Method to get profile server url and activation type
 *
 * @param {Object} pkgSessionSettings shared object for create package flow session
 *
 * @returns {Object} frlOnlineActivationType, profileServerUrl
 */
const getProfileServerUrlAndActivationType = (pkgSessionSettings) => {
  let frlOnlineActivationType, profileServerUrl;
  if (pkgSessionSettings.enableCustomDNS) {
    profileServerUrl = customDnsUrlFormatted(pkgSessionSettings.customDNS.profileServerUrl);
  }

  if (pkgSessionSettings.adobeLicenseFixedDns) {
    // process and append the fixed dns activation
    if (!profileServerUrl) {
      profileServerUrl = '';
    }
    frlOnlineActivationType = CREATE_PACKAGE_CONSTANTS.FRL_FIXED_DNS;
  } else {
    // process and append the fixed ip activation
    if (!profileServerUrl) {
      profileServerUrl = rootStore.packagesUiConstantsStore.frlStaticDnsEndpoint;
    }
    frlOnlineActivationType = CREATE_PACKAGE_CONSTANTS.FRL_FIXED_IP;
  }
  return {frlOnlineActivationType, profileServerUrl};
};

/**
 * @description Method to get populated package job frl object
 *
 * @param {Object} pkgSessionSettings shared object for create package flow session
 *
 * @returns {Object} packageJobFrl
 */
const getPackageJobFrlObject = (pkgSessionSettings) => {
  const packageJobFrl = {};
  if (pkgSessionSettings.packageType === CREATE_PACKAGE_CONSTANTS.FRL_LAN_PACKAGE) {
    packageJobFrl.serverId = pkgSessionSettings.frlSelectedServer.serverId;
  }
  if (pkgSessionSettings.packageType === CREATE_PACKAGE_CONSTANTS.FRL_ISOLATED_PACKAGE) {
    packageJobFrl.challengeCodes = pkgSessionSettings.frlIsolatedMachineCodes;
  }
  if (pkgSessionSettings.packageType === CREATE_PACKAGE_CONSTANTS.FRL_ONLINE_PACKAGE) {
    const {frlOnlineActivationType, profileServerUrl} =
      getProfileServerUrlAndActivationType(pkgSessionSettings);
    packageJobFrl.profileServerUrl = profileServerUrl;
    packageJobFrl.frlOnlineActivationType = frlOnlineActivationType;
  }
  packageJobFrl.licenses = getFrlLicenses(pkgSessionSettings.selectedFrlOffers);
  return packageJobFrl;
};

/**
 * @description Method checks if package job frl should be populated
 *
 * @param {Object} pkgSessionSettings shared object for create package flow session
 *
 * @returns {Boolean} returns true if package job frl should be populated, else false
 */
const shouldPopulatePackageJobFrl = (pkgSessionSettings) =>
  // Return true if nuell package mode/ isLicenseSelectedFrlPackageType returns true/ if package type is frl isolated and activation codes populated
  pkgSessionSettings.packageType === CREATE_PACKAGE_CONSTANTS.NUELL_PACKAGE_MODE ||
  isLicenseSelectedFrlPackageType(
    pkgSessionSettings.packageType,
    pkgSessionSettings.licenseFileSelected
  ) ||
  (pkgSessionSettings.packageType === CREATE_PACKAGE_CONSTANTS.FRL_ISOLATED_PACKAGE &&
    pkgSessionSettings.frlIsolatedMachineCodes?.length > 0);

const FRL_PACKAGE_CREATE_OBJ = {
  accAdminPrivileged: false,
  accDisableAutoAppUpdate: true,
  appPanelEnabled: false,
  selfServePluginsEnabled: false,
  updatesEnabled: false,
};

/**
 * @description Method to get plugins data
 *
 * @param {Object} pkgSessionSettings shared object for create package flow session
 *
 * @returns {Array} plugins data
 */
const getPluginsData = (selectedPlugins) =>
  selectedPlugins.map((plugin) => ({
    extensionId: plugin.id,
    type: plugin.type,
    versionId: plugin.versionId,
    versionString: plugin.version,
  }));

/**
 * @description Method to check if the next button should be enabled on the apps selection screen
 *
 * @param {Array} adobeProductsUI array of products from which ids are to be extracted
 * @param {Object} pkgSessionSettings shared object for create package flow session
 * @param {Array} selectedProducts
 *
 * @returns {Boolean} nextButtonEnabled
 */
const getNextButtonStatusForAppsScreen = (
  adobeProductsUI,
  pkgSessionSettings,
  selectedProducts
) => {
  let nextButtonEnabled;
  if (
    pkgSessionSettings.packageType === CREATE_PACKAGE_CONSTANTS.FRL_ISOLATED_PACKAGE &&
    (!pkgSessionSettings.frlIsolatedMachineCodes ||
      pkgSessionSettings.frlIsolatedMachineCodes.length === 0)
  ) {
    nextButtonEnabled = adobeProductsUI.some(
      (product) => product.isVisible() && product.isSelected()
    );
  } else if (
    pkgSessionSettings.packageType === CREATE_PACKAGE_CONSTANTS.FRL_ONLINE_PACKAGE ||
    pkgSessionSettings.packageType === CREATE_PACKAGE_CONSTANTS.FRL_OFFLINE_PACKAGE ||
    pkgSessionSettings.packageType === CREATE_PACKAGE_CONSTANTS.FRL_LAN_PACKAGE ||
    pkgSessionSettings.packageType === CREATE_PACKAGE_CONSTANTS.MANAGED_PACKAGE
  ) {
    nextButtonEnabled = pkgSessionSettings.licenseFileSelected || selectedProducts.length > 0;
  } else {
    nextButtonEnabled = true;
  }

  return nextButtonEnabled;
};

/**
 * @description Method to check if the next button should be enabled on the entitlements screen
 *
 * @param {Array} selectedFrlOffers
 *
 * @returns {Boolean} nextButtonEnabled
 */
const getNextButtonStatusForEntitlementsScreen = (selectedFrlOffers = []) =>
  selectedFrlOffers.length > 0;

/**
 * @description Method to find the package type in case of light weight packages
 *
 * @param {Object} pkgSessionSettings shared object for create package flow session
 * @param {Object} packageCreateObj create package request data object
 *
 * @returns {Boolean} nextButtonEnabled
 */
const getPackageTypeForLightweightPackages = (pkgSessionSettings, packageCreateObj) => {
  if (!packageCreateObj.plugins || packageCreateObj.plugins.length === 0) {
    return PACKAGE_TYPE_CONSTANTS.SW_CONFIG_ONLY;
  }
  if (
    packageCreateObj.plugins?.length > 0 &&
    pkgSessionSettings.isManagementOptionsSwitchSelected
  ) {
    return PACKAGE_TYPE_CONSTANTS.PLUGIN_AND_SW_CONFIG;
  }

  return PACKAGE_TYPE_CONSTANTS.PLUGIN_ONLY;
};

export {
  getDependentMacUSTIs,
  getDependentSTIs,
  getSelectedAdobeProductIDs,
  addValuesToPkgSessionsSetting,
  customDnsUrlFormatted,
  filterProductsForFrlWorkflow,
  getPackageTypeForLightweightPackages,
  FRL_PACKAGE_CREATE_OBJ,
  getAdobeProductUIKeyMap,
  getFrlLicenses,
  getFrlPackageType,
  getNextButtonStatusForAppsScreen,
  getNextButtonStatusForEntitlementsScreen,
  getPackageFlow,
  getPackageJobFrlObject,
  getPackageParameters,
  getPkgTypeAndTriggerIngestAnalytics,
  getPluginsData,
  getProductVisiblity,
  getProfileServerUrlAndActivationType,
  getStoreValuesForFlowPkgCreate,
  getStoreValuesForTempPkgCreate,
  INGEST_ANALYTICS_VARS,
  initializePackageCreateObj,
  isLatestKCCC,
  isLicenseSelectedFrlPackageType,
  isPackageNameValid,
  LATEST_KCCC_VERSION,
  postPackageCreationRequest,
  scrollToInitialPosition,
  sendIngestAnalytics,
  sendIngestAnalyticsForCreatePackage,
  shouldPopulatePackageJobFrl,
  STORE_VALUES_FOR_FLOW,
  triggerAnalyticsAndResetOffers,
  pushProductToProductUi,
};
/* eslint-enable max-lines -- over line limit */
