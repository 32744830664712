import {CREATE_PACKAGE_CONSTANTS} from '../packagesConstants';
import PackagesEntitlements from '../services/PackagesEntitlementsService';

/**
 * @description Filter to be applied on each template in the list, based on which template is included in packagesAdobeTemplatesList
 *
 * @param {Object} template
 * @returns {Boolean} returns if template has certain entitlement based on type of template passed
 */
/* eslint-disable complexity -- checks required */
const templateFilter = (template = {}) => {
  if (template.isCaptivateTemplate && template.isPresenterTemplate) {
    return (
      PackagesEntitlements.hasCaptivateEntitlement && PackagesEntitlements.hasPresenterEntitlement
    );
  }
  if (template.isCaptivateTemplate) {
    return PackagesEntitlements.hasCaptivateEntitlement;
  }
  if (template.isPresenterTemplate) {
    return PackagesEntitlements.hasPresenterEntitlement;
  }
  if (template.isRoboHelpTemplate) {
    return PackagesEntitlements.hasRobohelpEntitlement;
  }
  if (template.isFramemakerTemplate) {
    return PackagesEntitlements.hasFramemakerEntitlement;
  }
  if (template.isTechCommSuiteTemplate) {
    return PackagesEntitlements.hasTcsEntitlement;
  }
  if (template.isRoboHelpServerTemplate) {
    return PackagesEntitlements.hasRoboHelpServerEntitlement;
  }
  if (template.isPhotoshopElementsTemplate) {
    return PackagesEntitlements.hasPhotoshopElementsEntitlement;
  }
  if (template.isPremierElementsTemplate) {
    return PackagesEntitlements.hasPremierElementsEntitlement;
  }
  if (template.isAcrobatOnlyTemplate) {
    return (
      PackagesEntitlements.hasDcEntitlement ||
      PackagesEntitlements.hasCcEntitlement ||
      PackagesEntitlements.hasTcsEntitlement
    );
  }
  return PackagesEntitlements.hasCcEntitlement;
};
/* eslint-enable complexity -- checks required */

/**
 * @description get template sapcodes list
 *
 * @param {Object} packagedProductsMap
 * @returns {Array}
 */
const getTemplateSapCodes = (packagedProductsMap) => {
  const uniqueSet = new Set();
  if (
    typeof packagedProductsMap !== 'object' ||
    !Array.isArray(Object.values(packagedProductsMap))
  ) {
    return [];
  }
  Object.values(packagedProductsMap).forEach((packagedProducts) =>
    packagedProducts.productList.forEach((product) => {
      if (product?.sapCode) {
        uniqueSet.add(product.sapCode.trim());
      }
    })
  );
  return [...uniqueSet];
};

/**
 * @description get template sapcodes list
 *
 * @param {Object} packagedProductsMap
 * @returns {Array}
 */
const getOsBasedPlatform = () =>
  navigator.platform.toLowerCase().includes(CREATE_PACKAGE_CONSTANTS.WIN_PLATFORM.toLowerCase())
    ? CREATE_PACKAGE_CONSTANTS.WIN_PLATFORM
    : CREATE_PACKAGE_CONSTANTS.MAC_PLATFORM;

export {templateFilter, getTemplateSapCodes, getOsBasedPlatform};
