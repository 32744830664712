import {InfoItemScorecard, PageInfoBar} from '@admin-tribe/acsc-ui';
import React from 'react';
import {useIntl} from 'react-intl';

import {useAccessRequestListContext} from 'features/settings/common/components/access-request-context/AccessRequestListContext';
import {useDomainsListContext} from 'features/settings/common/components/domains-list-context/DomainsListContext';
import {useTrusteeListContext} from 'features/settings/common/components/trustee-list-context/TrusteeListContext';
import {useDirectoryListContext} from 'features/settings/components/directories/directory-list/DirectoryListContext';

/**
 * Displays the scorecards for the Identity page
 */
const IdentityPageScorecards = () => {
  const intl = useIntl();

  const {
    state: {directoryCount},
  } = useDirectoryListContext();
  const {
    state: {domainCount},
  } = useDomainsListContext();
  const {
    state: {trustCount},
  } = useTrusteeListContext();
  const {
    state: {trustCount: accessRequestCount},
  } = useAccessRequestListContext();

  return (
    <PageInfoBar>
      <InfoItemScorecard
        label={intl.formatMessage({id: 'settings.identitySettings.scorecards.directories'})}
        value={directoryCount}
      />
      <InfoItemScorecard
        label={intl.formatMessage({id: 'settings.identitySettings.scorecards.domains'})}
        value={domainCount}
      />
      <InfoItemScorecard
        label={intl.formatMessage({id: 'settings.identitySettings.scorecards.trustees'})}
        value={trustCount}
      />
      <InfoItemScorecard
        label={intl.formatMessage({id: 'settings.identitySettings.scorecards.accessRequests'})}
        value={accessRequestCount}
      />
    </PageInfoBar>
  );
};

// This is needed to render the component correctly
IdentityPageScorecards.displayName = 'PageInfoBar';
export default IdentityPageScorecards;
