import {log} from '@admin-tribe/acsc';
import {OverlayWait} from '@admin-tribe/acsc-ui';
import {Flex, Grid, Text, repeat} from '@adobe/react-spectrum';
import cloneDeep from 'lodash/cloneDeep';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';

import {PLUGIN_SUPPORT_TYPE} from '../../../../packagesConstants';
import {useCreatePackageModalContext} from '../../CreatePackageModalContext';

import AllPluginsTopSection from './all-plugins-top-section/AllPluginsTopSection';
import PluginCardCheckbox from './plugin-card-checkbox/PluginCardCheckbox';
import PluginErrorSection from './plugin-error-section/PluginErrorSection';
import {
  fetchPlugins,
  getApplicableSelectedProductList,
  getCompatibleAppList,
  getUpdatedPlugins,
  getUpdatedSelectedPlugins,
  hasNoPluginSupport,
  haveIncompatiblePluginsBeenSelected,
  isPluginSelectionDisabled,
  showErrorSection,
  showFilterApiErrorSection,
} from './pluginSelectionPageUtils';
import ShowSelectedPluginsTopSection from './show-selected-plugins-top-section/ShowSelectedPluginsTopSection';

// Screen of Create Package flow to select plugins in managed package workflow
const PluginSelectionPage = observer(() => {
  const intl = useIntl();
  const {createPackageModalStore} = useCreatePackageModalContext();
  const [allPlugins, setAllPlugins] = useState([]);

  const [areIncompatiblePluginsSelected, setAreIncompatiblePluginsSelected] = useState(false);
  const areMaxPluginsSelected =
    createPackageModalStore.pkgSessionSettings.selectedPlugins.length >=
    rootStore.packagesUiConstantsStore.maxPackageablePluginCount;
  const [hasErrorInFilterApi, setHasErrorInFilterApi] = useState(false);
  const [hasErrorOnInitialLoad, setHasErrorOnInitialLoad] = useState(false);

  const [isFilterApiInProgress, setIsFilterApiInProgress] = useState(false);
  const [pluginSearchString, setPluginSearchString] = useState('');

  const [showInitialSpinner, setShowInitialSpinner] = useState(true);
  const [showCompatiblePluginsOnly, setShowCompatiblePluginsOnly] = useState(true);

  const [showSelectedPlugins, setShowSelectedPlugins] = useState(false);
  const isMountedRef = useRef(true);

  const applicableSelectedProductList = getApplicableSelectedProductList(
    createPackageModalStore.selectedProducts
  );

  // When Selected Plugins button pressed and Selected plugins present, show Selected Plugins
  // Else, show all plugins
  const pluginsToShow =
    showSelectedPlugins && createPackageModalStore.pkgSessionSettings.selectedPlugins.length > 0
      ? createPackageModalStore.pkgSessionSettings.selectedPlugins
      : allPlugins;

  // Set up isMounted
  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  // Fetch plugins on mount
  useEffect(() => {
    if (!createPackageModalStore.isColdFusionEntitlementSelected) {
      fetchExtensionsInCurrentContext(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run on mount
  }, []);

  //  Fetch plugins from backend and set state appropriately
  async function fetchExtensionsInCurrentContext(
    isInitialLoad,
    searchText = '',
    showCompatiblePlugins = true
  ) {
    // set hasError to false, since fetch data call has been invoked, screen should load
    setHasErrorInFilterApi(false);
    setIsFilterApiInProgress(!isInitialLoad);
    const compatibleAppList = getCompatibleAppList(
      applicableSelectedProductList,
      showCompatiblePlugins
    );
    let hasError = false;
    let plugins = [];
    try {
      // setting to empty array before loading, so that pluginsToShow becomes empty
      setAllPlugins(plugins);
      plugins = await fetchPlugins({
        compatibleAppList,
        packageCreateObj: createPackageModalStore.packageCreateObj,
        searchText,
        selectedPlugins: createPackageModalStore.pkgSessionSettings.selectedPlugins,
      });
    } catch (error) {
      hasError = true;
      log.error('Unable to get plugins for selection with error: ', error);
    } finally {
      if (isMountedRef.current) {
        setAllPlugins(plugins);
        setHasErrorOnInitialLoad(hasError && isInitialLoad);
        setHasErrorInFilterApi(hasError && !isInitialLoad);
        setShowInitialSpinner(false);
        setIsFilterApiInProgress(false);
        setAreIncompatiblePluginsSelected(
          haveIncompatiblePluginsBeenSelected(
            applicableSelectedProductList,
            createPackageModalStore.pkgSessionSettings.selectedPlugins
          )
        );
      }
    }
  }

  // Triggered on selecting plugin
  function selectPlugin(plugin) {
    if (isPluginSelectionDisabled(areMaxPluginsSelected, plugin)) {
      return;
    }
    const currentPlugin = cloneDeep(plugin);
    currentPlugin.selected = !currentPlugin.selected;

    createPackageModalStore.setPkgSessionSettingValues(
      'selectedPlugins',
      getUpdatedSelectedPlugins(
        currentPlugin,
        createPackageModalStore.pkgSessionSettings.selectedPlugins
      )
    );
    setAllPlugins(getUpdatedPlugins(allPlugins, currentPlugin));

    // If no plugin selected, setShowSelectedPlugins to false
    if (createPackageModalStore.pkgSessionSettings.selectedPlugins.length === 0) {
      setShowSelectedPlugins(false);
    }
    createPackageModalStore.setPackageCreateObjValues(
      'enableExtenstions',
      createPackageModalStore.pkgSessionSettings.selectedPlugins.length > 0
    );
    /**
     * set isManagementOptionsSelected to null whenever plugins are selected so that values of
     * showManagementOptions and isShowManagementOptionsSelected state varaibles in
     * ManagementOptionsPage component can be computed correctly when we move forward and
     * backward in Create package workflow
     */
    createPackageModalStore.setPkgSessionSettingValues('isManagementOptionsSelected', null);
    setAreIncompatiblePluginsSelected(
      haveIncompatiblePluginsBeenSelected(
        applicableSelectedProductList,
        createPackageModalStore.pkgSessionSettings.selectedPlugins
      )
    );
  }

  if (
    showErrorSection(
      hasErrorOnInitialLoad,
      createPackageModalStore.packageCreateObj.platform,
      createPackageModalStore.isColdFusionEntitlementSelected
    )
  ) {
    return (
      <PluginErrorSection
        hasError={hasErrorOnInitialLoad}
        hasNoPluginSupport={hasNoPluginSupport(
          createPackageModalStore.packageCreateObj.platform,
          createPackageModalStore.isColdFusionEntitlementSelected
        )}
        isInitialLoad
        supportType={PLUGIN_SUPPORT_TYPE.OFFER}
      />
    );
  }

  return (
    <OverlayWait
      isLoading={showInitialSpinner}
      loadingMessage={intl.formatMessage({
        id: 'packages.createPackage.choosePlugins.initialLoadTitle',
      })}
      size="L"
    >
      <Flex
        direction="column"
        gap="size-100"
        height="size-6000"
        marginTop="size-100"
        minWidth="calc(size-3600 * 3 + size-400 * 2)"
      >
        <Text>{intl.formatMessage({id: 'packages.createPackage.choosePlugins.titleDesc'})}</Text>

        {showSelectedPlugins ? (
          <ShowSelectedPluginsTopSection
            onPress={() => setShowSelectedPlugins(false)}
            selectedPluginsLength={
              createPackageModalStore.pkgSessionSettings.selectedPlugins.length
            }
          />
        ) : (
          <AllPluginsTopSection
            allPluginsLength={allPlugins.length}
            applicableSelectedProductListLength={applicableSelectedProductList.length}
            areIncompatiblePluginsSelected={areIncompatiblePluginsSelected}
            areMaxPluginsSelected={areMaxPluginsSelected}
            fetchExtensionsInCurrentContext={fetchExtensionsInCurrentContext}
            hasErrorInFilterApi={hasErrorInFilterApi}
            isFilterApiInProgress={isFilterApiInProgress}
            pluginSearchString={pluginSearchString}
            pluginsToShowLength={pluginsToShow.length}
            selectedPluginsLength={
              createPackageModalStore.pkgSessionSettings.selectedPlugins.length
            }
            setPluginSearchString={setPluginSearchString}
            setShowCompatiblePluginsOnly={setShowCompatiblePluginsOnly}
            setShowSelectedPlugins={setShowSelectedPlugins}
            showCompatiblePluginsOnly={showCompatiblePluginsOnly}
          />
        )}
        {!hasErrorInFilterApi && (
          <Flex justifyContent={isFilterApiInProgress ? 'center' : 'start'} minHeight="size-3600">
            <OverlayWait
              isLoading={isFilterApiInProgress}
              loadingMessage={intl.formatMessage({
                id: 'packages.createPackage.choosePlugins.searchLoadTitle',
              })}
              size="L"
            >
              <Grid
                columns={repeat(3, 'size-3600')}
                gap="size-200"
                justifyContent="center"
                marginTop="size-100"
              >
                {pluginsToShow.map((plugin) => (
                  <PluginCardCheckbox
                    key={plugin.id}
                    description={plugin.description}
                    iconUrl={plugin.iconUrl}
                    isCardDisabled={isPluginSelectionDisabled(areMaxPluginsSelected, plugin)}
                    isCardSelected={plugin.selected}
                    learnMoreUrl={plugin.learnMoreUrl}
                    onChange={() => selectPlugin(plugin)}
                    products={plugin.supportedProducts}
                    rating={plugin.rating}
                    title={plugin.name}
                  />
                ))}
              </Grid>
            </OverlayWait>
          </Flex>
        )}
        <PluginErrorSection
          hasError={showFilterApiErrorSection(hasErrorInFilterApi, isFilterApiInProgress)}
        />
      </Flex>
    </OverlayWait>
  );
});

PluginSelectionPage.propTypes = {};

export default PluginSelectionPage;
