import {log} from '@admin-tribe/acsc';
import {showError, showInfo, showSuccess} from '@admin-tribe/acsc-ui';
import {Button} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import {useParams} from 'react-router-dom';

import JobResultList from 'common/services/job-result-list/JobResultList';

import {getResultsFilename} from './jobResultsUtils';

/**
 * The TableActions for the JobResultsPage consist of a 'Download results' button.
 * The button is disabled when a download operation is in progress.
 */
const JobResultsTableActions = ({path}) => {
  const {jobId} = useParams();
  const intl = useIntl();
  const [downloadInProgress, setDownloadInProgress] = useState(false);
  const fileName = useRef(getResultsFilename({path})).current;

  // Download works even when there are no deficit items.
  const onPress = async () => {
    setDownloadInProgress(true);

    // CSV being prepared toast.
    showInfo(
      intl.formatMessage({
        id: 'bulkOperationResult.common.download.toast.beingPrepared2',
      })
    );

    // Get the CSV from the back-end and download it to the client.
    try {
      await JobResultList.export({
        fileName,
        jobId,
      });

      showSuccess(
        intl.formatMessage(
          {
            id: 'bulkOperationResult.common.download.toast.download.success',
          },
          {fileName}
        )
      );
    } catch (error_) {
      showError(
        intl.formatMessage({
          id: 'bulkOperationResult.common.download.toast.download.error',
        })
      );
      log.error(`JobResultsPage failed download. Error: ${error_}`);
    } finally {
      setDownloadInProgress(false);
    }
  };

  return (
    <Button
      data-testid="download-results-button"
      isDisabled={downloadInProgress}
      onPress={onPress}
      variant="accent"
    >
      {intl.formatMessage({id: 'users.jobResults.downloadResults.button'})}
    </Button>
  );
};

JobResultsTableActions.propTypes = {
  /**
   * The React router path used to reach this page.
   * For example, PATH_USERS_BULK_OPERATIONS_RESULTS.
   */
  path: PropTypes.string.isRequired,
};

export default JobResultsTableActions;
