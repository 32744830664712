import {log} from '@admin-tribe/acsc';
import React, {useEffect, useState} from 'react';
import {isRouteErrorResponse, useRouteError} from 'react-router-dom';

import GenericErrorBumper from 'shell/components/generic-error-bumper/GenericErrorBumper';
import NotFoundBumper from 'shell/components/not-found-bumper/NotFoundBumper';

/** Handles a page level error or route error */
const PageError = () => {
  const error = useRouteError();
  const [errorType, setErrorType] = useState(null);

  // Determines the error type and logs
  useEffect(() => {
    if (isRouteErrorResponse(error)) {
      // 403 indicates a failed access check
      if (error.status === 403) {
        setErrorType('noAccess');
        log.error('No Access error', error);
        return;
      }

      if (error.status === 404) {
        setErrorType('notFound');
        log.error('Not Found error', error);
        return;
      }
    }

    log.error('Page error', error);
  }, [error]);

  if (errorType === 'noAccess' || errorType === 'notFound') {
    return <NotFoundBumper />;
  }

  return <GenericErrorBumper />;
};

export default PageError;
