import binkyUI from '@admin-tribe/acsc-ui';
// eslint-disable-next-line @admin-tribe/admin-tribe/react-spectrum-prefer-v3 -- @neculaes needs to fix
import Checkbox from '@react/react-spectrum/Checkbox';
import {TH} from '@react/react-spectrum/Table';
import PropTypes from 'prop-types';
import React, {useContext} from 'react';
import {useIntl} from 'react-intl';

import ArrowDownSmall from './ArrowDownSmall.svg';
import TableConstants from './TableConstants';
import TableContext from './TableContext';

import './Table.pcss';

const ImageIcon = binkyUI.common.components.ImageIcon;

const SPACE_KEY = 32;

const ARIA_SORT = {
  [TableConstants.SORTING_DIRECTIONS.ASC]: 'ascending',
  [TableConstants.SORTING_DIRECTIONS.DESC]: 'descending',
};

const SortIcon = ({sort}) => {
  const intl = useIntl();
  const isSortReversed = sort === TableConstants.SORTING_DIRECTIONS.DESC;

  return (
    <ImageIcon
      alt={
        isSortReversed
          ? intl.formatMessage({id: 'common.table.arrowUpAlt'})
          : intl.formatMessage({id: 'common.table.arrowDownAlt'})
      }
      size="XXS"
      src={ArrowDownSmall}
      styleName={`sort-icon ${isSortReversed ? 'reversed' : ''}`}
    />
  );
};

SortIcon.propTypes = {
  sort: PropTypes.oneOf(Object.values(TableConstants.SORTING_DIRECTIONS)),
};

/**
 * @awaitingdeprecation - import TableStore from binky-ui and use Spectrum's TableView
 */
const HeaderCell = ({
  children,
  className,
  first,
  onSelect = () => {},
  onSort = () => {},
  selectable = true,
  selectionStatus,
  sort,
  sortable,
}) => {
  const tableContext = useContext(TableContext);

  // Events
  const onHeaderClick = () => {
    if (!sortable) {
      return;
    }

    onSort();
  };

  const onHeaderKeyDown = (e) => {
    if (e.keyCode === SPACE_KEY) {
      e.preventDefault();
      onHeaderClick();
    }
  };

  return (
    <TH
      aria-sort={sortable ? ARIA_SORT[sort] : null}
      className={className}
      onClick={onHeaderClick}
      onKeyDown={onHeaderKeyDown}
      styleName={`${sortable ? 'sortable' : ''}`}
      tabIndex="0"
    >
      <span
        data-testid="header-container"
        styleName={`${sortable ? 'sortable-header' : ''} ${
          tableContext.isTableSelectable && first ? 'selectable-cell' : ''
        }`}
      >
        {tableContext.isTableSelectable && first ? (
          <Checkbox
            checked={selectionStatus === TableConstants.SELECTION_STATUS.FULL}
            disabled={!selectable}
            indeterminate={selectionStatus === TableConstants.SELECTION_STATUS.PARTIAL}
            onChange={onSelect}
            styleName="checkbox"
          />
        ) : null}
        <span>
          {children}
          {sortable ? <SortIcon sort={sort} /> : null}
        </span>
      </span>
    </TH>
  );
};

HeaderCell.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  first: PropTypes.bool,
  onSelect: PropTypes.func,
  onSort: PropTypes.func,
  selectable: PropTypes.bool,
  selectionStatus: PropTypes.oneOf(Object.values(TableConstants.SELECTION_STATUS)),
  sort: PropTypes.oneOf(Object.values(TableConstants.SORTING_DIRECTIONS)),

  sortable: PropTypes.bool,
};

export default HeaderCell;
export {SortIcon};
