import {Consumable} from '@admin-tribe/acsc';
import {InfoBar} from '@admin-tribe/acsc-ui';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import ProductGroupProductList from 'core/services/product/product-group/product-list/ProductGroupProductList';
import {constructSummaryProductGroup} from 'features/products/components/product-group-page-info-bar/utils/productGroupPageInfoBarUtils';

/**
 * Info bar for product with scorecard data.
 */
const ProductGroupPageInfoBar = ({consumable, productGroup}) => {
  const intl = useIntl();

  const [infoCards, setInfoCards] = useState([]);

  // Get all scorecards to display
  useEffect(() => {
    const resolvedInfoCards = constructSummaryProductGroup({
      consumable,
      intl,
      productGroup,
    });

    setInfoCards(resolvedInfoCards);
  }, [consumable, intl, productGroup]);

  return infoCards.length > 0 && <InfoBar>{infoCards}</InfoBar>;
};

ProductGroupPageInfoBar.propTypes = {
  /**
   * The consumable summarization list for displaying consumable FI info
   */
  consumable: PropTypes.instanceOf(Consumable),
  /**
   * The product group product list for displaying non-consumable FIG info
   */
  productGroup: PropTypes.instanceOf(ProductGroupProductList).isRequired,
};

export default ProductGroupPageInfoBar;
