import {Locale, configStore, log} from '@admin-tribe/acsc';
import {OverlayWait} from '@admin-tribe/acsc-ui';
import {DialogContainer, View} from '@adobe/react-spectrum';
import {MessageType, SystemMessageSubType} from '@pandora/mini-app-messaging-types';
import {useIframeMessage} from '@pandora/react-app-context-iframe-message-handler';
import {useEnv} from '@pandora/react-env-provider';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';
import {FAILED, SUBMITTED} from 'features/mini-apps/MiniAppConstants';
import './MiniAppModalWrapper.pcss';

const MINI_APP_URL_ENV_PARAM = 'services.adminMiniApps';

/**
 * Setup Mini App URL
 * See Parameters PROVIDE LINK TO COMMON MINI APP PARAMS
 *
 * @param {String} baseUrl - The base URL for the mini app
 * @param {Object} params - Custom params to pass in
 * @param {String} theme - Allows to pass the parent theme to the Mini App, default to light as Onesie has hardcoded light theme, if default removed it will follow browser theme
 *
 * @returns configured Mini App URL
 */

const setUpMiniAppUrl = ({baseUrl, params, theme}) => {
  const {organizationStore} = rootStore;

  // Basic parameters
  const miniAppParams = {
    cli: configStore.get(MINI_APP_URL_ENV_PARAM).cli,
    csm: theme,
    // App Context Identifier as Mini App is loaded in iframe
    ctx: 'if',
    // Prepare for the Locale information, AEM takes locale in BCP-47 format
    lang: Locale.get().activeLanguageBCP47Code,
    orgId: organizationStore.activeOrgId,
    ...params,
    thm: theme,
  };

  if (typeof window !== 'undefined') {
    const urlParams = new URLSearchParams(window.location.search);

    // If a cli was provided on the URL, we let it override what's passed to the mini app
    if (urlParams.get('cli')) {
      miniAppParams.cli = urlParams.get('cli');
    }
  }

  return `${baseUrl}?${qs.stringify(miniAppParams, {skipNulls: true})}`;
};

/**
 * @description Represents an extensible wrapper for Mini Apps
 *
 * @param {String} baseUrl - The base URL to load for the mini app
 * @param {String} miniAppNames - A list of mini app names, which will be used when looking at dispatched iframe messages
 * @param {String} loadingMessage - Loading message
 * @param {Function} onClose - Optional callback to run when the modal is closed
 * @param {Function} onMessage - Optional handler that is called when a message is received for this mini app.
 * @param {Function} onSuccess - Optional callback to run when the modal completes successfully
 * @param {Object} params - Optional items to pass along to the mini app as query params
 */
const MiniAppModalWrapper = ({
  baseUrl,
  height = '80vh',
  loadingMessage,
  miniAppName,
  onClose,
  onMessage,
  params,
  theme = 'light',
  title,
  width = '80vw',
}) => {
  const intl = useIntl();
  const env = useEnv();

  // updates the miniapp name when the prop changes so the systemMessageHandler can
  // get the updated name
  const miniApp = useRef(miniAppName);

  if (miniApp.current !== miniAppName) {
    miniApp.current = miniAppName;
  }

  const miniAppUrl = useMemo(
    () => setUpMiniAppUrl({baseUrl, params: {env, ...params}, theme}),
    [baseUrl, env, params, theme]
  );

  const [modalHeight, setModalHeight] = useState(height);
  const [modalWidth, setModalWidth] = useState(width);

  // Identify Mini App is ready
  const [appReady, setAppReady] = useState(false);

  // Message handler to process common subtypes
  const systemMessageHandler = useCallback(
    ({data, subType}) => {
      // eslint-disable-next-line default-case -- no need to handle default case
      switch (subType) {
        case SystemMessageSubType.REPORT_STATE:
          if (data.params?.state === SUBMITTED) {
            setAppReady(false);
          } else if (data.params?.state === FAILED) {
            setAppReady(true);
          }
          break;

        case SystemMessageSubType.APP_CLOSED:
          // actions signifies there is more to do, so set loading state and let app wrappers decide what to do
          if (data?.actions) {
            setAppReady(false);
          }
          onClose?.(data);
          break;

        case SystemMessageSubType.APP_LOADED:
          setAppReady(true);
          break;

        case SystemMessageSubType.RESIZE:
          setModalHeight(data.modalHeight);
          setModalWidth(data.modalWidth);
          break;
      }
    },
    [onClose]
  );

  // Message handler to process the events from the Mini App
  const messageHandler = useCallback(
    ({app, data, subType, type}) => {
      // Ignore the message if it does not come from one of the expected Mini Apps
      if (miniApp.current !== app) return;

      log.info('Message from Mini App:', {data, subType, type});

      if (type === MessageType.SYSTEM) {
        systemMessageHandler({data, subType});
      }

      onMessage?.({app, data, subType, type});
    },
    [onMessage, systemMessageHandler]
  );

  // Consume message from Mini App iframe
  useIframeMessage(messageHandler);

  // Manually close modal when Escape key is pressed while wrapper is rendered
  useEffect(() => {
    const handleKeyDown = (keyDownEvent) => {
      // Once the iframe loads fully and has focus, it should contain its key inputs.
      // We shouldn't have to check if the keyDownEvent.target is inside or not.
      if (keyDownEvent.key === 'Escape') {
        onClose();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose]);
  // reusing addAdminsToProductModal loading message since there is no generic key and it is most closely related
  const defaultLoadingMessage = intl.formatMessage({id: 'common.addAdminsToProductModal.loading'});
  const currentLoadingMessage = loadingMessage || defaultLoadingMessage;
  return (
    <DialogContainer onDismiss={onClose}>
      <OverlayWait
        aria-label={currentLoadingMessage}
        isLoading={!appReady}
        loadingMessage={currentLoadingMessage}
        size="L"
      >
        <View aria-label={title} height={modalHeight} role="dialog" width={modalWidth}>
          <iframe src={miniAppUrl} styleName="mini-app-iframe" title={title} />
        </View>
      </OverlayWait>
    </DialogContainer>
  );
};

MiniAppModalWrapper.propTypes = {
  /**
   * The base URL to load for the mini app
   */
  baseUrl: PropTypes.string.isRequired,
  /**
   * The desired height of the modal. Default is 80% of the viewport height.
   */
  height: PropTypes.string,
  /**
   * Optional loading message.
   */
  loadingMessage: PropTypes.string,
  /**
   * The mini apps names, which will be used when looking at dispatched iframe messages
   */
  miniAppName: PropTypes.string.isRequired,
  /**
   * Optional handler that is called when the modal is closed.
   */
  onClose: PropTypes.func,
  /**
   * Optional handler that is called when a message is received for this mini app.
   */
  onMessage: PropTypes.func,
  /**
   * Optional items to pass along to the mini app as query params
   */
  // eslint-disable-next-line react/forbid-prop-types -- mini app params are custom to each one
  params: PropTypes.object,
  /**
   * Optional theme to pass to the mini app to ensure the same theme as parent
   */
  theme: PropTypes.string,
  /**
   * The title of the mini app in the iframe.
   */
  title: PropTypes.string.isRequired,
  /**
   * The desired width of the modal. Default is 80% of the viewport width.
   */
  width: PropTypes.string,
};

export default MiniAppModalWrapper;
