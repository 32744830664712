import {MemberList, jilProducts} from '@admin-tribe/acsc';

import ComplianceStatusUser from 'core/products/compliance-status-user/ComplianceStatusUser';

class ProductProvisioningStatusUserList extends MemberList {
  /**
   * @description  Retrieve list of users for a product who are expiring
   *   (cancelling and provisioned).
   * @param {String} options - options to get the provisioning status user list
   * @param {String} options.orgId - id of the ogranization
   * @param {Number} options.pageSize - the number of records to retrieve
   * @param {String} options.productId - productId to retrieve user list
   * @returns {ProductProvisioningStatusUserList} model of existing user list
   */
  static getExpiringUsers({orgId, pageSize, productId}) {
    const params = {
      delegationStatus: 'CANCELLING',
      orgId,
      pageSize,
      productId,
      provisioningStatus: 'PROVISIONED',
    };
    return this.get(params);
  }

  /**
   * @description Export the list of users that do not have access to
   *  the product (delegated, but not provisioned).
   * @param {Object} options - api parameters
   * @param {String} options.orgId - id of the ogranization
   * @param {String} options.productId - id of the product
   * @returns {Promise<Object>} - resolves to the response from the api
   */
  static getNoAccessUsersCSV({orgId, productId}) {
    const params = {
      delegationStatus: 'ACCEPTED',
      orgId,
      productId,
      provisioningStatus: 'UNPROVISIONED',
    };
    return new ProductProvisioningStatusUserList(params).exportCSV();
  }

  /**
   * @class
   * @description Constructor for ProductProvisioningStatusUserList model Objects.
   * @param {Object} options- options passed to the List constructor
   * @param {String} options.delegationStatus - The delegation_status query param passed to the GET
   * @param {String} options.licenseQualityType - license quality type for the user list
   * @param {String} options.orgId - id of the organization
   * @param {String} options.productId - id of the product
   * @param {String} options.provisioningStatus - The query param provisioning_status passed to the GET
   */
  constructor({delegationStatus, licenseQualityType, orgId, productId, provisioningStatus}) {
    super({
      isCacheable: true,
      itemClassRef: ComplianceStatusUser,
      modelCacheId: 'ProductProvisioningStatusUserList',
      orgId,
      productId,
      refreshResource:
        licenseQualityType === 'need_access'
          ? jilProducts.getProductLicenseQualityUsers
          : jilProducts.getProductProvisioningStatusUsers,
    });

    this.exportParams = {orgId, productId};
    this.productId = productId;
    this.delegationStatus = delegationStatus;
    this.provisioningStatus = provisioningStatus;
  }

  /**
   * @description Export the user csv for a given payment status   *
   * @returns {Promise} promise - resolved when the csv is downloaded
   */
  exportCSV() {
    return jilProducts.exportProductProvisioningStatusUsers(this.exportParams);
  }

  /**
   * @description Get params for api call to fetch list.
   *
   * @returns {Object} params to be passed to query
   */
  getQueryParams() {
    return {
      ...super.getQueryParams(),
      delegation_status: this.delegationStatus,
      productId: this.productId,
      provisioning_status: this.provisioningStatus,
    };
  }
}

export default ProductProvisioningStatusUserList;
