import binkyUI, {OverlayWait} from '@admin-tribe/acsc-ui';
import {
  Button,
  ButtonGroup,
  Content,
  Divider,
  Heading,
  Provider,
  View,
  useDialogContainer,
} from '@adobe/react-spectrum';
import {OptionSelector} from '@pandora/react-option-selector';
import Provider2 from '@react/react-spectrum/Provider';
import {useId} from '@react-aria/utils';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {FormattedMessage} from 'react-intl';

import GenericLogo from 'features/settings/assets/add-domain-logo.svg';
import AzureLogo from 'features/settings/assets/azure-logo.svg';
import GoogleLogo from 'features/settings/assets/google-logo.svg';
import OptionSelectorWrapper from 'features/settings/common/components/option-selector-wrapper/OptionSelectorWrapper';
import DirectoryDomainSourceCard from 'features/settings/components/add-directory-domain-modal/DirectoryDomainSourceCard';
import ExternalAuthService, {
  AZURE_SCOPES,
  GOOGLE_SCOPES,
} from 'features/settings/services/ExternalAuthService';

const ModalDialog = binkyUI.common.components.modal.ModalDialog;

const SELECTABLE_DOMAIN_SOURCES = {
  AZURE: {
    description: 'settings.directoryDomains.sources.azureDescription',
    goUrl: 'add_azure_oidc_prod',
    id: 'azure-card',
    learnMore: 'settings.directoryDomains.sources.learnMoreAzure',
    logo: AzureLogo,
    title: 'settings.directoryDomains.sources.azureTitle',
    type: 'AZURE',
  },
  GOOGLE: {
    description: 'settings.directoryDomains.sources.googleDescription2',
    goUrl: 'setup_google_sso_prod',
    id: 'google-card',
    learnMore: 'settings.directoryDomains.sources.googleGoUrl2',
    logo: GoogleLogo,
    title: 'settings.directoryDomains.sources.googleTitle',
    type: 'GOOGLE',
  },
  // eslint-disable-next-line sort-keys -- for iterating in order
  DNS: {
    description: 'settings.directoryDomains.sources.dnsDescription',
    goUrl: 'add_generic_domain_prod',
    id: 'dns-card',
    learnMore: 'settings.directoryDomains.sources.learnMoreDns',
    logo: GenericLogo,
    title: 'settings.directoryDomains.sources.dnsTitle',
    type: 'DNS',
  },
};

const MODAL_ID = 'add-directory-domain-modal';

const AddDirectoryDomainModal = ({onAddManualDomainSelected}) => {
  const [selectedCard, setSelectedCard] = useState();
  const [nextButtonLabel, setNextButtonLabel] = useState('settings.directoryDomains.next');
  const [isLoading, setIsLoading] = useState(false);
  const dialog = useDialogContainer();
  const modalHeadingId = useId();

  const handleCardClick = (type) => {
    setSelectedCard(type);
    switch (type) {
      case SELECTABLE_DOMAIN_SOURCES.AZURE.type:
        setNextButtonLabel('settings.directoryDomains.loginToAzure');
        break;
      case SELECTABLE_DOMAIN_SOURCES.GOOGLE.type:
        setNextButtonLabel('settings.directoryDomains.loginToGoogle');
        break;
      case SELECTABLE_DOMAIN_SOURCES.DNS.type:
        setNextButtonLabel('settings.directoryDomains.next');
        break;
      // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- Ignored the default as an unknown card would never be visible
      // istanbul ignore next
      default:
        break;
    }
  };

  const onNextClick = () => {
    setIsLoading(true);

    if (selectedCard === SELECTABLE_DOMAIN_SOURCES.DNS.type) {
      onAddManualDomainSelected();
    }

    if (selectedCard === SELECTABLE_DOMAIN_SOURCES.AZURE.type) {
      ExternalAuthService.startAzureAuthentication(
        [
          AZURE_SCOPES.OPENID,
          AZURE_SCOPES.PROFILE,
          AZURE_SCOPES.EMAIL,
          AZURE_SCOPES.USER_READ,
        ].join(' ')
      );
    }
    if (selectedCard === SELECTABLE_DOMAIN_SOURCES.GOOGLE.type) {
      ExternalAuthService.startGoogleAuthentication(
        [
          GOOGLE_SCOPES.OPENID,
          GOOGLE_SCOPES.EMAIL,
          GOOGLE_SCOPES.PROFILE,
          GOOGLE_SCOPES.DOMAIN_READONLY,
        ].join(' ')
      );
    }
    return false;
  };

  return (
    <ModalDialog height="100vh" id={MODAL_ID}>
      <Heading id={modalHeadingId} level={2}>
        <FormattedMessage id="settings.directoryDomains.addDomainModal.title" />
      </Heading>
      <Divider />
      <Content>
        {/* This is needed for the v2 components to render properly */}
        <Provider2>
          <Provider>
            <OverlayWait isLoading={isLoading} showContent size="M">
              <FormattedMessage id="settings.directoryDomains.addDomainModalDescription" />
              <OptionSelectorWrapper>
                <OptionSelector
                  data={Object.values(SELECTABLE_DOMAIN_SOURCES)}
                  options={{
                    on_selection_change: (event) =>
                      event.selected && handleCardClick(event.data.type),
                    preventDeselection: true,
                  }}
                  react_function={(props) => (
                    <View>
                      <DirectoryDomainSourceCard {...props} />
                    </View>
                  )}
                />
              </OptionSelectorWrapper>
            </OverlayWait>
          </Provider>
        </Provider2>
      </Content>
      <ButtonGroup>
        <Button
          data-test-id="cancel-btn"
          // eslint-disable-next-line react/jsx-handler-names -- erroneous reporting
          onPress={dialog.dismiss}
          variant="primary"
        >
          <FormattedMessage id="settings.directoryDomains.cancel" />
        </Button>
        <Button
          data-test-id="next-btn"
          isDisabled={isLoading || !selectedCard}
          onPress={onNextClick}
          variant="accent"
        >
          <FormattedMessage id={nextButtonLabel} />
        </Button>
      </ButtonGroup>
    </ModalDialog>
  );
};

AddDirectoryDomainModal.propTypes = {
  onAddManualDomainSelected: PropTypes.func,
};

export default AddDirectoryDomainModal;
export {SELECTABLE_DOMAIN_SOURCES};
