import {
  DIRECTORY_DOMAIN_ENFORCEMENT_STATUS,
  OrganizationEncryptionMigration,
  jilDirectories,
} from '@admin-tribe/acsc';
import {
  GoUrl,
  showError as showErrorToast,
  showSuccess as showSuccessToast,
} from '@admin-tribe/acsc-ui';
import {
  ComboBox,
  Flex,
  Heading,
  Item,
  ListView,
  Switch,
  View,
  useDialogContainer,
} from '@adobe/react-spectrum';
import {
  ModalButtonGroup,
  ModalContent,
  ModalDialog,
  ModalHeading,
} from '@pandora/react-modal-dialog';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import useDirectoryList from 'common/hooks/api/useDirectoryList';
import rootStore from 'core/RootStore';
import DomainEnforcementInlineWarnings from 'features/settings/components/directory/domain-enforcement/components/DomainEnforcementInlineWarnings';
import TransferDomainErrorModal from 'features/settings/components/domains/transfer-domain-modal/TransferDomainErrorModal';
import TransferDomainModalPageBanner from 'features/settings/components/domains/transfer-domain-modal/TransferDomainModalPageBanner';
import useDomainTransferState from 'features/settings/components/domains/transfer-domain-modal/useDomainTransferState';
import useDomainList from 'features/settings/hooks/api/useDomainList';
import useTrustList from 'features/settings/hooks/api/useTrustList';

const MODAL_ID = 'transfer-domain-modal';

const TransferDomainModal = ({domains, onDomainsTransferred}) => {
  const intl = useIntl();
  const dialog = useDialogContainer();

  const {getDirectoryList} = useDirectoryList();
  const {getTrustList} = useTrustList();
  const {linkDomainsToDirectory} = useDomainList();

  // this needs to be removed when we remove the temp_org_asset_encryption_migrated feature flag
  const [orgEncryptionMigrationInfo, setOrgEncryptionMigrationInfo] = useState({
    encryptedDirectories: [],
    isMigrated: true,
  });

  const [directories, setDirectories] = useState([]);
  const [trusts, setTrusts] = useState([]);

  const {
    eligibleDirectories,
    eligibleDomains,
    errorState,
    ineligibleDomains,
    transferredDomains,
    setTransferredDomains,
  } = useDomainTransferState({
    directories,
    domains,
    encryptedDirectories: orgEncryptionMigrationInfo.encryptedDirectories,
    trusts,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [notifyAdmins, setNotifyAdmins] = useState(true);
  const [selectedDirectoryId, setSelectedDirectoryId] = useState(null);
  const [selectedDirectoryDeStatus, setSelectedDirectoryDeStatus] = useState(null);

  const hasAnyDomainWithDomainEnforcementEnabled = domains.some(
    (domain) =>
      domain.domainEnforcement?.state === DIRECTORY_DOMAIN_ENFORCEMENT_STATUS.ACTIVATED ||
      domain.domainEnforcement?.state === DIRECTORY_DOMAIN_ENFORCEMENT_STATUS.ENFORCED
  );

  const handleOnDirectorySelectionChange = (directoryId) => {
    setSelectedDirectoryId(directoryId);

    const directoryStatus = directories.find((directory) => directory.id === directoryId)
      ?.domainEnforcement?.state;

    setSelectedDirectoryDeStatus(directoryStatus);
  };

  const onSave = async () => {
    setIsLoading(true);

    try {
      await jilDirectories.updateDirectory({
        directoryData: {notifyAdminsDomainMigrationComplete: notifyAdmins},
        directoryId: selectedDirectoryId,
        orgId: rootStore.organizationStore.activeOrgId,
      });

      const {failedToLink, linked} = await linkDomainsToDirectory({
        directoryId: selectedDirectoryId,
        domains: eligibleDomains,
      });

      if (linked.length > 0) {
        onDomainsTransferred?.();

        showSuccessToast(
          intl.formatMessage({
            id: notifyAdmins
              ? 'settings.transferDomainModal.toasts.successWithEmailEnabled'
              : 'settings.transferDomainModal.toasts.successWithEmailDisabled',
          })
        );
      }

      if (failedToLink.length > 0) {
        setTransferredDomains(linked); // remove success cases from the eligible domains list
        showErrorToast(
          intl.formatMessage(
            {id: 'settings.transferDomainModal.toasts.errorMoving.partial'},
            {count: eligibleDomains.length, failedCount: failedToLink.length}
          )
        );
      } else {
        dialog.dismiss();
      }
    } catch {
      showErrorToast(intl.formatMessage({id: 'settings.transferDomainModal.toasts.errorMoving'}));
    } finally {
      setIsLoading(false);
    }
  };

  // initial load
  useEffect(() => {
    const loadDirectoriesAndTrusts = async () => {
      setIsLoading(true);

      try {
        const [directoriesResponse, trustsResponse, orgEncryptionMigrationData] = await Promise.all(
          [
            getDirectoryList({page: null}),
            getTrustList({page: null}),
            OrganizationEncryptionMigration.get({
              orgId: rootStore.organizationStore.activeOrgId,
            }),
          ]
        );

        setDirectories(directoriesResponse.data);
        setTrusts(trustsResponse.data);
        setOrgEncryptionMigrationInfo(orgEncryptionMigrationData);
      } catch (error) {
        showErrorToast(
          intl.formatMessage({
            id: 'settings.transferDomainModal.toasts.errorLoading',
          })
        );

        dialog.dismiss();
      } finally {
        setIsLoading(false);
      }
    };

    loadDirectoriesAndTrusts();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we only want this to run once
  }, []);

  if (errorState) return <TransferDomainErrorModal count={domains.length} error={errorState} />;

  return (
    <ModalDialog id={MODAL_ID} isLoading={isLoading}>
      <ModalHeading>
        <FormattedMessage id="settings.transferDomainModal.title" />
      </ModalHeading>
      <ModalContent>
        <TransferDomainModalPageBanner
          count={eligibleDomains.length}
          isError={transferredDomains.length > 0}
        />

        <Heading id="select-directory-label" level={3}>
          <FormattedMessage id="settings.transferDomainModal.selectDirectory" />
        </Heading>
        <Flex direction="column" gap="size-100">
          <View>
            <FormattedMessage
              id={
                orgEncryptionMigrationInfo.isMigrated
                  ? 'settings.transferDomainModal.trustedDomainsCannotBeMoved'
                  : 'settings.transferDomainModal.cannotMoveToEncryptedDirectories'
              }
              values={{
                goUrl: (url) => <GoUrl name="aac_consolidate_domain">{url}</GoUrl>,
              }}
            />
          </View>
          <ComboBox
            aria-labelledby="select-directory-label"
            defaultItems={eligibleDirectories}
            isDisabled={eligibleDirectories.length === 0}
            onSelectionChange={handleOnDirectorySelectionChange}
            width="size-3400"
          >
            {(item) => <Item>{item.name}</Item>}
          </ComboBox>
          <DomainEnforcementInlineWarnings
            activatedTextKey="settings.transferDomainModal.warning.domainEnforcement"
            deactivatedToActivatedTextKey="settings.transferDomainModal.warning.deactivatedToActivated"
            enforcedTextKey="settings.transferDomainModal.warning.requireEmailChange"
            hasAnyDomainWithDomainEnforcementEnabled={hasAnyDomainWithDomainEnforcementEnabled}
            status={selectedDirectoryDeStatus}
          />
        </Flex>

        {ineligibleDomains.length > 0 && (
          <>
            <Heading data-testid="domains-cannot-be-transferred" level={3}>
              <FormattedMessage id="settings.transferDomainModal.domainsThatCannotBeMoved" />
            </Heading>
            <FormattedMessage
              id="settings.transferDomainModal.cannotMoveTrustedOrEncryptedDomains"
              values={{
                goUrl: (url) => <GoUrl name="aac_consolidate_domain">{url}</GoUrl>,
              }}
            />
            <ListView items={ineligibleDomains} marginTop="size-100">
              {(item) => <Item>{item.domainName}</Item>}
            </ListView>
          </>
        )}

        <Heading data-testid="domains-will-be-transferred" level={3}>
          <FormattedMessage id="settings.transferDomainModal.domainsThatWillBeMoved" />
        </Heading>
        <ListView items={eligibleDomains}>{(item) => <Item>{item.domainName}</Item>}</ListView>

        <Heading level={3}>
          <FormattedMessage id="settings.transferDomainModal.getNotifiedWhenMigrationIsComplete" />
        </Heading>
        <Flex alignItems="center" gap="size-200">
          <FormattedMessage id="settings.transferDomainModal.adminsWillReceiveAnEmail" />
          <Switch isEmphasized isSelected={notifyAdmins} onChange={setNotifyAdmins} />
        </Flex>
      </ModalContent>

      <ModalButtonGroup
        cancelLabel={intl.formatMessage({id: 'common.modal.buttons.cancel'})}
        ctaLabel={intl.formatMessage({id: 'settings.transferDomainModal.transfer'})}
        isCtaDisabled={isLoading || !selectedDirectoryId}
        onCancel={() => dialog.dismiss()}
        onCta={onSave}
      />
    </ModalDialog>
  );
};

TransferDomainModal.propTypes = {
  /** The selected domains that we want to attempt to transfer. See DomainEntity.js */
  domains: PropTypes.arrayOf(
    PropTypes.shape({
      directoryId: PropTypes.string,
      domainName: PropTypes.string,
    })
  ).isRequired,
  /** Callback invoked after at least one selected domains is successfully transferred */
  onDomainsTransferred: PropTypes.func,
};
export default TransferDomainModal;
