import {feature} from '@admin-tribe/acsc';

import {
  CONTRACT_RENEWAL_POINT_FOR_DIRECT_CONTRACT,
  RENEWAL_WINDOW_MARKERS,
} from './AutoRenewalConstants';

const {T, T3, T30, T45, T90} = RENEWAL_WINDOW_MARKERS;
/**
 * @description Method to get the current point in the renewal window (or
 *     outside of it), so we can provide custom messaging to the user. This
 *     caters is determined by the offset from the anniversary date.
 *
 * @param {Contract} contract - The contract that you want to determine the
 *     current point in the renewal window for
 * @returns {string} The current point in the renewal window, 'NA', 'PRE_RENEWAL_WINDOW',
 * 'IN_RENEWAL_WINDOW', 'POST_RENEWAL_WINDOW'.
 */
function getCurrentPointInRenewalInContract(contract) {
  return getCurrentPointInRenewal(
    contract.getOffsetFromAnniversaryDate(),
    contract.isOptInSelfServiceAvailable()
  );
}

/**
 * @description Method to get the current point in the renewal window (or outside of it), so we
 * can provide custom messaging to the user. This caters is
 *
 * If Auto Renewal Self Service is not supported (default):
 *
 * before T-45 (NA),
 * T-45 to T-30 (PRE_RENEWAL_WINDOW),
 * T-30 to T (IN_RENEWAL_WINDOW), and then
 * post T (POST_RENEWAL_WINDOW).
 *
 * If Auto Renewal Self Service is supported:
 *
 * before T-45 (NA),
 * T-45 to T-3 (PRE_RENEWAL_WINDOW),
 * T-3 to T (IN_RENEWAL_WINDOW), and then
 * post T (POST_RENEWAL_WINDOW).
 *
 * @param {Object} overview - The renewal overview as calculated in
 * contractAndProductsToOverview in the contract compliance service, that you want to determine
 * the current point in the renewal window for
 * @returns {string} The current point in the renewal window, 'NA', 'PRE_RENEWAL_WINDOW',
 * 'IN_RENEWAL_WINDOW', 'POST_RENEWAL_WINDOW'.
 */
function getCurrentPointInRenewalInOverview(overview) {
  return getCurrentPointInRenewal(
    overview.offsetFromAnniversaryDate,
    overview.isOptInSelfServiceAvailable
  );
}

function getCurrentPointInRenewal(offset, isOptInSelfServiceAvailable) {
  // If auto renewal self service is not supported, then the renewal window starts at T30, otherwise it starts at T3
  const renewalWindowStartOffset = isAutoRenewalSelfServiceSupported(isOptInSelfServiceAvailable)
    ? T3
    : T30;

  // If offset > T3 and optInSelfServiceAvailable = true, show the opt-in self service global banner
  if (offset > T3 && offset <= T90 && isOptInSelfServiceAvailable) {
    return CONTRACT_RENEWAL_POINT_FOR_DIRECT_CONTRACT.PRE_RENEWAL_WINDOW_OPT_IN_SELF_SERVICE;
  }
  if (offset < T) {
    return CONTRACT_RENEWAL_POINT_FOR_DIRECT_CONTRACT.POST_RENEWAL_WINDOW;
  }
  if (offset >= T && offset <= renewalWindowStartOffset) {
    return CONTRACT_RENEWAL_POINT_FOR_DIRECT_CONTRACT.IN_RENEWAL_WINDOW;
  }
  if (offset <= T45) {
    return CONTRACT_RENEWAL_POINT_FOR_DIRECT_CONTRACT.PRE_RENEWAL_WINDOW;
  }

  return CONTRACT_RENEWAL_POINT_FOR_DIRECT_CONTRACT.NA;
}

/**
 * @description Method to determine if auto renewal self service is supported for the contract or not
 * @param {boolean} optInSelfServiceAvailable - is opt in self service is available or not
 * @returns {boolean} - true if auto renewal self service is supported
 */
function isAutoRenewalSelfServiceSupported(optInSelfServiceAvailable) {
  return feature.isEnabled('auto_renewal_self_service') && optInSelfServiceAvailable;
}

export {
  getCurrentPointInRenewalInContract,
  getCurrentPointInRenewalInOverview,
  isAutoRenewalSelfServiceSupported,
};
