import {AcceptedUserList, AlphaListState} from '@admin-tribe/acsc';
import {useAsyncModel} from '@pandora/react-async-model';
import {useCallback} from 'react';

/**
 * Loads the AcceptedUserList model.
 *
 * @param {String} clientId - the client id of the app integration
 * @param {String} orgId - the org id of the app integration
 * @returns {Object} Object with state variables
 * @property {AcceptedUserList} acceptedUserList - the AcceptedUserList model
 * @property {Boolean} isLoading - whether the data is being loaded
 * @property {Object} error - the error is loading fails
 * @property {Function} refreshList - the refresh function to update the acceptedUserList state.
 */
const useAcceptedUserList = ({clientId, orgId}) => {
  const loadAcceptedUserList = useCallback(
    () =>
      AcceptedUserList.get({clientId, orgId, state: new AlphaListState({cacheNextTokens: true})}),
    [clientId, orgId]
  );

  const {
    error,
    isLoading,
    model: acceptedUserList,
    updateModel,
  } = useAsyncModel({loadFn: loadAcceptedUserList, modelLogId: 'useAcceptedUserList'});

  const refreshList = useCallback(async () => {
    await updateModel(() => acceptedUserList.refresh());
  }, [acceptedUserList, updateModel]);

  return {acceptedUserList, error, isLoading, refreshList};
};

export default useAcceptedUserList;
