import {eventBus, log, navBus} from '@admin-tribe/acsc';
import {showError, showSuccess, translateString} from '@admin-tribe/acsc-ui';
import axios from 'axios';
import {generatePath} from 'react-router-dom';

import rootStore from 'core/RootStore';

import {
  getAuthReportUploadProgress,
  getAuthReportUploadUrl,
  postAuthReportUploadSuccess,
  postServerCreateRequest,
  reauthorizeServer,
  updateServer,
} from '../api/tronData';
import {CREATE_SERVER, SERVERS_NEXT_STEP_BANNER, SERVER_API_MESSAGE} from '../packagesConstants';
import {PATH_PACKAGES_SERVERS} from '../routing/packagesPaths';

import LanServers from './PackagesLanServersService';

class CreateServerService {
  /**
   * @description Method to post the server creation request
   * @param {Object} serverCreateData server create data object
   * @returns {Promise} Promise indicating completion of the API
   */
  static async postServerCreationRequest(serverCreateData) {
    try {
      const response = await postServerCreateRequest(serverCreateData);
      if (response.data === SERVER_API_MESSAGE.SUCCESS) {
        showSuccess(translateString({id: 'packages.alerts.createServer.serverCreatedSuccess'}));
        // TODO: Trigger ingest event
        this.reloadServersTab();
        setTimeout(() => {
          eventBus.emit(SERVERS_NEXT_STEP_BANNER.EVENT, SERVERS_NEXT_STEP_BANNER.CREATE);
        }, 550);
        return;
      }
      throw new Error(response.data);
    } catch (error) {
      log.error('Create server API failure.', error);
      showError(translateString({id: 'packages.alerts.createServer.serverCreatedError'}));
      throw error;
    }
  }

  /**
   * @description Method to post the server edit request
   * @param {Object} serverCreateData server create data object
   * @param {String} id the ID of server to edit
   * @returns {Promise} Promise indicating completion of the API
   */
  static async postServerEditRequest(serverCreateData, id) {
    try {
      const response = await updateServer(id, serverCreateData);
      if (response.data === SERVER_API_MESSAGE.SUCCESS) {
        showSuccess(translateString({id: 'packages.alerts.createServer.serverEditSuccess'}));
        // TODO: Trigger ingest event
        this.reloadServersTab();
        eventBus.emit(SERVERS_NEXT_STEP_BANNER.EVENT, SERVERS_NEXT_STEP_BANNER.EDIT);
        return;
      }
      throw new Error(response.data);
    } catch (error) {
      log.error('Edit server API failure.', error);
      showError(translateString({id: 'packages.alerts.createServer.serverEditError'}));
      throw error;
    }
  }

  /**
   * @description Method to post the server reauthorize request
   * @param {Object} serverCreateData server create data object
   * @param {String} id the ID of server to reauthorize
   * @returns {Promise} Promise indicating completion of the API
   */
  static async postServerReauthorizeRequest(serverCreateData, id) {
    try {
      const response = await reauthorizeServer(id, serverCreateData);
      if (response.data === SERVER_API_MESSAGE.SUCCESS) {
        showSuccess(
          translateString(
            {id: 'packages.alerts.createServer.serverReauthorizeSuccess'},
            {serverName: serverCreateData.displayName}
          )
        );
        // TODO: Trigger ingest event
        this.reloadServersTab();
        eventBus.emit(SERVERS_NEXT_STEP_BANNER.EVENT, SERVERS_NEXT_STEP_BANNER.REAUTH);
        return;
      }
      throw new Error(response.data);
    } catch (error) {
      log.error('Reauthorize server API failure.', error);
      showError(translateString({id: 'packages.alerts.createServer.serverReauthorizeError'}));
      throw error;
    }
  }

  /**
   * @description Method to cancel all further file upload operations
   */
  static cancelUploadOperations() {
    this.isUploadOperationCancelled = true;
  }

  /**
   * @description Method to upload auth report after generating pre-signed URL and poll for its progress
   * @param {File} file The file to upload
   * @param {String} serverId the serverId of the server in edit/reauth workflow
   * @returns {Promise} Promise indicating completion of the API
   */
  static async uploadAuthReport(file, serverId) {
    this.isUploadOperationCancelled = false;
    const urlObject = await this.getAuthReportUploadUrl();
    const urlObjectKey = await this.putAuthReport(urlObject, file);
    const key = await this.postAuthReportUploadSuccessMessage(urlObjectKey, serverId);
    const id = await this.pollForAuthReportUploadProgress(key);
    return id;
  }

  /**
   * @description Method to reload the servers tab or emit new server created event
   */
  static reloadServersTab() {
    // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- required to check page opened
    if (window.location.href.includes('packages/servers')) {
      eventBus.emit(CREATE_SERVER.EVENTS.NEW_SERVER_CREATED);
    } else {
      LanServers.markModelDirty();
      setTimeout(() => {
        navBus.navigate(
          generatePath(PATH_PACKAGES_SERVERS, {
            orgId: rootStore.organizationStore.activeOrgId,
          })
        );
      }, 500);
    }
  }

  /**
   * @description Method to get upload URL for auth report
   * @returns {Promise} Promise indicating completion of the API
   */
  static async getAuthReportUploadUrl() {
    try {
      const response = await getAuthReportUploadUrl();
      return response.data;
    } catch (error) {
      log.error('Error getting upload URL for auth report.', error);
      throw error;
    }
  }

  /**
   * @description Method to get upload progress for auth report
   * @param {String} id The temporary job ID for the file
   * @returns {Promise} Promise indicating completion of the API
   */
  static async getAuthReportUploadProgress(id) {
    try {
      const response = await getAuthReportUploadProgress(id);
      return response.data;
    } catch (error) {
      log.error('Error getting auth report upload progress.', error);
      throw error;
    }
  }

  /**
   * @description Method to clear the poller interval
   */
  static clearPollerInterval() {
    clearInterval(this.intervalId);
    this.intervalId = undefined;
  }

  /**
   * @description Method to get upload progress for auth report
   * @param {String} id The temporary job ID for the file
   * @returns {Promise} Promise indicating completion of the API
   */
  static pollForAuthReportUploadProgress(id) {
    return new Promise((resolve, reject) => {
      this.intervalId = setInterval(async () => {
        if (CreateServerService.isUploadOperationCancelled) {
          reject(SERVER_API_MESSAGE.WIZARD_CLOSED);
          CreateServerService.clearPollerInterval();
          return;
        }
        try {
          const data = await CreateServerService.getAuthReportUploadProgress(id);
          if (data.status === SERVER_API_MESSAGE.COMPLETE) {
            resolve(data.id);
            CreateServerService.clearPollerInterval();
          } else if (
            data.status === SERVER_API_MESSAGE.ERROR_UPLOAD ||
            data.status === SERVER_API_MESSAGE.ERROR_VALIDATION ||
            data.status === SERVER_API_MESSAGE.ERROR
          ) {
            reject(data.status);
            CreateServerService.clearPollerInterval();
          }
        } catch (error) {
          reject(error);
          CreateServerService.clearPollerInterval();
        }
      }, rootStore.packagesUiConstantsStore.pollingFrequency);
    });
  }

  /**
   * @description Method to signal backend about success of upload operation
   * @param {String} id The temporary job ID for the file
   * @param {String} serverId the serverId of the server in edit/reauth workflow
   * @returns {Promise} Promise indicating completion of the API
   */
  static async postAuthReportUploadSuccessMessage(id, serverId) {
    if (this.isUploadOperationCancelled) {
      throw SERVER_API_MESSAGE.WIZARD_CLOSED;
    }
    try {
      const response =
        serverId === undefined
          ? await postAuthReportUploadSuccess(id)
          : await postAuthReportUploadSuccess(id, {serverId});
      if (response.data === SERVER_API_MESSAGE.SUCCESS) {
        return id;
      }
      throw new Error(response.data);
    } catch (error) {
      log.error('Error posting auth report upload success.', error);
      throw error;
    }
  }

  /**
   * @description Method to upload file at given S3 URL
   * @param {String} url URL to upload file at
   * @param {Object} fileContent The Uint8Array of file to be uploaded
   * @return {Promise<AxiosResponse<any>>} Axios response
   */
  static uploadFile(url, fileContent) {
    return axios.put(url, fileContent, {
      headers: {'Content-Type': ''},
    });
  }

  /**
   * @description Method to upload auth report at given S3 URL
   * @param {Object} urlObject The URL object with key and URL to upload file at
   * @param {File} file The file to upload
   * @returns {Promise} Promise indicating completion of the API
   */
  static putAuthReport(urlObject, file) {
    return new Promise((resolve, reject) => {
      if (this.isUploadOperationCancelled) {
        reject(SERVER_API_MESSAGE.WIZARD_CLOSED);
        return;
      }
      const reader = new FileReader();
      reader.addEventListener('load', async (event) => {
        const fileContent = new Uint8Array(event.target.result);
        try {
          await this.uploadFile(urlObject.url, fileContent);
          resolve(urlObject.key);
        } catch (error) {
          reject(error);
        }
      });
      reader.readAsArrayBuffer(file);
    });
  }
}

export default CreateServerService;
