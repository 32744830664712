import {DetailSection} from '@admin-tribe/acsc-ui';
import {ActionButton, Text} from '@adobe/react-spectrum';
import DownloadIcon from '@spectrum-icons/workflow/Download';
import FileCSVIcon from '@spectrum-icons/workflow/FileCSV';
import React from 'react';
import {useIntl} from 'react-intl';

import {useBulkOperationJob} from 'features/users/services/bulk-operations/BulkOperationJobContext';

import BulkOperationIconDetail from './BulkOperationIconDetail';

/* section displaying the job's input file name and an icon */
const InputFileDetailSection = () => {
  const intl = useIntl();
  const {filename, isExportJob, download} = useBulkOperationJob();

  return (
    <DetailSection
      title={intl.formatMessage({id: 'bulkOperations.results.drawer.section.filename'})}
    >
      {isExportJob ? (
        <ActionButton onPress={download}>
          <DownloadIcon />
          <Text>{filename}</Text>
        </ActionButton>
      ) : (
        <BulkOperationIconDetail
          ariaLabel={intl.formatMessage({
            id: 'bulkOperations.results.drawer.section.filename.ariaLabel',
          })}
          Icon={FileCSVIcon}
        >
          {filename}
        </BulkOperationIconDetail>
      )}
    </DetailSection>
  );
};

export default InputFileDetailSection;
