import {log} from '@admin-tribe/acsc';
import {useCallback, useRef, useState} from 'react';

/**
 * Custom hook for fetching AEM content.
 * @returns {Object} The content object.
 */
const useModalContent = (contentFetcherFn) => {
  const [content, setContent] = useState();
  const [showModal, setShowModal] = useState(false);
  const isLoading = useRef(false);

  const trigger = useCallback(async () => {
    if (isLoading.current) {
      return;
    }

    isLoading.current = true;

    try {
      if (content) return;
      const modalContent = await contentFetcherFn();
      if (modalContent) {
        setContent(modalContent);
        setShowModal(true);
      }
    } catch (error) {
      log.error('Error calling aem:', error);
    }
    isLoading.current = false;
  }, [contentFetcherFn, content]);

  return {
    closeModal: () => setShowModal(false),
    content,
    isOpen: showModal,
    trigger,
  };
};

export default useModalContent;
