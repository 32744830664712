import help from '../../constants/help';
import {hasEnterpriseContractAccess} from '../../utils/helpUtils';

const {HELP_ITEM_TYPE, LINK_TARGET_TYPE, LINK_TYPE, TOPICS} = help;

/**
 *
 * @returns content for admin console tab -> enterprise domains
 */
const enterpriseDomains = () => ({
  access: hasEnterpriseContractAccess,
  content: [
    {
      links: [
        {
          key: 'help.topics.enterpriseDomains2.link.one.key',
          locator: 'settings.identity',
          migrated: true,
          target: LINK_TARGET_TYPE.APPLICATION,
          type: LINK_TYPE.BUTTON,
        },
        {
          key: 'help.topics.enterpriseDomains2.link.two.key',
          locator: 'ac_identity',
          target: LINK_TARGET_TYPE.GO_URL,
          type: LINK_TYPE.TEXT,
        },
      ],
      text: 'help.topics.enterpriseDomains2.text',
      type: HELP_ITEM_TYPE.STEP,
    },
  ],
  id: TOPICS.ENTERPRISE_DOMAINS,
  parents: [
    {
      id: TOPICS.ADMIN_CONSOLE,
    },
  ],
  title: 'help.topics.enterpriseDomains2.title',
});
export default enterpriseDomains;
