import {Subpage} from '@admin-tribe/acsc-ui';
import PropTypes from 'prop-types';
import React from 'react';

import LegacyDevicesTableContainer from '../../components/product-legacy-table/LegacyDevicesTableContainer';

const PAYMENT__STATUS = 'OVER_ACTIVATED';

// Show the devices table and its call to action buttons
const LegacyDevicesSubpage = ({
  activeTab,
  poolId,
  productId,
  isDeviceListLoading,
  deviceListError,
  deviceList,
  updateTableChange,
}) => (
  <Subpage isBumpered={deviceListError} isLoading={isDeviceListLoading}>
    {deviceList && (
      <LegacyDevicesTableContainer
        deviceCollection={
          activeTab === 'payment-needed-devices'
            ? deviceList.filter((item) => item.status === PAYMENT__STATUS)
            : deviceList
        }
        poolId={poolId}
        productId={productId}
        updateTableChange={updateTableChange}
      />
    )}
  </Subpage>
);
LegacyDevicesSubpage.propTypes = {
  /** Indentifies the active tab */
  activeTab: PropTypes.string.isRequired,
  /** Indentifies the active tab */
  deviceList: PropTypes.arrayOf(
    PropTypes.shape({
      deviceId: PropTypes.string.isRequired,
      deviceName: PropTypes.string.isRequired,
      lastActivityDate: PropTypes.string.isRequired,
      selectedForRenewal: PropTypes.bool.isRequired,
      status: PropTypes.string.isRequired,
    })
  ),
  /** boolean indentifies when the devices list fetch has any error and bumps the sub page */
  deviceListError: PropTypes.bool,
  /** boolean indentifies when the devices list available to render */
  isDeviceListLoading: PropTypes.bool,
  /** Identifies the pool that this device belongs to. */
  poolId: PropTypes.string.isRequired,
  /** productId - Id of the current product */
  productId: PropTypes.string.isRequired,
  /** Reload page and update header section when device table is updated */
  updateTableChange: PropTypes.func.isRequired,
};

export default LegacyDevicesSubpage;
