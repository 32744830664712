import {InvoiceList} from '@admin-tribe/acsc';
import {useAsyncModel} from '@admin-tribe/acsc-ui';
import {TABLE_SECTION_ACTIONS} from '@pandora/react-table-section';
import {useCallback, useReducer} from 'react';

import rootStore from 'core/RootStore';

import {FIELD_TO_SORT_EXPRESSION} from '../BillingHistoryConstants';
import {isDescending} from '../BillingHistoryUtils';

const tableSectionChangeToJILModelListReducer = (state, {action, payload}) => {
  switch (action) {
    case TABLE_SECTION_ACTIONS.GO_TO_NEXT_PAGE:
    case TABLE_SECTION_ACTIONS.GO_TO_PREVIOUS_PAGE:
      return {
        ...state,
        pageNumber: payload,
      };
    case TABLE_SECTION_ACTIONS.ON_PAGE_SIZE_CHANGE:
      return {
        ...state,
        pageNumber: 1,
        pageSize: payload,
      };
    case TABLE_SECTION_ACTIONS.ON_SORT_BY:
      return {
        ...state,
        pageNumber: 1,
        sortExpression: FIELD_TO_SORT_EXPRESSION[payload.id],
        sortOrder: isDescending(payload) ? 'DESC' : 'ASC',
      };
    case TABLE_SECTION_ACTIONS.ON_SEARCH_SUBMIT:
      return {
        ...state,
        filterQuery: payload.value,
        pageNumber: 1,
      };
    case 'SET_DATA_ATTRIBUTES':
      return {
        ...state,
        sortExpression: FIELD_TO_SORT_EXPRESSION[payload[0].id],
        sortOrder: isDescending(payload[0]) ? 'DESC' : 'ASC',
      };
    default:
      return {
        ...state,
      };
  }
};

/**
 * Hook that retrieves invoices, search, and pagination information
 * @param {String} [options.orgId] - associates InvoiceList instance with an org.
 * @param {String} [options.start_date] - ISO date string, earliest invoice date to include
 * @param {String} [options.end_date] - ISO date string, latest invoice date to include
 * @param {Array<INVOICE_STATUS>} [options.invoiceStatuses] - an array of invoice statuses as
 * @param {String} [options.filterQuery] - query to filter results against
 * @param {Number} [options.pageNumber] - 1-based page number to fetch
 * @param {Number} [options.pageSize] - number of items to display per page
 * @param {String} [options.sortExpression] - sorting criteria (e.g. - name)
 * @param {String} [options.sortOrder] - sorting order (e.g. - ASC or DESC)
 */
const useInvoiceList = (initialParams = {pageNumber: 1, pageSize: 20}) => {
  const orgId = rootStore.organizationStore.activeOrgId;
  const [listState, dispatchTableSectionChange] = useReducer(
    tableSectionChangeToJILModelListReducer,
    initialParams
  );
  const {filterQuery, pageNumber, pageSize, sortExpression, sortOrder} = listState;

  const fetchInvoiceList = useCallback(async () => {
    const invoiceList = await InvoiceList.get({
      filterQuery,
      isCacheable: true,
      orgId,
      pageNumber,
      pageSize,
      sortExpression,
      sortOrder,
    });

    invoiceList.pagination.totalPages = Math.ceil(
      invoiceList.pagination.itemCount / invoiceList.pagination.pageSize
    );
    return invoiceList;
  }, [filterQuery, orgId, pageNumber, pageSize, sortExpression, sortOrder]);

  const {model: invoiceList, isLoading, error} = useAsyncModel({loadFn: fetchInvoiceList});
  return {dispatchTableSectionChange, error, invoiceList, isLoading};
};

export default useInvoiceList;
