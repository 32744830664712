import {DetailSection} from '@admin-tribe/acsc-ui';
import CheckmarkCircleIcon from '@spectrum-icons/workflow/CheckmarkCircle';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';

import {JOB_STATUS} from 'common/services/job/JobConstants';
import {useBulkOperationJob} from 'features/users/services/bulk-operations/BulkOperationJobContext';

import BulkOperationIconDetail from './BulkOperationIconDetail';
import JobFailedSummary from './JobFailedSummary';
import JobResultsSummary from './JobResultsSummary';

/* section displaying the job's status when processing is completed */
const ResultsDetailSection = () => {
  const intl = useIntl();
  const {jobStatus} = useBulkOperationJob();

  // switch between results view based on status
  const ResultSummary = useMemo(() => {
    switch (jobStatus) {
      case JOB_STATUS.CANCELED:
        // return a generic component since the view is very basic
        return () => (
          <BulkOperationIconDetail
            ariaLabel={intl.formatMessage({
              id: 'bulkOperations.results.drawer.section.result.canceled.ariaLabel',
            })}
            color="positive"
            Icon={CheckmarkCircleIcon}
          >
            {intl.formatMessage({id: 'common.status.canceled'})}
          </BulkOperationIconDetail>
        );
      case JOB_STATUS.FAILED:
        return JobFailedSummary;
      default:
        return JobResultsSummary;
    }
  }, [intl, jobStatus]);

  return (
    <DetailSection title={intl.formatMessage({id: 'bulkOperations.results.drawer.section.result'})}>
      <ResultSummary />
    </DetailSection>
  );
};

export default ResultsDetailSection;
