import {DirectoryUser, OrganizationUser, UserGroup} from '@admin-tribe/acsc';
import {Content, Header} from '@adobe/react-spectrum';
import {Drawer} from '@pandora/react-drawer';
import {useId} from '@react-aria/utils';
import PropTypes from 'prop-types';
import React from 'react';

import UserDetailsDrawerHeader from 'common/components/user-details-drawer-header/UserDetailsDrawerHeader';
import {useMemberListContext} from 'common/hooks/member-list-context/MemberListContext';
import ApiIntegrationUser from 'core/api-integration/ApiIntegrationUser';
import ApiCredentialsDetailsDrawerContent from 'features/users/components/api-credentials-details-drawer-content/ApiCredentialsDetailsDrawerContent';
import DirectoryUserDetailsDrawerContent from 'features/users/components/directory-user-details-drawer-content/DirectoryUserDetailsDrawerContent';
import UserDetailsContent from 'features/users/components/user-details-content/UserDetailsContent';
import UserGroupDetailsDrawerContent from 'features/users/components/user-group-details-drawer-content/UserGroupDetailsDrawerContent';

/**
 * The detail drawer for a Member.
 */
const UsersDetailsDrawer = ({member}) => {
  const headerId = useId();
  const {pageContext} = useMemberListContext();

  const isApiCredentials = pageContext.isIntegrationTargetType();
  const isDirectoryUser = pageContext.isUserTargetType() && pageContext.isDirectoryTarget();

  // We check the member type here instead of pageContext because the user group drawer can be displayed
  // in the context of Product users (/products/:productId/users) in addition to the usual User groups page
  const isUserGroup = member.getType().isUserGroup();

  const showDefaultContent = !isApiCredentials && !isDirectoryUser && !isUserGroup;

  return (
    <Drawer aria-labelledby={headerId}>
      <Header>
        <UserDetailsDrawerHeader member={member} pageContext={pageContext} />
      </Header>
      <Content>
        {isApiCredentials && <ApiCredentialsDetailsDrawerContent member={member} />}
        {isDirectoryUser && (
          <DirectoryUserDetailsDrawerContent directoryId={pageContext.targetId} member={member} />
        )}
        {isUserGroup && <UserGroupDetailsDrawerContent userGroup={member} />}
        {showDefaultContent && <UserDetailsContent member={member} />}
      </Content>
    </Drawer>
  );
};

UsersDetailsDrawer.propTypes = {
  /**
   * The user / administrator / directory user / API credentials (integrations).
   * When the drawer is used in one of the tables it is created without a member property
   * and then it is cloned and the member property is added for each row item.
   */
  member: PropTypes.oneOfType([
    PropTypes.instanceOf(ApiIntegrationUser),
    PropTypes.instanceOf(DirectoryUser),
    PropTypes.instanceOf(OrganizationUser),
    PropTypes.instanceOf(UserGroup),
  ]),
};

export default UsersDetailsDrawer;
