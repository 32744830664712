import {MemberConfigurationRoles} from '@admin-tribe/acsc';
import sortBy from 'lodash/sortBy';
import {action, computed, makeObservable, observable, runInAction, toJS} from 'mobx';

import UserStore from './UserStore';

//
// @description Domain data for Product users and Profile users.
//    This subclass of UserStore adds support for member product roles (Adobe Sign) and legacy product roles (Target).
// @param {Object} options - configuration for the store
// @param {String} options.productId - the product id for the product users or profile users page
// @param {Object} options.storeOptions - see UserStore options
//
class ProductUserStore extends UserStore {
  availableRoles = [];
  licenseGroupId;
  memberRolesTooltip = null;
  roles = [];
  showLegacyRoles = null;
  showMemberRoles = null;

  constructor({productId, ...storeOptions}) {
    super({...storeOptions});

    this.productId = productId;

    makeObservable(this, {
      availableRoles: observable,
      initProductRoles: action,
      memberRolesTooltip: observable,
      productId: observable,
      roles: observable, // either legacy roles or member roles
      showLegacyRoles: observable, // only supported for LicenseGroupUserList & Target
      showMemberRoles: observable, // for example Adobe Sign for Business
      showProductRole: computed,
    });
  }

  /**
   * @description The first time this is called (by Store.load), it will init the product roles before
   *   fetching the users. After the users are loaded, if there are member roles to show, it will fetch them.
   *
   *   Note: Store.load wraps this in a try/catch.
   *   If an error occurs, this.hasLoadingError will be set to true.
   */
  async fetchData() {
    const joinFn = this.showMemberRoles ? this.fetchMemberRoles.bind(this) : undefined;
    await super.fetchData({joinFn});
  }

  /**
   * @description If product roles are being shown for a member, this does a join with MemberConfigurationRoles
   *   and sets member.productRole and optionally the member.productRoleTooltip properties.
   *
   *   Note: this is called by fetchData which is called by load which wraps this in a try/catch.
   *   If an error occurs, this.hasLoadingError will be set to true.
   */
  async fetchMemberRoles(items) {
    const memberConfigRoles = await MemberConfigurationRoles.get({
      licenseGroupId: this.licenseGroupId,
      memberIds: items.map((user) => user.id),
      orgId: this.orgId,
      productId: this.productId,
    });

    runInAction(() => {
      this.memberConfigRoles = memberConfigRoles;
      this.roles = sortBy(toJS(memberConfigRoles?.availableRoles), ['name']);

      // Description can have newlines in it.
      const {description, learnMoreHref} = memberConfigRoles;

      // Note the description may have line breaks which should be preserved when constructing the content.
      const roleTooltip = description || learnMoreHref ? {description, learnMoreHref} : null;

      // Add the productRole to each user object.
      items.forEach((user) => {
        const foundRole = memberConfigRoles.memberRoles[user.id];
        if (foundRole) {
          Object.assign(user, {
            productRole: this.roles.find((role) => role.id === foundRole[0]),
            productRoleTooltip: roleTooltip,
          });
        }
      });
    });

    return items;
  }

  /**
   * @description Used to set the product role options for the particular type of user list
   *   before the list is loaded for the first time.
   *   This results in either no roles being shown, legacy roles being shown, or memmber roles being shown.
   *
   *    {String} licenseGroupId - required if showLegacyRoles or showMemberRoles is true
   *    {Boolean} showLegacyRoles - true if LicenseGroupUserList and product roles are part of the LicenseGroup object
   *    {Boolean} showMemberRoles - true if product roles are fetched from MemberConfigurationRoles
   *    {Array of LegacyProductRole} roles - required if showLegacyRoles is true
   */
  initProductRoles(roleOptions) {
    runInAction(() => {
      this.licenseGroupId = roleOptions.licenseGroupId;
      this.showLegacyRoles = !!(roleOptions.legacyRoles?.length > 0);
      this.showMemberRoles = !!roleOptions.showMemberRoles;
      this.roles = roleOptions.legacyRoles;
    });
  }

  /**
   * @description Save the product role for each of the selected items.
   * @param {Array} selectedItems The array of members which should have their product roles updated.
   * @param {String} selectedProductRole The id of the selected role.
   * @returns {Promise} resolves if changes successfully saved, else rejects with error message
   * @throws {Error} if called when neither showMemberRoles or showLegacyRoles is true
   */
  saveProductRole({selectedItems, selectedProductRole}) {
    this.isLoading = true;

    if (this.showMemberRoles) {
      selectedItems.forEach((item) => {
        this.memberConfigRoles.memberRoles[item.id] = [selectedProductRole];
      });

      try {
        return this.memberConfigRoles.save();
      } finally {
        // The list should already be cached. The roles will be refetched and joined with the cached list.
        // load() will set isLoading to false when it completes.
        this.load();
      }
    }

    if (this.showLegacyRoles) {
      selectedItems.forEach((member) => {
        Object.assign(member, {productRole: [selectedProductRole]});
      });
      try {
        return this.memberList.saveMemberProductRole(selectedItems);
      } finally {
        // The roles are part of the list so the list must be refreshed.
        // load() will set isLoading to false when it completes.
        this.load();
      }
    }

    throw new Error('ProductUserStore: saveProductRole no roles');
  }

  /**
   * @description Determines if the product role switcher should be shown with the table actions.
   * @returns {Boolean} returns true if the product role switcher should be shown with the table actions.
   */
  get showMultiProductRoleSwitcher() {
    return this.showLegacyRoles;
  }

  /**
   * @description Determines if the product role should be shown for each user in the table.
   * @returns {Boolean} returns true if the product role should be shown for each user in the table.
   */
  get showProductRole() {
    return this.showLegacyRoles || (this.showMemberRoles && this.roles?.length > 0);
  }
}

export default ProductUserStore;
