import {UserGroupAdminList, UserGroupUserList} from '@admin-tribe/acsc';
import {useAsyncModel} from '@pandora/react-async-model';
import {useCallback} from 'react';

import {sortProducts} from 'common/components/products-detail-section/productsDetailSectionUtils';
import rootStore from 'core/RootStore';
import {getProducts} from 'features/users/components/user-group-details-drawer-content/userGroupDrawerUtils';

/**
 * @description Hook for handling the fetching of the data needs for the UserGroupDetailsDrawerContent.
 *
 * @param {Object} intl - the intl object
 * @param {UserGroup} userGroup - the user Group for the drawer
 * @returns {Object} state - Object with state variables
 *    {UserGroupAdminList} state.adminList - the list of user group admins,
 *    {Object} state.adminListError,
 *    {Boolean} state.isAdminListLoading - whether the adminList is being loaded
 *    {Boolean} state.isProductsLoading - whether the products are being loaded,
 *    {Boolean} state.isQuantitiesLoading - whether the user quantities are being loaded,
 *    {Object} state.products - a sorted list of products for this user group,
 *    {Object} state.productsError,
 *    {Object} state.quantities - an object with 'totalQuantity' of users,
 *    {Object} state.quantitiesError,
 */
const useUserGroupDetailsDrawerContent = ({intl, userGroup}) => {
  const fetchProducts = useCallback(async () => {
    const theProducts = await getProducts(userGroup.id);
    const products = sortProducts(intl, theProducts);
    return products;
  }, [intl, userGroup.id]);

  const {
    model: products,
    error: productsError,
    isLoading: isProductsLoading,
  } = useAsyncModel({
    loadFn: fetchProducts,
  });

  const fetchUserQuantities = useCallback(async () => {
    const usersList = await UserGroupUserList.get({
      orgId: rootStore.organizationStore.activeOrgId,
      userGroupId: userGroup.id,
    });
    return {userCount: usersList.getTotalItemCount()};
  }, [userGroup]);
  const {
    model: quantities,
    error: quantitiesError,
    isLoading: isQuantitiesLoading,
  } = useAsyncModel({
    loadFn: fetchUserQuantities,
  });

  const fetchAdmins = useCallback(
    () =>
      UserGroupAdminList.get({
        orgId: rootStore.organizationStore.activeOrgId,
        userGroupId: userGroup.id,
      }),
    [userGroup.id]
  );

  const {
    model: adminList,
    isLoading: isAdminListLoading,
    error: adminListError,
  } = useAsyncModel({
    loadFn: fetchAdmins,
  });

  return {
    adminList,
    adminListError,
    isAdminListLoading,
    isProductsLoading,
    isQuantitiesLoading,
    products,
    productsError,
    quantities,
    quantitiesError,
  };
};

export default useUserGroupDetailsDrawerContent;
