import {getTaxTerm} from '@admin-tribe/acsc';
import {useMemo} from 'react';

import {
  getAllRemainingLicensesCount,
  getCancellableLicensesCount,
  getCurrency,
  getNextBilling,
  getNextBillingFromContract,
} from '../../SelfCancelUtils';
import {useProductsChangeContext} from '../../components/products-change-context/ProductsChangeContext';

/**
 * Hook that retrieves the total price and tax information when cancelling licenses
 * @param {Object} options Contains contract, selectedSeats hashmap, and list of product licenses purchased by the org.
 * @param {Contract} options.contract The org's contract.
 * @param {Boolean} [options.isErrorDisplayed] Whether this hook should react to ProductsChange errors or loading state.
 * @param {Object} options.selectedSeats The hash map of initially selected licenses per product id.
 * @param {Array} options.productList The list of org's products.
 * @returns {Object} Result containing total information and if any API calls are currently loading or failed
 *          {Object} result.currency - The currency object for rendering prices
 *          {Object} result.error - Returns the error message from accessing productsChange/API calls
 *          {Boolean} result.hasLicensesSelected - If any licenses have been selected for cancellation
 *          {Boolean} result.isLoading - If an API call is currently being made this will be set to true, false when it completes
 *          {Number} result.remainingLicenses - The number of licenses that remain after the rest are selected to be canceled.
 *          {String} result.taxTerm - "TAX", "VAT", etc. to be appended if the price is included/excluded
 *          {Number} result.totalTax - The tax amount from the cost of the remaining licenses
 *          {Number} result.totalWithoutTax - The total amount without tax of the cost of remaining licenses
 *          {Number} result.totalWithTax - The total amount including tax of the cost of remaining licenses
 */
const useCancellationTotal = ({contract, selectedSeats, productList, isErrorDisplayed = false}) => {
  const {error, isLoading, productsChange} = useProductsChangeContext();

  // use contract information for billing price (contains next billing amount)
  // when no selection then pass that to as totalWithoutTax
  const initial = useMemo(() => {
    const {currency, totalTax, totalWithoutTax, totalWithTax} =
      getNextBillingFromContract(contract);
    const remainingLicenses = productList.items.reduce(
      (summedLicenses, product) => summedLicenses + getCancellableLicensesCount(product),
      0
    );
    return {
      currency,
      recurrenceTerm: contract.getBillingFrequency(),
      remainingLicenses,
      taxTerm: getTaxTerm(contract.getOwnerCountryCode()),
      totalTax,
      totalWithoutTax,
      totalWithTax,
    };
  }, [contract, productList]);

  return useMemo(() => {
    let remainingLicenses;
    const hasLicensesSelected = Object.keys(selectedSeats).length > 0;
    if (!selectedSeats || !hasLicensesSelected) {
      return {
        ...initial,
        error,
        isLoading,
      };
    }

    remainingLicenses = productList.items.reduce(
      (summedLicenses, product) =>
        summedLicenses +
        Math.max(
          getCancellableLicensesCount(product) - (selectedSeats[product.id]?.length || 0),
          0
        ),
      0
    );

    const nextBillingData = getNextBilling(productsChange);

    // return early and use values from initial if error exists or isLoading is true,
    // as well as if productsChange is not defined
    if ((isErrorDisplayed && (error || isLoading)) || !nextBillingData) {
      return {
        ...initial,
        error,
        hasLicensesSelected,
        isLoading,
        remainingLicenses,
        totalTax: undefined,
        totalWithoutTax: undefined,
        totalWithTax: undefined,
      };
    }

    const currency = initial.currency || getCurrency(productsChange);
    const {totalTax, totalWithoutTax, totalWithTax} = nextBillingData;
    remainingLicenses = getAllRemainingLicensesCount(productsChange) ?? remainingLicenses;
    return {
      ...initial,
      currency,
      error,
      hasLicensesSelected,
      isLoading,
      remainingLicenses,
      totalTax,
      totalWithoutTax,
      totalWithTax,
    };
  }, [
    initial,
    error,
    isErrorDisplayed,
    isLoading,
    productsChange,
    productList.items,
    selectedSeats,
  ]);
};

export default useCancellationTotal;
