import get from 'lodash/get';

import {setProperty} from '../aepAnalytics';

/**
 *
 * @param {*} launchContext
 * @param {*} chatDescriptor
 */
export default function chatEventTranslator(chatDescriptor) {
  setProperty('chat', {});
  setProperty('chat.chatInfo', {
    chatCategory: get(chatDescriptor.data, 'event.workflow'),
    chatClientId: get(chatDescriptor.data, 'source.client_id'),
    chatContentName: get(chatDescriptor.data, 'content.name'),
    chatConversationId: get(chatDescriptor.data, 'event.guid'),
    chatEnv: get(chatDescriptor, 'environment'),
    chatLanguage: get(chatDescriptor.data, 'event.language'),
    chatOrgguid: get(chatDescriptor.data, 'event.org_guid'),
  });
}
