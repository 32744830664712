import {
  showError as showErrorToast,
  showInfo as showInfoToast,
  showSuccess as showSuccessToast,
} from '@admin-tribe/acsc-ui';
import {
  CreateInsightsItemDetailModal,
  InsightsItemDetailListTableSection as PandoraInsightsItemDetailListTable,
  useInsightsItemDetailListTableContext,
} from '@pandora/react-insights';
import {ModalContainer, useModalDialog} from '@pandora/react-modal-dialog';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';

const InsightsItemDetailListTable = () => {
  const {
    isOpen: isCreateOpen,
    openModal: openCreateModal,
    closeModal: closeCreateModal,
  } = useModalDialog(false);
  const {reload, insightsItem} = useInsightsItemDetailListTableContext();
  const intl = useIntl();

  const onCreateConfirm = useCallback(
    async (response) => {
      try {
        await response;
        reload();
        closeCreateModal();
        showSuccessToast();
      } catch {
        showErrorToast();
      }
    },
    [reload, closeCreateModal]
  );

  const onExport = useCallback(
    async (response) => {
      try {
        await response;
      } catch {
        showErrorToast(intl.formatMessage({id: 'insights.export.unableToDownload'}));
      }
    },
    [intl]
  );

  const onDelete = useCallback(async (response) => {
    try {
      await response;
      showSuccessToast();
    } catch {
      showErrorToast();
    }
  }, []);

  const onBeforeDownload = useCallback(() => {
    showInfoToast(intl.formatMessage({id: 'insights.download.downloadBeingPrepared'}));
  }, [intl]);

  const onBeforeExport = useCallback(() => {
    showInfoToast(intl.formatMessage({id: 'insights.export.downloadBeingPrepared'}));
  }, [intl]);

  return (
    <>
      <PandoraInsightsItemDetailListTable
        onBeforeDownload={onBeforeDownload}
        onBeforeExport={onBeforeExport}
        onCreateClick={openCreateModal}
        onDelete={onDelete}
        onExport={onExport}
      />
      <ModalContainer>
        {isCreateOpen && (
          <CreateInsightsItemDetailModal
            insightsItem={insightsItem}
            onCancel={closeCreateModal}
            onConfirm={onCreateConfirm}
          />
        )}
      </ModalContainer>
    </>
  );
};

export default InsightsItemDetailListTable;
