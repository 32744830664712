import binkyUI, {ModalContent, ModalDescription, ModalWizardStepList} from '@admin-tribe/acsc-ui';
import {Text} from '@adobe/react-spectrum';
import {ListContainer} from '@pandora/react-list-container';
import {useCollator} from '@react-aria/i18n';
import React from 'react';
import {useIntl} from 'react-intl';

import ProductProfileServicesSection from 'common/components/product-profile-services-section/ProductProfileServicesSection';

import {getSortedServicesAndPersonDelegatedQuotas} from '../../utils/productProfileUtils';
import {useProductProfileModalContext} from '../AddProductProfileModalContext';

import './AddProductProfileModalServicesPage.pcss';

const GoUrl = binkyUI.common.components.GoUrl;

const AddProductProfileModalServicesPage = () => {
  const intl = useIntl();
  const {currentProductProfile} = useProductProfileModalContext();
  const collator = useCollator();

  const sortedServicesAndQuotas = getSortedServicesAndPersonDelegatedQuotas(
    currentProductProfile,
    collator
  );

  return (
    <>
      <ModalDescription>
        <ModalWizardStepList />
        <Text data-testid="description-text">
          {intl.formatMessage(
            {
              id: 'products.addProductProfileModal.services.description',
            },
            {
              goUrl: (str) => <GoUrl name="aac_manage_services">{str}</GoUrl>,
            }
          )}
        </Text>
      </ModalDescription>
      <ModalContent>
        <ListContainer getItemKey={(item) => item.id} items={sortedServicesAndQuotas}>
          <ProductProfileServicesSection
            product={currentProductProfile.product}
            showDivider={false}
          />
        </ListContainer>
      </ModalContent>
    </>
  );
};

export default AddProductProfileModalServicesPage;
