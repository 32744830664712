import {OverlayWait} from '@admin-tribe/acsc-ui';
import {Flex, Switch, Text, View} from '@adobe/react-spectrum';
import {GoUrl} from '@pandora/react-go-url';
import differenceInDays from 'date-fns/differenceInDays';
import React, {useContext} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import Context from './JitRuleContext';

/**
 * JIT Rule Widget / slot
 */
const JitRuleWidget = () => {
  const intl = useIntl();
  const {
    isLoading,
    notificationsEnabled,
    ruleDate,
    ruleEnabled,
    setNotificationsEnabled,
    setRuleEnabled,
    productName,
    variant,
  } = useContext(Context);

  const daysLeft = differenceInDays(ruleDate, Date.now());

  const toggleRuleStatus = (ruleActive) => {
    setRuleEnabled(ruleActive);
    if (!ruleActive) {
      setNotificationsEnabled(false);
    }
  };

  return (
    <OverlayWait isLoading={isLoading}>
      <Flex direction="column" rowGap="size-100">
        {variant.includes('status') && (
          <Switch
            aria-label={intl.formatMessage({
              id: 'common.sophia.sophiaModal.widgets.jitRuleWidget.toggleRule.label',
            })}
            isEmphasized
            isSelected={ruleEnabled}
            onChange={toggleRuleStatus}
          >
            <View>
              <Text UNSAFE_style={{fontWeight: 'bold'}}>
                <FormattedMessage id="common.sophia.sophiaModal.widgets.jitRuleWidget.toggleRule.title" />
              </Text>
            </View>
            <View>
              <Text>
                <FormattedMessage
                  id="common.sophia.sophiaModal.widgets.jitRuleWidget.toggleRule.description"
                  values={{daysLeft, productName}}
                />
              </Text>
            </View>
          </Switch>
        )}
        {variant.includes('notifications') && (
          <Switch
            aria-label={intl.formatMessage({
              id: 'common.sophia.sophiaModal.widgets.jitRuleWidget.toggleNotifications.label',
            })}
            isDisabled={!ruleEnabled}
            isEmphasized
            isSelected={notificationsEnabled}
            onChange={setNotificationsEnabled}
          >
            <View>
              <Text UNSAFE_style={{fontWeight: 'bold'}}>
                <FormattedMessage
                  id="common.sophia.sophiaModal.widgets.jitRuleWidget.toggleNotifications.title"
                  values={{productName}}
                />
              </Text>
            </View>
            <View>
              <Text>
                <FormattedMessage
                  id="common.sophia.sophiaModal.widgets.jitRuleWidget.toggleNotifications.description"
                  values={{productName}}
                />
              </Text>
            </View>
          </Switch>
        )}
        <GoUrl name="express-firefly-business">
          <View>
            <FormattedMessage id="common.sophia.sophiaModal.widgets.jitRuleWidget.learnMore" />
          </View>
        </GoUrl>
      </Flex>
    </OverlayWait>
  );
};

export default JitRuleWidget;
