import {feature, jilDirectories} from '@admin-tribe/acsc';
import {
  GoUrl,
  showError as showErrorToast,
  showSuccess as showSuccessToast,
} from '@admin-tribe/acsc-ui';
import {DialogContainer, Flex, Heading, Switch} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import rootStore from 'core/RootStore';
import {canChangeIdentityConfig} from 'core/admin/access/adminAccess';
import GlobalAdminPolicyActionDisabled from 'features/settings/common/components/contextual-help/GlobalAdminPolicyActionDisabled';
import TeacherValidationPolicyConfirmationModal from 'features/settings/components/directory/directory-settings-modal/teacher-access-policy-section/TeacherValidationPolicyConfirmationModal';
import {useDirectoryDetailsContext} from 'features/settings/pages/directory-details-page/DirectoryDetailsContext';

const TeacherAccessPolicySection = ({setIsLoading}) => {
  const intl = useIntl();
  const [showTeacherValidationConfirmationModal, setShowTeacherValidationConfirmationModal] =
    useState(false);
  const {directory, reloadDirectory} = useDirectoryDetailsContext();

  const canManageIdentityConfig = canChangeIdentityConfig();

  const isTeacherValidationPolicyFlagEnabled = feature.isEnabled('temp_teacher_validation_policy');

  const headingId = isTeacherValidationPolicyFlagEnabled
    ? 'settings.directorySettingsModal.teacherValidationPolicySection.heading'
    : 'settings.directorySettingsModal.teacherAccessPolicySection.heading';

  const descriptionId = isTeacherValidationPolicyFlagEnabled
    ? 'settings.directorySettingsModal.teacherValidationPolicySection.description'
    : 'settings.directorySettingsModal.teacherAccessPolicySection.description';

  const isSwitchSelected = isTeacherValidationPolicyFlagEnabled
    ? directory.isPolicyEnabled('ENFORCE_VERIFIED_TEACHER_CLASSROOM_INVITES_ONLY')
    : !directory.isPolicyEnabled('POLICY_BLOCK_CLASSROOM_ORGANIZATION_CREATE');

  const successDisabledId = isTeacherValidationPolicyFlagEnabled
    ? 'settings.directorySettingsModal.teacherValidationPolicySection.toasts.successDisabled'
    : 'settings.directorySettingsModal.teacherAccessPolicySection.toasts.successDisabled';

  const onToggle = async (newValue) => {
    setIsLoading(true);

    if (newValue && isTeacherValidationPolicyFlagEnabled) {
      setShowTeacherValidationConfirmationModal(true);
      setIsLoading(false);
      return;
    }

    try {
      const policies = {...directory.policies};

      if (isTeacherValidationPolicyFlagEnabled && !newValue) {
        delete policies.ENFORCE_VERIFIED_TEACHER_CLASSROOM_INVITES_ONLY;
      } else if (newValue) {
        delete policies.POLICY_BLOCK_CLASSROOM_ORGANIZATION_CREATE;
      } else {
        policies.POLICY_BLOCK_CLASSROOM_ORGANIZATION_CREATE = 'true';
      }

      await jilDirectories.updateDirectory({
        directoryData: {
          policies,
        },
        directoryId: directory.id,
        orgId: rootStore.organizationStore.activeOrgId,
      });

      await reloadDirectory();

      showSuccessToast(
        intl.formatMessage({
          id: newValue
            ? 'settings.directorySettingsModal.teacherAccessPolicySection.toasts.successEnabled'
            : successDisabledId,
        })
      );
    } catch {
      showErrorToast();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Heading>
        <FormattedMessage id={headingId} />
      </Heading>
      {isTeacherValidationPolicyFlagEnabled ? (
        <>
          <Flex alignItems="start" gap="size-200">
            <Flex alignItems="baseline" gap="size-100">
              <Switch
                isDisabled={!canManageIdentityConfig}
                isEmphasized
                isSelected={isSwitchSelected}
                marginEnd={0}
                onChange={onToggle}
              />
              {!canManageIdentityConfig && <GlobalAdminPolicyActionDisabled />}
            </Flex>
            <Flex direction="column">
              <FormattedMessage id={descriptionId} />
              {/* FormattedMessage renders translated string and goUrl as siblings. keep them under one parent <span> for correct flex spacing */}
              <span>
                <FormattedMessage
                  id="settings.directorySettingsModal.teacherValidationPolicySection.description.help"
                  values={{
                    goUrl:
                      // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- not testing format message values function
                      // istanbul ignore next -- not testing this -- goUrl is in the snapshot
                      (url) => <GoUrl name="ac_teacher_access_learn">{url}</GoUrl>,
                  }}
                />
              </span>
            </Flex>
          </Flex>

          <DialogContainer onDismiss={() => setShowTeacherValidationConfirmationModal(false)}>
            {showTeacherValidationConfirmationModal && <TeacherValidationPolicyConfirmationModal />}
          </DialogContainer>
        </>
      ) : (
        <Flex alignItems="center" gap="size-200">
          {/* FormattedMessage renders translated string and goUrl as siblings. keep them under one parent <span> for correct flex spacing */}
          <span>
            <FormattedMessage
              id="settings.directorySettingsModal.teacherAccessPolicySection.description"
              values={{
                goUrl:
                  // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- not testing format message values function
                  // istanbul ignore next -- not testing this -- goUrl is in the snapshot
                  (url) => <GoUrl name="ac_teacher_access_learn">{url}</GoUrl>,
              }}
            />
          </span>

          <Switch
            isDisabled={!canManageIdentityConfig}
            isEmphasized
            // policy is "block" but toggle label is "allow"
            isSelected={isSwitchSelected}
            marginEnd={0}
            onChange={onToggle}
          />
          {!canManageIdentityConfig && <GlobalAdminPolicyActionDisabled />}
        </Flex>
      )}
    </>
  );
};

TeacherAccessPolicySection.propTypes = {
  setIsLoading: PropTypes.func.isRequired,
};
export default TeacherAccessPolicySection;
