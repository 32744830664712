import {configStore, getHeaders} from '@admin-tribe/acsc';
import axios from 'axios';

import rootStore from 'core/RootStore';

let clientId, includeRoles, url;

(async function loadJilConfig() {
  ({url, clientId, includeRoles} = await configStore.getServiceConfiguration('jil'));
  url = `${url}/v2/organizations`;
})();

/**
 * @description Fetches the organization's storage repositories.
 *
 * @return {Promise} a promise which will resolve with the response.
 */
const getStorageRepositories = () =>
  axios.get(`${url}/${rootStore.organizationStore.activeOrgId}/storage-repositories`, {
    headers: getHeaders({clientId, includeRoles}),
  });

/**
 * @description Updates the organization's storage repositories.
 *
 * @param {Array<{op: String, name: String, value: String}>} operations - an array of operations to update repositories.
 * @return {Promise} a promise which will resolve with the response.
 */
const patchStorageRepositories = (operations) =>
  axios.patch(
    `${url}/${rootStore.organizationStore.activeOrgId}/storage-repositories`,
    operations,
    {
      headers: getHeaders({clientId, includeRoles}),
    }
  );

const jilStorageRepositories = {
  getStorageRepositories,
  patchStorageRepositories,
};

export default jilStorageRepositories;
