import {Tooltip, TooltipTrigger} from '@adobe/react-spectrum';
import {ImageIcon} from '@pandora/react-image-icon';
import {TooltipButton} from '@pandora/react-tooltip-button';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import Copy from 'features/packages/svgs/Copy.svg';

const HIDE_DELAY = 800;

// A component that wraps a spectrum button and copies the
// given value to the clipboard when clicked.
const CopyCheckSumButton = ({children, hideDelay = HIDE_DELAY, value, ...buttonProps}) => {
  const intl = useIntl();
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltip, setTooltip] = useState({
    id: 'packages.pregeneratedPackages.copyCheckSumButton.valueCopied',
    variant: 'success',
  });

  // Hide the tooltip after it has been shown
  // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- using ToolTip component
  // istanbul ignore next - issues with timeout
  useEffect(() => {
    if (!showTooltip) return () => ({});

    const timeoutId = setTimeout(() => {
      setShowTooltip(false);
    }, hideDelay);

    return () => clearTimeout(timeoutId);
  }, [showTooltip, hideDelay]);

  const handleClick = async () => {
    try {
      await navigator.clipboard.writeText(value);

      setTooltip({
        id: 'packages.pregeneratedPackages.copyCheckSumButton.valueCopied',
        variant: 'success',
      });
    } catch (error) {
      setTooltip({
        id: 'common.copyToClipboardButton.errorCopying',
        variant: 'error',
      });
    } finally {
      setShowTooltip(true);
    }
  };

  return (
    <TooltipTrigger isOpen={showTooltip} placement="top">
      <TooltipButton
        hoverText={intl.formatMessage({
          id: 'packages.pregeneratedPackages.copyCheckSumButton.copyChecksum',
        })}
        isQuiet
        onPress={handleClick}
        UNSAFE_style={{backgroundColor: 'var(--spectrum-global-color-gray-200)'}}
        {...buttonProps}
        variant="action"
      >
        <ImageIcon alt="copy" src={Copy} />
        {children}
      </TooltipButton>
      <Tooltip variant={tooltip.variant}>{intl.formatMessage({id: tooltip.id})}</Tooltip>
    </TooltipTrigger>
  );
};

CopyCheckSumButton.propTypes = {
  children: PropTypes.node,
  hideDelay: PropTypes.number,
  value: PropTypes.string.isRequired,
};

export default CopyCheckSumButton;
