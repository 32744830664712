import {navBus} from '@admin-tribe/acsc';
import {generatePath} from 'react-router-dom';

import rootStore from 'core/RootStore';
import {PATH_ALL_PACKAGES} from 'features/packages/routing/packagesPaths';

/**
 * @description Method to navigate to the Packages page
 */
const goToAllPackages = () => {
  navBus.navigate(
    generatePath(PATH_ALL_PACKAGES, {orgId: rootStore.organizationStore.activeOrgId})
  );
};

// eslint-disable-next-line import/prefer-default-export -- utils
export {goToAllPackages};
