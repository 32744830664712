import {
  CLOUD,
  DIRECTORY_OWNERSHIP_STATUS,
  DIRECTORY_STATUS,
  DIRECTORY_TYPE,
  DOMAIN_STATUS,
  MIGRATION_TYPE,
  hasContractInRenewalWindow as checkContractInRenewalWindow,
  hasPackageSupport as checkPackageSupport,
  hasTeamProducts as checkTeamProducts,
  getEarliestAnniversaryDate,
  getEarliestStartDate,
  getSeatBasedAssignableLicenseCount,
  getSeatBasedTotalProvisionedQuantity,
  hasProductsInCloud,
  jilDirectories,
} from '@admin-tribe/acsc';

import {getLinks} from 'features/settings/api/directory-sync';
import eduRosterSync from 'features/settings/api/eduRosterSync';
import {getAuthSources} from 'features/settings/api/ims-federated';
import {IDP_TYPES, SOIDC_NAMES} from 'features/settings/entities/IdpEntity';
import DirectoryStore from 'features/settings/stores/DirectoryStore';

import {getDirectoryList} from '../../common/hooks/api/useDirectoryList';
import {getTypeAndAudienceFromAutoAssignRules} from '../../common/services/sophia/shared-contextual-params/sharedContextualParamsUtils';
import AutoAssignRulesCache from '../services/product/AutoAssignRulesCache';

import {
  getEduSyncs,
  getPropertyValues,
  getTimestamp,
  isConfigured,
  isEDUIdPEligible,
  isType3,
} from './gainsightCommon';

/**
 * Retrieves license information from the productList.
 * @param {Object} productList - The productList object.
 * @returns {Object} - The license information.
 */
function getLicenseInformation(productList) {
  const licensesTotal = getSeatBasedAssignableLicenseCount(productList);
  const licensesAssigned = getSeatBasedTotalProvisionedQuantity(productList);
  const licensesUnassigned = licensesTotal - licensesAssigned;
  const autoAssignRules = AutoAssignRulesCache.get().autoAssignRules;
  const [jitRuleAudience, jitRuleType] = getTypeAndAudienceFromAutoAssignRules(autoAssignRules);
  return {jitRuleAudience, jitRuleType, licensesAssigned, licensesTotal, licensesUnassigned};
}

/**
 * Retrieves information about the organization.
 *
 * @param {Object} rootStore - The root store object.
 * @returns {Object} - An object containing various information about the organization.
 */
function getOrgInformation(rootStore) {
  const organizationStore = rootStore.organizationStore;
  const activeOrg = organizationStore.activeOrg;
  const productList = organizationStore.productList;
  const orgMarketSegment = activeOrg.marketSegment;
  const orgMarketSubsegments = activeOrg.getMarketSubsegments();
  const orgContracts = organizationStore.contractList;
  const orgMigrations = organizationStore.migrationList;

  return {
    orgContracts,
    orgMarketSegment,
    orgMarketSubsegments,
    orgMigrations,
    productList,
  };
}

/**
 * Retrieves information about the products.
 *
 * @param {Object} productList - The list of products.
 * @returns {Object} - An object containing various information about the products.
 */
function getProductsInformation(productList) {
  const hasPackageSupport = checkPackageSupport(productList);
  const hasCreativeCloudProducts = hasProductsInCloud(productList, CLOUD.CREATIVE);
  const hasDocumentCloudProducts = hasProductsInCloud(productList, CLOUD.DOCUMENT);
  const contractProductCodeList = productList.items.map((product) => product.code);
  const hasExperienceCloudProducts = hasProductsInCloud(productList, CLOUD.EXPERIENCE);
  const hasTeamProducts = checkTeamProducts(productList);
  const hasEnterpriseProducts = productList.hasETLAProducts || productList.hasEVIPProducts;
  const productFamilies = productList.items.map((product) => product.family);
  const hasSDLProducts = productList.items.some((item) =>
    item.fulfillableItemList.hasLaboratoryLicenseManagement()
  );
  const paCodeList = productList.items.map((product) => product.productArrangementCode);
  return {
    contractProductCodeList,
    hasCreativeCloudProducts,
    hasDocumentCloudProducts,
    hasEnterpriseProducts,
    hasExperienceCloudProducts,
    hasPackageSupport,
    hasSDLProducts,
    hasTeamProducts,
    paCodeList,
    productFamilies,
  };
}

/**
 * Retrieves information about authentication sources for the active organization.
 *
 * @param {Object} activeOrg - The active organization object.
 * @returns {Object} - An object containing information about the authentication sources.
 * @throws {Error} - If there is an error retrieving the authentication sources.
 */
async function getAuthSourcesInformation(activeOrg) {
  const authSources = await getAuthSources({orgId: activeOrg.id});

  const ownedFederatedAuthSources = authSources.data.filter(
    (item) => item.ownershipStatus === DIRECTORY_OWNERSHIP_STATUS.OWNED && item.type === 'fed'
  );
  const idps = ownedFederatedAuthSources.flatMap((item) => item.idps);
  // Check if there are no federated directories with an Education SSO provider
  const hasNoEduSSOproviders = idps.every((idp) => idp.federationType !== IDP_TYPES.SOIDC);

  // Check if there is at least one federated directory without Google OIDC

  const hasDirectoryWithNoGoogleOIDC = ownedFederatedAuthSources.some(
    (authSource) =>
      !authSource.idps.some(
        (idp) => idp.federationType === IDP_TYPES.SOIDC && idp.providerName === SOIDC_NAMES.GOOGLE
      )
  );

  // Check if there is at least one directory without JIT enabled on any of its IDPs
  const hasDirectoryWithNoJITAccount = ownedFederatedAuthSources.some((authSource) =>
    authSource.idps.every((idp) => !idp.jitConfig || idp.jitConfig.accountCreation === false)
  );

  return {
    hasDirectoryWithNoGoogleOIDC,
    hasDirectoryWithNoJITAccount,
    hasNoEduSSOproviders,
  };
}

/**
 * Retrieves contract information from the given orgContracts object.
 * @param {Object} orgContracts - The orgContracts object containing contract information.
 * @returns {Object} - An object containing various contract information.
 */
function getContractInformation(orgContracts) {
  const list = orgContracts.items;
  const customerSegments = getPropertyValues(list, 'customerSegment');
  const contractBuyingPrograms = getPropertyValues(list, 'buyingProgram');
  const earliestContractStartDate = getTimestamp(getEarliestStartDate(orgContracts));
  const hasContractInRenewalWindow = checkContractInRenewalWindow(orgContracts);
  const earliestContractAnniversaryDate = getTimestamp(getEarliestAnniversaryDate(orgContracts));
  const salesChannels = getPropertyValues(list, 'salesChannel');
  const models = getPropertyValues(list, 'model');
  return {
    contractBuyingPrograms,
    customerSegments,
    earliestContractAnniversaryDate,
    earliestContractStartDate,
    hasContractInRenewalWindow,
    models,
    salesChannels,
  };
}

/**
 * Retrieves migration information from the provided `orgMigrations` object.
 *
 * @param {Object} orgMigrations - The object containing the organization's migrations.
 * @returns {Object} - An object containing various migration information.
 */
function getMigrationInformation(orgMigrations) {
  const migrations = orgMigrations.items;
  const getMigrationStatus = (type) =>
    migrations.find((migration) => migration.type === type)?.status;

  const esmType1Migration = migrations.find(
    (migration) => migration.type === MIGRATION_TYPE.ESM_TYPE1
  );
  const t2eMigration = migrations.find((migration) => migration.type === MIGRATION_TYPE.T2E);
  const esmType1MigrationStatus = getMigrationStatus(MIGRATION_TYPE.ESM_TYPE1);
  const t2eMigrationStatus = getMigrationStatus(MIGRATION_TYPE.T2E);
  const orgMigrationStatusVIP2DIRECT = getMigrationStatus(MIGRATION_TYPE.VIP2DIRECT);

  // TODO : Need to Test all this variables with a test org which has metadata. All the current test orgs which i have, do not have metadata.
  // Before migration code also, fields like orgMigrationVIP2DIRECTStartDate, orgMigrationMALegacyToAdobeMetadataStartDate comes as Nan since metadata is undefined.
  const orgMigrationVIP2DIRECT = migrations.find(
    (migration) => migration.type === MIGRATION_TYPE.VIP2DIRECT
  )?.metadata?.scheduledStartDate;

  const orgMigrationVIP2DIRECTStartDate = orgMigrationVIP2DIRECT
    ? Date.parse(orgMigrationVIP2DIRECT)
    : '';

  const maLegacyToAdobeMigration = migrations.find(
    (migration) => migration.type === MIGRATION_TYPE.MA_LEGACY_TO_ADMIN_CONSOLE
  );

  const orgMigrationStatusMALegacyToAdobe = maLegacyToAdobeMigration?.status;

  const orgMigrationMALegacyToAdobeMetadataStartDate =
    maLegacyToAdobeMigration?.metadata?.scheduledStartDate;

  const orgMigrationMALegacyToAdobeStartDateInMs = orgMigrationMALegacyToAdobeMetadataStartDate
    ? Date.parse(orgMigrationMALegacyToAdobeMetadataStartDate)
    : '';

  const orgMigrationMALegacyToAdobeGainsightDataBlob = maLegacyToAdobeMigration
    ? maLegacyToAdobeMigration.getGainsightData().toString()
    : '';

  const orgMigrationMALegacyToAdobeProducts = maLegacyToAdobeMigration?.products;

  const esmType1MigrationStartDate = esmType1Migration?.metadata?.scheduledStartDate;

  const orgMigrationEsmStartDateInMs = esmType1MigrationStartDate
    ? Date.parse(esmType1MigrationStartDate)
    : '';

  const t2eMigrationStartDate = t2eMigration?.metadata?.scheduledStartDate;
  const orgMigrationT2EStartDateInMs = t2eMigrationStartDate
    ? Date.parse(t2eMigrationStartDate)
    : '';

  return {
    orgMigrationESMStartDate:
      orgMigrationEsmStartDateInMs === '' ? null : orgMigrationEsmStartDateInMs,
    orgMigrationMALegacyToAdobeGainsightDataBlob,
    orgMigrationMALegacyToAdobeProducts,
    orgMigrationMALegacyToAdobeStartDate:
      orgMigrationMALegacyToAdobeStartDateInMs === ''
        ? null
        : orgMigrationMALegacyToAdobeStartDateInMs,
    orgMigrationStatusESM: esmType1MigrationStatus,
    orgMigrationStatusMALegacyToAdobe,
    orgMigrationStatusT2E: t2eMigrationStatus,
    orgMigrationStatusVIP2DIRECT,
    orgMigrationT2EStartDate:
      orgMigrationT2EStartDateInMs === '' ? null : orgMigrationT2EStartDateInMs,
    orgMigrationVIP2DIRECTStartDate:
      orgMigrationVIP2DIRECTStartDate === '' ? null : orgMigrationVIP2DIRECTStartDate,
  };
}

/**
 * Retrieves synchronization information for the active organization.
 * @param {Object} activeOrg - The active organization object.
 * @returns {Object} - An object containing various synchronization information.
 */
async function getSyncInfo(activeOrg) {
  const eduRosterSyncs = await getEduSyncs(activeOrg);
  const directoryList = await getDirectoryList();
  const directories = directoryList.data;

  const orgDirectoryConfigurationStatuses = directories.map(
    (directory) =>
      `${directory.ownershipStatus}:${directory.status}:${
        isConfigured(directory) ? 'CONFIGURED' : 'NOT_CONFIGURED'
      }`
  );
  const hasDeprecatedOktaIdp = directories.some((item) => item.isPrimaryIdpOkta);

  const hasFederationSetup = directories.some((item) => isType3(item));
  const hasDirectoryWithNoDomain = directories.some(
    (item) => item.status === DIRECTORY_STATUS.NEEDS_DOMAIN && item.type === DIRECTORY_TYPE.TYPE3
  );
  const isEDURosterSyncEligible =
    hasFederationSetup && isEDUIdPEligible(activeOrg) && eduRosterSyncs.data.count === 0;

  const links = await getLinks(activeOrg.id);
  const syncSources = [...eduRosterSyncs.data.syncConfigs, ...links.data].map(
    (sync) => sync.partnerType || sync.rosterSource || 'Roster'
  );
  const hasNoSyncSetup = syncSources.length === 0;

  return {
    hasDeprecatedOktaIdp,
    hasDirectoryWithNoDomain,
    hasFederationSetup,
    hasNoSyncSetup,
    isEDURosterSyncEligible,
    orgDirectoryConfigurationStatuses,
    syncSources,
  };
}

// TODO : Test it thoroughly with different kind of data as i have tweaked the logic here
/**
 * Retrieves the Gainsight data for creating an Edu IDP creation event.
 *
 * @param {Object} options - The options for retrieving the Gainsight data.
 * @param {Object} options.activeOrg - The active organization.
 * @param {string} options.directoryId - The directory ID.
 * @param {Array} options.idps - The array of IDPs.
 * @returns {Promise<Object>} The Gainsight data for creating an Edu IDP creation event.
 */
async function getEduIdpCreationEventData({activeOrg, directoryId, idps}) {
  const mappedIdps = idps.map(
    (idp) => idp.providerName || idp.sniffedProvider || `${idp.federationType} Provider`
  );
  const gainsightData = {
    idps: mappedIdps,
  };

  const directoryStore = new DirectoryStore(directoryId);
  await directoryStore.sync.fetchData();
  const hasDirectoryLink = directoryStore.sync.isSynced;

  const {data} = await eduRosterSync.getAllDirectoryRosterSyncs({
    directoryId,
  });
  const hasEduRosterSyncs = data.length > 0;
  Object.assign(gainsightData, {hasActiveSync: hasDirectoryLink || hasEduRosterSyncs});

  const domains = await jilDirectories.getDomains({
    directoryId,
    orgId: activeOrg.id,
  });
  const hasActiveDomains = domains.data.some((domain) => domain.status === DOMAIN_STATUS.ACTIVE);
  Object.assign(gainsightData, {hasActiveDomains});

  return gainsightData;
}

/**
 * Loads the Gainsight script with the provided API key and URL.
 *
 * @param {string} gainsightApiKey - The Gainsight API key.
 * @param {string} gainsightUrl - The URL of the Gainsight script.
 */

// eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- unit testing is not needed here as it is library code
/* istanbul ignore next -- unit testing is not needed here as it is library code */
const loadGainsightScript = (gainsightApiKey, gainsightUrl) => {
  (function fn(n, t, a, e) {
    const i = 'aptrinsic';
    n[i] =
      n[i] ||
      function fn1() {
        (n[i].q = n[i].q || []).push(arguments); // eslint-disable-line prefer-rest-params -- this is a library function
      };
    n[i].p = e;
    const r = t.createElement('script');
    r.async = !0;
    r.src = `${a}?a=${e}`;
    const c = t.querySelectorAll('script')[0];
    c.parentNode.insertBefore(r, c);
  })(window, document, gainsightUrl, gainsightApiKey);
};

export {
  getOrgInformation,
  getLicenseInformation,
  getProductsInformation,
  getAuthSourcesInformation,
  getContractInformation,
  getMigrationInformation,
  getSyncInfo,
  getEduIdpCreationEventData,
  loadGainsightScript,
};
