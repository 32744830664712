import {AlertDialog, showError as showErrorToast} from '@admin-tribe/acsc-ui';
import {Content, useDialogContainer} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {useDirectoryContext} from 'features/settings/components/directory/DirectoryContext';

const RemoveExternallyManagedModal = observer(({clearCaches}) => {
  const dialog = useDialogContainer();
  const intl = useIntl();
  const {directoryStore} = useDirectoryContext();

  const onConfirmClick = async () => {
    try {
      await directoryStore.toggleExternallyManagedPolicy(false);
      clearCaches();
      dialog.dismiss();
    } catch (error) {
      showErrorToast(
        intl.formatMessage({
          id: 'settings.sync.error',
        })
      );
    }
  };

  return (
    <AlertDialog
      ctaLabel={intl.formatMessage({id: 'settings.sync.removeExternallyManaged.buttons.enable'})}
      id="remove-externally-managed-modal"
      isLoading={directoryStore.isLoading}
      // eslint-disable-next-line react/jsx-handler-names -- erroneous reporting
      onCancel={dialog.dismiss}
      onCta={onConfirmClick}
      title={intl.formatMessage({id: 'settings.sync.removeExternallyManaged.title'})}
      variant="warning"
    >
      <Content>
        <p>
          <FormattedMessage id="settings.sync.removeExternallyManaged.description.allow" />
        </p>
        <p>
          <FormattedMessage id="settings.sync.removeExternallyManaged.description.manualChange" />
        </p>
      </Content>
    </AlertDialog>
  );
});

RemoveExternallyManagedModal.propTypes = {
  clearCaches: PropTypes.func,
};

export default RemoveExternallyManagedModal;
