// ***********************************************************************
import {Flex} from '@adobe/react-spectrum';
// ADOBE CONFIDENTIAL
// ___________________
//
// Copyright 2024 Adobe
// All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains
// the property of Adobe and its suppliers, if any. The intellectual
// and technical concepts contained herein are proprietary to Adobe
// and its suppliers and are protected by all applicable intellectual
// property laws, including trade secret and copyright laws.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Adobe.
// ************************************************************************
import PropTypes from 'prop-types';
import React from 'react';

import QuickLinkButton from 'features/overview/components/quick-link-banner-section/QuickLinkButton';

const DynamicActionsSection = ({buttonsData, marginTop}) => (
  <Flex
    alignContent="center"
    alignItems="center"
    columnGap={{L: '24px', M: '24px'}}
    direction={{
      base: 'column',
      L: 'row',
      M: 'row',
      S: 'column',
    }}
    justifyContent="center"
    justifyItems="center"
    marginTop={marginTop}
    rowGap="18px"
  >
    {buttonsData.map((buttonData) => (
      <QuickLinkButton key={buttonData.testId} {...buttonData} />
    ))}
  </Flex>
);

DynamicActionsSection.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types -- button data array
  buttonsData: PropTypes.array,
  marginTop: PropTypes.string,
};

export default DynamicActionsSection;
