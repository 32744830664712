import {Subpage} from '@admin-tribe/acsc-ui';
import {PAGE_SIZE_OPTIONS, TABLE_SECTION_ACTIONS, TableSection} from '@pandora/react-table-section';
import {observer} from 'mobx-react-lite';
import React, {useEffect} from 'react';

import rootStore from 'core/RootStore';
import SingleAppFilters from 'features/packages/components/single-app-template-list-table/SingleAppFilters';
import SingleAppTemplateListTable from 'features/packages/components/single-app-template-list-table/SingleAppTemplateListTable';
import {usePregeneratedPackagesPageContext} from 'features/packages/pages/pregenerated-packages/PregeneratedPackagesPageContext';

/**
 * Single Applications subpage
 */
const SingleApplicationSubPage = observer(() => {
  const {packagesAdobeTemplatesStore} = usePregeneratedPackagesPageContext();
  const pageSizeOption = PAGE_SIZE_OPTIONS.DEFAULT;
  pageSizeOption[0].isDefault = true;
  pageSizeOption[1].isDefault = false;
  /** Keep the currently selected tab in sync with the URL */
  useEffect(() => {
    if (!packagesAdobeTemplatesStore.isLoading) {
      packagesAdobeTemplatesStore.fetchSingleAppFilters();
    }
  }, [packagesAdobeTemplatesStore, packagesAdobeTemplatesStore.isLoading]);

  const onTableSectionChange = ({action, payload}) => {
    switch (action) {
      case TABLE_SECTION_ACTIONS.GO_TO_NEXT_PAGE:
        packagesAdobeTemplatesStore.goToNextPage();
        break;
      case TABLE_SECTION_ACTIONS.GO_TO_PREVIOUS_PAGE:
        packagesAdobeTemplatesStore.goToPreviousPage();
        break;
      case TABLE_SECTION_ACTIONS.ON_PAGE_SIZE_CHANGE:
        packagesAdobeTemplatesStore.onPageSizeChange(payload);
        break;
      case TABLE_SECTION_ACTIONS.ON_SEARCH_SUBMIT:
        packagesAdobeTemplatesStore.search(payload.value);
        break;
      default:
        // Do nothing
        break;
    }
  };
  const {isLoading, isSingleAppPageLoading, hasLoadingError} = packagesAdobeTemplatesStore;
  const isPageLoading = isSingleAppPageLoading && !isLoading;
  return (
    <Subpage isLoading={isPageLoading}>
      <SingleAppFilters />
      <TableSection
        getItemKey={(item) => item.data.id}
        isServerError={rootStore.packagesUiConstantsStore.hasLoadingError || hasLoadingError}
        items={packagesAdobeTemplatesStore.singleAppsTableStore.rows}
        onTableSectionChange={onTableSectionChange}
        pageNumber={packagesAdobeTemplatesStore.singleAppsTableStore.currentPage}
        pageSizeOptions={pageSizeOption}
        totalPages={packagesAdobeTemplatesStore.singleAppsTableStore.totalPages}
      >
        <SingleAppTemplateListTable />
      </TableSection>
    </Subpage>
  );
});

SingleApplicationSubPage.propTypes = {};

export default SingleApplicationSubPage;
