import {
  getTeamToEnterpriseMigrationEligibleContract,
  isMigrating,
  isMigratingFromBusinessTrialsToPaid,
  isOrderProcessing,
} from '@admin-tribe/acsc';

import rootStore from 'core/RootStore';
import {TEAM_TO_ENTERPRISE_MIGRATION_MODAL_ID} from 'features/contract/migrations/migrationsConstants';
import {
  GLOBAL_BANNER_PRIORITY,
  GLOBAL_BANNER_TYPES,
} from 'features/global-banner/GlobalBannerConstants';

/**
 * @description Method for checking if contract migration global banners should
 *     be shown.
 */
function notifyContractMigrationBanners() {
  if (rootStore.organizationStore.contractList.items.length > 0) {
    const contractList = rootStore.organizationStore.contractList;
    if (isMigratingFromBusinessTrialsToPaid(contractList)) {
      rootStore.organizationStore.globalBannerStore.add(
        getBanner({message: 'globalBanner.messages.contract.migrations.businessTrialToPaid'})
      );
    } else if (isMigrating(contractList)) {
      rootStore.organizationStore.globalBannerStore.add(
        getBanner({message: 'globalBanner.messages.contract.migrations.migrating'})
      );
    }

    if (isOrderProcessing(contractList) && !isMigratingFromBusinessTrialsToPaid(contractList)) {
      rootStore.organizationStore.globalBannerStore.add({
        message: 'globalBanner.messages.contract.migrations.orderIsProcessing',
        type: GLOBAL_BANNER_TYPES.INFO,
      });
    }

    const teamToEnterpriseMigrationEligibleContract =
      getTeamToEnterpriseMigrationEligibleContract(contractList);
    if (teamToEnterpriseMigrationEligibleContract) {
      rootStore.organizationStore.globalBannerStore.add({
        buttonText: 'globalBanner.buttonText.teamToEnterpriseMigration',
        id: 'team-to-enterprise-migration-banner-id',
        message: 'globalBanner.messages.migrations.t2e.licensesExpiringSoon',
        messageArgs: {
          goUrlName: 'sdl_deployment',
        },
        modalId: TEAM_TO_ENTERPRISE_MIGRATION_MODAL_ID,
        type: GLOBAL_BANNER_TYPES.ERROR,
      });
    }
  }
}

function getBanner({message}) {
  return {
    message,
    priority: GLOBAL_BANNER_PRIORITY.CONTRACT_MIGRATIONS,
    type: GLOBAL_BANNER_TYPES.INFO,
  };
}

// eslint-disable-next-line import/prefer-default-export -- Utils file
export {notifyContractMigrationBanners};
