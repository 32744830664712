import {DetailSection, MoreOptionsMenu, getPersonName} from '@admin-tribe/acsc-ui';
import {Flex, View} from '@adobe/react-spectrum';
import {SupportTicket} from '@pandora/data-model-support-ticket';
import PropTypes from 'prop-types';
import React, {useRef, useState} from 'react';
import {useIntl} from 'react-intl';

import {SUPPORT_CONSTANTS} from '../../support.constants';
import SupportTicketContactsModalWrapper from '../support-ticket-contacts-modal-wrapper/SupportTicketContactsModalWrapper';

import styles from './SupportTicketContactsSection.pcss';

const {SUPPORT_TICKET_RECORD_TYPE} = SUPPORT_CONSTANTS;

const SupportTicketContactsSection = ({onUpdateSuccess, supportTicket}) => {
  const recordType = supportTicket.recordType.toUpperCase();
  const [showContactsModal, setShowContactsModal] = useState(false);
  const intl = useIntl();

  const adminFallbackName = intl.formatMessage({
    id: 'support.supportTicketContactsSection.adminFallbackName',
  });

  const menuItems = useRef([
    {
      body: intl.formatMessage({id: 'support.supportTicketContactsSection.buttons.updateContacts'}),
      key: 'UPDATE_CONTACTS',
    },
  ]).current;

  const getAgentName = () => {
    if (!supportTicket.isAgentAssigned()) {
      return intl.formatMessage({
        id: 'support.supportTicketAgentAssignedNameFilter.notYetAssigned',
      });
    }
    return getPersonName(intl, {
      firstName: supportTicket.agentAssigned?.firstName,
      fullName: supportTicket.agentAssigned?.fullName,
      lastName: supportTicket.agentAssigned?.lastName,
    });
  };

  const onUpdateContacts = () => {
    setShowContactsModal(false);
    onUpdateSuccess?.();
  };

  return (
    <DetailSection
      data-testid="support-ticket-contact-section"
      headingLevel={3}
      rightHeaderContent={
        supportTicket.isOpen() && (
          <MoreOptionsMenu
            buttonMarginStart="size-200"
            menuItems={menuItems}
            onItemSelected={() => setShowContactsModal(true)}
          />
        )
      }
      title={intl.formatMessage({id: 'support.supportTicketContactsSection.title'})}
    >
      <Flex direction="column" gap="size-100">
        {!SupportTicket.isMigrated && (
          <Flex direction="row" gap="size-100">
            <View
              data-testid="support-ticket-agent-label"
              minWidth="size-2000"
              // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- need font-size & font-color
              UNSAFE_className={styles['contact-title']}
            >
              {intl.formatMessage({
                id:
                  recordType.toUpperCase() === SUPPORT_TICKET_RECORD_TYPE.CASE
                    ? 'support.supportTicketContactsSection.adobeSupportAgent'
                    : 'support.supportTicketContactsSection.adobeSupportExpert',
              })}
            </View>
            <View data-testid="support-ticket-agent-name">{getAgentName()}</View>
          </Flex>
        )}
        <Flex direction="row" gap="size-100">
          <View
            data-testid="support-ticket-admin-label"
            minWidth="size-2000"
            // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- need font-size & font-color
            UNSAFE_className={styles['contact-title']}
          >
            {intl.formatMessage({
              id: 'support.supportTicketContactsSection.admin',
            })}
          </View>
          <View data-testid="support-ticket-admin-name">
            {getPersonName(intl, {
              fallbackName: adminFallbackName,
              firstName: supportTicket?.admin?.firstName,
              lastName: supportTicket?.admin?.lastName,
              userId: supportTicket?.admin?.rengaId,
            })}
          </View>
        </Flex>
      </Flex>
      {showContactsModal && (
        <SupportTicketContactsModalWrapper
          isOpen={showContactsModal}
          onClose={() => setShowContactsModal(false)}
          onSuccess={onUpdateContacts}
          supportTicket={supportTicket}
        />
      )}
    </DetailSection>
  );
};

SupportTicketContactsSection.propTypes = {
  /**
   * Callback function to be called after updating contact
   */
  onUpdateSuccess: PropTypes.func,
  /**
   * Support Ticket data model
   */
  supportTicket: PropTypes.instanceOf(Object),
};

export default SupportTicketContactsSection;
