import {feature} from '@admin-tribe/acsc';

import {notifyContractComplianceBanners} from 'core/contract/contract-compliance/contractComplianceNotifier';
import {notifyContractComplianceTrialBanners} from 'core/contract/contract-compliance-trial/contractComplianceTrialNotifier';
import {notifyContractRenewalBanners} from 'core/contract/contract-renewal/contractRenewalNotifier';
import {notifyContractStateBanners} from 'core/contract/contract-state/contractStateNotifier';
import {notifyContractMigrationBanners} from 'core/contract/migrations/contractMigrationNotifier';
import {notifyTermsAndConditionsBanners} from 'core/contract/terms-and-conditions/termsAndConditionsNotifier';
import {notifyThreeYearCommitGlobalBanner} from 'core/contract/three-year-commit-global-banner/threeYearCommitGlobalBannerNotifier';
import {notifyAccessingAsAdobeAgentBanner} from 'core/global-banners/adobe-agents/adobeAgentAccessNotifier';
import {notifyOrganizationMigrationBanners} from 'core/migration/organizationMigrationNotifier';
import {notifyOrganizationSettingsBanners} from 'core/organizations/organization-settings/organizationSettingsNotifier';
import {notifyAutoAssignRuleErrorBanners} from 'core/products/auto-assignment/productAutoAssignmentNotifier';
import {notifyCertificatesBanners} from 'core/settings/certificates/certificatesNotifier';

/**
 * Generate global banners for the application.
 */
const generateGlobalBanners = () => {
  // Agent Access is always first, so it isn't lost to internal users
  notifyAccessingAsAdobeAgentBanner();
  // These are first to ensure they're most visible
  notifyContractMigrationBanners();
  notifyContractComplianceTrialBanners();
  notifyTermsAndConditionsBanners();
  notifyOrganizationMigrationBanners();
  notifyContractComplianceBanners();
  notifyContractRenewalBanners();

  if (feature.isEnabled('temp_vip_mp_m7_3yc')) {
    notifyThreeYearCommitGlobalBanner();
  }

  notifyContractStateBanners();
  notifyOrganizationSettingsBanners();
  notifyAutoAssignRuleErrorBanners();

  if (feature.isEnabled('temp_saml_certs')) {
    notifyCertificatesBanners();
  }
};

export default generateGlobalBanners;
