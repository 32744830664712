import {
  TRUST_STATUS,
  feature,
  isMigrating,
  someByFulfillableItemCondition,
} from '@admin-tribe/acsc';
import {when} from 'mobx';

import {
  getDirectoryList,
  getDirectoryListWithCertificates,
} from 'common/hooks/api/useDirectoryList';
import rootStore from 'core/RootStore';
import {isOrgAdmin} from 'core/organizations/access/organizationAccess';
import {getDomainList} from 'features/settings/hooks/api/useDomainList';
import {getTrustList} from 'features/settings/hooks/api/useTrustList';
/**
 * @returns {Promise<Boolean>} - true/false if org can add domains
 */
function canAddDomains() {
  return hasDomainClaiming();
}

/**
 * @returns {Promise<Boolean>} - true/false if user can view identity domains
 */
async function canViewDomains() {
  if (!isOrgAdmin()) return false;

  const [canClaimDomains, hasDomainsPresent] = await Promise.all([
    hasDomainClaiming(),
    hasDomains(),
  ]);

  return canClaimDomains || hasDomainsPresent;
}

/**
 * @returns {Promise<Boolean>} - true/false if user can create directories
 *          an admin in an org with domain claiming can create directories
 */
function canCreateDirectory() {
  return canViewDomains();
}

/**
 *
 * @returns {Promise<Boolean>} - true if org can create type 2 or 3, false otherwise
 */
async function canCreateType2Or3() {
  await when(() => !!rootStore.organizationStore.contractList);
  const contractList = rootStore.organizationStore.contractList;

  if (isMigrating(contractList)) {
    return false;
  }
  return hasDomainClaiming();
}

/**
 * Method to determine, based on the list of products in the org, if the identity type
 * can be switched.
 *
 * @returns {Boolean} True if identity type can be switched, else false.
 */
function canSwitchIdentityTypes() {
  const contractList = rootStore.organizationStore.contractList;

  if (isMigrating(contractList)) {
    return false;
  }

  if (isOrgAdmin()) {
    const productList = rootStore.organizationStore.productList;
    return someByFulfillableItemCondition(productList, (fItem) => fItem.hasDomainClaiming());
  }
  return false;
}

/**
 * Method to determine based on the user roles, domains and directories in the org,
 * if the list of directories can be viewed.
 * If directories can be viewed, directory users can be viewed.
 *
 * @returns {Promise<Boolean>} True if directories can be viewed, else false.
 */
async function canViewDirectories() {
  if (!isOrgAdmin()) {
    return false;
  }

  const hasDirectoriesPresent = await hasDirectories();
  const hasDomainClaimingAvailable = await hasDomainClaiming();

  return hasDirectoriesPresent || hasDomainClaimingAvailable;
}

let trustsShown = false;
/**
 * Trusts route can be accessed if the org has access requests or trusts.
 * When these conditions are negated through user action (deleting all trusts/requests), the route should still be
 * accessible until the next time the app is loaded.
 *
 * @returns Promise<Boolean> true if trusts route can be accessed, false otherwise.
 */
async function canViewTrusts() {
  if (trustsShown) {
    return true;
  }

  const hasTrust = await hasTrusts();

  // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- once hasTrusts is set to true we cannot test the other conditions that set it to true
  /* istanbul ignore else -- not testing this */
  if (hasTrust) {
    trustsShown = true;
    return trustsShown;
  }

  // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- see explanation for "ignore else" above
  /* istanbul ignore next -- not testing this */
  const hasAccessRequest = await hasAccessRequests();

  // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- see explanation for "ignore else" above
  /* istanbul ignore next -- not testing this */
  trustsShown = hasAccessRequest;

  // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- see explanation for "ignore else" above
  /* istanbul ignore next -- not testing this */
  return trustsShown;
}

let accessRequestsShown = false;
/**
 * Access requests can be accessed if the org has access requests present.
 * When this condition is negated through user action (deleting all requests), the route should still be
 * accessible until the next time the app is loaded.
 *
 * @returns Promise<Boolean> true if access requests route can be accessed, false otherwise.
 */
async function canViewAccessRequests() {
  if (accessRequestsShown) {
    return true;
  }

  const hasAccessRequest = await hasAccessRequests();

  if (hasAccessRequest) {
    accessRequestsShown = true;
    return true;
  }

  return false;
}

/**  ------------------------ PRIVATE METHODS  ------------------------ */

/**
 * @returns {Promise<Boolean>} - true/false if org can add/claim domains
 */
async function hasDomainClaiming() {
  await when(() => !!rootStore.organizationStore.productList);
  const productList = rootStore.organizationStore.productList;

  const fItemsHaveDomainClaiming = someByFulfillableItemCondition(productList, (fItem) =>
    fItem.hasDomainClaiming()
  );

  await when(() => !!rootStore.organizationStore.migrationList);
  const migrationList = rootStore.organizationStore.migrationList;

  const migratingOrgHasDomainClaiming = migrationList.shouldForceAllowDomainClaiming();
  return fItemsHaveDomainClaiming || migratingOrgHasDomainClaiming;
}

/**
 * @returns {Promise<Boolean>} true if org has directories, false otherwise
 */
async function hasDirectories() {
  const response = feature.isEnabled('temp_saml_certs')
    ? await getDirectoryListWithCertificates()
    : await getDirectoryList();
  return response.totalCount > 0;
}

/**
 * @returns {Promise<Boolean>} true if org has domains, false otherwise
 */
async function hasDomains() {
  const response = await getDomainList();
  return response.totalCount > 0;
}

/**
 * @returns {Promise<Boolean>} true if org has trusts, false otherwise
 */
async function hasTrusts() {
  const response = await getTrustList();
  return response.totalCount > 0;
}

/**
 * @returns {Promise<Boolean>} true if org has access requests, false otherwise
 */
async function hasAccessRequests() {
  const response = await getTrustList({status: TRUST_STATUS.PENDING});
  return response.totalCount > 0;
}

export {
  canAddDomains,
  canCreateDirectory,
  canCreateType2Or3,
  canSwitchIdentityTypes,
  canViewAccessRequests,
  canViewDirectories,
  canViewDomains,
  canViewTrusts,
};
