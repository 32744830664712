import {InsightsDetailsLink, Page, PageContent, PageHeader, Subpage} from '@admin-tribe/acsc-ui';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';
import {useLoaderData} from 'react-router-dom';

import useDocumentTitle from 'common/hooks/useDocumentTitle';

import InsightsBreadcrumbs from '../components/InsightsBreadcrumbs';
import InsightsDetailsTabs from '../components/InsightsDetailsTabs';
import {getTranslationFromReportBroker} from '../insightsUtils';

/** Page which shows Insight Item details */
const InsightsSectionDetailsPage = ({insightsSection}) => {
  const intl = useIntl();
  const {insightsItem} = useLoaderData();

  const title =
    getTranslationFromReportBroker(intl, insightsItem?.nameKey, insightsItem?.name) || '';

  useDocumentTitle({defaultTitle: title});

  return (
    <Page data-testid={`app-insights-${insightsSection}-details`}>
      {insightsItem && <InsightsBreadcrumbs insightsItem={insightsItem} />}
      <PageHeader title={title} />
      <PageContent>
        <Subpage>
          {insightsItem?.details && <InsightsDetailsLink details={insightsItem.details} />}
          {insightsItem && <InsightsDetailsTabs insightsItem={insightsItem} />}
        </Subpage>
      </PageContent>
    </Page>
  );
};

InsightsSectionDetailsPage.propTypes = {
  insightsSection: PropTypes.string,
};

export default InsightsSectionDetailsPage;
