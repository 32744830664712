import {View} from '@adobe/react-spectrum';
import {GoUrl} from '@pandora/react-go-url';
import {OptionSelector} from '@pandora/react-option-selector';
import {VisuallyHidden} from '@react-aria/visually-hidden';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';

import AzureLogo from 'features/settings/assets/azure-logo.svg';
import EduLogo from 'features/settings/assets/edu-logo.svg';
import GoogleLogo from 'features/settings/assets/google-logo.svg';
import OptionSelectorWrapper from 'features/settings/common/components/option-selector-wrapper/OptionSelectorWrapper';
import {canAddEdu} from 'features/settings/common/utils/eduUtils';
import SyncSourceCard from 'features/settings/components/sync-users-modal/SyncSourceCard';

const SYNC_SOURCES = {
  AZURE: {goUrl: 'add_azure_sync_prod', logo: AzureLogo, name: 'azure', syncType: 'AZURE'},
  GOOGLE: {goUrl: 'add_google_sync_prod', logo: GoogleLogo, name: 'google', syncType: 'GSUITE'},
};

const EDU_SYNC_SOURCE = {
  EDU: {goUrl: 'edu_roster_sync', logo: EduLogo, name: 'edu', syncType: 'EDU'},
};

const SyncSourceCards = ({onCardSelected}) => {
  const intl = useIntl();

  const AVAILABLE_SYNC_SOURCES = useMemo(
    () =>
      // Only allow adding EDU sync if the market segment is GOV or COM,
      // or `force_edu_idps` flag is on
      canAddEdu() ? {...SYNC_SOURCES, ...EDU_SYNC_SOURCE} : SYNC_SOURCES,
    []
  );

  return (
    <>
      <OptionSelectorWrapper>
        <OptionSelector
          data={Object.values(AVAILABLE_SYNC_SOURCES)}
          options={{
            on_selection_change: (event) => event.selected && onCardSelected(event.data.syncType),
          }}
          react_function={(props) => (
            <View>
              <SyncSourceCard {...props} />
            </View>
          )}
        />
      </OptionSelectorWrapper>

      <VisuallyHidden>
        {Object.values(AVAILABLE_SYNC_SOURCES).map((syncSource) => (
          <GoUrl
            key={syncSource.name}
            aria-hidden="true"
            name={
              // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- cannot change constant exported here
              /* istanbul ignore next-line */
              syncSource.goUrl || 'aac_identity_learn_more'
            }
          >
            {intl.formatMessage({
              id: `settings.sync.syncSourceCards.${syncSource.name}.learnMore`,
            })}
          </GoUrl>
        ))}
      </VisuallyHidden>
    </>
  );
};

SyncSourceCards.propTypes = {
  onCardSelected: PropTypes.func,
};

export default SyncSourceCards;
