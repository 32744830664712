import {DetailSection} from '@admin-tribe/acsc-ui';
import {Content, Header, Heading, View} from '@adobe/react-spectrum';
import {Drawer} from '@pandora/react-drawer';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import UserFolder from 'common/services/user-folder/UserFolder';

import LinksList from '../links-list/LinksList';
import UsageValue from '../usage-value/UsageValue';
import getNamesOfOwners from '../utils/getNamesOfOwners';

/**
 * This is a detail drawer available on the Individual Folders tab in Storage
 */
const UserFolderDetailsDrawer = ({selectedFolder}) => {
  const intl = useIntl();

  const {owners, quota} = selectedFolder;

  return (
    <Drawer aria-labelledby="user-folder-detail">
      <Header>
        <Heading id="user-folder-detail" level={2} marginBottom="size-125" marginTop="size-0">
          {getNamesOfOwners(intl, owners)}
        </Heading>
      </Header>

      <Content>
        <DetailSection
          title={intl.formatMessage({
            id: 'storage.userFolderDetailsDrawer.ownerDetailSection.title',
          })}
        >
          <LinksList marginTop="size-125" users={selectedFolder.owners} />
        </DetailSection>
        <DetailSection
          title={intl.formatMessage({
            id: 'storage.userFolderDetailsDrawer.quotaDetailSection.title',
          })}
        >
          <View id="usage-value" marginTop="size-125">
            <UsageValue amount={quota.consumed} total={quota.amount} />
          </View>
        </DetailSection>
      </Content>
    </Drawer>
  );
};

UserFolderDetailsDrawer.propTypes = {
  /**
   * The selectedFolder property contains all the folder info needed in the drawer
   */
  selectedFolder: PropTypes.instanceOf(UserFolder).isRequired,
};

export default UserFolderDetailsDrawer;
