import {feature} from '@admin-tribe/acsc';
import {Flex, Text, View} from '@adobe/react-spectrum';
import InfoIcon from '@spectrum-icons/workflow/Info';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';
import {
  ACTIVATION_CODE_VALID_LENGTH,
  MAX_ACTIVATION_CODE_VALID_LENGTH,
} from 'features/packages/packagesConstants';
import PackagesIngestAnalytics from 'features/packages/services/PackagesIngestAnalyticsService';

import {useCreatePackageModalContext} from '../../CreatePackageModalContext';

import ActivationCodeInput from './ActivationCodeInput';
import CodeGeneratorToolMenuButton from './CodeGeneratorToolMenuButton';
import DragAndDropFile from './DragAndDropFile';

const ActivationCodesPage = observer(() => {
  const intl = useIntl();

  const {createPackageModalStore} = useCreatePackageModalContext();

  let hasReachedMaxActivationCodeCount = false;

  const maxActivationCodeCount = 1000;

  const updateAllCodeList = () => {
    createPackageModalStore.setPkgSessionSettingValues('frlIsolatedMachineCodes', [
      ...createPackageModalStore.challengeCodeList
        .filter((challengeCode) =>
          feature.isEnabled('temp_package_umi_challenge_code')
            ? challengeCode.code.length <= MAX_ACTIVATION_CODE_VALID_LENGTH &&
              challengeCode.code.length > 0 &&
              challengeCode.isValid
            : challengeCode.code.length === ACTIVATION_CODE_VALID_LENGTH && challengeCode.isValid
        )
        .map((challengeCode) => challengeCode.code),
      ...(createPackageModalStore.fileChallengeCodeList?.map(
        (fileChallengeCode) => fileChallengeCode.code
      ) ?? []),
    ]);

    hasReachedMaxActivationCodeCount =
      createPackageModalStore.pkgSessionSettings.frlIsolatedMachineCodes.length >
      maxActivationCodeCount;

    createPackageModalStore.setPkgSessionSettingValues(
      'nextButtonEnabled',
      !createPackageModalStore.challengeCodeList.some((file) => file.isValid === false) &&
        !createPackageModalStore.addedFiles.some((file) => file.isValid === false) &&
        !hasReachedMaxActivationCodeCount
    );
  };

  const getMaximumId = () =>
    Math.max(
      ...createPackageModalStore.challengeCodeList.map((challengeCode) => challengeCode.id),
      0
    );

  const addNewActivationCodeBlock = () => {
    const activationCode = {};
    activationCode.id = getMaximumId() + 1;
    activationCode.code = '';
    activationCode.codeInput = '';
    activationCode.isValid = true;
    createPackageModalStore.addChallengeCode(activationCode);
  };

  function removeActivationCodeBlock(id) {
    const removedElementList = createPackageModalStore.challengeCodeList.filter(
      (activationCode) => activationCode.id !== id
    );

    createPackageModalStore.setChallengeCodeList([...removedElementList]);
    updateAllCodeList();
  }

  function downloadTool(toolUrl, analyticsEventName) {
    PackagesIngestAnalytics.triggerAnalytics({
      subCategory: 'FRL',
      subType: 'offline-activation-code-generator',
      type: 'click',
      value: analyticsEventName === 'censusToolDownloadWin' ? 'Windows' : 'Mac',
    });
    // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- Open Url
    window.open(toolUrl, '_self');
  }

  function onDownloadButtonClicked(key) {
    switch (key) {
      case 'mac':
        downloadTool(
          rootStore.packagesUiConstantsStore.adobeCodeGeneratorToolMacUrl,
          'censusToolDownloadMac'
        );
        break;
      case 'macarm':
        downloadTool(
          rootStore.packagesUiConstantsStore.adobeCodeGeneratorToolMacArmUrl,
          'censusToolDownloadMacArm'
        );
        break;
      case 'win':
        downloadTool(
          rootStore.packagesUiConstantsStore.adobeCodeGeneratorToolWinUrl,
          'censusToolDownloadWin'
        );
        break;
      case 'winarm':
        downloadTool(
          rootStore.packagesUiConstantsStore.adobeCodeGeneratorToolWinArmUrl,
          'censusToolDownloadWinArm'
        );
        break;
      default:
        break;
    }
  }

  const cardMenuItems = [
    {
      displayString: intl.formatMessage({
        id: 'packages.tools.adminTools.downloadPlatforms.mac',
      }),
      key: 'mac',
      toShow: !!rootStore.packagesUiConstantsStore.adobeCodeGeneratorToolMacUrl,
    },
    {
      displayString: intl.formatMessage({
        id: 'packages.tools.adminTools.downloadPlatforms.macarm',
      }),
      key: 'macarm',
      toShow: !!rootStore.packagesUiConstantsStore.adobeCodeGeneratorToolMacArmUrl,
    },
    {
      displayString: intl.formatMessage({
        id: 'packages.tools.adminTools.downloadPlatforms.win',
      }),
      key: 'win',
      toShow: !!rootStore.packagesUiConstantsStore.adobeCodeGeneratorToolWinUrl,
    },
    {
      displayString: intl.formatMessage({
        id: 'packages.tools.adminTools.downloadPlatforms.winarm',
      }),
      key: 'winarm',
      toShow: !!rootStore.packagesUiConstantsStore.adobeCodeGeneratorToolWinArmUrl,
    },
  ];

  return (
    <>
      <View
        height="size-1700"
        UNSAFE_style={{backgroundColor: 'var(--spectrum-global-color-gray-75)'}}
      >
        <Flex alignItems="center" direction="row" justifyContent="space-between">
          <Flex direction="column" marginStart="size-300" marginTop="size-350" width="size-5000">
            <Text UNSAFE_style={{fontSize: 'size-175', fontWeight: 'bold'}}>
              {intl.formatMessage({
                id: 'packages.createPackage.challengeCodes.prerequisite',
              })}
            </Text>
            <Text>
              {intl.formatMessage({
                id: 'packages.createPackage.challengeCodes.prerequisiteDesc',
              })}
            </Text>
          </Flex>
          <View marginEnd="size-600" marginTop="size-350">
            <CodeGeneratorToolMenuButton
              buttonString={intl.formatMessage({
                id: 'packages.createPackage.challengeCodes.downloadButtonText',
              })}
              cardMenuItems={cardMenuItems}
              data-testid="toolMenuButton"
              onCardMenuItemPress={(key) => onDownloadButtonClicked(key)}
            />
          </View>
        </Flex>
      </View>
      <Flex direction="column" marginStart="size-300" marginTop="size-250">
        <Text UNSAFE_style={{fontSize: 'size-175', fontWeight: 'bold'}}>
          {intl.formatMessage({
            id: 'packages.createPackage.challengeCodes.title',
          })}
        </Text>
        <Text marginTop="size-125">
          {intl.formatMessage({
            id: 'packages.createPackage.challengeCodes.titleDesc',
          })}
        </Text>
        <Flex alignItems="center" direction="row" marginTop="size-75">
          <InfoIcon marginEnd="size-75" size="S" UNSAFE_style={{color: 'var(--color-grey-700)'}} />
          <Text>{intl.formatMessage({id: 'packages.createPackage.challengeCodes.noteDesc'})}</Text>
        </Flex>
        {createPackageModalStore.challengeCodeList.map((activationCode) => (
          <ActivationCodeInput
            key={activationCode.id}
            activationCodeObject={activationCode}
            addNewActivationCodeBlock={addNewActivationCodeBlock}
            data-testid={`challengeCodeListItem-${activationCode.id}`}
            removeActivationCodeBlock={() => removeActivationCodeBlock(activationCode.id)}
            updateAllCodeList={updateAllCodeList}
          />
        ))}
        <Text marginBottom="size-100" marginTop="size-500">
          {intl.formatMessage({
            id: 'packages.createPackage.challengeCodes.fileUpload.title',
          })}
        </Text>
        <DragAndDropFile
          maxActivationCodeCount={maxActivationCodeCount}
          updateAllCodeList={updateAllCodeList}
        />
      </Flex>
    </>
  );
});
ActivationCodesPage.propTypes = {};
export default ActivationCodesPage;
