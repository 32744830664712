import {LicenseGroup, LicenseGroupConfigurations, Product, log} from '@admin-tribe/acsc';
import {Subpage, showError, showSuccess} from '@admin-tribe/acsc-ui';
import {Flex, SearchField, View} from '@adobe/react-spectrum';
import {useAsyncModel} from '@pandora/react-async-model';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, {useCallback, useState} from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';

import CopyPermissionsButton from './copy-permissions/CopyPermissionsButton';
import EditProductProfilePermissionsModal from './edit-product-profile-permissions/EditProductProfilePermissionsModal';
import PermissionsTableList from './permissions-tables/PermissionsTableList';
import {
  canCopyPermissions,
  canSearchPermissions,
  getFilteredConfigurations,
  isSearching,
} from './productProfilePermissionsUtils';

/**
 * The subpage for the product profile/license configuration permissions.
 */
const ProductProfilePermissions = observer(({licenseGroup, product}) => {
  const intl = useIntl();
  const orgId = rootStore.organizationStore.activeOrgId;

  const [searchValue, setSearchValue] = useState('');
  const [editModalState, setEditModalState] = useState({
    configurationGroups: [],
    configurationIndex: null,
    initialGroupId: null,
    isOpen: false,
    sectionId: null,
  });
  const [isSaving, setIsSaving] = useState(false);

  const fetchLicenseGroupConfigurations = useCallback(
    () =>
      LicenseGroupConfigurations.get({
        licenseGroupId: licenseGroup.id,
        orgId,
        productId: product.id,
        usesLicenseGroupConfiguration: product.hasConfigurationSettingForLicenseGroup(),
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps -- exclude product since licenseGroup does not change if product updates
    [licenseGroup.id, orgId]
  );

  const {
    error: licenseGroupConfigurationsError,
    isLoading: isLicenseGroupConfigurationsLoading,
    model: licenseGroupConfigurations,
  } = useAsyncModel({
    loadFn: fetchLicenseGroupConfigurations,
  });

  const filteredItems = getFilteredConfigurations(licenseGroupConfigurations, searchValue);
  const canSearchPermissionsResult = canSearchPermissions(licenseGroupConfigurations);
  const canCopyPermissionsResult = canCopyPermissions(licenseGroupConfigurations);

  const onSaveEditModal = async (updatedGroups) => {
    setIsSaving(true);
    setEditModalState({...editModalState, isOpen: false});
    try {
      licenseGroupConfigurations.configurations[editModalState.configurationIndex].sections.find(
        (section) => section.id === editModalState.sectionId
      ).content = updatedGroups;
      await licenseGroupConfigurations.save();
      showSuccess(
        intl.formatMessage({id: 'products.productProfilePermissions.table.edit.saveSuccess'})
      );
    } catch (error) {
      log.error(error);
      licenseGroupConfigurations.restore();
      showError(
        intl.formatMessage({id: 'products.productProfilePermissions.table.edit.saveError'})
      );
    } finally {
      setIsSaving(false);
      setEditModalState({
        configurationGroups: [],
        configurationIndex: null,
        initialGroupId: null,
        isOpen: false,
        sectionId: null,
      });
    }
  };

  const onPermissionRowEditClicked = (
    configurationGroups,
    groupId,
    sectionId,
    configurationIndex
  ) => {
    setEditModalState({
      configurationGroups,
      configurationIndex,
      initialGroupId: groupId,
      isOpen: true,
      sectionId,
    });
  };

  const onCloseEditModal = () => {
    setEditModalState({
      configurationGroups: [],
      configurationIndex: null,
      initialGroupId: null,
      isOpen: false,
      sectionId: null,
    });
  };

  return (
    <Subpage
      data-testid="product-profile-permissions-subpage"
      isBumpered={!!licenseGroupConfigurationsError}
      isLoading={isLicenseGroupConfigurationsLoading || isSaving}
    >
      {licenseGroup && licenseGroupConfigurations && (
        <>
          <Flex alignItems="end" direction="row" gap="size-200" marginTop="size-200" width="100%">
            <View flexGrow="1">
              <SearchField
                data-testid="permission-search-field"
                isDisabled={!canSearchPermissionsResult}
                label={intl.formatMessage({
                  id: 'products.productProfilePermissions.searchLabel',
                })}
                onClear={() => setSearchValue('')}
                onSubmit={setSearchValue}
                width="size-3600"
              />
            </View>

            {canCopyPermissionsResult && (
              <View data-testid="permission-copy-button">
                <CopyPermissionsButton
                  licenseGroupConfigurations={licenseGroupConfigurations}
                  orgId={orgId}
                  product={product}
                />
              </View>
            )}
          </Flex>

          <PermissionsTableList
            configurations={filteredItems}
            isSearching={isSearching(searchValue)}
            onPermissionRowEditClicked={onPermissionRowEditClicked}
          />
          <EditProductProfilePermissionsModal
            configurationGroups={editModalState.configurationGroups}
            initialGroupId={editModalState.initialGroupId}
            isOpen={editModalState.isOpen}
            licenseGroupName={licenseGroup.name}
            onClose={onCloseEditModal}
            onSave={onSaveEditModal}
            productName={product.shortName}
          />
        </>
      )}
    </Subpage>
  );
});

ProductProfilePermissions.propTypes = {
  /**
   * The LicenseGroup model.
   */
  licenseGroup: PropTypes.instanceOf(LicenseGroup),
  /**
   * The Product model.
   */
  product: PropTypes.instanceOf(Product),
};

export default ProductProfilePermissions;
