import {DirectoryUser} from '@admin-tribe/acsc';
import {
  Avatar,
  Page,
  PageBreadcrumbs,
  PageContent,
  PageHeader,
  PageNav,
  getMemberDisplayName,
} from '@admin-tribe/acsc-ui';
import {Item} from '@adobe/react-spectrum';
import {useAsyncModel} from '@pandora/react-async-model';
import React, {useCallback, useState} from 'react';
import {useIntl} from 'react-intl';
import {useLoaderData, useParams} from 'react-router-dom';

import UserDetailsSection from 'common/components/user-details-section/UserDetailsSection';
import useDocumentTitle from 'common/hooks/useDocumentTitle';
import DirectoryUserMembershipSection from 'features/users/components/directory-user-membership-section/DirectoryUserMembershipSection';
import EditUserDetailsModal from 'features/users/edit-user-details-modal/EditUserDetailsModal';
import {
  goToDirectoryList,
  goToDirectoryUsers,
} from 'features/users/routing/navigation-callbacks/navigationCallbacks';

const DIRECTORY_LIST_ITEM = 'directory-list';
const DIRECTORY_ITEM = 'directory';

/**
 * The Directory User details page.
 */
const DirectoryUserDetailsPage = () => {
  const {directoryId, orgId, userId} = useParams();
  const {directory} = useLoaderData();

  const intl = useIntl();

  const [isModalOpened, setIsModalOpened] = useState(false);

  const fetchDirectoryUser = useCallback(
    () =>
      DirectoryUser.get({
        directoryId,
        orgId,
        userId,
      }), // eslint-disable-next-line react-hooks/exhaustive-deps -- no dependencies
    []
  );

  const {
    model: member,
    isLoading,
    error,
    updateModel,
  } = useAsyncModel({loadFn: fetchDirectoryUser});

  const onSuccess = () => {
    setIsModalOpened(false);
    // Re-render page when onSuccess is triggered.
    updateModel(fetchDirectoryUser);
  };

  const onBreadcrubms = (item) => {
    // eslint-disable-next-line default-case -- no default case
    switch (item) {
      case DIRECTORY_LIST_ITEM:
        goToDirectoryList();
        break;
      case DIRECTORY_ITEM:
        goToDirectoryUsers({directoryId});
        break;
    }
  };

  const showContent = !error && !isLoading;

  const title = showContent
    ? getMemberDisplayName(intl, member, {
        fallbackToEmail: false,
      })
    : '';
  useDocumentTitle({defaultTitle: title});

  return (
    <Page data-testid="directory-user-details-page" isBumpered={!!error} isLoading={isLoading}>
      {showContent && (
        <PageBreadcrumbs isDisabled={false} onAction={onBreadcrubms}>
          <Item key={DIRECTORY_LIST_ITEM}>
            {intl.formatMessage({id: 'users.ownedDirectoryListPage.title'})}
          </Item>
          <Item key={DIRECTORY_ITEM}>{directory.name}</Item>
          <Item key="name">{title}</Item>
        </PageBreadcrumbs>
      )}
      {showContent && (
        <PageHeader title={title}>
          <Avatar member={member} size="S" />
        </PageHeader>
      )}
      <PageNav />
      <PageContent>
        {showContent && (
          <>
            {isModalOpened && (
              <EditUserDetailsModal
                isOpen
                onCancel={() => setIsModalOpened(false)}
                onSuccess={onSuccess}
                orgId={orgId}
                user={member}
              />
            )}
            <UserDetailsSection
              headingLevel={2}
              isPageVariant
              member={member}
              onMenuActionPress={() => setIsModalOpened(true)}
            />
            <DirectoryUserMembershipSection
              directoryId={directoryId}
              headingLevel={2}
              isPageVariant
              memberId={member.id}
            />
          </>
        )}
      </PageContent>
    </Page>
  );
};

export default DirectoryUserDetailsPage;
