import {isMigrationListESMType1} from '@admin-tribe/acsc';
import {showError, showSuccess} from '@admin-tribe/acsc-ui';
import {AlertDialog, Button, DialogContainer} from '@adobe/react-spectrum';
import {GoUrl} from '@pandora/react-go-url';
import {useTableSectionContext} from '@pandora/react-table-section';
import {toJS} from 'mobx';
import {observer} from 'mobx-react-lite';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import RemoveUsersWithStorageModal from 'common/components/remove-users-with-storage-modal/RemoveUsersWithStorageModal';
import {useMemberListContext} from 'common/hooks/member-list-context/MemberListContext';
import UserFolder from 'common/services/user-folder/UserFolder';
import {REMOVE_USER_STORAGE_OPTIONS} from 'common/services/user-folder/userFolderConstants';
import {getTableMsgSubkey, onRemoveSelectedMembers} from 'common/utils/member-utils/memberUtils';
import rootStore from 'core/RootStore';
import {canViewStorage} from 'core/storage/access/storageAccess';

import DirectoryUsersContextualHelp from './DirectoryUsersContextualHelp';

// If more directory users are selected, disable the button and display a ContextualHelp
// directing the admin to use "Remove users by CSV".
const MAX_REMOVE_DIRECTORY_USERS = 100;

// To remove admin(s), directory users(s), user(s) or developer(s) from their respective tables.
// This component assumes it is wrapped in a TableSection which has selection enabled.

const RemoveMemberButton = observer(() => {
  const {canRemoveMember, pageContext, store} = useMemberListContext();
  const intl = useIntl();
  const {tableSectionUtils} = useTableSectionContext();
  const selectedItemCount = tableSectionUtils.getSelectedItemCount();
  const [userStorageList, setUserStorageList] = useState(null);
  const [dialog, setDialog] = useState(null);
  const msgSubkey = getTableMsgSubkey({pageContext});
  const messageNamespace = `common.removeMemberButton.${msgSubkey}`;

  const removeButtonLabel = intl.formatMessage({id: `${messageNamespace}.button`});

  const alertDialogTitle = intl.formatMessage(
    {
      id: `${messageNamespace}.alertDialog.title`,
    },
    {
      count: selectedItemCount,
    }
  );

  const isDirectoryUsers = pageContext.isUserTargetType() && pageContext.isDirectoryTarget();

  const isDisabledForDirectoryUsers =
    isDirectoryUsers && selectedItemCount > MAX_REMOVE_DIRECTORY_USERS;
  const isDisabled = selectedItemCount === 0 || isDisabledForDirectoryUsers;

  const getAlertDialogContent = () => {
    let content;

    // When deleting directory users there are 2 flavors of content depending on whether
    // it is an ESM org.
    if (isDirectoryUsers) {
      const orgTypeSubKey = isMigrationListESMType1(rootStore.organizationStore.migrationList)
        ? 'esmOrg'
        : 'nonEsmOrg';
      content = intl.formatMessage(
        {id: `${messageNamespace}.alertDialog.content.${orgTypeSubKey}`},
        {
          br: <br />,
          count: selectedItemCount,
          goUrl: (linkString) => <GoUrl name="remove_users_help">{linkString}</GoUrl>,
        }
      );
    } else {
      content = intl.formatMessage(
        {id: `${messageNamespace}.alertDialog.content`},
        {
          br: <br />,
          count: selectedItemCount,
        }
      );
    }

    return content;
  };

  const onRemove = ({onShowSuccessToast, saveOptions} = {}) => {
    onRemoveSelectedMembers({
      intl,
      onShowSuccessToast,
      pageContext,
      saveOptions,
      store,
      tableSectionUtils,
    });
  };

  // Called either directly for an EDU which has users with individual storage or
  // called when the CTA in the RemoveUsersWithStorageModal is pressed.
  const onRemoveUsersWithStorage = ({designatedUserId, storageOption}) => {
    // Removed users with individual storage have custom success toasts.
    const onShowSuccessToast = () => {
      if (storageOption === REMOVE_USER_STORAGE_OPTIONS.DELETE) {
        showSuccess(
          intl.formatMessage(
            {id: 'common.userListTableActions.removeStorage.toast.userRemoved'},
            {count: selectedItemCount}
          )
        );
      } else if (selectedItemCount === 1) {
        // One user removed with associated folder.
        showSuccess(
          intl.formatMessage({
            id: 'common.userListTableActions.removeStorage.toast.singleUserRemovedFolderProcessing',
          })
        );
      } else {
        // More than one user removed. Depending on count, one folder or many folders removed.
        showSuccess(
          intl.formatMessage(
            {
              id: 'common.userListTableActions.removeStorage.toast.userRemovedFolderProcessing',
            },
            {count: userStorageList.length}
          )
        );
      }
    };

    const removePathOverride = UserFolder.getRemovePathOverride({
      id: designatedUserId,
      type: storageOption,
      userStorageList,
    });

    // Remove the users and dispose of the associated storage, per the 'saveOptions'.
    onRemove({onShowSuccessToast, saveOptions: {removePathOverride}});
  };

  const openDialog = async () => {
    if (
      canViewStorage() &&
      (pageContext.isOrganizationTarget() || pageContext.isDirectoryTarget()) &&
      pageContext.isUserTargetType()
    ) {
      try {
        const theUserStorageList = await store.getUserFolderListForSelectedIds();
        setUserStorageList(theUserStorageList);
        if (theUserStorageList.length > 0) {
          if (rootStore.organizationStore.isActiveOrgEdu) {
            setDialog('alert-with-edu-storage');
          } else {
            // After storage disposition chosen in modal, onRemoveUsersWithStorage is called.
            setDialog('remove-user-with-storage');
          }
          return;
        }
      } catch {
        setDialog(null);
        showError();
        return;
      }
    }

    setDialog('alert');
  };

  const onAlertDialogPrimaryAction = () => {
    if (dialog === 'alert-with-edu-storage') {
      onRemoveUsersWithStorage({
        storageOption: REMOVE_USER_STORAGE_OPTIONS.DISCARD,
      });
    } else {
      onRemove();
    }
  };

  return (
    canRemoveMember && (
      <>
        <Button
          data-testid="remove-member-btn"
          isDisabled={isDisabled}
          onPress={openDialog}
          variant="negative"
        >
          {removeButtonLabel}
        </Button>

        {isDisabledForDirectoryUsers && (
          <DirectoryUsersContextualHelp maxUsers={MAX_REMOVE_DIRECTORY_USERS} />
        )}

        {!isDisabled && (
          <DialogContainer
            onDismiss={() => {
              setDialog(null);
            }}
          >
            {(dialog === 'alert' || dialog === 'alert-with-edu-storage') && (
              <AlertDialog
                cancelLabel={intl.formatMessage({
                  id: 'common.modal.buttons.cancel',
                })}
                onPrimaryAction={onAlertDialogPrimaryAction}
                primaryActionLabel={removeButtonLabel}
                title={alertDialogTitle}
                variant="destructive"
              >
                {getAlertDialogContent()}
              </AlertDialog>
            )}
            {dialog === 'remove-user-with-storage' && (
              <RemoveUsersWithStorageModal
                onSuccess={onRemoveUsersWithStorage}
                selectedMembers={toJS(store.selectedItems)}
                userStorageList={userStorageList}
              />
            )}
          </DialogContainer>
        )}
      </>
    )
  );
});

export default RemoveMemberButton;
