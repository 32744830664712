import {feature} from '@admin-tribe/acsc';

import rootStore from 'core/RootStore';

import HvaCard from '../HvaCard';
import {HVA_ORDER, HVA_TYPE} from '../hvaConstants';

import educationHvaLogo from './educationHvaLogo.svg';

class RosterSyncHva extends HvaCard {
  static get({intl}) {
    const shouldDisplayCard = rootStore.organizationStore.isActiveOrgEdu;

    if (!shouldDisplayCard || feature.isDisabled('temp_roster_sync_hva')) {
      return Promise.reject();
    }

    return Promise.resolve([new RosterSyncHva({intl})]);
  }

  constructor({intl}) {
    const namespace = 'overview.highValueActionArea.setUpTasks.rosterSync';

    super({
      ctaLabel: intl.formatMessage({id: `${namespace}.ctaLabel`}),
      description: intl.formatMessage({id: `${namespace}.description`}),
      goUrl: 'edu_roster_sync',
      header: intl.formatMessage({id: `${namespace}.header`}),
      icon: {
        src: educationHvaLogo,
      },
      id: 'roster-sync',
      priorityOrder: HVA_ORDER.ROSTER_SYNC,
      sectionTitle: intl.formatMessage({id: `${namespace}.sectionTitle`}),
      type: HVA_TYPE.SET_UP,
    });
  }
}

export default RosterSyncHva;
