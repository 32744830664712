import {Flex, Image, Text} from '@adobe/react-spectrum';
import {GoUrl} from '@pandora/react-go-url';
import LinkOutIcon from '@spectrum-icons/workflow/LinkOut';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {hasChinaLocaleOrContract} from 'core/organizations/access/organizationAccess';
import OverviewList from 'features/overview/components/overview-list/OverviewList';
import OverviewPod from 'features/overview/shell/overview-pod/OverviewPod';

// We store these icon in our AEM
const IMAGE_ROOT = 'https://wwwimages.adobe.com/content/dam/acom/one-console/icons_rebrand';
const UPDATE_SUMMARY_LINKS = [
  {
    goUrlName: 'aac_overview_cc_updates',
    id: 'overview-updates-cc-link',
    imageSrc: `${IMAGE_ROOT}/cc_appicon.svg`,
    translateKey: '.creativeCloudUpdates',
  },
  {
    goUrlName: 'aac_overview_dc_updates',
    id: 'overview-updates-dc-link',
    imageSrc: `${IMAGE_ROOT}/dc_appicon_256.svg`,
    translateKey: '.documentCloudUpdates',
  },
  {
    goUrlName: 'aac_overview_xc_updates',
    id: 'overview-updates-xc-link',
    imageSrc: `${IMAGE_ROOT}/ec_appicon.svg`,
    translateKey: '.experienceCloudUpdates',
  },
];

const UpdatesSummarySectionItem = ({id, imageSrc, goUrlName, label}) => (
  <GoUrl flexGrow={1} name={goUrlName} variant="secondary">
    <Flex gap="size-150" id={id}>
      <Image alt="" flexShrink={0} height="size-250" src={imageSrc} width="size-250" />
      <Text flexGrow={1} UNSAFE_style={{whiteSpace: 'break-spaces'}}>
        {label}
      </Text>
      <LinkOutIcon flexShrink={0} size="S" />
    </Flex>
  </GoUrl>
);

/**
 * The Updates Summary Section component
 */
const UpdatesSummarySection = ({isHidden}) => {
  const hideContent = {...{base: false, L: false, M: false, S: false}, ...isHidden};
  const intl = useIntl();

  // Adobe's cloud features are not supposed to be
  // actively communicated or promoted to anyone in China.
  // See https://git.corp.adobe.com/admin-tribe/onesie/issues/12083 for details
  if (hasChinaLocaleOrContract()) {
    return null;
  }

  return (
    <OverviewPod
      data-testid="updates-summary-section"
      isHidden={hideContent}
      title={intl.formatMessage({id: 'overview.updates.pod.title'})}
    >
      <OverviewList items={UPDATE_SUMMARY_LINKS}>
        {({id, imageSrc, goUrlName, translateKey}) => (
          <UpdatesSummarySectionItem
            goUrlName={goUrlName}
            id={id}
            imageSrc={imageSrc}
            label={intl.formatMessage({id: `overview.updates.pod${translateKey}`})}
          />
        )}
      </OverviewList>
    </OverviewPod>
  );
};

UpdatesSummarySection.propTypes = {
  /**
   * Responsive props instructing pod how to show/hide at various display sizes.
   * Optional, default is to show all the time
   */
  isHidden: PropTypes.shape({
    base: PropTypes.bool,
    L: PropTypes.bool,
    M: PropTypes.bool,
    S: PropTypes.bool,
  }),
};

UpdatesSummarySectionItem.propTypes = {
  /**
   * Name of goURL link
   */
  goUrlName: PropTypes.string.isRequired,
  /**
   * the DOM id for this item
   */
  id: PropTypes.string,
  /**
   * Link to image to render
   */
  imageSrc: PropTypes.string.isRequired,
  /**
   * Text to render as label
   */
  label: PropTypes.string.isRequired,
};

export default UpdatesSummarySection;
