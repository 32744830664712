import {PageBanner} from '@admin-tribe/acsc-ui';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

/**
 * A wrapper component of the Product Profiles Page Banner
 */
const SharedDeviceLicenseProductPageBanner = ({showBanner}) => {
  const intl = useIntl();
  return showBanner ? (
    <PageBanner
      header={intl.formatMessage({id: 'products.details.sdlBanner.header'})}
      variant="info"
    >
      {intl.formatMessage({id: 'products.details.sdlBanner.content'})}
    </PageBanner>
  ) : null;
};

SharedDeviceLicenseProductPageBanner.propTypes = {
  /**
   * Whether to show banner
   */
  showBanner: PropTypes.bool.isRequired,
};
export default SharedDeviceLicenseProductPageBanner;
