import {TableSectionTable} from '@admin-tribe/acsc-ui';
import {Text} from '@adobe/react-spectrum';
import React from 'react';
import {useIntl} from 'react-intl';

import FederatedDomainsStatus from 'features/settings/components/import-federated-domains-modal/FederatedDomainStatus';

const FederatedDomainsSectionTable = () => {
  const intl = useIntl();

  const columnDescriptor = [{key: 'domainName', props: {allowsSorting: true}}, {key: 'status'}];
  const renderers = {
    domainName: ({item}) => <Text>{item.name}</Text>,
    status: ({item}) => (
      <FederatedDomainsStatus detailedStatus={item.detailedStatus} status={item.status} />
    ),
  };

  return (
    <TableSectionTable
      aria-label={intl.formatMessage({id: 'settings.domains.addDomainsTable.ariaLabel'})}
      columnDescriptor={columnDescriptor}
      columnNamespace="settings.domains.addDomainsTable"
      noItemsFoundContentMessage={intl.formatMessage({
        id: 'settings.domainsTable.noItemsFoundContentMessage',
      })}
      noSearchResultsContentMessage={intl.formatMessage({
        id: 'settings.domainsTable.noSearchResultsContentMessage',
      })}
      renderers={renderers}
    />
  );
};

FederatedDomainsSectionTable.displayName = 'FederatedDomainsSectionTable';

export default FederatedDomainsSectionTable;
