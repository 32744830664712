import {MemberListTable, OverlayWait} from '@admin-tribe/acsc-ui';
import {
  SELECTION_MODE,
  TABLE_SECTION_ACTIONS,
  TableSection,
  onTokenPaginatedTableSectionChange,
} from '@pandora/react-table-section';
import PropTypes from 'prop-types';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';

import useTableSectionPageCounter from 'common/hooks/useTableSectionPageCounter';
import rootStore from 'core/RootStore';

import useAcceptedUserList from './hooks/useAcceptedUserList';

const memberListDisplay = {avatar: true, email: true};

/**
 * Displays the list of users that have consented to
 * an app integration that has access to their Adobe data
 */
const AppIntegrationsManageUsersTableSection = ({clientId, onTableSelection}) => {
  const intl = useIntl();
  const {acceptedUserList, isLoading, error, refreshList} = useAcceptedUserList({
    clientId,
    orgId: rootStore.organizationStore.activeOrgId,
  });

  const {currentPage, totalPages, onTableSectionChangePageReducer} = useTableSectionPageCounter({
    listState: acceptedUserList?.state,
  });

  const onTableSectionChange = useCallback(
    async ({action, payload}) => {
      // Update acceptedUserList token state
      onTokenPaginatedTableSectionChange({
        action,
        payload,
        tokenPaginatedDataUtils: acceptedUserList?.state,
      });

      // Update page states
      onTableSectionChangePageReducer({action});

      switch (action) {
        case TABLE_SECTION_ACTIONS.GO_TO_NEXT_PAGE:
        case TABLE_SECTION_ACTIONS.GO_TO_PREVIOUS_PAGE:
        case TABLE_SECTION_ACTIONS.ON_PAGE_SIZE_CHANGE:
          await refreshList();
          break;
        case TABLE_SECTION_ACTIONS.ON_ROW_SELECTION_CHANGE:
          // Pass selected user ids to callback
          onTableSelection(payload);
          break;
        default:
        // do nothing
      }
    },
    [acceptedUserList, onTableSelection, onTableSectionChangePageReducer, refreshList]
  );

  return (
    <OverlayWait isLoading={isLoading}>
      {acceptedUserList && (
        <TableSection
          isDisabled={isLoading}
          isServerError={!!error}
          items={acceptedUserList.items}
          onTableSectionChange={onTableSectionChange}
          pageNumber={currentPage}
          selectionMode={SELECTION_MODE.MULTIPLE}
          totalPages={totalPages}
        >
          <MemberListTable
            allowsSorting={false}
            aria-label={intl.formatMessage({
              id: 'products.appIntegrations.appIntegrationsManageUsersTableSection.tableSection.label',
            })}
            display={memberListDisplay}
          />
        </TableSection>
      )}
    </OverlayWait>
  );
};

AppIntegrationsManageUsersTableSection.propTypes = {
  /** The client id of the app integration */
  clientId: PropTypes.string.isRequired,
  /** The function called with the ids of the selected users  */
  onTableSelection: PropTypes.func.isRequired,
};

export default AppIntegrationsManageUsersTableSection;
