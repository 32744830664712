import {DetailSection, NavigationAnchor} from '@admin-tribe/acsc-ui';
import {Link} from '@adobe/react-spectrum';
import React from 'react';
import {useIntl} from 'react-intl';

import {getHrefForUserDetails} from 'features/users/routing/navigation-callbacks/navigationCallbacks';
import {useBulkOperationJob} from 'features/users/services/bulk-operations/BulkOperationJobContext';

/* section displaying and linking to the user intitiating the bulk operation */
const InitiatedByDetailSection = () => {
  const intl = useIntl();
  const {userInitiatingJob} = useBulkOperationJob();

  return (
    <DetailSection
      title={intl.formatMessage({id: 'bulkOperations.results.drawer.section.initiatedBy'})}
    >
      <Link>
        <NavigationAnchor href={getHrefForUserDetails({userId: userInitiatingJob.id})}>
          {userInitiatingJob.getDisplayName()}
        </NavigationAnchor>
      </Link>
    </DetailSection>
  );
};

export default InitiatedByDetailSection;
