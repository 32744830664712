import {JIL_CONSTANTS} from '@admin-tribe/acsc';

import {DEFAULT_TABLE_OPTIONS} from 'features/settings/hooks/jilTableOptionsConstants';

/**
 * @description  Removes all undefined values and returns the cache key
 * Example
 * single item directories/:orgId.itemId.param1.param2...paramN
 * multiple items directories/:orgId.param1.param2...paramN
 */
const getCacheKey = (...params) => {
  const joinedParams = params
    .map((element) => (typeof element === 'object' ? Object.values(element) : element))
    .flat()
    .filter((item) => !!item)
    .join('/');
  return joinedParams;
};

/**
 * Merges the provided params with the default table options.
 *
 * @param params
 *
 * @returns {Object} Object will all merged params
 */
const mergeParamsWithDefaults = (params = {}) => {
  let {page, pageSize} = params;

  // if the page is set to null, it means it's intentionally skipped
  if (page === null) {
    page = undefined;
    // setting a pageSize when page is skipped defeats the purpose of not providing a page
    pageSize = undefined;
  } else {
    // if no page is provided (undefined) we use the defaults. We do that for pageSize as well
    page = page ? params.page - 1 : 0;
    pageSize = params.pageSize || DEFAULT_TABLE_OPTIONS.pageSize;
  }

  return {
    sort: JIL_CONSTANTS.SORT.NAME,
    ...DEFAULT_TABLE_OPTIONS,
    ...params,
    page,
    pageSize,
    sortOrder:
      params.sortOrder === 'descending' ? JIL_CONSTANTS.ORDER.DESC : JIL_CONSTANTS.ORDER.ASC,
  };
};

export {getCacheKey, mergeParamsWithDefaults};
