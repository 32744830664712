import {log} from '@admin-tribe/acsc';
import {useCallback} from 'react';

/**
 * @description Custom hook that retries refreshing the user folder list.  This is necessary because
 *              the fetch api is unstable immediately after a write operation.
 *
 * @param {Number} options.maxRetries - The maximum number of times to retry refreshing the user folder list.
 * @param {Number} options.retryDelay - The number of milliseconds to wait between retries.
 * @param {Function} options.updateModel - Function to update the model list state
 * @param {Object} options.userFolderList - The returned model list
 * @throws {Error} -- Error returns by fetch api if the maximum number of retries is exceeded.
 */
const useRefreshUserFolderListWithRetry = ({
  maxRetries,
  refreshDelay,
  updateModel,
  userFolderList,
}) => {
  const refreshListWithRetry = useCallback(async () => {
    await updateModel(async () => {
      let retries = 0;
      let lastError;

      // eslint-disable-next-line no-constant-condition -- loop ends on throw or return
      while (true) {
        /* eslint-disable no-await-in-loop -- Polling endpoint.  Not parallelizable */
        try {
          // The explicit `await` is necessary to ensure that the catch block
          // is executed if the fetch fails.
          return await userFolderList.refresh();
        } catch (error) {
          lastError = error;
        }
        if (retries >= maxRetries) {
          // breaking here allows us to avoid unnecessary waiting at beginning
          // or end of loop.
          log.error('useRefreshUserFolderListWithRetry max maxRetries exceeded', lastError);
          throw lastError;
        }
        await new Promise((resolve) => {
          setTimeout(resolve, refreshDelay);
        });
        /* eslint-enable no-await-in-loop -- Polling endpoint.  Not parallelizable */
        retries += 1;
      }
    });
  }, [maxRetries, refreshDelay, updateModel, userFolderList]);

  return {refreshListWithRetry};
};

export default useRefreshUserFolderListWithRetry;
