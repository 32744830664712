import {download, jilDirectories, log} from '@admin-tribe/acsc';
import {
  showError as showErrorToast,
  showInfo as showInfoToast,
  showSuccess as showSuccessToast,
} from '@admin-tribe/acsc-ui';
import {useCallback} from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';

/**
 * @typedef {Object} DirectoryListActions
 * @property {() => Promise} exportToCsv
 */

const DIRECTORY_LIST_ACTIONS = {
  EXPORT_TO_CSV: 'EXPORT_TO_CSV',
};

/**
 * Hook that provides the callbacks for the actions exposed by the directory list action menu.
 *
 * @returns {DirectoryListActions}
 */
const useDirectoryListActions = () => {
  const intl = useIntl();

  const exportToCsv = useCallback(async () => {
    const DIRECTORIES_CSV_FILE_NAME = 'directories.csv';
    const orgId = rootStore.organizationStore.activeOrgId;

    showInfoToast(
      intl.formatMessage({id: 'settings.directories.directoryListActionMenu.downloadBeingPrepared'})
    );

    try {
      const response = await jilDirectories.exportDirectoriesToCSV({orgId});

      if (!response?.data?.file || response.data.file.size === 0) {
        throw new Error('received empty directory list response from server');
      }

      download(response.data.file, DIRECTORIES_CSV_FILE_NAME);

      showSuccessToast(
        intl.formatMessage({id: 'settings.directories.directoryListActionMenu.downloadSuccess'})
      );
    } catch (error) {
      log.error(`Failed to download directory list CSV for ${orgId}. Error: `, error);

      showErrorToast(
        intl.formatMessage({id: 'settings.directories.directoryListActionMenu.downloadError'})
      );
    }
  }, [intl]);

  return {
    exportToCsv,
  };
};

export default useDirectoryListActions;
export {DIRECTORY_LIST_ACTIONS};
