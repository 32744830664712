import {
  PATH_ADMINISTRATORS,
  PATH_ADMINISTRATOR_DETAILS,
  PATH_API_CREDENTIALS,
  PATH_DEVELOPERS,
  PATH_DEVELOPER_DETAILS,
  PATH_DIRECTORIES,
  PATH_DIRECTORY_USERS,
  PATH_DIRECTORY_USER_BULK_OPERATIONS,
  PATH_DIRECTORY_USER_BULK_OPERATIONS_RESULTS,
  PATH_DIRECTORY_USER_DETAILS,
  PATH_USERS,
  PATH_USERS_ADD_USERS_TO_ORG,
  PATH_USERS_BULK_OPERATIONS,
  PATH_USERS_BULK_OPERATIONS_LICENSE_DEFICIT_REPORT,
  PATH_USERS_BULK_OPERATIONS_RESULTS,
  PATH_USERS_BULK_OPS_MODAL,
  PATH_USERS_BULK_OPS_MODAL_MODE,
  PATH_USERS_REVIEW_SAVED_USER_INTRODUCTIONS,
  PATH_USERS_REVIEW_USER_INTRODUCTIONS,
  PATH_USER_DETAILS,
  PATH_USER_GROUPS,
  PATH_USER_GROUPS_BULK_OPERATIONS,
  PATH_USER_GROUPS_BULK_OPERATIONS_RESULTS,
  PATH_USER_GROUP_DETAILS,
  PATH_USER_GROUP_DETAILS_ADMINS,
  PATH_USER_GROUP_DETAILS_ASSIGNED_PRODUCT_PROFILES,
  PATH_USER_GROUP_DETAILS_BULK_OPERATIONS,
  PATH_USER_GROUP_DETAILS_LICENSE_DEFICIT_REPORT,
  PATH_USER_GROUP_DETAILS_RESULTS,
  PATH_USER_GROUP_DETAILS_USERS,
} from 'features/users/routing/usersPaths';

const usersRouteMigration = [
  // Users
  {
    stateName: ['users', 'users.users', 'users.users.all-users'],
    url: PATH_USERS,
    useReactRoute: true,
  },
  {
    stateName: 'users.user-details',
    url: PATH_USER_DETAILS,
    useReactRoute: true,
  },
  {
    stateName: 'users.jobs',
    url: PATH_USERS_BULK_OPERATIONS,
    useReactRoute: true,
  },
  {
    stateName: 'users.results',
    url: PATH_USERS_BULK_OPERATIONS_RESULTS,
    useReactRoute: true,
  },
  {
    stateName: 'users.license-deficit-report',
    url: PATH_USERS_BULK_OPERATIONS_LICENSE_DEFICIT_REPORT,
    useReactRoute: true,
  },
  {
    stateName: '', // no corresponding angular state
    url: PATH_USERS_BULK_OPS_MODAL,
    useReactRoute: true,
  },
  {
    stateName: '', // no corresponding angular state
    url: PATH_USERS_BULK_OPS_MODAL_MODE,
    useReactRoute: true,
  },
  {
    stateName: '', // no corresponding angular state
    url: PATH_USERS_ADD_USERS_TO_ORG,
    useReactRoute: true,
  },
  {
    stateName: '', // no corresponding angular state
    url: PATH_USERS_REVIEW_USER_INTRODUCTIONS,
    useReactRoute: true,
  },
  {
    stateName: '', // no corresponding angular state
    url: PATH_USERS_REVIEW_SAVED_USER_INTRODUCTIONS,
    useReactRoute: true,
  },
  // User groups
  {
    stateName: 'users.user-groups',
    url: PATH_USER_GROUPS,
    useReactRoute: true,
  },
  {
    stateName: 'users.user-group-details',
    url: PATH_USER_GROUP_DETAILS,
    useReactRoute: true,
  },
  {
    stateName: 'users.user-group-details.admins',
    url: PATH_USER_GROUP_DETAILS_ADMINS,
    useReactRoute: true,
  },
  {
    stateName: 'users.user-group-details.assigned-product-profiles',
    url: PATH_USER_GROUP_DETAILS_ASSIGNED_PRODUCT_PROFILES,
    useReactRoute: true,
  },
  {
    stateName: 'users.user-group-details.users',
    url: PATH_USER_GROUP_DETAILS_USERS,
    useReactRoute: true,
  },
  {
    stateName: 'users.user-group-jobs',
    url: PATH_USER_GROUP_DETAILS_BULK_OPERATIONS,
    useReactRoute: true,
  },
  {
    stateName: 'users.user-group-results',
    url: PATH_USER_GROUP_DETAILS_RESULTS,
    useReactRoute: true,
  },
  {
    stateName: 'users.user-group-bulk-operations',
    url: PATH_USER_GROUPS_BULK_OPERATIONS,
    useReactRoute: true,
  },
  {
    stateName: 'users.user-group-bulk-operations-results',
    url: PATH_USER_GROUPS_BULK_OPERATIONS_RESULTS,
    useReactRoute: true,
  },
  {
    stateName: 'users.user-group-license-deficit-report',
    url: PATH_USER_GROUP_DETAILS_LICENSE_DEFICIT_REPORT,
    useReactRoute: true,
  },
  // Administrators
  {
    stateName: ['users.administrators', 'users.administrators.all-admins'],
    url: PATH_ADMINISTRATORS,
    useReactRoute: true,
  },
  {
    stateName: 'users.administrator-details',
    url: PATH_ADMINISTRATOR_DETAILS,
    useReactRoute: true,
  },
  // Developers
  {
    stateName: ['users.developers', 'users.developers.all-developers'],
    url: PATH_DEVELOPERS,
    useReactRoute: true,
  },
  {
    stateName: 'users.developer-details', // this angular route does not exist
    url: PATH_DEVELOPER_DETAILS,
    useReactRoute: true,
  },
  // Directory users
  {
    stateName: 'users.owned-directory-list',
    url: PATH_DIRECTORIES,
    useReactRoute: true,
  },
  {
    stateName: ['users.directory-users', 'users.directory-users.all-users'],
    url: PATH_DIRECTORY_USERS,
    useReactRoute: true,
  },
  {
    stateName: 'users.directory-user-details',
    url: PATH_DIRECTORY_USER_DETAILS,
    useReactRoute: true,
  },
  {
    stateName: 'users.directory-user-jobs',
    url: PATH_DIRECTORY_USER_BULK_OPERATIONS,
    useReactRoute: true,
  },
  {
    stateName: 'users.directory-user-results',
    url: PATH_DIRECTORY_USER_BULK_OPERATIONS_RESULTS,
    useReactRoute: true,
  },
  // API credentials
  {
    stateName: ['users.integrations', 'users.integrations.all-integrations'],
    url: PATH_API_CREDENTIALS,
    useReactRoute: true,
  },
];

export default usersRouteMigration;
