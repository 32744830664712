import {JIL_CONSTANTS} from '@admin-tribe/acsc';
import {useCallback, useReducer} from 'react';

import useDirectoryList from 'common/hooks/api/useDirectoryList';
import useJilTableOptions from 'features/settings/hooks/useJilTableOptions';
import DirectoryListModel from 'features/settings/models/DirectoryListModel';

const ACTIONS = {
  DATA_LOAD: 'dataLoad',
  DATA_LOAD_ERROR: 'dataLoadError',
  FINISH_DATA_LOAD: 'finishDataLoad',
  UPDATE_DATA: 'updateData',
};

/**
 * A reducer which handles the actions dispatch for the directoryList
 */
const directoryListStateReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.DATA_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case ACTIONS.DATA_LOAD_ERROR:
      return {
        ...state,
        hasLoadingError: true,
        isLoading: false,
      };
    case ACTIONS.FINISH_DATA_LOAD:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
        directoryCount: action.payload.directoryCount ?? state.directoryCount,
      };
    case ACTIONS.UPDATE_DATA:
      return {
        ...state,
        directoryListData: [...action.payload],
      };
    default:
      return state;
  }
};

/**
 * A hook that holds the state for a directory list. It provides methods
 * for loading a directory list or updating it. This is used by DirectoryListContext,
 * to pass the state to multiple other components, but it can also be used independently
 * in a component if prop-drilling is not an issue.
 */
const useDirectoryListState = (initialData) => {
  const [state, dispatch] = useReducer(directoryListStateReducer, {
    directoryCount: initialData?.totalCount,
    directoryListData: DirectoryListModel.fromObject(initialData),
    hasLoadingError: false,
    isLoading: !initialData,
  });

  const [tableOptions, setTableOptions] = useJilTableOptions({
    sort: JIL_CONSTANTS.SORT.NAME,
  });

  const {getDirectoryList, getDirectoryListWithCertificates} = useDirectoryList();

  /**
   * Function used to fetch a directory list.
   * It handles setting the state on the context, including error and loading states.
   */
  const loadDirectoryList = useCallback(
    async (params = tableOptions, {updateCount} = {}) => {
      dispatch({type: ACTIONS.DATA_LOAD});

      try {
        const response = await getDirectoryList(params);

        const dataModel = DirectoryListModel.fromObject(response);
        setTableOptions({...tableOptions, ...params, totalPages: dataModel.totalPages});

        dispatch({
          payload: {
            directoryCount: updateCount ? response.totalCount : null,
            directoryListData: dataModel,
          },
          type: ACTIONS.FINISH_DATA_LOAD,
        });

        return dataModel;
      } catch (error) {
        dispatch({type: ACTIONS.DATA_LOAD_ERROR});
        return undefined;
      }
    },
    [getDirectoryList, setTableOptions, tableOptions]
  );

  /**
   * Function used to fetch a directory list with each directory idp's certificates.
   * It handles setting the state on the context, including error and loading states.
   */
  const loadDirectoryListWithCertificates = useCallback(
    async (params = tableOptions, {updateCount} = {}) => {
      dispatch({type: ACTIONS.DATA_LOAD});

      try {
        const response = await getDirectoryListWithCertificates(params);

        const dataModel = DirectoryListModel.fromObject(response);
        setTableOptions({...tableOptions, ...params, totalPages: dataModel.totalPages});

        dispatch({
          payload: {
            directoryCount: updateCount ? response.totalCount : null,
            directoryListData: dataModel,
          },
          type: ACTIONS.FINISH_DATA_LOAD,
        });

        return dataModel;
      } catch (error) {
        dispatch({type: ACTIONS.DATA_LOAD_ERROR});
        return undefined;
      }
    },
    [getDirectoryListWithCertificates, setTableOptions, tableOptions]
  );

  /**
   * Function used to patch the data from the state (i.e. manually insert a directory object)
   */
  const updateDirectoryList = useCallback((data) => {
    dispatch({payload: data, type: ACTIONS.UPDATE_DATA});
  }, []);

  return {
    loadDirectoryList,
    loadDirectoryListWithCertificates,
    setTableOptions,
    state,
    tableOptions,
    updateDirectoryList,
  };
};

export default useDirectoryListState;

// exported for unit-testing
export {directoryListStateReducer};
