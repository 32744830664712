import {formatUsageSummary} from '@admin-tribe/acsc-ui';
import {fetchReadableUnitsOfStorage} from '@pandora/react-formatted-file-size';
import {EnDashDefaultContent} from '@pandora/react-table-section';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

/**
 * Component that displays the quota usage value
 */
const UsageValue = ({amount, total}) => {
  const intl = useIntl();
  if (typeof amount === 'undefined' || typeof total === 'undefined') {
    return <EnDashDefaultContent />;
  }

  const usageSummary = {
    amount: fetchReadableUnitsOfStorage(amount, 1),
    total: fetchReadableUnitsOfStorage(total, 1),
  };

  return formatUsageSummary(intl, usageSummary);
};

UsageValue.propTypes = {
  amount: PropTypes.number,
  total: PropTypes.number,
};

export default UsageValue;
