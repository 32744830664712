import {TableSectionTable} from '@admin-tribe/acsc-ui';
import {ActionButton, DialogContainer, Text, View} from '@adobe/react-spectrum';
import {
  EnDashDefaultContent,
  TableActions,
  useTableSectionContext,
} from '@pandora/react-table-section';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import RemoveFromExceptionListConfirmationModal from 'features/settings/components/directory/domain-enforcement/exclude-users-section/RemoveFromExceptionListConfirmationModal';

import './ExcludeUserSections.pcss';

const ExceptionListTable = () => {
  const intl = useIntl();
  const {tableSectionState, tableSectionUtils} = useTableSectionContext();
  const [dialog, setDialog] = useState(false);

  const userEmails = tableSectionState.selectedItemKeys;

  const columnDescriptor = [{key: 'email'}];

  const renderers = {
    email: ({item}) => (
      <EnDashDefaultContent>
        <div styleName="exception-list-table-email-cell">{item.email}</div>
      </EnDashDefaultContent>
    ),
  };

  return (
    <>
      <TableActions>
        <View marginEnd="size-200">
          <ActionButton isDisabled={userEmails.length === 0} onPress={() => setDialog(true)}>
            <Text>
              {intl.formatMessage(
                {
                  id: 'settings.domainEnforcement.exceptionListModal.exemptionListTable.button.remove',
                },
                {count: userEmails.length}
              )}
            </Text>
          </ActionButton>
        </View>
      </TableActions>
      <TableSectionTable
        aria-label={intl.formatMessage({
          id: 'settings.domainEnforcement.exceptionListModal.exemptionListTable.ariaLabel',
        })}
        columnDescriptor={columnDescriptor}
        columnNamespace="binky.common.memberListTable.column"
        renderers={renderers}
      />
      <DialogContainer
        onDismiss={() => {
          setDialog(false);
        }}
      >
        {dialog && (
          <RemoveFromExceptionListConfirmationModal
            onConfirm={() => tableSectionUtils.clearSelectedItemKeys()}
            userEmails={userEmails}
          />
        )}
      </DialogContainer>
    </>
  );
};

ExceptionListTable.displayName = 'ExceptionListTable';
export default ExceptionListTable;
