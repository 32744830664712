import {navBus} from '@admin-tribe/acsc';
import {CLOUD} from '@pandora/administration-core-types';
import {JilModelList} from '@pandora/data-model-list';
import {hasProductsInCloud} from '@pandora/data-model-product';

import AppConstants from 'common/services/AppConstants';
import rootStore from 'core/RootStore';
import {GetProductListItems} from 'features/support/utils/GetProductListItems';

/**
 * This function checks if the product list in the organization store is DxOnly.
 * It filters the product list and checks if the products belong to specific clouds.
 *
 * @returns {boolean} Returns true if the product list is DxOnly, false otherwise.
 */
const isDxOnly = () => {
  const {productList} = rootStore.organizationStore;

  const filteredProductList = new JilModelList({
    itemCount: productList.pagination.itemCount,
    items: GetProductListItems(productList.items),
  });

  const hasCreative = hasProductsInCloud(filteredProductList, CLOUD.CREATIVE);

  if (hasCreative) {
    return false;
  }

  const hasDocument = hasProductsInCloud(filteredProductList, CLOUD.DOCUMENT);

  if (hasDocument) {
    return false;
  }

  const hasOthers = hasProductsInCloud(filteredProductList, CLOUD.OTHERS);

  if (hasOthers) {
    return false;
  }

  const hasExperience = hasProductsInCloud(filteredProductList, CLOUD.EXPERIENCE);

  return hasExperience;
};

/**
 * This function redirects to the Experience League page.
 */
const goToExl = () => {
  const {activeOrgId} = rootStore.organizationStore;
  const exlBaseUrl = AppConstants.configuration.services.experienceLeague.url;

  const exlUrl = `${exlBaseUrl}/home?support-tab=home&org-id=${activeOrgId}#support`;

  navBus.navigate(exlUrl);
};

/**
 * This function redirects to the Experience League details page with the caseId.
 * @param {string} caseId
 */
const goToExlDetails = (caseId) => {
  const {activeOrgId} = rootStore.organizationStore;
  const exlBaseUrl = AppConstants.configuration.services.experienceLeague.url;

  // https://experienceleague.adobe.com/home?support-tab=my-cases&case-id=CASEID&org-id=ORGID#support
  const exlUrl = `${exlBaseUrl}/home?support-tab=my-cases&case-id=${caseId}&org-id=${activeOrgId}#support`;

  navBus.navigate(exlUrl);
};

export {isDxOnly, goToExl, goToExlDetails};
