import {ModalContent} from '@admin-tribe/acsc-ui';
import {Divider, Flex, Heading, Text, View} from '@adobe/react-spectrum';
import {GoUrl} from '@pandora/react-go-url';
import Box from '@spectrum-icons/workflow/Box';
import Help from '@spectrum-icons/workflow/Help';
import UserGroupIcon from '@spectrum-icons/workflow/UserGroup';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

/**
 * TeamToEnterpriseContractMigrationIntro Component
 *
 * This component renders an introduction modal for the team to enterprise contract migration process.
 * It provides users with information about the migration, including steps and benefits.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {number} props.totalLicenseCount - The total number of licenses involved in the migration.
 * @returns {JSX.Element} The rendered component.
 */
const TeamToEnterpriseContractMigrationIntro = ({totalLicenseCount}) => {
  const intl = useIntl();
  const getString = (suffix) =>
    intl.formatMessage({id: `migrations.teamToEnterpriseMigrationModal.${suffix}`});

  const GO_URL_NAMES = {
    Box: 'ac_manage_product',
    Help: 'aac_support_expert_services',
    UserGroupIcon: 'ac_manage_users',
  };

  const renderIconSection = (IconComponent, descriptionKey, iconKey) => (
    <Flex alignItems="center" direction="column" flex gap="size-150">
      <IconComponent size="L" />
      <Text UNSAFE_style={{textAlign: 'center'}}>{getString(descriptionKey)}</Text>
      <GoUrl name={GO_URL_NAMES[iconKey]}>{getString('processingPanel.learnMore')}</GoUrl>
    </Flex>
  );

  return (
    <ModalContent>
      <Flex alignItems="center" direction="column" gap="size-200">
        <Heading level={3}>{getString('summaryPanel.linksHeading')}</Heading>
        <View UNSAFE_style={{textAlign: 'center'}}>
          {getString('summaryPanel.linksSubheading')}
        </View>
        <Flex direction="row" gap="size-400">
          {renderIconSection(Box, 'summaryPanel.easyIntegrationDescription', 'Box')}
          {renderIconSection(UserGroupIcon, 'summaryPanel.manageUsersDescription', 'UserGroupIcon')}
          {renderIconSection(Help, 'summaryPanel.unlimitedHelpDescription', 'Help')}
        </Flex>
      </Flex>

      <Divider marginBottom="size-150" marginTop="size-200" size="S" />

      <Flex alignItems="center" direction="column" gap="size-200">
        <Heading level={3}>{getString('summaryPanel.licensesHeading')}</Heading>
        <View UNSAFE_style={{textAlign: 'center'}}>
          {getString('summaryPanel.licensesSubheading')}
        </View>
        <Flex
          alignItems="center"
          direction="column"
          gap="size-100"
          marginTop="size-200"
          width="50%"
        >
          <Text>
            {getString('summaryPanel.currentTeamLicenses')}: {totalLicenseCount}
          </Text>
          <Text UNSAFE_style={{color: 'green', fontWeight: 'bold'}}>
            {getString('summaryPanel.newEnterpriseLicenses')}: {totalLicenseCount}
          </Text>
        </Flex>
        <Divider size="S" />
      </Flex>
    </ModalContent>
  );
};

TeamToEnterpriseContractMigrationIntro.propTypes = {
  totalLicenseCount: PropTypes.number.isRequired,
};

export default TeamToEnterpriseContractMigrationIntro;
