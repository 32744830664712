import {
  Product,
  downloadExportData,
  getDirectContract,
  getDirectOrIndirectContract,
  log,
} from '@admin-tribe/acsc';
import {
  MemberListTable,
  getProductDisplayName,
  showError as showErrorToast,
  showInfo as showInfoToast,
  showSuccess as showSuccessToast,
} from '@admin-tribe/acsc-ui';
import {Button, Content, Header, Heading, Text, View} from '@adobe/react-spectrum';
import {Drawer} from '@pandora/react-drawer';
import {TABLE_SECTION_ACTIONS, TableSection} from '@pandora/react-table-section';
import kebabcase from 'lodash/kebabCase';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';
import {canViewUserDetails} from 'core/user/access/userAccess';
import {getHrefForUserDetails} from 'features/users/routing/navigation-callbacks/navigationCallbacks';

import {PAYMENT_STATUS_TYPE} from './paymentStatusDrawerConstants';
import {getMessageArgs, getUsers, getUsersCSV} from './paymentStatusDrawerUtils';

const PAYMENT_STATUS_DRAWER_ID = 'payment-status-drawer-id';
const DRAWER_NAMESPACE = 'products.details.drawers.paymentStatus';
const memberListDisplay = {avatar: true, email: true};

/**
 * @description Drawer that lists users who need to pay for a product or renew their license.
 */
const PaymentStatusDrawer = ({paymentStatusType, product}) => {
  const intl = useIntl();
  const [isDownloadInProgress, setIsDownloadInProgress] = useState(false);
  const [isMessageLoading, setIsMessageLoading] = useState(true);
  const [isUserListLoading, setIsUserListLoading] = useState(true);
  const [messageArgs, setMessageArgs] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const [userList, setUserList] = useState(undefined);
  const fileName = `${kebabcase(getProductDisplayName(intl, product))}-need-${
    paymentStatusType === PAYMENT_STATUS_TYPE.NEED_PAYMENT ? 'payment' : 'renewal'
  }-report.csv`;

  const contract = (
    paymentStatusType === PAYMENT_STATUS_TYPE.IMPACTED_USERS
      ? getDirectContract
      : getDirectOrIndirectContract
  )(rootStore.organizationStore.contractList);
  const isGracePastDue = contract.isComplianceStatusGracePastDue();

  let namespace = `${DRAWER_NAMESPACE}.${paymentStatusType}`;
  if (paymentStatusType === PAYMENT_STATUS_TYPE.IMPACTED_USERS) {
    namespace = `${namespace}.${isGracePastDue ? 'gracePastDue' : 'pastDue'}`;
  }

  // get message
  useEffect(() => {
    const setMessageAndUsers = async () => {
      try {
        setMessageArgs(await getMessageArgs({contract, paymentStatusType, product}));
      } catch (error) {
        log.error('PaymentStatusDrawer failed to get message', error);
      } finally {
        setIsMessageLoading(false);
      }
    };
    setMessageAndUsers();
  }, [contract, paymentStatusType, product]);

  // get users for the table
  useEffect(() => {
    const queryUsers = async () => {
      let users;
      try {
        users = await getUsers({
          isGracePastDue,
          pageNumber,
          paymentStatusType,
          productId: product.id,
        });
      } catch (error) {
        log.error('PaymentStatusDrawer failed to get users', error);
      } finally {
        setUserList(users);
        setIsUserListLoading(false);
      }
    };
    queryUsers();
  }, [contract, isGracePastDue, pageNumber, paymentStatusType, product.id]);

  const onClickDownloadCsv = async () => {
    let exportData;

    setIsDownloadInProgress(true);
    showInfoToast(intl.formatMessage({id: 'common.toast.csvDownloadBeingPrepared'}));

    try {
      exportData = (await getUsersCSV({paymentStatusType, productId: product.id}))?.data?.file;
    } catch (error) {
      showErrorToast(intl.formatMessage({id: 'common.toast.downloadError'}));
      log.error('PaymentStatusDrawer failed download', error);
    } finally {
      setIsDownloadInProgress(false);
    }

    if (exportData) {
      downloadExportData(exportData, fileName);
      showSuccessToast(intl.formatMessage({id: 'common.toast.csvDownloadSuccess'}));
    }
  };

  const onTableSectionChange = useCallback(({action}) => {
    switch (action) {
      case TABLE_SECTION_ACTIONS.GO_TO_NEXT_PAGE:
        setPageNumber((curr) => curr + 1);
        break;
      case TABLE_SECTION_ACTIONS.GO_TO_PREVIOUS_PAGE:
        setPageNumber((curr) => curr - 1);
        break;
      default:
        break;
    }
  }, []);

  const hasUsers = userList?.items?.length > 0;

  return (
    <Drawer
      aria-labelledby={PAYMENT_STATUS_DRAWER_ID}
      isLoading={isMessageLoading || isUserListLoading}
    >
      <Header marginBottom="size-350" marginX="size-50">
        <Heading id={PAYMENT_STATUS_DRAWER_ID} level={2} marginBottom="size-125" marginTop="size-0">
          {intl.formatMessage({id: `${namespace}.title`})}
        </Heading>
      </Header>
      <Content marginX="size-50">
        <Text>
          {messageArgs?.id
            ? intl.formatMessage(
                {
                  id: `${namespace}.${messageArgs.id}`,
                },
                messageArgs.values
              )
            : null}
        </Text>
        {hasUsers && paymentStatusType !== PAYMENT_STATUS_TYPE.IMPACTED_USERS && (
          <View>
            <Button
              data-testid="download-users-csv-button"
              isDisabled={isDownloadInProgress}
              marginY="size-200"
              onPress={onClickDownloadCsv}
              variant="primary"
            >
              {intl.formatMessage({id: `${DRAWER_NAMESPACE}.downloadUserList`})}
            </Button>
          </View>
        )}

        {hasUsers && (
          <View marginBottom="size-300">
            <TableSection
              items={userList.items}
              onTableSectionChange={onTableSectionChange}
              pageNumber={pageNumber}
              // hide page size dropdown - note this is not officially supported by Table Section
              pageSizeOptions={false}
              totalPages={userList.pagination.totalPages}
            >
              <MemberListTable
                allowsSorting={false}
                aria-labelledby={PAYMENT_STATUS_DRAWER_ID}
                canViewMemberDetails={canViewUserDetails}
                display={memberListDisplay}
                getDisplayNameHref={(member) => getHrefForUserDetails({userId: member.id})}
              />
            </TableSection>
          </View>
        )}
      </Content>
    </Drawer>
  );
};

PaymentStatusDrawer.propTypes = {
  /* The type of access failure for these users. */
  paymentStatusType: PropTypes.oneOf(Object.values(PAYMENT_STATUS_TYPE)).isRequired,
  /* Product that these users don't have access to */
  product: PropTypes.instanceOf(Product).isRequired,
};

export default PaymentStatusDrawer;
