import {feature} from '@admin-tribe/acsc';
import {OfferType} from '@pandora/commerce-core-types';

import digitalData from '../../core/analytics/digitalData';

/**
 * This function is used to get existing organizationInfo from digitalData
 * and set subscriptionsStatus in project based on offerId
 *
 * @param {String} offerId
 * @param {String} offerType
 * @return {Object}
 */
function getOrgInfoWithSubscriptionStatusForAnalytics(offerId, offerType) {
  const organizationInfo = digitalData.analyticsData.organization;
  const trialWithPaymentEnabled = feature.isEnabled('trial_with_payment');

  if (!trialWithPaymentEnabled) return organizationInfo;

  const subscriptionStatus = offerType === OfferType.TRIAL ? 'Trial' : 'Paid';
  if (organizationInfo.product?.length > 0) {
    organizationInfo.product = organizationInfo.product.map((product) => {
      if (product.productInfo.offerId === offerId) {
        product.productInfo.subscriptionStatus = subscriptionStatus;
      }
      return product;
    });
  }
  return organizationInfo;
}

// eslint-disable-next-line import/prefer-default-export -- disabling default export
export {getOrgInfoWithSubscriptionStatusForAnalytics};
