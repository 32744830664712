import {showError as showErrorToast} from '@admin-tribe/acsc-ui';
import {Button, DialogContainer, Heading} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {useDirectoryContext} from 'features/settings/components/directory/DirectoryContext';
import RemoveExternallyManagedModal from 'features/settings/components/remove-externally-managed-modal/RemoveExternallyManagedModal';
import ExternallyManagedStatus from 'features/settings/components/sync-settings-modal/ExternallyManagedStatus';

const AllowEditingSection = observer(({clearCaches}) => {
  const {directoryStore} = useDirectoryContext();
  const [isRemovePolicyDialogOpened, toggleRemovePolicyDialog] = useState(false);
  const intl = useIntl();

  const editSyncLabel = directoryStore.isExternallyManaged
    ? 'settings.sync.syncSettingsModal.allowEditing.ENABLE'
    : 'settings.sync.syncSettingsModal.allowEditing.DISABLE';

  const onToggleEditingClick = async () => {
    if (directoryStore.isExternallyManaged) {
      toggleRemovePolicyDialog(true);
    } else {
      try {
        await directoryStore.toggleExternallyManagedPolicy(true);

        clearCaches();
      } catch (error) {
        showErrorToast(intl.formatMessage({id: 'settings.sync.error'}));
      }
    }
  };

  return (
    <>
      <Heading>
        <FormattedMessage id="settings.sync.syncSettingsModal.allowEditing.title" />
      </Heading>

      <p>
        <FormattedMessage id="settings.sync.syncSettingsModal.allowEditing.description.allowSyncing" />
      </p>
      <p>
        <FormattedMessage id="settings.sync.syncSettingsModal.allowEditing.description.defaultSetting" />
      </p>

      <ExternallyManagedStatus isExternallyManaged={directoryStore.isExternallyManaged} />

      <Button
        data-test-id="edit-sync-btn"
        isDisabled={directoryStore.isLoading}
        onPress={onToggleEditingClick}
        variant="secondary"
      >
        <FormattedMessage id={editSyncLabel} />
      </Button>

      <DialogContainer onDismiss={() => toggleRemovePolicyDialog(false)}>
        {isRemovePolicyDialogOpened && <RemoveExternallyManagedModal clearCaches={clearCaches} />}
      </DialogContainer>
    </>
  );
});

AllowEditingSection.propTypes = {
  clearCaches: PropTypes.func,
};

export default AllowEditingSection;
