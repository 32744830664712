import {
  OverlayWait,
  showError as showErrorToast,
  showSuccess as showSuccessToast,
} from '@admin-tribe/acsc-ui';
import {Heading, Item, ListView, View, useDialogContainer} from '@adobe/react-spectrum';
import {IllustratedMessage} from '@pandora/react-illustrated-message';
import {
  ModalButtonGroup,
  ModalContent,
  ModalDialog,
  ModalHeading,
} from '@pandora/react-modal-dialog';
import Alert from '@react/react-spectrum/Alert';
import ProviderV2 from '@react/react-spectrum/Provider';
import {useId} from '@react-aria/utils';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {DOMAIN_STATUSES} from 'common/entities/DomainEntity';
import AccessDnsToken from 'features/settings/common/components/access-dns-token/AccessDnsToken';
import useDomainList from 'features/settings/hooks/api/useDomainList';

const MODAL_ID = 'validate-domain-modal';

const ValidateDomainModal = ({domains = [], onDomainsValidated}) => {
  const intl = useIntl();

  const modalHeadingId = useId();
  const dialog = useDialogContainer();

  const {getDomainList, validateDomains} = useDomainList();

  const [domainsToValidate, setDomainsToValidate] = useState([]);
  const [shouldShowHelp, setShouldShowHelp] = useState(false);
  const [isLoadingDomains, setIsLoadingDomains] = useState(false);
  const [isLoadingToken, setIsLoadingToken] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const hasHandledApiError = useRef(false);

  const handleApiError = () => {
    if (hasHandledApiError.current) return;

    showErrorToast(intl.formatMessage({id: 'settings.validateDomainModal.toast.error'}));
    dialog.dismiss();

    hasHandledApiError.current = true;
  };

  const loadDomainsFromProps = ({domainsToExclude}) => {
    const shouldDomainBeExcluded = ({domainName}) =>
      domainsToExclude.some((domain) => domain.domainName === domainName);

    setDomainsToValidate(domains.filter((domain) => !shouldDomainBeExcluded(domain)));
  };

  const loadDomainsFromApi = async () => {
    try {
      setIsLoadingDomains(true);

      const jilResponse = await getDomainList({
        page: 1,
        pageSize: 100,
        status: DOMAIN_STATUSES.UNCLAIMED,
      });

      setDomainsToValidate(jilResponse.data);
    } catch {
      handleApiError();
    } finally {
      setIsLoadingDomains(false);
    }
  };

  const loadDomains = (options = {domainsToExclude: []}) => {
    if (domains.length > 0) {
      loadDomainsFromProps(options);
    } else {
      loadDomainsFromApi();
    }
  };

  // initial loading of domains list
  useEffect(() => {
    loadDomains();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we only want this to run once
  }, []);

  const handleValidate = async () => {
    try {
      setShouldShowHelp(false);
      setIsValidating(true);

      const {failedToValidate, validated} = await validateDomains(domainsToValidate);

      if (validated.length > 0) onDomainsValidated?.();

      if (failedToValidate.length > 0) {
        setShouldShowHelp(true);

        // reload to remove successfully validated domains from the list
        loadDomains({domainsToExclude: validated});
      } else {
        showSuccessToast(
          intl.formatMessage(
            {id: 'settings.validateDomainModal.toast.success'},
            {count: domainsToValidate.length}
          )
        );
        dialog.dismiss();
      }
    } catch {
      handleApiError();
    } finally {
      setIsValidating(false);
    }
  };

  const isCtaDisabled =
    isValidating || isLoadingDomains || isLoadingToken || domainsToValidate.length === 0;

  return (
    <ModalDialog id={MODAL_ID} isLoading={isValidating}>
      <ModalHeading id={modalHeadingId}>
        <FormattedMessage id="settings.validateDomainModal.title" />
      </ModalHeading>

      <ModalContent>
        <View marginTop="size-125">
          {shouldShowHelp && (
            <ProviderV2>
              <View>
                <Alert
                  header={intl.formatMessage({id: 'settings.validateDomainModal.alert.header'})}
                  variant="help"
                >
                  <FormattedMessage id="settings.validateDomainModal.alert.body" />
                </Alert>
              </View>
            </ProviderV2>
          )}

          <AccessDnsToken
            isValidatingDomains
            onError={handleApiError}
            onLoadingStateChange={setIsLoadingToken}
          />
        </View>

        <Heading level={3}>
          <FormattedMessage id="settings.validateDomainModal.domainsThatRequireValidation" />
        </Heading>
        <OverlayWait isLoading={isLoadingDomains} showContent>
          <ListView
            aria-label={intl.formatMessage({
              id: 'settings.validateDomainModal.domainsThatRequireValidation',
            })}
            items={domainsToValidate}
            minHeight={domainsToValidate.length === 0 ? 'size-2400' : undefined}
            renderEmptyState={() => (
              <IllustratedMessage
                heading={intl.formatMessage({id: 'settings.validateDomainModal.noDomains'})}
                level={3}
              />
            )}
          >
            {(item) => <Item key={item.domainName}>{item.domainName}</Item>}
          </ListView>
        </OverlayWait>
      </ModalContent>

      <ModalButtonGroup
        cancelLabel={intl.formatMessage({id: 'common.modal.buttons.cancel'})}
        ctaLabel={intl.formatMessage({id: 'settings.validateDomainModal.validate'})}
        isCtaDisabled={isCtaDisabled}
        onCancel={() => dialog.dismiss()}
        onCta={handleValidate}
      />
    </ModalDialog>
  );
};

ValidateDomainModal.propTypes = {
  /**
   * The domains to validate. Defaults to [], in which case domains needing validation will be fetched using JIL API.
   */
  domains: PropTypes.arrayOf(PropTypes.shape({domainName: PropTypes.string})),
  /**
   * Callback triggered when validation action completes.
   */
  onDomainsValidated: PropTypes.func,
};

export default ValidateDomainModal;
