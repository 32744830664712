import {AlphaListState, log} from '@admin-tribe/acsc';
import {
  DATE_FORMATS,
  OverlayWait,
  Subpage,
  TableSectionTable,
  ViewDetailsDrawerTrigger,
  showError,
} from '@admin-tribe/acsc-ui';
import {View} from '@adobe/react-spectrum';
import {useAsyncModel} from '@pandora/react-async-model';
import {useContentEntry} from '@pandora/react-content-provider';
import {
  EnDashDefaultContent,
  PAGE_SIZE_OPTIONS,
  TABLE_SECTION_ACTIONS,
  TableFilters,
  TableSection,
  onTokenPaginatedTableSectionChange,
} from '@pandora/react-table-section';
import {
  PICKER_TYPE,
  SEARCH_TYPE,
  UserPickerContextProvider,
  UserPickerV3,
  UserPickerV3ContentModel,
} from '@pandora/react-user-picker';
import React, {useCallback, useState} from 'react';
import {useIntl} from 'react-intl';

import useTableSectionPageCounter from 'common/hooks/useTableSectionPageCounter';
import {USER_FOLDER_DEFAULT_PAGE_SIZE} from 'common/services/user-folder/userFolderConstants';
import UserFolderList from 'common/services/user-folder-list/UserFolderList';
import rootStore from 'core/RootStore';
import FolderIconWithName from 'features/storage/components/folder-icon-with-name/FolderIconWithName';
import UserFolderDetailsDrawer from 'features/storage/components/user-folder-details-drawer/UserFolderDetailsDrawer';
import {
  formatName,
  formatStorageForDisplay,
} from 'features/storage/components/utils/storageDisplayUtils';

/**
 *  The individual users folders active users subpage
 */
const ActiveUsersSubpage = () => {
  const intl = useIntl();
  const [isFiltering, setIsFiltering] = useState(false);
  const [filteredItems, setFilteredItems] = useState(null);

  const content = useContentEntry(UserPickerV3ContentModel);

  const fetchUserFolderList = useCallback(
    () =>
      UserFolderList.get({
        state: new AlphaListState({
          cacheNextTokens: true,
          pageSize: USER_FOLDER_DEFAULT_PAGE_SIZE,
        }),
      }),
    []
  );

  const {
    model: userFolderList,
    isLoading,
    error,
    updateModel,
  } = useAsyncModel({loadFn: fetchUserFolderList});

  // Keep the list updated when changing number of items in the page
  const refreshList = useCallback(async () => {
    await updateModel(() => userFolderList.refresh());
  }, [userFolderList, updateModel]);

  // Maintains current page and total page state for the paginator
  const {currentPage, totalPages, onTableSectionChangePageReducer} = useTableSectionPageCounter({
    listState: userFolderList?.state,
  });

  const getViewDetailsDrawerTrigger = ({item}) => (
    <ViewDetailsDrawerTrigger
      tooltipText={intl.formatMessage({
        id: 'storage.individualUserFolders.drawerButton.hoverText',
      })}
    >
      {() => <UserFolderDetailsDrawer selectedFolder={item} />}
    </ViewDetailsDrawerTrigger>
  );

  const onTableSectionChange = async ({action, payload}) => {
    // Update internal state
    onTokenPaginatedTableSectionChange({
      action,
      payload,
      tokenPaginatedDataUtils: userFolderList?.state,
    });

    // Update current page and total pages separately as it isn't handled by TableSection
    onTableSectionChangePageReducer({action});

    switch (action) {
      case TABLE_SECTION_ACTIONS.GO_TO_NEXT_PAGE:
      case TABLE_SECTION_ACTIONS.GO_TO_PREVIOUS_PAGE:
      case TABLE_SECTION_ACTIONS.ON_PAGE_SIZE_CHANGE:
      case TABLE_SECTION_ACTIONS.ON_SORT_BY:
        // Remove ON_SORT_BY action with temp_remove_storage_date_sort flag
        // Refresh user folders
        await refreshList();
        break;
      default:
      // do nothing
    }
  };

  const isInitialLoadDone = userFolderList?.items !== undefined;

  const tableColumnDescriptor = [
    {key: 'folder'},
    {
      key: 'viewDetails',
      props: {align: 'end', hideHeader: true, maxWidth: '15%', showDivider: true},
    },
    {key: 'email'},
    {key: 'createdOn', props: {maxWidth: '15%'}},
    {key: 'usage', props: {maxWidth: '12%'}},
    {key: 'quota', props: {maxWidth: '12%'}},
  ];

  const tableRenderers = {
    createdOn: ({item}) => (
      <EnDashDefaultContent>
        {intl.formatDate(item.repositoryCreatedDate, DATE_FORMATS.default)}
      </EnDashDefaultContent>
    ),
    email: ({item}) => item.userName,
    folder: ({item}) => <FolderIconWithName>{formatName(intl, item)}</FolderIconWithName>,
    quota: ({item}) => formatStorageForDisplay(intl, item.quota.amount),
    usage: ({item}) => formatStorageForDisplay(intl, item.quota.consumed),
    viewDetails: ({item}) => getViewDetailsDrawerTrigger({item}),
  };

  // Called when filtering by a specific user
  const filterResults = useCallback(
    async (user) => {
      setIsFiltering(true);
      const folderList = new UserFolderList();
      try {
        await folderList.search({user});
        setFilteredItems(folderList.items);
      } catch (searchError) {
        showError(intl.formatMessage({id: 'common.toast.default.error'}));
        log.error('Error searching active user folders', searchError);
      }
      setIsFiltering(false);
    },
    [intl]
  );

  return (
    <Subpage isBumpered={!!error} isLoading={isLoading && !isInitialLoadDone}>
      <OverlayWait isLoading={isLoading || isFiltering} showContent={isInitialLoadDone} size="M">
        {isInitialLoadDone && (
          <TableSection
            id="active-user-folders-table"
            isServerError={error}
            items={filteredItems ?? userFolderList.items}
            onTableSectionChange={onTableSectionChange}
            // filteredItems can only contian one item or zero.  In either
            // case, there is only one page of results.
            pageNumber={filteredItems ? 1 : currentPage}
            pageSizeOptions={PAGE_SIZE_OPTIONS.MAX_AND_DEFAULT_50}
            totalPages={filteredItems ? 1 : totalPages}
          >
            <TableFilters hasSearch={false}>
              {/* add the marginTop that hasSearch="true" normally provides */}
              <View marginBottom="size-10" marginTop="size-200">
                <UserPickerContextProvider canAddUser={false}>
                  <UserPickerV3
                    content={content}
                    isDisabled={!isFiltering && userFolderList.items.length === 0}
                    label={intl.formatMessage({
                      id: 'common.userListTableSection.search',
                    })}
                    onInputChange={(value) => {
                      if (!value) {
                        setFilteredItems(null);
                      }
                    }}
                    onSelect={filterResults}
                    orgId={rootStore.organizationStore.activeOrgId}
                    pickerType={PICKER_TYPE.USERS_ONLY}
                    searchType={SEARCH_TYPE.EXISTING_OR_NEW_USER}
                    width="size-3600"
                  />
                </UserPickerContextProvider>
              </View>
            </TableFilters>
            <TableSectionTable
              aria-label={intl.formatMessage({id: 'storage.individualUserFolders.table.ariaLabel'})}
              columnDescriptor={tableColumnDescriptor}
              columnNamespace="storage.individualUserFolders.column"
              displayName="TableSectionTable"
              noItemsFoundContentMessage={intl.formatMessage({
                id: 'storage.individualUserFolders.table.activeUsers.noItemsFoundContentMessage',
              })}
              renderers={tableRenderers}
            />
          </TableSection>
        )}
      </OverlayWait>
    </Subpage>
  );
};

export default ActiveUsersSubpage;
