/* eslint-disable @admin-tribe/admin-tribe/check-browser-globals -- gainsight needs window */

import {Locale, dispatchUiEventAnalytics, eventBus, feature, log} from '@admin-tribe/acsc';
import pick from 'lodash/pick';
import {useEffect, useState} from 'react';

import SharedContextualParams, {
  CONTEXTUAL_PARAMS_SHARED,
} from 'common/services/sophia/shared-contextual-params/SharedContextualParams';
import rootStore from 'core/RootStore';
import {useAppInitializationState} from 'core/providers/appInitializationState/AppInitializationStateProvider';
import {useConfiguration} from 'core/providers/configuration/ConfigurationProvider';
import useEduRosterSync from 'features/settings/hooks/api/useEduRosterSync';

import orgChangeConstants from '../../shell/components/organization-switcher-container/orgChangeConstants';
import {useAuthentication} from '../providers/authentication/AuthenticationProvider';
import {useOrganization} from '../providers/organization/OrganizationProvider';

import {GAINSIGHT_EVENT_TYPES, PANEL_MANAGER} from './GainSightConstants';
import {isEDUIdPEligible} from './gainsightCommon';
import {
  getAuthSourcesInformation,
  getContractInformation,
  getEduIdpCreationEventData,
  getLicenseInformation,
  getMigrationInformation,
  getOrgInformation,
  getProductsInformation,
  getSyncInfo,
  loadGainsightScript,
} from './gainsightUtils';
import useExtendedUserProfile from './useExtendedUserProfile';

/**
 * Custom React hook for integrating with Gainsight.
 *
 * @returns {Object} An object containing the following properties:
 *   - authenticationLoaded: A boolean indicating whether the authentication has been loaded.
 *   - gainSightLoaded: A boolean indicating whether Gainsight has been loaded.
 *   - GainSights: The GainSights data.
 */
export default function useGainsightInit() {
  const [gainSightLoaded, setGainSightLoaded] = useState(false);
  const {profile, authenticationLoaded, roles} = useAuthentication();
  const {autoAssignRulesCacheLoaded} = useAppInitializationState();
  const {configuration} = useConfiguration();
  const {organization} = useOrganization();
  const {getAllDirectoryRosterSyncs} = useEduRosterSync();
  const {firstLoginTime, extendedUserProfileLoaded} = useExtendedUserProfile();
  const localeVal = Locale.get().activeLocaleCode.replace('_', '-');

  // Fetch data from the GainSight file
  useEffect(() => {
    if (
      !organization ||
      !configuration ||
      !extendedUserProfileLoaded ||
      !autoAssignRulesCacheLoaded
    )
      return;

    function setupCustomEventTracking() {
      eventBus.registerEventHandler((eventId, eventDetail) => {
        if (eventId === PANEL_MANAGER.MODAL.CLOSE) {
          const {id, params} = eventDetail;
          window.aptrinsic('track', 'Modal Close', {'modal-id': id, ...params});
        }
      });

      eventBus.registerEventHandler(async (eventId, eventDetail) => {
        if (eventId === 'EDU IdP added') {
          const {addedIdpName, directoryId, idps} = eventDetail;
          try {
            const data = await getEduIdpCreationEventData({
              activeOrg: organization.id,
              directoryId,
              idps,
            });
            window.aptrinsic('track', 'EDU IdP added', data);
          } catch (error) {
            log.error(
              `${addedIdpName} config added for ${directoryId}, but error occurred preparing Gainsight data. Did not send to Gainsight. Error: `,
              error
            );
          }
        }
      });
    }

    async function pushAccountInfo() {
      const {productList, orgContracts, orgMigrations} = getOrgInformation(rootStore);

      const activeOrg = rootStore.organizationStore.activeOrg;

      const sharedContextualParams = await SharedContextualParams.get();

      const authSourcesInformation = await getAuthSourcesInformation(activeOrg);
      const enabledFeatures = feature.getFeatures().filter(feature.isEnabled);

      const pendingUserIntroductions = sharedContextualParams['1pic'] > 0;

      const landingPage = window.location.pathname;

      window.aptrinsic('reset');

      const syncInfo = await getSyncInfo(activeOrg);
      const sharedContextParamsObj = pick(sharedContextualParams, CONTEXTUAL_PARAMS_SHARED);
      const orgMarketSegment = activeOrg.marketSegment;

      window.aptrinsic(
        'identify',
        {
          // user information
          adminRoles: sharedContextualParams['1ar'],
          enabledFeatures,
          id: profile.userId, // Required for logged in app users
          landingPage,
          locale: localeVal,
          signUpDate: firstLoginTime.getTime(), // gainsight needs date in milliseconds
        },
        {
          // account/org information
          ...getContractInformation(orgContracts),
          ...getProductsInformation(productList),
          ...syncInfo,
          id: activeOrg.id, // Required
          isEDUIdPEligible: isEDUIdPEligible(activeOrg),
          ...getLicenseInformation(productList),
          ...getMigrationInformation(orgMigrations),
          pendingUserIntroductions,
          ...authSourcesInformation,
          ...sharedContextParamsObj,
          orgMarketSegment,
        }
      );
      sendVariantIdToGainsight();
    }

    function setupSwitchOrgListener() {
      eventBus.registerEventHandler(async (eventId) => {
        if (eventId === orgChangeConstants.SUCCESS) {
          await pushAccountInfo();
        }
      });
    }

    function setupEventListeners() {
      // Add listeners for Gainsight Engagement Events and mirror to Launch
      // https://support.gainsight.com/PX/API_for_Developers/02Usage_of_Different_APIs/Javascript_Event_Listener_API

      Object.values(GAINSIGHT_EVENT_TYPES).forEach((eventType) => {
        try {
          window.aptrinsic('addListener', eventType, (...args) => dispatch(eventType, ...args));
        } catch (error) {
          log.warn(`Unable to add Gainsight PX listener for type "${eventType}"`, error);
        }
      });

      // This dispatch will handle the callback for each Gainsight event type. The arguments varies by event type:
      // For Link Click and Custom Button Clicks arguments are: type, [url, engagement, step]
      // For all other event types, the arguments are: type, [engagement]
      function dispatch(type, ...args) {
        let gainsightEvent = {};

        switch (type) {
          case GAINSIGHT_EVENT_TYPES.LINK_CLICK:
          case GAINSIGHT_EVENT_TYPES.CUSTOM_BUTTON_CLICK:
            if (args.length >= 3) {
              gainsightEvent = {
                engagement: args[1],
                step: args[2],
                url: args[0],
              };
            } else {
              log.warn(
                `Cannot dispatch analytics for Gainsight event "${type}": expected >= 3 args but got ${args.length}`
              );
            }
            break;

          default:
            if (args.length > 0) {
              gainsightEvent = {
                engagement: args[0],
              };
            } else {
              log.warn(
                `Cannot dispatch analytics for Gainsight event "${type}": expected >= 1 args but got ${args.length}`
              );
            }
        }

        dispatchUiEventAnalytics({
          eventAction: type,
          eventName: 'gainsight',
          gainsightEvent,
        });
      }
    }

    async function sendVariantIdToGainsight() {
      const groups = await feature.getAllReleases();
      const expectedReleases =
        Object.keys(groups).length > 0
          ? groups.filter((group) => group.release_name.includes('_gainsight_modal_'))
          : [];

      const eventResults = [];
      eventResults.push(
        expectedReleases.map(
          (expectedRelease) =>
            `${expectedRelease.release_name}:${expectedRelease.release_analytics_params[0].variant_id}`
        )
      );

      // Send the variant-id to 'userLandingEvent'
      window.aptrinsic('track', 'userLandingEvent', {'variant-id': eventResults.join(',')});
    }

    const setUpData = async () => {
      const gainsightApiKey = configuration.services.gainsight.apiKey;
      const gainsightUrl = configuration.services.gainsight.url;
      if (!gainsightApiKey) {
        log.info('No Gainsight api key found');
      }
      if (!gainsightUrl) {
        log.info('No Gainsight url found');
      }
      // Gainsight can only run if this user is an org admin for the org being looked at
      if (roles.isOrgAdminForOrg(organization.id)) {
        loadGainsightScript(gainsightApiKey, gainsightUrl);
        await pushAccountInfo();
        setupCustomEventTracking();
        setupSwitchOrgListener();
        setupEventListeners();
        setGainSightLoaded(true);
      } else {
        log.info('Gainsight cannot load for non-org admins');
      }

      /* eslint-enable @admin-tribe/admin-tribe/check-browser-globals -- window is needed by gainsight*/
    };
    if (feature.isEnabled('gainsight_tracking')) {
      setUpData();
    }
  }, [
    profile.userId,
    authenticationLoaded,
    autoAssignRulesCacheLoaded,
    configuration,
    localeVal,
    getAllDirectoryRosterSyncs,
    roles,
    organization,
    extendedUserProfileLoaded,
    firstLoginTime,
  ]);

  return {authenticationLoaded, gainSightLoaded};
}
