import {EVENT_ACTION, dispatchUiEventAnalytics} from '@admin-tribe/acsc';
import cond from 'lodash/cond';
import merge from 'lodash/merge';
import property from 'lodash/property';

import rootStore from 'core/RootStore';
import {canViewRenewalNotifications} from 'core/contract/access/contractAccess';
import {
  ACCOUNT_PAGE_LINK,
  CONTRACT_RENEWAL_POINT_FOR_DIRECT_CONTRACT,
} from 'core/contract/auto-renewal/AutoRenewalConstants';
import {getCurrentPointInRenewalInOverview} from 'core/contract/auto-renewal/autoRenewalUtils';
import {CHAT_APP_ID} from 'core/services/chat/chatProviderConstants';
import {GLOBAL_BANNER_TYPES} from 'features/global-banner/GlobalBannerConstants';

import {getRenewalOverviews, hasNotRenewedContractPostRenewalWindow} from './contractRenewalUtils';

/**
 * @description Method for checking if renewal banners should be shown, will
 *     generate the corresponding banner payloads, and then add them to the
 *     global banner store.
 */
async function notifyContractRenewalBanners() {
  // This checks the Acquisition Summaries, which are only accessible to org admins
  if (canViewRenewalNotifications()) {
    const renewalOverviews = await getRenewalOverviews();
    const renewalBanners = Object.keys(renewalOverviews).map((key) =>
      overviewToGlobalBanner(renewalOverviews[key])
    );

    addVipSubscriptionLapsedBanners();

    renewalBanners
      .flat(Number.POSITIVE_INFINITY)
      .filter((x) => !!x)
      .forEach((banner) => rootStore.organizationStore.globalBannerStore.add(banner));
  }
}

//////////

function addVipSubscriptionLapsedBanners() {
  const vipSubscriptionLapsedOverview = hasNotRenewedContractPostRenewalWindow();
  if (vipSubscriptionLapsedOverview) {
    rootStore.organizationStore.globalBannerStore.add({
      message: vipSubscriptionLapsedOverview.isVIPMPContract
        ? 'globalBanner.messages.renewals.indirect.postRenewal.noSubscriptions.vipmp'
        : 'globalBanner.messages.renewals.indirect.postRenewal.noSubscriptions.vip',
      messageArgs: {
        resellerName: vipSubscriptionLapsedOverview.resellerName,
      },
      messageDates: {
        anniversaryDate: vipSubscriptionLapsedOverview.anniversaryDate,
      },
      messageTimes: {
        anniversaryTime: vipSubscriptionLapsedOverview.anniversaryTime,
      },
      type: GLOBAL_BANNER_TYPES.ERROR,
    });
  }
}

function chatOnClick() {
  dispatchUiEventAnalytics({
    eventAction: 'click',
    eventName: 'chatAutoRenewOffPost',
  });
}

function completeRenewalNotification(overview) {
  const LIMITED_COUNTRIES = ['RU', 'BY'];
  if (
    rootStore.organizationStore.contractList.items.some((contract) =>
      LIMITED_COUNTRIES.includes(contract.getOwnerCountryCode())
    )
  ) {
    return {
      message: 'globalBanner.messages.renewals.indirect.completeAbbreviated',
      type: GLOBAL_BANNER_TYPES.INFO,
    };
  }
  const message = overview.resellerName
    ? 'globalBanner.messages.renewals.indirect.complete'
    : 'globalBanner.messages.renewals.indirect.completeVIPMP';
  return {
    message,
    messageArgs: {
      resellerName: overview.resellerName,
    },
    type: GLOBAL_BANNER_TYPES.INFO,
  };
}

// Adding this temporary fix in order to support some data which currently not supported binky/dispatchUiEventAnalytics
// eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- temp fix
/* istanbul ignore next */
function mergeDigitalData(data) {
  // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- required
  if (window?.digitalData) {
    // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- required
    window.digitalData = merge(window.digitalData, data);
  }
}

function onBannerLoad() {
  // organizationInfo.contract is not supported via binky/dispatchUiEventAnalytics
  // hence, using this temparory solution of updating digitalData directly
  mergeDigitalData({
    adminConsole: {
      organizationInfo: {
        contract: {
          autoRenewStatus: rootStore.organizationStore.contractList.items[0]?.autoRenewalMode,
          autoRenewUpdateChannel: 'renewal',
        },
      },
    },
  });

  dispatchUiEventAnalytics({
    eventAction: EVENT_ACTION.DISPLAY,
    eventName: 'optOutRenGlobalBanner',
    interaction: {
      impression:
        'optOutRenGlobalBanner:customerSupport|adminConsole|renewal,optOutRenGlobalBanner:LearnMore|adminConsole|renewal',
    },
  });
}

function renderPreRenewalWindowGlobalOptInBanner(overview) {
  const optInSelfServiceWindow = {
    messageDates: {
      anniversaryDate: overview.anniversaryDate,
      startOfRenewalWindow: overview.startOfRenewalWindow,
    },
    type: GLOBAL_BANNER_TYPES.WARNING,
  };

  optInSelfServiceWindow.message =
    'globalBanner.messages.renewals.direct.autoRenewalOptOut.preRenewalWindowSelfService';
  optInSelfServiceWindow.messageArgs = {
    linkUiSref: 'account.account',
    linkUiSrefArgs: {context: ACCOUNT_PAGE_LINK.OPT_IN_SELF_SERVICE},
  };

  return {
    ...optInSelfServiceWindow,
  };
}

function createAutoRenewalOptOutNotification(overview) {
  switch (getCurrentPointInRenewalInOverview(overview)) {
    case CONTRACT_RENEWAL_POINT_FOR_DIRECT_CONTRACT.PRE_RENEWAL_WINDOW_OPT_IN_SELF_SERVICE:
      onBannerLoad();
      return renderPreRenewalWindowGlobalOptInBanner(overview);
    case CONTRACT_RENEWAL_POINT_FOR_DIRECT_CONTRACT.PRE_RENEWAL_WINDOW:
      onBannerLoad();
      return {
        message: 'globalBanner.messages.renewals.direct.autoRenewalOptOut.preRenewalWindow',
        messageArgs: {
          linkHref: 'https://www.adobe.com/go/csupport_subscripterms',
          linkUiHrefArgs: {context: ACCOUNT_PAGE_LINK.CONTACT_SUPPORT},
        },
        messageDates: {
          anniversaryDate: overview.anniversaryDate,
          startOfRenewalWindow: overview.startOfRenewalWindow,
        },
        type: GLOBAL_BANNER_TYPES.WARNING,
      };
    case CONTRACT_RENEWAL_POINT_FOR_DIRECT_CONTRACT.IN_RENEWAL_WINDOW:
      onBannerLoad();
      return {
        message: 'globalBanner.messages.renewals.direct.autoRenewalOptOut.inRenewalWindow',
        messageArgs: {
          linkHref: 'https://www.adobe.com/go/csupport_subscripterms',
          linkUiHrefArgs: {context: ACCOUNT_PAGE_LINK.CONTACT_SUPPORT},
        },
        messageDates: {
          anniversaryDate: overview.anniversaryDate,
        },
        type: GLOBAL_BANNER_TYPES.WARNING,
      };
    case CONTRACT_RENEWAL_POINT_FOR_DIRECT_CONTRACT.POST_RENEWAL_WINDOW:
      onBannerLoad();
      return {
        chatAppId: CHAT_APP_ID.ONESIE1_CCT_RETENTION,
        chatOnClick,
        message: 'globalBanner.messages.renewals.direct.autoRenewalOptOut.postRenewalWindow',
        messageArgs: {
          linkHref: 'https://www.adobe.com/go/csupport_subscripterms',
          linkUiHrefArgs: {context: ACCOUNT_PAGE_LINK.CONTACT_SUPPORT},
        },
        messageDates: {
          anniversaryDate: overview.anniversaryDate,
        },
        type: GLOBAL_BANNER_TYPES.ERROR,
      };
    default:
      // Do not show any Auto Renewal Opt Out related Notifications
      return undefined;
  }
}

function createDigitalRiverRenewalNotification(overview) {
  return {
    message: 'globalBanner.messages.renewals.teamDirect.digitalRiver',
    messageArgs: {
      goUrlName: 'cct_dr_renewal',
    },
    messageDates: {
      anniversaryDate: overview.anniversaryDate,
    },
    type: GLOBAL_BANNER_TYPES.INFO,
  };
}

function createDirectRenewalWindowNotification(overview) {
  const futureCurrency = overview.nextBillingInfo?.futureCurrency;

  if (futureCurrency && (futureCurrency === 'MXN' || futureCurrency === 'CAD')) {
    return {
      message: `globalBanner.messages.renewals.teamDirect.directCurrencyUpdate.${futureCurrency}`,
      messageArgs: {
        linkUiSref: 'account.account',
      },
      messageDates: {
        anniversaryDate: overview.anniversaryDate,
      },
      type: GLOBAL_BANNER_TYPES.INFO,
    };
  }

  return {
    message: 'globalBanner.messages.renewals.teamDirect.direct',
    messageArgs: {
      linkUiSref: 'account.account',
    },
    messageDates: {
      anniversaryDate: overview.anniversaryDate,
    },
    type: GLOBAL_BANNER_TYPES.INFO,
  };
}

function getAnniversaryArgs(overview) {
  return {
    messageArgs: {
      resellerName: overview.resellerName,
    },
    messageDates: {
      anniversaryDate: overview.anniversaryDate,
    },
    messageTimes: {
      anniversaryTime: overview.anniversaryDate,
    },
  };
}

function getEndOfRenewalWindowArgs(overview) {
  return {
    messageArgs: {
      resellerName: overview.resellerName,
    },
    messageDates: {
      endOfRenewalWindowDate: overview.endOfRenewalWindow,
    },
    messageTimes: {
      endOfRenewalWindowTime: overview.endOfRenewalWindow,
    },
  };
}

function preAnniversaryNothingRenewedNotification(overview) {
  return {
    message: overview.resellerName
      ? 'globalBanner.messages.renewals.indirect.preAnniversary.none.withReseller'
      : 'globalBanner.messages.renewals.indirect.preAnniversary.none.withoutReseller',
    type: GLOBAL_BANNER_TYPES.INFO,
    ...getAnniversaryArgs(overview),
  };
}

function preAnniversaryPartialRenewalNotification(overview) {
  if (overview.isVIPMPContract) {
    return {
      message: overview.isUserBasedRenewal
        ? 'globalBanner.messages.renewals.indirect.preAnniversary.partial.VIPMPUserBased'
        : 'globalBanner.messages.renewals.indirect.preAnniversary.partial.VIPMPNonUserBased',
      type: GLOBAL_BANNER_TYPES.INFO,
      ...getAnniversaryArgs(overview),
    };
  }
  return {
    message: 'globalBanner.messages.renewals.indirect.preAnniversary.partial',
    type: GLOBAL_BANNER_TYPES.INFO,
    ...getAnniversaryArgs(overview),
  };
}

function overviewToGlobalBanner(overview) {
  const notifications = [];
  if (shouldAttemptToShowAutoRenewalBanners(overview)) {
    notifications.push(createAutoRenewalOptOutNotification(overview));
  } else if (overview.isInRenewalWindow && overview.hasRenewableProducts) {
    if (overview.isDirectContract) {
      if (overview.isDrContract) {
        notifications.push(createDigitalRiverRenewalNotification(overview));
      } else if (overview.isRenewableDirectContract) {
        notifications.push(createDirectRenewalWindowNotification(overview));
      }
    } else if (overview.isInPostAnniversaryRenewalWindow) {
      notifications.push(overviewToPostAnniversaryNotification(overview));
    } else {
      notifications.push(overviewToPreAnniversaryNotification(overview));
    }
  }
  return notifications;
}

function overviewToPostAnniversaryNotification(overview) {
  return cond([
    [property('allComplete'), postAnniversaryCompleteNotification],
    [property('hasPartial'), postAnniversaryPartialNotification],
    [() => true, postAnniversaryNothingRenewedNotification], // default
  ])(overview); // immediately invoke
}

function overviewToPreAnniversaryNotification(overview) {
  return cond([
    [property('allComplete'), preAnniversaryCompleteRenewalNotification],
    [property('hasPartial'), preAnniversaryPartialRenewalNotification],
    [() => true, preAnniversaryNothingRenewedNotification], // default
  ])(overview); // immediately invoke
}

function postAnniversaryCompleteNotification(overview) {
  return completeRenewalNotification(overview);
}

function postAnniversaryNothingRenewedNotification(overview) {
  return {
    message: 'globalBanner.messages.renewals.indirect.postAnniversary.none',
    type: GLOBAL_BANNER_TYPES.ERROR,
    ...getEndOfRenewalWindowArgs(overview),
  };
}

function postAnniversaryPartialNotification(overview) {
  return {
    message: 'globalBanner.messages.renewals.indirect.postAnniversary.partial',
    ...getEndOfRenewalWindowArgs(overview),
    type: GLOBAL_BANNER_TYPES.WARNING,
  };
}

function preAnniversaryCompleteRenewalNotification(overview) {
  return completeRenewalNotification(overview);
}

function shouldAttemptToShowAutoRenewalBanners(overview) {
  return overview.isDirectContract && !overview.isDrContract && overview.isAutoRenewalOff;
}
// eslint-disable-next-line import/prefer-default-export -- Utils file
export {notifyContractRenewalBanners};
