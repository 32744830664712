import get from 'lodash/get';

import {setProperty} from '../aepAnalytics';

/**
 * /
 */
export default function errorEventTranslator(errorDescriptor) {
  setProperty('error.client.primary.errorInfo.name', get(errorDescriptor, 'clientErrorName'));
}
