import {DIRECTORY_TYPE} from '@admin-tribe/acsc';
import {Flex, Text} from '@adobe/react-spectrum';
import {ImageIcon} from '@pandora/react-image-icon';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';

import BusinessID from './icons/business-id.svg';
import EnterpriseID from './icons/enterprise-id.svg';
import FederatedID from './icons/federated-id.svg';

const TYPE_TO_ICON = {
  [DIRECTORY_TYPE.TYPE2]: EnterpriseID,
  [DIRECTORY_TYPE.TYPE2E]: BusinessID,
  [DIRECTORY_TYPE.TYPE3]: FederatedID,
};

const DirectoryType = ({directoryType, withIcon, ...styleProps}) => (
  <Flex
    alignItems="center"
    gap="size-100"
    marginBottom="size-200"
    marginTop="size-200"
    {...styleProps}
  >
    {withIcon && <ImageIcon alt="" src={TYPE_TO_ICON[directoryType]} />}
    <Text>
      <FormattedMessage id={`settings.common.directoryType.${directoryType}`} />
    </Text>
  </Flex>
);

DirectoryType.propTypes = {
  directoryType: PropTypes.string,
  /**
   * Whether to show an icon to the left of the directory type string or not.
   */
  withIcon: PropTypes.bool,
};

export default DirectoryType;
