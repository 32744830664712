import {feature, translateJson} from '@admin-tribe/acsc';
import get from 'lodash/get';
import set from 'lodash/set';

import {setProperty} from '../aepAnalytics';

/**
 * Translate the product list descriptor into a format consumable by analytics.
 *
 * @param {*} launchContext
 * @param {*} productListDescriptor
 */
export default function productListEventTranslator(productListDescriptor) {
  const productList = productListDescriptor.items.map((productDescriptor) => {
    const product = {};

    translateJson(productDescriptor, product, {
      licenseCount: 'attributes.totalLicense',
      licenseType: 'attributes.licenseType',
      numberSelected: 'attributes.assignedLicense',
      numberUnassigned: 'attributes.unassignedLicenseCount',
      offer_id: 'productInfo.offerId',
      productName: 'productInfo.productName',
    });

    if (feature.isEnabled('temp_self_cancel')) {
      translateJson(productDescriptor, product, {
        cancelQuantity: 'attributes.cancelQuantity',
        id: 'productInfo.subscriptionGUID',
      });

      if (Number.isFinite(get(productDescriptor, 'cancelQuantity'))) {
        // A boolean primitive with value 'false' will be ignored by Launch Analytics.
        // A coerced string value is used here to ensure it is sent.
        set(
          product,
          'attributes.completeLicenseCancel',
          (productDescriptor.cancelQuantity === productDescriptor.licenseCount).toString()
        );
      }
    }

    if (feature.isEnabled('temp_renewal_analytcs_promos')) {
      set(product, 'price', get(productDescriptor, 'pricing'));
      set(product, 'attributes.sourceOfferLocation', get(productDescriptor, 'productBucket'));
    }

    // Need to map 'numberSelected' to multiple attributes, and
    // jsUtils.translateJson doesn't support single source property to
    // multiple targets.
    set(product, 'quantity', get(productDescriptor, 'numberSelected'));

    return product;
  });

  setProperty('product', productList);
}
