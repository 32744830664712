import {
  GLOBAL_ADMIN_POLICY,
  GlobalAdminPolicyList,
  PAGE_TARGET_TYPE,
  download,
  log,
} from '@admin-tribe/acsc';

import {BULK_OPERATION_MODE, BULK_OPERATION_UPLOAD_ERRORS} from './bulkOperationConstants';
import {localizeCsvFile} from './bulkOperationLocalization';

/**
 * @description Returns corresponding upload error message
 *
 * @param {String} errorCode - the errorCode
 * @returns {Object} params for PageBanner
 */
const getErrorMessage = (errorCode) => {
  if (errorCode === BULK_OPERATION_UPLOAD_ERRORS.BULK_UPLOAD_ROW_COUNT_EXCEEDS_LIMIT) {
    return {
      children: {
        goUrlName: 'ac_bulk_threshold',
        id: `common.bulkOperation.pageBanner.error.body.BULK_UPLOAD_ROW_COUNT_EXCEEDS_LIMIT`,
      },
      header: {
        id: 'common.bulkOperation.pageBanner.error.header.BULK_UPLOAD_ROW_COUNT_EXCEEDS_LIMIT',
      },
      variant: 'error',
    };
  }

  if (Object.values(BULK_OPERATION_UPLOAD_ERRORS).includes(errorCode)) {
    return {
      children: {
        id: `common.bulkOperation.pageBanner.error.body.${errorCode}`,
      },
      header: {
        id: 'common.bulkOperation.pageBanner.error.header.generic',
      },
      variant: 'error',
    };
  }

  return {
    children: {
      id: 'common.bulkOperation.pageBanner.error.body.generic',
    },
    header: {
      id: 'common.bulkOperation.pageBanner.error.header.generic',
    },
    variant: 'error',
  };
};

/**
 * @description Get bulk operation page header message when the "manageAdmins" policy is disabled
 * @param {Object} options Options
 * @param {String} options.mode One of the BULK_OPERATION_MODE values
 * @param {String} options.orgId Organization ID
 * @param {String} options.targetType One of the PAGE_TARGET_TYPE
 * @returns {Object} Bulk operation page header data if "manageAdmins" policy is disabled.
 * Otherwise, return null.
 */
const getManageAdminPolicyBanner = async ({mode, orgId, targetType}) => {
  // This should use hasManageAdminsPolicy() in adminAccess.
  const globalAdminPolicyList = await GlobalAdminPolicyList.get({orgId});
  const canManageAdmins = globalAdminPolicyList.getPolicyValue(GLOBAL_ADMIN_POLICY.MANAGE_ADMINS);

  if (
    !canManageAdmins &&
    targetType === PAGE_TARGET_TYPE.USER &&
    (mode === BULK_OPERATION_MODE.ADD ||
      mode === BULK_OPERATION_MODE.EDIT ||
      mode === BULK_OPERATION_MODE.REMOVE)
  ) {
    return {
      children: {
        id: 'common.bulkOperation.globalAdminPolicy.manageAdmins.description',
      },
      header: {
        id: 'common.bulkOperation.globalAdminPolicy.manageAdmins.header',
      },
      showDefaultButton: false,
      variant: 'info',
    };
  }

  return null;
};

/**
 * @description Returns bulk operation localized upload success message
 */
const getSuccessMessage = (intl, fileCount) =>
  intl.formatMessage(
    {
      id: 'common.bulkOperation.toast.success',
    },
    {
      fileCount,
    }
  );

/**
 * @description Localizes the CSV and downloads it. If localization fails,
 * will download the given CSV.
 *
 * @param {File} csvFile - the CSV file to download
 * @param {String} fileName - the name of the csv to download. Defaults to 'sample.csv'
 */
const localizeAndDownload = async (csvFile, fileName = 'sample.csv') => {
  let localizedTemplate;
  try {
    localizedTemplate = await localizeCsvFile(csvFile);
  } catch (error) {
    log.error('Error localizing CSV sample', error);
  }

  if (localizedTemplate) {
    download(localizedTemplate, fileName);
  } else {
    // If localization fails, just download the regular csv
    download(csvFile, fileName);
  }
};

/**
 * @description Download user list
 * @param {String} [domainName] Domain name
 * @param {Function} exportFunction JIL API used to export the user list
 * @param {Object} [exportParams] Params to call exportFunction with
 * @param {Function} onError Callback when exportFunction fails
 * @param {String} orgId Org ID
 */
const downloadUserList = async ({domainName, exportFunction, exportParams, onError, orgId}) => {
  let response;

  const queryParams = domainName
    ? {
        emailDomain: domainName,
      }
    : undefined;

  try {
    response = await exportFunction({
      orgId,
      params: queryParams,
      ...exportParams,
    });
  } catch (error) {
    onError?.(error);
  }

  if (response?.data?.file) {
    const downloadFileName = domainName ? `${domainName}-users.csv` : 'users.csv';
    await localizeAndDownload(response.data.file, downloadFileName);
  }
};

/**
 * @description Download user list
 * @param {String} [domainName] Domain name
 * @param {Function} exportFunction JIL API used to export the user list
 * @param {Object} [exportParams] Params to call exportFunction with
 * @param {Function} onError Callback when exportFunction fails
 * @param {String} orgId Org ID
 */
const downloadUserGroupList = async ({exportFunction, exportParams, onError, orgId}) => {
  let response;

  try {
    response = await exportFunction({
      orgId,
      ...exportParams,
    });
  } catch (error) {
    onError?.(error);
  }

  if (response?.data?.file) {
    download(response?.data.file, 'user-groups.csv');
  }
};

/**
 * @description - uploads the given files and returns their upload promises
 *
 * @param {Object} params params object, see below
 * @param {Array<File>} params.files - the files to upload
 * @param {String} params.orgId - the orgId
 * @param {Function} params.uploadFunction - the uploadFunction that will upload the CSVs
 * @param {Object} params.uploadParams - the uploadParams to be attached as query params
 * @returns {Array<Promise>} - the promises return by the uploadFunction
 */
const uploadFiles = ({files, orgId, uploadFunction, uploadParams}) => {
  const uploadPromises = [];

  files.forEach((file) => {
    const data = new FormData();
    data.append('file', file, file.name);

    const promise = uploadFunction({
      data,
      orgId,
      params: uploadParams,
    });
    uploadPromises.push(promise);
  });

  return uploadPromises;
};

export {
  downloadUserGroupList,
  downloadUserList,
  getErrorMessage,
  getManageAdminPolicyBanner,
  getSuccessMessage,
  localizeAndDownload,
  uploadFiles,
};
