import {DetailSection} from '@admin-tribe/acsc-ui';
import {View} from '@adobe/react-spectrum';
import ClockIcon from '@spectrum-icons/workflow/Clock';
import React from 'react';
import {useIntl} from 'react-intl';

import {useBulkOperationJob} from 'features/users/services/bulk-operations/BulkOperationJobContext';

import BulkOperationIconDetail from './BulkOperationIconDetail';

/* section displaying the job's status when processing is still active */
const StatusDetailSection = () => {
  const intl = useIntl();
  const {getProcessingRate, getProgress} = useBulkOperationJob();

  return (
    <DetailSection
      ariaLabel={intl.formatMessage({
        id: 'bulkOperations.results.drawer.section.processing.ariaLabel',
      })}
      title={intl.formatMessage({id: 'bulkOperations.results.drawer.section.status'})}
    >
      <>
        <BulkOperationIconDetail Icon={ClockIcon}>
          {intl.formatMessage({id: 'common.status.processing'})}
        </BulkOperationIconDetail>
        <View>{getProcessingRate()}</View>
        <View>{getProgress()}</View>
      </>
    </DetailSection>
  );
};

export default StatusDetailSection;
