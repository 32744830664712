import {log, modelCache} from '@admin-tribe/acsc';
import cloneDeep from 'lodash/cloneDeep';
import toInteger from 'lodash/toInteger';

import {CART_EVENT} from 'features/offers/freeOfferCartConstants';

import jilStorageQuota from '../../api/jil/jilStorageQuota';

import STORAGE_QUOTA_CONSTANTS from './StorageQuotaConstants';

const {CACHE_ID, UNIT} = STORAGE_QUOTA_CONSTANTS;

modelCache.clearOnEvent(CACHE_ID, [CART_EVENT.SUBMIT]);

class StorageQuota {
  static get() {
    const model = new StorageQuota();
    const key = model.getKey();
    if (modelCache.has(CACHE_ID, key)) {
      const cachedPromise = modelCache.get(CACHE_ID, key);
      if (cachedPromise) {
        // Cache expired
        return cachedPromise;
      }
    }

    // Item is not already cached.
    return model.refresh();
  }

  /**
   * @description Creates a new StorageQuota for use.
   *
   * @param {Object} options Initialization Object (params described below).
   * @param {String} options.enforcement The enforcement of the quota for this org.
   * @param {Number} options.amount The total number of quota.
   * @param {Number} options.consumed The number of consumer quota.
   * @param {String} options.unit The unit of quota.
   */
  constructor(options = {}) {
    this.modelId = CACHE_ID;
    updateModel(this, options);
  }

  /**
   * @description Fetches the storage total amount as an integer.
   *
   * @returns {Integer} the storage total amount as an integer.
   */
  getAmount() {
    return toInteger(this.amount);
  }

  /**
   * @description The storage quota key, used for caching.
   *
   * @returns {String} the unique key for this instance of the storage quota.
   */
  getKey() {
    return this.modelId;
  }

  /**
   * @description Calculates the percentage of used storage.
   *
   * @returns {Integer} the percentage, rounded as an integer
   *  or -1 if the total storage amount is 0.
   */
  getUsagePercentage() {
    if (this.amount === 0) {
      log.error('StorageQuota amount is 0, can not calculate usage percentage.');
      return -1;
    }
    return Math.round((this.consumed / this.amount) * 100);
  }

  /**
   * @description Method to fetch storage quota from back-end.
   *
   * @returns {Promise} resolves to StorageQuota on success, else rejects with error.
   */
  async refresh() {
    try {
      const response = await jilStorageQuota.getStorageQuota();
      updateModel(this, response.data);
      modelCache.put(CACHE_ID, this.getKey(), this);
    } catch (error) {
      log.error('StorageQuota failed to load. Error: ', error);
      return Promise.reject(error);
    }
    return this;
  }
}

/**
 * @description Initializes Storage Quota data.
 *
 * @param {Object} model StorageQuota model Object instance to initialize.
 * @param {Object} options initialization object (as described in constructor options parameter).
 */
function updateModel(model, options) {
  // Cloned to avoid issues when updating the nested object items.
  const clonedOptions = cloneDeep(options);
  const {amount, consumed, enforcement} = clonedOptions;
  Object.assign(model, {amount, consumed, enforcement, unit: getUnit(options.unit)});
}

/**
 * @description Maps the unit of measurement into one of the expected constants.
 *
 * @param {String} unit the unit of measurement on the storage. For example "Bytes".
 * @returns {STORAGE_QUOTA_CONSTANTS.UNIT | undefined} returns a string of the type STORAGE_QUOTA_CONSTANTS.UNIT
 *  or undefined if the provided unit is not recognized.
 */
function getUnit(unit = UNIT.GIGABYTES) {
  const upperCaseUnit = unit.toUpperCase();
  if (!UNIT[upperCaseUnit]) {
    log.error(`Invalid storage quota unit: ${unit}`);
  }
  return UNIT[upperCaseUnit];
}

export default StorageQuota;
