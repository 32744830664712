import {navBus} from '@admin-tribe/acsc';
import {Content, Flex, Header, Heading} from '@adobe/react-spectrum';
import {Drawer, DrawerTrigger} from '@pandora/react-drawer';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {generatePath} from 'react-router-dom';

import rootStore from 'core/RootStore';
import useStatusData from 'features/notifications/hooks/use-status-data/useStatusData';
import {
  PATH_EXPERT_SESSION_DETAILS,
  PATH_SUPPORT_CASE_DETAILS,
} from 'features/support/routing/supportPaths';

import NotificationsDrawerContent from './NotificationsDrawerContent';
import NotificationsDrawerTrigger from './NotificationsDrawerTrigger';

/**
 * Header component for the notifications tab
 * @deprecated Remove with temp_change_status_api
 */
const NotificationsContainer = () => {
  const intl = useIntl();
  const notificationList = rootStore.organizationStore.notificationList;
  const orgId = rootStore.organizationStore.activeOrgId;
  const {statusData} = useStatusData();
  const statusPanelCount = statusData?.getActiveAndRecentStatusEvents()?.length ?? 0;

  const notificationCount = notificationList?.getUnreadCount();

  const [notificationDisplayCount, setNotificationDisplayCount] = useState(notificationCount);

  // Set the notification count in the drawer trigger
  useEffect(() => {
    setNotificationDisplayCount(notificationCount);
  }, [notificationCount]);

  const onChangeNotification = () => {
    setNotificationDisplayCount(notificationList?.getUnreadCount());
  };

  const handleClick = (payload, closeDrawer) => {
    if (payload.expertSessionId) {
      navBus.navigate(
        generatePath(PATH_EXPERT_SESSION_DETAILS, {orgId, ticketId: payload.expertSessionId})
      );
    } else if (payload.supportCaseId) {
      navBus.navigate(
        generatePath(PATH_SUPPORT_CASE_DETAILS, {orgId, ticketId: payload.supportCaseId})
      );
    } else {
      // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- opens link
      window?.open(payload.surveyUrl, '_blank', 'noopener,noreferrer');
    }

    closeDrawer();
  };

  return (
    <DrawerTrigger offsetTop="var(--spectrum-global-dimension-size-700)" paddingX="size-150">
      {(open) => (
        <NotificationsDrawerTrigger
          notificationDisplayCount={notificationDisplayCount}
          openDrawer={open}
        />
      )}
      {/* eslint-disable-next-line @admin-tribe/admin-tribe/extract-large-computations -- needed to add custom close onClick */}
      {(closeDrawer) => (
        <Drawer aria-label={intl.formatMessage({id: 'shell.notifications.drawer.ariaLabel'})}>
          <Header marginX="size-100">
            <Heading level={1} marginTop="calc(-1 * size-100)">
              {intl.formatMessage({id: 'shell.notifications.title'})}
            </Heading>
            <Flex marginBottom="size-225">
              {intl.formatMessage({id: 'shell.notifications.description'})}
            </Flex>
          </Header>
          <Content>
            <NotificationsDrawerContent
              notificationDisplayCount={notificationDisplayCount}
              notificationList={notificationList}
              onChangeNotification={onChangeNotification}
              onClick={(payload) => handleClick(payload, closeDrawer)}
              statusPanelCount={statusPanelCount}
            />
          </Content>
        </Drawer>
      )}
    </DrawerTrigger>
  );
};

export default NotificationsContainer;
