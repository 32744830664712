import {ORGANIZATION_MARKET_SEGMENT, feature} from '@admin-tribe/acsc';

import rootStore from 'core/RootStore';
import {TEMPLATE_TYPES} from 'features/packages/components/create-package-modal/CreatePackageConstants';
import {
  CREATE_PACKAGE_CONSTANTS,
  PACKAGE_TYPE_CONSTANTS,
} from 'features/packages/packagesConstants';
import PackagesEntitlementsService from 'features/packages/services/PackagesEntitlementsService';

/**
 * @description Method to check if ACC is not suppressed. In case of lightweight packages containing software configuration, ACC is not actually suppressed
 * @param {Object} attributes - attributes object used in summary drawer
 * @returns {Boolean} True if ACC is supressed, flase otherwise
 */
function isAccUnsupressed(attributes) {
  return (
    !attributes.accSupressed ||
    attributes.package?.packageType === PACKAGE_TYPE_CONSTANTS.PLUGIN_AND_SW_CONFIG ||
    attributes.package?.packageType === PACKAGE_TYPE_CONSTANTS.SW_CONFIG_ONLY
  );
}

/**
 * @description Method to check if we need to show summary drawer options
 * @param {Object} attributes.package - the package
 * @param {Object} attributes.package.packageType - type of the package
 * @param {Object} attributes.template - the template
 * @param {Object} attributes.template.nativePackageId - package id of native template
 * @returns {Boolean} True if we need to show summary drawer options, false otherwise
 */
function showOptionsTab(attributes) {
  return !(
    attributes.template?.nativePackageId ||
    ((PackagesEntitlementsService.hasDcOnlyEntitlement ||
      PackagesEntitlementsService.hasNonCcOnlyEntitlement) &&
      attributes.package?.packageType === PACKAGE_TYPE_CONSTANTS.FRL_ISOLATED) ||
    attributes.package?.packageType === PACKAGE_TYPE_CONSTANTS.PLUGIN_ONLY
  );
}

/**
 * @description Method to check if we need to show RUM options in summary drawer
 * @param {Object} attributes.package - the package
 * @param {Object}  attributes.package.packageType - type of the package
 * @returns {Boolean} True if we need to show RUM options, flase otherwise
 */
function showRUMOption(attributes) {
  return !(attributes?.package?.packageType === PACKAGE_TYPE_CONSTANTS.FRL_ISOLATED);
}

/**
 * @description Method to check if we need to show Apps panel in summary drawer
 * @param {Object} attributes - attributes object used in summary drawer
 * @param {Boolean} attributes.accSupressed - whether option to show Apps panel is checked in management option
 * @returns {Boolean} True if we need to show Apps panel, flase otherwise
 */
function showAppsPanelOption(attributes) {
  return isAccUnsupressed(attributes);
}

/**
 * @description Method to check if package type is managed
 * @param {Object} attributes - attributes object used in summary drawer
 * @returns {Boolean} True if package type is managed, flase otherwise
 */
function isManagedPackage(attributes) {
  return (
    attributes.package.packageType === PACKAGE_TYPE_CONSTANTS.MANAGED ||
    attributes.package.packageType === PACKAGE_TYPE_CONSTANTS.PLUGIN_ONLY ||
    attributes.package.packageType === PACKAGE_TYPE_CONSTANTS.PLUGIN_AND_SW_CONFIG ||
    attributes.package.packageType === PACKAGE_TYPE_CONSTANTS.SW_CONFIG_ONLY
  );
}

/**
 * @description Method to check if we need to show beta app available option in summary drawer
 * @param {Object} attributes - attributes object used in summary drawer
 * @param {Object} attributes.package - the package
 * @param {Object} attributes.package.configurations - package configurations
 * @param {Boolean} attributes.package.configurations.betaAppDownloadEnabled - whether option to enable install of beta apps is checked in management option
 * @returns {Boolean} True if we need to show beta app available option, flase otherwise
 */
function showBetaAppDownloadOption(attributes) {
  if (
    attributes.package &&
    attributes.package.configurations &&
    attributes.package.configurations.betaAppDownloadEnabled === undefined
  ) {
    return false;
  }
  return (
    isAccUnsupressed(attributes) &&
    (attributes.isTemplate ||
      attributes.package.packageType === PACKAGE_TYPE_CONSTANTS.NUELL ||
      isManagedPackage(attributes) ||
      attributes.package.packageType === PACKAGE_TYPE_CONSTANTS.SELF_SERVICE)
  );
}

/**
 * @description Method to check if we need to show AUSST option in summary drawer
 * @param {Object} attributes - attributes object used in summary drawer
 * @param {Object} attributes.package - the package
 * @param {Object}  attributes.package.packageType - type of the package
 * @param {Boolean}  attributes.showCcpCreatedPackages - whether we are showing CCP created packages
 * @returns {Boolean} True if we need to show AUSST option, flase otherwise
 */
function showAUSSTOption(attributes) {
  return (
    !attributes.showCcpCreatedPackages &&
    attributes.package?.packageType !== PACKAGE_TYPE_CONSTANTS.FRL_ISOLATED
  );
}

/**
 * @description Method to get frl online activation type
 * @param {Object} attributes - attributes object used in summary drawer
 * @param {Object} attributes.package - the package
 * @param {Object}  attributes.package.packageType - type of the package
 * @param {Object}  attributes.package.frlPackagingInfo - information related to FRL package
 * @param {String}  attributes.package.frlPackagingInfo.frlOnlineActivationType - activation type of FRL packages
 * @returns {String} frl online activation type
 */
function getFrlOnlineActivationType(attributes) {
  if (
    attributes.package &&
    attributes.package.packageType === PACKAGE_TYPE_CONSTANTS.FRL_HIGH_PRIVACY_ONLINE
  ) {
    if (
      attributes.package.frlPackagingInfo?.frlOnlineActivationType ===
      CREATE_PACKAGE_CONSTANTS.FRL_FIXED_DNS
    ) {
      return CREATE_PACKAGE_CONSTANTS.FRL_FIXED_DNS;
      // else this is FIXED IP
    }
    if (
      attributes.package.frlPackagingInfo?.frlOnlineActivationType ===
      CREATE_PACKAGE_CONSTANTS.FRL_FIXED_IP
    ) {
      return CREATE_PACKAGE_CONSTANTS.FRL_FIXED_IP;
    }
  }
  return '';
}

/**
 * @description Method to check if device information sharing is enabled in package
 * @param {Object} attributes - attributes object used in summary drawer
 * @param {Object} attributes.package - the package
 * @param {Boolean} attributes.package.isDeviceInformationShared - whether option to share device information is checked in management options
 * @returns {Boolean} True if device information sharing is enabled, flase otherwise
 */
function isDeviceInformationShared(attributes) {
  return (
    rootStore.organizationStore.activeOrg.marketSegment !== ORGANIZATION_MARKET_SEGMENT.EDUCATION &&
    attributes.package &&
    attributes.package.isDeviceInformationShared
  );
}

/**
 * @description Method to check if package type is accepted in showExtenstionsOption method
 * @param {Object} attributes - attributes object used in summary drawer
 * @param {Object} attributes.package - the package
 * @param {Object}  attributes.package.packageType - type of the package
 * @returns {Boolean} True if package type is accepted in showExtenstionsOption method, flase otherwise
 */
function isAcceptedPackageType(attributes) {
  return (
    attributes.package.packageType === PACKAGE_TYPE_CONSTANTS.FRL_ISOLATED ||
    attributes.package.packageType === PACKAGE_TYPE_CONSTANTS.FRL_OFFLINE ||
    attributes.package.packageType === PACKAGE_TYPE_CONSTANTS.FRL_HIGH_PRIVACY_ONLINE
  );
}

/* eslint-disable complexity -- will be fixed when we remove feature flag */
/**
 * @description Method to check if we need to show extensions option in summary drawer
 * @param {Object} attributes - attributes object used in summary drawer
 * @param {Object} attributes.package - the package
 * @param {Object} attributes.package.platform - platform for which package is built
 * @param {Boolean}  attributes.showCcpCreatedPackages - whether we are showing CCP created packages
 * @param {Boolean} attributes.accSupressed - whether option to show Apps panel is checked in management option
 * @returns {Boolean} True if we need to show extensions option, flase otherwise
 */
function showExtenstionsOption(attributes) {
  if (
    !attributes.showCcpCreatedPackages &&
    ((feature.isDisabled('temp_packages_acrobat_only_flow') &&
      PackagesEntitlementsService.hasCcEntitlement &&
      (isAccUnsupressed(attributes) ||
        (attributes.package && isAcceptedPackageType(attributes)))) ||
      (feature.isEnabled('temp_packages_acrobat_only_flow') &&
        (PackagesEntitlementsService.hasCcEntitlement ||
          PackagesEntitlementsService.hasDcEntitlement)))
  ) {
    if (
      attributes.package &&
      attributes.package.platform === CREATE_PACKAGE_CONSTANTS.MAC_ARM_PLATFORM
    ) {
      return true;
    }
    if (
      attributes.package &&
      attributes.package.platform === CREATE_PACKAGE_CONSTANTS.WIN_ARM_PLATFORM
    ) {
      return feature.isEnabled('packages_extensions_winarm');
    }
    return true;
  }
  return false;
}
/* eslint-enable complexity -- will be fixed when we remove feature flag */

/**
 * @description Method to check if we need to show package install diretory option in summary drawer
 * @param {Object} attributes - attributes object used in summary drawer
 * @param {Object} attributes.package - the package
 * @param {Object}  attributes.package.packageType - type of the package
 * @returns {Boolean} True if we need to show package install diretory option, flase otherwise
 */
function showInstallDirectoryOption(attributes) {
  if (
    !attributes.showCcpCreatedPackages &&
    (isAccUnsupressed(attributes) ||
      (attributes.package &&
        (attributes.package.packageType === PACKAGE_TYPE_CONSTANTS.FRL_OFFLINE ||
          attributes.package.packageType === PACKAGE_TYPE_CONSTANTS.FRL_ISOLATED ||
          attributes.package.packageType === PACKAGE_TYPE_CONSTANTS.FRL_HIGH_PRIVACY_ONLINE)))
  ) {
    return true;
  }
  return false;
}

/**
 * @description Method to check if we need to show self serve plugins option in summary drawer
 * @param {Object} attributes - attributes object used in summary drawer
 * @param {Boolean} attributes.isTemplate - whether the package-summary-drawer is opened on Template's page
 * @param {Object} attributes.package - the package
 * @param {Object}  attributes.package.packageType - type of the package
 * @param {Object} attributes.package.platform - platform for which package is built
 * @param {Object} attributes.package.bits - platform bits used for which package is built
 * @returns {Boolean} True if we need to show self serve plugins option, flase otherwise
 */
function showSelfServePluginsOption(attributes) {
  return (
    isAccUnsupressed(attributes) &&
    !rootStore.organizationStore.isActiveOrgEdu &&
    (attributes.isTemplate ||
      ((attributes.package.packageType === PACKAGE_TYPE_CONSTANTS.MANAGED ||
        attributes.package.packageType === PACKAGE_TYPE_CONSTANTS.SELF_SERVICE) &&
        !(
          attributes.package.platform === CREATE_PACKAGE_CONSTANTS.WIN_PLATFORM &&
          attributes.package.bits === CREATE_PACKAGE_CONSTANTS.THIRTY_TWO_BIT
        ) &&
        !(
          attributes.package.platform === CREATE_PACKAGE_CONSTANTS.WIN_ARM_PLATFORM &&
          feature.isDisabled('packages_extensions_winarm')
        )))
  );
}

/**
 * @description Method to check if we need to show auto app update option in summary drawer
 * @param {Object} attributes - attributes object used in summary drawer
 * @param {Object} attributes.package - the package
 * @param {Object} attributes.package.configurations - package configurations
 * @param {Boolean} attributes.package.configurations.accDisableAutoAppUpdate - whether auto-update package for end users option is checked in management option
 * @returns {Boolean} True if we need to show auto app update option, flase otherwise
 */
function showAutoAppUpdateOption(attributes) {
  if (
    attributes.package &&
    attributes.package.configurations &&
    attributes.package.configurations.accDisableAutoAppUpdate === undefined
  ) {
    return false;
  }
  return isAccUnsupressed(attributes);
}

/**
 * @description Method to check if we need to show browser based authentication option in summary drawer
 * @param {Object} attributes - attributes object used in summary drawer
 * @param {Boolean} attributes.accSupressed - whether option to show Apps panel is checked in management option
 * @returns {Boolean} True if we need to show browser based authentication option, flase otherwise
 */
function showBrowserBasedAuthOption(attributes) {
  return isAccUnsupressed(attributes);
}

/**
 * @description Method to check if we need to show custom DNS option in summary drawer
 * @param {Object} attributes - attributes object used in summary drawer
 * @param {Object} attributes.package - the package
 * @param {Object}  attributes.package.packageType - type of the package
 * @param {Object}  attributes.package.frlPackagingInfo - information related to FRL package
 * @param {Object}  attributes.package.frlPackagingInfo.profileServerUrl - link to server url in case of FRL package
 * @returns {Boolean} True if we need to show custom DNS option, flase otherwise
 */
function showCustomDNSAddress(attributes) {
  return (
    attributes.package &&
    attributes.package.packageType === PACKAGE_TYPE_CONSTANTS.FRL_HIGH_PRIVACY_ONLINE &&
    attributes.package.frlPackagingInfo?.profileServerUrl &&
    attributes.package.frlPackagingInfo.profileServerUrl !==
      rootStore.packagesUiConstantsStore.frlStaticDnsEndpoint
  );
}

/**
 * @description Method to check if we need to show disable update option in summary drawer
 * @param {Object} attributes - attributes object used in summary drawer
 * @param {Boolean} attributes.templateType - to check if it template customized package
 * @returns {Boolean} True if we need to show disable update option, False otherwise
 */
function showDisableUpdateOption(attributes) {
  return !!(
    attributes.templateType &&
    attributes.templateType === TEMPLATE_TYPES.NON_CC_CUSTOMIZED &&
    feature.isEnabled('packages_disable_update_option')
  );
}

export {
  showCustomDNSAddress,
  showBrowserBasedAuthOption,
  showAutoAppUpdateOption,
  showSelfServePluginsOption,
  showInstallDirectoryOption,
  showExtenstionsOption,
  isDeviceInformationShared,
  getFrlOnlineActivationType,
  showAUSSTOption,
  showBetaAppDownloadOption,
  showAppsPanelOption,
  showRUMOption,
  showOptionsTab,
  isAccUnsupressed,
  showDisableUpdateOption,
};
