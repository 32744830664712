import {Cache, jilDirectories, log} from '@admin-tribe/acsc';
import {useCallback} from 'react';

import rootStore from 'core/RootStore';

const domainEnforcementCache = new Cache();

/**
 * A hook that helps with making calls to the JIL API for Domain Enforcement.
 */
const useDomainEnforcement = () => {
  const updateDomainEnforcementPolicy = useCallback(async ({directoryId, state}) => {
    try {
      const response = await jilDirectories.putDirectoryDomainEnforcement({
        directoryId,
        orgId: rootStore.organizationStore.activeOrgId,
        state,
      });

      return response;
    } catch (error) {
      log.error(
        `[ID][JIL] Error update Domain Enforcement policy for directory ${directoryId}`,
        error
      );

      throw error;
    }
  }, []);

  const createExceptions = useCallback(async ({directoryId, users}) => {
    try {
      const {data} = await jilDirectories.postDomainEnforcementExceptionList({
        directoryId,
        exclusions: users,
        orgId: rootStore.organizationStore.activeOrgId,
      });

      const successful = data.filter(({responseCode}) => responseCode === 200);

      const failed = data.filter(({responseCode}) => responseCode !== 200);

      return {failed, successful};
    } catch (error) {
      log.error(
        `[ID][JIL] Error creating the exceptions from Domain Enforcement policy for directory ${directoryId}`,
        error
      );

      throw error;
    }
  }, []);

  const getExceptions = useCallback(async ({directoryId}) => {
    try {
      const cachedResponse = domainEnforcementCache.get(`domainEnforcement/${directoryId}`);

      if (cachedResponse) {
        return cachedResponse;
      }

      const {data} = await jilDirectories.getDomainEnforcementExceptionList({
        directoryId,
        orgId: rootStore.organizationStore.activeOrgId,
      });

      domainEnforcementCache.put(`domainEnforcement/${directoryId}`, data);

      return data;
    } catch (error) {
      log.error(
        `[ID][JIL] Error getting the exceptions from Domain Enforcement policy for directory ${directoryId}`,
        error
      );

      throw error;
    }
  }, []);

  const deleteExceptions = useCallback(async ({directoryId, emails}) => {
    const patches = emails.map((email) => ({exclusion: email, op: 'remove'}));

    try {
      const {data} = await jilDirectories.patchDomainEnforcementExceptionList({
        directoryId,
        orgId: rootStore.organizationStore.activeOrgId,
        patches,
      });

      return {data};
    } catch (error) {
      log.error(
        `[ID][JIL] Error deleting the exceptions from Domain Enforcement policy for directory ${directoryId}`,
        error
      );

      throw error;
    }
  }, []);

  const clearDomainEnforcementCache = useCallback(() => domainEnforcementCache.clearAll(), []);

  return {
    clearDomainEnforcementCache,
    createExceptions,
    deleteExceptions,
    getExceptions,
    updateDomainEnforcementPolicy,
  };
};

export default useDomainEnforcement;
