import {configStore, getHeaders} from '@admin-tribe/acsc';
import axios from 'axios';

import AppConstants from 'common/services/AppConstants';

let clientId, includeRoles, url;

(async function loadJilConfig() {
  ({url, clientId, includeRoles} = await configStore.getServiceConfiguration('jil'));
})();

/**
 * Configure JIL org contract migrations API
 *
 * @param {String} contractId - The contract id
 * @param {String} type - The contract migration type. Ex: TEAM_VIP_TO_TEAM_EVIP
 *
 * @return {Promise} a promise which will resolve with the response data
 */
const getMigrations = ({contractId, type}) =>
  axios.get(`${url}/v2/organizations/${AppConstants.orgId}/contracts/${contractId}/migrations`, {
    headers: getHeaders({clientId, includeRoles}),
    params: {type},
  });

/**
 * Migrate user to new prooduct.
 *
 * @param {Array<Object>} requestBody - Array of data to migrate user to new prooduct
 *
 * @return {Promise} a promise which will resolve with the response data
 */
const migrate = (requestBody) => {
  if (requestBody?.type && requestBody?.type === 'TEAM_VIP_TO_TEAM_EVIP') {
    return axios.post(
      `${url}/v2/organizations/${AppConstants.orgId}/contracts/${requestBody.contractId}/migrations`,
      requestBody,
      {
        headers: getHeaders({clientId, includeRoles}),
        params: {type: requestBody.type},
      }
    );
  }
  return axios.post(
    `${url}/v2/organizations/${AppConstants.orgId}:migrate.delegates`,
    requestBody,
    {
      headers: getHeaders({clientId, includeRoles}),
    }
  );
};

const jilOrganizationContractMigration = {getMigrations, migrate};

export default jilOrganizationContractMigration;
