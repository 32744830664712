import PropTypes from 'prop-types';

/**
 * Component to wrap the shell GlobalHeader and footer, and conditionally render the children based
 * on the current path
 * (i.e. don't render the children if the current path contains /approve-product). This is
 * generally because we want to show a PageWait spinner instead of the normal content with
 * GlobalHeader + footer.
 */
const HeaderFooterWrapper = ({children}) => (hideHeaderFooterOnCurrentPage() ? null : children);

// When all Angular UI is gone and the GlobalHeader moves back into Shell.jsx, this can use
// react-router-dom instead of window.location
function hideHeaderFooterOnCurrentPage() {
  if (typeof window !== 'undefined') {
    const currentPath = window.location.pathname;
    return currentPath && currentPath.includes('/approve-product');
  }
  return false;
}

HeaderFooterWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HeaderFooterWrapper;
