import {navBus} from '@admin-tribe/acsc';
import {Item, TabList, TabPanels, Tabs, View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {useParams} from 'react-router-dom';

import useInsightsPaths from '../hooks/useInsightsPaths';
import {getTranslationFromReportBroker} from '../insightsUtils';

import InsightsItemDetailListTableWrapper from './InsightsItemDetailListTableWrapper';

/** Renders tabs for the current Insights item */
const InsightsDetailsTabs = ({insightsItem}) => {
  const intl = useIntl();
  const {sectionDetails} = useInsightsPaths();
  const {tabName} = useParams();
  const [selectedTab, setSelectedTab] = useState(tabName);

  /** Keep the currently selected tab in sync with the URL */
  useEffect(() => {
    setSelectedTab(tabName);
  }, [tabName]);

  const tabs = useMemo(
    () =>
      insightsItem.tabs.map((tab) => {
        const tabTitle = getTranslationFromReportBroker(intl, tab.nameKey, tab.name);
        return {
          content: (
            <Item key={tab.id}>
              <View data-testid="tab-description" marginTop="size-100">
                {getTranslationFromReportBroker(intl, tab.descriptionKey, tab.description)}
              </View>

              <InsightsItemDetailListTableWrapper insightsItem={insightsItem} tabName={tab.id} />
            </Item>
          ),
          title: <Item key={tab.id}>{tabTitle}</Item>,
        };
      }),
    [insightsItem, intl]
  );

  const onChange = useCallback(
    (key) => {
      if (key === tabName) {
        // Currently on the tab that was clicked, don't navigate
        return;
      }

      // Just modify the URL, the key will get updated in the useEffect above
      navBus.pushState({url: sectionDetails(insightsItem.insightsSection, insightsItem.id, key)});
    },
    [insightsItem, sectionDetails, tabName]
  );

  return (
    <Tabs
      aria-label={intl.formatMessage({id: 'insights.details.tabs.ariaLabel'})}
      data-testid="details-tabs"
      onSelectionChange={onChange}
      selectedKey={selectedTab}
    >
      <TabList>{tabs.map((tab) => tab.title)}</TabList>
      <TabPanels>{tabs.map((tab) => tab.content)}</TabPanels>
    </Tabs>
  );
};

InsightsDetailsTabs.propTypes = {
  /** The Insights Item to render tabs for */
  insightsItem: PropTypes.shape({
    id: PropTypes.string,
    insightsSection: PropTypes.string,
    name: PropTypes.string,
    nameKey: PropTypes.string,
    tabs: PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.string,
        descriptionKey: PropTypes.string,
        id: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
};

export default InsightsDetailsTabs;
