import {
  DATE_FORMATS,
  FormattedDateAndTime,
  OverlayWait,
  showError as showErrorToast,
} from '@admin-tribe/acsc-ui';
import {ActionButton, Badge, Divider, Flex, Heading, Text, View} from '@adobe/react-spectrum';
import {Card} from '@react/react-spectrum/Card';
import Refresh from '@spectrum-icons/workflow/Refresh';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {SCHEDULE_OPTIONS} from 'features/settings/api/eduRosterSync';
import {useRosterSyncContext} from 'features/settings/components/directory/sync/RosterSyncContext';
import EduSyncCardFooter from 'features/settings/components/edu-sync-card/footer/EduSyncCardFooter';
import useEduRosterSync from 'features/settings/hooks/api/useEduRosterSync';

import styles from './EduSyncCard.pcss';
import EduSyncStatus, {EDU_SYNC_STATUSES} from './EduSyncStatus';

const EduSyncCard = ({syncDetails}) => {
  const intl = useIntl();

  const {fetchRosterSyncInfo} = useEduRosterSync();
  const {updateRosterSyncData} = useRosterSyncContext();

  const [isLoading, setIsLoading] = useState(false);

  const isSyncStatusStopped = syncDetails.status === EDU_SYNC_STATUSES.STOPPED;

  const onRefresh = async () => {
    try {
      setIsLoading(true);

      const response = await fetchRosterSyncInfo({syncId: syncDetails.syncId});
      const updatedSyncData = response.data;

      updateRosterSyncData({syncId: syncDetails.syncId, updatedSyncData});
    } catch {
      showErrorToast(intl.formatMessage({id: 'settings.sync.eduSync.toast.refreshError'}));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <OverlayWait isLoading={isLoading} showContent>
      <Card
        allowsSelection={false}
        data-testid="edu-roster-sync-card"
        isDropTarget={false}
        styleName="sync-card"
      >
        <Badge
          data-testid="sync-schedule-badge"
          // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needed for XD styling
          UNSAFE_className={styles.badge}
          variant="neutral"
        >
          <Text>
            <FormattedMessage
              id={`settings.sync.eduSync.card.badge.labels.${syncDetails.syncSchedule}`}
            />
          </Text>
        </Badge>
        <EduSyncStatus status={syncDetails.status} />

        <View height="size-2400">
          <Flex alignItems="center" justifyContent="space-between">
            <Heading
              data-testid="roster-source-title"
              level={2}
              margin="0"
              // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needed for XD styling
              UNSAFE_className={styles.provider}
            >
              <FormattedMessage
                id={`settings.sync.eduSync.providers.${syncDetails.rosterSource}`}
              />
            </Heading>
            <ActionButton
              aria-label={intl.formatMessage({
                id: 'settings.sync.eduSync.card.refreshButtonAriaLabel',
              })}
              data-testid="refresh-button"
              isQuiet
              onPress={onRefresh}
            >
              <Refresh />
            </ActionButton>
          </Flex>

          <Divider size="S" />
          <Heading
            level={3}
            marginBottom="size-100"
            marginTop="size-200" // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needed for XD styling
            UNSAFE_className={styles.heading}
          >
            <FormattedMessage id="settings.sync.eduSync.card.lastSync" />
          </Heading>
          {syncDetails.lastSyncedAt ? (
            <FormattedDateAndTime
              format={DATE_FORMATS.defaultDateTime}
              value={syncDetails.lastSyncedAt}
            />
          ) : (
            <FormattedMessage id="settings.sync.eduSync.card.notSyncedYet" />
          )}

          {syncDetails.nextSyncAt && (
            <>
              <Heading
                level={3}
                marginBottom="size-100"
                marginTop="size-200" // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needed for XD styling
                UNSAFE_className={styles.heading}
              >
                <FormattedMessage id="settings.sync.eduSync.card.nextScheduledSync" />
              </Heading>
              {isSyncStatusStopped ? (
                <FormattedMessage id="settings.sync.eduSync.card.nextScheduledSync.stopped" />
              ) : (
                <FormattedDateAndTime value={syncDetails.nextSyncAt} />
              )}
            </>
          )}
        </View>

        <Flex justifyContent="space-between" marginTop="auto">
          <EduSyncCardFooter syncDetails={syncDetails} />
        </Flex>
      </Card>
    </OverlayWait>
  );
};

EduSyncCard.propTypes = {
  syncDetails: PropTypes.shape({
    /**
     * The ID of the school district as registered with the roster provider
     */
    districtId: PropTypes.string.isRequired,
    /**
     * Denotes the current state of the edu roster sync
     */
    enabled: PropTypes.bool.isRequired,
    /**
     * The unique identifier for the IMS Org
     */
    imsOrgId: PropTypes.string.isRequired,
    /**
     * The timestamp for the last sync. It's null if no sync happened yet
     */
    lastSyncedAt: PropTypes.string,
    /**
     * The timestamp for the next scheduled sync. It's null if sync is manual
     */
    nextSyncAt: PropTypes.string,
    /**
     * Denotes an Adobe product
     */
    products: PropTypes.arrayOf(
      PropTypes.shape({
        productAppName: PropTypes.string,
        productName: PropTypes.string,
      }).isRequired
    ),
    /**
     * The roster sync provider. The supported providers are Clever and Classlink
     */
    rosterSource: PropTypes.string.isRequired,
    /**
     * Denotes the current status of the edu roster sync
     */
    status: PropTypes.oneOf(Object.values(EDU_SYNC_STATUSES)).isRequired,
    /**
     * The unique identifier for a edu roster sync
     */
    syncId: PropTypes.string.isRequired,
    /**
     * The type of schedule by which the sync runs. One of "daily", "weekly", and "manual"
     */
    syncSchedule: PropTypes.oneOf(Object.values(SCHEDULE_OPTIONS)).isRequired,
  }).isRequired,
};

export default EduSyncCard;
