import {InvoiceList} from '@admin-tribe/acsc';
import {useAsyncModel} from '@admin-tribe/acsc-ui';
import {useCallback} from 'react';

import rootStore from 'core/RootStore';
import {hasUnpaidInvoices as unpaidInvoicesExist} from 'features/account/billing-history/BillingHistoryUtils';

/**
 * @description Hook used to check if there are any unpaid invoices
 * @returns {Object} state - Object composed of state variables: error, isLoading, unpaidInvoicesExist
 *          {Error} state.error - Defined if there is a problem retrieving the invoices
 *          {Boolean} state.isLoading - Whether the call is currently being made
 *          {Boolean} state.unpaidInvoicesExist - True if there are any unpaid invoices, false otherwise
 */
const useUnpaidInvoicesCheck = () => {
  const orgId = rootStore.organizationStore.activeOrgId;

  // it makes sense for useCallback here because we want to cache this call to reduce repeat API calls
  const fetchInvoiceList = useCallback(async () => {
    // operating on the assumption that unpaid invoices will show up in the most recent 100 invoices
    const invoiceList = await InvoiceList.get({
      isCacheable: true,
      orgId,
      pageSize: 100,
    });
    // cache unpaid invoices so that it will not make the API call again unless an invoice is paid
    // which will change the count
    const cachedUnpaidInvoices = invoiceList?.items?.filter((invoice) => invoice.isUnpaid());
    return (
      cachedUnpaidInvoices.length > 0 ||
      // this call will occur to invalidate the cache when all invoices are paid
      unpaidInvoicesExist(
        (
          await InvoiceList.get({
            isCacheable: false,
            orgId,
            pageSize: 100,
          })
        )?.items
      )
    );
  }, [orgId]);

  const {model: hasUnpaidInvoices, isLoading, error} = useAsyncModel({loadFn: fetchInvoiceList});
  return {error, hasUnpaidInvoices, isLoading};
};

export default useUnpaidInvoicesCheck;
