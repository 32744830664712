import {OverlayWait} from '@admin-tribe/acsc-ui';
import {View} from '@adobe/react-spectrum';
import {useContentEntry} from '@pandora/react-content-provider';
import {FileDropZone, FileDropZoneContentModel} from '@pandora/react-file-drop-zone';
import {useIsMounted} from '@pandora/react-is-mounted';
import SpectrumV2Provider from '@react/react-spectrum/Provider';
import {observer} from 'mobx-react-lite';
import React from 'react';

import {useCreateServerModalContext} from 'features/packages/components/servers-tab/create-server-modal/CreateServerModalContext';

import FileInfo from '../file-info/FileInfo';

import {onFileAddHelper} from './DragAndDropAuthFileUtils';

/**
 * This component is used in uploading the server authorization file.
 */
const DragAndDropAuthFile = observer(() => {
  const {packagesServerStore} = useCreateServerModalContext();
  const {authorizationFile} = packagesServerStore.serverSessionData;
  const isMounted = useIsMounted();

  const onFileAddHandler = async (addedFiles) => {
    if (addedFiles?.length > 0) {
      await onFileAddHelper(addedFiles, packagesServerStore, isMounted);
    }
  };

  const onAuthFileRemove = () => {
    packagesServerStore.setServerSessionDataValues('authorizationFileIdList', []);
    packagesServerStore.setServerSessionDataValues('authorizationFile', null);
    packagesServerStore.setServerSessionSettingValues('nextButtonEnabled', false);
  };

  const showFileDropZone =
    !authorizationFile ||
    !authorizationFile.isValid ||
    authorizationFile.hasValidationError ||
    authorizationFile.hasUploadError;

  const content = useContentEntry(FileDropZoneContentModel);

  return (
    <>
      <OverlayWait isLoading={packagesServerStore.serverSessionData.isAuthFileUploading}>
        {showFileDropZone && (
          <View marginBottom="size-300">
            <SpectrumV2Provider>
              <FileDropZone
                content={content}
                onChangeFiles={onFileAddHandler}
                onFileAdded={onFileAddHandler}
                showIcon={false}
              />
            </SpectrumV2Provider>
          </View>
        )}
      </OverlayWait>
      {/* file info area */}
      {authorizationFile && (
        <FileInfo fileInfoObject={authorizationFile} onDismiss={onAuthFileRemove} />
      )}
    </>
  );
});

export default DragAndDropAuthFile;
