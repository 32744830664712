import {feature, log} from '@admin-tribe/acsc';
import PropTypes from 'prop-types';
import React, {createContext, useContext, useEffect, useMemo, useState} from 'react';
import {useErrorHandler} from 'react-error-boundary';

import {useConfiguration} from 'core/providers/configuration/ConfigurationProvider';
import {useFeature} from 'core/providers/features/FeatureProvider';
import {useOrganization} from 'core/providers/organization/OrganizationProvider';
import chatProvider from 'core/services/chat/chatProvider';

const ChatStateContext = createContext();

/**
 * Provides chat state to its children components.
 * @returns {Object} The chat state.
 */

// eslint-disable-next-line @admin-tribe/admin-tribe/one-component-file -- @srathore this is a provider
const ChatStateProvider = ({children}) => {
  const {featureFlagsLoaded} = useFeature();
  const {configuration} = useConfiguration();
  const {organizationsLoaded} = useOrganization();
  const [chatStateReady, setChatStateReady] = useState(false);
  const handleError = useErrorHandler();

  // Initialize the chat provider when all dependencies are loaded
  useEffect(() => {
    if (!organizationsLoaded || !featureFlagsLoaded) {
      return;
    }

    const initChatProvider = async () => {
      try {
        await chatProvider.initialize();
        setChatStateReady(true);
      } catch (error) {
        log.error('Error in Provider when initializing chat:', error);
      }
    };

    // Initialize the chat based on the feature flags and user consent.
    async function initializeChat() {
      // not dependent on privacy consent anymore with flag temp_chat_unlink_cookies
      if (feature.isDisabled('disable_jarvis')) {
        if (
          feature.isEnabled('temp_chat_unlink_cookies') ||
          // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- This is a browser check
          window.AdobeMessagingExperienceClient
        ) {
          // If the chat library is already loaded then we are in fakes mode - do not set the event listener
          await initChatProvider();
        } else {
          // Must not initialize Jarvis chat until user gives their consent
          // Add event listener only if window is defined
          // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- This is a browser check
          window.addEventListener('adobePrivacy:PrivacyConsent', initChatProvider);
        }
      }
    }

    initializeChat();
  }, [featureFlagsLoaded, configuration, handleError, organizationsLoaded]);

  const chatStateContextValue = useMemo(
    () => ({
      chatStateReady,
    }),
    [chatStateReady]
  );

  return (
    <ChatStateContext.Provider value={chatStateContextValue}>{children}</ChatStateContext.Provider>
  );
};

ChatStateProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useChatState = () => useContext(ChatStateContext);

export {ChatStateProvider, useChatState};
