import {feature} from '@admin-tribe/acsc';
import {getContractTermsAcceptance} from '@pandora/data-model-contract';

import rootStore from 'core/RootStore';
import {isContractAdmin, isOrgAdmin} from 'core/organizations/access/organizationAccess';
import {GLOBAL_BANNER_TYPES} from 'features/global-banner/GlobalBannerConstants';

/**
 * @description Method called by the main boot service to generate the global banner for VIP MP 3YC
 */
function notifyThreeYearCommitGlobalBanner() {
  const contractList = rootStore.organizationStore.contractList;
  const hasEligible3YCContract = contractList.items.some((contract) => contract.isEligibleFor3YC());

  // If the customer is enrolled 3YC VIP, but needs re-accept new terms, set isNeedAcceptance true.
  let is3YCNeedsReacceptance = false;
  if (feature.isEnabled('temp_3yc_needs_reaccept')) {
    is3YCNeedsReacceptance = contractList.items.some(
      (contract) =>
        contract.isEnrolledIn3YC() &&
        getContractTermsAcceptance(contract, 'tc_vip3yc_indirect')?.needsAcceptance?.needed
    );
  }

  // The VIP3YC status is either Eligible or Enrolled.
  // If the customer is eligible for 3YC VIP, show banner of invitation to join.
  // Otherwise, customer has enrolled to 3YC VIP. Check if the customer needs to re-accept the terms.
  const message = hasEligible3YCContract
    ? 'globalBanner.messages.threeYearCommitMessage'
    : 'globalBanner.messages.threeYearCommitReacceptAgreement';

  if ((isOrgAdmin() || isContractAdmin()) && (hasEligible3YCContract || is3YCNeedsReacceptance)) {
    const banner = {
      linkText: 'globalBanner.linkText.threeYearCommitViewDetails',
      linkUiSref: 'account.account',
      message,
      type: GLOBAL_BANNER_TYPES.WARNING,
    };

    rootStore.organizationStore.globalBannerStore.add(banner);
  }
}

// eslint-disable-next-line import/prefer-default-export -- Utils file
export {notifyThreeYearCommitGlobalBanner};
