import {InfoItem, InfoItemScorecard} from '@admin-tribe/acsc-ui';
import React from 'react';

const createInfoItemScorecard = ({count, intl, key, label, value}) => {
  const scorecardData = {
    'data-testid': key,
    key,
    label: intl.formatMessage(
      {id: `products.groups.productGroupPageInfoBar.infoItem.label.${label}`},
      {count}
    ),
    value,
  };
  return typeof value === 'string' ? (
    <InfoItem {...scorecardData} />
  ) : (
    <InfoItemScorecard {...scorecardData} />
  );
};

const getUnit = (consumable) => (consumable.unit === 'credit' ? 'credit' : 'nonCredit');

/**
 * @description Method to construct a scorecard for total consumables
 * @param {Consumable} consumable - consumable summary to construct info from
 * @param {Object} intl - reference to translation service
 * @returns {JSX} element with total consumable data for consumable
 */
const renderTotalConsumables = ({consumable, intl}) => {
  const unit = getUnit(consumable);

  return createInfoItemScorecard({
    intl,
    key: 'total-consumables',
    label: `${unit}TotalLabel`,
    value: consumable.totalQuantity,
  });
};

/**
 * @description Method to construct a scorecard for total instances
 * @param {Object} intl - reference to translation service
 * @param {ProductGroupProductList} productGroup - product group to construct
 *   info from
 * @returns {JSX} element with total instances data for product grouping
 */
const renderTotalInstances = ({intl, productGroup}) =>
  createInfoItemScorecard({
    intl,
    key: 'total-instances',
    label: 'instancesTotalLabel',
    value: productGroup.items.length,
  });

/**
 * @description Method to construct a scorecard for used consumables
 * @param {Consumable} consumable - consumable summary to construct info from
 * @param {Object} intl - reference to translation service
 * @returns {JSX} element with used consumable data for consumable
 */
const renderUsedConsumables = ({consumable, intl}) => {
  const unit = getUnit(consumable);

  return createInfoItemScorecard({
    intl,
    key: 'used-consumables',
    label: `${unit}UsedLabel`,
    value: consumable.consumedQuantity,
  });
};

export {renderTotalConsumables, renderTotalInstances, renderUsedConsumables};
