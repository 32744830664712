import binky, {SEARCH_TARGET_CLASS, SEARCH_TARGET_RESULT_TYPE} from '@admin-tribe/acsc';
import binkyUI, {getOrganizationUserErrorProps} from '@admin-tribe/acsc-ui';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import AddUserFormTableWrapper from 'common/components/add-user-form-table/AddUserFormTableWrapper';
import {ROLE} from 'features/users/users.constants';

const AddUserFormTable = binkyUI.common.components.addUser.AddUserFormTable;
const MemberAndSelectedItemsList = binkyUI.common.components.addUser.MemberAndSelectedItemsList;
const ImageIcon = binkyUI.common.components.ImageIcon;
const {ModalContainer, ModalContent, ModalDescription, ModalDialog, ModalHeading, ModalTagHeader} =
  binkyUI.common.components.modal;
const UserPicker = binkyUI.common.components.UserPicker;

/**
 * Defines the modal for adding admins to a given License Group, also called a Product Profile in the UI.
 */
const AddAdminsToLicenseGroupModal = observer(
  ({isOpen, licenseGroupId, onCancel, onClosed /* @deprecated */, onSuccess, orgId, productId}) => {
    const intl = useIntl();

    const [modalError, setModalError] = React.useState(null);
    const [modalErrorProps, setModalErrorProps] = React.useState();
    const [isLoading, setIsLoading] = React.useState(true);
    const [isModalOpen, setModalOpen] = React.useState(isOpen);
    const [orgUserList, setOrgUserList] = React.useState();
    const [product, setProduct] = React.useState();
    const [licenseGroup, setLicenseGroup] = React.useState();
    const isCurrentRef = React.useRef(true);
    const memberAndSelectedItemsListRef = React.useRef();

    const onChange = (addUserFormTableData) => {
      const newOrgUserList = MemberAndSelectedItemsList.toOrgUserUnsavedList(
        addUserFormTableData,
        orgId,
        (memberAndSelectedItem) => {
          const member = memberAndSelectedItem.member;

          // Set the new admin role for all submitted users
          const role = {
            targets: [{id: licenseGroupId, parentId: productId}],
            type: ROLE.ADMIN.LICENSE,
          };
          member.roles.push(role);

          return member;
        }
      );
      setOrgUserList(newOrgUserList);
      memberAndSelectedItemsListRef.current = addUserFormTableData;
    };

    const onConfirm = async () => {
      setIsLoading(true);
      try {
        await orgUserList.save();
        if (isCurrentRef.current) {
          onSuccess?.();
          setModalOpen(false);
          setIsLoading(false);
        }
        return true;
      } catch (error) {
        if (isCurrentRef.current) {
          const props = getOrganizationUserErrorProps(intl, error);
          setModalError(props.message);
          setModalErrorProps(props);
          setIsLoading(false);
        }
        return false;
      }
    };

    const ctaToastGenerator = () =>
      intl.formatMessage(
        {id: 'common.toast.modal.adminsAdded'},
        {adminCount: orgUserList.items.length}
      );

    // gets Product information for rendering the modal
    React.useEffect(() => {
      setIsLoading(true);

      async function fetchProductAndLicenseGroup() {
        let retrievedLicenseGroup, retrievedProduct;

        try {
          // Calling APIs in this manner to run these awaits concurrently.
          const retrievedProductPromise = binky.services.product.Product.get({
            id: productId,
            orgId,
          });
          const retrievedLicenseGroupPromise = binky.services.product.licenseGroup.LicenseGroup.get(
            {
              id: licenseGroupId,
              orgId,
              productId,
            }
          );
          retrievedProduct = await retrievedProductPromise;
          retrievedLicenseGroup = await retrievedLicenseGroupPromise;
        } catch {
          if (isCurrentRef.current) {
            setModalError(intl.formatMessage({id: 'common.modal.error.generic'}));
            setModalErrorProps(undefined);
          }
        }

        if (isCurrentRef.current) {
          setProduct(retrievedProduct);
          setLicenseGroup(retrievedLicenseGroup);
          setIsLoading(false);
        }
      }

      fetchProductAndLicenseGroup();

      return () => {
        isCurrentRef.current = false;
      };
    }, [intl, licenseGroupId, orgId, productId]);

    return (
      <ModalContainer>
        {isModalOpen && (
          <ModalDialog
            cancelLabel={intl.formatMessage({
              id: 'common.addAdminsToLicenseGroupModal.cancelButton',
            })}
            ctaLabel={intl.formatMessage({
              id: 'common.addAdminsToLicenseGroupModal.confirmButton',
            })}
            ctaToastGenerator={ctaToastGenerator}
            errorMessage={modalError}
            errorToastProps={modalErrorProps}
            heightVariant="static"
            id="add-admins-to-product-profile-modal"
            isCtaDisabled={
              !orgUserList?.hasUnsavedChanges() || memberAndSelectedItemsListRef.current.isInvalid()
            }
            isLoading={isLoading}
            onCancel={onClosed || onCancel}
            onCta={onConfirm}
          >
            <ModalHeading>
              {intl.formatMessage({id: 'common.addAdminsToLicenseGroupModal.header'})}
            </ModalHeading>

            {product && licenseGroup && (
              <ModalTagHeader IconComponent={<ImageIcon alt="" size="M" src={product.getIcon()} />}>
                {licenseGroup.name}
              </ModalTagHeader>
            )}

            <ModalDescription>
              {intl.formatMessage({id: 'common.addAdminsToLicenseGroupModal.description'})}
            </ModalDescription>

            <ModalContent>
              <AddUserFormTableWrapper orgId={orgId}>
                <AddUserFormTable
                  data-testid="add-user-form-table-testid"
                  onChange={onChange}
                  orgId={orgId}
                  pickerType={UserPicker.PICKER_TYPE.USERS_ONLY}
                  searchType={UserPicker.SEARCH_TYPE.EXISTING_USER}
                  targetOptions={{
                    searchTargetType: SEARCH_TARGET_RESULT_TYPE.ADMIN,
                    targetClass: SEARCH_TARGET_CLASS.PRODUCT_CONFIGURATION,
                    targetId: licenseGroupId,
                    targetParentId: productId,
                  }}
                  titleTextId="common.AddUserFormTable.title.admin"
                />
              </AddUserFormTableWrapper>
            </ModalContent>
          </ModalDialog>
        )}
      </ModalContainer>
    );
  }
);

AddAdminsToLicenseGroupModal.propTypes = {
  isOpen: PropTypes.bool,
  licenseGroupId: PropTypes.string.isRequired,
  /**
   * Optional callback to invoke when the modal's cancel button is pressed.
   */
  onCancel: PropTypes.func,
  onClosed: PropTypes.func, // @deprecated in favor of onCancel
  onSuccess: PropTypes.func,
  orgId: PropTypes.string.isRequired,
  productId: PropTypes.string.isRequired,
};

export default AddAdminsToLicenseGroupModal;
