import {showError as errorToast} from '@admin-tribe/acsc-ui';
import {Text, View, useDialogContainer} from '@adobe/react-spectrum';
import {
  ModalButtonGroup,
  ModalContent,
  ModalDescription,
  ModalDialog,
  ModalHeading,
} from '@pandora/react-modal-dialog';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import StepItem from 'common/components/stepped-view/StepItem';
import SteppedView from 'common/components/stepped-view/SteppedView';
import SteppedViewHeader from 'common/components/stepped-view/SteppedViewHeader';
import useSteppedState from 'common/components/stepped-view/useSteppedState';
import EduApplicationSelection from 'features/settings/common/components/edu-application-selection/EduApplicationSelection';
import EduProviderLogo from 'features/settings/common/components/edu-provider-logo/EduProviderLogo';
import EduRosterSyncSchedule from 'features/settings/common/components/edu-roster-sync-schedule/EduRosterSyncSchedule';
import {useDirectoryContext} from 'features/settings/components/directory/DirectoryContext';
import useEduRosterSync from 'features/settings/hooks/api/useEduRosterSync';
import useRosterSyncSetup from 'features/settings/hooks/roster-sync-setup/useRosterSyncSetup';
import ExternalAuthService from 'features/settings/services/ExternalAuthService';

const MODAL_STEPS = {
  SELECT_APPLICATIONS: {
    id: 'SELECT_APPLICATIONS',
  },
  SELECT_SCHEDULING: {
    id: 'SELECT_SCHEDULING',
  },
};

const MODAL_ID = 'edu-sync-confirmation-modal';

const EduSyncConfirmationModal = ({onConfirm}) => {
  const intl = useIntl();
  const dialog = useDialogContainer();
  const [eduProviderAccessToken, setEduProviderAccessToken] = useState();
  const [rosterTenantInfo, setRosterTenantInfo] = useState();
  const [selectedEduProvider, setSelectedEduProvider] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const isLoginRestored = useRef(false);
  const {directoryStore} = useDirectoryContext();

  const stepManager = useSteppedState({
    initialStepId: MODAL_STEPS.SELECT_APPLICATIONS.id,
    steps: Object.values(MODAL_STEPS),
  });

  const {getAccessToken, getRosterTenants} = useEduRosterSync();
  const {
    setSelectedProducts,
    selectedProducts,
    selectedSchedule,
    setSelectedSchedule,
    isLoading: isCreatingSync,
    createSync,
  } = useRosterSyncSetup();

  const modalTitle =
    stepManager.currentStep.id === MODAL_STEPS.SELECT_APPLICATIONS.id
      ? intl.formatMessage({id: 'settings.sync.eduSyncConfirmationModal.title'})
      : intl.formatMessage({id: 'settings.sync.eduSyncConfirmationModal.schedulingTitle'});

  const ctaLabel =
    stepManager.currentStep.id === MODAL_STEPS.SELECT_APPLICATIONS.id
      ? intl.formatMessage({id: 'settings.sync.eduSyncConfirmationModal.buttons.next'})
      : intl.formatMessage({id: 'settings.sync.eduSyncConfirmationModal.buttons.finishSetup'});

  // Restore EDU login if it's in progress
  useEffect(() => {
    let isMounted = true;

    const fetchData = async ({authCode, syncProvider}) => {
      setIsLoading(true);

      try {
        const {
          data: {access_token: accessToken},
        } = await getAccessToken({
          authCode,
          syncProvider,
        });
        const {data: rosterTenant} = await getRosterTenants({accessToken, syncProvider});

        if (isMounted) {
          setEduProviderAccessToken(accessToken);
          setRosterTenantInfo(rosterTenant);
        }

        if (!rosterTenant?.districtName || !rosterTenant?.districtId) {
          errorToast(
            intl.formatMessage({id: 'settings.sync.syncUsersModal.errors.noDistrictAvailable'})
          );

          // if information is not available there is nothing more to do in this modal, so we should close it
          dialog.dismiss();
        }
      } catch {
        errorToast(
          intl.formatMessage({id: 'settings.sync.syncUsersModal.errors.authenticationFailed'})
        );

        // if authentication failed there is nothing for the user to do in this modal, so we should close it
        dialog.dismiss();
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- TODO: See how you can test this
    // istanbul ignore else
    if (!isLoginRestored.current) {
      const loginInfo = ExternalAuthService.completeAuthentication();

      const syncType = loginInfo.data.syncType;
      setSelectedEduProvider(syncType);

      fetchData({
        authCode: loginInfo.queryData.get('code'),
        syncProvider: syncType,
      });

      isLoginRestored.current = true;
    }

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- this effect only makes sense to run once at mount since it's controlled by a ref value
  }, []);

  const onCta = async () => {
    if (stepManager.currentStep.id === MODAL_STEPS.SELECT_APPLICATIONS.id) {
      stepManager.goNext();
    }

    if (stepManager.currentStep.id === MODAL_STEPS.SELECT_SCHEDULING.id) {
      await createSync({
        accessToken: eduProviderAccessToken,
        directoryId: directoryStore.directoryId,
        districtId: rosterTenantInfo.districtId,
        products: selectedProducts,
        syncProvider: selectedEduProvider,
        syncSchedule: selectedSchedule,
      });

      dialog.dismiss();
      onConfirm();
    }
  };

  return (
    <ModalDialog
      dialogStyle={{
        minHeight: '70vh',
        width: '1100px',
      }}
      id={MODAL_ID}
      isLoading={isLoading || isCreatingSync}
    >
      <ModalHeading>{modalTitle}</ModalHeading>

      <ModalDescription showDivider>
        {stepManager.currentStep.id === MODAL_STEPS.SELECT_APPLICATIONS.id && (
          <Text>
            <FormattedMessage id="settings.sync.eduSyncConfirmationModal.ariaLabels.products" />
          </Text>
        )}
      </ModalDescription>
      <ModalContent>
        <SteppedView
          currentStep={stepManager.currentStep.id}
          steps={Object.values(MODAL_STEPS).map((step) => ({
            id: step.id,
          }))}
        >
          <SteppedViewHeader>
            {selectedEduProvider && <EduProviderLogo rosterSource={selectedEduProvider} />}
          </SteppedViewHeader>
          <StepItem id={MODAL_STEPS.SELECT_APPLICATIONS.id}>
            <View maxWidth="70%">
              <Text>
                <FormattedMessage id="settings.sync.eduSyncConfirmationModal.description" />
              </Text>
            </View>

            {rosterTenantInfo && (
              <View marginTop="size-200">
                <View marginBottom="size-100">
                  <Text marginEnd="size-100">
                    <strong>
                      <FormattedMessage id="settings.sync.eduSyncConfirmationModal.labels.organization" />
                    </strong>
                  </Text>
                  <Text>{rosterTenantInfo.districtName}</Text>
                </View>
                <EduApplicationSelection
                  onChange={setSelectedProducts}
                  products={rosterTenantInfo.integrations}
                />
              </View>
            )}
          </StepItem>
          <StepItem id={MODAL_STEPS.SELECT_SCHEDULING.id}>
            <View maxWidth="70%">
              <Text>
                <FormattedMessage id="settings.sync.eduSyncConfirmationModal.scheduleDescription" />
              </Text>
            </View>
            <View maxWidth="70%">
              <Text>
                <FormattedMessage id="settings.sync.eduSyncConfirmationModal.scheduleNote" />
              </Text>
            </View>
            <EduRosterSyncSchedule
              defaultSelectedKey={selectedSchedule}
              onChange={setSelectedSchedule}
            />
          </StepItem>
        </SteppedView>
      </ModalContent>
      <ModalButtonGroup
        cancelLabel={intl.formatMessage({
          id: 'settings.sync.eduSyncConfirmationModal.buttons.cancel',
        })}
        closeModal={() => dialog.dismiss()}
        ctaLabel={ctaLabel}
        isCtaDisabled={selectedProducts.length === 0}
        onCta={onCta}
      />
    </ModalDialog>
  );
};

EduSyncConfirmationModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
};

export default EduSyncConfirmationModal;

// used in test
export {MODAL_STEPS};
