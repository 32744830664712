import {ActionGroup, Flex, Item, Text} from '@adobe/react-spectrum';
import {ImageIcon} from '@pandora/react-image-icon';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {useIntl} from 'react-intl';

import {usePregeneratedPackagesPageContext} from 'features/packages/pages/pregenerated-packages/PregeneratedPackagesPageContext';
import AcrobatIcon from 'features/packages/svgs/Acrobat.svg';
import AcrobatActiveIcon from 'features/packages/svgs/AcrobatActive.svg';
import AllAppsIcon from 'features/packages/svgs/AllApps.svg';
import AllAppsActiveIcon from 'features/packages/svgs/AllAppsActive.svg';
import GraphicDesignIcon from 'features/packages/svgs/GraphicDesign.svg';
import GraphicDesignActiveIcon from 'features/packages/svgs/GraphicDesignActive.svg';
import IllustrationIcon from 'features/packages/svgs/Illustration.svg';
import IllustrationActiveIcon from 'features/packages/svgs/IllustrationActive.svg';
import PhotoIcon from 'features/packages/svgs/Photo.svg';
import PhotoActiveIcon from 'features/packages/svgs/PhotoActive.svg';
import VideoIcon from 'features/packages/svgs/Video.svg';
import VideoActiveIcon from 'features/packages/svgs/VideoActive.svg';

const componentMap = {
  Acrobat: <ImageIcon alt="AcrobatIcon" size="M" src={AcrobatIcon} />,
  AcrobatActive: <ImageIcon alt="Acrobat" size="M" src={AcrobatActiveIcon} />,
  AllApps: <ImageIcon alt="AllAppsIcon" size="M" src={AllAppsIcon} />,
  AllAppsActive: <ImageIcon alt="AllApps" size="M" src={AllAppsActiveIcon} />,
  GraphicDesign: <ImageIcon alt="GraphicDesignIcon" size="M" src={GraphicDesignIcon} />,
  GraphicDesignActive: <ImageIcon alt="GraphicDesign" size="M" src={GraphicDesignActiveIcon} />,
  Illustration: <ImageIcon alt="IllustrationIcon" size="M" src={IllustrationIcon} />,
  IllustrationActive: <ImageIcon alt="Illustration" size="M" src={IllustrationActiveIcon} />,
  Photo: <ImageIcon alt="PhotoIcon" size="M" src={PhotoIcon} />,
  PhotoActive: <ImageIcon alt="Photo" size="M" src={PhotoActiveIcon} />,
  Video: <ImageIcon alt="VideoIcon" size="M" src={VideoIcon} />,
  VideoActive: <ImageIcon alt="VideoActive" size="M" src={VideoActiveIcon} />,
};

const SingleAppFilters = observer(() => {
  const intl = useIntl();
  const {packagesAdobeTemplatesStore} = usePregeneratedPackagesPageContext();
  return (
    <Flex marginTop="size-160">
      <ActionGroup
        defaultSelectedKeys={['allApps']}
        isQuiet
        onSelectionChange={packagesAdobeTemplatesStore.handleCategoryChange}
        selectedKeys={[packagesAdobeTemplatesStore.selectedCategory]}
        selectionMode="single"
      >
        {packagesAdobeTemplatesStore.singleAppFilters.map((filter) => (
          <Item key={filter.key}>
            {packagesAdobeTemplatesStore.selectedCategory === filter.key
              ? componentMap[`${filter.icon}Active`]
              : componentMap[filter.icon]}
            <Text>{intl.formatMessage({id: filter.key})}</Text>
          </Item>
        ))}
      </ActionGroup>
    </Flex>
  );
});

SingleAppFilters.propTypes = {};

export default SingleAppFilters;
