import {feature} from '@admin-tribe/acsc';
import {ModalDialog, OverlayWait, showError, useStore} from '@admin-tribe/acsc-ui';
import {
  Button,
  ButtonGroup,
  Content,
  Divider,
  Heading,
  useDialogContainer,
} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import React, {useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import StepItem from 'common/components/stepped-view/StepItem';
import SteppedView from 'common/components/stepped-view/SteppedView';
import SelectIdpSection from 'features/settings/components/directories/create-directory-modal/select-idp-section/SelectIdpSection';
import {useDirectoryContext} from 'features/settings/components/directory/DirectoryContext';
import {DirectorySetupContextProvider} from 'features/settings/components/directory/DirectorySetupContext';
import SetupDirectoryAuthentication from 'features/settings/components/setup-directory-authentication/SetupDirectoryAuthentication';
import SetupDirectoryAuthenticationSteps from 'features/settings/components/setup-directory-authentication/SetupDirectoryAuthenticationSteps';
import {IDP_TYPES} from 'features/settings/entities/IdpEntity';
import useDirectorySetup from 'features/settings/hooks/directory-setup/useDirectorySetup';
import DirectorySetupStore, {
  SETUP_AUTHENTICATION_STEPS,
  SETUP_SECTIONS,
} from 'features/settings/stores/DirectorySetupStore';

const MODAL_ID = 'authentication-add-idp-modal';

const AddIdpModal = observer(({refreshData}) => {
  const {directoryStore} = useDirectoryContext();
  const directorySetupStore = useStore(() => new DirectorySetupStore(directoryStore));
  const dialog = useDialogContainer();
  const {
    createIdp,
    ctaButtonLabelId,
    setIdpInfo,
    idpInfo,
    saveIdp,
    saveJitSettings,
    loginToAzure,
    isCtaButtonDisabled,
    isDoingExternalAuth,
    restoreAzureIdpCreation,
    sectionsSteps,
    sectionsStepManager,
    setupAuthenticationStepManager,
    updateProvider,
  } = useDirectorySetup(directorySetupStore, SETUP_SECTIONS.SELECT_IDP.id);
  const intl = useIntl();

  // Continue with azure IdP setup if we're coming back from an azure login
  useEffect(() => {
    const [restoredAzureIdpCreation] = restoreAzureIdpCreation();

    if (restoredAzureIdpCreation) {
      // go to setup authentication UI
      sectionsStepManager.goNext();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps -- this should only run once on mount
  }, []);

  const onCreateIdp = async () => {
    if (idpInfo.type === IDP_TYPES.SAML) {
      const isSuccessful = await createIdp();

      if (isSuccessful) {
        refreshData();
        sectionsStepManager.goNext();
      }
    }

    if (idpInfo.type === IDP_TYPES.AZURE) {
      if (directorySetupStore.directoryStore.hasAzureIdp) {
        showError(
          intl.formatMessage({
            id: 'settings.directories.createDirectoryModal.toasts.idpAlreadyExists',
          })
        );

        return;
      }

      loginToAzure({isAddingAzureIdp: true});
    }

    if (idpInfo.type === IDP_TYPES.SOIDC) {
      sectionsStepManager.goNext();
    }
  };

  const onSaveIdp = async () => {
    const isSuccessful = await saveIdp();

    if (isSuccessful) {
      refreshData();
      setupAuthenticationStepManager.goNext();
    }
  };

  const onSaveJitChanges = async () => {
    if (directorySetupStore.hasJitChanges) {
      const isSuccessful = await saveJitSettings();

      if (isSuccessful) {
        refreshData();
        dialog.dismiss();
      }
    } else {
      dialog.dismiss();
    }
  };

  const onCta = () => {
    if (sectionsStepManager.currentStep.id === SETUP_SECTIONS.SELECT_IDP.id) {
      onCreateIdp();
    }

    if (sectionsStepManager.currentStep.id === SETUP_SECTIONS.SETUP_AUTHENTICATION.id) {
      if (
        setupAuthenticationStepManager.currentStep.id === SETUP_AUTHENTICATION_STEPS.SETUP_IDP.id
      ) {
        onSaveIdp();
      }

      if (
        setupAuthenticationStepManager.currentStep.id === SETUP_AUTHENTICATION_STEPS.SETUP_JIT.id
      ) {
        onSaveJitChanges();
      }
    }
  };

  return (
    <ModalDialog id={MODAL_ID} minHeight="80vh">
      <Heading data-test-id="modal-heading" level={2} marginBottom="size-200">
        <FormattedMessage id="settings.directories.addIdpModal.title" />
      </Heading>
      <Divider />

      <Content data-testid="modal-content">
        <DirectorySetupContextProvider directorySetupStore={directorySetupStore}>
          <OverlayWait
            isLoading={
              directorySetupStore.isLoading || directoryStore.isUpdatingIdp || isDoingExternalAuth
            }
            showContent
          >
            <SteppedView currentStep={sectionsStepManager.currentStep.id} steps={sectionsSteps}>
              <StepItem id={SETUP_SECTIONS.SELECT_IDP.id}>
                <SelectIdpSection
                  hasDefaultIdp={directoryStore.hasDefaultIdp}
                  onSelectIdp={setIdpInfo}
                />
              </StepItem>
              <StepItem id={SETUP_SECTIONS.SETUP_AUTHENTICATION.id}>
                {feature.isEnabled(`temp_saml_certs_wizard`) && (
                  <SetupDirectoryAuthenticationSteps
                    currentStep={setupAuthenticationStepManager.currentStep}
                    isGoogleSelected={idpInfo.isGoogleSelected}
                    isSoidcSelected={idpInfo.isSoidcSelected}
                    isStepListInteractive={false}
                    updateProvider={updateProvider}
                  />
                )}

                {feature.isDisabled('temp_saml_certs_wizard') && (
                  <SetupDirectoryAuthentication
                    currentStep={setupAuthenticationStepManager.currentStep}
                    isGoogleSelected={idpInfo.isGoogleSelected}
                    isSoidcSelected={idpInfo.isSoidcSelected}
                    updateProvider={updateProvider}
                  />
                )}
              </StepItem>
            </SteppedView>
          </OverlayWait>
        </DirectorySetupContextProvider>
      </Content>

      <ButtonGroup>
        <Button data-testid="cancel-button" onPress={() => dialog.dismiss()} variant="primary">
          <FormattedMessage id="settings.directories.addIdpModal.buttons.cancel" />
        </Button>

        <Button
          data-testid="cta-button"
          isDisabled={isCtaButtonDisabled}
          onPress={onCta}
          variant="accent"
        >
          <FormattedMessage id={ctaButtonLabelId} />
        </Button>
      </ButtonGroup>
    </ModalDialog>
  );
});

export default AddIdpModal;
