import {
  CUSTOMER_SEGMENT,
  OFFER_LIST_INTENT,
  OFFER_LIST_SERVICE_PROVIDER,
  OfferList,
  PRODUCT_CODE,
  PoresOfferList,
  feature,
  getDirectOrIndirectContractId,
  getOffersForSegment,
  getStorageOnlyProducts,
  hasOnlyTeamProducts,
} from '@admin-tribe/acsc';

import rootStore from 'core/RootStore';
/**
 * @description Method to fetch id for storage only offer.
 *
 * @returns {Promise} promise - resolved with offer id when storage only
 *  offer can be found, otherwise resolves with undefined.
 */
async function getStorageOnlyOfferId() {
  const productList = rootStore.organizationStore.productList;
  const contractList = rootStore.organizationStore.contractList;
  const orgId = rootStore.organizationStore.activeOrgId;

  // Get the offer id from storage only product if there is one
  const storageOnlyProducts = getStorageOnlyProducts(productList);
  if (storageOnlyProducts.length > 0) {
    return Promise.resolve(storageOnlyProducts[0].offerId);
  }

  try {
    let offerList;
    if (feature.isDisabled('temp_ctir_21074_oms_removal')) {
      offerList = await OfferList.get({
        contractId: getDirectOrIndirectContractId(contractList),
        orgId,
      });
    } else {
      offerList = await PoresOfferList.get({
        contractId: getDirectOrIndirectContractId(contractList),
        orgId,
        queryParams: {
          intent: OFFER_LIST_INTENT.ADD_SEATS_OFFER_DISCOVERY,
          service_providers: [
            OFFER_LIST_SERVICE_PROVIDER.MERCHANDISING,
            OFFER_LIST_SERVICE_PROVIDER.PRODUCT_ARRANGEMENT,
          ],
        },
      });
    }
    const offersForSegment = getOffersForSegment(
      offerList,
      hasOnlyTeamProducts(productList) ? CUSTOMER_SEGMENT.TEAM : CUSTOMER_SEGMENT.ENTERPRISE
    );
    return Promise.resolve(
      offersForSegment.find((offer) => offer.product_code === PRODUCT_CODE.CSAS)?.offer_id
    );
  } catch (error) {
    // Resolve with undefined so that storage overview page can still load properly
    return Promise.resolve(undefined);
  }
}

// eslint-disable-next-line import/prefer-default-export -- util file
export {getStorageOnlyOfferId};
