import {TableSectionTable} from '@admin-tribe/acsc-ui';
import {Text} from '@adobe/react-spectrum';
import {useTableSectionContext} from '@pandora/react-table-section';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';

import AddDomainStatus from 'features/settings/components/domains/add-domains-modal/add-domains-table/AddDomainStatus';
import {useAddDomainsModalContext} from 'features/settings/components/domains/add-domains-modal-context/AddDomainsModalContext';

const AddDomainsTable = ({domains, renderStatus}) => {
  const intl = useIntl();
  const {tableSectionUtils} = useTableSectionContext();
  const {setSelectedDomains} = useAddDomainsModalContext();

  let columnDescriptor = [{key: 'domainName', props: {maxWidth: renderStatus ? '35%' : '100%'}}];
  let renderers = {
    domainName: ({item}) => <Text>{item.domainName}</Text>,
  };

  if (renderStatus) {
    columnDescriptor = [...columnDescriptor, {key: 'status'}];
    renderers = {
      ...renderers,
      status: ({item}) => <AddDomainStatus domain={item} />,
    };
  }

  // Pre-select all of the items in the table
  useEffect(() => {
    const domainsToSelect = domains.map((item) => item.domainName);

    tableSectionUtils.setSelectedItemKeys(domainsToSelect);
    setSelectedDomains(domainsToSelect);

    // eslint-disable-next-line react-hooks/exhaustive-deps -- will run only once during the initial render
  }, []);

  return (
    <TableSectionTable
      aria-label={intl.formatMessage({id: 'settings.domains.addDomainsTable.ariaLabel'})}
      columnDescriptor={columnDescriptor}
      columnNamespace="settings.domains.addDomainsTable"
      renderers={renderers}
    />
  );
};

AddDomainsTable.propTypes = {
  /**
   * The domains for the table in AddDomains Modal
   */
  domains: PropTypes.arrayOf(
    PropTypes.shape({
      domainName: PropTypes.string,
      response: PropTypes.shape({
        errorCode: PropTypes.string,
      }),
      status: PropTypes.string,
    })
  ),
  /**
   * Whether the table should render a status column or not
   */
  renderStatus: PropTypes.bool,
};

AddDomainsTable.displayName = 'AddDomainsTable';

export default AddDomainsTable;
