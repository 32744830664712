import {feature} from '@admin-tribe/acsc';
import {Flex, Link, Text, TextField} from '@adobe/react-spectrum';
import {TooltipButton} from '@pandora/react-tooltip-button';
import Delete from '@spectrum-icons/workflow/Delete';
import {runInAction} from 'mobx';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import {
  ACTIVATION_CODE_VALID_LENGTH,
  MAX_ACTIVATION_CODE_VALID_LENGTH,
  MIN_ACTIVATION_CODE_VALID_LENGTH,
} from 'features/packages/packagesConstants';
import PackagesIngestAnalyticsService from 'features/packages/services/PackagesIngestAnalyticsService';

import {useCreatePackageModalContext} from '../../CreatePackageModalContext';

import ActivationCodeValidator from './ActivationCodeValidator';
import './ActivationCodeInput.pcss';

/**
 * This component is used to take activation codes via text input
 */
const ActivationCodeInput = observer(
  ({
    activationCodeObject,
    addNewActivationCodeBlock,
    removeActivationCodeBlock,
    updateAllCodeList,
  }) => {
    const intl = useIntl();

    const {createPackageModalStore} = useCreatePackageModalContext();

    const [codeInput, setCodeInput] = useState(activationCodeObject.codeInput);

    const [input, setInput] = useState(activationCodeObject.code);

    const isCodeValidCheck = () => {
      let isValid = false;
      createPackageModalStore.challengeCodeList?.forEach((activationCode) => {
        if (activationCode.id === activationCodeObject.id) {
          isValid =
            activationCode.code?.length <= MAX_ACTIVATION_CODE_VALID_LENGTH &&
            !activationCode.isValid;
        }
      });
      return isValid;
    };

    const [isCodeInvalid, setIsCodeInvalid] = useState(isCodeValidCheck());

    const getMaximumId = () =>
      Math.max(
        ...createPackageModalStore.challengeCodeList.map((challengeCode) => challengeCode.id),
        0
      );

    function activationCodesCheck(codeEntered) {
      runInAction(() => {
        // eslint-disable-next-line complexity -- complexity will reduce after feature flags cleanup
        createPackageModalStore.challengeCodeList?.forEach((activationCode) => {
          if (activationCode.id === activationCodeObject.id) {
            const activationCodeObj = {...activationCode};
            activationCode.code = codeEntered?.replace(/[\s-]/g, '') ?? '';
            setInput(activationCode.code);
            activationCode.codeInput = formatString(activationCode.code);

            let validLength = 0;
            if (feature.isEnabled('temp_package_umi_challenge_code')) {
              activationCode.isValid =
                activationCode.code.length === 0 ||
                ActivationCodeValidator.isValidCodeV2(activationCode.code);
              validLength =
                codeEntered.split('@').length === 1
                  ? ACTIVATION_CODE_VALID_LENGTH
                  : MIN_ACTIVATION_CODE_VALID_LENGTH;
            } else {
              activationCode.code = ActivationCodeValidator.normalizeCode(activationCode.code);
              activationCode.isValid =
                activationCode.code.length === 0 ||
                ActivationCodeValidator.isValidCode(activationCode.code);
              validLength = ACTIVATION_CODE_VALID_LENGTH;
            }
            setCodeInput(activationCode.codeInput);

            const requiresUpdation =
              activationCode.codeInput.length === 0 ||
              activationCode.codeInput.length === 1 ||
              activationCode.codeInput.length >= validLength;

            setIsCodeInvalid(
              activationCode.codeInput.length < validLength || !activationCode.isValid
            );

            if (activationCodeObj.isValid) {
              PackagesIngestAnalyticsService.triggerAnalytics({
                subCategory: 'FRL:Offline:Codes',
                subType: 'activation-code',
                type: 'click',
                value: 'input',
              });
            }
            if (requiresUpdation) {
              updateAllCodeList();
            }
          }
        });
      });
    }

    function formatString(ActivationCodeinput) {
      const patterns = [
        /^(.{6})(.{6})(.{6})@(.{7})(.{6})(.{6})@(.{7})(.{6})(.{1,6})$/,
        /^(.{6})(.{6})(.{6})@(.{7})(.{6})(.{6})@(.{7})(.{6})(.{6})@(.{7})(.{6})(.{1,6})$/,
        /^(.{6})(.{6})(.{6})@(.{7})(.{6})(.{6})@(.{7})(.{6})(.{6})@(.{7})(.{6})(.{6})@(.{7})(.{6})(.{1,6})$/,
        /^(.{6})(.{6})(.{6})@(.{7})(.{6})(.{6})@(.{7})(.{6})(.{6})@(.{7})(.{6})(.{6})@(.{7})(.{6})(.{6})@(.{7})(.{6})(.{1,6})$/,
        /^(.{6})(.{6})(.{6})@(.{7})(.{6})(.{6})@(.{7})(.{6})(.{6})@(.{7})(.{6})(.{6})@(.{7})(.{6})(.{6})@(.{7})(.{6})(.{6})@(.{7})(.{6})(.{1,6})$/,
      ];

      const replacements = [
        '$1-$2-$3@$4-$5-$6@$7-$8-$9',
        '$1-$2-$3@$4-$5-$6@$7-$8-$9@$10-$11-$12',
        '$1-$2-$3@$4-$5-$6@$7-$8-$9@$10-$11-$12@$13-$14-$15',
        '$1-$2-$3@$4-$5-$6@$7-$8-$9@$10-$11-$12@$13-$14-$15@$16-$17-$18',
        '$1-$2-$3@$4-$5-$6@$7-$8-$9@$10-$11-$12@$13-$14-$15@$16-$17-$18@$19-$20-$21',
      ];

      const ar = ActivationCodeinput.split('@');
      if (ar.length < 3) return ActivationCodeinput?.match(/.{1,6}/g)?.join('-') ?? '';
      return ActivationCodeinput.replace(patterns[ar.length - 3], replacements[ar.length - 3]);
    }

    function getValidationState() {
      const validLength =
        codeInput.split('@').length === 1
          ? ACTIVATION_CODE_VALID_LENGTH + 2
          : MIN_ACTIVATION_CODE_VALID_LENGTH;
      if (codeInput.length < validLength) return '';
      return isCodeInvalid ? 'invalid' : 'valid';
    }

    function getLeftInputBits() {
      const ar = codeInput.split('@');
      if (ar.length === 1) return ACTIVATION_CODE_VALID_LENGTH - input.length;
      let requiredCodeLength = MIN_ACTIVATION_CODE_VALID_LENGTH;
      switch (ar.length) {
        case 4:
          requiredCodeLength = MIN_ACTIVATION_CODE_VALID_LENGTH + 22;
          break;
        case 5:
          requiredCodeLength = MIN_ACTIVATION_CODE_VALID_LENGTH + 44;
          break;
        case 6:
          requiredCodeLength = MIN_ACTIVATION_CODE_VALID_LENGTH + 66;
          break;
        case 7:
          requiredCodeLength = MAX_ACTIVATION_CODE_VALID_LENGTH;
          break;
        default:
          requiredCodeLength = MIN_ACTIVATION_CODE_VALID_LENGTH;
      }
      return requiredCodeLength - codeInput.length;
    }

    const getTextFieldLabel = () => (
      <Flex justifyContent="space-between" width="size-5000">
        <Text>
          {intl.formatMessage({
            id: 'packages.createPackage.challengeCodes.activationCode',
          })}
        </Text>
        {feature.isDisabled('temp_package_umi_hide_length_text') && (
          <Text>
            {feature.isEnabled('temp_package_umi_challenge_code') ? (
              <span aria-hidden="true">{getLeftInputBits()}</span>
            ) : (
              <span aria-hidden="true">{ACTIVATION_CODE_VALID_LENGTH - input.length}</span>
            )}
          </Text>
        )}
      </Flex>
    );

    return (
      <>
        <Flex marginTop="size-125">
          <Flex direction="column" width="size-5000">
            <div styleName="activation-code-input">
              <TextField
                data-testid="activation-code-input"
                errorMessage={intl.formatMessage({
                  id: 'packages.createPackage.challengeCodes.invalidCode',
                })}
                label={getTextFieldLabel()}
                maxLength={
                  feature.isDisabled('temp_package_umi_challenge_code')
                    ? ACTIVATION_CODE_VALID_LENGTH + 2
                    : MAX_ACTIVATION_CODE_VALID_LENGTH + 2
                }
                onChange={activationCodesCheck}
                validationState={getValidationState()}
                value={codeInput}
                width="100%"
              />
            </div>
          </Flex>
          {activationCodeObject.id !== getMaximumId() && (
            <TooltipButton
              aria-label={intl.formatMessage({
                id: 'packages.createPackage.challengeCodes.removeActivatioCode',
              })}
              isQuiet
              marginBottom="auto"
              marginStart="size-125"
              marginTop="size-300"
              onPress={removeActivationCodeBlock}
              variant="action"
            >
              <Delete />
            </TooltipButton>
          )}
        </Flex>
        {activationCodeObject.id === getMaximumId() && (
          <Link
            data-testid="add-another-input"
            isQuiet
            marginTop="size-125"
            onPress={addNewActivationCodeBlock}
            UNSAFE_style={{width: 'fit-content'}}
          >
            {intl.formatMessage({
              id: 'packages.createPackage.activationCodes.addAnotherCode',
            })}
          </Link>
        )}
      </>
    );
  }
);

ActivationCodeInput.propTypes = {
  /**
   * activation code object
   */
  activationCodeObject: PropTypes.shape({
    code: PropTypes.string,
    codeInput: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
  /**
   * Function invoked to add new activation code block
   */
  addNewActivationCodeBlock: PropTypes.func.isRequired,
  /**
   * Function invoked to remove an activation code block
   */
  removeActivationCodeBlock: PropTypes.func.isRequired,
  /**
   *  Function invoked to update the allCode list
   */
  updateAllCodeList: PropTypes.func.isRequired,
};

export default ActivationCodeInput;
