import {OrganizationUser} from '@admin-tribe/acsc';
import {Avatar, getMemberDisplayName} from '@admin-tribe/acsc-ui';
import {
  Cell,
  Column,
  Flex,
  Row,
  TableBody,
  TableHeader,
  TableView,
  Text,
} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import {compareFunction, generateUserId} from '../../SelfCancelUtils';

import './SelectLicensesTable.pcss';

const INITIAL_SORT_DESCRIPTOR = {
  column: 'displayName',
  direction: 'ascending',
};

const NO_DATA_DEFAULT_HEIGHT = 100;

/**
 * Table of assigned and unassigned licenses for a particular product.
 * The rows in the Table will be selecteable and sortable.
 * The selection is controlled by the consumer.
 * Assigned licenses will be represented by the assigned user information and will be provided by
 * the consumer.
 * Unassigned licenses will be represented as generic information created by this component and the
 * consumer will only pass the number of unassigned licenses.
 */
const SelectLicensesTable = ({
  assignedUsers = [],
  isLoading,
  onAllLicensesSelectedChange,
  onSelectedLicensesChange,
  selectedLicenses = [],
  unassignedLicensesCount = 0,
}) => {
  const intl = useIntl();
  const [itemsToRender, setItemsToRender] = useState([]);
  const [sortDescriptor, setSortDescriptor] = useState(INITIAL_SORT_DESCRIPTOR);

  // When controlled assignedUsers or unassignedLicensesCount are changed calculate and sort
  // initially the items to render. The items to render will be a combination of the assigned seats
  // and the virtual unassigned seats.
  useEffect(() => {
    const unassignedRows = [...new Array(unassignedLicensesCount).keys()].map((index) => ({
      id: generateUserId(intl, {index}),
      isUnassigned: true, // used internally for sorting logic
    }));

    const assignedRows = assignedUsers.map((user) => ({
      displayName: getMemberDisplayName(intl, user),
      email: user.email,
      id: generateUserId(intl, {user}),
      member: user,
    }));

    sortAndSetItemsToRender([...unassignedRows, ...assignedRows], INITIAL_SORT_DESCRIPTOR);
  }, [assignedUsers, intl, isLoading, unassignedLicensesCount]);

  function handleSort(descriptor) {
    sortAndSetItemsToRender(itemsToRender, descriptor);
  }

  function sortAndSetItemsToRender(rowsToSort, descriptor) {
    // Rows are sorted in place so clone the rows so React will detect that they changed.
    const newRows = [...rowsToSort].sort((a, b) => compareFunction(a, b, descriptor));
    setItemsToRender(newRows);
    setSortDescriptor(descriptor);
  }

  function handleSelectionChange(selection) {
    // When all licenses are selected via Table header Checkbox, selection is `all`,
    const selectedKeys = selection === 'all' ? itemsToRender.map(({id}) => id) : [...selection];
    onSelectedLicensesChange(selectedKeys);

    // If all licenses are selected via each row Checkbox, selection is not `all`, so we need to
    // manually check if all licenses are selected.
    const allLicensesSelected = selection === 'all' || itemsToRender.length === selectedKeys.length;
    onAllLicensesSelectedChange(allLicensesSelected);
  }

  return (
    <TableView
      aria-label={intl.formatMessage({
        id: 'account.selfCancel.components.SelectLicensesTable.ariaLabel',
      })}
      data-testid="select-licenses-table"
      height={itemsToRender.length > 0 ? undefined : NO_DATA_DEFAULT_HEIGHT}
      onSelectionChange={handleSelectionChange}
      onSortChange={handleSort}
      overflowMode="wrap"
      renderEmptyState={() => (
        <Text>
          {intl.formatMessage({
            id: 'account.selfCancel.components.SelectLicensesTable.emptyTable',
          })}
        </Text>
      )}
      selectedKeys={selectedLicenses}
      selectionMode="multiple"
      sortDescriptor={sortDescriptor}
    >
      <TableHeader>
        <Column key="displayName" allowsSorting>
          {intl.formatMessage({
            id: 'account.selfCancel.components.SelectLicensesTable.name',
          })}
        </Column>
        <Column key="email">
          {intl.formatMessage({
            id: 'account.selfCancel.components.SelectLicensesTable.email',
          })}
        </Column>
      </TableHeader>
      <TableBody loadingState={isLoading ? 'loading' : 'idle'}>
        {itemsToRender.map((user) => (
          <Row key={user.id}>
            <Cell>
              <Flex alignItems="center" gap="size-200">
                <Avatar member={user.member} />
                {user.isUnassigned ? (
                  <span styleName="unassigned-text">
                    {intl.formatMessage({
                      id: 'account.selfCancel.components.SelectLicensesTable.unassigned',
                    })}
                  </span>
                ) : (
                  user.displayName
                )}
              </Flex>
            </Cell>
            <Cell>{user.email || <>&ndash;</>}</Cell>
          </Row>
        ))}
      </TableBody>
    </TableView>
  );
};

SelectLicensesTable.propTypes = {
  /**
   * The list of users assigned to the product.
   */
  assignedUsers: PropTypes.arrayOf(PropTypes.instanceOf(OrganizationUser)),
  /**
   * Whether the Table component should display the loading indicator or not
   */
  isLoading: PropTypes.bool,
  /**
   * Handler that is called when the number of selected licenses changes in order
   * to determine if all licenses are selected.
   */
  onAllLicensesSelectedChange: PropTypes.func,
  /**
   * Handler that is called when the selected licenses changes.
   */
  onSelectedLicensesChange: PropTypes.func,
  /**
   * The array of currently selected licenses ids.
   */
  selectedLicenses: PropTypes.arrayOf(PropTypes.string),
  /**
   * The number if assigned seats  to the product.
   */
  unassignedLicensesCount: PropTypes.number,
};

export default SelectLicensesTable;
