import {LinkedUserAccount, ORGANIZATION_TYPE, Organization} from '@admin-tribe/acsc';
import {OrganizationSwitcher} from '@admin-tribe/acsc-ui';
import {
  Button,
  ButtonGroup,
  Content,
  Grid,
  Heading,
  IllustratedMessage,
  Provider as SpectrumV3Provider,
  View,
  defaultTheme,
} from '@adobe/react-spectrum';
import SpectrumV2Provider from '@react/react-spectrum/Provider';
import CloudIcon from '@spectrum-icons/workflow/Cloud';
import SettingIcon from '@spectrum-icons/workflow/Settings';
import PropTypes from 'prop-types';
import React from 'react';

const TYPE_KIT_ID = 'ubl8raj';
/**
 * ErrorBumper component displays an error message with a button.
 *
 * @param {Object} props - The properties that define the ErrorBumper component.
 * @param {string} props.titleText - The title of the error message.
 * @param {string} props.buttonLabelText - The label of the button.
 * @param {string} props.detailsText - The details of the error message.
 * @param {Function} props.onButtonClick - The function to execute when the button is clicked.
 * @param {string} props.iconType - The type of the icon to display.
 * @param {Object} props.orgSwitcherProps - The properties for the organization switcher.
 * @param {string} props.secondaryLabel - The label for the secondary action.
 * @param {Function} props.onSecondaryButtonClick - The function to execute when the secondary button is clicked.
 * @returns {JSX.Element} The ErrorBumper component.
 */
const ErrorBumper = ({
  titleText,
  buttonLabelText,
  detailsText,
  onButtonClick,
  iconType,
  orgSwitcherProps,
  secondaryLabel,
  onSecondaryButtonClick,
}) => (
  <SpectrumV2Provider
    locale="en"
    scale="medium"
    theme="light"
    typekitId={TYPE_KIT_ID}
    UNSAFE_style={{display: 'flex', flexDirection: 'column', overflow: 'hidden'}}
  >
    <SpectrumV3Provider
      colorScheme="light"
      locale="en"
      theme={defaultTheme}
      typekitId={TYPE_KIT_ID}
      UNSAFE_style={{display: 'flex', flexDirection: 'column', overflow: 'hidden'}}
    >
      <Grid
        alignContent="center"
        alignItems="center"
        areas={['icon', 'content']}
        gap="size-150"
        height="calc(100vh)"
        justifyContent="center"
        justifyItems="center"
      >
        {iconType &&
          (iconType === 'Settings' ? (
            <SettingIcon data-testid="settings-icon" size="XXL" />
          ) : (
            <CloudIcon data-testid="cloud-icon" size="XXL" />
          ))}
        <IllustratedMessage gridArea="content">
          <Heading level={2}>{titleText}</Heading>
          <Content>{detailsText}</Content>
          {orgSwitcherProps && (
            <View marginTop="size-200">
              <OrganizationSwitcher {...orgSwitcherProps} />
            </View>
          )}
          {onButtonClick && (
            <ButtonGroup marginTop="size-400">
              <Button onPress={onButtonClick} variant="accent">
                {buttonLabelText}
              </Button>
              {secondaryLabel && onSecondaryButtonClick && (
                <Button onPress={onSecondaryButtonClick} variant="secondary">
                  {secondaryLabel}
                </Button>
              )}
            </ButtonGroup>
          )}
        </IllustratedMessage>
      </Grid>
    </SpectrumV3Provider>
  </SpectrumV2Provider>
);

ErrorBumper.propTypes = {
  buttonLabelText: PropTypes.string,
  detailsText: PropTypes.string.isRequired,
  iconType: PropTypes.string,
  onButtonClick: PropTypes.func,
  onSecondaryButtonClick: PropTypes.func,
  orgSwitcherProps: PropTypes.shape({
    activeOrgId: PropTypes.string,
    authenticatedUserId: PropTypes.string,
    filterToTypes: PropTypes.arrayOf(PropTypes.oneOf(Object.values(ORGANIZATION_TYPE))),
    linkedUserAccounts: PropTypes.arrayOf(PropTypes.instanceOf(LinkedUserAccount)),
    onChange: PropTypes.func.isRequired,
    organizationItems: PropTypes.arrayOf(PropTypes.instanceOf(Organization)),
    showBorder: PropTypes.bool,
    width: PropTypes.string,
  }),
  secondaryLabel: PropTypes.string,
  titleText: PropTypes.string.isRequired,
};

export default ErrorBumper;
