import {
  DirectoryUser,
  MEMBER_TYPE,
  ORGANIZATION_MARKET_SEGMENT,
  ORGANIZATION_USER_API_QUERY_PARAMS,
  OrganizationUser,
  PageContext,
  UserGroup,
  feature,
  navBus,
} from '@admin-tribe/acsc';
import {
  Avatar,
  getMemberDisplayName,
  getMemberType,
  getMemberTypeLabel,
} from '@admin-tribe/acsc-ui';
import {
  ActionButton,
  Button,
  ButtonGroup,
  Flex,
  Grid,
  Heading,
  ProgressCircle,
  Text,
  Tooltip,
  TooltipTrigger,
} from '@adobe/react-spectrum';
import {useAsyncModel} from '@pandora/react-async-model';
import {EN_DASH} from '@pandora/react-table-section';
import {useId} from '@react-aria/utils';
import InfoOutline from '@spectrum-icons/workflow/InfoOutline';
import PropTypes from 'prop-types';
import React, {useCallback, useMemo} from 'react';
import {useIntl} from 'react-intl';

import AdobeioConsoleLink from 'common/components/user-details-drawer-header/integrations/AdobeioConsoleLink';
import {getEduTagHelp, getEduTags} from 'common/utils/edu-tags/eduTagsUtils';
import {getMemberDetailsHref} from 'common/utils/member-utils/memberUtils';
import rootStore from 'core/RootStore';
import {isOrgAdmin} from 'core/organizations/access/organizationAccess';
import {transformMember} from 'features/users/services/member-transformer/memberTransformerUtils';

// Styling for labels.
const labelStyle = {
  color: 'var(--color-grey-700)',
};

// eslint-disable-next-line complexity -- Need to fetch education role tags
const UserDetailsDrawerHeader = ({member, pageContext}) => {
  const intl = useIntl();
  const headerId = useId();
  const orgId = rootStore.organizationStore.activeOrgId;
  const marketSegment = rootStore.organizationStore.activeOrg.marketSegment;

  // We check the member type here instead of pageContext because the user group drawer can be displayed
  // in the context of Product users (/products/:productId/users) in addition to the usual User groups page
  const isUser = feature.isEnabled('temp_user_member_type')
    ? member.getType().isUser()
    : pageContext.isUserTargetType();

  // Whether to show member details in the header or not.
  const showDetails =
    pageContext.isAdminTargetType() || (isUser && !pageContext.isDirectoryTarget());

  const fetchOrganizationUser = useCallback(
    async () => {
      if (!showDetails) {
        return {
          isLoading: false,
          model: undefined,
        };
      }

      const include = [
        ORGANIZATION_USER_API_QUERY_PARAMS.PRODUCTS,
        ORGANIZATION_USER_API_QUERY_PARAMS.USER_EDU_ROLE_TAGS,
        ORGANIZATION_USER_API_QUERY_PARAMS.USER_GROUP_PRODUCTS,
      ];

      if (feature.isEnabled('temp_de_exclusion_40038')) {
        include.push(ORGANIZATION_USER_API_QUERY_PARAMS.DOMAIN_ENFORCEMENT_EXCLUSION_INDICATOR);
      }
      const response = await OrganizationUser.get({
        include,
        orgId,
        userId: member.id,
      });

      return transformMember({intl, member: response});
    }, // eslint-disable-next-line react-hooks/exhaustive-deps -- no dependencies needed
    []
  );

  const {model: resolvedMember, isLoading} = useAsyncModel({loadFn: fetchOrganizationUser});

  const memberType = getMemberType(member);

  const memberTypeLabel = getMemberTypeLabel(intl, memberType);

  const displayName = useMemo(
    () =>
      getMemberDisplayName(intl, member, {
        fallbackToEmail: false,
      }),
    [intl, member]
  );

  const descriptionText = useMemo(() => {
    if (member.type === MEMBER_TYPE.USER_GROUP && member.isTarget) {
      const sourceOrgName = member?.sharedUserGroupSource?.sourceOrg?.name;
      if (sourceOrgName) {
        return intl.formatMessage(
          {id: 'common.userDetailsHeader.sharedGroupTarget.namedOrg.text'},
          {orgName: sourceOrgName}
        );
      }
      return intl.formatMessage({id: 'common.userDetailsHeader.sharedGroupTarget.unnamedOrg.text'});
    }
    return undefined;
  }, [intl, member]);

  const detailsHref = getMemberDetailsHref({member, pageContext});
  const detailsFields = [
    {key: 'idType', value: memberTypeLabel},
    {key: 'email', value: member.email},
  ];

  const shouldRenderInfoButton = useCallback(
    (key) => key === 'idType' && member.domainEnforcementException === true && isOrgAdmin(),
    [member]
  );

  if (member.userName && member.type !== MEMBER_TYPE.TYPE1)
    detailsFields.push({key: 'username', value: member.userName});

  if (marketSegment === ORGANIZATION_MARKET_SEGMENT.EDUCATION) {
    detailsFields.push({
      key: 'eduTags',
      value: getEduTags(resolvedMember?.tags, intl),
    });
  }

  return (
    <>
      <Flex
        alignItems="top"
        marginBottom={showDetails ? 'size-0' : 'size-200'}
        UNSAFE_style={{overflowWrap: 'anywhere'}}
      >
        <Avatar marginEnd="size-150" member={member} size="S" />
        <div>
          <Heading id={headerId} level={2} marginBottom="size-0" marginTop="size-0">
            {displayName}
          </Heading>
          {descriptionText && <Text data-testid="header-description">{descriptionText}</Text>}
        </div>
      </Flex>
      {showDetails && (
        <Grid
          columnGap="size-125"
          columns={['size-1200', 'size-2000']}
          data-testid="header-details"
          marginBottom="size-200"
          marginStart="size-675"
          rows={['auto']}
        >
          {detailsFields.map(({key, value}) => (
            <React.Fragment key={key}>
              {feature.isEnabled('temp_de_exclusion_40038') ? (
                <Text
                  alignSelf={key === 'idType' ? 'center' : ''}
                  data-testid={`${key}-key`}
                  UNSAFE_style={labelStyle}
                >
                  {intl.formatMessage({
                    id: `common.userDetailsSection.drawer.${key}.label`,
                  })}
                  {key === 'eduTags' && getEduTagHelp(intl)}
                </Text>
              ) : (
                <Text data-testid={`${key}-key`} UNSAFE_style={labelStyle}>
                  {intl.formatMessage({
                    id: `common.userDetailsSection.drawer.${key}.label`,
                  })}
                  {key === 'eduTags' && getEduTagHelp(intl)}
                </Text>
              )}

              {isLoading && key === 'eduTags' ? (
                <ProgressCircle
                  aria-label={intl.formatMessage({
                    id: 'common.eduTags.loading',
                  })}
                  isIndeterminate
                  size="S"
                />
              ) : (
                <Text
                  data-testid={`${key}-value`}
                  UNSAFE_style={{
                    overflowWrap: 'anywhere',
                  }}
                >
                  <Flex
                    alignItems="center"
                    data-testid={`member-type-label-container-${key}`}
                    direction="row"
                  >
                    <Text data-testid="member-type-label">{value ?? EN_DASH}</Text>
                    {feature.isEnabled('temp_de_exclusion_40038') &&
                      shouldRenderInfoButton(key) && (
                        <Flex>
                          <TooltipTrigger placement="top">
                            <ActionButton
                              data-testid="tooltip-button-action"
                              isQuiet
                              justifySelf="center"
                              variant="action"
                            >
                              <InfoOutline size="S" />
                            </ActionButton>
                            <Tooltip data-testid="tooltip-button-tooltip" isOpen>
                              {intl.formatMessage({
                                id: 'common.userDetailsSection.identityType.idTooltipContent',
                              })}
                            </Tooltip>
                          </TooltipTrigger>
                        </Flex>
                      )}
                  </Flex>
                </Text>
              )}
            </React.Fragment>
          ))}
        </Grid>
      )}
      {/* add a bit of marginX so button focus ring isn't clipped */}
      <ButtonGroup marginBottom="size-200" marginX="size-50">
        {detailsHref && (
          <Button
            data-testid="view-profile-button"
            elementType="a"
            onClick={() => {
              navBus.navigate(detailsHref);
            }}
            variant="secondary"
          >
            {intl.formatMessage({
              id: 'common.userDetailsHeader.button.viewProfile',
            })}
          </Button>
        )}
        {pageContext.isIntegrationTargetType() && <AdobeioConsoleLink member={member} />}
      </ButtonGroup>
    </>
  );
};

UserDetailsDrawerHeader.propTypes = {
  /**
   * The user / administrator / directory user.
   * When the drawer is used in one of the tables it is created without a member property
   * and then it is cloned and the member property is added for each row item.
   */
  member: PropTypes.oneOfType([
    // Note: this is PropTypes.object is needed in testcases to create OrganizationUser.
    // Cannot create instance of OrganizationUser since mocking both a
    // static method (get) and constructor on one binky class was problematic.
    PropTypes.object,
    PropTypes.instanceOf(DirectoryUser),
    PropTypes.instanceOf(OrganizationUser),
    PropTypes.instanceOf(UserGroup),
  ]),
  /**
   * The PageContext object used to dispatch analytics for the add and remove operations.
   */
  pageContext: PropTypes.instanceOf(PageContext).isRequired,
};

export default UserDetailsDrawerHeader;
