import {GoUrl, OverlayWait} from '@admin-tribe/acsc-ui';
import {ButtonGroup, Provider as ProviderV3, Well} from '@adobe/react-spectrum';
import ProviderV2 from '@react/react-spectrum/Provider';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import jilDnsToken from 'common/api/jil/jilDnsToken';
import CopyToClipboardButton from 'common/components/copy-to-clipboard-button/CopyToClipboardButton';
import rootStore from 'core/RootStore';
import {
  DNS_RECORD,
  KEY,
} from 'features/settings/common/components/access-dns-token/accessDnsTokenConstants';

const AccessDnsToken = ({isValidatingDomains = false, onError, onLoadingStateChange}) => {
  const intl = useIntl();

  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState();

  // fetch dns token from jil api. service handles caching
  useEffect(() => {
    const fetchToken = async () => {
      setIsLoading(true);
      onLoadingStateChange?.(true);

      try {
        const response = await jilDnsToken.getToken(rootStore.organizationStore.activeOrgId);

        setToken(response.data.token);
      } catch {
        onError();
      } finally {
        setIsLoading(false);
        onLoadingStateChange?.(false);
      }
    };

    fetchToken();

    // eslint-disable-next-line react-hooks/exhaustive-deps -- we only want this to run once
  }, []);

  return (
    <OverlayWait isLoading={isLoading} showContent>
      <FormattedMessage
        id={
          isValidatingDomains
            ? 'settings.accessDnsToken.description.validatingDomains'
            : 'settings.accessDnsToken.description.notValidatingDomains'
        }
        values={{
          goUrl: (url) => <GoUrl name="aac_identity_learn_more">{url}</GoUrl>,
        }}
      />

      {token && (
        <>
          <Well
            marginBottom="size-125"
            UNSAFE_style={{wordBreak: 'break-all'}} // need long tokens to wrap around to next line
          >
            {`${DNS_RECORD}${token}`}
          </Well>

          <ProviderV2>
            <ProviderV3>
              <ButtonGroup>
                <CopyToClipboardButton
                  aria-label={intl.formatMessage({
                    id: 'settings.accessDnsToken.copyRecordValueAriaLabel',
                  })}
                  data-testid="copy-record-value-btn"
                  value={`${KEY}=${token}`}
                >
                  <FormattedMessage id="settings.accessDnsToken.copyRecordValue" />
                </CopyToClipboardButton>

                <CopyToClipboardButton
                  aria-label={intl.formatMessage({
                    id: 'settings.accessDnsToken.copyEntireDnsRecordAriaLabel',
                  })}
                  data-testid="copy-dns-record-btn"
                  value={`${DNS_RECORD}${token}`}
                >
                  <FormattedMessage id="settings.accessDnsToken.copyEntireDnsRecord" />
                </CopyToClipboardButton>
              </ButtonGroup>
            </ProviderV3>
          </ProviderV2>
        </>
      )}
    </OverlayWait>
  );
};

AccessDnsToken.propTypes = {
  /**
   * Whether the component is open as part of the workflow to validate domains. Used to decide what description to display.
   * Defaults to false.
   */
  isValidatingDomains: PropTypes.bool,
  /**
   * Function called when fetching the DNS token fails.
   */
  onError: PropTypes.func.isRequired,
  /**
   * Function called when loading starts or ends, with the new value of the isLoading state (true or false).
   */
  onLoadingStateChange: PropTypes.func,
};
export default AccessDnsToken;
