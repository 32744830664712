import {Locale, log} from '@admin-tribe/acsc';

import rootStore from 'core/RootStore';
import auth from 'core/services/auth';
import {getIngestEnvironment, sendAnalytics} from 'features/packages/api/packagesIngest';
import {
  CREATE_PACKAGE_CONSTANTS,
  PACKAGE_ANALYTICS_CONSTANTS,
} from 'features/packages/packagesConstants';

/**
 * @description Method to get OS version of machine browser is running on
 *
 * @returns {String} OS version string
 */

/* eslint-disable @admin-tribe/admin-tribe/check-browser-globals -- @gun to fix*/
function getOsVersion() {
  // Firefox has OS data available in navigator.oscpu
  if (window.navigator?.oscpu) {
    return window.navigator.oscpu;
  }
  // If not Firefox, extract OS data from navigator.userAgent
  return window.navigator.userAgent.slice(
    window.navigator.userAgent.indexOf('(') + 1,
    window.navigator.userAgent.indexOf(')')
  );
}
/* eslint-enable @admin-tribe/admin-tribe/check-browser-globals -- @gun to fix*/

class PackagesIngestAnalyticsService {
  //////////////////////////////////////////
  /**
   * @description get the sub category for ingest analytics
   * @param {Object} workflow the type of workflow
   * @returns {Boolean} if event should be sent
   */
  static shouldSendIngestAnalytics(workflow) {
    return (
      workflow === CREATE_PACKAGE_CONSTANTS.NUELL_PACKAGE_MODE ||
      workflow === CREATE_PACKAGE_CONSTANTS.FRL_PACKAGE_TYPE
    );
  }

  /**
   * @description get the sub category for ingest analytics
   * @param {Object} workflow the type of workflow
   * @param {String} type the type of the event
   * @returns {String} subcategory of the event
   */
  static getIngestSubcategory(workflow, type) {
    let subCategory = '';
    switch (workflow) {
      case CREATE_PACKAGE_CONSTANTS.NUELL_PACKAGE_MODE:
        subCategory = `SDL:${type}`;
        break;
      case CREATE_PACKAGE_CONSTANTS.FRL_ISOLATED_PACKAGE:
        subCategory = `FRL:Offline:${type}`;
        break;
      case CREATE_PACKAGE_CONSTANTS.FRL_LAN_PACKAGE:
        subCategory = `FRL:LAN:${type}`;
        break;
      case CREATE_PACKAGE_CONSTANTS.FRL_OFFLINE_PACKAGE:
        subCategory = `FRL:NewOffline:${type}`;
        break;
      case CREATE_PACKAGE_CONSTANTS.FRL_ONLINE_PACKAGE:
        subCategory = `FRL:Online:${type}`;
        break;
      default:
        break;
    }
    return subCategory;
  }

  /**
   * @description Method to trigger analytics for an event
   *
   * @param {Object} params Parameters for the event
   * @param {String} params.subCategory Sub-category of the analytics event
   * @param {String} params.type Type of the analytics event
   * @param {String} params.subType Sub-type of the analytics event
   * @param {String} params.value Value for the event
   * @param {String} params.content [Optional] additional content for the analytics event
   * @param {String} params.pagename pagename for the analytics event
   */
  static async triggerAnalytics(params) {
    try {
      const analyticsObject = {
        events: [
          {
            data: {
              'content.name': params?.content ?? '',
              'event.category': PACKAGE_ANALYTICS_CONSTANTS.CATEGORY,
              'event.dts_start': new Date().toISOString(),
              'event.language': Locale.get().activeLocaleCode,
              'event.org_guid': rootStore.organizationStore.activeOrgId,
              'event.pagename': params?.pagename ?? '',
              // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- gun@ to update
              'event.referrer': window.document.referrer,
              'event.subcategory': params?.subCategory ?? '',
              'event.subtype': params?.subType ?? '',
              'event.type': params?.type ?? '',
              // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- gun@ to update
              'event.url': window.location.href,
              // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- gun@ to update
              'event.user_agent': window.navigator.userAgent,
              'event.user_guid': await auth.getUserId(),
              'event.value': params?.value ?? '',
              'event.workflow': PACKAGE_ANALYTICS_CONSTANTS.WORKFLOW,
              'source.client_id': PACKAGE_ANALYTICS_CONSTANTS.CLIENT_ID,
              'source.name': PACKAGE_ANALYTICS_CONSTANTS.SOURCE_NAME,
              'source.os_version': getOsVersion(),
              'source.platform': PACKAGE_ANALYTICS_CONSTANTS.PLATFORM,
              'source.version': PACKAGE_ANALYTICS_CONSTANTS.SOURCE_VERSION,
            },
            environment: getIngestEnvironment(),
            ingesttype: PACKAGE_ANALYTICS_CONSTANTS.INGEST_TYPE,
            project: PACKAGE_ANALYTICS_CONSTANTS.PROJECT_NAME,
            time: new Date().toISOString(),
          },
        ],
      };
      await sendAnalytics(analyticsObject);
    } catch (error) {
      log.error('PackagesIngestAnalytics.triggerAnalytics(): Unable to send. Error: ', error);
    }
  }
}

export default PackagesIngestAnalyticsService;
export {getOsVersion, log};
