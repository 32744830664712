import {feature} from '@admin-tribe/acsc';
import {DATE_FORMATS, FormattedDateAndTime} from '@admin-tribe/acsc-ui';
import {Flex, View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedNumber, useIntl} from 'react-intl';

import {SUPPORT_CONSTANTS} from 'features/support/support.constants';

import styles from '../InfoCard.pcss';

const {INFO_CARD_TYPE} = SUPPORT_CONSTANTS;

/**
 * Widget to display an info card which consists of a localized label and a formatted value based on type
 */
const InfoCard = ({
  'data-testid': dataTestId,
  label,
  noDataTextKey,
  value,
  type = INFO_CARD_TYPE.STRING,
  children,
}) => {
  const intl = useIntl();
  const cardLabel = intl.formatMessage({id: label});
  const getValueControl = () => {
    if (type === INFO_CARD_TYPE.COMPONENT) {
      return children;
    }
    let cardValue;
    if (value) {
      if (type === INFO_CARD_TYPE.NUMBER) {
        cardValue = <FormattedNumber value={value} />;
      } else if (type === INFO_CARD_TYPE.DATETIME) {
        cardValue = <FormattedDateAndTime format={DATE_FORMATS.dateTime} value={value} />;
      } else {
        cardValue = value;
      }
    } else if (noDataTextKey) {
      cardValue = intl.formatMessage({id: noDataTextKey});
    }
    return cardValue;
  };

  const infoCardClassname =
    feature.isEnabled('temp_support_product_icon_fix') && dataTestId === 'productNameWithIcon'
      ? `${styles['binky-info-bar-value']} ${styles['product-name-info-bar-value']}`
      : styles['binky-info-bar-value'];

  return (
    <Flex
      data-testid={dataTestId || 'info-card'}
      direction="column"
      gap="size-50"
      // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- need font-size & font-color
      UNSAFE_className="info-card"
      wrap
    >
      <View
        data-testid="info-card-field"
        // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- need font-size & font-color
        UNSAFE_className={styles['binky-info-bar-field']}
      >
        {cardLabel}
      </View>
      <View
        data-testid="info-card-value"
        // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- need font-size & font-color
        UNSAFE_className={infoCardClassname}
      >
        {getValueControl()}
      </View>
    </Flex>
  );
};

InfoCard.propTypes = {
  /**
   * Prop to be used to set the child component. E.g. SupportTicketPriority
   */
  children: PropTypes.node,
  /**
   * The specified data-testid for the component.
   * The default is 'info-card'.
   */
  'data-testid': PropTypes.string,
  /**
   * Prop to be used to get transition key for label localization
   */
  label: PropTypes.string.isRequired,
  /**
   * Prop to be used when value prop is null or undefined
   */
  noDataTextKey: PropTypes.string,
  /**
   * Prop be used to define type of the InfoCard value, value is formatted based on type
   */
  type: PropTypes.oneOf(Object.values(INFO_CARD_TYPE)),
  /**
   * Prop to be used to get value of the InfoCard
   */
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default InfoCard;
