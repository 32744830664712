import {User} from '@admin-tribe/acsc';
import {fetchReadableUnitsOfStorage} from '@pandora/react-formatted-file-size';
import PropTypes from 'prop-types';
import React, {createContext, useContext, useMemo} from 'react';

import UserFolder from 'common/services/user-folder/UserFolder';

/**
 * Provider for the delete users with storage modal as a means to pre process the selected
 * member list to delete and the corresponding storage list to fit presentation across modal steps
 */
const DeleteUsersContext = createContext({});

const useDeleteUsers = () => useContext(DeleteUsersContext);

/**
 * Takes the selectedMembers, then fills three arrays with the members. usersWithStorage will have users with storage
 * and the user's calculated storage amount. usersWithoutStorage contains all users without any storage. allUsers has
 * all users with or without storage, maintaining selectedMembers selected order.
 */
// eslint-disable-next-line @admin-tribe/admin-tribe/one-component-file -- logic split to three lists
const DeleteUsersModalContext = ({children, selectedMembers, userStorageList}) => {
  const value = useMemo(
    () =>
      selectedMembers.reduce(
        (splitLists, currentUser) => {
          const userStorage = userStorageList.find((user) => user.name === currentUser.id);
          let currentUserWithStorage; // for Member copy in allUsers

          if (userStorage) {
            const storageConsumed = fetchReadableUnitsOfStorage(userStorage.quota.consumed, 1);
            // create a new instance with a new property without mutating the original
            currentUserWithStorage = Object.assign(Object.create(User.prototype), currentUser, {
              storageConsumed,
            });
            splitLists.usersWithStorage.push(currentUser);
          } else {
            currentUserWithStorage = currentUser;
            splitLists.usersWithoutStorage.push(currentUser);
          }
          splitLists.allUsers.push(currentUserWithStorage);

          return splitLists;
        },
        {
          allUsers: [],
          usersWithoutStorage: [],
          usersWithStorage: [],
        }
      ),
    [selectedMembers, userStorageList]
  );

  return <DeleteUsersContext.Provider value={value}>{children}</DeleteUsersContext.Provider>;
};

DeleteUsersModalContext.propTypes = {
  /**
   * The content to be passed through the delete users content provider.
   */
  children: PropTypes.node.isRequired,
  /**
   * The Users selected for deletion.
   */
  selectedMembers: PropTypes.arrayOf(PropTypes.instanceOf(User)).isRequired,
  /**
   * A UserFolderList with the users that have associated storage.
   * The UserFolder's name corresponds to the Member's id.
   */
  userStorageList: PropTypes.arrayOf(PropTypes.instanceOf(UserFolder)).isRequired,
};

export {DeleteUsersModalContext, useDeleteUsers};
