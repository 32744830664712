import {Flex, Grid, Text} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {
  CREATE_PACKAGE_CONSTANTS,
  PACKAGE_TYPE_CONSTANTS,
} from 'features/packages/packagesConstants';
import PackagesEntitlements from 'features/packages/services/PackagesEntitlementsService';

import style from '../FinalizePackagePage.pcss';
import PackageDetailOptions from '../package-detail-options/PackageDetailOptions';

const PackageDetails = observer(
  // eslint-disable-next-line complexity -- one over limit
  ({packageSessionSettings, packageCreateObj, selectedProductsLength}) => {
    const intl = useIntl();

    const getPlatform = () => {
      switch (packageCreateObj.platform) {
        case CREATE_PACKAGE_CONSTANTS.MAC_ARM_PLATFORM:
          return intl.formatMessage({
            id: 'packages.createPackage.finalizePackagePage.macArmPlatform',
          });
        case CREATE_PACKAGE_CONSTANTS.MAC_PLATFORM:
          return intl.formatMessage({
            id: 'packages.createPackage.finalizePackagePage.macPlatform',
          });
        case CREATE_PACKAGE_CONSTANTS.MACU_PLATFORM:
          return intl.formatMessage({
            id: 'packages.createPackage.finalizePackagePage.macUPlatform',
          });
        case CREATE_PACKAGE_CONSTANTS.WIN_PLATFORM:
          return intl.formatMessage({
            id: `packages.createPackage.finalizePackagePage.${
              packageCreateObj.bits === CREATE_PACKAGE_CONSTANTS.THIRTY_TWO_BIT
                ? 'winPlatform32'
                : 'winPlatform64'
            }`,
          });
        case CREATE_PACKAGE_CONSTANTS.WIN_ARM_PLATFORM:
          return intl.formatMessage({
            id: 'packages.createPackage.finalizePackagePage.windowsArmPlatform',
          });
        case CREATE_PACKAGE_CONSTANTS.LINUX_PLATFORM:
          return intl.formatMessage({
            id: 'packages.createPackage.finalizePackagePage.Linux',
          });
        default:
          break;
      }
      return null;
    };

    const getPackageType = () => {
      switch (packageSessionSettings.packageType) {
        case CREATE_PACKAGE_CONSTANTS.FRL_ISOLATED_PACKAGE:
          return intl.formatMessage({
            id: 'packages.createPackage.finalizePackagePage.offline',
          });
        case CREATE_PACKAGE_CONSTANTS.FRL_ONLINE_PACKAGE:
          return intl.formatMessage({
            id: 'packages.createPackage.finalizePackagePage.online',
          });
        case CREATE_PACKAGE_CONSTANTS.FRL_LAN_PACKAGE:
          return intl.formatMessage({
            id: 'packages.createPackage.finalizePackagePage.lan',
          });
        case CREATE_PACKAGE_CONSTANTS.FRL_OFFLINE_PACKAGE:
          return intl.formatMessage({
            id: `packages.createPackage.finalizePackagePage.offline2`,
          });
        default:
          break;
      }
      return null;
    };

    const isOptionsTabVisible = () => {
      // In case of plugin only lightweight packages we don't have to show options in package details section
      if (
        packageSessionSettings.packageType === PACKAGE_TYPE_CONSTANTS.MANAGED &&
        selectedProductsLength === 0
      ) {
        if (packageSessionSettings.selectedPlugins?.length > 0) {
          return packageSessionSettings.isManagementOptionsSwitchSelected;
        }
        return true;
      }
      return !(
        (PackagesEntitlements.hasDcOnlyEntitlement ||
          PackagesEntitlements.hasNonCcOnlyEntitlement) &&
        packageSessionSettings.packageType === CREATE_PACKAGE_CONSTANTS.FRL_ISOLATED_PACKAGE
      );
    };

    return (
      <Flex direction="column">
        {packageSessionSettings.packagingMode === CREATE_PACKAGE_CONSTANTS.FRL_PACKAGE_TYPE && (
          <Grid
            columns={['2fr', '3fr']}
            gap="size-100"
            marginBottom="size-100"
            marginTop="size-100"
          >
            <Text>
              {intl.formatMessage({
                id: 'packages.createPackage.finalizePackagePage.activation',
              })}
            </Text>
            <Text data-testid="package-type-value">{getPackageType()}</Text>
          </Grid>
        )}
        {packageSessionSettings.packagingMode === CREATE_PACKAGE_CONSTANTS.NUELL_PACKAGE_MODE && (
          <Grid
            columns={['2fr', '3fr']}
            gap="size-100"
            marginBottom="size-100"
            marginTop="size-100"
          >
            <Text>
              {intl.formatMessage({
                id: 'packages.createPackage.finalizePackagePage.activation',
              })}
            </Text>
            <Text data-testid="nuell-package">
              {intl.formatMessage({
                id: 'packages.createPackage.finalizePackagePage.nuellPackage',
              })}
            </Text>
          </Grid>
        )}

        {packageSessionSettings.packageType === CREATE_PACKAGE_CONSTANTS.FRL_LAN_PACKAGE && (
          <Grid
            columns={['2fr', '3fr']}
            gap="size-100"
            marginBottom="size-100"
            marginTop="size-100"
          >
            <Text>
              {intl.formatMessage({
                id: 'packages.createPackage.finalizePackagePage.server',
              })}
            </Text>
            <Text>{packageSessionSettings.frlSelectedServer.name}</Text>
          </Grid>
        )}

        {packageSessionSettings.packageType === CREATE_PACKAGE_CONSTANTS.FRL_ISOLATED_PACKAGE && (
          <Grid
            columns={['2fr', '3fr']}
            gap="size-100"
            marginBottom="size-100"
            marginTop="size-100"
          >
            <Text>
              {intl.formatMessage({
                id: 'packages.createPackage.finalizePackagePage.codes',
              })}
            </Text>
            <Text data-testid="frl-machine-codes">
              {intl.formatMessage({
                id: `packages.createPackage.finalizePackagePage.${
                  packageSessionSettings.frlIsolatedMachineCodes &&
                  packageSessionSettings.frlIsolatedMachineCodes.length > 0
                    ? 'codesIncluded'
                    : 'codesNotIncluded'
                }`,
              })}
            </Text>
          </Grid>
        )}
        {(packageSessionSettings.packagingMode === CREATE_PACKAGE_CONSTANTS.FRL_PACKAGE_TYPE ||
          packageSessionSettings.packagingMode === CREATE_PACKAGE_CONSTANTS.NUELL_PACKAGE_MODE) && (
          <Grid
            columns={['2fr', '3fr']}
            gap="size-100"
            marginBottom="size-100"
            marginTop="size-100"
          >
            <Text>
              {intl.formatMessage({
                id: 'packages.createPackage.finalizePackagePage.entitlements',
              })}
            </Text>
            <ul className={style['options-list']}>
              {packageSessionSettings.selectedFrlOffers.map((offer) => (
                <li key="offer.longName">{offer.longName}</li>
              ))}
            </ul>
          </Grid>
        )}
        <Grid columns={['2fr', '3fr']} gap="size-100" marginBottom="size-100" marginTop="size-100">
          <Text>
            {' '}
            {intl.formatMessage({
              id: 'packages.createPackage.finalizePackagePage.platform',
            })}
          </Text>
          <Text data-testid="platform-value">{getPlatform()}</Text>
        </Grid>
        <Grid columns={['2fr', '3fr']} gap="size-100" marginBottom="size-100" marginTop="size-100">
          <Text>
            {intl.formatMessage({
              id: 'packages.createPackage.finalizePackagePage.language',
            })}
          </Text>
          <Text>
            {packageCreateObj.osLangDetection ? (
              <>
                <span>
                  {intl.formatMessage({
                    id: `packages.locales.${packageCreateObj.locale}`,
                  })}
                </span>
                <span data-testid="os-locale-detected">
                  {intl.formatMessage({
                    id: `packages.createPackage.finalizePackagePage.mathOSLocale`,
                  })}
                </span>
              </>
            ) : (
              intl.formatMessage({
                id: `packages.locales.${packageCreateObj.locale}`,
              })
            )}
          </Text>
        </Grid>
        {isOptionsTabVisible() && (
          <PackageDetailOptions
            packageCreateObj={packageCreateObj}
            packageSessionSettings={packageSessionSettings}
          />
        )}
      </Flex>
    );
  }
);

PackageDetails.propTypes = {
  /**
   * Function to open create server page
   */
  packageCreateObj: PropTypes.shape({
    bits: PropTypes.string,
    osLangDetection: PropTypes.bool,
    platform: PropTypes.string,
  }).isRequired,
  /**
   * Shared object for package creation session
   */
  packageSessionSettings: PropTypes.shape({
    frlSelectedServer: PropTypes.instanceOf(Object),
  }).isRequired,

  selectedProductsLength: PropTypes.number.isRequired,
};

export default PackageDetails;
