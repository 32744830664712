import {
  OverlayWait,
  showError as showErrorToast,
  showSuccess as showSuccessToast,
} from '@admin-tribe/acsc-ui';
import {Flex, Heading, TextField, useDialogContainer} from '@adobe/react-spectrum';
import {ModalButtonGroup, ModalContent, ModalDialog} from '@pandora/react-modal-dialog';
import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {useSetupCertificatesContext} from 'features/settings/components/setup-directory-authentication/SetupCertificatesContext';

const CreateCsrModal = () => {
  const intl = useIntl();
  const dialog = useDialogContainer();

  const {state, createCsr} = useSetupCertificatesContext();
  const {isLoading} = state;

  const [formData, setFormData] = useState({
    fields: {
      /* eslint-disable sort-keys -- Rendering order */
      country: {isValid: true, value: ''},
      state: {isValid: true, value: ''},
      locality: {isValid: true, value: ''},
      organization: {isValid: true, value: ''},
      organizationUnit: {isValid: true, value: ''},
      commonName: {isValid: true, value: ''},
      /* eslint-enable sort-keys -- x*/
    },
    isFormValid: false,
  });

  const handleOnCreateCSR = async () => {
    try {
      const certificateData = {};

      Object.entries(formData.fields).forEach(([key, value]) => {
        certificateData[key] = value.value;
      });

      await createCsr(certificateData);

      showSuccessToast(
        intl.formatMessage({id: 'settings.certificates.createCertificateModal.success'})
      );

      dialog.dismiss();
    } catch (error) {
      showErrorToast(
        intl.formatMessage({id: 'settings.certificates.createCertificateModal.error'})
      );
    }
  };

  const onValueChange = (changedValue, fieldName) =>
    setFormData((currentFormData) => {
      const newFormData = {
        ...currentFormData,
        fields: {
          ...currentFormData.fields,
          [fieldName]: {
            isValid: changedValue.trim() !== '',
            value: changedValue.trim(),
          },
        },
      };
      newFormData.isFormValid = Object.values(newFormData.fields).every(
        (data) => data.value !== ''
      );
      return newFormData;
    });

  return (
    <OverlayWait isLoading={isLoading} showContent>
      <ModalDialog size="M">
        <Heading data-testid="create-csr-modal-header" marginStart="size-400">
          <FormattedMessage id="settings.certificates.createCertificateModal.title" />
        </Heading>

        <ModalContent showDivider={false}>
          <Flex alignItems="flex-start" direction="column" marginTop="size-125" rowGap="16px">
            {Object.keys(formData.fields).map((field) => (
              <TextField
                key={field}
                isDisabled={isLoading}
                isRequired
                label={intl.formatMessage({
                  id: `settings.certificates.createCertificateModal.${field}.label`,
                })}
                name={field}
                onChange={(value) => onValueChange(value, field)}
                validationState={formData.fields[field].isValid ? null : 'invalid'}
                width="100%"
              />
            ))}
          </Flex>
        </ModalContent>

        <ModalButtonGroup
          cancelLabel={intl.formatMessage({id: 'common.modal.buttons.close'})}
          closeModal={dialog.dismiss}
          ctaLabel={intl.formatMessage({
            id: 'settings.certificates.createCertificateModal.buttons.create',
          })}
          data-testid="csr-modal-button-group"
          isCtaDisabled={!formData.isFormValid || isLoading}
          onCancel={() => dialog.dismiss()}
          onCta={handleOnCreateCSR}
        />
      </ModalDialog>
    </OverlayWait>
  );
};

export default CreateCsrModal;
