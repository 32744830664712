import {OfferList, PoresOfferList, feature} from '@admin-tribe/acsc';
import {Heading, IllustratedMessage, View} from '@adobe/react-spectrum';
import NotFound from '@spectrum-icons/illustrations/NotFound';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';

/**
 * @description Component for showing the no products illustrated message when
 * no products exist for an organization when the all products page is loaded.
 */
const NoProductsIllustratedMessage = ({freeOfferList}) => {
  const intl = useIntl();

  const isProductListEmpty = rootStore.organizationStore.productList.items.length === 0;

  if (
    feature.isEnabled('temp_ctir_18512_product_offers') &&
    isProductListEmpty &&
    freeOfferList?.items?.length > 0
  ) {
    return null;
  }

  return (
    isProductListEmpty && (
      <View backgroundColor="gray-50" paddingY="size-800">
        <IllustratedMessage>
          <NotFound />
          <Heading level={2}>
            {intl.formatMessage({id: 'products.allProducts.noProducts.heading'})}
          </Heading>
        </IllustratedMessage>
      </View>
    )
  );
};

NoProductsIllustratedMessage.propTypes = {
  /** The freeOfferList, omitted if ineligible for free offers. */
  freeOfferList: PropTypes.oneOfType([
    PropTypes.instanceOf(OfferList),
    PropTypes.instanceOf(PoresOfferList),
  ]),
};

export default NoProductsIllustratedMessage;
