import {PAGE_TARGET, dispatchUiEventAnalytics, feature} from '@admin-tribe/acsc';
import {Button} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import AddUserToOrgModal from 'common/components/add-user-modals/AddUsersToOrgModal';
import AddUsersToProductModal from 'common/components/add-user-modals/AddUsersToProductModal';
import AddUsersToProductProfileModal from 'common/components/add-user-modals/AddUsersToProductProfileModal';
import AddUsersToUserGroupModal from 'common/components/add-user-modals/add-users-to-user-group/AddUsersToUserGroupModal';
import {useMemberListContext} from 'common/hooks/member-list-context/MemberListContext';
import rootStore from 'core/RootStore';
import {canUseAddProductMiniAppForAll} from 'core/products/access/productAccess';

import {getOrgInfoWithSubscriptionStatusForAnalytics} from '../../../utils/analyticsUtils';

// The 'Add user' button, and the associated modal to add new users to one of
// the Users page, the User group users sub-page, a Product users sub-page or
// a Product profile users sub-page.
const AddUsersButton = observer(() => {
  const intl = useIntl();
  const {canAddLicense, canAddMember, canAddMemberDisabled, pageContext, store} =
    useMemberListContext();
  const [isOpen, setIsOpen] = useState(false);
  const product =
    store.productId &&
    rootStore.organizationStore.productList.items.find((p) => p.id === store.productId);

  const showLicenseButton = canUseAddProductMiniAppForAll() && canAddLicense && !!product;
  let variant = 'accent';
  if (feature.isEnabled('temp_add_licenses_cta_blue') && showLicenseButton) {
    variant = 'secondary';
  }

  const commonProps = {
    isOpen,
    onClosed,
    onSuccess,
    orgId: store.orgId,
  };

  function onClosed() {
    setIsOpen(false);
  }

  // Filter/page params are not changed.
  function onSuccess() {
    store.load();
    setIsOpen(false);
  }

  // Render the 'Add users' modal for the given pageContext.
  function renderModal() {
    if (pageContext.isOrganizationTarget()) {
      return <AddUserToOrgModal {...commonProps} />;
    }

    if (pageContext.isProductTarget()) {
      return <AddUsersToProductModal {...commonProps} productId={pageContext.targetId} />;
    }

    if (pageContext.isProductConfigurationTarget()) {
      return (
        <AddUsersToProductProfileModal
          {...commonProps}
          licenseGroupId={pageContext.targetId}
          productId={pageContext.targetParentId}
        />
      );
    }

    if (pageContext.target === PAGE_TARGET.USER_GROUP) {
      return <AddUsersToUserGroupModal {...commonProps} userGroupId={pageContext.targetId} />;
    }

    throw new Error('AddUsersButton: unrecognized target');
  }

  const onPress = () => {
    const newOrg = getOrgInfoWithSubscriptionStatusForAnalytics(
      product?.offerId,
      product?.offerType
    );
    dispatchUiEventAnalytics({
      eventAction: 'display',
      eventName: 'addUsersToProductModal',
      organizationInfo: newOrg,
    });

    setIsOpen(true);
  };

  return (
    canAddMember && (
      <>
        {/* Since this button has an isDisabled property we must explicitly disable it if there is an API error. */}
        <Button
          data-testid="add-users-btn"
          isDisabled={canAddMemberDisabled || store.hasLoadingError}
          onPress={onPress}
          variant={variant}
        >
          {intl.formatMessage({id: 'common.userListTableSection.button.addUsers'})}
        </Button>
        {isOpen && renderModal()}
      </>
    )
  );
});

export default AddUsersButton;
