import {ModelList, log, modelCache} from '@admin-tribe/acsc';
import {showError, showSuccess, translateString} from '@admin-tribe/acsc-ui';
import axios from 'axios';
import {reaction} from 'mobx';

import rootStore from 'core/RootStore';

import {deleteServers, getLanServers} from '../api/tronData';
import {PACKAGES_LAN_SERVERS, POLLING_FAILURE_COUNT, SERVERS_CONSTANTS} from '../packagesConstants';

import PackagesLanServerService from './PackagesLanServerService';

let cancelTokenServersSource, lanServersGetterResourceResponse, lanServersModel, pollerPromise;
let isModelDirty = false;
let isCreateDisabled = false;
let pollingQueue = [];
let pollingFailureCountMap = {};

/**
 * @description Method to reset all LAN Servers
 */
function configure() {
  modelCache.put(PACKAGES_LAN_SERVERS, 'LAN_SERVERS', 1);
  reaction(
    () => rootStore.organizationStore.activeOrg,
    () => {
      resetLanServers();
    }
  );
}

configure();

/* Model for LAN servers list */
class PackagesLanServersService extends ModelList {
  /**
   * @description Method to fetch and process all LAN Servers
   *
   * @param {Boolean} forcedFetch force fetch from back-end ignoring model-cache. Default false.
   * @returns {Promise} returns promise
   */
  static async fetchAndProcessLanServers(forcedFetch = false) {
    const cachedLanServers = await modelCache.get(PACKAGES_LAN_SERVERS, 'LAN_SERVERS');
    if (!isModelDirty && !forcedFetch && cachedLanServers?.data) {
      onLanServersFetchSuccess(cachedLanServers);
      return 'Promise resolved successfully';
    }
    try {
      cancelTokenServersSource = axios.CancelToken.source();
      lanServersGetterResourceResponse = await getLanServers(cancelTokenServersSource);
      if (lanServersGetterResourceResponse) {
        pollingQueue = [];
        pollingFailureCountMap = {};
        lanServersModel = new PackagesLanServersService(lanServersGetterResourceResponse.data);
        initializeServerProgressPoller();
        modelCache.put(PACKAGES_LAN_SERVERS, 'LAN_SERVERS', lanServersGetterResourceResponse);
        isModelDirty = false;
      }
      return lanServersGetterResourceResponse;
    } catch (error) {
      log.error(
        'PackagesLanServers.fetchAndProcessLanServers(): Unable to retrieve model from back-end. Error: ',
        error
      );
      throw error;
    }
  }

  /**
   * @description Method to mark the model dirty
   */
  static markModelDirty() {
    isModelDirty = true;
  }

  /**
   * @description Method to get list of all LAN Servers with 'ACTIVE' status having fulfillable items as desktop items from model
   *
   * @returns {Array} array of PackagesLanServer objects
   */
  static getAllActiveLanServers() {
    return lanServersModel.items.filter((server) => {
      if (server.status !== SERVERS_CONSTANTS.STATUS.ACTIVE) {
        return false;
      }
      return (
        server.licenses.filter((license) =>
          license.fulfillableItemList.getDesktopTypeItems().some((fulfillableItem) => {
            const unitCodes = fulfillableItem.chargingModel?.unit;
            return (
              fulfillableItem.code ===
                rootStore.packagesUiConstantsStore.frlPreconditioningFiCode &&
              unitCodes.includes(rootStore.packagesUiConstantsStore.frlLanUnitCode)
            );
          })
        ).length > 0
      );
    });
  }

  /**
   * @description Method to get list of all LAN Servers from model
   *
   * @returns {Array} array of PackagesLanServer objects
   */
  static getAllLanServers() {
    return lanServersModel?.items;
  }

  /**
   * @description Method to get number of LAN Servers in model
   *
   * @returns {Integer} number of LAN Servers in model
   */
  static getLanServersCount() {
    return this.getAllLanServers().length;
  }

  /**
   * @description Method to remove given servers from model and backend
   *
   * @param {Array} selectedServers list of PackagesLanServer objects to remove
   * @param {Boolean} isCancelOperation flag if the given server is to be cancelled or removed
   * @returns {Promise} promise indicating completion of removal from backend
   */
  static async deleteSelectedServers(selectedServers, isCancelOperation) {
    const selectedServerIds = selectedServers.map((server) => server.serverId);
    const selectedServerIdsSize = selectedServers.length;
    try {
      const lanServerDeleteResourceResponse = await deleteServers(selectedServerIds);
      const responseSize = lanServerDeleteResourceResponse.data.length;
      // show error if some/all server failed to delete
      if (responseSize < selectedServerIdsSize) {
        showDeleteServersErrorToast(selectedServerIdsSize - responseSize, isCancelOperation);
      }

      // show success if atleast 1 server deleted successfully
      if (responseSize > 0) {
        if (isCancelOperation) {
          showSuccess(
            translateString(
              {
                id: 'packages.servers.removeServers.cancelServerSuccess',
              },
              {countServer: responseSize}
            )
          );
        } else {
          showSuccess(
            translateString(
              {
                id: 'packages.servers.removeServers.removeServerSuccess',
              },
              {count: responseSize}
            )
          );
        }
      }

      return 'Promise resolved successfully';
    } catch (error) {
      log.error(
        'PackagesLanServers.deleteSelectedServers(): Unable to delete servers on back-end. Error: ',
        error
      );
      showDeleteServersErrorToast(selectedServerIdsSize, isCancelOperation);
      throw error;
    }
  }

  /**
   * @description Method to check if creation of LAN server is disabled
   *
   * @returns {Boolean} true if creation of LAN server is disabled
   */
  static isCreateServerDisabled() {
    return isCreateDisabled;
  }

  /**
   * @description Class constructor to initialize LAN Server list model with given JSON.
   *
   * @param {Array} servers Servers JSON as received from backend.
   */
  constructor(servers) {
    super({
      items: servers.map((serverInfoWrapper) => {
        const newLanServer = new PackagesLanServerService(serverInfoWrapper);
        if (newLanServer.isCreationInProgress()) {
          pollingQueue.push(newLanServer);
        }
        return newLanServer;
      }),
    });
  }
}

/* Private methods */

function checkPollingFailureCountMap(server) {
  return !(
    pollingFailureCountMap[server.serverId] &&
    pollingFailureCountMap[server.serverId] >= POLLING_FAILURE_COUNT
  );
}

function clearPollerPromise() {
  clearInterval(pollerPromise);
  pollerPromise = undefined;
}

/**
 * @description Method to initialize Server Progress Poller
 */
function initializeServerProgressPoller() {
  if (pollerPromise) {
    clearPollerPromise();
  }
  if (pollingQueue.length === 0) {
    return;
  }

  pollerPromise = setInterval(async () => {
    const filteredPollingQueue = pollingQueue.filter(checkPollingFailureCountMap);
    // eslint-disable-next-line no-restricted-syntax -- added comment
    for (const element of filteredPollingQueue) {
      try {
        // eslint-disable-next-line no-await-in-loop -- to ensure that the statements are executed sequentially
        await element.fetchAndProcessServerProgress();
        pollingFailureCountMap[element.serverId] = 0;
        if (!element.isCreationInProgress()) {
          pollingQueue = pollingQueue.filter(
            (server) => String(server.serverId) !== String(element.serverId)
          );
        }
      } catch (error) {
        log.error(`Failed to fetch progress for server ${element.serverId}`, error);
        // eslint-disable-next-line no-plusplus -- unary operator is used
        pollingFailureCountMap[element.serverId] = ++pollingFailureCountMap[element.serverId] || 1;
      }
    }
    if (filteredPollingQueue.filter(checkPollingFailureCountMap).length === 0) {
      clearPollerPromise();
    }
  }, rootStore.packagesUiConstantsStore.pollingFrequency);
}

/**
 * @description Method to reset all LAN Server data
 */
function resetLanServers() {
  cancelTokenServersSource?.cancel();
  lanServersModel = undefined;
  pollingQueue = [];
  pollingFailureCountMap = {};
  isModelDirty = false;
  isCreateDisabled = false;
  modelCache.clear(PACKAGES_LAN_SERVERS);
}

function onLanServersFetchSuccess(response) {
  pollingQueue = [];
  pollingFailureCountMap = {};
  lanServersModel = new PackagesLanServersService(response.data);
  initializeServerProgressPoller();
  isModelDirty = false;
}

function showDeleteServersErrorToast(count, isCancelOperation) {
  if (isCancelOperation) {
    showError(
      translateString(
        {
          id: 'packages.servers.removeServers.cancelServerError',
        },
        {countServer: count}
      )
    );
  } else {
    showError(
      translateString(
        {
          id: 'packages.servers.removeServers.removeServerError',
        },
        {countServer: count}
      )
    );
  }
}

export default PackagesLanServersService;
export {initializeServerProgressPoller, resetLanServers, configure};
