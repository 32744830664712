import {
  showError as showErrorToast,
  showInfo as showInfoToast,
  showSuccess as showSuccessToast,
} from '@admin-tribe/acsc-ui';
import {Button} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useMemo, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {SCHEDULE_OPTIONS} from 'features/settings/api/eduRosterSync';
import {useRosterSyncContext} from 'features/settings/components/directory/sync/RosterSyncContext';
import {EDU_SYNC_STATUSES} from 'features/settings/components/edu-sync-card/EduSyncStatus';
import {ACTIONS} from 'features/settings/components/edu-sync-card/footer/eduActionsUtils';
import useEduRosterSync from 'features/settings/hooks/api/useEduRosterSync';

const QueueResumeButton = ({
  disabledEduActions,
  syncDetails,
  onQueueSyncConfirm,
  onResumeSyncConfirm,
}) => {
  const intl = useIntl();
  const {status, syncSchedule, syncId} = syncDetails;

  const [isLoadingSync, setIsLoadingSync] = useState(false);

  const {queueRosterSyncAction, resumeRosterSyncAction} = useEduRosterSync();
  const {updateRosterSyncData} = useRosterSyncContext();

  const isQueueAction = status !== EDU_SYNC_STATUSES.STOPPED;

  const isQueueResumeButtonDisabled = useMemo(
    () =>
      !!disabledEduActions.find(
        (action) => action === ACTIONS.QUEUE_SYNC || action === ACTIONS.RESUME_SYNC
      ),
    [disabledEduActions]
  );

  const queueResumeButtonLabel = useMemo(() => {
    if (status === EDU_SYNC_STATUSES.STOPPED) {
      return 'settings.sync.eduSync.card.footer.buttons.resumeSync';
    }
    return 'settings.sync.eduSync.card.footer.buttons.queueNow';
  }, [status]);

  const handleSyncAction = async () => {
    setIsLoadingSync(true);

    try {
      const response = isQueueAction
        ? await queueRosterSyncAction({syncId})
        : await resumeRosterSyncAction({syncId});
      const updatedSyncData = response.data;

      if (isQueueAction) {
        // Remove when queueRosterSync is deprecrated in favor of queueRosterSyncAction
        updatedSyncData.status = EDU_SYNC_STATUSES.QUEUED;

        updateRosterSyncData({syncId, updatedSyncData});
        showInfoToast(intl.formatMessage({id: 'settings.sync.eduSync.toast.addToQueue'}));
      } else {
        updateRosterSyncData({syncId, updatedSyncData});
        showSuccessToast(intl.formatMessage({id: 'settings.sync.eduSync.toast.resumeSync'}));
      }
    } catch (error) {
      showErrorToast(intl.formatMessage({id: 'settings.sync.eduSync.toast.error'}));
    } finally {
      setIsLoadingSync(false);
    }
  };

  const handleOnQueueResumePress = () => {
    // Automatic syncs require QueueSyncConfirmation modal to be open to warn about,
    // one-time queueing, but only the weekly sync requires ResumeRosterSyncConfirmation
    // modal warning regarding changing the recurring day of the sync
    const handleWeeklySync = isQueueAction ? onQueueSyncConfirm : onResumeSyncConfirm;
    const handleDailySync = isQueueAction ? onQueueSyncConfirm : handleSyncAction;

    switch (syncSchedule) {
      case SCHEDULE_OPTIONS.MANUAL:
        handleSyncAction();
        break;
      case SCHEDULE_OPTIONS.WEEKLY:
        handleWeeklySync();
        break;
      case SCHEDULE_OPTIONS.DAILY:
        handleDailySync();
        break;
      // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- Unknown sync schedule is nonexistent
      // istanbul ignore next
      default:
        break;
    }
  };

  return (
    <Button
      data-testid="queue-resume-button"
      isDisabled={isQueueResumeButtonDisabled || isLoadingSync}
      onPress={handleOnQueueResumePress}
      variant="secondary"
    >
      <FormattedMessage id={queueResumeButtonLabel} />
    </Button>
  );
};

QueueResumeButton.propTypes = {
  /**
   * Array of disabled actions for the buttons menu
   */
  disabledEduActions: PropTypes.arrayOf(PropTypes.oneOf(Object.values(ACTIONS))).isRequired,
  /**
   * The callback function to open the Queue Sync confirmation modal
   */
  onQueueSyncConfirm: PropTypes.func.isRequired,
  /**
   * The callback function to open the Resume Sync confirmation modal
   */
  onResumeSyncConfirm: PropTypes.func.isRequired,
  syncDetails: PropTypes.shape({
    /**
     * The ID of the school district as registered with the roster provider
     */
    districtId: PropTypes.string.isRequired,
    /**
     * Denotes the current state of the edu roster sync
     */
    enabled: PropTypes.bool.isRequired,
    /**
     * The unique identifier for the IMS Org
     */
    imsOrgId: PropTypes.string.isRequired,
    /**
     * The timestamp for the last sync. It's null if no sync happened yet
     */
    lastSyncedAt: PropTypes.string,
    /**
     * Denotes an Adobe product
     */
    products: PropTypes.arrayOf(
      PropTypes.shape({
        productAppName: PropTypes.string,
        productName: PropTypes.string,
      }).isRequired
    ),
    /**
     * The roster sync provider. The supported providers are Clever and Classlink
     */
    rosterSource: PropTypes.string.isRequired,
    /**
     * Denotes the current status of the edu roster sync
     */
    status: PropTypes.oneOf(Object.values(EDU_SYNC_STATUSES)).isRequired,
    /**
     * The unique identifier for a edu roster sync
     */
    syncId: PropTypes.string.isRequired,
    /**
     * The type of schedule by which the sync runs. One of "daily", "weekly", and "manual"
     */
    syncSchedule: PropTypes.oneOf(Object.values(SCHEDULE_OPTIONS)).isRequired,
  }).isRequired,
};

export default QueueResumeButton;
