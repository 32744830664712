import {
  AuthenticatedUser,
  CONTRACT_ADMIN,
  LICENSE_ADMIN,
  ORG_ADMIN,
  PRODUCT_ADMIN,
  PRODUCT_SUPPORT_ADMIN,
  STORAGE_ADMIN,
  SUPPORT_ADMIN,
  USER_GROUP_ADMIN,
  feature,
} from '@admin-tribe/acsc';
import {isT2EUser} from '@admin-tribe/acsc-ui';

import {isLoggedInMember} from 'common/utils/member-utils/memberUtils';
import rootStore from 'core/RootStore';
import {
  isOrgAdmin,
  isReadOnly,
  canEditUser as isUserEditable,
} from 'core/organizations/access/organizationAccess';

// Permission matrix is https://helpx.adobe.com/enterprise/using/admin-roles.html#permissions-matrix
// Sign migration matrix is https://git.corp.adobe.com/admin-tribe/onesie/pull/13598

/**
 * Determines whether this admin can assign a user to the given organization.
 *
 * @param {String} [orgId] The organization id. The default is the current org.
 * @returns {Boolean} returns true if this admin can assign a user to the organization.
 */
function canAssignUser(orgId = rootStore.organizationStore.activeOrgId) {
  const userRoles = AuthenticatedUser.get().getRoles();

  return (
    userRoles.anyOfForOrg([LICENSE_ADMIN, ORG_ADMIN, CONTRACT_ADMIN, PRODUCT_ADMIN], orgId) &&
    !isReadOnly() &&
    rootStore.organizationStore.migrationList.shouldAlignWithAddUserLogic()
  );
}

/**
 * @description method to determine whether member can edit user profile or not.
 *
 * @param {Member} member a member to check
 * @returns {Boolean} whether member can edit user profile or not.
 */
function canEditUser(member) {
  if (!member) {
    return false;
  }
  return (
    isUserEditable() &&
    !member.getType()?.isTechnicalAccount() &&
    !isLoggedInMember(member) &&
    (isT2EUser(member) ? member.authenticatingAccount?.editable : member.editable) &&
    !member.isExternallyManaged?.()
  );
}

/**
 * Determines whether this admin can remove a user (including user storage) from the current organization
 * or whether this admin can assign a user to the current organization via CSV.
 *
 * @returns {Boolean} returns true if this admin can remove a user from the organization.
 */
function canRemoveUserOrAssignUserByCSV() {
  return (
    isOrgAdmin() &&
    !isReadOnly() &&
    rootStore.organizationStore.migrationList.shouldAlignWithAddUserLogic()
  );
}

/**
 * @returns Determines whether the license deficit report can be viewed.
 */
function canViewLicenseDeficitReport() {
  return isOrgAdmin();
}

/**
 * @returns Whether the current admin can view the details for a user in the org.
 */
function canViewUserDetails() {
  return canViewUsers();
}

/**
 * @description Method to determine if admin can view user introductions
 *
 * @returns {Boolean} Resolves to true if the admin can view the user introductions, false otherwise
 */
function canViewUserIntroductions(orgId = rootStore.organizationStore.activeOrgId) {
  return canAssignUser(orgId);
}

/**
 * @returns Whether the current admin can view the users in the org.
 */
function canViewUsers() {
  const orgId = rootStore.organizationStore.activeOrgId;
  const userRoles = AuthenticatedUser.get().getRoles();

  if (feature.isEnabled('temp_adobe_agent_access')) {
    return (
      userRoles.anyOfForOrg(
        [
          LICENSE_ADMIN,
          ORG_ADMIN,
          CONTRACT_ADMIN,
          PRODUCT_ADMIN,
          PRODUCT_SUPPORT_ADMIN,
          STORAGE_ADMIN,
          SUPPORT_ADMIN,
          USER_GROUP_ADMIN,
        ],
        orgId
      ) || AuthenticatedUser.get().getRoles().isActingAsAdobeAgentForOrg(orgId)
    );
  }

  return userRoles.anyOfForOrg(
    [
      LICENSE_ADMIN,
      ORG_ADMIN,
      CONTRACT_ADMIN,
      PRODUCT_ADMIN,
      PRODUCT_SUPPORT_ADMIN,
      STORAGE_ADMIN,
      SUPPORT_ADMIN,
      USER_GROUP_ADMIN,
    ],
    orgId
  );
}

export {
  canAssignUser,
  canRemoveUserOrAssignUserByCSV as canAssignUserByCSV,
  canEditUser,
  canRemoveUserOrAssignUserByCSV as canRemoveUser,
  canViewLicenseDeficitReport,
  canViewUsers,
  canViewUserDetails,
  canViewUserIntroductions,
};
