import {ORGANIZATION_USER_API_QUERY_PARAMS, OrganizationUser, feature} from '@admin-tribe/acsc';
import {useAsyncModel} from '@pandora/react-async-model';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import UserDetailsContentWrapper from 'common/components/user-details-content/user-details-content-wrapper/UserDetailsContentWrapper';
import UserDetailsModalsWrapper from 'common/components/user-details-content/user-details-modals-wrapper/UserDetailsModalsWrapper';
import useEditDetailsModal from 'common/hooks/use-edit-details-modal/useEditDetailsModal';
import rootStore from 'core/RootStore';
import {getManageableAdminRoles} from 'core/admin/access/adminAccess';
import {transformMember} from 'features/users/services/member-transformer/memberTransformerUtils';

/**
 * User details content
 */
const UserDetailsContent = ({headingLevel, isPageVariant, member}) => {
  const intl = useIntl();
  const orgId = rootStore.organizationStore.activeOrgId;

  const [manageableRoles, setManageableRoles] = useState();
  const [isRolesLoading, setIsRolesLoading] = useState(true);

  const fetchOrganizationUser = useCallback(
    async () => {
      let includeList = [
        ORGANIZATION_USER_API_QUERY_PARAMS.PRODUCTS,
        ORGANIZATION_USER_API_QUERY_PARAMS.USER_GROUP_PRODUCTS,
        ORGANIZATION_USER_API_QUERY_PARAMS.USER_CREATION_SOURCE,
        ORGANIZATION_USER_API_QUERY_PARAMS.USER_EDU_ROLE_TAGS,
      ];

      includeList = feature.isEnabled('temp_de_exclusion_40038')
        ? [
            ...includeList,
            ORGANIZATION_USER_API_QUERY_PARAMS.DOMAIN_ENFORCEMENT_EXCLUSION_INDICATOR,
          ]
        : includeList;
      const response = await OrganizationUser.get({
        include: includeList,
        orgId,
        userId: member.id,
      });

      return transformMember({intl, member: response});
    }, // eslint-disable-next-line react-hooks/exhaustive-deps -- no dependencies needed
    []
  );

  const {
    model: resolvedMember,
    isLoading,
    error,
    updateModel,
  } = useAsyncModel({loadFn: fetchOrganizationUser});
  const showError = !!error;

  // Get manageable admin roles. These are the roles shown in the 'Add admin' modal.
  useEffect(() => {
    let isMounted = true;
    const getAdminRoles = async () => {
      try {
        const roles = await getManageableAdminRoles();
        if (isMounted) {
          setManageableRoles(roles);
        }
        // No action necessary
      } catch {
      } finally {
        if (isMounted) {
          setIsRolesLoading(false);
        }
      }
    };

    getAdminRoles();

    return () => {
      isMounted = false;
    };
  }, []);

  const {onCloseModal, onOpenModal, onSuccess, openedModal} = useEditDetailsModal({
    updateModelFn: () => updateModel(fetchOrganizationUser),
  });

  return (
    <>
      {!isLoading && !isRolesLoading && !showError && (
        <UserDetailsModalsWrapper
          manageableRoles={manageableRoles}
          member={resolvedMember}
          onCancel={onCloseModal}
          onSuccess={onSuccess}
          openedModal={openedModal}
        />
      )}
      <UserDetailsContentWrapper
        headingLevel={headingLevel}
        isLoading={isLoading}
        isPageVariant={isPageVariant}
        member={resolvedMember}
        onOpenModal={onOpenModal}
        showError={showError}
      />
    </>
  );
};

UserDetailsContent.propTypes = {
  /**
   * The heading level for the section Heading. The default is 3.
   */
  headingLevel: PropTypes.number,
  /**
   * Whether the section has page variant or not.
   */
  isPageVariant: PropTypes.bool,
  /**
   * The user or admin.
   */
  member: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(OrganizationUser)]),
};

export default UserDetailsContent;
