import {feature} from '@admin-tribe/acsc';
import {PageBanner} from '@admin-tribe/acsc-ui';
import {Button, DialogTrigger, Flex, Link} from '@adobe/react-spectrum';
import {getContractTermsAcceptance} from '@pandora/data-model-contract';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';

import LinkedMembershipTermsModal from 'common/components/linked-membership-terms-modal/LinkedMembershipTermsModal';
import rootStore from 'core/RootStore';

/**
 * VIP MP Linked Membership terms and conditions banner.
 * @param {String} contractId - Current Contract ID
 * @returns {React.Element|null} - Linked Membership Banner component or null if the contract does not need acceptance.
 */
const LinkedMembershipBanner = ({contractId}) => {
  const intl = useIntl();
  const {contractList} = rootStore.organizationStore;
  const contract = useMemo(
    () => contractList.items.find((item) => item.id === contractId),
    [contractId, contractList]
  );
  const header = intl.formatMessage({id: 'common.linkedMembershipBanner.title'});
  const acceptLabel = intl.formatMessage({id: 'common.linkedMembershipBanner.accept'});

  const shouldShowLinkedMembershipBanner =
    feature.isEnabled('temp_linked_membership_vipmp') &&
    getContractTermsAcceptance(contract, 'tc_vipmp_linked_membership')?.needsAcceptance?.needed;

  return (
    shouldShowLinkedMembershipBanner && (
      <PageBanner header={header} showDefaultButton={false}>
        {/* eslint-disable-next-line @admin-tribe/admin-tribe/extract-large-computations -- needed more lines */}
        {intl.formatMessage(
          {id: 'common.linkedMembershipBanner.description'},
          {
            goUrl: (str) => (
              <Link
                href="https://www.adobe.com/howtobuy/buying-programs/vipmp-linked-membership-supplemental.html"
                target="_blank"
              >
                {str}
              </Link>
            ),
          }
        )}
        <Flex justifyContent="end">
          <DialogTrigger>
            <Button data-testid="accept-terms-button" variant="primary">
              {acceptLabel}
            </Button>
            {(close) => <LinkedMembershipTermsModal contract={contract} onClose={close} />}
          </DialogTrigger>
        </Flex>
      </PageBanner>
    )
  );
};

LinkedMembershipBanner.propTypes = {
  contractId: PropTypes.string,
};

export default LinkedMembershipBanner;
