import {getSeatBasedAssignableLicenseCount, hasPackageSupport} from '@admin-tribe/acsc';

import rootStore from 'core/RootStore';
import auth from 'core/services/auth';
import {goToAllPackages} from 'features/packages/routing/navigation-callbacks/navigationCallbacks';

import HvaCard from '../HvaCard';
import {HVA_ORDER, HVA_TYPE, MINIMUM_SEATS_REQUIRED} from '../hvaConstants';

/**
 * This class creates a HVA card allowing the user to create packages
 */
class CreatePackagesHva extends HvaCard {
  /**
   * @description Method to fetch the CreatePackagesHva
   *
   * @param {Object} intl - react-intl
   *
   * @returns {Promise<CreatePackagesHva[]>} the fetched instance
   */
  static get({intl}) {
    if (
      (!auth.isUserOrgAdmin() && !auth.isUserDeploymentAdmin()) ||
      getSeatBasedAssignableLicenseCount(rootStore.organizationStore.productList) <
        MINIMUM_SEATS_REQUIRED ||
      !hasPackageSupport(rootStore.organizationStore.productList)
    ) {
      return Promise.reject();
    }

    return Promise.resolve([new CreatePackagesHva({intl})]);
  }

  /**
   *
   * @description Creates a new CreatePackagesHva instance
   *
   * @param {Object} obj
   *
   * @param {Object} obj.intl - react-intl to translate texts
   *
   * @returns {CreatePackagesHva} the fetched instance
   */
  constructor({intl}) {
    const namespace = 'overview.highValueActionArea.valueDiscoveryTasks.createPackages';

    super({
      ctaLabel: intl.formatMessage({id: `${namespace}.ctaLabel`}),
      description: intl.formatMessage({id: `${namespace}.description`}),
      dismissOnCTA: true,
      goUrl: 'aac_nul_packages_learn',
      header: intl.formatMessage({id: `${namespace}.header`}),
      icon: {
        src: 'https://odin.adobe.com/content/dam/admin-tribe/assets/hva-illustrations/create-packages.svg',
        style: {width: '40px'},
      },
      id: 'hva-create-packages',
      isDismissible: true,
      onCTA: goToAllPackages,
      priorityOrder: HVA_ORDER.CREATE_PACKAGES,
      sectionTitle: intl.formatMessage({id: `${namespace}.sectionTitle`}),
      type: HVA_TYPE.VALUE_DISCOVERY,
    });
  }
}

export default CreatePackagesHva;
