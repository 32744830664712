import {log} from '@admin-tribe/acsc';
import cloneDeep from 'lodash/cloneDeep';

import rootStore from 'core/RootStore';

import {
  getOrgPreferences,
  getUserPreferences,
  storeOrgPreferences,
  storeUserPreferences,
} from '../api/tronData';
import {CREATE_PACKAGE_CONSTANTS} from '../packagesConstants';

class PackagesPreferencesService {
  /**
   * @description Method to check is ausst fallback is enabled
   * @returns {boolean} return true if ausstFallbackEnabled
   */

  static get ausstFallbackEnabled() {
    return this.preferences?.ausstFallbackEnabled ?? false;
  }

  /**
   * @description Method to fetch User Preferences from back-end
   *
   */
  static async fetchUserPreferences() {
    if (this.preferences) {
      return;
    }
    try {
      const response = await getUserPreferences();
      this.preferences = response.data;
    } catch (error) {
      log.error(
        `PackagesPreferences.fetchUserPreferences(): Unable to retrieve model from back-end. Error:`,
        error
      );
      // eslint-disable-next-line consistent-return -- return only required in case of error
      return Promise.reject(error);
    }
  }

  /**
   * @description Method to fetch Organization Preferences from back-end
   * @throws {Error} if call to fetch org preferences fails
   */
  static async fetchOrgPreferences() {
    const queryParams = {
      country: rootStore.organizationStore.activeOrg.countryCode,
      segment: rootStore.organizationStore.activeOrg.marketSegment,
    };
    try {
      const response = await getOrgPreferences(queryParams);
      this.orgPreferences = response.data;
    } catch (error) {
      log.error(
        `PackagesPreferences.fetchOrgPreferences(): Unable to retrieve model for orgPreferences from back-end. Error:`,
        error
      );
      throw error;
    }
  }

  /**
   * @description Method to get AUSST XML
   *
   * @param {String} platform platform specifier ['MAC', 'WIN']
   * @returns {String} AUSST XML string (false if not present)
   */
  static getAusstXml(platform) {
    if (this.isMac(platform)) {
      return this.preferences?.ausstOverrideMac ?? false;
    }
    if (this.isWin(platform)) {
      return this.preferences?.ausstOverrideWin ?? false;
    }
    return false;
  }

  /**
   * @description Method to get user install directory for given platform
   *
   * @param {String} platform platform specifier ['MAC', 'WIN']
   * @returns {String} default user directory (false if not present)
   */
  static getUserInstallDirectory(platform) {
    if (this.isMac(platform)) {
      return this.preferences?.userInstallDirectoryMac ?? false;
    }
    if (this.isWin(platform)) {
      return this.preferences?.userInstallDirectoryWin ?? false;
    }
    return false;
  }

  static isMac(platform) {
    return [
      CREATE_PACKAGE_CONSTANTS.MAC_PLATFORM,
      CREATE_PACKAGE_CONSTANTS.MAC_ARM_PLATFORM,
      CREATE_PACKAGE_CONSTANTS.MACU_PLATFORM,
    ].includes(platform);
  }

  static isWin(platform) {
    return [
      CREATE_PACKAGE_CONSTANTS.WIN_PLATFORM,
      CREATE_PACKAGE_CONSTANTS.WIN_ARM_PLATFORM,
    ].includes(platform);
  }

  /**
   * @description Method to check if showUnsupportedVersions is enabled.
   * @returns {boolean} return true if showUnsupportedVersions
   */
  static get showUnsupportedVersions() {
    if (this.preferences?.showUnsupportedVersions) {
      return this.preferences.showUnsupportedVersions;
    }
    return false;
  }

  /**
   * @description Method to store new preferences
   *
   * @returns {Object} response object
   */
  static async storePreferences(newPreferences) {
    let response;
    try {
      response = await storeUserPreferences(newPreferences);
    } catch (error) {
      log.error(
        `PackagesPreferences.storePreferences(): Unable to save preferences to back-end. Error:`,
        error
      );
      return Promise.reject(error);
    }
    if (response?.data === 'SUCCESS') {
      this.preferences = cloneDeep(newPreferences);
    }
    return response;
  }

  /**
   * @description Method to store new preferences
   *
   * @returns {Object} response object
   * @throws {Error} if call to fetch org preferences fails
   */
  static async storeOrgPreferences(newPreferences) {
    let response;
    try {
      response = await storeOrgPreferences(newPreferences);
    } catch (error) {
      log.error(
        `PackagesPreferences.storeOrgPreferences(): Unable to save preferences for org to back-end. Error:`,
        error
      );
      throw error;
    }
    if (response?.data === 'SUCCESS') {
      this.orgPreferences = cloneDeep(newPreferences);
    }
    return response;
  }

  /**
   * @description Method to get current preferences
   *
   * @returns {Object} preferences object
   */

  static get transformedPreferences() {
    return {
      ausstFallbackEnabled: !!this.preferences?.ausstFallbackEnabled,
      ausstOverrideMac: this.preferences?.ausstOverrideMac ?? '',
      ausstOverrideWin: this.preferences?.ausstOverrideWin ?? '',
      showUnsupportedVersions: !!this.preferences?.showUnsupportedVersions,
      subscribed: !!this.preferences?.subscribed,
      userInstallDirectoryMac: this.preferences?.userInstallDirectoryMac ?? '',
      userInstallDirectoryWin: this.preferences?.userInstallDirectoryWin ?? '',
    };
  }

  /**
   * @description Method to get current org preferences
   *
   * @returns {Object} org preferences object
   */

  static get transformedOrgPreferences() {
    return {
      consentValue: !!this.orgPreferences?.consentValue,
      country: this.orgPreferences?.country ?? '',
      organizationMarketSegment: this.orgPreferences?.organizationMarketSegment ?? '',
      orgId: this.orgPreferences?.orgId ?? '',
    };
  }
}

export default PackagesPreferencesService;
