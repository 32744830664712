const MANAGE_PLAN_CONSTANTS = {
  PAID_CANCEL_PRODUCT_DETAILS: 'paidCancelProductDetails',
  SWITCH_PLAN_PRODUCT_DETAILS: 'switchPlanProductDetails',
};

const NON_SWITCHABLE_PRODUCTS = [
  'ccle_direct_indirect_team',
  'creative_cloud_all_apps_edu_team_direct_team',
  'creative_cloud_all_apps_stock_bundle_edu_t_direct_team',
  'ctsk_direct_indirect_team',
];

export {MANAGE_PLAN_CONSTANTS, NON_SWITCHABLE_PRODUCTS};
