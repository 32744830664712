import {authentication} from '@admin-tribe/acsc';
import axios from 'axios';

import API_CONSTANTS from 'common/api/ApiConstants';

import DEFAULT_TOPICS from '../defaultTopics';

const CLIENT_ID = API_CONSTANTS.CLIENT_ID;

const SEARCH_QUERY_MIN_LENGTH = 1;
let token = '';

/**
 * @description Method to construct and return request headers for API calls
 * @return {Object} representing request headers with bearer token
 */
const getHeaders = () => {
  const headers = {
    'Content-Type': 'application/vnd.adobe.search-request+json',
    'X-Api-Key': CLIENT_ID,
  };

  token = authentication.getAccessToken()?.token;

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  return headers;
};

/**
 * @description Method to obtain the locale code that helpx uses from the locale
 *   string that our application uses.
 * @param {string} locale - five character application locale code to use
 * @returns {string} two character locale code that can be used with helpx
 */
const getLocaleCodeFromLocale = (locale) => locale.split('-')[1].toLowerCase();

/**
 * @description Method to obtain a localized helpx URL root for making calls
 *   into helpx content while retaining your current language/locale
 * @param {string} locale - the current five character application locale code
 * @returns {string} localized helpx prefix for help content/links
 */
const getRootHelpUrl = (locale) => {
  const localeCode = getLocaleCodeFromLocale(locale);

  let result = 'https://helpx.adobe.com/';
  if (localeCode !== 'us') {
    result += `${localeCode}/`;
  }

  return result;
};

/**
 * @description Method to post and return search results
 * @param {string} searchApiUrl - URL of search endpoint
 * @param {string} qParams - additional data for api call
 * @returns {Object[]} representing the searched help topics (matches)
 */
const getApiResult = async (searchApiUrl, qParams) => {
  const searchResult = await axios.post(searchApiUrl, qParams, {
    headers: getHeaders(),
  });

  return searchResult;
};

/**
 * @description Method to retrieve a list of default search topics
 * @param {Object} intl - reference to intl for translations
 * @param {string} locale - the current five character application locale code
 * @returns {Object[]} representing the default, browseable help search topics
 */
const getDefaultTopics = (intl, locale) => {
  const result = [];
  result[0] = {
    children: [],
    id: DEFAULT_TOPICS[0].id,
    title: intl.formatMessage({id: DEFAULT_TOPICS[0].titleKey}),
  };

  DEFAULT_TOPICS[0].children.forEach((topic, index) => {
    result[0].children[index] = {
      id: topic.id,
      link: `${getRootHelpUrl(locale)}${topic.link}`,
      title: intl.formatMessage({id: topic.titleKey}),
    };
  });

  return result;
};

/**
 * @description Method to determine if the search query meets minimum length
 *   requirements
 * @param {string} value - current search query to test
 * @returns {boolean} true if search input is not long enough, else false
 */
const isSearchInputNotMinLength = (value) => value.length < SEARCH_QUERY_MIN_LENGTH;

export {
  getHeaders,
  getLocaleCodeFromLocale,
  getRootHelpUrl,
  getApiResult,
  getDefaultTopics,
  isSearchInputNotMinLength,
};
