import {feature} from '@admin-tribe/acsc';
import {ModalDialog, OverlayWait, showError, showSuccess, useStore} from '@admin-tribe/acsc-ui';
import {
  Button,
  ButtonGroup,
  Content,
  DialogContainer,
  Divider,
  Flex,
  Heading,
  useDialogContainer,
} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, {useEffect, useMemo, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import StepItem from 'common/components/stepped-view/StepItem';
import SteppedView from 'common/components/stepped-view/SteppedView';
import {AUTH_SOURCE_TYPES} from 'features/settings/api/ims-federated';
import SaveChangesAlertModal from 'features/settings/common/components/save-changes-alert-modal/SaveChangesAlertModal';
import CancelJitSetupWarningModal from 'features/settings/components/directories/create-directory-modal/cancel-jit-setup-warning-modal/CancelJitSetupWarningModal';
import {useDirectoryListContext} from 'features/settings/components/directories/directory-list/DirectoryListContext';
import {DirectorySetupContextProvider} from 'features/settings/components/directory/DirectorySetupContext';
import SetupDirectoryAuthentication from 'features/settings/components/setup-directory-authentication/SetupDirectoryAuthentication';
import SetupDirectoryAuthenticationSteps from 'features/settings/components/setup-directory-authentication/SetupDirectoryAuthenticationSteps';
import {IDP_TYPES} from 'features/settings/entities/IdpEntity';
import useDirectorySetup from 'features/settings/hooks/directory-setup/useDirectorySetup';
import {goToDirectoryDetails} from 'features/settings/routing/navigation-callbacks/navigationCallbacks';
import DirectorySetupStore, {
  SETUP_AUTHENTICATION_STEPS,
  SETUP_SECTIONS,
} from 'features/settings/stores/DirectorySetupStore';
import DirectoryStore from 'features/settings/stores/DirectoryStore';

import CreateDirectorySection from './create-directory-section/CreateDirectorySection';
import SelectIdpSection from './select-idp-section/SelectIdpSection';

const MODAL_ID = 'create-directory-modal';

const CreateDirectoryModal = observer(({clearDirectoryCache, goToSetupDomains}) => {
  const dialog = useDialogContainer();
  const intl = useIntl();
  const {loadDirectoryList} = useDirectoryListContext();
  const directoryStore = useStore(() => new DirectoryStore());
  const directorySetupStore = useStore(() => new DirectorySetupStore(directoryStore));
  const {
    createIdp,
    ctaButtonLabelId,
    directoryInfo,
    setIdpInfo,
    idpInfo,
    isCtaButtonDisabled,
    loginToAzure,
    restoreAzureIdpCreation,
    saveIdp,
    saveJitSettings,
    sectionsSteps,
    sectionsStepManager,
    setupAuthenticationStepManager,
    setDirectoryInfo,
  } = useDirectorySetup(directorySetupStore, SETUP_SECTIONS.DIRECTORY_INFO.id);
  const [isCancelJitWarningModalVisible, setIsCancelJitWarningModal] = useState(false);
  const [isSaveChangesModalVisible, setSaveChangesModalVisible] = useState(false);

  const modalTitleId = useMemo(() => {
    if (sectionsStepManager.currentStep.id === SETUP_SECTIONS.DIRECTORY_INFO.id) {
      return 'settings.directories.createDirectoryModal.createDirectoryTitle';
    }

    if (sectionsStepManager.currentStep.id === SETUP_SECTIONS.SELECT_IDP.id) {
      return 'settings.directories.createDirectoryModal.selectIdpTitle';
    }

    // return the simple "Create directory" title if there's an unknown step
    return 'settings.directories.createDirectoryModal.createDirectoryTitle';
  }, [sectionsStepManager.currentStep.id]);

  // Continue with azure IdP setup if we're coming back from an azure login
  useEffect(() => {
    const [restoredAzureIdpCreation, stateData] = restoreAzureIdpCreation();

    if (restoredAzureIdpCreation) {
      directoryStore.setDirectoryId(stateData.directoryId);
      directoryStore.setDirectoryName(stateData.directoryName);

      // go to setup authentication UI
      sectionsStepManager.goToStep(SETUP_SECTIONS.SETUP_AUTHENTICATION);
    }
  }, [directoryStore, restoreAzureIdpCreation, sectionsStepManager]);

  const createDirectory = async () => {
    try {
      await directorySetupStore.createDirectory(directoryInfo);

      clearDirectoryCache();

      // We're doing this for now because of the temp_directory_list_refactor. The optional chaining operator (?)
      // should be removed when the flag is removed
      loadDirectoryList?.({}, {updateCount: true});
      showSuccess(
        intl.formatMessage({
          id: 'settings.directories.createDirectoryModal.toasts.createdDirectory',
        })
      );

      if (directoryInfo.type === AUTH_SOURCE_TYPES.FEDERATED) {
        sectionsStepManager.goToStep(SETUP_SECTIONS.SELECT_IDP);
      } else {
        goToDirectoryDetails(directorySetupStore.directoryStore.directoryId);
      }
    } catch (error) {
      showError(
        intl.formatMessage({
          id: 'settings.directories.createDirectoryModal.toasts.errorCreatingDirectory',
        })
      );
    }
  };

  const onCreateIdp = async () => {
    if (idpInfo.type === IDP_TYPES.SAML) {
      const isSuccessful = await createIdp();

      if (isSuccessful) {
        sectionsStepManager.goNext();
      }
    }

    if (idpInfo.type === IDP_TYPES.AZURE) {
      loginToAzure();
    }
  };

  const onConfigureIdp = async () => {
    const isSuccessful = await saveIdp();

    if (isSuccessful) {
      setupAuthenticationStepManager.goNext();
    }
  };

  const onSaveJitConfig = async () => {
    const isSuccessful = await saveJitSettings();

    if (isSuccessful) {
      dialog.dismiss();

      // Continue setup in domains tab
      goToSetupDomains(directorySetupStore.directoryStore.directoryId);
    }
  };

  const onCta = () => {
    if (sectionsStepManager.currentStep.id === SETUP_SECTIONS.DIRECTORY_INFO.id) {
      createDirectory();
    }

    if (sectionsStepManager.currentStep.id === SETUP_SECTIONS.SELECT_IDP.id) {
      onCreateIdp();
    }

    if (sectionsStepManager.currentStep.id === SETUP_SECTIONS.SETUP_AUTHENTICATION.id) {
      if (
        setupAuthenticationStepManager.currentStep.id === SETUP_AUTHENTICATION_STEPS.SETUP_IDP.id
      ) {
        onConfigureIdp();
      }

      if (
        setupAuthenticationStepManager.currentStep.id === SETUP_AUTHENTICATION_STEPS.SETUP_JIT.id
      ) {
        onSaveJitConfig();
      }
    }
  };

  const onConfirmSaveChanges = () => {
    setSaveChangesModalVisible(false);
    onCta();
  };

  const onCancelSaveChanges = () => {
    setSaveChangesModalVisible(false);
    dialog.dismiss();
  };

  const onCancel = () => {
    if (
      sectionsStepManager.currentStep.id === SETUP_SECTIONS.SETUP_AUTHENTICATION.id &&
      (directorySetupStore.hasJitChanges ||
        (directorySetupStore.hasIdpChanges && !isCtaButtonDisabled))
    ) {
      setSaveChangesModalVisible(true);
    } else {
      if (
        (setupAuthenticationStepManager.currentStep.id ===
          SETUP_AUTHENTICATION_STEPS.SETUP_JIT.id &&
          directorySetupStore.isCancelingOutOfJitConfig) ||
        (setupAuthenticationStepManager.currentStep.id ===
          SETUP_AUTHENTICATION_STEPS.SETUP_IDP.id &&
          directorySetupStore?.idp?.isSaml)
      ) {
        setIsCancelJitWarningModal(true);
        return;
      }
      dialog.dismiss();
    }
  };

  return (
    <>
      <ModalDialog id={MODAL_ID} minHeight="80vh">
        <Heading data-test-id="modal-heading" level={2} marginBottom="size-200">
          <FormattedMessage id={modalTitleId} />
        </Heading>
        <Divider />

        <Content data-testid="modal-content">
          <DirectorySetupContextProvider directorySetupStore={directorySetupStore}>
            <OverlayWait isLoading={directorySetupStore.isLoading} showContent>
              <SteppedView currentStep={sectionsStepManager.currentStep.id} steps={sectionsSteps}>
                <StepItem id={SETUP_SECTIONS.DIRECTORY_INFO.id}>
                  <CreateDirectorySection
                    onDirectoryNameChange={(name) => setDirectoryInfo({...directoryInfo, name})}
                    onSelectDirectoryType={(type) => setDirectoryInfo({...directoryInfo, type})}
                  />
                </StepItem>
                <StepItem id={SETUP_SECTIONS.SELECT_IDP.id}>
                  <SelectIdpSection onSelectIdp={setIdpInfo} />
                </StepItem>
                <StepItem id={SETUP_SECTIONS.SETUP_AUTHENTICATION.id}>
                  {feature.isEnabled(`temp_saml_certs_wizard`) && (
                    <SetupDirectoryAuthenticationSteps
                      currentStep={setupAuthenticationStepManager.currentStep}
                      isGoogleSelected={idpInfo.isGoogleSelected}
                      isStepListInteractive={false}
                    />
                  )}

                  {feature.isDisabled('temp_saml_certs_wizard') && (
                    <SetupDirectoryAuthentication
                      currentStep={setupAuthenticationStepManager.currentStep}
                      isGoogleSelected={idpInfo.isGoogleSelected}
                    />
                  )}
                </StepItem>
              </SteppedView>
            </OverlayWait>
          </DirectorySetupContextProvider>
        </Content>

        <ButtonGroup>
          <Flex flex={1}>
            {sectionsStepManager.currentStep.id === SETUP_SECTIONS.SETUP_AUTHENTICATION.id &&
              idpInfo.type !== IDP_TYPES.AZURE && (
                <Button
                  data-test-id="previous-btn"
                  isDisabled={!setupAuthenticationStepManager.canGoPrevious()}
                  onPress={() => setupAuthenticationStepManager.goPrevious()}
                  variant="primary"
                >
                  <FormattedMessage id="settings.directories.createDirectoryModal.buttons.previous" />
                </Button>
              )}
          </Flex>

          <Flex flex={1} justifyContent="end">
            <Button data-test-id="cancel-btn" onPress={onCancel} variant="primary">
              <FormattedMessage id="settings.directories.createDirectoryModal.buttons.cancel" />
            </Button>

            <Button
              data-test-id="cta-btn"
              isDisabled={isCtaButtonDisabled}
              onPress={onCta}
              variant="accent"
            >
              <FormattedMessage id={ctaButtonLabelId} />
            </Button>
          </Flex>
        </ButtonGroup>
      </ModalDialog>

      <DialogContainer
        data-testid="cancel-jit-warning-modal-container"
        onDismiss={() => setIsCancelJitWarningModal(false)}
      >
        {isCancelJitWarningModalVisible && (
          <CancelJitSetupWarningModal onConfirm={() => dialog.dismiss()} />
        )}
      </DialogContainer>

      <DialogContainer data-testid="save-changes-modal-container" onDismiss={onCancelSaveChanges}>
        {isSaveChangesModalVisible && <SaveChangesAlertModal onConfirm={onConfirmSaveChanges} />}
      </DialogContainer>
    </>
  );
});

CreateDirectoryModal.propTypes = {
  clearDirectoryCache: PropTypes.func,
};

export default CreateDirectoryModal;
