import {feature} from '@admin-tribe/acsc';

import {activeOrgHasESM} from 'core/storage/access/storageAccess';

/**
 * Determines if the admin is able to view the "Project policies" page under Settings. Conditions below:
 *    1. Requires ESM repo
 *    2. temp_shared_project_policies is enabled (this check is being done here
 *         rather than in the routes file because of additional flags that are being checked)
 *
 * This canViewSharedProjects can be replaced by the canViewSharedProjects in storageAccess.js
 * once both temp_storage_shared_projects and temp_shared_project_policies flags are removed,
 * and this file can be deleted.
 *
 * @returns {Promise<Boolean>} whether or not the user has the necessary permissions to view workspaces
 */
function canViewSharedProjects() {
  return feature.isEnabled('temp_shared_project_policies')
    ? activeOrgHasESM()
    : Promise.resolve(false);
}

// eslint-disable-next-line import/prefer-default-export -- utils file
export {canViewSharedProjects};
