import {GoUrl, ImageIcon} from '@admin-tribe/acsc-ui';
import {Flex, Link, Text, View} from '@adobe/react-spectrum';
import DocumentIcon from '@spectrum-icons/workflow/Document';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import AddOnsMenu from 'features/packages/components/create-package-modal/screens/choose-apps/add-ons/AddOnsMenu';
import AddOnsSummary from 'features/packages/components/create-package-modal/screens/choose-apps/add-ons/add-ons-summary/AddOnsSummary';
import {CREATE_PACKAGE_CONSTANTS, PRODUCT_SAPCODES} from 'features/packages/packagesConstants';

import ProductActionsSection from './ProductActionsSection';
import styles from './ProductsList.pcss';

// ProductsList contains rows of products, in each row there are 3 sections
// 1.Product Icon 2.Product Name, Version and relevant links 3.Actions Section
const ProductsList = ({
  styleOverride = null,
  isAvailableProductsListStyling = false,
  onAddProduct,
  onRemoveProduct,
  packageCreateObj,
  products,
  setSelectedProductAddOnsMap,
  selectedAddOnsMap,
  showAddButton = false,
  showAppAddOns = false,
  showLearnMoreLink = false,
  showLTSLink = false,
  showRemoveButton = false,
  showOnlyAppAddOnsSummary = false,
  showSysReqLink = false,
}) => {
  const intl = useIntl();

  function showLTSLinkForProduct(product) {
    return showLTSLink && product.isMaintenanceBuild;
  }

  function showSysReqLinkForProduct(product) {
    return showSysReqLink && product.hasSpecialSystemRequirements();
  }

  function getPreSelectedAddOns() {
    return packageCreateObj ? packageCreateObj.selectedProductAddOnsMap : [];
  }

  function showAcrobatBits(product) {
    return product.sapCode === PRODUCT_SAPCODES.APRO;
  }

  function getProductBits(product) {
    switch (product.platform) {
      case 'win32':
      case 'osx10':
        return 32;
      default:
        return 64;
    }
  }

  const getAcrobatBitsInfo = (product) =>
    showAcrobatBits(product) && (
      <Text
        marginStart="size-100"
        UNSAFE_style={{fontSize: 'var(--spectrum-global-dimension-size-150)'}}
      >
        <GoUrl data-testid="acrobat-bits" name="ent_sys_req_acrobat" variant="secondary">
          {intl.formatMessage(
            {
              id: 'packages.createPackage.chooseApps.appList.bits',
            },
            {bits: getProductBits(product)}
          )}
        </GoUrl>
      </Text>
    );

  const getLTSLinkForProduct = (product) =>
    showLTSLinkForProduct(product) && (
      <Text
        marginStart="size-100"
        UNSAFE_style={{fontSize: 'var(--spectrum-global-dimension-size-150)'}}
      >
        <GoUrl name="ent_pref_unsupported_version" variant="secondary">
          {intl.formatMessage({
            id: 'packages.createPackage.chooseApps.appList.LTSLink',
          })}
        </GoUrl>
      </Text>
    );

  const getSysReqLinkForProduct = (product) =>
    showSysReqLinkForProduct(product) && (
      <Text
        marginStart="size-100"
        UNSAFE_style={{fontSize: 'var(--spectrum-global-dimension-size-150)'}}
      >
        <Link variant="secondary">
          <a href={product.getSystemRequirementUrl()} rel="noreferrer" target="_blank">
            {intl.formatMessage({
              id: 'packages.createPackage.chooseApps.appList.SysReqLink',
            })}
          </a>
        </Link>
      </Text>
    );

  return (
    // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- styling for reusing this component
    <Flex direction="column" UNSAFE_className={styleOverride}>
      {products.map((product) => (
        <Flex
          key={product.product_key}
          direction="column"
          // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- styling required on products list
          UNSAFE_className={
            isAvailableProductsListStyling
              ? styles['available-products-list-row']
              : styles['selected-products-list-row']
          }
        >
          <Flex alignItems="center">
            <Flex alignItems="center" direction="row" flexGrow={1} width="size-3600">
              {/* Product Icon */}
              <Flex>
                {product.sapCode === CREATE_PACKAGE_CONSTANTS.LICENSE_FILE_SAP_CODE ? (
                  <DocumentIcon size="M" />
                ) : (
                  <ImageIcon alt="" size="M" src={product.getIconUrl()} />
                )}
              </Flex>
              {/* Product name, version and relevant links*/}
              <Flex direction="column">
                <View marginStart="size-100">
                  <Text
                    UNSAFE_style={{
                      fontWeight: 'bold',
                    }}
                  >
                    {product.name}
                  </Text>
                  {product.version && <Text marginStart="size-100">({product.version})</Text>}
                </View>
                {getLTSLinkForProduct(product)}
                {getSysReqLinkForProduct(product)}
                {getAcrobatBitsInfo(product)}
              </Flex>
            </Flex>
            {/* Actions section */}
            <ProductActionsSection
              onAddProduct={onAddProduct}
              onRemoveProduct={onRemoveProduct}
              product={product}
              showAddButton={showAddButton}
              showLearnMoreLink={showLearnMoreLink}
              showRemoveButton={showRemoveButton}
            />
          </Flex>
          <Flex>
            {showAppAddOns && showRemoveButton && product.modules && (
              <Flex direction="row" marginStart="30px">
                <AddOnsMenu
                  data-testid="add-ons"
                  preSelectedAddOns={getPreSelectedAddOns()}
                  productAddOns={product.modules}
                  productKey={product.product_key}
                  setSelectedProductAddOnsMap={setSelectedProductAddOnsMap}
                />
              </Flex>
            )}
          </Flex>
          {showOnlyAppAddOnsSummary && selectedAddOnsMap[product.product_key]?.length > 0 && (
            <View marginStart="size-400">
              <AddOnsSummary selectedAddOnsList={selectedAddOnsMap[product.product_key]} />
            </View>
          )}
        </Flex>
      ))}
    </Flex>
  );
};

ProductsList.propTypes = {
  /**
   * Indicates if available products list type styling to be applied. Defaults to false.
   */
  isAvailableProductsListStyling: PropTypes.bool,
  /**
   * Method to add product.
   */
  onAddProduct: PropTypes.func,
  /**
   * Method to remove product.
   */
  onRemoveProduct: PropTypes.func,
  /**
   * Package Create Object, needed for pre-selected addOns
   */
  packageCreateObj: PropTypes.shape({
    selectedProductAddOnsMap: PropTypes.shape({
      displayName: PropTypes.string,
      id: PropTypes.string,
    }),
  }),
  /**
   * List of products to display
   */
  products: PropTypes.instanceOf(Array).isRequired,
  /**
   * Selected AddOns map, needed while showing AddOns Summary on Finalize Screen
   */
  selectedAddOnsMap: PropTypes.shape({
    displayName: PropTypes.string,
    id: PropTypes.string,
  }),
  /**
   * func to set selectedProductAddOnsMap in Package Create Object
   */
  setSelectedProductAddOnsMap: PropTypes.func,
  /**
   * If add button should be visible. Defaults to false.
   */
  showAddButton: PropTypes.bool,
  /**
   * If app add-ons optionn should be visible. Defaults to false.
   */
  showAppAddOns: PropTypes.bool,
  /**
   * If learn more links for products should be visible. Defaults to false.
   */
  showLearnMoreLink: PropTypes.bool,
  /**
   * If LTS links for products should be visible. Defaults to false.
   */
  showLTSLink: PropTypes.bool,
  /**
   * If AddOnsSummary to be shown on Finalize Screen, Defaults to false.
   */
  showOnlyAppAddOnsSummary: PropTypes.bool,
  /**
   * If remove button should be visible. Defaults to false.
   */
  showRemoveButton: PropTypes.bool,
  /**
   * If system requirements links for products should be visible. Defaults to false.
   */
  showSysReqLink: PropTypes.bool,
  /**
   * To add additional style in order to reuse this component
   */
  styleOverride: PropTypes.string,
};

export default ProductsList;
