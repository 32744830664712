import {LicenseGroup, Product} from '@admin-tribe/acsc';
import {Rule} from '@pandora/data-model-acrs';
import {AutoProductAssignmentModal} from '@pandora/react-auto-product-assignment-modal';
import {ModalContainer} from '@pandora/react-modal-dialog';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';

import rootStore from 'core/RootStore';
import useRuleDetailsList from 'features/products/hooks/useRuleDetailsList';

/**
 * Renders the auto-assignment-rule modal to create/edit a jit rule.
 *
 * Note: Since this uses the useRuleDetailsList hook, just calling this component, whether
 * or not the modal is open will make the API call to get the rules.
 */
const AutoAssignmentRuleModal = ({
  onRuleAssignmentCancel,
  onRuleAssignmentSave,
  onShowPageLoading,
  product,
  productProfile,
  rule,
}) => {
  const orgId = rootStore.organizationStore.activeOrgId;

  const {directories, isLoading, ruleDetailsList} = useRuleDetailsList();

  // While loading, display a spinner over the page. The other alternative is to
  // use the modal's isLoading but AutoProductAssignmentModal wasn't coded for that.
  useEffect(() => {
    onShowPageLoading(isLoading);
  }, [isLoading, onShowPageLoading]);

  // Pass the modal the product and profile so that the modal skips the product and profile selection steps.
  const ruleDetails = {
    licenseId: product.id,
    orgId,
    product,
    productProfile: productProfile.id,
    productProfileName: productProfile.name,
    ...rule,
  };

  return (
    !isLoading && (
      <ModalContainer>
        <AutoProductAssignmentModal
          directories={directories}
          onCancel={onRuleAssignmentCancel}
          onComplete={onRuleAssignmentSave}
          orgId={orgId}
          ruleDetails={ruleDetails}
          ruleDetailsList={ruleDetailsList}
          skipProductProfileSelection
        />
      </ModalContainer>
    )
  );
};

AutoAssignmentRuleModal.propTypes = {
  /**
   * The callback to invoke after cancelling Automatic assignment rule.
   */
  onRuleAssignmentCancel: PropTypes.func.isRequired,
  /**
   * The callback to invoke after saving Automatic assignment rule.
   */
  onRuleAssignmentSave: PropTypes.func.isRequired,
  /**
   * The callback to invoke when isLoading changes.
   * This notifies the caller that the PageWait should be shown or not.
   */
  onShowPageLoading: PropTypes.func.isRequired,
  /**
   * The product associated with the AutoAssignmentRule.
   */
  product: PropTypes.instanceOf(Product).isRequired,
  /**
   * The product profile associated with the AutoAssignmentRule.
   */
  productProfile: PropTypes.instanceOf(LicenseGroup).isRequired,
  /**
   * The rule associated with the product and profile.
   * If there is no rule, productProfileRule will be an empty object.
   */
  rule: PropTypes.oneOfType([PropTypes.instanceOf(Rule), PropTypes.object]),
};

export default AutoAssignmentRuleModal;
