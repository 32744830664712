import {PAGE_TARGET_TYPE, PageContext, download, log} from '@admin-tribe/acsc';
import {Pill, showInfo} from '@admin-tribe/acsc-ui';
import {
  ActionButton,
  Flex,
  Heading,
  Item,
  Menu,
  MenuTrigger,
  Section,
  Text,
  View,
} from '@adobe/react-spectrum';
import {useContentEntry} from '@pandora/react-content-provider';
import {FileDropZone, FileDropZoneContentModel} from '@pandora/react-file-drop-zone';
import SpectrumV2Provider from '@react/react-spectrum/Provider';
import AttachIcon from '@spectrum-icons/workflow/Attach';
import DownloadIcon from '@spectrum-icons/workflow/Download';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import rootStore from 'core/RootStore';

import useBulkOperationSamples from '../bulk-operation-hooks/useBulkOperationSamples';
import {BULK_OPERATION_MODE} from '../bulk-operation-utils/bulkOperationConstants';
import {
  downloadUserGroupList,
  downloadUserList,
  localizeAndDownload,
} from '../bulk-operation-utils/bulkOperationUtils';

import styles from './BulkOperationForm.pcss';
import {
  CURRENT_USER_GROUP_LIST_KEY,
  CURRENT_USER_LIST_KEY,
  DOMAIN_USER_LIST_KEY_PREFIX,
  STANDARD_TEMPLATE_KEY,
} from './bulkOperationFormConstants';

const DOMAIN_SECTION_ID = 'domain-user-lists-section';
const MEMBER_SECTION_ID = 'member-list-section';
const STANDARD_TEMPLATE_SECTION_ID = 'standard-template-section';

// Accept Excel MIME types because Excel can change a .csv file's
// MIME type without changing the file extension.
const ACCEPTED_TYPES = [
  'text/csv',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

/**
 * @description the BulkOperationForm used for uploading and downloading CSVs.
 */
const BulkOperationForm = ({
  addFiles,
  bulkOperationState,
  children,
  mode,
  pageContext,
  removeFiles,
  selectedFiles,
  showPageBanner,
}) => {
  const intl = useIntl();
  const {exportFunction, exportParams} = bulkOperationState;

  const {domains, sampleTemplate} = useBulkOperationSamples({
    mode,
    orgId: rootStore.organizationStore.activeOrgId,
    pageContext,
  });

  // Build the CSV download menu items.
  const menuItems = useMemo(() => {
    // Member (user/user group) CSV download menu items to be hydrated later
    const memberCsvTemplateItems = [];

    // Init basic menu skeleton state
    const menuParams = [
      // Section 1: Always show the standard CSV template with dummy data when ready
      sampleTemplate
        ? {
            children: [
              {
                id: STANDARD_TEMPLATE_KEY,
                name: intl.formatMessage({
                  id: 'common.bulkOperation.bulkOperationForm.menuItem.standardTemplate',
                }),
              },
            ],
            id: STANDARD_TEMPLATE_SECTION_ID,
            name: intl.formatMessage({
              id: 'common.bulkOperation.bulkOperationForm.menuHeading.template',
            }),
          }
        : undefined,
      // Section 2: Show applicable Member (user or user group) CSV templates
      {
        children: memberCsvTemplateItems,
        id: MEMBER_SECTION_ID,
        name:
          pageContext.targetType === PAGE_TARGET_TYPE.USER_GROUP
            ? intl.formatMessage({
                id: 'common.bulkOperation.bulkOperationForm.menuHeading.userGroupList',
              })
            : // else: targetType === PAGE_TARGET_TYPE.USER
              intl.formatMessage({
                id: 'common.bulkOperation.bulkOperationForm.menuHeading.userList',
              }),
      },
    ];

    // Hydrate applicable menu items
    if (pageContext.targetType === PAGE_TARGET_TYPE.USER) {
      memberCsvTemplateItems.push({
        id: CURRENT_USER_LIST_KEY,
        name: intl.formatMessage({
          id: 'common.bulkOperation.bulkOperationForm.menuItem.users',
        }),
      });

      if (mode === BULK_OPERATION_MODE.SWITCH_IDENTITY_TYPE && domains) {
        const domainMenuItems = domains.map((domain) => ({
          id: `${DOMAIN_USER_LIST_KEY_PREFIX}${domain.domainName}`,
          name: intl.formatMessage(
            {
              id: 'common.bulkOperation.bulkOperationForm.menuItem.domainUsers',
            },
            {
              domainName: domain.domainName,
            }
          ),
        }));

        menuParams.push({
          // Section 3: Show Domain user lists
          children: domainMenuItems,
          id: DOMAIN_SECTION_ID,
          name: intl.formatMessage({
            id: 'common.bulkOperation.bulkOperationForm.menuHeading.domainUserLists',
          }),
        });
      }
    } else if (pageContext.targetType === PAGE_TARGET_TYPE.USER_GROUP) {
      memberCsvTemplateItems.push({
        id: CURRENT_USER_GROUP_LIST_KEY,
        name: intl.formatMessage({
          id: 'common.bulkOperation.bulkOperationForm.menuItem.userGroups',
        }),
      });
    }

    return menuParams;
  }, [domains, intl, mode, pageContext, sampleTemplate]);

  const downloadSampleTemplate = async () => {
    if (pageContext.targetType === PAGE_TARGET_TYPE.USER) {
      await localizeAndDownload(sampleTemplate);
    } else {
      download(sampleTemplate, 'sample.csv');
    }
  };

  const downloadUserListTemplate = (domainName) => {
    const onError = (error) => {
      showGenericDownloadError(showPageBanner);
      log.error('Failed to download users csv:', error);
    };

    showInfo(
      intl.formatMessage({
        id: 'common.bulkOperation.bulkOperationForm.csvProcessing.alert.info',
      })
    );

    downloadUserList({
      domainName,
      exportFunction,
      exportParams,
      onError,
      orgId: rootStore.organizationStore.activeOrgId,
    });
  };

  const downloadUserGroupListTemplate = () => {
    showInfo(
      intl.formatMessage({
        id: 'common.bulkOperation.bulkOperationForm.csvProcessing.alert.info',
      })
    );

    downloadUserGroupList({
      exportFunction,
      exportParams,
      onError: (error) => {
        showGenericDownloadError(showPageBanner);
        log.error('Failed to download user groups csv:', error);
      },
      orgId: rootStore.organizationStore.activeOrgId,
    });
  };

  const onDownloadSample = async (key) => {
    // reset any page banner errors
    showPageBanner();

    if (key === CURRENT_USER_LIST_KEY) {
      await downloadUserListTemplate();
    } else if (key === CURRENT_USER_GROUP_LIST_KEY) {
      await downloadUserGroupListTemplate();
    } else if (key.includes(DOMAIN_USER_LIST_KEY_PREFIX)) {
      const domainName = key.split(DOMAIN_USER_LIST_KEY_PREFIX)[1];
      await downloadUserListTemplate(domainName);
    } else {
      // else key === STANDARD_TEMPLATE_KEY
      downloadSampleTemplate();
    }
  };

  const translateSize = (bytes) => {
    let i = 0;
    let size = 0;
    if (bytes > 0) {
      i = Math.floor(Math.log(bytes) / Math.log(1000));
      size = Number.parseFloat((bytes / 1000 ** i).toFixed(2));
    }
    const sizeKeys = ['bytes', 'kb', 'mb', 'gb', 'tb', 'pb', 'eb', 'zb', 'yb'];
    return intl.formatMessage(
      {
        id: `common.bulkOperation.bulkOperationForm.size.${sizeKeys[i]}`,
      },
      {
        size,
      }
    );
  };

  const content = useContentEntry(FileDropZoneContentModel);

  return (
    <>
      <View>{children}</View>
      <View marginTop="size-200">
        <MenuTrigger>
          <ActionButton data-testid="download-templates-menu-button">
            <DownloadIcon />
            <Text>
              <FormattedMessage id="common.bulkOperation.bulkOperationForm.button.downloadTemplate" />
            </Text>
          </ActionButton>
          <Menu items={menuItems} onAction={(key) => onDownloadSample(key)}>
            {(section) => (
              <Section items={section.children} title={section.name}>
                {(item) => (
                  <Item textValue={item.name}>
                    <Text data-testid={`download-menu-item-${item.id.toLowerCase()}`}>
                      {item.name}
                    </Text>
                  </Item>
                )}
              </Section>
            )}
          </Menu>
        </MenuTrigger>
        <View data-testid="attached-files">
          {selectedFiles.length > 0 && (
            <>
              <Heading level="3">
                <FormattedMessage id="common.bulkOperation.bulkOperationForm.heading.attachedFiles" />
              </Heading>
              <Flex direction="row" gap="size-50" justifyContent="start" wrap>
                {selectedFiles.map((file) => (
                  <Pill
                    key={`${file.name}-${file.lastModified}`}
                    closeButtonAriaLabel={intl.formatMessage(
                      {
                        id: 'common.bulkOperation.bulkOperationForm.pill.removeAttachment',
                      },
                      {
                        fileName: file.name,
                      }
                    )}
                    closeButtonTooltipText={intl.formatMessage(
                      {
                        id: 'common.bulkOperation.bulkOperationForm.pill.removeAttachment',
                      },
                      {
                        fileName: file.name,
                      }
                    )}
                    header={file.name}
                    IconComponent={<AttachIcon />}
                    iconVariant="circle"
                    onDismiss={() => {
                      // reset any page banner errors
                      showPageBanner();

                      removeFiles([file]);
                    }}
                    pillWidth="316px"
                    subtitle={translateSize(file.size)}
                  />
                ))}
              </Flex>
            </>
          )}
        </View>
        <Flex data-testid="file-drop-zone" direction="column" marginTop="size-200">
          {/* This V2 Provider is needed so FileDropZone has proper styling. */}
          <SpectrumV2Provider>
            <FileDropZone
              acceptedTypes={ACCEPTED_TYPES}
              className={styles['drop-zone']}
              content={content}
              headingLevel={3}
              multiple
              onFileAdded={(fileList) => {
                // reset any page banner errors
                showPageBanner();

                addFiles(convertFileListToArrayOfFiles(fileList));
              }}
              showIcon={selectedFiles.length === 0}
            />
          </SpectrumV2Provider>
        </Flex>
      </View>
    </>
  );
};

BulkOperationForm.propTypes = {
  /**
   * The function for adding files to selectedFiles
   */
  addFiles: PropTypes.func,
  /**
   * Object wrapping export function and export params
   */
  bulkOperationState: PropTypes.shape({
    exportFunction: PropTypes.func,
    exportParams: PropTypes.shape({
      licenseGroupId: PropTypes.string,
      productId: PropTypes.string,
      userGroupId: PropTypes.string,
    }),
  }),
  /**
   * Children components
   */
  children: PropTypes.node,
  /**
   * The BULK_OPERATION_MODE that will determine the modal content.
   */
  mode: PropTypes.oneOf(Object.values(BULK_OPERATION_MODE)),
  /**
   * The PageContext object used to determine the type of bulk operation
   */
  pageContext: PropTypes.instanceOf(PageContext),
  /**
   * The function for removing files from selectedFiles
   */
  removeFiles: PropTypes.func,
  /**
   * The array of files that are selected
   */
  selectedFiles: PropTypes.arrayOf(PropTypes.instanceOf(File)),
  /**
   * The setter function to set the page banner error
   */
  showPageBanner: PropTypes.func,
};

/**
 *
 * @param {FileList} fileList - The FileList object
 * @returns {Array<File>} - an array of Files
 */
function convertFileListToArrayOfFiles(fileList) {
  return [...fileList];
}

function showGenericDownloadError(showPageBanner) {
  showPageBanner({
    children: {
      id: 'common.bulkOperation.bulkOperationForm.download.pageBanner.error.children',
    },
    header: {
      id: 'common.bulkOperation.bulkOperationForm.download.pageBanner.error.header',
    },
    variant: 'error',
  });
}

export default BulkOperationForm;
