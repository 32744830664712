import {
  MEMBER_EVENT,
  MEMBER_TECH_ACCOUNT_DOMAIN,
  MemberList,
  OrganizationUser,
  PRODUCT_USER_LIST_CACHE_ID,
  PRODUCT_USER_LIST_EVENT,
  UserGroup,
  compareArrays,
  compareProductLicenseGroups,
  eventBus,
  explodeProductLicenseGroups,
  feature,
  getProductLicensePatches,
  jilProducts,
  jilUsers,
  log,
} from '@admin-tribe/acsc';
import union from 'lodash/union';

import {PRODUCT_USERS_API_QUERY_PARAMS} from './ProductUserListConstants';

class ProductUserList extends MemberList {
  /**
   * @description ProductUserList default constructor
   * @todo Move this module binky
   *
   * @param {Object}[options] - Constructor options
   * @param {String} [options.filterQuery] - Query to filter results against
   * @param {Number} [options.pageSize] - Number of items to display per page
   * @param {String} [options.productId] - Product ID
   */
  constructor(options) {
    super({
      filterExcludeDomain: MEMBER_TECH_ACCOUNT_DOMAIN,
      itemClassRef: [OrganizationUser, UserGroup],
      modelCacheId: PRODUCT_USER_LIST_CACHE_ID,
      refreshResource: jilProducts.getProductUsers,
      saveResource: jilUsers.patchUsers,
      transformResponseData,
      ...options,
    });

    this.productId = options.productId;
    this.modifiedUsers = [];
  }

  /**
   * @description Add list of modified users
   *
   * @param {Array<OrganizationUser>} users - List of modified users
   */
  addModifiedUsers(users) {
    this.modifiedUsers = union(this.modifiedUsers, users);
  }

  /**
   * @description Get params for api call to fetch list.
   *
   * @returns {Object} params to be passed to query
   */
  getQueryParams() {
    return {
      ...super.getQueryParams(),
      include: feature.isEnabled('temp_user_provisioning_status')
        ? [PRODUCT_USERS_API_QUERY_PARAMS.PROVISIONING_STATUS]
        : [],
      productId: this.productId,
    };
  }

  /**
   * @description Check whether there are unsaved changes
   *
   * @returns {Boolean} true if there are unsaved changes, false otherwise
   */
  hasUnsavedChanges() {
    return getModifiedUsers(this.modifiedUsers).length > 0 || super.hasUnsavedChanges();
  }

  /**
   * @description Method to execute after a successful refresh.
   */
  onRefreshSuccess() {
    eventBus.emit(PRODUCT_USER_LIST_EVENT.UPDATE.LIST, this.productId);
    if (this.shouldUpdateTotalItemCount()) {
      eventBus.emit(PRODUCT_USER_LIST_EVENT.UPDATE.COUNT, this.pagination.itemCount);
    }
  }

  /**
   * @description Save changes to user list to backend, and refreshes the list
   *
   * @returns {Promise} Resolves to the refreshed product user list
   */
  async save({refresh = true} = {}) {
    if (!this.hasUnsavedChanges()) {
      return this;
    }

    // First we check if users are being added, and fail if so
    // This list does not yet support add in that way
    if (this.addedItems.length > 0) {
      throw new Error('ProductUserList.save cannot be invoked with added people');
    }

    let operations = [];
    getModifiedUsers(this.modifiedUsers).forEach((modifiedUser) => {
      operations = union(operations, getProductLicensePatches(modifiedUser));
    });
    this.removedItems.forEach((member) => {
      const memberOps = member.licenseGroups.map((productConfiguration) => ({
        op: 'remove',
        path: `/${member.id}/products/${this.productId}/licenseGroups/${productConfiguration.id}`,
      }));
      operations = union(operations, memberOps);
    });

    try {
      await jilUsers.patchUsers({operations, orgId: this.orgId});
    } catch (error) {
      log.error(
        `ProductUserList.save(): Failed to save data to back-end. ${error.config.url} failed with ${error.response.status} : ${error.response.headers['X-Request-Id']}`
      );
      throw error;
    }

    union(this.removedItems, this.modifiedUsers).forEach((member) => {
      eventBus.emit(MEMBER_EVENT.UPDATE, member.id);
    });

    super.resetRemovedItems();
    this.modifiedUsers = [];
    this.filter.query = '';

    if (refresh) {
      try {
        await this.refresh();
      } catch (error) {
        log.error(
          `ProductUserList.save(): Failed to refresh data from back-end. ${error.config.url} failed with ${error.response.status} : ${error.response.headers['X-Request-Id']}`
        );
        throw error;
      }
    }

    return this;
  }
}

function getModifiedUsers(users) {
  return users.filter(
    (user) =>
      !compareArrays(
        explodeProductLicenseGroups(user.savedState.products),
        explodeProductLicenseGroups(user.products),
        compareProductLicenseGroups
      )
  );
}

function transformResponseData(responseData, classRefs) {
  return responseData.map((responseItem) => {
    const ClassRef = classRefs.find((ref) => ref.canTransform(responseItem));
    return new ClassRef(responseItem);
  });
}

export default ProductUserList;
