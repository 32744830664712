import accountRouteMigration from './accountRouteMigration';
import insightsRouteMigration from './insightsRouteMigration';
import overviewRouteMigration from './overviewRouteMigration';
import packagesRouteMigration from './packagesRouteMigration';
import productApproveMigration from './productApproveMigration';
import productsRouteMigration from './productsRouteMigration';
import settingsRouteMigration from './settingsRouteMigration';
import storageRouteMigration from './storageRouteMigration';
import supportRouteMigration from './supportRouteMigration';
import usersRouteMigration from './usersRouteMigration';

/**
 * A list that maps Angular state names to their resulting URLs. This is used
 * by the main App.jsx component to determine the shell to render.
 *
 * @see {stateName} Can be of type:
 * string: A single Angular state name
 * string[]: Multiple Angular state names
 *
 * @see {useReactRoute} Can be of type:
 * boolean: If true then use the React route
 * string: Assumes a feature flag name and will render the React route when the flag is true
 *
 * @see {url} Can be of type:
 * string: A URL to match
 * string[]: Multiple URLs to match, or aliases of the same route. If fetching this
 *           route by state name then the last URL in the list will be returned.
 *
 * @type {{stateName: string | string[], useReactRoute: boolean | string, url: string | string[]}[]}
 */
const routeMigration = [
  ...productApproveMigration,
  ...accountRouteMigration,
  ...overviewRouteMigration,
  ...productsRouteMigration,
  ...insightsRouteMigration,
  ...settingsRouteMigration,
  ...storageRouteMigration,
  ...supportRouteMigration,
  ...usersRouteMigration,
  ...packagesRouteMigration,
];

export default routeMigration;
