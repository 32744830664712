import binky from '@admin-tribe/acsc';
import {showInfo} from '@admin-tribe/acsc-ui';
import {useSupportTicketHook} from '@pandora/data-model-support-ticket';
import {
  SupportTicketDetailsDrawer,
  SupportTicketDetailsDrawerContentModel,
} from '@pandora/react-support-ticket';
import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';

const downloadUtils = binky.utils.downloadUtils;

/**
 * A widget to display the Support Ticket Details Drawer
 */
const SupportDetailsDrawer = ({
  attachments,
  description,
  proposedTime1,
  proposedTime2,
  proposedTime3,
  recordType,
  ticketId,
}) => {
  const intl = useIntl();
  const content = SupportTicketDetailsDrawerContentModel.createEntry({
    attachmentsSectionTitle: intl.formatMessage({
      id: 'support.supportTicketDetailsDrawer.attachmentsSectionTitle',
    }),
    buttonDetails: intl.formatMessage({
      id: 'support.supportTicketDetailsDrawer.buttonDetails',
    }),
    expertSessiondescriptionTitle: intl.formatMessage({
      id: 'support.supportTicketDetailsDrawer.expertSessiondescriptionTitle',
    }),
    noDescription: intl.formatMessage({
      id: 'support.supportTicketDetailsDrawer.noDescription',
    }),
    proposedTimesTitle: intl.formatMessage({
      id: 'support.supportTicketDetailsDrawer.proposedTimesTitle',
    }),
    supportCaseDescriptionTitle: intl.formatMessage({
      id: 'support.supportTicketDetailsDrawer.supportCaseDescriptionTitle',
    }),
    title: intl.formatMessage({
      id: 'support.supportTicketDetailsDrawer.title',
    }),
  });

  const orgId = rootStore.organizationStore.activeOrgId;
  const {downloadTicketAttachment} = useSupportTicketHook();

  const downloadFile = async (attachmentId, fileName) => {
    showInfo(
      intl.formatMessage({
        id: 'support.supportTicketDetailsDrawer.attachmentDownloadInfoMessage',
      })
    );
    const response = await downloadTicketAttachment({attachmentId, id: ticketId, orgId});
    downloadUtils.download(response, fileName);
  };

  return (
    <SupportTicketDetailsDrawer
      attachments={orderBy(attachments, 'createdOn', 'desc')}
      content={content}
      data-testid="ticket-details-drawer"
      description={description}
      downloadFile={downloadFile}
      proposedTimes={[proposedTime1, proposedTime2, proposedTime3]}
      recordType={recordType && recordType.toUpperCase()}
    />
  );
};

SupportDetailsDrawer.propTypes = {
  /**
   * The attachments is the array of attachments of the case.
   */
  // eslint-disable-next-line react/forbid-prop-types -- existing usage
  attachments: PropTypes.arrayOf(PropTypes.object),
  /**
   * The description is the description of the case.
   */
  description: PropTypes.string,
  /**
   * The proposedTime1 is the first proposedTime of the expert case only.
   */
  proposedTime1: PropTypes.string,
  /**
   * The proposedTime2 is the second proposedTime of the expert case only.
   */
  proposedTime2: PropTypes.string,
  /**
   * The proposedTime3 is the third proposedTime of the expert case only.
   */
  proposedTime3: PropTypes.string,
  /**
   * The recordType defines the type of case
   '1' is for SupportCase and '2' is for ExpertSession.
   */
  recordType: PropTypes.string.isRequired,
  /**
   * id represents the support ticket id.
   */
  ticketId: PropTypes.string.isRequired,
};

export default SupportDetailsDrawer;
