import {log} from '@admin-tribe/acsc';
import {useCallback, useState} from 'react';

import hendrix from 'features/account/billing-history/api/hendrix';

import {INVOICE_TYPES} from '../BillingHistoryConstants';

/**
 * @typedef {Object} emailInvoicesProps
 *
 * @property {String[]} emailList - List of pre-validated emails to send.
 * @property {Boolean} includePrimaryAdmin - Whether or not to include a copy of the email to the primary admin.
 * @property {Object} invoices - An object whose keys are externalContractIds and values are the list of associated invoices.
 *            e.g. {
 *                    externalContractId1: ['invoice1', 'invoice2'],
 *                    externalContractId2: ['invoice3']
 *                  }
 */

/**
 * @callback emailInvoicesCallback
 *
 * @param {emailInvoicesProps} options - object with the named parameters to be passed into the email invoices call.
 *
 * @returns {Promise} A promise that set the error and loading status of the hook.
 */

/**
 * @typedef {Object} useEmailInvoices
 *
 * @param {Contract} contract - The organization's contract
 * @property {Boolean} emailError - indicates whether or not an email call has error
 * @property {emailInvoicesCallback} emailInvoices - Calls the hendrix email invoices service
 * @property {Boolean} isEmailing - Whether or not emailing is in flight
 */

/**
 * @description Hook to send emails with the selected invoices
 *
 * @returns {useEmailInvoices} An object that contains call to email invoices and status indicators
 */
const useEmailInvoices = ({contract}) => {
  const rengaId = contract.ownerInfo.userId;

  const [isEmailing, setIsEmailing] = useState(false);
  const [emailError, setEmailError] = useState(undefined);

  const emailInvoices = useCallback(
    async ({emailList, includePrimaryAdmin, invoices}) => {
      setIsEmailing(true);
      setEmailError(undefined);

      try {
        const emailByExtContractId = ({type}) => {
          if (!invoices[type]) {
            return [];
          }
          return Object.entries(invoices[type]).map(([externalContractId, invoiceList]) =>
            hendrix.postEmailInvoices({
              emailList,
              includePrimaryAdmin,
              invoiceList,
              orderId: externalContractId,
              rengaId,
              type,
            })
          );
        };

        await Promise.all([
          ...emailByExtContractId({type: INVOICE_TYPES.INVOICE}),
          ...emailByExtContractId({type: INVOICE_TYPES.RECEIPT}),
          ...emailByExtContractId({type: INVOICE_TYPES.CREDIT}),
        ]);
      } catch (error) {
        log.error('Failed to email invoices.', error);
        setEmailError(error);
      } finally {
        setIsEmailing(false);
      }
    },
    [rengaId]
  );

  return {emailError, emailInvoices, isEmailing};
};

export default useEmailInvoices;
