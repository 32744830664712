import {EVENT_SOURCES, Store, eventBus} from '@admin-tribe/acsc';
import {
  TableConstants,
  TableStore,
  WIZARD_DISPATCH_ACTION,
  translateString,
} from '@admin-tribe/acsc-ui';
import cloneDeep from 'lodash/cloneDeep';
import {action, makeObservable, observable, runInAction} from 'mobx';

import rootStore from 'core/RootStore';
import CreateServerService from 'features/packages/services/CreateServerService';
import PackagesEntitlementsService from 'features/packages/services/PackagesEntitlementsService';
import PackagesLanServersService from 'features/packages/services/PackagesLanServersService';

import {
  CREATE_SERVER,
  SERVERS_NEXT_STEP_BANNER,
  SERVER_SCREEN_NAME_CONSTANTS,
} from '../packagesConstants';

import {
  getCreateServerObject,
  initialStateData,
  resetServerSessionDataValues,
  setServerObjectForEditFlow,
} from './PackagesServerStoreUtils';
// Domain data for Packages Adobe Templates page
class PackagesServerStore extends Store {
  createServerFlow = initialStateData.createServerFlow;
  editServerFlow = initialStateData.editServerFlow;
  filteredServers = undefined;
  isDeletionInProgress = false;

  packagesServerList = [];
  searchInputValue = ''; // the visible Search input string
  selectedServers = [];
  serverSessionData = {
    ...initialStateData.serverSessionData,
    organizationName: rootStore.organizationStore.activeOrg.name,
  };

  serverSessionSettings = initialStateData.serverSessionSettings;
  tableStore;

  constructor() {
    super();

    makeObservable(this, {
      fetchData: action,
      goToNextPage: action.bound,
      gotoNextScreen: action.bound,
      goToPageNumber: action.bound,
      goToPreviousPage: action.bound,
      gotoPreviousScreen: action.bound,
      handleEvents: action.bound,
      isDeletionInProgress: observable,
      onPageSizeChange: action.bound,
      resetServerSessionDataValues: action.bound,
      search: action,
      searchInputValue: observable,
      selectedServers: observable,
      serverSessionData: observable,
      serverSessionSettings: observable,
      setSelectedServers: action.bound,
      setServerObjectForEditFlow: action.bound,
      setServerSessionDataValues: action.bound,
      setServerSessionSettingValues: action.bound,
    });

    // Set this to true right away so the table doesn't have a chance to think it is empty.
    this.isLoading = true;

    this.tableStore = new TableStore({
      getKey: (item) => item.id,
    });
  }

  // Implementation will be added
  createServer(closeCreateServerModal) {
    const serverCreateData = getCreateServerObject(this.serverSessionData);
    this.isLoading = true;
    if (this.serverSessionSettings.editServerWorkflow) {
      CreateServerService.postServerEditRequest(serverCreateData, this.serverSessionData.serverId);
    } else {
      CreateServerService.postServerCreationRequest(serverCreateData);
    }
    PackagesEntitlementsService.resetOffers();
    this.currentScreenIndex = 0;
    this.currentScreen = this.createServerFlow.steps[0];
    closeCreateServerModal();
    this.resetServerSessionDataValues();
  }

  // Function to remove selected servers
  async deleteSelectedServers(selectedPackages, isCancelOperation) {
    this.isLoading = true;
    this.setDeletionFlagValue(true);
    await PackagesLanServersService.deleteSelectedServers(selectedPackages, isCancelOperation);
    // reload servers list
    await PackagesLanServersService.fetchAndProcessLanServers(true);
    this.packagesServerList = PackagesLanServersService.getAllLanServers();
    this.setDeletionFlagValue(false);
    this.goToPageNumber(TableConstants.FIRST_PAGE);
    this.setSelectedServers(null);
  }

  /**
   * This is the callback for Store.load() which wraps this in a try/catch.
   * If an error is caught this.hasLoadError will be true.
   */
  async fetchData() {
    this.isLoading = true;
    await rootStore.packagesUiConstantsStore.fetchUiConstants();
    await PackagesEntitlementsService.fetchEntitlements();
    await PackagesLanServersService.fetchAndProcessLanServers();
    this.packagesServerList = PackagesLanServersService.getAllLanServers();
    this.goToPageNumber(TableConstants.FIRST_PAGE);
  }

  // Function to filter the servers list
  filterServerList() {
    // if search query not empty, then add servers to list based on the name/platform matching search query
    if (this.tableStore.searchQuery) {
      this.filteredServers = this.packagesServerList.filter((item) => {
        const lowerCaseName = item.name.toLocaleLowerCase();
        const lowerCaseSearchText = this.tableStore.searchQuery.toLocaleLowerCase();
        return lowerCaseName.includes(lowerCaseSearchText);
      });
    } else {
      this.filteredServers = this.packagesServerList;
    }

    runInAction(() => {
      this.tableStore.setTotalItems(this.filteredServers.length);
      // Add servers as data to the rows visible in table, based on the current page and page size
      this.tableStore.mapDataToRows(
        cloneDeep(
          this.filteredServers.slice(
            (this.tableStore.currentPage - 1) * this.tableStore.pageSize,
            this.tableStore.currentPage * this.tableStore.pageSize
          )
        )
      );
      this.isLoading = false;
    });
  }

  getItem(key) {
    return this.filteredServers?.find((item) => item.serverId === key);
  }

  goToNextPage() {
    return this.goToPageNumber(this.tableStore.currentPage + 1);
  }

  // When next or create server button clicked on create server modal
  gotoNextScreen(dispatch, closeCreateServerModal) {
    if (this.serverSessionSettings.currentScreen === SERVER_SCREEN_NAME_CONSTANTS.SERVER_FINALIZE) {
      this.createServer(closeCreateServerModal);
    } else {
      this.serverSessionSettings.currentScreenIndex += 1;
      this.setWizardStateForNextScreen(dispatch);
    }
  }

  // Sets current page number and updates the list accordingly
  goToPageNumber(newPageNumber) {
    this.tableStore.setCurrentPage(newPageNumber);
    return this.filterServerList();
  }

  // When previous clicked on create server modal
  goToPreviousPage() {
    return this.goToPageNumber(this.tableStore.currentPage - 1);
  }

  // When previous button clicked on create server modal
  gotoPreviousScreen(dispatch) {
    this.serverSessionSettings.currentScreenIndex -= 1;
    this.setWizardStateForPrevScreen(dispatch);
  }

  /**
   * @description Function to handle server creation events
   */
  handleEvents(eventName, payload) {
    if (eventName === CREATE_SERVER.EVENTS.NEW_SERVER_CREATED) {
      this.isLoading = true;
      this.refreshServerList();
    } else if (eventName === CREATE_SERVER.EVENTS.SERVER_CREATION_PROGRESS) {
      const serverObj = payload.serverObject;
      const serverIndex = this.packagesServerList?.findIndex(
        (pkg) => pkg.serverId === serverObj.serverId
      );
      if (serverIndex > -1) {
        this.packagesServerList[serverIndex] = serverObj;
        this.filterServerList();
      } else {
        this.refreshServerList();
      }
    } else if (eventName === CREATE_SERVER.EVENTS.DOWNLOAD_ATO) {
      this.refreshServerList();
    } else if (eventName === SERVERS_NEXT_STEP_BANNER.EVENT) {
      switch (payload) {
        case SERVERS_NEXT_STEP_BANNER.CREATE:
          this.serverSessionSettings.nextStepBannerBody = translateString({
            id: 'packages.servers.nextStepBanner.createBody',
          });
          this.serverSessionSettings.showNextStepBanner = true;
          break;
        case SERVERS_NEXT_STEP_BANNER.EDIT:
        case SERVERS_NEXT_STEP_BANNER.REAUTH:
          this.serverSessionSettings.nextStepBannerBody = translateString({
            id: 'packages.servers.nextStepBanner.editBody',
          });
          this.serverSessionSettings.showNextStepBanner = true;
          break;
        default:
          this.serverSessionSettings.showNextStepBanner = false;
          break;
      }
    }
  }

  /**
   * @description Function to register event listener to get create package progress data
   */
  invokeEventListener() {
    eventBus.registerEventHandler(this.handleEvents, EVENT_SOURCES.SRC2);
  }

  onPageSizeChange(pageSize) {
    this.tableStore.setPageSize(pageSize);

    // Reset page back to the first page and filter the list
    return this.goToPageNumber(TableConstants.FIRST_PAGE);
  }

  /**
   * @description Function to fetch latest servers list
   */
  async refreshServerList() {
    await PackagesLanServersService.fetchAndProcessLanServers(true);
    this.packagesServerList = PackagesLanServersService.getAllLanServers();
    this.goToPageNumber(TableConstants.FIRST_PAGE);
  }

  /**
   * @description Function for re-setting server session data
   */
  resetServerSessionDataValues() {
    runInAction(() => {
      const response = resetServerSessionDataValues();
      this.serverSessionData = response.serverSessionData;
      this.serverSessionSettings = response.serverSessionSettings;
    });
  }

  /**
   * @description Function to deregister event listener
   */
  revokeEventListener() {
    eventBus.deregisterEventHandler(this.handleEvents, EVENT_SOURCES.SRC2);
  }

  search(value) {
    this.tableStore.searchQuery = value;
    // Reset page back to the first page and filter the list based on search query
    this.goToPageNumber(TableConstants.FIRST_PAGE);
  }

  /**
   * @description Function to set isDeletionInProgress flag
   */
  setDeletionFlagValue(val) {
    runInAction(() => {
      this.isDeletionInProgress = val;
    });
  }

  setSelectedServers(serverIds) {
    this.selectedServers = serverIds ? serverIds.map((serverId) => this.getItem(serverId)) : [];
  }

  setServerObjectForEditFlow(serverObject) {
    runInAction(() => {
      const response = setServerObjectForEditFlow(serverObject);
      this.serverSessionData = response.serverSessionData;
      this.serverSessionSettings = response.serverSessionSettings;
    });
  }

  /**
   * @description Setter function for setting sever session data
   */
  setServerSessionDataValues(key, value) {
    runInAction(() => {
      this.serverSessionData[key] = value;
    });
  }

  /**
   * @description Setter function for setting sever session settings
   */
  setServerSessionSettingValues(key, value) {
    this.serverSessionSettings[key] = value;
  }

  /**
   * @description Function to set wizard flow for next screen
   */
  setWizardStateForNextScreen(dispatch) {
    if (this.serverSessionSettings.editServerWorkflow) {
      this.serverSessionSettings.currentScreen =
        this.editServerFlow.steps[this.serverSessionSettings.currentScreenIndex];
    } else {
      this.serverSessionSettings.currentScreen =
        this.createServerFlow.steps[this.serverSessionSettings.currentScreenIndex];
    }
    this.serverSessionSettings.isPreviousButtonDisabled = false;
    this.serverSessionSettings.nextButtonEnabled = false;
    if (
      this.serverSessionSettings.currentScreen === SERVER_SCREEN_NAME_CONSTANTS.SERVER_INFO &&
      this.serverSessionData.organizationName
    ) {
      this.serverSessionSettings.nextButtonEnabled = true;
    }
    dispatch({type: WIZARD_DISPATCH_ACTION.INCREMENT});
  }

  /**
   * @description Function to set wizard flow for previous screen
   */
  setWizardStateForPrevScreen(dispatch) {
    if (this.serverSessionSettings.editServerWorkflow) {
      this.serverSessionSettings.currentScreen =
        this.editServerFlow.steps[this.serverSessionSettings.currentScreenIndex];
    } else {
      this.serverSessionSettings.currentScreen =
        this.createServerFlow.steps[this.serverSessionSettings.currentScreenIndex];
    }
    this.serverSessionSettings.nextButtonEnabled = true;
    this.serverSessionSettings.isPreviousButtonDisabled =
      this.serverSessionSettings.currentScreenIndex < 1;
    dispatch({type: WIZARD_DISPATCH_ACTION.DECREMENT});
  }
}

export default PackagesServerStore;
