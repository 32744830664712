import {useStore} from '@admin-tribe/acsc-ui';
import {Button, DialogContainer} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import {DirectoryContextProvider} from 'features/settings/components/directory/DirectoryContext';
import AddIdpModal from 'features/settings/components/directory/authentication/add-idp-modal/AddIdpModal';
import IdpList from 'features/settings/components/idp-list/IdpList';
import ExternalAuthService from 'features/settings/services/ExternalAuthService';
import DirectoryStore from 'features/settings/stores/DirectoryStore';

import './DirectoryAuthentication.pcss';

const DirectoryAuthentication = ({authSource, directoryId, refreshData, ...actions}) => {
  const directoryStore = useStore(() => new DirectoryStore(directoryId));
  const [isAddIdpDialogOpen, setIsAddIdpDialogOpen] = useState(false);
  const intl = useIntl();

  directoryStore.hydrate(authSource);

  // If we're in the middle of an external login
  // check to see what action is being done (adding azure IdP or editing an existing one)
  // and open the respective modal
  useEffect(() => {
    if (ExternalAuthService.isLoginInProgress()) {
      const stateData = ExternalAuthService.getStateData();

      if (stateData?.isAddingAzureIdp) {
        setIsAddIdpDialogOpen(true);
      }
    }
  }, []);

  return (
    <DirectoryContextProvider directoryStore={directoryStore}>
      <div styleName="directory-authentication-container">
        <div data-testid="idp-list" styleName="idp-list">
          <IdpList
            editIdp={actions.onEditIdp}
            idps={directoryStore.idps}
            makeDefaultIdp={actions.onMakeDefaultIdp}
            refreshData={refreshData}
            removeIdp={actions.onRemoveIdp}
            updateIdpStatusConfirm={actions.onUpdateIdpStatusConfirm}
          />
        </div>
        <div styleName="idp-actions">
          <Button
            data-testid="add-idp-button"
            onPress={() => setIsAddIdpDialogOpen(true)}
            variant="accent"
          >
            {intl.formatMessage({id: 'settings.authentication.addIdpButton'})}
          </Button>
        </div>
      </div>

      <DialogContainer onDismiss={() => setIsAddIdpDialogOpen(false)}>
        {isAddIdpDialogOpen && <AddIdpModal refreshData={refreshData} />}
      </DialogContainer>
    </DirectoryContextProvider>
  );
};

DirectoryAuthentication.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types -- this is currently coming from angular
  authSource: PropTypes.object,
  directoryId: PropTypes.string,
  refreshData: PropTypes.func,
};

export default DirectoryAuthentication;
