import {
  getDeveloperProducts,
  getProductListWithLicenseGroupSummariesIfSafe,
} from '@admin-tribe/acsc';
import binkyUI, {getOrganizationUserErrorProps} from '@admin-tribe/acsc-ui';
import {Text} from '@adobe/react-spectrum';
import {JilModelList as PandoraJilModelList} from '@pandora/data-model-list';
import {LicenseGroup as PandoraLicenseGroup} from '@pandora/data-model-product';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import AddUserFormTableWrapper from 'common/components/add-user-form-table/AddUserFormTableWrapper';
import {transformToPandoraProducts} from 'common/utils/products-utils/getPandoraProducts';
import {ROLE} from 'features/users/users.constants';

const AddUserFormTable = binkyUI.common.components.addUser.AddUserFormTable;
const MemberAndSelectedItemsList = binkyUI.common.components.addUser.MemberAndSelectedItemsList;
const GoUrl = binkyUI.common.components.GoUrl;
const UserPicker = binkyUI.common.components.UserPicker;

const {TARGET_TYPE} = binkyUI.common.components.ASSIGNMENT_MENU_CONSTANTS;

const {ModalContainer, ModalContent, ModalDescription, ModalDialog, ModalHeading} =
  binkyUI.common.components.modal;

const AddDevelopersToOrgModal = observer(
  ({isOpen, onCancel, onClosed /* @deprecated */, onSuccess, orgId}) => {
    const intl = useIntl();

    const [modalError, setModalError] = React.useState(null);
    const [modalErrorProps, setModalErrorProps] = React.useState();
    const [isModalOpen, setModalOpen] = React.useState(isOpen);
    const [productList, setProductList] = React.useState();
    const [pandoraProductList, setPandoraProductList] = React.useState();
    const [isLoading, setIsLoading] = React.useState(true);
    const [orgUserList, setOrgUserList] = React.useState(null);
    const isCurrentRef = React.useRef(true);
    const memberAndSelectedItemsListRef = React.useRef();

    const onConfirm = async () => {
      setIsLoading(true);
      try {
        await orgUserList.save();
        // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- zakn@ to update
        // istanbul ignore else
        if (isCurrentRef.current) {
          onSuccess?.();
          setModalOpen(false);
          setIsLoading(false);
        }
        return true;
      } catch (error) {
        // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- zakn@ to update
        // istanbul ignore else
        if (isCurrentRef.current) {
          const props = getOrganizationUserErrorProps(intl, error);
          setModalError(props.message);
          setModalErrorProps(props);
          setIsLoading(false);
        }
        return false;
      }
    };

    const onChange = (addUserFormTableData) => {
      const newOrgUserList = MemberAndSelectedItemsList.toOrgUserUnsavedList(
        addUserFormTableData,
        orgId,
        (memberAndSelectedItem) => {
          const member = memberAndSelectedItem.member;
          // Remove developer roles
          member.roles = member.roles.filter((role) => role.type !== ROLE.ADMIN.LICENSE_DEV);

          // Re-add only developer roles that are selected
          const products = memberAndSelectedItem.getSelectedProductsAndLicenseGroups();
          products.forEach((product) => {
            product.licenseGroups?.forEach((licenseGroup) => {
              const role = {
                targets: [{id: licenseGroup.id, parentId: product.id}],
                type: ROLE.ADMIN.LICENSE_DEV,
              };
              member.roles.push(role);
            });
          });
          return member;
        }
      );
      setOrgUserList(newOrgUserList);
      memberAndSelectedItemsListRef.current = addUserFormTableData;
    };

    const createPandoraAssignmentSectionContext = (selectedMember) => {
      const getItemsToPreselect = () => {
        // Don't define itemsToPreselect until the productList is ready to help define the license groups.
        // Pandora's AssignmentModalSection will use the first non-undefined value as the initial value.
        if (pandoraProductList?.items === undefined) {
          return undefined;
        }
        const licenseDevAdminRole = selectedMember?.roles?.find?.(
          (role) => role.type === ROLE.ADMIN.LICENSE_DEV
        );
        const roleTargetLicenseGroups = licenseDevAdminRole?.targets.map((adminRoleTarget) => {
          const productId = adminRoleTarget.parentId;
          const pandoraProduct = pandoraProductList.items.find((item) => item.id === productId);
          return new PandoraLicenseGroup({
            id: adminRoleTarget.id,
            name: adminRoleTarget.name,
            product: pandoraProduct,
          });
        });
        return {
          productProfiles: roleTargetLicenseGroups ?? [],
        };
      };

      return {
        itemsToPreselect: getItemsToPreselect(),
        products: pandoraProductList?.items,
      };
    };

    const ctaToastGenerator = () =>
      intl.formatMessage(
        {id: 'common.toast.modal.usersUpdated'},
        {userCount: orgUserList.items.length}
      );

    const isCtaDisabled = () => {
      // If no changes have been made, there is nothing to save.
      if (
        memberAndSelectedItemsListRef.current?.isInvalid() ||
        !orgUserList ||
        !orgUserList.addedItems ||
        orgUserList.addedItems.length === 0
      ) {
        return true;
      }

      // Enable the CTA only if all the users have changes.
      //   - For existing users, check use hasUnsavedChanges()
      //   - For new users, we check to see whether they have any roles (we can't use
      //     hasUnsavedChanges() because all new users return true for that function)
      return orgUserList.addedItems.some((orgUser) =>
        orgUser.isNew() ? orgUser.roles?.length === 0 : !orgUser.hasUnsavedChanges()
      );
    };

    // Update the pandora-products only when the binky products change.
    React.useEffect(() => {
      if (productList) {
        setPandoraProductList(transformToPandoraProducts(productList));
      } else {
        setPandoraProductList(new PandoraJilModelList());
      }
    }, [productList]);

    React.useEffect(() => {
      setIsLoading(true);

      async function fetchProducts() {
        let tempProductList;

        try {
          tempProductList = await getProductListWithLicenseGroupSummariesIfSafe(orgId);
          // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- bluu@ to update
          // istanbul ignore else
          if (isCurrentRef.current) {
            tempProductList.items = getDeveloperProducts(tempProductList);
          }
        } catch {
          // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- zakn@ to update
          // istanbul ignore else
          if (isCurrentRef.current) {
            setModalError(intl.formatMessage({id: 'common.modal.error.generic'}));
            setModalErrorProps(undefined);
          }
        }

        if (isCurrentRef.current) {
          setProductList(tempProductList);
          setIsLoading(false);
        }
      }

      fetchProducts();

      return () => {
        isCurrentRef.current = false;
      };
    }, [intl, orgId]);

    return (
      <ModalContainer>
        {isModalOpen && (
          <ModalDialog
            cancelLabel={intl.formatMessage({
              id: 'common.addDevelopersToOrgModal.cancelButton',
            })}
            ctaLabel={intl.formatMessage({
              id: 'common.addDevelopersToOrgModal.confirmButton',
            })}
            ctaToastGenerator={ctaToastGenerator}
            errorMessage={modalError}
            errorToastProps={modalErrorProps}
            heightVariant="static"
            id="add-developers-to-org-modal"
            isCtaDisabled={isCtaDisabled()}
            isLoading={isLoading}
            onCancel={onClosed || onCancel}
            onCta={onConfirm}
          >
            <ModalHeading>
              {intl.formatMessage({id: 'common.addDevelopersToOrgModal.header'})}
            </ModalHeading>
            <ModalDescription>
              <Text>
                {intl.formatMessage(
                  {id: 'common.addDevelopersToOrgModal.description'},
                  {
                    goUrl: (str) => <GoUrl name="aac_api_prod_learn">{str}</GoUrl>,
                  }
                )}
              </Text>
            </ModalDescription>
            <ModalContent>
              <AddUserFormTableWrapper orgId={orgId}>
                <AddUserFormTable
                  createPandoraAssignmentSectionContext={createPandoraAssignmentSectionContext}
                  data-testid="add-user-form-table-testid"
                  onChange={onChange}
                  orgId={orgId}
                  pickerType={UserPicker.PICKER_TYPE.USERS_ONLY}
                  searchType={UserPicker.SEARCH_TYPE.EXISTING_USER}
                  targets={[TARGET_TYPE.PRODUCT_PROFILES, TARGET_TYPE.PRODUCTS]}
                  titleTextId="common.AddUserFormTable.title.developer"
                />
              </AddUserFormTableWrapper>
            </ModalContent>
          </ModalDialog>
        )}
      </ModalContainer>
    );
  }
);

AddDevelopersToOrgModal.propTypes = {
  isOpen: PropTypes.bool,
  /**
   * Optional callback to invoke when the modal's cancel button is pressed.
   */
  onCancel: PropTypes.func,
  onClosed: PropTypes.func, // @deprecated in favor of onCancel
  /**
   * Optional callback to invoke when developer is successfully created.
   */
  onSuccess: PropTypes.func,
  orgId: PropTypes.string.isRequired,
};

export default AddDevelopersToOrgModal;
