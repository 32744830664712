import get from 'lodash/get';

import {setProperty} from '../aepAnalytics';

/**
 *
 * @param {*} uiEventDescriptor
 */
export default function uiEventTranslator(uiEventDescriptor) {
  const {eventAction, eventName, interaction} = uiEventDescriptor;
  setProperty('primaryEvent.eventInfo.eventName', `adminConsole:${eventName}:${eventAction}`);
  setProperty('primaryEvent.eventInfo.eventAction', eventName);
  setProperty('primaryEvent.eventInfo.interaction.click', get(interaction, 'click'));
  setProperty('primaryEvent.eventInfo.interaction.impression', get(interaction, 'impression'));
}
