import {feature} from '@admin-tribe/acsc';
import {Flex, Text, View} from '@adobe/react-spectrum';
import {useContentEntry} from '@pandora/react-content-provider';
import {FileDropZone, FileDropZoneContentModel} from '@pandora/react-file-drop-zone';
import SpectrumV2Provider from '@react/react-spectrum/Provider';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {ACTIVATION_CODE_VALID_LENGTH} from 'features/packages/packagesConstants';
import PackagesIngestAnalytics from 'features/packages/services/PackagesIngestAnalyticsService';

import {useCreatePackageModalContext} from '../../CreatePackageModalContext';

import ActivationCard from './ActivationCard';
import ActivationCodeValidator from './ActivationCodeValidator';

const DEFAULT_MAX_FILES_TO_ADD = 100;

/**
 * This component is used in getting activation codes via .csv files.
 * It also shows the details regarding the files using ActivationCard component.
 */
const DragAndDropFile = observer(
  ({maxFilesToAdd = DEFAULT_MAX_FILES_TO_ADD, updateAllCodeList, maxActivationCodeCount}) => {
    const intl = useIntl();
    const {createPackageModalStore} = useCreatePackageModalContext();

    const onDismiss = (file) => {
      const filteredChallengeCodeList = createPackageModalStore.fileChallengeCodeList.filter(
        (fileChallengeCode) => JSON.stringify(fileChallengeCode.file) !== JSON.stringify(file)
      );
      createPackageModalStore.setFileChallengeCodeList(filteredChallengeCodeList);
      createPackageModalStore.removeFile(file);
      updateAllCodeList();
    };

    const onFilesAdded = (filesToAdd) => {
      [...filesToAdd].forEach((file) => {
        if (createPackageModalStore.addedFiles.length < maxFilesToAdd) {
          addFile(file);
        }
      });
    };

    function addFile(file) {
      if (
        createPackageModalStore.pkgSessionSettings.frlIsolatedMachineCodes.length >
        maxActivationCodeCount
      ) {
        return;
      }
      parseAndValidateFile(file);

      PackagesIngestAnalytics.triggerAnalytics({
        subCategory: 'FRL:Offline:Codes',
        subType: 'activation-code',
        type: 'click',
        value: 'file',
      });
    }
    function isLengthAllowed(code) {
      if (feature.isDisabled('temp_package_umi_challenge_code') || code.split('@').length === 1) {
        return code.length === ACTIVATION_CODE_VALID_LENGTH;
      }
      // length of codes allowed without hyphen
      const allowedLengths = [58, 78, 98, 118, 138];
      return allowedLengths.includes(code.length);
    }

    async function parseAndValidateFile(file) {
      const fileObj = file;
      const fileDoesNotExist =
        createPackageModalStore.addedFiles.find(
          (addedFile) =>
            addedFile.name === file.name && addedFile.lastModified === file.lastModified
        ) === undefined;

      if (fileDoesNotExist) {
        fileObj.hasFileTypeError = file.name.toLowerCase().split('.').slice(-1)[0] !== 'csv';
        fileObj.hasFileSizeError = file.size > 2097152;

        if (!file.hasFileTypeError && !file.hasFileSizeError) {
          const fileContent = await file.text();

          // extract codes from file (seperated by ',' or 'new-line')
          const codes = fileContent
            .split(/[\n\r,]/)
            .filter((code) => code !== '')
            .map((code) => code.replace(/-/g, ''));

          fileObj.hasFileParsingError =
            codes.length === 0 || codes.some((code) => !isLengthAllowed(code));

          fileObj.hasInvalidCodes = codes.some((code) =>
            feature.isEnabled('temp_package_umi_challenge_code')
              ? !ActivationCodeValidator.isValidCodeV2(code)
              : !ActivationCodeValidator.isValidCode(code)
          );

          fileObj.isValid = !file.hasFileParsingError && !file.hasInvalidCodes;

          if (file.isValid) {
            const codeList = createPackageModalStore.fileChallengeCodeList;
            codes.forEach((code) => {
              const newCode = {
                code:
                  feature.isEnabled('temp_package_umi_challenge_code') &&
                  code.split('@').length !== 1
                    ? code
                    : ActivationCodeValidator.normalizeCode(code),
                file,
              };
              codeList.push(newCode);
            });
            createPackageModalStore.setFileChallengeCodeList(codeList);

            fileObj.codeCount = codes.length;
          } else {
            fileObj.codeCount = 0;
          }
        } else {
          fileObj.isValid = false;
        }

        createPackageModalStore.addFile(fileObj);
        updateAllCodeList();
      }
    }
    const content = useContentEntry(FileDropZoneContentModel);

    return (
      <SpectrumV2Provider toastPlacement="bottom left">
        <FileDropZone
          content={content}
          data-testid="file-drop-zone"
          id="file-drop-zone"
          multiple={maxFilesToAdd > 1}
          onChangeFiles={onFilesAdded}
          onFileAdded={onFilesAdded}
          showIcon={false}
        />
        {createPackageModalStore.addedFiles.length > 0 && (
          <Flex
            justifyContent="space-between"
            marginBottom="size-65"
            marginTop="size-500"
            maxWidth="size-6000"
            UNSAFE_style={{color: '#747474', fontSize: '12px'}}
            width="100%"
          >
            <Text>
              {intl.formatMessage({
                id: 'packages.createPackage.challengeCodes.fileUpload.fileListLabel',
              })}
            </Text>
            <View>
              {intl.formatMessage(
                {id: 'packages.createPackage.challengeCodes.fileUpload.codeCount'},
                {count: createPackageModalStore.fileChallengeCodeList.length}
              )}
            </View>
          </Flex>
        )}
        {createPackageModalStore.addedFiles.map((file) => (
          <ActivationCard
            key={file.name + file.lastModified}
            data-testid="activation-cards"
            file={file}
            onDismiss={() => onDismiss(file)}
          />
        ))}
      </SpectrumV2Provider>
    );
  }
);

DragAndDropFile.propTypes = {
  /**
   * count of maximum activation codes supported
   */
  maxActivationCodeCount: PropTypes.number.isRequired,
  /**
   * Maximum files that can be added in DragAndDrop file component
   */
  maxFilesToAdd: PropTypes.number,
  /**
   * Function to be called to update allCodeList
   */
  updateAllCodeList: PropTypes.func.isRequired,
};

export default DragAndDropFile;
