import {ModalContent, ModalDialog, ModalHeading} from '@admin-tribe/acsc-ui';
import {Divider, View} from '@adobe/react-spectrum';
import {GoUrl} from '@pandora/react-go-url';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import rootStore from 'core/RootStore';

import useAppIntegrationGlobalPolicy from '../hooks/useAppIntegrationGlobalPolicy';

import AppIntegrationsPolicyForm from './AppIntegrationsPolicyForm';

/**
 * AppIntegrationsGlobalPolicyModal allows the admin to set their global app integration policy.
 */
const AppIntegrationsGlobalPolicyModal = ({onClose}) => {
  const intl = useIntl();
  const {globalPolicy, isLoading, updateGlobalPolicy} = useAppIntegrationGlobalPolicy({
    orgId: rootStore.organizationStore.activeOrgId,
  });
  const [selectedPolicy, setSelectedPolicy] = useState(globalPolicy);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState();

  // Update selectedPolicy when globalPolicy is loaded
  useEffect(() => {
    setSelectedPolicy(globalPolicy);
  }, [globalPolicy]);

  const onCta = async () => {
    let isSuccess = false;
    setIsSubmitting(true);

    try {
      await updateGlobalPolicy({newPolicy: selectedPolicy});
      isSuccess = true;
    } catch {
      setSubmitError(
        intl.formatMessage({
          id: 'products.appIntegrations.appIntegrationsGlobalPolicyModal.errorMessage',
        })
      );
      isSuccess = false;
    } finally {
      setIsSubmitting(false);
    }

    if (isSuccess) {
      onClose();
    }
    return isSuccess; // show success toast
  };

  const isCtaDisabled = () => isLoading || globalPolicy === selectedPolicy || isSubmitting;

  return (
    <ModalDialog
      ctaToastGenerator={() =>
        intl.formatMessage({
          id: 'products.appIntegrations.appIntegrationsGlobalPolicyModal.successMessage',
        })
      }
      errorMessage={submitError}
      id="app-integrations-global-policy-modal"
      isCtaDisabled={isCtaDisabled()}
      isLoading={isLoading || isSubmitting}
      onCancel={onClose}
      onCta={onCta}
    >
      <ModalHeading>
        <FormattedMessage id="products.appIntegrations.appIntegrationsGlobalPolicyModal.heading" />
      </ModalHeading>
      <ModalContent>
        <Divider marginBottom="size-150" marginTop="size-150" size="M" />
        <View>
          <FormattedMessage
            id="products.appIntegrations.appIntegrationsGlobalPolicyModal.description"
            values={{goUrl: (str) => <GoUrl name="aac_app_integrations_learn">{str}</GoUrl>}}
          />
        </View>
        <AppIntegrationsPolicyForm
          isGlobalPolicy
          onSelectPolicy={setSelectedPolicy}
          selectedPolicy={selectedPolicy}
        />
      </ModalContent>
    </ModalDialog>
  );
};

AppIntegrationsGlobalPolicyModal.propTypes = {
  /** The onClose function required for DetailsButton to close modal. Calling it will close the modal. */
  onClose: PropTypes.func,
};

export default AppIntegrationsGlobalPolicyModal;
