import {Store, feature} from '@admin-tribe/acsc';
import {TableConstants, TableStore} from '@admin-tribe/acsc-ui';
import {action, makeObservable, observable, runInAction} from 'mobx';

import rootStore from 'core/RootStore';
import {CREATE_PACKAGE_CONSTANTS} from 'features/packages/packagesConstants';
import {TAB_KEYS} from 'features/packages/pages/pregenerated-packages/PregeneratedPackagesTabConstants';

import PackagesAdobeProducts from '../services/PackagesAdobeProductsService';
import PackagesAdobeProductsUi from '../services/PackagesAdobeProductsUiService';
import PackagesAdobeTemplatesList from '../services/PackagesAdobeTemplatesService';
import PackagesEntitlements from '../services/PackagesEntitlementsService';
import PackagesExtensions from '../services/PackagesExtensionsService';
import PackagesLanServers from '../services/PackagesLanServersService';
import PackagesPreferences from '../services/PackagesPreferencesService';

import {
  getOsBasedPlatform,
  getTemplateSapCodes,
  templateFilter,
} from './packagesAdobeTemplatesStoreUtils';

// Domain data for Packages Adobe Templates page
class PackagesAdobeTemplatesStore extends Store {
  categorisedPlatformSingleAppsMap = {};
  currentTab = TAB_KEYS.SINGLE_APPLICATION;
  filteredAdobeTemplates = undefined;
  filteredSingleApps = undefined;
  isSingleAppPageLoading = false;
  isTemplatesPageLoading;
  packagesAdobeTemplatesList = [];
  packagesSingleAppsList = [];
  selectedCategory;
  selectedPlatform = getOsBasedPlatform();
  selectedSingleApps = [];
  singleAppFilters = [];
  singleAppsFilteredByCategoryAndPlatform = [];
  singleAppsTableStore;
  tableStore;

  constructor() {
    super();

    makeObservable(this, {
      currentTab: observable,
      fetchData: action.bound,
      fetchTemplates: action.bound,
      goToNextPage: action.bound,
      goToPageNumber: action.bound,
      goToPreviousPage: action.bound,
      handleCategoryChange: action.bound,
      handlePlatformChange: action.bound,
      isSingleAppPageLoading: observable,
      isTemplatesPageLoading: observable,
      onPageSizeChange: action.bound,
      search: action.bound,
      selectedCategory: observable,
      selectedPlatform: observable,
      setCurrentTab: action.bound,
      singleAppFilters: observable,
    });

    // Set this to true right away so the table doesn't have a chance to think it is empty.
    this.isLoading = true;

    this.tableStore = new TableStore({
      getKey: (item) => item.id,
      pageSize: 10,
    });

    this.singleAppsTableStore = new TableStore({
      getKey: (item) => item.id,
      pageSize: 10,
    });

    if (feature.isEnabled('temp_packages_pregenerated_page')) {
      this.currentTab = TAB_KEYS.SINGLE_APPLICATION;
    } else {
      this.currentTab = TAB_KEYS.TEMPLATES;
    }
  }

  /**
   * This is the callback for Store.load() which wraps this in a try/catch.
   * If an error is caught this.hasLoadError will be true.
   */
  async fetchData() {
    // Fetch in this order:
    // 1. PackagesUiConstants
    // 2. PackagesPreferences
    // 3. PackagesEntitlements
    // 4. PackagesAdobeProducts
    // 5. PackagesAdobeProductsUi
    // 6. PackagesAdobeTemplates
    await rootStore.packagesUiConstantsStore.fetchUiConstants();
    await PackagesEntitlements.fetchEntitlements();
    await PackagesPreferences.fetchUserPreferences();
    if (
      PackagesEntitlements.hasCcEntitlement ||
      PackagesEntitlements.hasDcEntitlement ||
      PackagesEntitlements.hasSubstanceNamedEntitlement
    ) {
      await PackagesExtensions.initApis();
    }
    await PackagesAdobeProducts.fetchAndProcessAdobeProducts();
    await PackagesAdobeProductsUi.fetchAndProcessAdobeProducts();
    if (PackagesEntitlements.hasFrlLanEntitlement) {
      await PackagesLanServers.fetchAndProcessLanServers();
    }
    this.filteredAdobeTemplates = [];
    if (feature.isDisabled('temp_packages_pregenerated_page')) {
      await this.fetchTemplates();
    }
  }

  async fetchSingleAppFilters() {
    if (this.singleAppsFilteredByCategoryAndPlatform?.length > 0) {
      return;
    }
    this.isSingleAppPageLoading = true;
    const singleAppsList = await PackagesAdobeTemplatesList.get(true);
    singleAppsList.items = singleAppsList.items.filter((singleApp) => !!singleApp.nativePackageId);
    this.packagesSingleAppsList = singleAppsList.items.filter(templateFilter);
    this.packagesSingleAppsList = this.packagesSingleAppsList.map((singleApp) => {
      singleApp.sapCodeList = getTemplateSapCodes(singleApp.packagedProductsMap);
      return singleApp;
    });
    const singleAppFilters = rootStore.packagesUiConstantsStore.singleAppFilter;
    if (singleAppFilters && Array.isArray(JSON.parse(singleAppFilters))) {
      this.singleAppFilters = JSON.parse(singleAppFilters);
      this.selectedCategory = this.singleAppFilters[0].key;
    }
    this.populateCategorisedPlatformSingleAppsMap();
    this.singleAppsFilteredByCategoryAndPlatform =
      this.categorisedPlatformSingleAppsMap?.[this.selectedCategory]?.[this.selectedPlatform];
    this.goToPageNumber(TableConstants.FIRST_PAGE);
    this.isSingleAppPageLoading = false;
  }

  async fetchTemplates() {
    if (this.packagesAdobeTemplatesList.length === 0) {
      this.isTemplatesPageLoading = true;
      const templatesList = await PackagesAdobeTemplatesList.get(false);
      this.packagesAdobeTemplatesList = templatesList.items.filter(templateFilter);
      this.goToPageNumber(TableConstants.FIRST_PAGE);
      this.isTemplatesPageLoading = false;
    }
  }

  // Function to filter the templates list
  filterAdobeTemplatesList() {
    // if search query not empty, then add templates to list based on the name/platform matching search query
    if (this.tableStore.searchQuery) {
      this.filteredAdobeTemplates = this.packagesAdobeTemplatesList.filter((item) => {
        const lowerCaseName = item.name.toLocaleLowerCase();
        const lowerCasePlatform = item.platformString.toLocaleLowerCase();
        const lowerCaseSearchText = this.tableStore.searchQuery.toLocaleLowerCase();
        return (
          lowerCaseName.includes(lowerCaseSearchText) ||
          lowerCasePlatform.includes(lowerCaseSearchText)
        );
      });
    } else if (this.singleAppsTableStore.searchQuery) {
      this.singleAppsFilteredByCategoryAndPlatform = this.categorisedPlatformSingleAppsMap?.[
        this.selectedCategory
      ]?.[this.selectedPlatform]?.filter((item) => {
        const lowerCaseName = item.name.toLocaleLowerCase();
        const lowerCasePlatform = item.platformString.toLocaleLowerCase();
        const lowerCaseSearchText = this.singleAppsTableStore.searchQuery.toLocaleLowerCase();
        return (
          lowerCaseName.includes(lowerCaseSearchText) ||
          lowerCasePlatform.includes(lowerCaseSearchText)
        );
      });
    } else {
      this.filteredAdobeTemplates = this.packagesAdobeTemplatesList;
      this.singleAppsFilteredByCategoryAndPlatform =
        this.categorisedPlatformSingleAppsMap?.[this.selectedCategory]?.[this.selectedPlatform];
    }
    runInAction(() => {
      this.tableStore.setTotalItems(this.filteredAdobeTemplates.length);
      // Add templates as data to the rows visible in table, based on the current page and page size
      this.tableStore.mapDataToRows(
        this.filteredAdobeTemplates.slice(
          (this.tableStore.currentPage - 1) * this.tableStore.pageSize,
          this.tableStore.currentPage * this.tableStore.pageSize
        )
      );
      if (this.singleAppsFilteredByCategoryAndPlatform?.length > 0) {
        this.singleAppsTableStore.setTotalItems(
          this.singleAppsFilteredByCategoryAndPlatform.length
        );
        // Add single apps as data to the rows visible in table, based on the current page and page size
        this.singleAppsTableStore.mapDataToRows(
          this.singleAppsFilteredByCategoryAndPlatform.slice(
            (this.singleAppsTableStore.currentPage - 1) * this.singleAppsTableStore.pageSize,
            this.singleAppsTableStore.currentPage * this.singleAppsTableStore.pageSize
          )
        );
      } else {
        this.singleAppsTableStore.mapDataToRows([]);
      }
    });
  }

  getItem(key) {
    return this.packagesSingleAppsList?.find((item) => item.id === key);
  }

  goToNextPage() {
    if (this.currentTab === TAB_KEYS.SINGLE_APPLICATION) {
      return this.goToPageNumber(this.singleAppsTableStore.currentPage + 1);
    }
    return this.goToPageNumber(this.tableStore.currentPage + 1);
  }

  // Sets current page number and updates the list accordingly
  goToPageNumber(newPageNumber) {
    if (this.currentTab === TAB_KEYS.SINGLE_APPLICATION) {
      this.singleAppsTableStore.setCurrentPage(newPageNumber);
    } else {
      this.tableStore.setCurrentPage(newPageNumber);
    }
    return this.filterAdobeTemplatesList();
  }

  goToPreviousPage() {
    if (this.currentTab === TAB_KEYS.SINGLE_APPLICATION) {
      return this.goToPageNumber(this.singleAppsTableStore.currentPage - 1);
    }
    return this.goToPageNumber(this.tableStore.currentPage - 1);
  }

  handleCategoryChange(key) {
    if (key.currentKey && key.currentKey !== this.selectedCategory) {
      this.selectedCategory = key.currentKey;
      this.search(this.singleAppsTableStore.searchQuery);
    }
  }

  handlePlatformChange(key) {
    if (key === this.selectedPlatform) {
      return;
    }
    if (key === CREATE_PACKAGE_CONSTANTS.WIN_PLATFORM) {
      this.selectedPlatform = CREATE_PACKAGE_CONSTANTS.WIN_PLATFORM;
    } else {
      this.selectedPlatform = CREATE_PACKAGE_CONSTANTS.MAC_PLATFORM;
    }
    this.search(this.singleAppsTableStore.searchQuery);
  }

  onPageSizeChange(pageSize) {
    if (this.currentTab === TAB_KEYS.SINGLE_APPLICATION) {
      this.singleAppsTableStore.setPageSize(pageSize);

      // Reset page back to the first page and filter the list
      this.singleAppsTableStore.setCurrentPage(TableConstants.FIRST_PAGE);
      return this.filterAdobeTemplatesList();
    }

    this.tableStore.setPageSize(pageSize);

    // Reset page back to the first page and filter the list
    this.tableStore.setCurrentPage(TableConstants.FIRST_PAGE);
    return this.filterAdobeTemplatesList();
  }

  populateCategorisedPlatformSingleAppsMap() {
    const allAppsWinList = this.packagesSingleAppsList.filter(
      (allApps) => allApps.platform === CREATE_PACKAGE_CONSTANTS.WIN_PLATFORM
    );
    const allAppsMacList = this.packagesSingleAppsList.filter(
      (allApps) => allApps.platform !== CREATE_PACKAGE_CONSTANTS.WIN_PLATFORM
    );
    for (let index = 0; index < this.singleAppFilters.length; index++) {
      const filter = this.singleAppFilters[index];
      if (filter?.val?.length === 0) {
        this.categorisedPlatformSingleAppsMap[filter.key] = {
          [CREATE_PACKAGE_CONSTANTS.MAC_PLATFORM]: allAppsMacList,
          [CREATE_PACKAGE_CONSTANTS.WIN_PLATFORM]: allAppsWinList,
        };
      } else {
        this.categorisedPlatformSingleAppsMap[filter.key] = {
          [CREATE_PACKAGE_CONSTANTS.MAC_PLATFORM]: allAppsMacList.filter((temp) =>
            filter.val.some((code) => temp.sapCodeList.includes(code.trim()))
          ),
          [CREATE_PACKAGE_CONSTANTS.WIN_PLATFORM]: allAppsWinList.filter((temp) =>
            filter.val.some((code) => temp.sapCodeList.includes(code.trim()))
          ),
        };
      }
    }
  }

  search(value) {
    if (this.currentTab === TAB_KEYS.SINGLE_APPLICATION) {
      this.singleAppsTableStore.searchQuery = value ? value.trim() : '';
      this.goToPageNumber(TableConstants.FIRST_PAGE);
    } else {
      this.tableStore.searchQuery = value ? value.trim() : '';
      this.goToPageNumber(TableConstants.FIRST_PAGE);
    }
  }

  setCurrentTab(tab) {
    this.tableStore.searchQuery = null;
    this.singleAppsTableStore.searchQuery = null;
    this.singleAppsTableStore.pageSize = 10;
    this.currentTab = tab;
    this.goToPageNumber(TableConstants.FIRST_PAGE);
  }
}

export default PackagesAdobeTemplatesStore;
