import {GoUrl, OverlayWait} from '@admin-tribe/acsc-ui';
import {Flex, Heading, Link, View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {SUPPORT_CONSTANTS} from '../../support.constants';
import SupportTicketCreateButton from '../support-ticket-create-button/SupportTicketCreateButton';

import styles from './SupportSummaryQuickActions.pcss';

const {SUPPORT_TICKET_RECORD_TYPE} = SUPPORT_CONSTANTS;

/**
 * A container for quick actions you might take having to do with Expert Sessions: a heading, a "Create Expert Session"
 * button, a link to open Expert Sessions and a help link.
 */
const ExpertSessionQuickActions = (props) => {
  const {
    cloudType,
    'data-testid': dataTestId,
    expertSessionCount,
    isLoading = false,
    onExpertSessionCreated,
    onGoToOpenSessions,
  } = props;
  const intl = useIntl();
  return (
    // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes --  need a top level class to namespace the styles below
    <View data-testid={dataTestId} flex="1" UNSAFE_className={styles['quick-actions']}>
      <OverlayWait isLoading={isLoading} showContent size="M">
        <Heading level="2" marginTop="size-10">
          {intl.formatMessage({id: 'support.quickActions.expertSession.title'})}
        </Heading>
        {/* eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- View doesn't seem to have a way of toggling
       visibility and taking up space until the open cases call resolves with a non-empty list */}
        <View UNSAFE_className={styles[expertSessionCount > 0 ? 'show' : 'hide']}>
          <Link isQuiet onPress={onGoToOpenSessions}>
            {intl.formatMessage(
              {
                id: 'support.quickActions.expertSession.viewAllActive',
              },
              {
                count: intl.formatNumber(expertSessionCount),
              }
            )}
          </Link>
        </View>
        <View>
          <Flex direction="row" gap="size-100" marginTop="size-200" wrap>
            <SupportTicketCreateButton
              cloudType={cloudType}
              onCreate={onExpertSessionCreated}
              recordType={SUPPORT_TICKET_RECORD_TYPE.EXPERT}
            />
            <GoUrl
              isQuiet
              name="aac_pop_artt_support_for_enterprise"
              UNSAFE_style={{paddingTop: 'var(--spectrum-global-dimension-static-size-65)'}}
            >
              {intl.formatMessage({
                id: 'support.quickActions.expertSession.learnMore',
              })}
            </GoUrl>
          </Flex>
        </View>
      </OverlayWait>
    </View>
  );
};

ExpertSessionQuickActions.propTypes = {
  /**
   * Used to set cloud type
   */
  cloudType: PropTypes.string,
  /**
   * The specified data-testid for the component.
   */
  'data-testid': PropTypes.string,
  /**
   * The number of existing expert sessions that have been opened
   */
  expertSessionCount: PropTypes.number.isRequired,
  /**
   * Used to show loader for count
   */
  isLoading: PropTypes.bool,
  /**
   * Callback function for after expert session request is opened
   */
  onExpertSessionCreated: PropTypes.func.isRequired,
  /**
   * Callback function for navigating to open expert sessions
   */
  onGoToOpenSessions: PropTypes.func.isRequired,
};

export default ExpertSessionQuickActions;
