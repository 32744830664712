import {LicenseGroup} from '@admin-tribe/acsc';
import {action, computed, makeObservable, observable, runInAction} from 'mobx';

import MemberStore from 'common/stores/member-store/MemberStore';
import rootStore from 'core/RootStore';
import OrganizationIntegrationList from 'core/organizations/integration-list/OrganizationIntegrationList';

/**
 * @description Domain data for AddApiCredentialsModal.
 * @param {Object} options - configuration for the store
 * @param {String} options.licenseGroupId - the profile id for the profile  page
 * @param {String} options.productId - the product id for the profile  page
 * @param {Object} options.memberStoreProps - see MemberStore options
 */
class ApiCredentialsStore extends MemberStore {
  licenseGroup;
  licenseGroupId;
  product;

  constructor({licenseGroupId, productId, ...memberStoreProps}) {
    super({
      ...memberStoreProps,
      listClassRef: OrganizationIntegrationList,
    });

    this.licenseGroupId = licenseGroupId;
    this.product = rootStore.organizationStore.productList.items.find(
      (item) => item.id === productId
    );

    makeObservable(this, {
      getIsItemSelectable: action,
      licenseGroup: observable,
      profileName: computed,
      saveProfileApiCredentials: action,
    });

    this.load();
  }

  /**
   * @description The first time thru fetch the LicenseGroup.
   *   Always fetch the OrganizationIntegrationList.
   *
   *   Note: Store.load wraps this in a try/catch.
   *   If an error occurs, this.hasLoadingError will be set to true.
   */
  async fetchData() {
    if (!this.licenseGroup) {
      const licenseGroup = await LicenseGroup.get({
        id: this.licenseGroupId,
        orgId: this.orgId,
        product: this.product,
      });
      runInAction(() => {
        this.licenseGroup = licenseGroup;
      });
    }

    await super.fetchData();
  }

  /**
   * @description Method to determine if this credential is selectable for this product profile.
   *  License groups are returned for the products when includeLicenseGroups is included on the API call.
   *
   * @returns {Boolean} True if this credential is selectable. False if this credential is already assigned to
   *   this product profile.
   */
  getIsItemSelectable(itemKey) {
    const apiCredential = this.getItem(itemKey);
    if (!apiCredential) {
      return false;
    }
    // Err on the side of including. If a credential which is already assigned to the profile is
    // added again it is effectively a no-op.
    const thisProduct = apiCredential.products?.find((product) => product.id === this.product.id);
    if (thisProduct) {
      const hasLicenseGroup = thisProduct.licenseGroups?.some(
        (licenseGroup) => licenseGroup.id === this.licenseGroupId
      );
      return !hasLicenseGroup;
    }
    return true;
  }

  /**
   * @description Method to get the profile name.
   * @returns {String} If there is a profile, will return its name, otherwise the empty string.
   */
  get profileName() {
    return this.licenseGroup?.name ?? '';
  }

  /**
   * @description Method to add the selected api credentials to the given profile integration list.
   * @param {Object} options - options
   * @param {LicenseGroupIntegrationList} options.licenseGroupIntegrationList - the profile integration list
   * @returns {Promise<LicenseGroupIntegrationList>} resolves with the list, rejects with list's save error
   */
  saveProfileApiCredentials({licenseGroupIntegrationList}) {
    const itemsToAdd = this.selectedItems.map((apiIntegration) => apiIntegration.toMinimumModel());
    licenseGroupIntegrationList.add(itemsToAdd);
    return licenseGroupIntegrationList.save();
  }
}

export default ApiCredentialsStore;
