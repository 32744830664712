import {getPersonName} from '@admin-tribe/acsc-ui';

const DELETED_USER_FOLDER = {
  DISPLAY_NAME: 'DELETED DELETED',
  EMAIL_SUFFIX: 'rfs.adobe-identity.com',
};

/**
 * Utility for determining a storage folder owners display list
 * @param {Object} intl
 * @param {Array<Object>} owners
 * @returns <String> - The string to be included in the H2 at the top of the Drawer
 */
const getNamesOfOwners = (intl, owners) => {
  // Utility that determines if a string ends with a certain suffix.
  // Could not use String.prototype.endsWith() due to lack of IE support
  const endsWith = (str, suffix) => str.slice(-suffix.length) === suffix;

  const displayNameMapper = (owner) => getPersonName(intl, owner);

  // Take the first 3 owners, from the list of all folder owners
  // slice will return the array if less than 3 elements
  const names = owners.map(displayNameMapper).slice(0, 3);

  let nameOfOwners = '';

  // If we only have 1 owner, we display the name as is
  // If the owner was deleted it would display as 'DELETED DELETED'
  // and would have an email suffix of 'rfs.adobe-identity.com'
  if (names.length === 1) {
    if (
      names[0] === DELETED_USER_FOLDER.DISPLAY_NAME &&
      endsWith(owners[0].email, DELETED_USER_FOLDER.EMAIL_SUFFIX)
    ) {
      nameOfOwners = owners[0].id;
    } else {
      nameOfOwners = names[0];
    }
  } else if (names.length > 1) {
    // If there are multiple owners then we use a translation string that
    // displays something else depending on the number of owners e.g.
    // =2 {{nameOfUser1}, {nameOfUser2}}
    // =3 {{nameOfUser1}, {nameOfUser2}, {nameOfUser3}}
    // >3 {{nameOfUser1}, {nameOfUser2}, {nameOfUser3} and {othersCount} others
    nameOfOwners = intl.formatMessage(
      {id: 'storage.userFolderDetailsDrawer.owners.list'},
      {
        count: owners.length,
        nameOfUser1: names[0],
        nameOfUser2: names[1],
        nameOfUser3: names[2],
        othersCount: owners.length - 3,
      }
    );
  }

  return nameOfOwners;
};

export default getNamesOfOwners;
