import {showError as showErrorToast, showSuccess as showSuccessToast} from '@admin-tribe/acsc-ui';
import {useCallback, useState} from 'react';
import {useIntl} from 'react-intl';

import {ERROR_CODES, SCHEDULE_OPTIONS} from 'features/settings/api/eduRosterSync';
import {useRosterSyncContext} from 'features/settings/components/directory/sync/RosterSyncContext';
import useEduRosterSync from 'features/settings/hooks/api/useEduRosterSync';

const ERRORS = {
  [ERROR_CODES.LICENSE_NOT_AVAILABLE]: 'settings.sync.eduSync.error.noAdobeExpressAvailableInOrg',
  [ERROR_CODES.USER_NOT_DISTRICT_ADMIN]: 'settings.sync.eduSync.error.notDistrictAdmin',
  GENERIC_CREATE: 'settings.sync.eduSyncConfirmationModal.toasts.errorAddingSync',
  GENERIC_UPDATE: 'settings.sync.eduSyncSettingsModal.toasts.errorUpdatingSync',
};

const getErrorLabelParams = (error, defaultLabelId) => {
  const errorData = error.response?.data?.data;
  const errorCode = error.response?.data?.error;

  if (errorCode === ERROR_CODES.LICENSE_NOT_AVAILABLE) {
    return errorData?.productAppName
      ? [
          {
            id: 'settings.sync.eduSync.error.productNotAvailableInOrg',
          },
          {
            productName: errorData.productAppName,
          },
        ]
      : [{id: 'settings.sync.eduSync.error.noLicensesAvailableInOrg'}];
  }

  if (errorCode === ERROR_CODES.LICENSE_VARIANTS_CONFLICT) {
    return [{id: 'settings.sync.eduSync.error.licenseConflict'}];
  }

  if (
    errorCode === ERROR_CODES.CCX_INTEGRATION_MISSING ||
    errorCode === ERROR_CODES.CCALL_INTEGRATION_MISSING
  ) {
    return errorData?.productAppName
      ? [
          {
            id: 'settings.sync.eduSync.error.productNotAvailableInLMS',
          },
          {
            productName: errorData.productAppName,
          },
        ]
      : [{id: 'settings.sync.eduSync.error.noLicensesAvailableInLMS'}];
  }

  return [{id: ERRORS[errorCode] ?? defaultLabelId}];
};

/**
 * A hook that helps with setting up or editing settings for a roster sync.
 * It contains state for app selection/scheduling and also callbacks that
 * do the appropriate calls.
 */
const useRosterSyncSetup = (syncId, defaultSelectedProducts, defaultSchedule) => {
  const [selectedProducts, setSelectedProducts] = useState(defaultSelectedProducts || []);
  const [selectedSchedule, setSelectedSchedule] = useState(
    defaultSchedule || SCHEDULE_OPTIONS.WEEKLY
  );
  const [isLoading, setIsLoading] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [areSelectedProductsValid, setAreSelectedProductsValid] = useState(true);
  const {updateRosterSyncData} = useRosterSyncContext();
  const {createRosterSync, updateRosterSync} = useEduRosterSync();
  const intl = useIntl();

  // Make the CTA enabled only when we have the data and there are changes to it
  const isCtaEnabled = selectedProducts.length > 0 && selectedSchedule && hasChanges;

  const checkForChanges = useCallback(
    (products, schedule) => {
      const areProductsChanged =
        products.length !== defaultSelectedProducts.length ||
        !products.every((product) =>
          defaultSelectedProducts.find((defaultProduct) => defaultProduct === product)
        );
      const isScheduleChanged = schedule !== defaultSchedule;

      setHasChanges(isScheduleChanged || areProductsChanged);
    },
    [defaultSchedule, defaultSelectedProducts]
  );

  const onChangeProducts = useCallback(
    (products) => {
      setSelectedProducts(products);
      checkForChanges(products, selectedSchedule);
    },
    [checkForChanges, selectedSchedule]
  );

  const onChangeSchedule = useCallback(
    (schedule) => {
      setSelectedSchedule(schedule);
      checkForChanges(selectedProducts, schedule);
    },
    [checkForChanges, selectedProducts]
  );

  const createSync = useCallback(
    async ({accessToken, directoryId, syncProvider, districtId, syncSchedule, products}) => {
      setIsLoading(true);

      try {
        const {data: newRosterSync} = await createRosterSync({
          accessToken,
          directoryId,
          districtId,
          products,
          syncProvider,
          syncSchedule,
        });

        // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- this is the setState function from react which cannot be tested because has to be mocked.
        // istanbul ignore next -- not testing this
        updateRosterSyncData({syncId: newRosterSync.syncId, updatedSyncData: newRosterSync});

        showSuccessToast(
          intl.formatMessage({
            id:
              syncSchedule === SCHEDULE_OPTIONS.MANUAL
                ? 'settings.sync.eduSyncConfirmationModal.toasts.syncAdded'
                : 'settings.sync.eduSyncConfirmationModal.toasts.scheduledSyncAdded',
          })
        );
      } catch (error) {
        showErrorToast(intl.formatMessage(...getErrorLabelParams(error, ERRORS.GENERIC_CREATE)));

        throw error;
      } finally {
        setIsLoading(false);
      }
    },
    [createRosterSync, intl, updateRosterSyncData]
  );

  const updateSync = useCallback(async () => {
    setIsLoading(true);

    try {
      const {data: updatedRosterSync} = await updateRosterSync({
        data: {
          products: selectedProducts,
          syncSchedule: selectedSchedule,
        },
        syncId,
      });

      // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- this is the setState function from react which cannot be tested because has to be mocked.
      // istanbul ignore next -- not testing this
      updateRosterSyncData({syncId: updatedRosterSync.syncId, updatedSyncData: updatedRosterSync});

      setIsLoading(false);
      showSuccessToast(
        intl.formatMessage({id: 'settings.sync.eduSyncSettingsModal.toasts.settingsSaved'})
      );
    } catch (error) {
      showErrorToast(intl.formatMessage(...getErrorLabelParams(error, ERRORS.GENERIC_UPDATE)));

      setIsLoading(false);
      throw error;
    }
  }, [intl, selectedProducts, selectedSchedule, updateRosterSyncData, updateRosterSync, syncId]);

  return {
    areSelectedProductsValid,
    createSync,
    isCtaEnabled,
    isLoading,
    onChangeProducts,
    onChangeSchedule,
    selectedProducts,
    selectedSchedule,
    setAreSelectedProductsValid,
    setSelectedProducts,
    setSelectedSchedule,
    updateSync,
  };
};

export default useRosterSyncSetup;
