import {
  CONTRACT_LIST_CACHE_ID,
  JilModelList,
  MEMBER_EVENT,
  PRODUCT_USER_LIST_CACHE_ID,
  eventBus,
  log,
  modelCache,
} from '@admin-tribe/acsc';

import jilOrganizationContractMigration from '../api/jilOrganizationContractMigration';

import OrganizationContractMigration from './OrganizationContractMigration';
import ORGANIZATION_CONTRACT_MIGRATION_CONSTANTS from './OrganizationContractMigrationConstants';

const {ORG_CONTRACT_MIGRATION_LIST_CACHE_ID} = ORGANIZATION_CONTRACT_MIGRATION_CONSTANTS;

class OrganizationContractMigrationList extends JilModelList {
  /**
   * @description Refreshes the contents of the list
   *
   * @param {Array<Object>} requestBody An array of object for delegates migration api.
   * Ex: [{destinationLicenseId: 'lid', destinationOfferId: 'oid', licenseGroupId: '123', sourceContractId: 'cid',
   *  sourceOfferId: 'sid', sourceLicenseId: 's-lid', users: [{id: 'userId'}]}]
   *
   * @returns {Promise} a promise which is resolved when the list is refreshed successfully
   */
  static async migrate(requestBody) {
    try {
      await jilOrganizationContractMigration.migrate(requestBody);
      modelCache.clear(ORG_CONTRACT_MIGRATION_LIST_CACHE_ID);
      modelCache.clear(PRODUCT_USER_LIST_CACHE_ID);
      modelCache.clear(CONTRACT_LIST_CACHE_ID);
      eventBus.emit(MEMBER_EVENT.UPDATE); // calling this so that product list will be updated
    } catch (error) {
      log.error('Failed to migrate users to new product. Error: ', error);
      throw new Error(error);
    }
  }

  /**
   * @description Method to create a new instance of an OrganizationList.
   */
  constructor(options = {}) {
    super({
      isCacheable: true,
      itemClassRef: OrganizationContractMigration,
      modelCacheId: ORGANIZATION_CONTRACT_MIGRATION_CONSTANTS.ORG_CONTRACT_MIGRATION_LIST_CACHE_ID,
      resource: jilOrganizationContractMigration.getMigrations,
    });

    this.contractId = options.contractId;
    this.type = options.type;
  }

  /**
   * @description Refreshes the contents of the list
   *
   * @returns {Promise} a promise which is resolved when the list is refreshed successfully
   */
  async refresh() {
    try {
      await super.refresh({
        contractId: this.contractId,
        type: this.type,
      });
    } catch (error) {
      log.error('OrganizationContractMigrationList.refresh() failed. Error: ', error);
      return Promise.reject(error);
    }
    return this;
  }
}

export default OrganizationContractMigrationList;
