import {feature, log} from '@admin-tribe/acsc';
import PropTypes from 'prop-types';
import React, {createContext, useContext, useEffect, useMemo, useState} from 'react';
import {useErrorHandler} from 'react-error-boundary';

import rootStore from 'core/RootStore';
import {useAuthentication} from 'core/providers/authentication/AuthenticationProvider';

import {useConfiguration} from '../configuration/ConfigurationProvider';

const FeatureContext = createContext();

/**
 * Provides Feature data to its children components.
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The children components.
 * @returns {React.ReactNode} The wrapped children components.
 */

const FeatureProvider = ({children}) => {
  const {configuration, configurationLoaded} = useConfiguration();
  const {authenticationLoaded} = useAuthentication();

  const [featureFlagsLoaded, setFeatureFlagsLoaded] = useState(false);
  const handleError = useErrorHandler();

  // Fetch data from the Feature file
  useEffect(() => {
    if (!configurationLoaded || !authenticationLoaded) return;

    function initializeFeature() {
      const {featureFlags, floodgateReleases, featureNames} = configuration;
      feature.setup(featureFlags, floodgateReleases, featureNames);
    }

    function featureHandler(flag) {
      const featureFlagRequirements = configuration?.featureFlagRequirements;
      if (flag === 'force_requirements_match') {
        return true; // Always allow if flag is 'force_requirements_match'
      }
      const orgIdMatches = featureFlagRequirements?.[flag]?.orgIdMatches;
      const orgIdDoesNotMatch = featureFlagRequirements?.[flag]?.orgIdDoesNotMatch;

      if (feature.isDisabled('force_requirements_match')) {
        if (Array.isArray(orgIdMatches) || Array.isArray(orgIdDoesNotMatch)) {
          const organizationStore = rootStore.organizationStore;
          const activeOrgId = organizationStore.activeOrgId;
          if (Array.isArray(orgIdMatches)) {
            return orgIdMatches.some((regexStr) => activeOrgId.match(regexStr));
          }
          return orgIdDoesNotMatch.some((regexStr) => activeOrgId.match(regexStr));
        }
      }
      return true;
    }

    async function initialize() {
      try {
        initializeFeature();
        feature.registerHandler(featureHandler);
        await feature.refresh();
        setFeatureFlagsLoaded(true);
        log.info('Features loaded');
      } catch (error) {
        log.error('Error intializing feature cache:', error);
        handleError(error);
      }
    }

    initialize();
  }, [configurationLoaded, configuration, authenticationLoaded, handleError]);

  // Memoize the value of data
  const contextValue = useMemo(() => ({featureFlagsLoaded}), [featureFlagsLoaded]);

  return (
    configurationLoaded && (
      <FeatureContext.Provider value={contextValue}>{children}</FeatureContext.Provider>
    )
  );
};

FeatureProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useFeature = () => useContext(FeatureContext);

export {FeatureProvider, useFeature};
