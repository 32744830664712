import {configStore, feature} from '@admin-tribe/acsc';
import {Flex, Link} from '@adobe/react-spectrum';
import LinkOutIcon from '@spectrum-icons/workflow/LinkOut';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import ApiIntegrationUser from 'core/api-integration/ApiIntegrationUser';

// An external link to the Adobe Developer's Console (formerly known as the Adobe I/O Console).
const AdobeioConsoleLink = ({member}) => {
  const intl = useIntl();
  const namespace = 'common.userDetailsHeader.integrations';

  let linkToAdobeio = member.deepLink;
  if (!feature.isEnabled('temp_devconsole_deeplink')) {
    const adobeioConsoleConfig = configStore.get('services.adobeioConsole');
    const url = adobeioConsoleConfig?.url;

    linkToAdobeio = url;
    if (url && member.integrationOrgId && member.integrationId) {
      linkToAdobeio = `${url}/integrations/${member.integrationOrgId}/${member.integrationId}/overview`;
    }
  }

  // Try to render the link to the specific integration on the developer console but fallback to the top-level.

  return (
    linkToAdobeio && (
      <Flex
        alignItems="center"
        columnGap="size-100"
        data-testid="adobeio-console-link"
        direction="row"
      >
        <Link>
          <a
            aria-label={intl.formatMessage({
              id: `${namespace}.viewOnAdobeio.ariaLabel`,
            })}
            href={linkToAdobeio}
            rel="noreferrer"
            target="_blank"
          >
            {intl.formatMessage({
              id: `${namespace}.viewOnAdobeio`,
            })}
          </a>
        </Link>
        <LinkOutIcon size="S" />
      </Flex>
    )
  );
};

AdobeioConsoleLink.propTypes = {
  /**
   * The API integration user which should have properties integrationOrgId and integrationId.
   */
  member: PropTypes.instanceOf(ApiIntegrationUser),
};

export default AdobeioConsoleLink;
