import {EVENT_ACTION, dispatchPageAnalytics, dispatchUiEventAnalytics} from '@admin-tribe/acsc';

import {NO_ASSIGNMENT_ID} from 'features/products/product-migration-table/ProductMigrationTable.constants';
import {WIZARD_STEPS} from 'features/users/components/offer-switch-migration/OfferSwitchMigrationConstants';

/**
 * @description Trigger analytics event for unexpected errors
 * @param {Array<Object>} [options.eligibleMigrations] List of eligible migrations (see OfferSwitchMigrationContext - eligibleMigrations)
 * @param {String} options.eventAction Analytics event action
 * @param {String} options.errorDetail Error detail
 * @param {Array<Object>} [options.sourceProducts] List of source products
 * @param {Array<Object>} [options.targetProducts] List of target products
 */
const dispatchErrorAnalytics = ({
  eligibleMigrations,
  errorDetail,
  eventName,
  sourceProducts,
  targetProducts,
}) => {
  const analyticsData = {
    error: {
      // Store the error detail in error name because offer migration has only generic error handlers
      clientErrorName: errorDetail,
    },
    eventAction: EVENT_ACTION.FAILED,
    eventName,
  };

  if (eligibleMigrations && sourceProducts && targetProducts) {
    analyticsData.productList = {
      items: getAnalyticsProductList({eligibleMigrations, sourceProducts, targetProducts}),
    };
  }

  dispatchUiEventAnalytics(analyticsData);
};

/**
 * @description Trigger analytics state change based on wizard step
 * @param {Array<Object>} [options.eligibleMigrations] List of eligible migrations (see OfferSwitchMigrationContext - eligibleMigrations)
 * @param {Number} options.wizardStep Wizard step from one of WIZARD_STEPS constants
 * @param {Array<Object>} [options.sourceProducts] List of source products
 * @param {Array<Object>} [options.targetProducts] List of target products
 */
const dispatchWizardStepAnalytics = ({
  eligibleMigrations,
  sourceProducts,
  targetProducts,
  wizardStep,
}) => {
  let name;

  switch (wizardStep) {
    case WIZARD_STEPS.INTRO:
      name = 'reviewPreMigrationDetails';
      break;

    case WIZARD_STEPS.ASSIGN_PRODUCTS:
      name = 'reviewLicenseAssignments';
      break;

    case WIZARD_STEPS.SUMMARY:
      name = 'confirmLicenseAssignments';
      break;

    default:
      name = 'unknown';
  }

  dispatchPageAnalytics({
    name: `frictionlessOffer:${name}`,
    productList: {
      items: getAnalyticsProductList({eligibleMigrations, sourceProducts, targetProducts}),
    },
  });
};

/**
 * @description Trigger button press analytics
 * @param {Array<Object>} options.eligibleMigrations List of eligible migrations (see OfferSwitchMigrationContext - eligibleMigrations)
 * @param {String} options.eventAction Analytics event action
 * @param {Array<Object>} options.sourceProducts List of source products
 * @param {Array<Object>} options.targetProducts List of target products
 */
const dispatchProductListAnalytics = ({
  eligibleMigrations,
  eventAction,
  eventName = EVENT_ACTION.CLICK,
  sourceProducts,
  targetProducts,
}) => {
  dispatchUiEventAnalytics({
    eventAction,
    eventName,
    productList: {
      items: getAnalyticsProductList({eligibleMigrations, sourceProducts, targetProducts}),
    },
  });
};

/**
 * @description Trigger click event when the user launches wizard from HVA
 * @param {String} sourceName Event source name
 * @param {Array<Object>} options.sourceProducts List of source products
 * @param {Array<Object>} options.targetProducts List of target products
 */
const dispatchHvaClickAnalytics = ({sourceName, sourceProducts, targetProducts}) => {
  dispatchUiEventAnalytics({
    eventAction: `frictionlessOffer:HVA:${sourceName}`,
    eventName: EVENT_ACTION.CLICK,
    productList: {
      items: getAnalyticsProductList({sourceProducts, targetProducts}),
    },
  });
};

/**
 * @description Trigger analytics when HVA is loaded and the org is eligible for migration
 * @param {Array<Object>} options.sourceProducts List of source products
 * @param {Number} options.sourceProducts[n].count Available product count
 * @param {String} options.sourceProducts[n].displayName Product display name
 * @param {String} options.sourceProducts[n].id Product offer ID
 * @param {Array<Object>} options.targetProducts List of target products
 * @param {Number} options.targetProducts[n].count Available product count
 * @param {String} options.targetProducts[n].displayName Product display name
 * @param {String} options.targetProducts[n].id Product offer ID
 */
const dispatchHvaLoadedAnalytics = (sourceProducts, targetProducts) => {
  const totalLicenses = targetProducts.reduce((total, product) => total + product.count, 0);
  const impression = `HVA Banner|FrictionlessOffer|${totalLicenses}|Admin Console`;

  dispatchUiEventAnalytics({
    eventAction: 'hvaBannerLoad',
    eventName: EVENT_ACTION.LOADED,
    interaction: {impression},
    productList: {
      items: getAnalyticsProductList({sourceProducts, targetProducts}),
    },
  });
};

/* Private methods */

function getAnalyticsProductList({eligibleMigrations, sourceProducts, targetProducts}) {
  const analyticsProducts = {};

  sourceProducts?.forEach((product) => {
    analyticsProducts[product.id] = generateProductData({licenseType: 'Expiring', product});
  });

  targetProducts?.forEach((product) => {
    analyticsProducts[product.id] = generateProductData({licenseType: 'New', product});
  });

  if (eligibleMigrations) {
    // HVA shouldn't trigger unassigned products
    analyticsProducts[NO_ASSIGNMENT_ID] = {
      numberUnassigned: 0,
      productName: 'UnassignedProduct',
    };

    eligibleMigrations.forEach((migration) => {
      // Assume no target product as no assignment
      const selectedTargetId = migration.selectedTargetId || NO_ASSIGNMENT_ID;

      if (selectedTargetId === NO_ASSIGNMENT_ID) {
        analyticsProducts[selectedTargetId].numberUnassigned += 1;
      } else {
        analyticsProducts[selectedTargetId].numberSelected += 1;
      }
    });
  }

  return Object.values(analyticsProducts);

  ////////

  function generateProductData({licenseType, product}) {
    return {
      licenseCount: product.count,
      licenseType,
      numberSelected: 0,
      offer_id: product.id,
      productName: product.displayName,
    };
  }
}

export {
  dispatchErrorAnalytics,
  dispatchHvaClickAnalytics,
  dispatchHvaLoadedAnalytics,
  dispatchProductListAnalytics,
  dispatchWizardStepAnalytics,
};
