import {ConsumableUsageList} from '@admin-tribe/acsc';
import {OverlayWait} from '@admin-tribe/acsc-ui';
import {TableSection} from '@pandora/react-table-section';
import PropTypes from 'prop-types';
import React from 'react';

import useJilModelList from 'common/hooks/useJilModelList';

/**
 * @description Table for displaying lists of product group products that are
 *   consumable (e.g. - credit packs). Should be used with a back-end API that
 *   supports pagination (not transactions, see:
 *   https://jira.corp.adobe.com/browse/DCES-4253556). Should not be used to
 *   display non-consumable grouped product data
 * @param {Consumable} consumable - summary to build table data
 * @param {ProductGroupProductList} productGroup - list to build table row names
 *   from
 */
const PaginatedConsumablesListTableSection = ({children, hasLoadingError = false, usageList}) => {
  const getInitStateOptions = () => ({
    pageNumber: usageList.pagination.currentPage,
    pageSize: usageList.pagination.pageSize,
    sortExpression: usageList.sort.expression,
    sortOrder: usageList.sort.order,
  });
  const getListOptions = () => ({
    items: usageList.items,
    parentConsumable: usageList.parentConsumable,
    usageTotal: usageList.getTotalItemCount(),
  });
  const getSortFields = () => ({expirationDate: 'expiration_date', startDate: 'start_date'});

  const {
    error: listError,
    dispatchTableSectionChange: onTableSectionChange,
    isLoading: isLoadingList,
    list: consumableUsageList,
  } = useJilModelList({
    initStateOptions: getInitStateOptions(),
    ListClassRef: ConsumableUsageList,
    listOptions: getListOptions(),
    sortFields: getSortFields(),
  });

  return (
    <OverlayWait isLoading={isLoadingList} showContent size="M">
      <TableSection
        getItemKey={(item) => item.id}
        isServerError={hasLoadingError || (usageList && listError)} // either error in parent or parent loaded usage list and error fetching consumables page
        items={consumableUsageList?.items ?? []}
        onTableSectionChange={onTableSectionChange}
        pageNumber={consumableUsageList?.pagination.currentPage}
        totalPages={consumableUsageList?.pagination.totalPages}
      >
        {children}
      </TableSection>
    </OverlayWait>
  );
};

PaginatedConsumablesListTableSection.propTypes = {
  /**
   * Table to show, must be either a ConsumablesListTable
   */
  children: PropTypes.element.isRequired,
  /**
   * If error loading table data this should be true, else false.
   * Defaults to false
   */
  hasLoadingError: PropTypes.bool,
  /**
   * ConsumableUsageList to build table data from
   */
  usageList: PropTypes.instanceOf(ConsumableUsageList).isRequired,
};

export default PaginatedConsumablesListTableSection;
