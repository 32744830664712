import {StatusLightWithContextualHelp} from '@admin-tribe/acsc-ui';
import {DrawerTrigger} from '@pandora/react-drawer';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

/**
 * NoAccessDrawerTriggerContainer is a container component that wraps the UpdatedUserStatusContextualHelp
 * and provides a DrawerTrigger to handle opening and closing the drawer.
 *
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.drawer - The content to be rendered within the drawer.
 * @param {Object} props.statusLightProps - The props to be passed to UpdatedUserStatusContextualHelp's StatusLight.
 * @param {boolean} props.hasContextualHelp - Flag indicating whether contextual help is needed or not.
 * @returns {React.ReactNode} - Rendered NoAccessDrawerTriggerContainer component.
 */
const NoAccessDrawerTriggerContainer = ({drawer, statusLightProps, hasContextualHelp}) => {
  const intl = useIntl();
  return (
    <DrawerTrigger>
      {(open) => (
        <StatusLightWithContextualHelp
          hasContextualHelp={hasContextualHelp}
          onLinkPressed={open}
          statusLightProps={statusLightProps}
          statusLightText={intl.formatMessage({id: 'common.userProvisioningStatus.unprovisioned'})}
        />
      )}
      {drawer}
    </DrawerTrigger>
  );
};

NoAccessDrawerTriggerContainer.propTypes = {
  drawer: PropTypes.node,
  hasContextualHelp: PropTypes.bool.isRequired,
  statusLightProps: PropTypes.shape({
    variant: PropTypes.string.isRequired,
  }).isRequired,
};

export default NoAccessDrawerTriggerContainer;
