import {ORGANIZATION_MARKET_SEGMENT, feature} from '@admin-tribe/acsc';
import {ButtonLink} from '@admin-tribe/acsc-ui';
import {Text} from '@adobe/react-spectrum';
import {GoUrl} from '@pandora/react-go-url';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';

/**
 * AddUsersToOrgModalDescription component.
 */
const AddUsersToOrgModalDescription = ({onPressHandler}) => {
  const intl = useIntl();
  const marketSegment = rootStore.organizationStore.activeOrg.marketSegment;
  const showB2BFreeMessage =
    feature.isEnabled('temp_b2b_free_provisioning') &&
    marketSegment === ORGANIZATION_MARKET_SEGMENT.COMMERCIAL &&
    rootStore.organizationStore.productList.freeItems?.length > 0;

  const descriptionMessage = showB2BFreeMessage
    ? 'common.addUsersToOrgModal.description2FreeOffer'
    : 'common.addUsersToOrgModal.description2';

  return (
    <Text data-testid="user-info-text">
      {showB2BFreeMessage
        ? intl.formatMessage(
            {id: 'common.addUsersToOrgModal.descriptionFreeOffer'},
            {
              goUrl: (urlText) => (
                <GoUrl name="complimentary_membership" noWrap={false}>
                  {urlText}
                </GoUrl>
              ),
            }
          )
        : intl.formatMessage({id: 'common.addUsersToOrgModal.description1'})}

      <br />
      {intl.formatMessage(
        {id: descriptionMessage},
        {
          // eslint-disable-next-line react/forbid-elements -- zakn to update
          b: (chunks) => <b>{chunks}</b>,
          link: ([content]) => <ButtonLink onPress={onPressHandler}>{content}</ButtonLink>,
        }
      )}
    </Text>
  );
};

AddUsersToOrgModalDescription.propTypes = {
  onPressHandler: PropTypes.func,
};

export default AddUsersToOrgModalDescription;
