import {CONTRACT_BILLING_FREQUENCY, FULFILLABLE_ITEM_CHARGING_UNIT} from '@admin-tribe/acsc';
import {IconHeaderDescription, ImageIcon, TruncatedText} from '@admin-tribe/acsc-ui';
import {Flex, Heading, View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';

import SafePrice from 'common/components/safe-price/SafePrice';

import styles from './CartItem.pcss';

// Basic cart item wrapper intended for use with purchase, renewal and cancel flows.
// Built for common styling across all usages.
const CartItem = ({
  children,
  currency,
  perUnit,
  price,
  productIcon,
  productName,
  subtitle,
  recurrenceTerm,
}) => (
  <View
    backgroundColor="static-white"
    paddingX="size-300"
    paddingY="size-200"
    // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- to add box shadow
    UNSAFE_className={styles['cart-item']}
  >
    <Flex direction="column" gap="size-130">
      <IconHeaderDescription
        IconComponent={<ImageIcon alt="" size="L" src={productIcon} />}
        subtitle={
          subtitle || (
            <SafePrice
              currency={currency}
              perUnit={perUnit}
              price={price}
              recurrenceTerm={recurrenceTerm}
            />
          )
        }
        title={
          <Heading level={4} margin="size-0">
            <TruncatedText>{productName}</TruncatedText>
          </Heading>
        }
      />
      {children}
    </Flex>
  </View>
);

CartItem.propTypes = {
  /**
   * Nodes for intended for the different variations of cart items across different workflows.
   */
  children: PropTypes.node,
  /**
   * The currency object with price display information
   */
  currency: PropTypes.shape({
    /**
     * The format string in which to display the currency.
     */
    formatString: PropTypes.string.isRequired,
    /**
     * True to include decimal value of the currency, false to only use whole values.
     */
    usePrecision: PropTypes.bool,
  }),
  /**
   * The charging unit for an item. e.g. license or transactions
   */
  perUnit: PropTypes.oneOf(Object.values(FULFILLABLE_ITEM_CHARGING_UNIT)),
  /**
   * The raw number of the price in decimal form
   */
  price: PropTypes.number,
  /**
   * The icon url string of the product
   */
  productIcon: PropTypes.string.isRequired,
  /**
   * The name of the product
   */
  productName: PropTypes.string.isRequired,
  /**
   * The billing frequency of current product i.e. monthly or annually
   */
  recurrenceTerm: PropTypes.oneOf(Object.values(CONTRACT_BILLING_FREQUENCY)),
  /**
   * Any component to be displayed in the subtitle, usually price component
   */
  subtitle: PropTypes.node,
};

export default CartItem;
