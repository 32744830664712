import {AuthenticatedUser, feature, hasPackageSupport} from '@admin-tribe/acsc';

import rootStore from 'core/RootStore';
import PackagesEntitlementsService from 'features/packages/services/PackagesEntitlementsService';

/**
 * @description Method to determine if user has access to Packages related content.
 *
 * @returns {Boolean} true if user has access
 */
function canViewPackages() {
  if (rootStore.organizationStore.isActiveOrgDeveloper) {
    return false;
  }
  const orgId = rootStore.organizationStore.activeOrgId;
  const currentUserRoles = AuthenticatedUser.get().getRoles();

  if (
    !currentUserRoles.isOrgAdminForOrg(orgId) &&
    !currentUserRoles.isDeploymentAdminForOrg(orgId) &&
    (feature.isDisabled('temp_adobe_agent_access') ||
      !currentUserRoles.isActingAsAdobeAgentForOrg(orgId))
  ) {
    return false;
  }

  return hasPackageSupport(rootStore.organizationStore.productList);
}

/**
 * @description Method to determine if user has access to Servers related content.
 *
 * @returns {Promise} resolves to true if servers tab should be shown
 */
function canViewServers() {
  return PackagesEntitlementsService.showServersTab();
}

/**
 * @description Method to determine if user has access to Templates related content.
 *
 * @returns {Promise} resolves to true if templates tab should be shown
 */
function canViewTemplates() {
  return PackagesEntitlementsService.showTemplatesTab();
}

export {canViewPackages, canViewServers, canViewTemplates};
