// ***********************************************************************
// ADOBE CONFIDENTIAL
// ___________________
//
// Copyright 2024 Adobe
// All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains
// the property of Adobe and its suppliers, if any. The intellectual
// and technical concepts contained herein are proprietary to Adobe
// and its suppliers and are protected by all applicable intellectual
// property laws, including trade secret and copyright laws.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Adobe.
// ************************************************************************
import {EVENT_ACTION, dispatchUiEventAnalytics} from '@admin-tribe/acsc';
import {
  TNO_ADD_PRODUCT_CLI,
  isTnoOfferExistingOrgProduct,
} from '@pandora/react-tno-reject-product-assign-modal';
import React, {useCallback, useState} from 'react';

import rootStore from 'core/RootStore';
import chatProvider from 'core/services/chat/chatProvider';
import {
  ADD_PRODUCT_STEPS,
  ADD_PRODUCT_WORKFLOWS,
} from 'features/products/components/add-product-modal-wrapper/AddProduct.constants';
import AddProductModalWrapper from 'features/products/components/add-product-modal-wrapper/AddProductModalWrapper';

import {useTnoRejectProductAssignRequests} from '../../hooks/useTnoRejectProductAssignRequests';

import ProductRequestsPage from './ProductRequestsPage';

const REQUEST_ACCESS_ANALYTICS_PAGE_NAME = 'requestAccess';
const REQUEST_ACCESS_BUY_MORE_ANALYTICS_EVENT_NAME = 'requestaccessbuymorecomplete';

// ONESIE-43019 - a change or misunderstanding of the data message format from Add Product Modal, support both
const getAnalyticsProductListItems = (data) => {
  // original message format seen before ONESIE-43019
  if (data?.products) {
    return data?.products?.map((product) => ({
      licenseCount: product?.quantity,
      offer_id: product?.offerId,
    }));
  }

  // new message format seen after ONESIE-43019
  const cartItems = data?.actions?.[0]?.actionMessage?.data?.cartItems;
  if (cartItems) {
    return cartItems?.map((product) => ({
      licenseCount: product?.quantity,
      offer_id: product?.offerId,
    }));
  }

  // we couldn't derive from the message so return an empty array
  return [];
};

/**
 * @returns A simple decorator component for the ProductRequestsPage that wraps it with the TNO reject product assign modal provider
 */
const ProductRequestsPageTnoWrapper = () => {
  // props
  const activeOrgProducts = rootStore.organizationStore.productList.items;

  // state
  const [showTnoAddProductModal, setShowTnoAddProductModal] = useState(false);
  const [tnoOfferRequestIds, setTnoOfferRequestIds] = useState([]);
  const [tnoOfferId, setTnoOffer] = useState();
  const [addProductsTrackingData, setAddProductsTrackingData] = useState(undefined);

  // hooks
  const {tnoContext} = useTnoRejectProductAssignRequests({
    orgId: rootStore.organizationStore.activeOrgId,
  });

  // callbacks
  const getAnalyticsImpression = useCallback(
    (requestIds) => `contextRequestIds:${requestIds && requestIds.join('|')}`,
    []
  );

  const onTnoPrimaryCTA = useCallback(
    ({offerId, requestIds}) /* ReviewProductRequestTnoCTAArgs */ => {
      setTnoOfferRequestIds(requestIds);
      setTnoOffer(offerId.trim());

      setAddProductsTrackingData(requestIds ? requestIds.join(',') : null);

      dispatchUiEventAnalytics({
        eventAction: 'promoOfferAccept',
        eventName: REQUEST_ACCESS_ANALYTICS_PAGE_NAME,
        interaction: {
          impression: getAnalyticsImpression(requestIds),
        },
      });

      setShowTnoAddProductModal(true);
    },
    [getAnalyticsImpression]
  );

  const onTnoSecondaryCTA = useCallback(
    ({requestIds}) /* ReviewProductRequestTnoCTAArgs */ => {
      dispatchUiEventAnalytics({
        eventAction: 'promoOfferReject',
        eventName: REQUEST_ACCESS_ANALYTICS_PAGE_NAME,
        interaction: {
          impression: getAnalyticsImpression(requestIds),
        },
      });

      setShowTnoAddProductModal(false);
    },
    [getAnalyticsImpression]
  );

  const onAddProductClose = useCallback(() => {
    setTnoOffer(undefined);

    dispatchUiEventAnalytics({
      eventAction: EVENT_ACTION.CLOSE,
      eventName: REQUEST_ACCESS_BUY_MORE_ANALYTICS_EVENT_NAME,
      interaction: {
        impression: getAnalyticsImpression(tnoOfferRequestIds),
      },
    });

    setShowTnoAddProductModal(false);
  }, [getAnalyticsImpression, tnoOfferRequestIds]);

  const onAddProductSuccess = useCallback(
    (data) => {
      setTnoOffer(undefined);

      // see ONESIE-42781: adminconsole:requestaccessbuymorecomplete:success
      dispatchUiEventAnalytics({
        eventAction: EVENT_ACTION.SUCCESS,
        eventName: REQUEST_ACCESS_BUY_MORE_ANALYTICS_EVENT_NAME,
        interaction: {
          impression: getAnalyticsImpression(tnoOfferRequestIds),
        },
        productList: {
          items: getAnalyticsProductListItems(data),
        },
      });

      setShowTnoAddProductModal(false);
    },
    [tnoOfferRequestIds, getAnalyticsImpression]
  );

  // NOTE See ONESIE-42752 for refactor of selection of best product
  // currently we will not use onTnoSelectBestProduct and rely on the default behavior
  //
  // If tnoContext is undefined, don't pass the handlers
  const tnoProps = tnoContext ? {onTnoPrimaryCTA, onTnoSecondaryCTA} : undefined;

  return (
    <>
      <ProductRequestsPage tnoProps={tnoProps} />

      {tnoContext && tnoOfferId && showTnoAddProductModal && (
        <AddProductModalWrapper
          chat={chatProvider}
          cli={TNO_ADD_PRODUCT_CLI}
          disabledFeatures="add_product_with_quick_assign"
          items={[
            {
              offerId: tnoOfferId,
              quantity: 1,
            },
          ]}
          onClose={onAddProductClose}
          onSuccess={onAddProductSuccess}
          step={
            isTnoOfferExistingOrgProduct(tnoContext, activeOrgProducts)
              ? ADD_PRODUCT_STEPS.REVIEW_ORDER
              : ADD_PRODUCT_STEPS.SELECT_PRODUCT
          }
          tracking={addProductsTrackingData}
          workflow={
            isTnoOfferExistingOrgProduct(tnoContext, activeOrgProducts)
              ? ADD_PRODUCT_WORKFLOWS.ADD_LICENSE
              : ADD_PRODUCT_WORKFLOWS.ADD_PRODUCT
          }
        />
      )}
    </>
  );
};

ProductRequestsPageTnoWrapper.propTypes = {};
export default ProductRequestsPageTnoWrapper;
