import {
  CONSUMABLE_SUMMARIZATION_SUMMARIZE_BY,
  ConsumableSummarizationList,
  Product,
} from '@admin-tribe/acsc';
import {Subpage} from '@admin-tribe/acsc-ui';
import {useAsyncModel} from '@pandora/react-async-model';
import PropTypes from 'prop-types';
import React, {useCallback} from 'react';
import {useParams} from 'react-router-dom';

import UnpaginatedConsumablesListTableSection from 'features/products/components/consumables-list-table/UnpaginatedConsumablesListTableSection';
import TransactionsListTable from 'features/products/components/transactions-list-table/TransactionsListTable';

/**
 *  The Product Transactions Subpage component.
 */
const ProductTransactionsSubpage = ({product}) => {
  const {orgId} = useParams();

  const fetchConsumable = useCallback(async () => {
    const summarizationList = await ConsumableSummarizationList.get({
      include_usage: false,
      organization_id: orgId,
      summarize_by: CONSUMABLE_SUMMARIZATION_SUMMARIZE_BY.LICENSE_ID,
    });
    // First item returned will be summary consumable, so grab head and return
    const [consumablesForSummary] = summarizationList.getConsumablesForSummaryId(product.id, {
      summarize_by: CONSUMABLE_SUMMARIZATION_SUMMARIZE_BY.LICENSE_ID,
    });
    return consumablesForSummary;
  }, [orgId, product.id]);
  const {model: consumable, error, isLoading} = useAsyncModel({loadFn: fetchConsumable});

  const fetchUsageList = useCallback(() => {
    if (!consumable) {
      return undefined;
    }
    const {consumableUsageList} = consumable;
    consumableUsageList.sortBy('expiration_date');
    return consumableUsageList.refresh();
  }, [consumable]);
  const {
    model: usageList,
    error: errorUsage,
    isLoading: isLoadingUsage,
  } = useAsyncModel({loadFn: fetchUsageList});

  return (
    <Subpage data-testid="product-transactions-subpage" isLoading={isLoading}>
      {(usageList || errorUsage || error) && (
        <UnpaginatedConsumablesListTableSection
          hasLoadingError={!!errorUsage || !!error}
          isLoading={isLoadingUsage}
          usageList={usageList}
        >
          <TransactionsListTable product={product} />
        </UnpaginatedConsumablesListTableSection>
      )}
    </Subpage>
  );
};

ProductTransactionsSubpage.propTypes = {
  /**
   * The FiProductGroupList for the grouped org consumables.
   */
  product: PropTypes.instanceOf(Product).isRequired,
};

export default ProductTransactionsSubpage;
