import {getProductDisplayName} from '@admin-tribe/acsc-ui';

import ProductGroupProductList from './ProductGroupProductList';
import {findFulfillableItem} from './ProductGroupProductListUtils';

/**
 * @class FiProductGroupProductList
 * @description Model for an individual fulfillable item product group product
 *   list (FiProductGroupProductList). The fulfillable item product group
 *   product list is used as a short-hand to group similar products by their
 *   fulfillable items. This can make reasoning about data across products or
 *   displaying data across products much easier
 */
class FiProductGroupProductList extends ProductGroupProductList {
  /**
   * @description Constructor for FiProductGroupProductList model Objects.
   *   This assumes there is at least one item and they are all the same org type, ie direct, indirect, etc.
   * @param {Object} options - options object
   * @param {String} options.items - The products in this group.
   * @param {String} options.fiCode - The fulfillable item code for this group of fulfillable items.
   */
  constructor(options) {
    const fulfillableItem = findFulfillableItem(options.items, options.fiCode);

    super({
      assets: fulfillableItem?.assets,
      id: options.fiCode, // unique id for this productGroup which will be used as the cache key
      isConsumable: true,
      items: options.items,
      links: fulfillableItem?.links,
      longName: fulfillableItem?.longName,
    });

    this.longDescription = fulfillableItem?.longDescription;
  }

  /**
   * @description Method to return the first matching fi.
   * @returns {Object} first fulfillable item in this list
   */
  findFulfillableItem() {
    return findFulfillableItem(this.items, this.id);
  }

  /**
   * @description Method to construct a buy more link data structure from this
   *   fulfillable item group product list. This is temporary until Admin
   *   Console supports purchasing credit packs for direct orgs.
   * @param {Object} intl - react-intl to translate text
   * @returns {Object|undefined} structure with href and text properties if fi
   *   group is for stock credits, otherwise undefined
   */
  getBuyMoreLink(intl) {
    if (this.isStockCredit()) {
      const text = intl.formatMessage({
        id: 'core.services.product.productGroup.productList.fiProductGroupProductList.buyMore',
      });
      return {href: 'https://stock.adobe.com/teams', text};
    }
    return undefined;
  }

  /**
   * @description Method to retrieve a display name for an offer id. There
   *   should always be a match but if not, fallback to the name for the
   *   "grouped" product.
   * @param {Object} intl - react intl reference for translations
   * @param {String} offerId - unique id of offer to get name for
   * @returns {String|undefined} the name for the individual product in the
   *   group with the matching offerId, else undefined if no matching product
   */
  getDisplayNameForOfferId(intl, offerId) {
    const product = this.items.find((item) => item.offerId === offerId);
    if (product) {
      return getProductDisplayName(intl, product);
    }
    return this.longName;
  }

  /**
   * @description Method to retrieve any long description set for this
   *   fulfillable item product group product list.
   * @returns {String} this product group product list's long description
   */
  getLongDescription() {
    return this.longDescription;
  }
}

export default FiProductGroupProductList;
