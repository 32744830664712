import {OverlayWait} from '@admin-tribe/acsc-ui';
import {Flex, Heading, useDialogContainer} from '@adobe/react-spectrum';
import {ModalButtonGroup, ModalContent, ModalDialog} from '@pandora/react-modal-dialog';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import UploadFile from 'features/settings/common/components/upload-file/UploadFile';
import {useSetupCertificatesContext} from 'features/settings/components/setup-directory-authentication/SetupCertificatesContext';

const UploadCsrModal = ({handleOnCompleteCsr}) => {
  const intl = useIntl();
  const dialog = useDialogContainer();
  const {state} = useSetupCertificatesContext();
  const {isLoading} = state;
  const [selectedFile, setSelectedFile] = useState(null);

  return (
    <OverlayWait isLoading={isLoading} showContent>
      <ModalDialog size="M">
        <Heading data-testid="upload-csr-modal-header" marginStart="size-400">
          <FormattedMessage id="settings.certificates.uploadCsrModal.title" />
        </Heading>

        <ModalContent showDivider={false}>
          <Flex alignItems="flex-start" direction="column" marginTop="size-125" rowGap="16px">
            <FormattedMessage id="settings.certificates.uploadCsrModal.text" />

            <UploadFile selectedFile={selectedFile} setSelectedFile={setSelectedFile} />
          </Flex>
        </ModalContent>

        <ModalButtonGroup
          cancelLabel={intl.formatMessage({id: 'common.modal.buttons.close'})}
          ctaLabel={intl.formatMessage({
            id: 'settings.certificates.uploadCsrModal.buttons.complete',
          })}
          isCtaDisabled={selectedFile === null || isLoading}
          onCancel={() => dialog.dismiss()}
          onCta={() => handleOnCompleteCsr(selectedFile)}
        />
      </ModalDialog>
    </OverlayWait>
  );
};

UploadCsrModal.propTypes = {
  handleOnCompleteCsr: PropTypes.func.isRequired,
};

export default UploadCsrModal;
