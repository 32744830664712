import {showError as showErrorToast, showSuccess as showSuccessToast} from '@admin-tribe/acsc-ui';
import {Text, useDialogContainer} from '@adobe/react-spectrum';
import {ModalAlertDialog} from '@pandora/react-modal-dialog';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import {useDomainEnforcementContext} from 'features/settings/components/directory/domain-enforcement/DomainEnforcementContext';
import useDomainEnforcement from 'features/settings/hooks/api/useDomainEnforcement';

const RemoveFromExceptionListConfirmationModal = ({userEmails, onConfirm}) => {
  const intl = useIntl();
  const dialog = useDialogContainer();
  const {deleteExceptions} = useDomainEnforcement();
  const {domainEnforcement, reloadDomainEnforcementExceptionList} = useDomainEnforcementContext();
  const [isLoading, setIsLoading] = useState(false);

  const handleRemoveFromExceptionList = async () => {
    setIsLoading(true);
    try {
      await deleteExceptions({
        directoryId: domainEnforcement.authSrc,
        emails: userEmails,
      });

      showSuccessToast(
        intl.formatMessage(
          {
            id: 'settings.domainEnforcement.removeUsersExceptionListModal.toast.success',
          },
          {count: userEmails.length}
        )
      );

      reloadDomainEnforcementExceptionList();
      onConfirm();
      dialog.dismiss();
    } catch (error) {
      showErrorToast(
        intl.formatMessage(
          {
            id: 'settings.domainEnforcement.removeUsersExceptionListModal.toast.error',
          },
          {count: userEmails.length}
        )
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalAlertDialog
      cancelLabel={intl.formatMessage({
        id: 'common.modal.buttons.cancel',
      })}
      isLoading={isLoading}
      onClose={() => dialog.dismiss()}
      onPrimaryAction={handleRemoveFromExceptionList}
      primaryActionLabel={intl.formatMessage({
        id: 'common.modal.buttons.remove',
      })}
      title={intl.formatMessage({
        id: 'settings.domainEnforcement.removeUsersExceptionListModal.title',
      })}
      variant="destructive"
    >
      <Text>
        {intl.formatMessage({
          id: 'settings.domainEnforcement.removeUsersExceptionListModal.description',
        })}
      </Text>
    </ModalAlertDialog>
  );
};

RemoveFromExceptionListConfirmationModal.propTypes = {
  /**
   * The callback for exception list removal confirmation
   */
  onConfirm: PropTypes.func,
  /**
   * The emails of the users to be remove from exception list
   */
  userEmails: PropTypes.arrayOf(PropTypes.string).isRequired,
};
RemoveFromExceptionListConfirmationModal.displayName = 'RemoveFromExceptionListConfirmationModal';

export default RemoveFromExceptionListConfirmationModal;
