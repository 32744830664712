import {
  User,
  csvBlobTransformer,
  downloadExportData,
  fetchAndDownloadExportData,
  hasDomain,
  log,
} from '@admin-tribe/acsc';
import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';

import API_CONSTANTS from '../../api/ApiConstants';

const {CLIENT_ID} = API_CONSTANTS;
const DEFAULT_FILE_NAME = 'storage_file.csv';
const LARGE_FILE_ERROR_TEXT = 'Response too large';

class StorageFile {
  /**
   * @description Creates a new StorageFile for use.
   *
   * @param {Object} options Initialization Object (params described below)
   * @param {User} options.createdBy The user who created the storage file
   * @param {String} options.id A StorageFile's ID
   * @param {String} options.linkPath A link path to download storage file
   * @param {String} options.name A StorageFile's name
   * @param {String} options.path the path of the StorageFile
   * @param {StorageQuota} options.quota the storage quota of the StorageFile
   * @param {Date} options.repositoryCreatedDate The creation date of the StorageFile
   * @param {Date} options.repositoryLastModifiedDate The last modification date of the StorageFile
   * @param {String} options.state the state of the StorageFile
   * @param {String} options.type the type of the file which StorageFile is stored
   */
  constructor(options = {}) {
    // Cloned to avoid issues when updating the nested object items
    const clonedOptions = cloneDeep(options);
    const {createdBy, name} = clonedOptions;
    Object.assign(this, {
      ...clonedOptions,
      createdBy: createdBy ? new User(createdBy) : undefined,
      name: name ? decodeURI(name) : undefined,
    });
  }

  /**
   * @description Method to download the storage file.
   *
   * @returns {Promise} promise - resolved when the file is downloaded
   */
  async download() {
    let errorMessage, response;
    if (!this.linkPath) {
      errorMessage = 'The StorageFile link path is undefined.';
    } else if (!hasDomain(this.linkPath, 'adobe.io')) {
      errorMessage = `The link path, ${this.linkPath}, contains an invalid domain.`;
    }
    if (errorMessage) {
      log.error(errorMessage);
      return Promise.reject(new Error(errorMessage));
    }

    try {
      response = await fetchAndDownloadExportData({
        clientId: CLIENT_ID,
        fileName: this.name || DEFAULT_FILE_NAME,
        url: this.linkPath,
      });
    } catch (error) {
      if (error.response?.data?.title === LARGE_FILE_ERROR_TEXT) {
        return this.downloadLargeFile(error.response.headers.location);
      }
      log.error('Failed to fetch and download the storage file. Error: ', error);
      return Promise.reject(error);
    }
    return response;
  }

  /**
   * @description Method to download the large storage file.
   * @param {String} largeFileLinkPath AWS S3 link to the large storage file.
   *
   * @returns {Promise} promise - resolved when the file is downloaded
   */
  async downloadLargeFile(largeFileLinkPath) {
    let errorMessage, response;

    if (!largeFileLinkPath) {
      errorMessage = 'The link to download large storage file is undefined.';
      log.error(errorMessage);
      return Promise.reject(new Error(errorMessage));
    }

    try {
      response = await axios.get(largeFileLinkPath, {
        headers: {
          'X-Api-Key': CLIENT_ID,
        },
      });
    } catch (error) {
      log.error('Failed to fetch and download large storage file. Error: ', error);
      return Promise.reject(error);
    }
    const fileName = this.name || DEFAULT_FILE_NAME;
    return downloadExportData(csvBlobTransformer(response)?.data?.file, fileName);
  }
}

export {DEFAULT_FILE_NAME};

export default StorageFile;
