import {fraudMitigation, log} from '@admin-tribe/acsc';
import {useEffect} from 'react';
import {useErrorHandler} from 'react-error-boundary';

import {useConfiguration} from '../providers/configuration/ConfigurationProvider';

/**
 * Custom hook for fraud mitigation.
 */
const useFraudMitigation = () => {
  const {configuration, configurationLoaded} = useConfiguration();
  const handleError = useErrorHandler();

  // Fetch data from the Feature file
  useEffect(() => {
    const fetchData = async () => {
      if (!configurationLoaded) return;

      try {
        // TODO: refactor the configure method
        fraudMitigation.configure(configuration.services.fraudMitigation);
        await fraudMitigation.initialize();
      } catch (error) {
        log.error('Failed to initialize Fraud Mitigation:', error);
        // Need to ignore the reject from initialize() so the app continues to run
      }
    };

    fetchData();
  }, [configuration, handleError, configurationLoaded]);
};

export default useFraudMitigation;
