import {StatusEvents} from '@admin-tribe/acsc';
import {useAsyncModel} from '@pandora/react-async-model';
import {useCallback} from 'react';

/**
 * Hook that calls the statusEvents api to fetch a list of status events
 */
const useStatusEvents = () => {
  const loadStatusEvents = useCallback(async () => {
    const statusData = await StatusEvents.get();

    return statusData;
  }, []);

  const {model, isLoading, error} = useAsyncModel({
    loadFn: loadStatusEvents,
  });

  return {error, isLoading, statusEvents: model};
};

export default useStatusEvents;
