import {log} from '@admin-tribe/acsc';
import {showError, showSuccess} from '@admin-tribe/acsc-ui';
import PropTypes from 'prop-types';
import React, {createContext, useContext, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';

import Job from 'common/services/job/Job';
import {JOB_STATUS} from 'common/services/job/JobConstants';

/**
 * Provider meant for pre-processing a bulk operation job
 */
const JobContext = createContext({});

const useBulkOperationJob = () => useContext(JobContext);

const getIsJobStatusNegative = (job) =>
  job.itemSummary?.failureCount ||
  job.status === JOB_STATUS.FAILED ||
  job.status === JOB_STATUS.REJECTED;

const getJobVariant = (job) => {
  if (getIsJobStatusNegative(job)) {
    return 'negative';
  }
  switch (job.status) {
    case JOB_STATUS.COMPLETED:
    case JOB_STATUS.CANCELED:
      return 'positive';
    case JOB_STATUS.PROCESSING:
    case JOB_STATUS.QUEUED:
      return 'notice';
    default:
      return 'neutral';
  }
};

/**
 * Takes in a Job object and exposes data to help summarize the job status and results
 * as shown in the details drawer
 */
// eslint-disable-next-line @admin-tribe/admin-tribe/one-component-file -- processing job for display logic
const BulkOperationJobContext = ({
  children,
  detailFn,
  downloadResultsFn,
  job,
  licenseDeficitHrefFn,
  onCancelRequested,
}) => {
  const intl = useIntl();
  const [isCanceling, setIsCanceling] = useState(false);

  const value = useMemo(() => {
    // job values
    const displayName = intl.formatMessage({id: job.getOperationDisplayName()});

    // drawer content logic
    const canShowResults = job.canShowResults();
    const jobHasTerminated = job.hasTerminated();
    const startDate = job.getStarted(intl);

    // Inititiated by content
    const userInitiatingJob = job.getInitiatedBy();

    // Job failed summary content
    const getJobStatusReason = () =>
      intl.formatMessage({
        defaultMessage: job.statusReason,
        id: `common.bulkOperation.pageBanner.error.body.${job.statusReason || 'generic'}`, // reusing existing strings
      });

    // Job results summary content
    const errorCount = job.itemSummary?.failureCount;
    const errorSummary =
      errorCount > 0 &&
      intl.formatMessage(
        {id: 'bulkOperations.results.drawer.section.result.errored'},
        {errorCount}
      );

    const successCount = job.itemSummary?.successCount;
    const successSummary =
      successCount > 0 &&
      intl.formatMessage(
        {id: 'bulkOperations.results.drawer.section.result.completed'},
        {successCount}
      );
    // Just return a list so that localization and rendering can be done in one loop
    const errorDetails = job.itemSummary?.failureSummary ?? [];

    // Results detail sections content
    const jobStatus = job.status;

    // Status detail section content
    const getProcessingRate = () => {
      const avgDuration = job.itemSummary?.averageDurationMs;
      const avgDurationS = avgDuration ? 1000 / avgDuration : 0;
      const rate = avgDuration > 1000 ? avgDurationS.toFixed(2) : Math.floor(avgDurationS);

      return intl.formatMessage({id: 'bulkOperations.results.drawer.section.status.rate'}, {rate});
    };

    const getProgress = () => {
      const processed = job.itemSummary?.processedCount ?? 0;
      const total = job.itemSummary?.totalCount ?? 0;
      return intl.formatMessage(
        {id: 'bulkOperations.results.drawer.section.status.progress'},
        {processed, total}
      );
    };

    // drawer actions
    const jobId = job.id;

    const cancelJob = async () => {
      try {
        setIsCanceling(true);
        await job.cancel();

        showSuccess(
          intl.formatMessage({id: 'bulkOperations.results.status.actions.cancelProcessing.success'})
        );

        // signal that the cancel request was accepted
        onCancelRequested();
      } catch (error) {
        log.error(`failed to cancel processing for job ${jobId}`);
        showError(
          intl.formatMessage({id: 'bulkOperations.results.status.actions.cancelProcessing.error'})
        );
      } finally {
        setIsCanceling(false);
      }
    };

    const download = () => downloadResultsFn?.({jobId});
    const goToDetails = () => detailFn?.({jobId});
    const licenseDeficitHref = licenseDeficitHrefFn?.({jobId});

    // job status
    const isJobStatusNegative = getIsJobStatusNegative(job);

    const getJobStatusVariant = () => getJobVariant(job);

    const getJobStatusText = () =>
      errorCount
        ? intl.formatMessage({id: 'common.status.countFailed'}, {failureCount: errorCount})
        : intl.formatMessage({id: job.getStatusDisplayName()});

    const getIsCancelable = () => job.isCancelable();

    const isExportJob = job.isExportJob();
    const filename = job.getFilename();

    return {
      cancelJob,
      canShowResults,
      displayName,
      download,
      errorDetails,
      errorSummary,
      filename,
      getIsCancelable,
      getJobStatusReason,
      getJobStatusText,
      getJobStatusVariant,
      getProcessingRate,
      getProgress,
      goToDetails,
      isCanceling,
      isExportJob,
      isJobStatusNegative,
      jobHasTerminated,
      jobId,
      jobStatus,
      licenseDeficitHref,
      startDate,
      successSummary,
      userInitiatingJob,
    };
  }, [
    detailFn,
    downloadResultsFn,
    intl,
    isCanceling,
    job,
    licenseDeficitHrefFn,
    onCancelRequested,
  ]);
  return <JobContext.Provider value={value}>{children}</JobContext.Provider>;
};

BulkOperationJobContext.propTypes = {
  /**
   * Child components consuming BulkOperationJobContext
   */
  children: PropTypes.node,
  /**
   * Method that directs to the job details page for the current job
   */
  detailFn: PropTypes.func,
  /**
   * Callback to download job results and show the appropriate toasts
   */
  downloadResultsFn: PropTypes.func,
  /**
   * Bulk operation job to use for this context
   */
  job: PropTypes.instanceOf(Job),
  /**
   * Method that gets the href for the license deficit based on the job
   */
  licenseDeficitHrefFn: PropTypes.func,
  /**
   * Callback to signal when canceling has been confirmed to be requested
   */
  onCancelRequested: PropTypes.func.isRequired,
};

export {BulkOperationJobContext, useBulkOperationJob};
