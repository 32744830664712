import {Checkbox, Flex} from '@adobe/react-spectrum';
import {DividerPlacement, TitledSection} from '@pandora/react-titled-section';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

const AssetCredentialsSetting = ({CASettings, content, isDisabled, onSelection}) => {
  const intl = useIntl();
  const {titleKey, descriptionKey, apiKey} = content;
  return (
    <Flex direction="column" height="100%" marginStart="size-250">
      <TitledSection
        dividerPlacement={DividerPlacement.NONE}
        headingLevel={4}
        ruleVariant="S"
        title={intl.formatMessage({id: titleKey})}
      />

      <Checkbox
        data-testid={apiKey}
        isDisabled={isDisabled}
        isEmphasized
        isSelected={CASettings[apiKey]}
        marginBottom="size-50"
        onChange={(selection) => {
          onSelection(apiKey, selection);
        }}
      >
        <FormattedMessage id={descriptionKey} />
      </Checkbox>
    </Flex>
  );
};

AssetCredentialsSetting.propTypes = {
  /**
   * The content authenticity settings used to set the selected key.
   */
  CASettings: PropTypes.shape(PropTypes.objectOf(PropTypes.bool.isRequired).isRequired),
  /**
   * The data and copy needed for each setting.
   */
  content: PropTypes.shape({
    /**
     * The identifier key used in the api call.
     */
    apiKey: PropTypes.string.isRequired,
    descriptionKey: PropTypes.string.isRequired,
    /**
     * The localization key for the title of the setting.
     */
    titleKey: PropTypes.string.isRequired,
  }).isRequired,
  /**
   * Whether the setting is disabled.
   */
  isDisabled: PropTypes.bool,
  /**
   * The function handler when an option is selected.
   */
  onSelection: PropTypes.func.isRequired,
};

export default AssetCredentialsSetting;
