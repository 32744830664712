import {ViewDetailsDrawerTrigger, useStore} from '@admin-tribe/acsc-ui';
import {Content, DialogContainer, Header} from '@adobe/react-spectrum';
import {Drawer} from '@pandora/react-drawer';
import {useModalDialog} from '@pandora/react-modal-dialog';
import {observer} from 'mobx-react-lite';
import React, {useContext, useState} from 'react';
import {useIntl} from 'react-intl';

import CreatePackageModal from 'features/packages/components/create-package-modal/CreatePackageModal';
import {CreatePackageModalContextProvider} from 'features/packages/components/create-package-modal/CreatePackageModalContext';
import UpdatePackage from 'features/packages/components/update-package/UpdatePackage';
import CreatePackageModalStore from 'features/packages/stores/CreatePackageModalStore';

import {PackageSummaryDrawerContext} from '../PackageSummaryDrawerContextProvider';

import PackageSummaryDrawerContentBody from './package-summary-drawer-content-body/PackageSummaryDrawerContentBody';
import PackageSummaryDrawerContentHeader from './package-summary-drawer-content-header/PackageSummaryDrawerContentHeader';

const PackageSummaryDrawerContent = observer(({allPackagesStore}) => {
  const {isOpen, openModal, closeModal} = useModalDialog();
  const createPackageModalStore = useStore(() => new CreatePackageModalStore());
  const intl = useIntl();
  const {attributes} = useContext(PackageSummaryDrawerContext);
  const [showUpdatePackageDialog, setShowUpdatePackageDialog] = useState(false);

  const onUpdatePress = () => {
    setShowUpdatePackageDialog(true);
  };

  const onCustomizePress = () => {
    createPackageModalStore.setStoreValuesforCreatePackageOpen();
    openModal();
  };

  const openDrawer = (close) => (
    <Drawer aria-label="summary-drawer" id="package-summary-drawer">
      <Header marginBottom="size-300" marginTop="size-0">
        <PackageSummaryDrawerContentHeader
          allPackagesStore={allPackagesStore}
          closeDrawer={close}
          onCustomizePress={onCustomizePress}
          onUpdatePress={onUpdatePress}
        />
      </Header>
      <Content>
        <PackageSummaryDrawerContentBody />
      </Content>
    </Drawer>
  );

  return (
    <>
      <ViewDetailsDrawerTrigger
        aria-label={intl.formatMessage(
          {
            id: 'packages.summaryDrawer.viewDetails.label',
          },
          {packageName: attributes.packageName}
        )}
        offsetTop="var(--spectrum-global-dimension-size-700)"
        tooltipText={intl.formatMessage(
          {
            id: 'packages.summaryDrawer.viewDetails.label',
          },
          {packageName: attributes.packageName}
        )}
      >
        {(close) => openDrawer(close)}
      </ViewDetailsDrawerTrigger>
      <DialogContainer
        aria-label="update-package-dialog-container"
        id="update-package-dialog-container"
        onDismiss={() => setShowUpdatePackageDialog(false)}
      >
        {showUpdatePackageDialog && <UpdatePackage packageObj={attributes.package} />}
      </DialogContainer>

      <DialogContainer onDismiss={closeModal}>
        {isOpen && (
          <CreatePackageModalContextProvider createPackageModalStore={createPackageModalStore}>
            <CreatePackageModal onCancel={closeModal} />
          </CreatePackageModalContextProvider>
        )}
      </DialogContainer>
    </>
  );
});

export default PackageSummaryDrawerContent;
