import {eventBus, log} from '@admin-tribe/acsc';
import {showError, translateString} from '@admin-tribe/acsc-ui';

import rootStore from 'core/RootStore';

import {getAtoDownloadUrl, getAtoGenerationProgress, getServer} from '../api/tronData';
import {
  CREATE_SERVER,
  CREATION_STATUS_TO_SERVER_STATE,
  MILLISECONDS_IN_A_DAY,
  SERVERS_CONSTANTS,
  SERVER_API_MESSAGE,
} from '../packagesConstants';

import PackagesEntitlementsService from './PackagesEntitlementsService';

class PackagesLanServerService {
  constructor(options) {
    this.initModel(options);
  }

  /**
   * @description Method to download ATO file for server
   *
   * @returns {Promise} Promise indicating completion of API
   */
  async downloadAto() {
    try {
      eventBus.emit(CREATE_SERVER.EVENTS.DOWNLOAD_ATO);
      const response = await getAtoDownloadUrl(this.serverId);
      if (response?.data?.url) {
        if (typeof window !== 'undefined') window.open(response.data.url, '_self');
      }
      return Promise.resolve();
    } catch (error) {
      log.error(
        'PackagesLanServer.downloadAto(): Unable to get download URL from back-end. Error: ',
        error
      );
      return Promise.reject(error);
    }
  }

  /**
   * @description Method to fetch and process server creation progress
   *
   * @returns {Promise} Promise indicating completion of API
   */
  async fetchAndProcessServerProgress() {
    try {
      const atoGenerationProgressResponse = await getAtoGenerationProgress(this.serverId);
      if (
        atoGenerationProgressResponse.data.status === SERVER_API_MESSAGE.COMPLETE &&
        this.creationStatus !== SERVER_API_MESSAGE.COMPLETE
      ) {
        await this.refresh().finally(() => {
          this.downloadAto();
        });
      } else if (atoGenerationProgressResponse.data.status === SERVER_API_MESSAGE.ERROR) {
        showError(translateString({id: 'packages.errors.servers.errorGeneratingAto'}));
      }
      this.creationStatus = atoGenerationProgressResponse.data.status;
      eventBus.emit(CREATE_SERVER.EVENTS.SERVER_CREATION_PROGRESS, {
        serverObject: this,
      });
      return Promise.resolve();
    } catch (error) {
      showError(translateString({id: 'packages.errors.servers.errorGeneratingAto'}));
      return Promise.reject(error);
    }
  }

  /**
   * @description Method to get action state for server
   *
   * @returns {String} Action state: BUILDING / PREPARING / REAUTHORIZE
   */
  getActionState() {
    return (
      CREATION_STATUS_TO_SERVER_STATE[this.creationStatus] || SERVERS_CONSTANTS.STATE.REAUTHORIZE
    );
  }

  /**
   * @description Method to check if the server has hard quota
   *
   * @returns {Boolean} true if the server has hard quota
   */
  hasHardQuota() {
    return this.activationEnforcementType === SERVERS_CONSTANTS.QUOTA.HARD;
  }

  /**
   * @description Method to check if the server has had an ATO created previously
   *
   * @returns {Boolean} true if the server has had an ATO created previously
   */
  hasLastAtoDetails() {
    return this.expiryTimestamp && this.creationTimestamp;
  }

  /**
   * @description Method to check if the server has soft quota
   *
   * @returns {Boolean} true if the server has soft quota
   */
  hasSoftQuota() {
    return this.activationEnforcementType === SERVERS_CONSTANTS.QUOTA.SOFT;
  }

  /**
   * @description Creates a new LAN Server for use.
   *
   * @param {Object} options Initialization Object
   * @param {String} options.userName Name of the user who created the server
   * @param {String} options.status Server's creation status/progress
   * @param {Object} options.frlLanServer information relating to the frlLanServer
   * @param {String} options.frlLanServer.serverId Server's unique ID
   * @param {String} options.frlLanServer.displayName Server's display name
   * @param {String} options.frlLanServer.rootUrl Server's DNS address
   * @param {String} options.frlLanServer.status Server's status: "INACTIVE, ACTIVATED, EXPIRED"
   * @param {Object} options.frlLanServer.lastAtoDetails Server's latest Authority To Operate (ATO) file it was authorized with
   * @param {String} options.frlLanServer.lastAtoDetails.fileId Server's latest ATO file ID
   * @param {Timestamp} options.frlLanServer.lastAtoDetails.expiryTimestamp Server's latest ATO file expiry timestamp
   * @param {Timestamp} options.frlLanServer.lastAtoDetails.creationTimestamp Server's latest ATO file creation timestamp
   * @param {Object} options.frlLanServer.branding Server's branding details
   * @param {String} options.frlLanServer.branding.name Server's branding name
   * @param {Timestamp} options.frlLanServer.lanTimeout Server's LAN timeout
   * @param {String} options.frlLanServer.activationEnforcementType Server's Activation Enforcement Type: "HARD", "SOFT"
   * @param {Array} options.frlLanServer.licenses Server's licenses
   * @param {String} options.frlLanServer.licenses[].licenseId Server's license ID for a particular license
   */
  // eslint-disable-next-line complexity -- intialising the model
  initModel(options = {}) {
    const {frlLanServer, userName, status} = options;
    this.serverId = frlLanServer?.serverId ?? '';
    this.name = frlLanServer?.displayName ?? '';
    this.userName = userName ?? '';
    this.dns = frlLanServer?.rootUrl ?? '';
    this.status = frlLanServer?.status ?? '';
    this.creationStatus = status ?? '';
    this.atoFileId = frlLanServer?.lastAtoDetails?.fileId ?? '';
    this.expiryTimestamp = frlLanServer?.lastAtoDetails?.expiryTimestamp ?? null;
    this.creationTimestamp = frlLanServer?.lastAtoDetails?.creationTimestamp ?? '';
    this.branding = frlLanServer?.branding?.name ?? '';
    this.lanTimeout = frlLanServer?.lanTimeout ?? '';
    this.activationEnforcementType = frlLanServer?.activationEnforcementType ?? '';
    // get licenses for given license IDs
    this.licenses = PackagesEntitlementsService.getFrlOffersByLicenseIds(
      frlLanServer?.licenses ? frlLanServer.licenses.map((license) => license.licenseId) : []
    );
    // set expiry status
    this.expiryStatus = getExpiryStatus(this.status, this.expiryTimestamp);
  }

  /**
   * @description Method to check if the server is in active state
   *
   * @returns {Boolean} true if the server is in active state
   */
  isActive() {
    return this.expiryStatus === SERVERS_CONSTANTS.EXPIRY_STATUS.ACTIVE;
  }

  /**
   * @description Method to check if the server is in creation state
   *
   * @returns {Boolean} true if the server is in creation state
   */
  isCreationInProgress() {
    return (
      this.creationStatus === SERVER_API_MESSAGE.WAITING ||
      this.creationStatus === SERVER_API_MESSAGE.INPROGRESS
    );
  }

  /**
   * @description Method to check if the server is in expired state
   *
   * @returns {Boolean} true if the server is in expired state
   */
  isExpired() {
    return this.expiryStatus === SERVERS_CONSTANTS.EXPIRY_STATUS.EXPIRED;
  }

  /**
   * @description Method to check if the server is in expiring state
   *
   * @returns {Boolean} true if the server is in expiring state
   */
  isExpiring() {
    return this.expiryStatus === SERVERS_CONSTANTS.EXPIRY_STATUS.EXPIRING;
  }

  /**
   * @description Method to refresh server data from backend
   *
   * @returns {Promise} Promise indicating completion of API
   */
  async refresh() {
    try {
      const response = await getServer(this.serverId);
      this.initModel(response.data);
      return Promise.resolve();
    } catch (error) {
      log.error(
        'PackagesLanServer.refresh(): Unable to refresh server details from back-end. Error: ',
        error
      );
      return Promise.reject(error);
    }
  }
}

// private function
function getExpiryStatus(status, expiryTimestamp) {
  let expiryStatus;
  const timeDiff = expiryTimestamp - Date.now();
  const daysLeftToShowServerExpiryWarningInMilis =
    rootStore.packagesUiConstantsStore.daysLeftToShowServerExpiryWarning * MILLISECONDS_IN_A_DAY;

  if (timeDiff > daysLeftToShowServerExpiryWarningInMilis) {
    expiryStatus = SERVERS_CONSTANTS.EXPIRY_STATUS.ACTIVE;
  } else if (status === SERVERS_CONSTANTS.STATUS.EXPIRED) {
    expiryStatus = SERVERS_CONSTANTS.EXPIRY_STATUS.EXPIRED;
  } else {
    expiryStatus = SERVERS_CONSTANTS.EXPIRY_STATUS.EXPIRING;
  }

  return expiryStatus;
}

export default PackagesLanServerService;
