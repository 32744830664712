import {getTrialContract, hasOnlyTrialOrAllocationContracts} from '@admin-tribe/acsc';
import {getProductDisplayName} from '@admin-tribe/acsc-ui';

import rootStore from 'core/RootStore';
import trialHelper from 'core/products/trial-helper/trialHelper';
import {TRIAL_STATUS} from 'core/products/trial-helper/trialHelperConstants';
import {GLOBAL_BANNER_TYPES} from 'features/global-banner/GlobalBannerConstants';

/**
 * @description Method for creating global banners for trials that are ending
 *     soon.
 */
function notifyContractComplianceTrialBanners() {
  if (rootStore.organizationStore.contractList.items.length > 0) {
    const contractList = rootStore.organizationStore.contractList;

    if (hasOnlyTrialOrAllocationContracts(contractList) || trialHelper.getDxTrialContract()) {
      const globalBanner = createGlobalBannerForTrialContract();
      if (globalBanner) {
        rootStore.organizationStore.globalBannerStore.add(globalBanner);
      }
    }
  }
}

/////////
function createGlobalBannerForTrialContract() {
  const contract = getTrialContract(rootStore.organizationStore.contractList);
  const product = trialHelper.getTrialProduct();
  if (product && !contract?.isMigrating?.()) {
    const trialStatus = trialHelper.getTrialStatusInfo(product);

    const bannerOptions = {
      isDismissible: false,
      linkOnClick: () => trialHelper.openBuyNowUrl(product.id),
      linkText: 'globalBanner.linkText.trials',
    };

    if (trialStatus.status === TRIAL_STATUS.EXPIRED) {
      return {
        message: 'globalBanner.messages.trials.expired',
        translationRequiredMessageArgs: {
          productName: (intl) => getProductDisplayName(intl, product),
        },
        type: GLOBAL_BANNER_TYPES.ERROR,
        ...bannerOptions,
      };
    }

    const messageParams = {
      messageDates: {
        endDate: trialStatus.endDate,
      },
      translationRequiredMessageArgs: {
        productName: (intl) => getProductDisplayName(intl, product),
      },
    };

    if (trialStatus.status === TRIAL_STATUS.LAST_DAY) {
      return {
        message: 'globalBanner.messages.trials.lastDay',
        type: GLOBAL_BANNER_TYPES.WARNING,
        ...bannerOptions,
        ...messageParams,
      };
    }

    if (trialStatus.status === TRIAL_STATUS.DAYS_LEFT_WARNING) {
      return {
        message: 'globalBanner.messages.trials.daysLeftWarning',
        type: GLOBAL_BANNER_TYPES.WARNING,
        ...bannerOptions,
        ...messageParams,
      };
    }

    if (trialStatus.status === TRIAL_STATUS.DAYS_LEFT_INFO) {
      return {
        message: 'globalBanner.messages.trials.daysLeftInfo',
        type: GLOBAL_BANNER_TYPES.INFO,
        ...bannerOptions,
        ...messageParams,
      };
    }
  }

  return undefined;
}

// eslint-disable-next-line import/prefer-default-export -- Utils file
export {notifyContractComplianceTrialBanners};
