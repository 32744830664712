import {feature} from '@admin-tribe/acsc';
import {useMemo, useState} from 'react';

import useSteppedState from 'common/components/stepped-view/useSteppedState';
import {
  SETUP_AUTHENTICATION_STEPS,
  SETUP_SAML_CERTS,
  SETUP_SECTIONS,
} from 'features/settings/stores/DirectorySetupStore';

import useButtons from './useButtons';
import useCallbacks from './useCallbacks';

/**
 * A hook containing callbacks and state for directory setup modals (create directory, add idp, etc.)
 */
const useDirectorySetup = (directorySetupStore, initialSection) => {
  const [idpInfo, setIdpInfo] = useState({});
  const [isDoingExternalAuth, setIsDoingExternalAuth] = useState(false);
  const [directoryInfo, setDirectoryInfo] = useState({});

  const sectionsSteps = useMemo(() => Object.values(SETUP_SECTIONS), []);

  const setupAuthenticationSteps = useMemo(
    () =>
      feature.isEnabled('temp_saml_certs') && directorySetupStore?.idp?.isSaml
        ? Object.values({...SETUP_AUTHENTICATION_STEPS, SETUP_SAML_CERTS})
        : Object.values(SETUP_AUTHENTICATION_STEPS),
    [directorySetupStore?.idp?.isSaml]
  );

  const sectionsStepManager = useSteppedState({
    initialStepId: initialSection,
    steps: sectionsSteps,
  });
  const setupAuthenticationStepManager = useSteppedState({
    initialStepId: SETUP_AUTHENTICATION_STEPS.SETUP_IDP.id,
    steps: setupAuthenticationSteps,
  });

  const callbacks = useCallbacks({
    directorySetupStore,
    idpInfo,
    setIdpInfo,
    setIsDoingExternalAuth,
  });

  const buttonStates = useButtons({
    directoryInfo,
    directorySetupStore,
    idpInfo,
    isDoingExternalAuth,
    sectionsStepManager,
    setupAuthenticationStepManager,
  });

  return {
    directoryInfo,
    idpInfo,
    isDoingExternalAuth,
    sectionsStepManager,
    sectionsSteps,
    setDirectoryInfo,
    setIdpInfo,
    setIsDoingExternalAuth,
    setupAuthenticationStepManager,
    ...callbacks,
    ...buttonStates,
  };
};

export default useDirectorySetup;
