import {dispatchPageAnalytics} from '@admin-tribe/acsc';
import {useEffect} from 'react';

/**
 * @description Custom hook that triggers page analytics
 * @param {String} opts.name - The name for this page to pass to analytics. This should be
 *   a colon separated list for sub-pages, for example: 'overview' (as it has only one page)
 *   and 'products:overview' (as it has many)
 */
function usePageAnalytics({name}) {
  // Trigger page analytics on mount
  useEffect(() => {
    dispatchPageAnalytics({name});
  }, [name]);
}

export default usePageAnalytics;
