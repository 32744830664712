import {
  ACQUISITION_SUMMARY_LIST_SUMMARIZED_BY,
  ACQUISITION_SUMMARY_RENEWAL_QUANTITY_STATUS,
  AcquisitionSummaryList,
  CONTRACT_LEGACY_DX_ID,
  LICENSE_QUANTITY_RENEWAL_STATUS,
  feature,
  getFirstContractForProduct,
  getLapsedVIPSubscriptionContract,
  getLapsedVIPSubscriptionPrevAnniversaryDate,
  getLapsedVIPSubscriptionResellerName,
  hasAssignableLicenses,
  hasLapsedVIPSubscription,
} from '@admin-tribe/acsc';
import {subDays} from 'date-fns';
import groupBy from 'lodash/groupBy';

import rootStore from 'core/RootStore';
import {isContractAdmin, isOrgAdmin} from 'core/organizations/access/organizationAccess';

import {RENEWAL_WINDOW_MARKERS} from '../auto-renewal/AutoRenewalConstants';
import {isAutoRenewalSelfServiceSupported} from '../auto-renewal/autoRenewalUtils';

/**
 * @description Method to obtain an object that will be used to determine which
 *     renewal banners should be displayed.
 * @returns {Object} Object wrapping properties that will dictate what renewal
 *     banners should be shown per contract.
 */
async function getRenewalOverviews() {
  const contractList = rootStore.organizationStore.contractListIncludingInactive;
  const productList = rootStore.organizationStore.productList;
  const contractsById = groupBy(contractList.items, 'id');
  const result = {};

  if (contractList.items.length > 0) {
    if (feature.isEnabled('temp_sls_m_3')) {
      const acquisitionSummaryLists = await Promise.all(
        Object.keys(contractsById)
          .filter((contractId) => contractId !== CONTRACT_LEGACY_DX_ID)
          .map((contractId) =>
            AcquisitionSummaryList.get({
              contractId,
              orgId: rootStore.organizationStore.activeOrgId,
              summarizedBy: ACQUISITION_SUMMARY_LIST_SUMMARIZED_BY.RENEWAL,
            })
          )
      );

      Object.keys(contractsById).forEach((contractId) => {
        const contracts = contractsById[contractId];
        const contract = contracts[0];
        const acquisitionSummaryList = acquisitionSummaryLists.find(
          (list) => list.contractId === contract.id
        );
        const products = productList.items.filter((product) => product.hasContractId(contractId));
        result[contractId] = contractAndProductsToOverview(
          contract,
          products,
          acquisitionSummaryList
        );
      });
    } else {
      Object.keys(contractsById).forEach((contractId) => {
        const contracts = contractsById[contractId];
        const contract = contracts[0];

        const products = productList.items.filter((product) => product.hasContractId(contractId));
        result[contractId] = contractAndProductsToOverview(contract, products);
      });
    }
  }
  return result;
}

/**
 * @description Method used by product page banner notifier to get renewal
 *     information to populate page banners with.
 * @param {Product} product - Product that will be used to find the appropriate
 *     contract.
 * @returns {Object} Object wrapping renewal summary info that will be used to
 *     populate product page banners.
 */
function getRenewalSummary(product) {
  const contractList = rootStore.organizationStore.contractList;
  let contract;
  if (contractList.items.length > 0) {
    contract = getFirstContractForProduct(contractList, product);
    return contract ? constructRenewalSummary(product, contract) : {};
  }
  return {};
}

/**
 * @description Method for the System Admin to determine whether or not this
 *     organization has a contract with a lapsed VIP subscription and also does
 *     not have any available licenses to assign (pending or paid).
 * @return {Object | false} if System Admin and organization has a lapsed
 *     subscription and no licenses, then an Object is returned with
 *     anniversaryDate and resellerName attributes that reflect these values
 *     from the lapsed contract. Otherwise, false is returned
 */
function hasNotRenewedContractPostRenewalWindow() {
  const contractList = rootStore.organizationStore.contractList;
  if (contractList.items.length > 0) {
    const productList = rootStore.organizationStore.productList;

    if (
      (isOrgAdmin() || isContractAdmin()) &&
      hasLapsedVIPSubscription(contractList) &&
      !hasAssignableLicenses(productList)
    ) {
      const lapsedVIPSubscriptionContract = getLapsedVIPSubscriptionContract(contractList);
      const isVIPMPContract = lapsedVIPSubscriptionContract.isVIPMPContract();
      return {
        anniversaryDate: getLapsedVIPSubscriptionPrevAnniversaryDate(contractList),
        anniversaryTime: getLapsedVIPSubscriptionPrevAnniversaryDate(contractList),
        isVIPMPContract,
        resellerName: getLapsedVIPSubscriptionResellerName(contractList),
      };
    }
  }
  return false;
}

//////////

/**
 * @description Helper method to construct a renewal summary from a product and
 *     a contract.
 * @param {Product} product - Product whose properties will be checked.
 * @param {Contract} contract - Contract whose properties will be checked.
 * @returns {Object} Object summary that contains renewal information for the
 *     given contract and product.
 */
function constructRenewalSummary(product, contract) {
  const isInRenewalWindow = contract.isInRenewalWindow();
  const renewalStatus = product.licenseTupleList.getRenewalStatus(
    contract.getRenewalWindowEndDate()
  );

  // True if any messages for this product can refer to users and/or users losing access.
  const isUserBasedRenewal =
    isInRenewalWindow &&
    renewalStatus !== ACQUISITION_SUMMARY_RENEWAL_QUANTITY_STATUS.NOT_RENEWAL &&
    !product.fulfillableItemList.hasOverdelegationAllowed();

  return {
    anniversaryDate: contract.getAnniversaryDate(),
    hasGracePastDueLicenses: product.licenseTupleList.hasGracePastDueLicenses(),
    isIndirectContract: contract.isIndirectContract(),
    isInPostAnniversaryRenewalWindow: contract.isInPostAnniversaryRenewalWindow(),
    isInPreAnniversaryRenewalWindow: contract.isInPreAnniversaryRenewalWindow(),
    isInRenewalWindow,
    isUserBasedRenewal,
    isVIPMPContract: contract.isVIPMPContract(),
    needPaymentLicensesQuantity: product.licenseTupleList.getNeedPaymentQuantity(),
    needRenewalQuantity: product.licenseTupleList.getNeedRenewalQuantity(),
    productId: product.id,
    renewalStatus,
    renewalWindowEndDate: contract.getRenewalWindowEndDate(),
    resellerName: contract.getResellerName(),
  };
}

/**
 * @description Helper method to construct an renewal overview object from a
 *     contract and an array of products.
 * @param {Contract} contract - Contract whose properties will be used to
 *     populate the overview object.
 * @param {Product[]} products - Array of products that will be checked for
 *     renewal status.
 * @param {AcquisitionSummaryList} acquisitionSummaryList - List of renewal
 *     acquisition summaries that will be used to determine the license quantity
 *     renewal status.
 * @returns {Object} Object wrapping renewal banner properties
 */
// eslint-disable-next-line complexity -- Will be reduced when flags removed
function contractAndProductsToOverview(contract, products, acquisitionSummaryList) {
  let allComplete = false;
  let isUserBasedRenewal = false;
  let hasPartial = false;
  let renewalSummaries = [];

  // If auto renewal self service is supported, then calculate startOfRenewalWindow on the client side.
  // This is needed as backend only provide standard data like T-30.
  // Otherwise, use the start date provided by the backend
  const startOfRenewalWindow = isAutoRenewalSelfServiceSupported(
    contract.isOptInSelfServiceAvailable()
  )
    ? subDays(new Date(contract.getAnniversaryDate()), RENEWAL_WINDOW_MARKERS.T3).toISOString()
    : contract.getRenewalWindowStartDate();

  if (contract.isInRenewalWindow() && products?.length > 0) {
    const summaries = products.map((product) => constructRenewalSummary(product, contract));
    // filter out any products not participating in renewal
    renewalSummaries = summaries.filter(
      (summary) => summary.renewalStatus !== LICENSE_QUANTITY_RENEWAL_STATUS.NOT_RENEWAL
    );

    const considerableAcquisitionSummaryItems = acquisitionSummaryList?.items?.filter(
      (item) => item.renewalQuantityStatus !== undefined
    );

    // hasPartial and allComplete apply only to licenses participating in renewal
    hasPartial = feature.isEnabled('temp_sls_m_3')
      ? considerableAcquisitionSummaryItems.some(
          (item) =>
            item.renewalQuantityStatus === ACQUISITION_SUMMARY_RENEWAL_QUANTITY_STATUS.PARTIAL
        ) ||
        (considerableAcquisitionSummaryItems.some(
          (item) =>
            item.renewalQuantityStatus === ACQUISITION_SUMMARY_RENEWAL_QUANTITY_STATUS.COMPLETE
        ) &&
          considerableAcquisitionSummaryItems.some(
            (item) =>
              item.renewalQuantityStatus === ACQUISITION_SUMMARY_RENEWAL_QUANTITY_STATUS.NONE
          ))
      : renewalSummaries.some(
          (summary) => summary.renewalStatus === LICENSE_QUANTITY_RENEWAL_STATUS.PARTIAL
        ) ||
        (renewalSummaries.some(
          (summary) => summary.renewalStatus === LICENSE_QUANTITY_RENEWAL_STATUS.COMPLETE
        ) &&
          renewalSummaries.some(
            (summary) => summary.renewalStatus === LICENSE_QUANTITY_RENEWAL_STATUS.NONE
          ));
    allComplete = feature.isEnabled('temp_sls_m_3')
      ? considerableAcquisitionSummaryItems.length > 0 &&
        considerableAcquisitionSummaryItems.every(
          (item) =>
            item.renewalQuantityStatus === ACQUISITION_SUMMARY_RENEWAL_QUANTITY_STATUS.COMPLETE
        )
      : renewalSummaries.length > 0 &&
        renewalSummaries.every(
          (summary) => summary.renewalStatus === LICENSE_QUANTITY_RENEWAL_STATUS.COMPLETE
        );

    // Only true if every product is user-based and any renewal messages can refer to users.
    isUserBasedRenewal = summaries.every((summary) => summary.isUserBasedRenewal);
  }

  return {
    allComplete,
    anniversaryDate: contract.getAnniversaryDate(),
    endOfRenewalWindow: contract.getRenewalWindowEndDate(),
    hasPartial,
    hasRenewableProducts: renewalSummaries.length > 0,
    isAutoRenewalOff: contract.isAutoRenewalOff(),
    isDirectContract: contract.isDirectContract(),
    isDrContract: contract.isDrContract || false,
    isInPostAnniversaryRenewalWindow: contract.isInPostAnniversaryRenewalWindow(),
    isInRenewalWindow: contract.isInRenewalWindow(),
    isOptInSelfServiceAvailable: contract.isOptInSelfServiceAvailable(),
    isRenewableDirectContract: contract.isRenewableDirectContract(),
    isUserBasedRenewal,
    isVIPMPContract: contract.isVIPMPContract(),
    nextBillingInfo: contract.getNextBillingAmountInfo(),
    offsetFromAnniversaryDate: contract.getOffsetFromAnniversaryDate(),
    resellerName: contract.getResellerName(),
    startOfRenewalWindow,
  };
}

export {getRenewalOverviews, getRenewalSummary, hasNotRenewedContractPostRenewalWindow};
