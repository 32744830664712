import {PAGE_TARGET_TYPE, feature} from '@admin-tribe/acsc';
import React from 'react';

import AddUsersToOrgModal from 'common/components/add-user-modals/AddUsersToOrgModal';
import {BULK_OPERATION_MODAL_ID} from 'common/components/bulk-operation/bulk-operation-utils/bulkOperationConstants';
import rootStore from 'core/RootStore';
import {canViewAdmins} from 'core/admin/access/adminAccess';
import {canViewApiCredentials, canViewDevelopers} from 'core/developer/access/developerAccess';
import {canViewDirectories} from 'core/directories/access/directoryAccess';
import {buildAccessCheckLoader} from 'core/router/loaders';
import {canViewLicenseDeficitReport, canViewUsers} from 'core/user/access/userAccess';
import {
  canViewUserGroups,
  canViewUserGroupsBulkOperations,
} from 'core/user-group/access/userGroupAccess';
import BulkOperationsPage from 'features/bulk-operations/pages/bulk-operations-page/BulkOperationsPage';
import JobResultsPage from 'features/bulk-operations/pages/job-results-page/JobResultsPage';
import LicenseDeficitReportPage from 'features/bulk-operations/pages/license-deficit-report-page/LicenseDeficitReportPage';
import ReviewUserIntroductionsModalWrapper from 'features/overview/components/review-user-introductions-modal-wrapper/ReviewUserIntroductionsModalWrapper';
import AdministratorsPage from 'features/users/pages/administrators-page/AdministratorsPage';
import ApiCredentialsPage from 'features/users/pages/api-credentials-page/ApiCredentialsPage';
import DevelopersPage from 'features/users/pages/developers-page/DevelopersPage';
import DirectoryUserDetailsPage from 'features/users/pages/directory-user-details-page/DirectoryUserDetailsPage';
import DirectoryUsersPage from 'features/users/pages/directory-users-page/DirectoryUsersPage';
import OwnedDirectoryListPage from 'features/users/pages/owned-directory-list-page/OwnedDirectoryListPage';
import UsersDetailsPage from 'features/users/pages/user-details-page/UserDetailsPage';
import UserGroupDetailsPage from 'features/users/pages/user-group-details-page/UserGroupDetailsPage';
import UserGroupsPage from 'features/users/pages/user-groups-page/UserGroupsPage';
import UsersPage from 'features/users/pages/users-page/UsersPage';
import {goToUsers} from 'features/users/routing/navigation-callbacks/navigationCallbacks';
import {
  PATH_DIRECTORY_USER_BULK_OPERATIONS,
  PATH_DIRECTORY_USER_BULK_OPERATIONS_RESULTS,
  PATH_SEGMENT_ADD_USERS_TO_ORG,
  PATH_SEGMENT_REVIEW_SAVED_USER_INTRODUCTIONS,
  PATH_SEGMENT_REVIEW_USER_INTRODUCTIONS,
  PATH_USERS,
  PATH_USERS_BULK_OPERATIONS,
  PATH_USERS_BULK_OPERATIONS_LICENSE_DEFICIT_REPORT,
  PATH_USERS_BULK_OPERATIONS_RESULTS,
  PATH_USER_GROUPS_BULK_OPERATIONS,
  PATH_USER_GROUPS_BULK_OPERATIONS_RESULTS,
  PATH_USER_GROUP_DETAILS_BULK_OPERATIONS,
  PATH_USER_GROUP_DETAILS_LICENSE_DEFICIT_REPORT,
  PATH_USER_GROUP_DETAILS_RESULTS,
  USER_GROUP_DETAILS_TAB_NAV,
} from 'features/users/routing/usersPaths';
import UsersWorkspace from 'features/users/workspace/UsersWorkspace';

import {
  defaultSectionLoader,
  directoryUsersSectionLoader,
  userGroupDetailsBulkOperationsLoader,
  userGroupDetailsLoader,
  userGroupDetailsSectionLoader,
  usersBulkOpsModalLoader,
} from './usersRoutesLoaders';

function getAdministratorRoutes() {
  return [
    {
      children: [
        {
          element: <AdministratorsPage />,
          index: true,
          loader: buildAccessCheckLoader(canViewAdmins),
        },
        {
          element: <UsersDetailsPage targetType={PAGE_TARGET_TYPE.ADMIN} />,
          loader: buildAccessCheckLoader(canViewAdmins),
          path: ':userId',
        },
      ],
      path: 'administrators',
    },
  ];
}

function getApiCredentialsRoutes() {
  return [
    {
      children: [
        {
          element: <ApiCredentialsPage />,
          index: true,
          loader: buildAccessCheckLoader(canViewApiCredentials),
        },
      ],
      path: 'integrations',
    },
  ];
}

function getDeveloperRoutes() {
  return [
    {
      children: [
        {
          element: <DevelopersPage />,
          index: true,
          loader: buildAccessCheckLoader(canViewDevelopers),
        },
        {
          element: <UsersDetailsPage targetType={PAGE_TARGET_TYPE.DEVELOPER} />,
          loader: buildAccessCheckLoader(canViewDevelopers),
          path: ':userId',
        },
      ],
      path: 'developers',
    },
  ];
}

function getDirectoryUserRoutes() {
  return [
    {
      children: [
        {
          element: <OwnedDirectoryListPage />,
          index: true,
          loader: buildAccessCheckLoader(canViewDirectories),
        },
        {
          children: [
            {
              element: <DirectoryUsersPage />,
              index: true,
              loader: directoryUsersSectionLoader,
            },
            {
              element: <DirectoryUserDetailsPage />,
              loader: directoryUsersSectionLoader,
              path: ':userId',
            },
            // Bulk operations
            {
              children: [
                {
                  element: <BulkOperationsPage path={PATH_DIRECTORY_USER_BULK_OPERATIONS} />,
                  index: true,
                  loader: buildAccessCheckLoader(canViewDirectories),
                },
                {
                  element: <JobResultsPage path={PATH_DIRECTORY_USER_BULK_OPERATIONS_RESULTS} />,
                  loader: buildAccessCheckLoader(canViewDirectories),
                  path: ':jobId',
                },
              ],
              path: 'bulk-operations',
            },
          ],
          path: ':directoryId',
        },
      ],
      path: 'directory-users',
    },
  ];
}

function getUserGroupRoutes() {
  return [
    {
      children: [
        {
          element: <UserGroupsPage />,
          index: true,
          loader: buildAccessCheckLoader(canViewUserGroups),
        },
        // Bulk operations
        {
          children: [
            {
              element: <BulkOperationsPage path={PATH_USER_GROUPS_BULK_OPERATIONS} />,
              index: true,
              loader: buildAccessCheckLoader(canViewUserGroupsBulkOperations),
            },
            {
              element: <JobResultsPage path={PATH_USER_GROUPS_BULK_OPERATIONS_RESULTS} />,
              loader: buildAccessCheckLoader(canViewUserGroupsBulkOperations),
              path: ':jobId',
            },
          ],
          path: 'bulk-operations',
        },
        // User group details
        {
          children: [
            {
              element: <UserGroupDetailsPage />,
              index: true,
              loader: userGroupDetailsSectionLoader,
            },
            {
              element: <UserGroupDetailsPage />,
              loader: userGroupDetailsLoader(USER_GROUP_DETAILS_TAB_NAV.ADMINS),
              path: 'admins',
            },
            {
              element: <UserGroupDetailsPage />,
              loader: userGroupDetailsLoader(USER_GROUP_DETAILS_TAB_NAV.ASSIGNED_PROFILES),
              path: 'assigned-product-profiles',
            },
            {
              element: <UserGroupDetailsPage />,
              loader: userGroupDetailsLoader(USER_GROUP_DETAILS_TAB_NAV.USERS),
              path: 'users',
            },
            // User group details bulk operations
            {
              children: [
                {
                  element: <BulkOperationsPage path={PATH_USER_GROUP_DETAILS_BULK_OPERATIONS} />,
                  index: true,
                  loader: userGroupDetailsBulkOperationsLoader,
                },
                {
                  element: <JobResultsPage path={PATH_USER_GROUP_DETAILS_RESULTS} />,
                  loader: userGroupDetailsBulkOperationsLoader,
                  path: ':jobId',
                },
                {
                  element: (
                    <LicenseDeficitReportPage
                      path={PATH_USER_GROUP_DETAILS_LICENSE_DEFICIT_REPORT}
                    />
                  ),
                  loader: userGroupDetailsBulkOperationsLoader,
                  path: ':jobId/deficit',
                },
              ],
              path: 'bulk-operations',
            },
          ],
          path: ':userGroupId',
        },
      ],
      path: 'user-groups',
    },
  ];
}

function getUsersSubroutes() {
  return [
    {
      // to deep-link to bulkOps modal on Users page
      children: [
        {
          // required so /users/bulk-operation-modal doesn't match /users/:userId path
          element: null,
          index: true,
          loader: buildAccessCheckLoader(false),
        },
        {
          element: <UsersPage />,
          loader: usersBulkOpsModalLoader,
          path: ':mode',
        },
      ],
      path: BULK_OPERATION_MODAL_ID,
    },
    {
      element: <UsersDetailsPage targetType={PAGE_TARGET_TYPE.USER} />,
      loader: buildAccessCheckLoader(canViewUsers),
      path: ':userId',
    },
    {
      children: [
        {
          element: <BulkOperationsPage path={PATH_USERS_BULK_OPERATIONS} />,
          index: true,
          loader: buildAccessCheckLoader(canViewUsers),
        },
        {
          element: <JobResultsPage path={PATH_USERS_BULK_OPERATIONS_RESULTS} />,
          loader: buildAccessCheckLoader(canViewUsers),
          path: ':jobId',
        },
        {
          element: (
            <LicenseDeficitReportPage path={PATH_USERS_BULK_OPERATIONS_LICENSE_DEFICIT_REPORT} />
          ),
          loader: buildAccessCheckLoader(canViewLicenseDeficitReport),
          path: ':jobId/deficit',
        },
      ],
      path: 'bulk-operations',
    },
  ];
}

/** Gets all of the Users section routes */
const usersRoutes = () => [
  {
    children: [
      {
        element: <UsersPage />,
        index: true,
        loader: buildAccessCheckLoader(canViewUsers),
      },
      ...getAdministratorRoutes(),
      ...getApiCredentialsRoutes(),
      ...getDeveloperRoutes(),
      ...getDirectoryUserRoutes(),
      ...getUserGroupRoutes(),
      ...getUsersSubroutes(),
      ...(feature.isEnabled('temp_introductions_modal_deeplink')
        ? [
            {
              element: <ReviewUserIntroductionsModalWrapper onClose={goToUsers} />,
              loader: buildAccessCheckLoader(
                feature.isEnabled('temp_introductions_modal_deeplink')
              ),
              path: PATH_SEGMENT_REVIEW_USER_INTRODUCTIONS,
            },
          ]
        : []),
      ...(feature.isEnabled('temp_saved_introductions_modal_deeplink')
        ? [
            {
              element: <ReviewUserIntroductionsModalWrapper onClose={goToUsers} startTab="saved" />,
              loader: buildAccessCheckLoader(
                feature.isEnabled('temp_saved_introductions_modal_deeplink')
              ),
              path: PATH_SEGMENT_REVIEW_SAVED_USER_INTRODUCTIONS,
            },
          ]
        : []),
      ...(feature.isEnabled('temp_tno_add_users_modal_deeplink')
        ? [
            {
              element: (
                <AddUsersToOrgModal
                  isOpen
                  onClosed={goToUsers}
                  onSuccess={goToUsers}
                  orgId={rootStore.organizationStore.activeOrgId}
                />
              ),
              loader: buildAccessCheckLoader(
                feature.isEnabled('temp_tno_add_users_modal_deeplink')
              ),
              path: PATH_SEGMENT_ADD_USERS_TO_ORG,
            },
          ]
        : []),
    ],
    element: <UsersWorkspace />,
    loader: defaultSectionLoader,
    path: PATH_USERS,
  },
];

export {defaultSectionLoader};
export default usersRoutes;
