import {ImageIcon} from '@admin-tribe/acsc-ui';
import {Flex, Text, View} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import React from 'react';

import {useCreateServerModalContext} from '../../../CreateServerModalContext';
import styles from '../ServerFinalize.pcss';

/**
 * Server Entitlements component for Finalize screen in create/edit server flow
 * it shows selected entitlements for the server
 */
const EntitlementsList = observer(() => {
  const {packagesServerStore} = useCreateServerModalContext();

  return (
    <Flex direction="column">
      <View
        backgroundColor="gray-50"
        data-testid="plugin-list"
        direction="column"
        height="size-3600"
        maxWidth="size-4600"
        UNSAFE_style={{overflow: 'auto'}}
      >
        {packagesServerStore.serverSessionData.selectedLicenses.map((license) => (
          <Flex
            key={license.offerId}
            alignItems="center"
            justifyContent="space-between"
            // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- need to add style for specs matching
            UNSAFE_className={styles['entitlement-card']}
          >
            <Flex alignItems="center" gap="size-150">
              <Flex alignItems="center" height="100%">
                <ImageIcon alt="" size="M" src={license.getIconUrl()} />
              </Flex>
              <Flex direction="column">
                <Text UNSAFE_style={{fontWeight: 'bold'}}>{license.longName}</Text>
              </Flex>
            </Flex>
          </Flex>
        ))}
      </View>
    </Flex>
  );
});
export default EntitlementsList;
