import {AlertDialog, showError, showSuccess} from '@admin-tribe/acsc-ui';
import {Content, useDialogContainer} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {useAuthSettingsContext} from 'features/settings/components/auth-settings-page/AuthSettingsContext';

// remove with temp_social_auth_policy_form feature flag -- replaced by UpdateSocialLoginPolicyModal
const SocialProvidersModal = observer(({socialProvider, isEnablingMode}) => {
  const {authenticationPoliciesStore} = useAuthSettingsContext();
  const confirmationModalMode = isEnablingMode ? 'enable' : 'disable';

  const intl = useIntl();
  const dialog = useDialogContainer();

  const handleSocialProviderUpdate = async () => {
    try {
      if (isEnablingMode) {
        // remove the social provider from the denied list
        await authenticationPoliciesStore.allowSocialProvider(socialProvider);
      } else {
        // add the social provider to the denied list
        await authenticationPoliciesStore.denySocialProvider(socialProvider);
      }
      showSuccess(
        intl.formatMessage({
          id: `settings.authSettings.socialLogin.toast.updateProvider.success`,
        })
      );

      dialog.dismiss();
    } catch (error) {
      showError(
        intl.formatMessage({
          id: `settings.authSettings.socialLogin.toast.updateProvider.error`,
        })
      );
    }
  };

  return (
    <AlertDialog
      ctaLabel={intl.formatMessage({
        id: `common.modal.buttons.${confirmationModalMode}`,
      })}
      data-testid="social-providers-modal"
      id="social-providers-modal"
      isLoading={authenticationPoliciesStore.isLoadingSocialProviders}
      // eslint-disable-next-line react/jsx-handler-names -- erroneous reporting
      onCancel={dialog.dismiss}
      onCta={handleSocialProviderUpdate}
      title={intl.formatMessage({
        id: `settings.authSettings.socialLogin.modal.title.${socialProvider}.${confirmationModalMode}`,
      })}
      variant={confirmationModalMode === 'enable' ? 'default' : 'destructive'}
    >
      <Content>
        <FormattedMessage id="settings.authSettings.socialLogin.modal.description" />
      </Content>
    </AlertDialog>
  );
});

SocialProvidersModal.propTypes = {
  isEnablingMode: PropTypes.bool.isRequired,
  socialProvider: PropTypes.string.isRequired,
};

export default SocialProvidersModal;
