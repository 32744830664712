import {feature} from '@admin-tribe/acsc';
import {
  Page,
  PageActions,
  PageBanners,
  PageContent,
  PageHeader,
  PageNav,
} from '@admin-tribe/acsc-ui';
import {Flex} from '@adobe/react-spectrum';
import {CLOUD} from '@pandora/administration-core-types';
import {TableSection} from '@pandora/react-table-section';
import {Accordion, AccordionItem} from '@react/react-spectrum/Accordion';
import {useCollator} from '@react-aria/i18n';
import cloneDeep from 'lodash/cloneDeep';
import React, {useEffect, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {v4 as uuid4} from 'uuid';

import BuyMoreButton from 'common/components/buy-more-button/BuyMoreButton';
import SophiaBanner from 'common/components/sophia/banner/SophiaBanner';
import useDocumentTitle from 'common/hooks/useDocumentTitle';
import usePageAnalytics from 'common/hooks/usePageAnalytics';
import ProductSummaryList from 'common/services/product-summary-list/ProductSummaryList';
import SOPHIA_CONSTANTS from 'common/services/sophia/SophiaConstants';
import rootStore from 'core/RootStore';
import {isAllowedToAddProducts} from 'core/organizations/access/organizationAccess';
import chatProvider from 'core/services/chat/chatProvider';
import FreeOfferModal from 'features/offers/FreeOfferModal';
import AddProductModalWrapper from 'features/products/components/add-product-modal-wrapper/AddProductModalWrapper';
import ExportProductsButton from 'features/products/components/export-products-button/ExportProductsButton';
import OfferTable from 'features/products/components/offer-table/OfferTable';
import ProductSearch from 'features/products/components/product-search/ProductSearch';
import ProductTableSectionAccordion from 'features/products/components/product-table-accordion/ProductTableSectionAccordion';
import SophiaProductsBannerSection from 'features/products/components/sophia-banner/SophiaProductsBannerSection';
import useAddProductsModalFromDeeplink from 'features/products/hooks/useAddProductsModalFromDeeplink';
import useFreeOffers from 'features/products/hooks/useFreeOffers';
import {getSortedProductsAndProductGroups} from 'features/products/utils/productGroupProductUtils';

import AllProductsNoSearchResultsIllustratedMessage from './AllProductsNoSearchResultsIllustratedMessage';
import NoProductsIllustratedMessage from './NoProductsIllustratedMessage';

const ACCORDION_ARIA_LEVEL = 2;

/**
 * Represents the new product overview page implemented as part of PA-6055.
 */
const AllProductsPage = () => {
  const intl = useIntl();
  const collator = useCollator();

  const {
    isModalOpen: isAddProductsModalOpen,
    props: addProductsModalProps,
    closeModal: closeAddProductsModal,
  } = useAddProductsModalFromDeeplink('products.add-products');

  useDocumentTitle({key: 'products.allProducts.title'});
  usePageAnalytics({name: 'products:overview'});

  const [searchResults, setSearchResults] = useState({
    [CLOUD.CREATIVE]: [],
    [CLOUD.DOCUMENT]: [],
    [CLOUD.EXPERIENCE]: [],
    [CLOUD.OTHERS]: [],
    ...(feature.isEnabled('temp_ctir_18512_product_offers') && {OFFERS: []}),
  });
  const [productSummaryList, setProductSummaryList] = useState([]);
  const [allValuesGrouped, setAllValuesGrouped] = useState({
    [CLOUD.CREATIVE]: [],
    [CLOUD.DOCUMENT]: [],
    [CLOUD.EXPERIENCE]: [],
    [CLOUD.OTHERS]: [],
    ...(feature.isEnabled('temp_ctir_18512_product_offers') && {OFFERS: []}),
  });

  const allItems = useMemo(
    () => getSortedProductsAndProductGroups({collator, intl}),
    [collator, intl]
  );

  const [selectedOffer, setSelectedOffer] = useState(null);
  const {freeOfferList, refreshFreeOffers} = useFreeOffers();

  // Setting up initial productSummaryList
  useEffect(() => {
    const callback = (updatedProductSummaryList) => {
      setProductSummaryList(updatedProductSummaryList);
    };

    setProductSummaryList(
      ProductSummaryList({
        callback,
        contractList: rootStore.organizationStore.contractList,
        includeAction: true,
        includeStatus: true,
        includeTags: true,
        intl,
        orgId: rootStore.organizationStore.activeOrgId,
        productAndProductGroupList: allItems,
      }).map((item) => Object.assign(item, {id: uuid4()}))
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps -- only want this to run on setup and takedown, not every render
  }, []);

  // Update the search results when the product summary list changes, or the offersList changes
  useEffect(() => {
    // assign the id property to each offer to keep the table happy
    if (feature.isEnabled('temp_ctir_18512_product_offers') && freeOfferList) {
      freeOfferList.items.forEach((offer) => {
        offer.id = offer.offer_id;
      });
    }

    const allValuesGroupedByCloud = {
      [CLOUD.CREATIVE]: productSummaryList.filter((item) => item.product.cloud === CLOUD.CREATIVE),
      [CLOUD.EXPERIENCE]: productSummaryList.filter(
        (item) => item.product.cloud === CLOUD.EXPERIENCE
      ),
      [CLOUD.DOCUMENT]: productSummaryList.filter((item) => item.product.cloud === CLOUD.DOCUMENT),
      [CLOUD.OTHERS]: productSummaryList.filter((item) => item.product.cloud === CLOUD.OTHERS),
      ...(feature.isEnabled('temp_ctir_18512_product_offers') && {
        OFFERS: freeOfferList ? freeOfferList.items : [],
      }),
    };
    const clonedValue = cloneDeep(allValuesGroupedByCloud);
    // Need to make a nested copy because otherwise the re-render won't occur
    // when the usage quantities and actions are resolved.
    setSearchResults(clonedValue);
    setAllValuesGrouped(clonedValue);
  }, [productSummaryList, freeOfferList]);

  const getKeySuffix = (key) => {
    let headerKey;
    switch (key) {
      case CLOUD.CREATIVE:
        headerKey = 'creative';
        break;
      case CLOUD.DOCUMENT:
        headerKey = 'document';
        break;
      case CLOUD.EXPERIENCE:
        headerKey = 'experience';
        break;
      case 'OFFERS':
        headerKey = 'offers';
        break;
      default:
        headerKey = 'others';
    }
    return headerKey;
  };

  const getAccordionHeaderLabel = (key) => {
    const keySuffix = getKeySuffix(key);
    return intl.formatMessage({id: `products.allProducts.accordions.header.${keySuffix}`});
  };

  const getTableAriaLabel = (key) => {
    const keySuffix = getKeySuffix(key);
    return intl.formatMessage({id: `products.allProducts.table.ariaLabel.${keySuffix}`});
  };

  const isProductListEmpty = allItems.length === 0;

  return (
    <Page data-testid="app-all-products-page">
      <PageBanners>
        {feature.isEnabled('temp_use_product_overview_hva_banner') ? (
          <SophiaProductsBannerSection />
        ) : (
          <SophiaBanner surfaceId={SOPHIA_CONSTANTS.SURFACE_ID.ONE_CONSOLE_PRODUCTS} />
        )}
      </PageBanners>
      <PageHeader title={intl.formatMessage({id: 'products.allProducts.pageHeader.title'})} />
      {isAllowedToAddProducts() && (
        <PageActions>
          <BuyMoreButton />
        </PageActions>
      )}
      <PageNav />
      <PageContent>
        {isAddProductsModalOpen && (
          <AddProductModalWrapper
            chat={chatProvider}
            onClose={closeAddProductsModal}
            {...addProductsModalProps}
          />
        )}
        <Flex direction="row" marginBottom="size-300">
          <Flex alignItems="flex-start" direction="column" justifyContent="flex-start" width="50%">
            {!isProductListEmpty && (
              <ProductSearch
                allProductSummaryEntries={allValuesGrouped}
                onSearchResultsChanged={setSearchResults}
              />
            )}
          </Flex>
          <Flex alignItems="flex-end" direction="column" justifyContent="flex-end" width="50%">
            <ExportProductsButton />
          </Flex>
        </Flex>
        <NoProductsIllustratedMessage freeOfferList={freeOfferList} />
        <AllProductsNoSearchResultsIllustratedMessage searchResults={searchResults} />

        {/* the product offers table */}
        {feature.isEnabled('temp_ctir_18512_product_offers') && (
          <div aria-live="polite" data-adobe-cloud={getKeySuffix('OFFERS')} role="region">
            {searchResults.OFFERS.length > 0 && (
              <Accordion ariaLevel={ACCORDION_ARIA_LEVEL} defaultSelectedIndex={0}>
                <AccordionItem header={getAccordionHeaderLabel('OFFERS')}>
                  <TableSection items={searchResults.OFFERS} pageNumber={0}>
                    <OfferTable
                      ariaLabel={getTableAriaLabel('OFFERS')}
                      onSelectOffer={setSelectedOffer}
                    />
                  </TableSection>
                </AccordionItem>
              </Accordion>
            )}

            {/* have to re-render FreeOfferModal rather than just setting isOpen to true */}
            {selectedOffer !== null && (
              <FreeOfferModal
                isOpen
                offerList={freeOfferList}
                onClosed={() => {
                  setSelectedOffer(null);
                  refreshFreeOffers();
                }}
                preferredOffer={freeOfferList.items.find(
                  (offer) => offer.offer_id === selectedOffer
                )}
              />
            )}
          </div>
        )}

        {Object.keys(CLOUD).map(
          (key) =>
            searchResults[key] && (
              <ProductTableSectionAccordion
                key={key}
                ariaLabel={getTableAriaLabel(key)}
                ariaLevel={ACCORDION_ARIA_LEVEL}
                cloud={getKeySuffix(key)}
                header={getAccordionHeaderLabel(key)}
                items={searchResults[key]}
              />
            )
        )}
      </PageContent>
    </Page>
  );
};

export default AllProductsPage;
