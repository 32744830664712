import {GoUrl} from '@admin-tribe/acsc-ui';
import {Checkbox, Flex, Text, View} from '@adobe/react-spectrum';
import {DividerPlacement} from '@pandora/react-titled-section';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import PackagesSection from '../../packages-section/PackagesSection';

/**
 * Genuine Service Section
 * To indicate if user is to be notified by email when creative cloud application updates are available.
 */
const GenuineServiceSection = ({
  genuineServiceChecBoxRef,
  preferences,
  setPreferences,
  showDivider,
}) => {
  const intl = useIntl();

  return (
    <PackagesSection
      dividerPlacement={showDivider ? DividerPlacement.TOP : DividerPlacement.NONE}
      headingId="packages-genuine-service-heading"
      title={intl.formatMessage({
        id: 'packages.preferences.genuine.service.title',
      })}
    >
      <Flex direction="column" gap="size-200" marginTop="size-100">
        <Checkbox
          ref={genuineServiceChecBoxRef}
          data-testid="genuine-service-section-checkbox"
          isEmphasized
          isSelected={preferences.consentValue}
          marginTop="size-200"
          onChange={() => setPreferences({...preferences, consentValue: !preferences.consentValue})}
        >
          <Text UNSAFE_style={{fontWeight: 'bold'}}>
            {intl.formatMessage({id: 'packages.preferences.genuine.service.consentLabel'})}
          </Text>
        </Checkbox>
        <Text marginStart="size-300">
          {intl.formatMessage({id: 'packages.preferences.genuine.service.consentText'})}
        </Text>
        <View marginStart="size-300" width="size-900">
          <GoUrl name="ent_learnmore_gocart" variant="primary">
            {intl.formatMessage({id: 'packages.preferences.genuine.service.learnmore.text'})}
          </GoUrl>
        </View>
      </Flex>
    </PackagesSection>
  );
};

GenuineServiceSection.propTypes = {
  /**
   * Reference to notification section's checkbox. Required in preferences button group.
   */
  genuineServiceChecBoxRef: PropTypes.shape({
    current: PropTypes.shape({focus: PropTypes.func}),
  }).isRequired,
  /**
   * Preferences object
   */
  preferences: PropTypes.shape({
    /**
     * Indicates if user has given consent for GoCart workflow.
     * Default value will be false (if undefined)
     */
    consentValue: PropTypes.bool,
  }).isRequired,
  /**
   * Function to be called with updated preferences object when preferences.agsConsent value is to be modified.
   */
  setPreferences: PropTypes.func.isRequired,
  /**
   * Boolean value to indicate if divider is to be shown
   */
  showDivider: PropTypes.bool.isRequired,
};

export default GenuineServiceSection;
