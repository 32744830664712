import PropTypes from 'prop-types';
import React, {createContext, useContext, useMemo} from 'react';

import PackagesAdobeTemplatesStore from '../../stores/PackagesAdobeTemplatesStore';

/**
 * Private Context for the TemplatesPage and its sub-components.
 * Used to pass the allPackagesStore between sub-components.
 */

const PregeneratedPackagesPageContext = createContext(null);
const usePregeneratedPackagesPageContext = () => useContext(PregeneratedPackagesPageContext);

const PregeneratedPackagesPageContextProvider = ({children, packagesAdobeTemplatesStore}) => {
  const value = useMemo(() => ({packagesAdobeTemplatesStore}), [packagesAdobeTemplatesStore]);

  return (
    <PregeneratedPackagesPageContext.Provider value={value}>
      {children}
    </PregeneratedPackagesPageContext.Provider>
  );
};

PregeneratedPackagesPageContextProvider.propTypes = {
  /**
   * The content to be passed through PageContext
   */
  children: PropTypes.node.isRequired,
  /**
   * The domain store for the templates
   */
  packagesAdobeTemplatesStore: PropTypes.instanceOf(PackagesAdobeTemplatesStore).isRequired,
};

export {PregeneratedPackagesPageContextProvider, usePregeneratedPackagesPageContext};
