import {
  ORG_ADMIN,
  OrganizationAdminList,
  getDirectContract,
  getIndirectContract,
} from '@admin-tribe/acsc';
import {showSuccess as showSuccessToast} from '@admin-tribe/acsc-ui';

import rootStore from 'core/RootStore';
import {canChangeContractOwner} from 'core/contract/access/contractAccess';
import {canViewValueDiscoveryTasksForTeam} from 'core/organizations/access/organizationAccess';

import ChangeContractOwnerModal from '../../../account/change-contract-owner-modal/ChangeContractOwnerModal';
import HvaCard from '../HvaCard';
import {HVA_ORDER, HVA_TYPE} from '../hvaConstants';

/**
 * @description Method to fetch if the user has a contract allowed to change owner
 *
 * @param {ContractList} contractList - list of contracts assigned to the account
 *
 * @returns {Contract} the first instance of a direct or indirect contract
 */
const findContractToChangeOwner = (contractList) =>
  getIndirectContract(contractList) || getDirectContract(contractList);

/**
 * Class to create HVA card allowing user to change contract owner
 */
class ChangeContractOwnerHva extends HvaCard {
  /**
   * @description Method to fetch the ChangeContractOwnerHva
   *
   * @param {Object} intl - react-intl to translate texts
   *
   * @returns {Promise<ChangeContractOwnerHva[]>} the fetched instance
   */
  static async get({intl}) {
    if (!canViewValueDiscoveryTasksForTeam()) {
      return Promise.reject();
    }
    const organizationAdminList = await OrganizationAdminList.get({
      orgId: rootStore.organizationStore.activeOrgId,
      pageSize: 10,
      role: ORG_ADMIN,
    });

    const contractsToChangeOwner = findContractToChangeOwner(
      rootStore.organizationStore.contractList
    );

    if (!canChangeContractOwner(contractsToChangeOwner, organizationAdminList)) {
      return Promise.reject();
    }

    if (!contractsToChangeOwner) {
      return Promise.reject();
    }

    return Promise.resolve([
      new ChangeContractOwnerHva({contractsToChangeOwner, intl, organizationAdminList}),
    ]);
  }

  /**
   * @description Creates a new ChangeContractOwnerHva instance
   *
   * @param {Contract} contractsToChangeOwner - the first instance of a direct or indirect contract
   *
   * @param {Object} intl - react-intl to translate texts
   *
   * @param {OrganizationAdminList} organizationAdminList - list of admin organizations linked to the user account
   *
   * @returns {ChangeContractOwnerHva} the fetched instance
   */
  constructor({contractsToChangeOwner, intl}) {
    const namespace = 'overview.highValueActionArea.valueDiscoveryTasks.changeContractOwner';
    super({
      ctaLabel: intl.formatMessage({id: `${namespace}.ctaLabel`}),
      description: intl.formatMessage({id: `${namespace}.description`}),
      dismissOnCTA: true,
      goUrl: 'aac_change_owner_learn',
      header: intl.formatMessage({id: `${namespace}.header`}),
      icon: {
        src: 'https://odin.adobe.com/content/dam/admin-tribe/assets/hva-illustrations/contract-owners.svg',
        style: {width: '40px'},
      },
      id: 'hva_change_contract_owner',
      isDismissible: true,
      modalConfig: {
        closeProp: 'onCancel',
        Component: ChangeContractOwnerModal,
        props: {
          contractId: contractsToChangeOwner.id,
          onSubmit: showSuccessToast,
        },
      },
      priorityOrder: HVA_ORDER.CHANGE_CONTRACT_OWNERS,
      sectionTitle: intl.formatMessage({id: `${namespace}.sectionTitle`}),
      type: HVA_TYPE.VALUE_DISCOVERY,
    });
  }
}

export default ChangeContractOwnerHva;
