import {constructAsyncAddAssignUserAnalytics} from '@admin-tribe/acsc';
import {Button} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import BulkOperationModal from 'common/components/bulk-operation/bulk-operation-modal/BulkOperationModal';
import {BULK_OPERATION_MODE} from 'common/components/bulk-operation/bulk-operation-utils/bulkOperationConstants';
import {useMemberListContext} from 'common/hooks/member-list-context/MemberListContext';
import rootStore from 'core/RootStore';

// The 'Add users by CSV' button which opens the bulk operations modal to allow the admin to add users.
const AddUsersByCsv = observer(() => {
  const {canAddMemberByCsv, pageContext} = useMemberListContext();
  const [isOpen, setIsOpen] = useState(false);
  const intl = useIntl();
  const orgId = rootStore.organizationStore.activeOrgId;

  return (
    canAddMemberByCsv && (
      <>
        <Button data-testid="add-users-CSV-btn" onPress={() => setIsOpen(true)} variant="primary">
          {intl.formatMessage({id: 'common.userListTableSection.button.addUsersByCsv'})}
        </Button>
        {isOpen && (
          <BulkOperationModal
            analyticsContextFunc={() => constructAsyncAddAssignUserAnalytics({orgId})}
            isOpen={isOpen}
            mode={BULK_OPERATION_MODE.ADD}
            onCancel={() => {
              setIsOpen(false);
            }}
            onSuccess={() => {
              setIsOpen(false);
            }}
            pageContext={pageContext}
          />
        )}
      </>
    )
  );
});

export default AddUsersByCsv;
