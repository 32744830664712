import {GoUrl} from '@admin-tribe/acsc-ui';
import {Flex, Text} from '@adobe/react-spectrum';
import React from 'react';
import {useIntl} from 'react-intl';

import OverlayWait from 'common/components/wait/OverlayWait';
import useStatusData from 'features/notifications/hooks/use-status-data/useStatusData';

import StatusList from './status-list/StatusList';

/**
 * Represents the panel displayed when the status tab is selected in
 * the notifications drawer
 */
const StatusPanel = () => {
  const intl = useIntl();

  const {statusData} = useStatusData();

  const eventsToDisplay = statusData?.getActiveAndRecentStatusEvents();

  return (
    <Flex data-testid="notifications-status" direction="column">
      <Text marginBottom="size-100" marginTop="size-300">
        {intl.formatMessage(
          {id: 'status.panel.learnMore'},
          {
            goUrl: (urlText) => (
              <GoUrl name="aac_status" noWrap={false}>
                {urlText}
              </GoUrl>
            ),
          }
        )}
      </Text>
      <OverlayWait isLoading={!eventsToDisplay}>
        {eventsToDisplay?.length > 0 && <StatusList eventsToDisplay={eventsToDisplay} />}
      </OverlayWait>
    </Flex>
  );
};

export default StatusPanel;
