import {GoUrl} from '@admin-tribe/acsc-ui';
import {VisuallyHidden} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';
import './MerchandisingText.pcss';

const LINK_REGEX = /<a.+href="(.*)".*>(.*)<\/a>/gi;
const GO_URL_REGEX = /<gourl.+name="(.*)".*>(.*)<\/gourl>/gi;

/**
 * Component to render a custom message with enhanced markup using intl.formatMessage
 * with defaultMessage formatting. It renders HTML for `<a href="url">content</a>` and
 * `<b>text</b>`, by default. Custom tags can be added via values prop.
 */
const MerchandisingText = ({message, values}) => {
  const intl = useIntl();

  if (!message) {
    return null;
  }

  // Transform message to support special tag attributes
  const defaultMessage = message
    .replace(LINK_REGEX, '<Link>$1|$2</Link>')
    .replace(GO_URL_REGEX, '<GoUrl>$1|$2</GoUrl>');

  return intl.formatMessage(
    {
      defaultMessage,
      id: 'useDefaultMessage',
    },
    {
      b: (text) => <span styleName="bolded-text">{text}</span>,
      br: () => <br />,
      GoUrl: (...chunks) => {
        const [name, text] = chunks.join().split('|');
        return (
          <GoUrl name={name} noWrap={false}>
            {text}
          </GoUrl>
        );
      },
      i: (text) => <span styleName="italic-text">{text}</span>,
      Link: (...chunks) => {
        const [href, text] = chunks.join().split('|');
        return (
          <a href={href} rel="noopener noreferrer" target="_blank">
            {text}
            <VisuallyHidden elementType="span">
              {intl.formatMessage({
                id: 'account.selfCancel.components.merchandisingText.opensWindow',
              })}
            </VisuallyHidden>
          </a>
        );
      },
      p: (text) => <div>{text}</div>,
      ...values,
      shouldDisplayNewTerms: false,
    }
  );
};

MerchandisingText.propTypes = {
  /**
   * Message string to interpolate using intl.formatMessage
   */
  message: PropTypes.string.isRequired,
  /**
   * Object with tagName to render function mapping, to process custom tags from the message.
   */
  values: PropTypes.objectOf(PropTypes.func),
};

export default MerchandisingText;
