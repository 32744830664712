import {
  CONSUMABLE_SUMMARIZATION_SUMMARIZE_BY,
  ConsumableSummarizationList,
} from '@admin-tribe/acsc';

import rootStore from 'core/RootStore';
import auth from 'core/services/auth';

import HvaCard from '../HvaCard';
import {HVA_ORDER, HVA_TYPE} from '../hvaConstants';

/**
 * @description Function to assess if all SDL products have no seats assigned
 *
 * @returns {Promise<Boolean>} whether all SDL products have no seats assigned
 */
async function hasSDLProductsWithNoSeatsAssigned() {
  const sdlProducts = rootStore.organizationStore.productList.items.filter((product) =>
    product.isSharedDeviceLicense()
  );

  const summarizationList = await ConsumableSummarizationList.get({
    include_usage: false,
    organization_id: rootStore.organizationStore.activeOrgId,
    summarize_by: CONSUMABLE_SUMMARIZATION_SUMMARIZE_BY.LICENSE_ID,
  });
  return sdlProducts?.length > 0
    ? sdlProducts.every((product) =>
        summarizationList
          .getConsumablesForSummaryId(product.id, {
            summarize_by: CONSUMABLE_SUMMARIZATION_SUMMARIZE_BY.LICENSE_ID,
          })
          .some((consumable) => consumable.consumedQuantity === 0)
      )
    : false;
}

/**
 * This class creates a HVA card allowing user to set up their Shared Device Licenses
 */
class SetUpSdlHva extends HvaCard {
  /**
   * @description Method to fetch the SetUpSdlHva
   *
   * @param {Object} intl - react-intl to translate texts
   *
   * @returns {Promise<SetUpSdlHva[]>} the fetched instance
   */
  static async get({intl}) {
    if (!auth.isUserOrgAdmin()) {
      return Promise.reject();
    }

    const hasSDLsAndNoPackages = await hasSDLProductsWithNoSeatsAssigned();

    if (hasSDLsAndNoPackages) {
      return [new SetUpSdlHva({intl})];
    }

    return Promise.reject();
  }

  /**
   * @description Creates a new SetUpSdlHva instance
   *
   * @param {Object} intl - react-intl to translate texts
   *
   * @returns {SetUpSdlHva} the fetched instance
   */
  constructor({intl}) {
    const namespace = 'overview.highValueActionArea.setUpTasks.setUpSDL';

    super({
      ctaLabel: intl.formatMessage({id: `${namespace}.ctaLabel`}),
      description: intl.formatMessage({id: `${namespace}.description`}),
      dismissOnCTA: false,
      goUrl: 'aac_pop_artt_sdl_deploy',
      header: intl.formatMessage({id: `${namespace}.header`}),
      icon: {
        src: 'https://odin.adobe.com/content/dam/admin-tribe/assets/hva-illustrations/create-packages.svg',
        style: {width: '40px'},
      },
      id: 'hva-set-up-sdl',
      onCTA: () => {
        rootStore.helpStore.goToTopic('get-started.products.manage-sdl#sdl-set-up');
      },
      priorityOrder: HVA_ORDER.SET_UP_SDL,
      sectionTitle: intl.formatMessage({id: `${namespace}.sectionTitle`}),
      type: HVA_TYPE.SET_UP,
    });
  }
}

export default SetUpSdlHva;
