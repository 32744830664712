import binky, {CONTRACT_ADMIN, ConsumableSummarizationList, feature} from '@admin-tribe/acsc';
import binkyUI, {
  ImageIcon,
  ModalContent,
  ModalDescription,
  ModalHeading,
  ModalTagHeader,
  ModalWizardStepList,
  getProductDisplayName,
} from '@admin-tribe/acsc-ui';
import {Flex, Text, View} from '@adobe/react-spectrum';
import Provider from '@react/react-spectrum/Provider';
import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';

import {ROLE} from '../../users.constants';

import {useAdminModalContext} from './AddAdminModalContext';
import AddAdminModalToggleSection from './AddAdminModalToggleSection';
import {
  assignTargets,
  canEditRole,
  getDisabledSubRoles,
  getItemsPreselectedForRole,
  getItemsToDisableForRole,
  getProductsForAssignmentSection,
  getRoleValidityStatus,
  getSubRoles,
  isTopLevelRole,
  roleToTargetType,
  setUserRole,
  shouldPreselectSupportAdmin,
} from './adminRoleUtils';
import './AddAdminModal.pcss';

const AdminRolePicker = binkyUI.common.components.AdminRolePicker;
const AssignmentSectionContext = binkyUI.common.components.AssignmentSectionContext;
const GoUrl = binkyUI.common.components.GoUrl;

const AddAdminAssignRolesPage = ({consumableSummarizationList, productToAddAdmin, roles}) => {
  const intl = useIntl();
  const {
    contractList,
    initialUser,
    orgId,
    products,
    setHasUnsavedRoleChanges,
    setRoleValidityStatus,
    user,
  } = useAdminModalContext();
  const [rolesCount, setRolesCount] = useState(0);
  const initialRoles = useRef(cloneDeep(user.roles));
  const [assignableContracts, setAssignableContracts] = useState([]);

  // set assignable contracts if admin group id is available
  useEffect(() => {
    // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- cawright@ to update
    // istanbul ignore else -- else path is not taken because in the absence of contractList, assignableContracts is set to empty array
    if (contractList) {
      const contracts = contractList.filter((contract) => contract.adminGroupId);
      setAssignableContracts(contracts);
    }
  }, [contractList]);

  const [itemsToPreselect, setItemsToPreselect] = useState([]);
  const [isOpen, setOpen] = useState(false);

  const getDefaultSelectedSubRoles = (role) => {
    if (shouldPreselectSupportAdmin(role, user)) {
      return {checked: [ROLE.ADMIN.SUPPORT], disabled: getDisabledSubRoles(orgId)};
    }

    const subRoles = getSubRoles(role);
    if (subRoles) {
      return {
        checked: user?.roles.reduce((adminRoles, adminRole) => {
          if (
            subRoles.includes(adminRole.type) &&
            (isTopLevelRole(adminRole.type) || adminRole.targets?.length > 0)
          ) {
            adminRoles.push(adminRole.type);
          }
          return adminRoles;
        }, []),
        disabled: getDisabledSubRoles(orgId),
      };
    }
    return undefined;
  };

  const getDefaultSelectedRole = (role) => {
    if (feature.isEnabled('temp_parkour_mm')) {
      const subRoles = getSubRoles(role);
      if (subRoles) {
        return user?.roles.find((userRole) => subRoles.includes(userRole.type));
      }
      return user?.roles.some(
        (userRole) =>
          userRole.type === role &&
          (!productToAddAdmin ||
            // check if the product is in the targets of the user role for product detial page flow
            userRole.targets?.find((target) => target.id === productToAddAdmin.id))
      );
    }
    return user && user.roles.some((userRole) => userRole.type === role);
  };

  // eslint-disable @admin-tribe/admin-tribe/comment-side-effects -- cawright@ to update
  useEffect(() => {
    setHasUnsavedRoleChanges(false);
  }, [setHasUnsavedRoleChanges]);

  // eslint-disable @admin-tribe/admin-tribe/comment-side-effects -- cawright@ to update
  useEffect(() => {
    // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- cawright@ to update
    // istanbul ignore else -- set from context, cannot test in unit tests
    if (products?.length > 0 || contractList?.length > 0) {
      const processDefaultItems = () => getItemsPreselectedForRole(roles, user, orgId, products);
      setItemsToPreselect(processDefaultItems());
    }

    const initRoles = initialRoles.current;

    return () => {
      user.roles = initRoles;
      setRoleValidityStatus({});
      setHasUnsavedRoleChanges(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run on mount
  }, [products, contractList]);

  const onAdminRoleTargetAssignment = (role, assignedItems, subRole) => {
    // set invalid when a non top-level role has nothing assigned to it
    setRoleValidityStatus((prevRoleValidityStatus) => ({
      ...prevRoleValidityStatus,
      [role]: assignedItems.length > 0,
    }));
    assignTargets({assignedItems, role: subRole || role, user});
    setHasUnsavedRoleChanges(user.hasUnsavedChanges());
  };

  const onSelectCheckbox = (checked, role, subRole) => {
    setUserRole({checked, orgId, role: subRole, user});
    setRoleValidityStatus((prevRoleValidityStatus) => ({
      ...prevRoleValidityStatus,
      [role]: getRoleValidityStatus(role, user),
    }));
    setRolesCount((prevCount) => (checked ? prevCount + 1 : prevCount - 1));
    setHasUnsavedRoleChanges(user.hasUnsavedChanges());
  };

  const onAdminRoleToggle = (role, toggleState) => {
    if (role === ROLE.ADMIN.PRODUCT) setOpen((isOpened) => !isOpened);
    // set invalid when a non top-level role is toggled, since nothing is assigned to it yet
    setRoleValidityStatus((prevRoleValidityStatus) => ({
      ...prevRoleValidityStatus,
      [role]: isTopLevelRole(role) || productToAddAdmin || !toggleState,
    }));

    const subRoles = getSubRoles(role);

    if (subRoles && !toggleState) {
      const userRoles = user.roles.filter((userRole) => subRoles.includes(userRole.type));
      setRolesCount((prevCount) => prevCount - userRoles.length);
    } else if (!subRoles) {
      setRolesCount((prevCount) => (toggleState ? prevCount + 1 : prevCount - 1));
    }

    setUserRole({
      role,
      target: productToAddAdmin,
      toggleState,
      user,
    });
    setHasUnsavedRoleChanges(user.hasUnsavedChanges());

    // trigger selecting support admin action since it's preselected when user enable the toggle
    if (shouldPreselectSupportAdmin(role, user) && toggleState) {
      onSelectCheckbox(true, role, ROLE.ADMIN.SUPPORT);
    }
  };

  return (
    <>
      <ModalHeading>
        {user.isNew()
          ? intl.formatMessage({id: 'users.addAdminModal.header.addAdmin'})
          : intl.formatMessage({
              id: 'users.addAdminModal.header.editAdmin',
            })}
      </ModalHeading>
      {productToAddAdmin && (
        <ModalTagHeader
          IconComponent={<ImageIcon alt="" size="M" src={productToAddAdmin.getIcon()} />}
        >
          {getProductDisplayName(intl, productToAddAdmin)}
        </ModalTagHeader>
      )}
      <ModalDescription>
        {!initialUser && <ModalWizardStepList />}
        <Text>
          {intl.formatMessage(
            {id: 'users.addAdminModal.description.assignRoles'},
            {
              goUrl: (str) => <GoUrl name="aac_admin_roles">{str}</GoUrl>,
            }
          )}
        </Text>
      </ModalDescription>
      <ModalContent>
        <View marginBottom="size-300" marginTop="size-200">
          <span styleName="user-label">
            {intl.formatMessage(
              {
                id: 'users.addAdminModal.userEmail',
              },
              {bold: (str) => <span styleName="email-label">{str}</span>, user: user.email}
            )}
          </span>
          {user.isNew() && rolesCount > 0 && (
            <span data-testid="role-count-label" styleName="role-count-label">
              {intl.formatMessage(
                {
                  id: 'users.addAdminModal.selectedRoles',
                },
                {count: rolesCount}
              )}
            </span>
          )}
        </View>
        <Provider>
          <Flex alignItems="center" direction="column">
            <div
              aria-label={intl.formatMessage({
                id: 'users.addAdminModal.description.assignRolesAriaLabel',
              })}
              role="group"
              styleName="admin-roles-container"
            >
              {roles.map((role) =>
                (role === ROLE.ADMIN.PRODUCT || role === ROLE.ADMIN.LICENSE) &&
                feature.isEnabled('temp_add_contract_display_name') ? (
                  <AddAdminModalToggleSection
                    key={role}
                    defaultSelectedRole={getDefaultSelectedRole(role)}
                    disabled={
                      !canEditRole({
                        consumableSummarizationList,
                        orgId,
                        product: productToAddAdmin,
                        role,
                        user,
                      })
                    }
                    isOpen={isOpen}
                    itemsToDisable={getItemsToDisableForRole(
                      consumableSummarizationList,
                      products,
                      role,
                      user
                    )}
                    itemsToPreselect={itemsToPreselect}
                    onAdminRoleTargetAssignment={(assignedItems, subRole) =>
                      onAdminRoleTargetAssignment(role, assignedItems, subRole)
                    }
                    onAdminRoleToggle={(toggleState) => onAdminRoleToggle(role, toggleState)}
                    orgId={orgId}
                    products={products}
                    role={role}
                    user={user}
                  />
                ) : (
                  (role !== CONTRACT_ADMIN ||
                    (role === CONTRACT_ADMIN && feature.isEnabled('temp_contract_admin_role'))) && (
                    <AssignmentSectionContext.Provider
                      key={role}
                      // eslint-disable-next-line react/jsx-no-constructed-context-values -- @cawright to fix
                      value={{
                        consumableSummarizationList,
                        contractList: assignableContracts,
                        itemsToDisable: getItemsToDisableForRole(
                          consumableSummarizationList,
                          products,
                          role,
                          user
                        ),
                        itemsToPreselect: itemsToPreselect[roleToTargetType(role)]
                          ? {[roleToTargetType(role)]: itemsToPreselect[roleToTargetType(role)]}
                          : {},
                        products: getProductsForAssignmentSection(products, role),
                        role,
                        setItemsToPreselect,
                      }}
                    >
                      <AdminRolePicker
                        adminRole={role}
                        consumableSummarizationList={consumableSummarizationList}
                        defaultCheckbox={getDefaultSelectedSubRoles(role)}
                        defaultChecked={getDefaultSelectedRole(role)}
                        disabled={
                          !canEditRole({
                            consumableSummarizationList,
                            orgId,
                            product: productToAddAdmin,
                            role,
                            user,
                          })
                        }
                        onAssignment={(items, subRole) =>
                          onAdminRoleTargetAssignment(role, items, subRole)
                        }
                        onSelectCheckbox={(checked, subRole) =>
                          onSelectCheckbox(checked, role, subRole)
                        }
                        onToggle={(toggleState) => onAdminRoleToggle(role, toggleState)}
                        orgId={orgId}
                        productToAddAdmin={productToAddAdmin}
                      />
                    </AssignmentSectionContext.Provider>
                  )
                )
              )}
            </div>
          </Flex>
        </Provider>
      </ModalContent>
    </>
  );
};

AddAdminAssignRolesPage.propTypes = {
  consumableSummarizationList: PropTypes.instanceOf(ConsumableSummarizationList).isRequired,
  productToAddAdmin: PropTypes.instanceOf(binky.Product),
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default AddAdminAssignRolesPage;
