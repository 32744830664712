import {ANALYTICS_EVENT, eventBus} from '@admin-tribe/acsc';
import camelCase from 'lodash/camelCase';

import AnalyticsCollector from './AnalyticsCollector';
import {setProperty, track, trackEvent} from './aepAnalytics';

class AnalyticsEventHandler {
  constructor(analyticsConfig, organization) {
    this.analyticsConfig = analyticsConfig;
    this.organization = organization;
    this.pageName = undefined;

    const analyticContext = {
      analyticsConfig,
      pageName: undefined,
      reset: () => {
        analyticContext.pageName = undefined;
      },
      setProperty: setProperty.bind(this),
      triggerStateChange: (value) => {
        analyticContext.pageName = value;
      },
    };

    this.analyticsCollector = new AnalyticsCollector(analyticContext, organization);

    eventBus.registerEventHandler((eventId, eventDetail) => {
      if (eventId === ANALYTICS_EVENT) {
        this.onAnalyticsEvent(eventDetail);
      }
    });
  }

  onAnalyticsEvent(evt) {
    const {descriptors = {}, componentMethod, componentMethodType, attributes = {}, cgen} = evt;
    const {uiEvent = {}} = descriptors;
    const eventName = camelCase(uiEvent.eventName || componentMethod);
    const eventAction = uiEvent.eventAction || componentMethodType;
    const componentName = uiEvent.componentName || evt.componentName;

    setProperty('attributes', {
      action: eventAction,
      attributes,
      cgen,
      element: eventName,
      feature: eventName,
      type: componentName,
      widget: {
        name: eventName,
        type: componentName,
      },
    });

    const {analyticsContext} = this.analyticsCollector;
    analyticsContext.reset();

    AnalyticsCollector.collectProductOffer(evt);
    AnalyticsCollector.collectPurchasedProducts(evt);
    AnalyticsCollector.collectFloodgateInfo();
    this.analyticsCollector.collectDescriptors(evt);

    if (analyticsContext.pageName) {
      this.analyticsCollector.collectOrganizationInfo();
      track('state');
    } else {
      trackEvent(eventName, eventAction);
    }
  }

  onPageChange(topNavName) {
    this.analyticsCollector.collectPageInfo(topNavName);
    AnalyticsCollector.collectFloodgateInfo();
    this.analyticsCollector.collectOrganizationInfo();
    track('state');
  }
}

export default AnalyticsEventHandler;
