import {
  DIRECTORY_OWNERSHIP_STATUS,
  DIRECTORY_TYPE,
  DOMAIN_STATUS,
  IDP_STATUS,
  ORGANIZATION_MARKET_SUBSEGMENT,
  feature,
} from '@admin-tribe/acsc';
import {RULE_STATUS} from '@pandora/react-data-source-acrs';
import {toJS} from 'mobx';

import {getDirectoryList} from 'common/hooks/api/useDirectoryList';
import rootStore from 'core/RootStore';
import AutoAssignRulesCache from 'core/services/product/AutoAssignRulesCache';
import AdobeExpressK12Modal from 'features/overview/components/adobe-express-k12/AdobeExpressK12Modal';
import {IDP_TYPES} from 'features/settings/entities/IdpEntity';
import {getDirectory} from 'features/settings/hooks/api/useDirectory';
import {getDirectoryDomainList} from 'features/settings/hooks/api/useDomainList';

import HvaCard from '../HvaCard';
import {HVA_ORDER, HVA_TYPE} from '../hvaConstants';

import adobeExpressK12HvaLogo from './adobeExpressK12HvaLogo.svg';

// represents Adobe Express for K-12 for VIP product
const ADOBE_EXPRESS_K12_OFFER_ID = 'FA05B6C74154E566A9316813302AA3E5';

async function getPatchableIdps(directories) {
  let idps = [];
  let activeDomains = [];
  let shortestDomains = [];

  await Promise.all(
    directories.map(async (directory) => {
      // At least one active domain and default active IdP conditioned
      const domainList = await getDirectoryDomainList({directoryId: directory.id});
      const directoryDetails = await getDirectory({
        directoryId: directory.id,
        orgId: rootStore.organizationStore.activeOrgId,
      });

      const defaultIdp = directoryDetails?.idps?.find(
        (idp) => idp.id === directoryDetails?.defaultIdp
      );

      const currentActiveDomains = (domainList?.data ?? []).filter(
        (domain) => domain?.status === DOMAIN_STATUS.ACTIVE
      );

      activeDomains = [...activeDomains, ...currentActiveDomains];

      if (currentActiveDomains?.length > 0) {
        shortestDomains = [
          ...shortestDomains,
          currentActiveDomains
            .map((result) => result?.domainName)
            .reduce((a, b) => (a?.length <= b?.length ? a : b)),
        ];
      }

      if (domainList?.totalCount > 0 && activeDomains && defaultIdp?.status === IDP_STATUS.ACTIVE) {
        // At least one IdP with auto account creation not enabled and that IdP can't be SAML
        const patchableIdps = directoryDetails?.idps?.filter(
          (idp) => idp?.federationType !== IDP_TYPES.SAML && !idp?.jitConfig.accountCreation
        );

        if (patchableIdps?.length > 0) {
          patchableIdps.forEach((idp) => {
            idps = [
              ...idps,
              {
                directoryId: directory.id,
                idp,
              },
            ];
          });
        }
      }
    })
  );

  return {activeDomains, idps, shortestDomains};
}

/**
 * This class creates a HVA card allowing user to finish setting up Adobe Express K12
 */
class AdobeExpressK12Hva extends HvaCard {
  // eslint-disable-next-line complexity -- needed for conditional rendering
  static async get({intl}) {
    // temp_adobe_express_k12 feature flag conditioned
    if (feature.isDisabled('temp_adobe_express_k12')) {
      return Promise.reject();
    }

    // EDU and K12 market segment conditioned
    if (
      !rootStore.organizationStore.isActiveOrgEdu &&
      !rootStore.organizationStore.activeOrg.marketSubsegments.includes(
        ORGANIZATION_MARKET_SUBSEGMENT.K_12
      )
    ) {
      return Promise.reject();
    }

    const adobeExpressK12Product = rootStore.organizationStore.productList?.items.find(
      (product) => product.offerId === ADOBE_EXPRESS_K12_OFFER_ID
    );

    // Adobe Express K12 license conditioned
    if (!adobeExpressK12Product) {
      return Promise.reject();
    }

    let hasValidRules = false;
    let inactiveRule = null;

    // Auto product assignment for the Adobe Express K12 product rule is disabled
    const rules = AutoAssignRulesCache.get().autoAssignRules;

    const k12Rule = rules?.find((rule) => rule?.licenseId === adobeExpressK12Product?.id);

    if (k12Rule) {
      if (k12Rule.status === RULE_STATUS.ACTIVE) {
        hasValidRules = true;
      } else {
        inactiveRule = toJS(k12Rule);
      }
    }

    // Federated and owned directory conditioned
    const directoryList = await getDirectoryList();

    const federatedAndOwnedDirectories = directoryList?.data?.filter(
      (item) =>
        item.type === DIRECTORY_TYPE.TYPE3 &&
        item.ownershipStatus === DIRECTORY_OWNERSHIP_STATUS.OWNED
    );

    if (!federatedAndOwnedDirectories?.length) {
      return Promise.reject();
    }

    const {activeDomains, idps, shortestDomains} = await getPatchableIdps(
      federatedAndOwnedDirectories
    );

    if (activeDomains?.length === 0) {
      return Promise.reject();
    }

    if (idps?.length === 0 && hasValidRules && !inactiveRule) {
      return Promise.reject();
    }

    return Promise.resolve([
      new AdobeExpressK12Hva({
        adobeExpressK12Product,
        hasValidRules,
        idps,
        inactiveRule,
        intl,
        shortestDomains,
      }),
    ]);
  }

  constructor({shortestDomains, intl, idps, inactiveRule, hasValidRules, adobeExpressK12Product}) {
    const namespace = 'overview.highValueActionArea.setUpTasks.adobeExpressK12';
    super({
      ctaLabel: intl.formatMessage({id: `${namespace}.ctaLabel`}),
      description: intl.formatMessage({
        id: `${namespace}.description`,
      }),
      header: intl.formatMessage({id: `${namespace}.header`}),
      icon: {
        src: adobeExpressK12HvaLogo,
      },
      id: 'hva-adobe-express-k12',
      isDismissible: false,
      modalConfig: {
        closeProp: 'onClose',
        Component: AdobeExpressK12Modal,
        props: {
          adobeExpressK12Product,
          domains: shortestDomains,
          hasValidRules,
          inactiveRule,
          patchableIdps: idps,
        },
      },
      priorityOrder: HVA_ORDER.ADOBE_EXPRESS_K12,
      sectionTitle: intl.formatMessage({id: `${namespace}.sectionTitle`}),
      type: HVA_TYPE.SET_UP,
    });
  }
}

export default AdobeExpressK12Hva;
export {ADOBE_EXPRESS_K12_OFFER_ID};
