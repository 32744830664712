import {feature} from '@admin-tribe/acsc';
import React from 'react';

import AddUsersToOrgModal from 'common/components/add-user-modals/AddUsersToOrgModal';
import {
  getIntroductionsData,
  getNumberOfPendingProductRequests,
} from 'common/services/sophia/shared-contextual-params/sharedContextualParamsUtils';
import rootStore from 'core/RootStore';
import {
  canViewDirectContractBillingHistory,
  isAllowedToAddProducts,
} from 'core/organizations/access/organizationAccess';
import {
  canUseAddProductMiniApp,
  canViewProductAccessRequests,
  canViewQuickAssignModal,
} from 'core/products/access/productAccess';
import chatProvider from 'core/services/chat/chatProvider';
import {canAssignUser, canViewUserIntroductions} from 'core/user/access/userAccess';
import {goToAccountRoot} from 'features/account/routing/navigation-callbacks/navigationCallbacks';
import {
  ButtonKeys,
  getButtonArray,
  getCohortStatus,
} from 'features/overview/components/quick-link-banner-section/QuickLinkCohortUtils';
import {
  goToAddUserToOrg,
  goToQuickAssignProducts,
  goToSavedUserIntroductions,
  goToUserIntroductions,
} from 'features/overview/routing/navigation-callbacks/navigationCallbacks';
import AddProductModalWrapper from 'features/products/components/add-product-modal-wrapper/AddProductModalWrapper';
import {
  goToAddProducts,
  goToProductRequests,
  goToProducts,
} from 'features/products/routing/navigation-callbacks/navigationCallbacks';

/* eslint-disable max-lines -- temp disable. Size will shrink when deeplinking flags are permanent */
/* eslint-disable react/jsx-filename-extension -- temporarily disabled until more deep-links created */
/* eslint-disable @admin-tribe/admin-tribe/istanbul-ignore -- temp workaround until deep-links completed */
/**
 * Asynchronously fetches and returns data for the "Buy More" button,
 * if the user is allowed to add products.
 * @async
 * @returns {Promise<Object|undefined>} An object containing label
 * and analytics data for the button, or undefined if not allowed.
 */
const getBuyLicensesButtonData = (
  primaryButton,
  openAddProductMiniApp,
  setOpenAddProductMiniApp
) => {
  if (!isAllowedToAddProducts() || !canUseAddProductMiniApp()) {
    return undefined;
  }

  /* istanbul ignore next -- unmockable, temporary code to be replaced with deeplinking */
  const openAddProducts = () => {
    setOpenAddProductMiniApp(true);
  };

  /* istanbul ignore next -- unmockable, temporary code to be replaced with deeplinking */
  const onAddProductClose = () => {
    setOpenAddProductMiniApp(false);
  };

  /* istanbul ignore next -- unmockable, temporary code to be replaced with deeplinking */
  const AddProductMiniApp = () =>
    openAddProductMiniApp && (
      <AddProductModalWrapper chat={chatProvider} onClose={onAddProductClose} />
    );

  return {
    analyticsOptions: {
      analyticsAction: 'add-licenses',
    },
    children: [<AddProductMiniApp key="add-product-mini-app" />],
    displayCount: false,
    handleCTA: openAddProducts,
    iconAlt: 'icon',
    iconName: 'buyLicenses',
    label: 'Buy licenses',
    number: 0,
    testId: 'quick-link-add-licenses',
    variant: primaryButton ? 'accent' : 'secondary',
  };
};

/**
 * Asynchronously fetches and returns data for the "Buy More" button,
 * if the user is allowed to add products. This version uses the deeplink.
 * @async
 * @returns {Promise<Object|undefined>} An object containing label
 * and analytics data for the button, or undefined if not allowed.
 */
const getBuyLicensesDeeplinkButtonData = (primaryButton) => {
  if (!isAllowedToAddProducts() || !canUseAddProductMiniApp()) {
    return undefined;
  }

  return {
    analyticsOptions: {
      analyticsAction: 'add-licenses',
    },
    displayCount: false,
    handleCTA: goToAddProducts,
    iconAlt: 'icon',
    iconName: 'buyLicenses',
    label: 'Buy licenses',
    number: 0,
    testId: 'quick-link-add-licenses',
    variant: primaryButton ? 'accent' : 'secondary',
  };
};

/**
 * Fetches and returns data for the "Add Users ETLA" button.
 *
 * @async
 * @returns {Promise<Object|undefined>} An object containing label and count
 * for adding users, or undefined if not applicable.
 */
const getAddUsersETLAButtonData = (isAddUsersModalOpen, setIsAddUsersModalOpen) => {
  if (canAssignUser(rootStore.organizationStore.activeOrgId)) {
    /* istanbul ignore next -- unmockable, temporary code to be replaced with deeplinking */
    const openAddUsersModal = () => {
      setIsAddUsersModalOpen(true);
    };

    /* istanbul ignore next -- unmockable, temporary code to be replaced with deeplinking */
    const onAddUsersModalClose = () => {
      setIsAddUsersModalOpen(false);
    };

    /* istanbul ignore next -- unmockable, temporary code to be replaced with deeplinking */
    const AddUsersModal = () =>
      isAddUsersModalOpen && (
        <AddUsersToOrgModal
          isOpen
          onClosed={onAddUsersModalClose}
          onSuccess={onAddUsersModalClose}
          orgId={rootStore.organizationStore.activeOrgId}
        />
      );

    return {
      analyticsOptions: {
        analyticsAction: 'add-users',
      },
      children: [<AddUsersModal key="add-users-modal" />],
      count: 0,
      displayCount: false,
      handleCTA: openAddUsersModal,
      iconAlt: 'icon',
      iconName: 'addUsers',
      label: 'overview.quickLinks.addUsers.title',
      testId: 'quick-link-add-users',
      variant: 'secondary',
    };
  }

  return undefined;
};

/**
 * Fetches and returns data for the "Add Users ETLA" button, however using the deeplink.
 *
 * @async
 * @returns {Promise<Object|undefined>} An object containing label and count
 * for adding users, or undefined if not applicable.
 */
const getAddUsersToOrgDeeplinkButtonData = () => {
  if (canAssignUser(rootStore.organizationStore.activeOrgId)) {
    return {
      analyticsOptions: {
        analyticsAction: 'add-users',
      },
      count: 0,
      displayCount: false,
      handleCTA: goToAddUserToOrg,
      iconAlt: 'icon',
      iconName: 'addUsers',
      label: 'overview.quickLinks.addUsers.title',
      testId: 'quick-link-add-users',
      variant: 'secondary',
    };
  }

  return undefined;
};

/**
 * Fetches and returns data for the "Manage Products" button.
 *
 * @async
 * @returns {Promise<Object|undefined>} An object containing label and details
 * for managing the plan, or undefined if not applicable.
 */
const getManageProductsButtonData = () => ({
  analyticsOptions: {
    analyticsAction: 'manage-products',
  },
  count: 0,
  displayCount: false,
  handleCTA: goToProducts,
  iconAlt: 'icon',
  iconName: 'managePlan',
  label: 'Manage products',
  testId: 'quick-link-manage-products',
  variant: 'secondary',
});

/**
 * Fetches and returns data for the "Assign Licenses" button. Currently goes to same place as Manage Products.
 *
 * @async
 * @returns {Promise<Object|undefined>} An object containing label and details
 * for managing the plan, or undefined if not applicable.
 */
const getAssignLicensesButtonData = () => ({
  analyticsOptions: {
    analyticsAction: 'assign-licenses',
  },
  count: 0,
  displayCount: false,
  handleCTA: goToProducts,
  iconAlt: 'icon',
  iconName: 'assignLicenses',
  label: 'Assign licenses',
  testId: 'quick-link-assign-licenses',
  variant: 'accent',
});

/**
 * Fetches and returns data for the "Account And Billing" button.
 *
 * @async
 * @returns {Promise<Object|undefined>} An object containing label and details
 * for the account button, or undefined if not applicable.
 */
const getAccountAndBillingButtonData = () => {
  if (!canViewDirectContractBillingHistory()) {
    return undefined;
  }

  return {
    analyticsOptions: {
      analyticsAction: 'go-to-account',
    },
    count: 0,
    displayCount: false,
    handleCTA: goToAccountRoot,
    iconAlt: 'icon',
    iconName: 'accountAndBilling',
    label: 'overview.quickLinks.goToAccount.title',
    testId: 'quick-link-account',
    variant: 'secondary',
  };
};

/**
 * Fetches and returns data for the "Add Users" button.
 *
 * @async
 * @returns {Promise<Object|undefined>} An object containing label and details
 * for adding users, or undefined if not applicable.
 */
const getAddUsersButtonData = () => {
  if (!canViewQuickAssignModal()) {
    return undefined;
  }

  return {
    analyticsOptions: {
      analyticsAction: 'quick-assign-products',
    },
    count: 0,
    displayCount: false,
    handleCTA: goToQuickAssignProducts,
    iconAlt: 'icon',
    iconName: 'addUsers',
    label: 'Add users',
    testId: 'quick-link-quick-assign',
    variant: 'secondary',
  };
};

/**
 * Asynchronously fetches and returns data for the "Product Requests" button,
 * if the user can view product access requests.
 *
 * @async
 * @returns {Promise<Object|undefined>} An object containing label and count
 * for pending product requests, or undefined if not viewable.
 */
const getReviewRequestsButtonData = async (primaryButton) => {
  if (!canViewProductAccessRequests()) {
    return undefined;
  }

  const numberOfPendingProductRequests = await getNumberOfPendingProductRequests();

  if (numberOfPendingProductRequests > 0) {
    return {
      analyticsOptions: {
        analyticsAction: 'product-requests',
      },
      count: numberOfPendingProductRequests,
      displayCount: true,
      handleCTA: goToProductRequests,
      iconAlt: 'icon',
      iconName: 'assignLicensesSVG',
      label: 'Review requests',
      testId: 'quick-link-product-requests',
      variant: primaryButton ? 'accent' : 'secondary',
    };
  }

  return undefined;
};

/**
 * Asynchronously fetches and returns data for the "User Introductions" button,
 * if the user can view user introductions.
 *
 * @async
 * @returns {Promise<Object|undefined>} An object containing label and count
 * for user introductions, or undefined if not viewable.
 */
/* eslint-disable no-nested-ternary -- the alternative is ugly. */
const getConsolidateTeamButtonData = async (primaryButton) => {
  if (!canViewUserIntroductions()) {
    return undefined;
  }

  const {ignoredIntroductionsCount, pendingIntroductionsCount} = await getIntroductionsData();

  const activationCount =
    ignoredIntroductionsCount > 0
      ? ignoredIntroductionsCount
      : pendingIntroductionsCount >= 1 && pendingIntroductionsCount <= 10
      ? pendingIntroductionsCount
      : 0;

  if (activationCount > 0) {
    return {
      analyticsOptions: {
        analyticsAction:
          ignoredIntroductionsCount > 0 ? 'saved-user-introductions' : 'pending-user-introductions',
      },
      count: ignoredIntroductionsCount + pendingIntroductionsCount,
      displayCount: true,
      handleCTA: ignoredIntroductionsCount > 0 ? goToSavedUserIntroductions : goToUserIntroductions,
      iconAlt: 'icon',
      iconName: 'consolidateTeam',
      label: 'Review users',
      testId:
        ignoredIntroductionsCount > 0
          ? 'quick-link-saved-user-introductions'
          : 'quick-link-pending-user-introductions',
      variant: primaryButton ? 'accent' : 'secondary',
    };
  }

  return undefined;
};
/* eslint-enable no-nested-ternary -- re-enabling */

/**
 * Asynchronously fetches and returns data for all the buttons relevant to the
 * user by determining their cohort then building the buttons based on the
 * cohort status.
 *
 * @async
 * @returns {Promise<Array|undefined>} An array containing objects with label
 * and details for the buttons, or undefined.
 */
const getAllButtonData = async (
  openAddProductMiniApp,
  setOpenAddProductMiniApp,
  isAddUsersModalOpen,
  setIsAddUsersModalOpen
) => {
  const cohortStatus = await getCohortStatus();
  const cohortButtonData = getButtonArray(cohortStatus);
  // eslint-disable-next-line complexity -- feature flags raise complexity from 10 to 11 temporarily
  const buttonDataPromises = cohortButtonData.map((data, index) => {
    const primaryButton = index === 0;
    switch (data) {
      case ButtonKeys.CONSOLIDATE_TEAM:
        return getConsolidateTeamButtonData(primaryButton);
      case ButtonKeys.REVIEW_REQUESTS:
        return getReviewRequestsButtonData(primaryButton);
      case ButtonKeys.MANAGE_PRODUCTS:
        return getManageProductsButtonData();
      case ButtonKeys.ADD_USERS:
        if (feature.isEnabled('temp_tno_use_sqa_for_qlv2_add_user')) {
          return getAddUsersButtonData();
        }
        if (feature.isEnabled('temp_tno_add_users_modal_deeplink_via_quicklink')) {
          return getAddUsersToOrgDeeplinkButtonData();
        }
        return getAddUsersETLAButtonData(isAddUsersModalOpen, setIsAddUsersModalOpen);
      case ButtonKeys.ASSIGN_LICENSES:
        return getAssignLicensesButtonData();
      case ButtonKeys.ACCOUNT_AND_BILLING:
        return feature.isEnabled('temp_tno_quick_links_account_button')
          ? getAccountAndBillingButtonData()
          : undefined;
      case ButtonKeys.BUY_LICENSES:
      default:
        return feature.isEnabled('temp_tno_buy_license_deeplink_via_quicklink')
          ? getBuyLicensesDeeplinkButtonData(primaryButton)
          : getBuyLicensesButtonData(
              primaryButton,
              openAddProductMiniApp,
              setOpenAddProductMiniApp
            );
    }
  });

  const resolvedButtonData = await Promise.all(buttonDataPromises);
  return resolvedButtonData.filter((data) => data !== undefined);
};
/* eslint-enable react/jsx-filename-extension -- temporarily disabled until more deep-links created */
/* eslint-enable @admin-tribe/admin-tribe/istanbul-ignore -- temp workaround will last until deep-links completed */

export {
  getAssignLicensesButtonData,
  getBuyLicensesButtonData,
  getBuyLicensesDeeplinkButtonData,
  getReviewRequestsButtonData,
  getCohortStatus,
  getConsolidateTeamButtonData,
  getAddUsersButtonData,
  getAddUsersETLAButtonData,
  getAddUsersToOrgDeeplinkButtonData,
  getAllButtonData,
  getAccountAndBillingButtonData,
  getManageProductsButtonData,
};
/* eslint-enable max-lines -- reactivate */
