import {FULFILLABLE_ITEM_DELEGATION_TYPE} from '@admin-tribe/acsc';

function getProductDataSummaryItemsForConsumable(consumable, product, intl) {
  const {unit} = consumable;
  return [
    product.fulfillableItemList.hasLaboratoryLicenseManagement()
      ? undefined
      : {
          label: intl.formatMessage(
            {id: 'products.productProfileDetails.infobar.summaryItem.label.total'},
            {unit}
          ),
          value: consumable.totalQuantity,
        },
    {
      label: intl.formatMessage(
        {id: 'products.productProfileDetails.infobar.summaryItem.label.used'},
        {unit}
      ),
      value: consumable.consumedQuantity,
    },
  ];
}

function getSummaryItemsForConsumable(consumableSummarizationList, licenseGroupId, product, intl) {
  const consumables = consumableSummarizationList.getConsumablesForSummaryId(licenseGroupId);
  return consumables
    .flatMap((consumable) => getProductDataSummaryItemsForConsumable(consumable, product, intl))
    .filter((summaryItem) => !!summaryItem);
}

function getSummaryItemsForLicenses(licenseGroup, product, intl) {
  const totalLicenses = licenseGroup.totalQuantity;
  const usedLicenses = licenseGroup.provisionedQuantity;

  if (totalLicenses && product.usesSeatBasedDelegation()) {
    return [
      {
        label: intl.formatMessage({
          id: 'products.productProfileDetails.infobar.summaryItem.label.totalLicenses',
        }),
        value: totalLicenses,
      },
      {
        label: intl.formatMessage({
          id: 'products.productProfileDetails.infobar.summaryItem.label.usedLicenses',
        }),
        value: usedLicenses,
      },
    ];
  }
  return [];
}

function getSummaryItemsForServices(licenseGroup, intl) {
  const services = licenseGroup.getServiceFulfilledItems({delegationConfigurable: true});
  const quotas = licenseGroup.getQuotaFulfilledItems({
    delegationConfigurable: true,
    delegationType: FULFILLABLE_ITEM_DELEGATION_TYPE.PERSON,
  });
  let totalServiceAndQuotaCount = services.length;
  let usedServiceAndQuotaCount = services.filter((service) => service.selected).length;

  totalServiceAndQuotaCount += quotas.length;
  usedServiceAndQuotaCount += quotas.filter(
    (quota) => Number.parseInt(quota.chargingModel.cap, 10) > 0
  ).length;

  if (totalServiceAndQuotaCount) {
    return [
      {
        label: intl.formatMessage({
          id: 'products.productProfileDetails.infobar.summaryItem.label.totalServices',
        }),
        value: totalServiceAndQuotaCount,
      },
      {
        label: intl.formatMessage({
          id: 'products.productProfileDetails.infobar.summaryItem.label.usedServices',
        }),
        value: usedServiceAndQuotaCount,
      },
    ];
  }
  return [];
}

/**
 * @description Generates an Auto-Assign-Rule summary (Object with label/value
 *   properties) for a given ProductProfile
 * @param {Rule} productProfileRule - the product profile rule
 * @param {Object} intl - react intl object
 * @returns {Object} a summary {label, value} of the applicable Auto-Assign-Rule
 */
function getSummaryItemAutoAssign({productProfileRule, intl}) {
  let valueText;

  switch (productProfileRule.triggers) {
    case 'ON_DEMAND_OR_URL':
      valueText = intl.formatMessage({
        id: 'products.productProfileDetails.infobar.summaryItem.autoAssignRuleValue.onDemandAccess',
      });
      break;
    case 'ON_URL':
      valueText = intl.formatMessage({
        id: 'products.productProfileDetails.infobar.summaryItem.autoAssignRuleValue.urlAccess',
      });
      break;
    default:
      // For all other cases, including the unused "ON_DEMAND", show "none"
      valueText = intl.formatMessage({
        id: 'products.productProfileDetails.infobar.summaryItem.autoAssignRuleValue.none',
      });
      break;
  }

  return {
    label: intl.formatMessage({
      id: 'products.productProfileDetails.infobar.summaryItem.label.autoAssignRule',
    }),
    value: valueText,
  };
}

/**
 * @description Gets the summary items for the Product Profile.
 *
 * @param {Object} options - The options
 * @param {LicenseGroup} options.licenseGroup - a LicenseGroup model object
 * @param {ConsumableSummarizationList} options.consumableSummarizationList - a ConsumableSummarizationList model object
 * @param {Product} options.product - a Product model object
 * @param {Rule} [options.productProfileRule] - the auto assignment rule
 * @param {Object} intl - react intl object
 * @returns {Array} an array with the summary items for the product
 */
function getProductProfileSummaryItems(options, intl) {
  let summaryData = [];

  if (options.product.isConsumable()) {
    summaryData = [
      ...summaryData,
      ...getSummaryItemsForConsumable(
        options.consumableSummarizationList,
        options.licenseGroup.id,
        options.product,
        intl
      ),
    ];
  } else {
    summaryData = [
      ...summaryData,
      ...getSummaryItemsForLicenses(options.licenseGroup, options.product, intl),
    ];
  }
  summaryData = [...summaryData, ...getSummaryItemsForServices(options.licenseGroup, intl)];

  if (options.productProfileRule) {
    summaryData = [
      ...summaryData,
      getSummaryItemAutoAssign({intl, productProfileRule: options.productProfileRule}),
    ];
  }

  return summaryData;
}

export {getProductProfileSummaryItems, getSummaryItemAutoAssign};
