import {configStore} from '@admin-tribe/acsc';
import {CUSTOMER_SEGMENT} from '@pandora/data-model-product';
import {SystemMessageSubType} from '@pandora/mini-app-messaging-types';
import PropTypes from 'prop-types';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';
import chatProvider from 'core/services/chat/chatProvider';
import MiniAppModalWrapper from 'features/mini-apps/modal-wrapper/MiniAppModalWrapper';

const ADD_PRODUCT_MINI_APP_NAME = 'AddProductMiniApp';
const REVIEW_USER_INTRODUCTIONS_MINI_APP_NAME = 'review-user-introductions-app';
const REVIEW_USER_INTROS_MINI_APP_URL_ENV_PARAM = 'services.adminMiniApps.reviewUserIntroductions';
const START_CHAT_PASS_THROUGH = 'START_CHAT_PASS_THROUGH';
/**
 * @description Represents a wrapper for Review User Introductions Mini App
 */
const ReviewUserIntroductionsModalWrapper = ({onClose, startTab}) => {
  const intl = useIntl();
  const {organizationStore} = rootStore;
  const {url} = configStore.get(REVIEW_USER_INTROS_MINI_APP_URL_ENV_PARAM);

  const validStartTabs = ['saved']; // from the mini-app
  const actualStartTab = validStartTabs.includes(startTab) ? startTab : undefined;

  const params = {
    chatProvider,
    ctrId: organizationStore.contractList.items.find(
      (contract) =>
        (contract.isDirectContract() ||
          (contract.isIndirectContract() && contract.customerSegment === CUSTOMER_SEGMENT.TEAM)) &&
        contract.isStatusActive()
    )?.id,
    orgId: organizationStore.activeOrgId,
    ...(actualStartTab && {startTab: actualStartTab}),
  };

  // Message handler to process just the System events from Add Product Mini App
  const systemMessageHandler = useCallback(({data, subType, type}) => {
    // eslint-disable-next-line default-case -- no need to handle default case
    switch (subType) {
      case SystemMessageSubType.NOTIFICATION:
        if (type === START_CHAT_PASS_THROUGH) {
          if (data?.open ?? true) {
            // Open jarvis chat if data?.open is true or undefined/null
            chatProvider.openMessagingWindow({
              sourceText: ADD_PRODUCT_MINI_APP_NAME,
            });
          } else {
            // Close jarvis chat
            chatProvider.closeMessageWindow();
          }
        }
        break;
    }
  }, []);

  return (
    <MiniAppModalWrapper
      baseUrl={url}
      miniAppName={REVIEW_USER_INTRODUCTIONS_MINI_APP_NAME}
      onClose={onClose}
      onMessage={systemMessageHandler}
      params={params}
      title={intl.formatMessage({id: 'miniApps.reviewUserIntroductions.title'})}
    />
  );
};

ReviewUserIntroductionsModalWrapper.propTypes = {
  /** Callback to invoke when modal is closed. */
  onClose: PropTypes.func.isRequired,
  startTab: PropTypes.string,
};

export default ReviewUserIntroductionsModalWrapper;
