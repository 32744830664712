import {AuthenticatedUser} from '@admin-tribe/acsc';
import {CUSTOMER_SEGMENT} from '@pandora/administration-core-types';
import {Contract} from '@pandora/data-model-contract';
import {JilModelList} from '@pandora/data-model-list';
import {Product} from '@pandora/data-model-product';
import {canAccessEnterpriseSupport} from '@pandora/data-model-support-ticket';
import {getContactInfo} from '@pandora/react-data-model-support-contact-info';
import {
  SupportTicketContactInfo,
  SupportTicketContactInfoContentModel,
} from '@pandora/react-support-ticket';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';

const {organizationStore} = rootStore;

const CONTACT_INFO_VARIANT = {
  FORM_SECTION: 'FORM_SECTION',
  HEADER: 'HEADER',
};

/**
 * A widget to display the Support Ticket Contact Info
 */
const SupportContactInfo = ({variant = CONTACT_INFO_VARIANT.HEADER}) => {
  const intl = useIntl();
  const SUPPORT_NUMBER = 'aac_cct_support_numbers';
  const OTHER_REGION = 'ac_other_region_ph_chg';

  const contractList = new JilModelList({
    items: organizationStore.contractList.items.map((contract) => new Contract(contract)),
  });

  const productList = new JilModelList({
    itemCount: organizationStore.productList.pagination.itemCount,
    items: organizationStore.productList.items.map(
      (product) =>
        new Product({
          ...product,
          fulfillableItems: product.fulfillableItemList.items,
        })
    ),
  });

  const customerSegment = canAccessEnterpriseSupport(contractList, productList)
    ? CUSTOMER_SEGMENT.ENTERPRISE
    : CUSTOMER_SEGMENT.TEAM;

  const {hoursKey, languageKey, phoneNumbers, regionKey} = getContactInfo(
    AuthenticatedUser.get().getCountryCode(),
    customerSegment
  );

  const phoneLabels = phoneNumbers.reduce((result, phone) => {
    if (phone.labeledBy) {
      return {
        ...result,
        [phone.labeledBy]: intl.formatMessage({
          id: `support.supportTicketContactInfo.phoneNumber.${phone.labeledBy}`,
        }),
      };
    }
    return result;
  }, {});

  const content = SupportTicketContactInfoContentModel.createEntry({
    goUrlContentModel: customerSegment === CUSTOMER_SEGMENT.TEAM ? SUPPORT_NUMBER : OTHER_REGION,
    [languageKey]: intl.formatMessage({
      id: `support.supportTicketContactInfo.language.${languageKey}`,
    }),
    otherRegions: intl.formatMessage({
      id: 'support.supportTicketContactInfo.linkOutAriaLabel',
    }),
    [`hours${hoursKey}`]: intl.formatMessage({
      id: `support.supportTicketContactInfo.${hoursKey}`,
    }),
    [regionKey]: intl.formatMessage({
      id: `support.supportTicketContactInfo.region.${regionKey}`,
    }),
    ...phoneLabels,
  });

  return (
    <SupportTicketContactInfo
      content={content}
      customerSegment={customerSegment}
      data-testId="support-contact-info"
      variant={variant}
    />
  );
};

SupportContactInfo.propTypes = {
  /**
   * Used to display the type of the variant
   * Default is HEADER
   */
  variant: PropTypes.oneOf(Object.keys(CONTACT_INFO_VARIANT)),
};

export default SupportContactInfo;
