import {
  AuthenticatedUser,
  PRODUCT_APPLICABLE_OFFER_TYPE,
  feature,
  flattenKeys,
  getFloodgateVariantData,
  log,
} from '@admin-tribe/acsc';
import {format} from 'date-fns';
import get from 'lodash/get';

import rootStore from 'core/RootStore';

import {
  AEP_ANALYTICS_SITE_SECTION,
  ANALYTICS_TRACKED_FEATURE_GROUP_FLAGS,
} from './AnalyticsConstants';
import {setProperty} from './aepAnalytics';
import analyticsPageEventTranslator from './translator/analyticsPageEventTranslator';
import cancellationOrderEventTranslator from './translator/cancellationOrderEventTranslator';
import cartEventTranslator from './translator/cartEventTranslator';
import chatEventTranslator from './translator/chatEventTranslator';
import errorEventTranslator from './translator/errorEventTranslator';
import gainsightEventTranslator from './translator/gainsightEventTranslator';
import organizationEventTranslator from './translator/organizationEventTranslator';
import primaryProductEventTranslator from './translator/primaryProductEventTranslator';
import productListEventTranslator from './translator/productListEventTranslator';
import sophiaEventTranslator from './translator/sophiaEventTranslator';
import uiEventTranslator from './translator/uiEventTranslator';

export default class AnalyticsCollector {
  static collectPurchasedProductsPrice(product) {
    // Any of the price components may be missing
    const basePrice = product.priceWithoutTax;
    let priceWithTax;
    if (basePrice !== undefined && product.tax !== undefined) {
      priceWithTax = Number(basePrice) + Number(product.tax);
    }
    let price = {basePrice, priceWithTax};
    price = Object.values(price).some((val) => val !== undefined) ? price : undefined;
    return price;
  }

  static setOrganizationProps(organizationInfo) {
    return {
      accountType: organizationInfo.accountType,
      authOrigin: organizationInfo.authOrigin,
      authSystem: organizationInfo.authSystem,
      corpId: organizationInfo.corpId,
      corpName: organizationInfo.corpName,
      product: organizationInfo.product,
    };
  }

  static getSubscriptionStatus(Offer) {
    return Offer === PRODUCT_APPLICABLE_OFFER_TYPE.TRIAL ? 'Trial' : 'Paid';
  }

  /**
   * @description Convenience method to easily map data from 'sourceObj' into the `digitalData`.
   *
   * @param {*} sourceObj the object that has the source data
   * @param {*} dataMap  the object mapping to follow
   * @param {*} prefix (optional) allow getting 'sourceObject' data with different starting property.
   */
  static dataMapper(sourceObj, dataMap) {
    const flattenDataMapKeys = flattenKeys(dataMap);
    flattenDataMapKeys.forEach((sourceObjKey) => {
      const destPropName = get(dataMap, sourceObjKey);
      setProperty(destPropName, get(sourceObj, sourceObjKey));
    });
  }

  static collectOrgContracts() {
    const contractList = rootStore.organizationStore.contractList;
    const contracts = contractList?.items || [];
    if (contracts.length === 0) {
      return {}; // Make sure there's no extra data sent to analytics
    }

    const contractData = contracts.map((contract) => ({
      anniversaryDate: contract.getAnniversaryDate()
        ? format(contract.getAnniversaryDate(), 'MM|yyyy')
        : '',
      autoRenewStatus: contract.autoRenewalMode || '',
      autoRenewUpdateChannel: 'renew',
      billingType: contract.getBillingFrequency(),
      buyingProgram: contract.buyingProgram,
      customerSegment: contract.customerSegment || '',
      id: contract.id,
      inRenewalWindow: contract.isInRenewalWindow(),
      model: contract.model || '',
      mustAcceptTerms: contract.mustAcceptTerms() || false,
      offsetFromAnniversaryDate: contract.getOffsetFromAnniversaryDate(),
      offsetFromContractStartDate: Number.isNaN(contract.getOffsetFromStartDate())
        ? ''
        : contract.getOffsetFromStartDate(),
      offsetFromRenewalStartDate: Number.isNaN(contract.getOffsetFromRenewalWindowStartDate())
        ? ''
        : contract.getOffsetFromRenewalWindowStartDate(),
      salesChannel: contract.salesChannel || '',
    }));

    return {
      contract: {
        anniversaryDate: contractData.map((data) => data.anniversaryDate).join(','),
        autoRenewStatus: contractData.map((data) => data.autoRenewStatus).join(','),
        autoRenewUpdateChannel: contractData.map((data) => data.autoRenewUpdateChannel).join(','),
        billingType: contractData.map((data) => data.billingType).join(','),
        buyingProgram: contractData.map((data) => data.buyingProgram).join(','),
        contractID_Added: contractList.contractID_Added || [],
        contractID_Removed: contractList.contractID_Removed || [],
        customerSegment: contractData.map((data) => data.customerSegment).join(','),
        id: contractData.map((data) => data.id).join(','),
        inRenewalWindow: contractData.map((data) => data.inRenewalWindow).join(','),
        model: contractData.map((data) => data.model).join(','),
        mustAcceptTerms: contractData.map((data) => data.mustAcceptTerms).join(','),
        offsetFromAnniversaryDate: contractData
          .map((data) => data.offsetFromAnniversaryDate)
          .join(','),
        offsetFromContractStartDate: contractData
          .map((data) => data.offsetFromContractStartDate)
          .join(','),
        offsetFromRenewalStartDate: contractData
          .map((data) => data.offsetFromRenewalStartDate)
          .join(','),
        salesChannel: contractData.map((data) => data.salesChannel).join(','),
      },
    };
  }

  /**
   * collect product offer information
   * @param {eventData} eventData
   */
  static collectProductOffer(eventData) {
    AnalyticsCollector.dataMapper(eventData, {
      attributes: {
        productOffer: 'primaryProduct.productInfo.offerId',
        profilesChangedCount: 'primaryProduct.attributes.profilesChangedCount',
        profilesRemovedCount: 'primaryProduct.attributes.profilesRemovedCount',
      },
    });
  }

  /**
   * @description Collect floodgate analytcs data
   */
  static collectFloodgateInfo() {
    if (feature.isEnabled('temp_variant_info_analytics')) {
      const variantInfoPipeSplit = getFloodgateVariantData(ANALYTICS_TRACKED_FEATURE_GROUP_FLAGS);
      const variantInfoCommaSplit = variantInfoPipeSplit.replace(/\|/g, ',');

      const variantInfo = feature.isEnabled('temp_variant_info_with_comma_split')
        ? variantInfoCommaSplit
        : variantInfoPipeSplit;
      setProperty('attributes.variantInfo', variantInfo, false);
    }
  }

  static collectOrgProducts() {
    const productList = rootStore.organizationStore.productList?.items || [];
    const productCodes = [...new Set(productList.map((item) => item.code))].sort().join(',');

    if (productCodes.length === 0) {
      return {}; // Make sure there's no extra data sent to analytics
    }

    const ownedProducts = productList.map((product) => {
      let assignedLicense = '';
      let totalLicense = '';

      if (product.delegatedQuantity !== null && product.delegatedQuantity !== undefined) {
        assignedLicense = product.delegatedQuantity;
        totalLicense = product.delegatedQuantity;
      }

      if (!product.hasUnlimitedLicenses()) {
        totalLicense = product.getAssignableLicenseCount();
      }

      return {
        attributes: {
          assignedLicense,
          contractId: product.contractId || '',
          totalLicense,
          // A boolean primitive with value 'false' will be ignored by Launch Analytics.
          // A coerced string value is used here to ensure it is sent.
          unlimitedLicenses: product.hasUnlimitedLicenses().toString(),
        },
        productInfo: {
          offerId: product.offerId || '',
          subscriptionGUID: product.id || '',
          subscriptionStatus: AnalyticsCollector.getSubscriptionStatus(
            product.applicableOfferType || ''
          ),
        },
      };
    });

    return {
      product: ownedProducts,
      productCodes,
    };
  }

  /**
   * collect purchased products information
   * @param {eventData} eventData
   */
  static collectPurchasedProducts(eventData) {
    const purchasedProducts = eventData.attributes?.productsPurchased;
    if (purchasedProducts) {
      const productList = purchasedProducts
        .map((product) => ({
          category: {
            primaryCategory: product.category,
          },
          price: AnalyticsCollector.collectPurchasedProductsPrice(product),
          productInfo: {offerId: product.offer},
          quantity: product.quantity,
        }))
        .filter(Boolean);

      setProperty('product', productList);

      AnalyticsCollector.dataMapper(eventData, {
        attributes: {
          transaction: {
            amountWithoutTax: 'transaction.total.basePrice',
            amountWithTax: 'transaction.total.priceWithTax',
            currencyCode: 'currency.code',
            purchaseAuthorizationId: 'transaction.transactionID',
          },
        },
      });
    }
  }

  constructor(analyticsContext, activeOorganization) {
    this.analyticsContext = analyticsContext;
    this.activeOorganization = activeOorganization;
    this.config = analyticsContext.analyticsConfig;
    this.translators = {};
    this.loadTranslators();
  }

  /**
   *
   * @param {*} eventData
   */
  collectDescriptors(eventData) {
    Object.entries(eventData.descriptors ?? {}).forEach(([descriptorName, descriptor]) => {
      let descriptorService;
      try {
        descriptorService = this.translators[descriptorName];
      } catch (error) {
        log.error(`Missing Launch translator for ${descriptorName}`, error);
      }
      if (descriptorService && typeof descriptorService === 'function') {
        descriptorService(descriptor, this.analyticsContext);
      }
    });
  }

  /**
   * Collects organization information.
   */
  collectOrganizationInfo() {
    const org = this.activeOorganization;
    const organizationInfo = {
      accountType: AuthenticatedUser.get().getRoles().getHighestRoleForOrg(org.id),
      authOrigin: 'ims',
      authSystem: 'ims',
      corpId: org.id,
      corpName: org.name,
      ...AnalyticsCollector.collectOrgContracts(),
      ...AnalyticsCollector.collectOrgProducts(),
    };

    Object.keys(organizationInfo).forEach((key) => {
      if (organizationInfo[key] === undefined) {
        delete organizationInfo[key];
      }
    });

    setProperty('organization', AnalyticsCollector.setOrganizationProps(organizationInfo), false);
    setProperty('contract', organizationInfo.contract, false);
    setProperty('productCodes', organizationInfo.productCodes, false);
  }

  /**
   * Collects page information.
   * @param {string} pageName - The name of the page.
   */
  collectPageInfo(pageName) {
    setProperty('page.autoTrack', false, false);
    setProperty('page.env', this.config.env, false);
    setProperty('page.language', this.analyticsContext.locale, false);
    let host;
    // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- unit testing is not needed here as it is not server side rendering
    /* istanbul ignore next -- unit testing is not needed here as it is it is not server side rendering */
    if (typeof window !== 'undefined') {
      host = window.location.host;
    }
    setProperty('page.customPageName', `${host}:${pageName}`, false);
    setProperty('page.siteSection', AEP_ANALYTICS_SITE_SECTION, false);
    setProperty('page.solution.name', 'aac', false);
  }

  loadTranslators() {
    this.registerTranslator('analyticsPage', analyticsPageEventTranslator);
    this.registerTranslator('cancellationOrder', cancellationOrderEventTranslator);
    this.registerTranslator('cart', cartEventTranslator);
    this.registerTranslator('error', errorEventTranslator);
    this.registerTranslator('chat', chatEventTranslator);
    this.registerTranslator('uiEvent', uiEventTranslator);
    this.registerTranslator('productList', productListEventTranslator);
    this.registerTranslator('sophia', sophiaEventTranslator);
    this.registerTranslator('gainsight', gainsightEventTranslator);
    this.registerTranslator('organizationInfo', organizationEventTranslator);
    this.registerTranslator('primaryProduct', primaryProductEventTranslator);
  }

  registerTranslator(key, translator) {
    this.translators[key] = translator;
  }

  ////////
}
