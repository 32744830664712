import {getAdminConsoleDisplayableContracts} from '@admin-tribe/acsc';
import {HeaderNavItem} from '@admin-tribe/acsc-ui';
import {generatePath} from 'react-router-dom';

import rootStore from 'core/RootStore';
import {canViewAccountOverviewPage, canViewAccounts} from 'core/account/access/accountAccess';
import {canViewPackages} from 'core/packages/access/packagesAccess';
import {canViewProducts} from 'core/products/access/productAccess';
import {canViewSettings} from 'core/settings/access/settingsAccess';
import {canViewStorage} from 'core/storage/access/storageAccess';
import {canViewSupport} from 'core/support/supportAccess';
import {
  PATH_ACCOUNT_CONTRACT_DETAILS,
  PATH_ACCOUNT_OVERVIEW,
} from 'features/account/routing/accountPaths';
import insightsStore from 'features/insights/insightsStore';
import {PATH_INSIGHTS_ROOT} from 'features/insights/routing/insightsPaths';
import {PATH_OVERVIEW} from 'features/overview/routing/overviewPaths';
import {PATH_ALL_PACKAGES} from 'features/packages/routing/packagesPaths';
import {PATH_PRODUCTS} from 'features/products/routing/productsPaths';
import {PATH_SETTINGS_ROOT} from 'features/settings/routing/settingsPaths';
import {PATH_STORAGE_ROOT} from 'features/storage/routing/storagePaths';
import {PATH_SUPPORT} from 'features/support/routing/supportPaths';
import {PATH_USERS} from 'features/users/routing/usersPaths';

/**
 * Gets the navigation items that will be displayed in the site header
 * @returns {<HeaderNavItem[]>}
 */
const getHeaderNavItems = () => {
  const pathParams = {
    orgId: rootStore.organizationStore.activeOrgId,
  };

  const accountPath = canViewAccountOverviewPage()
    ? generatePath(PATH_ACCOUNT_OVERVIEW, pathParams)
    : generatePath(PATH_ACCOUNT_CONTRACT_DETAILS, {
        ...pathParams,
        contractId: getAdminConsoleDisplayableContracts(
          rootStore.organizationStore.contractListIncludingInactive
        )?.[0]?.id,
      });

  const settingsPathTemplate = PATH_SETTINGS_ROOT;

  return [
    new HeaderNavItem({
      // All users have access to Overview
      href: generatePath(PATH_OVERVIEW, pathParams),
      key: 'overview',
      labelKey: 'overview.title',
      order: 0,
    }),
    new HeaderNavItem({
      accessor: canViewProducts,
      href: generatePath(PATH_PRODUCTS, pathParams),
      key: 'products',
      labelKey: 'products.title',
      order: 1,
    }),
    new HeaderNavItem({
      href: generatePath(PATH_USERS, pathParams),
      key: 'users',
      labelKey: 'users.title',
      order: 2,
    }),
    new HeaderNavItem({
      accessor: canViewPackages,
      href: generatePath(PATH_ALL_PACKAGES, pathParams),
      key: 'packages',
      labelKey: 'packages.title',
      order: 3,
    }),
    new HeaderNavItem({
      accessor: canViewAccounts,
      href: accountPath,
      key: 'account',
      labelKey: 'account.title',
      order: 4,
    }),
    new HeaderNavItem({
      accessor: canViewStorage,
      href: generatePath(PATH_STORAGE_ROOT, pathParams),
      key: 'storage',
      labelKey: 'storage.title',
      order: 5,
    }),
    new HeaderNavItem({
      // Wrapped in a promise due to JS private fields issue
      accessor: () => Promise.resolve(insightsStore.canViewInsights()),
      href: generatePath(PATH_INSIGHTS_ROOT, pathParams),
      key: 'insights',
      labelKey: 'binky.widgets.insights.title',
      order: 6,
    }),
    new HeaderNavItem({
      accessor: canViewSettings,
      href: generatePath(settingsPathTemplate, pathParams),
      key: 'settings',
      labelKey: 'settings.title',
      order: 7,
    }),
    new HeaderNavItem({
      accessor: canViewSupport,
      href: generatePath(PATH_SUPPORT, pathParams),
      key: 'support',
      labelKey: 'support.title',
      order: 8,
    }),
  ];
};

// eslint-disable-next-line import/prefer-default-export -- Only function for now
export {getHeaderNavItems};
