import {MemberListTable, OverlayWait} from '@admin-tribe/acsc-ui';
import {
  SELECTION_MODE,
  TableActions,
  TableFilters,
  TableSection,
} from '@pandora/react-table-section';
import {observer} from 'mobx-react-lite';
import React, {cloneElement} from 'react';
import {useIntl} from 'react-intl';

import UsersDetailsDrawer from 'common/components/users-details-drawer/UsersDetailsDrawer';
import {useMemberListContext} from 'common/hooks/member-list-context/MemberListContext';
import updateMemberStore from 'common/stores/member-store/updateMemberStore';
import {getMemberDetailsHref, getTableMsgSubkey} from 'common/utils/member-utils/memberUtils';
import {isOrgAdmin} from 'core/organizations/access/organizationAccess';
import {canViewUserDetails} from 'core/user/access/userAccess';
import {goToAdminDetails} from 'features/users/routing/navigation-callbacks/navigationCallbacks';

import AdminListTableActions from './AdminListTableActions';

/**
 * A table to display an JIL admin or developer list.
 * A developer is an admin with role=LICENSE_DEV_ADMIN.
 * This should be wrapped in a MemberListContextProvider.
 */
const AdminListTableSection = observer(() => {
  const {
    canRemoveMember,
    getIsItemSelectable,
    memberListDisplay,
    pageContext,
    productIconList,
    store,
    tableFilter,
  } = useMemberListContext();
  const intl = useIntl();
  const msgSubkey = getTableMsgSubkey({pageContext});
  const selectionMode = canRemoveMember ? SELECTION_MODE.MULTIPLE : SELECTION_MODE.NONE;

  // When admin wants to see more products, go to admins detail page.
  const onClickMore = (member) => {
    goToAdminDetails({userId: member.id});
  };

  return (
    <OverlayWait isLoading={store.isLoading} showContent size="M">
      <TableSection
        getIsItemSelectable={getIsItemSelectable}
        getItemKey={(item) => item.id}
        isServerError={store.hasLoadingError}
        items={store.currentItems}
        onTableSectionChange={(params) => updateMemberStore({...params, store})}
        pageNumber={store.pageNumber}
        selectionMode={selectionMode}
        totalPages={store.totalPages}
      >
        <TableFilters
          hasSearch
          label={intl.formatMessage({id: 'common.adminListTableSection.search'})}
        >
          {tableFilter}
        </TableFilters>
        <TableActions>
          <AdminListTableActions />
        </TableActions>
        {/* Don't show the MemberListTable until there are items (or a message to be shown) */}
        {/* because we don't want the no items message shown during the initial load. */}
        {(store.currentItems || store.hasLoadingError) && (
          <MemberListTable
            aria-label={intl.formatMessage({
              id: `common.adminListTableSection.table.${msgSubkey}.ariaLabel`,
            })}
            canViewMemberDetails={canViewUserDetails}
            display={memberListDisplay}
            drawerComponent={<UsersDetailsDrawer />}
            getDisplayNameHref={(member) => getMemberDetailsHref({member, pageContext})}
            isSystemAdmin={isOrgAdmin()}
            noItemsFoundContentMessage={intl.formatMessage({
              id: `common.adminListTableSection.table.${msgSubkey}.noItemsFoundContentMessage`,
            })}
            noSearchResultsContentMessage={intl.formatMessage({
              id: `common.adminListTableSection.table.${msgSubkey}.noSearchResultsContentMessage`,
            })}
            productIconList={productIconList && cloneElement(productIconList, {onClickMore})}
          />
        )}
      </TableSection>
    </OverlayWait>
  );
});

export default AdminListTableSection;
