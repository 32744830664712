import {
  ModalContent,
  ModalDialog,
  ModalHeading,
  showError as showErrorToast,
  showSuccess as showSuccessToast,
} from '@admin-tribe/acsc-ui';
import {DateRangePicker, Divider, Flex, View} from '@adobe/react-spectrum';
import {getLocalTimeZone, today} from '@internationalized/date';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import {useContentLogsContext} from '../ContentLogsContext';

/**
 * @description the modal for creating a new content log.
 */
const CreateContentLogModal = observer(({onClose}) => {
  const {contentLogsStore} = useContentLogsContext();

  const dateTodayLocal = today(getLocalTimeZone());
  const ninetyDaysAgoLocal = dateTodayLocal.subtract({days: 90});
  const oneWeekAgoLocal = dateTodayLocal.subtract({weeks: 1});

  const [endDate, setEndDate] = useState(dateTodayLocal.toString());
  const [isValidEntry, setIsValidEntry] = useState(true); // default date range is always valid
  const [startDate, setStartDate] = useState(oneWeekAgoLocal.toString());

  const intl = useIntl();

  const onChangeDateRange = (range) => {
    if (!range) {
      setIsValidEntry(false);
      return;
    }
    const {start, end} = range;
    setStartDate(start.toString());
    setEndDate(end.toString());
    setIsValidEntry(
      start.compare(ninetyDaysAgoLocal) >= 0 &&
        start.compare(end) <= 0 &&
        end.compare(dateTodayLocal) <= 0
    );
  };

  const onCreate = async () => {
    try {
      await contentLogsStore.createContentLog({
        endDate,
        startDate,
      });
      contentLogsStore.resetPageState();
      onClose();
      contentLogsStore.load();
      showSuccessToast(
        intl.formatMessage({id: 'settings.contentLogs.createReport.confirmMessage'})
      );
    } catch (error) {
      showErrorToast();
    }
  };

  return (
    <ModalDialog
      cancelLabel={intl.formatMessage({id: 'common.modal.buttons.cancel'})}
      ctaLabel={intl.formatMessage({id: 'settings.contentLogs.createReport'})}
      heightVariant="static"
      id="create-content-log-modal"
      isCtaDisabled={!isValidEntry}
      isLoading={contentLogsStore.isLoading}
      onCancel={onClose}
      onCta={onCreate}
    >
      <ModalHeading>{intl.formatMessage({id: 'settings.contentLogs.createReport'})}</ModalHeading>
      <Divider />

      <ModalContent>
        <Flex direction="column" gap="size-200">
          <View>
            {intl.formatMessage({id: 'settings.contentLogs.createReport.modalDescription'})}
          </View>
          <DateRangePicker
            data-testid="date-range-picker"
            defaultValue={{
              end: dateTodayLocal,
              start: oneWeekAgoLocal,
            }}
            description={intl.formatMessage({
              id: 'settings.contentLogs.createReport.dateRange.description',
            })}
            errorMessage={intl.formatMessage({
              id: 'settings.contentLogs.createReport.dateRange.errorMessage',
            })}
            label={intl.formatMessage({id: 'settings.contentLogs.createReport.dateRange.label'})}
            maxValue={dateTodayLocal}
            minValue={ninetyDaysAgoLocal}
            onChange={onChangeDateRange}
          />
          <View UNSAFE_style={{fontStyle: 'italic'}}>
            {intl.formatMessage({id: 'settings.contentLogs.createReport.modalFooter'})}
          </View>
        </Flex>
      </ModalContent>
    </ModalDialog>
  );
});

CreateContentLogModal.propTypes = {
  /**
   * Function to close the modal dialog.
   */
  onClose: PropTypes.func.isRequired,
};

export default CreateContentLogModal;
