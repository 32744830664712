import {FULFILLABLE_ITEM_DELEGATION_TYPE} from '@admin-tribe/acsc';
import {Rule} from '@pandora/data-model-acrs';

import {canViewAutoAssignmentRules} from 'core/products/access/productAccess';
import auth from 'core/services/auth';
import AutoAssignRulesCache from 'core/services/product/AutoAssignRulesCache';

/**
 * @description Fetches Auto-Assign-Rule for a given LicenseGroup
 * @param {ProductProfile} licenseGroup a LicenseGroup model object
 * @returns {Rule|Object} The Auto-Assign-Rule associated with ProductProfile
 *   if there is one, else an empty Object.
 */
function getProductProfileRule(licenseGroup) {
  // This notifier currently checks a managed cache rather than make a dynamic API call,
  // as the API code in Pandora is not cached yet.
  const rules = AutoAssignRulesCache.get().autoAssignRules;
  const ruleObject = rules.find((rule) => rule.productProfile === licenseGroup.id);
  return ruleObject ? new Rule(ruleObject) : {};
}

/**
 * @description Whether the auto assignment modal should be enabled
 *   on a ProductProfile page.
 * @param {Product} product - the Product model
 * @returns true if the modal should be enabled, false otherwise
 */
function enableAutoAssignmentModal(product) {
  return canViewAutoAssignmentRules() && product.isDelegatableToUser();
}

/**
 * @description Whether the settings link should be shown
 * @param {LicenseGroup} licenseGroup - the LicenseGroup model
 * @param {Product} product - the Product model
 * @returns true if the settings link should be shown, false otherwise
 */
function canEditProfile(licenseGroup, product) {
  return auth.canUpdateProfile({
    productProfileId: licenseGroup.id,
    productTargetExpression: product.targetExpression,
  });
}

/**
 * @description Gets the icon for a license group
 *
 * @param {LicenseGroup} licenseGroup - the LicenseGroup model
 * @param {Product} product - the Product model
 * @returns the icon for the license group
 */
function getIcon(licenseGroup, product) {
  return product.fulfillableItemList.hasSingleDesktopApplicationConfig() &&
    licenseGroup.getSelectedDesktopIcons()?.svg
    ? licenseGroup.getSelectedDesktopIcons()?.svg
    : product.getIcon?.();
}

/**
 * Fetch the configurable services and person delegated quotas for the provided
 * product profile, sorted by enterprise name.
 *
 * @param {LicenseGroup} productProfile the product profile to fetch items from
 * @param {Collator} collator provided by useCollator from @react-aria/i18n
 * @returns an array of Service and Person-delegated Quota FIs sorted by enterprise name
 */
function getSortedServicesAndPersonDelegatedQuotas(productProfile, collator) {
  const servicesAndQuotas = [
    ...productProfile.getServiceFulfilledItems({delegationConfigurable: true}),
    ...productProfile.getQuotaFulfilledItems({
      delegationConfigurable: true,
      delegationType: FULFILLABLE_ITEM_DELEGATION_TYPE.PERSON,
    }),
  ];

  return servicesAndQuotas.sort((a, b) => collator.compare(a.enterpriseName, b.enterpriseName));
}

export {
  getProductProfileRule,
  enableAutoAssignmentModal,
  canEditProfile,
  getIcon,
  getSortedServicesAndPersonDelegatedQuotas,
};
