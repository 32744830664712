const DATA = 'data';
const GLOBAL_MODAL_OPEN_EVENT = 'global-modals-open';

const HVA_ORDER = {
  /* eslint-disable sort-keys -- maintain order */
  SOPHIA: 0,

  // Set-up/onboarding tasks.
  ADOBE_EXPRESS_K12: 90,
  TAKE_TOUR: 100, // discovery task enabled for all contracts for first usage
  ADD_PRODUCTS: 200,
  UNPAID_INVOICES: 300,
  SET_UP_IDENTITY: 400,
  DOMAIN_ENFORCEMENT: 425,
  SET_UP_SOIDC: 450,
  SET_UP_SDL: 500,
  SET_UP_FRL: 600,
  OFFER_SWITCH_MIGRATION_ASSIGN_LICENSES: 700,
  START_ASSIGN_LICENSES: 800,
  FINISH_ASSIGN_LICENSES: 900,
  ASSIGN_ENTERPRISE_LICENSES: 1000,
  SET_UP_SECURITY_CONTACTS: 1100,

  // Value discovery tasks.
  ACCEPT_FREE_OFFERS: 1200,
  ROSTER_SYNC: 1250,
  ADD_ADMINS: 1300,
  CREATE_PACKAGES: 1400,
  CHANGE_CONTRACT_OWNERS: 1500,
  VIEW_REPORTS: 1600,
  SET_POLICIES: 1700,
  BOOK_EXPERT_SESSION: 1800,
  VISIT_ADOBE_IO: 1900,
  CREATE_USER_GROUPS: 2000,
  VISIT_USER_GUIDE: 2100,
  /* eslint-enable sort-keys -- maintain order  */
};

const HVA_TYPE = {
  SET_UP: 'setUp',
  SOPHIA: 'sophia',
  VALUE_DISCOVERY: 'valueDiscovery',
};

const MINIMUM_SEATS_REQUIRED = 30;

export {DATA, GLOBAL_MODAL_OPEN_EVENT, HVA_TYPE, HVA_ORDER, MINIMUM_SEATS_REQUIRED};
