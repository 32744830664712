import {log} from '@admin-tribe/acsc';
import {Button, Flex} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useContext} from 'react';
import {useIntl} from 'react-intl';

import {PackageSummaryDrawerContext} from 'features/packages/components/package-summary-drawer/PackageSummaryDrawerContextProvider';
import {PACKAGE_TYPE_CONSTANTS} from 'features/packages/packagesConstants';
import CreatePackageService from 'features/packages/services/CreatePackageService';
import PackagesAdminService from 'features/packages/services/PackagesAdminService';
import PackagesEntitlementsService from 'features/packages/services/PackagesEntitlementsService';

const DrawerActionButtons = ({allPackagesStore, closeDrawer, onCustomizePress, onUpdatePress}) => {
  const intl = useIntl();
  const {attributes} = useContext(PackageSummaryDrawerContext);

  function disableDownload() {
    if (attributes.isTemplate) {
      return PackagesAdminService.isBuildDisabled();
    }
    return false;
  }

  function areFrlUpdatesAvailableForPackageIfApplicable() {
    // if it's not FRL package, hasValidFrlEntitlements is true by default
    let hasValidFrlEntitlements = attributes.package && !attributes.package.isFrlOrNuellPackage;
    if (
      attributes.package?.isFrlOrNuellPackage &&
      (PackagesEntitlementsService.hasFrlEntitlement ||
        PackagesEntitlementsService.hasNuellEntitlement)
    ) {
      if (attributes.package.frlPackagingInfo) {
        const packagedOfferIdList = attributes.package.frlPackagingInfo.adminPackageLicenseInfo.map(
          (info) => info.licenseID
        );

        const entitledOfferIdList =
          attributes.package.packageType === PACKAGE_TYPE_CONSTANTS.NUELL
            ? PackagesEntitlementsService.getNuellOffers.map((offer) => offer.id)
            : PackagesEntitlementsService.getFrlOffers(attributes.package.packageType).map(
                (offer) => offer.id
              );
        hasValidFrlEntitlements =
          packagedOfferIdList.filter((id) => entitledOfferIdList.includes(id)).length ===
          packagedOfferIdList.length;
      } else {
        hasValidFrlEntitlements = true;
      }
    }
    return hasValidFrlEntitlements;
  }

  function disableRetry() {
    return PackagesAdminService.isBuildDisabled();
  }
  function disableCustomize() {
    return PackagesAdminService.isBuildDisabled();
  }

  const onDownloadPress = async () => {
    closeDrawer();
    if (attributes.template) {
      if (attributes.template.nativePackageId) {
        await attributes.template.downloadNativePackage();
        return;
      }
      CreatePackageService.downloadTemplatizedPackage(
        attributes.template,
        PackagesEntitlementsService.hasCcEntitlement && !attributes.template.shouldNotAddCCD
      );
    } else if (attributes.package) {
      attributes.package.downloadPackage();
      // @priyeshk to add analytics upon clicking download button
    } else {
      log.error(
        'Something is wrong Invalid/Null',
        attributes.isTemplate ? 'template' : 'package',
        'can not be downloaded'
      );
    }
  };

  function doRetry() {
    if (attributes.package) {
      allPackagesStore.retryBuildingPackage(attributes.package.packageId);
    } else {
      log.error('Something is wrong Invalid/Null package can not be retried');
    }
  }

  const onRetryPress = () => {
    closeDrawer();
    doRetry();
  };

  function setCreatePackageFactoryValues() {
    CreatePackageService.setCreatePackageFactoryValuesForTemplate(
      attributes.template,
      PackagesEntitlementsService.hasCcEntitlement && !attributes.template.shouldNotAddCCD,
      attributes.isNotELearningTemplate
    );
  }

  const handleCustomizePress = () => {
    closeDrawer();
    setCreatePackageFactoryValues();
    onCustomizePress();
  };

  const handleUpdatePress = () => {
    closeDrawer();
    onUpdatePress();
  };

  return (
    <Flex direction="row" marginBottom="size-50" marginStart="size-50" marginTop="size-200">
      {attributes.showDownload && (
        <Button
          data-testid="download-button"
          isDisabled={disableDownload()}
          marginEnd="size-200"
          onPress={onDownloadPress}
          variant="accent"
        >
          {intl.formatMessage({
            id: 'packages.summaryDrawerHeader.drawerActionButtons.downloadButton',
          })}
        </Button>
      )}
      {attributes.showRetry && (
        <Button
          data-testid="retry-button"
          isDisabled={disableRetry() || !areFrlUpdatesAvailableForPackageIfApplicable()}
          marginEnd="size-200"
          onPress={onRetryPress}
          variant="accent"
        >
          {intl.formatMessage({id: 'packages.summaryDrawerHeader.drawerActionButtons.retryButton'})}
        </Button>
      )}
      {attributes.showUpdatePackage && (
        <Button data-testid="update-package-button" onPress={handleUpdatePress} variant="primary">
          {intl.formatMessage({
            id: 'packages.summaryDrawerHeader.drawerActionButtons.updatePackageButton',
          })}
        </Button>
      )}
      {attributes.showCustomize && (
        <Button
          data-testid="customize-button"
          isDisabled={disableCustomize()}
          marginEnd="size-200"
          onPress={handleCustomizePress}
          variant="primary"
        >
          {intl.formatMessage({
            id: 'packages.summaryDrawerHeader.drawerActionButtons.customizeButton',
          })}
        </Button>
      )}
    </Flex>
  );
};

DrawerActionButtons.propTypes = {
  /**
   * AllPackageStore for retry package build
   */
  allPackagesStore: PropTypes.shape({
    retryBuildingPackage: PropTypes.func,
  }),
  /**
   * Callback function to close the summary drawer
   */
  closeDrawer: PropTypes.func,
  /**
   * Callback function that opens Customize template when customize template button is pressed
   */
  onCustomizePress: PropTypes.func,
  /**
   * Callback function that opens Update Package Dialog when Update Package button is pressed
   */
  onUpdatePress: PropTypes.func,
};

export default DrawerActionButtons;
