import {CountryPicker, GoUrl} from '@admin-tribe/acsc-ui';
import {Flex, Heading, Text} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import React, {useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {useDirectorySetupContext} from 'features/settings/components/directory/DirectorySetupContext';
import useAttributeMappingsLabels from 'features/settings/hooks/directory-setup/useAttributeMappingsLabels';

import './AttributeMapping.pcss';

const AttributeMapping = observer(() => {
  const intl = useIntl();
  const {directorySetupStore} = useDirectorySetupContext();

  // Get the default country
  useEffect(() => {
    directorySetupStore.getDefaultCountry();
  }, [directorySetupStore]);

  const attributeMappingsLabels = useAttributeMappingsLabels(directorySetupStore.idp);

  const setDefaultCountry = (selectedValue) => {
    if (directorySetupStore.data.defaultCountry === selectedValue) {
      return;
    }

    directorySetupStore.setDefaultCountry(selectedValue);
  };

  return (
    <>
      <div styleName="attributes-mapping-container">
        <Flex direction="column" width="100%">
          <Heading level={4} marginBottom="size-50" marginTop={0}>
            <FormattedMessage id="settings.attributeMapping.title" />
          </Heading>
          <Text>
            {directorySetupStore.idp.isSaml && (
              <FormattedMessage
                id="settings.attributeMapping.description"
                values={{
                  link: (linkText) => <GoUrl name="aac_attribute_mappings">{linkText}</GoUrl>,
                }}
              />
            )}
            {directorySetupStore.idp.isOidc && (
              <FormattedMessage
                id="settings.attributeMapping.descriptionOidc"
                values={{
                  link: (linkText) => <GoUrl name="aac_attribute_mappings">{linkText}</GoUrl>,
                }}
              />
            )}
          </Text>
        </Flex>
      </div>
      <div styleName="attributes-container">
        <dl styleName="attributes-list">
          <div styleName="attributes-list-item">
            <dt>
              <FormattedMessage id="settings.attributeMapping.firstNameLabel" />
            </dt>
            <dd>{attributeMappingsLabels?.firstName}</dd>
          </div>

          <div styleName="attributes-list-item">
            <dt>
              <FormattedMessage id="settings.attributeMapping.lastNameLabel" />
            </dt>
            <dd>{attributeMappingsLabels?.lastName}</dd>
          </div>

          <div styleName="attributes-list-item">
            <dt>
              <FormattedMessage id="settings.attributeMapping.emailLabel" />
            </dt>
            <dd>{attributeMappingsLabels?.email}</dd>
          </div>

          <div styleName="attributes-list-item">
            <dt>
              <FormattedMessage id="settings.attributeMapping.countryLabel" />
            </dt>
            <dd>{attributeMappingsLabels?.country}</dd>
          </div>
        </dl>

        <div styleName="default-country-picker">
          <CountryPicker
            defaultCountryCode={directorySetupStore.data.defaultCountry}
            label={intl.formatMessage({id: 'settings.attributeMapping.selectCountryLabel'})}
            onChange={setDefaultCountry}
            selectedCountryCode={directorySetupStore.data.defaultCountry}
            useLabel
          />
        </div>
      </div>
    </>
  );
});

export default AttributeMapping;
