import {
  InfoItemScorecard,
  Page,
  PageActions,
  PageBreadcrumbs,
  PageContent,
  PageHeader,
  PageInfoBar,
} from '@admin-tribe/acsc-ui';
import {Button, DialogContainer, Item} from '@adobe/react-spectrum';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {useLoaderData} from 'react-router-dom';

import useDocumentTitle from 'common/hooks/useDocumentTitle';
import DirectoryType from 'features/settings/common/components/directory-type/DirectoryType';
import {DomainsListContextProvider} from 'features/settings/common/components/domains-list-context/DomainsListContext';
import {TrusteeListContextProvider} from 'features/settings/common/components/trustee-list-context/TrusteeListContext';
import DirectoryDetailsPageTabs from 'features/settings/components/directory/DirectoryDetailsPageTabs';
import DirectorySettingsModal from 'features/settings/components/directory/directory-settings-modal/DirectorySettingsModal';
import useDirectoryTrusteeListState from 'features/settings/components/directory/trustees/hooks/useDirectoryTrusteeListState';
import useDirectoryState from 'features/settings/hooks/directory/useDirectoryState';
import useDirectoryDomainsListState from 'features/settings/hooks/domains/useDirectoryDomainsListState';
import {DirectoryDetailsContextProvider} from 'features/settings/pages/directory-details-page/DirectoryDetailsContext';
import {goToIdentityDirectories} from 'features/settings/routing/navigation-callbacks/navigationCallbacks';

const PAGE_BREADCRUMBS = {
  DIRECTORIES: 'directories',
  NAME: 'name',
};

const DirectoryDetailsPage = () => {
  const {
    canAddDomains,
    directory: directoryData,
    directoryDomains,
    directoryTrusts,
  } = useLoaderData();
  const intl = useIntl();

  const directoryState = useDirectoryState(directoryData);
  const trusteeListState = useDirectoryTrusteeListState(directoryData.id, directoryTrusts);
  const directoryDomainsListState = useDirectoryDomainsListState(directoryState.directory, {
    ...directoryDomains,
    canAddDomains,
  });

  const {directory} = directoryState;
  const {
    state: {domainCount},
  } = directoryDomainsListState;
  const {
    state: {trustCount},
  } = trusteeListState;

  const TAB_NAME = intl.formatMessage({id: 'settings.directoryDetails.title'});

  // For accessibility, the page title should contain the directory name and the translated tab name
  // the hook will append the app name to the title
  // eg. 'Directory Name | Identity Settings | Admin Console'
  useDocumentTitle({defaultTitle: `${directory.directoryName} ${TAB_NAME}`});

  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);

  const onActionBreadcrumb = (item) => {
    if (item === PAGE_BREADCRUMBS.DIRECTORIES) {
      goToIdentityDirectories();
    }
  };

  return (
    <DirectoryDetailsContextProvider state={directoryState}>
      <DomainsListContextProvider listState={directoryDomainsListState}>
        <TrusteeListContextProvider listState={trusteeListState}>
          <Page
            data-testid="directory-details-page"
            isLoading={directoryState.isLoading}
            showContent
          >
            <PageActions>
              <Button
                data-test-id="directory-settings-btn"
                onPress={() => setIsSettingsModalOpen(true)}
                variant="accent"
              >
                {intl.formatMessage({id: 'settings.directoryDetails.buttons.directorySettings'})}
              </Button>
            </PageActions>

            <PageInfoBar>
              <DirectoryType
                alignItems="start"
                direction="column-reverse"
                directoryType={directory.type}
                margin="0"
                withIcon
              />
              <InfoItemScorecard
                label={intl.formatMessage({id: 'settings.directoryDetails.scorecards.domains'})}
                value={domainCount}
              />
              <InfoItemScorecard
                label={intl.formatMessage({id: 'settings.directoryDetails.scorecards.trustees'})}
                value={trustCount}
              />
            </PageInfoBar>

            <PageBreadcrumbs isDisabled={false} onAction={onActionBreadcrumb}>
              <Item key={PAGE_BREADCRUMBS.DIRECTORIES}>
                {intl.formatMessage({id: 'settings.directoryDetails.breadcrumbs.directories'})}
              </Item>
              <Item key={PAGE_BREADCRUMBS.NAME}>{directory.directoryName}</Item>
            </PageBreadcrumbs>

            <PageHeader title={directory.directoryName} />

            <PageContent>
              <DirectoryDetailsPageTabs />
            </PageContent>
          </Page>
          <DialogContainer onDismiss={() => setIsSettingsModalOpen(false)}>
            {isSettingsModalOpen && <DirectorySettingsModal />}
          </DialogContainer>
        </TrusteeListContextProvider>
      </DomainsListContextProvider>
    </DirectoryDetailsContextProvider>
  );
};

export default DirectoryDetailsPage;
