import {jilOrganizations, log} from '@admin-tribe/acsc';

class AssignOrgTypes {
  /**
   * @description assign organisation type to user
   *
   * @param {Object} payload - The payload object
   * @param {String} payload.id -  The org id
   * @param {String} payload.name -  The name
   * @param {String} payload.marketSubsegments -  The market sub segments to be assigned
   */
  // eslint-disable-next-line class-methods-use-this -- individual function
  async assignOrgType(payload) {
    try {
      return await jilOrganizations.putOrganizations(payload);
    } catch (error) {
      log.error(
        `Failed to assign ${payload.name} to ${payload.id} type of ${payload.marketSubsegments}. Error:`,
        error
      );
      if (error?.response?.status === 409) {
        throw new Error('Market Segments already set');
      }
      throw new Error('Failed to assign user to an org type');
    }
  }
}

export default AssignOrgTypes;
