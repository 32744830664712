import {
  ACTIONBLOCK_ID,
  CAMPAIGN_ID,
  CONTAINER_ID,
  CONTROL_GROUP_ID,
  SURFACE_ID,
  TREATMENT_ID,
  VARIATION_ID,
} from '@admin-tribe/acsc';
import pick from 'lodash/pick';

import {getContentObject, getCtaClickEventCallback} from 'common/components/sophia/sophiaCardUtils';
import SOPHIA_CONSTANTS from 'common/services/sophia/SophiaConstants';
import sophiaHelper from 'common/services/sophia/sophiaHelper';
import {mapOverviewHvaBannerContent} from 'common/services/sophia/utils/utils';

import HvaCard from './HvaCard';
import {HVA_ORDER, HVA_TYPE} from './hvaConstants';

class SophiaHvaCard extends HvaCard {
  static async get() {
    try {
      let sophiaCardList = [];
      let isSophiaCard = true;
      sophiaCardList = await sophiaHelper.getSophiaCards({
        surfaceId: SOPHIA_CONSTANTS.SURFACE_ID.ONE_CONSOLE_HVA,
      });
      let sophiaCards = sophiaCardList.items;

      if (sophiaCards.length === 0) {
        sophiaCardList = await sophiaHelper.getSophiaContent({
          surfaceId: SOPHIA_CONSTANTS.SURFACE_ID.ONE_CONSOLE_HVA,
        });
        sophiaCards = sophiaCardList.items;
        isSophiaCard = false;
      }

      let defaultCardFields;
      const cards = sophiaCards.map((sophiaCard) => {
        if (isSophiaCard) {
          const card = sophiaCard.getCard();
          const cardContent = getContentObject(card);
          defaultCardFields = {
            ctaLabel: cardContent.ctaLabel,
            description: cardContent.displayText,
            header: cardContent.bodyCopy,
            icon: {
              src: card.backgroundImage,
            },
            id: sophiaCard.getCardId(),
            isDismissible: cardContent.eventData?.isDismissible,
            learnMoreHref: card.eventAction,
            onCTA: getCtaClickEventCallback(card.actionURL),
            sectionTitle: cardContent.cardLabel,
          };
        } else {
          defaultCardFields = mapOverviewHvaBannerContent(JSON.parse(sophiaCard.content));
        }

        const sophiaAnalyticsParams = {
          ...pick(sophiaCard.containerAnalyticsParams, [
            ACTIONBLOCK_ID,
            CAMPAIGN_ID,
            CONTROL_GROUP_ID,
            TREATMENT_ID,
            VARIATION_ID,
          ]),
          ...pick(sophiaCard, [CONTAINER_ID, SURFACE_ID]),
        };

        if (defaultCardFields) {
          return new SophiaHvaCard({...defaultCardFields, ...sophiaAnalyticsParams});
        }
        return null;
      });

      return cards.filter((card) => card !== null);
    } catch (error) {
      return [];
    }
  }

  constructor(options) {
    super({
      priorityOrder: HVA_ORDER.SOPHIA,
      type: HVA_TYPE.SOPHIA,
      ...options,
    });
  }
}

export default SophiaHvaCard;
