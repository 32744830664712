import {feature} from '@admin-tribe/acsc';
import {WorkspaceNav} from '@admin-tribe/acsc-ui';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {generatePath} from 'react-router-dom';

import rootStore from 'core/RootStore';
import usePackagesAccess from 'features/packages/hooks/usePackagesAccess';
import {
  PATH_ADOBE_TEMPLATES,
  PATH_ALL_PACKAGES,
  PATH_PACKAGES_PREFERENCES,
  PATH_PACKAGES_SERVERS,
  PATH_PACKAGES_TOOLS,
  PATH_PREGENERATED_PACKAGES_SINGLE_APPS,
} from 'features/packages/routing/packagesPaths';

/** Renders the workspace nav for Packages */
const PackagesWorkspaceNav = () => {
  const intl = useIntl();
  const {isLoading, canViewTemplatesTab, canViewServersTab} = usePackagesAccess();
  const navItems = useMemo(() => {
    if (isLoading) {
      return [];
    }
    const items = [
      {
        href: generatePath(PATH_ALL_PACKAGES, {orgId: rootStore.organizationStore.activeOrgId}),
        name: intl.formatMessage({id: 'packages.allPackages.title'}),
        order: 0,
        testId: 'nav-packages',
      },
      {
        href: generatePath(PATH_PACKAGES_TOOLS, {orgId: rootStore.organizationStore.activeOrgId}),
        name: intl.formatMessage({id: 'packages.tools.title'}),
        order: 3,
        testId: 'nav-tools',
      },
      {
        href: generatePath(PATH_PACKAGES_PREFERENCES, {
          orgId: rootStore.organizationStore.activeOrgId,
        }),
        name: intl.formatMessage({id: 'packages.preferences.title'}),
        order: 4,
        testId: 'nav-package-pref',
      },
    ];
    if (canViewTemplatesTab) {
      if (feature.isEnabled('temp_packages_pregenerated_page')) {
        items.splice(1, 0, {
          href: generatePath(PATH_PREGENERATED_PACKAGES_SINGLE_APPS, {
            orgId: rootStore.organizationStore.activeOrgId,
          }),
          name: intl.formatMessage({id: 'packages.pregeneratedPackages.title'}),
          order: 1,
          testId: 'nav-pregenerated-packages',
        });
      } else {
        items.splice(1, 0, {
          href: generatePath(PATH_ADOBE_TEMPLATES, {
            orgId: rootStore.organizationStore.activeOrgId,
          }),
          name: intl.formatMessage({id: 'packages.adobeTemplates.title'}),
          order: 1,
          testId: 'nav-adobe-templates',
        });
      }
    }
    if (canViewServersTab) {
      items.splice(2, 0, {
        href: generatePath(PATH_PACKAGES_SERVERS, {
          orgId: rootStore.organizationStore.activeOrgId,
        }),
        name: intl.formatMessage({id: 'packages.servers.title'}),
        order: 2,
        testId: 'nav-package-servers',
      });
    }
    return items;
  }, [canViewServersTab, canViewTemplatesTab, intl, isLoading]);

  return <WorkspaceNav navItems={navItems} />;
};

PackagesWorkspaceNav.displayName = 'PackagesWorkspaceNav';

export default PackagesWorkspaceNav;
