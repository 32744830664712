import {
  hasAnyTrialContracts,
  hasAnyTrialOrAllocationContracts,
  hasDirectContract,
  hasEnterpriseContract,
  hasIndirectContract,
} from '@admin-tribe/acsc';
import {OfferType} from '@pandora/commerce-core-types';
import {generatePath} from 'react-router-dom';

import rootStore from 'core/RootStore';
import {canBuyTrialProduct} from 'core/products/access/productAccess';
import trialHelper from 'core/products/trial-helper/trialHelper';
import chatProvider from 'core/services/chat/chatProvider';
import {CHAT_APP_ID} from 'core/services/chat/chatProviderConstants';

import {
  PATH_ACCOUNT_BILLING_HISTORY,
  PATH_ACCOUNT_CONTRACT_DETAILS,
  PATH_ACCOUNT_ORGANIZATION_DETAILS,
  PATH_ACCOUNT_OVERVIEW,
  PATH_ACCOUNT_ROOT,
} from '../routing/accountPaths';

/** Generates pathnames for Account */
const getAccountPaths = () => {
  const account = () =>
    generatePath(PATH_ACCOUNT_ROOT, {orgId: rootStore.organizationStore.activeOrgId});

  const accountContractDetails = (contractId) =>
    generatePath(PATH_ACCOUNT_CONTRACT_DETAILS, {
      contractId,
      orgId: rootStore.organizationStore.activeOrgId,
    });

  const accountOverview = () =>
    generatePath(PATH_ACCOUNT_OVERVIEW, {orgId: rootStore.organizationStore.activeOrgId});

  const accountBillingHistory = () =>
    generatePath(PATH_ACCOUNT_BILLING_HISTORY, {orgId: rootStore.organizationStore.activeOrgId});

  const accountOrganizationDetails = () =>
    generatePath(PATH_ACCOUNT_ORGANIZATION_DETAILS, {
      orgId: rootStore.organizationStore.activeOrgId,
    });

  return {
    account,
    accountBillingHistory,
    accountContractDetails,
    accountOrganizationDetails,
    accountOverview,
  };
};

/**
 * Check contract id is valid in contract list
 * @returns {Object} Return contract if contract exists in list
 */
const getValidContract = (contractId) => {
  if (!contractId) return null;

  const contractList = rootStore.organizationStore.contractList;
  return contractList.items.find((contract) => contract.id === contractId);
};

/**
 * Returns the default contract to be used for the account page by checking contractList.
 * @returns contract
 */
const getContractForAccountPage = (contractId = null) => {
  let defaultContract;
  const contractList = rootStore.organizationStore.contractList;

  // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- no else condition needed
  /* istanbul ignore else  */
  if (contractList.items.length === 1) {
    defaultContract = contractList.items[0];
  } else {
    // There is more than one contract.
    // const hasDirectContract = contractList.hasDirectContract();
    const orgHasDirectContract = hasDirectContract(contractList);
    const orgHasIndirectContract = hasIndirectContract(contractList);
    const validContract = getValidContract(contractId);

    // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- no else condition needed
    /* istanbul ignore else  */
    if (orgHasIndirectContract) {
      if (contractId && validContract) {
        defaultContract = validContract;
      } else {
        defaultContract = contractList.items.find((contract) => contract.isIndirectContract());
      }
    } else if (orgHasDirectContract) {
      if (contractId && validContract) {
        defaultContract = validContract;
      } else {
        defaultContract = contractList.items.find((contract) => contract.isDirectContract());
      }
    }
  }

  return defaultContract;
};

/**
 * If contract list has atleast one enterprise contract, this function returns the key to the message to be shown.
 * @returns {String} Message to be shown incase of second contract is enterprise
 */
const getNonDefaultEnterpriseContractMessageKey = () => {
  let secondContractMessage;
  const contractList = rootStore.organizationStore.contractList;

  // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- no else condition needed
  /* istanbul ignore else  */
  if (contractList.items.length > 1) {
    // There is more than one contract.
    const orgHasDirectContract = hasDirectContract(contractList);
    const orgHasIndirectContract = hasIndirectContract(contractList);
    const orgHasEnterpriseContract = hasEnterpriseContract(contractList);

    // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- no else condition needed
    /* istanbul ignore else  */
    if (orgHasEnterpriseContract) {
      // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- no else condition needed
      /* istanbul ignore else  */
      if (orgHasIndirectContract) {
        secondContractMessage = 'account.secondContractMessage.indirectEnterprise';
      } else if (orgHasDirectContract) {
        secondContractMessage = 'account.secondContractMessage.directEnterprise';
      }
    }
  }

  return secondContractMessage;
};

/**
 * Opens Jarvis based with APP ID based on the options passed
 */
const startChat = (options) => {
  let appId;
  switch (options.step) {
    case 'BumperStep':
      appId = CHAT_APP_ID.ONESIE1_CCT_SELF_CANCEL_BUMPER;
      break;
    case 'CancelReviewStep':
      appId = CHAT_APP_ID.ONESIE1_CCT_SELF_CANCEL_REVIEW;
      chatProvider.reinitialize({appId});
      // we do not want to call chat.openMessagingWindow in this scenario
      // Jarvis will be handling the window automatically opening based on appId
      return;
    case 'SaveOffersStep':
      appId = CHAT_APP_ID.ONESIE1_CCT_SELF_CANCEL_SAVE_OFFERS;
      break;
    default:
      appId = CHAT_APP_ID.ONESIE1;
      chatProvider.reinitialize({appId});
      // we do not want to call chat.openMessagingWindow in this scenario
      // Jarvis will reset the appId to the previous one on modal close
      return;
  }
  chatProvider.openMessagingWindow({
    appId,
    sourceText: options.step,
    sourceType: 'button',
  });
};

/**
 * Checks if one of the contracts is enterprise or not
 * @returns {Boolean} returns true if the org has enterprise contract;
 */
const checkIfOrgHasEnterpriseContract = () => {
  const contractList = rootStore.organizationStore.contractList;
  return hasEnterpriseContract(contractList);
};

/**
 * Gets the bumper details for account page
 * @returns {Object} bumperMessageBody, bumperMessageHeader, showBuyNowButton
 */
const getAccountPageBumperDetails = () => {
  const contractList = rootStore.organizationStore.contractList;
  let bumperMessageBody, bumperMessageHeader, showBuyNowButton;

  // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- no else condition needed
  /* istanbul ignore else  */
  if (hasAnyTrialContracts(contractList)) {
    bumperMessageHeader = 'account.trialMessage.header';
    bumperMessageBody = 'account.trialMessage.body';
    showBuyNowButton = canBuyTrialProduct(trialHelper.getTrialProduct());
  } else if (checkIfOrgHasEnterpriseContract()) {
    bumperMessageHeader = 'account.enterpriseMessage.header';
  } else if (hasAnyTrialOrAllocationContracts(contractList)) {
    bumperMessageHeader = 'account.allocationMessage.header';
  }

  return {bumperMessageBody, bumperMessageHeader, showBuyNowButton};
};

/**
 * @description Returns whether product is trial offer
 *
 * @param offer - the offer to check
 * @returns {Boolean} true if offer has trial offer
 */
const isTrialOffer = (item) => item?.offer?.offer_type === OfferType.TRIAL;

/**
 * Checks if a product is a free offer.
 *
 * @param {Object} offer - the offer to check
 * @returns {boolean} - Returns true if the product is a free offer, false otherwise.
 */
const isFreeOffer = (offer) => {
  const product = rootStore.organizationStore.productList?.freeItems?.find(
    (item) => item?.productArrangementCode === offer?.product_arrangement_code
  );
  return !!product;
};

/**
 * Gets contract names from the list
 * @returns {Object} array of contract names
 */
const getContractNameList = () =>
  rootStore.organizationStore.contractList?.items?.map((contract) => contract?.name);

export {
  checkIfOrgHasEnterpriseContract,
  getAccountPaths,
  getAccountPageBumperDetails,
  getContractForAccountPage,
  getContractNameList,
  getNonDefaultEnterpriseContractMessageKey,
  getValidContract,
  isTrialOffer,
  isFreeOffer,
  startChat,
};
