import {download, log} from '@admin-tribe/acsc';
import {useCallback, useState} from 'react';

import {selectedInvoicesCount} from 'features/account/billing-history/BillingHistoryUtils';
import hendrix from 'features/account/billing-history/api/hendrix';

import {INVOICE_TYPES} from '../BillingHistoryConstants';

/**
 * @description Hook used to handle the call to download invoices
 * @param {Contract} contract - The organization's contract
 * @param {Object} invoices object with externalContractIds and invoiceIds
 * @returns {Object} state - Object composed of state variables: downloadInvoices, isDownloadError, isDownloading.
 *          {Callback} state.downloadInvoices - Callback to download invoices
 *          {Boolean} state.isDownloadError - Whether loading/downloading data failed.
 *          {Boolean} state.isDownloading - Whether data is currently being loaded/downloaded.
 */
const useDownloadInvoices = ({contract}) => {
  const rengaId = contract.ownerInfo.userId;

  const [isDownloading, setIsDownloading] = useState(false);
  const [isDownloadError, setIsDownloadError] = useState(false);
  const [hasDownloadFinished, setHasDownloadFinished] = useState(false);
  const [invoiceCount, setInvoiceCount] = useState(0);

  const downloadInvoices = useCallback(
    async ({invoices}) => {
      setInvoiceCount(selectedInvoicesCount(invoices));
      setIsDownloading(true);
      setHasDownloadFinished(false);
      setIsDownloadError(false);

      const invoiceDownloadPromises = ({type}) => {
        if (!invoices[type]) {
          return [];
        }
        return Object.entries(invoices[type]).map(async ([externalContractId, invoiceIds]) => {
          const response = await hendrix.getDownloadInvoices({
            invoices: invoiceIds?.join(':'),
            orderId: externalContractId,
            queryParams: {type},
            rengaId,
          });
          return {
            externalContractId,
            response,
            type,
          };
        });
      };

      try {
        const responses = await Promise.all([
          ...invoiceDownloadPromises({type: INVOICE_TYPES.INVOICE}),
          ...invoiceDownloadPromises({type: INVOICE_TYPES.RECEIPT}),
          ...invoiceDownloadPromises({type: INVOICE_TYPES.CREDIT}),
        ]);
        responses.forEach(({externalContractId, response, type}) =>
          download(response.data, `Invoice_${externalContractId}_${type}.pdf`)
        );
      } catch (error) {
        setIsDownloadError(true);
        log.error('Failed to download invoices.', error);
      } finally {
        setIsDownloading(false);
        setHasDownloadFinished(true);
      }
    },
    [rengaId]
  );
  return {downloadInvoices, hasDownloadFinished, invoiceCount, isDownloadError, isDownloading};
};

export default useDownloadInvoices;
