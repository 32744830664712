import {Page, PageBanners, PageContent, PageHeader, PageNav, useStore} from '@admin-tribe/acsc-ui';
import {
  PAGE_SIZE_OPTIONS,
  SELECTION_MODE,
  TABLE_SECTION_ACTIONS,
  TableActions,
  TableFilters,
  TableSection,
} from '@pandora/react-table-section';
import {observer} from 'mobx-react-lite';
import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';

import SophiaBanner from 'common/components/sophia/banner/SophiaBanner';
import SOPHIA_CONSTANTS from 'common/services/sophia/SophiaConstants';
import rootStore from 'core/RootStore';
import PackageListTable from 'features/packages/components/all-packages/PackageListTable';
import RemovePackageButton from 'features/packages/components/all-packages/RemovePackageButton';
import EmptyPackageList from 'features/packages/components/all-packages/empty-package-list/EmptyPackageList';
import CreatePackageButton from 'features/packages/components/create-package-modal/CreatePackageButton';
import {goBack} from 'features/packages/components/preferences-page/preferencesPageUtils';
import {ALL_PACKAGES_CONSTANTS} from 'features/packages/packagesConstants';
import CreatePackageFactory from 'features/packages/services/CreatePackageService';
import PackagesEntitlements from 'features/packages/services/PackagesEntitlementsService';
import AllPackagesStore from 'features/packages/stores/AllPackagesStore';

import {AllPackagesPageContextProvider} from './AllPackagesPageContext';

/**
 * All Packages page
 */
const AllPackagesPage = observer(() => {
  const allPackagesStore = useStore(() => new AllPackagesStore());
  const pageSizeOption = PAGE_SIZE_OPTIONS.DEFAULT;
  pageSizeOption[0].isDefault = true;
  pageSizeOption[1].isDefault = false;
  const intl = useIntl();

  // Initial load of the admin packages list.
  useEffect(() => {
    allPackagesStore.load();
    allPackagesStore.invokeEventListener();
    // component will unmount
    return () => {
      allPackagesStore.revokeEventListener();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- required to execute this only on mount
  }, []);

  const onTableSectionChange = ({action, payload}) => {
    switch (action) {
      case TABLE_SECTION_ACTIONS.GO_TO_NEXT_PAGE:
        allPackagesStore.goToNextPage();
        break;
      case TABLE_SECTION_ACTIONS.GO_TO_PREVIOUS_PAGE:
        allPackagesStore.goToPreviousPage();
        break;
      case TABLE_SECTION_ACTIONS.ON_PAGE_SIZE_CHANGE:
        allPackagesStore.onPageSizeChange(payload);
        break;
      case TABLE_SECTION_ACTIONS.ON_SEARCH_SUBMIT:
        allPackagesStore.search(payload.value);
        break;
      case TABLE_SECTION_ACTIONS.ON_ROW_SELECTION_CHANGE:
        allPackagesStore.setSelectedPackages(payload);
        break;
      default:
        // Do nothing
        break;
    }
  };

  const getIsItemSelectable = (itemKey) => {
    const pkg = allPackagesStore.getItem(itemKey);
    if (pkg) {
      return !(
        pkg.statusState === ALL_PACKAGES_CONSTANTS.STATE_PREPARING ||
        pkg.statusState === ALL_PACKAGES_CONSTANTS.STATE_BUILDING
      );
    }
    return false;
  };
  const setCreatePackageFactoryValues = () => {
    if (PackagesEntitlements.hasCcEntitlement || PackagesEntitlements.hasDcEntitlement) {
      CreatePackageFactory.setCreatePackageFactoryValues();
    } else {
      CreatePackageFactory.setCreatePackageFactoryValuesForFrl();
    }
  };
  return (
    <AllPackagesPageContextProvider allPackagesStore={allPackagesStore}>
      <Page
        bumperCtaButtonLabel={intl.formatMessage({id: 'packages.preferences.bumper.goBack'})}
        data-testid="all-packages-page"
        isBumpered={
          allPackagesStore.hasLoadingError || rootStore.packagesUiConstantsStore.hasLoadingError
        }
        isLoading={allPackagesStore.isLoading}
        onClickBumper={goBack}
        showContent
      >
        <PageBanners>
          <SophiaBanner surfaceId={SOPHIA_CONSTANTS.SURFACE_ID.ONE_CONSOLE_PACKAGES} />
        </PageBanners>

        <PageHeader title={intl.formatMessage({id: 'packages.allPackages.title'})} />
        <PageNav />
        <PageContent>
          {allPackagesStore.allPackagesFUEScreenState ? (
            <EmptyPackageList isCreatePkgBtnDisabled={allPackagesStore.isLoading} />
          ) : (
            (allPackagesStore.tableStore.rows.length > 0 ||
              allPackagesStore.tableStore.searchQuery) && (
              <TableSection
                defaultPageSize={10}
                getIsItemSelectable={getIsItemSelectable}
                getItemKey={(item) => item.packageId}
                isServerError={allPackagesStore.hasLoadingError}
                items={allPackagesStore.tableStore.rows}
                onTableSectionChange={onTableSectionChange}
                pageNumber={allPackagesStore.tableStore.currentPage}
                pageSizeOptions={pageSizeOption}
                selectionMode={SELECTION_MODE.MULTIPLE}
                totalPages={allPackagesStore.tableStore.totalPages}
              >
                <TableFilters
                  aria-label={intl.formatMessage({id: 'packages.allPackages.searchPlaceholder'})}
                  hasSearch
                  label={intl.formatMessage({id: 'packages.allPackages.searchPlaceholder'})}
                  minLength={1}
                  width="55%"
                />

                <TableActions>
                  <RemovePackageButton data-testid="remove-package-button" />
                  <CreatePackageButton
                    buttonLabel={intl.formatMessage({
                      id: 'packages.allPackages.createPackageButtonLabel',
                    })}
                    isDisabled={
                      allPackagesStore.isLoading || allPackagesStore.isCreatePackageDisabled
                    }
                    setCreatePackageFactoryValues={setCreatePackageFactoryValues}
                  />
                </TableActions>
                <PackageListTable allPackagesStore={allPackagesStore} />
              </TableSection>
            )
          )}
        </PageContent>
      </Page>
    </AllPackagesPageContextProvider>
  );
});

export default AllPackagesPage;
