import {showError as showErrorToast} from '@admin-tribe/acsc-ui';
import {Flex, Heading, Image, Link as LinkText, Text, View} from '@adobe/react-spectrum';
import {ModalContent} from '@pandora/react-modal-dialog';
import Link from '@spectrum-icons/workflow/Link';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import AdobeExpress from 'features/overview/components/adobe-express-k12/assets/adobe-express-logo.svg';
import Checkmark from 'features/overview/components/adobe-express-k12/assets/checkmark.svg';

/**
 * The Adobe Express K12 success confirmation modal
 */
const AdobeExpressK12SuccessConfirmationModal = ({domains}) => {
  const intl = useIntl();

  const adobeExpressLink = 'https://express.adobe.com/a';

  const copyToClipboard = async (domain) => {
    try {
      await navigator.clipboard.writeText(`${adobeExpressLink}/${domain}`);
    } catch (error) {
      showErrorToast(intl.formatMessage({id: 'common.adobeExpressK12SetupModal.errorToast'}));
    }
  };

  return (
    <ModalContent>
      <Flex alignItems="center" direction="column" justifyContent="center">
        <View marginBottom="size-250">
          <Image
            alt={intl.formatMessage({
              id: 'common.adobeExpressK12SetupSuccessConfirmationModal.successLogoAlt',
            })}
            height="size-1000"
            marginX="auto"
            src={Checkmark}
            width="size-1000"
          />
        </View>
        <Heading level={1}>
          {intl.formatMessage({
            id: 'common.adobeExpressK12SetupSuccessConfirmationModal.heading',
          })}
        </Heading>
        <View paddingEnd="size-500" paddingStart="size-500">
          <Text>
            {intl.formatMessage({
              id: 'common.adobeExpressK12SetupSuccessConfirmationModal.description',
            })}
          </Text>
        </View>

        <View
          backgroundColor="static-white"
          borderColor="light"
          borderWidth="thin"
          marginTop="size-350"
          padding="size-300"
          width="100%"
        >
          <Flex direction="column" gap="size-150" justifyContent="center">
            <Heading level={4} margin={0}>
              {intl.formatMessage({
                id: 'common.adobeExpressK12SetupSuccessConfirmationModal.content.title',
              })}
            </Heading>
            <Text>
              {intl.formatMessage({
                id: 'common.adobeExpressK12SetupSuccessConfirmationModal.content.description',
              })}
            </Text>
            <Flex alignItems="center" gap="size-125">
              <Image
                alt={intl.formatMessage({
                  id: 'common.adobeExpressK12SetupSuccessConfirmationModal.adobeExpressLogoAlt',
                })}
                height="size-600"
                src={AdobeExpress}
                width="size-600"
              />
              <Text UNSAFE_style={{fontWeight: 'bold'}}>
                {intl.formatMessage({
                  id: 'common.adobeExpressK12SetupSuccessConfirmationModal.content.adobeExpressLabel',
                })}
              </Text>
            </Flex>

            <Flex direction="column" gap="size-100">
              {domains.map((domain) => (
                <View key={domain} backgroundColor="gray-200">
                  <View padding="size-150">
                    <Flex alignItems="center" direction="row" justifyContent="space-between">
                      <Text>
                        {adobeExpressLink}/{domain}
                      </Text>
                      <Flex alignItems="center" gap="size-50">
                        <Link
                          size="S"
                          UNSAFE_style={{color: 'var(--spectrum-global-color-blue-600)'}}
                        />
                        <LinkText data-testid="domain-link" onPress={() => copyToClipboard(domain)}>
                          {intl.formatMessage({
                            id: 'common.adobeExpressK12SetupSuccessConfirmationModal.content.copyLink',
                          })}
                        </LinkText>
                      </Flex>
                    </Flex>
                  </View>
                </View>
              ))}
            </Flex>
          </Flex>
        </View>
      </Flex>
    </ModalContent>
  );
};

AdobeExpressK12SuccessConfirmationModal.propTypes = {
  /**
   * The domains to display in the modal
   */
  domains: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default AdobeExpressK12SuccessConfirmationModal;
