import {OrganizationUser, UserGroup} from '@admin-tribe/acsc';
import {
  DETAIL_SECTION_VARIANT_CONSTANTS,
  DetailSection,
  DetailSectionMessage,
} from '@admin-tribe/acsc-ui';
import {ActionMenu, Flex, Item} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import UserGroupLinkMessage from 'common/components/user-group-link-message/UserGroupLinkMessage';
import {canMemberEditUserGroup} from 'core/user-group/access/userGroupAccess';
import {USER_DETAILS_MODAL_NAMES} from 'features/users/users.constants';

const EDIT_PRODUCTS_AND_GROUPS_ITEM_KEY = USER_DETAILS_MODAL_NAMES.EDIT_PRODUCTS_AND_GROUPS;

/**
 * The User Groups Detail section.
 */
const UserGroupsDetailSection = ({
  headingLevel,
  isLoading,
  isPageVariant,
  member,
  onMenuActionPress,
  showError,
  userGroups,
}) => {
  const intl = useIntl();

  const flexProps = isPageVariant
    ? {
        direction: {base: 'column', L: 'row'},
        justifyContent: {base: 'stretch', L: 'space-between'},
        wrap: {base: false, L: true},
      }
    : {direction: 'column', justifyContent: 'stretch', wrap: false};

  const groupFlex = isPageVariant ? {base: null, L: '0 0 50%'} : null;

  // Always show menu once member has loaded so focus can be recovered
  // after admin closes edit modal.
  const canShowEditMenu = member && !showError;
  // Disable the menu item keys until data is ready
  const disabledKeys = !isLoading && !showError ? [] : [EDIT_PRODUCTS_AND_GROUPS_ITEM_KEY];

  return (
    <DetailSection
      headingLevel={headingLevel}
      isLoading={isLoading}
      rightHeaderContent={
        canShowEditMenu &&
        canMemberEditUserGroup(member) && (
          <ActionMenu disabledKeys={disabledKeys} isQuiet onAction={onMenuActionPress}>
            <Item key={EDIT_PRODUCTS_AND_GROUPS_ITEM_KEY}>
              {intl.formatMessage({
                id: 'common.userGroupsDetailSection.moreOptions.edit',
              })}
            </Item>
          </ActionMenu>
        )
      }
      showError={showError}
      title={intl.formatMessage({
        id: 'common.userGroupsDetailSection.title',
      })}
      variant={isPageVariant && DETAIL_SECTION_VARIANT_CONSTANTS.PAGE}
    >
      {userGroups?.length > 0 ? (
        <Flex {...flexProps} rowGap="size-100">
          {userGroups.map((userGroup) => (
            <Flex key={userGroup.id} columnGap="size-200" direction="row" flex={groupFlex}>
              <UserGroupLinkMessage userGroup={userGroup} />
            </Flex>
          ))}
        </Flex>
      ) : (
        <DetailSectionMessage messageKey="common.userGroupsDetailSection.noGroups" />
      )}
    </DetailSection>
  );
};

UserGroupsDetailSection.propTypes = {
  /**
   * The heading level for the section Heading. The DetailSection default in binky-ui is 3.
   */
  headingLevel: PropTypes.number,
  /**
   * Whether the component should display the loading indicator or not.
   */
  isLoading: PropTypes.bool,
  /**
   * Whether the section has page variant or not.
   */
  isPageVariant: PropTypes.bool,
  /**
   * The user or administrator.
   */
  member: PropTypes.instanceOf(OrganizationUser),
  /**
   * Callback to invoke when the more options menu item is clicked. Params are being passed by MoreOptionsMenu.
   */
  onMenuActionPress: PropTypes.func.isRequired,
  /**
   * Whether an error occurred during fetching data or not.
   */
  showError: PropTypes.bool,
  /**
   * Array of user groups.
   */
  userGroups: PropTypes.arrayOf(PropTypes.instanceOf(UserGroup).isRequired),
};

export default UserGroupsDetailSection;
