import {NavigationAnchor, TableSectionTable} from '@admin-tribe/acsc-ui';
import {Link, View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useRef} from 'react';
import {useIntl} from 'react-intl';
import {useParams} from 'react-router-dom';

import JobResultsStatusLight from 'features/bulk-operations/components/job-results/JobResultsStatusLight';
import {PATH_USER_GROUPS_BULK_OPERATIONS_RESULTS} from 'features/users/routing/usersPaths';

import {getLicenseDeficitHref} from './jobResultsUtils';

/**
 * The table of job results for the given bulk operations job.
 * This component assumes it is wrapped by a TableSection which supplies the table with items.
 */
const JobResultsListTable = ({path}) => {
  const pathParams = useParams();
  const licenseDeficitHref = useRef(getLicenseDeficitHref({path, pathParams})).current;
  const intl = useIntl();

  const columnDescriptor = [
    {
      key: 'line',
      props: {align: 'end', maxWidth: '8%', showDivider: true},
    },
    {
      key: 'status',
      props: {maxWidth: '15%'},
    },
    {
      key: path === PATH_USER_GROUPS_BULK_OPERATIONS_RESULTS ? 'userGroup' : 'email',
    },
    {
      key: 'details',
    },
  ];

  // The render function will be called with an object which contains the item
  // which is type JobResult and the column 'key'.
  const renderers = {
    details: ({item}) => getDetailsRenderer({item}),
    email: ({item}) => item.input.email,
    line: ({item}) => item.line,
    status: ({item}) => <JobResultsStatusLight status={item.status} />,
    userGroup: ({item}) => item.input.userGroupName,
  };

  function getDetailsRenderer({item: jobResult}) {
    const messagesToDisplay = jobResult.getFailureDetailMessages(intl);
    const licenseCountFailure = jobResult.hasLicenseCountExceededFailure();

    if (messagesToDisplay?.length === 0) {
      return undefined; // so that an EN_DASH will be rendered
    }

    return (
      <>
        {messagesToDisplay.map((message, index) => (
          // eslint-disable-next-line react/no-array-index-key -- no id
          <View key={index}>{message}</View>
        ))}
        {licenseCountFailure && licenseDeficitHref && (
          <View>
            <Link>
              <NavigationAnchor href={licenseDeficitHref}>
                {intl.formatMessage({id: 'users.jobResultsList.details.deficitLinkText'})}
              </NavigationAnchor>
            </Link>
          </View>
        )}
      </>
    );
  }

  return (
    <TableSectionTable
      aria-label={intl.formatMessage({id: 'users.jobResultsList.table.ariaLabel'})}
      columnDescriptor={columnDescriptor}
      columnNamespace="users.jobResultsList.table.column"
      noItemsFoundContentMessage={intl.formatMessage({
        id: 'users.jobResultsList.table.setNoItemsFoundContentMessage',
      })}
      renderers={renderers}
    />
  );
};

JobResultsListTable.displayName = 'JobResultsListTable';

JobResultsListTable.propTypes = {
  /**
   * The React router path used to reach this page.
   * For example, PATH_USERS_BULK_OPERATIONS_RESULTS.
   */
  path: PropTypes.string.isRequired,
};

export default JobResultsListTable;
