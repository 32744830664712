import {ImageIcon} from '@admin-tribe/acsc-ui';
import {Button, Heading} from '@adobe/react-spectrum';
import {Card} from '@react/react-spectrum/Card';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import AzureLogo from 'features/settings/assets/azure-logo.svg';
import GoogleLogo from 'features/settings/assets/google-logo.svg';
import {useDirectoryContext} from 'features/settings/components/directory/DirectoryContext';

import SyncStatus from './SyncStatus';

import './SyncCard.pcss';

const SyncCard = observer(({onSyncSettingsPressed}) => {
  const intl = useIntl();
  const {directoryStore} = useDirectoryContext();

  const getCardTitle = () => {
    if (directoryStore.sync.isAzure) {
      return intl.formatMessage({id: 'settings.sync.card.azure'});
    }

    if (directoryStore.sync.isGoogle) {
      return intl.formatMessage({id: 'settings.sync.card.google'});
    }

    return intl.formatMessage({id: 'settings.sync.card.generic'});
  };

  const handleSyncSettings = () => {
    onSyncSettingsPressed?.();
  };

  return (
    <div styleName="sync-card-container">
      <Card
        allowsSelection={false}
        isDropTarget={false}
        size="L"
        styleName="sync-card"
        variant="standard"
      >
        <div styleName="sync-card-header">
          {directoryStore.sync.isAzure && <ImageIcon alt="" size="XL" src={AzureLogo} />}
          {directoryStore.sync.isGoogle && <ImageIcon alt="" size="XL" src={GoogleLogo} />}

          <Heading marginBottom={0} variant="subtitle1">
            {getCardTitle()}
          </Heading>

          <SyncStatus
            adminEnabled={directoryStore.sync.directoryLink.adminEnabled}
            detailed
            directorySyncStore={directoryStore.sync}
            enabled={directoryStore.sync.directoryLink.enabled}
            lastSyncDate={directoryStore.sync.directoryLink.lastSyncDate}
            status={directoryStore.sync.directoryLink.status}
          />
        </div>

        <div styleName="sync-card-body">
          {directoryStore.sync.isAzure &&
            intl.formatMessage({id: 'settings.sync.card.azureDescription'})}
          {directoryStore.sync.isGoogle &&
            intl.formatMessage({id: 'settings.sync.card.googleDescription'})}
        </div>

        <div styleName="sync-card-footer">
          <Button onPress={handleSyncSettings} variant="secondary">
            <FormattedMessage id="settings.sync.buttons.goToSettings" />
          </Button>
        </div>
      </Card>
    </div>
  );
});

SyncCard.propTypes = {
  onSyncSettingsPressed: PropTypes.func,
};

export default SyncCard;
