import {
  CONSUMABLE_SUMMARIZATION_SUMMARIZE_BY,
  ConsumableSummarizationList,
  FULFILLABLE_ITEM_CODE,
  feature,
  log,
} from '@admin-tribe/acsc';
import {DetailSection} from '@admin-tribe/acsc-ui';
import {Flex, View} from '@adobe/react-spectrum';
import {GoUrl} from '@pandora/react-go-url';
import InfoIcon from '@spectrum-icons/workflow/Info';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';

/**
 * The detail section shows support level, available services and help link for the product.
 */
const ProductSupportLevelDetailSection = ({productId, productSupportLevel}) => {
  const intl = useIntl();

  const [adminConsumableData, setAdminConsumableData] = useState();
  const [expertSessionConsumableData, setExpertSessionConsumableData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [showError, setShowError] = useState(false);

  // get expert session quota
  useEffect(() => {
    let isCurrent = true;

    const fetchConsumableData = async () => {
      try {
        const summarizationList = await ConsumableSummarizationList.get({
          include_usage: false,
          license_id: productId,
          organization_id: rootStore.organizationStore.activeOrgId,
          summarize_by: CONSUMABLE_SUMMARIZATION_SUMMARIZE_BY.LICENSE_ID,
        });

        const getConsumableData = (fiCode) =>
          summarizationList.getConsumableForSummaryIdAndFICode(fiCode, productId);

        if (isCurrent) {
          setAdminConsumableData(getConsumableData(FULFILLABLE_ITEM_CODE.SUPPORT_ALLOWED_ADMINS));
          setExpertSessionConsumableData(
            getConsumableData(FULFILLABLE_ITEM_CODE.SUPPORT_EXPERT_SESSION)
          );
        }
      } catch (error) {
        log.error(`Failed to get ConsumableSummarizationList: ${error}`);
        if (isCurrent) {
          setShowError(true);
        }
      } finally {
        if (isCurrent) {
          setIsLoading(false);
        }
      }
    };

    fetchConsumableData();

    return () => {
      isCurrent = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run on mount
  }, []);

  const getString = (key, params) =>
    intl.formatMessage({id: `products.productSupportLevelDetailSection.${key}`}, params);

  return (
    <DetailSection
      headingLevel={3}
      id="product-support-level-detail-section"
      isLoading={isLoading}
      marginBottom="size-200"
      showError={showError}
      title={productSupportLevel}
    >
      {(adminConsumableData || expertSessionConsumableData) && (
        <View marginTop="size-150" UNSAFE_style={{wordBreak: 'break-word'}}>
          {getString('service.header')}
        </View>
      )}
      <View elementType="ul" marginStart="-22px">
        {adminConsumableData && (
          <li>
            {getString(
              feature.isEnabled('temp_hide_psa_quota') ? 'service.admin.noQuota' : 'service.admin',
              adminConsumableData
            )}
          </li>
        )}
        {expertSessionConsumableData && (
          <li>
            {getString(
              feature.isEnabled('temp_hide_psa_quota')
                ? 'service.expertSession.noQuota'
                : 'service.expertSession',
              expertSessionConsumableData
            )}
          </li>
        )}
      </View>
      {/* update go url name when its available */}
      <GoUrl name="ac_support_how_to" noWrap={false}>
        {getString('goUrl')}
      </GoUrl>
      <Flex gap="size-50" marginTop="size-75">
        <InfoIcon marginTop="size-25" size="S" />
        {getString('info')}
      </Flex>
    </DetailSection>
  );
};

ProductSupportLevelDetailSection.propTypes = {
  /**
   * The product ID.
   */
  productId: PropTypes.string.isRequired,
  /**
   * The product support level, business or enterprise.
   */
  productSupportLevel: PropTypes.string.isRequired,
};

export default ProductSupportLevelDetailSection;
