import {
  DATE_FORMATS,
  FormattedDateAndTime,
  OverlayWait,
  TableSectionTable,
} from '@admin-tribe/acsc-ui';
import {EnDashDefaultContent, TableSection} from '@pandora/react-table-section';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';

import {useSetupCertificatesContext} from 'features/settings/components/setup-directory-authentication/SetupCertificatesContext';
import SamlCertificateStatus from 'features/settings/components/setup-directory-authentication/setup-saml-certs/SamlCertificateStatus';
import SamlCertificatesActionMenu from 'features/settings/components/setup-directory-authentication/setup-saml-certs/SamlCertificatesActionMenu';

const SamlCertificatesTable = () => {
  const intl = useIntl();
  const {state} = useSetupCertificatesContext();
  const {certificates, csrs, isLoading, hasLoadingError} = state;
  const items = useMemo(() => [...certificates, ...csrs], [certificates, csrs]);

  const columnDescriptor = [
    {key: 'type'},
    {key: 'created'},
    {key: 'expires'},
    {key: 'status'},
    {key: 'actions'},
  ];

  const renderers = {
    actions: ({item}) => <SamlCertificatesActionMenu certificate={item} />,
    created: ({item}) =>
      item?.certificateCreatedAt || item?.createdAt ? (
        <FormattedDateAndTime
          format={DATE_FORMATS.defaultDateTime}
          value={new Date(item?.certificateCreatedAt || item?.createdAt)}
        />
      ) : (
        <EnDashDefaultContent />
      ),
    expires: ({item}) =>
      item?.certificateExpiresAt ? (
        <FormattedDateAndTime
          format={DATE_FORMATS.defaultDateTime}
          value={new Date(item?.certificateExpiresAt)}
        />
      ) : (
        <EnDashDefaultContent />
      ),
    status: ({item}) =>
      item?.status ? <SamlCertificateStatus certificate={item} /> : <EnDashDefaultContent />,
    type: ({item}) => (
      <EnDashDefaultContent>
        {intl.formatMessage({id: `settings.certificates.table.certificateList.type.${item.type}`})}
      </EnDashDefaultContent>
    ),
  };

  return (
    <OverlayWait delay={0} isLoading={isLoading} showContent={items.length > 0}>
      <TableSection
        id="saml-certificates-table"
        isServerError={hasLoadingError}
        items={items}
        pageNumber={0}
      >
        <TableSectionTable
          aria-label={intl.formatMessage({id: 'settings.certificates.table.ariaLabel'})}
          columnDescriptor={columnDescriptor}
          columnNamespace="settings.certificates.table.column"
          noItemsFoundContentMessage={intl.formatMessage({
            id: 'settings.certificates.table.noItemsFoundContentMessage',
          })}
          renderers={renderers}
        />
      </TableSection>
    </OverlayWait>
  );
};

export default SamlCertificatesTable;
