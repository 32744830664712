import {Invoice} from '@admin-tribe/acsc';
import {Divider, Flex, Form, Item, Picker, Text} from '@adobe/react-spectrum';
import {
  ModalContent,
  ModalDialog,
  ModalDialogModel,
  ModalHeading,
} from '@pandora/react-modal-dialog';
import {ValidatedTextField, ValidatedTextFieldVariant} from '@pandora/react-validated-text-field';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import SafePrice from 'common/components/safe-price/SafePrice';
import {PO_NUMBER_UPDATE_REASON} from 'features/account/billing-history/BillingHistoryConstants';
import {isValidPONumber} from 'features/account/billing-history/BillingHistoryUtils';
import InvoiceStatus from 'features/account/billing-history/components/invoice-status/InvoiceStatus';

import styles from './EditPoNumberModal.pcss';

// ModalDialog has its own custom styling with width set to 1100px
// And in order to use size="M" we need to reset the styling
const CUSTOM_DIALOG_STYLE = {};

/**
 * The modal opened by the clicking on PO Number which is on BillingHistoryTable.
 */
const EditPoNumberModal = ({invoice, isUpdating, onCancel, onSuccess}) => {
  const intl = useIntl();

  const [newPONumber, setNewPONumber] = useState('');
  const [reason, setReason] = useState('');
  const [isCtaClicked, setIsCtaClicked] = useState(false);

  const onCta = () => {
    setIsCtaClicked(true);
    if (isValidPONumber(newPONumber)) {
      onSuccess({
        invoiceId: invoice.id,
        newPoNumber: newPONumber,
        oldPoNumber: invoice.poNumber,
        reason,
        type: invoice.documentType,
      });
    }
  };

  const modalDialogContent = ModalDialogModel.createEntry({
    cancelLabel: intl.formatMessage({
      id: 'account.billingHistory.editPoNumberModal.cancelButton',
    }),
    ctaLabel: intl.formatMessage({id: 'account.billingHistory.editPoNumberModal.saveButton'}),
  });

  const isCtaDisabled = !(newPONumber && reason);
  const isShowError = isCtaClicked && !isValidPONumber(newPONumber);

  return (
    <ModalDialog
      analyticsModalId="edit-po-number-modal"
      content={modalDialogContent}
      dialogStyle={CUSTOM_DIALOG_STYLE}
      isCtaDisabled={isCtaDisabled || isShowError}
      isLoading={isUpdating}
      onCancel={onCancel}
      onCta={onCta}
      size="M"
    >
      <ModalHeading>
        {intl.formatMessage({id: 'account.billingHistory.editPoNumberModal.title'})}
      </ModalHeading>
      <Divider size="M" />
      <ModalContent>
        <Text>
          {intl.formatMessage({id: 'account.billingHistory.editPoNumberModal.description'})}
        </Text>
        <Flex alignItems="center" marginTop="size-175">
          <Text
            minWidth="size-1600"
            // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- reduce size and color
            UNSAFE_className={styles.label}
          >
            {intl.formatMessage({
              id: 'account.billingHistory.editPoNumberModal.invoiceNumberLabel',
            })}
          </Text>
          <Text data-testid="edit-modal-invoice-number">{invoice.id}</Text>
        </Flex>
        <Flex alignItems="baseline" marginTop="size-100">
          <Text
            minWidth="size-1600"
            // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- reduce size and color
            UNSAFE_className={styles.label}
          >
            {intl.formatMessage({
              id: 'account.billingHistory.editPoNumberModal.invoiceAmountLabel',
            })}
          </Text>
          <Flex alignItems="center">
            <SafePrice currency={invoice.currency} price={invoice.total?.amountWithTax} />
            <InvoiceStatus invoice={invoice} />
          </Flex>
        </Flex>
        <Form
          aria-label={intl.formatMessage({
            id: 'account.billingHistory.editPoNumberModal.newPONumberForm.ariaLabel',
          })}
          isRequired
          necessityIndicator="label"
        >
          <ValidatedTextField
            label={intl.formatMessage({
              id: 'account.billingHistory.editPoNumberModal.newPONumberForm.input',
            })}
            onChange={(value) => {
              setIsCtaClicked(false);
              setNewPONumber(value);
            }}
            validationMessage={
              isShowError &&
              intl.formatMessage({
                id: 'account.billingHistory.editPoNumberModal.newPONumberForm.input.error',
              })
            }
            value={newPONumber}
            variant={isShowError && ValidatedTextFieldVariant.ERROR}
            width="100%"
          />
          <Picker
            data-testid="update-reason-picker"
            label={intl.formatMessage({
              id: 'account.billingHistory.editPoNumberModal.newPONumberForm.reasonPicker.label',
            })}
            marginTop="size-150"
            onSelectionChange={setReason}
            width="100%"
          >
            {PO_NUMBER_UPDATE_REASON.map((reasonKey) => (
              <Item key={reasonKey}>
                {intl.formatMessage({
                  id: `account.billingHistory.editPoNumberModal.newPONumberForm.reasonPicker.${reasonKey}`,
                })}
              </Item>
            ))}
          </Picker>
        </Form>
      </ModalContent>
    </ModalDialog>
  );
};

EditPoNumberModal.propTypes = {
  /**
   * Invoice from contract.
   */
  invoice: PropTypes.instanceOf(Invoice).isRequired,
  /**
   * Indicates whether PO number is currently updating or not.
   */
  isUpdating: PropTypes.bool,
  /**
   * Handler that closes the modal.
   */
  onCancel: PropTypes.func.isRequired,
  /**
   * Callback to invoke when the modal's actions have been successful.
   */
  onSuccess: PropTypes.func.isRequired,
};

export default EditPoNumberModal;
