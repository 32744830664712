/* eslint-disable max-lines  -- more util functions */
import binky, {CONTRACT_ADMIN, feature} from '@admin-tribe/acsc';
import binkyUI from '@admin-tribe/acsc-ui';
import pull from 'lodash/pull';

import {
  hasQuotaToAssignProductSupportAdmin,
  canShowAdmins as productCanShowAdmins,
} from 'core/products/access/productAccess';
import {
  canViewProductProfiles,
  canShowAdmins as licenseGroupCanShowAdmins,
} from 'core/products/access/productProfileAccess';
import {getContractDisplayNames} from 'core/products/utils/productUtils';
import auth from 'core/services/auth';

import {ROLE} from '../../users.constants';

const {TARGET_TYPE} = binkyUI.common.components.ASSIGNMENT_MENU_CONSTANTS;
const AuthenticatedUser = binky.models.user.AuthenticatedUser;
const LicenseGroup = binky.services.product.licenseGroup.LicenseGroup;
const UserGroup = binky.services.users.UserGroup;

// eslint-disable-next-line @admin-tribe/admin-tribe/jsdoc-exported-functions -- cawright@ to update
const assignTargets = ({user, role, assignedItems}) => {
  let newTargets;
  const existingRole = user.roles.find((userRole) => userRole.type === role);
  // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- cawright@ to update
  // istanbul ignore else -- not implemented
  if (role === ROLE.ADMIN.LICENSE) {
    newTargets = assignedItems.map((assignedItem) => ({
      id: assignedItem.id,
      name: assignedItem.name,
      parentId: assignedItem.product?.id,
    }));
    existingRole.targets = newTargets;
  } else if (
    role === CONTRACT_ADMIN ||
    role === ROLE.ADMIN.PRODUCT ||
    role === ROLE.ADMIN.PRODUCT_SUPPORT ||
    role === ROLE.ADMIN.USER_GROUP
  ) {
    newTargets = assignedItems.map((assignedItem) => ({
      id: assignedItem.id,
      name: assignedItem.name,
    }));

    existingRole.targets = newTargets;
  }
  return existingRole;
};

const isTargetAssignedToUser = (roleType, targetId, user) => {
  // we only want to know the original status so we need to use savedState
  const assignedRole = user.savedState.roles.find((role) => role.type === roleType);
  return !!assignedRole?.targets?.find((target) => target.id === targetId);
};

const canEditRoleByCurrentUserRoles = (currentUserRoles, orgId, role) => {
  // see https://wiki.corp.adobe.com/pages/viewpage.action?spaceKey=ONE&title=One+Console+Roles+and+Requirements
  // for role assignment permissions
  switch (role) {
    case ROLE.ADMIN.LICENSE:
      return currentUserRoles.anyOfForOrg(
        [ROLE.ADMIN.ORG, CONTRACT_ADMIN, ROLE.ADMIN.PRODUCT, ROLE.ADMIN.LICENSE],
        orgId
      );
    case CONTRACT_ADMIN:
    case ROLE.ADMIN.PRODUCT:
      return currentUserRoles.anyOfForOrg(
        [ROLE.ADMIN.ORG, ROLE.ADMIN.PRODUCT, CONTRACT_ADMIN, role],
        orgId
      );
    case ROLE.ADMIN.USER_GROUP:
    case ROLE.ADMIN.DEPLOYMENT:
      return currentUserRoles.anyOfForOrg([ROLE.ADMIN.ORG, role], orgId);
    case ROLE.ADMIN.ORG:
    case ROLE.ADMIN.STORAGE:
    case ROLE.ADMIN.SUPPORT:
    case ROLE.ADMIN.SUPPORTS:
      return currentUserRoles.isOrgAdminForOrg(orgId);
    default:
      return false;
  }
};

/**
 * @description Method to determine if the user has access to edit the role.
 *
 * @param {ConsumableSummarizationList} consumableSummarizationList the consumableSummarizationList to get quota
 * @param {String} orgId the org id
 * @param {Product} product the product to check
 * @param {ROLE.ADMIN} role the role to check
 * @param {User} user the user to check
 *
 * @returns {Boolean} return true if the user has access to edit the role
 */
const canEditRole = ({consumableSummarizationList, orgId, product, role, user}) => {
  const currentUser = AuthenticatedUser.get();
  const currentUserRoles = currentUser.getRoles();

  // Don't let an admin remove their own highest role
  if (role === currentUserRoles.getHighestRoleForOrg(orgId) && user.id === currentUser.getId()) {
    return false;
  }
  if (role === ROLE.ADMIN.PRODUCT_SUPPORT) {
    return (
      currentUserRoles.isOrgAdminForOrg(orgId) &&
      (isTargetAssignedToUser(ROLE.ADMIN.PRODUCT_SUPPORT, product.id, user) ||
        hasQuotaToAssignProductSupportAdmin(consumableSummarizationList, product))
    );
  }
  return canEditRoleByCurrentUserRoles(currentUserRoles, orgId, role);
};

/**
 * @description Method to get the sub roles which should be disabled.
 *
 * @param {String} orgId the org ID
 *
 * @returns {Array<ROLE.ADMIN>} return an array of sub roles which should be disabled
 */
const getDisabledSubRoles = (orgId) => {
  const currentUser = AuthenticatedUser.get();
  const currentUserRoles = currentUser.getRoles();
  // Don't let an admin remove their own highest role
  return [currentUserRoles.getHighestRoleForOrg(orgId)];
};

/**
 * @description Method to get the roles which should be disabled.
 *
 * @param {ConsumableSummarizationList} consumableSummarizationList the consumableSummarizationList to get quota
 * @param {Array<Product>} products an array of products
 * @param {ROLE.ADMIN} role the user role
 * @param {User} user the user to check
 *
 * @returns {Object} return an object with role as key and value as disabled items. Ex: {'products': ['productId1', 'productId2']}
 */
const getItemsToDisableForRole = (consumableSummarizationList, products, role, user) => {
  switch (role) {
    case ROLE.ADMIN.PRODUCT:
      if (products) {
        return {
          [TARGET_TYPE.PRODUCTS]: products.filter((p) => !productCanShowAdmins(p)).map((p) => p.id),
        };
      }
      return {};
    case ROLE.ADMIN.LICENSE:
      if (products) {
        return {
          [TARGET_TYPE.PRODUCT_PROFILES]: products
            .filter(
              (product) => !licenseGroupCanShowAdmins(product) || !canViewProductProfiles(product)
            )
            .map((p) => p.id),
        };
      }
      return {};
    case ROLE.ADMIN.USER_GROUP:
      return {
        [TARGET_TYPE.USER_GROUPS]: (item) =>
          (!auth.isUserOrgAdmin() && !auth.isUserUserGroupAdminForUserGroup(item.id)) ||
          item.isExternallyManaged() ||
          item.isTarget,
      };
    case ROLE.ADMIN.SUPPORTS:
      if (consumableSummarizationList && products && user) {
        return {
          [TARGET_TYPE.PRODUCTS]: products
            .filter(
              (product) =>
                product.isProductSupportRoleAssignmentAllowed() &&
                // only disable the product if it's not already assigned
                !isTargetAssignedToUser(ROLE.ADMIN.PRODUCT_SUPPORT, product.id, user) &&
                !hasQuotaToAssignProductSupportAdmin(consumableSummarizationList, product)
            )
            .map((p) => p.id),
        };
      }
      return {};
    default:
      return {};
  }
};

/**
 * @description Method to get products for assignment section.
 *
 * @param {Array<Product>} products
 * @param {ROLE.ADMIN} role the user role
 *
 * @returns {Array<Product>} return an array of products which are available on assignment section
 */
const getProductsForAssignmentSection = (products, role) => {
  if (role === ROLE.ADMIN.PRODUCT || role === ROLE.ADMIN.LICENSE) {
    return products?.map((product) => {
      if (feature.isEnabled('temp_add_contract_display_name')) {
        product.contractDisplayNames = getContractDisplayNames(product.contractIds);
      }
      return product;
    });
  }
  if (role === ROLE.ADMIN.SUPPORTS) {
    return products?.filter((product) => product.isProductSupportRoleAssignmentAllowed());
  }

  return [];
};

/**
 * @description Method to get sub roles for a user role.
 *
 * @param {ROLE.ADMIN} role the user role
 *
 * @returns {Array<ROLE.ADMIN>} return an array of sub roles based on the user role
 */
const getSubRoles = (role) => {
  if (role === ROLE.ADMIN.SUPPORTS) {
    return [ROLE.ADMIN.SUPPORT, ROLE.ADMIN.PRODUCT_SUPPORT];
  }
  return undefined;
};

/**
 * @description Method to get user role validity status.
 *
 * @param {ROLE.ADMIN} role the user role
 * @param {User} user the user to check
 *
 * @returns {Boolean} return true if the user role status is valid
 */
const getRoleValidityStatus = (role, user) =>
  // verify if all roles are valid and then check if at least one of the sub roles is checked
  !user.roles.some((userRole) => userRole.targets?.length === 0) &&
  user.roles.some((userRole) => getSubRoles(role).includes(userRole.type));

// eslint-disable-next-line @admin-tribe/admin-tribe/jsdoc-exported-functions -- cawright@ to update
const isTopLevelRole = (role) =>
  role === ROLE.ADMIN.ORG ||
  role === ROLE.ADMIN.STORAGE ||
  role === ROLE.ADMIN.SUPPORT ||
  role === ROLE.ADMIN.DEPLOYMENT;

const setExistingUserRoleWithTarget = ({existingUserRole, target, toggleState, user}) => {
  if (toggleState) {
    existingUserRole.targets?.push(target);
  } else if (!toggleState && existingUserRole.targets?.length > 1) {
    existingUserRole.targets = existingUserRole.targets.filter(
      (roleTarget) => roleTarget.id !== target.id
    );
  } else {
    user.roles = user.roles.filter((userRole) => userRole.type !== existingUserRole.type);
  }
};

/**
 * @description Method to set the user role or user role targets.
 *
 * @param {Boolean} checked whether the checkbox is checked
 * @param {String} orgId the org ID to get sub roles which should be disabled
 * @param {ROLE.ADMIN} role the user role to set
 * @param {Object} target the target to add or remove from user role
 * @param {Boolean} toggleState the role to check
 * @param {User} user the user to add or edit admin role
 */
const setUserRole = ({checked, orgId, role, target, toggleState, user}) => {
  const existingUserRole = user.roles.find((userRole) => userRole.type === role);

  if (existingUserRole && target) {
    setExistingUserRoleWithTarget({existingUserRole, target, toggleState, user});
  } else if ((checked || toggleState) && !getSubRoles(role)) {
    // do not add role when role with checkboxes is toggled
    user.roles.push({
      targets: isTopLevelRole(role) ? undefined : pull([target?.toMinimumModel?.()], undefined),
      type: role,
    });
  } else {
    const rolesToRemove = getSubRoles(role)?.filter(
      // only remove the sub roles which are not disabled
      (subRole) => !getDisabledSubRoles(orgId)?.includes(subRole)
    ) || [role];

    user.roles = user.roles.filter((userRole) => !rolesToRemove.includes(userRole.type));
  }
};

/**
 * @description Method to check if we should set support admin as default.
 *
 * @param {ROLE.ADMIN} role the user role to check
 * @param {User} user the user to check
 *
 * @returns {Boolean} return true if role is ROLE.ADMIN.SUPPORTS and user is not support admin or product support admin
 */
const shouldPreselectSupportAdmin = (role, user) => {
  if (feature.isEnabled('temp_preselect_support_admin')) {
    return (
      role === ROLE.ADMIN.SUPPORTS &&
      !user.roles.some((userRole) => getSubRoles(role).includes(userRole.type))
    );
  }
  return false;
};

/**
 * @description Method to check if we should process default items for the role.
 *
 * @param {Array<ROLE.ADMIN>} availableRoles roles that are available to be displayed
 * @param {ROLE.ADMIN} role the user role to check
 *
 * @returns {Boolean} return true if the we should process default items for the role
 */
const shouldProcessDefaultItems = (availableRoles, role) =>
  !isTopLevelRole(role) &&
  (availableRoles.includes(role) ||
    availableRoles.some((availableRole) => getSubRoles(availableRole)?.includes(role)));

// eslint-disable-next-line @admin-tribe/admin-tribe/jsdoc-exported-functions -- cawright@ to update
const roleToTargetType = (role) => {
  switch (role) {
    case CONTRACT_ADMIN:
      return TARGET_TYPE.CONTRACTS;
    case ROLE.ADMIN.PRODUCT:
      return TARGET_TYPE.PRODUCTS;
    case ROLE.ADMIN.PRODUCT_SUPPORT:
    case ROLE.ADMIN.SUPPORTS:
      return TARGET_TYPE.PRODUCT_SUPPORTS;
    case ROLE.ADMIN.LICENSE:
      return TARGET_TYPE.PRODUCT_PROFILES;
    case ROLE.ADMIN.USER_GROUP:
      return TARGET_TYPE.USER_GROUPS;
    // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- cawright@ to update
    // istanbul ignore next -- not implemented
    default:
      return null;
  }
};

/**
 *
 * @param {Array<ROLE.ADMIN>} roles
 * @param {User} user  the user to check
 * @param {String} orgId the org ID to get sub roles which should be disabled
 * @param {Array<Product>} products an array of products
 * @returns
 */
const getItemsPreselectedForRole = (roles, user, orgId, products) => {
  const mappedRoles = {};
  user.roles.forEach((role) => {
    if (shouldProcessDefaultItems(roles, role.type)) {
      const targetType = roleToTargetType(role.type);

      if (role.type === ROLE.ADMIN.LICENSE) {
        if (products)
          mappedRoles[targetType] = role.targets.map(
            (licenseGroup) =>
              new LicenseGroup({
                id: licenseGroup.id,
                name: licenseGroup.name,
                orgId,
                product: products.find((product) => product.id === licenseGroup.parentId),
              })
          );
      } else if (role.type === ROLE.ADMIN.USER_GROUP) {
        mappedRoles[targetType] = role.targets.map(
          (userGroup) => new UserGroup({id: userGroup.id, name: userGroup.name})
        );
      } else {
        mappedRoles[targetType] = role.targets.map((target) => target.id);
      }
    }
  });
  return mappedRoles;
};

/**
 *
 * @param {Array<ROLE.ADMIN>} availableRoles roles that are available to be displayed
 * @param {ROLE.ADMIN} role the user role to check
 * @returns Admin role name to match the translation key
 */
const roleToAdminRoles = (role) => {
  switch (role) {
    case ROLE.ADMIN.PRODUCT:
      return 'productAdmin';
    case ROLE.ADMIN.PRODUCT_SUPPORT:
      return 'productSupportAdmin';
    case ROLE.ADMIN.LICENSE:
      return 'licenseAdmin';
    case ROLE.ADMIN.USER_GROUP:
      return 'userGroupAdmin';
    case ROLE.ADMIN.SUPPORTS:
      return 'supportAdmin';
    // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- cawright@ to update
    // istanbul ignore next -- not implemented
    default:
      return '';
  }
};

export {
  assignTargets,
  canEditRole,
  getDisabledSubRoles,
  getItemsToDisableForRole,
  getItemsPreselectedForRole,
  getProductsForAssignmentSection,
  getRoleValidityStatus,
  getSubRoles,
  isTopLevelRole,
  roleToAdminRoles,
  roleToTargetType,
  setUserRole,
  shouldPreselectSupportAdmin,
  shouldProcessDefaultItems,
};
/* eslint-enable max-lines -- more util functions */
