import {NavigationAnchor} from '@admin-tribe/acsc-ui';
import {Link, View} from '@adobe/react-spectrum';
import Alert from '@spectrum-icons/workflow/Alert';
import CheckmarkCircleIcon from '@spectrum-icons/workflow/CheckmarkCircle';
import React from 'react';
import {useIntl} from 'react-intl';

import {useBulkOperationJob} from 'features/users/services/bulk-operations/BulkOperationJobContext';

import BulkOperationIconDetail from './BulkOperationIconDetail';

/* Displays the results of a bulk operation when completed*/
const JobResultsSummary = () => {
  const intl = useIntl();
  const {errorDetails, errorSummary, licenseDeficitHref, successSummary} = useBulkOperationJob();

  return (
    <>
      {successSummary && (
        <BulkOperationIconDetail
          ariaLabel={intl.formatMessage({
            id: 'bulkOperations.results.drawer.section.result.completed.ariaLabel',
          })}
          color="positive"
          data-testid="success-summary"
          Icon={CheckmarkCircleIcon}
        >
          {successSummary}
        </BulkOperationIconDetail>
      )}
      {errorSummary && (
        <BulkOperationIconDetail
          ariaLabel={intl.formatMessage({
            id: 'bulkOperations.results.drawer.section.result.errorsFound.ariaLabel',
          })}
          color="negative"
          data-testid="error-summary"
          Icon={Alert}
        >
          {errorSummary}
        </BulkOperationIconDetail>
      )}
      {errorDetails.map((error) => (
        <View key={error.type} data-testid="error-detail" marginStart="size-200">
          {/* eslint-disable-next-line @admin-tribe/admin-tribe/extract-large-computations -- inlining for links and count */}
          {intl.formatMessage(
            {
              defaultMessage: `${error.count} ${error.type}`,
              id: `bulkOperations.results.drawer.section.result.error.${error.type}`,
            },
            {
              count: error.count,
              link: (text) =>
                licenseDeficitHref && (
                  <Link>
                    {/* only license deficit errors will have this link */}
                    <NavigationAnchor href={licenseDeficitHref}>{text}</NavigationAnchor>
                  </Link>
                ),
            }
          )}
        </View>
      ))}
    </>
  );
};

export default JobResultsSummary;
