import {GoUrl, ImageIcon} from '@admin-tribe/acsc-ui';
import {Heading} from '@adobe/react-spectrum';
import {Card} from '@react/react-spectrum/Card';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';

import styles from './DirectoryDomainSourceCard.pcss';

const DirectoryDomainSourceCard = ({description, goUrl, id, learnMore, logo, selected, title}) => (
  <Card
    allowsSelection={false}
    data-test-id={id}
    selected={selected}
    size="L"
    styleName="provider-card"
  >
    <div styleName="provider-card-body">
      <ImageIcon alt="" size="XL" src={logo} styleName="provider-card-logo" />
      <Heading
        // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- needed to make heading smaller
        UNSAFE_className={styles['provider-card-title']}
      >
        <FormattedMessage id={title} />
      </Heading>
      <p>
        <FormattedMessage id={description} />
      </p>
      <GoUrl name={goUrl}>
        <FormattedMessage id={learnMore} />
      </GoUrl>
    </div>
  </Card>
);

DirectoryDomainSourceCard.propTypes = {
  description: PropTypes.string.isRequired,
  goUrl: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  learnMore: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

export default DirectoryDomainSourceCard;
