import {JIL_CONSTANTS} from '@admin-tribe/acsc';
import {useCallback} from 'react';

import useDomainList from 'features/settings/hooks/api/useDomainList';
import useDomainListState from 'features/settings/hooks/domains/useDomainListState';
import useJilTableOptions from 'features/settings/hooks/useJilTableOptions';

/**
 * A hook that holds the state for a DomainList.
 */
const useOrganizationDomainListState = (initialData) => {
  const [tableOptions, setTableOptions] = useJilTableOptions({
    sort: JIL_CONSTANTS.SORT.NAME,
  });
  const {getDomainList} = useDomainList();

  const fetchFn = useCallback(
    (params = tableOptions) => getDomainList(params),
    [getDomainList, tableOptions]
  );

  const domainListState = useDomainListState({fetchFn, setTableOptions, tableOptions}, initialData);

  return {...domainListState, setTableOptions, tableOptions};
};

export default useOrganizationDomainListState;
