import {Locale} from '@admin-tribe/acsc';

import rootStore from 'core/RootStore';
import {KCCC_SAP_CODE} from 'features/packages/packagesConstants';

import {stringVersionCompare} from './packagesAdobeProductEntityUtils';

class PackagesAdobeProductEntity {
  //////////////////////////////////////////
  /** Constructor for Adobe Product (Prototype function declaration) **/
  /**
   * @class
   * @description Creates a new AdobeProductEntity for use.
   *
   * @param {Object} options Initialization Object (params described below)
   * @param {String} options.sapCode Product's SAP Code
   * @param {String} options.version Product's version
   * @param {String} options.platform Product's platform
   * @param {String} options.name Product's display name
   * @param {Boolean} options.isSTI If the product is STI
   * @param {Boolean} options.isHyperdriveUpdate If the product is HyperdriveUpdate
   * @param {Boolean} options.isMaintenanceBuild If the product is LTS
   * @param {Boolean} options.isUnsupportedVersion If the product is isUnsupported
   * @param {Boolean} options.archive If the product is archived
   * @param {Array} options.productCategories Catagories that product belongs to
   * @param {Object} options.stiDependencyMap Product's stiDependencyMap
   * @param {Object} options.localeInfoUrls Product's localeInfoUrls
   * @param {Object} options.nglLicensingLibVersion Minimum supported NGL Lib version for FRL Offline
   */
  constructor(options) {
    Object.assign(this, {
      archive: options.archive,
      baseVersion: options.baseVersion,
      betaAppApui: options.betaAppApui,
      frlEnabled: options.frlEnabled,
      isHyperdriveUpdate: options.isHyperdriveUpdate,
      isMaintenanceBuild: options.isMaintenanceBuild,
      isModuleInfoAvailable: options.isModuleInfoAvailable,
      isPackageable: options.isPackageable,
      isRevokedBuild: options.isRevokedBuild,
      isSTI: options.isSTI,
      isUnsupportedVersion: options.isUnsupportedVersion,
      latestProductKey: options.latestProductKey,
      latestProductKeyOverSameBase: options.latestProductKeyOverSameBase,
      latestProductVersion: options.latestProductVersion,
      latestVersionOverSameBase: options.latestVersionOverSameBase,
      localeInfoUrls: options.localeInfoUrls,
      modules: options.modules,
      name: options.name,
      nglLicensingLibVersion: options.nglLicensingLibVersion,
      platform: options.platform,
      platformsSupported: options.platformsSupported,
      preReleaseApui: options.preReleaseApui,
      product_key: options.product_key,
      productCategories: options.productCategories,
      sapCode: options.sapCode,
      stiDependencyMap: options.stiDependencyMap,
      stiDependencyMapMacU: options.stiDependencyMapMacU,
      systemRequirementURL: options.systemRequirementURL,
      version: options.version,
      visible: options.visible,
    });
    // if it's an STI, make its latestProductVersion and latestProductKey same as its
    // latestVersionOverSameBase and latestProductKeyOverSameBase respectively
    if (this.isSTI) {
      this.latestProductVersion = this.latestVersionOverSameBase;
      this.latestProductKey = this.latestProductKeyOverSameBase;
    }

    // set isUpToDate flag
    this.isUpToDate =
      this.sapCode === KCCC_SAP_CODE ||
      stringVersionCompare(this.version, this.latestProductVersion);

    // add CSS query selector to uniquely identify the adobe product
    this.querySelectorKey = `${this.sapCode}-${this.version.replace(/\./g, '-')}`;
  }

  /**
   * @description Method to check if Adobe Product belongs to a productCategory.
   *
   * @param {String} productCategoryName  Product Category name
   * @returns {Boolean} true if Adobe Product belongs to given productCategory
   */
  belongsToProductCategory(productCategoryName) {
    return this.productCategories.includes(productCategoryName);
  }

  /**
   * @description Method to get the base version of the product.
   *
   * @returns {String} returns baseVersion of the product
   */
  getBaseVersion() {
    return this.baseVersion;
  }

  /**
   * @description Method to retrieve the display name for a given Adobe Product.
   *
   * @returns {String} Display name that should be used for this Adobe Product
   */
  getDisplayName() {
    return this.name;
  }

  /**
   * @description Method get icon URL for given size.
   *
   * @param {Integer} iconSize  dimension (example, for 96x96 icon, iconSize = 96)
   *                            Defaults to 96.
   * @returns {String} icon URL.
   */
  getIconUrl(iconSize) {
    const baseUrl = rootStore.packagesUiConstantsStore.iconBucketBaseUrl;
    if (!baseUrl) {
      return '';
    }
    if (iconSize) {
      return `${baseUrl}/${this.sapCode}/${this.version}/${iconSize}x${iconSize}.png`;
    }
    return `${baseUrl}/${this.sapCode}/${this.version}/96x96.png`;
  }

  /**
   * @description Method to get sapcode/version/platform key for update for a given Adobe Product.
   *
   * @param {String} platform Package platform context
   * @returns {String} sapcode/version/platform key for update  for this Adobe Product
   */
  getLatestProductKey() {
    return this.latestProductKey;
  }

  /**
   * @description Method to get locale specific info URL for given Adobe Product.
   *
   * @param {String} locale Info page's language
   * @returns {String} URL string. Empty string if not found.
   */
  getLocaleInfoUrl(locale) {
    let localeInfoUrl = '';
    const localeInfo = Locale.get();
    let activeLanguage, activeLocaleCode;
    if (localeInfo) {
      activeLanguage = localeInfo.activeLanguage;
      activeLocaleCode = localeInfo.activeLocaleCode;
    }
    if (this.localeInfoUrls) {
      if (locale && this.localeInfoUrls[locale]) {
        localeInfoUrl = this.localeInfoUrls[locale];
      } else if (activeLanguage && this.localeInfoUrls[activeLanguage]) {
        localeInfoUrl = this.localeInfoUrls[activeLanguage];
      } else if (activeLocaleCode && this.localeInfoUrls[activeLocaleCode]) {
        localeInfoUrl = this.localeInfoUrls[activeLocaleCode];
      } else if (this.localeInfoUrls.en) {
        // default to 'en'
        localeInfoUrl = this.localeInfoUrls.en;
      }
    }
    return localeInfoUrl;
  }

  /**
   * @description Method to retrieve the add-ons for a given Adobe Product.
   *
   * @returns {Object[]} array of details of app add-ons
   */
  getModules() {
    return this.modules;
  }

  /**
   * @description Method to retrieve the platform for a given Adobe Product.
   *
   * @returns {String} platform that should be used for this Adobe Product
   */
  getPlatform() {
    return this.platform;
  }

  /**
   * @description Method to get productCategories for given Adobe Product.
   *
   * @returns {Array} list of string productCategories
   */
  getProductCategories() {
    return this.productCategories;
  }

  /**
   * @description Method to retrieve the SAP code for a given Adobe Product.
   *
   * @returns {String} SAP code that should be used for this Adobe Product
   */
  getSapCode() {
    return this.sapCode;
  }

  /**
   * @description Method to get system Requirement info URL
   *
   * @returns {String} systemRequirementURL
   */
  getSystemRequirementUrl() {
    return this.systemRequirementURL || '';
  }

  /**
   * @description Method to retrieve the version for a given Adobe Product.
   *
   * @returns {String} version that should be used for this Adobe Product
   */
  getVersion() {
    return this.version;
  }

  /**
   * @description Method to check if this product has any special system requirements
   *
   * @returns {Boolean} true if it has any special system requirements
   */

  hasSpecialSystemRequirements() {
    return rootStore.packagesUiConstantsStore.hasSpecialSystemRequirements(this.product_key);
  }

  /**
   * @description Method to check if given Adobe Product is Archived.
   *
   * @returns {Boolean} true if given Adobe Product is Archived
   */
  isArchived() {
    return this.archive;
  }

  /**
   * @description Method to check if given Adobe Product is supported for frl offline offer.
   *
   * @returns {Boolean} true if given Adobe Product is supported for frl offline offer.
   */
  isFRLOfflineSupported() {
    return stringVersionCompare(
      this.nglLicensingLibVersion,
      rootStore.packagesUiConstantsStore.minNGLLibVersionFRLOffline
    );
  }

  /**
   * @description Method to check if given Adobe Product is HyperdriveUpdate.
   *
   * @returns {Boolean} true if given Adobe Product is HyperdriveUpdate
   */
  isHdUpdate() {
    return this.isHyperdriveUpdate;
  }

  /**
   * @description Method to check if given Adobe Product is LTS (isMaintenanceBuild).
   *
   * @returns {Boolean} true if given Adobe Product is LTS
   */
  isMaintenance() {
    return this.isMaintenanceBuild;
  }

  /**
   * @description Method to check if given Adobe Product is NGL enabled product.
   *
   * @returns {Boolean} true if given Adobe Product is NGL enabled
   */
  isNGLEnabled() {
    return this.frlEnabled;
  }

  /**
   * @description Method to check if given Adobe Product is STI.
   *
   * @returns {Boolean} true if given Adobe Product is STI
   */
  isSti() {
    return this.isSTI;
  }

  /**
   * @description Method to check if given Adobe Product is unsupported (isUnsupportedVersion).
   *
   * @returns {Boolean} true if given Adobe Product is unsupported
   */
  isUnsupported() {
    return this.isUnsupportedVersion;
  }

  /**
   * @description Method to check if given Adobe Product is visible.
   *
   * @returns {Boolean} true if given Adobe Product is visible
   */
  isVisible() {
    return this.visible;
  }

  //////////////////////////////////////////
}

export default PackagesAdobeProductEntity;
