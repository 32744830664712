import {Store} from '@admin-tribe/acsc';
import {action, makeObservable, observable} from 'mobx';

import help from 'shell/help/constants/help';

const {ON_CLOSE_FOCUS_ELEMENT_ID} = help;

// UI data for help content
class UiStore extends Store {
  constructor() {
    super();

    Object.assign(this, {
      isOpen: false, // boolean (true is open); defaults closed
    });

    makeObservable(this, {
      closeHelpRail: action.bound,
      isOpen: observable,
      openHelpRail: action.bound,
      setElementIdToFocusOnClose: action.bound,
      toggleHelpRail: action.bound,
    });
  }

  /**
   * @description Method to close the help rail. Will attempt to set focus to
   *   the element specified when the help rail was set up. If no element was
   *   set to receive focus (or if that element is no longer in the DOM), then
   *   the default element (id: ON_CLOSE_FOCUS_ELEMENT_ID) will receive focus on
   *   close
   */
  closeHelpRail() {
    this.isOpen = false;
    const toFocusElement =
      (this.elementIdToFocusOnClose &&
        document.querySelector(`#${this.elementIdToFocusOnClose}`)) ||
      document.querySelector(`#${ON_CLOSE_FOCUS_ELEMENT_ID}`);
    toFocusElement?.focus();
  }

  /**
   * @description Method to open the help rail for viewing help content.
   */
  openHelpRail() {
    this.isOpen = true;
  }

  /**
   * @description Method to set the element to focus on when the help rail is
   *   closed.
   * @param {string} elementId - id of element to focus when help rail closes
   */
  setElementIdToFocusOnClose(elementId) {
    this.elementIdToFocusOnClose = elementId;
  }

  /**
   * @description Method to either open or close the help rail, depending on its
   * current state (e.g. - closes if it is open)
   */
  toggleHelpRail() {
    if (this.isOpen) {
      this.closeHelpRail();
    } else {
      this.openHelpRail();
    }
  }
}

export default UiStore;
