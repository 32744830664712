import {
  feature,
  getSeatBasedAssignableLicenseCount,
  getSeatBasedTotalProvisionedQuantity,
  hasOnlyTrialContracts,
} from '@admin-tribe/acsc';
import AlertCircleFilledIcon from '@spectrum-icons/workflow/AlertCircleFilled';

import rootStore from 'core/RootStore';
import {canViewQuickAssignModal} from 'core/products/access/productAccess';
import auth from 'core/services/auth';
import {goToQuickAssignProducts} from 'features/overview/routing/navigation-callbacks/navigationCallbacks';

import HvaCard from '../HvaCard';
import {HVA_ORDER, HVA_TYPE} from '../hvaConstants';

function getUnassignedLicenseCount() {
  const assignableLicenses = getSeatBasedAssignableLicenseCount(
    rootStore.organizationStore.productList
  );
  const provisionedLicenses = getSeatBasedTotalProvisionedQuantity(
    rootStore.organizationStore.productList
  );

  // At this point in HVA priority sequence, the org will have > 0 provisioned licenses
  // This is important to note as the wording of this HVA is to finish their provisioning of licenses
  // Temporary fix for https://git.corp.adobe.com/admin-tribe/onesie/issues/12327 while we figure out
  // how to handle quick assign assignable products that are not seat based
  return assignableLicenses - provisionedLicenses;
}

/**
 * This class creates a HVA card allowing user to finish assigning licenses
 */
class FinishAssigningLicensesHva extends HvaCard {
  static get({intl}) {
    const isNotOrgOrContractAdmin = feature.isEnabled('temp_contract_admin_role')
      ? !auth.isUserOrgAdmin() && !auth.isUserContractAdmin()
      : !auth.isUserOrgAdmin();
    if (
      isNotOrgOrContractAdmin ||
      hasOnlyTrialContracts(rootStore.organizationStore.contractList)
    ) {
      return Promise.reject();
    }

    const unassignedLicenseCount = getUnassignedLicenseCount();

    if (canViewQuickAssignModal() && getUnassignedLicenseCount() > 0) {
      return Promise.resolve([new FinishAssigningLicensesHva({intl, unassignedLicenseCount})]);
    }
    return Promise.reject();
  }

  constructor({intl, unassignedLicenseCount}) {
    const namespace = 'overview.highValueActionArea.setUpTasks.finishAssigningLicenses';
    super({
      ctaLabel: intl.formatMessage({id: `${namespace}.ctaLabel`}),
      description: intl.formatMessage({
        id: `${namespace}.description`,
      }),
      header: intl.formatMessage(
        {id: `${namespace}.header`},
        {
          unassignedLicenseCount,
        }
      ),
      icon: {
        Component: AlertCircleFilledIcon,
        props: {color: 'informative', size: 'L'},
      },
      id: 'hva-finish-assigning-licenses',
      isDismissible: false,
      onCTA: goToQuickAssignProducts,
      priorityOrder: HVA_ORDER.FINISH_ASSIGN_LICENSES,
      sectionTitle: intl.formatMessage({id: `${namespace}.sectionTitle`}),
      type: HVA_TYPE.SET_UP,
    });
  }
}

export default FinishAssigningLicensesHva;
