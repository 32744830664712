import {
  AuthenticatedUser,
  LinkedUserAccountList,
  ORGANIZATION_TYPE,
  authentication,
  feature,
  getEarliestAnniversaryDate,
  getPersistentData,
  log,
} from '@admin-tribe/acsc';
import {DATE_FORMATS, OrganizationSwitcher} from '@admin-tribe/acsc-ui';
import {
  ActionButton,
  Dialog,
  DialogTrigger,
  Flex,
  Provider,
  Text,
  View,
} from '@adobe/react-spectrum';
import {useIsMounted} from '@pandora/react-is-mounted';
import CalendarIcon from '@spectrum-icons/workflow/Calendar';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';

/**
 * Component to switch between organizations
 */
const OrganizationSwitcherContainer = () => {
  const intl = useIntl();
  const isMounted = useIsMounted();
  const filterToTypes = feature.isEnabled('bug_fix_developer_org_switcher')
    ? [ORGANIZATION_TYPE.DEVELOPER, ORGANIZATION_TYPE.DIRECT, ORGANIZATION_TYPE.ENTERPRISE]
    : [ORGANIZATION_TYPE.ENTERPRISE, ORGANIZATION_TYPE.DIRECT];
  const activeOrgName = rootStore.organizationStore.activeOrg.name;
  const activeOrgId = rootStore.organizationStore.activeOrgId;
  const authenticatedUser = AuthenticatedUser.get();
  const [linkedUserAccounts, setlinkedUserAccounts] = useState([]);
  const earliestRenewalDate = getEarliestAnniversaryDate(rootStore.organizationStore.contractList);
  const renewalDate = earliestRenewalDate
    ? intl.formatDate(earliestRenewalDate, DATE_FORMATS.default)
    : undefined;
  const width = '400px';

  // Loads the list of associated organizations to be displayed
  useEffect(() => {
    const fetchOrganizationList = async () => {
      try {
        const linkedUserAccountList = await LinkedUserAccountList.get({
          includePaths: true,
          userId: authenticatedUser.profile.userId,
        });
        if (isMounted()) {
          setlinkedUserAccounts(linkedUserAccountList?.items);
        }
      } catch (error) {
        log.error('Error getting Linked User Accounts: ', error);
      }
    };
    fetchOrganizationList();
  }, [authenticatedUser, isMounted]);

  const setActiveOrg = async (selectedOrg) => {
    if (
      rootStore.organizationStore.activeOrgId !== selectedOrg.orgId ||
      authenticatedUser.profile.userId !== selectedOrg.userId
    ) {
      try {
        const persistentData = getPersistentData();
        const query =
          persistentData && Object.keys(persistentData).length > 0
            ? `?${Object.keys(persistentData)
                .map((key) => `${key}=${persistentData[key]}`)
                .join('&')}`
            : '';
        await authentication.switchTo(selectedOrg.userId, `${selectedOrg.orgId}/overview${query}`);
      } catch (error) {
        log.error(`authentication.switchTo(): switchProfile failed. Error: `, error);
      }
    }
  };

  return (
    <DialogTrigger containerPadding={0} hideArrow offset={6} shouldFlip={false} type="popover">
      <Provider colorScheme="dark">
        <View backgroundColor="gray-50">
          <ActionButton data-testid="org-switch-button" isQuiet maxWidth="size-4600">
            {activeOrgName}
          </ActionButton>
        </View>
      </Provider>
      <Dialog alignItems="center" marginBottom="calc(-1 * size-900)">
        <Flex
          direction="column"
          UNSAFE_style={{
            width,
          }}
        >
          {renewalDate && (
            <View backgroundColor="gray-200" height="size-450">
              <Flex alignItems="center" height="size-450" marginStart="size-250">
                <CalendarIcon marginEnd="size-100" size="S" />
                <Text>
                  {intl.formatMessage(
                    {id: 'shell.orgSwitcherContainer.anniversaryDate'},
                    {
                      // eslint-disable-next-line react/forbid-elements -- needs update
                      b: (chunks) => <b>{chunks}</b>,
                      renewalDate,
                    }
                  )}
                </Text>
              </Flex>
            </View>
          )}
          <Flex
            direction="column"
            justifyContent="center"
            marginStart="size-200"
            marginTop="size-125"
          >
            <OrganizationSwitcher
              activeOrgId={activeOrgId}
              authenticatedUserId={authenticatedUser.profile.userId}
              data-testid="organization-switcher-component"
              filterToTypes={filterToTypes}
              linkedUserAccounts={linkedUserAccounts}
              onChange={setActiveOrg}
              showBorder={false}
              width="size-4600"
            />
          </Flex>
        </Flex>
      </Dialog>
    </DialogTrigger>
  );
};

export default OrganizationSwitcherContainer;
