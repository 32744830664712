import {
  CONTRACT_EVENT,
  Locale,
  configStore,
  dispatchUiEventAnalytics,
  eventBus,
  getLocalStorageItem,
  log,
  removeLocalStorageItem,
  setLocalStorageItem,
} from '@admin-tribe/acsc';
import {OverlayWait, showSuccess as showSuccessToast} from '@admin-tribe/acsc-ui';
import {Dialog} from '@adobe/react-spectrum';
import {NavigationMessageSubType} from '@pandora/mini-app-messaging-types';
import {useIframeMessage} from '@pandora/react-app-context-iframe-message-handler';
import {MessageType, SupportedSystemMessageTypes} from '@pandora/react-app-context-provider';
import {ModalContainer, ModalDialogType} from '@pandora/react-modal-dialog';
import {FocusScope} from '@react-aria/focus';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';
import {CART_EVENT} from 'features/offers/freeOfferCartConstants';

import styles from './EditBillingModal.pcss';

const EDIT_BILLING_RETURN_ID = 'editBillingReturn';

/**
 * @description Hook to manage the state of the REBA (React Edit Billing App) modal
 */
const useRebaMessageHandler = ({inPaymentMode, onCancel, onSubmit}) => {
  const {
    postMessageAppName,
    postMessageStatusSaveComplete,
    postMessageStatusSaveIncomplete,
    postSystemMessageComplete,
  } = configStore.get('services.rebaEditBilling');

  const intl = useIntl();
  const showPaymentSavedMessage = () => {
    showSuccessToast(intl.formatMessage({id: 'common.updatePayment.success.toast'}));
  };

  const [appReady, setAppReady] = useState(false);

  const submitForSaveComplete = () => {
    dispatchUiEventAnalytics({
      eventAction: 'closeWithCompletedSave',
      eventName: 'editBillingModal',
    });

    // Attempt to refresh contract data
    eventBus.emit(CONTRACT_EVENT.UPDATE);
    eventBus.emit(CART_EVENT.SUBMIT);

    // Show success toast, close modal, and dismiss global banner
    showPaymentSavedMessage();
    onSubmit();
    rootStore.organizationStore.globalBannerStore.dismiss();
  };

  // Message handler to process the events from with subType as CLOSE
  const handleCloseMessages = (data) => {
    // eslint-disable-next-line default-case -- no need to handle default case
    switch (data?.status) {
      case postMessageStatusSaveComplete:
        submitForSaveComplete();
        break;
      case postMessageStatusSaveIncomplete:
        dispatchUiEventAnalytics({
          eventAction: 'closeWithIncompleteSave',
          eventName: 'editBillingModal',
        });
        onCancel();
        break;
    }
  };

  // Message handler to process System event messages
  const systemMessageHandler = ({data, subType}) => {
    // eslint-disable-next-line default-case -- no need to handle default case
    switch (subType) {
      case SupportedSystemMessageTypes.APP_LOADED:
        dispatchUiEventAnalytics({
          eventAction: 'open',
          eventName: 'editBillingModal',
        });
        setAppReady(true);
        break;
      case SupportedSystemMessageTypes.CLOSE:
        dispatchUiEventAnalytics({
          eventAction: 'close',
          eventName: 'editBillingModal',
        });
        // Remove any Edit Billing return data
        removeLocalStorageItem(EDIT_BILLING_RETURN_ID);

        // Call appropriate close handler
        handleCloseMessages(data);
        break;
      case SupportedSystemMessageTypes.ERROR:
        dispatchUiEventAnalytics({
          eventAction: 'error',
          eventName: 'editBillingModal',
        });
        break;
      case postSystemMessageComplete:
        // The user saved payment info successfully
        dispatchUiEventAnalytics({
          eventAction: 'saveComplete',
          eventName: 'editBillingModal',
        });

        // Auto-close modal if a save was completed in OTP / payment mode
        if (inPaymentMode) {
          submitForSaveComplete();
        }
        break;
    }
  };

  // See https://wiki.corp.adobe.com/display/BPS/Edit+Billing+Client+Integration+Guide#EditBillingClientIntegrationGuide-PostMessageEvents
  const messageHandler = ({app, data, error, subType, type}) => {
    // Ignore the message if it is from a different app
    if (app !== postMessageAppName) return;

    log.info('Message from React Edit Billing App:', {data, error, subType, type});

    if (type === MessageType.SYSTEM) {
      systemMessageHandler({data, subType});
    } else if (type === MessageType.OPEN_URL) {
      if (subType === NavigationMessageSubType.RETURN_BACK) {
        // Save returnUrl and returnUrlParams in storage to use upon return from PayPal
        const {returnUrl, returnUrlParams} = data;
        setLocalStorageItem(EDIT_BILLING_RETURN_ID, JSON.stringify({returnUrl, returnUrlParams}));
      }

      // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- opens link
      window.open(data.externalUrl, data.target);
    }
  };

  return {appReady, messageHandler};
};

/**
 * @description Return URL for Admin Console deep link if in an iframe, otherwise return to standalone Add Products page
 */
const buildPayPalReturnUrl = (paypal) => {
  // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- opens link
  const url = new URL(window?.location?.href);

  // Update URL search params with target step and PayPal decision
  const mergedSearchParams = {
    ...Object.fromEntries(url.searchParams.entries()),
    paypal,
  };
  url.search = new URLSearchParams(mergedSearchParams).toString();

  return encodeURI(url.href);
};

/**
 * @description Builds REBA (React Edit Billing App) URL
 */
const buildRebaUrl = ({contractId, inPaymentMode, invoiceId, localeLanguage, orgId}) => {
  // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- gets host URL
  const hostUrl = new URL(window?.location?.href);
  const hostUrlSearchParams = Object.fromEntries(hostUrl.searchParams.entries());
  const editBillingReturnData = getLocalStorageItem(EDIT_BILLING_RETURN_ID) ?? {};
  const {returnUrl, returnUrlParams} =
    typeof editBillingReturnData === 'string' ? JSON.parse(editBillingReturnData) : {};

  // Restore returnUrl and returnUrlParams from storage to use upon return from PayPal
  if (returnUrl && returnUrlParams?.length) {
    const targetUrl = new URL(returnUrl);

    // Update URL search params with hostUrl query param values identified by returnUrlParams (param keys)
    const hostUrlSearchParamsToUse = Object.fromEntries(
      returnUrlParams.map((key) => [key, hostUrlSearchParams[key]])
    );
    const mergedSearchParams = {
      ...Object.fromEntries(targetUrl.searchParams.entries()),
      ...hostUrlSearchParamsToUse,
    };
    targetUrl.search = new URLSearchParams(mergedSearchParams).toString();

    // Return target URL meant for resolving pending PayPal payment update
    return targetUrl.href;
  }

  const {
    commerceClientId,
    commerceClientIdForPay,
    baseUrl,
    urlPathSegmentForEdit,
    urlPathSegmentForPay,
  } = configStore.get('services.rebaEditBilling');
  const params = {
    // React Edit Billing App params: https://wiki.corp.adobe.com/display/BPS/Edit+Billing+Client+Integration+Guide#EditBillingClientIntegrationGuide-Tableofpublicqueryparams
    cli: inPaymentMode ? commerceClientIdForPay : commerceClientId, // REBA uses a Commerce (not IMS) client ID
    coid: contractId,
    ctx: 'if',
    // URL to redirect back to in from PayPal: https://wiki.corp.adobe.com/display/BPS/Edit+Billing+Client+Integration+Guide#EditBillingClientIntegrationGuide-PayPalIntegration
    ctxRtUrl: buildPayPalReturnUrl(true),
    inv: invoiceId,
    lang: localeLanguage,
    orgid: orgId,
  };

  // Omit nullish or empty string param values
  Object.entries(params).forEach(([key, value]) => {
    if ((value ?? '') === '') {
      delete params[key];
    }
  });

  const urlWithParams = `${baseUrl}${
    inPaymentMode ? urlPathSegmentForPay : urlPathSegmentForEdit
  }?${qs.stringify(params)}`;

  return urlWithParams;
};

/**
 * @description Loads REBA (React Edit Billing App) modal in an iframe
 */
const EditBillingModal = ({
  contractId,
  inPaymentMode = false,
  invoiceId = '',
  isOpen = false,
  onCancel,
  onSubmit,
}) => {
  const {organizationStore} = rootStore;
  const orgId = organizationStore.activeOrgId;

  // Consume messages from iframe
  const {appReady, messageHandler} = useRebaMessageHandler({inPaymentMode, onCancel, onSubmit});
  useIframeMessage(messageHandler);

  // AEM content expects locale in BCP-47 format
  const localeLanguage = Locale.get().activeLanguageBCP47Code;
  const urlWithParams = buildRebaUrl({
    contractId,
    inPaymentMode,
    invoiceId,
    localeLanguage,
    orgId,
  });

  return (
    // Wrapper is needed to restore focus per https://github.com/adobe/react-spectrum/issues/3877#issuecomment-1369427785
    <div className={styles['edit-billing-modal']}>
      {isOpen && (
        <FocusScope contain restoreFocus>
          <ModalContainer dialogType={ModalDialogType.FULLSCREEN}>
            <Dialog>
              <div styleName="dialog-content">
                <OverlayWait isLoading={!appReady} size="L">
                  <iframe
                    className={styles['edit-billing-iframe']}
                    src={urlWithParams}
                    title="Edit Billing"
                  />
                </OverlayWait>
              </div>
            </Dialog>
          </ModalContainer>
        </FocusScope>
      )}
    </div>
  );
};

EditBillingModal.propTypes = {
  /**
   * Contract ID
   */
  contractId: PropTypes.string,
  /**
   * Boolean to indicate whether user is editing or paying
   */
  inPaymentMode: PropTypes.bool,
  /**
   * Invoice ID for one time payment
   */
  invoiceId: PropTypes.string,
  /**
   * Boolean to indicate whether the modal is open
   */
  isOpen: PropTypes.bool,
  /**
   * Callback to invoke when the modal's cancel button is pressed.
   */
  onCancel: PropTypes.func.isRequired,
  /**
   * Callback to invoke when the modal's action is submitted.
   */
  onSubmit: PropTypes.func.isRequired,
};

export default EditBillingModal;
