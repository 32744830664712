import {
  ORGANIZATION_MARKET_SEGMENT,
  ORGANIZATION_USER_API_QUERY_PARAMS,
  OrganizationUserList,
  OrganizationUserListCount,
  PAGE_TARGET,
  PAGE_TARGET_TYPE,
  PageContext,
  feature,
} from '@admin-tribe/acsc';
import {
  InfoItemScorecard,
  Page,
  PageBanners,
  PageContent,
  PageDescription,
  PageHeader,
  PageInfoBar,
  PageNav,
  useStore,
} from '@admin-tribe/acsc-ui';
import {ActionButton} from '@adobe/react-spectrum';
import {GoUrl} from '@pandora/react-go-url';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import {useLoaderData, useParams} from 'react-router-dom';

import GlobalAdminPolicyBanner from 'common/components/global-admin-policy-banner/GlobalAdminPolicyBanner';
import {USERS_CONTEXT} from 'common/components/global-admin-policy-banner/globalAdminBannerConstants';
import MemberProductIconList from 'common/components/member-product-icon-list/MemberProductIconList';
import SophiaBanner from 'common/components/sophia/banner/SophiaBanner';
import UserListTableSection from 'common/components/user-list-table-section/UserListTableSection';
import UserOperations from 'common/entities/user-operations/UserOperations';
import {MemberListContextProvider} from 'common/hooks/member-list-context/MemberListContext';
import useDocumentTitle from 'common/hooks/useDocumentTitle';
import SOPHIA_CONSTANTS from 'common/services/sophia/SophiaConstants';
import {getIntroductionsData} from 'common/services/sophia/shared-contextual-params/sharedContextualParamsUtils';
import UserStore from 'common/stores/user-store/UserStore';
import rootStore from 'core/RootStore';
import {canAssignUser, canAssignUserByCSV, canRemoveUser} from 'core/user/access/userAccess';
import {goToUserIntroductions} from 'features/overview/routing/navigation-callbacks/navigationCallbacks';
import InfoItemScorecard2 from 'features/users/components/info-item-container-2/InfoItemScorecard2';

/**
 * The Users top-level page.
 * useLoaderData will optionally contain {onInitBulkOperationMode: one of BULK_OPERATIONS_MODE}.
 * On init, if onInitBulkOperationMode is set, the bulk operations modal will open in this mode.
 */
const UsersPage = observer(() => {
  const {orgId} = useParams();
  const {onInitBulkOperationMode} = useLoaderData() || {};
  const marketSegment = rootStore.organizationStore.activeOrg.marketSegment;

  const showB2BFreeDescription =
    feature.isEnabled('temp_b2b_free_provisioning') &&
    marketSegment === ORGANIZATION_MARKET_SEGMENT.COMMERCIAL &&
    rootStore.organizationStore.productList.freeItems?.length > 0;

  useDocumentTitle({key: 'users.users.documentTitle'});

  const pageContext = useRef(
    new PageContext({
      target: PAGE_TARGET.ORGANIZATION,
      targetId: orgId,
      targetType: PAGE_TARGET_TYPE.USER,
    })
  ).current;

  const userStore = useStore(() => {
    const includeList = feature.isEnabled('temp_de_exclusion_40038')
      ? ORGANIZATION_USER_API_QUERY_PARAMS.DOMAIN_ENFORCEMENT_EXCEPTION_INDICATOR
      : undefined;
    return new UserStore({
      immediateLoad: true,
      listClassRef: OrganizationUserList,
      listOptions: {
        include: includeList,
      },
      refreshUserCountRef: OrganizationUserListCount,
    });
  });

  const intl = useIntl();
  const [loadingMessage, setLoadingMessage] = useState();
  const [userOperations, setUserOperations] = useState();
  const [actionableIntroductionsCount, setActionableIntroductionsCount] = useState();

  // Initialize the user operations.
  useEffect(() => {
    (async function initUserOperations() {
      // This call is async but does not throw.
      const operations = await UserOperations.getOrgUserOperations({onInitBulkOperationMode});
      setUserOperations(operations);
    })();
  }, [onInitBulkOperationMode]);

  // If we're in searchOnlyContentMode, display a string with the wait spinner.
  useEffect(() => {
    if (userStore.searchOnlyContentMode) {
      // @cframpto - this message should be set on UserListTableSection OverlayWait
      setLoadingMessage(intl.formatMessage({id: 'users.users.searchForUsers.loadingMessage'}));
    }
  }, [intl, userStore.searchOnlyContentMode]);

  // make the call for the pending user intros, if flag enabled
  useEffect(() => {
    (async function initPendingIntroductions() {
      if (feature.isEnabled('temp_user_introductions_scorecard')) {
        // This call is async but does not throw.
        const userIntroductions = await getIntroductionsData();
        setActionableIntroductionsCount(userIntroductions.actionableIntroductionsCount);
      }
    })();
  }, []);

  return (
    <Page
      data-testid="users-page"
      isLoading={userStore.isLoading && !userStore.currentItems}
      loadingMessage={loadingMessage}
    >
      <PageBanners>
        <SophiaBanner surfaceId={SOPHIA_CONSTANTS.SURFACE_ID.ONE_CONSOLE_USERS} />
        <GlobalAdminPolicyBanner context={USERS_CONTEXT} />
      </PageBanners>
      <PageHeader title={intl.formatMessage({id: 'users.users.title'})} />
      {showB2BFreeDescription && (
        <PageDescription>
          {intl.formatMessage(
            {id: 'users.users.description'},
            {
              goUrl: (urlText) => (
                <GoUrl name="complimentary_membership" noWrap={false}>
                  {urlText}
                </GoUrl>
              ),
            }
          )}
        </PageDescription>
      )}

      <PageInfoBar>
        <InfoItemScorecard
          label={intl.formatMessage({id: 'users.users.scorecard.users'})}
          value={userStore.scorecardValue}
        />
        {actionableIntroductionsCount > 0 && actionableIntroductionsCount <= 10 && (
          <InfoItemScorecard2
            label={intl.formatMessage({id: 'users.users.scorecard.user-introductions'})}
            value={actionableIntroductionsCount}
          >
            <ActionButton
              data-testid="users-page-review-introductions"
              marginStart="size-100"
              onPress={() => goToUserIntroductions()}
              UNSAFE_style={{height: '85%'}}
            >
              {intl.formatMessage({id: 'users.users.scorecard.review-user-introductions'})}
            </ActionButton>
          </InfoItemScorecard2>
        )}
      </PageInfoBar>
      <PageNav />
      <PageContent>
        <MemberListContextProvider
          canAddMember={canAssignUser()}
          canAddMemberByCsv={canAssignUserByCSV()}
          canRemoveMember={canRemoveUser()}
          loadingMessage={loadingMessage}
          memberListDisplay={{
            avatar: true,
            email: true,
            idType: true,
            productIcons: true,
            viewDetails: true,
          }}
          pageContext={pageContext}
          productIconList={<MemberProductIconList />}
          store={userStore}
          userOperations={userOperations}
        >
          <UserListTableSection />
        </MemberListContextProvider>
      </PageContent>
    </Page>
  );
});

export default UsersPage;
