import {Contract} from '@admin-tribe/acsc';
import {showError, showSuccess} from '@admin-tribe/acsc-ui';
import {Checkbox, Divider, Text, View} from '@adobe/react-spectrum';
import {
  ModalContent,
  ModalDialog,
  ModalDialogModel,
  ModalHeading,
} from '@pandora/react-modal-dialog';
import SpectrumV2Provider from '@react/react-spectrum/Provider';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import {selectedInvoicesCount} from 'features/account/billing-history/BillingHistoryUtils';
import EmailTagList from 'features/account/billing-history/components/email-tag-list/EmailTagList';
import useEmailInvoices from 'features/account/billing-history/hooks/useEmailInvoices';

import styles from './EmailInvoicesModal.pcss';

// ModalDialog has its own custom styling with width set to 1100px
// And in order to use size="M" we need to reset the styling
const CUSTOM_DIALOG_STYLE = {};

/**
 * The modal opened by the `Email invoices` button which is on BillingHistoryPage.
 */
const EmailInvoicesModal = ({contract, onCancel, onSuccess, selectedInvoices: invoices}) => {
  const intl = useIntl();
  const {emailError, emailInvoices, isEmailing} = useEmailInvoices({contract});
  const [isCheckboxSelected, setCheckboxSelected] = useState(false);
  const [emailsToSend, setEmailsToSend] = useState([]);
  const [isEmailListInvalid, setIsEmailListInvalid] = useState(false);
  const [hasEmailBeenInitiated, setHasEmailBeenInitiated] = useState(false);

  // Wait for call to email resolves, then react to the result
  useEffect(() => {
    if (hasEmailBeenInitiated && !isEmailing) {
      if (emailError) {
        showError(
          intl.formatMessage({id: 'account.billingHistory.emailInvoicesModal.toast.errorMessage'})
        );
      } else {
        showSuccess(
          intl.formatMessage(
            {id: 'account.billingHistory.emailInvoicesModal.toast.successMessage'},
            {invoiceCount: selectedInvoicesCount(invoices), recipientCount: emailsToSend.length}
          )
        );
      }
      onSuccess();
    }
  }, [
    emailError,
    emailsToSend.length,
    hasEmailBeenInitiated,
    intl,
    invoices,
    isEmailing,
    onSuccess,
  ]);

  const onEmailAdded = ({emailList, isListInvalid}) => {
    setEmailsToSend(emailList);
    setIsEmailListInvalid(isListInvalid);
  };

  const onCta = () => {
    emailInvoices({emailList: emailsToSend, includePrimaryAdmin: isCheckboxSelected, invoices});
    setHasEmailBeenInitiated(true);
  };

  const modalDialogContent = ModalDialogModel.createEntry({
    cancelLabel: intl.formatMessage({id: 'account.billingHistory.emailInvoicesModal.cancelButton'}),
    ctaLabel: intl.formatMessage({id: 'account.billingHistory.emailInvoicesModal.sendEmailButton'}),
  });

  const isCtaDisabled = isEmailListInvalid || emailsToSend.length === 0;

  return (
    <ModalDialog
      analyticsModalId="email-invoices-modal"
      content={modalDialogContent}
      dialogStyle={CUSTOM_DIALOG_STYLE}
      isCtaDisabled={isCtaDisabled}
      isLoading={isEmailing}
      onCancel={onCancel}
      onCta={onCta}
      size="M"
    >
      <ModalHeading>
        {intl.formatMessage(
          {id: 'account.billingHistory.emailInvoicesModal.title'},
          {count: selectedInvoicesCount(invoices)}
        )}
      </ModalHeading>
      <Divider size="M" />
      <ModalContent>
        <Text>
          {intl.formatMessage(
            {id: 'account.billingHistory.emailInvoicesModal.description'},
            {
              i: (text) => <span className={styles['italic-text']}>{text}</span>,
            }
          )}
        </Text>
        <View marginBottom="size-300" marginTop="size-200">
          <SpectrumV2Provider>
            <EmailTagList onEmailAdded={onEmailAdded} />
          </SpectrumV2Provider>
        </View>
        <Checkbox
          isDisabled={isCtaDisabled}
          isSelected={isCheckboxSelected}
          onChange={setCheckboxSelected}
        >
          {intl.formatMessage({id: 'account.billingHistory.emailInvoicesModal.checkBoxLabel'})}
        </Checkbox>
      </ModalContent>
    </ModalDialog>
  );
};

EmailInvoicesModal.propTypes = {
  /**
   * The organization's contract
   */
  contract: PropTypes.instanceOf(Contract).isRequired,
  /**
   * Handler that closes the modal.
   */
  onCancel: PropTypes.func.isRequired,
  /**
   * Callback to invoke when the modal's actions have been successful.
   */
  onSuccess: PropTypes.func.isRequired,
  /**
   * Object containing selected invoices mapped to externalContractId.
   * Example: {
   *   externalContractId1: ['invoiceId1', 'invoiceId2'],
   *   externalContractId2: ['invoiceId3']
   * }
   */
  // eslint-disable-next-line react/forbid-prop-types -- cannot set proptype for object because externalContractId is dynamically generated
  selectedInvoices: PropTypes.object.isRequired,
};

export default EmailInvoicesModal;
