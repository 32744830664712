import {
  NavigationAnchor,
  TableSectionTable,
  ViewDetailsDrawerTrigger,
  showError,
  showInfo,
  showSuccess,
} from '@admin-tribe/acsc-ui';
import {ActionButton, Link, Text} from '@adobe/react-spectrum';
import DownloadIcon from '@spectrum-icons/workflow/Download';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {BulkOperationJobContext} from 'features/users/services/bulk-operations/BulkOperationJobContext';

import BulkOperationDetailsDrawer from './BulkOperationDetailsDrawer';
import BulkOperationsStatus from './BulkOperationsStatus';

const BulkOperationsListTable = ({
  detailsHrefFn,
  detailsNavFn,
  downloadFn,
  licenseDeficitHrefFn,
  onCancelRequested,
}) => {
  const intl = useIntl();
  const columnDescriptor = [
    {key: 'operation'},
    {key: 'file'},
    {
      key: 'viewDetails',
      props: {hideHeader: true, showDivider: true},
    },
    {key: 'initiatedBy'},
    {key: 'started'},
    {key: 'status'},
  ];

  const onCancelRequestedWithRender = (item) => {
    item.forceRender = true;
    onCancelRequested();
  };

  const onDownloadResults = async ({jobId}) => {
    try {
      showInfo(intl.formatMessage({id: 'bulkOperationResult.common.download.toast.beingPrepared'}));

      const fileName = await downloadFn?.({jobId});

      showSuccess(
        intl.formatMessage(
          {id: 'bulkOperationResult.common.download.toast.download.success'},
          {fileName}
        )
      );
    } catch {
      showError(
        intl.formatMessage({id: 'bulkOperationResult.common.download.toast.download.error'})
      );
    }
  };

  const getFile = ({item}) => {
    if (item.isExportJob()) {
      return item.isCompleted() ? (
        <ActionButton onPress={() => onDownloadResults({jobId: item.id})}>
          <DownloadIcon />
          <Text>{item.getFilename()}</Text>
        </ActionButton>
      ) : null;
    }
    return item.getFilename();
  };

  const getInitiatedBy = ({item}) => item.getInitiatedBy().getDisplayName();

  const getOperation = ({item}) => {
    const operationName = intl.formatMessage({id: item.getOperationDisplayName()});

    return item.canShowResults() && detailsHrefFn ? (
      <Link>
        <NavigationAnchor href={detailsHrefFn({jobId: item.id})}>{operationName}</NavigationAnchor>
      </Link>
    ) : (
      <Text>{operationName}</Text>
    );
  };

  const getStarted = ({item}) => item.getStarted(intl);

  const getStatus = ({item}) => (
    <BulkOperationJobContext job={item} onCancelRequested={() => onCancelRequestedWithRender(item)}>
      <BulkOperationsStatus />
    </BulkOperationJobContext>
  );

  const getViewDetails = ({item}) => {
    const fileName = item.inputFileName;

    return (
      <BulkOperationJobContext
        detailFn={detailsNavFn}
        downloadResultsFn={onDownloadResults}
        job={item}
        licenseDeficitHrefFn={licenseDeficitHrefFn}
        onCancelRequested={() => onCancelRequestedWithRender(item)}
      >
        <ViewDetailsDrawerTrigger
          tooltipText={intl.formatMessage(
            {id: 'bulkOperations.results.table.column.operation.tooltip'},
            {fileName}
          )}
        >
          {() => <BulkOperationDetailsDrawer />}
        </ViewDetailsDrawerTrigger>
      </BulkOperationJobContext>
    );
  };

  const renderers = {
    file: getFile,
    initiatedBy: getInitiatedBy,
    operation: getOperation,
    started: getStarted,
    status: getStatus,
    viewDetails: getViewDetails,
  };

  return (
    <TableSectionTable
      aria-label={intl.formatMessage({id: 'bulkOperations.results.table.ariaLabel'})}
      columnDescriptor={columnDescriptor}
      columnNamespace="bulkOperations.results.table.column"
      noItemsFoundContentMessage={intl.formatMessage({
        id: 'bulkOperations.results.table.noItemsFoundContentMessage',
      })}
      renderers={renderers}
    />
  );
};

BulkOperationsListTable.displayName = 'BulkOperationsListTable';

BulkOperationsListTable.propTypes = {
  /**
   * A method that takes a jobId as a property within an object as an argument to generate
   * the desired href link to the job details page.
   */
  detailsHrefFn: PropTypes.func,
  /**
   * A method that takes a jobId as a property within an object as an argument go to the desired page.
   */
  detailsNavFn: PropTypes.func,
  /**
   * A method that takes a job id as an argument to download the results for that id
   */
  downloadFn: PropTypes.func,
  /**
   * A method that takes a jobId as a property within an object as an argument to generate desired href link to
   * the license deficit page.
   */
  licenseDeficitHrefFn: PropTypes.func,
  /**
   * A callback method signaling canceling a job has completed
   */
  onCancelRequested: PropTypes.func,
};

export default BulkOperationsListTable;
