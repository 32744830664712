import {feature} from '@admin-tribe/acsc';

import {CREATE_PACKAGE_CONSTANTS} from 'features/packages/packagesConstants';
import PackagesEntitlements from 'features/packages/services/PackagesEntitlementsService';
import PackagesPreferences from 'features/packages/services/PackagesPreferencesService';

/* utility function that determines whether or not to show mangement options block* */
const showManagementOptionsBlock = () =>
  PackagesEntitlements.hasCcEntitlement || PackagesEntitlements.hasDcOnlyEntitlement;

/* utility function that determines if ausst override is available * */
const isAusstOverrideAvailable = (packageCreateObj) =>
  !!PackagesPreferences.getAusstXml(packageCreateObj.platform);

/* utility function that decides whether or not to show beta app download option* */
const showBetaAppDownloadOption = (pkgSessionSettings) =>
  (PackagesEntitlements.hasCcEntitlement || PackagesEntitlements.hasSubstanceNamedEntitlement) &&
  (pkgSessionSettings.packagingMode === CREATE_PACKAGE_CONSTANTS.NUELL_PACKAGE_MODE ||
    pkgSessionSettings.packagingMode === CREATE_PACKAGE_CONSTANTS.NAMED_PACKAGE_TYPE);

/* utility function that decides whether or not to show extensions options* */
const shouldShowExtensionOptions = (platform) => {
  if (
    PackagesEntitlements.hasCcEntitlement ||
    (feature.isEnabled('temp_packages_acrobat_only_flow') && PackagesEntitlements.hasDcEntitlement)
  ) {
    if (platform === CREATE_PACKAGE_CONSTANTS.MAC_ARM_PLATFORM) {
      return true;
    }
    if (platform === CREATE_PACKAGE_CONSTANTS.WIN_ARM_PLATFORM) {
      return feature.isEnabled('packages_extensions_winarm');
    }
    return true;
  }
  return false;
};

export {
  shouldShowExtensionOptions,
  showManagementOptionsBlock,
  isAusstOverrideAvailable,
  showBetaAppDownloadOption,
};
