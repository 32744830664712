import {User, log} from '@admin-tribe/acsc';
import {DetailSection, MoreOptionsMenu, formatFileSize} from '@admin-tribe/acsc-ui';
import {Content, DialogContainer, Header, Heading, View} from '@adobe/react-spectrum';
import {Drawer} from '@pandora/react-drawer';
import {fetchReadableUnitsOfStorage} from '@pandora/react-formatted-file-size';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import UserFolder from 'common/services/user-folder/UserFolder';
import {USER_FOLDER_STATE} from 'common/services/user-folder/userFolderConstants';

import EditFolderAccessModal from '../edit-folder-access-modal/EditFolderAccessModal';
import LinksList from '../links-list/LinksList';
import getNamesOfOwners from '../utils/getNamesOfOwners';

/**
 * SharedUserFolderDetailsDrawer will be displayed in all cases where the user is no longer part of the current organization
 * and after deletion of the user, the folder was shared with other users or no users at all.
 */
const SharedUserFolderDetailsDrawer = ({selectedFolder}) => {
  const intl = useIntl();

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);

  const {owners, quota} = selectedFolder;

  const consumed = fetchReadableUnitsOfStorage(quota.consumed, 1);

  // eslint-disable @admin-tribe/admin-tribe/comment-side-effects -- update zipAssigned users
  useEffect(() => {
    const refreshFolderUsers = async () => {
      try {
        await selectedFolder.getFolderZipProgress();
        selectedFolder.zipAssignedUsers = selectedFolder.zipAssignedUsers.map(
          (user) => new User(user)
        );
      } catch (error) {
        log.error('Failed to update zipAssignedUsers. Error: ', error);
      } finally {
        setIsLoading(false);
        setIsUpdating(false);
      }
    };
    refreshFolderUsers();
  }, [isUpdating, selectedFolder]);

  // The description is shown when the folder state is ACTIVE, which is a
  // transient state while the owner folder owner deletion is processed
  // OR when the storage folders does not have any zipAssignedUsers
  // OR zipAssignedUsers is an array of 0 length
  const showDescription =
    selectedFolder.state === USER_FOLDER_STATE.ACTIVE ||
    !selectedFolder.zipAssignedUsers ||
    selectedFolder.zipAssignedUsers.length === 0;

  // This is the state when we show the Edit Folder Access button, so that
  // admins can edit the users who have access to that Individual storage folder
  // The folder needs to be in a DISCARDED state and have zipAssignedUsers
  const showEditFolderAccessButton =
    selectedFolder.state === USER_FOLDER_STATE.DISCARDED &&
    selectedFolder.zipAssignedUsers !== undefined;

  // When we have an ACTIVE folder, with zipAssignedUsers that is an array of 0 length
  const showNoUserProcessingMessage =
    selectedFolder.state === USER_FOLDER_STATE.ACTIVE &&
    selectedFolder.zipAssignedUsers?.length === 0;

  // For all folders in ACTIVE state we show this message
  const showProcessingMessage = selectedFolder.state === USER_FOLDER_STATE.ACTIVE;

  const getDescription = () => {
    if (selectedFolder.state === USER_FOLDER_STATE.DISCARDED) {
      return intl.formatMessage({
        id:
          selectedFolder.zipAssignedUsers === undefined
            ? 'storage.sharedUserFolderDetailsDrawer.error'
            : 'storage.sharedUserFolderDetailsDrawer.noUser',
      });
    }
    return intl.formatMessage({id: 'storage.sharedUserFolderDetailsDrawer.description'});
  };

  const editFoldersMenu = (editFolderAccessText) => (
    <MoreOptionsMenu
      menuItems={[
        {
          body: editFolderAccessText,
          key: 'edit-folder',
          value: editFolderAccessText,
        },
      ]}
      onItemSelected={() => setIsOpen(true)}
    />
  );

  const closeModal = () => setIsOpen(false);

  const onEditFolderSuccess = () => {
    // Trigger the onFolderAccessEdit callback, so that the upper component knows the folder access
    // has been edited and can fetch again zipAssignedUsers
    setIsUpdating(true);
    closeModal();
  };

  return (
    <>
      <Drawer aria-labelledby="shared-user-folder-detail" isLoading={isLoading}>
        <Header>
          <Heading
            id="shared-user-folder-detail"
            level={2}
            marginBottom="size-125"
            marginTop="size-0"
          >
            {getNamesOfOwners(intl, owners)}
          </Heading>
        </Header>

        <Content>
          <View padding="size-10">
            <DetailSection
              rightHeaderContent={
                showEditFolderAccessButton
                  ? editFoldersMenu(
                      intl.formatMessage({
                        id: 'storage.sharedUserFolderDetailsDrawer.editFolderAccess',
                      })
                    )
                  : null
              }
              title={intl.formatMessage({
                id: 'storage.sharedUserFolderDetailsDrawer.title',
              })}
            >
              {showDescription && (
                <View id="description-view" marginBottom="size-125">
                  {getDescription()}
                </View>
              )}

              {selectedFolder.zipAssignedUsers && (
                <LinksList
                  showProcessingMessage={showProcessingMessage}
                  users={selectedFolder.zipAssignedUsers}
                />
              )}
              {showNoUserProcessingMessage && (
                <View id="no-user-view">
                  {intl.formatMessage({
                    id: 'storage.sharedUserFolderDetailsDrawer.processing.empty',
                  })}
                </View>
              )}
            </DetailSection>
            <DetailSection
              title={intl.formatMessage({id: 'storage.userFolderDetailsDrawer.size.title'})}
            >{`${formatFileSize(intl, consumed)}`}</DetailSection>
          </View>
        </Content>
      </Drawer>
      <DialogContainer>
        {isOpen && (
          <EditFolderAccessModal
            folder={selectedFolder}
            onCancel={closeModal}
            onError={closeModal}
            onSuccess={onEditFolderSuccess}
          />
        )}
      </DialogContainer>
    </>
  );
};

SharedUserFolderDetailsDrawer.propTypes = {
  /**
   * The selectedFolder property contains all the folder info needed in the drawer
   */
  selectedFolder: PropTypes.instanceOf(UserFolder).isRequired,
};

export default SharedUserFolderDetailsDrawer;
