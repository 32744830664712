/* eslint-disable max-lines -- temp code should be removed once feature is merged */

import {
  LicenseGroup,
  UserGroupLicenseGroupsList,
  feature,
  getProductListWithLicenseGroupSummariesIfSafe,
  log,
  toBinkyLicenseGroup,
  toBinkyProduct,
  toPandoraLicenseGroup,
} from '@admin-tribe/acsc';
import binkyUI from '@admin-tribe/acsc-ui';
import {Heading, View} from '@adobe/react-spectrum';
import {AssignmentLicenseLimitProvider, ITEM_TYPE} from '@pandora/react-assignment-modal';
import {
  AssignmentModalSectionContentModel,
  AssignmentModalSection as AssignmentModalSectionPandora,
  AssignmentSectionContext as AssignmentSectionContextPandora,
} from '@pandora/react-assignment-section';
import {useContentEntry} from '@pandora/react-content-provider';
import {ProductRoleContext, ProductRoleMemberContext} from '@pandora/react-mini-cards';
import UserGroupIcon from '@react/react-spectrum/Icon/UserGroup';
import Provider from '@react/react-spectrum/Provider';
import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useIntl} from 'react-intl';

import {transformToPandoraProductsList} from 'common/utils/products-utils/getPandoraProducts';
import {getProductsAssignableToUsers} from 'common/utils/userProductUtils';
import {addTagsToProducts} from 'core/products/utils/productTagUtils';
import {getContractDisplayNames} from 'core/products/utils/productUtils';

const {ModalDescription, ModalContainer, ModalContent, ModalDialog, ModalHeading, ModalTagHeader} =
  binkyUI.common.components.modal;

const AssignmentSection = binkyUI.common.components.AssignmentSection;
const AssignmentSectionContext = binkyUI.common.components.AssignmentSectionContext;
const {toPandoraProductTargets} = binkyUI.common.components.assignmentSectionUtils;

const GoUrl = binkyUI.common.components.GoUrl;

const AssignProductProfilesToUserGroupsModal = ({
  id = 'assign-product-profiles-to-user-groups-modal',
  isOpen,
  onClose,
  onSuccess,
  orgId,
  userGroup,
}) => {
  const intl = useIntl();

  const [products, setProducts] = useState();
  const [pandoraProducts, setPandoraProducts] = useState([]);
  const targets = [ITEM_TYPE.PRODUCT_PROFILES, ITEM_TYPE.PRODUCTS];
  const [userGroupLicenseGroupsList, setUserGroupLicenseGroupsList] = useState();
  const [isSavingProfileToUserGroup, setIsSavingProfileToUserGroup] = useState(false);

  const [isLoadingProductsAndProfiles, setIsLoadingProductsAndProfiles] = useState(false);

  const [pandoraItemsToPreselect, setPandoraItemsToPreselect] = useState(undefined);
  const [assignedItemCount, setAssignedItemCount] = useState(0);
  const [itemsToPreselect, setItemsToPreselect] = useState([]);
  const [modalError, setModalError] = useState(null);
  const isMountedRef = useRef(true);
  const productTargets = toPandoraProductTargets(targets);
  const binkyItemMap = useRef({
    [ITEM_TYPE.PRODUCTS]: [],
    [ITEM_TYPE.PRODUCT_PROFILES]: [],
    [ITEM_TYPE.USER_GROUPS]: [],
  });

  const content = useContentEntry(AssignmentModalSectionContentModel);

  const showModalError = useCallback(() => {
    setModalError(intl.formatMessage({id: 'common.modal.error.generic'}));
  }, [intl]);

  // get pandora products with Contract display name
  useEffect(() => {
    if (products) {
      setPandoraProducts(transformToPandoraProductsList(products));
    } else {
      setPandoraProducts([]);
    }
  }, [products]);

  const onCta = async () => {
    let isSuccess = false;
    try {
      setIsSavingProfileToUserGroup(true);
      await userGroupLicenseGroupsList.save();
      onSuccess();
      isSuccess = true;
    } catch (error) {
      log.error('Failed to save user group. Error: ', error);
      // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- sathiyam@ to update
      // istanbul ignore else
      if (isMountedRef.current) {
        setModalError(intl.formatMessage({id: 'common.modal.error.generic'}));
      }
    } finally {
      // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- sathiyam@ to update
      // istanbul ignore else
      if (isMountedRef.current) {
        setIsSavingProfileToUserGroup(false);
      }
    }
    return isSuccess;
  };

  const ctaToastGenerator = () => intl.formatMessage({id: 'common.toast.modal.userGroupUpdated'});

  const onItemAssignmentOld = (assignedItemsList) => {
    // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- cawright@ to update
    // istanbul ignore else
    if (userGroupLicenseGroupsList) {
      setAssignedItemCount(assignedItemsList.length);

      const profilesToRemove = [];
      userGroupLicenseGroupsList.items?.forEach((item) => {
        if (!assignedItemsList.find((profile) => profile.id === item.id)) {
          profilesToRemove.push(item);
        }
      });
      if (profilesToRemove.length > 0) {
        userGroupLicenseGroupsList.remove(profilesToRemove);
      }
      assignedItemsList?.forEach((item) => {
        const existingProfile = userGroupLicenseGroupsList.items.find(
          (profile) => profile.id === item.id
        );

        const licenseGroup = cloneDeep(item);
        if (!existingProfile) {
          userGroupLicenseGroupsList.add([licenseGroup]);
        }
      });
    }
  };

  const onItemAssignment = useCallback(
    (assignedItemsList) => {
      // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- cawright@ to update
      // istanbul ignore else
      if (userGroupLicenseGroupsList) {
        setAssignedItemCount(assignedItemsList.length);

        const profilesToRemove = [];
        userGroupLicenseGroupsList.items?.forEach((item) => {
          // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- cawright@ to update
          // istanbul ignore else
          if (!assignedItemsList.find((profile) => profile.id === item.id)) {
            profilesToRemove.push(item);
          }
        });
        // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- cawright@ to update
        // istanbul ignore else
        if (profilesToRemove.length > 0) {
          userGroupLicenseGroupsList.remove(profilesToRemove);
        }
        assignedItemsList?.forEach((item) => {
          const existingProfile = userGroupLicenseGroupsList.items.find(
            (profile) => profile.id === item.id
          );

          const licenseGroup = cloneDeep(item);
          // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- cawright@ to update
          // istanbul ignore else
          if (!existingProfile) {
            userGroupLicenseGroupsList.add([licenseGroup]);
          }
        });
      }
    },
    [userGroupLicenseGroupsList, setAssignedItemCount]
  );

  const productAssignmentModalOnChange = useCallback(
    (itemMap) => {
      if (itemMap && itemMap[ITEM_TYPE.PRODUCTS] && itemMap[ITEM_TYPE.PRODUCT_PROFILES]) {
        const combinedItemMap = {
          ...binkyItemMap.current,
          [ITEM_TYPE.PRODUCTS]: itemMap[ITEM_TYPE.PRODUCTS].map((product) =>
            toBinkyProduct(product)
          ),
          [ITEM_TYPE.PRODUCT_PROFILES]: itemMap[ITEM_TYPE.PRODUCT_PROFILES].map((profile) =>
            toBinkyLicenseGroup(profile)
          ),
        };

        binkyItemMap.current = combinedItemMap;
        onItemAssignment([
          ...combinedItemMap[ITEM_TYPE.PRODUCTS],
          ...combinedItemMap[ITEM_TYPE.PRODUCT_PROFILES],
          ...combinedItemMap[ITEM_TYPE.USER_GROUPS],
        ]);
      }
    },
    [onItemAssignment]
  );

  const processDefaultItems = () => {
    const mappedRoles = {
      [ITEM_TYPE.PRODUCT_PROFILES]: userGroupLicenseGroupsList.items?.map((licenseGroup) => {
        const product = products.find((p) => p.id === licenseGroup.product.id);
        return new LicenseGroup({...licenseGroup, product});
      }),
    };

    return mappedRoles;
  };

  // eslint-disable @admin-tribe/admin-tribe/comment-side-effects -- sathiyam@ to update
  useEffect(() => {
    isMountedRef.current = true;

    if (isOpen) {
      const fetchProducts = async () => {
        setIsLoadingProductsAndProfiles(true);
        try {
          // Calling APIs and then awaiting the promises
          const userGroupPromise = userGroup.refresh();
          const userGroupLicenseGroupsPromise = UserGroupLicenseGroupsList.get({
            groupId: userGroup.id,
            orgId,
          });

          const productListPromise = await getProductListWithLicenseGroupSummariesIfSafe(orgId);

          await userGroupPromise;
          const userGroupLicenseGroups = await userGroupLicenseGroupsPromise;

          const productList = await productListPromise;

          let assignableProducts = getProductsAssignableToUsers(productList);

          // Team products cannot be assigned to user groups
          assignableProducts = assignableProducts.filter((product) => !product.isTeam());

          userGroupLicenseGroups.items = userGroupLicenseGroups.items.filter(
            (licenseGroup) => licenseGroup.administerable
          );
          // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- sathiyam@ to update
          // istanbul ignore else
          if (isMountedRef.current) {
            setUserGroupLicenseGroupsList(userGroupLicenseGroups);
            addTagsToProducts(intl, productList);
            setProducts(assignableProducts);
          }
        } catch (error) {
          log.error('Failed to get products and profiles. Error: ', error);
          // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- sathiyam@ to update
          // istanbul ignore else
          if (isMountedRef.current) {
            showModalError();
          }
        }
        // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- sathiyam@ to update
        // istanbul ignore else
        if (isMountedRef.current) {
          setIsLoadingProductsAndProfiles(false);
        }
      };

      fetchProducts();
    } else {
      setModalError(null);
      setIsLoadingProductsAndProfiles(false);
      setItemsToPreselect([]);
      setPandoraItemsToPreselect([]);
      setProducts(null);
      setUserGroupLicenseGroupsList(null);
      setAssignedItemCount(0);
    }
    return () => {
      isMountedRef.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run on isOpen change
  }, [isOpen]);

  const pandoraAssignmentSectionValue = useMemo(
    () => ({
      itemsToPreselect: pandoraItemsToPreselect,
      products: pandoraProducts,
      setItemsToPreselect: setPandoraItemsToPreselect,
    }),
    [pandoraItemsToPreselect, pandoraProducts]
  );

  // Generate the AssignmentModal's "preselectedItems" and "disabledItems" from the given parameters
  useEffect(() => {
    if (products && userGroupLicenseGroupsList) {
      setAssignedItemCount(processDefaultItems()?.productProfiles.length);
      setItemsToPreselect(processDefaultItems());

      if (feature.isEnabled('temp_add_contract_display_name')) {
        const preselectedItems = processDefaultItems();
        // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- cawright@ to update
        // istanbul ignore else
        if (preselectedItems) {
          setPandoraItemsToPreselect({
            // "Products" are just ID strings in this format so they don't need conversion.
            [ITEM_TYPE.PRODUCTS]: preselectedItems[ITEM_TYPE.PRODUCTS],
            [ITEM_TYPE.PRODUCT_PROFILES]: preselectedItems[ITEM_TYPE.PRODUCT_PROFILES]?.map(
              (licenseGroup) => {
                licenseGroup.product.contractDisplayNames = getContractDisplayNames(
                  licenseGroup.product?.contractIds
                );
                return toPandoraLicenseGroup(licenseGroup);
              }
            ),
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run once products changes
  }, [products, userGroupLicenseGroupsList]);
  const productRoleValue = useMemo(() => ({onError: showModalError}), [showModalError]);

  const productRoleMemberValue = useMemo(() => ({member: userGroup}), [userGroup]);

  const assignmentSectionValue = useMemo(
    () => ({
      itemsToPreselect,
      products,
      setItemsToPreselect,
    }),
    [itemsToPreselect, products, setItemsToPreselect]
  );

  return (
    <ModalContainer>
      {isOpen && (
        <ModalDialog
          cancelLabel={intl.formatMessage({
            id: 'common.modal.buttons.cancel',
          })}
          ctaLabel={intl.formatMessage({
            id: 'common.modal.buttons.save',
          })}
          ctaToastGenerator={ctaToastGenerator}
          errorMessage={modalError}
          heightVariant="static"
          id={id}
          isLoading={isSavingProfileToUserGroup || isLoadingProductsAndProfiles}
          onCancel={onClose}
          onCta={onCta}
        >
          <ModalHeading>
            {intl.formatMessage({
              id: 'userGroups.assignProductProfilesToUserGroupsModal.title',
            })}
          </ModalHeading>
          <ModalTagHeader IconComponent={<UserGroupIcon alt="" size="S" />}>
            {userGroup.name}
          </ModalTagHeader>
          <ModalDescription>
            {intl.formatMessage(
              {id: 'userGroups.assignProductProfilesToUserGroupsModal.description'},
              {
                goUrl: (str) => <GoUrl name="aac_learn_more_usergroups">{str}</GoUrl>,
              }
            )}
          </ModalDescription>
          <ModalContent>
            <Heading
              data-testid="assigned-item-count"
              level={3}
              marginBottom="size-150"
              marginTop="size-50"
            >
              {intl.formatMessage(
                {id: 'common.AssignProductProfilesToUserGroupsModal.assigned'},
                {count: assignedItemCount}
              )}
            </Heading>
            <View marginBottom="size-200">
              {feature.isEnabled('temp_add_contract_display_name') ? (
                <AssignmentLicenseLimitProvider value={{products: pandoraProducts}}>
                  <AssignmentSectionContextPandora.Provider
                    // We cannot convert Binky Products to Pandora Products here, this gets run
                    // every render, and the conversion is too heavy-weight.
                    value={pandoraAssignmentSectionValue}
                  >
                    <ProductRoleContext.Provider value={productRoleValue}>
                      <ProductRoleMemberContext.Provider value={productRoleMemberValue}>
                        <AssignmentModalSectionPandora
                          content={content}
                          data-testid="assignment-modal-sections-pandora"
                          onChange={productAssignmentModalOnChange}
                          orgId={orgId}
                          showButtonLabel
                          targets={productTargets}
                        />
                      </ProductRoleMemberContext.Provider>
                    </ProductRoleContext.Provider>
                  </AssignmentSectionContextPandora.Provider>
                </AssignmentLicenseLimitProvider>
              ) : (
                <AssignmentSectionContext.Provider value={assignmentSectionValue}>
                  <Provider>
                    <AssignmentSection
                      onChange={onItemAssignmentOld}
                      orgId={orgId}
                      showButtonLabel
                      targets={[ITEM_TYPE.PRODUCT_PROFILES, ITEM_TYPE.PRODUCTS]}
                    />
                  </Provider>
                </AssignmentSectionContext.Provider>
              )}
            </View>
          </ModalContent>
        </ModalDialog>
      )}
    </ModalContainer>
  );
};

AssignProductProfilesToUserGroupsModal.propTypes = {
  id: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  orgId: PropTypes.string.isRequired,
  userGroup: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    refresh: PropTypes.func,
  }).isRequired,
};

export default AssignProductProfilesToUserGroupsModal;
/* eslint-enable max-lines -- temp code should be removed once feature is merged */
