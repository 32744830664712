import get from 'lodash/get';

import {setProperty} from '../aepAnalytics';

/**
 *
 * @param {*} descriptor
 */
export default function organizationEventTranslator(descriptor) {
  setProperty('organization.assignedLicenseVariation', get(descriptor, 'assignedLicenseVariation'));
  setProperty('organization.totalUserCount', get(descriptor, 'totalCount'));
  setProperty('organization.userCountVariation', get(descriptor, 'userCountVariation'));
}
