import {ConsumableSummarizationList, Product} from '@admin-tribe/acsc';
import PropTypes from 'prop-types';
import React, {createContext, useContext, useMemo} from 'react';

const ProductProfileDetailsContext = createContext({});

/**
 * @description A hook to get the EditProductProfilePermissionsContext values.
 * @returns EditProductProfilePermissionsContext
 */
const useProductProfileDetailsContext = () => useContext(ProductProfileDetailsContext);

// eslint-disable-next-line @admin-tribe/admin-tribe/one-component-file -- this is one component
const ProductProfileDetailsContextProvider = ({
  children,
  consumableSummarizationList,
  licenseGroupId,
  openAutoAssignmentRuleModal,
  product,
  summaryItems,
}) => {
  const value = useMemo(
    () => ({
      children,
      consumableSummarizationList,
      licenseGroupId,
      openAutoAssignmentRuleModal,
      product,
      summaryItems,
    }),
    [
      children,
      consumableSummarizationList,
      licenseGroupId,
      openAutoAssignmentRuleModal,
      product,
      summaryItems,
    ]
  );
  return (
    <ProductProfileDetailsContext.Provider value={value}>
      {children}
    </ProductProfileDetailsContext.Provider>
  );
};

ProductProfileDetailsContextProvider.propTypes = {
  /**
   * The content to be passed through the content provider.
   */
  children: PropTypes.node.isRequired,
  /**
   * A ConsumableSummarizationList instance
   */
  consumableSummarizationList: PropTypes.instanceOf(ConsumableSummarizationList),
  /**
   * The license group id
   */
  licenseGroupId: PropTypes.string.isRequired,
  /**
   * Callback function to open the Auto Assignment modal
   */
  openAutoAssignmentRuleModal: PropTypes.func.isRequired,
  /**
   * A Product instance
   */
  product: PropTypes.instanceOf(Product).isRequired,
  /**
   * The list of summary items to display
   */
  summaryItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })
  ).isRequired,
};

export {ProductProfileDetailsContextProvider, useProductProfileDetailsContext};
