import {feature} from '@admin-tribe/acsc';
import {Divider, Flex, Item, Picker, Switch, Text, View} from '@adobe/react-spectrum';
import {TooltipButton} from '@pandora/react-tooltip-button';
import InfoOutlineIcon from '@spectrum-icons/workflow/InfoOutline';
import isEmpty from 'lodash/isEmpty';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';
import {isMacTypePlatform} from 'features/packages/adminToolsUtils';
import {
  COLD_FUSION_SAP_CODE,
  CREATE_PACKAGE_CONSTANTS,
  PACKAGE_TYPE_CONSTANTS,
} from 'features/packages/packagesConstants';
import PackagesAdobeProductsUi from 'features/packages/services/PackagesAdobeProductsUiService';
import PackagesEntitlements from 'features/packages/services/PackagesEntitlementsService';
import PackagesIngestAnalytics from 'features/packages/services/PackagesIngestAnalyticsService';
import PackagesPreferencesService from 'features/packages/services/PackagesPreferencesService';

import {useCreatePackageModalContext} from '../../CreatePackageModalContext';
import './SystemConfigPage.pcss';
import FlatPackageCheckbox from '../../common-components/FlatPackageCheckbox';

const SystemConfigPage = observer(({platformPickerAutoFocus = false}) => {
  const intl = useIntl();
  const {createPackageModalStore} = useCreatePackageModalContext();
  const {
    packageCreateObj,
    pkgSessionSettings,
    setPackageCreateObjValues,
    setPkgSessionSettingValues,
    updateCreatePackageButtonStatus,
    isColdFusionEntitlementSelected,
  } = createPackageModalStore;

  const supportedPlatformList = getSupportedPlatformList();

  // Change nextButtonEnabled value based on platform value set
  useEffect(() => {
    setPkgSessionSettingValues('nextButtonEnabled', !!packageCreateObj.platform);
  }, [packageCreateObj, setPkgSessionSettingValues]);

  const templateLocaleListObj = rootStore.packagesUiConstantsStore.packageLocaleSelectionList.map(
    (locale) => ({
      label: intl.formatMessage({
        id: `packages.locales.${locale}`,
      }),
      value: locale,
    })
  );

  function showPlatformSelectionOption(platform, is32Bit) {
    if (isColdFusionEntitlementSelected && platform === CREATE_PACKAGE_CONSTANTS.LINUX_PLATFORM) {
      return !isEmpty(
        rootStore.packagesUiConstantsStore.nonFfcProductMap?.[COLD_FUSION_SAP_CODE]?.linux
      );
    }
    if (pkgSessionSettings.isTemplatizedPackageCreate) {
      return !isEmpty(
        pkgSessionSettings.template.getPackagedAppsForCustomization(platform, is32Bit)
      );
    }

    if (PackagesEntitlements.hasDcOnlyEntitlement) {
      return PackagesAdobeProductsUi.getProductsForPlatform(
        platform,
        is32Bit ? CREATE_PACKAGE_CONSTANTS.THIRTY_TWO_BIT : CREATE_PACKAGE_CONSTANTS.SIXTY_FOUR_BIT
      ).some((product) => product.sapCode === 'APRO' && !product.archive);
    }

    return !isEmpty(
      PackagesAdobeProductsUi.getProductsForPlatform(
        platform,
        is32Bit ? CREATE_PACKAGE_CONSTANTS.THIRTY_TWO_BIT : CREATE_PACKAGE_CONSTANTS.SIXTY_FOUR_BIT
      )
    );
  }

  function getSupportedPlatformList() {
    const platforms = [];

    if (showPlatformSelectionOption(CREATE_PACKAGE_CONSTANTS.MACU_PLATFORM, false)) {
      platforms.push({
        id: CREATE_PACKAGE_CONSTANTS.MACU_PLATFORM,
        name: intl.formatMessage({
          id: 'packages.createPackage.systemConfig.platforms.macUniversal',
        }),
      });
    }

    if (showPlatformSelectionOption(CREATE_PACKAGE_CONSTANTS.MAC_PLATFORM, false)) {
      platforms.push({
        id: CREATE_PACKAGE_CONSTANTS.MAC_PLATFORM,
        name: intl.formatMessage({
          id: 'packages.createPackage.systemConfig.platforms.macIntel',
        }),
      });
    }

    if (showPlatformSelectionOption(CREATE_PACKAGE_CONSTANTS.MAC_ARM_PLATFORM, false)) {
      platforms.push({
        id: CREATE_PACKAGE_CONSTANTS.MAC_ARM_PLATFORM,
        name: intl.formatMessage({
          id: 'packages.createPackage.systemConfig.platforms.macArm',
        }),
      });
    }

    if (showPlatformSelectionOption(CREATE_PACKAGE_CONSTANTS.WIN_PLATFORM, false)) {
      platforms.push({
        id: String.prototype.concat(
          CREATE_PACKAGE_CONSTANTS.WIN_PLATFORM,
          CREATE_PACKAGE_CONSTANTS.SIXTY_FOUR_BIT
        ),
        name: intl.formatMessage({
          id: 'packages.createPackage.systemConfig.platforms.win64',
        }),
      });
    }

    if (showPlatformSelectionOption(CREATE_PACKAGE_CONSTANTS.WIN_PLATFORM, true)) {
      platforms.push({
        id: String.prototype.concat(
          CREATE_PACKAGE_CONSTANTS.WIN_PLATFORM,
          CREATE_PACKAGE_CONSTANTS.THIRTY_TWO_BIT
        ),
        name: intl.formatMessage({
          id: 'packages.createPackage.systemConfig.platforms.win32',
        }),
      });
    }

    if (showPlatformSelectionOption(CREATE_PACKAGE_CONSTANTS.WIN_ARM_PLATFORM, false)) {
      platforms.push({
        id: CREATE_PACKAGE_CONSTANTS.WIN_ARM_PLATFORM,
        name: intl.formatMessage({
          id: 'packages.createPackage.systemConfig.platforms.winArm',
        }),
      });
    }

    if (showPlatformSelectionOption(CREATE_PACKAGE_CONSTANTS.LINUX_PLATFORM, false)) {
      platforms.push({
        id: CREATE_PACKAGE_CONSTANTS.LINUX_PLATFORM,
        name: intl.formatMessage({
          id: 'packages.createPackage.systemConfig.platforms.linux',
        }),
      });
    }
    return platforms;
  }

  function setPreferencesBasedSettings(selectedOption) {
    const platform = isMacTypePlatform(selectedOption)
      ? CREATE_PACKAGE_CONSTANTS.MAC_PLATFORM
      : CREATE_PACKAGE_CONSTANTS.WIN_PLATFORM;
    const preferencesProvider = PackagesPreferencesService;
    setPkgSessionSettingValues(
      'userInstallDirectoryAvailable',
      !!preferencesProvider.getUserInstallDirectory(platform)
    );
    if (!preferencesProvider.getUserInstallDirectory(platform)) {
      setPackageCreateObjValues('userInstallDirectory', false);
    }
    setPkgSessionSettingValues(
      'ausstOverrideAvailable',
      !preferencesProvider.getAusstXml(
        isMacTypePlatform(selectedOption)
          ? CREATE_PACKAGE_CONSTANTS.PACKAGE_CONSTANTS_MAC_PLATFORM
          : CREATE_PACKAGE_CONSTANTS.PACKAGE_CONSTANTS_WIN_PLATFORM
      )
    );
    if (
      !preferencesProvider.getAusstXml(
        isMacTypePlatform(selectedOption)
          ? CREATE_PACKAGE_CONSTANTS.PACKAGE_CONSTANTS_MAC_PLATFORM
          : CREATE_PACKAGE_CONSTANTS.PACKAGE_CONSTANTS_WIN_PLATFORM
      )
    ) {
      setPackageCreateObjValues('ausstOverride', false);
    }
  }

  function onPlatformChange(selectedOption) {
    if (
      isMacTypePlatform(selectedOption) ||
      selectedOption === CREATE_PACKAGE_CONSTANTS.WIN_ARM_PLATFORM
    ) {
      setPackageCreateObjValues('platform', selectedOption);
      setPackageCreateObjValues('bits', CREATE_PACKAGE_CONSTANTS.SIXTY_FOUR_BIT);
    } else if (selectedOption === CREATE_PACKAGE_CONSTANTS.LINUX_PLATFORM) {
      setPackageCreateObjValues('platform', CREATE_PACKAGE_CONSTANTS.LINUX_PLATFORM);
    } else {
      setPackageCreateObjValues('platform', CREATE_PACKAGE_CONSTANTS.WIN_PLATFORM);
      setPackageCreateObjValues(
        'bits',
        selectedOption.includes(CREATE_PACKAGE_CONSTANTS.THIRTY_TWO_BIT)
          ? CREATE_PACKAGE_CONSTANTS.THIRTY_TWO_BIT
          : CREATE_PACKAGE_CONSTANTS.SIXTY_FOUR_BIT
      );
    }

    if (feature.isEnabled('temp_packages_flat_templates') && isMacTypePlatform(selectedOption)) {
      setPackageCreateObjValues('flatPackageEnabled', true);
    } else {
      setPackageCreateObjValues('flatPackageEnabled', undefined);
    }

    // Required in case of config screen
    setPkgSessionSettingValues('nextButtonEnabled', true);
    // Required in case of self serve screen, disable based on validity of packagename
    updateCreatePackageButtonStatus();

    setPreferencesBasedSettings(selectedOption);
    handleFixedLicense();
    if (PackagesIngestAnalytics.shouldSendIngestAnalytics(pkgSessionSettings.packagingMode)) {
      sendPlatformIngestAnalytics(`${packageCreateObj.platform}/${packageCreateObj.bits}`);
    }
  }

  function handleFixedLicense() {
    setPkgSessionSettingValues('enableCustomDNS', false);
    setPkgSessionSettingValues('customDNS', {});
    setPkgSessionSettingValues('adobeLicenseFixedDns', true);
    setPkgSessionSettingValues('adobeLicenseServerFixedIp', false);
  }

  function onLanguageChange(languageCode) {
    setPackageCreateObjValues('locale', languageCode);
    if (PackagesIngestAnalytics.shouldSendIngestAnalytics(pkgSessionSettings.packagingMode)) {
      PackagesIngestAnalytics.triggerAnalytics({
        content: languageCode,
        subCategory: PackagesIngestAnalytics.getIngestSubcategory(
          pkgSessionSettings.packageType,
          'Language'
        ),
        subType: 'language',
        type: 'click',
      });
    }
  }

  function sendPlatformIngestAnalytics(platformStr) {
    PackagesIngestAnalytics.triggerAnalytics({
      content: platformStr,
      subCategory: PackagesIngestAnalytics.getIngestSubcategory(
        pkgSessionSettings.packageType,
        'Platform'
      ),
      subType: 'platform',
      type: 'click',
    });
  }

  function setOSLangDetection(selectedOption) {
    setPackageCreateObjValues('osLangDetection', selectedOption);
  }

  function getSelectedPlatform() {
    if (
      isMacTypePlatform(packageCreateObj.platform) ||
      packageCreateObj.platform === CREATE_PACKAGE_CONSTANTS.WIN_ARM_PLATFORM
    ) {
      return packageCreateObj.platform;
    }
    if (packageCreateObj.platform === CREATE_PACKAGE_CONSTANTS.WIN_PLATFORM) {
      return String.prototype.concat(CREATE_PACKAGE_CONSTANTS.WIN_PLATFORM, packageCreateObj.bits);
    }
    return undefined;
  }

  return (
    <>
      <Picker
        autoFocus={platformPickerAutoFocus}
        data-testid="platform-picker"
        defaultSelectedKey={getSelectedPlatform()}
        items={supportedPlatformList}
        label={intl.formatMessage({
          id: 'packages.createPackage.systemConfig.platforms.choosePlatform',
        })}
        marginTop="size-125"
        onSelectionChange={onPlatformChange}
        placeholder={intl.formatMessage({
          id: 'packages.createPackage.systemConfig.platforms.selectPlatform',
        })}
      >
        {supportedPlatformList.map((supportedPlatform) => (
          <Item key={supportedPlatform.id}>{supportedPlatform.name}</Item>
        ))}
      </Picker>
      {pkgSessionSettings.packageType === PACKAGE_TYPE_CONSTANTS.SELF_SERVICE && (
        <FlatPackageCheckbox />
      )}
      <Divider marginBottom="size-250" marginTop="size-250" size="M" />
      <Text UNSAFE_style={{fontWeight: 'bold'}}>
        {intl.formatMessage({
          id: 'packages.createPackage.systemConfig.platforms.language',
        })}
      </Text>
      <Flex alignItems="center" direction="row" marginTop="size-125">
        <Switch
          aria-labelledby="use-oslocale-switch-label"
          isEmphasized
          isSelected={packageCreateObj.osLangDetection}
          marginEnd="size-125"
          onChange={setOSLangDetection}
        />
        <View id="use-oslocale-switch-label">
          {intl.formatMessage({
            id: 'packages.createPackage.systemConfig.platforms.useOSLocale',
          })}
        </View>
        <TooltipButton
          aria-label={intl.formatMessage({
            id: 'packages.createPackage.systemConfig.infoOutlineIconText',
          })}
          elementType="span"
          hoverText={intl.formatMessage({
            id: 'packages.createPackage.systemConfig.infoOutlineIconText',
          })}
          isQuiet
          marginEnd="size-100"
          marginStart="size-100"
          variant="action"
        >
          <InfoOutlineIcon />
        </TooltipButton>
        <Picker
          aria-label={intl.formatMessage({
            id: 'packages.createPackage.systemConfig.platforms.language',
          })}
          data-testid="language-picker"
          defaultSelectedKey={packageCreateObj.locale}
          items={templateLocaleListObj}
          onSelectionChange={onLanguageChange}
        >
          {templateLocaleListObj.map((language) => (
            <Item key={language.value}>{language.label}</Item>
          ))}
        </Picker>
      </Flex>
    </>
  );
});

SystemConfigPage.propTypes = {
  /**
   * Whether the platform picker will be auto focused. Defaults to false.
   */
  platformPickerAutoFocus: PropTypes.bool,
};

export default SystemConfigPage;
