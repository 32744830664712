import {formatFileSize} from '@admin-tribe/acsc-ui';
import {Flex, Text, View} from '@adobe/react-spectrum';
import {FileSize} from '@pandora/react-formatted-file-size';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {USAGE_TYPES} from 'common/services/storage-stats/StorageStatsConstants';

/**
 * A component to show the storage usage bar chart and legend.
 * This is a bar graph where each type of storage is represented by a different color and its
 * width is relative to the percentage used of the total available storage.
 *
 * Note that this information is inaccessible to someone using a screen-reader and
 * potentially someone with a sight imparement because the different plans are differentiated
 * by color only (color with pattern is preferred).
 */
const UsageBarChart = ({usageData, tableId}) => {
  const intl = useIntl();
  const borderProps = {borderColor: 'gray-400', borderRadius: 'regular', borderWidth: 'thin'};

  const colorMap = {
    [USAGE_TYPES.REMAINING_USED]: 'gray-600',
    [USAGE_TYPES.SHARED_FOLDERS]: 'blue-400',
    [USAGE_TYPES.UNUSED]: 'gray-75',
    [USAGE_TYPES.USER_FOLDERS]: 'chartreuse-400',
  };

  return (
    <div
      aria-describedby={tableId}
      aria-label={intl.formatMessage({id: 'storage.usageBarSection.chart.ariaLabel'})}
      role="group"
    >
      {/* the chart */}
      <View {...borderProps} data-testid="bar-container" marginY="size-200" width="100%">
        <Flex direction="row">
          {usageData.map((datum, index, arr) => (
            <View
              key={datum.type}
              backgroundColor={colorMap[datum.type]}
              borderColor={borderProps.borderColor}
              /* borders just between the datum to avoid double borders on the ends */
              borderEndWidth={(index < arr.length - 1 && 'thin') || undefined}
              data-testid="bar-datum"
              height="size-400"
              /* width may 0% in which case the div is not visible */
              width={`${datum.percentage}%`}
            />
          ))}
        </Flex>
      </View>

      {/* the legend */}
      <Flex direction="row" rowGap="size-100" wrap>
        {usageData.map((datum) => (
          <React.Fragment key={datum.type}>
            {/* Wrap the datum color block and legend text so they wrap together */}
            <Flex alignItems="center" data-testid="item" marginEnd="size-300">
              <View
                {...borderProps}
                backgroundColor={colorMap[datum.type]}
                borderRadius="xsmall"
                data-testid="symbol"
                elementType="span"
                height="size-200"
                marginEnd="size-100"
                width="size-200"
              />
              <Text>
                <FormattedMessage
                  id={`storage.usageBarSection.type.legend.${datum.type}`}
                  values={{fileSize: formatFileSize(intl, datum.displayAmount)}}
                />
              </Text>
            </Flex>
          </React.Fragment>
        ))}
      </Flex>
    </div>
  );
};

UsageBarChart.propTypes = {
  /**
   * The id for table that is the text equivalent for this bar chart.
   */
  tableId: PropTypes.string.isRequired,
  /**
   * The data for the table.
   */
  usageData: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * The usage to display for the given type of storage.
       */
      displayAmount: PropTypes.instanceOf(FileSize).isRequired,
      /**
       * The numeric percentage of storage used for the given type of storage.
       */
      percentage: PropTypes.number.isRequired,
      /**
       * The type of storage.
       */
      type: PropTypes.oneOf(Object.values(USAGE_TYPES)),
    })
  ).isRequired,
};

export default UsageBarChart;
