import {
  LICENSE_DEV_ADMIN,
  OrganizationAdminList,
  PAGE_TARGET,
  PAGE_TARGET_TYPE,
  PageContext,
} from '@admin-tribe/acsc';
import {
  InfoItemScorecard,
  Page,
  PageContent,
  PageHeader,
  PageInfoBar,
  PageNav,
  useStore,
} from '@admin-tribe/acsc-ui';
import {observer} from 'mobx-react-lite';
import React, {useRef} from 'react';
import {useIntl} from 'react-intl';
import {useParams} from 'react-router-dom';

import AdminListTableSection from 'common/components/admin-list-table-section/AdminListTableSection';
import MemberProductIconList from 'common/components/member-product-icon-list/MemberProductIconList';
import {MemberListContextProvider} from 'common/hooks/member-list-context/MemberListContext';
import useDocumentTitle from 'common/hooks/useDocumentTitle';
import AdminStore from 'common/stores/admin-store/AdminStore';
import {isSelectable} from 'common/utils/member-utils/memberUtils';
import {canAssignDeveloper, canRemoveDeveloper} from 'core/organizations/access/organizationAccess';

/**
 * The Developers top-level page.
 */
const DevelopersPage = observer(() => {
  const {orgId} = useParams();

  useDocumentTitle({key: 'users.developers.documentTitle'});

  const pageContext = useRef(
    new PageContext({
      target: PAGE_TARGET.ORGANIZATION,
      targetId: orgId,
      targetType: PAGE_TARGET_TYPE.DEVELOPER,
    })
  ).current;

  const adminStore = useStore(
    () =>
      new AdminStore({
        immediateLoad: true,
        listClassRef: OrganizationAdminList,
        listOptions: {
          role: LICENSE_DEV_ADMIN,
        },
      })
  );

  const intl = useIntl();

  // This callback may be called with an item that no longer exists so member may be undefined.
  const getIsItemSelectable = (itemKey) =>
    isSelectable({member: adminStore.getItem(itemKey), pageContext});

  return (
    <Page
      data-testid="developers-page"
      isLoading={adminStore.isLoading && !adminStore.currentItems}
    >
      <PageHeader title={intl.formatMessage({id: 'users.developers.title'})} />
      <PageInfoBar>
        <InfoItemScorecard
          label={intl.formatMessage({id: 'users.developers.scorecard.developers'})}
          value={adminStore.scorecardValue}
        />
      </PageInfoBar>
      <PageNav />
      <PageContent>
        <MemberListContextProvider
          canAddMember={canAssignDeveloper()}
          canRemoveMember={canRemoveDeveloper()}
          getIsItemSelectable={getIsItemSelectable}
          memberListDisplay={{
            email: true,
            idType: true,
            productIcons: true,
            viewDetails: true,
          }}
          pageContext={pageContext}
          productIconList={<MemberProductIconList />}
          store={adminStore}
        >
          <AdminListTableSection />
        </MemberListContextProvider>
      </PageContent>
    </Page>
  );
});

export default DevelopersPage;
