import {authentication} from '@admin-tribe/acsc';

/**
 * Returns an object that contains the `Authotization` header.
 *
 * @return {{Authorization: string} | {}} A headers object
 */
const getAuthorizationHeader = () => {
  const accessToken = authentication.getAccessToken();

  // Don't return a header if we don't have the token
  if (!accessToken || !accessToken.token) {
    return {};
  }

  return {
    Authorization: `Bearer ${accessToken.token}`,
  };
};

/**
 * This function returns a serialized and encoded query string based on the key/value pairs in a given object. This
 * is compatible with the axios "paramsSerializer" request config option. This function maps primitives directly to strings,
 * and primitives within arrays are repeated with the same key in the query string (e.g. key=value1&key=value2). Non-primitives
 * (like objects and functions) will be serialized as an empty value. Both keys and values will be URI encoded.
 * @param {Object} queryParams - An object containing the key/value pairs for serialization to a query string.
 * @returns {String} representing the queryParams object as a query string with encoded keys and values.
 */
const getSerializedQueryParams = (queryParams) => {
  const result = [];
  const encodedValue = (key, value) => {
    switch (typeof value) {
      case 'boolean':
      case 'number':
      case 'string':
        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
      default:
        return `${encodeURIComponent(key)}=`;
    }
  };

  Object.keys(queryParams).forEach((key) => {
    // If the object key is an array, then repeat the k/v pair in the string
    if (Array.isArray(queryParams[key])) {
      queryParams[key].forEach((item) => {
        result.push(encodedValue(key, item));
      });
    } else {
      result.push(encodedValue(key, queryParams[key]));
    }
  });
  return result.join('&');
};

/**
 * This function returns the current path without the protocol and domain.
 * @returns {String} The current path without the protocol and domain.
 */
const getPathWithoutProtocolAndDomain = () => {
  if (typeof window !== 'undefined') {
    if (window.location) {
      return `${window.location.pathname}${window.location.search}`;
    }
  }
  throw new Error('Unable to retrieve path. Not running in a browser environment.');
};

export {getAuthorizationHeader, getSerializedQueryParams, getPathWithoutProtocolAndDomain};
