import get from 'lodash/get';

import {setProperty} from '../aepAnalytics';

/**
 *
 * @param {*} sophiaDescriptor
 */
export default function sophiaEventTranslator(sophiaDescriptor) {
  setProperty('sophiaResponse', {
    fromPage: get(sophiaDescriptor, 'responses'),
  });
}
