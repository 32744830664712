import {JIL_CONSTANTS} from '@admin-tribe/acsc';
import {useCallback} from 'react';

import {TRUST_STATUS} from 'features/settings/common/components/trustsConstants';
import useTrustList from 'features/settings/hooks/api/useTrustList';
import useTrustListState from 'features/settings/hooks/trust/useTrustListState';
import useJilTableOptions from 'features/settings/hooks/useJilTableOptions';

/**
 * A hook that holds the state for a directory TrusteeList.
 */
const useDirectoryTrusteeListState = (directoryId, initialData) => {
  const [tableOptions, setTableOptions] = useJilTableOptions({
    sort: JIL_CONSTANTS.SORT.TRUSTEE_ORG_NAME,
  });
  const {getDirectoryTrusts, clearTrustCache} = useTrustList();

  const fetchFn = useCallback(
    (params = tableOptions) =>
      getDirectoryTrusts({...params, directoryId, status: TRUST_STATUS.ACTIVE}),
    [directoryId, getDirectoryTrusts, tableOptions]
  );

  const trustListState = useTrustListState(
    {
      clearCacheFn: clearTrustCache,
      fetchFn,
      setTableOptions,
      tableOptions,
    },
    initialData
  );

  return {...trustListState, setTableOptions, tableOptions};
};

export default useDirectoryTrusteeListState;
