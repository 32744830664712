import {
  Locale,
  authentication,
  csvBlobTransformer,
  downloadExportData,
  log,
} from '@admin-tribe/acsc';
import axios from 'axios';

import API_CONSTANTS from '../../api/ApiConstants';

const {CLIENT_ID} = API_CONSTANTS;

class ContentLog {
  /**
   * @description Creates a new ContentLog for use.
   *
   * @param {Object} options Initialization Object (params described below)
   * @param {String} options.id The id of the ContentLog
   * @param {String} options.path The path of the ContentLog
   * @param {String} options.type The type of the ContentLog
   * @param {Date} options.created The creation date of the ContentLog
   * @param {String} options.status the status of the ContentLog
   * @param {Object} options.children The children of current ContentLog
   * @param {Object} options._links The links of the ContentLog
   * @param {String} options.admin_id The ID of the Admin who created the ContentLog
   * @param {String} options.admin_name The name of the Admin who created the ContentLog
   * @param {Date} options.start_date The start date of the ContentLog
   * @param {Date} options.end_date The end date of the ContentLog
   */
  constructor(options = {}) {
    /* eslint-disable camelcase -- JIL fields are snake_case */
    const {
      id,
      path,
      type,
      created,
      status,
      children,
      _links,
      admin_id,
      admin_name,
      start_date,
      end_date,
    } = options;

    Object.assign(this, {
      _links,
      admin_id,
      admin_name,
      children,
      created,
      end_date,
      id,
      path,
      start_date,
      status,
      type,
    });
    /* eslint-enable camelcase -- JIL fields are snake_case */
  }

  /**
   * @description Downloads reports associated with the content log.
   *
   * @param {String} orgId Org ID
   */
  async download({orgId}) {
    const promises = this.children.map(async (child) => {
      try {
        const tokenObject = authentication.getAccessToken();
        // eslint-disable-next-line no-underscore-dangle -- Underscore dangle in backend variable name
        const response = await axios.get(child._links.primary.href, {
          headers: {
            'Accept-Language': Locale.get().activeLocaleCode,
            Authorization: `Bearer ${tokenObject.token}`,
            'org-id': orgId,
            'X-Api-Key': CLIENT_ID,
            'x-user-token': tokenObject.token,
          },
          responseType: 'blob',
        });

        downloadExportData(csvBlobTransformer(response)?.data?.file, child.name);
      } catch (error) {
        log.error(`Error encountered in download at ${this.id} for child ${child.name}`);
        throw new Error('Error encountered in download.');
      }
    });

    await Promise.all(promises);
  }
}

export default ContentLog;
