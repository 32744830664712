import {GoUrl, OverlayWait, showError, showSuccess} from '@admin-tribe/acsc-ui';
import {DialogContainer, Flex, Heading, Switch, Text, View} from '@adobe/react-spectrum';
import {observer} from 'mobx-react-lite';
import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {useAuthSettingsContext} from 'features/settings/components/auth-settings-page/AuthSettingsContext';
import ConfirmTwoStepVerificationDialog from 'features/settings/components/auth-settings-page/two-step-verification-section/ConfirmTwoStepVerificationDialog';
import {AUTHENTICATION_POLICIES} from 'features/settings/stores/AuthenticationPoliciesStore';

const TwoStepVerificationSection = observer(() => {
  const intl = useIntl();

  const {authenticationPoliciesStore} = useAuthSettingsContext();

  const [isConfirmTwoStepVerificationDialogOpened, toggleConfirmTwoStepVerificationDialog] =
    useState(false);

  const updateAuthenticationPolicy = async () => {
    try {
      if (authenticationPoliciesStore.isMFARequired) {
        await authenticationPoliciesStore.removeAuthenticationPolicy(
          AUTHENTICATION_POLICIES.IS_MFA_REQUIRED
        );
        showSuccess(
          intl.formatMessage({
            id: `settings.authSettings.twoStepVerification.toast.IS_MFA_REQUIRED.delete.success`,
          })
        );
      } else {
        toggleConfirmTwoStepVerificationDialog(true);
      }
    } catch (error) {
      showError(
        intl.formatMessage({
          id: `settings.authSettings.twoStepVerification.toast.IS_MFA_REQUIRED.update.error`,
        })
      );
    }
  };

  return (
    <View data-testid="two-step-verification-section">
      <OverlayWait isLoading={authenticationPoliciesStore.isLoading} showContent>
        <Heading data-testid="titled-section-title" level={2}>
          <FormattedMessage id="settings.authSettings.twoStepVerification.title" />
        </Heading>
        <View
          backgroundColor="gray-50"
          borderColor="gray-200"
          borderRadius="small"
          borderWidth="thin"
          flexGrow="1"
          height="auto"
          marginBottom="size-100"
          width="100%"
        >
          <View padding="size-200">
            <Flex direction="column" gap="size-100" height="100%" padding="size-200">
              <Text data-testid="description">
                <FormattedMessage
                  id="settings.authSettings.twoStepVerification.description2"
                  values={{
                    b:
                      // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- not testing format message values function
                      // istanbul ignore next -- not testing this
                      (chunks) => <strong>{chunks}</strong>,
                  }}
                />
              </Text>
              <Text data-testid="does-not-apply-to-federated-id">
                <FormattedMessage
                  id="settings.authSettings.twoStepVerification.doesNotApplyToFederatedId"
                  values={{
                    b:
                      // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- not testing format message values function
                      // istanbul ignore next -- not testing this
                      (chunks) => <strong>{chunks}</strong>,
                    goUrl: (linkText) => <GoUrl name="ac_authsettings">{linkText}</GoUrl>,
                  }}
                />
              </Text>
              <Switch
                aria-label={intl.formatMessage({
                  id: 'settings.authSettings.twoStepVerification.switchLabel',
                })}
                data-testid="switch"
                isDisabled={authenticationPoliciesStore.isLoadingMFARequired}
                isEmphasized
                isSelected={authenticationPoliciesStore.isMFARequired}
                onChange={updateAuthenticationPolicy}
              >
                <FormattedMessage id="settings.authSettings.twoStepVerification.switchLabel" />
              </Switch>
            </Flex>
          </View>
        </View>
        <DialogContainer onDismiss={() => toggleConfirmTwoStepVerificationDialog(false)}>
          {isConfirmTwoStepVerificationDialogOpened && <ConfirmTwoStepVerificationDialog />}
        </DialogContainer>
      </OverlayWait>
    </View>
  );
});

export default TwoStepVerificationSection;
