import {OrganizationUser} from '@admin-tribe/acsc';
import {
  OverlayWait,
  showError as showErrorToast,
  showSuccess as showSuccessToast,
} from '@admin-tribe/acsc-ui';
import {ActionButton, Flex, View, useDialogContainer} from '@adobe/react-spectrum';
import {looksLikeAnEmail} from '@pandora/administration-utils';
import {useContentEntry} from '@pandora/react-content-provider';
import {GoUrl} from '@pandora/react-go-url';
import {
  ModalContent,
  ModalDescription,
  ModalDialog,
  ModalHeading,
} from '@pandora/react-modal-dialog';
import {SELECTION_MODE, TableSection} from '@pandora/react-table-section';
import {
  PICKER_TYPE,
  SEARCH_TYPE,
  USER_PICKER_MEMBER_TYPE,
  UserPickerContextProvider,
  UserPickerV3,
  UserPickerV3ContentModel,
} from '@pandora/react-user-picker';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';
import {useDomainsListContext} from 'features/settings/common/components/domains-list-context/DomainsListContext';
import {useDomainEnforcementContext} from 'features/settings/components/directory/domain-enforcement/DomainEnforcementContext';
import ExceptionListTable from 'features/settings/components/directory/domain-enforcement/exclude-users-section/ExceptionListTable';
import useDomainEnforcement from 'features/settings/hooks/api/useDomainEnforcement';

const MODAL_ID = 'domain-enforcement-exception-list-modal';
const ERROR_EMAIL_DOMAIN_NOT_IN_AUTHSRC = 'DOMAIN_ENFORCEMENT_EMAIL_DOMAIN_NOT_IN_AUTHSRC';
// eslint-disable-next-line id-length -- the error code is long
const ERROR_INVALID_DOMAIN_ENFORCEMENT_EXCLUSION_REQUEST =
  'INVALID_DOMAIN_ENFORCEMENT_EXCLUSION_REQUEST';

const ExceptionListModal = () => {
  const dialog = useDialogContainer();
  const intl = useIntl();

  const [searchedItem, setSearchedItem] = useState(null);
  const [isSearchClear, setIsSearchClear] = useState(false);
  const [isAddLoading, setIsAddLoading] = useState(false);

  const isNewExclusion = looksLikeAnEmail(searchedItem) && isSearchClear;

  const content = useContentEntry(UserPickerV3ContentModel);

  const {domainEnforcement, loadExceptionList, reloadDomainEnforcementExceptionList} =
    useDomainEnforcementContext();
  const {createExceptions} = useDomainEnforcement();

  const {
    domainList: {domainListData},
  } = useDomainsListContext();

  const allowedDomains = domainListData?.items?.map((domain) => domain.domainName) ?? [];

  // load the list of users excluded from domain enforcement
  useEffect(() => {
    loadExceptionList();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- once on load
  }, []);

  const handleOnFormChange = async (member) => {
    if (domainEnforcement.exceptionList.find((user) => user?.email === member?.email)) {
      showErrorToast(
        intl.formatMessage({
          id: 'settings.domainEnforcement.exceptionListModal.addUser.toast.error.userAlreadyExists',
        })
      );

      return;
    }

    if (domainEnforcement.isLoading !== true && member) {
      try {
        setIsAddLoading(true);

        const {successful, failed} = await createExceptions({
          directoryId: domainEnforcement.authSrc,
          // The JIL API expects an array of emails(and/or ids), but the UI works with single email
          users: [member.email],
        });

        // partial success
        if (successful.length > 0) {
          showSuccessToast(
            intl.formatMessage(
              {
                id: 'settings.domainEnforcement.exceptionListModal.addUser.toast.partialSuccess',
              },
              {successCount: successful.length}
            )
          );
          reloadDomainEnforcementExceptionList();
        }

        // partial failure
        if (failed.length > 0) {
          if (failed[0]?.response?.errorCode === ERROR_EMAIL_DOMAIN_NOT_IN_AUTHSRC) {
            showErrorToast(
              intl.formatMessage({
                id: 'settings.domainEnforcement.exceptionListModal.addUser.toast.error.domainNotInAuthSrc',
              })
            );
          } else if (
            failed[0]?.response?.errorCode === ERROR_INVALID_DOMAIN_ENFORCEMENT_EXCLUSION_REQUEST
          ) {
            showErrorToast(
              intl.formatMessage({
                id: 'settings.domainEnforcement.exceptionListModal.addUser.toast.error.emailAlreadyUsed',
              })
            );
          } else {
            showErrorToast(
              intl.formatMessage(
                {
                  id: 'settings.domainEnforcement.exceptionListModal.addUser.toast.partialFail',
                },
                {failedCount: failed.length}
              )
            );
          }
        }
      } catch (error) {
        showErrorToast(
          intl.formatMessage({
            id: 'settings.domainEnforcement.exceptionListModal.addUser.toast.error',
          })
        );
      } finally {
        setIsAddLoading(false);
      }
    }
  };

  const handleOnInputChange = (value) => {
    setSearchedItem(value);
    setIsSearchClear(false);
  };

  return (
    <ModalDialog heightVariant="static" id={MODAL_ID} onCancel={() => dialog.dismiss()}>
      <ModalHeading>
        {intl.formatMessage({id: 'settings.domainEnforcement.exceptionListModal.title'})}
      </ModalHeading>
      <ModalDescription>
        {intl.formatMessage(
          {id: 'settings.domainEnforcement.exceptionListModal.description'},
          {goUrl: (str) => <GoUrl name="aac_restricting_domains_exception_list">{str}</GoUrl>}
        )}
      </ModalDescription>
      <ModalContent>
        <OverlayWait
          alignSelf="center"
          isLoading={domainEnforcement.isLoading || isAddLoading}
          showContent
        >
          <Flex direction="column">
            <Flex alignItems="center" direction="row" gap="size-200">
              <View marginTop="size-200" maxWidth="size-5000">
                <UserPickerContextProvider canAddUser={false}>
                  <UserPickerV3
                    allowedDomains={allowedDomains}
                    allowedMemberTypes={[USER_PICKER_MEMBER_TYPE.TYPE1]}
                    content={content}
                    description={
                      isNewExclusion
                        ? intl.formatMessage({
                            id: 'settings.domainEnforcement.exceptionListModal.warning',
                          })
                        : null
                    }
                    label={intl.formatMessage({
                      id: 'settings.domainEnforcement.exceptionListModal.search',
                    })}
                    onInputChange={handleOnInputChange}
                    // CLEAR is received when no item is found on search
                    onSelect={handleOnFormChange}
                    onValidationStatusChange={(value) => setIsSearchClear(value === 'CLEAR')}
                    orgId={rootStore.organizationStore.activeOrgId}
                    pickerType={PICKER_TYPE.USERS_ONLY}
                    // The search result limit is set to 0 to get all the results
                    searchResultLimit={0}
                    searchType={SEARCH_TYPE.EXISTING_OR_NEW_USER}
                    width="size-5000"
                  />
                </UserPickerContextProvider>
              </View>

              {isNewExclusion && (
                <View marginTop="size-150">
                  <ActionButton
                    alignSelf="flex-end"
                    onPress={() => handleOnFormChange(new OrganizationUser({email: searchedItem}))}
                  >
                    {intl.formatMessage({
                      id: 'settings.domainEnforcement.exceptionListModal.button.add',
                    })}
                  </ActionButton>
                </View>
              )}
            </Flex>

            {domainEnforcement.exceptionList.length > 0 && (
              <TableSection
                items={domainEnforcement.exceptionList.map((item) => {
                  // Add a key for rendering unique rows
                  // Add an id for selection reference to properly work
                  item.key = item.email;
                  item.id = item.email;
                  return item;
                })}
                pageNumber={0} // disables pagination
                selectionMode={SELECTION_MODE.MULTIPLE}
              >
                <ExceptionListTable />
              </TableSection>
            )}
          </Flex>
        </OverlayWait>
      </ModalContent>
    </ModalDialog>
  );
};

// used in unit testing
export {ERROR_EMAIL_DOMAIN_NOT_IN_AUTHSRC, ERROR_INVALID_DOMAIN_ENFORCEMENT_EXCLUSION_REQUEST};
export default ExceptionListModal;
