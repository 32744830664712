import PropTypes from 'prop-types';
import React from 'react';
import {ErrorBoundary as ReactErrorBoundary} from 'react-error-boundary';

import ErrorBumper from './ErrorBumper';

const ErrorBoundary = ({children}) => {
  function getComponent() {
    return (
      <ErrorBumper
        buttonLabelText="Go Back"
        detailsText="Something went wrong. We're working on the problem. Please try again later."
        iconType="Cloud"
        onButtonClick={() => {
          // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- In browser
          window.history.back();
        }}
        titleText="We are sorry."
      />
    );
  }

  return <ReactErrorBoundary FallbackComponent={getComponent}>{children}</ReactErrorBoundary>;
};

ErrorBoundary.propTypes = {
  children: PropTypes.node,
};

export default ErrorBoundary;
