import {
  showError as showErrorToast,
  showInfo as showInfoToast,
  showSuccess as showSuccessToast,
} from '@admin-tribe/acsc-ui';
import {Button, DialogContainer} from '@adobe/react-spectrum';
import {TableActions, TableFilters, useTableSectionContext} from '@pandora/react-table-section';
import React, {useCallback, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {useDomainsListContext} from 'features/settings/common/components/domains-list-context/DomainsListContext';
import AddDomainButtonPorted from 'features/settings/components/directory/domains/add-domain-button/AddDomainButtonPorted';
import RemoveDomainConfirmationModal from 'features/settings/components/directory/domains/directory-domains-table/RemoveDomainConfirmationModal';
import useDomainList from 'features/settings/hooks/api/useDomainList';

const DirectoryDomainListTableActions = () => {
  const intl = useIntl();
  const {domainList, directoryData, reloadDomainList} = useDomainsListContext();
  const {exportDirectoryDomainsToCSV} = useDomainList();
  const {tableSectionState, tableSectionUtils} = useTableSectionContext();

  const [showRemoveDomainConfirmationModal, setShowRemoveDomainConfirmationModal] = useState(false);
  const [isExportingToCsv, setIsExportingToCsv] = useState(false);

  const onExportToCsv = useCallback(async () => {
    setIsExportingToCsv(true);
    try {
      showInfoToast(intl.formatMessage({id: 'common.toast.csvDownloadBeingPrepared'}));

      await exportDirectoryDomainsToCSV(directoryData);
      showSuccessToast(intl.formatMessage({id: 'common.toast.csvDownloadSuccess'}));
    } catch (error) {
      showErrorToast(intl.formatMessage({id: 'common.toast.downloadError'}));
    } finally {
      setIsExportingToCsv(false);
    }
  }, [directoryData, exportDirectoryDomainsToCSV, intl]);

  const onConfirm = () => {
    tableSectionUtils.clearSelectedItems();
    reloadDomainList({}, {updateCount: true});
  };

  return (
    <>
      <TableFilters
        isDisabled={domainList.hasLoadingError}
        label={intl.formatMessage({
          id: 'settings.directoryDomains.searchPlaceholder',
        })}
      />
      <TableActions>
        {!directoryData.isType2E && (
          <AddDomainButtonPorted
            directoryId={directoryData.id}
            refreshDomains={directoryData.refreshDomains}
          />
        )}
        <Button
          data-testid="export-to-csv"
          isDisabled={isExportingToCsv}
          onPress={onExportToCsv}
          variant="primary"
        >
          <FormattedMessage id="settings.directoryDomains.exportToCsv" />
        </Button>
        <Button
          data-testid="remove-domain"
          isDisabled={tableSectionState.selectedItems.length === 0}
          onPress={() => setShowRemoveDomainConfirmationModal(true)}
          variant="negative"
        >
          <FormattedMessage id="settings.directoryDomains.removeDomain" />
        </Button>
        <DialogContainer onDismiss={() => setShowRemoveDomainConfirmationModal(false)}>
          {showRemoveDomainConfirmationModal && (
            <RemoveDomainConfirmationModal
              domains={tableSectionState.selectedItems}
              onConfirm={onConfirm}
            />
          )}
        </DialogContainer>
      </TableActions>
    </>
  );
};

DirectoryDomainListTableActions.displayName = 'DirectoryDomainListTableActions';

export default DirectoryDomainListTableActions;
