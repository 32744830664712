import {GoUrl} from '@pandora/react-go-url';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import OrgSwitcher from 'core/providers/organization/OrgSwitcher';

const MigrationBumper = ({migrationType, activeOrg}) => {
  const intl = useIntl();
  const migrationTypeToKeyMap = {
    'legacy-to-ac-migrating': 'legacyToACMigrating',
    't2e-migrating': 't2eMigrating',
    'vip2direct-migrating': 'vip2directMigrating',
    'vipmp-migrating': 'vipmpMigrating',
  };

  const migrationKey = migrationTypeToKeyMap[migrationType] || migrationType;

  const iconType = 'Settings';
  const titleKey = `main.routes.${migrationKey}.title`;
  const detailsKey = `main.routes.${migrationKey}.details`;
  const title = intl.formatMessage({id: titleKey}, {orgName: activeOrg});
  const details = intl.formatMessage(
    {id: detailsKey},
    {
      goUrl: (str) => <GoUrl name="aac_vip_migration_bumper">{str}</GoUrl>,
    }
  );

  return <OrgSwitcher details={details} iconType={iconType} title={title} />;
};

MigrationBumper.propTypes = {
  activeOrg: PropTypes.string,
  migrationType: PropTypes.string,
};

export default MigrationBumper;
