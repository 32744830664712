import {download} from '@admin-tribe/acsc';
import {useCallback, useReducer} from 'react';

import {
  CERTIFICATE_TYPES,
  SAML_CERTIFICATE_STATUS,
} from 'features/settings/common/constants/samlCertificatesConstants';
import {IDP_TYPES} from 'features/settings/entities/IdpEntity';
import useSamlCertificates from 'features/settings/hooks/api/useSamlCertificates';
import CertificateModel from 'features/settings/models/CertificateModel';

const ACTIONS = {
  DATA_LOAD: 'dataLoad',
  DATA_LOAD_ERROR: 'dataLoadError',
  FINISH_DATA_LOAD: 'finishDataLoad',
  UPDATE_STATE: 'updateState',
};

/**
 * A reducer which handles the actions dispatch for the SetupCertificatesStep
 */
const setupCertificatesStateReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.DATA_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case ACTIONS.DATA_LOAD_ERROR:
      return {
        ...state,
        hasLoadingError: true,
        isLoading: false,
      };
    case ACTIONS.FINISH_DATA_LOAD:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    case ACTIONS.UPDATE_STATE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

/**
 * A generic hook that can be used to create a certificate list state
 */
const useSetupCertificatesState = ({directoryId, idpId, idpSpDefaultKey}) => {
  const [state, dispatch] = useReducer(setupCertificatesStateReducer, {
    certificates: [],
    csrs: [],
    defaultCertificateId: idpSpDefaultKey,
    directoryId,
    hasLoadingError: false,
    idpId,
    isLoading: false,
  });

  const {
    clearCertificatesCache,
    clearCsrsCache,
    getCertificates,
    getCertificatesSigningRequests,
    getCsrDetails,
    removeCertificate,
    removeCertificateSigningRequest,
    setCertificateAsDefault,
    updateCertificateStatus,
    uploadCsrFile,
    createCertificate,
    createCertificateSigningRequest,
  } = useSamlCertificates();

  /**
   * Function used to fetch a SAML certificate list.
   * It handles setting the state on the context, including error and loading states.
   */
  const loadCertificatesList = useCallback(async () => {
    dispatch({type: ACTIONS.DATA_LOAD});

    try {
      const [certificates, csrs] = await Promise.all([
        getCertificates({directoryId, idpId}),
        getCertificatesSigningRequests({directoryId, idpId}),
      ]);

      const mappedCertificates = certificates.map((certificate) =>
        CertificateModel.fromObject({
          ...certificate,
          directoryId,
          idpId,
          type: CERTIFICATE_TYPES.ADOBE_SIGNED,
        })
      );
      const mappedCsrs = csrs.map((csr) =>
        CertificateModel.fromObject({...csr, type: CERTIFICATE_TYPES.CERTIFICATE_SIGNING_REQUEST})
      );

      dispatch({
        payload: {certificates: mappedCertificates, csrs: mappedCsrs},
        type: ACTIONS.FINISH_DATA_LOAD,
      });

      return [...certificates, ...csrs];
    } catch (error) {
      dispatch({type: ACTIONS.DATA_LOAD_ERROR});
      return undefined;
    }
  }, [directoryId, getCertificates, getCertificatesSigningRequests, idpId]);

  /**
   * Function used to reload the list of certificates
   */
  const reloadCertificatesList = useCallback(async () => {
    // clear the cache and reload the list of certificates
    clearCertificatesCache();
    clearCsrsCache();

    await loadCertificatesList();
  }, [clearCertificatesCache, clearCsrsCache, loadCertificatesList]);

  const activateCertificate = useCallback(
    async (certificateId) => {
      try {
        dispatch({type: ACTIONS.DATA_LOAD});

        await updateCertificateStatus({
          certificateId,
          directoryId,
          idpId,
          status: SAML_CERTIFICATE_STATUS.ACTIVE,
        });
        await reloadCertificatesList();
      } catch (error) {
        dispatch({type: ACTIONS.FINISH_DATA_LOAD});
        throw error;
      }
    },
    [directoryId, idpId, reloadCertificatesList, updateCertificateStatus]
  );

  const deactivateCertificate = useCallback(
    async (certificateId) => {
      try {
        dispatch({type: ACTIONS.DATA_LOAD});

        await updateCertificateStatus({
          certificateId,
          directoryId,
          idpId,
          status: SAML_CERTIFICATE_STATUS.DISABLED,
        });
        await reloadCertificatesList();

        dispatch({type: ACTIONS.FINISH_DATA_LOAD});
      } catch (error) {
        dispatch({type: ACTIONS.FINISH_DATA_LOAD});
        throw error;
      }
    },
    [directoryId, idpId, reloadCertificatesList, updateCertificateStatus]
  );

  // TODO: 500 error response for CSRs...either other API is needed (and doesn't exist)  or there is a bug in the keys API
  const deleteCertificate = useCallback(
    async (certificateId, certificateType) => {
      try {
        dispatch({type: ACTIONS.DATA_LOAD});

        if (certificateType === CERTIFICATE_TYPES.ADOBE_SIGNED) {
          await removeCertificate({
            certificateId,
            directoryId,
            idpId,
          });
        } else {
          await removeCertificateSigningRequest({
            csrId: certificateId,
            directoryId,
            idpId,
          });
        }

        await reloadCertificatesList();
      } catch (error) {
        dispatch({type: ACTIONS.FINISH_DATA_LOAD});
        throw error;
      }
    },
    [directoryId, idpId, reloadCertificatesList, removeCertificate, removeCertificateSigningRequest]
  );

  const downloadCsrFile = useCallback(
    async (csrId) => {
      try {
        dispatch({type: ACTIONS.DATA_LOAD});

        const response = await getCsrDetails({
          csrId,
          directoryId,
          idpId,
        });

        download(
          new Blob([response.data], {type: response.headers['Content-Type']}),
          `${csrId}.csr`
        );

        await reloadCertificatesList();
      } catch (error) {
        dispatch({type: ACTIONS.FINISH_DATA_LOAD});
        throw error;
      }
    },
    [directoryId, getCsrDetails, idpId, reloadCertificatesList]
  );

  const setDefault = useCallback(
    async (certificateId) => {
      try {
        dispatch({type: ACTIONS.DATA_LOAD});

        await setCertificateAsDefault({
          certificateId,
          directoryId,
          federationType: IDP_TYPES.SAML,
          idpId,
        });

        dispatch({payload: {defaultCertificateId: certificateId}, type: ACTIONS.UPDATE_STATE});

        await reloadCertificatesList();
      } catch (error) {
        dispatch({type: ACTIONS.FINISH_DATA_LOAD});
        throw error;
      }
    },
    [directoryId, idpId, reloadCertificatesList, setCertificateAsDefault]
  );

  const createNewCertificate = useCallback(async () => {
    try {
      dispatch({type: ACTIONS.DATA_LOAD});

      await createCertificate({
        directoryId,
        idpId,
      });

      await reloadCertificatesList();
    } catch (error) {
      dispatch({type: ACTIONS.FINISH_DATA_LOAD});
      throw error;
    }
  }, [createCertificate, directoryId, idpId, reloadCertificatesList]);

  const createCsr = useCallback(
    async (certificateData) => {
      try {
        dispatch({type: ACTIONS.DATA_LOAD});

        await createCertificateSigningRequest({
          certificateData,
          directoryId,
          idpId,
        });

        await reloadCertificatesList();
      } catch (error) {
        dispatch({type: ACTIONS.FINISH_DATA_LOAD});
        throw error;
      }
    },
    [createCertificateSigningRequest, directoryId, idpId, reloadCertificatesList]
  );

  const completeCsr = useCallback(
    async ({csrId, csrFile}) => {
      try {
        dispatch({type: ACTIONS.DATA_LOAD});

        await uploadCsrFile({csrFile, csrId, directoryId, idpId});

        await reloadCertificatesList();
      } catch (error) {
        dispatch({type: ACTIONS.FINISH_DATA_LOAD});
        throw error;
      }
    },
    [uploadCsrFile, directoryId, idpId, reloadCertificatesList]
  );

  return {
    activateCertificate,
    completeCsr,
    createCsr,
    createNewCertificate,
    deactivateCertificate,
    deleteCertificate,
    downloadCsrFile,
    loadCertificatesList,
    reloadCertificatesList,
    setDefault,
    state,
  };
};

export default useSetupCertificatesState;
export {setupCertificatesStateReducer};
