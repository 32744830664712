import {canAddProducts, feature, hasIndirectProduct, hasTeamContract, log} from '@admin-tribe/acsc';

import StorageRepositoryList from 'common/services/storage-repository-list/StorageRepositoryList';
import rootStore from 'core/RootStore';
import auth from 'core/services/auth';

/**
 * @description Method to determine if the org has esm
 *
 * @returns {Promise} Resolves with true if the org has storage repo
 */
async function activeOrgHasESM() {
  if (userCanCallStorageAPIs()) {
    try {
      const storageRepositoryList = await StorageRepositoryList.get();
      return storageRepositoryList.items.some((item) => item.entitled && item.provisioned);
    } catch (error) {
      log.error('Failed to fetch StorageRepositoryList. Error: ', error);
      return Promise.reject(error);
    }
  }
  return Promise.resolve(false);
}

/**
 * @description Method to determine if the org has access to purchase additional storage
 *
 * @returns {Promise} Resolves true if the org has access to purchase additional storage
 */
function canPurchaseAdditionalStorage() {
  const contractList = rootStore.organizationStore.contractList;
  const productList = rootStore.organizationStore.productList;

  const checkOrgHasESM =
    canAddProducts(contractList) &&
    (hasTeamContract(contractList) || hasIndirectProduct(productList));
  return checkOrgHasESM ? activeOrgHasESM() : Promise.resolve(false);
}

/**
 * @description Method to determine if the org has access to view Individual User Folders
 *
 * @returns {Promise<Boolean>} Resolves with boolean, true if the org has access to view Individual User Folders, false otherwise
 */
function canViewIndividualUserFolders() {
  // This doesn't just return true like shared folders, because the check is used outside of routes
  // to determine whether to offer asset reclamation during user removal. It needs to check for the
  // presence of storage at all on the org, just like general storage access.
  return canViewStorage();
}

/**
 * @deprecated with temp_storage_shared_projects
 * @description Method to determine if the org has access to view Libraries Folders
 *
 * @returns {Promise<Boolean>} Resolves with boolean, true if the org has access to view Libraries, false otherwise
 */
function canViewLibraries() {
  return feature.isEnabled('temp_storage_shared_projects')
    ? Promise.resolve(false)
    : activeOrgHasESM();
}

/**
 * @description Method to determine if the org has access to view the "Shared projects" pages
 *
 * @returns {Promise<Boolean>} Resolves with boolean, true if the org has access to view Projects, false otherwise
 */
function canViewSharedProjects() {
  return feature.isEnabled('temp_storage_shared_projects')
    ? activeOrgHasESM()
    : Promise.resolve(false);
}

/**
 * @description Method to determine if user and org has access to Storage related content
 *
 * @returns {Promise<Boolean>} Resolves with true if the user and org has access to Storage related content
 */
async function canViewStorage() {
  if (rootStore.organizationStore.isActiveOrgDeveloper || !userCanCallStorageAPIs()) {
    return Promise.resolve(false);
  }

  try {
    const repositoryList = await StorageRepositoryList.get();
    const hasStorage = repositoryList.items.some((item) => item.entitled && item.provisioned);
    return Promise.resolve(hasStorage);
  } catch (error) {
    return Promise.resolve(false);
  }
}

/**
 * @description Method to determine if the admin has a role that allows them to call storage APIs
 *
 * @returns {Boolean} Returns true if the admin's role allows them to call storage APIs
 */
function userCanCallStorageAPIs() {
  return auth.isUserOrgAdmin() || auth.isUserStorageAdmin();
}

export {
  activeOrgHasESM,
  canPurchaseAdditionalStorage,
  canViewIndividualUserFolders,
  canViewLibraries,
  canViewSharedProjects,
  canViewStorage,
};
