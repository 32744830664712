import {feature} from '@admin-tribe/acsc';
import {Workspace} from '@admin-tribe/acsc-ui';
import React from 'react';

import SupportWorkspaceNav from 'features/support/components/support-workspace/SupportWorkspaceNav';
import {goToExl, isDxOnly} from 'features/support/utils/SupportUtils';

/**
 * Support workspace page wrapper for side nav
 */
const SupportWorkspaceWrapper = () => {
  if (feature.isEnabled('temp_support_experience_league') && isDxOnly()) {
    return goToExl();
  }
  return (
    <Workspace useOutlet>
      <SupportWorkspaceNav />
    </Workspace>
  );
};

export default SupportWorkspaceWrapper;
