import {DetailSection} from '@admin-tribe/acsc-ui';
import {Content, Header, Heading, Text, View} from '@adobe/react-spectrum';
import {Drawer} from '@pandora/react-drawer';
import {GoUrl} from '@pandora/react-go-url';
import PropTypes from 'prop-types';
import React from 'react';

const STORAGE_DETAIL_DRAWER_ID = 'storage-detail-drawer-id';

/**
 * Reusable React component for all Details Drawers in the Storage section in Admin Console
 */
const StorageDetailDrawer = ({headerText, descriptionText, helpfulLinksText, goUrls}) => (
  <Drawer aria-labelledby={STORAGE_DETAIL_DRAWER_ID}>
    <Header>
      <Heading id={STORAGE_DETAIL_DRAWER_ID} level={2} marginBottom="size-125" marginTop="size-0">
        {headerText}
      </Heading>
    </Header>
    <Content>
      <View>
        <Text>{descriptionText}</Text>
      </View>

      <DetailSection marginTop="size-200" title={helpfulLinksText}>
        <View marginTop="size-100">
          {goUrls.map((link, index) => (
            <View key={`url-${index + 1}`} marginBottom="size-40">
              <GoUrl name={link.name}>{link.label}</GoUrl>
            </View>
          ))}
        </View>
      </DetailSection>
    </Content>
  </Drawer>
);

StorageDetailDrawer.propTypes = {
  /* The description text at the top of the drawer */
  descriptionText: PropTypes.string.isRequired,

  /* The list of URLs to show below the description text */
  goUrls: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      name: PropTypes.string,
    })
  ).isRequired,

  /* The header text of the drawer */
  headerText: PropTypes.string.isRequired,

  /* Helpful links text */
  helpfulLinksText: PropTypes.string.isRequired,
};

export default StorageDetailDrawer;
